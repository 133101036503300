import { Button, Kind, Size } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { merchantUsersApi } from 'api/merchantUsers'
import billDotComLogo from 'assets/images/billing/logo_billcom.svg'
import Cell from 'components/Cell'
import HeaderCell from 'components/HeaderCell'
import Section from 'components/Section'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Row, Table } from 'react-bootstrap-five'
import { push } from 'react-router-redux'
import { useAbility } from 'utilities/ability'

const t = buildTranslate('billing')
const billDotComLink = 'https://app.bill.com/p/thanxbilling'

const BillingPage = () => {
  const [css] = useStyletron()
  const canManageMerchantUsers = useAbility().can('manage', 'MerchantUsers')
  const dispatch = useDispatch()

  const { data, isLoading } = merchantUsersApi.useGetMerchantUsersQuery({
    roles: ['accountant', 'admin'],
  })
  const merchantUsers = data?.merchant_users || []

  return (
    <div>
      <h3 className="mt-0 extra-bold">{t('pay_balance')}</h3>
      <Section className="p-m mt-l" kind="primary">
        <Row className="align-items-center">
          <Col sm="auto">
            <img
              src={billDotComLogo}
              className={css({ height: '24px' })}
              alt={t('bill_com_logo')}
            />
          </Col>
          <Col md={6} sm={7} className="mr-md-auto mr-sm-auto my-3 my-sm-0">
            <Text.BodyText3 color="grey70" className="m-0" tag="p">
              {t('pay_balance_message')}
            </Text.BodyText3>
          </Col>
          <Col sm="auto">
            <Button onClick={() => window.open(billDotComLink)}>
              {t('pay_now')}
            </Button>
          </Col>
        </Row>
      </Section>
      <Row className="mt-xl mb-m align-items-center">
        <Col as={'h3'} className="my-0 extra-bold">
          {t('invoices_sent_to')}
        </Col>
        <Col xs={'auto'}>
          <Button
            kind={Kind.SECONDARY}
            size={Size.SMALL}
            onClick={() => dispatch(push('/merchant_users'))}
            disabled={!canManageMerchantUsers}
          >
            {t('manage_users')}
          </Button>
        </Col>
      </Row>
      <Spinner isLoading={isLoading} size="4x" className="text-center mt-l">
        <Table>
          <thead>
            <tr>
              <HeaderCell className="border-bottom-0 grey-90">
                {t('email')}
              </HeaderCell>
            </tr>
          </thead>
          <tbody>
            {merchantUsers.map(user => {
              return (
                <tr key={user.id}>
                  <Cell className="border-top-0 border-bottom-1 grey-30-border">
                    {user.email}
                  </Cell>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Spinner>
    </div>
  )
}

export default BillingPage
