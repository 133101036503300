import {
  BannerStyles,
  LandingPageTheme,
  LandingPageThemeUpdatePayload,
  Settings,
} from 'api/landingPageTheme'
import { FormModel } from './hooks'

export const getDefaultBannerStyles = (): BannerStyles => {
  return {
    location_banner: {
      background_color: '#F26335',
      header_text_color: '#FFFFFF',
      body_text_color: '#FFFFFF',
      button_color: '#FFFFFF',
    },
    order_placed_banner: {
      background_color: '#F26335',
      header_text_color: '#FFFFFF',
      body_text_color: '#FFFFFF',
      button_color: '#FFFFFF',
    },
    error_banner: {
      background_color: '#F26335',
      body_text_color: '#FFFFFF',
      button_color: '#FFFFFF',
    },
  }
}

type GetSubmitPayloadT = {
  formModel: FormModel
  landingPageTheme: LandingPageTheme | null
  defaultSettings: Settings
}

export const getSubmitPayload = ({
  formModel,
  landingPageTheme,
  defaultSettings,
}: GetSubmitPayloadT): LandingPageThemeUpdatePayload['landing_page_theme'] => {
  const existingContentBlocks = (landingPageTheme?.content_blocks ?? []).map(
    ({ id, position, type, settings }) => ({
      id,
      position,
      type,
      settings,
    })
  )

  const themeStyles = !!landingPageTheme
    ? landingPageTheme.settings
    : defaultSettings

  const menuCategoryStyles =
    !!landingPageTheme &&
    landingPageTheme.settings.menu_category_page.background_color
      ? landingPageTheme.settings.menu_category_page
      : defaultSettings.menu_category_page

  return {
    id: landingPageTheme?.id,
    content_blocks: existingContentBlocks,
    settings: {
      ...themeStyles,
      menu_category_page: menuCategoryStyles,
      ...formModel,
    },
  }
}
