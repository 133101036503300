import { Modal } from '@thanx/uikit/modal'
import CampaignEmailPreview from 'components/CampaignEmailPreview'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { useVariant } from 'scenes/CampaignCenter/Builder/components/VariantContext'

type Props = {
  campaign: Campaign
  show: boolean
  onModalClose: () => void
}

const PreviewModal: React.FC<Props> = props => {
  const { campaign, show, onModalClose } = props
  const { config } = useCampaignConfig()
  const variant = useVariant()

  const variantConfig = variant || config

  return (
    <Modal
      size="auto"
      position="center"
      closeType="small"
      isOpen={show}
      onClose={onModalClose}
      zIndex={1000}
    >
      <div className="fs-unmask p-m">
        <CampaignEmailPreview campaign={campaign} config={variantConfig} />
      </div>
    </Modal>
  )
}

export default PreviewModal
