import Section from 'components/Section'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import { cmsBrandedAppConfigUrl } from 'utilities/urlUtils'
import Header from '../../components/Header'
import Asset from '../Asset'
import ImageAsset from '../ImageAsset'

const t = buildTranslate('cms.brand.brand_assets')

const CoverImage: React.FC = () => {
  const merchant = useCurrentMerchant()

  if (!merchant) return null

  const { cover_image_urls } = merchant

  return (
    <Section className="p-xl mt-l">
      <Header
        title={t('cover_image.title')}
        linkText={t('where_customers')}
        link={cmsBrandedAppConfigUrl}
      />
      <Asset
        className="mt-s"
        description={t('cover_image.description')}
        badges={[t('cover_image.size'), t('cover_image.jpg')]}
      >
        <ImageAsset image={cover_image_urls?.large} height="130px" />
      </Asset>
    </Section>
  )
}

export default CoverImage
