import birthdayImage from 'assets/images/campaign-center/opportunity/birthday/example.png'
import bouncebackImage from 'assets/images/campaign-center/opportunity/bounceback/example.png'
import referralsImage from 'assets/images/campaign-center/opportunity/referrals/example.png'
import reputationImage from 'assets/images/campaign-center/opportunity/reputation/example.png'
import tiersImage from 'assets/images/campaign-center/opportunity/tiers/example.png'
import vipImage from 'assets/images/campaign-center/opportunity/vip/example.png'
import winbackImage from 'assets/images/campaign-center/opportunity/winback/example.png'
import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'

type Props = {
  campaignType: string
  merchantName?: string
}

const UseCase: React.FC<Props> = ({ campaignType, merchantName }) => {
  let image

  switch (campaignType) {
    case 'reputation':
      image = reputationImage
      break
    case 'winback':
      image = winbackImage
      break
    case 'bounceback':
      image = bouncebackImage
      break
    case 'birthday':
      image = birthdayImage
      break
    case 'tiers':
      image = tiersImage
      break
    case 'vip':
      image = vipImage
      break
    case 'referrals':
      image = referralsImage
      break
    default:
      break
  }

  return (
    <div className="margin-bottom-huge padding-large">
      <div className="display-flex flex-justify-center flex-wrap flex-align-center">
        <div className="col-md-3 col-sm-12 padding-large">
          <Translate
            tag="div"
            className="font-size-12 letter-spacing-1 uppercase spearmint-50 bold"
            value={`thanx_campaigns.opportunity.${campaignType}.example.header`}
          />
          <hr className="pipe spearmint-50-bkg inline-block margin-top-medium margin-bottom-medium" />
          <Translate
            tag="h5"
            className="blue-chrome-60"
            value={`thanx_campaigns.opportunity.${campaignType}.example.title`}
          />
          <Translate
            tag="p"
            className="grey-70 font-size-16"
            value={`thanx_campaigns.opportunity.${campaignType}.example.text_one`}
            dangerousHTML
          />
        </div>
        <img
          src={image}
          className="img-responsive"
          alt={I18n.t(
            `thanx_campaigns.opportunity.${campaignType}.example.image_alt`
          )}
          style={{ maxWidth: 500 }}
        />
        <div className="col-md-3 col-sm-12 padding-large">
          <Translate
            tag="h6"
            className="blue-chrome-40"
            value={`thanx_campaigns.opportunity.${campaignType}.example.text_two`}
            merchantName={merchantName}
            dangerousHTML
          />
        </div>
      </div>
    </div>
  )
}

export default UseCase
