import Flash from 'components/Flash'
import React, { createContext, useContext, useState } from 'react'
import Navbar from './Navbar/Bar'

const Header = () => {
  const { shouldShow } = useHeaderContext()

  if (!shouldShow) return null

  return (
    <header>
      <Navbar />
      <Flash />
    </header>
  )
}

type HeaderContextT = {
  shouldShow: boolean
  setShouldShow: (value: boolean) => void
}

export const HeaderContext = createContext<HeaderContextT>({
  shouldShow: true,
  setShouldShow: () => {},
})

export const HeaderContextProvider: React.FC = ({ children }) => {
  const [shouldShow, setShouldShow] = useState(true)
  return (
    <HeaderContext.Provider
      value={{
        shouldShow,
        setShouldShow,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
}

export function useHeaderContext() {
  const value = useContext(HeaderContext)

  if (value === null) {
    const errorInfo =
      'useHeaderContext must be used within a HeaderContext.Provider'
    throw errorInfo
  }

  return value
}

export default Header
