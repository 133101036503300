import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import questionIcon from 'assets/images/redeem-manager/icon_question.svg'
import HelpLink from 'components/HelpLink'

export type Article = {
  title: string
  url: string
}

type Props = {
  className?: string
  articles: Article[]
  title: string
}

const Help = ({ className = '', articles, title }: Props) => {
  const [css] = useStyletron()

  if (!articles?.length) {
    return null
  }

  return (
    <div className="my-xl">
      <Text.BodyText3 bold color="grey90" tag="div" className="mb-s d-flex">
        <img src={questionIcon} className="mr-xxs" alt="lock" />
        {title}
      </Text.BodyText3>
      {articles.map(article => (
        <HelpLink
          key={article.title}
          url={article.url}
          className={`${css({
            display: 'block',
            marginLeft: '-16px',
          })} ${className}`}
        >
          {article.title}
        </HelpLink>
      ))}
    </div>
  )
}

export default Help
