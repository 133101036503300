import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Counter from 'components/Counter'
import CircleChart from 'components/graph/CircleChart'
import HelpLink from 'components/HelpLink'
import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import palette from 'constants/palette'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import {
  LifecycleRecentMetric,
  LifecycleRecentShortType,
} from 'models/MerchantMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  getBenchmarkConversionRate,
  stagesMetricsMappingWithDetailedReport,
  stagesSegmentsMapping,
  stagesWithDetailedReport,
} from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { selectMerchantMetricByType } from 'selectors/merchantMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'
import {
  conversionHelpUrl,
  lifecycleBenchmarksHelpUrl,
} from 'utilities/urlUtils'

const t = buildTranslate('lifecycle_report.detail.current_conversion')

type Props = {
  stage: typeof stagesWithDetailedReport[number]
}

const CurrentConversion: React.FC<Props> = ({ stage }) => {
  const metric = stagesMetricsMappingWithDetailedReport[stage]
  const segment = stagesSegmentsMapping[stage]

  const [css] = useStyletron()
  const memberLifecycleRecentState = useSelector(state =>
    selectMerchantMetricByType(
      state,
      `member_lifecycle_recent_state_${segment}` as LifecycleRecentShortType
    )
  ) as LifecycleRecentMetric
  const merchant = useCurrentMerchant()
  if (!memberLifecycleRecentState) {
    return null
  }

  const convertedCount = memberLifecycleRecentState.data?.user_converted_count
  const previousCount = memberLifecycleRecentState.data?.user_count
  const conversionRate =
    memberLifecycleRecentState.data?.user_converted_fraction ?? 0
  const benchmarkConversionRate = getBenchmarkConversionRate({
    merchantType: merchant?.merchant_type,
    metric: metric,
    recency: 'current',
  })

  return (
    <>
      <Text.Header3 bold color="grey90" className="mt-0 mb-xs">
        {t('title')}
      </Text.Header3>
      <Text.BodyText3 tag="div" color="grey70" className="mt-0 mb-xl">
        {t('description')}
      </Text.BodyText3>
      <div
        className={`p-l mb-xl ${css({
          border: `1px solid ${palette.grey30}`,
          borderRadius: '4px',
        })}`}
      >
        <div className="d-flex">
          <div className="pr-s">
            <i
              className={`fa fa-calendar font-size-16 grey-90 ${css({
                position: 'relative',
                top: '-6px',
              })}`}
            />
          </div>
          <div>
            <Text.SmallCaps3 tag="p" className="pb-s m-0" color="grey90" bold>
              {t('info_box.title')}
            </Text.SmallCaps3>
            <Text.Header4 bold className="m-0" color="grey90">
              {t(`info_box.${stage}.description`)}
            </Text.Header4>
            <HelpLink
              url={conversionHelpUrl}
              className="border-left-0 pl-0 mt-xs"
            >
              {t('info_box.help_link')}
            </HelpLink>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <CircleChart
          radius={190}
          fraction={conversionRate}
          content={
            <div
              className={`d-flex align-items-center flex-column text-center p-xl`}
            >
              <Counter
                shouldCenter
                primaryValue={`${(100 * conversionRate).toFixed(1)}%`}
                isLoading={!!memberLifecycleRecentState.isLoading}
                isErrored={!!memberLifecycleRecentState.isErrored}
                name={t(`${stage}.radial_converted.description`)}
              />
              <Text.BodyText4 className="mt-xl" color={palette.wisteria}>
                {t(`benchmark_description`, {
                  rate: percentageFormatter(benchmarkConversionRate, 0, 0),
                })}
              </Text.BodyText4>
            </div>
          }
          strokeWidth={16}
          strokeColor={palette.spearmint50}
          markFraction={benchmarkConversionRate}
          markColor={palette.wisteria}
          markLength={24}
          markWidth={2}
          animate={
            !memberLifecycleRecentState.isLoading &&
            !memberLifecycleRecentState.isErrored
          }
        />
        <div className="ml-xl w-100">
          <InfoRow
            title={t(`${stage}.previous_count.description`)}
            primaryContent={
              <div className="ml-xl">
                <Metric metric={numberFormatter(previousCount)}>
                  <PopoverContent
                    sections={[
                      {
                        content: t(`${stage}.previous_count.tooltips`),
                      },
                      {
                        title: t('tooltips.title.recency'),
                        content: t(`tooltips.recency`),
                      },
                    ]}
                  />
                </Metric>
              </div>
            }
            isLoading={!!memberLifecycleRecentState.isLoading}
            isErrored={!!memberLifecycleRecentState.isErrored}
          />
          <InfoRow
            title={t(`${stage}.converted.description`)}
            primaryContent={
              <div className="ml-xl">
                <Metric metric={numberFormatter(convertedCount)}>
                  <PopoverContent
                    sections={[
                      {
                        content: t(`${stage}.converted.tooltips`),
                      },
                      {
                        title: t('tooltips.title.recency'),
                        content: t(`tooltips.recency`),
                      },
                    ]}
                  />
                </Metric>
              </div>
            }
            isLoading={!!memberLifecycleRecentState.isLoading}
            isErrored={!!memberLifecycleRecentState.isErrored}
          />
          <div className="d-flex justify-content-end">
            <HelpLink url={lifecycleBenchmarksHelpUrl} className="pr-0 mt-xs">
              {t('help_link')}
            </HelpLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default CurrentConversion
