import { Button } from '@thanx/uikit/button'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = PassDownProps<boolean> & {
  disabled?: boolean
  onToggle?: (value: boolean) => void
}

const t = buildTranslate('redeem_manager.builder.buttons')

const FormsyEnabledButton: React.FC<Props> = props => {
  const { value: isEnabled, disabled, onToggle, setValue } = props
  const onClick = () => {
    const newValue = !isEnabled
    if (onToggle) {
      onToggle(newValue)
    }
    setValue(newValue)
  }
  return (
    <Button
      className="p-0"
      kind="minimal"
      disabled={disabled}
      onClick={onClick}
    >
      {t(isEnabled ? 'disable' : 'enable')}
    </Button>
  )
}

export default withFormsy(FormsyEnabledButton)
