import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useVariant } from 'scenes/CampaignCenter/Builder/components/VariantContext'
import DayPart from './DayPart'

const t = buildTranslate('thanx_campaigns.report.v3.configuration')

type Props = {
  className?: string
  campaign: Campaign
  isControlVariant?: Boolean
}
const RewardSection: React.FC<Props> = ({
  campaign,
  className = '',
  isControlVariant = false,
}) => {
  const variant = useVariant()
  const {
    redeem,
    redeem_description,
    redeem_retire_after_days,
    redeem_minimum,
    redeem_maximum,
    redeem_restriction_locations_description,
    redeem_type,
    redeem_venue,
    fine_print,
  } = variant || campaign
  const { config_signup_required, config_target_subscriber } = campaign
  const retireAfterText =
    redeem_type === 'experience/hidden_menu'
      ? t('restrictions.retire_after_days_hidden_menu', {
          days: redeem_retire_after_days,
        })
      : t('restrictions.retire_after_days', {
          days: redeem_retire_after_days,
        })
  return (
    <Section
      className={`p-xl ${className}`}
      kind={!isControlVariant && redeem ? 'primary' : 'secondary'}
    >
      <Text.Header4 className="m-0 mb-s">{t('reward')}</Text.Header4>

      <Switch
        condition={!isControlVariant && redeem}
        fallback={
          <Text.BodyText4 color="grey70">{t('no_reward')}</Text.BodyText4>
        }
      >
        <Text.BodyText4 tag="p" color="grey70" className="m-0 mb-m">
          {redeem_description}
        </Text.BodyText4>
        <Text.BodyText4 tag="p" color="grey90" bold className="m-0 mb-xs">
          {t('restrictions_text')}
        </Text.BodyText4>
        <DayPart campaign={campaign} />
        <Switch condition={!!redeem_retire_after_days}>
          <Text.BodyText4 tag="p" color="grey70" className="m-0 mb-xs">
            {retireAfterText}
          </Text.BodyText4>
        </Switch>
        <Switch condition={!!redeem_restriction_locations_description}>
          <Text.BodyText4 tag="p" color="grey70" className="m-0 mb-xs">
            {t('restrictions.locations', {
              locations: redeem_restriction_locations_description,
            })}
          </Text.BodyText4>
        </Switch>
        <Switch
          condition={!!config_signup_required && !!config_target_subscriber}
        >
          <Text.BodyText4 tag="p" color="grey70" className="m-0 mb-xs">
            {t('restrictions.signup_required')}
          </Text.BodyText4>
        </Switch>
        <Switch condition={!!redeem_minimum}>
          <Text.BodyText4 tag="p" color="grey70" className="m-0 mb-xs">
            {t('restrictions.min_amount', { amount: redeem_minimum })}
          </Text.BodyText4>
        </Switch>
        <Switch condition={!!redeem_maximum}>
          <Text.BodyText4 tag="p" color="grey70" className="m-0 mb-xs">
            {t('restrictions.max_amount', { amount: redeem_maximum })}
          </Text.BodyText4>
        </Switch>
        <Switch
          condition={!!redeem_venue}
          fallback={
            <Text.BodyText4 tag="p" color="grey70" className="m-0">
              {t(`restrictions.redemption_venue.all`)}
            </Text.BodyText4>
          }
        >
          <Text.BodyText4 tag="p" color="grey70" className="m-0">
            {t(`restrictions.redemption_venue.${redeem_venue}`)}
          </Text.BodyText4>
        </Switch>
        <Switch condition={!!fine_print}>
          <Text.BodyText4
            tag="p"
            color="grey90"
            bold
            className="m-0 mb-xs mt-m"
          >
            {t('fine_print')}
          </Text.BodyText4>
          <Text.BodyText4 tag="p" color="grey70" className="m-0">
            {fine_print}
          </Text.BodyText4>
        </Switch>
      </Switch>
    </Section>
  )
}

export default RewardSection
