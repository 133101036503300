import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import TestIcon from 'assets/images/campaign-center/reports/icons/test-icon.svg'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type PropsT = {
  campaign: Partial<Campaign>
  className?: string
}

const TestBadge: React.FC<PropsT> = props => {
  const { campaign, className = '' } = props
  const [css] = useStyletron()
  if (!campaign.variants || campaign.variants.length < 2) return null
  let text
  switch (campaign.state) {
    case 'active':
    case 'scheduled':
    case 'complete':
      text = campaign.state
      break
    default:
      text = 'draft'
  }
  if (campaign.winning_variant_id) text = 'complete'
  return (
    <div
      className={`white-bkg border grey-20-border inline-block py-xxs px-xs ${css(
        {
          borderRadius: '4px',
        }
      )} ${className}`}
    >
      <Text.SmallCaps3 bold color="grey70" tag="p" className="m-0 d-flex">
        <img src={TestIcon} className="mr-xxs" alt="" />
        {I18n.t('thanx_campaigns.report.test_badge')} {text}
      </Text.SmallCaps3>
    </div>
  )
}

export default TestBadge
