import { Text } from '@thanx/uikit/text'
import { Fields as CampaignType } from 'models/CampaignType'
import { Fields as UserCampaign } from 'models/UserCampaign'
import React from 'react'
import CategoryIndicator from 'scenes/CampaignCenter/components/CategoryIndicator'
import TestBadge from 'scenes/CampaignCenter/Report/components/TestBadge'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { formatDate } from 'scenes/Users/View/helpers'
import { useUserTimeZone } from 'utilities/userTimeZone'
import StatusBadge from './StatusBadge'

import ReportLink from './ReportLink'

type PropsT = {
  userCampaign: UserCampaign
  campaignTypes: Array<CampaignType>
  onReportClick?: (campaign: UserCampaign) => void
}

const Row: React.FC<PropsT> = ({ userCampaign, campaignTypes }: PropsT) => {
  const timezone = useUserTimeZone()
  const deliveredAt = formatDate(timezone, userCampaign.delivered_at)
  const { campaign } = userCampaign

  const campaignType = campaignTypes.find(c => c.type === campaign.type)
  if (!campaignType) return null

  const name = (() => {
    const campaignName = userCampaign.campaign.name
    if (!userCampaign.variant) return campaignName

    return `${campaignName} - Variant ${userCampaign.variant}`
  })()

  return (
    <tr>
      <BodyCellContainer isLoading={false} isErrored={false}>
        <Text.BodyText3 color="grey70">
          <div>{name}</div>
          <div className="d-flex mt-xxxs">
            {campaign.type && (
              <CategoryIndicator category={campaignType.category} />
            )}
            <TestBadge campaign={campaign} className="ml-xs" />
          </div>
        </Text.BodyText3>
      </BodyCellContainer>
      <BodyCellContainer isErrored={false} isLoading={false}>
        <Text.BodyText4 tag={'p'} className={'mb-0'} color="grey70">
          {deliveredAt?.day}
        </Text.BodyText4>
        <Text.BodyText5 color="grey70">{deliveredAt?.time}</Text.BodyText5>
      </BodyCellContainer>
      <BodyCellContainer isErrored={false} isLoading={false}>
        <StatusBadge status={userCampaign.engagement} />
      </BodyCellContainer>
      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className="text-right"
      >
        <ReportLink campaign={campaign} campaignType={campaignType} />
      </BodyCellContainer>
    </tr>
  )
}

export default Row
