import {
  AnalyticsConfigApiFields,
  PreferencesModalConfigApiFields,
  ReceiptConfigApiFields,
  TiersConfigApiFields,
} from 'models/Config'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

export const GET_RECEIPT_CONFIG = 'config/GET_RECEIPT_CONFIG'
export const GET_RECEIPT_CONFIG_FAIL = 'config/GET_RECEIPT_CONFIG_FAIL'
export const GET_RECEIPT_CONFIG_SUCCESS = 'config/GET_RECEIPT_CONFIG_SUCCESS'

export const GET_TIERS_CONFIG = 'config/GET_TIERS_CONFIG'
export const GET_TIERS_CONFIG_FAIL = 'config/GET_TIERS_CONFIG_FAIL'
export const GET_TIERS_CONFIG_SUCCESS = 'config/GET_TIERS_CONFIG_SUCCESS'

export const GET_ANALYTICS_CONFIG = 'config/GET_ANALYTICS_CONFIG'
export const GET_ANALYTICS_CONFIG_SUCCESS =
  'config/GET_ANALYTICS_CONFIG_SUCCESS'
export const GET_ANALYTICS_CONFIG_FAIL = 'config/GET_ANALYTICS_CONFIG_FAIL'

export const UPDATE_ANALYTICS_CONFIG = 'config/UPDATE_ANALYTICS_CONFIG'
export const UPDATE_ANALYTICS_CONFIG_SUCCESS =
  'config/UPDATE_ANALYTICS_CONFIG_SUCCESS'
export const UPDATE_ANALYTICS_CONFIG_FAIL =
  'config/UPDATE_ANALYTICS_CONFIG_FAIL'

export const GET_PREFERENCES_MODAL_CONFIG =
  'config/GET_PREFERENCES_MODAL_CONFIG'
export const GET_PREFERENCES_MODAL_CONFIG_SUCCESS =
  'config/GET_PREFERENCES_MODAL_CONFIG_SUCCESS'
export const GET_PREFERENCES_MODAL_CONFIG_FAIL =
  'config/GET_PREFERENCES_MODAL_CONFIG_FAIL'

export const UPDATE_PREFERENCES_MODAL_CONFIG =
  'config/UPDATE_PREFERENCES_MODAL_CONFIG'
export const UPDATE_PREFERENCES_MODAL_CONFIG_SUCCESS =
  'config/UPDATE_PREFERENCES_MODAL_CONFIG_SUCCESS'
export const UPDATE_PREFERENCES_MODAL_CONFIG_FAIL =
  'config/UPDATE_PREFERENCES_MODAL_CONFIG_FAIL'

export type GetReceiptConfigResolvedAction =
  | FailureAction<typeof GET_RECEIPT_CONFIG_FAIL>
  | SuccessAction<
      typeof GET_RECEIPT_CONFIG_SUCCESS,
      { receipt_config: ReceiptConfigApiFields }
    >

export type GetTiersConfigResolvedAction =
  | FailureAction<typeof GET_TIERS_CONFIG_FAIL>
  | SuccessAction<
      typeof GET_TIERS_CONFIG_SUCCESS,
      { tiers_config: TiersConfigApiFields | null }
    >

export type GetAnalyticsResolvedAction =
  | FailureAction<typeof GET_ANALYTICS_CONFIG_FAIL>
  | SuccessAction<
      typeof GET_ANALYTICS_CONFIG_SUCCESS,
      { ordering_analytics: AnalyticsConfigApiFields }
    >

export type UpdateAnalyticsResolvedAction =
  | AxiosAction<typeof UPDATE_ANALYTICS_CONFIG>
  | FailureAction<typeof UPDATE_ANALYTICS_CONFIG_FAIL>
  | SuccessAction<
      typeof UPDATE_ANALYTICS_CONFIG_SUCCESS,
      { ordering_analytics: AnalyticsConfigApiFields }
    >

export type GetPreferencesModalResolvedAction =
  | FailureAction<typeof GET_PREFERENCES_MODAL_CONFIG_FAIL>
  | SuccessAction<
      typeof GET_PREFERENCES_MODAL_CONFIG_SUCCESS,
      { preferences_modal: PreferencesModalConfigApiFields }
    >

export type UpdatePreferencesModalResolvedAction =
  | AxiosAction<typeof UPDATE_PREFERENCES_MODAL_CONFIG>
  | FailureAction<typeof UPDATE_PREFERENCES_MODAL_CONFIG_FAIL>
  | SuccessAction<
      typeof UPDATE_PREFERENCES_MODAL_CONFIG_SUCCESS,
      { preferences_modal: PreferencesModalConfigApiFields }
    >

export function getReceiptConfig(): AxiosAction<
  typeof GET_RECEIPT_CONFIG,
  { receipt_config: ReceiptConfigApiFields }
> {
  return {
    type: GET_RECEIPT_CONFIG,
    payload: {
      client: 'default',
      request: {
        url: 'receipt_config',
        method: 'get',
      },
    },
  }
}

export function getTiersConfig(): AxiosAction<
  typeof GET_TIERS_CONFIG,
  { tiers_config: TiersConfigApiFields | null }
> {
  return {
    type: GET_TIERS_CONFIG,
    payload: {
      client: 'default',
      request: {
        url: 'tiers_config',
        method: 'get',
      },
    },
  }
}

export function getAnalytics(): AxiosAction<typeof GET_ANALYTICS_CONFIG> {
  return {
    type: GET_ANALYTICS_CONFIG,
    payload: {
      client: 'default',
      request: {
        url: 'ordering_analytics',
        method: 'get',
      },
    },
  }
}

export function updateAnalytics(
  analytics: object
): AxiosAction<typeof UPDATE_ANALYTICS_CONFIG> {
  return {
    type: UPDATE_ANALYTICS_CONFIG,
    payload: {
      client: 'default',
      request: {
        url: 'ordering_analytics',
        method: 'patch',
        data: {
          ordering_analytics: analytics,
        },
      },
    },
  }
}

export function getPreferencesModalSetting(): AxiosAction<
  typeof GET_PREFERENCES_MODAL_CONFIG
> {
  return {
    type: GET_PREFERENCES_MODAL_CONFIG,
    payload: {
      client: 'default',
      request: {
        url: 'preferences_modal',
        method: 'get',
      },
    },
  }
}

export function updatePreferencesModalSetting(
  setting: string
): AxiosAction<typeof UPDATE_PREFERENCES_MODAL_CONFIG> {
  return {
    type: UPDATE_PREFERENCES_MODAL_CONFIG,
    payload: {
      client: 'default',
      request: {
        url: 'preferences_modal',
        method: 'put',
        data: { preferences_modal: { setting } },
      },
    },
  }
}

export type ResolvedAction =
  | GetReceiptConfigResolvedAction
  | GetTiersConfigResolvedAction
  | GetAnalyticsResolvedAction
  | UpdateAnalyticsResolvedAction
  | GetPreferencesModalResolvedAction
  | UpdatePreferencesModalResolvedAction
