import { FormsyInput } from '@thanx/uikit/input'
import {
  getOrderingBanner,
  GET_ORDERING_BANNER_SUCCESS,
  updateOrderingBanner,
  UPDATE_ORDERING_BANNER_FAIL,
} from 'actions/banners'
import { alert } from 'actions/flash'
import { getValidation } from 'actions/validations'
import ConfirmModal from 'components/ConfirmModal'
import ImagePicker, { ImagePickerValue } from 'components/ImagePicker'
import Section from 'components/Section'
import Formsy from 'formsy-react'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { OrderingBanner } from 'models/Banner'
import { ValidationTypes } from 'models/Validation'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import CmsPage from 'scenes/Cms/components/CmsPage'
import PublishBanner from 'scenes/Cms/components/PublishBanner'
import { selectOrderingBannerValidation } from 'selectors/validation'
import NotEnabledPage from './NotEnabledPage'
import Sidebar from './Sidebar'

const t = buildTranslate('cms.content.web_hero_image')
const MAX_MESSAGE_LENGTH = 50

type FormModel = {
  bannerMessage: string
  image: ImagePickerValue
}

const WebHeroImage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formModel, setFormModel] = useState<FormModel>({
    bannerMessage: '',
    image: {
      id: null,
      url: '',
      uploading: false,
    },
  })
  const [imageDeleted, setImageDeleted] = useState(false)
  const dispatch = useDispatch()

  const merchant = useCurrentMerchant()
  const { web_ordering_enabled } = merchant || {}
  const imageValidations = useSelector(selectOrderingBannerValidation)
  const resetFormRef = useRef(resetForm)

  async function publishChanges() {
    setIsSubmitting(true)

    const imageId = formModel.image.id
    let fileUpload
    if (imageId) {
      fileUpload = {
        banner_image: {
          file_upload_id: imageId,
        },
      }
      setImageDeleted(false)
    } else if (imageDeleted) {
      fileUpload = {
        banner_image: {
          destroy: true,
        },
      }
    }

    const response = await dispatch(
      updateOrderingBanner({
        text: formModel.bannerMessage,
        file_uploads: fileUpload,
      })
    )

    setIsSubmitting(false)
    setIsModalOpen(false)

    if (response.type === UPDATE_ORDERING_BANNER_FAIL) {
      dispatch(
        alert({
          key: 'danger',
          message: t(imageDeleted ? 'delete_image_error' : 'submit_error'),
          timeout: 5,
        })
      )
      return
    }

    resetForm(response.payload.data.ordering_banner)

    dispatch(
      alert({
        key: 'success',
        message: t('submit_success'),
        timeout: 5,
      })
    )
  }

  function resetForm(orderingBanner: OrderingBanner) {
    const { text, image_urls } = orderingBanner
    const imgUrl = image_urls?.small && !imageDeleted ? image_urls?.small : ''
    setFormModel({
      bannerMessage: text || '',
      image: {
        id: null,
        url: imgUrl,
        uploading: false,
      },
    })
    setImageDeleted(false)
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const [response] = await Promise.all([
        dispatch(getOrderingBanner()),
        dispatch(getValidation({ type: ValidationTypes.OrderingBanner })),
      ])

      if (response.type === GET_ORDERING_BANNER_SUCCESS) {
        resetFormRef.current(response.payload.data.ordering_banner)
      }

      setIsLoading(false)
    }

    if (web_ordering_enabled) {
      fetchData()
    }
  }, [web_ordering_enabled, dispatch])

  if (!web_ordering_enabled) {
    return <NotEnabledPage />
  }

  const imageUploading = formModel.image.uploading

  return (
    <Formsy
      onValidSubmit={() => {
        setIsModalOpen(true)
      }}
      onChange={newFormModel => setFormModel(newFormModel)}
      onInvalidSubmit={() =>
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('cms.content.form_error'),
            timeout: 5,
          })
        )
      }
    >
      <CmsPage
        title={t('title')}
        description={t('description')}
        badges={[t('badges.banner_size'), t('badges.png')]}
        sidebar={<Sidebar isLoading={isSubmitting} disabled={imageUploading} />}
        isLoading={isLoading}
      >
        <Section className="p-m mt-l mb-m">
          {imageValidations && (
            <ImagePicker
              name="image"
              label={t('banner_image')}
              value={formModel.image}
              imageValidations={imageValidations}
              removable
              onRemoveComplete={() => setImageDeleted(true)}
            />
          )}
        </Section>
        <Section className="p-m mb-m">
          <FormsyInput
            // @ts-ignore
            name="bannerMessage"
            label={t('banner_message')}
            value={formModel.bannerMessage}
            validations={{
              maxLength: MAX_MESSAGE_LENGTH,
            }}
            validationErrors={{
              maxLength: I18n.t('validations.max_length', {
                maxLength: MAX_MESSAGE_LENGTH,
              }),
            }}
          />
          <p className="body-text-5 grey-70 mt-xs">{t('write_message')}</p>
        </Section>
        <PublishBanner
          buttonText={t('publish')}
          isLoading={isSubmitting}
          disabled={imageUploading}
        />
      </CmsPage>
      <ConfirmModal
        title={t('modal.publish_changes')}
        description={t('modal.you_are_publishing')}
        confirmText={t('modal.publish')}
        isOpen={isModalOpen}
        isLoading={isSubmitting}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => publishChanges()}
      />
    </Formsy>
  )
}

export default WebHeroImage
