import { Fields, ShortType } from 'models/RetentionRateMetric'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

export const GET_RETENTION_RATE_METRICS = 'retention_rate_metrics/GET_METRICS'
export const GET_RETENTION_RATE_METRICS_SUCCESS =
  'retention_rate_metrics/GET_METRICS_SUCCESS'
export const GET_RETENTION_RATE_METRICS_FAIL =
  'retention_rate_metrics/GET_METRICS_FAIL'

export type GetRetentionRateMetricsAction = AxiosAction<
  typeof GET_RETENTION_RATE_METRICS
>

export function getRetentionRateMetrics(
  type: ShortType,
  params: {} = {}
): GetRetentionRateMetricsAction {
  return {
    type: GET_RETENTION_RATE_METRICS,
    payload: {
      client: 'default',
      request: {
        url: `retention_rate/${type}`,
        method: 'get',
        params,
      },
    },
  }
}

export type ResolvedAction =
  | FailureAction<typeof GET_RETENTION_RATE_METRICS_FAIL>
  | SuccessAction<
      typeof GET_RETENTION_RATE_METRICS_SUCCESS,
      { metric: Fields },
      GetRetentionRateMetricsAction
    >
