import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('thanx_campaigns.report.v3.test')

type Props = {
  startDate: string
}
const Scheduled: React.FC<Props> = ({ startDate }) => {
  return (
    <Text.BodyText4 color="primary">
      {t('scheduled', { start: startDate })}
    </Text.BodyText4>
  )
}

export default Scheduled
