import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { getPointsExperiences } from 'actions/pointsExperiences'
import Info from 'components/Info'
import { buildTranslate } from 'locales'
import { CampaignConfig } from 'models/Campaign'
import React, { useEffect } from 'react'
import Markdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux'
import {
  formatCurrency,
  getCostToBusiness,
  getDiscount,
  getTemplateKeyForType,
} from 'scenes/RedeemManager/helpers'
import { selectExperience } from 'selectors/pointsExperience'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'

type PropsT = {
  config: CampaignConfig
}

const detailT = buildTranslate('redeem_manager.detail.cards.reward_details')
const typeT = buildTranslate('redeem_manager.types')

const General: React.FC<PropsT> = (props: PropsT) => {
  const { config } = props
  const dispatch = useDispatch()
  const pointsExperience = useSelector(selectExperience)
  const redeemTemplate = useSelector(state =>
    selectRedeemTemplate(state, config.redeem_template_id)
  )
  const typeKey = getTemplateKeyForType(
    config.redeem_type,
    config.redeem_subtype
  )

  useEffect(() => {
    dispatch(getPointsExperiences())
  }, [dispatch])

  const isFreeItem = config.redeem_type === 'manual/item'
  const isBonusPoints = config.redeem_type === 'points/static'
  const longDescription = (
    <Markdown className="grey-70">
      {config.redeem_long_description || '-'}
    </Markdown>
  )

  return (
    <>
      <Info
        className="mb-m"
        label={detailT('reward_type')}
        value={typeT(`${typeKey}.title`)}
      />
      <Switch condition={isBonusPoints}>
        <Info
          className="mb-m"
          label={'Number of points'}
          value={
            <>
              <img
                src={pointsExperience?.currency_primary_icon.default}
                height="16"
                alt="currency"
              />
              <Text.BodyText4 className="ml-xs">
                {redeemTemplate?.number_of_points}
              </Text.BodyText4>
            </>
          }
        />
      </Switch>
      <Switch condition={!!longDescription && !isBonusPoints}>
        <Info
          className="mb-m"
          label={detailT('long_description')}
          value={longDescription}
        />
      </Switch>

      <Info
        className="mb-m"
        label={detailT('discount')}
        value={getDiscount(config.redeem_type, config.redeem_discount)}
      />
      <Switch condition={isFreeItem}>
        <Info
          className="mb-m"
          label={detailT('cost_to_business')}
          value={getCostToBusiness(config.redeem_type, config.redeem_discount)}
        />
      </Switch>
      {config.redeem_retail_value > 0 ? (
        <Info
          label={detailT('retail_price')}
          value={formatCurrency(config.redeem_retail_value)}
        />
      ) : null}
    </>
  )
}

export default General
