import { Component } from 'react'
import { connect } from 'react-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import currentMerchant from 'utilities/currentMerchant'
import { FullStoryT, getFullStory } from 'utilities/fullStory'

import type { Fields as Merchant } from 'models/Merchant'
import type { Fields as MerchantUser } from 'models/MerchantUser'

type Props = {
  orgKey?: string
  debug?: boolean

  currentMerchantUser?: MerchantUser | null
  currentMerchant?: Merchant
  isThanxAdmin?: boolean
}

export class FullStory extends Component<Props> {
  componentDidMount = () => {
    if (this.props.isThanxAdmin) return
    if (!this.props.orgKey) return

    if (!getFullStory()) {
      this.runScript()
    }

    if (this.props.currentMerchantUser) {
      this.identifyUser(this.props.currentMerchantUser)
    }
    this.identifyMerchant(this.props.currentMerchant)
  }

  componentWillUnmount = () => {
    const fullStory = getFullStory()
    if (!fullStory) return
    // @ts-ignore
    fullStory.shutdown()
    // @ts-ignore
    delete getFullStory()
  }

  componentDidUpdate = (prevProps: Props) => {
    if (this.props.isThanxAdmin) return

    const prevUser = prevProps.currentMerchantUser
    const newUser = this.props.currentMerchantUser
    if (newUser && (!prevUser || prevUser.email !== newUser.email)) {
      this.identifyUser(newUser)
    }

    const merchantName = this.props.currentMerchant?.name
    const prevMerchantName = prevProps.currentMerchant?.name
    if (merchantName !== prevMerchantName) {
      this.identifyMerchant(this.props.currentMerchant)
    }
  }

  identifyUser = (user: MerchantUser) => {
    const fullStory = getFullStory() as FullStoryT
    if (!fullStory) return

    if (user.email) {
      fullStory.identify(user.email, {
        displayName: `${user.first_name || ''} ${user.last_name || ''}`,
        email: user.email,
      })
    } else {
      fullStory.identify(false)
    }
  }

  identifyMerchant = (merchant?: Merchant) => {
    if (!merchant?.name) return

    const fullStory = getFullStory() as FullStoryT
    if (!fullStory) return

    fullStory.setUserVars({
      merchant: merchant?.name,
    })
  }

  runScript = () => {
    // Copied from FullStory
    /* eslint-disable */
    window['_fs_debug'] = this.props.debug || false
    window['_fs_host'] = 'fullstory.com'
    window['_fs_script'] = 'edge.fullstory.com/s/fs.js'
    window['_fs_org'] = this.props.orgKey
    window['_fs_namespace'] = 'FS'
    ;(function (m, n, e, t, l, o, g, y) {
      if (e in m) {
        if (m.console && m.console.log) {
          m.console.log(
            'FullStory namespace conflict. Please set window["_fs_namespace"].'
          )
        }
        return
      }
      // @ts-ignore
      g = m[e] = function (a, b, s) {
        // @ts-ignore
        g.q ? g.q.push([a, b, s]) : g._api(a, b, s)
      }
      // @ts-ignore
      g.q = []
      // @ts-ignore
      o = n.createElement(t)
      // @ts-ignore
      o.async = 1
      // @ts-ignore
      o.crossOrigin = 'anonymous'
      // @ts-ignore
      o.src = 'https://' + _fs_script
      // @ts-ignore
      y = n.getElementsByTagName(t)[0]
      // @ts-ignore
      y.parentNode.insertBefore(o, y)
      // @ts-ignore
      g.identify = function (i, v, s) {
        // @ts-ignore
        g(l, { uid: i }, s)
        // @ts-ignore
        if (v) g(l, v, s)
      }
      // @ts-ignore
      g.setUserVars = function (v, s) {
        // @ts-ignore
        g(l, v, s)
      }
      // @ts-ignore
      g.event = function (i, v, s) {
        // @ts-ignore
        g('event', { n: i, p: v }, s)
      }
      // @ts-ignore
      g.shutdown = function () {
        // @ts-ignore
        g('rec', !1)
      }
      // @ts-ignore
      g.restart = function () {
        // @ts-ignore
        g('rec', !0)
      }
      // @ts-ignore
      g.log = function (a, b) {
        // @ts-ignore
        g('log', [a, b])
      }
      // @ts-ignore
      g.consent = function (a) {
        // @ts-ignore
        g('consent', !arguments.length || a)
      }
      // @ts-ignore
      g.identifyAccount = function (i, v) {
        // @ts-ignore
        o = 'account'
        v = v || {}
        v.acctId = i
        // @ts-ignore
        g(o, v)
      }
      // @ts-ignore
      g.clearUserCookie = function () {}
      // @ts-ignore
      g._w = {}
      // @ts-ignore
      y = 'XMLHttpRequest'
      // @ts-ignore
      g._w[y] = m[y]
      // @ts-ignore
      y = 'fetch'
      // @ts-ignore
      g._w[y] = m[y]
      // @ts-ignore
      if (m[y])
        // @ts-ignore
        m[y] = function () {
          // @ts-ignore
          return g._w[y].apply(this, arguments)
        }
    })(window, document, window['_fs_namespace'], 'script', 'user')
    /* eslint-enable */
  }

  render() {
    return false
  }
}

const mapStateToProps = state => ({
  currentMerchantUser: selectCurrentMerchantUser(state),
  currentMerchant: currentMerchant(state),
  isThanxAdmin: state.auth.is_thanx_admin,
})

export default connect(mapStateToProps, null)(FullStory)
