import { createCampaign, getCampaigns } from 'actions/campaigns'
import { getCampaignTypes } from 'actions/campaignTypes'
import { getTargetSegments } from 'actions/targetSegments'
import bannerImage from 'assets/images/campaign-center/landing/targeted/banner_image.png'
import ABOneTime from 'components/banners/ABOneTime'
import Loading from 'components/Loading'
import PromoBanner from 'components/PromoBanner'
import Spacer from 'components/Spacer'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as TargetSegment } from 'models/TargetSegment'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Container from 'react-bootstrap-five/Container'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Can } from 'utilities/ability'
import CustomCampaignsModal from '../CustomCampaignsModal'
import HeroBanner from '../HeroBanner'
import CustomCampaigns from './CustomCampaigns'
import ExistingCampaignsTable from './ExistingCampaignsTable'
import styles from './Targeted.module.scss'

type Props = RouteComponentProps

const t = buildTranslate('thanx_campaigns.landing.targeted')

const Targeted: React.FC<Props> = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [customModalText, setCustomModalText] = useState<string | undefined>(
    undefined
  )
  const [isCustomModalOpen, setIsCustomModalOpen] = useState(false)

  const dispatch = useDispatch()
  const merchant = useCurrentMerchant()

  async function handleCreate(targetSegment?: TargetSegment) {
    const params = {
      campaign: {
        type: 'custom',
        target_segment_id: targetSegment?.id,
      },
    }
    const action = await dispatch(createCampaign(params))
    if (action.error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('custom.modal.errors.submit'),
        })
      )
      return
    }

    const campaignId = action.payload?.data?.campaign?.id
    if (campaignId) {
      dispatch(push(`/thanx_campaigns/targeted/custom/${campaignId}`))
    } else {
      dispatch(
        alert({
          key: 'danger',
          message: t('custom.modal.errors.submit'),
        })
      )
    }
    return
  }

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        dispatch(getCampaignTypes({ category: 'targeted' })),
        dispatch(getCampaigns({ category: 'targeted' })),
      ])
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  // This doesn't need to block page rendering
  useEffect(() => {
    if (!merchant) return
    dispatch(getTargetSegments())
  }, [dispatch, merchant])

  // Scroll the correct element into view if the hash changes.
  useEffect(() => {
    const hash = location.hash.replace('#', '')
    if (hash && !isLoading) {
      const element = document.getElementById(hash)
      if (element) {
        element.scrollIntoView()
      }
    }
  }, [location.hash, isLoading])

  const contactButton = () => {
    if (merchant && merchant.custom_campaigns_enabled) {
      return (
        <Button
          bsStyle="primary"
          className="btn-huge padding-left-large padding-right-large"
          onClick={() => setIsCustomModalOpen(true)}
        >
          {t('footer.button_text')}
        </Button>
      )
    } else {
      return (
        <Button
          bsStyle="primary"
          className="btn-huge padding-left-large padding-right-large"
          href="mailto:merchant.support@thanx.com"
        >
          {t('footer.button_text')}
        </Button>
      )
    }
  }

  return (
    <div className={styles.targetedLanding}>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <CustomCampaignsModal
        title={t('custom.modal.title')}
        defaultText={customModalText}
        show={isCustomModalOpen}
        onCreate={handleCreate}
        onModalClose={() => {
          setCustomModalText(undefined)
          setIsCustomModalOpen(false)
        }}
      />
      <HeroBanner
        title={t('title')}
        description={t('description')}
        image={bannerImage}
        alt={t('title')}
        className="wisteria-bkg"
      />
      <Container>
        <PromoBanner storageKey="abPromoOneTimeTargeted">
          <ABOneTime className="mt-xxl" />
        </PromoBanner>
      </Container>
      <ExistingCampaignsTable className="py-xxl" />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {merchant && merchant.custom_campaigns_enabled && (
            <CustomCampaigns onCreate={handleCreate} />
          )}
        </>
      )}

      <Can I="manage" a="TargetedCampaign::Custom">
        <div className="relative grey-05-bkg pt-xxl">
          <div className={styles.footerBackground} />
          <div className="container relative spearmint-10-bkg py-xxl">
            <p className="center-block text-center body-text-1 width-50 bold full-width-on-xs line-height-40">
              {t('footer.message')}
            </p>
            <Spacer paddingTop="30px" />
            <div className="display-flex flex-space-around">
              {contactButton()}
            </div>
          </div>
        </div>
      </Can>
    </div>
  )
}

export default withRouter(Targeted)
