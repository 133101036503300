import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import CogIcon from 'components/Icons/Cog'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { useStyletron } from 'styletron-react'
import { merchantTheme } from 'theme'
import AttributionModal from './AttributionModal'
import { useAttributionWindow } from './AttributionWindowContext'

const t = buildTranslate('thanx_campaigns.report.v3.attribution')

const AttributionSelector: React.FC = () => {
  const { attributionWindow } = useAttributionWindow()
  const [isAttributionModalOpen, setIsAttributionModalOpen] =
    useState<boolean>(false)

  const [css] = useStyletron()

  return (
    <>
      {/* Compensate for <Button>'s top and bottom margins. */}
      <div className={css({ margin: '-8px 0px' })}>
        <Text.BodyText5 bold color="grey90">
          {t('attribution')}
        </Text.BodyText5>
        <Button
          kind={Kind.MINIMAL}
          className={`p-xs border-0 d-inline ${css({
            color: merchantTheme.colors.belizeHole50,
          })}`}
          onClick={() => setIsAttributionModalOpen(true)}
        >
          <Text.BodyText5 color="inherit" className="mr-xs">
            {t('days', { count: attributionWindow })}
          </Text.BodyText5>
          <CogIcon />
        </Button>
      </div>
      <AttributionModal
        isOpen={isAttributionModalOpen}
        onClose={() => setIsAttributionModalOpen(false)}
      />
    </>
  )
}

export default AttributionSelector
