import { Text } from '@thanx/uikit/text'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('legal.common.legal_container')

type Props = {
  children: React.ReactNode
  isLoading?: boolean
  copyrigthSuffix?: string
}

function LegalContainer({
  children,
  isLoading = false,
  copyrigthSuffix = '',
}: Props) {
  return (
    <div className="p-xl pt-xl">
      {isLoading && (
        <Spinner className="mt-xl text-center" isLoading size="2x" />
      )}
      {!isLoading && children}
      <br />
      <Text.BodyText3 tag="p">
        {`© ${t('copyright', { year: new Date().getFullYear() })}`}
        {copyrigthSuffix}
      </Text.BodyText3>
    </div>
  )
}

export default LegalContainer
