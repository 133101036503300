import { GetAllResolvedAction, getCouponPools } from 'actions/couponPools'
import { getAllFileUploads, SourcePath } from 'actions/fileUpload'
import { alert } from 'actions/flash'
import { getRedeemTemplate } from 'actions/redeemTemplates'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { CouponPoolGenerationType } from 'models/CouponPool'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import { selectCouponPoolsByTemplateId } from 'selectors/couponPool'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import Builder from './Builder'

type Props = RouteComponentProps<{ id: string }>

const t = buildTranslate('redeem_manager.builder.edit')

const Edit: React.FC<Props> = props => {
  const { id } = props.match.params
  const templateId = Number(id)
  const template = useSelector(store => selectRedeemTemplate(store, templateId))
  const couponPools = useSelector(state =>
    selectCouponPoolsByTemplateId(state, templateId)
  )

  const [isLoading, setIsLoading] = useState<boolean>(!template)
  const [rendered, setRendered] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchCouponPools() {
      setIsLoading(true)
      const result: GetAllResolvedAction = (await dispatch(
        getCouponPools(templateId)
      )) as any

      if (result.type === 'couponPool/GET_ALL_SUCCESS') {
        const { coupon_pools } = result.payload.data
        if (coupon_pools.length > 0) {
          const lastPool = coupon_pools[coupon_pools.length - 1]

          if (lastPool.generation_type !== CouponPoolGenerationType.STATIC) {
            dispatch(getAllFileUploads(SourcePath.COUPON_POOLS, lastPool.id))
          }
        }
      }

      setIsLoading(false)
    }

    async function fetchTemplate() {
      setIsLoading(true)
      await dispatch(getRedeemTemplate(templateId))
      setIsLoading(false)
    }

    if (!template) {
      fetchTemplate()
    }

    if (!rendered) {
      fetchCouponPools()
      setRendered(true)
    }
  }, [template, templateId, rendered, dispatch])

  if (isLoading) {
    return (
      <Spinner isLoading size="4x" className="text-center margin-top-huge" />
    )
  }
  if (!template) return null

  return (
    <Builder
      template={template}
      couponPools={couponPools}
      onSuccessExit={() => {
        dispatch(push(`/rewards/${template.id}`))
        dispatch(
          alert({
            key: 'success',
            message: t('success', {
              name: template.name,
            }),
            timeout: 5,
          })
        )
      }}
    />
  )
}

export default Edit
