import { Switch } from '@thanx/uikit/switch'
import React from 'react'

type Props = {
  children?: React.ReactNode
  /** Whether to render the loading spinner. */
  isLoading?: boolean
  /** Custom styles for the spinner. */
  style?: React.CSSProperties
  /** Custom class name for the spinner. */
  className?: string
  /** Custom icon class for the spinner. */
  icon?: string
  /** Size to use for the spinner (i.e. size="2x"). */
  size?: string
}

const Spinner = (props: Props) => {
  const {
    children,
    isLoading = false,
    style,
    className = '',
    icon = 'fa-circle-o-notch',
    size = '1x',
  } = props

  return (
    <Switch condition={isLoading} fallback={children || null}>
      <div className={className} style={style} data-testid="Loading">
        <span className={'fa fa-spin ' + icon + ' fa-' + size} />
      </div>
    </Switch>
  )
}
export default Spinner
