import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const CREATE_UPLOAD_URL = 's3/CREATE_UPLOAD_URL'
export const CREATE_UPLOAD_URL_SUCCESS = 's3/CREATE_UPLOAD_URL_SUCCESS'
export const CREATE_UPLOAD_URL_FAIL = 's3/CREATE_UPLOAD_URL_FAIL'

type UploadParams = {
  filename: string
  type: string
  acl?: string
  expiresIn?: number
}

export function getUploadUrl(
  data: UploadParams
): AxiosAction<typeof CREATE_UPLOAD_URL, { upload_url: { url: string } }> {
  return {
    type: CREATE_UPLOAD_URL,
    payload: {
      client: 'default',
      request: {
        url: '/upload_url',
        method: 'post',
        data: data,
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getUploadUrl>
>
