import { Fields as Card } from 'models/Card'
import { PaginationT } from 'models/Pagination'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_CARDS = 'cards/GET_CARDS'
export const GET_CARDS_SUCCESS = 'cards/GET_CARDS_SUCCESS'
export const GET_CARDS_FAIL = 'cards/GET_CARDS_FAIL'

export type GetCardsAction = AxiosAction<
  typeof GET_CARDS,
  {
    cards: Card[]
    pagination: PaginationT
  }
>

export function getCards(
  userIdentifier: string,
  params?: { page: number }
): GetCardsAction {
  return {
    type: GET_CARDS,
    payload: {
      client: 'default',
      request: {
        url: `users/${userIdentifier}/cards`,
        method: 'get',
        params,
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<ReturnType<typeof getCards>>
