import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import numeral from 'numeral'
import pluralize from 'pluralize'
import React, { useState } from 'react'
import CategoryListModal from './CategoryListModal'
import LocationListModal from './LocationListModal'

type Props = {
  className?: string
  isLoading?: boolean
  onEdit?: () => void
  locationIds: number[]
  categoryIds: number[]
}

const translate = buildTranslate('thanx_campaigns.review.customers')

const LocationFilter: React.FC<Props> = props => {
  const {
    className = '',
    isLoading = false,
    onEdit,
    locationIds,
    categoryIds,
  } = props

  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false)

  const locationsButton = (
    <span className="a bold" onClick={() => setIsLocationModalOpen(true)}>
      <Spinner isLoading={isLoading} className="inline-block">
        {numeral(locationIds.length).format('0,0')}
      </Spinner>
      &nbsp;
      {pluralize(translate('locationModal.unit'), locationIds.length)}
    </span>
  )

  const categoriesButton = (
    <span className="a bold" onClick={() => setIsCategoryModalOpen(true)}>
      <Spinner isLoading={isLoading} className="inline-block">
        {numeral(categoryIds.length).format('0,0')}
      </Spinner>
      &nbsp;
      {pluralize(translate('categoryModal.unit'), categoryIds.length)}
    </span>
  )

  const text = (function () {
    if (locationIds.length && categoryIds.length) {
      return (
        <>
          {translate('text.location_count')}
          &nbsp;
          {locationsButton}
          &nbsp;
          {translate('text.location_and_category_count')}
          &nbsp;
          {categoriesButton}
        </>
      )
    }
    if (locationIds.length) {
      return (
        <>
          {translate('text.location_count')}
          &nbsp;
          {locationsButton}
        </>
      )
    }
    if (categoryIds.length) {
      return (
        <>
          {translate('text.category_count')}
          &nbsp;
          {categoriesButton}
        </>
      )
    }
    return null
  })()

  return (
    <>
      <div className={`${className} font-size-16 grey-70`}>{text}</div>
      <LocationListModal
        isOpen={isLocationModalOpen}
        onClose={() => setIsLocationModalOpen(false)}
        onEdit={onEdit}
        locationIds={locationIds}
      />
      <CategoryListModal
        isOpen={isCategoryModalOpen}
        onClose={() => setIsCategoryModalOpen(false)}
        onEdit={onEdit}
        categoryIds={categoryIds}
      />
    </>
  )
}

export default LocationFilter
