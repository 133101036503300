import FetchableMetricSection from 'components/FetchableMetricSection'
import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../Header'
import { useUploadState } from '../UploadStateContext'
import Chart from './Chart'
import Table from './Table'

type PropsT = {
  merchant: Merchant
}

const t = buildTranslate('capture_rate.revenue_by_channel')

const RevenueByChannel: React.FC<PropsT> = ({ merchant }) => {
  const { onFileProcessed } = useUploadState()
  const { currentLocation } = useContext(LocationContext)

  const [reFetchAt, setReFetchAt] = useState<number | null>(null)

  useEffect(() => {
    onFileProcessed(() => setReFetchAt(new Date().getTime()))
  }, [onFileProcessed])

  useEffect(() => {
    setReFetchAt(new Date().getTime())
  }, [currentLocation?.id])

  return (
    <FetchableMetricSection
      type="capture_rate_time_series_metric"
      merchantId={merchant?.id}
      locationId={currentLocation?.id}
      shouldFetch
      reFetchAt={reFetchAt}
      filterByChannel
    >
      <Header className="mb-m" title={t('title')} />
      <Chart merchant={merchant} />
      <Table merchant={merchant} />
    </FetchableMetricSection>
  )
}

export default RevenueByChannel
