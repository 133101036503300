import Counter from 'components/Counter'
import { DateRangeFilterContext } from 'components/DateFilter/DateFilterContext'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import {
  ActivityCountersMetric,
  ShortType,
} from 'models/PointsExperienceMetric'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectPointsExperienceMetricByType } from 'selectors/pointsExperienceMetric'
import { numberFormatter } from 'utilities/formatters'

const t = buildTranslate('points.activity.summary_counters')

const SummaryCounters: React.FC = () => {
  const showExpiredPoints = useFlag('show-points-expired-report', false)
  const { filter } = useContext(DateRangeFilterContext)
  const { range } = filter
  const activityCountersState = useSelector(state =>
    selectPointsExperienceMetricByType(
      state,
      range === 'all_time'
        ? ShortType.ACTIVITY_COUNTERS_ALL_TIME
        : ShortType.ACTIVITY_COUNTERS_CUSTOM
    )
  ) as ActivityCountersMetric

  if (!activityCountersState) {
    return null
  }

  const { isLoading, isErrored, data } = activityCountersState

  const counterValue = (value: number | undefined): string => {
    return numberFormatter(Math.round(value ?? 0))
  }

  return (
    <div className="d-flex">
      <Counter
        className="mr-xxl"
        primaryValue={counterValue(data?.total_issued_amount)}
        name={t('issued')}
        isErrored={isErrored}
        isLoading={isLoading}
        sections={[
          { content: t('issued_popover') },
          { title: t('example'), content: t('issued_example') },
        ]}
      />

      <Counter
        className="mr-xxl"
        primaryValue={counterValue(data?.total_redeemed_amount)}
        name={t('redeemed')}
        isErrored={isErrored}
        isLoading={isLoading}
        sections={[
          { content: t('redeemed_popover') },
          { title: t('example'), content: t('redeemed_example') },
        ]}
      />

      {showExpiredPoints && (
        <Counter
          className="mr-xxl"
          primaryValue={counterValue(data?.total_expired_amount)}
          name={t('expired')}
          isErrored={isErrored}
          isLoading={isLoading}
          sections={[
            { content: t('expired_popover') },
            { title: t('example'), content: t('expired_example') },
          ]}
        />
      )}
    </div>
  )
}

export default SummaryCounters
