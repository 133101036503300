export const errorHandler = {
  capture: function (errorMsg, context) {
    if (process.env.NODE_ENV === 'production') {
      //@ts-ignore
      window.Raven.captureMessage(errorMsg, {
        extra: context,
      })
      /* eslint-disable no-console */
    } else if (window.console && console.error) {
      console.error(errorMsg, context)
      /* eslint-enable no-console */
    }

    return null
  },

  setUserContext: function (userId) {
    //@ts-ignore
    window.Raven.setUserContext({
      id: userId,
    })
  },

  deleteUserContext: function () {
    //@ts-ignore
    window.Raven.setUserContext()
  },
}
