import { FormsyInput } from '@thanx/uikit/input'
import { FormsySelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { getCouponPools } from 'actions/couponPools'
import { getMerchantCouponPools } from 'actions/merchantCouponPools'
import TooltipLabel from 'components/TooltipLabel'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import {
  CouponPoolGenerationType,
  CouponPoolType,
  Fields as CouponPoolFields,
} from 'models/CouponPool'
import { Fields as Template } from 'models/RedeemTemplate'
import { RedemptionProvider } from 'models/RedemptionProvider'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useFormModel } from 'scenes/RedeemManager/Builder/FormModel'
import { selectMerchantCouponPools } from 'selectors/couponPool'
import CouponPoolSelect from './CouponPoolSelect'
import PoolUploadForm from './PoolUploadForm'
import ThanxGeneratedDisabled from './ThanxGeneratedDisabled'

type Props = {
  className?: string
  selectedCouponPools: CouponPoolFields[]
  template?: Template | null
  redemptionProvider?: RedemptionProvider
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes'
)

const CouponCodesForm: React.FC<Props> = props => {
  const {
    className = '',
    selectedCouponPools,
    template,
    redemptionProvider,
  } = props

  const dispatch = useDispatch()
  const formContext = useFormModel()
  const formModel = formContext.model
  const { posFields } = formModel

  const { generationType, type, staticCode } = redemptionProvider
    ? posFields[redemptionProvider.value].couponCodes
    : formModel.couponCodes

  const merchantCouponPools = useSelector(state =>
    selectMerchantCouponPools(state)
  )
  const couponPoolOptions = redemptionProvider
    ? merchantCouponPools.filter(
        pool => pool.redemption_provider?.value === redemptionProvider.value
      )
    : merchantCouponPools

  useEffect(() => {
    dispatch(getMerchantCouponPools(generationType))
    if (template?.id) {
      dispatch(getCouponPools(template.id))
    }
  }, [dispatch, generationType, template?.id, redemptionProvider])

  const generationTypeOptions = Object.values(CouponPoolGenerationType).map(
    type => ({
      label: t(`type.${type}`),
      value: type,
    })
  )
  const typeOptions = Object.values(CouponPoolType).map(format => ({
    label: t(`format.${format}`),
    value: format,
  }))

  const isStatic = generationType === CouponPoolGenerationType.STATIC
  const isVariable = generationType === CouponPoolGenerationType.VARIABLE

  const tooltipSections = [
    {
      title: t('type_tooltip.pool_title'),
      content: t('type_tooltip.pool_content'),
    },
    {
      title: t('type_tooltip.reusable_title'),
      content: t('type_tooltip.reusable_content'),
    },
    {
      title: t('type_tooltip.unique_title'),
      content: t('type_tooltip.unique_content_1'),
    },
    {
      title: '',
      content: t('type_tooltip.unique_content_2'),
    },
  ]

  return (
    <div className={`w-100 pl-l mt-s mb-m ${className}`}>
      <FormsySelect
        // @ts-ignore
        label={
          <TooltipLabel label={t('type_label')} sections={tooltipSections} />
        }
        name="couponCodes.generationType"
        value={generationType}
        options={generationTypeOptions}
      />
      <Switch condition={isStatic || isVariable}>
        <Switch condition={isStatic}>
          <FormsySelect
            // @ts-ignore
            className="mt-m"
            label={
              <TooltipLabel
                label={t('format_label')}
                tooltip={t('format_tooltip')}
              />
            }
            name="couponCodes.type"
            value={type}
            options={typeOptions}
          />
        </Switch>
        <Switch
          condition={
            !isEmpty(couponPoolOptions) &&
            generationType === CouponPoolGenerationType.VARIABLE
          }
        >
          <CouponPoolSelect
            name="selectedCouponPools"
            options={couponPoolOptions.filter(
              cp => cp.generation_type === generationType
            )}
            value={selectedCouponPools}
            isVariable={isVariable}
          />
        </Switch>
      </Switch>

      <Switch condition={isStatic}>
        <FormsyInput
          // @ts-ignore
          className="mt-m"
          label={
            <TooltipLabel
              label={t('static_code_label')}
              tooltip={t('static_code_tooltip')}
            />
          }
          name="couponCodes.staticCode"
          value={staticCode}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
          }}
        />
      </Switch>
      <Switch condition={isVariable}>
        <PoolUploadForm
          className="mt-m"
          template={template}
          formContext={formContext}
          redemptionProvider={redemptionProvider}
        />
      </Switch>
      <Switch condition={generationType === CouponPoolGenerationType.DYNAMIC}>
        <ThanxGeneratedDisabled />
      </Switch>
    </div>
  )
}

export default CouponCodesForm
