import { useStyletron } from '@thanx/uikit/theme'
import { getDateFilter } from 'components/DateFilter/utilities'
import { LocationContext } from 'contexts/LocationContext'
import { Fields as Merchant } from 'models/Merchant'
import moment from 'moment'
import React, { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectChannelTimeSeriesMetric } from 'selectors/captureRateMetric'
import { merchantTheme } from 'theme'
import { useUserTimeZone } from 'utilities/userTimeZone'
import ColumnChart from './ColumnChart'

type PropsT = {
  merchant: Merchant
}

const Chart: React.FC<PropsT> = ({ merchant }) => {
  const { currentLocation } = useContext(LocationContext)

  const metrics = useSelector(state =>
    selectChannelTimeSeriesMetric(state, merchant.id, currentLocation?.id)
  )

  const [css] = useStyletron()
  const userTimeZone = useUserTimeZone()
  const timezone = metrics?.time_zone || userTimeZone
  const filter = useMemo(() => {
    return getDateFilter(
      'all_time',
      timezone,
      moment(merchant.live_at).format('YYYY-MM-DD'),
      moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    )
  }, [merchant, timezone])

  return (
    <div
      className={`mb-xxl py-m pr-m pb-xxs pl-xxs ${css({
        backgroundColor: merchantTheme.colors.white,
        border: '1px solid ' + merchantTheme.colors.grey20,
        borderRadius: '4px',
      })}`}
    >
      <ColumnChart
        metrics={metrics}
        timezone={timezone}
        dateRangeFilter={filter}
      />
    </div>
  )
}

export default Chart
