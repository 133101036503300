import { Button } from '@thanx/uikit/button'
import { buildTranslate } from 'locales'
import React, { useRef } from 'react'

type Props = {
  onChoose?: (file: File) => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes.upload'
)

const ChooseFile: React.FC<Props> = props => {
  const { onChoose } = props

  const fileInputRef = useRef<HTMLInputElement>(null)

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length !== 1) return
    onChoose?.(event.target.files[0])
  }

  return (
    <div className="d-flex justify-content-end">
      <input
        data-testid="upload"
        className="hidden"
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Button onClick={() => fileInputRef.current?.click()}>
        {t('upload_button')}
      </Button>
    </div>
  )
}

export default ChooseFile
