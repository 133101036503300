import HelpLink from 'components/HelpLink'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { LifecycleMetric, ShortType } from 'models/MerchantMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectMerchantMetricByType } from 'selectors/merchantMetric'
import { lifecycleChartHelpUrl } from 'utilities/urlUtils'
import ColumnChart from './ColumnChart'

const t = buildTranslate('lifecycle_report.overview.chart')

const Chart: React.FC = () => {
  const memberLifecycleState = useSelector(state =>
    selectMerchantMetricByType(state, ShortType.LIFECYCLE)
  ) as LifecycleMetric
  const merchant = useCurrentMerchant()
  if (!memberLifecycleState) {
    return null
  }
  return (
    <>
      <ColumnChart
        isLoading={!!memberLifecycleState.isLoading}
        isErrored={!!memberLifecycleState.isErrored}
        data={memberLifecycleState.data}
        merchantType={merchant?.merchant_type}
      />
      <div className="d-flex justify-content-end">
        <HelpLink className="pr-0 mt-xs" url={lifecycleChartHelpUrl}>
          {t('help_link')}
        </HelpLink>
      </div>
    </>
  )
}

export default Chart
