import { Text } from '@thanx/uikit/text'
import ColorPicker from 'components/Form/ColorPicker'
import Section from 'components/Section'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { FormModel } from './hooks'

const t = buildTranslate('cms.content.menu_category.page_form')

type Props = {
  isLoading: boolean
  data: FormModel
}

const styleKeys = [
  'background_color',
  'header_text_color',
  'body_text_color',
  'link_color',
]

const PageForm = ({ isLoading, data }: Props) => {
  return (
    <Section className="p-m my-m">
      <Spinner
        isLoading={isLoading}
        size="3x"
        className={'d-flex justify-content-center'}
      >
        <Text.Header4>{t('title')}</Text.Header4>
        {styleKeys.map(key => (
          <ColorPicker
            className={'mt-m'}
            key={key}
            name={key}
            label={t(key)}
            validations={{
              hex: true,
              isRequired: true,
            }}
            value={data?.[key]}
            validationErrors={{
              hex: I18n.t('validations.hex.message'),
              isRequired: I18n.t('validations.is_required'),
            }}
            startEnhancer
          />
        ))}
      </Spinner>
    </Section>
  )
}

export default PageForm
