export default {
  filter: {
    last_30: 'Last 30 days',
    last_90: 'Last 90 days',
    last_365: 'Past Year',
    all_time: 'All time',
    custom: 'Custom date range',
  },
  custom_filter: {
    cancel: 'Cancel',
    apply: 'Apply',
  },
}
