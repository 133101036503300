import birthdayIcon from 'assets/images/campaign-center/landing/automated/icons/birthday.png'
import bouncebackIcon from 'assets/images/campaign-center/landing/automated/icons/bounceback.png'
import referralsIcon from 'assets/images/campaign-center/landing/automated/icons/referrals.png'
import reputationIcon from 'assets/images/campaign-center/landing/automated/icons/reputation.png'
import tiersIcon from 'assets/images/campaign-center/landing/automated/icons/tiers.png'
import vipIcon from 'assets/images/campaign-center/landing/automated/icons/vip.png'
import winbackIcon from 'assets/images/campaign-center/landing/automated/icons/winback.png'
import React from 'react'
import { Translate } from 'react-redux-i18n'

type Props = {
  campaignType: string
}
const Header: React.FC<Props> = ({ campaignType }) => {
  let icon
  switch (campaignType) {
    case 'reputation':
      icon = reputationIcon
      break
    case 'winback':
      icon = winbackIcon
      break
    case 'bounceback':
      icon = bouncebackIcon
      break
    case 'birthday':
      icon = birthdayIcon
      break
    case 'tiers':
      icon = tiersIcon
      break
    case 'vip':
      icon = vipIcon
      break
    case 'referrals':
      icon = referralsIcon
      break
    default:
      break
  }

  return (
    <div className="text-center margin-top-huge margin-bottom-huge padding-large">
      <img
        src={icon}
        style={{ height: '100px', width: 'auto' }}
        className="margin-bottom-large"
        alt={campaignType}
      />
      <Translate
        tag="h1"
        className="margin-bottom-small"
        value={`thanx_campaigns.opportunity.${campaignType}.title`}
      />
      <hr className="pipe spearmint-50-bkg inline-block" />
      <Translate
        tag="h6"
        className="grey-60"
        value={`thanx_campaigns.opportunity.${campaignType}.subtitle`}
        dangerousHTML
      />
    </div>
  )
}

export default Header
