import React from 'react'
import Nav from 'react-bootstrap-five/Nav'
import Tab from 'react-bootstrap-five/Tab'
import CampaignTab from './CampaignTab'
import CampaignTabPane from './CampaignTabPane'
import { Tab as TabT, TabsStateContext, TabsStateProvider } from './TabsState'

import { Fields as Campaign } from 'models/Campaign'

type Props = {
  reviewCampaigns: Campaign[]
  scheduledCampaigns: Campaign[]
  activeCampaigns: Campaign[]
  draftCampaigns: Campaign[]
  completeCampaigns: Campaign[]
  tabLoading: {
    review: boolean
    scheduled: boolean
    active: boolean
    draft: boolean
    complete: boolean
  }
}

const CampaignTabs: React.FC<Props> = props => {
  const {
    reviewCampaigns,
    scheduledCampaigns,
    activeCampaigns,
    draftCampaigns,
    completeCampaigns,
    tabLoading,
  } = props

  const reviewCampaignsCount = reviewCampaigns.length
  const scheduledCampaignsCount = scheduledCampaigns.length
  const activeCampaignsCount = activeCampaigns.length
  const draftCampaignsCount = draftCampaigns.length
  const completeCampaignsCount = completeCampaigns.length

  const initialTab: TabT = 'active'

  return (
    <TabsStateProvider initialValue={initialTab}>
      <TabsStateContext.Consumer>
        {value => {
          if (!value) return null
          const { activeTab, setActiveTab } = value

          return (
            <Tab.Container
              activeKey={activeTab}
              onSelect={(tab: TabT | null) => {
                if (!tab) return
                setActiveTab(tab)
              }}
              transition={false}
            >
              <Nav className="nav-tabs">
                <CampaignTab
                  state={'in_review'}
                  count={reviewCampaignsCount}
                  displayCount={true}
                />
                <CampaignTab
                  state={'scheduled'}
                  count={scheduledCampaignsCount}
                />
                <CampaignTab state={'active'} count={activeCampaignsCount} />
                <CampaignTab state={'draft'} count={draftCampaignsCount} />
                <CampaignTab
                  state={'complete'}
                  count={completeCampaignsCount}
                />
              </Nav>
              <Tab.Content>
                <CampaignTabPane
                  state="in_review"
                  campaigns={reviewCampaigns}
                  isLoading={tabLoading.review}
                />
                <CampaignTabPane
                  state="scheduled"
                  campaigns={scheduledCampaigns}
                  isLoading={tabLoading.scheduled}
                />
                <CampaignTabPane
                  state="active"
                  campaigns={activeCampaigns}
                  isLoading={tabLoading.active}
                />
                <CampaignTabPane
                  state="draft"
                  campaigns={draftCampaigns}
                  isLoading={tabLoading.draft}
                />
                <CampaignTabPane
                  state="complete"
                  campaigns={completeCampaigns}
                  isLoading={tabLoading.complete}
                />
              </Tab.Content>
            </Tab.Container>
          )
        }}
      </TabsStateContext.Consumer>
    </TabsStateProvider>
  )
}

export default CampaignTabs
