import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import AudienceIcon from 'assets/images/campaign-center/builder/img_audience.svg'
import ReviewSection from 'components/review/ReviewSection'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'

type Props = {
  text: string
  infoText?: string
}

export const DisabledContent: React.FC<Props> = props => {
  const { text, infoText } = props

  return (
    <Text.BodyText4 tag="div" className="mb-xs mt-xxs">
      {text}
      <Switch condition={!!infoText}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="audience-disabled-tooltip">{infoText}</Tooltip>}
        >
          <i className="fa fa-question-circle padding-left-small" />
        </OverlayTrigger>
      </Switch>
    </Text.BodyText4>
  )
}

const AudienceDisabled: React.FC<Props> = props => {
  const { text, infoText } = props

  return (
    <ReviewSection icon={AudienceIcon} isEditable={false}>
      <div className="margin-bottom-large">
        <Text.Header4 tag="div" className="mb-xs mt-xxs">
          <Translate value="thanx_campaigns.review.customers.title" />
        </Text.Header4>
        <DisabledContent text={text} infoText={infoText} />
      </div>
    </ReviewSection>
  )
}

export default AudienceDisabled
