import { buildTranslate } from 'locales'
import React from 'react'
import { useAbility } from 'utilities/ability'
import AccordionButton from '../Bar/AccordionButton'
import MenuItem from '../Bar/MenuItem'
import CustomersContent from './Content'

const t = buildTranslate('nav.customers_menu')

type PropsT = {
  isLeftNav?: boolean
  onClick?: VoidFunction
  paths: string[]
}

const Customers: React.FC<PropsT> = ({ isLeftNav = false, onClick, paths }) => {
  const ability = useAbility()
  const menuDisabled =
    ability.cannot('manage', 'Users') &&
    ability.cannot('manage', 'Segments') &&
    ability.cannot('manage', 'Insights') &&
    ability.cannot('manage', 'LegacyReports') &&
    ability.cannot('manage', 'OperationalReports') &&
    ability.cannot('access', 'LocationReports')

  const Parent = isLeftNav ? AccordionButton : MenuItem

  return (
    <Parent disabled={menuDisabled} paths={paths} buttonContents={t('button')}>
      <CustomersContent isLeftNav={isLeftNav} onClick={onClick} />
    </Parent>
  )
}

export default Customers
