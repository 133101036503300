export default {
  message: {
    steps: {
      setup: {
        select: {
          title: 'Customers whose favorite location is:',
          placeholder: 'Select location',
        },
      },
    },
  },
  timeshift: {
    naming: {
      morning: 'morning',
      evening: 'evening',
      midday: 'middle of the day',
      afternoon: 'afternoon',
      night: 'nighttime',
      weekday: 'weekdays',
      weekend: 'weekends',
    },

    steps: {
      setup: {
        title: 'Boost traffic %{conjunction} %{period}',
        daypart: 'in the',
        weekpart: 'on',
        subtitle: 'Only target customers who don’t visit during this time.',
        stat: {
          supertitle: 'Did you know',
          message:
            '<strong class="extra-bold">%{amount}</strong> customers don’t shop %{conjunction} %{period}. Let’s change that.',
          info: 'This number includes %{varied} customers who don’t have a preference.',
        },
        graph: {
          subtitle:
            'Customers who have not yet made multiple purchases are not included.',
          title: 'Customer shopping preferences.',
          labels: {
            morning_count:
              '<p class="text-left grey-70"><p class="bold">Morning</p>6am - 11am</p>',
            midday_count:
              '<p class="text-left grey-70"><p class="bold">Midday</p>11am - 2pm</p>',
            afternoon_count:
              '<p class="text-left grey-70"><p class="bold">Afternoon</p>2pm - 5pm</p>',
            evening_count:
              '<p class="text-left grey-70"><p class="bold">Evening</p>5pm - 10pm</p>',
            night_count:
              '<p class="text-left grey-70"><p class="bold">Night</p>10pm - 6am</p>',
            weekend_count:
              '<p class="text-left grey-70"><p class="bold">Weekend</p>Saturday & Sunday</p>',
            weekday_count:
              '<p class="text-left grey-70"><p class="bold">Weekday</p>Monday - Friday</p>',
          },
        },
      },
      review: {
        audience: {
          breakout_modal:
            'Customers who have <strong>not</strong> shopped %{conjunction} %{period}',
        },
      },
    },
  },
  upsell: {
    steps: {
      setup: {
        title: 'What item do you want customers to buy?',
        subtitle: 'Only target customers who don’t visit during this time.',
        stat: {
          supertitle: 'Did you know',
          message: 'Only %{percent} of your customers buy %{category}',
        },
        selector: {
          title: 'Encourage more customers to buy...',
          placeholder: 'Select an item',
        },
        graph: {
          title: 'Number of customers who purchased these items',
        },
      },
      review: {
        audience: {
          breakout_modal:
            'Customers who have <strong>not</strong> bought %{category} recently',
        },
      },
    },
  },
  location_close: {
    steps: {
      setup: {
        title: 'Choose a location to close',
        subtitle: 'Target customers who actively shop there',
        select: {
          label: 'Which location is closing?',
          placeholder: 'Choose one',
        },
        datepicker: {
          label: 'When is it closing?',
        },
        stat: {
          supertitle: 'Did you know',
          message:
            '<strong>%{count}</strong> customers shop at this location. Let’s encourage them to visit another location.',
          tooltip:
            'This is the total number of customers who have shopped at this location.',
        },
      },
      review: {
        audience: {
          description: 'Customers who have shopped at %{locationName}',
          location_placeholder: 'your closing location',
        },
      },
    },
  },
  location_reopen: {
    steps: {
      setup: {
        title: 'Choose a location to reopen',
        subtitle: 'Target customers who previously shop there',
        select: {
          label: 'Which location is reopening?',
          placeholder: 'Choose one',
        },
        datepicker: {
          label: 'When is it opening up again?',
        },
        stat: {
          supertitle: 'Did you know',
          message:
            '<strong>%{count}</strong> customers like to shop at this location. Let’s tell them it’s opening up again.',
          tooltip:
            'This is the total number of customers who have shopped at this location.',
        },
      },
      review: {
        audience: {
          description: 'Customers who have shopped at %{locationName}',
          location_placeholder: 'your reopening location',
        },
      },
    },
  },
  location_message: {
    steps: {
      setup: {
        audience: {
          title: 'Choose a location',
          subtitle: 'Target customers who actively shop there.',
          selector: {
            title: 'Choose a location',
            placeholder: 'Choose a location',
          },
          stat: {
            supertitle: 'Did you know',
            message:
              '<strong>%{count}</strong> customers actively shop at this location. Let’s tell them what’s happening there.',
            message_0:
              '<strong>%{count}</strong> customers actively shop at this location.',
            tooltip:
              'This is the total number of customers who have shopped at this location in the past 90 days.',
          },
        },
      },
      review: {
        audience: {
          description: 'Customers who have shopped at %{locationName}',
          location_placeholder: 'your location',
        },
      },
    },
  },
  location_promote: {
    steps: {
      setup: {
        audience: {
          title: 'Choose a location to promote',
          subtitle: 'Target customers who shop nearby.',
          selector: {
            title: 'Promote this location',
            placeholder: 'Choose a location',
          },
          multiselect: {
            title: 'To customers who shop at',
            placeholder: 'Any location',
          },
          stat: {
            supertitle: 'Did you know',
            message:
              'You have <strong>%{userCount}</strong> customers that shop at %{targetedLocation} but <i>haven’t</i> visited %{promotedLocation}. Let’s change that.',
            message_plural:
              'You have <strong>%{userCount}</strong> customers that shop at %{count} locations but <i>haven’t</i> visited %{promotedLocation}. Let’s change that.',
            message_0:
              'You have <strong>%{userCount}</strong> customers that <i>haven’t</i> visited %{promotedLocation}. Let’s change that.',
            tooltip:
              'This is the total number of customers that have made a purchase at %{targetedList} but have not made a purchase at %{promotedLocation}.',
          },
        },
      },
      review: {
        audience: {
          description: 'Customers who have not shopped at %{locationName}',
          location_placeholder: 'your location',
        },
      },
    },
  },
  engage_vip: {
    steps: {
      setup: {
        audience: {
          title: 'Surprise your VIPs with something special',
          subtitle: 'Target your most valuable customers',
          vip_count: {
            title:
              'Limit this campaign to a specific number of top spenders at each location',
            placeholder: 'All VIPs',
          },
          stat: {
            supertitle: 'Did you know',
            message_0:
              'You have <strong>%{userCount}</strong> VIPs who are worth <strong>%{lift}x</strong> as much as your average customer. Let’s show them some appreciation.',
            message:
              'You have <strong>%{userCount}</strong> VIPs who shop at <strong>%{street}</strong>. Let’s show them some appreciation.',
            message_plural:
              'You have <strong>%{userCount}</strong> VIPs who shop at these locations. Let’s show them some appreciation.',
            tooltip:
              'Your VIPs are customers whose spending is in the top 5%. This looks over multiple months to exclude people who may have had a large one-off purchase for a group or business event.',
          },
          graph: {
            title: 'Revenue per VIP customer',
            labels: {
              vip_spend:
                '<p class="text-center grey-70 font-size-14">Typically your VIPs<br />spend %{amount}</p>',
              avg_spend:
                '<p class="text-center grey-70 font-size-14">Typically your average<br />customers only<br />spend %{amount}</p>',
              lift: '<p class="text-center white-70 font-size-14">%{multiplier}x as much as<br />your average<br />customer</p>',
            },
          },
        },
      },
    },
  },
}
