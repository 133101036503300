import { Text } from '@thanx/uikit/text'
import React from 'react'
import ToS from './TermsOfService'

type Props = {
  title: string
  description: string
}

const TextInstructions: React.FC<Props> = ({ title, description }) => {
  return (
    <>
      <Text.BodyText4 bold tag="div" className="mb-m">
        {title}
      </Text.BodyText4>
      {description}
      <ToS className="mt-l" />
    </>
  )
}

export default TextInstructions
