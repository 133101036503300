import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import TabPane from 'components/Tabs/TabPane'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useState } from 'react'
import { useAbility } from 'utilities/ability'
import ButtonPanel from '../ButtonPanel'
import UserCampaigns from './Campaigns'
import Feedback from './Feedback'
import Forbidden from './Forbidden'
import ItemsPurchased from './ItemsPurchased'
import Orders from './Orders'
import Overview from './Overview'
import Rewards from './Rewards'

const t = buildTranslate('users.view.activity')

type Props = {
  profile: UserProfile | null
}

type PanelType =
  | 'overview'
  | 'items_purchased'
  | 'rewards'
  | 'feedback'
  | 'campaigns'
  | 'orders'

const Activity: React.FC<Props> = ({ profile }: Props) => {
  const [selectedPanel, setSelectedPanel] = useState<PanelType>('overview')
  const [css] = useStyletron()
  const isEmailsTabEnabled = useFlag('MX4-UserProfiles-EmailsTab', false)

  const hasAbility = useAbility().can('access', 'LocationDetailReports')

  const panels: Array<PanelType> = [
    'overview',
    'orders',
    'items_purchased',
    'rewards',
    'feedback',
  ]

  if (isEmailsTabEnabled) {
    panels.push('campaigns')
  }

  if (!profile) {
    return null
  }

  if (!hasAbility) {
    return (
      <TabPane state="activity">
        <Forbidden />
      </TabPane>
    )
  }

  return (
    <TabPane state={'activity'}>
      <section
        className={`mb-l ${css({
          minHeight: 'calc(100vh - 300px)',
        })}`}
      >
        <Text.Header3 className="my-0">{t('title')}</Text.Header3>
        <div className={'d-flex mt-xl'}>
          <ButtonPanel
            selectedPanel={selectedPanel}
            onSelectPanel={setSelectedPanel}
            panels={panels.map(panel => ({
              type: panel,
              label: t(`panels.${panel}`),
            }))}
          />
          <div className={'w-100'}>
            {selectedPanel === 'overview' && <Overview profile={profile} />}
            {selectedPanel === 'items_purchased' && (
              <ItemsPurchased profile={profile} />
            )}
            {selectedPanel === 'rewards' && <Rewards profile={profile} />}
            {selectedPanel === 'orders' && <Orders profile={profile} />}
            {selectedPanel === 'feedback' && <Feedback profile={profile} />}
            {selectedPanel === 'campaigns' && (
              <UserCampaigns profile={profile} />
            )}
          </div>
        </div>
      </section>
    </TabPane>
  )
}

export default Activity
