import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { EmailMessageCountMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import InfoRow from 'scenes/CampaignCenter/Report/v3/Test/components/InfoRow'
import { selectCampaignVariantMetricByType } from 'selectors/campaignMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
  variantIds: number[]
}

const Clicked: React.FC<PropsT> = props => {
  const { campaignId, variantIds } = props
  const metrics =
    (useSelector(state =>
      selectCampaignVariantMetricByType(
        state,
        campaignId,
        'email_message_count',
        variantIds
      )
    ) as EmailMessageCountMetric[]) || {}

  const variants = variantIds.map((_, i) => {
    const primaryValue = percentageFormatter(
      metrics[i]?.data?.clicked_rate,
      1,
      1
    )
    const secondaryValue = numberFormatter(metrics[i]?.data?.clicked_count)

    return {
      primaryContent: (
        <Metric metric={primaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('engagement.email.tooltips.percent_of_clicked'),
              },
              {
                title: t('how_its_calc'),
                content: t('engagement.email.tooltips.percent_clicked_calc'),
              },
            ]}
          />
        </Metric>
      ),
      secondaryContent: (
        <Metric primary={false} metric={secondaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('engagement.email.tooltips.number_of_clicked'),
              },
              {
                title: t('how_its_calc'),
                content: t('engagement.email.tooltips.number_clicked_calc'),
              },
            ]}
          />
        </Metric>
      ),
      isErrored: metrics[i]?.isErrored,
      isLoading: metrics[i]?.isLoading,
    }
  })
  return <InfoRow title={t('engagement.email.clicked')} variants={variants} />
}

export default Clicked
