import { Text } from '@thanx/uikit/text'
import { getLocation } from 'actions/location'
import { getMenus } from 'actions/menus'
import { getOrderingConfig, putOrderingRefresh } from 'actions/ordering_configs'
import Help from 'components/Help'
import InfoBox from 'components/InfoBox'
import PageHeader from 'components/PageHeader'
import PopoverContent from 'components/PopoverContent'
import UpdateButton from 'components/UpdateButton'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectLocation } from 'selectors/location'
import { selectMenusByLocationId } from 'selectors/menus'
import { selectOrderingConfig } from 'selectors/orderingConfigs'
import { getDateWithTimeAndZone } from 'utilities/date'
import { orderingManagement } from 'utilities/urlUtils'
import { useUserTimeZone } from 'utilities/userTimeZone'
import StatusBadge from '../StatusBadge'
import Menus from './Menus'
import UpdateOrderingStatusModal from './UpdateOrderingStatusModal'

type PropsT = {
  match: {
    params: {
      id: string
    }
  }
}

const t = buildTranslate('ordering_management.detail')

const View: React.FC<PropsT> = props => {
  const { match } = props
  const id = parseInt(match.params.id)

  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const dispatch = useDispatch()
  const timeZone = useUserTimeZone()
  const location = useSelector(state => selectLocation(state, id))
  const orderingConfig = useSelector(state => selectOrderingConfig(state, id))
  const menus = useSelector(state => selectMenusByLocationId(state, id))

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await dispatch(getLocation(id))
      setIsLoading(false)
    }
    if (!location?.id) fetchData()
  }, [id, dispatch, location?.id])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await dispatch(getOrderingConfig(id))
      setIsLoading(false)
    }
    if (!orderingConfig?.id) fetchData()
  }, [id, dispatch, orderingConfig?.id])

  useEffect(() => {
    const fetchData = () => {
      dispatch(getMenus(id))
    }
    if (isEmpty(menus)) fetchData()
  }, [id, dispatch, menus])

  if (!location || !orderingConfig || isLoading) return null

  const updatedAt = orderingConfig.menu_last_synced_at
    ? getDateWithTimeAndZone(orderingConfig.menu_last_synced_at, timeZone)
    : null

  const errorContainer = isEmpty(orderingConfig.config_errors) ? null : (
    <InfoBox
      className="my-xxl"
      isClosable={false}
      type="alert"
      title={t('errors.title')}
      content={orderingConfig.config_errors.map((error, index) => (
        <Text.BodyText4 key={index} color="grey70" tag="p" className="mb-xs">
          {error}
        </Text.BodyText4>
      ))}
    />
  )

  return (
    <>
      {' '}
      <Helmet>
        <title>
          {t('title', { name: !!location.name ? `${location.name} - ` : '' })}
        </title>
      </Helmet>
      <PageHeader
        backPath="/ordering_management"
        title={location.name || '-'}
        topComponent={
          <StatusBadge
            status={
              orderingConfig.ordering_status === 'enabled'
                ? 'success'
                : 'disabled'
            }
            value={t(`header.${orderingConfig.ordering_status}`)}
          />
        }
        description={location.fullName()}
        buttonText={t(`header.button.${orderingConfig.ordering_status}`)}
        buttonAction={() => setIsModalOpen(true)}
        buttonIsDisabled={orderingConfig.ordering_status === 'not_configured'}
      >
        <div className="grey-05-bkg border-top-1 py-xxl grey-20-border mt-l">
          <Container>
            <Text.BodyText4 className="text-right" tag="p" color="grey70">
              {updatedAt && `${t('update.last_updated')} ${updatedAt}. `}
              <UpdateButton
                pollSeconds={15}
                pollAction={() => dispatch(getOrderingConfig(id))}
                comparison={{
                  response: 'payload.data.ordering_config.menu_last_synced_at',
                  previous: orderingConfig.menu_last_synced_at,
                }}
                onClick={() => dispatch(putOrderingRefresh(id))}
              />
              <span className="d-inline-block pl-xxs">
                <PopoverContent
                  sections={[
                    {
                      content: t('popover'),
                    },
                  ]}
                />
              </span>
            </Text.BodyText4>
            {errorContainer}
            <Menus menus={menus} enabled />
            <Menus menus={menus} />
            <Help
              title={t('help.help')}
              articles={[{ title: t('help.link'), url: orderingManagement }]}
            />
          </Container>
        </div>
        <UpdateOrderingStatusModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          locationId={id}
          enable={orderingConfig.ordering_status !== 'enabled'}
        />
      </PageHeader>
    </>
  )
}

export default View
