import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import includes from 'lodash/includes'
import numeral from 'numeral'
import React from 'react'
import { I18n } from 'react-redux-i18n'

Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
  },
})

type Props = {
  currentPeriod: string
  order: string[]
  data: Highcharts.XrangePointOptionsObject[]
  selectedColor: string
  barColor: string
}

const Graph: React.FC<Props> = props => {
  const { currentPeriod, order, data, selectedColor, barColor } = props
  const colors: string[] = []
  order.forEach(period => {
    if (includes(period, currentPeriod)) {
      colors.push(selectedColor)
    } else {
      colors.push(barColor)
    }
  })
  const options: Highcharts.Options = {
    credits: { enabled: false },
    tooltip: { enabled: false },
    title: undefined,
    legend: { enabled: false },
    xAxis: {
      lineColor: 'transparent',
      categories: order,
      labels: {
        useHTML: true,
        align: 'left',
        reserveSpace: true,
        formatter: function () {
          return I18n.t(
            `thanx_campaigns.campaigns.timeshift.steps.setup.graph.labels.${this.value}`
          )
        },
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: {
      visible: false,
    },
    plotOptions: {
      bar: {
        pointPadding: -0.15,
        borderWidth: 0,
        colorByPoint: true,
      },
      series: {
        //@ts-ignore
        minPointLength: 2,
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            return `<span class="normal opacity-50">${numeral(this.y).format(
              '0,0'
            )}</span>`
          },
        },
      },
    },
    colors,
    chart: {
      type: 'bar',
      height: 400,
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Lato',
      },
    },
    series: [
      {
        data,
        type: 'bar',
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default Graph
