import { textUtils } from 'utilities/textUtils'
import isEqual from 'lodash/isEqual'
import isEqualWith from 'lodash/isEqualWith'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import isNaN from 'lodash/isNaN'

export function capitalizeOnChange(event) {
  event.currentTarget.value = textUtils.initialCapital(
    event.currentTarget.value
  )
}

export function uppercaseOnEvent(event) {
  let text = event.currentTarget.value
  if (text && text.length > 0) {
    event.currentTarget.value = text.toUpperCase()
  }
}

export function toFormData(
  params,
  parents = [],
  formData = new window.FormData()
) {
  Object.keys(params).forEach(key => {
    if (params[key] === null || params[key] === undefined) {
      return
    }

    if (Object.getPrototypeOf(params[key]) === Object.prototype) {
      let parentsCopy = parents.slice()
      parentsCopy.push(key)
      this.toFormData(params[key], parentsCopy, formData)
    } else {
      var combinedKey = key
      if (parents.length > 0) {
        let parentsCopy = parents.slice()
        parentsCopy.push(key)
        let rootKey = parentsCopy.shift()
        combinedKey = parentsCopy.reduce(
          (accumulator, currentValue) => accumulator + `[${currentValue}]`,
          rootKey
        )
      }
      formData.append(combinedKey, params[key])
    }
  })
  return formData
}

export function modelsAreEqual(modelA, modelB) {
  return isEqualWith(modelA, modelB, (value, other) => {
    return valuesAreEqual(value, other)
  })
}

function valuesAreEqual(valueA, valueB) {
  if (isEqual(valueA, valueB)) return true

  // Try to cast and compare floats if values are numbers
  // or strings. Note that !_.isNaN([1, 2]) === false
  if (isFloaty(valueA) && isFloaty(valueB)) {
    return areFloatsEqual(valueA, valueB)
  }

  // ignore emoji in string comparison
  if (
    typeof valueA === 'string' &&
    typeof valueB === 'string' &&
    valueA.replace(/[\uD800-\uDBFF]./g, '') ===
      valueB.replace(/[\uD800-\uDBFF]./g, '')
  ) {
    return true
  }

  if ((valueA === 0 && valueB === '') || (valueA === '' && valueB === 0)) {
    return true
  }
}

// Returns true if the value is a string or number
// that can be converted to a valid float
export function isFloaty(value) {
  return (isNumber(value) || isString(value)) && !isNaN(parseFloat(value))
}

// Compares two floats
export function areFloatsEqual(valueA, valueB) {
  return Math.abs(valueA - valueB) < Number.EPSILON
}
