import type { DayBucketCountData } from 'models/MerchantMetric'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { numberFormatter } from 'utilities/formatters'
import BucketTable from '../../../components/BucketTable'
import { avgDayTrajectory } from '../../../utilities'

type Props = {
  data: DayBucketCountData
}

const Membership: React.FC<Props> = ({ data }) => {
  return (
    <div>
      <span className="bold">
        {I18n.t(
          'reports.activity.sections.customers.metrics.membership.bucket_text'
        )}
        :
      </span>
      <BucketTable
        keySuffix="count"
        metrics={[{ data }]}
        valueFormatter={numberFormatter}
        trajectoryCalculator={avgDayTrajectory}
      />
    </div>
  )
}

export default Membership
