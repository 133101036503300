import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { Calendar } from 'baseui/datepicker'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { DateRangeFilterContext } from './DateFilterContext'
import DateInput from './DateInput'
import { DateInputsContext } from './DateInputsContext'
import {
  customDatepickerOverrides,
  DateRangeFilter,
  dateRangeFilterOptions,
  DateRangeFilterType,
  getDateFilter,
} from './utilities'

type Props = {
  timeZone: string
  onCancel: VoidFunction
  onApply: (filter: DateRangeFilter) => void
}

const t = buildTranslate('dateRangeFilter.custom_filter')

const CustomDateRangeFilter: React.FC<Props> = props => {
  const { onCancel, onApply, timeZone } = props
  const [css] = useStyletron()
  const { filter } = useContext(DateRangeFilterContext)
  const { inputs: dateInputs } = useContext(DateInputsContext)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (filter.start) {
      setStartDate(moment.tz(filter.start, timeZone).toDate())
    }

    if (filter.end) {
      setEndDate(moment.tz(filter.end, timeZone).toDate())
    }
  }, [filter.start, filter.end, timeZone])

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        // if outside the custom picker was clicked, close the custom picker
        onCancel()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeRange = ({ date }: { date: Date | Date[] }) => {
    const value = date instanceof Date ? date : date[0]
    if (!value) {
      return
    }

    if ((startDate && endDate) || !startDate) {
      setStartDate(value)
      setEndDate(null)
      return
    }

    if (
      moment
        .tz(value.toISOString(), timeZone)
        .isSameOrBefore(moment.tz(startDate.toISOString(), timeZone))
    ) {
      setStartDate(value)
    } else {
      setEndDate(value)
    }
  }

  const applyDateRange = () => {
    if (
      !startDate ||
      !endDate ||
      !dateInputs.startDateValid ||
      !dateInputs.endDateValid
    ) {
      return
    }

    const updatedFilter = getDateFilter(
      'custom',
      timeZone,
      startDate.toISOString().slice(0, 10),
      endDate.toISOString().slice(0, 10)
    )
    onApply(updatedFilter)
  }

  const applyPresetRange = (type: DateRangeFilterType) => {
    if (type === 'custom') {
      // do nothing as the custom filter is already open
      return
    }

    onApply(getDateFilter(type, timeZone))
  }

  const dateRange = ((): Date[] | Date | null => {
    if (startDate && endDate) {
      return [startDate, endDate]
    }

    if (startDate) {
      return startDate
    }

    return null
  })()

  return (
    <div
      ref={containerRef}
      className={`custom-date-range-filter mt-xs pb-l ${css({
        backgroundColor: palette.white,
        position: 'absolute',
        zIndex: 99,
        border: `1px solid ${palette.grey30}`,
        borderRadius: '4px',
        boxShadow: '0px 4px 8px rgba(54, 66, 78, 0.3)',
      })}`}
    >
      <div className="d-flex">
        <div className="pt-xs">
          {dateRangeFilterOptions.map(option => {
            const isSelected = option.value === filter.type
            return (
              <div
                key={option.value}
                className={css({
                  ':hover': {
                    backgroundColor: palette.belizeHole10,
                  },
                })}
              >
                <Button
                  kind="minimal"
                  className={`justify-content-start py-xxs ${css({
                    width: '200px',
                    borderRadius: 0,
                    backgroundColor: isSelected
                      ? palette.grey10
                      : palette.white,
                  })}`}
                  onClick={() => applyPresetRange(option.value)}
                >
                  <Text.BodyText4 className="line-height-20" color="grey70">
                    {option.label}
                  </Text.BodyText4>
                </Button>
              </div>
            )
          })}
        </div>
        <div className="mt-m">
          <div className="d-flex mx-s justify-content-between">
            <DateInput
              type="start_date"
              value={startDate}
              onChange={setStartDate}
              timeZone={timeZone}
            />
            <Text.BodyText3
              className="d-flex mx-s align-items-center"
              color="grey70"
            >
              -
            </Text.BodyText3>
            <DateInput
              type="end_date"
              value={endDate}
              onChange={setEndDate}
              timeZone={timeZone}
            />
          </div>
          <Calendar
            value={dateRange}
            range
            monthsShown={2}
            onChange={onChangeRange}
            overrides={customDatepickerOverrides}
          />
        </div>
      </div>
      <div className="d-flex mt-s justify-content-end">
        <Button className="mr-s" kind="secondary" onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button onClick={applyDateRange} className="mr-m">
          {t('apply')}
        </Button>
      </div>
    </div>
  )
}

export default CustomDateRangeFilter
