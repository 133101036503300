import { Fields as Campaign } from 'models/Campaign'
import React, { useState } from 'react'
import ContinueButton from 'scenes/CampaignCenter/Builder/components/ContinueButton'
import { useFormManagement } from 'scenes/CampaignCenter/Builder/components/FormManagementContext'
import TopBar from 'scenes/CampaignCenter/Builder/components/TopBar'
import Content from './Content'
import DeletionModal from './DeletionModal'
import { useVariantSelectionHandler, VariantAction } from './helper'

type PropsT = {
  campaign: Campaign
}

const VariantSelector: React.FC<PropsT> = props => {
  const { campaign } = props
  const { triggerSave, setCurrentStep } = useFormManagement()
  const { submitVariant } = useVariantSelectionHandler()
  const [variantAction, setVariantAction] =
    useState<VariantAction>('multiVariant')
  const [deletionModalIsOpen, setDeletionModalIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onContinueClick = async () => {
    if (variantAction === 'removeVariant') {
      setDeletionModalIsOpen(true)
    } else {
      onSubmit()
    }
  }

  const getVariantIds = (campaign, variantId, variantAction) => {
    if (variantAction !== 'removeVariant') return [variantId]

    // 'removeVariant' removes all variants in the campaign
    // except the specified ID.
    return campaign.variants
      .map(variant => variant.id)
      .filter(id => id !== variantId)
  }

  const onSubmit = async (variantId?: number) => {
    if (variantAction !== 'multiVariant' || campaign.variants?.length < 2) {
      setIsLoading(true)
      await submitVariant({
        action: variantAction,
        campaignId: campaign.id,
        variantIds: getVariantIds(campaign, variantId, variantAction),
      })
      setIsLoading(false)
    }
    setCurrentStep('review')
  }

  return (
    <>
      <TopBar
        triggerSave={triggerSave}
        campaign={campaign}
        stepName="variantSelect"
        setCurrentStep={setCurrentStep}
      >
        <ContinueButton
          onClick={onContinueClick}
          isSavingOverride={isLoading}
        />
      </TopBar>
      <div className="container">
        <Content campaign={campaign} setVariantAction={setVariantAction} />
      </div>
      <DeletionModal
        isOpen={deletionModalIsOpen}
        setIsOpen={setDeletionModalIsOpen}
        onDelete={onSubmit}
        variants={campaign.variants}
      />
    </>
  )
}

export default VariantSelector
