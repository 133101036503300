import { MembershipTag } from 'models/UserProfile'
import React from 'react'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'

type PropsT = {
  tag: MembershipTag
}

const MembershipTagContent: React.FC<PropsT> = ({ tag }: PropsT) => {
  return (
    <BodyCell
      isErrored={false}
      isLoading={false}
      values={tag.values.map(v => ({ value: v }))}
    />
  )
}

export default MembershipTagContent
