import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type Props = {
  onSort: (direction: 'asc' | 'desc') => void
}

const SortToggle: React.FC<Props> = props => {
  const { onSort } = props
  const [css] = useStyletron()

  return (
    <div className="pl-s cursor-pointer">
      <div className="d-flex">
        <i
          className={`fa fa-caret-up btn-link font-size-14 grey-90 ${css({
            lineHeight: '8px',
          })}`}
          onClick={() => onSort('asc')}
        />
      </div>
      <div className="d-flex">
        <i
          className={`fa fa-caret-down btn-link font-size-14 grey-90 ${css({
            lineHeight: '8px',
          })}`}
          onClick={() => onSort('desc')}
        />
      </div>
    </div>
  )
}

export default SortToggle
