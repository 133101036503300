import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import moment from 'moment-timezone'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAbility } from 'utilities/ability'
import { useUserTimeZone } from 'utilities/userTimeZone'
import ActionsMenu from '../../ActionsMenu'
import RedeemTemplateStatus from '../../RedeemTemplateStatus'

type PropsT = {
  template: RedeemTemplate
}

const t = buildTranslate('redeem_manager.landing.redeem_item')

const Item: React.FC<PropsT> = ({ template }) => {
  const { id, created_at, state } = template
  const ability = useAbility()
  const disableActions = ability.cannot('manage', 'RedeemManager')
  const timeZone = useUserTimeZone()

  return (
    <tr className="border-bottom">
      <td className="width-50 p-s pl-m">
        <p className="body-text-4">{template.name}</p>
      </td>
      <td className="pr-l">
        <p className="body-text-4">
          {moment.tz(created_at, timeZone).format('MM/DD/YYYY')}
        </p>
      </td>
      <td className="pr-l">
        <RedeemTemplateStatus state={state} />
      </td>
      <td className="pr-l">
        <Switch
          condition={state !== 'unpublished' || disableActions}
          fallback={<Link to={`/rewards/${id}/edit`}>{t('edit')}</Link>}
        >
          <Link to={`/rewards/${id}`}>{t('view')}</Link>
        </Switch>
      </td>
      {!disableActions && (
        <td className="pr-l">
          <ActionsMenu template={template} />
        </td>
      )}
    </tr>
  )
}

export default Item
