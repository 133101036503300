import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import type { Fields as Location } from '../models/Location'
import orm from '../models/orm'

const selectLocations = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Location[] => session.Location.all().toModelArray()
)

export const selectCurrentMerchantLocations = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (state: any) => state.auth.current_merchant_id,
  (session: any, merchantId): Location[] => {
    if (!merchantId) return []

    return session.Location.filter(
      location => location.merchant_id === merchantId
    ).toModelArray()
  }
)

export const selectLocation = createSelector(
  selectLocations,
  (_: any, id?: number): number | undefined => id,
  (locations: Location[], id): Location | null =>
    locations.find(l => l.id === id) ?? null
)

export const selectLocationsByIds = createSelector(
  selectLocations,
  (_, ids): number[] => ids,
  (locations, ids): Location[] => {
    return locations.filter(location => {
      return ids.includes(location.id)
    })
  }
)
