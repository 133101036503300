const thanx = {
  title: 'Thanx Terms of Service',
  welcome:
    'Welcome to Thanx! Thanx is a loyalty reward and customer engagement service (our "<b><i>Service</i></b>"), operated by Thanx, Inc. ("Thanx" or "We" or "Our") and made available via web ordering, our website, <a href="http://www.thanx.com">www.thanx.com</a> (our "<b><i>Site</i></b>") and as an application for mobile devices (our "<b><i>App</i></b>") in conjunction with Merchants who participate in the Service ("<b><i>Merchants</i></b>"). These Terms of Service ("Terms") govern the use and access to the Site, App and related services and content made available through the Service. ',
  accessing:
    'By accessing or using any part of the Site, App and/or Service, you agree to be bound by these Terms.',
  eligibility: {
    title: 'Eligibility',
    in_order: 'In order to use our Service, you must:',
    person: 'Be an individual person at least 18 years of age;',
    possess:
      'Possess the authority and capacity to create a binding legal obligation in your state and/or country of residence; and',
    not_be:
      'Not be a person barred from receiving the Service under the laws of the United States or other applicable jurisdiction.',
  },
  creating: {
    title: 'Creating an Account',
    in_order:
      'In order to access certain features of the Service, you will have to create an account.',
    you_may:
      'You may only create one account and it is non-transferable. When you create an account, you agree to provide accurate, current and complete information about yourself. You are responsible for safeguarding your account and are responsible for activities that occur under your account. Notify us immediately if your password is lost, stolen, or disclosed to an unauthorized third party, or otherwise may have been compromised.',
  },
  privacy: {
    title: 'Privacy Policy',
    refer:
      'Thanx values your loyalty and we are dedicated to protecting your privacy. Please refer to our <a href="https://dashboard.thanx.com/privacy">Privacy Policy</a> for more information on how we collect, use and disclose information from our users.',
  },
  using: {
    title: 'Using the Service',
    provides:
      'Our Service provides a personalized reward experience for consumers, including loyalty rewards and offers, discounts, experiences, targeted promotions and general information (collectively, "<b><i>Opportunities</i></b>") via email, mobile device notifications and other channels. You can link one or multiple payment methods to enable automatic accrual and, in some cases, automatic redemption of rewards. We will try to deliver you the most relevant Opportunities to you.',
    to_use:
      'To use the Service, you may (1) link one or multiple payment cards with your account ("<b><i>Linked Cards</i></b>") and (2) make eligible purchases (as defined by the merchant and described in the "<b>Important Notes About Your Cards and Transactions</b>" section below) with your Linked Cards or upload receipts for your purchases from merchants who participate in the Service ("<b><i>Merchants</i></b>") to earn progress and rewards (collectively, "<b><i>Qualifying Purchases</i></b>").',
    qualifying:
      'Making Qualifying Purchases will help you progress towards earning rewards from Merchants ("<b><i>Reward Progress</i></b>") and reaching a Merchant-specific threshold (a "<b><i>Reward Threshold</i></b>"). When you reach a Reward Threshold, you will have access to rewards offered by the applicable Merchant which can be redeemed automatically to your Linked Card or on your next visit by showing the reward to the Merchant, as applicable (collectively, "<b><i>Rewards</i></b>").',
  },
  notes: {
    title: 'Important Notes About Your Cards and Transactions',
    registering:
      'By registering for the Service and linking a payment card, you agree that your payment network (e.g., MasterCard or Visa), may examine transactions (including returns) on your Linked Card to identify Qualifying Purchases and share transaction data with Thanx for the purposes of determining whether a transaction is a Qualifying Purchase, determining your Rewards Progress and when you have reached a Rewards Threshold, providing you with Rewards and notifying you about the status of your Qualifying Purchases, Rewards Progress, Rewards Thresholds and Rewards.',
    payment:
      "Not all payment cards are eligible for the Service: for example, corporate and purchasing cards, Health Savings Account (HSA), Flexible Spending Account (FSA) cards, government-administered prepaid cards (including EBT cards), insurance prepaid cards, Visa Buxx and other cards whose transactions are not processed through the payment network's own system are not eligible.",
    merchant:
      "Not all merchant locations or transactions with your Linked Card may be eligible for Thanx, including PIN-based purchases, payments made through third-party digital wallets or payment apps other than Apple Pay, and other transactions not processed through the payment network's own system.",
    pin: 'Do not use a Personal Identification Number (PIN) when paying for your purchases with your Linked Card if you want the transaction to count toward your Reward Progress.',
    service:
      'The Service may offer additional Opportunities to you, based on your account settings and account history. At any point in time you can opt-out of receiving additional Opportunities and/or specific Merchant offerings via the Service through your account settings within the App or on the Site.',
    reward:
      'Reward Progress is not redeemable for cash or any other item of value. Your Reward Progress may be forfeited if a Merchant has restrictions associated with Reward Progress (e.g. if a Qualifying Purchase(s) must occur within a certain timeframe). You are responsible for ensuring your Reward Progress from a Qualifying Transactions is properly added to your account. It may take up to 7 days after a Qualifying Transaction is made for your Reward Progress to be viewable via your account. You should <a href="mailto:support@thanx.com">contact us</a> if it has been more than 7 days and the Reward Progress has not been applied to your account. We must receive your request within 21 days of a Qualifying Transaction in order to credit the account. We will not be responsible for investigating any Reward Progress that has not been credited to your account 21 days after the Qualifying Purchase. Your Reward Progress can also be reversed by a Merchant or Thanx if any of the items you purchased in connection with a Qualifying Transaction are subsequently returned. We may also, at our discretion, delay any Reward Progress in order to validate or verify a Qualifying Transaction. Any attempt to earn Reward Progress through the return of merchandise in connection with a Qualifying Transaction will be considered possible grounds for termination of your account.',
    acknowledge: 'You acknowledge that:',
    redemption:
      'The redemption of your Rewards (including the granting of any Reward Progress) is subject to the discretion of the applicable Merchant and the decision to permit (or to continue to permit) the redemption of your Rewards is made entirely in the sole discretion of the applicable Merchant;',
    work: 'We work closely with merchants to ensure an easy redemption experience but we do not have control over that process or liability for the inability to redeem your Rewards at a Merchant;',
    purchase:
      'The purchase of any goods from Merchants is subject to the terms and conditions set by the applicable Merchant;',
    providers:
      'We and our providers will not have any liability to you for any actions taken by a Merchant with respect to Rewards or any goods purchased from a Merchant;',
    redeem: 'You must redeem your Reward with the applicable Merchant;',
    progress:
      'Reward Progress and Rewards are not transferable and can only be used in accordance with any terms and conditions provided by the Merchant;',
    use: 'Your use of the Service and your activities in connection with the Service must be lawful and in compliance with these Terms and you may not in any way inhibit any other user from using our Service;',
    liable:
      'We and our providers are not responsible or liable for any unredeemed, unused or lost Rewards or Opportunities;',
    not_redeemable:
      'Rewards are not redeemable for any sum of money or monetary value; and',
    responsible:
      'You are solely responsible for use of your Rewards and Opportunities in connection with the Service and for use of and access to your account.',
    remedy:
      'Your only remedy for a failure of a Reward or Opportunity is to redeem that Reward or Opportunity at a later time and/or to contact the applicable Merchant, but you may also contact us with any questions or concerns related to the Merchant. If you have a dispute over any goods that you purchased from a Merchant in connection with the Service, you must contact the applicable Merchant. We are not responsible for (and will have no liability regarding) any such goods. Notwithstanding the foregoing, you may also contact us at any time, and we may, in our sole discretion, work with the applicable Merchant to help resolve your dispute. You acknowledge and agree that we and our service providers are not a party to any disputes between you and Merchants and that we have no obligation to resolve any such disputes.',
  },
  liability: {
    title: 'RELEASE OF LIABILITY – MERCHANT DISPUTES',
    dispute:
      'In addition, if you have a dispute with a Merchant about an Opportunity, a Qualifying Purchase, your Reward Progress, a Reward Threshold or any other problem related to a Reward (collectively "Disputes"), your dispute is with the Merchant and not us or our service providers. You hereby release Thanx, our affiliates, and our officers, directors, employees, agents, successors, assigns, and service providers (including payment card networks) from any and all liability, claims, demands, actions or causes of action, damages, or suits in equity of whatever kind or nature arising out of or related to such Disputes. YOU EXPRESSLY WAIVE THE PROVISIONS OF CALIFORNIA CIVIL CODE §1542, WHICH SAYS:',
    general:
      '"<b>A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF NOT KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.</b>"',
    short:
      'In short, this means that you release Thanx and other entities providing services to Thanx from any and all claims, known or unknown,  you may have arising out of Disputes with Merchants.',
  },
  mobile: {
    title: 'Access via Our Mobile App',
    terms:
      'The following terms apply to your use of our App that is accessed through or downloaded from any app store or app distribution platform (like the Apple App Store or Google Play, who are referred to below as an "<b><i>App Provider</i></b>").',
    concluded:
      'These Terms are concluded between us, and not with the App Provider. We are responsible for the App (not the App Provider).',
    obligation:
      'The App Provider has no obligation to furnish any maintenance and support services with respect to the App.',
    event:
      'In the event of any failure of the App to conform to any applicable warranty, you may notify the App Provider, and the App Provider will refund the purchase price for the App to you (if applicable) and to the maximum extent permitted by applicable law, the App Provider will have no other warranty obligation whatsoever with respect to the App. Any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will be our responsibility.',
    provider:
      'The App Provider is not responsible for addressing any claims you have relating to the App or your possession and use of the App, including, but not limited to: (1) product liability claims; (2) any claim that the App fails to conform to any applicable legal or regulatory requirement; and (3) claims arising under consumer protection or similar legislation.',
    third_party:
      "In the event of any third party claim that the App or your possession and use of that App infringes that third party's intellectual property rights, we will be responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim to the extent required by these Terms.",
    subsidiaries:
      'The App Provider, and its subsidiaries, are third party beneficiaries of these Terms as related to your license of the App, and that, upon your acceptance of the terms and conditions of these Terms, the App Provider will have the right (and will be deemed to have accepted the right) to enforce these Terms as related to your license of the App against you as a third party beneficiary thereof.',
    comply:
      'You must also comply with any applicable terms of use provided by the App Provider, when using the App.',
  },
  unauthorized: {
    title: 'Unauthorized Activities',
    lawful:
      'Our Service may be used and accessed for lawful purposes only. You agree that you will not do any of the following while using or accessing the Service: (1) access or tamper with the App, or try to access non-public areas of the Service, our computer systems, or the technical delivery systems of our providers; (2) gather and use information, such as user names, real names, email addresses or transmit any unsolicited advertising, junk mail, spam or other form of solicitation; (3) use the Service for any commercial purpose or for the benefit of any third party or in any manner not by these Terms; (4) violate any applicable law or regulation; (5) use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor the Service or any portion of the Service, without the our permission; or (6) encourage or enable any other individual to do any of the foregoing. We reserve the right to investigate and prosecute violations of any of the above and/or involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms.',
    additionally:
      'Additionally, the following activities are prohibited and constitute a violation of these Terms:',
    fraud: 'Providing fraudulent or false information;',
    accessing: 'Accessing the personal information of third parties;',
    tampering: 'Tampering with security we implement to protect the Service;',
    soliciting:
      'Soliciting Merchants or users for commercial purposes or otherwise or using information made available via the Service for commercial purposes;',
    reselling: 'Reselling Opportunities;',
    attempting: 'Attempting to use Opportunities more than once;',
    overwhelming:
      'Intentionally overwhelming our servers with excessive demand; and',
    hyperlinks: 'Adding any hyperlinks without our written consent.',
  },
  account: {
    title: 'Account Cancellation or Suspension',
    cancel:
      'You may cancel your account at any time through the Service or by <a href="mailto:support@thanx.com">contacting us</a>. If you cancel your account, you will lose all of your accumulated Rewards and Reward Progress.',
    remove:
      'You can remove your Linked Card from the Services at any time by visiting your account, selecting "Registered Cards", clicking "Edit" and deleting any card(s) you prefer. When you do so we will no longer capture future transactions associated with the Linked Card, but we will retain the Transaction Data associated with your Linked Cards for historical transactions as necessary to maintain your rewards program progress and keep track of any applicable rewards.',
    suspend:
      'We may suspend, disable or terminate your access to or use of the Service at any time if (1) you have violated or breached any provision of these Terms (or have acted in a manner which shows that you do not intend to, or are unable to comply with the provisions of these Terms), (2) we believe it is required to do so by law (for example, where the provision of the Service to you is, or becomes, unlawful); or (3) we provide notice to you. If your account is terminated, you may not later re-enroll in the Service.',
    inactive:
      'Accounts that remain inactive for more than 6 months may be subject to automatic cancellation. Once your account is cancelled (whether by you or us), you will no longer be able to access your account. <b>We will not be liable to you or to any third party for the suspension or termination of your account or your access or use of the Service</b>. Once your account is terminated, you understand and acknowledge that we will have no further obligation to provide the Service to you or access to any of your account information.',
  },
  ownership: {
    title: 'Ownership',
    copyright:
      'The Service is protected by copyright, trademark, and other laws of the United States and foreign countries. Thanx and its licensors exclusively own all right, title and interest in and to the Service, Site and the App, including all associated intellectual property rights. You may not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Service, except as expressly permitted in these Terms. Any feedback, comments and suggestions you may provide for improvements to the Service ("<b><i>Feedback</i></b>") is given entirely voluntary and, even if designated as confidential, will not create any confidentiality obligation for us and we will be free to use, disclose, reproduce, license, distribute, and otherwise exploit such Feedback as we see fit, entirely without obligation or restriction of any kind on account of intellectual property rights or otherwise.',
  },
  links: {
    title: 'Links to Third-Party Web Sites',
    contains:
      'Our Service contains links to Merchants and other third party sites or resources. We provide these links as a convenience to you but do not endorse the Merchants, companies or the contents of these sites or their products and services. <b>We are not responsible for the content of such sites or resources. If you decide to access any of the third-party web sites linked to the Service, you do this entirely at your own risk</b>.',
  },
  special: {
    title: 'SPECIAL PROGRAMS - Exclusive Offers',
    product: 'Merchant Product Purchases',
    may: 'From time-to-time, certain Merchants may offer goods or services ("Merchant Products") for purchase on our Site, our App, through Web Ordering, or otherwise through our Service (collectively, the "Thanx Platform") (such offers are referred to below as "Thanx Exclusive Deals"). The following terms, as well as any terms and conditions referenced by the applicable Merchant or otherwise displayed via the Thanx Platform when you order the Merchant Products on the Thanx Platform ("Merchant Terms"), apply to Thanx Exclusive Deals and your purchase of Merchant Products on the Thanx Platform.',
    order:
      'To order Merchant Products, you will be required to provide certain information, such as your debit or credit card number, expiration date, and security code, to Stripe, a third party payments processor, via the Thanx Platform. For Stripe\'s terms and privacy policies, please go to: <a href="https://stripe.com/us/privacy" target="_blank">https://stripe.com/us/privacy</a>. When you purchase a Merchant Product, you are purchasing such Merchant Products from the applicable Merchant – not Thanx. Thanx is not a bank or money transmitter and does not process payments for Merchant Products sold on the Thanx Platform. When you purchase Merchant Products from a Merchant via the Thanx Platform, you will receive a digital code (each, a "Redemption Code") which, subject to the Merchant Terms, is redeemable for Merchant Products as set forth in the Merchant Terms. Redemption Codes may not be exchanged for cash or merged with Thanx or other Merchant offers or rewards.',
    intend:
      'If you did not intend to purchase a Merchant Products or want to cancel an order, you must contact the Merchant and/or Stripe.  Any decision to accept returns of, or issue refunds for, Merchant Products will be made by the merchant and/or Stripe.',
    reserves:
      'Thanx reserves the right to terminate the Thanx Exclusive Deals program or any specific Thanx Exclusive Deal at any time. Thanx also reserves the right to cancel, revoke or otherwise prevent the issuance of Redemption Codes in cases of mistake and in any suspected case of unauthorized or fraudulent use. Thanx is not required to facilitate transfers of Merchant Products or Redemption Codes from you to other individuals.',
    agree:
      'You agree that Thanx may use, share and exchange information about your use of the Thanx Platform, including any purchase of Merchant Products, as permitted by these Terms or the <a href="https://dashboard.thanx.com/privacy">Privacy Policy</a> including, without limitation: to confirm the redemption of a Redemption Code; confirm whether you qualify for a Thanx Exclusive Deal; confirm whether a specific transaction has occurred; create a record of the transaction data to verify and maintain a record of your purchases; provide reports concerning activity as it relates to Thanx Exclusive Deals; perform analytics regarding the performance of Thanx Exclusive Deals; enable Thanx and/or Merchants communicate with you concerning transactions and provide general customer service and respond to your inquiries; and to respond to legal, regulatory and audit requests and resolve disputes and defend claims against or by Thanx concerning its services.',
    authorize:
      'You specifically authorize the sharing, exchange and use of transaction data in connection with your claimed Merchant rewards and Thanx Exclusive Deals with Stripe, any other service providers of Thanx, and applicable Merchants, as described herein and in the Privacy Policy.',
  },
  offer_disclaimer: {
    title: 'Exclusive Offer Disclaimers.',
    platform:
      'Thanx provides a platform for the purchase of goods and services sold by Thanx Merchants, but we are not responsible for the goods or services sold by any Merchant on the Thanx Platform.',
    liability:
      'Neither Thanx nor Stripe will have any liability to you in connection with your purchase of Merchant Products or redemption of Redemption Codes, including, without limitation, in connection with:',
    any_goods: 'any goods or services associated with a Thanx Exclusive Deal,',
    redemption:
      'honoring Redemption Codes, delivering Merchant Products, or fulfilling a Thanx Exclusive Deal,',
    action:
      'any action or inaction by Stripe or the merchant whose goods or services are the subject of a Thanx Exclusive Deal.',
    failure:
      'the failure of Thanx or Stripe to provide any notifications or engage in any monitoring described herein,',
    honor:
      'to honor or refund the Thanx Offer, or to provide any updates about claimed or redeemed Thanx Exclusive Offers.',
    exclusively:
      'This means that any liability regarding a Thanx Exclusive Offer/Deal lies exclusively with the Thanx Network Merchant participating Merchant.',
  },
  statement: {
    title: 'STATEMENT CREDITS',
    may_be:
      'You may be eligible to redeem Rewards for statement credits to eligible Linked Cards ("Statement Credits") in connection with some Merchant-specific deals delivered through your account (each, an "Offer"). You will earn Statement Credits for Qualifying Transactions, which are defined as transactions that satisfy all requirements specified in the terms of the individual Merchant Offers ("Offer Terms") and these Terms. The amount of Statement Credits earned on Qualifying Transactions as well as any additional terms and conditions of the Offer will be presented in the Offer Terms.',
    credits:
      'Statement Credits will not appear or be reflected on your transaction receipt from the Merchant at the time of purchase, and will instead be reflected on your periodic Linked Card statement. Subject to eligibility verification and settlement of the Qualifying Transaction, Statement Credits will typically appear on your Linked Card statement approximately 3-5 days after the final Qualifying Transaction, but may be subject to delays.  Statement Credits cannot be processed if your Linked Card number expires or changes while Statement Credit is pending and not settled, or your Linked Card account is not open or in good standing. You may not receive the value associated with the Reward if the applicable issuer does not post the Statement Credit. Not all transactions made with your Linked Card (including, but not limited to, PIN based transactions, payment through a third-party digital wallet or payment app) can be monitored for eligibility for earned Rewards. Do not use a PIN when making purchases if you want the transaction to be eligible as a Qualifying Transaction to earn Statement Credits.   If the transaction cannot be verified for eligibility with the Linked Card issuer, Thanx may be unable to issue a Statement Credit. Thanx, the applicable card network and your issuer will have no responsibility or liability for the failure of a Statement Credit to be posted. Neither Thanx nor the applicable card network is responsible for any finance or other charge, or impact on any Reward, feature, or term of your account resulting from the Statement Credit; your Linked Card issuer is responsible for all such charges or impacts. Redemption of Statement Credits is further subject to the terms and conditions of your Linked Card issuer.',
    no_event:
      '<b>In no event shall Thanx or any applicable card network constitute or be considered as maintaining any type of financial obligation or deposit or other asset account, or holding funds or other value for you for distribution to you. Any pending Statement Credits and any associated dollar values represent offer fulfillment amounts in process owed by the Merchant, and not your funds or balances maintained or held by any applicable card network or Thanx.</b>',
  },
  indemnification: {
    title: 'Indemnification',
    indemnify:
      "You agree to indemnify, defend and hold Thanx, its affiliated companies, its service providers and suppliers (including payment card networks and payment processors) and their respective officers, directors, employees, agents, successors and assigns harmless from and against all claims, liabilities, losses, expenses, damages, and costs, including but not limited to reasonable attorneys' fees, resulting from (1) a violation or breach of these Terms by you, (2) any activity related to access to or use of your account by you or any other person accessing or using your account, or (3) any activity related to your redemption of Rewards or Opportunities.",
  },
  disclaimer: {
    title: 'Disclaimer',
    as_is:
      'The Service is provided "AS IS" and on an "AS AVAILABLE" basis, without warranty of any kind. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THANX AND ITS SUPPLIERS (INCLUDING PAYMENT CARD NETWORKS AND PAYMENT PROCESSORS) EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. We do not warrant that the use or quality of the Site, App or the Service will be uninterrupted, error-free, timely, secure or meet your requirements or expectations.',
  },
  limitation: {
    title: 'Limitation of Liability',
    maximum:
      'TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THANX, OUR SERVICE PROVIDERS OR SUPPLIERS (INCLUDING PAYMENT CARD NETWORKS AND PAYMENT PROCESSORS) BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR: LOSS OF PROFITS, LOSS OF CONFIDENTIAL OR OTHER INFORMATION, BUSINESS INTERRUPTION, PERSONAL INJURY, LOSS OF PRIVACY, LOSS OF DATA, FAILURE OF DELIVERY OF GOODS, FAILURE TO MEET ANY DUTY, NEGLIGENCE, AND ANY OTHER PECUNIARY OR OTHER LOSS WHATSOEVER ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF OR INABILITY TO USE THE SERVICE EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.',
    maximum_two:
      'TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL THANX OR OUR SUPPLIERS (INCLUDING PAYMENT CARD NETWORKS AND PAYMENT PROCESSORS) AGGREGATE LIABILITY, OR THAT OF ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF, OR OF THE INABILITY TO USE, THE SITE, APP AND/OR SERVICE, HOWSOEVER CAUSED, AND UNDER ANY THEORY OF LIABILITY, INCLUDING NEGLIGENCE, TORT, STRICT LIABILITY, BREACH OF CONTRACT OR OTHERWISE, EXCEED <b>$50</b>.',
  },
  exclusions: {
    title: 'Exclusions',
    some: 'Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.',
  },
  force: {
    title: 'Force Majeure',
    neither:
      'Neither Thanx nor its Suppliers (including payment card networks and payment processors) will be liable or responsible for any delays in providing the Site, App and/or Services, as a result of any event beyond its reasonable control, including, without limitation, adverse weather conditions, internet outage or interruption of service, telecommunications or power outage, fire, flood, civil disobedience, labor disruptions, strikes, lockouts, government-ordered closures, pandemic, epidemic, freight, embargoes, terrorism, natural disaster, war or acts of God.',
  },
  general: {
    title: 'General',
    terms:
      'These Terms are governed by the laws of the State of California, excluding its conflicts of laws principles. Any claim or dispute between us arising out of the Services will be decided exclusively in the federal and/or state courts for Santa Clara County, California. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service. We may revise these Terms from time to time, and we will try to provide at least 30 days notice prior to any new terms taking effect. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.',
  },
  questions: {
    title: 'Questions',
    have: '<strong>If you have any questions about these Terms, please contact us at </strong><a href="mailto:support@thanx.com">support@thanx.com</a><strong>.</strong>',
    last: 'Last Updated: March 14, 2022',
  },
  copyright: '%{year} Thanx',
}

export default thanx
