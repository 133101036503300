import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import Review from 'scenes/CampaignCenter/Builder/Steps/Shared/Review'
import Audience from './Audience'
import RetargetFrequencyField from './RetargetFrequencyField'

type Props = {
  campaign: Campaign
  setCurrentStep: (step: string) => void
  isSaving: boolean
}

const t = buildTranslate('thanx_campaigns.customAutomated.review.schedule')

const CustomAutomatedReview = (props: Props) => {
  const { campaign, setCurrentStep, isSaving } = props
  const onEdit = () => setCurrentStep('audience')
  return (
    <Review
      campaign={campaign}
      setCurrentStep={setCurrentStep}
      audience={
        <Audience
          campaign={campaign}
          onEdit={onEdit}
          isEditable={!!setCurrentStep}
          isSaving={isSaving}
        />
      }
      shouldShowEndDate
    >
      <RetargetFrequencyField
        name="retarget_frequency"
        value={campaign.retarget_frequency}
        className="mb-xl"
      />

      <Text.BodyText4 tag="div" color="grey70" className="mb-xl">
        {t('bullets.manualStop')}
      </Text.BodyText4>
    </Review>
  )
}

export default CustomAutomatedReview
