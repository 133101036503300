import { Fields as PointsExperience } from 'models/PointsExperience'

import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

import type { ExpirationTypes } from 'models/PointsExperience'

export const GET = 'pointsExperience/GET'
export const GET_SUCCESS = 'pointsExperience/GET_SUCCESS'
export const GET_FAIL = 'pointsExperience/GET_FAIL'

export const GET_ALL = 'pointsExperience/GET_ALL'
export const GET_ALL_SUCCESS = 'pointsExperience/GET_ALL_SUCCESS'
export const GET_ALL_FAIL = 'pointsExperience/GET_ALL_FAIL'

export const UPDATE = 'pointsExperience/UPDATE'
export const UPDATE_SUCCESS = 'pointsExperience/UPDATE_SUCCESS'
export const UPDATE_FAIL = 'pointsExperience/UPDATE_FAIL'

export function getPointsExperiences(): AxiosAction<
  typeof GET_ALL,
  { points_experiences: PointsExperience[] }
> {
  return {
    type: GET_ALL,
    payload: {
      client: 'default',
      request: {
        url: 'points_experiences',
        method: 'get',
      },
    },
  }
}

export function getPointsExperience(
  id: string | number
): AxiosAction<typeof GET, { points_experience: PointsExperience }> {
  return {
    type: GET,
    payload: {
      client: 'default',
      request: {
        url: `points_experiences/${id}`,
        method: 'get',
      },
    },
  }
}

export type PointsExperienceUpdates = {
  name: string
  currency_name: string
  currency_name_plural: string
  conversion_rate: number
  earn_image: number | null
  points_expires_in_months: number
  expiration_type: ExpirationTypes
}
export function updatePointsExperience(
  id: string | number,
  data: Partial<PointsExperienceUpdates>
): AxiosAction<typeof UPDATE, { points_experience: PointsExperience }> {
  return {
    type: UPDATE,
    payload: {
      client: 'default',
      request: {
        url: `points_experiences/${id}`,
        method: 'patch',
        data: {
          points_experience: data,
        },
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getPointsExperiences>>
  | ResolvedAxiosAction<ReturnType<typeof getPointsExperience>>
  | ResolvedAxiosAction<ReturnType<typeof updatePointsExperience>>
