import { useState } from 'react'

import { locationsApi } from 'api/locations'
import usePaginateArray from 'hooks/usePaginateArray'
import { buildTranslate } from 'locales'

import { Text } from '@thanx/uikit/text'
import SearchablePaginatedContent from 'components/SearchablePaginatedContent'
import Table from 'scenes/Locations/Landing/Table'

import type { Fields as Location } from 'models/Location'

const t = buildTranslate('locations.location_list')

const Content = () => {
  const [searchValue, setSearchValue] = useState('')
  const { data: locationsData, isLoading: locationsLoading } =
    locationsApi.useGetLocationsQuery({ full_visibility: true })
  const unfilteredLocations = locationsData?.locations || []

  const searchIncludesLocation = (location: Location) => {
    const { name, city, street, state, zip, phone } = location
    const searchableParams = [name, city, street, state, zip, phone]
    const searchString = searchableParams.join('').toLowerCase()

    return searchString.includes(searchValue.trim().toLowerCase())
  }

  const locations = searchValue
    ? unfilteredLocations.filter(searchIncludesLocation)
    : unfilteredLocations
  const {
    currentPage,
    setPage,
    paginatedData: paginatedLocations,
    totalPages,
  } = usePaginateArray(locations, 10)

  const handleSearchInputChange = (value: string) => {
    setSearchValue(value)
    setPage(1) // Reset pagination when search changes
  }

  return (
    <div className="mb-l">
      <div className="d-flex">
        <div className="mb-xl">
          <Text.BodyText2 tag="p" className="m-0" bold>
            {t('list_title')}
          </Text.BodyText2>
        </div>
      </div>
      <SearchablePaginatedContent
        contentIsLoading={locationsLoading}
        onChangePage={page => setPage(page)}
        onChangeSearchInput={handleSearchInputChange}
        currentPage={currentPage}
        numberOfPages={totalPages}
        searchPlaceholder={t('search_placeholder')}
      >
        <Table locations={paginatedLocations} isLoading={locationsLoading} />
      </SearchablePaginatedContent>
    </div>
  )
}

export default Content
