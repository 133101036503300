import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Info from 'components/Info'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { CouponPoolGenerationType } from 'models/CouponPool'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  getInstoreRedemptionType,
  isDirectLoyaltyIntegrationMerchant,
} from 'scenes/RedeemManager/helpers'
import { selectCouponPoolsByTemplateId } from 'selectors/couponPool'
import Card from './Card'
import CouponCodes from './CouponCodes'
import DynamicCouponsInfo from './DynamicCouponsInfo'

type PropsT = {
  template: RedeemTemplate
}

const t = buildTranslate('redeem_manager.detail.cards.instore_redemption')

const InstoreRedemptionCard: React.FC<PropsT> = ({ template }) => {
  const { redemption_venue, uses_coupon_codes } = template
  const isAvailable =
    redemption_venue === 'instore' || redemption_venue === 'all'
  const isExperience = template.type.includes('experience')
  const isGoldenTicket = template.type === 'experience/golden_ticket'
  const redemptionType = getInstoreRedemptionType(template)
  const couponPools = useSelector(state =>
    selectCouponPoolsByTemplateId(state, template.id)
  )
  const couponPool = !!couponPools?.length
    ? couponPools[couponPools.length - 1]
    : null

  const merchant = useCurrentMerchant()
  const hasDirectLoyaltyIntegration =
    isDirectLoyaltyIntegrationMerchant(merchant)

  return (
    <Card title={t('title')} info={t('info')} isActive={isAvailable}>
      <Switch
        condition={isAvailable}
        fallback={<p className="grey-70">{t('not_available')}</p>}
      >
        <Switch
          condition={!uses_coupon_codes}
          fallback={
            <>
              {couponPool?.generation_type ===
              CouponPoolGenerationType.DYNAMIC ? (
                <DynamicCouponsInfo
                  template={template}
                  isDirect={hasDirectLoyaltyIntegration}
                />
              ) : (
                <CouponCodes template={template} displayUploadForm />
              )}
            </>
          }
        >
          <Info
            label={t('redemption')}
            value={
              redemptionType === 'automatic_cash_back' ? (
                t('automatic_cash_back')
              ) : (
                <>
                  <p className="grey-70 m-0">{t('manager_comp')}</p>
                  <p className="grey-70 m-0">{t('manager_comp_info')}</p>
                  <Switch condition={isExperience}>
                    <p className="mt-m">
                      <p className="mb-m">
                        <Text.BodyText4
                          bold
                          color="grey90"
                          tag="p"
                          className="mb-xs"
                        >
                          {t('redemption_image.title')}
                        </Text.BodyText4>
                        <Switch
                          condition={!!template.images?.detail_image?.small}
                          fallback={
                            <Text.BodyText3 color="grey70">
                              {t('redemption_image.no_image')}
                            </Text.BodyText3>
                          }
                        >
                          <img
                            src={template.images?.detail_image?.small}
                            alt={template.description!}
                          />
                        </Switch>
                      </p>
                      <Switch condition={isGoldenTicket}>
                        <Text.BodyText4
                          bold
                          color="grey90"
                          tag="p"
                          className="mb-xs"
                        >
                          {t('url.title')}
                        </Text.BodyText4>
                        <Text.BodyText3 color="grey70">
                          {template.url ? template.url : t('url.no_url')}
                        </Text.BodyText3>
                      </Switch>
                    </p>
                  </Switch>
                </>
              )
            }
          />
        </Switch>
      </Switch>
    </Card>
  )
}

export default InstoreRedemptionCard
