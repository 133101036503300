import React from 'react'
import { Translate } from 'react-redux-i18n'

type Props = {
  className?: string
  isSaving: boolean
  value: string
}

const ContentWithSave: React.FC<Props> = props => {
  const { isSaving, value, className = '' } = props

  return (
    <span className={`relative ${className}`}>
      <Translate
        value={value}
        className={isSaving ? 'invisible' : ''}
        dangerousHTML
      />
      <Translate
        value="thanx_campaigns.builder.steps.nav.buttons.saving"
        className={`absolute full-width left-0 ${isSaving ? '' : 'invisible'}`}
        dangerousHTML
      />
    </span>
  )
}

export default ContentWithSave
