import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type ManualTypes = {
  campaignId: number
}

const ManualItem: React.FC<ManualTypes> = props => {
  const { campaignId } = props

  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'unscoped_redeem')
    ) as RedeemMetric) || {}
  const primaryValue = currencyFormatter(metric?.data?.total_cost, false)
  const secondaryValue = t('rewards_accounting.per_reward', {
    value: currencyFormatter(metric?.data?.average_cost),
  })

  return (
    <InfoRow
      title={t('rewards_accounting.cogs')}
      primaryContent={
        <Metric metric={primaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('rewards_accounting.tooltips.number_of_cogs'),
              },
              {
                title: t('how_its_calc'),
                content: t('rewards_accounting.tooltips.calc_of_number_cogs'),
              },
              {
                title: t('example'),
                content: t(
                  'rewards_accounting.tooltips.number_cogs_example_content'
                ),
              },
            ]}
          />
        </Metric>
      }
      secondaryContent={
        <Metric primary={false} metric={secondaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('rewards_accounting.tooltips.per_of_cogs'),
              },
              {
                title: t('how_its_calc'),
                content: t('rewards_accounting.tooltips.calc_of_per_cogs'),
              },
              {
                title: t('example'),
                content: t(
                  'rewards_accounting.tooltips.per_cogs_example_content'
                ),
              },
            ]}
          />
        </Metric>
      }
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default ManualItem
