import { useState } from 'react'

const usePaginateArray = <T>(data: Array<T>, itemsPerPage: number) => {
  const [currentPage, setCurrentPage] = useState(1)

  const totalPages = Math.ceil(data.length / itemsPerPage)

  const setPage = page => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const paginatedData = data.slice(startIndex, endIndex)

  return {
    currentPage,
    setPage,
    paginatedData,
    totalPages,
  }
}

export default usePaginateArray
