import { Switch } from '@thanx/uikit/switch'
import { alert } from 'actions/flash'
import {
  MainLinkData,
  updateLandingLinks,
  UPDATE_LANDING_LINKS_FAIL,
} from 'actions/landingLink'
import { getValidation } from 'actions/validations'
import ConfirmModal from 'components/ConfirmModal'
import { ImagePickerValue } from 'components/ImagePicker'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import uniqueId from 'lodash/uniqueId'
import { LandingLinkSection } from 'models/LandingLink'
import { ValidationTypes } from 'models/Validation'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Blocks from 'scenes/Cms/components/Blocks'
import CmsPage from 'scenes/Cms/components/CmsPage'
import DraftBanner from 'scenes/Cms/components/DraftBanner'
import PublishBanner from 'scenes/Cms/components/PublishBanner'
import { UrlValue } from 'scenes/Cms/components/UrlPicker'
import { getUrlValue } from 'scenes/Cms/helper'
import { selectApp } from 'selectors/app'
import { selectMainLinks } from 'selectors/landingLink'
import { selectLandingPage } from 'selectors/landingPage'
import BlockItem from './BlockItem'
import Sidebar from './Sidebar'

export enum UrlType {
  External = 'external',
  Inapp = 'inapp',
}

export interface BlockData {
  url: UrlValue
  image: ImagePickerValue
}

interface FormModel {
  [id: string]: BlockData
}

const NEW_BLOCK_DATA = {
  url: {
    inapp: '',
    external: '',
    type: UrlType.External,
    authenticable: false,
  },
  image: {
    id: null,
    url: '',
    uploading: false,
  },
}

const t = buildTranslate('cms.content.content_blocks')

const ContentBlocks: React.FC = () => {
  const dispatch = useDispatch()
  const links = useSelector(selectMainLinks)
  const app = useSelector(selectApp)
  const [formModel, setFormModel] = useState<FormModel>(resetForm(links))

  // order is not a part of the FormModel objects as FormModel is directly
  // overwritten by Formsy onChange method.
  const [order, setOrder] = useState<string[]>(sortForm(formModel, links))
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const landingPage = useSelector(selectLandingPage)
  const isDraft = landingPage.state === 'inactive'

  useEffect(() => {
    async function fetchImageValidation() {
      setIsLoading(true)
      await dispatch(getValidation({ type: ValidationTypes.AppLinkUnpressed }))
      setIsLoading(false)
    }

    fetchImageValidation()
  }, [dispatch])

  function onNewBlock() {
    const newId = uniqueId('content-block-')
    setFormModel({
      ...formModel,
      [newId]: {
        ...NEW_BLOCK_DATA,
      },
    })
    setOrder([...order, newId])
  }

  function resetForm(newLinks): FormModel {
    if (newLinks.length === 0) {
      return {
        [uniqueId('content-block-')]: {
          ...NEW_BLOCK_DATA,
        },
      }
    }

    return newLinks.reduce((acc, obj) => {
      const { id, url, unpressed_image_urls, authenticable } = obj

      acc[id] = {
        url: getUrlValue(url, authenticable),
        image: {
          id: null,
          url: unpressed_image_urls?.small,
          uploading: false,
        },
      }
      return acc
    }, {})
  }

  function sortForm(form: FormModel, newLinks): string[] {
    if (newLinks.length === 0) return Object.keys(form)
    return newLinks.sort((a, b) => a.position - b.position).map(l => `${l.id}`)
  }

  async function handleSubmit() {
    const data: MainLinkData[] = []
    order.forEach((orderId, index) => {
      const { url, image } = formModel[orderId]
      const link = links.find(l => `${l.id}` === orderId)

      data.push({
        id: link?.id,
        position: index + 1,
        url: url.type === UrlType.External ? url.external : url.inapp,
        section: LandingLinkSection.Main,
        file_uploads: image?.id
          ? {
              unpressed_image: image.id,
            }
          : undefined,
      })
    })

    // destroy links which are deleted
    links.forEach(link => {
      // a link is deleted if it doesn't exist on the order array anymore
      if (order.indexOf(`${link.id}`) === -1) {
        data.push({
          ...link,
          section: LandingLinkSection.Main,
          destroy: true,
        })
      }
    })

    setIsSubmitting(true)
    const response = await dispatch(updateLandingLinks(app.id, data))
    setIsSubmitting(false)
    setIsModalOpen(false)
    if (response.type === UPDATE_LANDING_LINKS_FAIL) {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
          timeout: 5,
        })
      )
      return
    }

    const newlinks = response.payload.data.links.filter(
      link => link.section === LandingLinkSection.Main
    )
    if (newlinks.length > 0) {
      setFormModel({})
      setOrder([])

      const newForm = resetForm(newlinks)
      const newOrder = sortForm(newForm, newlinks)

      setFormModel(newForm)
      setOrder(newOrder)
    }

    dispatch(
      alert({
        key: 'success',
        message: isDraft ? t('submit_draft_success') : t('submit_success'),
        timeout: 5,
      })
    )
  }

  let imageUploading = false
  order.forEach(orderId => {
    if (formModel[orderId]?.image?.uploading) imageUploading = true
  })

  return (
    <Formsy
      onChange={newFormModel => setFormModel(newFormModel)}
      onValidSubmit={() => (isDraft ? handleSubmit() : setIsModalOpen(true))}
      onInvalidSubmit={() =>
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('cms.content.form_error'),
            timeout: 5,
          })
        )
      }
    >
      <CmsPage
        className="mr-m"
        title={t('title')}
        description={t('description')}
        badges={[
          t('badges.app_only'),
          t('badges.wide_1800_px'),
          t('badges.jpg'),
          t('badges.png'),
        ]}
        sidebar={<Sidebar isLoading={isSubmitting} disabled={imageUploading} />}
        isLoading={isLoading}
      >
        <Blocks
          type="block"
          order={order}
          setOrder={setOrder}
          renderItem={(itemId, index) => (
            <Switch condition={!!formModel[itemId]}>
              <BlockItem
                key={itemId}
                index={index}
                id={itemId}
                data={formModel[itemId]}
              />
            </Switch>
          )}
          onNewBlock={onNewBlock}
        />
        <Switch
          condition={isDraft}
          fallback={
            <PublishBanner
              buttonText={t('publish')}
              isLoading={isSubmitting}
              disabled={imageUploading}
            />
          }
        >
          <DraftBanner isLoading={isSubmitting} disabled={imageUploading} />
        </Switch>
      </CmsPage>
      <ConfirmModal
        title={t('modal.publish_changes')}
        description={t('modal.you_are_publishing')}
        confirmText={t('modal.publish')}
        isOpen={isModalOpen}
        isLoading={isSubmitting}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => handleSubmit()}
      />
    </Formsy>
  )
}

export default ContentBlocks
