import { getPurchaseDetails } from 'actions/orders'
import { updatePaginationCurrentPage } from 'actions/pagination'
import { getUserEvents } from 'actions/users'
import useDispatch from 'hooks/useDispatch'
import { Fields as UserEvent } from 'models/UserEvent'
import { Fields as UserProfile } from 'models/UserProfile'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPagination } from 'selectors/pagination'
import { selectUserEvents } from 'selectors/userEvent'

export const useUserEventsTable = (profile: UserProfile) => {
  const dispatch = useDispatch()
  const [isFetching, setIsFetching] = useState(false)

  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'user_event', profile.uid)
  )

  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const eventIds = paginationInfo?.record_ids?.[currentPage] ?? []
  const userEvents = (
    useSelector(state => selectUserEvents(state, profile.id)) ?? []
  ).filter(event => eventIds.includes(event.id))

  const fetchEvents = useCallback(
    async (page: number) => {
      setIsFetching(true)
      const params: Parameters<typeof getUserEvents>[1] = {
        page,
      }
      await dispatch(getUserEvents(profile.uid, params))
      setIsFetching(false)
    },
    [dispatch, profile.uid]
  )

  const onChangePage = useCallback(
    async (page: number) => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // events for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'user_event',
            id: profile.uid,
            page,
          })
        )
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      fetchEvents(page)
    },
    [dispatch, paginationInfo, fetchEvents, profile.uid]
  )

  const fetchOrder = async (event: UserEvent) => {
    const result = await dispatch(getPurchaseDetails(event.related_id))
    if (!result.error) {
      return result.payload.data.purchase
    }
  }

  useEffect(() => {
    onChangePage(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangePage])

  return {
    isFetching,
    onChangePage,
    currentPage,
    numPages,
    userEvents,
    fetchOrder,
  }
}
