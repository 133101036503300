import { DateRangeFilterContext } from 'components/DateFilter/DateFilterContext'
import palette from 'constants/palette'
import Highcharts, { SeriesColumnOptions } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { MinimalVariant } from 'models/Campaign'
import { EventsMetric } from 'models/CampaignMetric'
import React, { useContext, useMemo, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { getXAxisCategories } from 'scenes/CampaignCenter/Report/v3/helpers'
import {
  getDateLabel,
  getFormattedDate,
  getMomentFromSeriesCategory,
} from 'utilities/date'
import { numberFormatter } from 'utilities/formatters'
import { createPlotlines, getSeries } from './helpers'

type PropsT = {
  metrics: EventsMetric[]
  t: any
  startAt: string
  endAt: string | null
  timezone: string
  testEndedAt: string | null
  variants: MinimalVariant[]
}

const ColumnChart: React.FC<PropsT> = ({
  metrics,
  t,
  startAt,
  endAt,
  timezone,
  testEndedAt,
  variants,
}) => {
  const [isUpdateAllowed, setIsUpdateAllowed] = useState(false)
  const chartRef = useRef()
  const { filter } = useContext(DateRangeFilterContext)

  const tooltipFormatter = (series_id, x, y) => {
    const date = getFormattedDate(
      getMomentFromSeriesCategory(x, granularity, timezone),
      granularity
    )
    // <Text> would be ideal here but it doesn't seem to play nice with renderToString
    return renderToString(
      <>
        <div className="mb-xs grey-70 font-size-14">{date}</div>
        <h3 className="m-0">{numberFormatter(y)}</h3>
        <div
          className="grey-70 font-size-14"
          dangerouslySetInnerHTML={{
            __html: t(`tooltip_${series_id}`, { count: y }),
          }}
        />
      </>
    )
  }

  const granularity = metrics[0]?.granularity ?? 'daily'
  const categories: string[] = useMemo(
    () => getXAxisCategories(metrics[0]?.data?.[0]?.values, filter, timezone),
    [metrics, filter, timezone]
  )
  const series: Highcharts.SeriesColumnOptions[] = useMemo(() => {
    if (metrics.length < 1) return []

    const series = metrics.map(
      metric => getSeries(metric, t, categories) as SeriesColumnOptions
    )

    setIsUpdateAllowed(true)

    return series
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics, t, categories])

  const plotLines: Highcharts.XAxisPlotLinesOptions[] = useMemo(() => {
    if (metrics.length < 1 || variants?.length < 2) return []

    // Plot lines to show when the test started and ended if there are multiple variants
    return createPlotlines(
      categories,
      startAt,
      testEndedAt || endAt,
      timezone,
      metrics[0].granularity,
      t
    )
  }, [
    metrics,
    categories,
    startAt,
    variants?.length,
    testEndedAt,
    endAt,
    timezone,
    t,
  ])

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Lato',
        overflow: 'visible !important',
      },
      ignoreHiddenSeries: true,
    },
    title: undefined,
    credits: { enabled: false },
    tooltip: {
      formatter: function () {
        return tooltipFormatter(
          this.series.userOptions.id,
          this.point.category,
          this.y
        )
      },
      backgroundColor: palette.white,
      borderWidth: 1,
      borderColor: palette.grey20,
      borderRadius: 4,
      outside: true,
      useHTML: true,
      shadow: false,
      padding: 16,
    },
    xAxis: {
      categories,
      title: {
        text: t('date'),
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      labels: {
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject
        ) {
          return getDateLabel(String(this.value), granularity, timezone)
        },
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
      tickLength: 0,
      lineColor: palette.grey30,
      plotLines,
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      softMax: 1,
      title: {
        text: t('activity'),
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      gridLineDashStyle: 'LongDash',
      gridLineColor: palette.grey40,
      labels: {
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
    },
    series,
    legend: {
      align: 'left',
      symbolRadius: 0,
      x: 30,
      itemStyle: {
        color: palette.grey90,
        fontSize: '14px',
      },
    },
  }

  if (!isUpdateAllowed) {
    return null
  }
  return (
    <div className="mr-m">
      <HighchartsReact
        allowChartUpdate={isUpdateAllowed}
        highcharts={Highcharts}
        options={options}
        immutable={false}
        ref={chartRef}
      />
    </div>
  )
}

export default ColumnChart
