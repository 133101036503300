import { Button, Kind } from '@thanx/uikit/button'
import { FormGroup } from '@thanx/uikit/form-group'
import { Text } from '@thanx/uikit/text'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import React, { useState } from 'react'
import DatePicker from 'react-16-bootstrap-date-picker'
import { InputGroup } from 'react-bootstrap'
import { textUtils } from 'utilities/textUtils'

type Props = {
  label: React.ReactNode
  addText: string
  timeText?: string
  value: string
  minDate?: string | null
  maxDate?: string | null
} & PassDownProps<string>

const addTimeToDate = (date?: string | null) => {
  if (!date) {
    return date
  }
  return `${date}T12:00:00.000Z`
}

const LinkButton = ({ children, onClick }) => (
  <Button
    kind={Kind.MINIMAL}
    size="small"
    onClick={onClick}
    className={`font-weight-normal px-0 border-0`}
  >
    {children}
  </Button>
)

const DateField = (props: Props) => {
  const {
    label,
    addText,
    timeText,
    value,
    setValue,
    isPristine,
    errorMessages,
    showError,
    minDate,
    maxDate,
  } = props
  const [visible, setVisible] = useState(value !== '')
  const onChange = (value: string) => {
    setValue(value.split('T')[0])
  }

  const onRemove = () => {
    setVisible(false)
    onChange('')
  }

  const className =
    showError && !isPristine ? 'form-group has-error' : 'form-group'

  return (
    <FormGroup label={label} className={`mt-s ${className}`}>
      {!visible && (
        <LinkButton onClick={() => setVisible(true)}>{addText}</LinkButton>
      )}
      {visible && (
        <>
          <div className="inline-block" style={{ width: '220px' }}>
            <InputGroup
              name={props.name}
              className="padding-top-small datepicker"
            >
              <DatePicker
                value={value}
                showClearButton={false}
                minDate={addTimeToDate(minDate)}
                maxDate={addTimeToDate(maxDate)}
                onChange={onChange}
              />
              <InputGroup.Addon>
                <i className="fa fa-calendar" />
              </InputGroup.Addon>
            </InputGroup>
            {timeText && (
              <div>
                <Text.BodyText5 color="grey70">{timeText}</Text.BodyText5>
              </div>
            )}
            <div>
              <LinkButton onClick={onRemove}>Remove</LinkButton>
            </div>
          </div>

          <span className="text-danger error-message block">
            {textUtils.initialCapital(
              textUtils.toSentence({
                array: (isPristine ? [] : errorMessages) as string[],
                punctuation: '',
              })
            )}
          </span>
        </>
      )}
    </FormGroup>
  )
}
export default withFormsy(DateField)
