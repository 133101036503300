import { buildTranslate } from 'locales'
import React from 'react'
import { useAbility } from 'utilities/ability'
import NavLink from '../Bar/NavLink'

const t = buildTranslate('nav.rewards_menu')

type PropsT = {
  isLeftNav?: boolean
  onClick?: () => void
}

const RewardsContent: React.FC<PropsT> = ({
  isLeftNav = false,
  onClick = () => {},
}) => {
  const ability = useAbility()

  return (
    <>
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('rewards')}
        link="/rewards"
        disabled={ability.cannot('access', 'RedeemManager')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        link={'/reports/reward_activity'}
        text={t('reward_activity')}
        disabled={ability.cannot('access', 'LocationReports')}
      />
    </>
  )
}

export default RewardsContent
