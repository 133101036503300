import { Button, Kind, Size } from '@thanx/uikit/button'
import { pointsApi, PointsMultiplier } from 'api/points'
import ModalContainer from 'components/ModalContainer'
import PopoverMenu, { PopoverOption } from 'components/PopoverMenu'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import { useState } from 'react'
import { push } from 'react-router-redux'

type Props = {
  pointsMultiplier: PointsMultiplier
  pointsExperience: PointsExperience
}

const t = buildTranslate('points.configuration.multipliers')

function ActionsNav(props: Props) {
  const { pointsMultiplier, pointsExperience } = props

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [deleteMultiplier, { isLoading }] =
    pointsApi.useDeleteMultiplierMutation()
  const dispatch = useDispatch()

  const handleEdit = () => {
    dispatch(
      push(
        `/points/experiences/${pointsExperience.id}/multipliers/${pointsMultiplier.id}/edit`
      )
    )
  }
  const handleDelete = () => {
    if (!pointsMultiplier.id) {
      return false
    }
    deleteMultiplier(pointsMultiplier.id)
    setIsConfirmationModalOpen(false)
    return true
  }

  return (
    <>
      <div className="d-flex justify-content-between mr-xs">
        <PopoverMenu
          dismissOnClickOutside
          placement="bottomRight"
          testId={`popover-menu-${pointsMultiplier.id}`}
        >
          <PopoverOption onClick={() => setIsConfirmationModalOpen(true)}>
            {t('list.remove')}
          </PopoverOption>
        </PopoverMenu>
        <Button
          kind={Kind.SECONDARY}
          size={Size.SMALL}
          className={`ml-s`}
          onClick={handleEdit}
        >
          {t('edit')}
        </Button>
      </div>

      <ModalContainer
        title={t('delete_confirmation.title')}
        subtitle={t('delete_confirmation.subtitle')}
        proceedText={t('delete_confirmation.button')}
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onProceed={handleDelete}
        canProceed={!isLoading}
      />
    </>
  )
}

export default ActionsNav
