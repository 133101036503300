import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import PopoverContent, { Sections } from 'components/PopoverContent'
import React from 'react'
import FormsyEnabledButton from './FormsyEnabledButton'

type Props = {
  className?: string
  title: string
  subtitle?: string
  infoSections?: Sections
  isEnabled?: boolean
  isOpen: boolean
  buttonName?: string
  disableToggleButton?: boolean
  children: React.ReactNode
  onToggle?: (value: boolean) => void
}

const Card: React.FC<Props> = ({
  className = 'mb-m',
  title,
  subtitle,
  infoSections,
  isEnabled = true,
  isOpen,
  buttonName,
  disableToggleButton,
  children,
  onToggle,
}) => {
  const isActive = isOpen && isEnabled
  return (
    <div
      className={`card ${className} p-m ${
        isActive ? 'white-bkg' : 'grey-10-bkg'
      }`}
    >
      <div
        className={`d-flex align-items-center ${
          isActive && !subtitle ? 'mb-s' : ''
        }`}
      >
        <h4 className="extra-bold mr-xs">{title}</h4>
        <Switch condition={isOpen}>
          {!!infoSections && <PopoverContent sections={infoSections} />}
          {buttonName && (
            <div className="d-flex flex-1 justify-content-end">
              <FormsyEnabledButton
                name={buttonName}
                onToggle={onToggle}
                value={isEnabled}
                disabled={disableToggleButton}
              />
            </div>
          )}
        </Switch>
      </div>
      <Switch condition={!!subtitle && isOpen && isEnabled}>
        <div className={isActive ? 'mb-s' : ''}>
          <Text.BodyText4 color="grey70">{subtitle}</Text.BodyText4>
        </div>
      </Switch>
      <Switch condition={isOpen}>{children}</Switch>
    </div>
  )
}

export default Card
