import { RouteComponentProps } from 'react-router-dom'
import BrandedTerms from './BrandedTerms'
import SusieCakesTerms from './SusieCakesTerms'
import ThanxTerms from './ThanxTerms'

type RouteParams = {
  handle?: string
}

type Props = RouteComponentProps<RouteParams>

const customHandles = {
  susiecakes: <SusieCakesTerms />,
  thanx: <ThanxTerms />,
}

function Terms(props: Props) {
  const handle = props.match?.params?.handle

  if (!handle) {
    return <ThanxTerms />
  }

  if (customHandles[handle]) {
    return customHandles[handle]
  }

  return <BrandedTerms handle={handle} />
}

export default Terms
