export default {
  form: {
    restrictions: 'Restrictions',
    date_range: {
      title: 'Date range this multiplier applies',
      disabled: {
        title: 'Any time',
        text: 'No date range restriction. The multiplier starts immediately and is applicable indefinitely.',
      },
      enabled: {
        title: 'Only during a specific date range',
        text: 'If a multiplier is scheduled for the future, customers will see it listed with a coming soon badge, however it will not apply to purchases before your start date.',
      },
      starts_on: {
        add: 'Add start date',
        label: 'Start date (optional)',
        time_text: 'At 12:00 AM PST',
        help: [
          'You don’t need to add a start date',
          "Adding a start date that's in the future means the multiplier won’t apply to orders until that date.",
        ],
      },
      ends_on: {
        add: 'Add end date',
        label: 'End date (optional)',
        time_text: 'At 11:59 PM PST',
        help: [
          'Add an end date to schedule when this multiplier should automatically stop applying to orders.',
          'Without an end date, this multiplier will apply indefinitely until you manually remove it.',
        ],
      },
    },
    help_title: 'Help',
    factor: {
      title: 'Multiplier amount',
    },
    validation: {
      less_than_two_decimal: 'the value must be less than 3 decimal places',
    },
    configuration: 'Configuration',
    configuration_footer:
      'You can configure fractional multipliers up to two decimal (1.25, 1.5, 2, etc)',
  },
  layout: {
    title: 'Configuration',
    page_header: 'Multiplier',
    exit: 'Exit without saving',
    publish: 'Publish',
  },
  new_multiplier: {
    page_title: 'New Multiplier - Points | Thanx',
    error_generic: 'An error happenened while trying to create the multiplier.',
    confirmation: {
      title: 'Turn on this multiplier for customers',
      subtitle:
        'Customers will receive this multiplier in your app and web experience going forward. If a multiplier is scheduled for the future, customers will see it listed with a coming soon badge (it will not apply to purchases before your start date). You can remove this multiplier later if you change your mind.',
      proceed_text: 'Start',
    },
  },
  edit_multiplier: {
    page_title: 'Edit Multiplier - Points | Thanx',
    error_generic: 'An error happenened while trying to update the multiplier.',
    confirmation: {
      title: 'Publish this change',
      subtitle:
        'Publishing this change will immediately update the consumer experience where this multiplier is already visible.',
      proceed_text: 'Publish',
    },
  },
}
