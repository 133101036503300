import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { CampaignStateT, MinimalVariant } from 'models/Campaign'
import React, { useState } from 'react'
import { push } from 'react-router-redux'
import WinnerModal from 'scenes/CampaignCenter/Report/v3/Test/components/WinnerModal'

const t = buildTranslate('thanx_campaigns.report.v3.test')

type Props = {
  campaignId: number
  campaignState: CampaignStateT
  winningVariantId: number | null
  variants: MinimalVariant[]
}
const Footer: React.FC<Props> = ({
  campaignId,
  campaignState,
  winningVariantId,
  variants,
}) => {
  const [css] = useStyletron()
  const [showModal, setShowModal] = useState(false)
  const dispatch = useDispatch()
  let title
  let subtitle
  let buttonText
  let onClick = () => {}
  if (campaignState === 'scheduled') {
    return null
  } else if (campaignState === 'complete' || !!winningVariantId) {
    title = t('another_variant')
    subtitle = t('another_variant_subtitle')
    buttonText = t('another_button')
    onClick = () => {
      dispatch(push(`/thanx_campaigns/automated`))
    }
  } else if (campaignState === 'active') {
    title = t('end_this_test')
    buttonText = t('end')
    onClick = () => {
      setShowModal(true)
    }
  }
  return (
    <div
      className={`p-l belize-hole-bkg mb-l ${css({
        borderRadius: '4px',
      })}`}
    >
      <Text.Header3 color="white" tag="div">
        {title}
      </Text.Header3>
      <Switch condition={!!subtitle}>
        <Text.BodyText3 className="pt-xs" color="white" tag="div">
          {subtitle}
        </Text.BodyText3>
      </Switch>
      <div className="pt-m">
        <Button kind="primary_outline" onClick={onClick}>
          {buttonText}
        </Button>
      </div>
      <WinnerModal
        campaignId={campaignId}
        variants={variants}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  )
}

export default Footer
