'use strict'
var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i]
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
        }
        return t
      }
    return __assign.apply(this, arguments)
  }
exports.__esModule = true
exports.useTabsState =
  exports.TabsStateProvider =
  exports.TabsStateContext =
    void 0
var jsx_runtime_1 = require('react/jsx-runtime')
var react_1 = require('react')
exports.TabsStateContext = (0, react_1.createContext)(null)
var TabsStateProvider = function (props) {
  var initialValue = props.initialValue,
    children = props.children
  var _a = (0, react_1.useState)(initialValue),
    activeTab = _a[0],
    setActiveTab = _a[1]
  return (0, jsx_runtime_1.jsx)(
    exports.TabsStateContext.Provider,
    __assign(
      { value: { activeTab: activeTab, setActiveTab: setActiveTab } },
      { children: children }
    )
  )
}
exports.TabsStateProvider = TabsStateProvider
function useTabsState() {
  var context = (0, react_1.useContext)(exports.TabsStateContext)
  if (context === null) {
    var errorInfo = 'Must be used in a TabsStateProvider'
    throw errorInfo
  }
  return context
}
exports.useTabsState = useTabsState
