// TODO. Use css modules to get the sass variables
// when we eject or upgrade to CRAv2

export default {
  /* Deprecated */
  spearmint60: '#229D9D',

  nephritis: '#27AE60',
  nephritis10: '#E9F6EF',
  nephritis20: '#D6F5E4',
  wisteria: '#8e44ad',
  wisteria10: '#eff0ff',
  eggYolk: '#e7b41a',
  eggYolk10: '#FAF0D1',
  eggYolk50: '#E7B41A',
  orange: '#f39c12',
  belizeHole: '#0073bf',
  belizeHole10: '#e5f0f8',
  grey05: '#F8F9VA',
  grey10: '#f2f4f5',
  grey20: '#e9eaec',
  grey30: '#d6dade',
  grey40: '#c2c8ce',
  grey50: '#a4adb6',
  grey: '#a4adb6',
  grey70: '#6a747f',
  grey90: '#36424e',
  spearmint05: '#FAFEFE',
  spearmint10: '#E7F9F9',
  spearmint20: '#D0F3F2',
  spearmint30: '#89E0DF',
  spearmint50: '#13C1BF',
  spearmint50Hover: '#15D6D4',
  spearmint: '#13C1BF',
  cayenne10: '#fbdfd6',
  cayenne50: '#f26335',
  cayenne: '#f26335',
  white: '#ffffff',
  black: '#000000',
}
