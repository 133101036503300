import LookerLabs from 'components/Icons/LookerLabs'
import InfoBox from 'components/InfoBox'
import PageHeader from 'components/PageHeader'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import qs from 'qs'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import { history } from 'store'
import LookerIframe from './LookerIframe'

const t = buildTranslate('looker')

const LookerEmbed: React.FC = () => {
  const merchant = useCurrentMerchant()
  const path = history.location.pathname.slice(3)
  if (!merchant || !path) return null
  const title = t(`urls.${path}.title`) || t('urls.generic.title')
  const queryParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  })
  const requestedHeight = queryParams?.height

  return (
    <PageHeader title={title}>
      <div className="h-100 w-100 grey-05-bkg border-top-1 pb-xxl grey-20-border mt-l">
        <Container className="full-height">
          <InfoBox
            type="tip"
            title={t('info.title')}
            content={t('info.content')}
            className="my-xxl"
            iconOverride={<LookerLabs className="d-flex mt-xxs" />}
          />
          <LookerIframe
            path={path}
            merchant={merchant}
            height={requestedHeight ? `${requestedHeight}px` : undefined}
          />
        </Container>
      </div>
    </PageHeader>
  )
}

export default LookerEmbed
