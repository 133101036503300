import { getCampaigns } from 'actions/campaigns'
import { updatePaginationCurrentPage } from 'actions/pagination'
import { CampaignPaginationT } from 'models/Pagination'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPagination } from 'selectors/pagination'

export const useCampaigns = (category: 'automated' | 'targeted') => {
  const dispatch = useDispatch()
  const [isFetchingPage, setIsFetchingPage] = useState(false)
  const paginationId: CampaignPaginationT['id'] = `all-${category}`
  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'campaign', paginationId)
  )
  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const campaignIds = paginationInfo?.record_ids?.[currentPage] ?? []

  const fetchCampaigns = useCallback(
    async (page: number) => {
      setIsFetchingPage(true)
      await dispatch(getCampaigns({ category, page }))
      setIsFetchingPage(false)
    },
    [dispatch]
  )

  const onChangePage = useCallback(
    async page => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // campaigns for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'campaign',
            id: paginationId,
            page,
          })
        )
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      fetchCampaigns(page)
    },
    [dispatch, fetchCampaigns, paginationId, paginationInfo?.record_ids]
  )

  useEffect(() => {
    onChangePage(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangePage])

  return {
    isFetchingPage,
    currentPage,
    onChangePage,
    numPages,
    campaignIds,
  }
}
