import { MerchantUser, Role } from 'api/merchantUsers'
import React from 'react'
import { useStyletron } from 'styletron-react'
import { useIsMobile } from 'utilities/responsive'
import LocationList from './LocationList'
import RolesCard from './RolesCard'

type Props = {
  merchantUser: Partial<MerchantUser>
  onChange: (merchantUser: Partial<MerchantUser>) => void
}

const MerchantUserForm = ({ merchantUser, onChange }: Props) => {
  const [css] = useStyletron()
  const isMobile = useIsMobile()

  return (
    <div
      className={`d-flex full-width full-height ${css({
        flexWrap: isMobile ? 'wrap' : 'nowrap',
      })}`}
    >
      <div className="full-width full-height mr-m">
        <RolesCard
          role={merchantUser.role || Role.admin}
          onChange={role => onChange({ ...merchantUser, role })}
        />
      </div>
      <div className="full-width">
        <LocationList
          role={merchantUser.role || Role.admin}
          initialLocationIds={merchantUser.location_ids}
          onChange={location_ids => {
            onChange({ ...merchantUser, location_ids })
          }}
        />
      </div>
    </div>
  )
}

export default MerchantUserForm
