// @flow
import { createSelector } from 'redux-orm'
// $FlowFixMe
import orm from 'models/orm'
// $FlowFixMe
import type { State } from '../models/orm'
import type { EmailProviderT } from '../models/EmailProvider'

const getOrmState = ormState => ormState

export const selectEmailProviderLists = createSelector<State, EmailProviderT[]>(
  orm,
  ormState => ormState,
  session => {
    return session.EmailProviderList.all().toModelArray()
  }
)

export const selectEmailProviderListsByProviderId = createSelector<
  State,
  EmailProviderT[]
>(
  orm,
  getOrmState,
  (state, providerId) => providerId,
  (session, providerId) => {
    let lists = session.EmailProviderList.all()
      .filter(list => list.provider_id === providerId)
      .toModelArray()
    return lists
  }
)
