import { Switch } from '@thanx/uikit/switch'
import Term from 'components/Term'
import { buildTranslate } from 'locales'
import { UserFunnelData } from 'models/MerchantMetric'
import React from 'react'
import { Table } from 'react-bootstrap'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('reports.activity.sections.customers.metrics.funnel')

type Props = {
  data: UserFunnelData
}

const Funnel: React.FC<Props> = ({ data }) => {
  const { subscriber_count, loyalty_count, connected_count, purchaser_count } =
    data
  return (
    <div>
      <div>
        <span className="bold">{t('bucket_text')}:</span>
      </div>
      <Table className="bucket-table" responsive>
        <tbody>
          <tr>
            <td>{t('subscribers')}</td>
            <td className="bucket-value">
              {numberFormatter(subscriber_count)}
              <span className="padding-left-small">
                <Switch
                  condition={
                    typeof subscriber_count === 'number' &&
                    subscriber_count <= 0
                  }
                >
                  <a href="/subscribers/setup/connect">{t('import')}</a>
                </Switch>
              </span>
            </td>
          </tr>
          <tr>
            <td>{t('loyalty_members')}</td>
            <td className="bucket-value">{numberFormatter(loyalty_count)}</td>
          </tr>
          <tr>
            <td>
              <span className="padding-left-medium">
                <Term search="Connected customers">
                  {t('connected_customers')}
                </Term>
              </span>
            </td>
            <td className="bucket-value">
              {numberFormatter(connected_count)}
              <Switch condition={!!loyalty_count}>
                <span className="padding-left-small">
                  ({percentageFormatter(connected_count / loyalty_count)})
                </span>
              </Switch>
            </td>
          </tr>
          <tr>
            <td>
              <span className="padding-left-medium margin-left-medium">
                {t('purchasers')}
              </span>
            </td>
            <td className="bucket-value">
              {numberFormatter(purchaser_count)}
              <Switch condition={!!connected_count}>
                <span className="padding-left-small">
                  ({percentageFormatter(purchaser_count / connected_count)})
                </span>
              </Switch>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default Funnel
