import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type ButtonT = {
  text: string
  icon: string
  onClick?: () => void
}

const Button: React.FC<ButtonT> = ({ icon, text, onClick }) => {
  const [css] = useStyletron()

  const buttonStyles = css({
    borderRadius: '4px',
    borderWidth: '1px',
    boxShadow: 'none',
    borderStyle: 'solid',
    transition: 'border-color 0.2s ease',
    borderColor: defaultTheme.colors.grey30,
    ':hover': {
      borderColor: defaultTheme.colors.grey90,
    },
  })

  const buttonClassName = `w-100 p-m white-bkg text-left d-flex align-content-center ${buttonStyles}`

  return (
    <button className={buttonClassName} onClick={onClick}>
      <img className="mr-s" src={icon} alt={text} />
      <Text.BodyText3>{text}</Text.BodyText3>
    </button>
  )
}

export default Button
