import moment from 'moment-timezone'

export default () => {
  return moment.tz.zonesForCountry('US').map(tz => {
    const tzObject = moment.tz(tz)
    return {
      label: `(${tzObject.format('zZ')}) ${tz}`,
      value: tz,
    }
  })
}
