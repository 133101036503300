import { Text } from '@thanx/uikit/text'
import { StoredValueConfig } from 'api/storedValue'
import { buildTranslate } from 'locales'
import { Button } from 'react-bootstrap-five'
import AutoReload from './AutoReload'
import CardImages from './CardImages'
import Funding from './Funding'

const t = buildTranslate('stored_value.stored_value_configuration')

type Props = {
  config: StoredValueConfig
}

const StoredValueConfiguration = ({ config }: Props) => {
  return (
    <>
      <div className="d-flex mb-xl align-items-baseline justify-content-between">
        <Text.Header3>{t('title')}</Text.Header3>

        <Button
          variant="primary"
          size="lg"
          href={`mailto:merchant.support@thanx.com?subject=${t(
            'email_subject'
          )}`}
        >
          {t('email_button')}
        </Button>
      </div>

      <CardImages config={config} />
      <Funding config={config} />
      <AutoReload config={config} />
    </>
  )
}

export default StoredValueConfiguration
