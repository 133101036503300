import InfoBox from 'components/InfoBox'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React from 'react'
import { getFormattedDate } from 'utilities/date'
import { useUserTimeZone } from 'utilities/userTimeZone'

const t = buildTranslate('thanx_campaigns.report.v3.summary')
const tDate = buildTranslate('dateFormat')

type Props = {
  startAt: string
  endAt: string | null
  state: string
  shouldShowActive: boolean
}

const StatusInfo: React.FC<Props> = props => {
  const { startAt, endAt, shouldShowActive, state } = props

  const timeZone = useUserTimeZone()
  const startMoment = moment.tz(startAt, timeZone)
  const endMoment = moment.tz(endAt, timeZone)

  const startDate = tDate('dateAtTime', {
    date: getFormattedDate(startMoment, 'daily'),
    time: startMoment.format('hh:mma z'),
  })
  const endDate = tDate('dateAtTime', {
    date: getFormattedDate(endMoment, 'daily'),
    time: endMoment.format('hh:mma z'),
  })

  let infoText: string = ''

  if (state === 'active' && shouldShowActive) {
    infoText = t('active_info')
  } else if (state === 'scheduled') {
    infoText = t('scheduled_info', { startDate })
  } else if (state === 'complete') {
    infoText = t('complete_info', { startDate, endDate })
  }

  if (!infoText) {
    return null
  }

  return <InfoBox type="info" content={infoText} className="mb-xl" />
}

export default StatusInfo
