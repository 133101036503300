// @flow
import React, { Component } from 'react'
import { Col, Grid, Row, Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { Form, RadioGroup } from 'formsy-react-components'

import Input from 'components/Form/Input'
import RadioButton from 'components/Form/RadioButton'
import Spacer from 'components/Spacer'

import type { EmailProviderListT } from 'models/EmailProviderList'

type ListStrategy = 'existing' | 'new'

type FormModel = {
  newListName: ?string,
  existingListId: ?string,
}

export type SubmitFormModel = {
  listStrategy: ListStrategy,
  newListName: ?string,
  existingListId: ?string,
}

type Props = {
  allowCreateNew: boolean,
  isSubmitting: boolean,
  lists: Array<EmailProviderListT>,
  selectedListId: ?number,
  onSubmit: SubmitFormModel => void,
}
type State = {
  listStrategy: ListStrategy,
}

export default class ExportSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      listStrategy: 'existing',
    }
  }

  setListStrategy(strategy: ListStrategy) {
    this.setState({ listStrategy: strategy })
  }

  onSubmit(formModel: FormModel) {
    this.props.onSubmit({
      listStrategy: this.state.listStrategy,
      ...formModel,
    })
  }

  radioOptions(): Array<{ label: string, value: string, disabled: boolean }> {
    const disabled = this.state.listStrategy !== 'existing'
    return this.props.lists.map(list => {
      return {
        label: list.name,
        value: String(list.id),
        disabled,
      }
    })
  }

  newListForm() {
    if (!this.props.allowCreateNew) return
    return (
      <Spacer paddingTop="20px">
        <Row>
          <Col sm={12}>
            <RadioButton
              name="listStrategy"
              value="new"
              checked={this.state.listStrategy === 'new'}
              onClick={this.setListStrategy.bind(this)}
              title={I18n.t('emails.subscribers.export.choose_list.new_list')}
            />
            {this.newFormContent()}
          </Col>
        </Row>
      </Spacer>
    )
  }

  newFormContent() {
    if (this.state.listStrategy !== 'new') return
    return (
      <Row>
        <Col sm={12}>
          <Spacer paddingLeft="30px">
            <Input
              disabled={this.state.listStrategy !== 'new'}
              name="newListName"
              placeholder="Loyalty Members"
              style={{ maxWidth: '300px' }}
              value=""
            />
          </Spacer>
        </Col>
      </Row>
    )
  }

  selectForm() {
    return (
      <Spacer paddingTop="30px">
        <Row>
          <Col sm={12}>
            <RadioButton
              name="listStrategy"
              value="existing"
              checked={this.state.listStrategy === 'existing'}
              onClick={this.setListStrategy.bind(this)}
              title={I18n.t(
                'emails.subscribers.export.choose_list.existing_list'
              )}
            />
          </Col>
        </Row>
        {this.selectFormContent()}
      </Spacer>
    )
  }

  selectFormContent() {
    if (this.state.listStrategy !== 'existing') return
    return (
      <Row>
        <Col sm={12}>
          <Spacer paddingLeft="30px">
            <RadioGroup
              layout="elementOnly"
              name="existingListId"
              options={this.radioOptions()}
              value={
                this.props.selectedListId
                  ? String(this.props.selectedListId)
                  : ''
              }
            />
          </Spacer>
        </Col>
      </Row>
    )
  }

  form() {
    return (
      <Form onValidSubmit={this.onSubmit.bind(this)}>
        {this.newListForm()}
        {this.selectForm()}
        <Spacer paddingTop="30px" />
        <Button
          bsStyle="primary"
          type="submit"
          disabled={this.props.isSubmitting}
        >
          {this.props.isSubmitting
            ? I18n.t(
                'emails.subscribers.export.choose_list.submit_button_submitting'
              )
            : I18n.t('emails.subscribers.export.choose_list.submit_button')}
        </Button>
      </Form>
    )
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col sm={12}>
            <h2 className="deprecated__h2">
              {I18n.t('emails.subscribers.export.choose_list.title')}
            </h2>
            <p>{I18n.t('emails.subscribers.export.choose_list.description')}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>{this.form()}</Col>
        </Row>
      </Grid>
    )
  }
}
