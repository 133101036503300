import { DateRangeFilter } from 'components/DateFilter/utilities'
import { buildTranslate } from 'locales'
import { ChannelTimeSeriesMetric } from 'models/CaptureRateMetric'
import moment from 'moment-timezone'
import { merchantTheme } from 'theme'

const t = buildTranslate('capture_rate.revenue_by_channel.chart')

type SeriesT = {
  key: string
  color: string
  name: string
}

export const seriesValues: SeriesT[] = [
  {
    key: 'member_purchases_instore_amount',
    color: merchantTheme.colors.spearmint50,
    name: 'instore_loyalty',
  },
  {
    key: 'non_member_purchases_instore_amount',
    color: merchantTheme.colors.spearmint30,
    name: 'instore_unknown',
  },
  {
    key: 'member_purchases_digital_amount',
    color: merchantTheme.colors.wisteria,
    name: 'digital_loyalty',
  },
  {
    key: 'non_member_purchases_digital_amount',
    color: merchantTheme.colors.wisteria30,
    name: 'digital_unknown',
  },
  {
    key: 'third_party_amount',
    color: merchantTheme.colors.grey40,
    name: 'third_party',
  },
]

export const limitedSeriesValues: SeriesT[] = [
  seriesValues[0],
  { ...seriesValues[2], color: merchantTheme.colors.spearmint30 },
]

export const getXAxisCategories = (filter: DateRangeFilter, timezone) => {
  const { start, end } = filter
  const categories: string[] = []
  const months = moment(end, 'YYYY-MM-DD').diff(
    moment(start, 'YYYY-MM-DD'),
    'months'
  )
  if (!start || !end) {
    return []
  }
  for (let i = 0; i <= months; i++) {
    const date = moment(start, 'YYYY-MM-DD')
      .tz(timezone)
      .add(i, 'month')
      .format('MMM YYYY')
    categories.push(date)
  }
  return categories
}

export const getRateChartSeries = (
  categories: string[],
  metrics: ChannelTimeSeriesMetric
) =>
  seriesValues.map(series => {
    const data = categories.map((category, i) => {
      const dateTime = moment(category, 'MMM YYYY').format('YYYY-MM')
      const value = metrics.values?.find(
        metric => metric.date_time === dateTime
      )
      return {
        x: i,
        y: value?.[series.key] ?? 0,
        custom: value ?? {},
      }
    })
    return {
      color: series.color,
      name: t(series.name),
      data,
      type: 'column',
      visible: true,
    }
  }) as Highcharts.SeriesColumnOptions[]

export const getLimitedRateChartSeries = (
  categories: string[],
  metrics: ChannelTimeSeriesMetric
) =>
  limitedSeriesValues.map(series => {
    const data = categories.map((category, i) => {
      const dateTime = moment(category, 'MMM YYYY').format('YYYY-MM')
      const value = metrics.values?.find(
        metric => metric.date_time === dateTime
      )
      return {
        x: i,
        y: value?.[series.key] ?? 0,
        custom: value ?? {},
      }
    })
    return {
      color: series.color,
      name: t(series.name),
      data,
      type: 'column',
      visible: true,
    }
  }) as Highcharts.SeriesColumnOptions[]
