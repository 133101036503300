import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import { defaultTheme } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import { getValidation } from 'actions/validations'
import Badges from 'components/Badges'
import Help from 'components/Help'
import ImagePicker from 'components/ImagePicker'
import Modal from 'components/ModalContainer'
import Formsy from 'formsy-react'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import { ValidationTypes } from 'models/Validation'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import { helpArticles } from 'scenes/Points/Landing/Configuration/helpers'
import {
  selectPointsPrimaryIconValidation,
  selectPointsSecondaryIconValidation,
} from 'selectors/validation'
import { useStyletron } from 'styletron-react'
import Card from './Card'
import Layout from './Layout'
import useUpdate, { FormModel as BaseFormModel } from './useUpdate'

const validationT = buildTranslate('validations')
const configT = buildTranslate('points.configuration')
const t = buildTranslate('points.configuration.edit.appearance')

type FormModel = Pick<
  BaseFormModel,
  | 'currency_name'
  | 'currency_name_plural'
  | 'currency_primary_icon'
  | 'currency_secondary_icon'
>

type Props = { pointsExperience: PointsExperience }

function InstructionsForCustomers(props: Props) {
  const { pointsExperience } = props

  const dispatch = useDispatch()
  const primaryValidations = useSelector(selectPointsPrimaryIconValidation)
  const secondaryValidations = useSelector(selectPointsSecondaryIconValidation)
  const gifEnabled = useFlag('gif-enabled-mx', false)

  const {
    formModel,
    updateFormModel,
    formState,
    setFormState,
    publish,
    isUpdating,
  } = useUpdate(
    pointsExperience.id,
    (pe: PointsExperience): FormModel => ({
      currency_name: pe.currency_name,
      currency_name_plural: pe.currency_name_plural,
      currency_primary_icon: {
        id: null,
        record_id: null,
        url: pe.currency_primary_icon.default,
        uploading: false,
      },
      currency_secondary_icon: {
        id: null,
        record_id: null,
        url: pe.currency_secondary_icon.default,
        uploading: false,
      },
    })
  )

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [css] = useStyletron()

  useEffect(() => {
    if (!primaryValidations) {
      dispatch(getValidation({ type: ValidationTypes.PointsPrimaryIcon }))
    }
  }, [dispatch, primaryValidations])

  useEffect(() => {
    if (!secondaryValidations) {
      dispatch(getValidation({ type: ValidationTypes.PointsSecondaryIcon }))
    }
  }, [dispatch, secondaryValidations])

  if (!formModel) return null

  async function doPublish() {
    const result = await publish()

    if (result.isErr()) {
      dispatch(
        alert({
          key: 'danger',
          message: configT('edit.publish_failed'),
        })
      )
      return
    }

    dispatch(push('/points#appearance'))
    dispatch(
      alert({
        key: 'success',
        message: configT('edit.publish_success'),
        timeout: 5,
      })
    )
  }

  const badgeValues = [
    t('icon.badge.app'),
    t('icon.badge.web'),
    t('icon.badge.dimensions'),
    t('icon.badge.png'),
    ...(gifEnabled ? [t('icon.badge.gif')] : []),
  ]

  const thumbnailStyle = {
    width: '64px',
    border: `1px solid ${defaultTheme.colors.grey30}`,
    borderRadius: '4px',
  }

  const uploaderStyle = {
    paddingLeft: '24px',
    height: '111px',
  }

  return (
    <>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <Layout
        backLink="/points#appearance"
        canPublish={formState === 'valid'}
        onPublish={() => setIsConfirmModalOpen(true)}
      >
        <div className="mb-xl">
          <Text.Header2 className="mb-xs mt-0">{t('title')}</Text.Header2>
          <Text.BodyText3 color="grey70">{t('subtitle')}</Text.BodyText3>
        </div>
        <Formsy
          onChange={updateFormModel}
          onInvalid={() => setFormState('invalid')}
          onValid={() => setFormState('valid')}
          role="form"
        >
          <Card className="mb-l">
            <div className="mb-l">
              <Text.Header4 className="mb-xs mt-0">
                {t('name.title')}
              </Text.Header4>
              <Text.BodyText4 color="grey70">
                {t('name.subtitle')}
              </Text.BodyText4>
            </div>
            <FormsyInput
              name="currency_name"
              value={formModel.currency_name}
              label={t('name.singular_label')}
              className="mb-l"
              validations={{
                maxLength: 20,
              }}
              validationErrors={{
                maxLength: validationT('max_length', { maxLength: 20 }),
              }}
            />
            <FormsyInput
              name="currency_name_plural"
              value={formModel.currency_name_plural}
              label={t('name.plural_label')}
              validations={{
                maxLength: 21,
              }}
              validationErrors={{
                maxLength: validationT('max_length', { maxLength: 21 }),
              }}
            />
          </Card>
          <Card>
            <div className="mb-l">
              <Text.Header4 className="mb-xs mt-0">
                {t('icon.title')}
              </Text.Header4>
            </div>
            <div className="mb-l d-flex flex-column">
              <Text.BodyText4 bold>{t('icon.primary.title')}</Text.BodyText4>
              <Text.BodyText4 color="grey70">
                {t('icon.primary.subtitle')}
              </Text.BodyText4>
              <Badges className="mb-s mt-xs" values={badgeValues} />
              {!!primaryValidations && (
                <ImagePicker
                  value={formModel.currency_primary_icon ?? undefined}
                  name="currency_primary_icon"
                  imageValidations={primaryValidations}
                  thumbnailClassName={css(thumbnailStyle)}
                  uploaderClassName={css(uploaderStyle)}
                />
              )}
            </div>
            <div className="d-flex flex-column">
              <Text.BodyText4 bold>{t('icon.secondary.title')}</Text.BodyText4>
              <Text.BodyText4 color="grey70">
                {t('icon.secondary.subtitle')}
              </Text.BodyText4>
              <Badges className="mb-s mt-xs" values={badgeValues} />
              {!!secondaryValidations && (
                <ImagePicker
                  value={formModel.currency_secondary_icon ?? undefined}
                  name="currency_secondary_icon"
                  imageValidations={secondaryValidations}
                  thumbnailClassName={css({
                    ...thumbnailStyle,
                    backgroundColor: defaultTheme.colors.grey10,
                  })}
                  uploaderClassName={css(uploaderStyle)}
                />
              )}
            </div>
          </Card>
        </Formsy>
        <Help
          title={configT('help.title')}
          articles={helpArticles.appearance}
        />
      </Layout>

      <Modal
        title={t('publish_modal.title')}
        proceedText={t(`publish_modal.proceed`)}
        isOpen={isConfirmModalOpen}
        onProceed={async () => {
          await doPublish()
          setIsConfirmModalOpen(false)
        }}
        onClose={() => setIsConfirmModalOpen(false)}
        isLoading={isUpdating}
      >
        {t('publish_modal.message')}
      </Modal>
    </>
  )
}

export default InstructionsForCustomers
