import { getItemPurchases } from 'actions/itemPurchases'
import { updatePaginationCurrentPage } from 'actions/pagination'
import { getUserItems } from 'actions/users'
import useDispatch from 'hooks/useDispatch'
import { Fields as UserItem } from 'models/UserItem'
import { Fields as UserProfile } from 'models/UserProfile'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectItemPurchases } from 'selectors/itemPurchase'
import { selectPagination } from 'selectors/pagination'
import { selectUserItems } from 'selectors/userItem'

export const useItemsTable = (profile: UserProfile) => {
  const dispatch = useDispatch()
  const [isFetchingItems, setIsFetchingItems] = useState(false)
  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'user_items', profile.uid)
  )
  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const itemIds = paginationInfo?.record_ids?.[currentPage] ?? []
  const items = useSelector(state => selectUserItems(state, profile.id)).filter(
    userItem => itemIds.includes(userItem.id)
  )

  const fetchItems = useCallback(
    async (page: number) => {
      setIsFetchingItems(true)
      await dispatch(getUserItems(profile.uid, { page }))
      setIsFetchingItems(false)
    },
    [dispatch, profile.uid]
  )

  const onChangePage = useCallback(
    async (page: number) => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // items for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'user_items',
            id: profile.uid,
            page,
          })
        )
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      fetchItems(page)
    },
    [dispatch, paginationInfo, fetchItems, profile.uid]
  )

  useEffect(() => {
    onChangePage(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangePage])

  return {
    isFetchingItems,
    items,
    currentPage,
    numPages,
    onChangePage,
  }
}

export const useItemPurchases = (profile: UserProfile, item: UserItem) => {
  const [isFetching, setIsFetching] = useState(false)
  const dispatch = useDispatch()
  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'item_purchases', item.id)
  )
  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const itemIds = paginationInfo?.record_ids?.[currentPage] ?? []
  const purchases = useSelector(state =>
    selectItemPurchases(state, item.id)
  ).filter(userItem => itemIds.includes(userItem.id))

  const fetchPurchases = useCallback(
    async page => {
      setIsFetching(true)
      await dispatch(getItemPurchases(profile.uid, item, { page }))
      setIsFetching(false)
    },
    [dispatch, profile.uid, item]
  )

  const onChangePage = useCallback(
    async (page: number) => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // purchases for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'item_purchases',
            id: item.id,
            page,
          })
        )
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      fetchPurchases(page)
    },
    [dispatch, paginationInfo, fetchPurchases, profile.uid]
  )

  useEffect(() => {
    onChangePage(currentPage)
  }, [onChangePage])

  return {
    isFetching,
    purchases,
    currentPage,
    numPages,
    onChangePage,
  }
}
