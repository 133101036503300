import { StyleableButton } from '@thanx/uikit/button'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import UploadIcon from './UploadIcon'

const t = buildTranslate('cms.components.image_picker')

type Props = {
  onClick: () => void
}

const AddImageButton: React.FC<Props> = ({ onClick }) => {
  const [css] = useStyletron()

  return (
    <StyleableButton
      onClick={onClick}
      style={{
        borderStyle: 'dashed',
        borderWidth: '1px',
        borderColor: defaultTheme.colors.grey50,
        height: '100px',
      }}
      className={
        'd-flex justify-content-center align-items-center flex-column w-100'
      }
    >
      <UploadIcon
        className={`${css({
          width: '18px',
          height: '24px',
        })} mb-xs align-self-center`}
      />
      <p className="small-caps-3">{t('add_image')}</p>
    </StyleableButton>
  )
}

export default AddImageButton
