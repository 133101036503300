import flatMap from 'lodash/flatMap'
import type { TransactionStatsMetric } from 'models/MerchantMetric'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import MetricSection from '../../components/MetricSection'
import SectionHeader from '../../components/SectionHeader'
import {
  AvgCheck,
  AvgPurchases,
  AvgSpend,
  MembershipPurchases,
  PurchasesPerUser,
  TotalSpendPerUser,
} from './metrics'

type Props = {
  transactionStats?: TransactionStatsMetric
}

const Transactions: React.FC<Props> = ({ transactionStats }) => {
  const transactionStatsData = transactionStats?.data
  const avgPurchases = transactionStatsData?.average_purchases && (
    <AvgPurchases data={transactionStatsData.average_purchases} />
  )

  const membershipPurchases = transactionStatsData?.membership_purchases && (
    <MembershipPurchases data={transactionStatsData.membership_purchases} />
  )

  const purchasesPerUser = transactionStatsData?.purchases_per_user_counts && (
    <PurchasesPerUser data={transactionStatsData.purchases_per_user_counts} />
  )

  const avgSpend = transactionStatsData?.average_spend && (
    <AvgSpend data={transactionStatsData.average_spend} />
  )

  const totalSpendPerUser = transactionStatsData?.total_spend_per_user && (
    <TotalSpendPerUser data={transactionStatsData.total_spend_per_user} />
  )

  const avgCheck = transactionStatsData?.average_check && (
    <AvgCheck data={transactionStatsData.average_check} />
  )

  const spendMetrics = (avgSpend || totalSpendPerUser) && (
    <div>
      {avgSpend}
      {totalSpendPerUser}
    </div>
  )

  const sections = () => {
    let sections: React.ReactNode[] = []

    if (avgPurchases || membershipPurchases)
      sections.push(
        <MetricSection
          key={0}
          leftMetric={avgPurchases}
          rightMetric={membershipPurchases}
        />
      )

    if (purchasesPerUser)
      sections.push(<MetricSection key={1} leftMetric={purchasesPerUser} />)

    if (spendMetrics)
      sections.push(<MetricSection key={2} leftMetric={spendMetrics} />)

    if (avgCheck) sections.push(<MetricSection key={3} leftMetric={avgCheck} />)

    return flatMap(sections, (value, index, array) =>
      array.length - 1 !== index
        ? [
            value,
            <hr key={`divider-${index}`} className="section-divider-dark" />,
          ]
        : value
    )
  }

  return (
    <div className="grey-10-bkg report-container">
      <SectionHeader
        title={I18n.t('reports.activity.sections.transactions.title')}
      />
      {sections()}
    </div>
  )
}

export default Transactions
