import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import ControlGroupIcon from 'assets/images/campaign-center/builder/img_control_group.svg'
import Email from 'components/Icons/Email'
import Variant from 'components/Icons/Variants/Variant'
import { PopoverOption } from 'components/PopoverMenu'
import ReviewSection from 'components/review/ReviewSection'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import EditableName from 'scenes/CampaignCenter/Builder/components/EditableName'
import Context from 'scenes/CampaignCenter/Builder/components/VariantContext'
import Channels from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Channels'
import { selectCampaignVariant } from 'selectors/campaignVariant'
import { abTestingHelpUrl } from 'utilities/urlUtils'
import Incentive from '../Incentive'
import Messaging from '../Messaging'
import DeleteModal from './DeleteModal'

const t = buildTranslate('thanx_campaigns.builder.variants')

type Props = {
  campaign: Campaign
  setCurrentStep?: (step: string) => void
  letter?: string
  title: string
  subtitle?: string
  variantId: number | undefined
  showKebab?: boolean
  isEditable?: boolean
  name?: string
}

const Section: React.FC<Props> = props => {
  const {
    campaign,
    isEditable = false,
    letter = '',
    name,
    setCurrentStep,
    showKebab = false,
    subtitle,
    title,
    variantId,
  } = props
  const allowNoRewardMsg = useFlag('mx-allow-no-reward-messages', false)
  const variant = useSelector(state => selectCampaignVariant(state, variantId))
  const [showDelete, setShowDelete] = useState(false)
  const { setConfig } = useCampaignConfig()
  const onSetCurrentStep = (step: string) => {
    if (variant) {
      setConfig(variant)
    }
    setCurrentStep && setCurrentStep(step)
  }
  const popoverValues = showKebab
    ? ({ close }) => (
        <>
          <PopoverOption
            onClick={() => {
              setShowDelete(true)
              close()
            }}
          >
            {t('delete.button')}
          </PopoverOption>
        </>
      )
    : null
  const config = variant || (campaign as unknown as VariantOrCampaign)
  const isControlVariant = config.internal_type === 'control'
  const newTitle =
    isEditable && !isControlVariant ? (
      <EditableName
        className={showKebab ? 'pr-xl d-flex' : 'd-flex'}
        campaignId={campaign.id}
        variantId={variantId}
        name={name === null ? undefined : name}
        type={config.campaign_config_type}
        styleSize="small"
        showEdit
        showPlaceholder
        onFocus={() => {
          if (variant) {
            setConfig(variant)
          }
        }}
      />
    ) : (
      <Text.Header4 tag="div">
        {isControlVariant ? t('control_group') : title}
      </Text.Header4>
    )

  const getIcon = () => {
    if (isControlVariant) return ControlGroupIcon
    if (
      config.campaign_config_type === 'variant' &&
      campaign.variants.length > 1
    )
      return <Variant variantLetter={letter} paddingLeft="9px" />

    return <Email />
  }

  const shouldNotDelete =
    campaign.variants?.filter(v => v.internal_type !== 'control').length <= 1 &&
    variant?.internal_type !== 'control'
  return (
    <ReviewSection
      className="position-relative"
      icon={getIcon()}
      popoverValues={popoverValues}
    >
      <Context.Provider value={config}>
        <div className={`mb-m ${isEditable ? '' : 'mt-xxs'}`}>
          {newTitle}
          {subtitle && (
            <Text.BodyText4 className="mt-xs" color="grey70">
              {subtitle}
            </Text.BodyText4>
          )}
        </div>
        <Incentive
          campaign={campaign}
          isEditable={true}
          isControlVariant={isControlVariant}
          setCurrentStep={onSetCurrentStep}
        />
        <Switch
          condition={allowNoRewardMsg}
          fallback={
            <Messaging
              campaign={campaign}
              isEditable={true}
              isControlVariant={isControlVariant}
              setCurrentStep={onSetCurrentStep}
            />
          }
        >
          <Channels
            campaign={campaign}
            config={config}
            isEditable={true}
            isControlVariant={isControlVariant}
          />
        </Switch>
        <Switch condition={isControlVariant}>
          <div>
            <Text.BodyText4 className="mt-xs" color="grey70" tag="p">
              {t('control_group_description')}
            </Text.BodyText4>
            <Link
              className="font-size-14"
              to={{ pathname: abTestingHelpUrl }}
              target="_blank"
            >
              {t('learn_more')}
            </Link>
          </div>
        </Switch>
        <Switch condition={!!variant}>
          <DeleteModal
            shouldNotDelete={shouldNotDelete}
            variant={variant}
            isOpen={showDelete}
            onClose={() => setShowDelete(false)}
            variantsLength={campaign.variants?.length}
            letter={letter}
          />
        </Switch>
      </Context.Provider>
    </ReviewSection>
  )
}

export default Section
