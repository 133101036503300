import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import RewardImage from 'components/RewardPreviewCard/RewardImage'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {}

const t = buildTranslate(
  'redeem_manager.detail.cards.online_redemption.hidden_menu'
)

export const HiddenMenuDefaultCard: React.FC<Props> = () => {
  const [css] = useStyletron()
  return (
    <div
      className={`border py-m ${css({
        display: 'flex',
        width: '432px',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '2px',
      })}`}
    >
      <RewardImage />
      <Text.BodyText3 className="py-xs" bold>
        {t('congrats')}
      </Text.BodyText3>
      <Text.BodyText3>{t('unlocked_menu')}</Text.BodyText3>
    </div>
  )
}
