import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type Props = {
  children: React.ReactNode
}

const Cell: React.FC<Props> = props => {
  const [css] = useStyletron()
  const { children } = props

  return (
    <div
      className={`
        p-m
        d-flex
        flex-column
        align-items-end
        col-2
        ${css({
          borderLeft: `1px solid ${defaultTheme.colors.grey30}`,
          borderBottom: `1px solid ${defaultTheme.colors.grey30}`,
          margin: '0 0 0 auto',
          minHeight: '88px',
        })}
    `}
    >
      {children}
    </div>
  )
}

export default Cell
