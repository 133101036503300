import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import { Link } from 'react-router-dom'
import { useIsMobile } from 'utilities/responsive'

const t = buildTranslate('page_header')

export type Props = {
  children?: React.ReactNode
  title: string
  description?: string | React.ReactNode
  backPath?: string
  buttonAction?: () => void
  buttonText?: string
  buttonIsDisabled?: boolean
  menu?: React.ReactNode
  topComponent?: React.ReactNode
  learnMoreUrl?: string
}

const PageHeader: React.FC<Props> = props => {
  const {
    children,
    title,
    description,
    backPath,
    buttonAction,
    buttonText,
    buttonIsDisabled = false,
    menu,
    topComponent,
    learnMoreUrl,
  } = props
  const isMobile = useIsMobile()

  return (
    <Container
      fluid
      className="w-100 h-100 pt-m px-0 white-bkg border-bottom-1 grey-20-border overflow-auto"
    >
      <Switch condition={!!backPath}>
        <Link
          className="p-s grey-90 grey-10-hover-bkg grey-90-hover bold border-radius-5 no-underline mb-l"
          to={backPath || ''}
        >
          <i className="mr-s fa fa-arrow-left grey-90" title={t('back')} />
          {t('back')}
        </Link>
      </Switch>
      <Container className="py-m d-flex px-0">
        <div className="d-flex flex-column mr-auto">
          <Switch condition={!!topComponent}>
            <div className="mb-xs">{topComponent}</div>
          </Switch>
          <Text.Header2 className="m-0 mr-xl">{title}</Text.Header2>
          <Switch
            condition={typeof description === 'string'}
            fallback={description}
          >
            <Text.BodyText3 tag="p" className="mt-xs mb-m">
              {description}
              {!!learnMoreUrl && (
                <>
                  {' '}
                  <a
                    href={learnMoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('learn_more')}
                  </a>
                </>
              )}
            </Text.BodyText3>
          </Switch>
        </div>
        <div
          className={`d-flex align-items-center
          ${
            isMobile && ((!!buttonAction && !!buttonText) || !!menu)
              ? 'my-m'
              : ''
          }`}
        >
          <Switch condition={!!buttonAction && !!buttonText}>
            <Button
              className={menu ? 'mr-m' : ''}
              onClick={buttonAction}
              disabled={buttonIsDisabled}
            >
              {buttonText}
            </Button>
          </Switch>
          <Switch condition={!!menu}>{menu}</Switch>
        </div>
      </Container>
      {children}
    </Container>
  )
}

export default PageHeader
