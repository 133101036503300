import { getPointsExperiences } from 'actions/pointsExperiences'
import noIcon from 'assets/images/points/img_noIcon.svg'
import Counter from 'components/Counter'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectExperience } from 'selectors/pointsExperience'
import { currencyFormatter, numberFormatter } from 'utilities/formatters'

const t = buildTranslate('users.view.counters')

type PropsT = {
  profile: UserProfile
}

const Counters: React.FC<PropsT> = ({ profile }: PropsT) => {
  const dispatch = useDispatch()
  const pointsExperience = useSelector(selectExperience)

  useEffect(() => {
    if (!pointsExperience) {
      dispatch(getPointsExperiences())
    }
  }, [dispatch, pointsExperience])

  return (
    <div className={'d-flex mb-xl'}>
      <Counter
        className="mr-xxl"
        primaryValue={currencyFormatter(profile.lifetime_spend)}
        name={t('lifetime_spend')}
      />
      <Counter
        className="mr-xxl"
        primaryValue={numberFormatter(profile.lifetime_visits)}
        name={t('lifetime_visits')}
      />
      <Counter
        className="mr-xxl"
        primaryValue={currencyFormatter(profile.average_check)}
        name={t('average_check')}
      />
      {!!pointsExperience && profile.points_balance !== null && (
        <Counter
          primaryValue={numberFormatter(profile.points_balance)}
          name={t('points_balance')}
          primaryIcon={
            <img
              height="16px"
              className="padding-right-small mb-xxs"
              src={pointsExperience.currency_primary_icon.default || noIcon}
              alt={`${pointsExperience.currency_name}-icon`}
            />
          }
        />
      )}
    </div>
  )
}

export default Counters
