import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Book from 'components/Icons/Book'
import PopoverContent from 'components/PopoverContent'
import palette from 'constants/palette'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import {
  EffectiveDiscountRateCostEfficacyMetric,
  EffectiveDiscountRateCumulativeMetric,
} from 'models/EffectiveDiscountRateMetric'
import React from 'react'
import GenericTable from 'scenes/Reports/components/Table'
import TableStub from 'scenes/Reports/components/TableStub'
import { getDateLabel } from 'utilities/date'
import { currencyFormatter } from 'utilities/formatters'
import { effectiveDiscountRateUrl } from 'utilities/urlUtils'
import Row from './Row'

const t = buildTranslate('points.effective_discount_rate.table')

type Props = {
  isErrored: boolean
  isLoading: boolean
  values: EffectiveDiscountRateCostEfficacyMetric['values']
  currentData: EffectiveDiscountRateCumulativeMetric['current']
  dateTime: string
  timezone: string
}

const Table: React.FC<Props> = ({
  isErrored,
  isLoading,
  values,
  currentData,
  dateTime,
  timezone,
}) => {
  const showTable = useFlag('MX4-EDR-R2', false)
  const dateLabel = (() => {
    if (!dateTime || !timezone) return null

    return getDateLabel(dateTime, 'monthly', timezone)
  })()

  const totalRedemptionCost = (values ?? []).reduce(
    (sum, value) => sum + value.reward_redemption_cost,
    0
  )
  const totalCost = currencyFormatter(totalRedemptionCost)
  const popoverTotalCost = currencyFormatter(totalRedemptionCost, false)

  const currentCostAmount = (() => {
    if (currentData?.cost_amount) {
      return currencyFormatter(currentData.cost_amount, false)
    }
    return ''
  })()

  return (
    <div className="mt-xxl">
      <Text.Header3 tag="div">{t('title')}</Text.Header3>
      <Switch condition={!!dateLabel}>
        <Text.BodyText4 tag="div" className="mt-xs">
          {t('subtitle', { dateLabel })}
        </Text.BodyText4>
      </Switch>
      <Text.BodyText3 tag="div" className="mt-s">
        <a
          href={effectiveDiscountRateUrl}
          className="body-text-3"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('learn_more')}
          <Book fillColor={palette.belizeHole} className="ml-xs" />
        </a>
      </Text.BodyText3>
      <Switch condition={showTable} fallback={<TableStub />}>
        <div className="mt-l">
          <GenericTable
            values={values}
            isErrored={isErrored}
            isLoading={isLoading}
            showRowToggle={false}
            headers={[
              'name',
              'amount_spent_to_earn',
              'cost_per_redemption',
              'configured_discount_rate',
              'reward_redemptions_count',
              'reward_redemption_cost',
            ]}
            t={buildTranslate('points.effective_discount_rate.table')}
            initialSortAttribute={'reward_redemption_cost'}
          >
            {Row}
          </GenericTable>
          <div className={'d-flex justify-content-end mt-m mr-m'}>
            <Text.BodyText4 color={'grey70'}>
              <span className={'mr-xs bold'}>
                {t('total_redeemed_cost', { totalCost })}
              </span>
              <PopoverContent
                sections={[
                  {
                    content: t('popovers.summary')[0],
                  },
                  {
                    title: t('popovers.what_this_means'),
                    content: t('popovers.summary', {
                      dateLabel,
                      currentCostAmount,
                    })[1],
                  },
                  {
                    content: t('popovers.summary', {
                      dateLabel,
                      totalCost: popoverTotalCost,
                    })[2],
                  },
                ]}
              />
            </Text.BodyText4>
          </div>
        </div>
      </Switch>
    </div>
  )
}

export default Table
