import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import startCase from 'lodash/startCase'
import React from 'react'
import styles from './CampaignStatus.module.scss'

type Props = {
  state: string
  className?: string
  reason?: 'coupon_codes' | 'statement_credit' | 'approval_required' | null
}

const CampaignStatus: React.FC<Props> = props => {
  const { state, className = '', reason } = props
  const [css, theme] = useStyletron()

  let status = state
  if (status === 'in_review') {
    status = 'thanx_review'
    if (reason === 'approval_required') status = 'needs_review'
  }

  let color, backgroundColor
  switch (status) {
    case 'active':
      color = 'nephritis50'
      backgroundColor = 'nephritis10'
      break
    case 'complete':
    case 'completed':
      color = 'belizeHole50'
      backgroundColor = 'belizeHole10'
      break
    case 'in_review':
    case 'needs_review':
    case 'thanx_review':
      color = 'eggYolk50'
      backgroundColor = 'eggYolk10'
      break
    case 'scheduled':
      color = 'spearmint50'
      backgroundColor = 'spearmint10'
      break
    default:
      // Handles 'draft' and 'archived' statuses
      color = 'grey70'
      backgroundColor = 'grey10'
  }

  return (
    <Text.SmallCaps3
      bold
      color={color}
      className={`
          ${className}
          inline-block
          ${styles.campaignStatusBox}
          ${css({
            backgroundColor: theme.colors[backgroundColor],
          })}
        `}
    >
      {startCase(status)}
    </Text.SmallCaps3>
  )
}

export default CampaignStatus
