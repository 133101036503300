export default {
  review: {
    audience: {
      description: {
        part1:
          'This campaign will target customers when they enter the segment ',
      },
      timeDisclaimer:
        'It may take up to 60 minutes for them to receive this campaign after they enter the segment',
      restrictions: {
        oneTime: 'A customer will not receive this campaign more than once',
      },
      no_target: {
        title: 'Your new target segment (in progress)',
        body: 'We received your new segment request and we will be in-touch once it’s ready. In the meantime you can customize your reward and your message below.',
      },
      delete: 'Delete',
    },
    schedule: {
      start: 'When do you want to start this campaign?',
      startNow: 'Start now',
      startLater: 'Start later',
      end: 'When do you want to stop this campaign?',
      bullets: {
        manualStop:
          'Once this campaign is active, you can always stop it from the campaign report.',
      },
      errors: {
        startPast: 'You can’t schedule a campaign in the past.',
        endBeforeStart: 'You can’t stop a campaign before its start date.',
      },
    },
    retargetFrequency: {
      title: 'How many times can a customer receive this campaign?',
      onceLabel: 'Exactly once',
      multipleLabel: 'Multiple times',
      multipleDescription1:
        'Customers will receive this campaign each time they fall into the segment.',
      multipleDescription2:
        'Exclude customers if they already received this campaign in the past X days.',
      days: 'days',
    },
  },
  audience: {
    page_title: 'Edit campaign audience | Thanx',
    title: 'Audience',
    subtitle: 'Who will receive your campaign',
    customers: 'Customers',
    select: {
      title: 'Segment',
      no_segment: 'Select your segment...',
      default: 'Your new target segment (in progress)',
    },
    description: {
      title: 'Description',
      default:
        'Allow 5 business days to process new segment requests. We will be in-touch once the new segment is ready. In the meantime you can customize your reward and your message.',
    },
  },
}
