export default {
  page_title: 'Billing | Thanx',
  title: 'Billing',
  pay_balance: 'Pay balance',
  pay_now: 'Pay now',
  invoices_sent_to: 'Invoices are sent to',
  pay_balance_message: `You'll need an account at Bill.com (it's free), where you can review past invoices, route to approvers, and much more.`,
  bill_com_logo: 'Bill.com Logo',
  manage_users: 'Manage users',
  email: 'Email',
}
