import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React from 'react'
import { numberFormatter } from 'utilities/formatters'

type PropsT = {
  template: RedeemTemplate
}

const t = buildTranslate(
  'redeem_manager.detail.cards.instore_redemption.coupon_codes'
)

const PoolDetails: React.FC<PropsT> = ({ template }) => {
  const { available_coupon_code_count, total_coupon_code_count } = template
  return (
    <div className="mb-xl grey-70">
      <p className="small-caps-2">{t('coupon_pool_details')}</p>
      <div className="d-flex justify-content-between p-m border-bottom-1 grey-20-border">
        <span>{t('total_codes')}</span>
        <span>{numberFormatter(total_coupon_code_count)}</span>
      </div>
      <div className="d-flex justify-content-between p-m border-bottom-1 grey-20-border">
        <div>
          <span>{t('redeemed_codes')}</span>
          <p className="mt-xxs mb-0 body-text-5">{t('not_available_to_use')}</p>
        </div>
        <span>
          <Switch condition={!!total_coupon_code_count}>
            {numberFormatter(
              total_coupon_code_count - available_coupon_code_count
            )}
          </Switch>
        </span>
      </div>
      <div className="d-flex justify-content-between p-m border-bottom-1 grey-20-border">
        <div>
          <span className="mb-xs">{t('available_codes')}</span>
          <p className="mt-xxs mb-0 body-text-5">{t('available_to_use')}</p>
        </div>
        <span className="cayenne-50">
          {numberFormatter(available_coupon_code_count)}
        </span>
      </div>
    </div>
  )
}

export default PoolDetails
