import React, { useEffect } from 'react'

type Props = {
  accountId: string
  className?: string
}

const Headway: React.FC<Props> = ({ accountId, className = '' }) => {
  const elementId = 'headway-widget'

  useEffect(() => {
    const initHeadway = () => {
      //@ts-ignore
      window.Headway.init({
        selector: `#${elementId}`,
        account: accountId,
        trigger: '.headway-nav-item',
        position: { y: 'bottom' },
      })
    }

    const loadScript = () => {
      const script = document.createElement('script')
      script.src = '//cdn.headwayapp.co/widget.js'
      script.async = true
      script.onload = () => initHeadway()
      if (document.body) document.body.appendChild(script)
    }
    //@ts-ignore
    if (!window.Headway) loadScript()
  }, [accountId])

  return <span id={elementId} className={className} />
}

export default Headway
