import moment from 'moment-timezone'
import React, { useContext, useEffect, useState } from 'react'
import { FormControl, FormGroup, InputGroup } from 'react-bootstrap'
import { DateInputsContext } from './DateInputsContext'

type Props = {
  type: 'start_date' | 'end_date'
  value: Date | null
  onChange: (value: Date) => void
  timeZone: string
}

const DateInput: React.FC<Props> = props => {
  const { value, onChange, timeZone, type } = props
  const [localValue, setLocalValue] = useState<string | null>(null)
  const { inputs: dateInputs, setInputs } = useContext(DateInputsContext)
  const inputValidKey =
    type === 'start_date' ? 'startDateValid' : 'endDateValid'
  const isInputValid = dateInputs[inputValidKey]

  useEffect(() => {
    if (value) {
      setLocalValue(null)
      setInputValid(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const displayValue = ((): string | null => {
    if (localValue) {
      return localValue
    }

    if (!value) {
      return null
    }

    return moment.tz(value, timeZone).format('MM/DD/YY')
  })()

  const setInputValid = (valid: boolean) => {
    setInputs({ [inputValidKey]: valid })
  }

  const handleChange = event => {
    const dateStr = event.target.value
    setLocalValue(dateStr)
    setInputValid(true)
  }

  const handleKeyPress = target => {
    // enter key
    if (target.charCode === 13) {
      handleUpdateValue()
    }
  }

  const handleUpdateValue = () => {
    if (!localValue) {
      return
    }

    if (localValue.match(/^\d{1,2}\/\d{1,2}\/\d{2}$/)) {
      const date = moment.tz(localValue, 'MM/DD/YY', timeZone)
      if (date.isValid()) {
        onChange(date.toDate())
        setLocalValue(null)
        return
      }
    }

    setInputValid(false)
  }

  return (
    <FormGroup
      style={{ width: 276 }}
      validationState={isInputValid ? '' : 'error'}
    >
      <InputGroup>
        <FormControl
          value={displayValue}
          onBlur={handleUpdateValue}
          onKeyPress={handleKeyPress}
          onChange={handleChange}
          type="text"
          placeholder="MM/DD/YY"
        />
      </InputGroup>
    </FormGroup>
  )
}

export default DateInput
