import palette from 'constants/palette'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import numeral from 'numeral'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type Props = {
  selectedKey: string
  order: string[]
  data: number[]
  selectedColor: string
  columnColor: string
  showColumnLabels: boolean
  categoryLabelColor: string | null
}

export function getColors(
  order: string[],
  selectedKey: string,
  selectedColor: string,
  columnColor: string
) {
  const colors: string[] = []
  order.forEach(key => {
    if (key === selectedKey) {
      colors.push(selectedColor)
    } else {
      colors.push(columnColor)
    }
  })
  return colors
}

const Graph: React.FC<Props> = props => {
  const {
    selectedKey,
    order,
    data,
    selectedColor,
    columnColor,
    showColumnLabels,
    categoryLabelColor,
  } = props
  const colors = getColors(order, selectedKey, selectedColor, columnColor)

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        credits: { enabled: false },
        tooltip: { enabled: false },
        title: { text: null },
        legend: { enabled: false },
        xAxis: {
          lineColor: 'transparent',
          categories: order,
          labels: {
            useHTML: true,
            align: 'center',
            reserveSpace: true,
            formatter: function () {
              let phrase = I18n.t(
                //@ts-ignore
                `thanx_campaigns.report.repeat_customers.times.${this.value}`
              )
              return `<div class="text-center"><span class="font-size-16">Customers return</span><br/><span class="bold font-size-24">${phrase}</span></div>`
            },
            style: {
              color: categoryLabelColor || palette.grey90,
            },
          },
          minorTickLength: 0,
          tickLength: 0,
        },
        yAxis: {
          visible: false,
          type: 'category',
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
            pointPadding: -0.2,
          },
          series: {
            minPointLength: 2,
            borderRadius: 5,
            dataLabels: {
              enabled: showColumnLabels,
              useHTML: true,
              formatter: function () {
                return `<span class="normal font-size-22 opacity-50">${numeral(
                  //@ts-ignore
                  this.y / 100
                ).format('0%')}</span>`
              },
            },
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
        colors,
        chart: {
          type: 'column',
          height: 400,
          backgroundColor: 'white',
          style: {
            fontFamily: 'Lato',
          },
        },
        series: [
          {
            data,
          },
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                xAxis: {
                  labels: {
                    autoRotation: false,
                    formatter: function () {
                      return I18n.t(
                        //@ts-ignore
                        `thanx_campaigns.report.repeat_customers.times.${this.value}`
                      )
                    },
                  },
                },
                plotOptions: {
                  series: {
                    dataLabels: {
                      enabled: false,
                    },
                  },
                },
              },
            },
          ],
        },
      }}
    />
  )
}

export default Graph
