import { StyleableButton } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { Notification } from '@thanx/uikit/notification'
import { Switch } from '@thanx/uikit/switch'
import {
  createFileUpload,
  getFileUpload,
  processFileUpload,
  SourcePath,
} from 'actions/fileUpload'
import axios from 'axios'
import ConfirmModal from 'components/ConfirmModal'
import FlagIcon from 'components/FlagIcon'
import InfoIcon from 'components/InfoIcon'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as FileUpload, State } from 'models/FileUpload'
import { Fields as ValidationFields, ValidationTypes } from 'models/Validation'
import React, { useRef, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { textUtils } from 'utilities/textUtils'
import { useInterval } from 'web-api-hooks'
import CropperModal from './CropperModal'
import { checkIfImageIsSmall, getFileWithExactDimensions } from './helper'
import ImageButton from './ImageButton'

export type ImagePickerValue = {
  id: number | null
  record_id?: number | null
  url?: string
  uploading: boolean
  pendingImage?: string
}

type Props = PassDownProps<ImagePickerValue> & {
  className?: string
  label?: string
  imageValidations: ValidationFields
  removable?: boolean
  onRemoveComplete?: () => void
  onFinalized?: (id: number) => void
  imageWidth?: string
  thumbnailClassName?: string
  uploaderClassName?: string
  shouldValidate?: boolean
}

const t = buildTranslate('cms.components.image_picker')
const MAX_ONBOARDING_SLIDE_IMAGE_SIZE_MB = 4

const ImagePicker: React.FC<Props> = props => {
  const {
    value,
    setValue,
    errorMessage: validationError,
    label,
    className = '',
    imageWidth,
    thumbnailClassName,
    uploaderClassName,
    isPristine,
    imageValidations,
    removable = false,
    onRemoveComplete,
    onFinalized,
    shouldValidate,
  } = props
  const {
    min_width,
    min_height,
    extensions,
    id: validationId,
  } = imageValidations
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isLowQualityModalOpen, setIsLowQualityModalOpen] = useState(false)
  const [imageToCrop, setImageToCrop] = useState<HTMLImageElement | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [image, setImage] = useState<HTMLImageElement | null>(null)
  const [isSmallImage, setIsSmallImage] = useState(false)
  const [fileUpload, setFileUpload] = useState<FileUpload | null>(null)
  const [uploadPercentage, setUploadPercentage] = useState<number | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)

  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const merchant = useCurrentMerchant()

  const dispatch = useDispatch()

  function handleError(message: string) {
    setError(message)
    setValue({
      id: null,
      record_id: null,
      url: value?.url || '',
      uploading: false,
    })
    setFileUpload(null)
    setUploadPercentage(null)
    setIsProcessing(false)
  }

  // Poll the file upload to see if it is still processing.
  useInterval(
    async () => {
      if (fileUpload && merchant) {
        const response = await dispatch(
          getFileUpload(SourcePath.MERCHANTS, merchant.id, fileUpload.id)
        )
        if (response.type === 'fileUpload/GET_FAIL') {
          handleError(t('error'))
          return
        }

        const responseFileUpload = response.payload.data.file_upload
        if (responseFileUpload.state === State.FINALIZED) {
          setIsProcessing(false)
          setFileUpload(null)
          setUploadPercentage(null)

          if (onFinalized) {
            onFinalized(responseFileUpload.id)
          }
          setValue({
            id: responseFileUpload.id,
            record_id: responseFileUpload.record_id,
            url: value?.url || '',
            uploading: false,
            pendingImage: image?.src,
          })
        } else if (responseFileUpload.state === State.ERRORED) {
          handleError(t('error'))
        }
      }
    },
    // Passing null clear the timer when there's nothing processing
    fileUpload ? 5000 : null
  )

  function reset() {
    setValue({
      id: null,
      record_id: null,
      url: '',
      uploading: false,
    })
    setError(null)
    setIsSmallImage(false)
    setImage(null)
    setIsLowQualityModalOpen(false)
    setImageToCrop(null)
    setSelectedFile(null)
    setUploadPercentage(null)
    setIsProcessing(false)
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event.target.files?.length !== 1) return
    reset()
    const file = event.target.files[0]
    setUploadPercentage(0)
    setSelectedFile(file)
    checkImageRestrictions(file)
  }

  async function checkImageRestrictions(file: File): Promise<void> {
    const reader = new FileReader()
    reader.onload = () => {
      const img = new Image()
      img.onload = function () {
        if (
          !min_width ||
          !min_height ||
          img.width / img.height === min_width / min_height
        ) {
          if (
            checkIfImageIsSmall(img, min_width, min_height) &&
            file.type !== 'image/gif'
          ) {
            resizeImage(file)
            return
          }
          setSelectedFile(file)
          setImage(img)
          uploadImage(file)
          return
        }

        // if aspect ratio doesn't match open the cropper tool
        setImageToCrop(img)
      }
      img.onerror = () => {
        setUploadPercentage(null)
        setSelectedFile(null)
        setError(t('error'))
        return
      }
      // @ts-ignore
      img.src = reader.result
    }
    await reader.readAsDataURL(file)
  }

  async function uploadImage(file: File | null) {
    if (!merchant) return
    setIsLowQualityModalOpen(false)
    if (!file) {
      setUploadPercentage(null)
      return
    }
    const fileSizeInMB = file.size / (1024 * 1024)
    if (
      fileSizeInMB > MAX_ONBOARDING_SLIDE_IMAGE_SIZE_MB &&
      validationId === ValidationTypes.OnboardingSlide
    ) {
      handleError(t('file_too_large', { size: fileSizeInMB.toFixed(1) }))
      return
    }

    setValue({
      id: null,
      record_id: null,
      url: value?.url || '',
      uploading: true,
    })

    // Create the file upload
    const fileExtension = file.type.split('/').pop() || ''

    const fileResponse = await dispatch(
      createFileUpload(SourcePath.MERCHANTS, merchant.id, {
        file_type: fileExtension,
        settings: {
          relation_name: 'image',
        },
        record_type: validationId,
      })
    )

    if (fileResponse.type === 'fileUpload/CREATE_FAIL') {
      handleError(t('error'))
      return
    }
    const fileUpload = fileResponse.payload.data.file_upload

    // Upload to s3
    try {
      if (!fileUpload.upload_url) {
        handleError(t('error'))
        return
      }
      setUploadPercentage(0)
      const uploadResponse = await axios.put(fileUpload.upload_url, file, {
        headers: { 'content-type': file.type },
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          setUploadPercentage(percent)
        },
      })

      if (!(uploadResponse.status >= 200 && uploadResponse.status < 300)) {
        handleError(t('error'))
      }
      setIsProcessing(true)
    } catch (error) {
      handleError(t('error'))
      return
    }

    // Process the file
    const processResponse = await dispatch(
      processFileUpload(
        SourcePath.MERCHANTS,
        fileUpload.source_id,
        fileUpload.id
      )
    )

    if (processResponse.type === 'fileUpload/PROCESS_FAIL') {
      handleError(t('error'))
      return
    }
    setFileUpload(processResponse.payload.data.file_upload)
  }

  function resizeImage(file) {
    const reader = new FileReader()
    reader.onload = () => {
      const img = new Image()
      img.onload = async function () {
        const newFile = await getFileWithExactDimensions(
          img,
          min_width || img.width,
          min_height || img.height,
          file.name,
          file.type
        )
        setSelectedFile(newFile)
        setImage(img)
        if (checkIfImageIsSmall(img, min_width, min_height)) {
          setIsLowQualityModalOpen(true)
          setIsSmallImage(true)
          return
        }
        uploadImage(newFile)
      }
      img.onerror = () => {
        setUploadPercentage(null)
        setSelectedFile(null)
        setError(t('error'))
        return
      }
      // @ts-ignore
      img.src = reader.result
    }
    reader.readAsDataURL(file)
  }

  function removeImage() {
    reset()
    if (onRemoveComplete) onRemoveComplete()
  }

  const imageTypes: string[] = []
  extensions.map(extension => {
    if (['jpg', 'jpeg'].includes(extension)) {
      return imageTypes.push('image/jpeg')
    }
    return imageTypes.push(`image/${extension}`)
  })
  const acceptedImages = imageTypes.join(', ')

  return (
    <div className={className}>
      <Switch condition={!!label}>
        <label className="font-size-14 bold">{label}</label>
      </Switch>
      <ImageButton
        imageWidth={imageWidth}
        thumbnailClassName={thumbnailClassName}
        uploaderClassName={uploaderClassName}
        onClick={() => fileInputRef.current?.click()}
        src={image?.src || value?.pendingImage || value?.url}
        fileName={selectedFile?.name}
        uploadPercentage={uploadPercentage}
        processing={isProcessing}
      />
      <input
        data-testid="upload"
        className="hidden"
        accept={acceptedImages}
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      {!!min_width && !!min_height && !!imageToCrop && (
        <CropperModal
          isOpen
          onClose={() => reset()}
          onConfirm={file => {
            setImageToCrop(null)
            resizeImage(file)
          }}
          width={min_width}
          height={min_height}
          image={imageToCrop}
          imageName={selectedFile?.name}
          imageType={selectedFile?.type || imageTypes[0]}
          onSelectImage={() => fileInputRef.current?.click()}
        />
      )}
      <ConfirmModal
        title={t('low_quality_modal.title')}
        description={t('low_quality_modal.description')}
        confirmText={t('low_quality_modal.confirm')}
        isOpen={isLowQualityModalOpen}
        onClose={() => reset()}
        onConfirm={() => uploadImage(selectedFile)}
      />
      <Switch
        condition={
          !!validationError && !isPristine && uploadPercentage === null
        }
      >
        <Notification kind="negative" size="full">
          <InfoIcon color="#b64b26" />
          <span className="ml-xs body-text-4">
            {textUtils.initialCapital(validationError as string)}
          </span>
        </Notification>
      </Switch>
      <Switch condition={!!error}>
        <Notification kind="negative" size="full">
          <InfoIcon color="#b64b26" />
          <span className="ml-xs body-text-4">{error}</span>
        </Notification>
      </Switch>
      <Switch condition={isSmallImage}>
        <Notification kind="warning" size="full">
          <FlagIcon color="#ad8813" />
          <span className="ml-xs body-text-4">{t('image_smaller')}</span>
        </Notification>
      </Switch>
      <Switch condition={!!value?.url && removable}>
        <div className="pb-s mt-m">
          <StyleableButton
            className="float-right font-size-12 belize-hole-50"
            onClick={() => removeImage()}
          >
            {t('delete')}
          </StyleableButton>
        </div>
      </Switch>
      {shouldValidate && (
        <FormsyInput
          // @ts-ignore
          className="hidden"
          type="hidden"
          name="id"
          value={value?.id || ''}
          validations={{
            isRequired: shouldValidate,
          }}
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
          }}
        />
      )}
    </div>
  )
}

export default withFormsy(ImagePicker)
