import { PaginationT } from 'models/Pagination'
import { Fields as User } from 'models/User'
import { Fields as UserCampaign } from 'models/UserCampaign'
import { Fields as UserEvent } from 'models/UserEvent'
import { Fields as UserFeedback } from 'models/UserFeedback'
import { Fields as UserItem } from 'models/UserItem'
import { Fields as UserProfile } from 'models/UserProfile'
import { Fields as UserReward } from 'models/UserReward'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_USERS = 'users/GET_USERS'
export const GET_USERS_SUCCESS = 'users/GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'users/GET_USERS_FAIL'

export const GET_USER_PROFILE = 'users/GET_USER_PROFILE'
export const GET_USER_PROFILE_SUCCESS = 'users/GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAIL = 'users/GET_USER_PROFILE_FAIL'

export const GET_USER_EVENTS = 'users/GET_USER_EVENTS'
export const GET_USER_EVENTS_SUCCESS = 'users/GET_USER_EVENTS_SUCCESS'
export const GET_USER_EVENTS_FAIL = 'users/GET_USER_EVENTS_FAIL'

export const GET_USER_REWARDS = 'users/GET_USER_REWARDS'
export const GET_USER_REWARDS_SUCCESS = 'users/GET_USER_REWARDS_SUCCESS'
export const GET_USER_REWARDS_FAIL = 'users/GET_USER_REWARDS_FAIL'

export const GET_USER_FEEDBACKS = 'users/GET_USER_FEEDBACKS'
export const GET_USER_FEEDBACKS_SUCCESS = 'users/GET_USER_FEEDBACKS_SUCCESS'
export const GET_USER_FEEDBACKS_FAIL = 'users/GET_USER_FEEDBACKS_FAIL'

export const GET_USER_CAMPAIGNS = 'users/GET_USER_CAMPAIGNS'
export const GET_USER_CAMPAIGNS_SUCCESS = 'users/GET_USER_CAMPAIGNS_SUCCESS'
export const GET_USER_CAMPAIGNS_FAIL = 'users/GET_USER_CAMPAIGNS_FAIL'

export const GET_USER_ITEMS = 'users/GET_USER_ITEMS'
export const GET_USER_ITEMS_SUCCESS = 'users/GET_USER_ITEMS_SUCCESS'
export const GET_USER_ITEMS_FAIL = 'users/GET_USER_ITEMS_FAIL'

export const GRANT_USER_REWARD = 'users/GRANT_USER_REWARD'
export const GRANT_USER_REWARD_SUCCESS = 'users/GRANT_USER_REWARD_SUCCESS'
export const GRANT_USER_REWARD_FAIL = 'users/GRANT_USER_REWARD_FAIL'

export type GetUsersAction = AxiosAction<
  typeof GET_USERS,
  { users: User[]; pagination: PaginationT; total_membership_count: number }
>

export type GetUserProfileAction = AxiosAction<
  typeof GET_USER_PROFILE,
  { profile: UserProfile }
>

export type GetUserEventsAction = AxiosAction<
  typeof GET_USER_EVENTS,
  { events: UserEvent[]; pagination: PaginationT }
>

export type GetUserRewardsAction = AxiosAction<
  typeof GET_USER_REWARDS,
  { rewards: UserReward[]; pagination: PaginationT }
>

export type GetUserFeedbacksAction = AxiosAction<
  typeof GET_USER_FEEDBACKS,
  { feedbacks: UserFeedback[]; pagination: PaginationT }
>

export type GetUserCampaignsAction = AxiosAction<
  typeof GET_USER_CAMPAIGNS,
  { campaign_messages: UserCampaign[]; pagination: PaginationT }
>
export type GrantUserRewardAction = AxiosAction<typeof GRANT_USER_REWARD, {}>

export type GetUserItemsAction = AxiosAction<
  typeof GET_USER_ITEMS,
  { purchase_items: UserItem[]; pagination: PaginationT }
>

export function getUsers(params: {
  page: number
  query?: string
}): GetUsersAction {
  return {
    type: GET_USERS,
    payload: {
      client: 'default',
      request: {
        url: 'users',
        method: 'get',
        params,
      },
    },
  }
}

export function getUserProfile(identifier: string): GetUserProfileAction {
  return {
    type: GET_USER_PROFILE,
    payload: {
      client: 'default',
      request: {
        url: `users/${identifier}`,
        method: 'get',
      },
    },
  }
}

export function getUserEvents(
  userIdentifier: string,
  params?: { page: number }
): GetUserEventsAction {
  return {
    type: GET_USER_EVENTS,
    payload: {
      client: 'default',
      request: {
        url: `users/${userIdentifier}/events`,
        method: 'get',
        params,
      },
    },
  }
}

type ProgressParamsT = {
  grant_type: 'progress'
  grant_progress: {
    grant_progress_type: 'dollar' | 'percentage'
    grant_value: number
  }
  rating_id?: number
}

type RewardParamsT = {
  grant_type: 'reward'
  grant_reward: {
    program_id: number
    issuance_reason?: string
  }
  rating_id?: number
}

export type GrantParamsT = ProgressParamsT | RewardParamsT

export function grantReward(
  userIdentifier: string,
  grantParams: GrantParamsT
): GrantUserRewardAction {
  return {
    type: GRANT_USER_REWARD,
    payload: {
      client: 'default',
      request: {
        url: `users/${userIdentifier}/grant_reward`,
        method: 'post',
        data: grantParams,
      },
    },
  }
}

export function getUserRewards(
  userIdentifier: string,
  params?: { page: number }
): GetUserRewardsAction {
  return {
    type: GET_USER_REWARDS,
    payload: {
      client: 'default',
      request: {
        url: `users/${userIdentifier}/rewards`,
        method: 'get',
        params,
      },
    },
  }
}

export function getUserCampaigns(
  userIdentifier: string,
  params?: { page: number }
): GetUserCampaignsAction {
  return {
    type: GET_USER_CAMPAIGNS,
    payload: {
      client: 'default',
      request: {
        url: `users/${userIdentifier}/campaign_messages`,
        method: 'get',
        params,
      },
    },
  }
}

export function getUserFeedbacks(
  userIdentifier: string,
  params?: { page: number }
): GetUserFeedbacksAction {
  return {
    type: GET_USER_FEEDBACKS,
    payload: {
      client: 'default',
      request: {
        url: `users/${userIdentifier}/feedbacks`,
        method: 'get',
        params,
      },
    },
  }
}

export function getUserItems(
  userIdentifier: string,
  params?: { page: number }
): GetUserItemsAction {
  return {
    type: GET_USER_ITEMS,
    payload: {
      client: 'default',
      request: {
        url: `users/${userIdentifier}/purchase_items`,
        method: 'get',
        params,
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getUsers>>
  | ResolvedAxiosAction<ReturnType<typeof getUserProfile>>
  | ResolvedAxiosAction<ReturnType<typeof getUserEvents>>
  | ResolvedAxiosAction<ReturnType<typeof getUserRewards>>
  | ResolvedAxiosAction<ReturnType<typeof getUserFeedbacks>>
  | ResolvedAxiosAction<ReturnType<typeof getUserCampaigns>>
  | ResolvedAxiosAction<ReturnType<typeof getUserItems>>
