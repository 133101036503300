import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type PropsT = {
  className?: string
  variantLetter: string
  color?: string
  paddingLeft?: string
}

const Variant: React.FC<PropsT> = props => {
  const {
    className,
    variantLetter,
    color = 'currentColor',
    paddingLeft = '1px',
  } = props
  const [css] = useStyletron()

  return (
    <span
      className={`${className} ${css({
        color,
        left: '10px',
        width: '32px',
      })}`}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
          fill={color}
        />
      </svg>
      <Text.Header4
        bold
        className={`text-center ${css({
          color,
          marginTop: '-25px',
          paddingLeft,
          height: '14px',
        })}`}
        color="inherit"
      >
        {variantLetter}
      </Text.Header4>
    </span>
  )
}

export default Variant
