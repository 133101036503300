import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { Switch } from 'react-router-dom'
import Landing from './Landing'

const Segments: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        redirect="/"
        accessType="access"
        resource="Segments"
        exact
        path="/segments"
        component={Landing}
      />
    </Switch>
  )
}

export default Segments
