import React from 'react'

type PropsT = {
  className?: string
}

const UserSupport: React.FC<PropsT> = ({ className = '' }) => (
  <svg
    className={className}
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1C4.21875 1 2 3.25 2 6V6.5C2 6.78125 1.75 7 1.5 7C1.21875 7 1 6.78125 1 6.5V6C1 2.6875 3.6875 0 7 0C10.3125 0 13 2.6875 13 6V6.5C13 8.4375 11.4062 10 9.5 10H7.5H7H6.5C5.9375 10 5.5 9.5625 5.5 9C5.5 8.46875 5.9375 8 6.5 8H7.5C8.03125 8 8.5 8.46875 8.5 9H9.5C10.875 9 12 7.90625 12 6.5V6C12 3.25 9.75 1 7 1ZM7 2C9.1875 2 11 3.8125 11 6C11 6.4375 10.9062 6.875 10.7812 7.25C10.5312 7.71875 10.0312 8 9.5 8H9.21875C9.125 7.875 9.03125 7.71875 8.90625 7.625C9.28125 7.1875 9.5 6.625 9.5 6C9.5 4.625 8.375 3.5 7 3.5C5.59375 3.5 4.5 4.625 4.5 6C4.5 6.625 4.6875 7.1875 5.0625 7.625C4.71875 7.96875 4.5 8.46875 4.5 9C4.5 9.0625 4.5 9.09375 4.5 9.125C3.5625 8.40625 3 7.28125 3 6C3 3.8125 4.78125 2 7 2ZM6.5 11H7H7.5H9.28125H9.5H9.78125C12.0938 11 14 12.9062 14 15.2188C14 15.6562 13.625 16 13.1875 16H0.78125C0.34375 16 0 15.6562 0 15.2188C0 12.9062 1.875 11 4.1875 11H4.6875H6.5ZM9.78125 12.5H4.1875C2.9375 12.5 1.875 13.375 1.5625 14.5H12.4062C12.0938 13.375 11.0312 12.5 9.78125 12.5Z"
      fill="#6A747F"
    />
  </svg>
)

export default UserSupport
