import { GET_CARDS_SUCCESS, ResolvedAction } from 'actions/cards'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  card_type:
    | 'anon'
    | 'diners'
    | 'visa'
    | 'unknown'
    | 'Card'
    | 'discover'
    | 'jcb'
    | 'maestro'
    | 'mastercard'
    | 'dinersclub'
  last4: string
  state: 'unlinked' | 'linked' | 'expired' | 'errored' | 'archived'
  payment_state:
    | 'payment_linked'
    | 'payment_unlinked'
    | 'payment_archived'
    | 'payment_errored'
    | 'payment_expired'
  created_at: string
}

// @ts-ignore
export default class Card extends Model<typeof Card, Fields> {
  static modelName: string = 'Card'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Card: ModelType<Card>
  ): void {
    switch (action.type) {
      case GET_CARDS_SUCCESS:
        action.payload.data.cards.forEach(item => Card.upsert(item))
        break
      case RESET_DB:
        Card.all().delete()
        break
      default:
        break
    }
  }
}
