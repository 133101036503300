import { FormsyCheckbox } from '@thanx/uikit/checkbox'
import { Text } from '@thanx/uikit/text'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React, { useContext, useState } from 'react'
import { EditContext } from 'scenes/Points/CreateEdit/EditContext'

const t = buildTranslate('points.create_edit.restrictions.modal')
const checkMarkOverrides = {
  Root: {
    style: {
      alignItems: 'flex-start',
    },
  },
  Checkmark: {
    style: ({ $checked, $disabled, $theme }) => {
      let backgroundColor
      if ($disabled) {
        backgroundColor = palette.grey20
      } else if ($checked) {
        backgroundColor = $theme.colors.primary
      } else {
        backgroundColor = 'none'
      }
      return {
        backgroundColor,
        borderColor: $disabled
          ? `${palette.grey20} !important`
          : `${$theme.colors.primary} !important`,
      }
    },
  },
}

const Where: React.FC = () => {
  const { redeemTemplate, template } = useContext(EditContext)
  const [values, setValues] = useState({
    online: template?.redemption_venue !== 'instore',
    instore: template?.redemption_venue !== 'online',
  })

  const onChange = (key, value) => {
    const newValues = {
      ...values,
      [key]: value,
    }
    setValues(newValues)
  }

  const isInstoreOnly = redeemTemplate?.redemption_venue === 'instore'
  const isOnlineOnly = redeemTemplate?.redemption_venue === 'online'

  return (
    <div className="border-1 grey-30-border border-radius-5 p-m mt-m">
      <Text.Header4 className="mt-0 mb-m">{t('channels')}</Text.Header4>
      <div className="d-flex flex-column grey-70">
        {!isInstoreOnly && (
          <FormsyCheckbox
            // @ts-ignore
            overrides={checkMarkOverrides}
            disabled={isOnlineOnly || !values.instore}
            onChange={value => onChange('online', value)}
            name="online"
            value={values.online}
          >
            <Text.BodyText3 color="grey70" tag="div">
              {t('online_title')}
            </Text.BodyText3>
            {isOnlineOnly && (
              <Text.BodyText4 color="grey70">
                {t('online_subtitle')}
              </Text.BodyText4>
            )}
          </FormsyCheckbox>
        )}
        {!isOnlineOnly && (
          <FormsyCheckbox
            // @ts-ignore
            overrides={checkMarkOverrides}
            disabled={isInstoreOnly || !values.online}
            onChange={value => onChange('instore', value)}
            name="instore"
            value={values.instore}
          >
            <Text.BodyText3 tag="div" color="grey70">
              {t('instore_title')}
            </Text.BodyText3>
            {isInstoreOnly && (
              <Text.BodyText4 color="grey70">
                {t('instore_subtitle')}
              </Text.BodyText4>
            )}
          </FormsyCheckbox>
        )}
      </div>
    </div>
  )
}

export default Where
