import { useStyletron } from '@thanx/uikit/theme'
import { PointsMultiplier } from 'api/points'
import Badge from 'components/Badge'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React from 'react'
import { isTodayWithinRange } from './helpers'

type Props = {
  pointsMultiplier: PointsMultiplier
}

const t = buildTranslate('points.configuration.multipliers')

function DateRangeStatusBadge(props: Props) {
  const { pointsMultiplier } = props

  const [css] = useStyletron()

  const dateRangeStatus = isTodayWithinRange(
    pointsMultiplier.date_range_starts_on,
    pointsMultiplier.date_range_ends_on
  )

  const { backgroundColor, color, text } = {
    within: {
      backgroundColor: palette.nephritis20,
      color: palette.nephritis,
      text: t('active'),
    },
    future: {
      backgroundColor: palette.belizeHole10,
      color: palette.belizeHole,
      text: t('scheduled'),
    },
    past: {
      backgroundColor: palette.grey20,
      color: palette.grey05,
      text: t('expired'),
    },
  }[dateRangeStatus]

  return (
    <Badge
      className={css({
        backgroundColor,
        color,
        height: '20px',
      })}
      value={text}
    />
  )
}

export default DateRangeStatusBadge
