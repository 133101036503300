import { Button, Kind } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { Notification } from '@thanx/uikit/notification'
import { FormsyTextArea } from '@thanx/uikit/textarea'
import { alert } from 'actions/flash'
import {
  requestCustomCampaign,
  REQUEST_CUSTOM_CAMPAIGN_FAIL,
} from 'actions/merchant'
import Modal from 'components/Modal'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'

type Props = {
  title: string
  subtitle?: string
  show: boolean
  onModalClose: () => void
}

const t = buildTranslate('thanx_campaigns.requestSegment.modal')

const RequestSegmentModal: React.FC<Props> = props => {
  const { title, subtitle, show, onModalClose } = props
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const dispatch = useDispatch()

  const handleModalClose = () => {
    setError(null)
    onModalClose()
  }

  const handleFormChange = () => {
    if (error) setError(null)
  }

  async function onSubmitNew({
    message,
    name,
  }: {
    message: string
    name: string
  }) {
    setIsSubmitting(true)
    setError(null)
    const response = await dispatch(requestCustomCampaign({ message, name }))
    if (response.type === REQUEST_CUSTOM_CAMPAIGN_FAIL) {
      const errorMessage =
        response.error?.response?.data?.error || t('form.errors.submit')
      setError(errorMessage)
      setIsSubmitting(false)
      return
    }
    setIsSubmitting(false)

    dispatch(
      alert({
        key: 'success',
        message: t('form.request_success'),
        timeout: 3,
      })
    )
    handleModalClose()
  }

  return (
    <Modal show={show} onModalClose={handleModalClose}>
      <Container fluid>
        <Row>
          <Col className="padding-huge">
            <h3 className="mb-xs grey-90-text">{title}</h3>
            <div className="body-text-3 grey-90-text mb-l">{subtitle}</div>
            {error && (
              <div className="mb-l">
                <Notification size="full" kind="negative">
                  {error}
                </Notification>
              </div>
            )}
            <Formsy onValidSubmit={onSubmitNew} onChange={handleFormChange}>
              <FormsyInput
                className="mb-l uikit-input"
                name="name"
                label={t('form.name_label')}
                description={t('form.name_description')}
                validations={{ isRequired: true }}
                validationErrors={{
                  isRequired: t('form.errors.name_required'),
                }}
              />
              <FormsyTextArea
                className="mb-xl"
                name="message"
                placeholder={t('form.message_placeholder')}
                label={t('form.message_label')}
                description={t('form.message_description')}
                validations={{ isRequired: true }}
                validationErrors={{
                  isRequired: t('form.errors.message_required'),
                }}
                rows={5}
              />
              <div className="d-flex justify-content-end">
                <Button
                  kind={Kind.SECONDARY}
                  className="mr-s"
                  type="button"
                  onClick={handleModalClose}
                >
                  {t('form.cancel_button')}
                </Button>
                <Button
                  kind={Kind.PRIMARY}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  {t('form.submit_button')}
                </Button>
              </div>
            </Formsy>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export default RequestSegmentModal
