import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import Navbar from 'react-bootstrap-five/Navbar'
import { merchantTheme } from 'theme'
import { useIsMobile } from 'utilities/responsive'
import Campaigns from '../Campaigns'
import { paths } from '../constants'
import Customers from '../Customers'
import Experiences from '../Experiences'
import Feedback from '../Feedback'
import Left from '../Left'
import Loyalty from '../Loyalty'
import ProfileMenu from '../ProfileMenu'
import Rewards from '../Rewards'
import Headway from './Headway'
import Help from './Help'
import HomeLogo from './HomeLogo'
import Status from './Status'

const Bar: React.FC = () => {
  const [css] = useStyletron()
  const isMobile = useIsMobile()

  return (
    <Navbar
      variant="dark"
      className={`p-0 align-items-stretch ${css({
        backgroundColor: merchantTheme.colors.grey90,
      })}`}
    >
      {isMobile && <Left />}
      <HomeLogo />
      {!isMobile && (
        <>
          <Campaigns paths={paths.campaigns.paths} />
          <Loyalty paths={paths.loyalty.paths} />
          <Rewards paths={paths.rewards.paths} />
          <Customers paths={paths.customers.paths} />
          <Feedback paths={paths.feedback.paths} />
          <Experiences paths={paths.experiences.paths} />
          <div className="justify-content-end flex-grow-1 d-flex">
            <Status />
            <Help />
            <Headway />
            <ProfileMenu />
          </div>
        </>
      )}
    </Navbar>
  )
}

export default Bar
