import orm from 'models/orm'
import type { Fields as TargetSegment } from 'models/TargetSegment'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectTargetSegments = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): TargetSegment[] => {
    return session.TargetSegment.all().toRefArray()
  }
)

export const selectTargetSegmentsByMerchant = createSelector(
  state => selectTargetSegments(state),
  (state: any) => state.auth.current_merchant_id,
  (targetSegments, merchantId): TargetSegment[] => {
    if (!merchantId) return []
    return targetSegments.filter(segment => segment.merchant_id === merchantId)
  }
)

export const selectTargetSegmentsByCategories = createSelector(
  state => selectTargetSegments(state),
  (_state, categories): string[] => categories,
  (targetSegments, categories): TargetSegment[] =>
    targetSegments.filter(segment =>
      categories.includes(segment.category ?? '')
    )
)
