export default {
  page_title: 'Home | Thanx',
  welcome: 'Welcome back',
  welcome_name: 'Welcome back, %{name}',
  subtitle: 'Here are some quick actions you can take today',
  help: {
    title: 'Need Help?',
    text: 'Want ideas on what type of offer will be most effective for your business, or help setting one up? Our merchant success team is always here to help with any questions you might have.',
  },
  buttons: {
    view_campaign: 'View campaign results',
    create_campaign: 'Create a new campaign',
    update_digital: 'Update digital experiences',
    read_feedback: 'Read customer feedback',
    feedback_scorecard: 'Review feedback scorecard',
    feedback_report: 'Review ratings',
    points_performance: 'Review points performance',
    reward_performance: 'Review reward performance',
    billing: 'Update billing information',
  },
  metrics: {
    title: 'Program performance',
    revenue_capture_rate: {
      title: 'Revenue capture rate',
      unlock_your_rate: 'Unlock your rate',
      popovers: {
        // To be finalized
        section_1:
          'The percentage of your revenue attributed to loyalty members.',
        section_2:
          'This rate is for the latest complete calendar month where POS data for total revenue was provided.',
        section_3:
          'Total revenue attributed to loyalty members / Total revenue across all channels uploaded by you',
        section_4:
          'You import POS data for July shows that you made $10,000 in net revenue. Thanx can see that $3,000 of this was attributed to loyalty members giving you a capture rate of 30% for that month.',
      },
    },
    activation_rate: {
      title: 'Activation rate',
      popovers: {
        section_1:
          'The percentage of customers that made their 1st purchase in a given month and then went on to make a 3rd purchase within 120 days.',
        section_2:
          'Count of customers that made 3rd purchase / Count of customers that made 1st purchase',
        section_3:
          '1,000 customers made their 1st purchase in July. 300 of those customers went on to make their 3rd purchase within 120 days. The activation rate for July is 30%.',
      },
    },
    retention_rate: {
      title: 'Retention rate',
      popovers: {
        section_1:
          'The percentage of activated customers (Activated means this wasn’t their 1st or 2nd purchase) that made a purchase in a given month and then returned to make another purchase within 180 days.',
        section_1_multiple:
          'The percentage of activated customers (activated means this wasn’t their 1st or 2nd purchase) that made a purchase in a given month and then returned to make another purchase within a specific time window (e.g., within 30 days, 60 days, 90 days, etc).',
        section_2:
          '1,000 activated customers made a purchase in July. 300 of those customers returned within 180 days to make another purchase. The 180 day retention rate for July is 30%.',
      },
    },
    effective_discount_rate: {
      title: 'Effective discount rate',
      popovers: {
        section_1:
          'The cost of loyalty rewards that loyalty members redeemed relative to the revenue they generated for you.',
        section_2:
          'Cost of loyalty rewards / Total revenue attributed to loyalty members',
        section_3:
          'Loyalty members exchanged their points for rewards this month. The total cost of those rewards was $1,000. The total amount spent by loyalty members this month was $100,000. The effective discount rate for this month was 1%.',
      },
      requires_points: 'Requires Points',
    },
    shared: {
      view_report: 'View report',
      metric_coming_soon: 'Coming soon',
      report_coming_soon: 'Report coming soon',
      benchmark: '%{rate} industry benchmark',
      popovers: {
        example: 'EXAMPLE',
        how_its_calculated: 'HOW IT’S CALCULATED',
      },
      available_after_launch: 'Available after launch',
      available_in_x_days: 'Available in %{days} days',
      learn_more: 'Learn more',
    },
  },
  smart_links: {
    title: 'Smart Links',
  },
}
