import palette from 'constants/palette'
import { Fields as Merchant } from 'models/Merchant'
import React, { useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import TimezoneBenchmarkCaption from '../components/TimezoneBenchmarkCaption'
import { BenchmarkT } from '../helpers'

export function useTimezoneBenchmarkCaption(
  merchant: Merchant | null,
  timezone: string,
  benchmark?: BenchmarkT
) {
  const captionText = useMemo(() => {
    if (!merchant) return ''

    const benchmarkData = (() => {
      if (!benchmark) return null

      return {
        benchmark,
        merchantType: merchant.merchant_type,
      }
    })()

    return renderToString(
      <TimezoneBenchmarkCaption
        benchmarkData={benchmarkData}
        timezone={timezone}
      />
    )
  }, [merchant, benchmark, timezone])

  return {
    text: captionText,
    style: { color: palette.grey70, fontFamily: 'Lato', fontSize: '12px' },
    align: 'right' as const,
    floating: true,
    useHTML: true,
  }
}
