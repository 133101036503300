import { Fields as Merchant } from 'models/Merchant'
import moment from 'moment'

export type ChannelFilterData = {
  date_time: string
  member_purchases_digital_percent: number
  member_purchases_instore_percent: number
  non_member_purchases_digital_percent: number
  non_member_purchases_instore_percent: number
  third_party_percent: number
}

export type SortFilterAttribute =
  | 'date_time'
  | 'member_purchases_instore_percent'
  | 'non_member_purchases_instore_percent'
  | 'member_purchases_digital_percent'
  | 'non_member_purchases_digital_percent'
  | 'third_party_percent'

export type SortFilter = {
  direction: 'asc' | 'desc'
  attribute: SortFilterAttribute
}

export const sortChannelData = (
  data: Array<ChannelFilterData>,
  sortFilter: SortFilter
): Array<ChannelFilterData> => {
  const { direction, attribute } = sortFilter
  return data.sort((a, b) => {
    if (direction === 'asc') {
      if (attribute === 'date_time') {
        return moment(a[attribute]).valueOf() - moment(b[attribute]).valueOf()
      }
      return a[attribute] - b[attribute]
    } else {
      if (attribute === 'date_time') {
        return moment(b[attribute]).valueOf() - moment(a[attribute]).valueOf()
      }
      return b[attribute] - a[attribute]
    }
  })
}

export const crrAccessState = (
  merchant: Merchant
): number | 'coming_soon' | 'available' => {
  if (!merchant.live_at) return 'coming_soon'
  const liveAt = moment(merchant.live_at)
  const targetDate = liveAt.clone().add(1, 'month').startOf('month')
  const now = moment()

  if (now.isSameOrAfter(targetDate)) {
    return 'available'
  }

  return Math.ceil(targetDate.diff(now, 'days', true))
}
