import Counter from 'components/Counter'
import SearchablePaginatedContent from 'components/SearchablePaginatedContent'
import { buildTranslate } from 'locales'
import { useCallback } from 'react'
import { numberFormatter } from 'utilities/formatters'
import { useUsersTable } from './hooks'
import Table from './Table'

const t = buildTranslate('users.user_list')

const Content = () => {
  const {
    numPages,
    currentPage,
    onChangePage,
    isFetchingUsers,
    totalMembershipCount,
    users,
    setSearchQuery,
  } = useUsersTable()

  const onChangeSearchInput = useCallback(
    (value: string) => {
      setSearchQuery(value)
    },
    [setSearchQuery]
  )

  return (
    <div className={'mb-l'}>
      <div className={'d-flex'}>
        <Counter
          primaryValue={numberFormatter(totalMembershipCount)}
          isLoading={isFetchingUsers}
          name={t('total_users')}
          className={'mb-xl'}
          sections={[
            {
              content: t('total_users_popover'),
            },
          ]}
        />
      </div>
      <SearchablePaginatedContent
        contentIsLoading={isFetchingUsers}
        onChangePage={onChangePage}
        onChangeSearchInput={onChangeSearchInput}
        currentPage={currentPage}
        numberOfPages={numPages}
        searchPlaceholder={t('search_placeholder')}
      >
        <Table users={users} isLoading={isFetchingUsers} />
      </SearchablePaginatedContent>
    </div>
  )
}

export default Content
