import palette from 'constants/palette'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'

type Props = {
  metricData: { [key: string]: number }
  title: string
  summaryText: string
  xAxisTitle: string
  yAxisTitle: string
  totalKey: string
  xAxisFormatter: (value: number) => string
  yAxisFormatter: (value: number) => string
  backgroundClass?: string
  consolidateMinPercentage?: number
  consolidateMinColumns?: number
}

const ColumnChartMetric: React.FC<Props> = props => {
  const {
    metricData,
    title,
    summaryText,
    xAxisTitle,
    yAxisTitle,
    totalKey,
    xAxisFormatter,
    yAxisFormatter,
    backgroundClass,
    consolidateMinPercentage,
    consolidateMinColumns,
  } = props
  const categories = Object.keys(metricData).filter(k => k !== totalKey)
  const minColIndex = consolidateMinColumns
    ? categories.length - consolidateMinColumns
    : categories.length
  const minAmount = consolidateMinPercentage
    ? metricData[totalKey] * consolidateMinPercentage
    : 0

  // Consolidates cohorts from right to left until the first cohort that represents >= consolidateMinPercentage
  // or has a non-zero value or reaches minimum number of columns.
  let consolidateAmount = 0

  const consolidatedData = categories
    .slice()
    .reverse()
    .reduce((data, category, i) => {
      const amount = metricData[category]

      if (amount <= minAmount && i < minColIndex) {
        consolidateAmount += amount
      } else if (consolidateAmount > 0 || i === minColIndex) {
        data[`${category.split('-')[0]} +`] = amount + consolidateAmount
        consolidateAmount = 0
      } else {
        data[category] = amount
      }

      return data
    }, {})

  const formattedCategories = Object.keys(consolidatedData)
    .reverse()
    .map(c => {
      const ends = c.split('-')
      return ends.length > 1 && ends[0] === ends[1] ? ends[0] : c
    })

  const options: Highcharts.Options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Lato',
        overflow: 'visible !important',
      },
    },
    title: undefined,
    credits: { enabled: false },
    tooltip: {
      formatter: function () {
        return yAxisFormatter(this.y)
      },
      positioner: function (labelWidth, labelHeight, point) {
        return {
          x: point.plotX + this.chart.plotLeft - labelWidth / 2,
          y: point.plotY + this.chart.plotTop - labelHeight - 16,
        }
      },
      borderWidth: 0,
    },
    xAxis: {
      categories: formattedCategories,
      title: {
        text: xAxisTitle,
        margin: 25,
        style: { 'font-weight': 'bold', color: palette.grey90 },
      },
      labels: {
        formatter: function () {
          return xAxisFormatter(this.value as number)
        },
        style: {
          color: palette.grey70,
        },
      },
      tickLength: 0,
      lineColor: palette.grey30,
    },
    yAxis: {
      min: 0,
      title: {
        text: yAxisTitle,
        margin: 25,
        style: { 'font-weight': 'bold', color: palette.grey90 },
      },
      labels: {
        formatter: function () {
          return yAxisFormatter(this.value as number)
        },
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
      gridLineDashStyle: 'LongDash',
      gridLineColor: palette.grey40,
    },
    series: [
      {
        showInLegend: false,
        data: Object.values(consolidatedData).reverse() as any,
        type: 'column',
      },
    ],
  }

  return (
    <div>
      <div className="small-caps-5 margin-bottom-micro">
        <span>{title}</span>
      </div>
      <div className="margin-bottom-medium">
        <span className="grey-70">{summaryText}</span>
      </div>
      <div
        className={`highchart-section ${
          backgroundClass ? backgroundClass : ''
        }`}
      >
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  )
}

export default ColumnChartMetric
