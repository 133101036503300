import CounterComponent from 'components/Counter'
import { buildTranslate } from 'locales'
import React from 'react'
import { numberFormatter } from 'utilities/formatters'

type PropsT = {
  count: number
  isLoading: boolean
  isErrored: boolean
}

const t = buildTranslate('purchases.tabs.first_purchases.counter')

const Counter: React.FC<PropsT> = ({ count, isLoading, isErrored }) => {
  return (
    <CounterComponent
      className={'mb-l'}
      primaryValue={numberFormatter(count)}
      isErrored={isErrored}
      isLoading={isLoading}
      name={t('subtitle')}
    />
  )
}

export default Counter
