import { Button } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate(`thanx_campaigns.builder.steps.setup.messaging`)

type PropsT = {
  messageType?: string
  onClick?: () => void
}

const RemoveButton: React.FC<PropsT> = ({ messageType = 'email', onClick }) => {
  const [css] = useStyletron()
  return (
    <Button
      kind="minimal"
      className={css({
        color: '#fff',
        fontWeight: 'normal',
      })}
      onClick={() => onClick?.()}
    >
      {t(`${messageType}.remove`)}
    </Button>
  )
}

export default RemoveButton
