import { Text } from '@thanx/uikit/text'
import { StoredValueConfig } from 'api/storedValue'
import Badges from 'components/Badges'
import Section from 'components/Section'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import { useStyletron } from 'styletron-react'

const t = buildTranslate('stored_value.stored_value_configuration.card_images')

type Props = {
  config: StoredValueConfig
}

const CardImages = ({ config }: Props) => {
  const [css] = useStyletron()

  return (
    <Section className="pt-m px-m mb-m">
      <Text.Header4 className="mb-m mt-0">{t('title')}</Text.Header4>

      {config.card_image?.medium && (
        <>
          <div className="mb-xs">
            <Text.BodyText4 bold>{t('image.title')}</Text.BodyText4>
          </div>

          <div className="mb-xs">
            <Text.BodyText4>{t('image.description')}</Text.BodyText4>
          </div>

          <div className="mb-xs">
            <Badges values={[t('image.size'), t('image.ext')]} />
          </div>

          <div className="mb-m">
            <img
              src={config.card_image.medium}
              alt={t('image.title')}
              className={css({
                width: '300px',
                borderRadius: '10px',
                borderWidth: '1px',
                borderColor: palette.grey20,
              })}
            />
          </div>
        </>
      )}

      {config.card_icon_image?.medium && (
        <>
          <div className="mb-xs">
            <Text.BodyText4 bold>{t('icon.title')}</Text.BodyText4>
          </div>

          <div className="mb-xs">
            <Text.BodyText4>{t('icon.description')}</Text.BodyText4>
          </div>

          <div className="mb-xs">
            <Badges values={[t('icon.size'), t('icon.ext')]} />
          </div>

          <div className="mb-m">
            <img
              src={config.card_icon_image.medium}
              alt={t('icon.title')}
              className={css({
                width: '24px',
                borderRadius: '1px',
              })}
            />
          </div>
        </>
      )}
    </Section>
  )
}

export default CardImages
