import { buildTranslate } from 'locales'
import {
  CouponPoolFields as CouponPoolFileUpload,
  State,
} from 'models/FileUpload'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React from 'react'
import { useUserTimeZone } from 'utilities/userTimeZone'

type Props = {
  className?: string
  uploads: CouponPoolFileUpload[]
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes.upload.uploads_list'
)

const UploadsList: React.FC<Props> = props => {
  const { className = '', uploads } = props

  const timeZone = useUserTimeZone()

  function formatTime(time?: string): string {
    if (!time) return ''
    return moment.tz(time, timeZone).format('MM/DD/YYYY')
  }

  function getText(upload: CouponPoolFileUpload): string | null {
    if (upload.state === State.PROCESSING) {
      return t('processing')
    }
    if (upload.state === State.ERRORED) {
      return t('errored', { date: formatTime(upload.errored_at) })
    }
    if (upload.state === State.FINALIZED) {
      return t('finalized', {
        date: formatTime(upload.finalized_at),
        count: numeral(upload.settings.row_count).format('0,0'),
      })
    }

    return null
  }

  return (
    <div className={className}>
      <div className="body-text-4 grey-90 bold mb-xs">{t('title')}</div>
      {uploads.map(upload => (
        <div key={upload.id} className="body-text-4 grey-70">
          {getText(upload)}
        </div>
      ))}
    </div>
  )
}

export default UploadsList
