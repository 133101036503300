import { Switch } from '@thanx/uikit/switch'
import IconMagic from 'assets/images/campaign-center/IconMagic'
import BulletPoint from 'components/BulletPoint'
import { buildTranslate } from 'locales'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import type { Fields as Campaign } from 'models/Campaign'
import moment from 'moment-timezone'
import React from 'react'
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import ThanxOptimization from 'scenes/CampaignCenter/Builder/components/ThanxOptimization'
import { useVariant } from 'scenes/CampaignCenter/Builder/components/VariantContext'
import { textUtils } from 'utilities/textUtils'
import { timeUtils } from 'utilities/timeUtils'
import { fractionalDays } from 'utilities/timeUtils'
import userTimeZone from 'utilities/userTimeZone'

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_summary'
)

const dayMapping = {
  redeem_restriction_monday: 'Mondays',
  redeem_restriction_tuesday: 'Tuesdays',
  redeem_restriction_wednesday: 'Wednesdays',
  redeem_restriction_thursday: 'Thursdays',
  redeem_restriction_friday: 'Fridays',
  redeem_restriction_saturday: 'Saturdays',
  redeem_restriction_sunday: 'Sundays',
}

type Props = {
  campaign: Campaign
  titleColor?: string
  showEdit?: boolean
  alwaysShow?: boolean
  editDisabled?: boolean
  redeemType?: string
  onEdit?: () => void
  validate?: () => void
}

const RestrictionSummary: React.FC<Props> = props => {
  const {
    campaign,
    titleColor,
    showEdit,
    alwaysShow,
    editDisabled,
    redeemType,
    onEdit,
    validate,
  } = props
  const variant = useVariant()
  const { config } = useCampaignConfig()
  const variantConfig = variant || config
  const {
    redeem_restriction_locations_description,
    redeem_restriction_description,
    redeem_minimum,
    redeem_maximum,
    redeem_venue,
    redeem_restriction_time_range,
    redeem_experience_expires_in_days,
    redeem_type,
  } = variantConfig
  const {
    config_signup_required,
    config_target_subscriber,
    redeem_end_at,
    redeem_retire_after_days,
    redeem_start_at,
    type,
  } = campaign

  const isExperience = redeem_type?.includes('experience')

  function retireAfterDays() {
    if (typeof redeem_retire_after_days === 'number') {
      return (
        <span>
          {t('retire_after_days', { days: redeem_retire_after_days })}
          <OverlayTrigger
            placement="right"
            overlay={optimization('retire_after_days')}
          >
            <span className="padding-left-small">
              <IconMagic />
            </span>
          </OverlayTrigger>
        </span>
      )
    }
    return null
  }

  function optimization(category: string) {
    return (
      <Tooltip id="optimization-tooltip" className="text-left">
        <ThanxOptimization
          title={t('optimizations.title')}
          body={t(`optimizations.${category}`, {
            campaignName: type,
            days: redeem_retire_after_days,
          })}
        />
      </Tooltip>
    )
  }

  function filterLocations() {
    if (!redeem_restriction_locations_description) return null

    return (
      <span>
        {t('locations', {
          locations: redeem_restriction_locations_description,
        })}
        <OverlayTrigger placement="right" overlay={optimization('locations')}>
          <span className="padding-left-small">
            <IconMagic />
          </span>
        </OverlayTrigger>
      </span>
    )
  }

  function signupRequired() {
    if (!isExperience && config_signup_required && config_target_subscriber) {
      return (
        <span>
          {t('signup_required')}
          <OverlayTrigger
            placement="right"
            overlay={optimization('signup_required')}
          >
            <span className="padding-left-small">
              <IconMagic />
            </span>
          </OverlayTrigger>
        </span>
      )
    }
    return null
  }

  function restrictionDescription(): string {
    if (redeem_restriction_description) {
      return `“${redeem_restriction_description}”`
    }
    return t('day_part.generic')
  }

  function dayPartStartEnd() {
    let startString = ''
    let endString = ''
    let dayString = ''
    let startTimeString = ''
    let endTimeString = ''
    let days: (string | undefined)[] = []

    if (redeem_start_at) {
      startString = t('day_part.start_at', {
        startAt: moment(redeem_start_at)
          .tz(userTimeZone())
          .format('ddd[,] MMM Do YYYY'),
      })
    }

    if (redeem_end_at) {
      endString = t('day_part.end_at', {
        endAt: moment(redeem_end_at)
          .tz(userTimeZone())
          .format('ddd[,] MMM Do YYYY'),
      })
    }

    if (redeem_restriction_time_range) {
      const restrictions: { [key: string]: number[] } = pickBy(
        pick(variantConfig, Object.keys(dayMapping)),
        value => {
          return !isEmpty(value)
        }
      ) as { [key: string]: number[] }
      let restrictionKeys = Object.keys(restrictions)

      if (restrictionKeys.length > 0) {
        const timeRange: number[] = Object.values(restrictions)[0]

        if (timeRange.length > 0) {
          startTimeString = t('day_part.start_time_string', {
            startTimeString: timeUtils.hourLabel(timeRange[0], false),
          })
          endTimeString = t('day_part.end_time_string', {
            endTimeString: timeUtils.hourLabel(
              last(timeRange),
              true,
              moment.tz(userTimeZone()).format('z')
            ),
          })
        }

        days = Object.values(pick(dayMapping, restrictionKeys))
        if (!isEmpty(days)) {
          let daySentence = textUtils.toSentence({
            array: days as string[],
            punctuation: '',
          })
          if (startString || endString) {
            dayString = t('day_part.day_string', { dayString: daySentence })
          } else {
            dayString = daySentence as string
          }
        }
      }
    }

    if (
      !isEmpty(
        startString + endString + dayString + startTimeString + endTimeString
      )
    ) {
      if (days.length === 7) {
        return (
          <Translate
            value="thanx_campaigns.builder.steps.incentive.restriction_summary.day_part.time_range_joined"
            restrictionTime={startTimeString + endTimeString}
            dangerousHTML
          />
        )
      } else {
        return (
          <Translate
            value="thanx_campaigns.builder.steps.incentive.restriction_summary.day_part.joined"
            description={restrictionDescription()}
            startAt={startString}
            endAt={endString}
            restrictionDays={dayString}
            restrictionTime={startTimeString + endTimeString}
            dangerousHTML
          />
        )
      }
    }

    return null
  }

  function minAmount(): string | null {
    if (redeem_minimum) {
      return t('min', { minAmount: redeem_minimum })
    }
    return null
  }

  function maxAmount(): string | null {
    if (redeem_maximum) {
      return t('max', { maxAmount: redeem_maximum })
    }
    return null
  }

  function experienceExpiry(): string | null {
    if (redeem_experience_expires_in_days) {
      const invertedFractionalDays = fractionalDays(true)
      let timePeriod
      if (redeem_experience_expires_in_days < 1) {
        const hours = invertedFractionalDays[redeem_experience_expires_in_days]
        timePeriod = `${hours} ${t('hours')}`
        if (hours === 1) timePeriod = `1 ${t('hour')}`
      } else {
        timePeriod =
          redeem_experience_expires_in_days === 1
            ? `1 ${t('day')}`
            : `${redeem_experience_expires_in_days} ${t('days')}`
      }
      return t('experience_expiry', {
        time: timePeriod,
      })
    }
    return null
  }

  function venue() {
    const venue = redeem_venue || 'all'
    return (
      <Translate
        value={`thanx_campaigns.builder.steps.incentive.restriction_creator.redemption_venue.restriction.${venue}`}
      />
    )
  }

  function getRestrictions(): React.ReactNode[] {
    if (redeemType === 'points/static') {
      return compact([retireAfterDays(), dayPartStartEnd()])
    }
    return compact([
      retireAfterDays(),
      filterLocations(),
      signupRequired(),
      dayPartStartEnd(),
      minAmount(),
      maxAmount(),
      venue(),
      experienceExpiry(),
    ])
  }

  const restrictions = getRestrictions()
  if (restrictions.length === 0 && !alwaysShow) return null
  return (
    <div>
      <Row>
        <Col xs={6}>
          <div className={`bold body-text-4 ${titleColor || ''}`}>
            {t('title')}
          </div>
        </Col>
        <Col xs={6}>
          <div className="pull-right">
            <Switch condition={!!showEdit}>
              <Button
                bsStyle="link"
                onClick={editDisabled ? validate : onEdit}
                className={`pull-right text-right padding-none ${
                  editDisabled ? 'disabled' : ''
                }`}
              >
                {I18n.t(
                  'thanx_campaigns.builder.steps.incentive.restriction_creator.button'
                )}
              </Button>
            </Switch>
          </div>
        </Col>
      </Row>
      {restrictions.map((restriction, i) => (
        <BulletPoint key={i} className="pt-xs body-text-4">
          {restriction}
        </BulletPoint>
      ))}
      <Switch condition={restrictions.length === 0 && !!alwaysShow}>
        <span className="grey-40 padding-top-medium">
          <em>{t('empty')}</em>
        </span>
      </Switch>
    </div>
  )
}

export default RestrictionSummary
