import { Fields as LocationFields } from 'models/Location'
import { Fields as CategoryFields } from 'models/LocationCategory'

//TODO: Fix type https://thanxapp.atlassian.net/browse/MRC-899
export function filterCategorizedLocations(
  locations: LocationFields[],
  categories: CategoryFields[]
) {
  const uncategoriezedLocations = locations.filter(
    location => !location.category_id
  )
  const validCategories = categories.filter(category =>
    locations.some(location => location.category_id === category.id)
  )

  return [uncategoriezedLocations, validCategories]
}
