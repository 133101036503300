import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('thanx_campaigns.newCampaign')

interface UserCountDisplayProps {
  isLoading: boolean
  count: number | null
}

const UserCountDisplay = ({ isLoading, count }: UserCountDisplayProps) => {
  if (isLoading) {
    return (
      <>
        <span className="fa fa-spin fa-circle-o-notch mr-xs" />
        {t('reach.fetching')}
      </>
    )
  }

  const hasValidCount = count !== null && !isNaN(count)
  if (!hasValidCount) {
    return (
      <Text.BodyText4 tag="p" color="red">
        {t('reach.error')}
      </Text.BodyText4>
    )
  }

  return (
    <Text.BodyText4 tag="p" color="grey70">
      {t('reach.details', { count: count.toLocaleString() })}
    </Text.BodyText4>
  )
}

export default UserCountDisplay
