export default {
  stubs: {
    coming_soon: 'Coming soon',
    help_link: 'All upcoming updates',
    icon_alt: 'learn',
    capture_rate: {
      title: 'Revenue capture rate',
      subtitle:
        'How much of your monthly revenue comes from your loyalty members',
      page_title: 'Thanx Dashboard - Revenue capture rate',
    },
    purchases: {
      title: 'Purchases',
      subtitle: 'Purchasing behavior of your loyalty members',
    },
    retention_rate: {
      title: 'Retention rate',
      subtitle: 'Activated customers that keep returning to make purchases.',
      page_title: 'Thanx Dashboard - Retention rate',
    },
  },
  dateRangeInputs: {
    from_date: 'From',
    to_date: 'To',
    error: 'From date cannot be greater than to date.',
  },
  benchmark_tooltip: {
    caption: [
      'Benchmark data for brands in the ',
      ' industry',
      'Benchmark data for brands across all industries',
    ],
    timezone: 'Data is shown in your timezone (%{timeZone})',
  },
  table: {
    show_more: 'Show more',
    show_less: 'Show less',
  },
  activity: {
    page_title: 'Activity report | Thanx',
    not_enough_data: 'There isn’t enough program activity to report on yet.',
    header: {
      title: 'Activity Report',
      description:
        'Statistics about your program’s usage. View your customers’ purchase behavior, reward redemption, and more.',
    },
    trajectory_text:
      'The arrow indicator shows current trajectory, e.g. were the last 30 days better or worse than the average for the year?',
    sections: {
      customers: {
        title: 'Customers',
        total_customers_popover:
          'Any customer who has given you their email address and opted in to receive marketing',
        metrics: {
          membership: {
            title: 'Customers in database',
            summary_text:
              'Total customers in your database to whom you can market',
            bucket_text: 'New customers in the last',
          },
          purchasers: {
            title: 'Customers who made a purchase',
            summary_text:
              'Total connected customers who have made a purchase captured by Thanx',
            bucket_text: 'Customers who purchased in the last',
          },
          rewards: {
            title: 'Customers and loyalty rewards',
            bucket_text: 'In the last',
            summary_text: 'Total customers',
            earned_text: 'Customers who earned loyalty rewards',
            redeemed_text: 'Customers who redeemed loyalty rewards',
          },
          funnel: {
            title: 'Customers in database',
            summary_text: 'Total customers you can market to',
            bucket_text: 'Customer funnel',
            add_more: 'Add more',
            import: 'Import',
            subscribers: 'Email subscribers (excludes loyalty members)',
            loyalty_members: 'Loyalty members',
            connected_customers: 'Who linked their card',
            purchasers: 'Who made a purchase',
          },
        },
      },
      transactions: {
        title: 'Transactions',
        metrics: {
          average_visits: {
            title: 'Average visits per customer',
            summary_text:
              'Average number of visits among customers who visited',
            bucket_text: 'Average number of visits in the the last',
          },
          membership_purchases: {
            title: '% of total connected customers who purchased',
            summary_text:
              'Percentage of your total connected customers that made a purchase',
            bucket_text: '% that made a purchase in the last',
          },
          average_check: {
            title: 'Average customer check',
            summary_text: 'Overall',
            bucket_text: 'In the last',
            all_customers_text: 'All customers',
            quartile_1_text: 'Top 25%',
            quartile_2_text: '25-50%',
            quartile_3_text: '50-75%',
          },
          purchases: {
            title: '# purchases per customer — last 365 days',
            summary_text: '(only customers with at least 1 purchase)',
            y_axis_title: 'Customers',
            x_axis_title: 'Purchases',
          },
          total_spend: {
            title: 'Average total spend per customer — to date',
            summary_text:
              'Average total spend per customer of all customer who have made a transaction',
            overall_qualifier: 'in past year',
          },
          total_spend_per_user: {
            title: 'TOTAL SPEND PER CUSTOMER — LAST 365 DAYS',
            summary_text: '(only customers with at least 1 purchase)',
            y_axis_title: 'Customers',
            x_axis_title: 'Total spend',
          },
        },
      },
      rewards: {
        title: 'Rewards',
        metrics: {
          earned_loyalty_rewards: {
            title: 'Number of loyalty rewards earned',
            summary_text: 'Total number of loyalty rewards earned by customers',
            bucket_text: 'Loyalty rewards earned in the last',
          },
          redeemed_loyalty_rewards: {
            title: 'Number of loyalty rewards redeemed',
            summary_text:
              'Total number of loyalty rewards redeemed by customers',
            bucket_text: 'Loyalty rewards redeemed in the last',
          },
          outstanding_rewards: {
            title: 'Outstanding rewards',
            count_summary_text: 'Total outstanding rewards',
            count_loyalty_text: 'loyalty only',
            amount_summary_text:
              'Liability of outstanding rewards — all programs',
          },
          redemption: {
            title: 'Redemption',
            summary_text: 'Average discount per redemption',
            bucket_text: 'Average time to redemption',
            units: 'days',
            buckets: {
              loyalty: 'Loyalty rewards',
              all: 'All campaigns',
              other: 'All other campaigns',
            },
          },
          loyalty_rewards_per_user: {
            title: '# of loyalty rewards earned per customer',
            summary_text: '(of customers who earned at least one)',
            y_axis_title: 'Customers',
            x_axis_title: 'Rewards earned',
          },
        },
      },
    },
  },
  customer: {
    title: 'Signups',
    tabs: {
      all_customers: {
        title: 'All customers',
        title_tag: 'All customers - Signups | Thanx',
      },
      loyalty_members: {
        title: 'Loyalty members',
        title_tag: 'Loyalty members - Signups | Thanx',
      },
    },
  },
  customer_attributes: {
    title: 'Member attributes',
    description: 'View all the known attributes about your customers',
    col_one: 'Attribute',
    col_two: 'Type',
    sections: {
      spend: {
        section: 'Spend and Frequency',
        attributes: {
          0: {
            name: 'Member since (first date email added to database)',
            source: 'Calculated',
          },
          1: { name: 'Date of first tracked purchase', source: 'Calculated' },
          2: { name: 'Date of most recent purchase', source: 'Calculated' },
          3: { name: 'Avg. spend (lifetime)', source: 'Calculated' },
          4: {
            name: 'Avg. visits per month (last 90 days)',
            source: 'Calculated',
          },
          5: { name: 'Avg. visits per month (lifetime)', source: 'Calculated' },
          6: { name: 'Current frequency (last 90 days)', source: 'Calculated' },
          7: { name: '(daily, weekly, biweekly, etc)"', source: 'Calculated' },
          8: {
            name: 'Visited at least 1 times in last 30 days',
            source: 'Calculated',
          },
          9: {
            name: 'Visited at least 1 times in last 90 days',
            source: 'Calculated',
          },
          10: {
            name: 'Visited at least 2 times in last 90 days',
            source: 'Calculated',
          },
          11: {
            name: 'Spent at least $500 in last 180 days',
            source: 'Calculated',
          },
          12: { name: 'Preferred daypart', source: 'Calculated' },
          13: { name: 'Preferred weekpart', source: 'Calculated' },
          14: {
            name: 'Big spender (e.g. avg check > 4x merchant average)',
            source: 'Calculated',
          },
        },
      },
      sku: {
        section: 'SKU',
        attributes: {
          0: { name: 'Favorite item', source: 'Calculated' },
          1: {
            name: 'Most recent server/staff interaction',
            source: 'Calculated',
          },
          2: {
            name: 'Has made X or more purchases from Y category',
            source: 'Calculated',
          },
          3: {
            name: 'Has made X or fewer purchases from Y category',
            source: 'Calculated',
          },
          4: {
            name: 'Purchases X category or item less than Y% of purchases',
            source: 'Calculated',
          },
          5: {
            name: 'Purchases X category or item more than Y% of purchases',
            source: 'Calculated',
          },
        },
      },
      lifetime: {
        section: 'Lifetime Value and Classification',
        attributes: {
          0: { name: 'Lifetime captured spend', source: 'Calculated' },
          1: { name: 'Estimated lifetime value', source: 'Calculated' },
          2: { name: 'Customer trajectory', source: 'Calculated' },
          3: { name: 'Customer status', source: 'Calculated' },
          4: {
            name: 'Current tier (Bronze, Silver, Gold)',
            source: 'Calculated',
          },
          5: { name: 'Is top 10% VIP', source: 'Calculated' },
          6: { name: 'Churned/lost', source: 'Calculated' },
          7: { name: 'Quartile', source: 'Calculated' },
          8: { name: 'Loyalty Member?', source: 'Calculated' },
          9: { name: 'Email Subscriber?', source: 'Calculated' },
        },
      },
      rewards: {
        section: 'Rewards',
        attributes: {
          0: { name: 'Zip code', source: 'User provided' },
          1: { name: 'Favorite store (Thanx)', source: 'Calculated' },
          2: { name: 'Favorite store (user)', source: 'User provided' },
          3: { name: 'Single or multi-store', source: 'Calculated' },
          4: {
            name: 'Home address / neighborhood (Thanx)',
            source: 'Calculated',
          },
          5: { name: 'Home address (user)', source: 'User provided' },
          6: {
            name: 'Work address / neighborhood (Thanx)',
            source: 'Calculated',
          },
          7: { name: 'Work address (user)', source: 'User provided' },
          8: {
            name: 'Home proximity to closest store (mi)',
            source: 'Calculated',
          },
          9: {
            name: 'Work proximity to closest store (mi)',
            source: 'Calculated',
          },
          10: {
            name: 'Average drive-bys (< 1 mi from a store location w/o a purchase) per month',
            source: 'Calculated',
          },
        },
      },
      satisfaction: {
        section: 'Satisfaction',
        attributes: {
          0: { name: 'Most recent NPS', source: 'Calculated' },
          1: { name: 'Average NPS (ever)', source: 'Calculated' },
          2: { name: 'Average NPS (last 90 days)', source: 'Calculated' },
          3: {
            name: 'Received a response to most recent NPS?',
            source: 'Calculated',
          },
          4: {
            name: 'Received a reward with most recent NPS?',
            source: 'Calculated',
          },
          5: {
            name: 'Returned since last NPS submission?',
            source: 'Calculated',
          },
        },
      },
      communication: {
        section: 'Communication and Rewards',
        attributes: {
          0: { name: 'Receives app notifications?', source: 'Calculated' },
          1: { name: 'Receives emails?', source: 'Calculated' },
          2: { name: 'Receives SMS?', source: 'Calculated' },
          3: {
            name: 'Preferred communication channel (Thanx)',
            source: 'Calculated',
          },
          4: {
            name: 'Preferred communication channel (user)',
            source: 'User provided',
          },
          5: {
            name: 'Likelihood to respond to reward of $X',
            source: 'Calculated',
          },
        },
      },
      demographics: {
        section: 'Demographics',
        attributes: {
          0: { name: 'Gender', source: 'User provided' },
          1: { name: 'Age', source: 'User provided' },
          2: { name: 'Wealth / salary', source: 'User provided' },
          3: { name: 'Married?', source: 'User provided' },
          4: { name: 'Kids?', source: 'User provided' },
          5: {
            name: 'Dietary restrictions (vegan?, etc)',
            source: 'User provided',
          },
        },
      },
      profile: {
        section: 'Profile',
        attributes: {
          0: { name: 'Name', source: 'User provided' },
          1: { name: 'Email', source: 'User provided' },
          2: { name: 'Phone', source: 'User provided' },
        },
      },
    },
  },
  daily_activity: {
    title: 'Daily activity',
    tabs: {
      signups: {
        title: 'Signups',
        title_tag: 'Daily signups | Thanx',
      },
      purchases: {
        title: 'Purchases',
        title_tag: 'Daily purchases | Thanx',
      },
      redemptions: {
        title: 'Redemptions by program',
        title_tag: 'Daily reward redemptions | Thanx',
      },
      app_downloads: {
        title: 'App downloads',
        title_tag: 'Daily app downloads | Thanx',
      },
    },
  },
  reward_activity: {
    title: 'Reward activity',
    tabs: {
      reward_usage: {
        title: 'Reward usage',
        title_tag: 'Reward usage - Reward activity | Thanx',
      },
      campaign_activity: {
        title: 'Activity by campaign',
        title_tag: 'Reward activity by campaign - Reward activity | Thanx',
      },
      estimated_costs: {
        title: 'Estimated costs',
        title_tag: 'Estimated reward costs - Reward activity | Thanx',
      },
    },
  },
  not_ready_yet: {
    title: 'This report is not ready yet',
    coming_soon:
      "You'll be able to view this report %{days} days after the first complete calendar month following your launch date.",
    available_in_x_days: "You'll be able to view this report in %{days} days.",
    available_after_launch:
      "You'll be able to view this report after your launch date.",
  },
}
