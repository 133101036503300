import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import StopwatchIcon from 'assets/images/campaign-center/builder/img_stopwatch.svg'
import { buildTranslate } from 'locales'
import { CampaignStateT, MinimalVariant } from 'models/Campaign'
import moment from 'moment-timezone'
import React from 'react'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { getFormattedDate } from 'utilities/date'

const t = buildTranslate('thanx_campaigns.report.v3.test')
const tDate = buildTranslate('dateFormat')

type PropsT = {
  campaignState: CampaignStateT
  startAt: string
  endAt: string | null
  testEndedAt: string | null
  timezone: string
  winningVariantId: number | null
  variants: MinimalVariant[]
}

const Chart: React.FC<PropsT> = ({
  campaignState,
  startAt,
  endAt,
  testEndedAt,
  timezone,
  winningVariantId,
  variants,
}) => {
  const { attributionWindow } = useAttributionWindow()

  const formatDate = (dateTimeStr: string | null): string => {
    const dateTime = moment(dateTimeStr).tz(timezone)
    return tDate('dateAtTime', {
      date: getFormattedDate(dateTime, 'daily'),
      time: dateTime.format('hh:mma z'),
    })
  }

  const startDate = formatDate(startAt)
  const endDate = formatDate(testEndedAt || endAt)
  let text: string | null = null
  if (campaignState === 'active') {
    text =
      !winningVariantId && !testEndedAt
        ? t('test_started', { start: startDate })
        : t('ended_description', {
            start: startDate,
            end: endDate,
            count: attributionWindow,
          })
  } else if (campaignState === 'complete') {
    text = t('test_ran', { start: startDate, end: endDate })
  }
  const winningVariant = !!winningVariantId
    ? variants.find(v => v.id === winningVariantId)
    : null
  const name = winningVariant?.meta?.name
  const showSending = !!winningVariantId && campaignState === 'active'
  const multiLineClass = showSending
    ? 'align-items-start'
    : 'align-items-center'

  return (
    <div className={`grey-10-bkg d-flex w-100 p-m ${multiLineClass}`}>
      <img src={StopwatchIcon} className="mr-xs" alt="stopwatch" />
      <div>
        <Text.BodyText4 color="grey70" tag="div">
          {text}
        </Text.BodyText4>
        <Switch condition={showSending}>
          <Text.BodyText4 color="grey70" tag="div">
            {t('variant_sending', { name })}
          </Text.BodyText4>
        </Switch>
      </div>
    </div>
  )
}

export default Chart
