import { FormsyInput } from '@thanx/uikit/input'
import { FormsySelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import Help from 'components/Help'
import Modal from 'components/ModalContainer'
import PopoverContent from 'components/PopoverContent'
import Formsy from 'formsy-react'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import { useState } from 'react'
import Helmet from 'react-helmet'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { helpArticles } from 'scenes/Points/Landing/Configuration/helpers'
import Card from './Card'
import Layout from './Layout'
import useUpdate from './useUpdate'

const MIN_EXPIRATION = 1
const MAX_EXPIRATION = 999

const t = buildTranslate('points.configuration')
const vt = buildTranslate('validations')

type Props = { pointsExperience: PointsExperience }

function Expiration(props: Props) {
  const { pointsExperience } = props
  const [css] = useStyletron()
  const fixedPeriodPointExpiryEnabled = useFlag(
    'enable-fixed-period-points-expiry',
    false
  )

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const dispatch = useDispatch()

  const {
    formModel,
    updateFormModel,
    formState,
    setFormState,
    publish,
    isUpdating,
  } = useUpdate(pointsExperience.id, (pe: PointsExperience) => ({
    points_expires_in_months: pe.points_expires_in_months,
    expiration_type: pe.expiration_type || 'inactivity',
  }))

  if (!formModel) return null

  async function doPublish() {
    const result = await publish()

    if (result.isErr()) {
      dispatch(
        alert({
          key: 'danger',
          message: t('edit.publish_failed'),
        })
      )
      return
    }

    dispatch(push('/points#expiration'))
    dispatch(
      alert({
        key: 'success',
        message: t('edit.publish_success'),
        timeout: 5,
      })
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('edit.expiration.page_title')}</title>
      </Helmet>
      <Layout
        backLink="/points#expiration"
        canPublish={formState === 'valid'}
        onPublish={() => setIsConfirmModalOpen(true)}
      >
        <Formsy
          onChange={updateFormModel}
          onInvalid={() => setFormState('invalid')}
          onValid={() => setFormState('valid')}
          role="form"
        >
          <Text.Header2 className="mb-xl mt-0">
            {t('edit.expiration.page_title')}
          </Text.Header2>
          <Card>
            <Switch condition={fixedPeriodPointExpiryEnabled}>
              <FormsySelect
                aria-label={t('labels.select_ordering_location')}
                className="mt-s mb-m"
                name="expiration_type"
                value={formModel.expiration_type}
                options={[
                  {
                    label: t('edit.expiration.types.inactivity.label'),
                    value: 'inactivity',
                  },
                  {
                    label: t('edit.expiration.types.fixed_period.label'),
                    value: 'fixed_period',
                  },
                ]}
                validations={{
                  isRequired: true,
                }}
              />
            </Switch>
            <div className="d-flex">
              <FormsyInput
                // @ts-ignore
                type="number"
                name="points_expires_in_months"
                value={formModel.points_expires_in_months}
                label={
                  <Text.BodyText3 color="grey70" tag="span">
                    {t(
                      `edit.expiration.types.${formModel.expiration_type}.content_part_one`
                    )}
                  </Text.BodyText3>
                }
                className={css({
                  display: 'grid',
                  gridTemplateColumns: '100px 170px',
                  gridGap: '10px',
                  alignItems: 'center',
                })}
                min={1}
                endEnhancer={t('edit.expiration.unit')}
                validations={{
                  isRequired: true,
                  isInt: true,
                  greaterThanOrEqual: MIN_EXPIRATION,
                  lessThanOrEqual: MAX_EXPIRATION,
                }}
                validationErrors={{
                  greaterThanOrEqual: vt('greater_than_or_equal', {
                    value: MIN_EXPIRATION,
                  }),
                  lessThanOrEqual: vt('less_than_or_equal', {
                    value: MAX_EXPIRATION,
                  }),
                  isRequired: vt('is_required'),
                  isInt: vt('is_int'),
                }}
              />
              <div className="d-flex" style={{ height: '48px' }}>
                <Text.BodyText3
                  color="grey70"
                  className="ml-xs align-self-center"
                  tag="span"
                >
                  {t(
                    `edit.expiration.types.${formModel.expiration_type}.content_part_two`
                  )}
                </Text.BodyText3>
                <span className="ml-xs align-self-center">
                  <Switch
                    condition={formModel.expiration_type === 'inactivity'}
                  >
                    <PopoverContent
                      sections={[
                        {
                          content: t(
                            'expiration.inactivity.expire_popover_description_1'
                          ),
                        },
                        {
                          content: t(
                            'expiration.inactivity.expire_popover_description_2'
                          ),
                        },
                      ]}
                    />
                  </Switch>
                  <Switch
                    condition={formModel.expiration_type === 'fixed_period'}
                  >
                    <PopoverContent
                      sections={[
                        {
                          content: t(
                            'expiration.fixed_period.expire_popover_description_1',
                            { months: formModel.points_expires_in_months }
                          ),
                        },
                        {
                          content: t(
                            'expiration.fixed_period.expire_popover_description_2'
                          ),
                        },
                        {
                          content: t(
                            'expiration.fixed_period.expire_popover_description_3'
                          ),
                          contentClassName: `mt-s text-uppercase ${css({
                            letterSpacing: '0.125rem',
                            fontSize: 'inherit',
                          })}`,
                        },
                        {
                          content: (
                            <span
                              // This is fixed content with no user input
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  'expiration.fixed_period.expire_popover_description_4'
                                ),
                              }}
                            />
                          ),
                          contentClassName: 'mb-s',
                        },
                        {
                          content: t(
                            'expiration.fixed_period.expire_popover_description_5'
                          ),
                        },
                        {
                          content: t(
                            'expiration.fixed_period.expire_popover_description_6'
                          ),
                        },
                      ]}
                    />
                  </Switch>
                </span>
              </div>
            </div>
            <Text.BodyText5 color="grey70" className="mt-xl" tag="div">
              {t(`edit.expiration.types.${formModel.expiration_type}.terms`)}
            </Text.BodyText5>
          </Card>
          <Help title={t('help.title')} articles={helpArticles.expiration} />
        </Formsy>
      </Layout>
      <Modal
        title={t(`edit.publish`)}
        proceedText={t(`edit.publish`)}
        isOpen={isConfirmModalOpen}
        onProceed={async () => {
          await doPublish()
          setIsConfirmModalOpen(false)
        }}
        onClose={() => setIsConfirmModalOpen(false)}
        isLoading={isUpdating}
      >
        <Text.BodyText4
          key={`publish-content`}
          tag="div"
          color="grey70"
          className="mb-s"
        >
          {t(`edit.expiration.publish_changes_content`)}
        </Text.BodyText4>
      </Modal>
    </>
  )
}

export default Expiration
