import {
  initializeCampaignVariant,
  updateCampaignVariant,
} from 'actions/campaignVariants'
import noEmailIcon from 'assets/images/campaign-center/builder/img-no_message.svg'
import emailIcon from 'assets/images/campaign-center/builder/img_email.svg'
import CampaignEmailPreview from 'components/CampaignEmailPreview'
import EmailSubjectAndPreview from 'components/EmailSubjectAndPreview'
import EmptyContentSection from 'components/review/EmptyContentSection'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import CampaignConfigContext from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import SendTestModal from 'scenes/CampaignCenter/Builder/Steps/Shared/Messaging/components/SendTestModal'
import Channel from './Channel'
import Edit from './Options/Edit'
import Remove from './Options/Remove'
import SendTest from './Options/SendTest'
import RemoveModal from './RemoveModal'

const t = buildTranslate('thanx_campaigns.builder.steps.setup.messaging.email')

type PropsT = {
  campaign: Campaign
  config: VariantOrCampaign
}

const EmailChannel = ({ campaign, config }: PropsT) => {
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [sendTestModalOpen, setSendTestModalOpen] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const dispatch = useDispatch()

  async function handleAdd() {
    setIsAdding(true)
    await dispatch(
      initializeCampaignVariant(campaign.id, config.id, { channel: 'email' })
    )
    setIsAdding(false)

    dispatch(push(`/thanx_campaigns/${campaign.id}/email/${config.id}`))
  }

  async function handleEdit() {
    dispatch(push(`/thanx_campaigns/${campaign.id}/email/${config.id}`))
  }

  async function handleRemovePrompt() {
    setRemoveModalOpen(true)
  }

  async function handleRemove() {
    setIsRemoving(true)
    await dispatch(
      updateCampaignVariant(campaign.id, config.id, {
        ...config,
        notify_email: false,
      })
    )
    setIsRemoving(false)
    setRemoveModalOpen(false)
  }

  if (!config.notify_email) {
    return (
      <EmptyContentSection
        icon={noEmailIcon}
        isEditable={config.internal_type !== 'control'}
        isAdding={isAdding}
        onAdd={handleAdd}
        addText={t('add')}
        text={t('missing')}
      />
    )
  }

  return (
    <CampaignConfigContext.Provider value={{ config, setConfig: () => {} }}>
      <Channel
        icon={emailIcon}
        title={t('title')}
        actions={
          <div className="d-flex">
            <Remove
              title={t('remove_modal.title')}
              onRemove={handleRemovePrompt}
            />
            <SendTest
              title={t('send_test')}
              onSendTest={() => setSendTestModalOpen(true)}
            />
            <Edit title={t('edit')} onEdit={handleEdit} />
          </div>
        }
      >
        <div className="margin-bottom-huge">
          <EmailSubjectAndPreview
            subject={config.notify_email_subject}
            previewText={config.notify_email_preview_text}
          />
        </div>
        <CampaignEmailPreview
          campaign={campaign}
          config={config}
          navStyle="radio"
        />
      </Channel>
      <RemoveModal
        onRemove={handleRemove}
        isRemoving={isRemoving}
        campaign={campaign}
        variant={config}
        channel="email"
        isOpen={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
      />
      <SendTestModal
        campaign={campaign}
        show={sendTestModalOpen}
        onModalClose={() => setSendTestModalOpen(false)}
      />
    </CampaignConfigContext.Provider>
  )
}

export default EmailChannel
