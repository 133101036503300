import orm from 'models/orm'
import type {
  Fields as PurchasesMetric,
  ShortType,
} from 'models/PurchasesMetric'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectAllPurchasesMetrics = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): PurchasesMetric[] => {
    return session.PurchasesMetric.all().toRefArray()
  }
)

export const selectPurchasesMetricByType = createSelector(
  selectAllPurchasesMetrics,
  (_: any, merchantId?: number) => merchantId,
  (_: any, _merchantId?: number, type?: ShortType) => type,
  (retentionRateMetrics, merchantId, type): PurchasesMetric | null => {
    return (
      (retentionRateMetrics.find(
        c => c.id === `${merchantId}_purchases_metric_${type}`
      ) as PurchasesMetric) ?? null
    )
  }
)
