import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { storedValueApi } from 'api/storedValue'
import Counter from 'components/Counter'
import Loading from 'components/Loading'
import TabPane from 'components/Tabs/TabPane'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import { Button } from 'react-bootstrap-five'
import EmptyState from 'scenes/StoredValue/EmptyState'
import { currencyFormatter } from 'utilities/formatters'
import Transactions from './Transactions'

const t = buildTranslate('users.stored_value')

type Props = {
  profile: UserProfile
}

const StoredValue = ({ profile }: Props) => {
  const flagEnabled = useFlag('stored-value-enabled', false)
  const { data: config, isLoading: configIsLoading } =
    storedValueApi.useGetConfigQuery(undefined, {
      skip: !flagEnabled,
    })
  const { data: user, isLoading: userIsLoading } =
    storedValueApi.useGetStoredValueUserQuery(profile.uid, {
      skip: !flagEnabled,
    })

  const [css] = useStyletron()

  const isLoading = configIsLoading || userIsLoading

  if (!flagEnabled) {
    return null
  }

  return (
    <TabPane state={'stored_value'}>
      {isLoading && <Loading />}
      {!isLoading && config?.state === 'inactive' && <EmptyState />}

      {!isLoading && config?.state === 'active' && (
        <section
          className={`mb-l ${css({
            minHeight: 'calc(100vh - 300px)',
          })}`}
        >
          <Text.Header3 className="my-0">{t('title')}</Text.Header3>

          <div
            className={'d-flex mt-xl justify-content-between align-items-start'}
          >
            <Counter
              className="mr-xxl"
              primaryValue={currencyFormatter(user?.balance)}
              name={t('balance')}
            />

            <Button
              target="_blank"
              size="lg"
              href={user?.external_transactions_url}
            >
              {t('transactions_link')}
            </Button>
          </div>
          {flagEnabled && <Transactions profile={profile} />}
        </section>
      )}
    </TabPane>
  )
}

export default StoredValue
