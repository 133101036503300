import React from 'react'
import { Col, Row } from 'react-bootstrap'

type PropsT = {
  children: React.ReactNode
  title: React.ReactNode
  icon: string
  actions?: React.ReactNode
}

/**
 * Wrapper for a channel preview
 */
const Channel = ({ children, icon, title, actions }: PropsT) => {
  return (
    <Row className="section">
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-center">
          <img src={icon} alt="icon" />
          <span className="vertical-middle small-caps font-size-14 ml-xs">
            {title}
          </span>
        </div>
        {actions}
      </div>
      <Col className="col-content mt-s">{children}</Col>
    </Row>
  )
}

export default Channel
