import ccpanotice from './ccpanotice'
import ccpaoptout from './ccpaoptout'
import previous from './previous'
import privacy from './privacy'

export default {
  privacy,
  previous,
  ccpanotice,
  ccpaoptout,
}
