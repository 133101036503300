import orm from 'models/orm'
import { Fields as Pagination, PaginationType } from 'models/Pagination'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectAllPaginations = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Pagination[] => {
    return session.Pagination.all().toRefArray()
  }
)

export const selectPagination = createSelector(
  selectAllPaginations,
  (_: any, type?: PaginationType['type']) => type,
  (_: any, type?: PaginationType['type'], id?: number | string) => id,
  (paginations, type, id): Pagination | null => {
    return (
      (paginations.find(c => c.id === `${type}-${id}`) as Pagination) ?? null
    )
  }
)
