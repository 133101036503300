import { Text } from '@thanx/uikit/text'
import InfoRow from 'components/InfoRow'
import { buildTranslate } from 'locales'
import React from 'react'
import { automatedCampaignHelpUrl } from 'utilities/urlUtils'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  title: string
}

const NotAvailable: React.FC<PropsT> = ({ title }) => {
  return (
    <InfoRow
      title={title}
      primaryContent={
        <Text.BodyText4 color="grey70">
          {t('revenue.not_available')}
        </Text.BodyText4>
      }
      secondaryContent={
        <a
          className="font-size-12"
          href={automatedCampaignHelpUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('learn_more')}
        </a>
      }
    />
  )
}

export default NotAvailable
