import { Select } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { TextColorKeys } from '@thanx/uikit/theme/colors'
import Modal from 'components/ModalContainer'
import Form from 'formsy-react'
import { buildTranslate } from 'locales'
import { Fields as Tier } from 'models/Tier'
import { Fields as Profile } from 'models/UserProfile'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTiers } from 'selectors/tier'
import GrantTierConfirmationModal from './GrantTierConfirmationModal'

type TierMap = {
  silverTier: Tier
  goldTier: Tier
  userCurrentTier: Tier
  userAtGoldTier: boolean
}

type Props = {
  profile: Profile
  visible: boolean
  setIsVisible: React.Dispatch<boolean>
}

const t = buildTranslate('users.view.actions.grant_tier_modal')

export const GrantTierModal: React.FC<Props> = props => {
  const { profile, visible, setIsVisible } = props

  const [formIsValid, setFormIsValid] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)

  const tiers = useSelector(selectTiers)

  const { silverTier, goldTier, userCurrentTier, userAtGoldTier } =
    tiers.reduce((acc, tier) => {
      if (tier.type === 'tier_silver') {
        acc.silverTier = tier
      }
      if (tier.type === 'tier_gold') {
        acc.goldTier = tier
      }
      if (
        tier.name === profile.current_tier ||
        (tier.type === 'tier_bronze' && !profile.current_tier)
      ) {
        acc.userCurrentTier = tier
        acc.userAtGoldTier = tier.type === 'tier_gold'
      }
      return acc
    }, {} as TierMap)

  function buildOptions(currentTier: Tier) {
    return [
      ...(currentTier?.type === 'tier_bronze'
        ? [
            {
              value: 'tier_silver',
              label: t('upgrade_to', { tierName: silverTier?.name }),
            },
          ]
        : []),
      {
        value: 'tier_gold',
        label: t('upgrade_to', { tierName: goldTier?.name }),
      },
    ]
  }

  const options = buildOptions(userCurrentTier)

  const nextTier = options[0].value

  const [selectedTierType, setSelectedTierType] = useState(nextTier)

  useEffect(() => {
    setSelectedTierType(nextTier)
  }, [nextTier])

  return (
    <>
      <Modal
        isOpen={visible}
        onClose={() => setIsVisible(false)}
        proceedText={t('submit')}
        onProceed={() => {
          setIsVisible(false)
          setIsConfirmationOpen(true)
        }}
        canProceed={formIsValid && selectedTierType !== '' && !userAtGoldTier}
      >
        <h3 className="mt-0 mb-xs extra-bold">{t('title')}</h3>
        <div className="mt-xl">
          <Form
            className="mb-xs"
            onValid={() => setFormIsValid(true)}
            onInvalid={() => setFormIsValid(false)}
          >
            <div className="d-flex flex-column align-items-start">
              <Text.BodyText4 bold className="mb-xs">
                {t('subtitle')}
              </Text.BodyText4>
              <Text.BodyText4 color={TextColorKeys.grey70} className="mb-l">
                {profile.current_tier}
              </Text.BodyText4>
              <Text.BodyText4 bold className="mb-xs">
                {t('upgrade_customer')}
              </Text.BodyText4>
            </div>
            <Switch
              condition={!userAtGoldTier}
              fallback={
                <div className="d-flex flex-column align-items-start">
                  <Text.BodyText4 color={TextColorKeys.grey70} className="mb-m">
                    {t('at_gold_tier')}
                  </Text.BodyText4>
                </div>
              }
            >
              <Select
                searchable={false}
                onChange={selected => setSelectedTierType(selected[0].value)}
                options={options}
                value={options.find(
                  option => option.value === selectedTierType
                )}
                zIndex={1002}
                maxDropdownHeight="200px"
              />
            </Switch>
          </Form>
        </div>
      </Modal>
      <GrantTierConfirmationModal
        profile={profile}
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        selectedTierType={selectedTierType}
      />
    </>
  )
}

export default GrantTierModal
