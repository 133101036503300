export const GET_EMAIL_PROVIDER_LISTS = 'emails/GET_EMAIL_PROVIDER_LISTS'
export const GET_EMAIL_PROVIDER_LISTS_SUCCESS =
  'emails/GET_EMAIL_PROVIDER_LISTS_SUCCESS'
export const GET_EMAIL_PROVIDER_LISTS_FAIL =
  'emails/GET_EMAIL_PROVIDER_LISTS_FAIL'

export const CREATE_PROVIDER_LIST = 'emails/CREATE_PROVIDER_LIST'
export const CREATE_PROVIDER_LIST_SUCCESS =
  'emails/CREATE_PROVIDER_LIST_SUCCESS'
export const CREATE_PROVIDER_LIST_FAIL = 'emails/CREATE_PROVIDER_LIST_FAIL'

export const UPDATE_PROVIDER_LIST_BULK = 'emails/UPDATE_PROVIDER_BULK_LIST'
export const UPDATE_PROVIDER_LIST_BULK_SUCCESS =
  'emails/UPDATE_PROVIDER_LIST_BULK_SUCCESS'
export const UPDATE_PROVIDER_LIST_BULK_FAIL =
  'emails/UPDATE_PROVIDER_LIST_BULK_FAIL'

export const getEmailProviderLists = () => {
  return dispatch => {
    return dispatch({
      type: GET_EMAIL_PROVIDER_LISTS,
      payload: {
        client: 'default',
        request: {
          url: 'email_provider_lists',
        },
      },
    })
  }
}

export const createProviderList = params => {
  return dispatch => {
    return dispatch({
      type: CREATE_PROVIDER_LIST,
      payload: {
        client: 'default',
        request: {
          url: 'email_provider_lists',
          method: 'post',
          data: {
            email_provider_list: params,
          },
        },
      },
    })
  }
}

export const updateProviderListsBulk = params => {
  return dispatch => {
    return dispatch({
      type: UPDATE_PROVIDER_LIST_BULK,
      payload: {
        client: 'default',
        request: {
          url: 'email_provider_lists/bulk',
          method: 'patch',
          data: {
            email_provider_lists: params,
          },
        },
      },
    })
  }
}
