import React from 'react'

type Props = {
  title: string
  state: 'active' | 'not active'
  image: string
  alt: string
  selected: boolean
  onSelect: () => void
}

const OptionTab: React.FC<Props> = props => {
  const { title, state, image, alt, selected, onSelect } = props
  return (
    <div
      className={`cursor-pointer flex-1 tab ${selected ? 'selected' : ''}`}
      onClick={() => onSelect()}
    >
      <div className="option-tab display-flex padding-large full-height">
        <div className="display-flex flex-align-center margin-right-medium visible-lg">
          <div>
            <img
              src={image}
              alt={alt}
              style={{ width: '64px', height: '64px' }}
            />
          </div>
        </div>
        <div className="display-flex flex-column">
          <div className="flex-1 font-size-18 line-height-23 margin-bottom-small">
            {title}
          </div>
          <div>
            <span
              className={`uppercase badge ${
                state === 'active' ? 'available' : ''
              }`}
            >
              {state}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OptionTab
