import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectExperience } from 'selectors/pointsExperience'

const t = buildTranslate('points.landing')

const ExperienceBadge: React.FC = () => {
  const pointsExperience = useSelector(selectExperience)

  const key = pointsExperience ? pointsExperience.state : 'inactive'
  const [backgroundColor, color] = (() => {
    switch (key) {
      case 'active':
        return [palette.nephritis20, palette.nephritis]
      case 'migrating':
        return [palette.eggYolk10, palette.eggYolk50]
      case 'archived':
      case 'inactive':
        return [palette.cayenne10, palette.cayenne]
    }
  })()

  const [css] = useStyletron()
  return (
    <Badge
      className={css({
        backgroundColor,
        color,
      })}
      value={t(key)}
    />
  )
}

export default ExperienceBadge
