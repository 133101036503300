import { merchantUsersApi } from 'api/merchantUsers'
import PageHeader from 'components/PageHeader'
import Spinner from 'components/Spinner'
import { useDisplayMessage } from 'hooks/useDisplayMessage'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import Table from './Table'

const t = buildTranslate('merchant_users')

const Landing = () => {
  const dispatch = useDispatch()
  const { data, isLoading } = merchantUsersApi.useGetMerchantUsersQuery({})

  const [, createUserStatus] = merchantUsersApi.useCreateMerchantUserMutation({
    fixedCacheKey: 'create',
  })
  const [, updateUserStatus] = merchantUsersApi.useUpdateMerchantUserMutation({
    fixedCacheKey: 'update',
  })
  const [, inviteUserStatus] = merchantUsersApi.useSendInvitationMutation({
    fixedCacheKey: 'invitation',
  })

  const displayCreateStatusMessage = useDisplayMessage(createUserStatus.reset)
  const displayUpdateStatusMessage = useDisplayMessage(updateUserStatus.reset)
  const displayInviteStatusMessage = useDisplayMessage(inviteUserStatus.reset)

  const merchantUsers = data?.merchant_users || []

  const title = t('header.title')

  if (createUserStatus.isSuccess) {
    displayCreateStatusMessage(t('new.success'), 'success')
  }

  if (updateUserStatus.isSuccess) {
    displayUpdateStatusMessage(t('edit.success'), 'success')
  }

  if (inviteUserStatus.isSuccess) {
    displayInviteStatusMessage(t('edit.invitation_success'), 'success')
  }

  return (
    <PageHeader
      title={title}
      buttonText={t('header.invite_someone')}
      buttonAction={() => dispatch(push('/merchant_users/new'))}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="grey-05-bkg border-top-1 pb-xxl pt-xl grey-20-border mt-l">
        <Spinner
          isLoading={isLoading}
          size="4x"
          className="text-center margin-top-huge"
        >
          <Container>
            <Table merchantUsers={merchantUsers} />
          </Container>
        </Spinner>
      </div>
    </PageHeader>
  )
}

export default Landing
