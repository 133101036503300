import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import { useAbility } from 'utilities/ability'

type Props = {
  component: React.ComponentType<any>
  redirect: string
  resource: string
  accessType?: 'manage' | 'access'
} & RouteProps

const ProtectedRoute: React.FC<Props> = ({
  component: Component,
  redirect,
  resource,
  accessType = 'manage',
  ...routeParams
}) => {
  const hasAbility = useAbility().can(accessType, resource)
  return (
    <Route
      {...routeParams}
      render={props =>
        hasAbility ? <Component {...props} /> : <Redirect to={redirect} />
      }
    />
  )
}

export default ProtectedRoute
