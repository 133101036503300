import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { formatSections } from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Content'
import CustomersSection from './CustomersSection'
import ScheduleSection from './ScheduleSection'
import Section from './Section'

const t = buildTranslate('thanx_campaigns.report.v3.configuration')

type Props = {
  campaign: Campaign
}
const Configuration: React.FC<Props> = ({ campaign }) => {
  const { start_at, end_at, variants } = campaign
  const sections = formatSections({
    variants,
    campaign,
    variantsLength: variants?.length || 0,
    shouldShowKebab: false,
  })
  return (
    <>
      <Text.Header3 tag="p" className="m-0 mt-s mb-xl">
        {t('configuration')}
      </Text.Header3>
      <CustomersSection className="mb-m" campaign={campaign} />
      {sections.map(section => (
        <Section {...section} />
      ))}
      <ScheduleSection
        startAt={start_at}
        endAt={end_at}
        type={campaign.type}
        retargetFrequency={campaign.retarget_frequency}
      />
    </>
  )
}

export default Configuration
