import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { CouponPoolGenerationType, CouponPoolType } from 'models/CouponPool'
import { RedemptionProvider } from 'models/RedemptionProvider'
import { isLoyaltyIntegrationPos } from 'scenes/RedeemManager/helpers'
import ThanxGeneratedDisabled from '../Builder/ConfigurationStep/InstoreRedemptionCard/CouponCodesForm/ThanxGeneratedDisabled'

type Props = {
  redemptionProvider: RedemptionProvider
  couponCodes: {
    generationType: CouponPoolGenerationType
    type: CouponPoolType
    staticCode: string
    uploadColumn: number | null
  }
  selectedCouponPools: any[]
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card'
)

function CouponCodesDisplay(props: Props) {
  const { redemptionProvider, couponCodes, selectedCouponPools } = props
  const { generationType, staticCode, type: couponPoolFormat } = couponCodes

  const supportsLoyaltyIntegration = isLoyaltyIntegrationPos(redemptionProvider)
  return (
    <>
      <Text.BodyText3 className="mb-s" color="grey70">
        {t('type.coupon_codes_enforced_by_pos')}
      </Text.BodyText3>
      <Text.BodyText3 bold>{t('coupon_codes.type_label')}</Text.BodyText3>

      {supportsLoyaltyIntegration && (
        <>
          <Text.BodyText3 color="grey70">
            {t('coupon_codes.type.dynamic')}
          </Text.BodyText3>
          <Switch
            condition={redemptionProvider.integration_type === 'direct'}
            fallback={
              <Text.BodyText4 color="grey70">
                {t('coupon_codes.dynamic.issued_5_digit_code')}
              </Text.BodyText4>
            }
          >
            <Text.BodyText4 color="grey70">
              <span
                dangerouslySetInnerHTML={{
                  __html: t('coupon_codes.dynamic.entered_by_customer'),
                }}
              />
            </Text.BodyText4>
          </Switch>
        </>
      )}

      {!supportsLoyaltyIntegration && !!generationType && (
        <>
          <Switch
            condition={generationType === CouponPoolGenerationType.VARIABLE}
          >
            <>
              <Text.BodyText3 className="mb-s" color="grey70">
                {t('coupon_codes.type.variable')}
              </Text.BodyText3>
              <Text.BodyText3 bold>
                {t('coupon_codes.associated_coupon_pools')}
              </Text.BodyText3>
              {selectedCouponPools?.map(pool => (
                <Text.BodyText3 className="mb-xs" color="grey70">
                  {pool.label}
                </Text.BodyText3>
              ))}
            </>
          </Switch>
          <Switch
            condition={generationType === CouponPoolGenerationType.STATIC}
          >
            <>
              <Text.BodyText3 className="mb-s" color="grey70">
                {t('coupon_codes.type.static')}
              </Text.BodyText3>
              <Text.BodyText3 bold>
                {t('coupon_codes.format_label')}
              </Text.BodyText3>
              <Text.BodyText3 className="mb-s" color="grey70">
                {couponPoolFormat}
              </Text.BodyText3>
              <Text.BodyText3 bold>
                {t('coupon_codes.static_code_label')}
              </Text.BodyText3>
              <Text.BodyText3 color="grey70">{staticCode}</Text.BodyText3>
            </>
          </Switch>
          <Switch
            condition={generationType === CouponPoolGenerationType.DYNAMIC}
          >
            <ThanxGeneratedDisabled />
          </Switch>
        </>
      )}
    </>
  )
}

export default CouponCodesDisplay
