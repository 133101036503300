import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import Helmet from 'react-helmet'
import Content from './Content'

type Props = {
  campaign: Campaign
  canProceed: boolean
  showValidationErrors?: () => void
  isSaving: boolean
  triggerSave: () => Promise<void>
}

const t = buildTranslate('thanx_campaigns.builder.steps.incentive')

const Incentive: React.FC<Props> = props => {
  const { campaign, canProceed, showValidationErrors, isSaving, triggerSave } =
    props
  return (
    <div className="container white-bkg">
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <Content
        campaign={campaign}
        canProceed={canProceed}
        showValidationErrors={showValidationErrors}
        isSaving={isSaving}
        triggerSave={triggerSave}
      />
    </div>
  )
}

export default Incentive
