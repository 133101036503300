import { Fields as TargetSegment } from 'models/TargetSegment'

type GroupedSegments = Record<string, Record<string, TargetSegment[]>>
type CategoryNames = Array<[category: string, subcategories: string[]]>

export const groupByCategories = (segments: TargetSegment[]): GroupedSegments =>
  segments.reduce((result, segment) => {
    // Handles keys with null and ''
    const category = segment.category ?? ''
    const subcategory = segment.subcategory ?? ''

    if (!result[category]) {
      result[category] = {}
    }

    if (!result[category][subcategory]) {
      result[category][subcategory] = []
    }

    result[category][subcategory].push(segment)

    return result
  }, {} as GroupedSegments)

export const categoryNames = (
  collection: GroupedSegments,
  locale: string = 'en'
): CategoryNames => {
  const sortedCategories = sortCategoryNames(Object.keys(collection), locale)

  return sortedCategories.map(category => [
    category,
    Object.keys(collection[category]).sort((a, b) =>
      a.localeCompare(b, locale, { sensitivity: 'base' })
    ),
  ])
}

export const sortCategoryNames = (
  categories: string[],
  locale: string
): string[] =>
  categories.sort((a, b) => {
    // empty categories should be last
    if (a === '') {
      return 1
    } else if (b === '') {
      return -1
    }
    return a.localeCompare(b, locale, { sensitivity: 'base' })
  })
