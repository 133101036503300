import orm from 'models/orm'
import { Fields } from 'models/UserFeedback'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectUsersFeedbacks = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.UserFeedback.all().toRefArray()
  }
)

export const selectUserFeedbacks = createSelector(
  (state: any) => selectUsersFeedbacks(state),
  (_: any, id: number): number => id,
  (usersFeedbacks: any, id: number): Fields[] => {
    return usersFeedbacks.filter(feedback => feedback.user_id === id)
  }
)
