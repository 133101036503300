import { Modal } from '@thanx/uikit/modal'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import BonusPointsRewardRestrictions from './BonusPointsRewardRestrictions'
import DiscountRewardRestrictions from './DiscountRewardRestrictions'
import NdrRewardRestrictions from './NdrRewardRestrictions'

type Props = {
  onClose: () => void
  campaign: Campaign
  showRewardRestrictions: boolean
  isSaving: boolean
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator'
)

const RestrictionCreator: React.FC<Props> = props => {
  const { onClose, campaign, showRewardRestrictions, isSaving } = props
  const { config } = useCampaignConfig()
  const { redeem_type } = config

  const restrictionForm = (() => {
    if (redeem_type === 'points/static') {
      return (
        <BonusPointsRewardRestrictions onClose={onClose} campaign={campaign} />
      )
    }
    return redeem_type?.includes('experience') ? (
      <NdrRewardRestrictions
        onClose={onClose}
        campaign={campaign}
        config={config}
      />
    ) : (
      <DiscountRewardRestrictions
        onClose={onClose}
        campaign={campaign}
        isSaving={isSaving}
      />
    )
  })()

  return (
    <Modal
      isOpen={showRewardRestrictions}
      onClose={onClose}
      closeType="small"
      size="auto"
      zIndex={1100}
    >
      <div className="fs-unmask p-xl w-100" style={{ maxWidth: '800px' }}>
        <Text.Header3 color="grey90" className="mb-xs mt-xs">
          {t('title')}
        </Text.Header3>
        <Text.BodyText3 color="grey70" className="mb-xl" tag="p">
          {t('subtitle')}
        </Text.BodyText3>{' '}
        {restrictionForm}
      </div>
    </Modal>
  )
}

export default RestrictionCreator
