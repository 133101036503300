import presentImage from 'assets/images/campaign-center/img_present_purple.png'
import headerImage from 'assets/images/campaign-center/sms_header.png'
import isNil from 'lodash/isNil'
import React, { useContext, useMemo } from 'react'
import { Translate } from 'react-redux-i18n'
import FormModelContext from 'scenes/CampaignCenter/Builder/components/FormModelContext'

import useCurrentMerchant from 'hooks/useCurrentMerchant'
import type { CampaignConfig } from 'models/Campaign'

type Props = {
  config: CampaignConfig
}

const Sms: React.FC<Props> = ({ config }) => {
  const formModel: any = useContext(FormModelContext)
  const campaignMerchant = useCurrentMerchant()
  const smsText = useMemo(() => {
    const text = isNil(formModel.notify_sms_text)
      ? config.notify_sms_text
      : formModel.notify_sms_text

    if (!text) {
      return (
        <Translate
          value="thanx_campaigns.builder.previews.sms.default"
          merchant={campaignMerchant?.nickname}
          dangerousHTML
        />
      )
    }

    return (
      <Translate
        value="thanx_campaigns.builder.previews.sms.custom"
        merchant={campaignMerchant?.nickname}
        customText={text}
        dangerousHTML
      />
    )
  }, [config, formModel, campaignMerchant])

  return (
    <div className="padding-huge padding-top-none">
      <div className="phone-bkg">
        <img src={headerImage} alt="phone header" className="full-width" />
        <div className="white-bkg sms padding-medium">
          <img
            src={presentImage}
            alt="you got a reward"
            className="full-width margin-bottom-medium"
          />
          <div className="bubble">{smsText}</div>
        </div>
      </div>
    </div>
  )
}

export default React.memo<Props>(Sms)
