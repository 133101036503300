import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import { merchantTheme } from 'theme'

type PropsT = {
  clickPassthrough?: any
  text?: string
  button?: string
  isLoading?: boolean
  disabled?: boolean
}

const FakeInput: React.FC<PropsT> = props => {
  const { clickPassthrough, text, button, isLoading, disabled = false } = props
  const [css] = useStyletron()

  const onClick = () => {
    if (clickPassthrough) {
      clickPassthrough()
    }
  }

  let textContent = isLoading ? (
    <span className="text-center flex-1">
      <i className={'fa fa-spin fa-1x fa-circle-o-notch'} />
    </span>
  ) : (
    <span className="ellipsis flex-1">{text}</span>
  )

  return (
    <div
      className={css({
        border: `1px solid ${merchantTheme.colors.grey30}`,
        borderRadius: '4px',
        fontSize: merchantTheme.typography.bodyText4.fontSize,
        color: disabled
          ? merchantTheme.colors.grey40
          : merchantTheme.colors.grey70,
        cursor: button ? '' : 'pointer',
        display: 'flex',
        width: '100%',
        tableLayout: 'fixed',
        backgroundColor: disabled ? merchantTheme.colors.grey10 : 'transparent',
      })}
      onClick={button ? () => {} : onClick}
    >
      <div
        className={`w-100 ${css({
          padding: '14px 16px',
          borderRight: button
            ? `1px solid ${merchantTheme.colors.grey30}`
            : 'none',
        })}`}
      >
        {textContent}
      </div>
      <Switch condition={!!button}>
        <Button
          kind="minimal"
          onClick={onClick}
          className={css({
            padding: '10px 18px',
            whiteSpace: 'nowrap',
          })}
        >
          <span
            className={css({
              fontSize: merchantTheme.typography.bodyText5.fontSize,
            })}
          >
            {button}
          </span>
        </Button>
      </Switch>
    </div>
  )
}

export default FakeInput
