import React from 'react'

type Props = {
  className?: string
  children?: React.ReactNode
}

const HeaderCell: React.FC<Props> = props => {
  const { className = '', children = null } = props

  return <th className={`body-text-4 py-s px-m ${className}`}>{children}</th>
}

export default HeaderCell
