import {
  EventsMetric,
  Fields,
  Metrics,
  ShortType,
  Type,
} from 'models/CampaignMetric'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

type InputType = Type | ShortType

const selectCampaignMetrics = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.CampaignMetric.all().toRefArray()
  }
)

export const selectCampaignMetricByType = createSelector(
  selectCampaignMetrics,
  (_: any, campaignId: number) => campaignId,
  (_: any, _campaignId: number, type: InputType): InputType => type,
  (metrics: Fields[], campaignId: number, type): Fields => {
    const flexibleType = type.includes('program_metric_')
      ? type
      : `program_metric_${type}`
    return (
      metrics.filter(
        m => m.type === flexibleType && m.campaign_id === campaignId
      )[0] ?? null
    )
  }
)

export const selectCampaignVariantMetricByType = createSelector(
  selectCampaignMetrics,
  (_, campaignId: number) => campaignId,
  (_, _campaignId, type: Type | ShortType) => type,
  (_, _campaignId, _type: Type | ShortType, variantIds: number[]) => variantIds,
  (metrics, campaignId, type, variantIds): Fields[] => {
    const flexibleType = type.includes('program_metric_')
      ? `${type}`
      : `program_metric_${type}`
    return variantIds.map(variantId => {
      return (
        metrics.filter(
          m =>
            m.type === flexibleType &&
            m.campaign_id === campaignId &&
            m.variant_id === variantId
        )[0] ?? null
      )
    })
  }
)

export const selectCampaignMetricsById = createSelector(
  selectCampaignMetrics,
  (_: any, campaignId: number) => campaignId,
  (metrics: Fields[], campaignId: number): Metrics => {
    const formattedMetrics = metrics.reduce((acc, metric) => {
      const { data, type, campaign_id } = metric
      if (campaignId === campaign_id) {
        const typeName = type?.replace('program_metric_', '')
        return { ...acc, [typeName]: { ...(acc[typeName] || []), ...data } }
      }
      return acc
    }, {})
    return formattedMetrics
  }
)

export const selectCampaignMetricsByType = createSelector(
  selectCampaignMetrics,
  (_: any, campaignIds: number[]) => campaignIds,
  (_: any, _campaignIds: number[], type: Type) => type,
  (metrics, campaignIds, type) => {
    return metrics.filter(
      m => m.type === type && campaignIds.includes(m.campaign_id)
    )
  }
)

export const selectErroredCampaignMetrics = createSelector(
  selectCampaignMetrics,
  metrics => metrics.filter(m => m.isErrored === true)
)

export const selectCampaignEventsMetricsByTypes = createSelector(
  selectCampaignMetrics,
  (_: any, campaignId: number) => campaignId,
  (_: any, _campaignId: number, types: Type[]) => types,
  (metrics: Fields[], campaignId: number, types: Type[]): EventsMetric[] => {
    return metrics.filter(
      m => types.includes(m.type) && campaignId === m.campaign_id
    ) as EventsMetric[]
  }
)
