import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'

const t = buildTranslate('thanx_campaigns.report.v3.configuration')

type Props = {
  className?: string
  campaign: Campaign
}
const CustomersSection: React.FC<Props> = ({ campaign, className = '' }) => {
  let body
  const {
    type,
    config_target_name,
    config_target_member,
    config_target_subscriber,
  } = campaign
  if (type === 'custom_automated') {
    body = (
      <>
        <Text.BodyText4 tag="p" color="grey70" className="m-0 mb-xs">
          {t('custom_automated.customers_receive')}
          <b className="grey-90">{config_target_name}</b>
        </Text.BodyText4>
        <Text.BodyText5 tag="p" color="grey50" className="m-0 mb-m">
          {t('time_to_receive')}
        </Text.BodyText5>
      </>
    )
  }

  if (type === 'custom') {
    body = (
      <Text.BodyText4 tag="p" color="grey70" className="m-0">
        {t('custom.audience_description')}
        <b className="grey-90">{config_target_name}</b>
      </Text.BodyText4>
    )
  }

  if (type === 'message') {
    body = (
      <Text.BodyText4 tag="p" color="grey70" className="m-0">
        {t('message_all.audience_description')}
        <Switch condition={config_target_member}>
          <b className="grey-90">{t('message_all.loyalty_members')}</b>
        </Switch>
        <Switch condition={config_target_member && config_target_subscriber}>
          {t('message_all.audience_description_and')}
        </Switch>
        <Switch condition={config_target_subscriber}>
          <b className="grey-90">{t('message_all.email_subcribers')}</b>
        </Switch>
      </Text.BodyText4>
    )
  }

  return (
    <Section className={`p-xl ${className}`}>
      <Text.Header4 tag="p" className="m-0 mb-s">
        {t('audience')}
      </Text.Header4>
      {body}
    </Section>
  )
}

export default CustomersSection
