import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { Switch } from 'react-router-dom'
import Landing from './Landing'
import View from './View'

const OrderingManagementRouter: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        redirect="/"
        accessType="access"
        resource="OrderingManagement"
        exact
        path="/ordering_management"
        component={Landing}
      />
      <ProtectedRoute
        redirect="/"
        accessType="access"
        resource="OrderingManagement"
        exact
        path="/ordering_management/:id"
        component={View}
      />
    </Switch>
  )
}

export default OrderingManagementRouter
