import { DateRangeGranularity } from 'components/DateFilter/utilities'
import palette from 'constants/palette'
import Highcharts from 'highcharts'
import { buildTranslate } from 'locales'
import {
  RetentionRateCohortMetric,
  RetentionRateTimeSeriesMetric,
  TimeSeriesInterval,
} from 'models/RetentionRateMetric'
import moment from 'moment-timezone'
import { getDateLabel } from 'utilities/date'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('retention_rate')

export const RETENTION_WINDOW_DAYS = 180
export const AVAILABLE_IN_DAYS = 180
export const DEFAULT_RETENTION_INTERVAL: TimeSeriesInterval = 'window_180d'

type RateSeriesT = {
  benchmarks: Array<{
    y: number
    custom: { is_benchmark: true }
  }>
  rates: Array<{
    y: number
    custom: Pick<
      RetentionRateTimeSeriesMetric['values'][number],
      'engaged_users_count' | 'returning_users_count' | 'churned_users_count'
    >
  }>
}

export function filterRecentValues(
  value: RetentionRateTimeSeriesMetric['values'][number]
): boolean {
  const timeZone = moment.tz.guess()
  return moment
    .tz(timeZone)
    .isAfter(
      moment
        .tz(value.date_time, timeZone)
        .endOf('month')
        .add(RETENTION_WINDOW_DAYS, 'days')
    )
}

export function getRateChartSeries(
  values: RetentionRateTimeSeriesMetric['values'],
  benchmarkRate: number
): Highcharts.SeriesOptionsType[] {
  if (!values) return []

  const initialSeries: RateSeriesT = { benchmarks: [], rates: [] }
  const series: RateSeriesT = values.reduce((acc, value) => {
    acc.benchmarks.push({
      y: benchmarkRate,
      custom: {
        is_benchmark: true,
      },
    })

    const { engaged_users_count, returning_users_count, churned_users_count } =
      value ?? {}

    acc.rates.push({
      y: (value.retention_rate ?? 0) * 100,
      custom: {
        engaged_users_count,
        returning_users_count,
        churned_users_count,
      },
    })

    return acc
  }, initialSeries)

  return [
    {
      id: 'benchmark',
      name: t('chart.industry_benchmark'),
      data: series.benchmarks,
      color: palette.wisteria,
      type: 'line',
      marker: { enabled: false, symbol: 'circle' },
    },
    {
      id: 'retention_rate',
      name: '',
      data: series.rates,
      color: palette.spearmint50,
      type: 'line',
      showInLegend: false,
      marker: { enabled: false, symbol: 'circle' },
    },
  ]
}

export function getCohortYAxisOptions(
  values: RetentionRateCohortMetric['values'],
  granularity: DateRangeGranularity,
  timezone: string
) {
  if (!values) return []

  const initialCategories: any = [
    {
      categories: [],
      title: t('cohort_chart.engaged_customers'),
    },
    {
      categories: [],
      labelOptions: {
        align: 'left',
        x: -8,
      },
      options: {
        linkedTo: 0,
      },
    },
  ]

  return values.reduce((acc, value) => {
    acc[0].categories.push(numberFormatter(value.engaged_users_count))
    acc[1].categories.push(getDateLabel(value.date_time, granularity, timezone))

    return acc
  }, initialCategories)
}

const rateMap = {
  window_30d: 30,
  window_60d: 60,
  window_90d: 90,
  window_120d: 120,
  window_150d: 150,
  window_180d: 180,
  window_210d: 210,
}

export function getCohortSeriesData(
  values: RetentionRateCohortMetric['values'],
  timezone: string
): Array<Highcharts.PointOptionsObject> {
  if (!values) return []
  const now = moment.tz(timezone)

  return values
    .map((value, y) => {
      const date = moment.tz(value.date_time, timezone)

      return Object.keys(rateMap).map((ratePeriod, x) => {
        const showValue = now.isAfter(
          date.clone().endOf('month').add(rateMap[ratePeriod], 'days')
        )
        return {
          x,
          y,
          value: showValue ? value[ratePeriod].retention_rate : null,
        }
      })
    })
    .flat()
}

export function cohortSeriesFormatter(value: number): string {
  return percentageFormatter(value, 1, 1)
}

const cohortXAxisMap = {
  0: 30,
  1: 60,
  2: 90,
  3: 120,
  4: 150,
  5: 180,
  6: 210,
}
export function cohortTopAxisFormatter(value: string | number): string {
  const val = String(value)
  if (!Object.keys(cohortXAxisMap).includes(val)) return ''

  return t('cohort_chart.days', {
    days: cohortXAxisMap[value],
  })
}
