import { Select } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { alert } from 'actions/flash'
import { getUserProfile } from 'actions/users'
import { locationsApi } from 'api/locations'
import { usersApi } from 'api/users'
import { isError } from 'api/utils'
import Input from 'components/Form/Input'
import Modal from 'components/ModalContainer'
import Form from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import DatePicker from 'react-16-bootstrap-date-picker'
import { InputGroup } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { useUserTimeZone } from 'utilities/userTimeZone'
import { generateTimeOptions } from './helpers'

type Props = {
  visible: boolean
  onClose: VoidFunction
  profile: UserProfile
}

const t = buildTranslate('users.view.actions.grant_purchase_modal')

type FormData = {
  date: string | null
  formattedDate: string | null
  time: string | null
  locationId: number | null
  total: number | null
}

const GrantPurchaseModal = ({ visible, onClose, profile }: Props) => {
  const dispatch = useDispatch()
  const { data } = locationsApi.useGetLocationsQuery({ coming_soon: false })
  const [formData, setFormData] = useState<FormData>({
    date: null,
    formattedDate: null,
    time: null,
    locationId: null,
    total: null,
  })
  const timeZone = useUserTimeZone()
  const [grantPurchase, { isLoading: isSubmitting }] =
    usersApi.useGrantPurchaseMutation()

  const times = useMemo(() => generateTimeOptions(timeZone), [timeZone])
  const locations = useMemo(() => {
    if (!data) return []

    return data.locations.map(location => ({
      value: location.id,
      label: location.name,
    }))
  }, [data])

  function updateFormData(updates: Partial<FormData>) {
    setFormData({
      ...formData,
      ...updates,
    })
  }

  async function onProceed() {
    const { total, locationId, formattedDate, time } = formData

    if (!locationId) return
    if (!total) return
    if (!formattedDate) return
    if (!time) return

    const purchasedAt = moment(`${formattedDate} ${time}`)
      .tz(timeZone)
      .toISOString()
    const purchase = {
      location_id: locationId,
      amount: total,
      purchased_at: purchasedAt,
    }

    const result = await grantPurchase({
      userUid: profile.uid,
      purchase,
    })

    if (isError(result)) {
      dispatch(
        alert({
          key: 'danger',
          message: t('alert_fail'),
          timeout: 3,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('alert_success', { total }),
        timeout: 5,
      })
    )

    await dispatch(getUserProfile(profile.uid))
    onClose()
  }

  const canProceed = Boolean(
    formData.locationId &&
      formData.total &&
      formData.formattedDate &&
      formData.time
  )

  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      proceedText={t('submit')}
      onProceed={onProceed}
      canProceed={canProceed}
      isLoading={isSubmitting}
    >
      <h3 className="mt-0 mb-xs extra-bold">{t('title')}</h3>
      <Text.BodyText3 color="grey70" className="mb-xs">
        {t('subtitle')}
      </Text.BodyText3>

      <div className="mt-xl">
        <Form>
          <div className="d-flex flex-column align-items-start">
            <div className="w-100 mb-m">
              <Text.BodyText4 bold className="mb-xs" tag="p">
                {t('location')}
              </Text.BodyText4>
              <Select
                searchable
                placeholder={t('locationPlaceholder')}
                options={locations}
                onChange={selected =>
                  updateFormData({ locationId: selected[0].value })
                }
                value={locations.find(
                  location => location.value === formData.locationId
                )}
                zIndex={1}
                maxDropdownHeight="200px"
              />
            </div>

            <div className="w-100 mb-m">
              <Text.BodyText4 bold>{t('date')}</Text.BodyText4>
              <InputGroup className="mt-xs">
                <DatePicker
                  autoFocus={false}
                  value={formData.date}
                  maxDate={moment().endOf('day').toISOString()}
                  placeholder={t('datePlaceholder')}
                  showClearButton={false}
                  onChange={(date, formattedDate) =>
                    updateFormData({ date, formattedDate })
                  }
                />
                <InputGroup.Addon>
                  <i className="fa fa-calendar" />
                </InputGroup.Addon>
              </InputGroup>
            </div>

            <div className="w-100 mb-m">
              <Text.BodyText4 bold className="mb-xs" tag="p">
                {t('time')}
              </Text.BodyText4>
              <Select
                placeholder={t('timePlaceholder')}
                options={times}
                onChange={selected =>
                  updateFormData({ time: selected[0].value })
                }
                value={times.find(time => time.value === formData.time)}
                zIndex={1002}
                maxDropdownHeight="200px"
              />
            </div>

            <div className="w-100 mb-m">
              <Text.BodyText4 bold>{t('total')}</Text.BodyText4>
              <Input
                name="total"
                addOnBefore={
                  <Text.BodyText3 className="d-flex justify-content-center align-center">
                    {t('dollar')}
                  </Text.BodyText3>
                }
                value={formData.total}
                onChange={event => {
                  const total = Number(event.currentTarget.value)
                  if (isNaN(total)) return
                  updateFormData({ total })
                }}
                className="w-100 mt-xs"
                validations={{
                  greaterThan: 0,
                  isRequired: true,
                  isNumeric: true,
                }}
                validationErrors={{
                  greaterThan: I18n.t('validations.greater_than', {
                    value: 0,
                  }),
                  isRequired: I18n.t('validations.is_required'),
                  isNumeric: I18n.t('validations.is_numeric'),
                }}
              />
            </div>

            <div className="p-m grey-10-bkg">
              <Text.BodyText4 color="grey70">
                {t('appear_delay')}
              </Text.BodyText4>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default GrantPurchaseModal
