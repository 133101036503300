import orm from 'models/orm'
import { Fields } from 'models/UserProfile'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectUserProfiles = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.UserProfile.all().toRefArray()
  }
)

export const selectUserProfile = createSelector(
  (state: any) => selectUserProfiles(state),
  (_: any, uid?: string): string | undefined => uid,
  (userProfiles: any, uid?: string): Fields | null => {
    return userProfiles.find(profile => profile.uid === uid) ?? null
  }
)
