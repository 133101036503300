import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import ImgCheckOff from 'assets/images/integrations/img_checkOff.svg'
import ImgCheckOn from 'assets/images/integrations/img_checkOn.svg'
import { getIntegrationImages } from 'assets/images/integrations/logos'
import Badge from 'components/Badge'
import { buildTranslate } from 'locales'
import { Integration } from 'models/IntegrationsConfig'
import React from 'react'
import { merchantTheme } from 'theme'

type LinkT = {
  src: string
  label: string
}

type PropsT = {
  state?: 'enabled' | 'disabled' | 'auto'
  integration: Integration
}

const t = buildTranslate('integration_center.card')

const Card: React.FC<PropsT> = ({ state = 'disabled', integration }) => {
  const isEnabled = state === 'enabled'
  const isComingSoon = integration.comingSoon
  const [css] = useStyletron()
  const checkIcon = isEnabled ? ImgCheckOn : ImgCheckOff
  const checkIconAlt =
    checkIcon === ImgCheckOn ? 'check mark' : 'empty checkbox'
  const { colors } = merchantTheme
  const background = isEnabled ? colors.white : colors.grey05
  const links: LinkT[] = []
  const integrationImages = getIntegrationImages()

  if (integration?.helpUrl) {
    links.push({
      src: integration.helpUrl,
      label: t('learn_more'),
    })
  }

  if (integration?.emailSubject && !isEnabled) {
    links.push({
      src: `mailto:merchant.support@thanx.com?subject=${integration.emailSubject}`,
      label: t('contact_us'),
    })
  }

  return (
    <div
      className={`${css({
        minHeight: '100px',
        border: `1px solid ${colors.grey30}`,
        borderRadius: '4px',
        backgroundColor: background,
      })} w=100 p-m d-flex justify-content-between`}
      data-testid={integration.key}
    >
      <div className="d-flex align-items-start">
        {/* Enabled/disabled icon */}
        <img src={checkIcon} alt={checkIconAlt} />

        {/* Content */}
        <div className="ml-s">
          <div className="d-flex">
            <Text.BodyText3 color={colors.grey90} bold>
              {integration?.title}
            </Text.BodyText3>
            {!!integration?.partners?.length && (
              <div className="d-flex">
                <Text.BodyText4
                  color={colors.grey70}
                  tag="div"
                  className={`${css({
                    marginTop: '2px',
                  })} ml-xs`}
                >
                  {t('via')}
                </Text.BodyText4>
                {integration.partners.map(partner => (
                  <img
                    key={partner}
                    src={integrationImages[partner]}
                    className="ml-xs mt-xxs"
                    height={16}
                  />
                ))}
              </div>
            )}
            {isEnabled && (
              <Badge
                value={t('enabled')}
                className={`${css({
                  backgroundColor: colors.nephritis20,
                  color: colors.nephritis50,
                  marginTop: '2px',
                })} ml-s`}
              />
            )}
            {isComingSoon && (
              <Badge
                value={t('coming_soon')}
                className={`${css({
                  backgroundColor: colors.grey20,
                  color: colors.grey70,
                  marginTop: '2px',
                })} ml-s`}
              />
            )}
          </div>
          {!!integration?.description && (
            <Text.BodyText4
              className="d-flex flex-column mt-xs"
              color={colors.grey70}
              tag="div"
            >
              {integration.description}
            </Text.BodyText4>
          )}
          {!!links.length && (
            <div className="d-flex flex-column">
              {links.map((link, index) => (
                <div key={`integration-link-${index}`}>
                  <a
                    href={link.src}
                    className={`${css({
                      fontSize: '14px',
                    })} d-block mt-xs`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.label}
                  </a>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* integration logo */}
      <div
        className={`${css({
          border: `1px solid ${colors.grey30}`,
          borderRadius: '4px',
          height: '48px',
          backgroundColor: colors.white,
        })} px-m d-flex align-items-center`}
      >
        <img src={integrationImages[integration.key]} />
      </div>
    </div>
  )
}

export default Card
