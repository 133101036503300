import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import Rows from './Rows'

const t = buildTranslate('lifecycle_report.overview.table')

const Table: React.FC = () => {
  return (
    <table id="lifecycle-table" className="w-100">
      <thead>
        <tr key="header">
          <th className="px-m pb-xs align-top" style={{ width: '40%' }}>
            <Text.BodyText4 color="grey90" bold>
              {t('header.stage')}
            </Text.BodyText4>
          </th>
          <th className="px-m pb-xs align-top" style={{ width: '15%' }}>
            <Text.BodyText4 color="grey90" bold>
              {t('header.customers.description')}
            </Text.BodyText4>
            <Text.BodyText4 tag="p" color="grey90" bold>
              {t('header.customers.recency')}
            </Text.BodyText4>
          </th>
          <th className="px-m pb-xs align-top" style={{ width: '15%' }}>
            <Text.BodyText4 color="grey90" bold>
              {t('header.conversion_rate.description')}
            </Text.BodyText4>
            <Text.BodyText4 tag="p" color="grey90" bold>
              {t('header.customers.recency')}
            </Text.BodyText4>
          </th>
          <th className="px-m pb-xs align-top" style={{ width: '15%' }}>
            <Text.BodyText4 color="grey90" bold>
              {t('header.percentage.description')}
            </Text.BodyText4>
            <Text.BodyText4 tag="p" color="grey90" bold>
              {t('header.customers.recency')}
            </Text.BodyText4>
          </th>
          <th className="px-m pb-xs align-top" />
        </tr>
      </thead>
      <tbody>
        <Rows />
      </tbody>
    </table>
  )
}

export default Table
