import React from 'react'

type Props = {
  isDisabled?: boolean
  disabledClass?: string
  isChecked: boolean
  checkedClass?: string
  uncheckedClass?: string
  labelText?: string
  labelClass?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: React.FC<Props> = props => {
  const {
    isDisabled,
    disabledClass = 'grey-20',
    isChecked,
    checkedClass = 'fa inline-block fa-lg fa-check-square text-info-bright',
    uncheckedClass = 'fa inline-block fa-lg fa-square text-muted-fade',
    labelClass = '',
    labelText,
    onChange,
  } = props

  const disabled = isDisabled ? disabledClass : ''
  const checkClass = isChecked
    ? `${checkedClass} ${disabled}`
    : `${uncheckedClass} ${disabled}`

  function onChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
    if (!onChange) return
    onChange(event)
  }

  return (
    <label className={`inline-block ${labelClass}`}>
      <input
        checked={isChecked}
        type="checkbox"
        onChange={onChangeEvent}
        style={{ display: 'none' }}
        disabled={isDisabled}
      />
      <span
        className={checkClass}
        style={{ marginRight: labelText ? '10px' : '0px' }}
      />
      {labelText}
    </label>
  )
}

export default Checkbox
