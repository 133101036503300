const accessibility = {
  title: 'Thanx Accessibility Statement',
  updated: 'Updated: October 2024.',
  general: {
    title: 'General',
    content:
      'Thanx strives to ensure that its services are accessible to people with disabilities. Thanx has invested a significant amount of resources to help ensure that the web ordering experience is made easier to use and more accessible for people with disabilities, with the strong belief that <a rel="nofollow" href="https://userway.org">website accessibility</a> efforts assist all users and that every person has the right to live with dignity, equality, comfort and independence.',
  },
  accessibility_on_order: {
    title: "Accessibility on Thanx's Web Ordering Experience",
    content:
      'Thanx\'s web ordering experience makes <a rel="nofollow" href="https://userway.org" alt="Free Web Accessibility Widget" title="Free Web Accessibility Widget">UserWay\'s Web Accessibility Widget</a> available which is powered by a dedicated accessibility server. The software allows Thanx\'s web ordering experience to improve its compliance with the Web Content Accessibility Guidelines (WCAG 2.1).',
  },
  enabling_menu: {
    title: 'Enabling the Accessibility Menu',
    content:
      'The Thanx web ordering experience accessibility menu can be enabled by clicking the accessibility menu icon that appears on the corner of the page. After triggering the accessibility menu, please wait a moment for the accessibility menu to load in its entirety.',
  },
  disclaimer: {
    title: 'Disclaimer',
    content:
      'Thanx continues its efforts to constantly improve the accessibility of its site and services in the belief that it is our collective moral obligation to allow seamless, accessible and unhindered use also for those of us with disabilities.<br /><br />In an ongoing effort to continually improve and remediate accessibility issues, we also regularly scan Thanx\'s web ordering experience with UserWay\'s <a rel="nofollow" href="https://UserWay.org/scanner" title="Free Website Accessibility Scanner">Accessibility Scanner</a> to identify and fix every possible accessibility barrier on our site. Despite our efforts to make all pages and content on Thanx\'s web ordering experience fully accessible, some content may not have yet been fully adapted to the strictest accessibility standards. This may be a result of not having found or identified the most appropriate technological solution.',
  },
  here_for_you: {
    title: 'Here For You',
    content:
      "If you are experiencing difficulty with any content on Thanx's web ordering experience or require assistance with any part of our site, please contact us during normal business hours as detailed below and we will be happy to assist.",
  },
  contact: {
    title: 'Contact Us',
    content:
      'If you wish to report an accessibility issue, have any questions or need assistance, please contact Thanx Customer Support as follows:',
    email: 'Email: <a href="mailto:support@thanx.com">support@thanx.com</a>',
  },
}

export default accessibility
