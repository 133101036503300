import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { updateCampaign } from 'actions/campaigns'
import AudienceIcon from 'assets/images/campaign-center/builder/img_audience.svg'
import { PopoverOption } from 'components/PopoverMenu'
import ReviewSection from 'components/review/ReviewSection'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import EmptyAudience from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Audience/components/EmptyAudience'
import RecentlyRewarded from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Audience/components/RecentlyRewarded'

type Props = {
  campaign: Campaign
  onEdit?: VoidFunction
  isEditable?: boolean
  isSaving?: boolean
}

const t = buildTranslate('thanx_campaigns.customAutomated.review.audience')

const AudienceContent = (props: Props) => {
  const {
    campaign,
    onEdit = () => null,
    isEditable = false,
    isSaving = false,
  } = props
  const dispatch = useDispatch()
  const { setConfig } = useCampaignConfig()
  if (!campaign.config_target_segment_id) {
    return <EmptyAudience isEditable={isEditable} onEdit={onEdit} />
  }

  const onDelete = () => {
    const updatedCampaign = { ...campaign, config_target_segment_id: null }
    dispatch(updateCampaign(campaign.id, { campaign: updatedCampaign }))
  }

  const onEditClick = () => {
    setConfig(campaign)
    onEdit()
  }

  return (
    <ReviewSection
      icon={AudienceIcon}
      isEditable={isEditable}
      onEdit={onEditClick}
      popoverValues={
        <PopoverOption onClick={onDelete}>{t('delete')}</PopoverOption>
      }
    >
      <Text.Header4 tag="div" className="mb-xs mt-xxs">
        <Translate value="thanx_campaigns.review.customers.title" />
      </Text.Header4>

      <div className="body-text-4 grey-70-text mb-m">
        {t('description.part1')}
        <span className="bold">{campaign.config_target_name}</span>
      </div>
      <div className="body-text-5 grey-50-text mb-xs">
        {t('timeDisclaimer')}
      </div>
      <div className="body-text-5 grey-50-text mb-m">
        {t('restrictions.oneTime')}
      </div>
      <Switch condition={isEditable}>
        <RecentlyRewarded
          isDisabled={isSaving}
          isChecked={!campaign.config_include_recently_rewarded_users}
        />
      </Switch>
    </ReviewSection>
  )
}

export default AudienceContent
