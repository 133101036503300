import {
  CREATE_CAMPAIGN_VARIANT_SUCCESS,
  DELETE_CAMPAIGN_VARIANT_SUCCESS,
  GET_CAMPAIGN_VARIANTS_SUCCESS,
  GET_CAMPAIGN_VARIANT_SUCCESS,
  INITIALIZE_CAMPAIGN_VARIANT_SUCCESS,
  ResolvedAction,
  UPDATE_CAMPAIGN_VARIANT_SUCCESS,
} from 'actions/campaignVariants'
import { RESET_DB } from 'actions/orm'
import {
  CampaignConfig,
  configTypes,
  Fields as Campaign,
} from 'models/Campaign'

import { attr, Model, ModelType } from 'redux-orm'
import { getMeta, sortVariants } from './helpers'

/* eslint-disable no-use-before-define */
export default class CampaignVariant extends Model<
  // @ts-ignore
  typeof CampaignVariant,
  Fields
> {
  /* eslint-enable no-use-before-define */
  static modelName = 'CampaignVariant'

  static get fields() {
    return {
      id: attr(),
      campaign_id: attr(),
    }
  }

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    CampaignVariant: ModelType<CampaignVariant>
  ) {
    function updateVariant(variant: Fields, index: number) {
      const { name, ...variantFields } = variant
      return {
        ...variantFields,
        variant_name: name,
        campaign_config_type: 'variant' as configTypes,
        redeem_template_id:
          //@ts-ignore
          variant.redeem_template_id ?? action.meta?.previousAction.templateId,
        meta: getMeta(
          variantFields.internal_type,
          variantFields.variant_name,
          index
        ),
      }
    }

    switch (action.type) {
      case GET_CAMPAIGN_VARIANTS_SUCCESS:
        action.payload.data.variants
          .sort(sortVariants)
          .forEach((variant, i) => {
            CampaignVariant.upsert(updateVariant(variant, i))
          })
        break
      case CREATE_CAMPAIGN_VARIANT_SUCCESS:
      case GET_CAMPAIGN_VARIANT_SUCCESS:
      case UPDATE_CAMPAIGN_VARIANT_SUCCESS:
      case INITIALIZE_CAMPAIGN_VARIANT_SUCCESS:
        const variant = CampaignVariant.withId(action.payload.data.variant.id)
        const index =
          variant?.meta?.index ||
          CampaignVariant.filter({
            campaign_id: action.payload.data.variant.campaign_id,
          }).count()
        CampaignVariant.upsert(
          updateVariant(action.payload.data.variant, index)
        )
        break
      case DELETE_CAMPAIGN_VARIANT_SUCCESS: {
        const variant = CampaignVariant.withId(
          action.meta.previousAction.variantId
        )
        if (variant) variant.delete()
        break
      }
      case RESET_DB:
        CampaignVariant.all().delete()
        break
      default:
        break
    }
  }
}

export type VariantMeta = {
  name: string
  color: string
  letter: string
  isControl: boolean
  index: number
}

export interface Fields extends CampaignConfig {
  id: number
  campaign_id: number
  name: string
  created_at: string
  variant_name: string
  internal_type: string
  meta?: VariantMeta
}

export type VariantOrCampaign = Fields | Campaign
