import { Fields } from 'models/UserProfile'
import moment from 'moment-timezone'

type FormattedDate = {
  day: string
  time: string
}

export function formatDate(timezone, date?: string): FormattedDate | null {
  if (!date) return null

  return {
    day: moment.tz(date, timezone).format('MMM D, YYYY'),
    time: moment.tz(date, timezone).format('h:mm a z'),
  }
}

export function getFullName(profile: Fields): string {
  return [profile.first_name, profile.last_name].filter(Boolean).join(' ')
}

export function generateTimeOptions(timeZone: string) {
  const times: Array<{ label: string; value: string }> = []
  const date = moment.tz(timeZone).startOf('day')

  for (let hour = 0; hour < 24; hour++) {
    date.hours(hour)
    times.push({
      label: date.format('hh:mm a z'),
      value: date.format('HH:mm Z'),
    })
  }

  return times
}
