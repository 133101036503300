import { RESET_DB } from 'actions/orm'
import {
  GET_ALL_SUCCESS,
  GET_SUCCESS,
  ResolvedAction,
  UPDATE_SUCCESS,
} from 'actions/pointsExperiences'
import { fk, Model, ModelType } from 'redux-orm'
import { ImageUrlsT } from 'utilities/types'

export type State = 'inactive' | 'migrating' | 'active' | 'archived'

export type SyncState = 'not_running' | 'running' | 'error'

export type ExpirationTypes = 'inactivity' | 'fixed_period'

export interface Fields {
  id: number
  name: string
  currency_name: string
  currency_name_plural: string
  merchant_id: number
  conversion_rate: number
  earn_description: string
  earn_image: ImageUrlsT
  currency_primary_icon: ImageUrlsT
  currency_secondary_icon: ImageUrlsT
  state: State
  activated_at: string | null
  archived_at: string | null
  sync_state: SyncState
  last_synced_at: string | null
  points_expires_in_months: number
  expiration_type: ExpirationTypes
}

export default class PointsExperience extends Model<
  // @ts-ignore
  typeof PointsExperience,
  Fields
> {
  static modelName: string = 'PointsExperience'

  static get fields(): any {
    return {
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'pointsExperiences',
      }),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    PointsExperience: ModelType<PointsExperience>
  ): void {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_ALL_SUCCESS:
        action.payload.data.points_experiences.forEach(experience => {
          PointsExperience.upsert(experience)
        })
        break
      case GET_SUCCESS:
      case UPDATE_SUCCESS:
        PointsExperience.upsert(action.payload.data.points_experience)
        break
      case RESET_DB:
        PointsExperience.all().delete()
        break
      default:
        break
    }
  }
}
