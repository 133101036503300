import zipObject from 'lodash/zipObject'
import { LifecycleData } from 'models/MerchantMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

export const stagesWithDetailedReport = [
  'created_account',
  'made_first_purchase',
  'made_second_purchase',
  'made_third_purchase',
] as const

export const stages = ['entered_database', ...stagesWithDetailedReport] as const

export const metricsWithDetailedReport = [
  'joined_user_count',
  'first_purchased_user_count',
  'second_purchased_user_count',
  'third_purchased_user_count',
] as const

export const metrics = ['user_count', ...metricsWithDetailedReport] as const

export const segments = [
  'joined',
  'first_purchased',
  'second_purchased',
  'third_purchased',
] as const

export const stagesMetricsMapping = zipObject(stages, metrics)
export const stagesMetricsMappingWithDetailedReport = zipObject(
  stagesWithDetailedReport,
  metricsWithDetailedReport
)
export const metricsStagesMapping = zipObject(metrics, stages)
export const stagesSegmentsMapping = zipObject(
  stagesWithDetailedReport,
  segments
)

export const stageSubcategoryMapping = {
  created_account: 'Drive account creation',
  made_first_purchase: 'Drive 1st purchase',
  made_second_purchase: 'Drive 2nd purchase',
  made_third_purchase: 'Drive 3rd purchase',
}

const benchmarks = {
  Mall: {
    all_time: {
      joined_user_count: 0.99,
      first_purchased_user_count: 0.74,
      second_purchased_user_count: 0.68,
      third_purchased_user_count: 0.8,
    },
    current: {
      joined_user_count: 0.99,
      first_purchased_user_count: 0.8,
      second_purchased_user_count: 0.5,
      third_purchased_user_count: 0.62,
    },
  },
  'Food: Quick Serve': {
    all_time: {
      joined_user_count: 0.81,
      first_purchased_user_count: 0.77,
      second_purchased_user_count: 0.68,
      third_purchased_user_count: 0.79,
    },
    current: {
      joined_user_count: 1.0,
      first_purchased_user_count: 0.77,
      second_purchased_user_count: 0.42,
      third_purchased_user_count: 0.53,
    },
  },
  'Food: Casual': {
    all_time: {
      joined_user_count: 1.0,
      first_purchased_user_count: 0.65,
      second_purchased_user_count: 0.62,
      third_purchased_user_count: 0.79,
    },
    current: {
      joined_user_count: 1.0,
      first_purchased_user_count: 0.79,
      second_purchased_user_count: 0.32,
      third_purchased_user_count: 0.47,
    },
  },
  'Car Wash': {
    all_time: {
      joined_user_count: 1.0,
      first_purchased_user_count: 0.54,
      second_purchased_user_count: 0.79,
      third_purchased_user_count: 0.85,
    },
    current: {
      joined_user_count: 1.0,
      first_purchased_user_count: 0.48,
      second_purchased_user_count: 0.44,
      third_purchased_user_count: 0.51,
    },
  },
  'Food: Coffee/Snack': {
    all_time: {
      joined_user_count: 0.93,
      first_purchased_user_count: 0.74,
      second_purchased_user_count: 0.74,
      third_purchased_user_count: 0.84,
    },
    current: {
      joined_user_count: 1.0,
      first_purchased_user_count: 0.81,
      second_purchased_user_count: 0.38,
      third_purchased_user_count: 0.55,
    },
  },
  'Food: Full Service': {
    all_time: {
      joined_user_count: 0.43,
      first_purchased_user_count: 0.37,
      second_purchased_user_count: 0.56,
      third_purchased_user_count: 0.72,
    },
    current: {
      joined_user_count: 1.0,
      first_purchased_user_count: 0.41,
      second_purchased_user_count: 0.34,
      third_purchased_user_count: 0.47,
    },
  },
  Other: {
    all_time: {
      joined_user_count: 1.0,
      first_purchased_user_count: 0.69,
      second_purchased_user_count: 0.86,
      third_purchased_user_count: 0.87,
    },
    current: {
      joined_user_count: 1.0,
      first_purchased_user_count: 0.74,
      second_purchased_user_count: 0.58,
      third_purchased_user_count: 0.59,
    },
  },
}

export const getBenchmarkConversionRate = ({
  merchantType,
  metric,
  recency = 'all_time',
}) => (benchmarks[merchantType] || benchmarks['Other'])[recency][metric]

export const calculateBenchmarkLevel = ({
  merchantType,
  metric,
  previousStageCount,
  recency = 'all_time',
}) =>
  getBenchmarkConversionRate({ merchantType, metric, recency }) *
  previousStageCount

const formatValue = (formater, value, ...params) => {
  if (!value) {
    return '-'
  }

  return formater(value, params)
}

export type LifecycleOpportunityMetrics = {
  loyalty_to_first_purchase: {
    current_metric_name: 'first_purchased_user_count'
    previous_members_count: string
    unconverted: string
    unconverted_percentage: string
  }
  first_purchase_to_second_purchase: {
    current_metric_name: 'second_purchased_user_count'
    previous_members_count: string
    unconverted: string
    unconverted_percentage: string
  }
  second_purchase_to_third_purchase: {
    current_metric_name: 'third_purchased_user_count'
    previous_members_count: string
    unconverted: string
    unconverted_percentage: string
  }
}

export const calculateLifecycleOpportunityMetrics = (
  lifecycleData: LifecycleData
): LifecycleOpportunityMetrics => {
  const {
    joined_user_count,
    first_purchased_user_count,
    second_purchased_user_count,
    third_purchased_user_count,
  } = lifecycleData || {}

  return {
    loyalty_to_first_purchase: {
      current_metric_name: 'first_purchased_user_count',
      previous_members_count: formatValue(numberFormatter, joined_user_count),
      unconverted: formatValue(
        numberFormatter,
        joined_user_count - first_purchased_user_count
      ),
      unconverted_percentage: formatValue(
        percentageFormatter,
        (joined_user_count - first_purchased_user_count) / joined_user_count,
        1
      ),
    },
    first_purchase_to_second_purchase: {
      current_metric_name: 'second_purchased_user_count',
      previous_members_count: formatValue(
        numberFormatter,
        first_purchased_user_count
      ),
      unconverted: formatValue(
        numberFormatter,
        first_purchased_user_count - second_purchased_user_count
      ),
      unconverted_percentage: formatValue(
        percentageFormatter,
        (first_purchased_user_count - second_purchased_user_count) /
          first_purchased_user_count,
        1
      ),
    },
    second_purchase_to_third_purchase: {
      current_metric_name: 'third_purchased_user_count',
      previous_members_count: formatValue(
        numberFormatter,
        second_purchased_user_count
      ),
      unconverted: formatValue(
        numberFormatter,
        second_purchased_user_count - third_purchased_user_count
      ),
      unconverted_percentage: formatValue(
        percentageFormatter,
        (second_purchased_user_count - third_purchased_user_count) /
          second_purchased_user_count,
        1
      ),
    },
  }
}
