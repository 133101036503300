import { Switch } from '@thanx/uikit/switch'
import { Fields as Product } from 'models/Product'
import React from 'react'
import { useStyletron } from 'styletron-react'
import { currencyFormatter } from 'utilities/formatters'
import placeholder from './placeholder.svg'

type Props = {
  product: Product
  displayDeleteIcon?: boolean
  onDelete?: () => void
}

const ProductItem: React.FC<Props> = ({
  product,
  displayDeleteIcon,
  onDelete,
}) => {
  const { name, price, image } = product
  const [css] = useStyletron()

  return (
    <div className="d-flex align-items-center border-1 grey-30-border border-radius-5 grey-05-bkg p-xs mb-xs">
      <img
        alt={name}
        className={`${css({
          width: '32px',
          height: '32px',
        })} mr-xs`}
        src={image.default || placeholder}
      />
      <p className="flex-1 mb-0 body-text-4 grey-70">{name}</p>
      <Switch condition={price > 0}>
        <p className="mr-l mb-0 body-text-4 grey-70">
          {currencyFormatter(price)}
        </p>
      </Switch>
      <Switch condition={!!displayDeleteIcon}>
        <span
          className="font-size-24 grey-70 light cursor-pointer"
          title="delete"
          onClick={onDelete}
        >
          &times;
        </span>
      </Switch>
    </div>
  )
}

export default ProductItem
