import { Fields } from 'models/Menu'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_MENUS = 'menus/GET_MENUS'
export const GET_MENUS_SUCCESS = 'menus/GET_MENUS_SUCCESS'
export const GET_MENUS_FAIL = 'menus/GET_MENUS_FAIL'

export function getMenus(
  location_id: number
): AxiosAction<typeof GET_MENUS, { menus: Fields[] }> {
  return {
    type: GET_MENUS,
    payload: {
      client: 'default',
      request: {
        url: `locations/${location_id}/menus`,
        params: {},
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<ReturnType<typeof getMenus>>
