import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import measureGraph from 'assets/images/reports/thanx_managed_support.svg'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'
import { useIsMobile } from 'utilities/responsive'
import {
  mailToMerchantSupportUrl,
  userSupportOptOutUrl,
  userSupportUrl,
} from 'utilities/urlUtils'

const t = buildTranslate('user_support')

type Props = {
  isSupportManagedByThanx: boolean
}

const ExplainerCard: React.FC<Props> = ({ isSupportManagedByThanx }) => {
  const isMobile = useIsMobile()
  const [css] = useStyletron()

  const mainButtonLinkTo = isSupportManagedByThanx
    ? userSupportUrl
    : mailToMerchantSupportUrl
  const secondaryButtonLinkTo = isSupportManagedByThanx
    ? userSupportOptOutUrl
    : userSupportUrl

  return (
    <Section
      className={`d-flex flex-wrap mb-xl mt-xl ${
        isMobile ? 'm-auto' : 'pl-l'
      } ${css({
        minHeight: '308px',
        backgroundColor: merchantTheme.colors.wisteria10,
        boxShadow: '0 1rem 3rem rgba(54, 66, 78, 0.2)',
      })}`}
    >
      <div className={`mt-xl ${isMobile ? 'ml-auto mr-auto' : ''}`}>
        <img src={measureGraph} alt="" />
      </div>
      <div
        className={`pl-l pt-l pb-m d-flex flex-column justify-content-between ${css(
          { width: isMobile ? '90%' : '70%' }
        )}`}
      >
        <section className="mb-s">
          <Text.Header4>
            {isSupportManagedByThanx
              ? t('explainer_card.title')
              : t('explainer_card.not_available_title')}
          </Text.Header4>
          <Text.BodyText3 color="grey70">
            {isSupportManagedByThanx
              ? t('explainer_card.subtitle')
              : t('explainer_card.not_available_subtitle')}
          </Text.BodyText3>
        </section>
        <section className="mb-xl">
          <Button onClick={() => window.open(mainButtonLinkTo)}>
            {isSupportManagedByThanx
              ? t('explainer_card.learn_more')
              : t('explainer_card.contact_us')}
          </Button>
          <Button
            className="ml-s"
            kind="secondary"
            onClick={() => window.open(secondaryButtonLinkTo)}
          >
            {isSupportManagedByThanx
              ? t('explainer_card.opt_out')
              : t('explainer_card.learn_more_secondary')}
          </Button>
        </section>
      </div>
    </Section>
  )
}

export default ExplainerCard
