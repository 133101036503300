import { useStyletron } from '@thanx/uikit/theme'
import { getTiersConfig } from 'actions/config'
import { getPointsExperiences } from 'actions/pointsExperiences'
import SectionHeader from 'components/Navbar/Bar/SectionHeader'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectTiersConfig } from 'selectors/config'
import { selectExperience } from 'selectors/pointsExperience'
import { useAbility } from 'utilities/ability'
import NavLink from '../Bar/NavLink'

const t = buildTranslate('nav.loyalty_menu')

type PropsT = {
  isLeftNav?: boolean
  onClick?: () => void
}

const LoyaltyContent: React.FC<PropsT> = ({
  isLeftNav = false,
  onClick = () => {},
}) => {
  const ability = useAbility()
  const pointsExperience = useSelector(selectExperience)
  const tiers = useSelector(selectTiersConfig)
  const dispatch = useDispatch()
  const [css] = useStyletron()

  useEffect(() => {
    if (!pointsExperience) {
      dispatch(getPointsExperiences())
    }
    if (!tiers) {
      dispatch(getTiersConfig())
    }
  }, [dispatch, pointsExperience, tiers])

  return (
    <>
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('points')}
        link="/points"
        disabled={
          ability.cannot('access', 'Points') ||
          !pointsExperience ||
          pointsExperience.state === 'archived'
        }
      />
      {ability.can('manage', 'all') && tiers && (
        <NavLink
          isLeftNav={isLeftNav}
          onClick={onClick}
          text={t('tiers')}
          link="/reports/tiers"
          status={'new'}
        />
      )}
      <div
        className={
          isLeftNav
            ? `my-s mx-m ${css({
                borderRadius: '4px',
                overflow: 'hidden',
              })}`
            : ''
        }
      >
        <SectionHeader
          isLeftNav={isLeftNav}
          text={I18n.t('nav.legacy.reports')}
          iconName="scroll"
          isLegacy
          learnMore={!isLeftNav}
        />
        <NavLink
          isLeftNav={isLeftNav}
          onClick={onClick}
          text={t('loyalty_setup')}
          link="/loyalty"
          disabled={ability.cannot('manage', 'LegacyReports')}
          isLegacy
        />
      </div>
    </>
  )
}

export default LoyaltyContent
