import reputationNav from 'assets/images/campaign-center/nav/ic_reputation_circle.png'
import messageNav from 'assets/images/campaign-center/nav/ic_speakerphone_circle.png'
import winbackNav from 'assets/images/campaign-center/nav/ic_winback_circle.png'
import targetNav from 'assets/images/campaign-center/nav/img_target.png'
import EditNavbar from 'components/Navbar/EditNavbar'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import SaveAndExit from 'scenes/CampaignCenter/Builder/components/SaveAndExit'
import { useShowV3 } from 'scenes/CampaignCenter/Builder/hooks'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import Breadcrumbs from './Breadcrumbs'
import Configuration from './Configuration'

type Props = {
  campaign: Campaign
  infoModal?: React.ReactNode
  children?: React.ReactNode
  triggerSave: () => Promise<void>
  stepName?: string | undefined
  setCurrentStep?: ((step: string) => void) | undefined
}

type ImageProps = {
  type: string
  category: string
}

const CampaignImage: React.FC<ImageProps> = ({ type, category }) => {
  const showV3 = useShowV3(type)
  if (showV3) return null
  let src
  switch (category) {
    case 'message':
      src = messageNav
      break
    case 'targeted':
      src = targetNav
      break
    case 'automated':
      switch (type) {
        case 'reputation':
          src = reputationNav
          break
        case 'winback':
          src = winbackNav
          break
        default:
          return null
      }
      break
    default:
      return null
  }

  return (
    <span className="padding-right-tiny">
      <img
        src={src}
        alt={`${type}-nav`}
        className="nudge-up margin-right-small relative"
        style={{ height: '36px' }}
      />
    </span>
  )
}

const TopBar: React.FC<Props> = props => {
  const {
    campaign,
    infoModal,
    children,
    triggerSave,
    stepName,
    setCurrentStep,
  } = props
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const showV3 = useShowV3(campaign?.type)
  const category = campaignType?.category || ''
  if (
    showV3 &&
    (stepName === 'incentive' ||
      stepName === 'audience' ||
      stepName === 'messaging')
  ) {
    return (
      <Configuration
        campaign={campaign}
        children={children}
        infoModal={infoModal}
        setCurrentStep={setCurrentStep}
        triggerSave={triggerSave}
        variants={campaign.variants}
      />
    )
  }
  const content = showV3 ? (
    <Breadcrumbs
      campaign={campaign}
      stepName={stepName}
      setCurrentStep={setCurrentStep}
      steps={['name', 'variantSelect', 'review']}
    />
  ) : (
    <Translate
      value={`thanx_campaigns.builder.nav.${campaign.type}.title`}
      className="font-size-20 padding-top-small padding-bottom-small inline-block"
      tag="div"
      dangerousHTML
    />
  )

  return (
    <EditNavbar
      shouldNotHide
      bkgClass={`category-${category}-bkg`}
      title={
        <>
          <CampaignImage type={campaign.type} category={category} />
          {content}
        </>
      }
    >
      {infoModal}
      <SaveAndExit triggerSave={triggerSave} />
      {children}
    </EditNavbar>
  )
}

export default TopBar
