import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'

type PropsT = {
  type: 'coming_soon' | 'beta' | 'new'
}

const t = buildTranslate('nav')

const StatusBadge: React.FC<PropsT> = props => {
  const { type } = props

  const [css] = useStyletron()
  let color, backgroundColor
  switch (type) {
    case 'coming_soon':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.grey20
      break
    case 'new':
      color = merchantTheme.colors.nephritis50
      backgroundColor = merchantTheme.colors.nephritis10
      break
    case 'beta':
      color = merchantTheme.colors.belizeHole50
      backgroundColor = merchantTheme.colors.belizeHole10
      break
    default:
      break
  }
  return (
    <Badge
      value={t(type)}
      className={`ml-xs ${css({
        backgroundColor,
        color,
      })}`}
    />
  )
}

export default StatusBadge
