export default {
  landing: {
    page_title: 'Loyalty status | Thanx',
    heading: {
      title: 'Your Loyalty Program',
      subtitle:
        'These are the benefits you offer customers in order to drive conversion and enroll a card.',
    },
  },
  campaign_details: {
    users_get: 'users get',
    action: 'when they',
    cost: 'cost to business',
    retail: 'retail price',
  },
  earned: {
    title: 'Earned Loyalty',
    subtext:
      'An ongoing earned loyalty reward to drive more visits. Customers get a reward each time they meet your requirements.',
    button_text: 'Contact us to activate',
    tab_icon_alt_text: 'earned loyalty',
  },
  intro: {
    title: 'Introductory Reward',
    subtext: 'Offer a one-time reward for customers who enroll their card.',
    button_text: 'Contact us to activate',
    tab_icon_alt_text: 'introductory reward',
  },
  tier: {
    customer_count: '%{count} customer',
    customer_count_plural: '%{count} customers',
    tier_name: '%{name} Tier',
    earn_requirements: 'Earn Requirements',
    reward_description: 'Reward Description',
  },
  tiers: {
    title: 'Tiers',
    subtext:
      'A flexible way to reward your long-time customers without relying solely on discounts.',
    button_text: 'Contact us to activate',
    tab_icon_alt_text: 'loyalty tiers',
  },
  notify_modal: {
    title: 'Thank you for your interest.',
    subtext: 'We’ll be in touch shortly.',
    button: 'OK',
  },
}
