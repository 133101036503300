import ImagePicker from 'components/ImagePicker'
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import UrlPicker, { UrlType } from 'scenes/Cms/components/UrlPicker'
import { ContentBlockData } from 'scenes/Cms/Content/AppHomePage/hooks'
import { selectAppHomePageValidation } from 'selectors/validation'
import MenuCategorySelector from './MenuCategorySelector'

type Props = {
  blockId: string
  contentBlock: ContentBlockData
}

const FeaturedContent = ({ blockId, contentBlock }: Props) => {
  const imageValidation = useSelector(selectAppHomePageValidation)
  if (!imageValidation) return <></>
  const { type } = contentBlock

  if (type !== 'featured_content') return <></>
  const { image, url } = contentBlock
  return (
    <>
      <ImagePicker
        className="mb-l"
        name={`${blockId}.image`}
        value={image}
        validations={{
          image: true,
        }}
        validationErrors={{
          image: I18n.t('validations.is_required'),
        }}
        imageValidations={imageValidation}
      />
      <UrlPicker
        id={blockId}
        type="image"
        url={url}
        required={false}
        hasQueryParam
      />
      {url.type === UrlType.Inapp &&
        url.inapp.startsWith('thanx://ordering-menu') && (
          <MenuCategorySelector id={blockId} url={contentBlock.url} />
        )}
    </>
  )
}

export default FeaturedContent
