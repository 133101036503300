import ColorPicker from 'components/Form/ColorPicker'
import ImageUploader from 'components/Form/ImageUploader'
import Input from 'components/Form/Input'
import TextEditor from 'components/Form/TextEditor'
import palette from 'constants/palette'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import ImageControls from '../ImageControls'
import ToggleContainer from '../ToggleContainer'

type Props = {
  campaign: Campaign
  additionalRewardInfo: React.ReactNode | null
  includeIntro?: boolean
  customButtonToggleGroup?: React.ReactNode
  disableButton?: boolean
  disableRedeem?: boolean
}
const Email: React.FC<Props> = props => {
  const {
    campaign,
    additionalRewardInfo,
    includeIntro = false,
    customButtonToggleGroup,
    disableButton,
    disableRedeem,
  } = props
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign.type)
  )
  const campaignPlaceholders = campaignType?.placeholders
  const { config } = useCampaignConfig()

  function subjectLine() {
    return (
      <Input
        className="margin-bottom-medium"
        label={I18n.t('thanx_campaigns.builder.forms.email.subject')}
        value={config.notify_email_subject}
        name="notify_email_subject"
        placeholder={campaignPlaceholders?.notify_email_subject}
        validations={{ isRequired: true }}
        validationErrors={{ isRequired: I18n.t('validations.is_required') }}
      />
    )
  }

  function previewText() {
    return (
      <Input
        className="margin-bottom-medium"
        label={I18n.t('thanx_campaigns.builder.forms.email.preview_text')}
        value={config.notify_email_preview_text}
        name="notify_email_preview_text"
        placeholder={campaignPlaceholders?.notify_email_preview_text}
        validations={{ isRequired: true }}
        validationErrors={{ isRequired: I18n.t('validations.is_required') }}
      />
    )
  }

  function heroImage() {
    return (
      <div className="margin-bottom-large">
        <ToggleContainer
          title={I18n.t('thanx_campaigns.builder.forms.email.hero_image.title')}
          openOnStart={config.notify_email_hero_image_included}
          name="notify_email_hero_image_included"
        >
          <ImageUploader
            minWidth={600}
            fieldName="notify_email_hero_image"
            name="notify_email_hero_image"
            setImageURL={() => {}}
            setIsLoading={() => {}}
            defaultText={''}
            handleUpdate={true}
          >
            <ImageControls
              imageUrl={config.notify_email_hero_image_urls?.default}
              linkUrl={config.notify_email_hero_url}
              title={I18n.t(
                'thanx_campaigns.builder.forms.email.hero_image.title'
              )}
              linkInputName="notify_email_hero_url"
            />
          </ImageUploader>
        </ToggleContainer>
      </div>
    )
  }

  function logoImage() {
    return (
      <div className="margin-bottom-large">
        <ToggleContainer
          title={I18n.t('thanx_campaigns.builder.forms.email.logo_image.title')}
          openOnStart={campaign.notify_email_header_image_included}
          name="notify_email_header_image_included"
        >
          <ImageUploader
            minWidth={100}
            fieldName="notify_email_header_image"
            name="notify_email_header_image"
            setImageURL={() => {}}
            setIsLoading={() => {}}
            defaultText={''}
            handleUpdate={true}
          >
            <ImageControls
              imageUrl={campaign.notify_email_header_image_urls?.default}
              linkUrl={campaign.notify_email_header_url}
              linkInputName="notify_email_header_url"
              title={I18n.t(
                'thanx_campaigns.builder.forms.email.logo_image.title'
              )}
            />
          </ImageUploader>
        </ToggleContainer>
      </div>
    )
  }

  function messageTitle() {
    return (
      <div className="margin-bottom-large">
        <Input type="hidden" name="notify_email_heading" value={null} />
        <ToggleContainer
          title={I18n.t('thanx_campaigns.builder.forms.email.title.text')}
          openOnStart={!isNull(campaign.notify_email_heading)}
        >
          <Input
            value={campaign.notify_email_heading || ''}
            name="notify_email_heading"
            placeholder={campaignPlaceholders?.notify_email_heading}
            validations={{ isRequired: true }}
            validationErrors={{ isRequired: I18n.t('validations.is_required') }}
            helpBlock={
              <em className="font-size-12 grey-50 block margin-top-tiny">
                {I18n.t('thanx_campaigns.builder.forms.email.title.help_text')}
              </em>
            }
          />
        </ToggleContainer>
      </div>
    )
  }

  function messageBody() {
    return (
      <div className="margin-bottom-large">
        <Input type="hidden" name="notify_email_body" value={null} />
        <ToggleContainer
          title={I18n.t('thanx_campaigns.builder.forms.email.body.text')}
          openOnStart={!isNull(campaign.notify_email_body)}
        >
          <TextEditor
            name="notify_email_body"
            value={
              isNil(campaign.notify_email_body)
                ? '<p><br></p>'
                : campaign.notify_email_body
            }
            placeholder={campaignPlaceholders?.notify_email_body}
            allowCustomFields={false}
            extraClassName="short"
            validations={{ isRequiredTextEditor: true }}
            validationErrors={{
              isRequiredTextEditor: I18n.t('validations.is_required'),
            }}
            helpBlock={
              <em className="font-size-12 grey-50 block margin-top-tiny">
                {I18n.t('thanx_campaigns.builder.forms.email.body.help_text')}
              </em>
            }
          />
        </ToggleContainer>
      </div>
    )
  }

  function buttonToggleGroup() {
    if (typeof customButtonToggleGroup !== 'undefined') {
      return customButtonToggleGroup
    }

    if (campaign.redeem) return null

    return (
      <div>
        <Input type="hidden" name="notify_email_button_text" value={''} />
        <Input type="hidden" name="notify_email_button_url" value={''} />
        <ToggleContainer
          title={I18n.t('thanx_campaigns.builder.forms.email.button.title')}
          openOnStart={
            !!campaign.notify_email_button_text &&
            !!campaign.notify_email_button_url
          }
        >
          <Input
            className="margin-bottom-medium"
            label={I18n.t('thanx_campaigns.builder.forms.email.button.text')}
            value={campaign.notify_email_button_text}
            name="notify_email_button_text"
            placeholder={campaignPlaceholders?.notify_email_button_text}
            validations={{ isRequired: true, noEmojis: true }}
            validationErrors={{
              isRequired: I18n.t('validations.is_required'),
              noEmojis: I18n.t('validations.no_emojis'),
            }}
          />
          <Row className="margin-bottom-medium">
            <Col xs={6}>
              <ColorPicker
                name="notify_email_link_color"
                label={I18n.t(
                  'thanx_campaigns.builder.forms.email.button.bkg_color'
                )}
                value={campaign.notify_email_link_color || '#818a91'}
                validations={{
                  isRequired: true,
                  hex: true,
                }}
                validationErrors={{
                  isRequired: I18n.t('validations.is_required'),
                  hex: I18n.t('validations.hex.message'),
                }}
              />
            </Col>
            <Col xs={6}>
              <ColorPicker
                name="notify_email_button_text_color"
                label={I18n.t(
                  'thanx_campaigns.builder.forms.email.button.text_color'
                )}
                value={campaign.notify_email_button_text_color || palette.white}
                validations={{
                  isRequired: true,
                  hex: true,
                }}
                validationErrors={{
                  isRequired: I18n.t('validations.is_required'),
                  hex: I18n.t('validations.hex.message'),
                }}
              />
            </Col>
          </Row>
          <Input
            className="margin-bottom-medium"
            label={I18n.t('thanx_campaigns.builder.forms.email.button.url')}
            value={campaign.notify_email_button_url}
            name="notify_email_button_url"
            placeholder={campaignPlaceholders?.notify_email_button_url}
            disabled={disableButton}
            validations={{
              isUrl: true,
              isRequired: true,
            }}
            validationErrors={{
              isUrl: I18n.t('validations.is_url'),
              isRequired: I18n.t('validations.is_required'),
            }}
          />
        </ToggleContainer>
      </div>
    )
  }

  function rewardColorFields() {
    return (
      <div>
        <Row className="margin-bottom-medium">
          <Col xs={6}>
            <ColorPicker
              name="notify_email_redeem_box_color"
              label={I18n.t(
                'thanx_campaigns.builder.forms.email.redeem_control.box_bkg'
              )}
              value={campaign.notify_email_redeem_box_color || '#818a91'}
              validations={{
                isRequired: true,
                hex: true,
              }}
              validationErrors={{
                isRequired: I18n.t('validations.is_required'),
                hex: I18n.t('validations.hex.message'),
              }}
            />
          </Col>
          <Col xs={6}>
            <ColorPicker
              name="notify_email_redeem_box_text_color"
              label={I18n.t(
                'thanx_campaigns.builder.forms.email.redeem_control.box_text'
              )}
              value={
                campaign.notify_email_redeem_box_text_color || palette.white
              }
              validations={{
                isRequired: true,
                hex: true,
              }}
              validationErrors={{
                isRequired: I18n.t('validations.is_required'),
                hex: I18n.t('validations.hex.message'),
              }}
            />
          </Col>
        </Row>
        <Row className="margin-bottom-medium">
          <Col xs={6}>
            <ColorPicker
              name="notify_email_redeem_button_color"
              label={I18n.t(
                'thanx_campaigns.builder.forms.email.redeem_control.button_bkg'
              )}
              value={campaign.notify_email_redeem_button_color || palette.white}
              validations={{
                isRequired: true,
                hex: true,
              }}
              validationErrors={{
                isRequired: I18n.t('validations.is_required'),
                hex: I18n.t('validations.hex.message'),
              }}
            />
          </Col>
          <Col xs={6}>
            <ColorPicker
              name="notify_email_redeem_button_text_color"
              label={I18n.t(
                'thanx_campaigns.builder.forms.email.redeem_control.button_text'
              )}
              value={
                campaign.notify_email_redeem_button_text_color || '#818a91'
              }
              validations={{
                isRequired: true,
                hex: true,
              }}
              validationErrors={{
                isRequired: I18n.t('validations.is_required'),
                hex: I18n.t('validations.hex.message'),
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }

  function signupSection() {
    if (!includeIntro) return null
    return (
      <div className="margin-bottom-large">
        <ToggleContainer
          title={I18n.t('thanx_campaigns.builder.forms.email.signup')}
          openOnStart={!!campaign.notify_email_conversion}
          name={'notify_email_conversion'}
          disabled={disableRedeem}
        >
          <em className="font-size-12 grey-50 margin-bottom-medium block">
            {I18n.t('thanx_campaigns.builder.forms.email.signup_message')}
          </em>
          {rewardColorFields()}
        </ToggleContainer>
      </div>
    )
  }

  function rewardSection() {
    if (campaign.redeem) return incentiveSection()
    return signupSection()
  }

  function incentiveSection() {
    return (
      <div className="margin-bottom-large">
        <ToggleContainer
          title={I18n.t('thanx_campaigns.builder.forms.email.incentive')}
          disabled={true}
        >
          <p className="font-size-20 margin-bottom-large grey-60">
            <i className="fa fa-gift inline-block margin-right-tiny" />
            {campaign.redeem_description}
            {additionalRewardInfo}
          </p>
          {rewardColorFields()}
        </ToggleContainer>
      </div>
    )
  }

  return (
    <div>
      <div className="margin-bottom-large">
        {subjectLine()}
        {previewText()}
      </div>
      {heroImage()}
      {logoImage()}
      {messageTitle()}
      {messageBody()}
      <div className="margin-bottom-large">{buttonToggleGroup()}</div>
      {rewardSection()}
    </div>
  )
}

export default Email
