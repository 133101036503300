import React from 'react'
import { Translate } from 'react-redux-i18n'

const textChoices = [
  'engage',
  'new_menu',
  'location',
  'slow_times',
  'frequency',
  'newsletter',
  'event',
  'rainy',
  'avg_check',
]

const ListMaker: React.FC<{ className: string }> = ({ className }) => {
  return (
    <span className={`absolute inline-block ${className}`}>
      {textChoices.map((text: string) => (
        <Translate
          value={`thanx_campaigns.landing.rotation_text.${text}`}
          key={text}
          tag="p"
          className="margin-bottom-none"
        />
      ))}
    </span>
  )
}

const TextRotation: React.FC = () => {
  return (
    <span className="relative overflow-hidden inline-block full-width font-size-32 extra-bold text-scroller">
      <ListMaker className="scroll-content" />
      <ListMaker className="scroll-content scroll-content-2" />
    </span>
  )
}

export default TextRotation
