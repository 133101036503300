import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('points.create_edit.restrictions.modal')

type PropT = {
  value?: number
}

const HiddenMenu: React.FC<PropT> = ({ value }) => {
  const [css] = useStyletron()

  return (
    <div className="d-flex align-items-center">
      <Text.BodyText4 color="grey70">{t('after_activate')}</Text.BodyText4>
      <FormsyInput
        // @ts-ignore
        className={`mx-xs ${css({
          width: '96px',
        })}`}
        name="redeem_retire_after_days"
        value={value}
      />
      <Text.BodyText4 color="grey70">{t('days')}</Text.BodyText4>
    </div>
  )
}

export default HiddenMenu
