// @flow
import React, { Component } from 'react'
import MessageTab from 'scenes/CampaignCenter/Builder/components/MessageTab'
import EmailForm from 'scenes/CampaignCenter/Builder/components/Forms/Email'
import SmsForm from 'scenes/CampaignCenter/Builder/components/Forms/Sms'
import PushForm from 'scenes/CampaignCenter/Builder/components/Forms/Push'
import EmailPreview from 'scenes/CampaignCenter/Builder/components/Previews/Email'
import SmsPreview from 'scenes/CampaignCenter/Builder/components/Previews/Sms'
import PushPreview from 'scenes/CampaignCenter/Builder/components/Previews/Push'
import { Tab, Row, Col } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import type { CampaignT } from 'models/Campaign'

type Props = {
  campaign: CampaignT,
  setSubstep?: string => void,
  disableButton?: boolean,
  disableRedeem?: boolean,
  customButtonToggleGroup?: any,
  canProceed: boolean,
  showValidationErrors?: () => void,
}
type State = {
  currentTab: string,
}

export class LegacyMessagingEditor extends Component<Props, State> {
  static defaultProps = {
    canProceed: true,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      currentTab: 'email',
    }
    const self: any = this
    self.setCurrentTab = this.setCurrentTab.bind(this)
    self.goToIncentive = this.goToIncentive.bind(this)
  }

  setCurrentTab(tab: string) {
    this.setState({ currentTab: tab })
  }

  preview() {
    switch (this.state.currentTab) {
      case 'email':
        return (
          <EmailPreview
            campaign={this.props.campaign}
            includePlaceholders={true}
          />
        )
      case 'push':
        return <PushPreview campaign={this.props.campaign} />
      case 'sms':
        return <SmsPreview campaign={this.props.campaign} />
      default:
        return null
    }
  }

  goToIncentive() {
    if (this.props.setSubstep) this.props.setSubstep('incentive')
  }

  additionalRewardInfo() {
    if (!this.props.campaign.redeem || !this.props.setSubstep) return null
    return (
      <span
        className="font-size-14 inline-block margin-left-extra-large a"
        onClick={this.goToIncentive}
      >
        <Translate value="thanx_campaigns.builder.steps.setup.messaging.email.customize" />
      </span>
    )
  }

  pushForm() {
    if (!this.props.campaign.redeem) return null
    return <PushForm campaign={this.props.campaign} />
  }

  smsForm() {
    if (!this.props.campaign.redeem) return null
    return <SmsForm campaign={this.props.campaign} />
  }

  render() {
    return (
      <Row>
        <Col
          md={6}
          className="padding-top-huge full-height-without-navs overflow-y"
        >
          <div className="padding-right-large">
            <Translate
              value="thanx_campaigns.builder.steps.setup.messaging.title"
              tag="p"
              className="grey-90 font-size-32 margin-bottom-micro bold"
            />
            <Translate
              value="thanx_campaigns.builder.steps.setup.messaging.subtitle"
              tag="em"
              className="grey-50 font-size-20 margin-bottom-extra-large block"
            />
            <MessageTab
              tabClick={this.setCurrentTab}
              emailOnly={!this.props.campaign.redeem}
              showTooltips={true}
              triggerValidationOnClick
              showValidationErrors={this.props.showValidationErrors}
              className="border-bottom-1 grey-20-border margin-bottom-huge"
            >
              <Tab.Content animation={false}>
                <Tab.Pane eventKey="email">
                  <EmailForm
                    campaign={this.props.campaign}
                    additionalRewardInfo={this.additionalRewardInfo()}
                    disableButton={this.props.disableButton}
                    disableRedeem={this.props.disableRedeem}
                    customButtonToggleGroup={this.props.customButtonToggleGroup}
                    includeIntro={this.props.campaign.type === 'message'}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="push">{this.pushForm()}</Tab.Pane>
                <Tab.Pane eventKey="sms">{this.smsForm()}</Tab.Pane>
                <Tab.Pane eventKey="facebook">tab 4</Tab.Pane>
              </Tab.Content>
            </MessageTab>
          </div>
        </Col>
        <Col
          md={6}
          className="grey-10-bkg padding-bottom-huge full-height-without-navs overflow-y"
        >
          <div className="padding-left-large padding-top-huge">
            {this.preview()}
          </div>
        </Col>
      </Row>
    )
  }
}

export default LegacyMessagingEditor
