import offersGraphic from 'assets/images/campaign-center/img_offers-graphic.png'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('thanx_campaigns.builder.previews.incentive.no_redeem')

const NoRedeem: React.FC = () => {
  return (
    <div className="white-bkg m-l p-xl">
      <h6 className="h6 grey-80">{t('title')}</h6>
      <p className="body-text-2 grey-60 padding-top-small">{t('subtitle')}</p>
      <img src={offersGraphic} alt="offer notification" />
    </div>
  )
}

export default NoRedeem
