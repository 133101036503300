import { Button, Kind } from '@thanx/uikit/button'
import { RadioGroup } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import { createCampaign } from 'actions/campaigns'
import { alert } from 'actions/flash'
import RichSelector from 'components/Form/RichSelector'
import Book from 'components/Icons/Book'
import BullHornRectangleIcon from 'components/Icons/BullHornRectangle'
import BullsEyeRectangleIcon from 'components/Icons/BullsEyeRectangle'
import RotateRectangleIcon from 'components/Icons/RotateRectangle'
import Modal from 'components/ModalContainer'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { useAbility } from 'utilities/ability'

const t = buildTranslate('thanx_campaigns.newCampaign')

type CampaignType = 'targeted' | 'message' | 'automated'

interface RouteConfig {
  campaignPath: string
  campaignType: string
}

const ROUTES: Record<CampaignType, RouteConfig> = {
  automated: {
    campaignType: 'custom_automated',
    campaignPath: '/thanx_campaigns/automated',
  },
  message: {
    campaignType: 'message',
    campaignPath: '/thanx_campaigns/message/message',
  },
  targeted: {
    campaignType: 'custom',
    campaignPath: '/thanx_campaigns/targeted',
  },
}

type Props = {
  isOpen: boolean
  onClose: () => void
}

const NewCampaignModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [selected, setSelected] = useState<CampaignType>('targeted')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()
  const ability = useAbility()

  async function handleContinue() {
    if (selected === 'message') {
      dispatch(push(ROUTES[selected].campaignPath))
      onClose()
      return
    }

    setIsSubmitting(true)

    const params = {
      campaign: {
        type: ROUTES[selected].campaignType,
      },
    }

    const action = await dispatch(createCampaign(params))
    if (action.error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('error_creating_campaign'),
        })
      )
      setIsSubmitting(false)
      return
    }

    const campaignId = action.payload?.data?.campaign?.id
    if (campaignId) {
      const { campaignPath, campaignType } = ROUTES[selected]
      const path = `${campaignPath}/${campaignType}/${campaignId}`
      dispatch(push(path))
    } else {
      dispatch(
        alert({
          key: 'danger',
          message: t('error_creating_campaign'),
        })
      )
    }
    setIsSubmitting(false)
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('title')}
      onClose={onClose}
      onProceed={handleContinue}
      proceedText={t('get_started')}
      canProceed={!isSubmitting}
      buttonGroupChildren={
        <div className="flex-1">
          <Link
            to={{
              pathname:
                'https://help.thanx.com/hc/en-us/categories/9020361665303-Marketing',
            }}
            target="_blank"
          >
            <Button kind={Kind.MINIMAL} size="small" className="p-0">
              {t('learn_more')}
              <Book className="ml-xs" />
            </Button>
          </Link>
        </div>
      }
    >
      <RadioGroup value={selected} className="mt-xl">
        <RichSelector
          disabled={ability.cannot('manage', 'TargetedCampaign')}
          value="targeted"
          image={<BullsEyeRectangleIcon />}
          imageClassName="ml-xs"
          className="mb-s"
          onClick={() => setSelected('targeted')}
        >
          <Text.Header4 bold className="mt-0">
            {t('targeted.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p" className="mb-0" color="grey70">
            {t('targeted.description')}
          </Text.BodyText4>
        </RichSelector>

        <RichSelector
          disabled={ability.cannot('manage', 'MessageCampaign')}
          value="message"
          image={<BullHornRectangleIcon />}
          imageClassName="ml-xs"
          className="mb-s"
          onClick={() => setSelected('message')}
        >
          <Text.Header4 bold className="mt-0">
            {t('message.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p" className="mb-0" color="grey70">
            {t('message.description')}
          </Text.BodyText4>
        </RichSelector>

        <RichSelector
          disabled={ability.cannot('manage', 'AutomatedCampaign')}
          value="automated"
          image={<RotateRectangleIcon />}
          imageClassName="ml-xs"
          onClick={() => setSelected('automated')}
        >
          <Text.Header4 bold className="mt-0">
            {t('automated.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p" className="mb-0" color="grey70">
            {t('automated.description')}
          </Text.BodyText4>
        </RichSelector>
      </RadioGroup>
    </Modal>
  )
}

export default NewCampaignModal
