import { Switch } from '@thanx/uikit/switch'
import includes from 'lodash/includes'
import type { Fields as Campaign } from 'models/Campaign'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ContinueButton from 'scenes/CampaignCenter/Builder/components/ContinueButton'
import TopBar from 'scenes/CampaignCenter/Builder/components/TopBar'
import Incentive from 'scenes/CampaignCenter/Builder/Steps/Shared/Incentive'
import InfoModal from 'scenes/CampaignCenter/components/InfoModal'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import Audience from './Substeps/Audience'
import Messaging from './Substeps/Messaging'

// TODO: MRC-1661 - Remove this file once `a-b-one-time` flag is fully enabled

type SubStep = 'audience' | 'incentive' | 'messaging'
type Props = {
  campaign: Campaign
  isSaving: boolean
  canProceed: boolean
  triggerSave: () => Promise<void>
  setCurrentStep: (step: string) => void
  showValidationErrors: () => void
}

const Setup: React.FC<Props> = props => {
  const {
    campaign,
    isSaving,
    canProceed,
    triggerSave,
    setCurrentStep,
    showValidationErrors,
  } = props
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )

  const substeps: SubStep[] = ['audience', 'incentive', 'messaging']
  const [currentSubstep, setCurrentSubstep] = useState<SubStep>('audience')

  function setSubstep(substep: SubStep) {
    if (includes(substeps, substep)) {
      setCurrentSubstep(substep)
    }
    window.scrollTo(0, 0)
  }

  function setNext() {
    let currentIndex = substeps.indexOf(currentSubstep)
    if (currentIndex === substeps.length - 1) return
    setSubstep(substeps[currentIndex + 1])
  }

  function continueClick() {
    if (currentSubstep === 'messaging') {
      setCurrentStep('review')
    } else {
      setNext()
    }
  }

  const campaignCategory = campaignType?.category
  return (
    <div>
      <TopBar
        triggerSave={triggerSave}
        campaign={campaign}
        infoModal={
          <InfoModal
            className="pull-left padding-top-micro margin-right-medium"
            campaignType={campaign?.type}
            campaignCategory={campaignCategory}
            withinBuilder
          >
            <i
              style={{ marginTop: '10px' }}
              className="fa fa-info-circle fa-2x cursor-pointer"
            />
          </InfoModal>
        }
      >
        <ContinueButton onClick={continueClick} />
      </TopBar>

      <div className="container">
        <Switch condition={currentSubstep === 'audience'}>
          <Audience campaign={campaign} />
        </Switch>
        <Switch condition={currentSubstep === 'incentive'}>
          <Incentive
            campaign={campaign}
            canProceed={canProceed}
            showValidationErrors={showValidationErrors}
            isSaving={isSaving}
            triggerSave={triggerSave}
          />
        </Switch>
        <Switch condition={currentSubstep === 'messaging'}>
          <Messaging
            campaign={campaign}
            canProceed={canProceed}
            showValidationErrors={showValidationErrors}
          />
        </Switch>
      </div>
    </div>
  )
}

export default Setup
