import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { CampaignStateT, MinimalVariant } from 'models/Campaign'
import { SignupMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import InfoRow from 'scenes/CampaignCenter/Report/v3/Test/components/InfoRow'
import { selectCampaignVariantMetricByType } from 'selectors/campaignMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'
import Section from '../Section'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type Props = {
  campaignId: number
  campaignState: CampaignStateT
  variantIds: number[]
  variants: MinimalVariant[]
  winningVariantId: number | null
}
const Signups: React.FC<Props> = ({
  campaignId,
  campaignState,
  variantIds,
  variants,
  winningVariantId,
}) => {
  const metrics =
    (useSelector(state =>
      selectCampaignVariantMetricByType(state, campaignId, 'signup', variantIds)
    ) as SignupMetric[]) || {}

  const variantContent = variantIds.map((_, i) => {
    const primaryValue = percentageFormatter(metrics[i]?.data?.rate, 1, 1)
    const secondaryValue = numberFormatter(metrics[i]?.data?.count)

    return {
      primaryContent: (
        <Metric metric={primaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('signups.tooltips.signup_rate'),
              },
              {
                title: t('how_its_calc'),
                content: t('signups.tooltips.signup_rate_calc'),
              },
              {
                title: t('example'),
                content: t('signups.tooltips.signup_rate_example_content1'),
              },
            ]}
          />
        </Metric>
      ),
      secondaryContent: (
        <Metric primary={false} metric={secondaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('signups.tooltips.signup_count'),
              },
              {
                title: t('how_its_calc'),
                content: t('signups.tooltips.signup_count_calc'),
              },
              {
                title: t('example'),
                content: t('signups.tooltips.signup_count_example_content1'),
              },
              {
                content: t('signups.tooltips.signup_count_example_content2'),
              },
            ]}
          />
        </Metric>
      ),
      isErrored: metrics[i]?.isErrored,
      isLoading: metrics[i]?.isLoading,
    }
  })

  return (
    <Section
      title={t('signups.title')}
      attributionWindow={-1}
      attributionTooltip={t('signups.attribution.tooltip')}
      campaignId={campaignId}
      metricTypes={['signup']}
      campaignState={campaignState}
      variants={variants}
      winningVariantId={winningVariantId}
    >
      <InfoRow title={t('signups.signed_up')} variants={variantContent} />
    </Section>
  )
}

export default Signups
