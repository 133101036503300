import { Fields } from 'models/HiddenMenu'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'

export const selectHiddenMenus = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.HiddenMenu.all().toRefArray()
  }
)
