import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import { RetentionRateTimeSeriesMetric } from 'models/RetentionRateMetric'
import moment from 'moment-timezone'
import React from 'react'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import { getDateLabel } from 'utilities/date'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'
import { RETENTION_WINDOW_DAYS } from '../helpers'

const t = buildTranslate('retention_rate')

type Props = {
  data: RetentionRateTimeSeriesMetric['values'][number]
  isErrored: boolean
  isLoading: boolean
}

const Row: React.FC<Props> = props => {
  const { data, isErrored, isLoading } = props
  const [css] = useStyletron()
  const borderClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: palette.grey30,
  })
  if (!data) {
    return null
  }

  const timeZone = moment.tz.guess()

  const retentionRate = percentageFormatter(data?.retention_rate, 1, 1)
  const churnRate = percentageFormatter(data?.churn_rate, 1, 1)

  const dateLabel = getDateLabel(data?.date_time, 'monthly', timeZone)
  const engagedUsersCount = numberFormatter(data?.engaged_users_count)
  const churnedUsersCount = numberFormatter(data?.churned_users_count)
  const returningUsersCount = numberFormatter(data?.returning_users_count)
  const retentionWindow = RETENTION_WINDOW_DAYS

  return (
    <tr>
      <BodyCell
        values={[{ value: dateLabel }]}
        isErrored={isErrored}
        isLoading={isLoading}
        className={borderClass}
      />
      <BodyCell
        values={[
          {
            value: numberFormatter(engagedUsersCount),
            sections: [
              {
                content: t('table.engaged_customers', { dateLabel })[0],
              },
              {
                title: t('popovers.what_this_means'),
                content: t('table.engaged_customers', {
                  dateLabel,
                  engagedUsersCount,
                })[1],
              },
            ],
          },
        ]}
        isErrored={isErrored}
        isLoading={isLoading}
        className={`${borderClass} text-right`}
      />
      <BodyCell
        values={[
          {
            value: retentionRate,
            sections: [
              {
                content: t('popovers.retention_rate', {
                  dateLabel,
                  retentionWindow,
                })[0],
              },
              {
                title: t('popovers.what_this_means'),
                content: t('popovers.retention_rate', {
                  dateLabel,
                  engagedUsersCount,
                  churnedUsersCount,
                  retentionRate,
                  returningUsersCount,
                  retentionWindow,
                })[1],
              },
            ],
          },
          {
            value: numberFormatter(returningUsersCount),
            sections: [
              {
                content: t('table.returned_customers', { retentionWindow })[0],
              },
              {
                title: t('popovers.what_this_means'),
                content: t('table.returned_customers', {
                  dateLabel,
                  engagedUsersCount,
                  retentionWindow,
                  returningUsersCount,
                })[1],
              },
            ],
          },
        ]}
        isErrored={isErrored}
        isLoading={isLoading}
        className={`${borderClass} text-right`}
      />
      <BodyCell
        values={[
          {
            value: churnRate,
            sections: [
              {
                content: t('table.churn_rate', {
                  dateLabel,
                  retentionWindow,
                })[0],
              },
              {
                title: t('popovers.what_this_means'),
                content: t('table.churn_rate', {
                  dateLabel,
                  engagedUsersCount,
                  churnedUsersCount,
                  returningUsersCount,
                  churnRate,
                  retentionWindow,
                })[1],
              },
            ],
          },
          {
            value: numberFormatter(churnedUsersCount),
            sections: [
              {
                content: t('table.churned_customers', { retentionWindow })[0],
              },
              {
                title: t('popovers.what_this_means'),
                content: t('table.churned_customers', {
                  dateLabel,
                  engagedUsersCount,
                  churnedUsersCount,
                  returningUsersCount,
                  retentionWindow,
                })[1],
              },
            ],
          },
        ]}
        isErrored={isErrored}
        isLoading={isLoading}
        className={`${borderClass} text-right`}
      />
    </tr>
  )
}

export default Row
