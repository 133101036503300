import InnerTabs from 'components/Tabs/InnerTabs'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import React from 'react'
import Analytics from './Analytics'
import OrderingConfiguration from './OrderingConfiguration'
import ReceiptSettings from './ReceiptSettings'
import SmartLinks from './SmartLinks'

export enum Tabs {
  Locations = 'locations',
  SmartLinks = 'smart_links',
  OrderingConfiguration = 'ordering_configuration',
  ReceiptSettings = 'receipt_settings',
  Analytics = 'analytics',
}

const t = buildTranslate('cms')

const Settings = () => {
  const smartLinksFlagEnabled = useFlag('use-smart-links', false)
  const smartLinksTab = {
    key: Tabs.SmartLinks,
    component: <SmartLinks />,
    title: t('settings.smart_links.title'),
  }
  const tabs = [
    ...(smartLinksFlagEnabled ? [smartLinksTab] : []),
    {
      key: Tabs.OrderingConfiguration,
      component: <OrderingConfiguration />,
      title: t('settings.ordering_configuration.title'),
    },
    {
      key: Tabs.ReceiptSettings,
      component: <ReceiptSettings />,
      title: t('settings.receipt_settings.title'),
    },
    {
      key: Tabs.Analytics,
      component: <Analytics />,
      title: t('settings.analytics.tab'),
    },
  ]

  return <InnerTabs tabs={tabs} />
}

export default Settings
