import { RESET_DB } from 'actions/orm'
import { GET_USER_PROFILE_SUCCESS, ResolvedAction } from 'actions/users'
import { Model, ModelType } from 'redux-orm'

export type CustomTagType = 'membershipTag' | 'specialOccasion'

export type CustomTagBase = {
  key: string
}

export type MembershipTag = {
  type: 'membershipTag'
  values: string[]
} & CustomTagBase

export type SpecialOcassionTag = {
  type: 'specialOccasion'
  occasion: string
  name: string
  month: number
  day: number
} & CustomTagBase

export type CustomTag = MembershipTag | SpecialOcassionTag

type PointsProvision = {
  id: number
  amount: number
  claimed_at: string
  created_at: string
}

export interface Fields {
  id: number
  uid: string
  first_name: string
  last_name: string
  membership_created_at: string
  tier: string | null
  lifetime_spend: number
  lifetime_visits: number
  average_check: number
  points_balance: number | null
  email: string
  phone: string
  birth_date: string
  birth_day: number
  birth_month: number
  current_tier: string | null
  custom_tags: Array<CustomTag>
  points_provisions: PointsProvision[]
  membership_state:
    | 'enrolled'
    | 'favorited'
    | 'disabled'
    | 'pending'
    | 'unconfirmed'
  show_pii: boolean
}

export default class UserProfile extends Model<
  // @ts-ignore
  typeof UserProfile,
  Fields
> {
  static modelName: string = 'UserProfile'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    UserProfile: ModelType<UserProfile>
  ): void {
    switch (action.type) {
      case GET_USER_PROFILE_SUCCESS:
        UserProfile.upsert(action.payload.data.profile)
        break
      case RESET_DB:
        UserProfile.all().delete()
        break
      default:
        break
    }
  }
}
