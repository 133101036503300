import { Switch } from '@thanx/uikit/switch'
import { Fields as Campaign, MinimalVariant } from 'models/Campaign'
import { sortVariants } from 'models/CampaignVariant/helpers'
import React from 'react'
import Chart from './Sections/Chart'
import Engagement from './Sections/Engagement'
import Footer from './Sections/Footer'
import Header from './Sections/Header'
import RevenueCosts from './Sections/RevenueCosts'
import Signups from './Sections/Signups'

type Props = {
  campaign: Campaign
  variants: MinimalVariant[]
}
const HasTest: React.FC<Props> = ({ campaign, variants }) => {
  const sortedVariants = variants.sort(sortVariants)
  const variantIds = sortedVariants.map(v => v.id)
  const variantTypes = sortedVariants.map(v => v.internal_type)
  const showTestStatus = campaign.type === 'custom_automated'
  return (
    <>
      <Switch condition={showTestStatus}>
        <Header campaign={campaign} />
      </Switch>
      <Chart campaign={campaign} />
      <RevenueCosts
        campaignId={campaign.id}
        campaignState={campaign.state}
        variantIds={variantIds}
        variantTypes={variantTypes}
        winningVariantId={campaign.winning_variant_id}
        variants={sortedVariants}
      />
      <Engagement
        campaignId={campaign.id}
        campaignState={campaign.state}
        variantIds={variantIds}
        winningVariantId={campaign.winning_variant_id}
        variants={sortedVariants}
      />
      <Signups
        campaignId={campaign.id}
        campaignState={campaign.state}
        variantIds={variantIds}
        winningVariantId={campaign.winning_variant_id}
        variants={sortedVariants}
      />
      <Switch condition={showTestStatus}>
        <Footer
          campaignId={campaign.id}
          campaignState={campaign.state}
          winningVariantId={campaign.winning_variant_id}
          variants={sortedVariants}
        />
      </Switch>
    </>
  )
}

export default HasTest
