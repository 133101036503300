import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { effectiveDiscountRateTableUrl } from 'utilities/urlUtils'

const t = buildTranslate('points.effective_discount_rate.table')

type Props = {
  isErrored: boolean
  isLoading: boolean
  borderClass: string
}

const NotInMarketplace: React.FC<Props> = ({
  isErrored,
  isLoading,
  borderClass,
}) => {
  return (
    <BodyCellContainer
      isLoading={isLoading}
      isErrored={isErrored}
      className={borderClass}
    >
      <div>
        <Text.BodyText5>{t('no_longer_in_marketplace')}</Text.BodyText5>
      </div>
      <div>
        <a
          href={effectiveDiscountRateTableUrl}
          className="body-text-5"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('learn_more')}
        </a>
      </div>
    </BodyCellContainer>
  )
}

export default NotInMarketplace
