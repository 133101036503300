import { StyleableButton } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import { CampaignStateT, Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import { LinkContainer } from 'react-router-bootstrap'
import Table from 'scenes/CampaignCenter/Landing/Reports/components/Table'
import { Column } from 'scenes/CampaignCenter/Landing/Reports/components/Table'

type Props = {
  className?: string
  title: React.ReactNode
  state?: CampaignStateT
  columns?: Column[]
  campaigns: Campaign[]
}

const t = buildTranslate('thanx_campaigns.landing.campaignsTableSection')

const CampaignsTableSection: React.FC<Props> = props => {
  const { className = '', title, state, columns, campaigns } = props

  const displayCampaigns = campaigns.slice(0, 5)
  const hasMore = campaigns.length > 5

  return (
    <Container className={className}>
      <h1 className="small-caps-1 mb-l grey-90">{title}</h1>
      <Table campaigns={displayCampaigns} state={state} columns={columns} />
      <Switch condition={hasMore}>
        <LinkContainer to="/thanx_campaigns/reports">
          <StyleableButton className="belize-hole-50">
            {t('seeMore')}
          </StyleableButton>
        </LinkContainer>
      </Switch>
    </Container>
  )
}

export default CampaignsTableSection
