import { buildTranslate } from 'locales'
import { DayBucketCountData } from 'models/MerchantMetric'
import React from 'react'
import BucketMultiMetric from 'scenes/Reports/ActivityReport/components/BucketMultiMetric'

const t = buildTranslate('reports.activity.sections.customers.metrics.rewards')

type Props = {
  earnedData: DayBucketCountData
  redeemedData: DayBucketCountData
}

export const Customers: React.FC<Props> = ({ earnedData, redeemedData }) => {
  return (
    <BucketMultiMetric
      title={t('title')}
      summaryText={t('summary_text')}
      bucketText={t('bucket_text')}
      keySuffix="count"
      metrics={[
        {
          header: t('earned_text'),
          data: earnedData,
        },
        {
          header: t('redeemed_text'),
          data: redeemedData,
        },
      ]}
    />
  )
}
