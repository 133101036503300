import {
  ResolvedAction,
  UPDATE_LANDING_LINKS_SUCCESS,
} from 'actions/landingLink'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'
import { Image } from './App'

export enum LandingLinkStyle {
  Solid = 'primary',
  Outline = 'outline-primary',
}

export enum LandingLinkSection {
  Main = 'main',
  CTA = 'cta',
}

export interface Fields {
  id: number
  landing_page_id: number
  title: string
  url: string
  unpressed_image_urls: Image | null
  pressed_image_urls: Image | null
  position: number
  section: LandingLinkSection
  style: LandingLinkStyle
  authenticable: boolean
}

// @ts-ignore
export default class LandingLink extends Model<typeof LandingLink, Fields> {
  static modelName: string = 'LandingLink'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    LandingLink: ModelType<LandingLink>
  ) {
    switch (action.type) {
      case UPDATE_LANDING_LINKS_SUCCESS:
        // Reset LandingLinks and build them again as some of the links
        // might be destroyed in the update request.
        LandingLink.all().delete()
        action.payload.data.links.forEach(header => {
          LandingLink.upsert(header)
        })
        break
      case RESET_DB:
        LandingLink.all().delete()
        break
      default:
        break
    }
  }
}
