import {
  addNonApiFieldsToCampaign,
  getCampaign,
  ResolvedAction as ResolvedCampaignAction,
  updateCampaign,
} from 'actions/campaigns'
import { getCampaignType } from 'actions/campaignTypes'
import {
  ResolvedAction as ResolvedCampaignVariantAction,
  updateCampaignVariant,
} from 'actions/campaignVariants'
import { alert } from 'actions/flash'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import useFetchCampaignVariants from 'hooks/useFetchCampaignVariants'
import { buildTranslate } from 'locales'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { replace } from 'react-router-redux'
import { selectCampaign } from 'selectors/campaign'
import { selectCampaignConfig } from 'selectors/campaignConfig'
import { useAbility } from 'utilities/ability'
import CampaignConfigContext from './CampaignConfigContext'

import {
  addAdditionalFields,
  canSave,
  extractVariantFields,
  filterFields,
  modelsToSave,
  overrideFields,
} from './helper'
import StepManager from './StepManager'

const t = buildTranslate('thanx_campaigns.builder')

type Props = RouteComponentProps<{ id: string; type: string }>

const BuilderContainer: React.FC<Props> = props => {
  const { id, type } = props.match.params

  const [isLoadingCampaign, setIsLoadingCampaign] = useState(true)
  const [isLoadingCampaignType, setIsLoadingCampaignType] = useState(true)
  const [hasLoadingError, setHasLoadingError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [config, setConfig] = useState<VariantOrCampaign | null>(null)
  const campaign = useSelector(state => selectCampaign(state, parseInt(id, 10)))
  const dispatch = useDispatch()
  const ability = useAbility()
  const shouldFetchData =
    (type === 'automated' && ability.cannot('manage', 'AutomatedCampaign')) ||
    (type === 'message' && ability.cannot('manage', 'MessageCampaign'))

  const campaignConfig = useSelector(state =>
    selectCampaignConfig(
      // @ts-ignore
      state,
      Number(id),
      config?.campaign_config_type === 'variant' ? config?.id : undefined
    )
  )
  useEffect(() => {
    async function fetchData() {
      const campaignTypeResponse = await dispatch(getCampaignType(type))
      if (campaignTypeResponse.error) {
        const error = campaignTypeResponse.error?.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: t('errors.load_campaign_type'),
            error,
          })
        )
        setHasLoadingError(true)
      }
      setIsLoadingCampaignType(false)

      const campaignResponse = await dispatch(getCampaign(Number(id)))
      if (campaignResponse.error) {
        const error = campaignResponse.error?.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: t('load_campaign'),
            error,
          })
        )
        setHasLoadingError(true)
      }
      setIsLoadingCampaign(false)
    }

    if (shouldFetchData) {
      dispatch(replace('/thanx_campaigns'))
    } else {
      fetchData()
    }
  }, [type, id, dispatch, shouldFetchData])

  useEffect(() => {
    setConfig(campaignConfig)
  }, [campaignConfig])

  const [hasFetchedVariants, isFetchingVariants] =
    useFetchCampaignVariants(campaign)

  async function triggerSaveImpl(model) {
    // Remove extraneous keys
    model = filterFields(model)
    if (campaign && config && canSave(campaign, config, model, isSaving)) {
      model = addAdditionalFields(model)
      const nonApiFields = pick(model, [
        'notify_email_hero_image',
        'notify_email_header_image',
        'campaign_config_type',
        'meta',
      ])
      // _.merge doesn't do what we want for nested arrays (it merges instead
      // of replacing)
      const overridenCampaign = overrideFields(campaign, model)
      const conformedCampaignModel = merge({}, overridenCampaign, model) // adds back any keys that weren't in both
      setIsSaving(true)
      let promises: any = []
      if (config?.campaign_config_type === 'variant') {
        const overridenVariant = overrideFields(config, model)
        const conformedVariantModel = merge({}, overridenVariant, model) // adds back any keys that weren't in both
        const [extractedCampaign, extractedVariant] = extractVariantFields(
          conformedVariantModel
        )
        const models = modelsToSave(extractedCampaign, extractedVariant, model)
        if (models.includes('campaign')) {
          promises.push(
            dispatch(
              updateCampaign(campaign.id, { campaign: extractedCampaign })
            )
          )
        }
        if (models.includes('variant')) {
          promises.push(
            dispatch(
              updateCampaignVariant(campaign.id, config.id, extractedVariant)
            )
          )
        }
      } else {
        promises = [
          dispatch(
            updateCampaign(campaign.id, { campaign: conformedCampaignModel })
          ),
        ]
      }

      const responses = (await Promise.all(promises)) as
        | ResolvedCampaignAction[]
        | ResolvedCampaignVariantAction[]

      if (responses.some(response => response.error)) {
        dispatch(
          alert({
            key: 'danger',
            message: t('save_campaign'),
            timeout: 5,
          })
        )
      } else if (!isEmpty(nonApiFields)) {
        addNonApiFieldsToCampaign(campaign.id, nonApiFields)
      }
      setIsSaving(false)
    }
  }

  const triggerSave = debounce(triggerSaveImpl, 750)

  if (
    !campaign ||
    isLoadingCampaign ||
    isLoadingCampaignType ||
    !hasFetchedVariants ||
    isFetchingVariants ||
    !config
  ) {
    return (
      <Spinner
        isLoading={true}
        size="4x"
        className="text-center padding-top-huge"
      />
    )
  } else if (hasLoadingError) {
    return (
      <div className="padding-top-huge text-center margin-top-large">
        <Button
          bsStyle="primary"
          bsSize="large"
          onClick={() => {
            // eslint-disable-next-line no-self-assign
            window.location = window.location
          }}
        >
          {t('reload_button')}
        </Button>
      </div>
    )
  }
  return (
    <CampaignConfigContext.Provider
      value={{
        config: config as unknown as VariantOrCampaign,
        setConfig,
      }}
    >
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <StepManager
        campaign={campaign}
        triggerSave={triggerSave}
        isSaving={isSaving}
      />
    </CampaignConfigContext.Provider>
  )
}

export default BuilderContainer
