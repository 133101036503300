const storedValue = {
  title: 'Stored value',
  tabs: {
    configuration: 'Configuration',
    activity: 'Activity',
  },
  stored_value_configuration: {
    title: 'Configuration',
    email_subject: 'I would like to edit my stored value configuration',
    email_button: 'Contact us to edit',
    card_images: {
      title: 'Card images',
      image: {
        title: 'Hero',
        description: 'Customers see this on the “Manage Your Balance” page',
        size: '1536px x 969px',
        ext: 'PNG',
      },
      icon: {
        title: 'Badge',
        description:
          'Customers see this as a badge next to their payment method during checkout',
        size: '72px x 48px',
        ext: 'PNG',
      },
    },
    funding: {
      title: 'Fund increments',
      description:
        'Customer can add funds to their balance (manually or via auto reload) in increments shown below.',
      increment: 'Increment %{count}',
      bonus: 'bonus',
    },
    auto_reload: {
      title: 'Auto reload',
      description:
        'If auto reload is on, funds will be added automatically when the customer’s balance falls below a certain threshold. The customer can configure that threshold, however you can set a minimum so the customer doesn’t set their threshold too low. Higher thresholds mean auto reload will happen more often.',
      min_threshold: 'Minimum threshold',
      min_amount: 'Minimum auto reload amount',
      max_amount: 'Maximum auto reload amount',
    },
  },
  empty_state: {
    title: 'Stored value is not configured',
    description1:
      'This groundbreaking offering (reminiscent of Starbucks’ payment system) allows consumers to preload funds for future digital and in-store purchases, all without the need for additional hardware.',
    description2:
      'This innovation offers a host of benefits. It enhances customer retention and revenue by encouraging consumers to return for subsequent purchases. Plus, it ensures upfront cash flow for restaurants, as stored value balances become immediately available upon loading. Starbucks, a pioneer in this space, currently maintains a prepaid balance of approximately two billion dollars, showcasing the immense potential of such systems.',
    contact_us: 'Contact us to learn more',
  },
}

export default storedValue
