import { buildTranslate } from 'locales'
import isNil from 'lodash/isNil'
import { ChannelData } from 'models/CaptureRateMetric'
import moment from 'moment-timezone'
import React from 'react'
import { getFormattedDate } from 'utilities/date'
import { currencyFormatter, percentageFormatter } from 'utilities/formatters'
import BodyCell from './BodyCell'

const t = buildTranslate('capture_rate.popovers')

type Props = {
  data: Partial<ChannelData>
  dateTime: string
  isErrored: boolean
  isLoading: boolean
}

const Row: React.FC<Props> = props => {
  const { data, isErrored, isLoading } = props
  const timeZone = moment.tz.guess()

  if (!data) {
    return null
  }

  const displayDateTime = getFormattedDate(
    moment.tz(data.date_time, timeZone),
    'monthly'
  )

  const displayPercent = (value?: number) => {
    if (isNil(value)) return '-'
    return percentageFormatter(value / 100, 1, 1)
  }

  const displayAmount = (value?: number) => {
    if (isNil(value)) return '-'
    return currencyFormatter(value, false)
  }

  return (
    <tr>
      <BodyCell
        isErrored={isErrored}
        isLoading={isLoading}
        values={[{ value: displayDateTime }]}
      />
      <BodyCell
        className="text-right"
        isErrored={isErrored}
        isLoading={isLoading}
        values={[
          {
            value: displayPercent(data.member_purchases_instore_percent),
            sections: [
              {
                content: t(
                  'revenue_by_channel.in_store.loyalty.percent.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.in_store.loyalty.percent.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.in_store.loyalty.percent.section_3'
                ),
              },
            ],
          },
          {
            value: displayAmount(data.member_purchases_instore_amount),
            sections: [
              {
                content: t(
                  'revenue_by_channel.in_store.loyalty.amount.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.in_store.loyalty.amount.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.in_store.loyalty.amount.section_3'
                ),
              },
            ],
          },
        ]}
      />
      <BodyCell
        className="text-right"
        isErrored={isErrored}
        isLoading={isLoading}
        values={[
          {
            value: displayPercent(data.non_member_purchases_instore_percent),
            sections: [
              {
                content: t(
                  'revenue_by_channel.in_store.unknown.percent.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.in_store.unknown.percent.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.in_store.unknown.percent.section_3'
                ),
              },
            ],
          },
          {
            value: displayAmount(data.non_member_purchases_instore_amount),
            sections: [
              {
                content: t(
                  'revenue_by_channel.in_store.unknown.amount.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.in_store.unknown.amount.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.in_store.unknown.amount.section_3'
                ),
              },
            ],
          },
        ]}
      />
      <BodyCell
        className="text-right"
        isErrored={isErrored}
        isLoading={isLoading}
        values={[
          {
            value: displayPercent(data.member_purchases_digital_percent),
            sections: [
              {
                content: t(
                  'revenue_by_channel.digital.loyalty.percent.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.digital.loyalty.percent.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.digital.loyalty.percent.section_3'
                ),
              },
            ],
          },
          {
            value: displayAmount(data.member_purchases_digital_amount),
            sections: [
              {
                content: t(
                  'revenue_by_channel.digital.loyalty.amount.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.digital.loyalty.amount.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.digital.loyalty.amount.section_3'
                ),
              },
            ],
          },
        ]}
      />
      <BodyCell
        className="text-right"
        isErrored={isErrored}
        isLoading={isLoading}
        values={[
          {
            value: displayPercent(data.non_member_purchases_digital_percent),
            sections: [
              {
                content: t(
                  'revenue_by_channel.digital.unknown.percent.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.digital.unknown.percent.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.digital.unknown.percent.section_3'
                ),
              },
            ],
          },
          {
            value: displayAmount(data.non_member_purchases_digital_amount),
            sections: [
              {
                content: t(
                  'revenue_by_channel.digital.unknown.amount.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.digital.unknown.amount.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.digital.unknown.amount.section_3'
                ),
              },
            ],
          },
        ]}
      />
      <BodyCell
        className="text-right"
        isErrored={isErrored}
        isLoading={isLoading}
        values={[
          {
            value: displayPercent(data.third_party_percent),
            sections: [
              {
                content: t(
                  'revenue_by_channel.third_party.unknown.percent.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.third_party.unknown.percent.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.third_party.unknown.percent.section_3'
                ),
              },
            ],
          },
          {
            value: displayAmount(data.third_party_amount),
            sections: [
              {
                content: t(
                  'revenue_by_channel.third_party.unknown.amount.section_1'
                ),
              },
              {
                title: t('how_its_calculated'),
                content: t(
                  'revenue_by_channel.third_party.unknown.amount.section_2'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'revenue_by_channel.third_party.unknown.amount.section_3'
                ),
              },
            ],
          },
        ]}
      />
    </tr>
  )
}

export default Row
