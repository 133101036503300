import { Button, Kind, Size } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Help from 'components/Help'
import PopoverContent from 'components/PopoverContent'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import React from 'react'
import { push } from 'react-router-redux'
import { helpArticles } from './helpers'

type Props = {
  canEdit: boolean
  pointsExperience: PointsExperience
}

const t = buildTranslate('points.configuration.expiration')
const ht = buildTranslate('points.configuration.help')

const Expiration: React.FC<Props> = ({ canEdit, pointsExperience }) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const {
    id,
    points_expires_in_months,
    expiration_type = 'inactivity',
  } = pointsExperience

  return (
    <section className="grey-90">
      <Text.Header4 tag="div" className="mb-xl">
        {t('title')}
      </Text.Header4>
      <div className="card p-m white-bkg">
        <div className="d-flex">
          <Text.BodyText4 bold tag="div" className="mb-m flex-1">
            {t('when_points_expire')}
          </Text.BodyText4>
          {canEdit && (
            <Button
              kind={Kind.SECONDARY}
              size={Size.SMALL}
              className={css({ height: '32px' })}
              onClick={() =>
                dispatch(push(`/points/experiences/${id}/expiration/edit`))
              }
            >
              {t('edit')}
            </Button>
          )}
        </div>
        <Text.BodyText3 color="grey70">
          {points_expires_in_months}{' '}
          {t(`${expiration_type}.months_after_purchase`)}
          <span className="ml-xs">
            <Switch condition={expiration_type === 'inactivity'}>
              <PopoverContent
                sections={[
                  {
                    content: t('inactivity.expire_popover_description_1'),
                  },
                  {
                    content: t('inactivity.expire_popover_description_2'),
                  },
                ]}
              />
            </Switch>
            <Switch condition={expiration_type === 'fixed_period'}>
              <PopoverContent
                sections={[
                  {
                    content: t('fixed_period.expire_popover_description_1', {
                      months: points_expires_in_months,
                    }),
                  },
                  {
                    content: t('fixed_period.expire_popover_description_2'),
                    contentClassName: `mt-s text-uppercase ${css({
                      letterSpacing: '0.125rem',
                      fontSize: 'inherit',
                    })}`,
                  },
                  {
                    content: (
                      <span
                        // This is fixed content with no user input
                        dangerouslySetInnerHTML={{
                          __html: t(
                            'fixed_period.expire_popover_description_3'
                          ),
                        }}
                      />
                    ),
                    contentClassName: 'mb-s',
                  },
                  {
                    content: t('fixed_period.expire_popover_description_4'),
                  },
                  {
                    content: t('fixed_period.expire_popover_description_5'),
                  },
                ]}
              />
            </Switch>
          </span>
        </Text.BodyText3>
      </div>

      <Help title={ht('title')} articles={helpArticles.expiration} />
    </section>
  )
}

export default Expiration
