import debounce from 'lodash/debounce'
import React, { useMemo, useState } from 'react'

import { Switch } from '@thanx/uikit/switch'
import Pagination from 'components/Pagination'
import SearchInput from 'components/SearchInput'
import Spinner from 'components/Spinner'

type PropsT = {
  children: React.ReactNode
  contentIsLoading: boolean
  onChangePage: (page: number) => unknown
  // onChangeSearchInput must be a memoized function in order to to prevent the debounce
  // function from being re-created on every render.
  onChangeSearchInput: (value: string) => void
  currentPage: number
  numberOfPages: number
  searchPlaceholder?: string
}

const SearchablePaginatedContent = ({
  children,
  contentIsLoading,
  onChangePage,
  onChangeSearchInput,
  currentPage,
  numberOfPages,
  searchPlaceholder = '',
}: PropsT) => {
  const [searchValue, setSearchValue] = useState('')

  const notifySearchChange = useMemo(
    () => debounce(onChangeSearchInput, 500),
    [onChangeSearchInput]
  )

  const handleSearchInputChange = value => {
    setSearchValue(value) // Update controlled input state
    notifySearchChange(value) // Notify parent component, after debounce timeout
  }

  return (
    <>
      <SearchInput
        className={'w-100 mb-xl'}
        onChange={handleSearchInputChange}
        value={searchValue}
        placeholder={searchPlaceholder}
      />
      <Spinner
        className={'d-flex justify-content-center'}
        size={'3x'}
        isLoading={contentIsLoading}
      >
        {children}
      </Spinner>
      <Switch condition={!contentIsLoading}>
        <div className={'d-flex justify-content-center margin-top-huge'}>
          <Pagination
            currentPage={currentPage}
            onChangePage={onChangePage}
            numPages={numberOfPages}
          />
        </div>
      </Switch>
    </>
  )
}

export default SearchablePaginatedContent
