import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Row } from 'react-bootstrap-five'
import { LinkContainer } from 'react-router-bootstrap'

const t = buildTranslate('points.marketplace')

const Empty: React.FC = () => (
  <Row className="pt-xl pb-l">
    <Col className="d-flex flex-column align-items-center w-100">
      <Text.Header4>{t('empty.title')}</Text.Header4>
      <Text.BodyText3 className="mb-m" tag="div" color="grey70">
        {t('empty.subtitle')}
      </Text.BodyText3>
      <LinkContainer className="mb-m" to="/points/create">
        <Button className="px-l">{t('add')}</Button>
      </LinkContainer>
    </Col>
  </Row>
)

export default Empty
