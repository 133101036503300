import { I18n } from 'react-redux-i18n'

export default (dimension: string, value: number) => {
  let I18nPath = {
    width: 'validations.images.fixed_width',
    height: 'validations.images.fixed_height',
    minWidth: 'validations.images.minimum_width',
    minHeight: 'validations.images.minimum_height',
    maxWidth: 'validations.images.maximum_width',
    maxHeight: 'validations.images.maximum_height',
  }[dimension]

  return I18n.t(I18nPath, { value: value })
}
