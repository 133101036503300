import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'

import { termsOfServiceApi } from 'api/termsOfService'
import { buildTranslate } from 'locales'
import LegalContainer from 'public/common/LegalContainer'
import Helmet from 'react-helmet'
import ThanxTerms from './ThanxTerms'

const t = buildTranslate('terms.brandedTerms')
const titles = buildTranslate('titles')

type Props = {
  handle: string
}

function BrandedTerms(props: Props) {
  const { handle } = props

  const { data, isLoading } =
    termsOfServiceApi.useGetTermsOfServiceQuery(handle)

  const { merchant_name, points_experience } = data ?? {}
  const {
    currency_name_plural,
    conversion_rate,
    points_expires_in_months,
    expiration_type,
  } = points_experience ?? {}

  const noPointsFallback = (
    <Text.BodyText3>{t('points.fallback', { merchant_name })}</Text.BodyText3>
  )

  return (
    <LegalContainer
      isLoading={isLoading}
      copyrigthSuffix={t('copyright_suffix', { merchant_name })}
    >
      <Switch condition={!!merchant_name} fallback={<ThanxTerms />}>
        <Helmet>
          <title>{titles('terms_branded', { name: merchant_name })}</title>
        </Helmet>
        <Text.Header2>{t('banner', { merchant_name })}</Text.Header2>
        <Text.Header2>{t('title')}</Text.Header2>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('terms', {
                merchant_name,
                handle,
              }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('accessing')}</Text.BodyText3>
        <Text.Header3>{t('eligibility.title')}</Text.Header3>
        <Text.BodyText3>{t('eligibility.in_order')}</Text.BodyText3>
        <ul>
          <li>
            <Text.BodyText3>{t('eligibility.person')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('eligibility.register')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('eligibility.possess')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('eligibility.not_be')}</Text.BodyText3>
          </li>
        </ul>
        <Text.BodyText3>{t('eligibility.applicable')}</Text.BodyText3>
        <Text.Header3>{t('creating.title')}</Text.Header3>
        <Text.BodyText3>{t('creating.in_order')}</Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('creating.you_may')}</Text.BodyText3>
        <Text.Header3>{t('participating.title')}</Text.Header3>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('participating.generally'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('participating.qualifying')}</Text.BodyText3>
        <Text.Header3>{t('notes.title')}</Text.Header3>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('notes.enrolling', {
                merchant_name,
              }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('notes.not_all')}</Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('notes.pin')}</Text.BodyText3>
        <Text.Header3>{t('opportunity.title')}</Text.Header3>
        <Text.BodyText3>{t('opportunity.terms')}</Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('opportunity.viewable')}</Text.BodyText3>
        <Text.Header3>{t('general_terms.title')}</Text.Header3>
        <ul>
          <li>
            <Text.BodyText3>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('general_terms.in_general', {
                    merchant_name,
                  }),
                }}
              />
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('general_terms.reward_progress'),
                }}
              />
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('general_terms.not_equivalent'),
                }}
              />
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('general_terms.not_transferrable'),
                }}
              />
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('general_terms.cannot_redeem', {
                    merchant_name,
                  }),
                }}
              />
            </Text.BodyText3>
          </li>
        </ul>
        <Text.BodyText3>{t('general_terms.in_')}</Text.BodyText3>
        <Switch condition={!!points_experience} fallback={noPointsFallback}>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('points.header'),
              }}
            />
          </Text.BodyText3>
          <br></br>
          <Text.BodyText3>{t('points.accumulate')}</Text.BodyText3>
          <br></br>
          <br></br>
          <Text.BodyText3>
            {t('points.will_earn', {
              conversion: conversion_rate,
              currency_name: currency_name_plural,
            })}
          </Text.BodyText3>
          <br></br>
          <br></br>
          <Text.BodyText3>
            {expiration_type === 'inactivity'
              ? t('points.inactivity_expiration', {
                  currency_name: currency_name_plural,
                  merchant_name,
                  expires_in: points_expires_in_months,
                })
              : t('points.fixed_period_expiration', {
                  currency_name: currency_name_plural,
                  expires_in: points_expires_in_months,
                })}
          </Text.BodyText3>
        </Switch>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('expiration.expiration'),
            }}
          />
        </Text.BodyText3>
        <ul>
          <li>
            <Text.BodyText3>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('expiration.verifying'),
                }}
              />
            </Text.BodyText3>
          </li>
        </ul>
        <Text.BodyText3>{t('expiration.acknowledge')}</Text.BodyText3>
        <ul>
          <li>
            <Text.BodyText3>{t('expiration.accumulation')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('expiration.reserve')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('expiration.personal_use')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('expiration.services')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              {t('expiration.neither', { merchant_name })}
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('expiration.progress')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('expiration.redemption')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              {t('expiration.change', { merchant_name })}
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('expiration.solely')}</Text.BodyText3>
          </li>
        </ul>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('expiration.remedy'),
            }}
          />
        </Text.BodyText3>
        <Text.Header3>{t('liability.title')}</Text.Header3>
        <Text.BodyText3>
          {t('liability.acknowledge', {
            merchant_name,
          })}
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          {t('liability.release', { merchant_name })}
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('liability.general_release'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('liability.short'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('mobile.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('mobile.subject'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('rights.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('rights.content')}</Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('alerts.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('alerts.content'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('unauthorized.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('unauthorized.lawful')}</Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('unauthorized.permitted')}</Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('unauthorized.additionally')}</Text.BodyText3>
        <ul>
          <li>
            <Text.BodyText3>{t('unauthorized.accessing')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('unauthorized.tampering')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('unauthorized.soliciting')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('unauthorized.reselling')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('unauthorized.attempting')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('unauthorized.overwhelming')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('unauthorized.hyperlinks')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('unauthorized.registering')}</Text.BodyText3>
          </li>
        </ul>
        <Text.BodyText3>{t('unauthorized.suspend')}</Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('termination.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('termination.cancel'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>{t('termination.continue')}</Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('termination.inactive'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('ownership.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('ownership.copyright', {
                merchant_name,
              }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('links.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('links.contains'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('special.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('special.may', { merchant_name }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('special.purchase'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('special.intend'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('special.reserves'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('offer_disclaimer.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('offer_disclaimer.platform'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('offer_disclaimer.liability'),
            }}
          />
        </Text.BodyText3>
        <ul>
          <li>
            <Text.BodyText3>{t('offer_disclaimer.any_goods')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('offer_disclaimer.redemption')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('offer_disclaimer.action')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('offer_disclaimer.failure')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('offer_disclaimer.honor')}</Text.BodyText3>
          </li>
        </ul>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('offer_disclaimer.exclusively', {
                merchant_name,
              }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('statement.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('statement.may_be'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('statement.credits', {
                merchant_name,
              }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('statement.no_event'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3 bold>
          <span
            dangerouslySetInnerHTML={{
              __html: t('indemnification.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('indemnification.indemnify', {
                merchant_name,
              }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('disclaimer.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('disclaimer.as_is', { merchant_name }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('limitation.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('limitation.maximum'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('limitation.maximum_two', {
                merchant_name,
              }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('exclusions.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('exclusions.some'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3 bold>
          <span
            dangerouslySetInnerHTML={{
              __html: t('force.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('force.neither', { merchant_name }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('itunes.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('itunes.acknowledge'),
            }}
          />
        </Text.BodyText3>
        <ol type="a">
          <li>
            <Text.BodyText3>
              {t('itunes.concluded', { merchant_name })}
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              {t('itunes.obligation', { merchant_name })}
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              {t('itunes.failure', { merchant_name })}
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              {t('itunes.addressing', { merchant_name })}
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              {t('itunes.infringes', { merchant_name })}
            </Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>
              {t('itunes.beneficiaries', { merchant_name })}
            </Text.BodyText3>
          </li>
        </ol>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('general.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('general.agency', {
                merchant_name,
              }),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('california'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('dmca.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('dmca.connection'),
            }}
          />
        </Text.BodyText3>
        <ul>
          <li>
            <Text.BodyText3>{t('dmca.physical')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('dmca.infringed')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('dmca.material')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('dmca.permit')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('dmca.telephone')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('dmca.statement')}</Text.BodyText3>
          </li>
          <li>
            <Text.BodyText3>{t('dmca.accurate')}</Text.BodyText3>
          </li>
        </ul>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('dmca.faith'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('questions.title'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('questions.terms'),
            }}
          />
        </Text.BodyText3>
        <br></br>
        <br></br>
        <Text.BodyText3>
          <span
            dangerouslySetInnerHTML={{
              __html: t('updated'),
            }}
          />
        </Text.BodyText3>
        <br />
      </Switch>
    </LegalContainer>
  )
}

export default BrandedTerms
