import { Switch } from '@thanx/uikit/switch'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import { parseDigit, templateFormatter, templateParser } from 'input-format'
import ReactInput from 'input-format/react'
import React from 'react'
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import { textUtils } from 'utilities/textUtils'

const US_PHONE_NUMBER = '(xxx) xxx-xxxx'

export type Props = {
  className?: string
  disabled?: boolean
  label?: string
  labelClassName?: string
  onChange?: (value: string) => void
} & PassDownProps<any>

const PhoneNumberInput: React.FC<Props> = props => {
  const className =
    props.showError && !props.isPristine ? 'form-group has-error' : 'form-group'
  const errorMessages = props.isPristine ? '' : props.errorMessages

  const valueWithoutCountryCode = props.value?.replace('+1', '')

  function onChange(value: string) {
    props.setValue(value)
    props.onChange?.(value)
  }

  return (
    <FormGroup
      className={className}
      bsSize="lg"
      controlId={`${props.name}PhoneNumberInput`}
    >
      <Switch condition={!!props.label}>
        <ControlLabel className={props.labelClassName}>
          {props.label}
        </ControlLabel>
      </Switch>
      <FormControl
        className="grey-70"
        value={valueWithoutCountryCode}
        onChange={onChange}
        componentClass={ReactInput}
        format={templateFormatter(US_PHONE_NUMBER)}
        parse={templateParser(US_PHONE_NUMBER, parseDigit)}
      />
      {props.showError && (
        <span className="text-danger error-message block">
          {textUtils.initialCapital(
            textUtils.toSentence({
              array: errorMessages as string[],
              punctuation: '',
            })
          )}
        </span>
      )}
    </FormGroup>
  )
}

export default withFormsy(PhoneNumberInput)
