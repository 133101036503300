import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import Header from '../../components/Header'
import Screens from './Screens'

const t = buildTranslate('cms.brand.app_store_assets')

const PlayStoreScreens: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const { android_screenshots_image_urls } = app
  return (
    <Section className="p-xl mt-l">
      <Header
        title={t('play_store_screens.title')}
        description={t('play_store_screens.description')}
        link="https://duisjpf052nxj.cloudfront.net/assets/mx/Launch+Screen+template.fig"
        linkText={t('play_store_screens.link_text')}
      />
      <Screens
        size={t('play_store_screens.size')}
        height={'267px'}
        screens={android_screenshots_image_urls}
        className="mt-m"
      />
    </Section>
  )
}

export default PlayStoreScreens
