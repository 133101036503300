export async function getFileWithExactDimensions(
  img: HTMLImageElement,
  width: number,
  height: number,
  name: string,
  type: string
): Promise<File> {
  // create an off-screen canvas
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  // set its dimension to target size
  canvas.width = width
  canvas.height = height

  // draw source image into the off-screen canvas:
  // @ts-ignore
  ctx.drawImage(img, 0, 0, width, height)

  // encode image to data-uri with base64 version of compressed image
  const res: Response = await fetch(canvas.toDataURL())
  const blob: Blob = await res.blob()

  return new File([blob], name, { type })
}

export function checkIfImageIsSmall(
  img: HTMLImageElement,
  minWidth: number | null,
  minHeight: number | null
): boolean {
  if (
    (!!minWidth && img.width < minWidth) ||
    (!!minHeight && img.height < minHeight)
  ) {
    return true
  }
  return false
}
