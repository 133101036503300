import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'

const TrajectoryTip: React.FC = () => {
  const popover = (
    <Tooltip id="trajectory-tooltip">
      <Translate value="reports.activity.trajectory_text" />
    </Tooltip>
  )

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={popover}
    >
      <i className="fa fa-question-circle grey-50 font-size-14" />
    </OverlayTrigger>
  )
}

export default TrajectoryTip
