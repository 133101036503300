import { Radio, RadioGroup } from '@thanx/uikit/radio'
import { Select } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import { getGrantableCampaigns } from 'actions/grantableCampaigns'
import {
  getUserProfile,
  GrantParamsT,
  grantReward,
  GRANT_USER_REWARD_FAIL,
} from 'actions/users'
import { usersApi } from 'api/users'
import { isError } from 'api/utils'
import Input from 'components/Form/Input'
import LearnMoreLink from 'components/LearnMoreLink'
import Modal from 'components/ModalContainer'
import Form from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectGrantableCampaigns } from 'selectors/grantableCampaign'
import { selectExperience } from 'selectors/pointsExperience'
import { grantRewardUrl } from 'utilities/urlUtils'
import { getFullName } from './helpers'

const t = buildTranslate('users.view.grant_modal')
const validationT = buildTranslate('validations')

type PropsT = {
  profile: UserProfile
  visible: boolean
  onClose: VoidFunction
}

const GrantModal: React.FC<PropsT> = ({
  visible,
  profile,
  onClose,
}: PropsT) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()

  const [issuanceReason, setIssuanceReason] = useState('')
  const [grantProgressType, setGrantProgressType] = useState<
    'dollar' | 'percentage'
  >('percentage')
  const [grantValue, setGrantValue] = useState(0)
  const campaigns = useSelector(state => selectGrantableCampaigns(state))
  const [selectedCampaignId, setSelectedCampaignId] = useState<number | null>(
    null
  )
  const [formIsValid, setFormIsValid] = useState(false)
  const [grantType, setGrantType] = useState<'reward' | 'points'>('reward')
  const pointsExperience = useSelector(selectExperience)
  const [numPointsToGrant, setNumPointsToGrant] = useState(0)
  const [grantPoints, { isLoading: isGrantingPoints }] =
    usersApi.useGrantPointsMutation()

  useEffect(() => {
    if (campaigns.length === 0) {
      dispatch(getGrantableCampaigns())
    }
  }, [dispatch, campaigns])

  const name = getFullName(profile)
  const selectedCampaign = campaigns.find(
    campaign => campaign.id === selectedCampaignId
  )
  const isGrantProgress = selectedCampaign?.type === 'loyalty'

  const options = campaigns.map(campaign => ({
    value: campaign.id,
    label:
      campaign.type === 'loyalty'
        ? t('progress_toward_loyalty')
        : campaign.name,
  }))
  const progressOptions = [
    { value: 'percentage', label: '%' },
    { value: 'dollar', label: '$' },
  ]

  function onProceed() {
    grantType === 'reward' ? handleGrantReward() : handleGrantPoints()
  }

  async function handleGrantPoints() {
    const result = await grantPoints({
      user_uid: profile.uid,
      amount: numPointsToGrant,
    })
    if (isError(result)) {
      dispatch(
        alert({
          key: 'danger',
          message: t('alert_fail'),
          timeout: 3,
        })
      )
      return
    }

    dispatch(getUserProfile(profile.uid))

    dispatch(
      alert({
        key: 'success',
        message: t('alert_success_points'),
        timeout: 3,
      })
    )
    onClose()
  }

  async function handleGrantReward() {
    if (!selectedCampaign) return

    const grantParams = ((): GrantParamsT => {
      if (selectedCampaign.type === 'loyalty') {
        return {
          grant_type: 'progress',
          grant_progress: {
            grant_progress_type: grantProgressType!,
            grant_value: grantValue,
          },
        }
      }

      return {
        grant_type: 'reward',
        grant_reward: {
          program_id: selectedCampaign.id,
          issuance_reason: issuanceReason,
        },
      }
    })()

    const result = await dispatch(grantReward(profile.uid, grantParams))
    if (result.type === GRANT_USER_REWARD_FAIL) {
      dispatch(
        alert({
          key: 'danger',
          message: t('alert_fail'),
          timeout: 3,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('alert_success'),
        timeout: 3,
      })
    )
    onClose()
  }

  const progressValueValidations: any = {
    isRequired: true,
    greaterThan: 0,
  }

  if (grantProgressType === 'percentage') {
    progressValueValidations.lessThanOrEqual = 100
  }

  const canProceed = (() => {
    if (!formIsValid) return false

    if (grantType === 'points') return true

    return selectedCampaignId !== null
  })()

  return (
    <Modal
      title={t('title', { name })}
      proceedText={t(grantType === 'reward' ? 'grant_reward' : 'grant_points')}
      buttonGroupChildren={
        <LearnMoreLink
          url={grantRewardUrl}
          className={css({ marginRight: 'auto' })}
        />
      }
      isOpen={visible}
      onClose={onClose}
      onProceed={onProceed}
      canProceed={canProceed}
      isLoading={isGrantingPoints}
    >
      {!!pointsExperience && (
        <RadioGroup value={grantType} onChange={value => setGrantType(value)}>
          <Radio value="reward" className={'pr-m'}>
            {t('grant_a_reward')}
          </Radio>
          <Radio value="points">{t('grant_points')}</Radio>
        </RadioGroup>
      )}

      <div className="mt-m">
        <Form
          className="mb-xs mt-m"
          onValid={() => setFormIsValid(true)}
          onInvalid={() => setFormIsValid(false)}
        >
          <Switch
            condition={grantType === 'reward'}
            fallback={
              <Input
                name="reason"
                label={t('number_points_to_grant')}
                value={numPointsToGrant}
                type="number"
                onChange={event =>
                  setNumPointsToGrant(Number(event.currentTarget.value))
                }
                addOnAfter={
                  <Text.BodyText3 className="d-flex align-center">
                    {t('points').toLowerCase()}
                  </Text.BodyText3>
                }
                validations={{
                  isRequired: true,
                  greaterThan: 0,
                  isInt: true,
                }}
                validationErrors={{
                  isRequired: validationT('is_required'),
                  greaterThan: validationT('greater_than', { value: 0 }),
                  lessThanOrEqual: validationT('less_than_or_equal', {
                    value: 100,
                  }),
                  isInt: validationT('is_int_point_reward'),
                }}
              />
            }
          >
            <Text.BodyText4 className="mb-xs" tag={'p'} bold>
              {t('subtitle')}
            </Text.BodyText4>

            <Select
              onChange={selected => setSelectedCampaignId(selected[0].value)}
              options={options}
              value={options.find(
                option => option.value === selectedCampaignId
              )}
              zIndex={1002}
              maxDropdownHeight="200px"
            />
          </Switch>

          {grantType === 'reward' && (
            <Switch
              condition={isGrantProgress}
              fallback={
                <>
                  <Input
                    name="reason"
                    componentClass={'textarea'}
                    label={t('add_message')}
                    inputProps={{ rows: 5 }}
                    value={issuanceReason}
                    onChange={event =>
                      setIssuanceReason(event.currentTarget.value)
                    }
                    className={'mt-m mb-xs'}
                  />
                  <Text.BodyText5 color="grey70">
                    {t('delivery_note')}
                  </Text.BodyText5>
                </>
              }
            >
              <Text.BodyText4 className="mt-m mb-xs" tag={'p'} bold>
                {t('how_much_progress')}
              </Text.BodyText4>
              <div className="d-flex">
                <div
                  className={`mr-l ${css({
                    width: '95px',
                  })}`}
                >
                  <Select
                    onChange={selected =>
                      setGrantProgressType(selected[0].value)
                    }
                    value={progressOptions.find(
                      option => option.value === grantProgressType
                    )}
                    options={progressOptions}
                    zIndex={1002}
                    maxDropdownHeight={'200px'}
                  />
                </div>
                <div className="w-100">
                  <Input
                    name="grantValue"
                    className={'w-100'}
                    value={grantValue}
                    onChange={event =>
                      setGrantValue(Number(event.currentTarget.value))
                    }
                    type="number"
                    validations={progressValueValidations}
                    validationErrors={{
                      isRequired: validationT('is_required'),
                      greaterThan: validationT('greater_than', { value: 0 }),
                      lessThanOrEqual: validationT('less_than_or_equal', {
                        value: 100,
                      }),
                    }}
                  />
                </div>
              </div>
            </Switch>
          )}
        </Form>
      </div>
    </Modal>
  )
}

export default GrantModal
