import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import IncentiveContainer from 'scenes/CampaignCenter/Builder/components/Forms/Incentive'
import IncentivePreview from 'scenes/CampaignCenter/Builder/components/Previews/Incentive'

type Props = {
  campaign: Campaign
  canProceed: boolean
  showValidationErrors?: () => void
  isSaving: boolean
  triggerSave: () => Promise<void>
}

const Incentive: React.FC<Props> = props => {
  const { campaign, canProceed, showValidationErrors, isSaving, triggerSave } =
    props

  return (
    <Row>
      <Col md={6} className="padding-top-huge">
        <div className="padding-right-large">
          <h4 className="deprecated__h4 grey-90 margin-none">
            {I18n.t('thanx_campaigns.builder.steps.incentive.title')}
          </h4>
          <div className="font-size-20 grey-50">
            <em>
              {I18n.t('thanx_campaigns.builder.steps.incentive.subtitle')}
            </em>
          </div>
          <IncentiveContainer
            campaign={campaign}
            canProceed={canProceed}
            showValidationErrors={showValidationErrors}
            isSaving={isSaving}
            triggerSave={triggerSave}
          />
        </div>
      </Col>
      <Col
        md={6}
        className="grey-10-bkg padding-top-huge"
        style={{ height: '900px' }}
      >
        <IncentivePreview campaign={campaign} />
      </Col>
    </Row>
  )
}

export default Incentive
