import { useStyletron } from '@thanx/uikit/theme'
import tableImage from 'assets/images/reports/table_stub.svg'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('reports')

const TableStub: React.FC = () => {
  const [css] = useStyletron()

  return (
    <div className={`mt-m`}>
      <img
        className={`${css({ width: '100%' })}`}
        src={tableImage}
        alt={t('coming_soon')}
      />
    </div>
  )
}

export default TableStub
