import Badge from 'components/Badge'
import React from 'react'
import BadgeDescriptions from './BadgeDescriptions'

type Props = {
  values?: string[]
  className?: string
  badgeDescriptions?: string[]
}

const Badges = ({ values, className = '', badgeDescriptions }: Props) => {
  if (!values?.length) return null

  return (
    <div className={`flex-row ${className}`}>
      {values.map(item => (
        <Badge
          className="grey-70 mr-xs grey-20-bkg d-inline"
          key={item}
          value={item}
        />
      ))}
      <BadgeDescriptions values={badgeDescriptions} />
    </div>
  )
}

export default Badges
