import { Text } from '@thanx/uikit/text'
import Book from 'components/Icons/Book'
import InfoBox from 'components/InfoBox'
import { buildTranslate } from 'locales'
import React from 'react'
import { dccHelpUrl } from 'utilities/urlUtils'

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes.dynamic'
)

const ThanxGeneratedDisabled: React.FC = () => {
  return (
    <div className="w-100 mt-xs">
      <Text.BodyText4 className="mb-s" color="grey70" tag="div">
        {t('disabled')}
      </Text.BodyText4>
      <InfoBox
        content={
          <>
            <Text.BodyText4 color="grey70" tag="div">
              {t('requires_integration')}
            </Text.BodyText4>
            <a href={dccHelpUrl} target="_blank" rel="noopener noreferrer">
              {t('learn_more')}
              <Book className="ml-xs" />
            </a>
          </>
        }
        type="info"
      />
    </div>
  )
}

export default ThanxGeneratedDisabled
