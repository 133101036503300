import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import CustomCampaignsModal from 'scenes/CampaignCenter/Landing/CustomCampaignsModal'

import { Fields as TargetSegment } from 'models/TargetSegment'
import Segments from 'scenes/CampaignCenter/Landing/Segments'

const t = buildTranslate('thanx_campaigns.landing.targeted')

type Props = {
  onCreate: (targetSegment?: TargetSegment) => Promise<void>
}
const CustomCampaigns: React.FC<Props> = ({ onCreate }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [segmentId, setSegmentId] = useState<number | null>(null)

  const onNewClick = () => {
    setSegmentId(null)
    setIsOpen(true)
  }
  const onExistingClick = segmentId => {
    setSegmentId(segmentId)
    setIsOpen(true)
  }

  return (
    <>
      <Segments onNewClick={onNewClick} onExistingClick={onExistingClick} />

      <CustomCampaignsModal
        title={t('custom.modal.title')}
        show={isOpen}
        onModalClose={() => {
          setSegmentId(null)
          setIsOpen(false)
        }}
        onCreate={onCreate}
        defaultSegmentId={segmentId}
      />
    </>
  )
}

export default CustomCampaigns
