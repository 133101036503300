import Input from 'components/Form/Input'
import Spacer from 'components/Spacer'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import type { Fields as Merchant } from 'models/Merchant'
import type { Fields as Redeem } from 'models/Redeem'
import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import AdditionalOptions from './AdditionalOptions'
import Discount from './Discount'
import Item from './Item'

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.reward_creator.redeem_type'
)

type Props = {
  showRewardCreator: boolean
  redeem: Redeem | Campaign
  merchant: Merchant | null
}

const RewardCreator: React.FC<Props> = props => {
  const { showRewardCreator, redeem, merchant } = props
  const redeem_type = redeem?.redeem_type || 'manual/item'
  const [campaignRedeemType, setCampaignRedeemType] = useState(redeem_type)
  const [selectedRedeemType, setSelectedRedeemType] = useState(redeem_type)

  useEffect(() => {
    setCampaignRedeemType(redeem_type)
    setSelectedRedeemType(redeem_type)
  }, [redeem_type])

  useEffect(() => {
    setSelectedRedeemType(campaignRedeemType)
  }, [campaignRedeemType])

  function redeemTypeChanged(value: string) {
    setCampaignRedeemType(value)
  }

  function itemChecked() {
    if (!selectedRedeemType) {
      return true
    } else if (selectedRedeemType === 'manual/item') {
      return true
    } else {
      return false
    }
  }

  function discountType(suffix: string): string {
    const prefix = merchant?.automatic_capable ? 'automatic/' : 'manual/'
    return `${prefix}${suffix}`
  }

  function discountChecked() {
    if (!selectedRedeemType) return false
    if (
      selectedRedeemType.includes('amount') ||
      selectedRedeemType.includes('percent')
    ) {
      return true
    }
    return false
  }

  function showAutomaticOption() {
    if (!selectedRedeemType || !merchant?.automatic_capable) return false
    return !selectedRedeemType.includes('item')
  }

  function setManual(value: boolean) {
    if (value) {
      redeemTypeChanged(campaignRedeemType.replace('automatic', 'manual'))
    } else {
      redeemTypeChanged(campaignRedeemType.replace('manual', 'automatic'))
    }
  }

  return (
    <div className={'padding-top-large ' + (showRewardCreator ? '' : 'hidden')}>
      <div className="body-text-2 margin-bottom-small">{t('title')}</div>
      <Spacer paddingBottom="40px">
        <Input name="redeem_type" value={campaignRedeemType} type="hidden" />
        <ButtonGroup justified className="z0up">
          <Button
            id="manual/item"
            onClick={() => {
              redeemTypeChanged('manual/item')
            }}
            style={{ width: '50%' }}
            bsSize="lg"
            className={itemChecked() ? 'active' : ''}
          >
            {t('item')}
          </Button>
          <Button
            id="manual/amount"
            onClick={() => {
              redeemTypeChanged(discountType('amount'))
            }}
            style={{ width: '50%' }}
            bsSize="lg"
            className={discountChecked() ? 'active' : ''}
          >
            {t('discount')}
          </Button>
        </ButtonGroup>
      </Spacer>
      {selectedRedeemType === 'manual/item' ? (
        <Item redeem={redeem as Redeem} />
      ) : (
        <Discount
          redeem={redeem as Redeem}
          onRedeemTypeChange={value => {
            redeemTypeChanged(value)
          }}
          selectedRedeemType={selectedRedeemType}
        />
      )}
      <AdditionalOptions
        redeem={redeem}
        showAutomaticOption={showAutomaticOption()}
        automaticChecked={campaignRedeemType.includes('automatic')}
        setManual={setManual}
      />
    </div>
  )
}

export default RewardCreator
