import { Switch } from '@thanx/uikit/switch'
import Badges from 'components/Badges'
import { buildTranslate } from 'locales'
import { Image } from 'models/App'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import ImageAsset from '../ImageAsset'

const t = buildTranslate('cms.brand.app_store_assets')

type Props = {
  screens: Image[] | null
  height: string
  title?: string
  size: string
  className?: string
}

const Screens: React.FC<Props> = props => {
  const { screens, height, title, size, className = '' } = props
  return (
    <div className={className}>
      <Switch condition={!!title}>
        <p className="font-size-4 grey-90 bold">{title}</p>
      </Switch>
      <Badges className="mt-s" values={[size, t('jpg')]} />
      <Row className="m-0">
        <Switch
          condition={!!screens && screens?.length > 0}
          fallback={[...Array(4)].map((_, index) => {
            return (
              <Col key={`emptyScreen${index}`} className="mr-m mt-m">
                <ImageAsset height={height} />
              </Col>
            )
          })}
        >
          {screens?.map((screen, index) => {
            return (
              <Col key={`screen${index}`} className="mr-m mt-m">
                <ImageAsset height={height} image={screen?.small} />
              </Col>
            )
          })}
        </Switch>
      </Row>
    </div>
  )
}

export default Screens
