import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}

const Envelope: React.FC<PropsT> = ({
  className = '',
  fillColor = 'currentColor',
}) => (
  <svg
    className={className}
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 2C0 0.90625 0.875 0 2 0H14C15.0938 0 16 0.90625 16 2V10C16 11.125 15.0938 12 14 12H2C0.875 12 0 11.125 0 10V2ZM1.5 2V2.71875L6.875 7.125C7.53125 7.65625 8.4375 7.65625 9.09375 7.125L14.5 2.71875V2C14.5 1.75 14.25 1.5 14 1.5H2C1.71875 1.5 1.5 1.75 1.5 2ZM1.5 4.65625V10C1.5 10.2812 1.71875 10.5 2 10.5H14C14.25 10.5 14.5 10.2812 14.5 10V4.65625L10.0625 8.28125C8.84375 9.28125 7.125 9.28125 5.90625 8.28125L1.5 4.65625Z"
      fill={fillColor}
    />
  </svg>
)

export default Envelope
