import { Button, Kind } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import { Notification } from '@thanx/uikit/notification'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('components.confirm_modal')

type Props = {
  title: string
  description: string
  confirmText: string
  bannerText?: string
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onConfirm: () => void
  isJustified?: boolean
}

const ConfirmModal: React.FC<Props> = props => {
  const {
    title,
    description,
    confirmText,
    isOpen,
    isLoading,
    onClose,
    onConfirm,
    bannerText = '',
    isJustified = false,
  } = props
  const [css] = useStyletron()
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="auto"
      closeType="small"
      zIndex={1200}
    >
      <div
        className={`${
          isJustified ? 'text-left' : 'text-center'
        } p-xl d-flex flex-column container
          ${css({
            maxWidth: '600px',
          })}
        `}
      >
        <h3 className="m-0">{title}</h3>
        <p
          className="body-text-3 grey-70 mt-xs"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
        <Switch condition={!!bannerText}>
          <Notification kind="info" size="full">
            <p
              className="body-text-4 mr-m"
              dangerouslySetInnerHTML={{
                __html: bannerText,
              }}
            />
          </Notification>
        </Switch>
        <div
          className={`d-flex mt-m ${isJustified ? 'justify-content-end' : ''}`}
        >
          <Button
            kind={Kind.SECONDARY}
            onClick={onClose}
            className={`${isJustified ? '' : 'flex-1'} mr-m`}
          >
            {t('cancel')}
          </Button>
          <Button
            className={`${isJustified ? '' : 'flex-1'}`}
            isLoading={isLoading}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmModal
