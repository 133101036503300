import { Fields as CampaignType } from 'models/CampaignType'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_CAMPAIGN_TYPES = 'emails/GET_CAMPAIGN_TYPES'
export const GET_CAMPAIGN_TYPES_SUCCESS = 'emails/GET_CAMPAIGN_TYPES_SUCCESS'
export const GET_CAMPAIGN_TYPES_FAIL = 'emails/GET_CAMPAIGN_TYPES_FAIL'

export const GET_CAMPAIGN_TYPE = 'emails/GET_CAMPAIGN_TYPE'
export const GET_CAMPAIGN_TYPE_SUCCESS = 'emails/GET_CAMPAIGN_TYPE_SUCCESS'
export const GET_CAMPAIGN_TYPE_FAIL = 'emails/GET_CAMPAIGN_TYPE_FAIL'

export function getCampaignTypes(params?: {
  category?: string
}): AxiosAction<typeof GET_CAMPAIGN_TYPES, { campaign_types: CampaignType[] }> {
  return {
    type: GET_CAMPAIGN_TYPES,
    payload: {
      client: 'default',
      request: {
        url: 'campaign_types',
        method: 'get',
        params,
      },
    },
  }
}

export function getCampaignType(
  type: string
): AxiosAction<typeof GET_CAMPAIGN_TYPE, { campaign_type: CampaignType }> {
  return {
    type: GET_CAMPAIGN_TYPE,
    payload: {
      client: 'default',
      request: {
        url: `campaign_types/${type}`,
        method: 'get',
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getCampaignTypes>>
  | ResolvedAxiosAction<ReturnType<typeof getCampaignType>>
