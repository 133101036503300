import { Text } from '@thanx/uikit/text'
import Input from 'components/Form/Input'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import ContinueButton from 'scenes/CampaignCenter/Builder/components/ContinueButton'
import { useFormManagement } from 'scenes/CampaignCenter/Builder/components/FormManagementContext'
import TopBar from 'scenes/CampaignCenter/Builder/components/TopBar'

const t = buildTranslate('thanx_campaigns.builder.steps.name')

type PropsT = {
  campaign: Campaign
}

const Name: React.FC<PropsT> = props => {
  const { campaign } = props
  const [tempValue, setTempValue] = useState(campaign.name)
  const { triggerSave, setCurrentStep } = useFormManagement()

  const handleEmpty = e => {
    if (e.target.value.length < 1) {
      e.target.value = tempValue
    } else {
      setTempValue(e.target.value)
    }
  }

  return (
    <>
      <TopBar
        triggerSave={triggerSave}
        campaign={campaign}
        stepName="name"
        setCurrentStep={setCurrentStep}
      >
        <ContinueButton onClick={() => setCurrentStep('variantSelect')} />
      </TopBar>
      <div className="container">
        <Text.Header2 className="mt-xl mb-xs">{t('title')}</Text.Header2>
        <Text.BodyText3 tag="p" className="mb-xl">
          {t('content')}
        </Text.BodyText3>
        <Input
          onBlur={handleEmpty}
          name="name"
          value={campaign.name}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
          }}
        />
      </div>
    </>
  )
}

export default Name
