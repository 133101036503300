// import React from 'react'
import { UPDATED } from 'actions/flash'
import { LOCATION_CHANGE } from 'react-router-redux'

export default (
  state = {
    alerts: [],
    errors: [],
  },
  action
) => {
  switch (action.type) {
    case UPDATED:
      return {
        ...state,
        alerts: [...action.alerts],
      }

    case LOCATION_CHANGE:
      return {
        ...state,
        alerts: [],
      }

    default:
      return state
  }
}
