import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import ReviewSection from 'components/review/ReviewSection'
import RichReward from 'components/RewardPreviewCard/RichReward'
import { buildTranslate } from 'locales'
import { Fields as PointsProduct } from 'models/PointsProduct'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import { selectExperience } from 'selectors/pointsExperience'
import CostBadge from './CostBadge'
import DeleteModal from './DeleteModal'
import Popover from './Popover'
import Restrictions from './Restrictions'
import Title from './Title'

type Props = {
  pointsProduct: PointsProduct
  isEditable: boolean
}

const t = buildTranslate('points.item_card')

const PointsProductComponent: React.FC<Props> = props => {
  const { pointsProduct, isEditable } = props
  const [css] = useStyletron()
  const pointsExperience = useSelector(selectExperience)
  const user = useSelector(selectCurrentMerchantUser)
  // TODO: Remove this once add and edit buttons are live as we can then use isEditable
  const isAdmin = user?.is_admin || false
  const dispatch = useDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  return (
    <ReviewSection
      isEditable={isEditable}
      onEdit={() => dispatch(push(`/points/${pointsProduct.id}/edit`))}
      className="position-relative pb-xs p-m"
      popoverValues={
        isAdmin
          ? ({ close }) => (
              <Popover
                close={close}
                isEditable={isAdmin}
                setShowDeleteModal={setShowDeleteModal}
              />
            )
          : null
      }
      paddingSize="medium"
    >
      <CostBadge
        cost={pointsProduct.cost}
        image={pointsExperience?.currency_primary_icon?.default}
      />
      <div className="mt-m">
        <RichReward
          className={`text-left grey-05-bkg mb-m ${css({
            boxShadow: '0px 1px 2px 0px rgba(54, 66, 78, 0.3)',
          })}`}
          description={pointsProduct.redeem_description}
          width="345px"
          imageSrc={pointsProduct?.image?.small}
          showRedeemButton={false}
        />
        <Title className="mb-xs" text={t('restrictions')} />
        <Restrictions
          end={pointsProduct.exchange_end_at}
          start={pointsProduct.exchange_start_at}
          venue={pointsProduct.redemption_venue}
          daysLength={
            pointsProduct.redeem_retire_after_days ??
            pointsProduct.redeem_experience_expires_in_days
          }
          maximum={pointsProduct.redeem_maximum}
        />
        <Title className="mt-m mb-xs" text={t('fine_print')} />
        <Text.BodyText4 tag="div" color="grey70">
          {!!pointsProduct.fine_print
            ? pointsProduct.fine_print
            : t('no_fine_print')}
        </Text.BodyText4>
      </div>
      <DeleteModal
        pointsProduct={pointsProduct}
        setIsOpen={setShowDeleteModal}
        isOpen={showDeleteModal}
      />
    </ReviewSection>
  )
}

export default PointsProductComponent
