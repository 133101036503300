import { useStyletron } from '@thanx/uikit/theme'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as Card } from 'models/Card'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.view.cards')

type PropsT = {
  cards: Array<Card>
}

const Table: React.FC<PropsT> = ({ cards }: PropsT) => {
  const [css] = useStyletron()

  return (
    <table
      className={`full-width ${css({
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
      })}`}
    >
      <thead>
        <tr>
          <HeaderCell className={`${css({ width: '30%' })}`}>
            {t('card_type')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '20%' })} text-right`}>
            {t('last4')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '20%' })} text-right`}>
            {t('date_added')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '20%' })} text-right`}>
            {t('loyalty_status')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '20%' })} text-right`}>
            {t('digital_ordering_status')}
          </HeaderCell>
        </tr>
      </thead>
      <tbody>
        {cards.map(card => (
          <Row key={`user_cards_${card.id}`} card={card} />
        ))}
      </tbody>
    </table>
  )
}

export default Table
