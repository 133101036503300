import { createCampaign } from 'actions/campaigns'
import { alert } from 'actions/flash'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as TargetSegment } from 'models/TargetSegment'
import React, { useState } from 'react'
import { push } from 'react-router-redux'
import CustomCampaignsModal from '../CustomCampaignsModal'
import Segments from '../Segments'

const t = buildTranslate('thanx_campaigns.landing.automated.customAutomated')

/**
 * The custom campaigns section on the automated landing page
 */
const CustomCampaigns: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [segmentId, setSegmentId] = useState<number | null>(null)
  const dispatch = useDispatch()
  async function handleCreate(targetSegment?: TargetSegment) {
    const params = {
      campaign: {
        type: 'custom_automated',
        target_segment_id: targetSegment?.id,
      },
    }
    const action = await dispatch(createCampaign(params))
    if (action.error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('modal.errors.submit'),
        })
      )
      return
    }

    const campaignId = action.payload?.data?.campaign.id
    if (campaignId) {
      dispatch(
        push(`/thanx_campaigns/automated/custom_automated/${campaignId}`)
      )
    } else {
      dispatch(
        alert({
          key: 'danger',
          message: t('modal.errors.submit'),
        })
      )
    }
    return
  }

  const onNewClick = () => {
    setSegmentId(null)
    setIsOpen(true)
  }

  const onExistingClick = segmentId => {
    setSegmentId(segmentId)
    setIsOpen(true)
  }

  return (
    <>
      <Segments onNewClick={onNewClick} onExistingClick={onExistingClick} />

      <CustomCampaignsModal
        title={t('modal.title')}
        subtitle={t('modal.subtitle')}
        show={isOpen}
        onModalClose={() => {
          setSegmentId(null)
          setIsOpen(false)
        }}
        onCreate={handleCreate}
        defaultSegmentId={segmentId}
      />
    </>
  )
}

export default CustomCampaigns
