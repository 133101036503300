import { useStyletron } from '@thanx/uikit/theme'
import carouselPreview from 'assets/images/cms/hero-carousel.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import PublishButton from '../PublishButton'

type Props = {
  isLoading: boolean
  disabled?: boolean
}

const t = buildTranslate('cms.content.hero_carousel')

const Sidebar: React.FC<Props> = ({ isLoading, disabled }) => {
  const [css] = useStyletron()

  return (
    <>
      <PublishButton
        isLoading={isLoading}
        publishText={t('publish')}
        disabled={disabled}
      />
      <img
        className={`mb-xl ${css({
          width: '200px',
          height: '397px',
        })}`}
        src={carouselPreview}
        alt={t('locations_preview')}
      />
    </>
  )
}

export default Sidebar
