type Props = {
  testId?: string
}

const ReplyO = (props: Props) => {
  const { testId } = props

  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testId}
    >
      <path
        d="M7 7.5H10C12.0625 7.5 13.8125 8.90625 14.3125 10.8125C14.4375 10.4375 14.5 10 14.5 9.5C14.5 7.3125 12.6875 5.5 10.5 5.5H7H6.25C5.8125 5.5 5.5 5.1875 5.5 4.75V4V3.125L1.71875 6.5L5.5 9.90625V9V8.25C5.5 7.84375 5.8125 7.5 6.25 7.5H7ZM7 9V10.5V11C7 11.4062 6.75 11.7812 6.40625 11.9375C6.03125 12.0938 5.625 12.0312 5.3125 11.75L0.3125 7.25C0.09375 7.0625 0 6.8125 0 6.5C0 6.21875 0.09375 5.96875 0.3125 5.78125L5.3125 1.28125C5.625 1 6.03125 0.9375 6.40625 1.09375C6.75 1.25 7 1.625 7 2V2.5V4H8.5H10.5C13.5312 4 16 6.46875 16 9.5C16 11.9375 14.7812 13.4688 13.8438 14.2812C13.7188 14.375 13.5938 14.4688 13.4688 14.5625C13.375 14.625 13.2812 14.6875 13.1875 14.75C13.0625 14.8438 12.9375 14.9062 12.8438 14.9688C12.7812 15 12.6875 15 12.5938 15C12.25 15 12 14.75 12 14.4062C12 14.1875 12.0938 14 12.25 13.8438C12.25 13.8125 12.2812 13.8125 12.2812 13.7812C12.375 13.7188 12.4375 13.625 12.5312 13.5312C12.5938 13.4375 12.625 13.375 12.6875 13.2812C12.8438 12.9688 13 12.5625 13 12.0312C13 10.375 11.6562 9.03125 10 9.03125H8.5H7V9Z"
        fill="#6A747F"
      />
    </svg>
  )
}

export default ReplyO
