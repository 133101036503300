import { Button } from '@thanx/uikit/button'
import { buildTranslate } from 'locales'
import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { useStyletron } from 'styletron-react'

import successImage from 'assets/images/redeem-manager/success.svg'
import { useIsMobile } from 'utilities/responsive'

const t = buildTranslate('redeem_manager.builder.success_page')

const Success: React.FC = () => {
  const [css] = useStyletron()
  const isMobile = useIsMobile()

  return (
    <div
      className={`d-flex align-items-center justify-content-center ${
        isMobile ? 'flex-column text-center' : 'mt-xl'
      }`}
    >
      <img
        className={`${css({
          height: '250px',
          width: '250px',
        })} ${isMobile ? '' : 'mr-xxl'}`}
        src={successImage}
        alt=""
      />
      <div>
        <h2>{t('all_done')}</h2>
        <p className="grey-70">{t('template_is_active')}</p>
        <p className="mb-l grey-70">{t('able_to_see')}</p>
        <div className={`d-flex ${isMobile ? 'justify-content-center' : ''}`}>
          <LinkContainer to="/rewards">
            <Button className="mr-m" kind="secondary">
              {t('go_to_rewards')}
            </Button>
          </LinkContainer>
          <LinkContainer to="/thanx_campaigns">
            <Button>{t('go_to_campaigns')}</Button>
          </LinkContainer>
        </div>
      </div>
    </div>
  )
}

export default Success
