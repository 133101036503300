import { alert } from 'actions/flash'
import { getValidation } from 'actions/validations'
import {
  ContentBlock,
  ContentBlockTypes,
  ExternalFormBlock,
  FeaturedContentBlock,
  landingPageThemeApi,
  MenuCategoriesBlock,
  MissingLoyaltyBenefitsCardBlock,
  RecentOrdersBlock,
  RewardsBlock,
  Settings,
  StoredValueBalanceBlock,
} from 'api/landingPageTheme'
import { useGetMenuCategoriesQuery } from 'api/menuCategories'
import { storedValueApi } from 'api/storedValue'
import { isError } from 'api/utils'
import ConfirmModal from 'components/ConfirmModal'
import { ImagePickerValue } from 'components/ImagePicker'
import { TabT } from 'components/Tabs/InnerTabs'
import palette from 'constants/palette'
import Formsy from 'formsy-react'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import sortBy from 'lodash/sortBy'
import uniqueId from 'lodash/uniqueId'
import { ValidationTypes } from 'models/Validation'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { UrlValue } from 'scenes/Cms/components/UrlPicker'
import Content from 'scenes/Cms/Content/AppHomePage/Content'
import StyleSettings from 'scenes/Cms/Content/AppHomePage/Style'
import { getDefaultBannerStyles } from 'scenes/Cms/Content/Banners/helpers'
import { getDefaultMenuCategoryStyles } from 'scenes/Cms/Content/MenuCategory/helpers'
import { getUrlValue } from 'scenes/Cms/helper'
import { selectApp } from 'selectors/app'
import Footer from './Footer'
import {
  getCategorySlug,
  getDefaultBlockData,
  getSubmitPayload,
  hasImage,
} from './helpers'
import Sidebar from './Sidebar'

export type useAppHomepageT = {
  tab: TabT[]
}

export enum Tabs {
  StyleSettings = 'app_homepage_style_settings',
  Content = 'app_homepage_content',
}

const t = buildTranslate('cms.content.app_home_page')

export type ContentBlockData =
  | (Pick<FeaturedContentBlock, 'type' | 'settings'> & {
      image: ImagePickerValue
      url: UrlValue
      urlQueryParam?: string
    })
  | Pick<RecentOrdersBlock, 'type' | 'settings'>
  | Pick<MenuCategoriesBlock, 'type' | 'settings'>
  | Pick<RewardsBlock, 'type' | 'settings'>
  | Pick<StoredValueBalanceBlock, 'type' | 'settings'>
  | Pick<MissingLoyaltyBenefitsCardBlock, 'type' | 'settings'>
  | (Pick<ExternalFormBlock, 'type' | 'settings'> & {
      image: ImagePickerValue
    })

type FormContentBlockData = {
  [id: string]: ContentBlockData
}

export type FormModel = {
  contentBlocks: FormContentBlockData
  settings: Settings
}

const initialFormState: FormModel = {
  contentBlocks: {},
  settings: {
    link_color: '#BE2227',
    background_color: '#FFFFFF',
    header_text_color: '#36424e',
    body_text_color: '#6a747f',
    ...getDefaultBannerStyles(),
    menu_category_page: {
      background_color: '#FFFFFF',
      header_text_color: '#36424E',
      body_text_color: '#6A747F',
      link_color: '#BE2227',
      product_card: {
        background_color: '#FFFFFF',
        header_text_color: '#4D4D4F',
        body_text_color: '#9FA1A4',
        border_color: '#DEDFE0',
        border_width: 1,
        border_radius: 2,
      },
      unavailable_product_card: {
        background_color: '#F3F4F4',
        header_text_color: '#BCBEC0',
        body_text_color: '#808285',
        border_color: '#DEDFE0',
      },
    },
  },
}

type FormAction =
  | { type: 'RESET'; payload: FormModel }
  | { type: 'UPDATE_SETTINGS'; payload: Partial<Settings> }
  | {
      type: 'UPDATE_CONTENT_BLOCK'
      payload: { key: string; data: ContentBlockData }
    }

const formReducer = (state: FormModel, action: FormAction): FormModel => {
  switch (action.type) {
    case 'UPDATE_CONTENT_BLOCK': {
      const { key, data } = action.payload
      return {
        ...state,
        contentBlocks: {
          ...state.contentBlocks,
          [key]: data,
        },
      }
    }
    case 'UPDATE_SETTINGS':
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload,
        },
      }
    case 'RESET':
      return action.payload
    default:
      return state
  }
}

export function useAppHomePage() {
  const app = useSelector(selectApp)
  const appId = app?.id
  const dispatch = useDispatch()
  const [formModel, updateForm] = useReducer(formReducer, initialFormState)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isFormValid, setIsFormValid] = useState(true)
  const defaultSettings = useDefaultLandingPageThemeSettings()

  const [blockOrder, setBlockOrder] = useState<string[]>([])
  const { availableTypes, isLoading: availableTypesIsLoading } =
    useAvailableContentBlockTypes()
  const { data: landingPageThemeData, isFetching } =
    landingPageThemeApi.useGetLandingPageThemeQuery(appId)
  const landingPageTheme = landingPageThemeData?.app_landing_page_theme ?? null
  const [updateLandingPageTheme, { isLoading: isFormSubmitting }] =
    landingPageThemeApi.useUpdateLandingPageThemeMutation()
  const isImageUploading = blockOrder.some(blockId => {
    const contentBlock = formModel.contentBlocks[blockId]
    return 'image' in contentBlock && contentBlock.image.uploading
  })

  const sidebar = (type: 'content' | 'style') => (
    <Sidebar
      isLoading={isFetching}
      disabled={isImageUploading || isFormSubmitting || !isFormValid}
      onPublish={
        type === 'content' ? () => setIsConfirmModalVisible(true) : () => {}
      }
      type={type}
    />
  )
  const footer = (type: 'content' | 'style') => (
    <Footer
      isLoading={isFetching}
      disabled={isImageUploading || isFormSubmitting || !isFormValid}
      onPublish={
        type === 'content' ? () => setIsConfirmModalVisible(true) : () => {}
      }
      type={type}
    />
  )

  function updateContentBlock(blockId: string, data: ContentBlockData) {
    const blockToUpdate = { ...formModel.contentBlocks[blockId] }
    Object.keys(data).forEach(key => {
      if (key === 'type') return
      if (key === 'image') {
        blockToUpdate[key] = data[key]
      } else if (key === 'url') {
        const incomingDataKey = data[key]
        const existingBlockKey = blockToUpdate[key]
        const queryParams = incomingDataKey.queryParams
          ? `category=${incomingDataKey.queryParams}`
          : ''
        const updated = {
          ...existingBlockKey,
          ...incomingDataKey,
          queryParams,
        }
        blockToUpdate[key] = updated
      } else {
        const incomingDataKey = data[key]
        const existingBlockKey = blockToUpdate[key]
        const updated = { ...existingBlockKey, ...incomingDataKey }
        blockToUpdate[key] = updated
      }
    })
    updateForm({
      type: 'UPDATE_CONTENT_BLOCK',
      payload: {
        key: blockId,
        data: blockToUpdate,
      },
    })
  }

  function createContentBlock(key: string, data: ContentBlockData) {
    updateForm({
      type: 'UPDATE_CONTENT_BLOCK',
      payload: {
        key,
        data,
      },
    })
  }

  function updateSettings(settings: Partial<Settings>) {
    updateForm({
      type: 'UPDATE_SETTINGS',
      payload: settings,
    })
  }

  function resetForm({
    contentBlocks,
    settings,
  }: {
    contentBlocks: Array<ContentBlock>
    settings: Settings
  }) {
    const formattedContentBlocks: FormContentBlockData = contentBlocks.reduce(
      (acc, block) => {
        const { id, type, settings } = block

        if (!availableTypes.includes(type)) return acc

        acc[id] = {
          id: block.id,
          type,
          position: block.position,
          settings,
        }

        if (hasImage(type)) {
          const imageUrl =
            'image_urls' in block ? block.image_urls?.small : undefined
          const image: ImagePickerValue = {
            id: null,
            url: imageUrl,
            uploading: false,
          }

          acc[id] = {
            ...acc[id],
            image,
          }
        }
        if (type === 'featured_content') {
          const url = 'url' in settings ? settings.url : undefined
          const urlValue = getUrlValue(url)
          acc[id] = {
            ...acc[id],
            url: urlValue,
          }
        }

        return acc
      },
      {}
    )

    updateForm({
      type: 'RESET',
      payload: { contentBlocks: formattedContentBlocks, settings },
    })

    const filteredContentBlocks = contentBlocks.filter(block =>
      Object.keys(formattedContentBlocks).includes(`${block.id}`)
    )
    setBlockOrder(
      sortBy(filteredContentBlocks, 'position').map(block => `${block.id}`)
    )
  }

  function onNewBlock(type: ContentBlockTypes) {
    const newBlock = getDefaultBlockData(type, app.primary_color)
    if (!newBlock) return
    const newId = uniqueId('content-')
    createContentBlock(newId, newBlock)
    setBlockOrder(blockOrder => [...blockOrder, newId])
  }

  async function handleSubmit() {
    const payload = getSubmitPayload({
      blockOrder,
      formModel,
      landingPageTheme,
    })

    const response = await updateLandingPageTheme({
      id: appId,
      landing_page_theme: payload,
    })
    setIsConfirmModalVisible(false)

    if (isError(response)) {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
          timeout: 5,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('submit_success'),
        timeout: 5,
      })
    )
  }

  useEffect(() => {
    if (availableTypesIsLoading) return
    const settings = landingPageTheme?.settings ?? defaultSettings
    const contentBlocks = landingPageTheme?.content_blocks ?? []

    resetForm({
      contentBlocks,
      settings,
    })
  }, [landingPageTheme, defaultSettings, availableTypesIsLoading])

  useEffect(() => {
    async function fetchData() {
      dispatch(getValidation({ type: ValidationTypes.AppHomePage }))
    }

    fetchData()
  }, [dispatch])

  function handleContentValidSubmit() {
    setIsEditModalVisible(false)
    setIsFormValid(true)
  }

  function handleContentInvalidSubmit() {
    setIsFormValid(false)
    dispatch(
      alert({
        key: 'danger',
        message: t('validation_error'),
        timeout: 5,
      })
    )
  }

  function handleStyleValidSubmit() {
    setIsConfirmModalVisible(true)
    setIsFormValid(true)
  }

  function handleStyleInValidSubmit() {
    dispatch(
      alert({
        key: 'danger',
        message: t('validation_error'),
        timeout: 5,
      })
    )
  }

  const tab: TabT[] = [
    {
      key: Tabs.Content,
      title: t('tab_title'),
      subtabs: [
        {
          key: Tabs.Content,
          title: t('content.subtab_title'),
          component: (
            <Formsy
              onChange={(
                data: FormModel['contentBlocks'],
                formChanged: boolean
              ) => {
                if (!formChanged) return
                blockOrder.forEach(blockId => {
                  const incomingData = data[blockId]
                  if (!incomingData) return
                  updateContentBlock(blockId, incomingData)
                })
              }}
              onValidSubmit={handleContentValidSubmit}
              onInvalidSubmit={handleContentInvalidSubmit}
            >
              <Content
                contentBlocks={formModel.contentBlocks}
                sidebar={sidebar('content')}
                footer={footer('content')}
                blockOrder={blockOrder}
                setBlockOrder={setBlockOrder}
                isFetching={isImageUploading || isFetching}
                onNewBlock={onNewBlock}
                updateContentBlock={updateContentBlock}
                isEditModalOpen={isEditModalVisible}
                setIsEditModalOpen={setIsEditModalVisible}
                setIsFormValid={setIsFormValid}
              />
              <ConfirmModal
                title={t('modal.publish_changes')}
                description={t('modal.you_are_publishing')}
                confirmText={t('modal.publish')}
                isOpen={isConfirmModalVisible}
                isLoading={isFormSubmitting}
                onClose={() => setIsConfirmModalVisible(false)}
                onConfirm={handleSubmit}
              />
            </Formsy>
          ),
        },
        {
          key: Tabs.StyleSettings,
          title: t('style_settings.subtab_title'),
          component: (
            <Formsy
              onChange={(data: FormModel['settings'], formChanged: boolean) => {
                if (!formChanged) return
                updateSettings(data)
              }}
              onValidSubmit={handleStyleValidSubmit}
              onInvalidSubmit={handleStyleInValidSubmit}
            >
              <StyleSettings
                sidebar={sidebar('style')}
                footer={footer('style')}
                isFetching={isFetching}
                settings={formModel.settings}
              />
            </Formsy>
          ),
        },
      ],
    },
  ]

  return {
    tab,
  }
}

export const useAvailableContentBlockTypes = (): {
  availableTypes: ContentBlockTypes[]
  isLoading: boolean
} => {
  const availableTypes: ContentBlockTypes[] = [
    'featured_content',
    'rewards',
    'recent_orders',
    'menu_categories',
    'external_form',
  ]

  const { data, isLoading } = storedValueApi.useGetConfigQuery()
  if (data?.state === 'active') {
    availableTypes.push('stored_value_balance')
  }

  const merchant = useCurrentMerchant()
  if (merchant?.loyalty_transition_settings?.eligible) {
    availableTypes.push('missing_loyalty_prompt')
  }

  return {
    availableTypes,
    isLoading,
  }
}

export type MenuCategory = { name: string; slug: string }

export const useUniqueMenuCategories = (): {
  menuCategories: MenuCategory[]
  isLoading: boolean
} => {
  const { data, isLoading } = useGetMenuCategoriesQuery('ALL')
  const menuCategories = useMemo(() => {
    const categories = (data?.menu_categories || [])
      .filter(category => !category.hidden)
      .map(category => category.name)

    const uniqueCategories = Array.from(new Set(categories))
    return uniqueCategories.map(category => ({
      name: category,
      slug: getCategorySlug(category),
    }))
  }, [data])

  return { menuCategories, isLoading }
}

export const useDefaultLandingPageThemeSettings = (): Settings => {
  const app = useSelector(selectApp)
  return useMemo(
    () =>
      app
        ? {
            link_color: app.primary_color,
            background_color: palette.white,
            header_text_color: palette.grey90,
            body_text_color: palette.grey70,
            ...getDefaultBannerStyles(),
            menu_category_page: getDefaultMenuCategoryStyles(app.primary_color),
          }
        : initialFormState.settings,
    [app]
  )
}
