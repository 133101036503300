import { externalFormsApi } from 'api/externalForms'
import { ContentBlockTypes } from 'api/landingPageTheme'
import ModalContainer from 'components/ModalContainer'
import Spinner from 'components/Spinner'
import React from 'react'
import {
  FormModel,
  useAvailableContentBlockTypes,
} from 'scenes/Cms/Content/AppHomePage/hooks'
import Block from './Block'

type Props = {
  isOpen: boolean
  onClose: () => void
  onAddNewBlock: (type: ContentBlockTypes) => void
  contentBlocks: FormModel['contentBlocks']
  blockOrder: Array<string>
}
const multipleAllowedBlockTypes: ContentBlockTypes[] = [
  'featured_content',
  'external_form',
]

const AddNewBlockModal = ({
  isOpen,
  onClose,
  onAddNewBlock,
  contentBlocks,
  blockOrder,
}: Props) => {
  const { availableTypes, isLoading: isAvailableContentBlockLoading } =
    useAvailableContentBlockTypes()
  const currentBlockTypes = blockOrder.map(
    blockId => contentBlocks[blockId].type
  )

  const { data: formResponse, isLoading: isExternalFormsLoading } =
    externalFormsApi.useGetExternalFormsQuery()
  const forms = formResponse?.external_forms || []

  return (
    <ModalContainer
      title={'Add a new block'}
      isOpen={isOpen}
      onClose={onClose}
      showBottomProceed={false}
      dialogStyle={{ marginTop: '70px' }}
      zIndex={1000}
    >
      <Spinner
        isLoading={isAvailableContentBlockLoading || isExternalFormsLoading}
        size="2x"
        className="text-center mt-xxl"
      >
        {availableTypes.map(type => {
          const isDisabled =
            currentBlockTypes.includes(type) &&
            !multipleAllowedBlockTypes.includes(type)
          const unavailable = type === 'external_form' && forms.length === 0

          return (
            <Block
              key={type}
              type={type}
              isDisabled={isDisabled}
              onAddNewBlock={onAddNewBlock}
              unavailable={unavailable}
            />
          )
        })}
      </Spinner>
    </ModalContainer>
  )
}

export default AddNewBlockModal
