import { Fields } from 'models/LandingPage'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectLandingPages = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => session.LandingPage.all().toRefArray()
)

export const selectLandingPage = createSelector(
  selectLandingPages,
  (apps: Fields[]): Fields => {
    return apps?.[0]
  }
)
