import React from 'react'
import { Col, Row } from 'react-bootstrap'

type Props = {
  title: string
  description: string
}
const ReportHeader: React.FC<Props> = ({ title, description }) => {
  return (
    <div className="spearmint-bkg white padding-huge">
      <div className="container">
        <Row>
          <Col md={6} className="text-center-sm">
            <h2 className="mb-s">{title}</h2>
            <p className="body-text-2">{description}</p>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ReportHeader
