import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { ContentBlockTypes } from 'api/landingPageTheme'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.content.app_home_page.content.block_item')

type Props = {
  type: ContentBlockTypes
  isDisabled?: boolean
  size?: 'small' | 'medium'
  unavailable?: boolean
}

const Description = ({
  type,
  isDisabled,
  size = 'medium',
  unavailable = false,
}: Props) => {
  const [css] = useStyletron()
  const description = t(`${type}.description`)
  const opacity = isDisabled ? '50%' : '100%'
  const fontSize = size ? '14px' : '16px'

  return (
    <div className={`d-flex flex-column ${css({ opacity })}`}>
      <Text.BodyText3
        color="grey70"
        className={css({ fontSize: fontSize, overflowWrap: 'anywhere' })}
      >
        {description}
      </Text.BodyText3>
      {unavailable && (
        <Text.BodyText3
          color="grey70"
          className={`mt-s ${css({
            fontSize: fontSize,
            overflowWrap: 'anywhere',
          })}`}
        >
          {t(`${type}.unavailable`)}
        </Text.BodyText3>
      )}
    </div>
  )
}

export default Description
