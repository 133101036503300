import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useLocale } from 'hooks/useLocale'
import { buildTranslate } from 'locales'
import { Fields as TargetSegment } from 'models/TargetSegment'
import React from 'react'
import { categoryNames, groupByCategories } from 'utilities/segmentCategories'
import Cards from './Cards'

const t = buildTranslate('thanx_campaigns.landing.segments')

type Props = {
  onClick: (value: number) => void
  segments: TargetSegment[]
  showCategory?: boolean
}

const SubcategoryHeader = ({ title, all }) => {
  if (!title && all.length === 1) {
    return null
  }

  return (
    <Text.BodyText3 bold tag="div" className="pt-s">
      {title || t('other')}
    </Text.BodyText3>
  )
}

const CardsByCategory: React.FC<Props> = ({
  onClick,
  segments,
  showCategory = true,
}) => {
  const locale = useLocale()
  const groupedSegments = groupByCategories(segments)
  const categories = categoryNames(groupedSegments, locale)

  return (
    <>
      {categories.map(([category, subcategories]) => (
        <div key={category} className="mt-l">
          <Switch condition={showCategory}>
            <Text.Header4 className="mt-m">
              {category || t('other')}
            </Text.Header4>
          </Switch>
          {subcategories.map(subcategory => (
            <div key={`${category}-${subcategory}`}>
              <SubcategoryHeader title={subcategory} all={subcategories} />
              <Cards
                segments={groupedSegments[category][subcategory]}
                onClick={onClick}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

export default CardsByCategory
