import { useStyletron } from '@thanx/uikit/theme'
import progressBarIcon from 'assets/images/tiers/progress_bar.png'
import progressPaddle from 'assets/images/tiers/progress_paddle.png'
import Badges from 'components/Badges'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.brand.tiers')

type Props = {
  progressBarImage?: string
  paddleImage?: string
}

const AdditionalAssets: React.FC<Props> = props => {
  const { progressBarImage, paddleImage } = props
  const [css] = useStyletron()

  return (
    <Section className="p-xl mt-l body-text-4 grey-90">
      <p className="small-caps-1">{t('additional_assets')}</p>
      <p className="mt-m bold">{t('status_bar')}</p>
      <Badges values={[t('status_size'), t('png')]} className="mt-xs" />
      <img
        src={progressBarImage || progressBarIcon}
        alt={t('status_bar')}
        className={`img-fluid mt-m ${css({
          objectFit: 'cover',
          maxHeight: '24px',
        })}`}
      />
      <p className="grey-70 mt-xs">{t('status_description')}</p>

      <p className="mt-m bold">{t('progress_indicator')}</p>
      <Badges values={[t('progress_size'), t('png')]} className="mt-xs" />
      <img
        src={paddleImage || progressPaddle}
        alt={t('progress_indicator')}
        className={`${css({
          objectFit: 'cover',
          width: '48px',
          height: '48px',
        })} mt-m`}
      />
      <p className="grey-70 mt-xs">{t('progress_description')}</p>
    </Section>
  )
}

export default AdditionalAssets
