import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { ContentBlockTypes } from 'api/landingPageTheme'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  type: ContentBlockTypes
  isDisabled: boolean
  onAddNewBlock: (type: ContentBlockTypes) => void
  unavailable?: boolean
}

const t = buildTranslate('cms.content.app_home_page.content.block_item')

const SubmitButton = ({
  isDisabled,
  unavailable,
  onAddNewBlock,
  type,
}: Props) => {
  if (!isDisabled && !unavailable) {
    return (
      <Button size="small" onClick={() => onAddNewBlock(type)}>
        {t('add_block')}
      </Button>
    )
  }

  if (unavailable) {
    return (
      <Button
        size="small"
        onClick={() =>
          window.open(
            `mailto:merchant.support@thanx.com?subject=${t(
              `${type}.email_subject`
            )}`,
            '_blank'
          )
        }
      >
        {t('contact_us')}
      </Button>
    )
  }

  return (
    <Text.BodyText4 tag="p" color="grey50">
      {t('already_added')}
    </Text.BodyText4>
  )
}

export default SubmitButton
