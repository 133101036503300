import NoMessageIcon from 'assets/images/campaign-center/builder/img-no_message.svg'
import EmptyContentSection from 'components/review/EmptyContentSection'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('thanx_campaigns.review.message')

type Props = {
  isEditable: boolean
  onAdd: () => void
}

const NoMessage: React.FC<Props> = ({ isEditable, onAdd }) => (
  <EmptyContentSection
    icon={NoMessageIcon}
    isEditable={isEditable}
    onAdd={onAdd}
    addText={t('add')}
    text={isEditable ? t('missing') : t('no_message')}
  />
)

export default NoMessage
