import { useStyletron } from '@thanx/uikit/theme'
import StatusIcon from 'assets/images/StatusIcon'
import React from 'react'
import { Nav } from 'react-bootstrap-five'
import { merchantTheme } from 'theme'
import { useIsDesktop } from 'utilities/responsive'

const Status: React.FC = () => {
  const [css] = useStyletron()
  const isDesktop = useIsDesktop()

  return (
    <Nav.Item className="d-flex align-items-center">
      <a
        href="https://status.thanx.com"
        target="_blank"
        rel="noreferrer"
        className={`d-flex ${css({
          padding: '15px ' + (isDesktop ? '8px' : '10px'),
          display: 'inline-block',
          transition: 'all 0.2s ease',
          color: merchantTheme.colors.grey30,
          ':hover': {
            backgroundColor: merchantTheme.colors.grey100,
            color: merchantTheme.colors.white,
          },
          ':focus': {
            backgroundColor: merchantTheme.colors.grey100,
            color: merchantTheme.colors.white,
          },
        })}`}
      >
        <StatusIcon />
      </a>
    </Nav.Item>
  )
}

export default Status
