import gift from 'assets/images/redeem-manager/welcome_gift.svg'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('redeem_manager.landing.welcome')

type PropsT = {
  onClose?: VoidFunction
}
const WelcomeCard: React.FC<PropsT> = props => {
  const { onClose } = props

  return (
    <div className="d-flex align-items-center mb-m p-m border-1 grey-30-border">
      <img
        style={{ height: '82px', width: 'auto' }}
        className="mr-l"
        src={gift}
        alt="Gift"
      />
      <div>
        <h4 className="extra-bold">{t('title')}</h4>
        <p className="mb-m grey-70">{t('description_1')}</p>
        <p className="grey-70">{t('description_2')}</p>
      </div>
      <button
        style={{ top: '16px', right: '32px' }}
        type="button"
        className="position-absolute close"
        aria-label="Close"
        onClick={onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}

export default WelcomeCard
