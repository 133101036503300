import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const t = buildTranslate('points.activity.report_not_active.migrating')
const header = buildTranslate('points.activity')

const Migrating: React.FC = () => {
  const [css] = useStyletron()

  return (
    <div>
      <h3 className="mt-0 mb-xs mr-2 inline-block">{header('title')}</h3>
      <div className="mb-m">
        <Text.BodyText3 color="grey70">{header('description')}</Text.BodyText3>
      </div>
      <section className={`${css({ marginTop: '60px' })}`}>
        <Row className="section">
          <Col sm={12}>
            <Text.Header4 bold className="text-center mt-xxs">
              {t('title')}
            </Text.Header4>
          </Col>
        </Row>
        <Row className="section justify-content-sm-center">
          <Col className="col-content text-center" sm={7}>
            <Text.BodyText3 color="grey70">{t('description')}</Text.BodyText3>
          </Col>
        </Row>
      </section>
    </div>
  )
}

export default Migrating
