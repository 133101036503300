import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

type Props = {
  className?: string
  style?: React.CSSProperties
  textClassName?: string
  title: string
  content: React.ReactNode
  tooltip?: string | null
}

const HeaderBox: React.FC<Props> = props => {
  const {
    className = '',
    style = {},
    textClassName = '',
    title,
    content,
  } = props

  function tooltip() {
    if (!props.tooltip) return null
    const popover = <Popover id="tooltip">{props.tooltip}</Popover>

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={popover}
      >
        <i className={`fa fa-question-circle fa-2x ${textClassName}`} />
      </OverlayTrigger>
    )
  }

  return (
    <div
      className={`
        padding-extra-large display-flex flex-space-between border-radius-5
        full-height full-width ${className}
      `}
      style={style}
    >
      <div className="display-flex flex-column">
        <p className={`deprecated__small-caps-3 bold ${textClassName}`}>
          {title}
        </p>
        <div className="flex-grow-1 display-flex flex-align-center">
          <p className={`${textClassName}`}>{content}</p>
        </div>
      </div>
      <div>{tooltip()}</div>
    </div>
  )
}

export default HeaderBox
