import { GET_ALL_SUCCESS, ResolvedAction } from 'actions/notifyEmails'
import { RESET_DB } from 'actions/orm'
import { attr, Model, ModelType } from 'redux-orm'
import { CampaignStateT, NotifyEmailConfig } from './Campaign'

interface SharedFields extends NotifyEmailConfig {
  id: string
  campaign_id: number
  campaign_name: string
  state: CampaignStateT
  start_at: string
  updated_at: string
}

interface CampaignNotifyEmail extends SharedFields {
  type: 'campaign'
}

export interface VariantNotifyEmail extends SharedFields {
  type: 'variant'
  variant_id: number
  variant_name: string | null
  variant_type: string
}

export type Fields = CampaignNotifyEmail | VariantNotifyEmail

export default class NotifyEmail extends Model<
  // @ts-ignore
  typeof NotifyEmail,
  Fields
> {
  static modelName: string = 'NotifyEmail'

  static get fields(): any {
    return {
      campaign_id: attr(),
      campaign_name: attr(),
      type: attr(),
      state: attr(),
      variant_id: attr(),
      variant_name: attr(),
      variant_type: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    NotifyEmail: ModelType<NotifyEmail>
  ): void {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_ALL_SUCCESS:
        action.payload.data.notify_emails.forEach(notify_email => {
          NotifyEmail.upsert(notify_email)
        })
        break
      case RESET_DB:
        NotifyEmail.all().delete()
        break
      default:
        break
    }
  }
}
