import { buildTranslate } from 'locales'
import type { Fields as CampaignType } from 'models/CampaignType'
import { ReducedCampaign } from 'models/UserCampaign'
import React from 'react'
import { Button } from 'react-bootstrap'
import { reportLink } from 'utilities/campaignUtils'

const t = buildTranslate('users.view.activity.campaigns')

type PropsT = {
  campaign: ReducedCampaign
  campaignType: CampaignType
}

const ReportLink: React.FC<PropsT> = ({ campaign, campaignType }) => {
  const linkInfo = reportLink(campaign, campaignType, true)

  return (
    <a
      className={'btn btn-link'}
      target="_blank"
      href={linkInfo.href}
      rel="noreferrer"
    >
      <Button bsStyle="link">{t('report_link')}</Button>
    </a>
  )
}

export default ReportLink
