import { GET_SUCCESS, ResolvedAction } from 'actions/hiddenMenus'
import { RESET_DB } from 'actions/orm'
import { fk, Model, ModelType } from 'redux-orm'

export interface Fields {
  ids: number[]
  name: string
}

// @ts-ignore
export default class HiddenMenu extends Model<typeof HiddenMenu, Fields> {
  static modelName: string = 'HiddenMenu'

  static get fields(): any {
    return {
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'hiddenMenus',
      }),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    HiddenMenu: ModelType<HiddenMenu>
  ): void {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_SUCCESS:
        action.payload.data.hidden_categories.forEach(category => {
          HiddenMenu.upsert({
            ...category,
            id: category.ids.join(','),
            label: category.name,
          })
        })
        break
      default:
        break
    }
  }
}
