import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import Nav from 'react-bootstrap-five/Nav'

type Props = {
  tabKey: string
  title: string
  isActive: boolean
  className?: string
  activeBackgroundClass?: string
}

const Item: React.FC<Props> = props => {
  const {
    tabKey,
    title,
    isActive,
    className = '',
    activeBackgroundClass = 'belize-hole-10-bkg',
  } = props
  const [css] = useStyletron()

  return (
    <Nav.Item>
      <Nav.Link
        className={`${className} body-text-4  ${
          isActive ? `grey-90 bold ${activeBackgroundClass}` : 'grey-70'
        } ${css({
          ':focus': {
            color: defaultTheme.colors.grey90,
          },
        })}`}
        eventKey={tabKey}
      >
        {title}
      </Nav.Link>
    </Nav.Item>
  )
}

export default Item
