function CcpaTable() {
  return (
    <>
      <table className="table table-bordered table-condensed ccpa">
        <tr>
          <th colSpan={6}>
            <b>
              Personal Information Collected, Used and/or Disclosed for Business
              Purpose in Preceding 12 Months
            </b>
          </th>
        </tr>

        <tr>
          <th>CCPA Category</th>
          <th>Collected?</th>
          <th>Source of Collection</th>
          <th>Business Purpose</th>
          <th>Disclosed or Shared for Business Purpose? </th>
          <th>Purpose of Disclosure</th>
        </tr>

        <tr>
          <th colSpan={6}>
            <u>Category A Identifiers & B Personal Information</u>
          </th>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Name/Alias</li>
            </ul>
          </td>
          <td>YES</td>
          <td>
            <ul>
              <li>YOU; Thanx Network Merchants</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Account Creation;</li>
              <li>Customer Communication</li>
              <li>Order Processing</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Social Networking Services</li>
              <li>Thanx Network Merchants</li>
              <li>Service Provider</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Upon election with SNS for account creation</li>
              <li>Customer Communications</li>
              <li>Order Processing</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Email</li>
            </ul>
          </td>
          <td>YES</td>
          <td>
            <ul>
              <li>YOU; Thanx Network Merchants</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Account Creation;</li>
              <li>Account Authentication;</li>
              <li>Customer Communication</li>
              <li>Order Processing</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Social Networking Services</li>
              <li>Thanx Network Merchants</li>
              <li>Merchants Service Provider</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Upon election with SNS for account creation;</li>
              <li>Customer Communications</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Address</li>
            </ul>
          </td>
          <td>YES</td>
          <td>
            <ul>
              <li>YOU</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Order Processing</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Thanx Network Merchants</li>
              <li>Service Provider</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Order Processing</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Cell or Telephone #</li>
            </ul>
          </td>
          <td>YES</td>
          <td>
            <ul>
              <li>YOU</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Customer Communications</li>
              <li>Order Processing</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Thanx Network Merchants</li>
              <li>Service Provider</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Order Processing</li>
              <li>Customer Communications</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Unique/Online ID (cookies)</li>
            </ul>
          </td>
          <td>YES</td>
          <td>
            <ul>
              <li>YOU; DEVICE; Service Provider</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
              <li>Auditing</li>
              <li>Fraud Prevention</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Thanx Network Merchants</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
              <li>Auditing</li>
              <li>Fraud Prevention</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>IP Address</li>
            </ul>
          </td>
          <td>YES</td>
          <td>
            <ul>
              <li>DEVICE; Service Provider</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
              <li>Auditing</li>
              <li>Data Security</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Thanx Network Merchan</li>
              <li>Service Provider</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
              <li>Auditing</li>
              <li>Data Security</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Account Name</li>
            </ul>
          </td>
          <td>YES</td>
          <td>YOU</td>
          <td>
            <ul>
              <li>Account Setup</li>
            </ul>
          </td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Social Security #</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Driver’s License #</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Passport #</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Credit or Debit Card # / ACH</li>
            </ul>
          </td>
          <td>YES* *token only</td>
          <td>YOU</td>
          <td>
            <ul>
              <li>Administer Loyalty Services;</li>
              <li>Process Payment</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Service Provider</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Process Payment;</li>
              <li>Analytics</li>
              <li>Transaction History to Administer Loyalty Services</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Other: Medical/Financial/Health</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>Category C: Protected Characteristics</td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <u>Category D: Commercial Information</u>
            <ul>
              <li>Personal Property Records</li>
            </ul>
          </td>
          <td>Yes</td>
          <td>
            <ul>
              <li>You</li>
              <li>Thanx Network Merchants</li>
              <li>Linked Card Processors</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Administer Loyalty Services</li>
              <li>Analytics</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Applicable Thanx Network Merchant</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
              <li>Transaction History to Administer Loyalty Services</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Products/Services Purchased-Obtained-Considered</li>
            </ul>
          </td>
          <td>Yes</td>
          <td>
            <ul>
              <li>Cookies</li>
              <li>You; Purchases</li>
              <li>Thanx Network Merchants</li>
              <li>Linked Card Processors</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Administer Loyalty Services</li>
              <li>Analytics</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Applicable Thanx Network Merchant</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
              <li>Transaction History to Administer Loyalty Services</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Purchasing history/tendencies</li>
            </ul>
          </td>
          <td>Yes</td>
          <td>
            <ul>
              <li>Cookies</li>
              <li>You;</li>
              <li>Linked Card Processors</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
              <li>Transaction History to Administer Loyalty Services</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Payment Processors;</li>
              <li>Applicable Thanx Network Merchant</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
              <li>Transaction History to Administer Loyalty Services</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <u>Category E: Biometric Information</u>
          </td>
          <td>No</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <th colSpan={6}>
            <u>Category F: Internet/Network Activity</u>
          </th>
        </tr>

        <tr>
          <td>
            <u>Category F: Internet/Network Activity</u>
            <ul>
              <li>Browsing/search history</li>
              <li>Interaction with site/advertisement</li>
            </ul>
          </td>
          <td>Yes</td>
          <td>
            <ul>
              <li>Cookies;</li>
              <li>IP Address;</li>
              <li>Use of website, services and/or app</li>
            </ul>
          </td>
          <td>N/A</td>
          <td>
            <ul>
              <li>Applicable Thanx Network Merchant</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <u>Category G: Geolocation data</u>
          </td>
          <td>Yes</td>
          <td>
            <ul>
              <li>Device</li>
              <li>IP Address</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Administer Loyalty Services</li>
              <li>Provide Directions</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Applicable Thanx Network Merchant</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Administer Loyalty Services</li>
              <li>Analytics</li>
              <li>Order Processing</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <u>Category H:</u>
            <ul>
              <li>Audio</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Electronic</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Visual</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Thermal</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Olfactory</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Other Similar</li>
            </ul>
          </td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>Category L: Professional/Employment</td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>Category J: Educational Information</td>
          <td>NO</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <u>Category K: Inferences from Data Collected</u>
              <li>Preferences</li>
            </ul>
          </td>
          <td>Yes</td>
          <td>Device; Cookies; 3rd Party</td>
          <td>
            <ul>
              <li>Analytics</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Applicable Thanx Network Merchant</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Characteristics</li>
            </ul>
          </td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Psychological Trends</li>
            </ul>
          </td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Predispositions</li>
            </ul>
          </td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Behavior</li>
            </ul>
          </td>
          <td>YES</td>
          <td>Device; Cookies; 3rd Party</td>
          <td>
            <ul>
              <li>Analytics</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Applicable Thanx Network Merchant</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Analytics</li>
            </ul>
          </td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Attitudes</li>
            </ul>
          </td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>

        <tr>
          <td>
            <ul>
              <li>Intelligence/Abilities/Aptitudes</li>
            </ul>
          </td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
          <td>N/A</td>
        </tr>
      </table>
    </>
  )
}

export default CcpaTable
