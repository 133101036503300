import merchantIcon from 'assets/images/campaign-center/opportunity/icons/merchant.png'
import phoneMessage from 'assets/images/campaign-center/opportunity/icons/phone_message.png'
import phoneReward from 'assets/images/campaign-center/opportunity/icons/phone_reward.png'
import withMerchantMetrics, {
  MetricsProps,
} from 'components/metrics/withMerchantMetrics'
import { buildTranslate } from 'locales'
import React from 'react'
import MetricSection from '../components/MetricSection'
import Header from '../components/Shared/Header'
import HowItWorks from '../components/Shared/HowItWorks'
import Setup from '../components/Shared/Setup'
import Solution from '../components/Shared/Solution'
import SolutionStep from '../components/Shared/SolutionStep'
import UseCase from '../components/Shared/UseCase'

const t = buildTranslate('thanx_campaigns.opportunity.birthday')

export const Birthday: React.FC<MetricsProps> = props => {
  const {
    handleClick,
    isCampaignTypeLoading,
    campaignType,
    merchantName,
    merchantMetrics,
    merchantMetricStatuses,
  } = props

  const birthdayCount =
    merchantMetrics?.birthday_count?.data?.birthday_count || 0

  return (
    <div>
      <Header campaignType="birthday" />
      <UseCase campaignType="birthday" merchantName={merchantName} />

      <MetricSection
        value={birthdayCount}
        description={t('metric')}
        isLoading={merchantMetricStatuses?.birthday_count.isLoading}
        className="spearmint-10-bkg"
      />

      <Solution campaignType="birthday">
        <SolutionStep
          icon={phoneMessage}
          iconAlt={t('solution.steps.image_one_alt')}
          text={t('solution.steps.one')}
        />
        <SolutionStep
          icon={phoneReward}
          iconAlt={t('solution.steps.image_two_alt')}
          text={t('solution.steps.two')}
        />
        <SolutionStep
          icon={merchantIcon}
          iconAlt={t('solution.steps.image_three_alt')}
          text={t('solution.steps.three')}
        />
      </Solution>
      <hr className="width-75" />
      <HowItWorks campaignType="birthday" />
      <Setup
        type="birthday"
        isLoading={isCampaignTypeLoading}
        handleClick={handleClick}
        campaignType={campaignType}
      />
    </div>
  )
}

export default withMerchantMetrics(['birthday_count'])(Birthday)
