import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Text } from '@thanx/uikit/text'
import Counter from 'components/Counter'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React from 'react'
import { useUikitTheme } from 'theme'
import { getFormattedDate } from 'utilities/date'
import { percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('lifecycle_report.detail.over_time_chart.tooltip')

type Props = {
  timestamp: number
  conversionRate: number
  timezone: string
}

const styletronEngine = new StyletronEngine({ prefix: 'st-ot-tt-' })

const Tooltip: React.FC<Props> = ({ timestamp, conversionRate, timezone }) => {
  const uikitTheme = useUikitTheme()
  const date = getFormattedDate(moment(timestamp).tz(timezone), 'weekly')

  return (
    <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
      <Text.BodyText4 color="grey70" tag="div" className="mb-s">
        {date}
      </Text.BodyText4>
      <Counter
        primaryValue={percentageFormatter(conversionRate, 1, 1)}
        name={t('description')}
      />
    </StyleProvider>
  )
}

export default Tooltip
