import { Switch } from '@thanx/uikit/switch'
import Checkbox from 'components/Form/CheckboxWithFormsy'
import React, { useState } from 'react'

type Props = {
  title: string
  openOnStart?: boolean
  children?: React.ReactNode
  keepHiddenChildren?: boolean
  name?: string
  disabled?: boolean
  labelText?: string
}

const ToggleContainer: React.FC<Props> = props => {
  const {
    title,
    openOnStart = true,
    children,
    keepHiddenChildren,
    name = '__toggle',
    disabled = false,
    labelText,
  } = props
  const [open, setOpen] = useState(openOnStart)

  const hideChildren = !!keepHiddenChildren && !open

  return (
    <div className="border-1 grey-30-border border-radius-5">
      <div className="clearfix grey-10-bkg padding-top-small padding-bottom-small padding-left-large padding-right-small">
        <span className="body-text-2 bold">{title}</span>
        <span
          className="pull-right white-bkg border-round inline-block margin-right-small"
          style={{ height: '24px' }}
        >
          <Checkbox
            name={name}
            isChecked={open}
            checkedClass="fa inline-block font-size-32 fa-lg fa-toggle-on belize-hole"
            uncheckedClass="fa inline-block font-size-32 fa-lg fa-toggle-on grey-40 flip-horiz"
            onChange={() => setOpen(!open)}
            isDisabled={disabled}
            checkedValue={true}
            uncheckedValue={false}
            labelText={labelText}
          />
        </span>
      </div>
      <Switch condition={open || !!keepHiddenChildren}>
        <div className={`padding-large ${hideChildren ? 'hide' : ''}`}>
          {children}
        </div>
      </Switch>
    </div>
  )
}

export default ToggleContainer
