import { RESET_DB } from 'actions/orm'
import { GET_USER_REWARDS_SUCCESS, ResolvedAction } from 'actions/users'
import { Model, ModelType } from 'redux-orm'

export type RewardState =
  | 'available'
  | 'active'
  | 'in_progress'
  | 'retired'
  | 'used'
  | 'fraudulent'

type IssuanceType =
  | 'granted'
  | 'intro'
  | 'vip'
  | 'special_offer'
  | 'purchased'
  | 'earned'

export interface Fields {
  id: number
  user_id: number
  redeem_description: string
  delivered_at: string
  redeemed_at?: string
  state: RewardState | null
  issuance_type: IssuanceType
}

// @ts-ignore
export default class UserReward extends Model<typeof UserReward, Fields> {
  static modelName: string = 'UserReward'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    UserReward: ModelType<UserReward>
  ): void {
    switch (action.type) {
      case GET_USER_REWARDS_SUCCESS:
        action.payload.data.rewards.forEach(reward => UserReward.upsert(reward))
        break
      case RESET_DB:
        UserReward.all().delete()
        break
      default:
        break
    }
  }
}
