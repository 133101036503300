import { Radio } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React, { useContext, useState } from 'react'
import { EditContext } from 'scenes/Points/CreateEdit/EditContext'
import DateRange from './DateRange'
import { FormT } from './index'

type PropsT = {
  currency: string
  formTemplate: FormT | null
}

const t = buildTranslate('points.create_edit.restrictions.modal')

const When: React.FC<PropsT> = props => {
  const { template } = useContext(EditContext)
  const { currency, formTemplate } = props

  const [hasTime, setHasTime] = useState(
    !!template?.exchange_end_at || !!template?.exchange_start_at
  )

  return (
    <>
      <div className="border-1 grey-30-border border-radius-5 p-m">
        <Text.Header4 className="mt-0 mb-m">
          {t('when', { currency })}
        </Text.Header4>
        <div className="d-flex flex-column">
          <Radio
            className="mb-0"
            onClick={() => setHasTime(false)}
            overrides={{
              Root: {
                style: {
                  alignItems: 'flex-start',
                },
              },
            }}
            value={'noTime'}
            checked={!hasTime}
          >
            <Text.BodyText3 color="grey70" className="mb-xs" tag="div">
              {t('any_title')}
            </Text.BodyText3>
            <Text.BodyText4 color="grey70">
              {t('any_subtitle', { currency })}
            </Text.BodyText4>
          </Radio>
          <Radio
            className="mt-m"
            onClick={() => setHasTime(true)}
            overrides={{
              Root: {
                style: {
                  alignItems: 'flex-start',
                },
              },
            }}
            value={'hasTime'}
            checked={hasTime}
          >
            <Text.BodyText3 color="grey70" className="mb-xs" tag="div">
              {t('within_title')}
            </Text.BodyText3>
            <Text.BodyText4 color="grey70">
              {t('within_subtitle', { currency })}
            </Text.BodyText4>
          </Radio>
          {hasTime && (
            <DateRange
              formTemplate={formTemplate}
              template={template}
              currency={currency}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default When
