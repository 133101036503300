import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import AudienceContainer from 'scenes/CampaignCenter/Builder/Steps/Message/Setup/Substeps/Audience'

type Props = {
  campaign: Campaign
}

const Audience: React.FC<Props> = ({ campaign }) => {
  return (
    <div className="container">
      <AudienceContainer campaign={campaign} />
    </div>
  )
}

export default Audience
