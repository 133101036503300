import { Fields as NotifyEmail } from 'models/NotifyEmail'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import { CampaignStateT } from '../models/Campaign'

export const selectAllNotifyEmails = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): NotifyEmail[] => {
    return session.NotifyEmail.all().toRefArray()
  }
)

export const selectNotifyEmailsByState = createSelector(
  selectAllNotifyEmails,
  (_: any, state: CampaignStateT) => state,
  (notifyEmails, state): NotifyEmail[] => {
    return notifyEmails.filter(notifyEmail => notifyEmail.state === state)
  }
)

export const selectNotifyEmail = createSelector(
  selectAllNotifyEmails,
  (_: any, id: string) => id,
  (notifyEmails, id): NotifyEmail | null => {
    return notifyEmails.find(notifyEmail => notifyEmail.id === id) ?? null
  }
)
