import { buildTranslate } from 'locales'
import React from 'react'
import { Button } from 'react-bootstrap'

type Props = {
  onClick?: () => void
  isEmpty?: boolean
  type?: string
}

const t = buildTranslate('thanx_campaigns.review')

const EditButton: React.FC<Props> = ({ onClick, isEmpty = false, type }) => {
  const bsStyle = isEmpty ? 'primary' : 'info'
  const text = isEmpty && type ? t(`${type}.add`) : t('button.edit')
  return (
    <Button bsStyle={bsStyle} onClick={onClick}>
      {text}
    </Button>
  )
}

export default EditButton
