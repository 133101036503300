import { alert } from 'actions/flash'
import { landingPageThemeApi, MenuCategoryStyles } from 'api/landingPageTheme'
import { isError } from 'api/utils'
import ConfirmModal from 'components/ConfirmModal'
import Spinner from 'components/Spinner'
import { TabT } from 'components/Tabs/InnerTabs'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tabs } from 'scenes/Cms/Content'
import { useDefaultLandingPageThemeSettings } from 'scenes/Cms/Content/AppHomePage/hooks'
import { selectApp } from 'selectors/app'
import MenuCategoryPage from './'
import { getSubmitPayload } from './helpers'

const t = buildTranslate('cms.content.menu_category')

export type FormModel = MenuCategoryStyles

type FormAction =
  | { type: 'RESET'; payload: FormModel }
  | { type: 'UPDATE_SETTINGS'; payload: FormModel }

const formReducer = (state: FormModel, action: FormAction): FormModel => {
  switch (action.type) {
    case 'RESET':
      return action.payload
    case 'UPDATE_SETTINGS':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const initialFormState: FormModel = {
  background_color: '#FFFFFF',
  header_text_color: '#36424E',
  body_text_color: '#6A747F',
  link_color: '#000000',
  product_card: {
    background_color: '#FFFFFF',
    header_text_color: '#4D4D4F',
    body_text_color: '#9FA1A4',
    border_color: '#DEDFE0',
    border_width: 1,
    border_radius: 2,
  },
  unavailable_product_card: {
    background_color: '#F3F4F4',
    header_text_color: '#BCBEC0',
    body_text_color: '#808285',
    border_color: '#DEDFE0',
  },
}

export function useMenuCategory() {
  const app = useSelector(selectApp)
  const appId = app?.id
  const dispatch = useDispatch()
  const [formModel, updateForm] = useReducer(formReducer, initialFormState)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const defaultSettings = useDefaultLandingPageThemeSettings()
  const defaultMenuCategoryStyles = defaultSettings.menu_category_page

  const { data: landingPageThemeData, isFetching: isLoading } =
    landingPageThemeApi.useGetLandingPageThemeQuery(appId)
  const landingPageTheme = landingPageThemeData?.app_landing_page_theme ?? null
  const [updateLandingPageTheme, { isLoading: isFormSubmitting }] =
    landingPageThemeApi.useUpdateLandingPageThemeMutation()

  function resetForm({ settings }: { settings: FormModel }) {
    updateForm({ type: 'RESET', payload: settings })
  }

  function updateSettings(settings: FormModel) {
    updateForm({ type: 'UPDATE_SETTINGS', payload: settings })
  }

  async function handleSubmit() {
    const payload = getSubmitPayload({
      formModel,
      landingPageTheme,
      defaultSettings,
    })

    const response = await updateLandingPageTheme({
      id: appId,
      landing_page_theme: payload,
    })

    setIsConfirmModalVisible(false)
    if (isError(response)) {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
          timeout: 5,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('submit_success'),
        timeout: 5,
      })
    )
  }

  function handleValidSubmit() {
    setIsConfirmModalVisible(true)
  }

  function handleInvalidSubmit() {
    dispatch(
      alert({
        key: 'danger',
        message: t('validation_error'),
        timeout: 5,
      })
    )
  }

  useEffect(() => {
    const menuCategoryStyles = landingPageTheme?.settings.menu_category_page
    const hasExistingSettings = !!menuCategoryStyles?.background_color

    const settings = hasExistingSettings
      ? menuCategoryStyles
      : defaultMenuCategoryStyles

    resetForm({ settings })
  }, [landingPageTheme?.settings.menu_category_page, defaultMenuCategoryStyles])

  const tab: TabT = {
    key: Tabs.MenuCategory,
    title: t('tab_title'),
    component: (
      <Spinner
        className="text-center margin-top-huge"
        size="4x"
        isLoading={isLoading}
      >
        <Formsy
          onChange={(data: FormModel, formChanged: boolean) => {
            if (!formChanged) return
            updateSettings(data)
          }}
          onValidSubmit={handleValidSubmit}
          onInvalidSubmit={handleInvalidSubmit}
        >
          <MenuCategoryPage
            isFetching={isFormSubmitting}
            isLoading={isLoading}
            data={formModel}
          />
          <ConfirmModal
            title={t('modal.publish_changes')}
            description={t('modal.you_are_publishing')}
            confirmText={t('modal.publish')}
            isOpen={isConfirmModalVisible}
            isLoading={false}
            onClose={() => setIsConfirmModalVisible(false)}
            onConfirm={handleSubmit}
          />
        </Formsy>
      </Spinner>
    ),
  }

  return { tab }
}
