import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { PointsMultiplierPayload } from 'api/points'
import { buildTranslate } from 'locales'
import Card from './Card'

const t = buildTranslate('points.multipliers.form')
const vt = buildTranslate('validations')

const MIN_FACTOR = 1
const MAX_FACTOR = 1000

type Props = { multiplier: PointsMultiplierPayload }

function validateLessThanTwoDecimals(value: string | null | undefined) {
  if (!value) {
    return true
  }
  return /^\d+(\.\d{0,2})?$/.test(value) // Custom validation for two decimal
}

const ConfigurationCard = ({ multiplier }: Props) => {
  const [css] = useStyletron()

  return (
    <Card title={t('configuration')} footer={t('configuration_footer')}>
      <div className="inline-block">
        <FormsyInput
          type="number"
          name="factor"
          label={
            <Text.Header4 className="mb-m">{t('factor.title')}</Text.Header4>
          }
          value={multiplier.factor}
          endEnhancer="x"
          step={0.01}
          className={css({
            width: '180px',
          })}
          validations={{
            isRequired: true,
            greaterThan: MIN_FACTOR,
            lessThanOrEqual: MAX_FACTOR,
            lessThanTwoDecimals: (_, v) => validateLessThanTwoDecimals(v),
          }}
          validationErrors={{
            isRequired: vt('is_required'),
            greaterThan: vt('greater_than', {
              value: MIN_FACTOR,
            }),
            lessThanOrEqual: vt('less_than_or_equal', {
              value: MAX_FACTOR,
            }),
            lessThanTwoDecimals: t('validation.less_than_two_decimal'),
          }}
        />
      </div>
    </Card>
  )
}

export default ConfigurationCard
