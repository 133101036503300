import { getCampaign } from 'actions/campaigns'
import {
  getCampaignVariant,
  updateCampaignVariant,
} from 'actions/campaignVariants'
// eslint-disable-next-line no-restricted-imports
import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import { Fields as CampaignVariant } from 'models/CampaignVariant'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCampaign } from 'selectors/campaign'
import { selectCampaignConfig } from 'selectors/campaignConfig'
import { selectCampaignVariant } from 'selectors/campaignVariant'

function useEditCampaign(campaignId: number, variantId: number) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const variant = useSelector(state => selectCampaignVariant(state, variantId))
  const campaign = useSelector(state => selectCampaign(state, campaignId))
  const campaignConfig = useSelector(state =>
    selectCampaignConfig(
      // @ts-ignore
      state,
      Number(campaignId),
      variant?.id
    )
  )
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetch() {
      setIsLoading(true)
      await Promise.allSettled([
        dispatch(getCampaignVariant(campaignId, variantId)),
        dispatch(getCampaign(campaignId)),
      ])
      setIsLoading(false)
    }
    fetch()
  }, [campaignId, variantId, dispatch])

  async function triggerSave(model: Partial<CampaignVariant>) {
    if (!campaignId || !variantId || !variant) {
      return
    }

    const updates = {
      ...variant,
      ...model,
    }

    setIsSaving(true)
    await dispatch(updateCampaignVariant(campaignId, variantId, updates))
    setIsSaving(false)
  }

  const debouncedSave: DebouncedFunc<any> = useCallback(
    debounce(triggerSave, 750),
    []
  )

  return {
    campaign,
    variant,
    config: campaignConfig,
    isLoading,
    isSaving,
    triggerSave,
    debouncedSave,
  }
}

export { useEditCampaign }
