// @flow
import React, { Component } from 'react'
import { Alert } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { Redirect, Route, Switch } from 'react-router'
import { push } from 'react-router-redux'

import ChooseProvider from './components/ChooseProvider'
import ExportSettingsContainer from './components/ExportSettingsContainer'
import EmailSuccess from '../Setup/components/EmailSuccess'
import { EmailProviderType } from '../constants'
// $FlowFixMe
import store from 'store'

import type { Match, Location } from 'flow-typed'

type Props = {
  match: Match,
  location: Location,
}
type State = {
  step: ?number,
  providerType: ?string,
  signupListName: ?string,
}

const oldDashboardPath = process.env.REACT_APP_MERCHANT_ROOT_URL || ''

const SUPPORTED_PROVIDERS = [
  EmailProviderType.MAILCHIMP,
  EmailProviderType.CONSTANT_CONTACT,
]

const STEPS = [
  {
    stepNum: 1,
    title: I18n.t('emails.subscribers.export.choose_provider.title'),
    path: '/choose',
  },
  {
    stepNum: 2,
    title: 'settings',
    path: new RegExp(`/settings/${SUPPORTED_PROVIDERS.join('|')}`),
  },
  {
    stepNum: 3,
    title: 'success',
    path: '/success',
  },
]

export class ProviderExportWizard extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      step: null,
      providerType: null,
      signupListName: null,
    }
  }

  getStep() {
    const pathname = this.props.location.pathname
    const wizardPath = pathname.replace(this.props.match.path, '')
    for (const step of STEPS) {
      if (wizardPath.match(step.path)) {
        return step
      }
    }

    return null
  }

  handleChooseProviderSuccess(providerType: string) {
    this.setState({ providerType })
    store.dispatch(push(`${this.props.match.path}/settings/${providerType}`))
  }

  handleChooseListSuccess(signupListName: string) {
    this.setState({ signupListName })
    store.dispatch(push(`${this.props.match.path}/success`))
  }

  handleProviderSuccessContinue() {
    window.location = `${oldDashboardPath}/subscriptions`
  }

  providerTypePretty() {
    switch (this.state.providerType) {
      case 'constant_contact':
        return 'Constant Contact'
      case 'mailchimp':
        return 'Mailchimp'
      default:
        return ''
    }
  }

  successDescription() {
    if (this.state.providerType && this.state.signupListName) {
      return I18n.t('emails.subscribers.export.success.description', {
        providerName: this.providerTypePretty(),
        signupListName: this.state.signupListName,
      })
    } else {
      return I18n.t('emails.subscribers.export.success.description_fallback')
    }
  }

  render() {
    const step = this.getStep()
    return (
      <div>
        <Alert bsStyle="warning">
          <p>
            Step {step && step.stepNum} of 3 - {step && step.title}
          </p>
        </Alert>
        <Switch>
          <Route
            exact
            path={`${this.props.match.path}`}
            render={() => <Redirect to={`${this.props.match.path}/choose`} />}
          />
          <Route
            exact
            path={`${this.props.match.path}/choose`}
            render={() => (
              <ChooseProvider
                supportedProviders={SUPPORTED_PROVIDERS}
                onSubmit={this.handleChooseProviderSuccess.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/settings/:providerType`}
            render={props => (
              <ExportSettingsContainer
                providerType={props.match.params.providerType}
                onSuccess={this.handleChooseListSuccess.bind(this)}
              />
            )}
          />
          <Route
            exact
            path={`${this.props.match.path}/success`}
            render={() => (
              <EmailSuccess
                title={I18n.t('emails.subscribers.export.success.title')}
                description={this.successDescription()}
                button={I18n.t('emails.subscribers.export.success.button')}
                handleContinue={this.handleProviderSuccessContinue.bind(this)}
              />
            )}
          />
        </Switch>
      </div>
    )
  }
}

export default ProviderExportWizard
