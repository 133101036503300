import { getLinks } from 'actions/links'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { RegularLinkTypes } from 'models/Link'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLinksByType, selectRegularLinks } from 'selectors/link'
import CmsPage from '../../components/CmsPage'
import PremiumLinks from './PremiumLinks'
import RewardsLinks from './RewardsLinks'

const t = buildTranslate('cms.content.custom_links')

const CustomLinks: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const regularLinks = useSelector(selectRegularLinks)
  const premiumLinks = useSelector(state =>
    selectLinksByType(state, RegularLinkTypes.Premium)
  )

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await dispatch(getLinks())
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      isLoading={isLoading}
    >
      <RewardsLinks links={regularLinks} />
      <PremiumLinks links={premiumLinks} />
    </CmsPage>
  )
}

export default CustomLinks
