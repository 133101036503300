import React from 'react'

type Row = {
  title: string
  subtitle: string
}
type Props = {
  title: string
  subtitle: string
  rows: Row[]
  checkClassName?: string
}

const InfoModalContent: React.FC<Props> = props => {
  const { title, subtitle, checkClassName = '' } = props

  const rows = () =>
    props.rows.map((row, i) => (
      <div key={i} className="display-table-row">
        <div className="display-table-cell vertical-top padding-left-large text-right">
          <i
            className={`fa fa-check-circle-o fa-2x margin-top-micro ${checkClassName}`}
          />
        </div>
        <div className="display-table-cell vertical-top padding-left-extra-large">
          <p className="font-size-20 bold grey-90 margin-bottom-medium">
            {row.title}
          </p>
          <p className="font-size-16 grey-70 margin-bottom-huge">
            {row.subtitle}
          </p>
        </div>
      </div>
    ))

  return (
    <div>
      <p className="font-size-22 bold grey-90 margin-bottom-small">{title}</p>
      <em className="font-size-18 grey-60 margin-bottom-extra-large block">
        {subtitle}
      </em>
      <hr className="grey-40-border border-top-1 margin-bottom-huge" />
      <div className="padding-right-extra-large padding-left-extra-large margin-top-extra-large margin-bottom-medium text-left">
        <div className="display-table">{rows()}</div>
      </div>
    </div>
  )
}

export default InfoModalContent
