import React, { useState } from 'react'
import Nav from 'react-bootstrap-five/Nav'
import Tab from 'react-bootstrap-five/Tab'
import { history } from 'store'
import StatusBadge from '../StatusBadge'
import DisabledBadge from './DisabledBadge'

export type TabT = {
  id: string
  disabled?: boolean
  disabledText?: string
  name: string
  content: React.ReactNode
  status?: 'coming_soon' | 'beta' | 'new'
}

type PropsT = {
  defaultTab: string
  shouldReplaceHash?: boolean
  tabs: TabT[]
  onSelect?: (tab: string) => void
}

const TabsContainer: React.FC<PropsT> = props => {
  const { defaultTab, onSelect, shouldReplaceHash, tabs } = props
  const [activeTab, setActiveTab] = useState<string>(defaultTab)
  const handleOnSelect = tab => {
    if (onSelect) {
      onSelect(tab)
    }
    if (shouldReplaceHash) {
      history.replace({ hash: tab })
    }
    setActiveTab(tab)
  }

  return (
    <Tab.Container
      activeKey={activeTab}
      transition={false}
      onSelect={handleOnSelect}
    >
      <div className="border-bottom-1 grey-20-border">
        <Nav className="container nav-tabs border-none">
          {tabs.map(tab => (
            <Nav.Item key={tab.id}>
              <Nav.Link eventKey={tab.id} disabled={tab.disabled}>
                <div>
                  {tab.name}
                  <DisabledBadge
                    disabled={tab.disabled}
                    disabledText={tab.disabledText}
                  />
                  {tab.status ? <StatusBadge type={tab.status} /> : null}
                </div>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <Tab.Content>{tabs.map(tab => tab.content)}</Tab.Content>
    </Tab.Container>
  )
}

export default TabsContainer
