import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import MtmEffectivenessBanner from 'components/banners/MtmEffectiveness'
import FetchableMetricSection from 'components/FetchableMetricSection'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import {
  ActivationRateCohortMetric,
  ActivationRateCumulativeMetric,
  ActivationRateTimeSeriesMetric,
  ShortType,
} from 'models/ActivationRateMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import CohortChart from 'scenes/Reports/components/CohortChart'
import CohortStub from 'scenes/Reports/components/CohortStub'
import Table from 'scenes/Reports/components/Table'
import { getBenchmarkRate } from 'scenes/Reports/helpers'
import { selectActivationMetricByType } from 'selectors/activationRateMetric'
import { useIsMobile } from 'utilities/responsive'
import {
  cohortSeriesFormatter,
  cohortTopAxisFormatter,
  filterRecentValues,
  getCohortSeriesData,
  getCohortYAxisOptions,
} from '../helpers'
import { Tabs } from '../index'
import Chart from './Chart'
import Counters from './Counters'
import ExplainerCard from './ExplainerCard'
import Row from './Table/Row'

type PropsT = {
  onSwitchTabAction: (tab: any) => void
}

const t = buildTranslate('activation_rate.tabs.activation_rate')

const Content: React.FC<PropsT> = ({ onSwitchTabAction }) => {
  const isMobile = useIsMobile()
  const merchant = useCurrentMerchant()
  const showCohortChart = useFlag('MX4-ActivationRateReport-R2', false)

  const benchmark = getBenchmarkRate(merchant?.merchant_type, 'activation_rate')

  const timeSeriesMetrics = useSelector(state =>
    selectActivationMetricByType(state, merchant?.id, ShortType.TIME_SERIES)
  ) as ActivationRateTimeSeriesMetric

  const cumulativeMetrics = useSelector(state =>
    selectActivationMetricByType(state, merchant?.id, ShortType.CUMULATIVE)
  ) as ActivationRateCumulativeMetric

  const cohortMetrics = useSelector(state =>
    selectActivationMetricByType(state, merchant?.id, ShortType.COHORTS)
  ) as ActivationRateCohortMetric

  const {
    isErrored: isTimeSeriesErrored,
    isLoading: isTimeSeriesLoading,
    timezone,
    granularity,
    values,
  } = timeSeriesMetrics ?? {}

  const {
    isErrored: isCumulativeErrored,
    isLoading: isCumulativeLoading,
    previous,
    current,
  } = cumulativeMetrics ?? {}

  const {
    isErrored: isCohortErrored,
    isLoading: isCohortLoading,
    values: cohortValues,
    granularity: cohortGranularity,
    timezone: cohortTimezone,
  } = cohortMetrics ?? {}

  const timeSeriesValues = (values ?? []).filter(filterRecentValues)
  const metricTypes = [ShortType.CUMULATIVE, ShortType.TIME_SERIES]
  if (showCohortChart) {
    metricTypes.push(ShortType.COHORTS)
  }

  return (
    <div className={isMobile ? 'mx-m' : 'mb-xl'}>
      <ExplainerCard />
      <div className="d-flex align-items-end w-100 flex-column mt-xl">
        <Button onClick={() => onSwitchTabAction(Tabs.ActivationFunnel)}>
          {t('explore_activation_funnel')}
        </Button>
      </div>
      <FetchableMetricSection
        className="mt-xl"
        type="activation_rate_metric"
        metricTypes={metricTypes}
        merchantId={merchant?.id}
        shouldFetch={!!merchant?.live_at}
      >
        <Text.Header3 className="mb-xs mt-0 mb-m" tag="div">
          {t('title')}
        </Text.Header3>
        <Counters
          isErrored={!!isCumulativeErrored}
          isLoading={!!isCumulativeLoading}
          currentRateData={current}
          previousRateData={previous}
          benchmark={benchmark}
          timezone={timezone}
        />
        <Chart
          isErrored={!!isTimeSeriesErrored}
          isLoading={!!isTimeSeriesLoading}
          granularity={granularity}
          values={timeSeriesValues}
          timezone={timezone}
          benchmark={benchmark}
        />
        {merchant && (
          <div className="mt-l">
            <Table
              isErrored={!!isTimeSeriesErrored}
              isLoading={!!isTimeSeriesLoading}
              values={timeSeriesValues}
              headers={['date_time', 'first_purchasers_count', 'rate']}
              headerClassNames={['w-50']}
              t={buildTranslate('activation_rate.tabs.activation_rate.table')}
              initialSortAttribute={'date_time'}
            >
              {Row}
            </Table>
          </div>
        )}
        <Switch
          condition={showCohortChart}
          fallback={
            <CohortStub
              title={t('cohort_chart.title')}
              subtitle={t('cohort_chart.sub_title')}
            />
          }
        >
          <CohortChart
            title={t('cohort_chart.title')}
            subtitle={t('cohort_chart.sub_title')}
            isErrored={!!isCohortErrored}
            isLoading={!!isCohortLoading}
            granularity={cohortGranularity}
            values={cohortValues}
            timezone={cohortTimezone}
            cohortSeriesFormatter={cohortSeriesFormatter}
            cohortTopAxisFormatter={cohortTopAxisFormatter}
            getCohortSeriesData={getCohortSeriesData}
            getCohortYAxisOptions={getCohortYAxisOptions}
          />
        </Switch>
      </FetchableMetricSection>
      <MtmEffectivenessBanner className="mt-xxxl" />
    </div>
  )
}

export default Content
