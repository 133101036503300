import React from 'react'

type Props = {
  className?: string
}

const DownloadIcon: React.FC<Props> = ({ className = '' }) => {
  return (
    <svg
      width="8"
      height="14"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7 4.75H6.15625L5.40625 5.5H7C7.125 5.5 7.25 5.625 7.25 5.75V7C7.25 7.14062 7.125 7.25 7 7.25H1C0.859375 7.25 0.75 7.14062 0.75 7V5.75C0.75 5.625 0.859375 5.5 1 5.5H2.57812L1.82812 4.75H1C0.4375 4.75 0 5.20312 0 5.75V7C0 7.5625 0.4375 8 1 8H7C7.54688 8 8 7.5625 8 7V5.75C8 5.20312 7.54688 4.75 7 4.75ZM6.75 6.375C6.75 6.17188 6.57812 6 6.375 6C6.15625 6 6 6.17188 6 6.375C6 6.59375 6.15625 6.75 6.375 6.75C6.57812 6.75 6.75 6.59375 6.75 6.375ZM3.73438 5.76562C3.79688 5.84375 3.89062 5.875 4 5.875C4.09375 5.875 4.1875 5.84375 4.25 5.76562L6.375 3.64062C6.53125 3.5 6.53125 3.26562 6.375 3.125C6.23438 2.96875 6 2.96875 5.85938 3.125L4.375 4.60938V0.375C4.375 0.171875 4.20312 0 4 0C3.78125 0 3.625 0.171875 3.625 0.375V4.60938L2.125 3.10938C1.96875 2.96875 1.73438 2.96875 1.59375 3.10938C1.4375 3.26562 1.4375 3.5 1.59375 3.64062L3.73438 5.76562Z"
        fill="#36424E"
      />
    </svg>
  )
}

export default DownloadIcon
