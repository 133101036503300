import { Button, Kind } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React from 'react'

type PropsT = {
  type: 'prev' | 'next'
  isDisabled: boolean
  onClick: VoidFunction
}

const t = buildTranslate('general.buttons')

const PrevNextButton: React.FC<PropsT> = ({
  isDisabled,
  onClick,
  type,
}: PropsT) => {
  const [css] = useStyletron()
  const iconColor = isDisabled ? 'grey-50' : 'grey-90'

  return (
    <Button
      kind={Kind.MINIMAL}
      disabled={isDisabled}
      onClick={onClick}
      className={css({
        color: palette.grey90,
        ':hover:enabled': {
          backgroundColor: `${palette.grey10} !important`,
          color: `${palette.grey90} !important`,
        },
      })}
    >
      {type === 'prev' && (
        <i className={`fa fa-chevron-left ${iconColor} mr-s`} />
      )}
      {t(type)}
      {type === 'next' && (
        <i className={`fa fa-chevron-right ${iconColor} ml-s`} />
      )}
    </Button>
  )
}

export default PrevNextButton
