import { Text } from '@thanx/uikit/text'
import React from 'react'

type Props = {
  title?: string
  children: React.ReactNode
  footer?: string
}

function Card({ children, title, footer }: Props) {
  const className = footer ? '' : 'mb-xl'
  return (
    <>
      {title && (
        <Text.Header3 bold className="mb-s mt-0">
          {title}
        </Text.Header3>
      )}
      <div className={`card ${className}`}>
        <div className="card-body">{children}</div>
      </div>

      {footer && (
        <div className="mb-xl">
          <Text.BodyText5 color="grey70">{footer}</Text.BodyText5>
        </div>
      )}
    </>
  )
}

export default Card
