import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ReportAccessStateT } from '../helpers'

const t = buildTranslate('points.effective_discount_rate.empty_state')

type PropsT = {
  remainingDays: ReportAccessStateT
  className?: string
}

const EmptyState: React.FC<PropsT> = ({ remainingDays, className }) => {
  const emptyStateMessage = (() => {
    if (remainingDays === 'points_inactive') return t('points_inactive')

    if (remainingDays === 'coming_soon') return t('coming_soon')

    return t('available_in_x_days', { remainingDays })
  })()

  return (
    <div className={className}>
      <section className="mt-xl">
        <Row className="section">
          <Col sm={12}>
            <Text.Header4 bold className="text-center mb-xs">
              {t('title')}
            </Text.Header4>
          </Col>
        </Row>
        <Row className="section justify-content-sm-center">
          <Col className="col-content text-center" sm={7}>
            <Text.BodyText3 color="grey70">{emptyStateMessage}</Text.BodyText3>
          </Col>
        </Row>
      </section>
    </div>
  )
}

export default EmptyState
