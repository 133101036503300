import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import FetchableMetricSection from 'components/FetchableMetricSection'
import { CampaignStateT, MinimalVariant } from 'models/Campaign'
import { MetricTypes } from 'models/CampaignMetric'
import React from 'react'
import Attribution from 'scenes/CampaignCenter/Report/v3/Summary/components/Attribution'
import HeaderRow from '../components/HeaderRow'

type Props = {
  title: string
  attributionWindow?: number
  attributionTooltip?: string
  subtitle?: string
  subsection?: boolean
  className?: string
  children?: any
  campaignId: number
  metricTypes: MetricTypes
  campaignState: CampaignStateT
  variants: MinimalVariant[]
  winningVariantId: number | null
  headerLeft?: React.ReactNode
}
const Section: React.FC<Props> = props => {
  const {
    campaignId,
    title,
    attributionWindow,
    attributionTooltip,
    headerLeft,
    subtitle,
    subsection = false,
    className = '',
    children,
    metricTypes,
    campaignState,
    variants,
    winningVariantId,
  } = props

  const [css] = useStyletron()
  const HeaderComponent = subsection ? Text.Header4 : Text.Header3
  const variantIds = variants.map(v => v.id)

  return (
    <FetchableMetricSection
      type="campaign_metric"
      className={`mb-xxl ${className} ${css({
        overflowX: 'auto',
      })}`}
      campaignId={campaignId}
      metricTypes={metricTypes}
      shouldFetch={['active', 'complete'].includes(campaignState)}
      variantIds={variantIds}
      attributionWindow={attributionWindow}
    >
      <div className="mb-0">
        <HeaderComponent tag="p" className="mb-0" bold>
          {title}
        </HeaderComponent>
        <Switch condition={!!attributionWindow && !!attributionTooltip}>
          <Attribution
            attributionWindow={attributionWindow}
            tooltipText={attributionTooltip!}
          />
        </Switch>
        <Switch condition={!!subtitle}>
          <Text.BodyText3 tag="p" className="mb-m mt-xs" color="grey70">
            {subtitle}
          </Text.BodyText3>
        </Switch>
      </div>
      <HeaderRow
        title={headerLeft}
        variants={variants}
        winningVariantId={winningVariantId}
      />
      {children}
    </FetchableMetricSection>
  )
}

export default Section
