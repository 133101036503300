import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { Order, ordersApi } from 'api/orders'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useState } from 'react'
import OrderDetailsModal from './OrderDetailsModal'
import Table from './Table'

const t = buildTranslate('users.view.activity.orders')

type PropsT = {
  profile: UserProfile
}

const Orders: React.FC<PropsT> = ({ profile }: PropsT) => {
  const [selectedOrder, setSelectedOrder] = useState<Order>()
  const { data, isLoading, isFetching } = ordersApi.useGetOrdersQuery(
    profile.id
  )

  function handleDetailsClick(order: Order) {
    setSelectedOrder(order)
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <Text.Header4 className={'mb-m'}>{t('title')}</Text.Header4>
      </div>
      <Spinner
        className="d-flex justify-content-center"
        size="3x"
        isLoading={isLoading || isFetching}
      >
        <Switch
          condition={!!(data && data.orders.length > 0)}
          fallback={
            <Text.BodyText3 color="grey70">{t('empty_state')}</Text.BodyText3>
          }
        >
          <Table
            orders={data?.orders || []}
            onDetailsClick={handleDetailsClick}
          />
        </Switch>
      </Spinner>
      {!!selectedOrder && (
        <OrderDetailsModal
          order={selectedOrder}
          onHide={() => setSelectedOrder(undefined)}
        />
      )}
    </div>
  )
}

export default Orders
