import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import InvitationLayout from './InvitationLayout'

const t = buildTranslate('merchant_users.invitation.success')

const InvitationSuccess = () => {
  const dispatch = useDispatch()

  return (
    <InvitationLayout>
      <div className="mb-l text-center">
        <Text.Header3>{t('title')}</Text.Header3>
        <Text.BodyText3 color="grey70">{t('description')}</Text.BodyText3>
      </div>
      <Button
        type="button"
        kind={Kind.PRIMARY}
        className="btn-block mt-s"
        onClick={() => {
          dispatch(push('/auth/login'))
        }}
      >
        {t('back')}
      </Button>
    </InvitationLayout>
  )
}

export default InvitationSuccess
