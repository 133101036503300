export default {
  title: 'Activation',
  subtitle:
    'Encourage new customers to make their third purchase as quickly as possible.',
  activation_rate: 'Activation rate',
  tabs: {
    activation_rate: {
      title: 'Activation rate',
      page_title: 'Activation rate - Activation | Thanx',
      explainer_card: {
        title: 'Activate new customers',
        description:
          'Customers that make their 3rd purchase will keep coming back on their own. These regular customers don’t need to be incentivized with discounts and require less effort from your marketing team.',
        video_link: {
          title: 'Watch',
          description: 'How to activate new customers',
        },
        activation_rate_101: 'Activation rate 101',
      },
      explore_activation_funnel: 'Explore the activation funnel',
      chart: {
        title: 'Activation rate over time',
        x_axis: 'Date of 1st purchase',
        y_axis: 'Activation Rate',
        industry_benchmark: 'Industry benchmark',
        tooltip: {
          first_purchase: 'Customers made their 1st purchase',
          third_purchase: 'Customers made their 3rd purchase within 120 days',
        },
      },
      popovers: {
        example: 'Example',
        how_calculated: 'How it’s calculated',
        what_this_means: 'What this means',
        in_given_month: 'a given month',
        sample_month: 'July',
        sample_first_purchase_count: '1,000',
        sample_third_purchase_count: '300',
        sample_activation_rate: '30%',
        activation_rate: [
          'The percentage of customers that made their 1st purchase in %{dateLabel} and then went on to make a 3rd purchase within 120 days.',
          'Count of customers that made 3rd purchase / Count of customers that made 1st purchase',
          '%{firstPurchaseCount} customers made their 1st purchase in %{dateLabel}. %{thirdPurchaseCount} of those customers went on to make their 3rd purchase within 120 days. The activation rate for %{dateLabel} is %{activationRate}.',
        ],
        first_purchase: [
          'Customers that made their 1st purchase in the most recent month 120 days before today.',
          '%{firstPurchaseCount} customers made their 1st purchase in %{dateLabel}.',
          'Customers that made their 1st purchase during this month.',
        ],
        third_purchase: [
          'Customers that made their 3rd purchase within 120 days of making their 1st purchase.',
          '%{firstPurchaseCount} customers made their 1st purchase in %{dateLabel}. %{thirdPurchaseCount} of those customers went on to make their 3rd purchase within 120 days.',
        ],
      },
      counters: {
        first_purchase: 'Customers that made their 1st purchase',
        third_purchase: 'Customers that made their 3rd purchase',
        industry_benchmark: 'Industry benchmark is %{rate}',
      },
      table: {
        date_time: 'Month',
        first_purchasers_count: 'Made 1st purchase',
        rate: 'Activation rate',
      },
      cohort_chart: {
        title: 'How new customers activate',
        sub_title:
          'The percentage of new customers that make a 3rd purchase and the amount of time they take to do so.',
        coming_soon: 'Coming soon',
        made_first_purchase: 'Made 1st purchase',
        days: '%{days} days',
      },
    },
    activation_funnel: {
      title: 'Activation funnel',
      page_title: 'Activation funnel - Activation | Thanx',
    },
  },
}
