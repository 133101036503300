const ccpaoptout = {
  title: 'CCPA Tracking Opt-Out Guide',
  services:
    'We use services provided by third parties that collect device data and online activity data from individuals who visit our website or use the app to analyze website traffic and facilitate advertising, such as Google and Facebook.',
  number:
    'There are a number of ways to opt-out, which we have summarized below.',
  changing:
    'Changing or Deleting Your Information. All Members may review, update, correct or delete the personal information accessible from their accounts by contacting us. If you request to delete all such information or information we designate as mandatory, then your account may become deactivated. If you would like us to delete your account in our system, please contact us at <a href="mailto:support@thanx.com">support@thanx.com</a>. We will use commercially reasonable efforts to honor your request. We may retain an archived copy of your records as permitted by law.',
  blocking_cookies:
    'Blocking cookies in your browser. Most browsers let you remove or reject cookies, including cookies used for interest-based advertising. To do this, follow the instructions in your browser settings. Many browsers accept cookies by default until you change your settings. See more at <a href="https://www.allaboutcookies.org">www.allaboutcookies.org. </a>',
  blocking_ads:
    'Blocking advertising ID use in your mobile settings. Your mobile device settings may provide functionality to limit use of the advertising ID associated with your mobile device for interest-based advertising purposes.',
  privacy:
    'Using privacy plug-ins or browsers. You can block our site from setting cookies used for interest-based ads by using a browser with privacy features, like <a href="https://www.brave.com">Brave </a>, or installing browser plugins, like <a href="https://www.eff.org/privacybadger">Privacy Badger</a>, <a href="https://www.ghostery.com/">Ghostery </a> or <a href="https://ublock.org/">uBlock Origin</a>, and configuring them to block third party cookies/trackers.',
  platform:
    'Platform opt-outs. Google and Facebook offer opt-out features that let you opt-out of use of your information for interest-based advertising. We have included the applicable links below to facilitate this election if you choose to do so:',
  google:
    'Google: <a href="https://adssettings.google.com">https://adssettings.google.com </a>',
  facebook:
    'Facebook: <a href="https://www.facebook.com/about/ads">https://www.facebook.com/about/ads </a>',
  advertising:
    'Advertising industry opt-out tools. You can also use these opt-out options to limit use of your information for interest-based advertising by participating companies:',
  digital:
    'Digital Advertising Alliance: <a href="http://optout.aboutads.info">http://optout.aboutads.info',
  network:
    'Network Advertising Initiative: <a href="http://optout.networkadvertising.org/?c=1">http://optout.networkadvertising.org/?c=1 </a>',
  last_updated: 'This Notice was last updated: December 16, 2021',
  copyright: '%{year} Thanx',
}

export default ccpaoptout
