import { buildTranslate } from 'locales'
import type { AvgRedemptionDaysData } from 'models/MerchantMetric'
import React from 'react'
import { Table } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import MetricSummary from 'scenes/Reports/ActivityReport/components/MetricSummary'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate('reports.activity.sections.rewards.metrics.redemption')

type Props = {
  averageDiscountAmount: number | string
  averageRedemptionDays: AvgRedemptionDaysData
}

export const Redemption: React.FC<Props> = ({
  averageDiscountAmount,
  averageRedemptionDays,
}) => {
  const dayUnit = t('units')

  const loyaltyRow = () => {
    if (!averageRedemptionDays.earn_loyalty) return null
    return (
      <tr>
        <td>{t('buckets.loyalty')}</td>
        <td className="text-right">{`${I18n.l(
          averageRedemptionDays.earn_loyalty
        )} ${dayUnit}`}</td>
      </tr>
    )
  }

  const otherText = () => {
    return averageRedemptionDays.earn_loyalty
      ? t('buckets.other')
      : t('buckets.all')
  }

  return (
    <div>
      <MetricSummary
        title={t('title')}
        summaryValue={currencyFormatter(averageDiscountAmount)}
        summaryText={t('summary_text')}
      />
      <div>
        <span className="bold">{t('bucket_text')}</span>
      </div>
      <div>
        <Table className="bucket-table" responsive>
          <tbody>
            {loyaltyRow()}
            <tr>
              <td>{otherText()}</td>
              <td className="text-right">{`${I18n.l(
                averageRedemptionDays.other_programs
              )} ${dayUnit}`}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default Redemption
