export default {
  rename: {
    button: 'Rename variant',
    title: 'Rename variant %{letter}',
    subtitle:
      'How this variant will appear in your reports. Customers won’t see this. (e.g., Has $5 reward)',
    apply: 'Apply name',
    label: 'Variant name',
    placeholder: 'What do you want to call this variant?',
    chars_remaining: '%{count} character remaining',
    chars_remaining_plural: '%{count} characters remaining',
  },
  delete: {
    title: 'Delete variant %{letter}',
    subtitle: 'Your campaign will no longer be an A/B test.',
    subtitle_2: 'You will lose any content configured in this variant.',
    apply: 'Delete variant',
    button: 'Delete variant',
    one_required: 'At least one variant is required',
    okay: 'Okay',
    delete_control:
      'If you no longer want to run an A/B test, delete the control group instead.',
    learn_more: 'Learn more',
  },
  choices: {
    run_ab: {
      title: 'Run an A/B test',
      content:
        'Find out if this campaign changed customer behavior. Learn which subject lines, rewards, and content convert more customers.',
      multi_variant_content:
        'You can test up to 4 variants or even add a control group.',
      multi_variant_link: 'How it works',
    },
    no_test: {
      title: 'Don’t test',
      content:
        'Send your campaign without testing if you already have confidence that it works.',
    },
  },
  deletion_modal: {
    title: 'Which variant do you want to keep?',
    content:
      'Select the variant you want to keep to make this a regular campaign instead of an A/B test.',
    keep_variant: 'Keep variant %{name}',
    buttons: {
      cancel: 'Cancel',
      keep: 'Keep this variant',
    },
  },
  cancel: 'Cancel',
  control_group: 'Control group',
  control_group_description:
    'Customers assigned to this variant will not receive a message or reward. They act as a control group allowing you to measure the impact of your marketing.',
  learn_more: 'Learn more',
}
