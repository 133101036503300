import React from 'react'
import Helmet from 'react-helmet'
import { useIsUserTabbing } from 'utilities/isUserTabbing'
import { createTabName } from 'utilities/pages'

const UserTabbing: React.FC = () => {
  // Display focus outlines only when the keyboard tab is pressed
  const isUserTabbing = useIsUserTabbing()
  const tabTitle = createTabName()

  return (
    <Helmet defaultTitle={tabTitle}>
      {!isUserTabbing && (
        <style>{`
            button:focus,
            input:focus,
            select:focus,
            textarea:focus,
            span,
            label,
            a:focus {
              outline: none !important;
            }
          `}</style>
      )}
    </Helmet>
  )
}

export default UserTabbing
