import { Switch } from '@thanx/uikit/switch'
import navFooter from 'assets/images/campaign-center/app_bottom_nav.jpg'
import statusBar from 'assets/images/campaign-center/status_bar.png'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import IntroReward from './IntroReward'
import LoyaltyReward from './LoyaltyReward'

type Props = {
  campaign: Campaign
}
const Phone: React.FC<Props> = props => {
  const { campaign } = props
  const { cover_image_urls, name, logo_image_urls } = useCurrentMerchant() || {}

  return (
    <div className="padding-huge padding-top-none">
      <div className="phone-bkg">
        <div className="incentive">
          <div
            className="cover-img"
            style={{
              backgroundImage: `url(${cover_image_urls?.default})`,
            }}
          >
            <div className="gradient text-center">
              <img
                src={statusBar}
                alt="status bar"
                className="full-width margin-bottom-medium"
              />
              <p className="white margin-bottom-large">{name}</p>
              <img src={logo_image_urls?.default} alt="logo" className="logo" />
            </div>
          </div>
          <div className="white-bkg rewards relative">
            <Row className="padding-top-medium padding-bottom-small text-center small-caps-6 extra-bold grey-70 padding-left-medium padding-right-medium border-bottom-1 grey-30-border margin-left-none margin-right-none">
              <Col xs={4} className="spearmint-50">
                <Translate
                  value="thanx_campaigns.builder.previews.incentive.nav.rewards"
                  className="inline-block padding-bottom-tiny border-bottom-2 spearmint-50-border"
                />
              </Col>
              <Col xs={4}>
                <Translate value="thanx_campaigns.builder.previews.incentive.nav.locations" />
              </Col>
              <Col xs={4}>
                <Translate value="thanx_campaigns.builder.previews.incentive.nav.more" />
              </Col>
            </Row>
            <Switch
              condition={campaign.type === 'loyalty'}
              fallback={<IntroReward campaign={campaign} />}
            >
              <LoyaltyReward campaign={campaign} />
            </Switch>
            <img
              src={navFooter}
              alt="nav"
              className="absolute bottom-0 full-width"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Phone
