import {
  createLandingPage,
  getLandingPage,
  GET_LANDING_PAGE_SUCCESS,
} from 'actions/landingPage'
import Spinner from 'components/Spinner'
import InnerTabs, { TabT } from 'components/Tabs/InnerTabs'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import { useAppHomePage } from './AppHomePage/hooks'
import AlertBanner from './Banners/AlertBanner'
import { useBanners } from './Banners/hooks'
import ContentBlocks from './ContentBlocks'
import CustomLinks from './CustomLinks'
import FixedButtons from './FixedButtons'
import HeroCarousel from './HeroCarousel'
import { useMenuCategory } from './MenuCategory/hooks'
import PreferencesModalSetting from './PreferencesModalSetting'
import WebHeroImage from './WebHeroImage'

export enum Tabs {
  MenuCategory = 'menu_category',
  HeroCarousel = 'hero_carousel',
  ContentBlocks = 'content_blocks',
  FixedButtons = 'fixed_buttons',
  WebHeroImage = 'web_hero_image',
  AlertBanner = 'alert_banner',
  CustomLinks = 'custom_links',
  PreferencesModalSetting = 'preferences_modal_setting',
}

const t = buildTranslate('cms')

const Content: React.FC = () => {
  const { app_ordering_enabled, branded_app } = useCurrentMerchant() || {}
  const app = useSelector(selectApp)
  const brandedAppAvailable = app?.allow_downloads && branded_app
  const appWithOrdering = app_ordering_enabled && brandedAppAvailable
  const [isLoading, setIsLoading] = useState(!appWithOrdering)
  const preferencesModalSettingEnabled = useFlag(
    'preferences-modal-setting-enabled',
    false
  )

  const { tab: appHomePageTab } = useAppHomePage()
  const { tab: menuCategoryTab } = useMenuCategory()
  const { tab: bannersTabs } = useBanners()

  const dispatch = useDispatch()
  const appId = app?.id

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(getLandingPage(appId))
      if (response.type !== GET_LANDING_PAGE_SUCCESS) {
        await dispatch(createLandingPage(appId))
      }
      setIsLoading(false)
    }

    if (!appWithOrdering && appId) {
      fetchData()
    }
  }, [dispatch, appWithOrdering, appId])

  const appHomepageTabs = (): Array<TabT> => {
    if (appWithOrdering) {
      return appHomePageTab
    } else if (brandedAppAvailable) {
      return [
        {
          // using the first sub tab key here to make it active
          // by default when this is selected
          key: Tabs.HeroCarousel,
          title: t('landing.tabs.home_page'),
          subtabs: [
            {
              key: Tabs.HeroCarousel,
              title: t('content.hero_carousel.tab_title'),
              component: (
                <Spinner
                  className="text-center margin-top-huge"
                  size="4x"
                  isLoading={isLoading}
                >
                  <HeroCarousel />
                </Spinner>
              ),
            },
            {
              key: Tabs.ContentBlocks,
              title: t('content.content_blocks.tab_title'),
              component: (
                <Spinner
                  className="text-center margin-top-huge"
                  size="4x"
                  isLoading={isLoading}
                >
                  <ContentBlocks />
                </Spinner>
              ),
            },
            {
              key: Tabs.FixedButtons,
              title: t('content.fixed_buttons.tab_title'),
              component: (
                <Spinner
                  className="text-center margin-top-huge"
                  size="4x"
                  isLoading={isLoading}
                >
                  <FixedButtons />
                </Spinner>
              ),
            },
          ],
        },
      ]
    } else {
      return []
    }
  }

  const bannerTabs = () => {
    if (appWithOrdering) {
      return bannersTabs
    }
    return {
      key: Tabs.AlertBanner,
      component: <AlertBanner />,
      title: t('content.alert_banner.title'),
    }
  }

  const preferencesModalSettingTab = (): TabT[] => {
    if (preferencesModalSettingEnabled) {
      return [
        {
          key: Tabs.PreferencesModalSetting,
          component: <PreferencesModalSetting />,
          title: t('content.preferences_modal_setting.title'),
        },
      ]
    }
    return []
  }

  return (
    <InnerTabs
      tabs={[
        ...appHomepageTabs(),
        menuCategoryTab,
        {
          key: Tabs.WebHeroImage,
          component: <WebHeroImage />,
          title: t('content.web_hero_image.title'),
        },
        bannerTabs(),
        ...preferencesModalSettingTab(),
        ...(brandedAppAvailable
          ? [
              {
                key: Tabs.CustomLinks,
                component: <CustomLinks />,
                title: t('content.custom_links.title'),
              },
            ]
          : []),
      ]}
    />
  )
}

export default Content
