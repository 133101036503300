import { Notification } from '@thanx/uikit/notification'
import React from 'react'
import Checkmark from './Icons/Checkmark'
import Information from './Icons/Information'

type Props = {
  text: string
  isLoading?: boolean
  isError?: boolean
  isSuccess?: boolean
  className?: string
}

const ProgressCard: React.FC<Props> = props => {
  const { className, text, isError, isLoading, isSuccess } = props
  if (!isError && !isLoading && !isSuccess) {
    return null
  }

  let icon
  let kind
  if (isError) {
    icon = <Information type="error" />
    kind = 'negative'
  } else if (isSuccess) {
    icon = <Checkmark />
    kind = 'positive'
  } else if (isLoading) {
    icon = <Information type="info" />
    kind = 'info'
  }

  return (
    <div className={className}>
      <Notification kind={kind} size="full">
        <div className="d-flex align-items-middle">
          <span className="mr-s">{icon}</span>
          {text}
        </div>
      </Notification>
    </div>
  )
}

export default ProgressCard
