import { Text } from '@thanx/uikit/text'
import React from 'react'

type Props = {
  children: string
  tag?: keyof JSX.IntrinsicElements
}

function Body3Html({ children, tag }: Props) {
  return (
    <Text.BodyText3 tag={tag}>
      <span dangerouslySetInnerHTML={{ __html: children }} />
    </Text.BodyText3>
  )
}

export default Body3Html
