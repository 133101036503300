export default {
  title: 'Create a new campaign',
  get_started: 'Get started',
  start_draft: 'Start new draft',
  cancel: 'Cancel',
  error_creating_campaign:
    'There was an error creating your campaign. Please try again.',
  targeted: {
    title: 'Targeted campaign',
    description: 'Send a campaign once to a specific segment of customers',
  },
  message: {
    title: 'Message to everyone',
    description: 'Send a campaign once to everyone',
  },
  automated: {
    title: 'Automated campaign',
    description:
      'Set up an ongoing campaign that will send automatically when a customer enters a segment',
  },
  learn_more: 'Learn more',
  reach: {
    details: '%{count} customers currently in this segment',
    error: 'Unable to fetch target segment count',
    fetching: 'Fetching customer count...',
  },
}
