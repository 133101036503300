import { defaultTheme } from '@thanx/uikit/theme'
import React from 'react'

type PropsT = {
  color?: string
}

const FlagIcon: React.FC<PropsT> = ({
  color = defaultTheme.colors.primary,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.47714 6.47714 2 12 2C17.5229 2 22 6.47714 22 12C22 17.5229 17.5229 22 12 22C6.47714 22 2 17.5229 2 12ZM20.0645 11.9999C20.0645 7.54232 16.4569 3.93542 12 3.93542C7.54238 3.93542 3.93549 7.54301 3.93549 11.9999C3.93549 16.4576 7.54307 20.0645 12 20.0645C16.4576 20.0645 20.0645 16.4569 20.0645 11.9999Z"
        fill={color}
      />
      <path
        d="M13.8359 9.18359C14.4609 9.18359 15.2227 8.98828 16.1211 8.55859C16.3164 8.48047 16.5117 8.48047 16.707 8.59766C16.9023 8.71484 17 8.89062 17 9.125V13.8711C17 14.1055 16.9023 14.2617 16.7266 14.3789C15.9453 14.9258 15.1641 15.1797 14.3438 15.1797C14.0312 15.1797 13.6992 15.1406 13.3672 15.0625C13.1719 15.0234 12.8789 14.9453 12.5273 14.8086C12.2148 14.7109 11.9805 14.6328 11.8242 14.5938C11.5508 14.5352 11.3164 14.4961 11.1016 14.4961C10.2812 14.4961 9.53906 14.6523 8.875 14.9453V16.7812C8.875 16.918 8.81641 17.0352 8.73828 17.1133C8.64062 17.2109 8.52344 17.25 8.40625 17.25H8.09375C7.95703 17.25 7.83984 17.2109 7.76172 17.1133C7.66406 17.0352 7.625 16.918 7.625 16.7812V9.24219C7.46875 9.14453 7.35156 9.00781 7.27344 8.85156C7.19531 8.69531 7.15625 8.53906 7.15625 8.34375C7.15625 8.03125 7.25391 7.77734 7.48828 7.5625C7.70312 7.34766 7.97656 7.25 8.28906 7.25C8.5625 7.26953 8.81641 7.36719 9.01172 7.5625C9.20703 7.75781 9.32422 7.99219 9.34375 8.26562C9.34375 8.44141 9.32422 8.59766 9.26562 8.73438C9.69531 8.57812 10.125 8.5 10.5938 8.5C10.9062 8.5 11.2188 8.53906 11.5703 8.61719C11.7656 8.67578 12.0391 8.75391 12.4102 8.87109C12.7227 8.98828 12.957 9.04688 13.1133 9.08594C13.3672 9.16406 13.6016 9.18359 13.8359 9.18359Z"
        fill={color}
      />
    </svg>
  )
}

export default FlagIcon
