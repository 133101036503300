import contentPreview from 'assets/images/cms/banners/placed_order_banner_preview.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import CmsPage from 'scenes/Cms/components/CmsPage'
import BannerForm from 'scenes/Cms/Content/Banners/components/BannerForm'
import Footer from 'scenes/Cms/Content/Banners/components/Footer'
import Sidebar from 'scenes/Cms/Content/Banners/components/Sidebar'
import { FormModel } from '../hooks'

const t = buildTranslate('cms.content.banners.order_placed_banner')

type Props = {
  data: FormModel
  isLoading: boolean
  isFetching: boolean
}

const OrderPlacedBanner = ({ data, isFetching, isLoading }: Props) => {
  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      badges={['APP']}
      sidebar={
        <Sidebar
          image={contentPreview}
          isLoading={isFetching}
          disabled={isFetching || isLoading}
        />
      }
    >
      <BannerForm
        type="order_placed_banner"
        data={data}
        isLoading={isLoading}
      />
      <Footer isLoading={isFetching} disabled={isFetching || isLoading} />
    </CmsPage>
  )
}

export default OrderPlacedBanner
