import withSteps from 'components/withSteps'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import View from './View'

type Props = {
  campaign: Campaign
  isSaving: boolean
  canProceed: boolean
  triggerSave: () => Promise<void>
  setCurrentStep: (step: string) => void
  showValidationErrors: () => void
  nextStep: () => void
  currentStepName: string
}

const Setup: React.FC<Props> = props => {
  const {
    campaign,
    isSaving,
    canProceed,
    triggerSave,
    setCurrentStep,
    showValidationErrors,
    nextStep,
    currentStepName,
  } = props
  return (
    <View
      campaign={campaign}
      isSaving={isSaving}
      canProceed={canProceed}
      triggerSave={triggerSave}
      setCurrentStep={setCurrentStep}
      setNextSubstep={nextStep}
      currentSubstep={currentStepName}
      showValidationErrors={showValidationErrors}
    />
  )
}

export default withSteps(['audience', 'incentive', 'messaging'])(Setup)
