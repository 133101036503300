import { Notification } from '@thanx/uikit/notification'
import { defaultTheme } from '@thanx/uikit/theme'
import InfoIcon from 'components/InfoIcon'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import moment from 'moment-timezone'
import React from 'react'
import { getFormattedDate } from 'utilities/date'
import { useUserTimeZone } from 'utilities/userTimeZone'
import Scheduled from './Scheduled'
import TestActive from './TestActive'
import TestEnded from './TestEnded'

const t = buildTranslate('thanx_campaigns.report.v3.test')
const tDate = buildTranslate('dateFormat')

type Props = {
  campaign: Campaign
}
const Header: React.FC<Props> = ({ campaign }) => {
  const {
    id,
    end_at,
    start_at,
    state,
    test_ended_at,
    winning_variant_id,
    variants,
  } = campaign
  const timezone = useUserTimeZone()
  const dateFormat = 'hh:mma z'
  const startMoment = moment(start_at).tz(timezone)
  const endMoment = moment(test_ended_at || end_at).tz(timezone)
  const startDate = tDate('dateAtTime', {
    date: getFormattedDate(startMoment, 'daily'),
    time: startMoment.format(dateFormat),
  })
  const endDate = tDate('dateAtTime', {
    date: getFormattedDate(endMoment, 'daily'),
    time: endMoment.format(dateFormat),
  })
  let content
  if (state === 'complete' || !!winning_variant_id) {
    let winningText: string
    if (!winning_variant_id) {
      winningText = t('no_variant')
    } else {
      const winningVariant = variants.find(
        variant => variant.id === winning_variant_id
      )
      const name = winningVariant?.meta?.name
      winningText = t('winning_variant', { name })
    }
    content = (
      <TestEnded
        endDate={endDate}
        winningText={winningText}
        startDate={startDate}
      />
    )
  } else if (state === 'active') {
    content = <TestActive campaignId={id} variants={variants} />
  } else if (state === 'scheduled') {
    content = <Scheduled startDate={startDate} />
  }

  return (
    <Notification
      kind="info"
      size="full"
      overrides={{
        Body: {
          style: {
            width: 'auto',
            marginTop: '0px',
            padding: '24px',
            marginBottom: '48px',
          },
        },
        InnerContainer: {
          style: { flexGrow: 1, lineHeight: '20px' },
        },
      }}
    >
      <div className="d-flex">
        <div className="mr-s">
          <InfoIcon color={defaultTheme.colors.primary} />
        </div>
        <div className="flex-1 mt-xxs">{content}</div>
      </div>
    </Notification>
  )
}

export default Header
