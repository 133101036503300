import ExplainerBanner from 'components/ExplainerBanner'
import { buildTranslate } from 'locales'
import React from 'react'
import { getHowTo } from './helpers'

const t = buildTranslate('redeem_manager.builder.steps.configuration.how_to')

type Props = {
  className?: string
  type: 'appearance' | 'instore' | 'hiddenMenu'
  imageLink: string
  videoLink: string
}

const HowTo: React.FC<Props> = ({
  className = '',
  imageLink,
  type,
  videoLink,
}) => {
  const image = getHowTo[type]
  return (
    <ExplainerBanner
      imageLink={imageLink}
      videoLink={videoLink}
      title={t('title')}
      image={image}
      type={type}
      className={className}
    />
  )
}

export default HowTo
