import { Fields } from 'models/PointsExperienceMetric'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

export const GET_CUMULATIVE_CAPTURE_RATE_METRICS =
  'capture_rate_metrics/GET_CUMULATIVE'
export const GET_CUMULATIVE_CAPTURE_RATE_METRICS_SUCCESS =
  'capture_rate_metrics/GET_CUMULATIVE_SUCCESS'
export const GET_CUMULATIVE_CAPTURE_RATE_METRICS_FAIL =
  'capture_rate_metrics/GET_CUMULATIVE_FAIL'
export const GET_TIME_SERIES_CAPTURE_RATE_METRICS =
  'capture_rate_metrics/GET_TIME_SERIES'
export const GET_TIME_SERIES_CAPTURE_RATE_METRICS_SUCCESS =
  'capture_rate_metrics/GET_TIME_SERIES_SUCCESS'
export const GET_TIME_SERIES_CAPTURE_RATE_METRICS_FAIL =
  'capture_rate_metrics/GET_TIME_SERIES_FAIL'

export type GetCumulativeAction = AxiosAction<
  typeof GET_CUMULATIVE_CAPTURE_RATE_METRICS
>

type GetCumulativeResolvedAction =
  | FailureAction<typeof GET_CUMULATIVE_CAPTURE_RATE_METRICS_FAIL>
  | SuccessAction<
      typeof GET_CUMULATIVE_CAPTURE_RATE_METRICS_SUCCESS,
      { metric: Fields },
      GetCumulativeAction
    >

export function getCumulativeCaptureRateMetrics(
  params: {} = {}
): GetCumulativeAction {
  return {
    type: GET_CUMULATIVE_CAPTURE_RATE_METRICS,
    payload: {
      client: 'default',
      request: {
        url: `capture_rate/cumulative`,
        method: 'get',
        params,
      },
    },
  }
}

export type GetTimeSeriesAction = AxiosAction<
  typeof GET_TIME_SERIES_CAPTURE_RATE_METRICS
>

type GetTimeSeriesResolvedAction =
  | FailureAction<typeof GET_TIME_SERIES_CAPTURE_RATE_METRICS_FAIL>
  | SuccessAction<
      typeof GET_TIME_SERIES_CAPTURE_RATE_METRICS_SUCCESS,
      { metric: Fields },
      GetCumulativeAction
    >

export function getTimeSeriesCaptureRateMetrics(
  params: {} = {}
): GetTimeSeriesAction {
  return {
    type: GET_TIME_SERIES_CAPTURE_RATE_METRICS,
    payload: {
      client: 'default',
      request: {
        url: `capture_rate/time_series`,
        method: 'get',
        params,
      },
    },
  }
}

export type ResolvedAction =
  | GetCumulativeResolvedAction
  | GetTimeSeriesResolvedAction
