import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import ProgressTooltip from './ProgressTooltip'

type PropsT = {
  color: string
  bgColor?: string
  height?: string
  value?: number | null
  className?: string
  labels?: number[]
  tooltipContent?: React.ReactNode
}

const ProgressBar: React.FC<PropsT> = props => {
  const {
    color,
    bgColor = 'none',
    height = '8px',
    value,
    className = '',
    labels,
    tooltipContent,
  } = props
  const [css] = useStyletron()
  const maxValue = labels ? Math.max(...labels) : 100
  const progress = Math.min(value ? (value / maxValue) * 100 : 0, 100)

  return (
    <div className="position-relative">
      <div
        className={`progress mb-xs ${css({
          height,
          backgroundColor: bgColor,
        })} ${className}`}
      >
        {!!value && (
          <ProgressTooltip progress={progress}>
            {tooltipContent}
          </ProgressTooltip>
        )}
        <div
          className={`progress-bar ${css({
            width: `${progress}%`,
            backgroundColor: color,
            height,
          })}`}
        ></div>
      </div>
      {labels && labels.length && (
        <div className="d-flex justify-content-between">
          {labels.map((label: number) => {
            return (
              <div key={label}>
                <Text.BodyText5 color="grey70">{`${label}%`}</Text.BodyText5>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default ProgressBar
