import { buildTranslate } from 'locales'
import { CampaignStateT } from 'models/Campaign'
import React from 'react'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import Section from '../Section'
import Cogs from './Cogs'
import Discount from './Discount'
import Purchased from './Purchased'
import Purchases from './Purchases'
import Redeemed from './Redeemed'
import Revenue from './Revenue'
import RewardsAccounting from './RewardsAccounting'

const t = buildTranslate('thanx_campaigns.report.v3.summary.revenue')

type Props = {
  campaignId: number
  campaignState: CampaignStateT
  redeem: boolean
  redeemTypes: (string | undefined)[]
}
const RevenueCosts: React.FC<Props> = ({
  campaignId,
  campaignState,
  redeem,
  redeemTypes,
}) => {
  const { attributionWindow } = useAttributionWindow()

  return (
    <Section
      title={t('title')}
      attributionWindow={attributionWindow}
      attributionTooltip={t('attribution.tooltip', {
        count: attributionWindow,
      })}
      campaignId={campaignId}
      metricTypes={['redeem', 'revenue']}
      campaignState={campaignState}
    >
      <Revenue campaignId={campaignId} />
      <Purchased campaignId={campaignId} />
      <Purchases campaignId={campaignId} />
      <Redeemed campaignId={campaignId} redeemTypes={redeemTypes} />
      <Discount campaignId={campaignId} redeemTypes={redeemTypes} />
      <Cogs campaignId={campaignId} redeemTypes={redeemTypes} />
      {redeem && (
        <RewardsAccounting
          campaignId={campaignId}
          campaignState={campaignState}
          redeemTypes={redeemTypes}
        />
      )}
    </Section>
  )
}

export default RevenueCosts
