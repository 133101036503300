import { getRedeems, GET_REDEEMS_SUCCESS } from 'actions/redeems'
import useDispatch from 'hooks/useDispatch'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Fields from 'scenes/CampaignCenter/Builder/components/Incentive/Fields'
import { selectRedeems } from 'selectors/redeem'

import type { CampaignConfig, Fields as Campaign } from 'models/Campaign'
import type { Fields as Redeem } from 'models/Redeem'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'

type Props = {
  campaign: Campaign
  canProceed: boolean
  showValidationErrors?: () => void
  isSaving: boolean
  triggerSave: () => void
}

export type SelectedRedeem = {
  label: string | React.ReactNode
  value: CampaignConfig | Redeem | 0
}

export function selectOptions(
  config: CampaignConfig,
  redeems: Redeem[]
): SelectedRedeem[] {
  let options: SelectedRedeem[] = []

  if (config.redeem_description) {
    options.push({
      label: config.redeem_description,
      value: config,
    })
  }

  if (redeems) {
    forEach(redeems, redeem => {
      if (
        !map(options, 'label').includes(redeem.redeem_description) &&
        !isEmpty(redeem.redeem_description)
      ) {
        options.push({
          label: redeem.redeem_description,
          value: redeem,
        })
      }
    })
    options = sortBy(options, 'label')
  }

  options.push({
    value: 0,
    label: (
      <span className="belize-hole">
        <em>{I18n.t('thanx_campaigns.builder.steps.incentive.new_option')}</em>
      </span>
    ),
  })

  return options
}

const Incentive: React.FC<Props> = props => {
  const { campaign, canProceed, showValidationErrors, isSaving, triggerSave } =
    props
  const { config } = useCampaignConfig()
  const [showRewardCreatorModal, setShowRewardCreatorModal] = useState(false)
  const [showRewardRestrictionsModal, setShowRewardRestrictionsModal] =
    useState(false)
  const [showRewards, setShowRewards] = useState(!!config.redeem)
  const [selectedRedeem, setSelectedRedeem] = useState<SelectedRedeem>({
    label: config.redeem_description,
    value: config,
  })
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false)
  const [isLoadingRedeems, setIsLoadingRedeems] = useState(false)

  const redeems = useSelector(state => selectRedeems(state))

  const dispatch = useDispatch()

  useEffect(() => {
    if (showRewards && isEmpty(redeems)) {
      fetchRedeems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!showRewardCreatorModal && !canProceed && showRewards) {
      setShowRewardCreatorModal(true)
    }
  }, [showRewardCreatorModal, canProceed, showRewards])

  async function fetchRedeems() {
    setIsLoadingRedeems(true)
    const response = await dispatch(getRedeems())
    if (
      response.type === GET_REDEEMS_SUCCESS &&
      !isEmpty(response.payload.data.redeems) &&
      isEmpty(config.redeem_description)
    ) {
      const firstRedeem = response.payload.data.redeems[0]
      setSelectedRedeem({
        label: firstRedeem.redeem_description,
        value: firstRedeem,
      })
    }
    triggerSave()
    setIsLoadingRedeems(false)
  }

  function handleInclusionChange(value: boolean) {
    if (value === false) {
      setShowConfirmationAlert(false)
    } else {
      fetchRedeems()
    }
    setShowRewards(value)
  }

  function handleSelectChange(selectedOption: SelectedRedeem) {
    setSelectedRedeem(selectedOption)
    setShowRewardCreatorModal(true)
  }

  function handleRestrictionsClose() {
    triggerSave()
    if (canProceed) {
      setShowRewardRestrictionsModal(false)
    } else if (showValidationErrors) {
      showValidationErrors()
    }
  }

  return (
    <Fields
      campaign={campaign}
      handleInclusionChange={handleInclusionChange}
      toggleRewardRestrictions={() =>
        setShowRewardRestrictionsModal(!showRewardRestrictionsModal)
      }
      toggleRewardCreator={() =>
        setShowRewardCreatorModal(!showRewardCreatorModal)
      }
      onRestrictionsClose={handleRestrictionsClose}
      showValidationErrors={showValidationErrors}
      showConfirmationAlert={showConfirmationAlert}
      selectedRedeem={selectedRedeem}
      showRewardCreator={showRewardCreatorModal}
      showRewardRestrictions={showRewardRestrictionsModal}
      showRewards={showRewards}
      selectOptions={selectOptions(config, redeems)}
      isLoadingRedeems={isLoadingRedeems}
      canProceed={canProceed}
      isSaving={isSaving}
      handleSelectChange={handleSelectChange}
    />
  )
}

export default Incentive
