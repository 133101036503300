import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import React, { Component, SyntheticEvent } from 'react'
import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { textUtils } from 'utilities/textUtils'

interface keyable {
  [key: string]: any
}

type Props = {
  addOnAfter?: React.ReactNode
  addOnBefore?: React.ReactNode
  className?: string
  componentClass?: string
  disabled?: boolean
  helpBlock?: React.ReactNode
  hint?: string
  inputProps?: keyable
  label?: string
  labelClassName?: string
  onChange?: (event: SyntheticEvent<HTMLInputElement>) => void
  onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void
  placeholder?: string
  spellCheck?: boolean
  style?: React.CSSProperties
  trimValue?: boolean
  type?: string
  isHeaderInput?: boolean
  width?: string
} & PassDownProps<any>

export class Input extends Component<Props> {
  static defaultProps = {
    spellCheck: true,
  }

  inputRef: any

  clear() {
    this.props.setValue('')
    this.inputRef.value = ''
  }

  onChange(event: SyntheticEvent<HTMLInputElement>, blur?: boolean) {
    const { onChange, setValue } = this.props
    if (onChange) onChange(event)
    let eventValue = event.currentTarget.value
    if (blur) eventValue = this.trimValue(eventValue)
    event.currentTarget.value = eventValue
    setValue(eventValue)
  }

  trimValue(value: string) {
    return this.props.trimValue ? value.trim() : value
  }

  onBlur(event: SyntheticEvent<HTMLInputElement>) {
    const { onBlur, setValue } = this.props
    if (onBlur) {
      onBlur(event)
      let eventValue = this.trimValue(event.currentTarget.value)
      event.currentTarget.value = eventValue
      setValue(eventValue)
    } else {
      this.onChange(event, true)
    }
  }

  helpBlock() {
    const { helpBlock, hint } = this.props
    if (helpBlock) return helpBlock
    if (hint) {
      return (
        <HelpBlock style={{ margin: '10px 0px 0px' }} className="grey-80">
          {hint}
        </HelpBlock>
      )
    }
    return null
  }

  labelId() {
    if (!this.props.label) return undefined

    return `${this.props.name.toLowerCase().replace(' ', '-')}-label`
  }

  label() {
    const { validations, label, labelClassName = '' } = this.props
    //@ts-ignore
    let maxLength = validations?.maxLength
    if (!label && !maxLength) return null
    return (
      <ControlLabel id={this.labelId()} className={labelClassName}>
        {label}
        {this.charsRemaining(maxLength)}
      </ControlLabel>
    )
  }

  charsRemaining(maxLength?: number) {
    if (!maxLength || !this.inputRef) return null
    let remaining = maxLength - this.inputRef.value.length
    if (remaining <= 0) return null
    return (
      <em className="pull-right grey-50 font-size-12 normal relative nudge-down-2">
        {I18n.t('defaults.chars_remaining', { count: remaining })}
      </em>
    )
  }

  addOnBefore() {
    if (!this.props.addOnBefore) return null
    return <InputGroup.Addon>{this.props.addOnBefore}</InputGroup.Addon>
  }

  addOnAfter() {
    if (!this.props.addOnAfter) return null
    return <InputGroup.Addon>{this.props.addOnAfter}</InputGroup.Addon>
  }

  formControl() {
    const {
      value,
      placeholder,
      componentClass,
      disabled,
      spellCheck,
      type,
      inputProps,
      addOnBefore,
      addOnAfter,
      onFocus = () => null,
      width = '100%',
    } = this.props
    const defaultValue = inputProps?.value ? undefined : value || ''
    const input = (
      <FormControl
        className="grey-70"
        style={{ width }}
        inputRef={el => (this.inputRef = el)}
        onFocus={onFocus}
        onChange={this.onChange.bind(this)}
        defaultValue={defaultValue}
        onBlur={this.onBlur.bind(this)}
        placeholder={placeholder}
        componentClass={componentClass}
        disabled={disabled}
        type={type || 'text'}
        aria-labelledby={this.labelId()}
        spellCheck={spellCheck}
        {...inputProps}
      />
    )
    if (!addOnBefore && !addOnAfter) {
      return input
    }

    return (
      <InputGroup>
        {this.addOnBefore()}
        {input}
        {this.addOnAfter()}
      </InputGroup>
    )
  }

  validationErrors() {
    if (this.props.isPristine) return null

    return (
      <span className="text-danger error-message block">
        {textUtils.initialCapital(
          textUtils.toSentence({
            array: this.props.errorMessages as string[],
            punctuation: '.',
          })
        )}
      </span>
    )
  }

  render() {
    const {
      showError,
      isHeaderInput,
      isPristine,
      className = '',
      style,
    } = this.props
    const headerInput = isHeaderInput ? 'header-input' : ''
    const classNameWithForm =
      showError && !isPristine
        ? `form-group has-error ${className} ${headerInput}`
        : `form-group ${className} ${headerInput}`

    return (
      <FormGroup className={classNameWithForm} bsSize="lg" style={style}>
        {this.label()}
        {this.formControl()}
        {this.validationErrors()}
        {this.helpBlock()}
      </FormGroup>
    )
  }
}

export default withFormsy(Input)
