import React from 'react'

type Props = {
  className?: string
  children: React.ReactNode
}

const Cell: React.FC<Props> = props => {
  const { children, className = '' } = props

  return (
    <td className={`body-text-4 grey-70-text py-s px-m ${className}`}>
      {children}
    </td>
  )
}

export default Cell
