import isNil from 'lodash/isNil'
import type { Fields as Campaign } from 'models/Campaign'
import type { Metrics } from 'models/CampaignMetric'
import numeral from 'numeral'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'
import HeaderBox from './HeaderBox'

type Props = {
  metrics?: Metrics
  campaign: Campaign
}

const MIN_PURCHASERS_COUNT = 25

export function formatRevenue(revenue: number): string {
  if (revenue < 1000) {
    return numeral(revenue).format('$0').toUpperCase()
  } else {
    return numeral(revenue).format('$0.0a').toUpperCase()
  }
}

export const RevenueMetricContainer: React.FC<Props> = ({
  metrics,
  campaign,
}) => {
  const revenueData = metrics?.revenue
  const incrementalRevenue = revenueData?.incremental_revenue
  const targetIncrementalRevenue = revenueData?.target_incremental_revenue
  const grossRevenue = revenueData?.gross_revenue
  const targetGrossRevenue = revenueData?.target_gross_revenue
  const purchasersCount = revenueData?.purchasers_count
  const hasControlGroup =
    !!campaign.config_control_percent && campaign.config_control_percent > 0
  const revenueAmount = getRevenueAmount()
  const notEnoughData = !revenueAmount
  const isEstimated =
    !notEnoughData &&
    !isNil(targetIncrementalRevenue) &&
    !isNil(incrementalRevenue) &&
    targetIncrementalRevenue !== incrementalRevenue

  function getRevenueAmount() {
    if (campaign.type === 'winback') return grossRevenue

    const useExtrapolated =
      !isNil(purchasersCount) && purchasersCount >= MIN_PURCHASERS_COUNT

    if (hasControlGroup) {
      if (useExtrapolated) {
        return incrementalRevenue
      } else {
        return targetIncrementalRevenue
      }
    } else {
      if (useExtrapolated) {
        return grossRevenue
      } else {
        return targetGrossRevenue
      }
    }
  }

  function content() {
    const revenue = revenueAmount ? formatRevenue(revenueAmount) : '~'

    return (
      <span className="white revenue-text">
        <strong>{revenue}</strong>
      </span>
    )
  }

  function getKey() {
    if (notEnoughData) return null
    if (campaign.redeem) {
      if (!hasControlGroup) return 'no_control_offer'
      if (isEstimated) return 'est_incremental_offer'
      return 'incremental_offer'
    } else {
      if (!hasControlGroup) return 'no_control_no_offer'
      if (isEstimated) return 'est_incremental_no_offer'
      return 'incremental_no_offer'
    }
  }

  function title(key: string | null): string {
    const title = I18n.t(
      `thanx_campaigns.report.summary.revenue.${campaign.type}.title`
    )
    if (title) return title

    if (key) {
      return I18n.t(`thanx_campaigns.report.summary.revenue.${key}.title`)
    }

    return I18n.t('thanx_campaigns.report.summary.revenue.default.title')
  }

  function tooltip(key: string | null): string | null {
    const tooltip = I18n.t(
      `thanx_campaigns.report.summary.revenue.${campaign.type}.tooltip`
    )
    if (tooltip) return tooltip

    if (key) {
      return I18n.t(`thanx_campaigns.report.summary.revenue.${key}.tooltip`)
    }

    return null
  }

  const key = getKey()
  return (
    <HeaderBox
      className="spearmint-bkg"
      textClassName="white"
      title={title(key)}
      content={content()}
      tooltip={tooltip(key)}
    />
  )
}

export default withMetrics(['revenue'])(RevenueMetricContainer)
