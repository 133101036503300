import { Text } from '@thanx/uikit/text'
import { Feedback, feedbacksApi, GetFeedbacksParams } from 'api/feedbacks'
import { locationsApi } from 'api/locations'
import Help from 'components/Help'
import Pagination from 'components/Pagination'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import Spinner from 'components/Spinner'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useIsThanxAdmin from 'hooks/useIsThanxAdmin'
import { buildTranslate } from 'locales'
import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'
import { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import { useStyletron } from 'styletron-react'
import Filter from './Filter'
import ResponseModal from './ResponseModal'
import Table from './Table'

const t = buildTranslate('feedbacks.responses')

const Responses = () => {
  const [css] = useStyletron()
  const [filters, setFilters] = useState<GetFeedbacksParams>({
    page: 1,
  })

  const [response, setResponse] = useState<Feedback | null>(null)
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [updateFeedback] = feedbacksApi.useUpdateFeedbackMutation()

  const {
    data: feedbacksData,
    isLoading: isLoadingFeedbacks,
    isFetching: isFetchingFeedbacks,
  } = feedbacksApi.useGetFeedbacksQuery({ ...filters })

  const { data: locationsData, isLoading: isLoadingLocations } =
    locationsApi.useGetLocationsQuery({ coming_soon: false })

  const merchant = useCurrentMerchant()
  const isThanxAdmin = useIsThanxAdmin()
  const responses = feedbacksData?.feedbacks || []
  const pagination = feedbacksData?.pagination
  const locations = locationsData?.locations || []
  const isLoading =
    isLoadingFeedbacks || isLoadingLocations || isFetchingFeedbacks
  const hasResponses = responses.length > 0
  const hasFilters =
    filters.location_id ||
    filters.unread ||
    filters.has_review ||
    filters.critical

  function updateFilters(updatedFilters: GetFeedbacksParams) {
    setFilters(pickBy({ ...filters, ...updatedFilters }, identity))
  }

  async function onView(response: Feedback) {
    if (!response.viewed_at && !isThanxAdmin) {
      updateFeedback({ id: response.id, viewed_at: new Date().toISOString() })
    }
    setResponse(response)
    setResponseModalOpen(true)
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('titles.feedback')}</title>
      </Helmet>
      <ProtectedPageHeader
        resource="Feedback"
        action="access"
        title={t('title')}
        menu={
          <Link to="/reports/feedback" className="btn btn-primary btn-lg">
            {t('view_report')}
          </Link>
        }
      >
        <div
          className={`pb-l mt-m grey-05-bkg ${css({
            minHeight: 'calc(100% - 75px)',
          })}`}
        >
          <Spinner
            className="pt-xl text-center"
            isLoading={isLoading}
            size="2x"
          />
          {!isLoading && !hasResponses && !hasFilters && (
            <div className="text-center py-xl grey-05-bkg">
              <Text.Header4 className="text-center" bold>
                {t('no_responses.title')}
              </Text.Header4>
              <Text.BodyText3 color="grey70">
                {t('no_responses.subtitle')}
              </Text.BodyText3>
            </div>
          )}
          {!isLoading && (hasResponses || hasFilters) && (
            <Container className="p-0 grey-05-bkg">
              <Filter
                filters={filters}
                updateFilters={updateFilters}
                locations={locations}
              />
              {!hasResponses && hasFilters && (
                <div className="text-center py-xl">
                  <Text.Header4 className="text-center" bold>
                    {t('no_responses.title_with_filter')}
                  </Text.Header4>
                  <Text.BodyText3 color="grey70">
                    {t('no_responses.subtitle_with_filter')}
                  </Text.BodyText3>
                </div>
              )}
              {hasResponses && (
                <>
                  <Table
                    responses={responses}
                    onView={response => onView(response)}
                    merchantName={merchant?.name}
                  />
                  <div
                    className={'d-flex justify-content-center margin-top-huge'}
                  >
                    <Pagination
                      currentPage={filters.page || 1}
                      onChangePage={page => updateFilters({ page })}
                      numPages={pagination?.total_pages || 1}
                    />
                  </div>
                  <Help
                    className="pl-0 ml-0"
                    title={t('helpers.title')}
                    articles={[
                      {
                        title: t('helpers.content'),
                        url: 'https://help.thanx.com/hc/en-us/articles/17663802915607-Review-your-NPS-Score',
                      },
                    ]}
                  />
                </>
              )}
            </Container>
          )}
        </div>
      </ProtectedPageHeader>
      {response && (
        <ResponseModal
          responseId={response.id}
          purchaseId={response.purchase_id}
          merchantName={merchant?.name}
          visible={responseModalOpen}
          onClose={() => setResponseModalOpen(false)}
        />
      )}
    </>
  )
}

export default Responses
