import { MerchantUser } from 'api/merchantUsers'
import FormsySelect from 'components/Form/SelectWithFormsy'
import React from 'react'
import FormLabel from 'react-bootstrap-five/FormLabel'
import { Translate } from 'react-redux-i18n'

type Props = {
  merchantUsers: Array<MerchantUser>
}

const Select: React.FC<Props> = ({ merchantUsers }) => {
  const options = merchantUsers.map(merchantUser => ({
    value: merchantUser.id,
    label: `${merchantUser.first_name} ${merchantUser.last_name}`,
  }))

  return (
    <>
      <FormLabel>
        <Translate
          value="thanx_campaigns.builder.confirm_modal.request_review.label"
          className="control-label grey-90"
        />
      </FormLabel>
      <FormsySelect
        options={options}
        name="approver_id"
        defaultValue={options[0].value}
        className="fs-mask"
      />
    </>
  )
}

export default Select
