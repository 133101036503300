import Error from 'components/Icons/Error'
import Spinner from 'components/Spinner'
import React from 'react'

type PropsT = {
  status: 'error' | 'loading' | null
  className?: string
}

const Status: React.FC<PropsT> = props => {
  const { status, className = '' } = props
  if (status === 'error')
    return (
      <div className={className}>
        <Error />
      </div>
    )

  if (status === 'loading') return <Spinner isLoading className={className} />

  return null
}

export default Status
