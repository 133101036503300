import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import LegacyMessagingEditor from './components/LegacyMessagingEditor'
import MessagingEditor from './components/MessagingEditor'

type Props = {
  campaign: Campaign
  setSubstep?: (step: string) => void
  disableButton?: boolean
  disableRedeem?: boolean
  customButtonToggleGroup?: React.ReactNode
  canProceed: boolean
  showValidationErrors?: () => void
}

const t = buildTranslate('thanx_campaigns.builder.forms.messaging_tabs')

const Messaging: React.FC<Props> = props => {
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, props.campaign?.type)
  )
  const useLegacyEditor = !campaignType?.unlayer_enabled
  return (
    <>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <Switch
        condition={useLegacyEditor}
        fallback={<MessagingEditor {...props} />}
      >
        <LegacyMessagingEditor {...props} />
      </Switch>
    </>
  )
}

export default Messaging
