import { DateRangeFilter } from 'components/DateFilter/utilities'
import moment from 'moment-timezone'
import { NamedRateTimeSeriesMetric } from './LineChart'

export const getXAxisCategories = (filter: DateRangeFilter, timezone) => {
  const { start, end } = filter
  const categories: string[] = []
  const months = moment(end, 'YYYY-MM-DD').diff(
    moment(start, 'YYYY-MM-DD'),
    'months'
  )
  if (!start || !end) {
    return []
  }
  for (let i = 0; i <= months; i++) {
    const date = moment(start, 'YYYY-MM-DD')
      .tz(timezone)
      .add(i, 'month')
      .format('MMM YYYY')
    categories.push(date)
  }
  return categories
}

export const getKnownRevenueSeries = (
  categories: string[],
  metrics: NamedRateTimeSeriesMetric[]
) =>
  metrics.map(metric => {
    const data = categories.map((category, i) => {
      const dateTime = moment(category, 'MMM YYYY').format('YYYY-MM')
      const value = metric?.values?.find(m => m.date_time === dateTime)
      return {
        x: i,
        y: value?.member_purchases_total_amount ?? 0,
        custom: value ?? {},
      }
    })
    return {
      color: metric.color,
      marker: { enabled: false, symbol: 'circle' },
      name: metric.locationName,
      data,
      type: 'line',
      visible: true,
      id: metric.id,
    }
  }) as Highcharts.SeriesLineOptions[]

export const getRateChartSeries = (
  categories: string[],
  metrics: NamedRateTimeSeriesMetric[]
) =>
  metrics.map(metric => {
    const data = categories.map((category, i) => {
      const dateTime = moment(category, 'MMM YYYY').format('YYYY-MM')
      const value = metric?.values?.find(m => m.date_time === dateTime)
      return {
        x: i,
        y: value?.capture_rate ? value?.capture_rate * 100 : 0,
        custom: value ?? {},
      }
    })
    return {
      color: metric.color,
      marker: { enabled: false, symbol: 'circle' },
      name: metric.locationName,
      data,
      type: 'line',
      visible: true,
      id: metric.id,
    }
  }) as Highcharts.SeriesLineOptions[]
