import { Button, Kind, Size } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import PopoverContent, { Sections } from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col } from 'react-bootstrap-five'

const t = buildTranslate('home.metrics')

export type CardT = {
  className?: string
  title: string
  sections: Sections
  children: React.ReactNode
  onClick: () => void
  isTeaser?: boolean
  isReportComingSoon?: boolean
  buttonText?: string
  buttonKind?: string
}

const Card: React.FC<CardT> = ({
  className = '',
  title,
  sections,
  children,
  onClick,
  isTeaser = false,
  isReportComingSoon = false,
  buttonText,
  buttonKind,
}) => {
  return (
    <Col xs={12} sm={6} className="my-s">
      <div
        className={`card h-100 ${
          isTeaser ? 'grey-05-bkg' : ''
        } p-sm-xl p-m ${className}`}
      >
        <div className="card-title">
          <Text.Header4 tag="p" color="grey90">
            <span className="mr-xs">{title}</span>
            <PopoverContent sections={sections} />
          </Text.Header4>
        </div>
        <div className="card-body p-0">
          <div className="d-flex align-items-end justify-content-between flex-wrap">
            <div className="pr-s">{children}</div>
            <div className="mt-xs">
              <Button
                kind={
                  buttonKind ?? isReportComingSoon
                    ? Kind.SECONDARY
                    : Kind.PRIMARY
                }
                size={Size.SMALL}
                onClick={onClick}
              >
                {buttonText ||
                  (isReportComingSoon
                    ? t('shared.report_coming_soon')
                    : t('shared.view_report'))}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default Card
