import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import { Fields as MerchantUser } from 'models/MerchantUser'
import React from 'react'
import { Link } from 'react-router-dom'
import { merchantTheme } from 'theme'

const t = buildTranslate('nav.profile_menu')

type PropsT = {
  merchant: Merchant
  merchantUser: MerchantUser
  onClick?: VoidFunction
  link: string
}

const Header: React.FC<PropsT> = props => {
  const { merchant, merchantUser, onClick, link } = props

  const [css] = useStyletron()

  return (
    <div
      className={`d-flex align-items-center pt-l p-m flex-column ${css({
        borderBottom: '1px solid ' + merchantTheme.colors.grey30,
        minWidth: '264px',
      })}`}
    >
      <img
        src={merchant.logo_image_urls.default}
        alt={`${merchant.handle}-logo`}
        className={`mb-xs ${css({ width: '64px', borderRadius: '4px' })}`}
      />
      <Text.Header4
        bold
        color="grey90"
        className="mt-0 mb-xs text-center text-nowrap"
      >
        {merchant.name}
      </Text.Header4>
      <Text.BodyText4 color="grey70" tag="p" className="mb-0">
        {merchantUser.first_name} {merchantUser.last_name}
      </Text.BodyText4>
      <Link to={link} onClick={onClick}>
        <Text.BodyText4 tag="span" className="mb-0" color="primary">
          {t('edit_profile')}
        </Text.BodyText4>
      </Link>
    </div>
  )
}

export default Header
