import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import { merchantTheme } from 'theme'

type PropsT<T extends string> = {
  panels: Array<{ type: T; label: string }>
  selectedPanel: T
  onSelectPanel: (p: T) => void
}

function ButtonPanel<T extends string>({
  selectedPanel,
  onSelectPanel,
  panels,
}: PropsT<T>) {
  const [css] = useStyletron()
  return (
    <div className={'mr-xl'}>
      {panels.map(panel => {
        const isSelected = selectedPanel === panel.type
        const buttonBackgroundColor = isSelected
          ? merchantTheme.colors.belizeHole10
          : 'transparent'
        return (
          <div
            key={panel.type}
            className={css({
              ':hover': {
                backgroundColor: merchantTheme.colors.belizeHole10,
              },
            })}
          >
            <Button
              kind="minimal"
              className={`justify-content-start p-s ${css({
                width: '200px',
                borderRadius: 0,
                backgroundColor: `${buttonBackgroundColor} !important`,
              })}`}
              onClick={() => onSelectPanel(panel.type)}
            >
              <Text.BodyText4
                className="line-height-20"
                bold={isSelected}
                color={`${isSelected ? 'grey90' : 'grey70'}`}
              >
                {panel.label}
              </Text.BodyText4>
            </Button>
          </div>
        )
      })}
    </div>
  )
}

export default ButtonPanel
