import type { Fields as Campaign } from 'models/Campaign'
import { Metrics } from 'models/CampaignMetric'
import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'
import configs from 'scenes/CampaignCenter/Report/configs'
import HeaderBox from '../../../Shared/Summary/components/HeaderBox'

type Props = {
  campaign: Campaign
  metrics?: Metrics
  locationName?: string
}

const ConversionMetric: React.FC<Props> = props => {
  const { campaign, locationName } = props
  const config = configs[campaign.type]
  const successCount = config.getSuccessCount(props)

  if (!successCount) {
    return (
      <HeaderBox
        className="grey-30-bkg"
        title={I18n.t('thanx_campaigns.report.summary.conversion.title')}
        content={
          <span className="grey deprecated__body-text-2">
            <strong>
              <Translate value="thanx_campaigns.report.summary.conversion.not_enough_data" />
            </strong>
          </span>
        }
      />
    )
  }

  const tooltip = I18n.t(
    `thanx_campaigns.report.campaign_types.${campaign.type}.conversion.tooltip`,
    {
      metric: I18n.l(successCount),
    }
  )
  return (
    <HeaderBox
      style={{ backgroundColor: config.color }}
      textClassName="white"
      title={I18n.t('thanx_campaigns.report.summary.conversion.title')}
      tooltip={tooltip}
      content={
        <span className="white body-text-1">
          <strong>
            <Translate
              value={`thanx_campaigns.report.campaign_types.${campaign.type}.conversion.description`}
              metric={I18n.l(successCount)}
              location={locationName}
            />
          </strong>
        </span>
      }
    />
  )
}

export default withMetrics(props => {
  return [configs[props.campaign.type].successMetric]
})(ConversionMetric)
