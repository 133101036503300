import * as actions from 'actions/emailProviderLists'
import * as providerActions from 'actions/emailProviders'
import { RESET_DB } from 'actions/orm'
import { attr, fk, Model, ModelType } from 'redux-orm'

export interface Fields {
  provider_id: number
  id: number
  state: string
  uid: string
  name: string
}

export default class EmailProviderList extends Model<
  // @ts-ignore
  typeof EmailProviderList,
  Fields
> {
  static modelName: string = 'EmailProviderList'

  static get fields(): any {
    return {
      provider_id: fk({
        to: 'EmailProvider',
        as: 'provider',
        relatedName: 'lists',
      }),
      id: attr(),
      state: attr(),
      uid: attr(),
      name: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  // @ts-ignore
  static reducer(action: any, EmailProviderList: ModelType<EmailProviderList>) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case actions.GET_EMAIL_PROVIDER_LISTS_SUCCESS:
      case providerActions.GET_PROVIDER_LISTS_SUCCESS:
      case actions.UPDATE_PROVIDER_LIST_BULK_SUCCESS:
        var emailProviderLists = action.payload.data.email_provider_lists
        emailProviderLists.forEach(list => {
          EmailProviderList.upsert(list)
        })
        break
      case actions.CREATE_PROVIDER_LIST_SUCCESS:
        var list = action.payload.data.email_provider_list
        return EmailProviderList.upsert<Fields>(list)
      case RESET_DB:
        EmailProviderList.all().delete()
        break
      default:
        break
    }
  }
}
