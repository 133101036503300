import { Button, Kind, Size } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { logout } from 'actions/auth'
import logo from 'assets/images/Thanx-logo-dark.png'
import SearchInput from 'components/SearchInput'
import useDispatch from 'hooks/useDispatch'
import useIsThanxAdmin from 'hooks/useIsThanxAdmin'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import { merchantTheme } from 'theme'
import { baseHelpUrl } from 'utilities/urlUtils'

const t = buildTranslate('auth.merchant_picker')

type Props = {
  merchants: Merchant[]
  setCurrentMerchant: (id: number) => void
}

const Choices: React.FC<Props> = ({ merchants, setCurrentMerchant }) => {
  const isThanxAdmin = useIsThanxAdmin()
  const [filter, setFilter] = useState<string>('')
  const [css] = useStyletron()
  const dispatch = useDispatch()

  const filteredMerchants = merchants.filter(merchant => {
    if (!filter) return merchant
    if (
      merchant.name
        .toLowerCase()
        .trim()
        .includes(filter.toLocaleLowerCase().trim())
    )
      return merchant
    return null
  })

  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <div className="d-flex m-m justify-content-end">
        {!isThanxAdmin && (
          <Button
            $as="a"
            href={baseHelpUrl}
            target="_blank"
            kind={Kind.SECONDARY}
            size={Size.SMALL}
            className={`mr-s ${css({
              transition: 'all 0.2s ease',
              ':hover': {
                backgroundColor:
                  merchantTheme.colors.belizeHole10 + '!important',
                textDecoration: 'none',
                color: merchantTheme.colors.belizeHole50,
              },
            })}`}
          >
            {t('help')}
          </Button>
        )}
        <Button
          kind={Kind.SECONDARY}
          size={Size.SMALL}
          onClick={() => dispatch(logout())}
        >
          {t('log_out')}
        </Button>
      </div>
      <div className="text-center mb-xl">
        <img src={logo} width={109} alt="Thanx" />
      </div>
      <Text.Header3
        bold
        color="grey90"
        className={`text-center d-flex align-self-center mb-xl mt-0 ${css({
          maxWidth: '400px',
        })}`}
      >
        {t('title')}
      </Text.Header3>
      <Container className={`align-items-center d-flex flex-column`}>
        {isThanxAdmin && (
          <SearchInput
            className={`w-100 mb-xl ${css({ maxWidth: '600px' })}`}
            onChange={setFilter}
            value={filter}
          />
        )}

        <div
          className={`overflow-auto w-100 ${css({
            maxWidth: '600px',
          })}`}
        >
          {filteredMerchants.map((merchant, index) => {
            return (
              <div
                className={`d-flex justify-content-between py-s px-m w-100 ${css(
                  {
                    borderBottom: '1px solid ' + merchantTheme.colors.grey30,
                    maxWidth: '600px',
                  }
                )}`}
                key={index}
              >
                <div className="align-items-center d-flex flex-row">
                  <img
                    key={index}
                    src={merchant.logo_image_urls.default}
                    alt={`${merchant.name} logo`}
                    className={`mr-xs ${css({ width: '32px' })}`}
                  />
                  <Text.BodyText3 color="grey70">
                    {merchant.name}
                  </Text.BodyText3>
                </div>
                <Button
                  onClick={() => {
                    setCurrentMerchant(merchant.id)
                  }}
                  size={Size.SMALL}
                >
                  {t('select_merchant')}
                </Button>
              </div>
            )
          })}
        </div>
      </Container>
    </div>
  )
}

export default Choices
