import { getProducts } from 'actions/products'
import useDispatch from 'hooks/useDispatch'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectProducts } from 'selectors/product'

export default function useProducts() {
  const products = useSelector(selectProducts)
  const [isLoaded, setIsLoaded] = useState<boolean>(products.length > 0)
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchProducts() {
      await dispatch(getProducts())
      setIsLoaded(true)
    }

    if (!isLoaded) {
      fetchProducts()
    }
  }, [isLoaded, dispatch])

  return {
    products,
    isLoading: !isLoaded,
    // products are not supported for this merchant when the API returns empty.
    // so we should fallback simply displaying product ids instead.
    shouldDisplayIds: isLoaded && products.length === 0,
  }
}
