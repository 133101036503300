import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import { ActivationRateTimeSeriesMetric } from 'models/ActivationRateMetric'
import moment from 'moment-timezone'
import React from 'react'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import { getDateLabel } from 'utilities/date'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('activation_rate.tabs.activation_rate.popovers')

type Props = {
  data: ActivationRateTimeSeriesMetric['values'][number]
  isErrored: boolean
  isLoading: boolean
}

const Row: React.FC<Props> = props => {
  const { data, isErrored, isLoading } = props
  const [css] = useStyletron()
  const borderClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: palette.grey30,
  })
  if (!data) {
    return null
  }

  const timeZone = moment.tz.guess()

  const activationRate = percentageFormatter(data?.rate, 1)

  const dateLabel = getDateLabel(data?.date_time, 'monthly', timeZone)
  const firstPurchaseCount = numberFormatter(data?.first_purchasers_count)
  const thirdPurchaseCount = numberFormatter(data?.third_purchasers_count)

  return (
    <tr>
      <BodyCell
        values={[{ value: dateLabel }]}
        isErrored={isErrored}
        isLoading={isLoading}
        className={borderClass}
      />
      <BodyCell
        values={[
          {
            value: numberFormatter(data.first_purchasers_count),
            sections: [
              {
                content: t('first_purchase')[2],
              },
              {
                title: t('what_this_means'),
                content: t('first_purchase', {
                  dateLabel,
                  firstPurchaseCount,
                })[1],
              },
            ],
          },
        ]}
        isErrored={isErrored}
        isLoading={isLoading}
        className={`${borderClass} text-right`}
      />
      <BodyCell
        values={[
          {
            value: activationRate,
            sections: [
              {
                content: t('activation_rate', {
                  dateLabel,
                })[0],
              },
              {
                title: t('how_calculated'),
                content: t('activation_rate')[1],
              },
              {
                title: t('what_this_means'),
                content: t('activation_rate', {
                  dateLabel,
                  firstPurchaseCount,
                  thirdPurchaseCount,
                  activationRate,
                })[2],
              },
            ],
          },
          {
            value: numberFormatter(data.third_purchasers_count),
            sections: [
              {
                content: t('third_purchase')[0],
              },
              {
                title: t('what_this_means'),
                content: t('third_purchase', {
                  dateLabel,
                  firstPurchaseCount,
                  thirdPurchaseCount,
                })[1],
              },
            ],
          },
        ]}
        isErrored={isErrored}
        isLoading={isLoading}
        className={`${borderClass} text-right`}
      />
    </tr>
  )
}

export default Row
