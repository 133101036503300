export default {
  browse_segments: 'Browse segments',
  create_campaign: 'Create a new campaign',
  title: 'All campaigns',
  page_title: 'Campaigns | Thanx',
  empty_search_results: {
    title: 'No results found',
    body: 'Try searching for something else',
  },
}
