import ABOneTime from 'components/banners/ABOneTime'
import PromoBanner from 'components/PromoBanner'
import React from 'react'
import Container from 'react-bootstrap-five/Container'
import Helmet from 'react-helmet'
import { I18n, Translate } from 'react-redux-i18n'
import { useFetchCampaignsByState } from 'scenes/CampaignCenter/Landing/Reports/hooks'
import TextRotation from '../TextRotation'
import CampaignTabs from './components/CampaignTabs'

const Reports: React.FC = () => {
  const { review, scheduled, active, draft, complete } =
    useFetchCampaignsByState()
  const contents = (
    <CampaignTabs
      reviewCampaigns={review.campaigns}
      scheduledCampaigns={scheduled.campaigns}
      activeCampaigns={active.campaigns}
      draftCampaigns={draft.campaigns}
      completeCampaigns={complete.campaigns}
      tabLoading={{
        review: review.loading,
        scheduled: scheduled.loading,
        active: active.loading,
        draft: draft.loading,
        complete: complete.loading,
      }}
    />
  )
  const Promo = ABOneTime
  const storageKey = 'abPromoOneTimeReports'
  return (
    <div className="grey-05-bkg h-auto min-vh-100">
      <Helmet>
        <title>{I18n.t('thanx_campaigns.landing.reporting.page_title')}</title>
      </Helmet>
      <div className="spearmint-50-bkg p-xl white mb-xxl">
        <Container>
          <Translate
            dangerousHTML
            value="thanx_campaigns.landing.overview.subtitle"
            className="white body-text-1 margin-bottom-none"
            tag="p"
          />
          <TextRotation />
        </Container>
      </div>
      <Container>
        <PromoBanner storageKey={storageKey}>
          <Promo />
        </PromoBanner>
        {contents}
      </Container>
    </div>
  )
}

export default Reports
