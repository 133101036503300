// @flow
// $FlowFixMe
import { alert } from 'actions/flash'
// $FlowFixMe
import { createProvider } from 'actions/emailProviders'
import Spacer from 'components/Spacer'
import Spinner from 'components/Spinner'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { bindActionCreators } from 'redux'
import { I18n } from 'react-redux-i18n'
import qs from 'qs'
// $FlowFixMe
import store from 'store'

type CallbackStatus = 'success' | 'fail' | 'in_progress'
type Props = {
  location: any,
  match: any,
  createProvider: (string, any) => Promise<*>,
}
type State = {
  status: CallbackStatus,
  providerType: string,
}

export class Callback extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      status: 'in_progress',
      providerType: props.match.params.providerType,
    }
  }

  componentDidMount() {
    this.connectProvider.bind(this)()
  }

  connectProvider() {
    const params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const oauth_code = params.code
    this.props
      .createProvider(this.state.providerType, oauth_code)
      .then(action => {
        if (action.error) {
          store.dispatch(
            alert({
              key: 'danger',
              message: I18n.t('emails.oauth.connect.error_message'),
              error: action.error.response
                ? action.error.response.data.error
                : null,
              displayDetails: true,
            })
          )
          this.setState({
            status: 'fail',
          })
          return
        }

        this.setState({
          status: 'success',
        })
      })
  }

  content() {
    if (this.state.status === 'in_progress') {
      return (
        <div className="text-center">
          <p>Connecting Account</p>
          <Spinner isLoading={true} size="5x" />
        </div>
      )
    } else if (this.state.status === 'success') {
      return (
        <Redirect
          to={`/subscribers/setup/settings/${this.state.providerType}`}
        />
      )
    }
  }

  render() {
    return (
      <div className="container">
        <Spacer paddingTop="30px" paddingBottom="30px">
          {this.content()}
        </Spacer>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createProvider,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(Callback)
