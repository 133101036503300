import { Role } from 'api/merchantUsers'
import { I18n } from 'react-redux-i18n'

function roleTitle(role: Role, isMall = false): string {
  const key = `${role}${role === 'manager' && isMall ? '_mall' : ''}`

  return I18n.t(`merchant_users.roles.${key}`)
}

export { roleTitle }
