import React, { useState } from 'react'

type PropsT = {
  storageKey: string
  children: React.ReactNode
}

const PromoBanner: React.FC<PropsT> = props => {
  const { storageKey, children } = props
  const [isOpen, setIsOpen] = useState(
    window.localStorage.getItem(storageKey) !== 'true'
  )

  const closeHandler = () => {
    window.localStorage.setItem(storageKey, 'true')
    setIsOpen(false)
  }
  if (!isOpen) return null

  return React.cloneElement(children as React.ReactElement<any>, {
    onClose: closeHandler,
  })
}

export default PromoBanner
