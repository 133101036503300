import Avatar from 'components/Avatar'
import React from 'react'
import { Button } from 'react-bootstrap'

type Props = {
  avatarColor: string
  avatarImage?: string
  button: string
  children: React.ReactNode
  subtitle?: string
  title: string
  onClick: () => void
}

const CustomerProfileView: React.FC<Props> = props => {
  const {
    avatarColor,
    avatarImage,
    button,
    children,
    subtitle,
    title,
    onClick,
  } = props

  return (
    <div className="padding-large border-1 border-radius-5 grey-30-border full-width full-height display-flex flex-column flex-justify-space-between">
      <div className="flex-grow-1 margin-bottom-medium">
        <div className="text-center margin-top-medium fs-mask">
          <div className="customer-avatar inline-block margin-bottom-extra-large">
            <Avatar color={avatarColor} image={avatarImage} />
          </div>
          <h6>{title}</h6>
          <p className="body-text-2 grey-70">
            <em>{subtitle}</em>
          </p>
        </div>
        <hr />
        <div className="margin-bottom-medium">{children}</div>
      </div>
      <Button
        onClick={onClick}
        bsSize="large"
        bsStyle="info"
        className="full-width margin-bottom-small fs-mask"
      >
        {button}
      </Button>
    </div>
  )
}

export default CustomerProfileView
