import scrollArrow from 'assets/images/campaign-center/reports/customers/arrow.svg'
import chunk from 'lodash/chunk'
import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'

type Props = {
  children: React.ReactNode
  itemsPerSlide: number
}

const CustomersCarousel: React.FC<Props> = ({ children, itemsPerSlide }) => {
  const [index, setIndex] = useState(0)
  const numSlides = Math.ceil(React.Children.count(children) / itemsPerSlide)

  function carouselItems(): React.ReactNode[] {
    if (!children) return []
    return chunk(React.Children.toArray(children), itemsPerSlide).map(
      (items, i) => {
        let filledItems = items
        if (items.length < itemsPerSlide) {
          filledItems = Array.from(Array(4)).map((_, i) => (
            <div key={`empty-${i}`} />
          ))
          filledItems.splice(0, items.length, ...items)
        }

        return (
          <Carousel.Item key={i} data-testid={`item-group-${i}`}>
            <div className="display-flex flex-all-children-even">
              {filledItems}
            </div>
          </Carousel.Item>
        )
      }
    )
  }

  function nextItem() {
    const newIndex = (index + 1) % numSlides
    setIndex(newIndex)
  }

  function prevItem() {
    const newIndex = (index + numSlides - 1) % numSlides
    setIndex(newIndex)
  }

  const carouselSettings = {
    activeIndex: index,
    controls: false,
    interval: null,
  }
  return (
    <div className="display-flex flex-justify-center flex-align-center">
      <div onClick={prevItem} className="arrow prev-arrow">
        <img src={scrollArrow} alt="previous arrow" />
      </div>
      <div className="full-width">
        <Carousel {...carouselSettings}>{carouselItems()}</Carousel>
      </div>
      <div onClick={nextItem} className="arrow next-arrow">
        <img src={scrollArrow} alt="next arrow" />
      </div>
    </div>
  )
}

export default CustomersCarousel
