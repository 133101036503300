import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}

const Unlock: React.FC<PropsT> = ({
  className = '',
  fillColor = 'currentColor',
}) => (
  <svg
    className={className}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 11.75C5.5625 11.75 5.25 11.4375 5.25 11C5.25 10.5938 5.5625 10.25 6 10.25H8C8.40625 10.25 8.75 10.5938 8.75 11C8.75 11.4375 8.40625 11.75 8 11.75H6ZM13.5 1.5C12.0938 1.5 11 2.625 11 4V6H12C13.0938 6 14 6.90625 14 8V14C14 15.125 13.0938 16 12 16H2C0.875 16 0 15.125 0 14V8C0 6.90625 0.875 6 2 6H9.5V4C9.5 1.8125 11.2812 0 13.5 0C15.6875 0 17.5 1.8125 17.5 4V6.25C17.5 6.6875 17.1562 7 16.75 7C16.3125 7 16 6.6875 16 6.25V4C16 2.625 14.875 1.5 13.5 1.5ZM12 7.5H2C1.71875 7.5 1.5 7.75 1.5 8V14C1.5 14.2812 1.71875 14.5 2 14.5H12C12.25 14.5 12.5 14.2812 12.5 14V8C12.5 7.75 12.25 7.5 12 7.5Z"
      fill={fillColor}
    />
  </svg>
)

export default Unlock
