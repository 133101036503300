import { Text } from '@thanx/uikit/text'
import Counter from 'components/Counter'
import { buildTranslate } from 'locales'
import { RetentionRateCumulativeMetric } from 'models/RetentionRateMetric'
import React from 'react'
import {
  BenchmarkT,
  diffPercentage,
  tooltipPercentage,
} from 'scenes/Reports/helpers'
import { getDateLabel } from 'utilities/date'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'
import { RETENTION_WINDOW_DAYS } from '../helpers'

const t = buildTranslate('retention_rate')

type PropsT = {
  isErrored: boolean
  isLoading: boolean
  currentRateData: RetentionRateCumulativeMetric['current']
  previousRateData: RetentionRateCumulativeMetric['previous']
  benchmark: BenchmarkT
  timezone: string
}

const Counters: React.FC<PropsT> = ({
  isErrored,
  isLoading,
  currentRateData,
  previousRateData,
  benchmark,
  timezone,
}) => {
  const rateDiffToPreviousPeriod = diffPercentage(
    currentRateData?.retention_rate,
    previousRateData?.retention_rate
  )

  const dateLabel = currentRateData
    ? getDateLabel(currentRateData?.date_time, 'monthly', timezone)
    : null
  const counterDateLabel = dateLabel ? `(${dateLabel})` : ''
  const returningUsersCount = numberFormatter(
    currentRateData?.returning_users_count
  )
  const engagedUsersCount = numberFormatter(
    currentRateData?.engaged_users_count
  )
  const retentionRate = percentageFormatter(
    currentRateData?.retention_rate,
    1,
    1
  )
  const retentionWindow = RETENTION_WINDOW_DAYS

  return (
    <div>
      <div className="d-flex">
        <Counter
          className="mr-xxl"
          primaryValue={retentionRate}
          isErrored={isErrored}
          isLoading={isLoading}
          secondaryValue={rateDiffToPreviousPeriod?.text}
          secondaryValueColor={rateDiffToPreviousPeriod?.color}
          sections={[
            {
              content: t('popovers.retention_rate', {
                dateLabel,
                retentionWindow,
              })[0],
            },
            {
              title: t('popovers.what_this_means'),
              content: t('popovers.retention_rate', {
                dateLabel,
                returningUsersCount,
                engagedUsersCount,
                retentionRate,
                retentionWindow,
              })[1],
            },
          ]}
          name={t('counters.retention_rate', { counterDateLabel })}
        />
        <Counter
          className="mr-xxl"
          primaryValue={returningUsersCount}
          isErrored={isErrored}
          isLoading={isLoading}
          sections={[
            {
              content: t('popovers.retained_customers', { retentionWindow })[0],
            },
            {
              title: t('popovers.what_this_means'),
              content: t('popovers.retained_customers', {
                dateLabel,
                retentionWindow,
                returningUsersCount,
                engagedUsersCount,
              })[1],
            },
          ]}
          name={t('counters.retained_customers', { retentionWindow })}
        />
        <Counter
          className="mr-xxl"
          primaryValue={engagedUsersCount}
          isErrored={isErrored}
          isLoading={isLoading}
          sections={[
            {
              content: t('popovers.engaged_customers', { dateLabel })[0],
            },
            {
              title: t('popovers.what_this_means'),
              content: t('popovers.engaged_customers', {
                dateLabel,
                engagedUsersCount,
              })[1],
            },
          ]}
          name={t('counters.engaged_customers', { counterDateLabel })}
        />
      </div>
      <Text.BodyText4 className="my-xs" color="wisteria" tag="div">
        {t('counters.industry_benchmark', {
          rate: tooltipPercentage(benchmark.rate),
        })}
      </Text.BodyText4>
    </div>
  )
}

export default Counters
