import numeral from 'numeral'
import React from 'react'
import Nav from 'react-bootstrap-five/Nav'
import { I18n } from 'react-redux-i18n'

import { useStyletron } from '@thanx/uikit/theme'
import { CampaignStateT } from 'models/Campaign'

type Props = {
  state: CampaignStateT
  count: number
  displayCount?: boolean
}

const CampaignTab: React.FC<Props> = props => {
  const { state, count, displayCount } = props
  const titleText = I18n.t(`thanx_campaigns.landing.reporting.tabs.${state}`)
  const [css] = useStyletron()
  let title = titleText

  if (count > 0 && displayCount) {
    title = (
      <span className="relative">
        {titleText}
        <span
          className={`absolute egg-yolk-50-bkg white inline-block border-radius-10 px-xxs mr-xxs ${css(
            {
              right: '-23px',
              top: '-10px',
            }
          )}`}
        >
          {numeral(count).format('0,0')}
        </span>
      </span>
    )
  }

  return (
    <Nav.Item key={state}>
      <Nav.Link
        eventKey={state}
        className={css({ backgroundColor: 'transparent !important' })}
      >
        {title}
      </Nav.Link>
    </Nav.Item>
  )
}

export default CampaignTab
