import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import { merchantTheme } from 'theme'

const Divider: React.FC = () => {
  const [css] = useStyletron()

  return (
    <hr
      className={`m-0 ${css({
        borderTop: '1px solid ' + merchantTheme.colors.grey30,
      })}`}
    />
  )
}

export default Divider
