import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { alert } from 'actions/flash'
import { DateRangeFilterProvider } from 'components/DateFilter/DateFilterContext'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { MessageCountMetric } from 'models/CampaignMetric'
import moment from 'moment-timezone'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { getCampaignReportingBounds } from 'scenes/CampaignCenter/Report/v3/helpers'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { automatedCampaignHelpUrl } from 'utilities/urlUtils'
import StatusInfo from './components/StatusInfo'
import Chart from './Sections/Chart'
import { getInitialDateFilter } from './Sections/Chart/helpers'
import Engagement from './Sections/Engagement'
import Header from './Sections/Header'
import Revenue from './Sections/RevenueCosts'
import Signups from './Sections/Signups'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type Props = {
  campaign: Campaign
}
const Summary: React.FC<Props> = ({ campaign }) => {
  const {
    end_at,
    id,
    redeem,
    redeem_type,
    state,
    start_at,
    test_ended_at,
    type,
    winning_variant_id,
    variants,
  } = campaign
  const dispatch = useDispatch()
  const messageCount =
    (useSelector(state =>
      selectCampaignMetricByType(state, id, 'message_count')
    ) as MessageCountMetric) || {}

  if (messageCount?.isErrored) {
    dispatch(
      alert({
        key: 'danger',
        message: I18n.t('errors.thanx'),
        error: '',
        displayDetails: false,
        hasReloadButton: true,
      })
    )
  }

  const delivered_count = messageCount?.data?.delivered_count || 0
  const redeemTypes =
    campaign.variants.length > 0
      ? campaign.variants.map(variant => variant.redeem_type)
      : [redeem_type]
  const timeZone = moment.tz.guess()
  const { attributionWindow } = useAttributionWindow()
  const filterBounds = useMemo(
    () =>
      getCampaignReportingBounds(
        campaign,
        timeZone,
        'summary',
        attributionWindow
      ),
    [campaign, timeZone, attributionWindow]
  )
  const initialFilter = useMemo(
    () => getInitialDateFilter(campaign, timeZone, attributionWindow),
    [campaign, timeZone, attributionWindow]
  )

  return (
    <div>
      <Text.Header3 tag="p" className="m-0 mt-s mb-xl">
        {t('summary')}
      </Text.Header3>
      <StatusInfo
        startAt={start_at}
        endAt={end_at}
        shouldShowActive={!delivered_count && !messageCount.isLoading}
        state={state}
      />
      <Header campaignId={id} campaignState={state} />
      <DateRangeFilterProvider
        initialFilter={initialFilter}
        bounds={filterBounds}
        timeZone={timeZone}
      >
        <Chart
          campaignId={id}
          campaignState={state}
          campaignType={type}
          startAt={start_at}
          endAt={end_at}
          testEndedAt={test_ended_at}
          winningVariantId={winning_variant_id}
          variants={variants}
        />
      </DateRangeFilterProvider>
      <Revenue
        campaignId={id}
        campaignState={state}
        redeem={redeem}
        redeemTypes={redeemTypes}
      />
      <Engagement campaignId={id} campaignState={state} />
      <Signups campaignId={id} campaignState={state} />
      <Switch condition={campaign.redeem}>
        <Text.BodyText4 color="grey70" tag="p" className="mb-m">
          {t('reward_count_warning_1')}
        </Text.BodyText4>
        <Text.BodyText4 color="grey70" tag="p" className="mb-m">
          {t('reward_count_warning_2')}
        </Text.BodyText4>
        <Text.BodyText4 color="grey70" tag="p" className="mb-xl">
          {t('reward_count_warning_3')}{' '}
          <a
            href={automatedCampaignHelpUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('learn_more')}
          </a>
        </Text.BodyText4>
      </Switch>
    </div>
  )
}

export default Summary
