import { buildTranslate } from 'locales'
import type { DayBucketCountData } from 'models/MerchantMetric'
import React from 'react'
import BucketMetric from 'scenes/Reports/ActivityReport/components/BucketMetric'
import { avgDayTrajectory } from 'scenes/Reports/ActivityReport/utilities'

const t = buildTranslate(
  'reports.activity.sections.rewards.metrics.redeemed_loyalty_rewards'
)

type Props = {
  data: DayBucketCountData
}

export const RedeemedLoyaltyRewards: React.FC<Props> = ({ data }) => {
  return (
    <BucketMetric
      title={t('title')}
      summaryText={t('summary_text')}
      bucketText={t('bucket_text')}
      keySuffix="count"
      metric={data}
      trajectoryCalculator={avgDayTrajectory}
    />
  )
}

export default RedeemedLoyaltyRewards
