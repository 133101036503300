import includes from 'lodash/includes'
import type { UserCountMetric } from 'models/CampaignMetric'
import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import View from 'scenes/CampaignCenter/Builder/components/AudienceBreakoutModalView'

type Props = {
  show: boolean
  userCountMetric?: UserCountMetric
  onModalCloseClicked: () => void
  campaignType: string
}

const AudienceBreakoutModal: React.FC<Props> = props => {
  const { userCountMetric, campaignType } = props
  const normalizedCampaignType = campaignType.split('-').pop()
  const isWeekPart = includes(normalizedCampaignType, 'week')

  function targetedItems() {
    return [
      {
        label: (
          <Translate
            value="thanx_campaigns.campaigns.timeshift.steps.review.audience.breakout_modal"
            dangerousHTML
            period={I18n.t(
              `thanx_campaigns.campaigns.timeshift.naming.${normalizedCampaignType}`
            )}
            conjunction={I18n.t(
              `thanx_campaigns.campaigns.timeshift.steps.setup.${
                isWeekPart ? 'weekpart' : 'daypart'
              }`
            )}
          />
        ),
        value: userCountMetric?.data?.member_count || 0,
      },
    ]
  }

  function excludedItems() {
    return [
      {
        label: I18n.t(
          'thanx_campaigns.review.customers.breakout_modal.table.excluded.excluded'
        ),
        value: userCountMetric?.data?.excluded_count || 0,
      },
    ]
  }

  return (
    <View
      {...props}
      targetedItems={targetedItems()}
      excludedItems={excludedItems()}
    />
  )
}

export default AudienceBreakoutModal
