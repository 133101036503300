import FetchableMetricSection from 'components/FetchableMetricSection'
import PageHeader from 'components/PageHeader'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { LifecycleRecentShortType, ShortType } from 'models/MerchantMetric'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import OneClickBox from 'scenes/Reports/ActivationRateReport/ActivationFunnel/DetailReport/components/OneClickBox'
import {
  stagesSegmentsMapping,
  stagesWithDetailedReport,
} from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { selectMerchantMetricByType } from 'selectors/merchantMetric'
import AllTimeConversion from './components/AllTimeConversion'
import CurrentConversion from './components/CurrentConversion'
import OverTimeConversionChart from './components/OverTimeConversionChart'

type Props = RouteComponentProps<{
  stage: typeof stagesWithDetailedReport[number]
}>

const t = buildTranslate('lifecycle_report.detail')

const DetailReport: React.FC<Props> = props => {
  const { stage } = props.match.params
  const segment = stagesSegmentsMapping[stage]

  const merchantId = useCurrentMerchant()?.id
  const segmentMetricType =
    `member_lifecycle_recent_state_${segment}` as LifecycleRecentShortType
  const memberLifecycleState = useSelector(state =>
    selectMerchantMetricByType(state, ShortType.LIFECYCLE)
  )
  const segmentLifecycleState = useSelector(state =>
    selectMerchantMetricByType(state, segmentMetricType)
  )
  const [timespan, setTimespan] = useState('6_months')

  return (
    <PageHeader title={t(stage)} backPath="/reports/lifecycle">
      <div className="grey-05-bkg border-top-1 pb-xxl grey-20-border mt-l">
        <Container className="pt-xl">
          <div className="pb-xxl">
            <FetchableMetricSection
              shouldFetch={!memberLifecycleState}
              type="lifecycle_metric"
              merchantId={merchantId}
              metricType={ShortType.LIFECYCLE}
            >
              <AllTimeConversion stage={stage} />
            </FetchableMetricSection>
          </div>
          <div className="pb-xxl">
            <FetchableMetricSection
              shouldFetch={!segmentLifecycleState}
              metricType={segmentMetricType}
              type="lifecycle_metric"
              merchantId={merchantId}
            >
              <CurrentConversion stage={stage} />
            </FetchableMetricSection>
          </div>
          <div className="pb-xxl">
            <FetchableMetricSection
              type="lifecycle_time_series_metric"
              shouldFetch
              merchantId={merchantId}
              metric={segment}
              timespan={timespan}
            >
              <OverTimeConversionChart
                stage={stage}
                timespan={timespan}
                setTimespan={setTimespan}
              />
            </FetchableMetricSection>
          </div>
          <OneClickBox stage={stage} />
        </Container>
      </div>
    </PageHeader>
  )
}

export default DetailReport
