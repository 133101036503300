import { Pagination as BasePagination } from 'baseui/pagination'
import React from 'react'
import { paginationOverrides } from './helpers'

type PropsT = {
  currentPage: number
  onChangePage: (page: number) => void
  numPages: number
}

const Pagination: React.FC<PropsT> = ({
  currentPage,
  onChangePage,
  numPages,
}: PropsT) => {
  return (
    <BasePagination
      currentPage={currentPage}
      onPageChange={({ nextPage }) => onChangePage(Math.max(nextPage, 1))}
      numPages={numPages}
      overrides={paginationOverrides}
    />
  )
}

export default Pagination
