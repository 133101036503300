import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'

import birthdayImage from 'assets/images/campaign-center/opportunity/birthday/email.png'
import bouncebackImage from 'assets/images/campaign-center/opportunity/bounceback/email.png'
import referralsImage from 'assets/images/campaign-center/opportunity/referrals/email.png'
import reputationImage from 'assets/images/campaign-center/opportunity/reputation/email.png'
import tiersImage from 'assets/images/campaign-center/opportunity/tiers/email.png'
import vipImage from 'assets/images/campaign-center/opportunity/vip/email.png'
import winbackImage from 'assets/images/campaign-center/opportunity/winback/email.png'

type Props = {
  campaignType: string
}

const HowItWorks: React.FC<Props> = ({ campaignType }) => {
  let emailImage
  switch (campaignType) {
    case 'reputation':
      emailImage = reputationImage
      break
    case 'winback':
      emailImage = winbackImage
      break
    case 'bounceback':
      emailImage = bouncebackImage
      break
    case 'birthday':
      emailImage = birthdayImage
      break
    case 'tiers':
      emailImage = tiersImage
      break
    case 'vip':
      emailImage = vipImage
      break
    case 'referrals':
      emailImage = referralsImage
      break
    default:
      break
  }

  return (
    <div>
      <div className="display-flex flex-justify-center flex-wrap flex-align-center">
        <div className="col-md-4 col-sm-12 padding-large padding-top-huge">
          <Translate
            tag="h5"
            className="blue-chrome-60"
            value={`thanx_campaigns.opportunity.${campaignType}.how_it_works.header`}
          />
          <Translate
            tag="div"
            className="grey-70 font-size-16"
            value={`thanx_campaigns.opportunity.${campaignType}.how_it_works.text`}
            dangerousHTML
          />
        </div>
        <div
          className="padding-right-large padding-left-large relative"
          style={{ bottom: '-50px' }}
        >
          <img
            src={emailImage}
            style={{ height: '328px', width: 'auto' }}
            className="img-responsive"
            alt={I18n.t(
              `thanx_campaigns.opportunity.${campaignType}.how_it_works.image_alt`
            )}
          />
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
