import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Row } from 'react-bootstrap-five'
import { useIsMobile } from 'utilities/responsive'

const t = buildTranslate('thanx_campaigns.landing.segments')

type Props = {
  onNewClick: () => void
}

const Header: React.FC<Props> = ({ onNewClick }) => {
  const isMobile = useIsMobile()

  return (
    <>
      <Row className="pb-xl">
        <Col md={7}>
          <Text.Header3 tag="div">{t('header.title')}</Text.Header3>
          <Text.BodyText3 color="grey70" tag="div">
            {t('header.subtitle')}
          </Text.BodyText3>
        </Col>
        <Col
          className={`d-flex align-items-center ${
            isMobile ? 'pt-m' : 'justify-content-end'
          }`}
        >
          <Button onClick={onNewClick}>{t('header.new')}</Button>
        </Col>
      </Row>
    </>
  )
}

export default Header
