import numeral from 'numeral'
import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'

const Info: React.FC<{ varied: number }> = ({ varied }) => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Popover id="popover-trigger-hover">
          <Translate
            value="thanx_campaigns.campaigns.timeshift.steps.setup.stat.info"
            varied={numeral(varied).format('0,0')}
          />
        </Popover>
      }
    >
      <i className="fa fa-info-circle white absolute right-0 margin-right-large opacity-75 cursor-help" />
    </OverlayTrigger>
  )
}

const Stat: React.FC<{
  amount: number
  period: string
  varied: number
  isWeekPart: boolean
}> = props => {
  const { amount, period, varied, isWeekPart } = props
  return (
    <div className="category-targeted-bkg padding-large border-radius-5 white relative">
      {Info({ varied })}
      <Translate
        value="thanx_campaigns.campaigns.timeshift.steps.setup.stat.supertitle"
        className="small-caps-5 margin-bottom-large"
        tag="p"
      />
      <Translate
        value="thanx_campaigns.campaigns.timeshift.steps.setup.stat.message"
        className="margin-bottom-large font-size-32 line-height-40"
        tag="p"
        amount={numeral(amount).format('0,0')}
        conjunction={I18n.t(
          `thanx_campaigns.campaigns.timeshift.steps.setup.${
            isWeekPart ? 'weekpart' : 'daypart'
          }`
        )}
        period={I18n.t(`thanx_campaigns.campaigns.timeshift.naming.${period}`)}
        dangerousHTML
      />
    </div>
  )
}

export default Stat
