import { FormsySelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import useHiddenMenus from 'hooks/useHiddenMenus'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { InputGroup } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { useFormModel } from 'scenes/RedeemManager/Builder/FormModel'

type Props = PassDownProps<string[]> & {
  className?: string
}

type Option = {
  label: string | undefined
  value: string | string[] | undefined
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.online_redemption_card.hidden_menu'
)

const CategorySelect: React.FC<Props> = props => {
  const { name, value, setValue, className = '' } = props
  const { hiddenMenuKey, hiddenMenuName } = useFormModel().model

  useEffect(() => {
    if (!value && hiddenMenuKey) setValue(hiddenMenuKey as string[])
  }, [hiddenMenuKey, setValue, value])
  const { categories, isLoading, showDisabled } = useHiddenMenus()
  const [options, setOptions] = useState<Option[]>([])
  useEffect(() => {
    if (isEmpty(categories)) {
      if (value) {
        setOptions([
          {
            label: hiddenMenuName,
            value: hiddenMenuKey?.[0],
          },
        ])
      } else {
        setOptions([])
      }
    } else {
      const newCategories: Option[] = categories.map(c => ({
        label: c.name,
        value: c.ids.join(','),
      }))
      if (!isEmpty(value)) {
        const hiddenMenu = newCategories.find(c => c.value === value?.[0])
        // If categories return but don't contain the existing value
        if (!hiddenMenu) {
          setValue([])
        }
      }
      setOptions(newCategories)
    }
  }, [categories, hiddenMenuKey, hiddenMenuName, setValue, value])
  const [isFocused, setIsFocused] = useState(false)
  const [css] = useStyletron()
  const hiddenMenu = options.find(c => c.value === value?.[0])
  const isDisabled = showDisabled && options.length === 0
  const placeholder = isDisabled
    ? t('no_categories')
    : isFocused
    ? t('search')
    : t('select_category')
  return (
    <div className={className}>
      <Text.BodyText4 bold>{t('categories')}</Text.BodyText4>
      <div className="mt-xs d-flex flex-row">
        <Switch condition={isFocused}>
          <InputGroup.Addon
            className={css({
              width: '46px',
              height: '48px',
            })}
          >
            <i className="fa fa-search pt-xxs"></i>
          </InputGroup.Addon>
        </Switch>
        <FormsySelect
          // @ts-ignore
          disabled={isDisabled}
          className="width-100"
          name={name}
          placeholder={placeholder}
          maxDropdownHeight="400px"
          multi={false}
          isLoading={isLoading}
          options={options}
          value={hiddenMenu?.value}
          onChange={e => {
            setValue([e])
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
          }}
        />
      </div>
    </div>
  )
}

export default withFormsy(CategorySelect)
