import { Text } from '@thanx/uikit/text'
import { Config } from 'api/onboardingSlideshow'
import ColorPicker from 'components/Form/ColorPicker'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import CmsPage from 'scenes/Cms/components/CmsPage'

const t = buildTranslate('cms.signup.onboarding_slideshow.style')

const controllerKeys = [
  'enabled_arrow_color',
  'disabled_arrow_color',
  'active_dot_color',
  'inactive_dot_color',
]

type Props = {
  config: Config
  sidebar: React.ReactNode
  isFetching: boolean
}

const OnboardingSlideshowStyle: React.FC<Props> = ({
  config,
  sidebar,
  isFetching,
}) => {
  return (
    <CmsPage
      title={t('subtitle')}
      description={t('description')}
      sidebar={sidebar}
    >
      <div className={'p-m white-bkg'}>
        <Text.Header4 tag={'p'} color="grey90">
          {t('controller_style.title')}
        </Text.Header4>
        <Spinner
          isLoading={isFetching}
          size={'3x'}
          className={'d-flex justify-content-center mt-m'}
        >
          {controllerKeys.map(key => (
            <ColorPicker
              className={'mt-m'}
              key={key}
              name={key}
              label={t(`controller_style.${key}`)}
              validations={{
                hex: true,
                isRequired: true,
              }}
              value={config.controller?.[key]}
              validationErrors={{
                hex: I18n.t('validations.hex.message'),
                isRequired: I18n.t('validations.is_required'),
              }}
              startEnhancer
            />
          ))}
        </Spinner>
      </div>
    </CmsPage>
  )
}

export default OnboardingSlideshowStyle
