import { RESET_DB } from 'actions/orm'
import { GET_TIERS_SUCCESS, ResolvedAction } from 'actions/tiers'
import { attr, fk, Model, ModelType } from 'redux-orm'
import { ImageUrlsT } from 'utilities/types'

export type TierType = 'tier_bronze' | 'tier_silver' | 'tier_gold'
export interface Fields {
  id: number
  merchant_id: number
  type: TierType
  name: string
  description: string
  user_count: number
  requirements: string
  color: string
  icon_image_urls?: ImageUrlsT
  background_image_urls?: ImageUrlsT
  progress_paddle_image_urls?: ImageUrlsT
}

// @ts-ignore
export default class Tier extends Model<typeof Tier, Fields> {
  static modelName: string = 'Tier'

  static get fields(): any {
    return {
      id: attr(),
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'tiers',
      }),
      type: attr(),
      name: attr(),
      description: attr(),
      user_count: attr(),
      requirements: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Tier: ModelType<Tier>
  ) {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case GET_TIERS_SUCCESS:
        action.payload.data.tiers.forEach(tier => {
          return Tier.upsert(tier)
        })
        break
      case RESET_DB:
        Tier.all().delete()
        break
      default:
        break
    }
  }
}
