import { Switch } from '@thanx/uikit/switch'
import React from 'react'
import PopoverInfo from './PopoverInfo'

type Props = {
  label: string
  value: React.ReactNode | null
  tooltip?: string
  className?: string
}

const Info: React.FC<Props> = props => {
  const { label, value, tooltip, className = '' } = props

  if (!value) return null

  return (
    <div className={className}>
      <p className="bold font-size-14">
        {label}
        <Switch condition={!!tooltip}>
          <PopoverInfo className="ml-xs" text={tooltip} />
        </Switch>
      </p>
      <Switch condition={typeof value === 'string'} fallback={value}>
        <p className="grey-70">{value}</p>
      </Switch>
    </div>
  )
}

export default Info
