import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { ContentBlockTypes } from 'api/landingPageTheme'
import { buildTranslate } from 'locales'
import React from 'react'
import Title from './Title'

const t = buildTranslate('cms.content.app_home_page.content.block_item')

type Props = {
  type: ContentBlockTypes
  onEdit: () => void
}

const Header = ({ type, onEdit }: Props) => {
  return (
    <div className="d-flex align-items-center justify-content-between mb-s">
      <Title type={type} />
      <div className="d-flex">
        <Button onClick={onEdit} kind={Kind.MINIMAL} className="p-0">
          <Text.BodyText3 tag="span" className="ml-xs mb-0" color="primary">
            {t('edit')}
          </Text.BodyText3>
        </Button>
      </div>
    </div>
  )
}

export default Header
