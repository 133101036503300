import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

const t = buildTranslate('reports.reward_activity.tabs.campaign_activity')

const CampaignActivityTab: React.FC = () => {
  const merchant = useCurrentMerchant()
  const [css] = useStyletron()

  if (!merchant) return null

  const path = 'dashboards/92'
  const height = 1150

  return (
    <>
      <Text.Header3 className="mt-0 mb-xl" tag="div">
        {t('title')}
      </Text.Header3>
      <Container className={`px-0 ${css({ minHeight: `${height + 300}px` })}`}>
        <LookerIframe path={path} merchant={merchant} height={`${height}px`} />
        <LookerFooter className="my-xl" />
      </Container>
    </>
  )
}

export default CampaignActivityTab
