import React from 'react'
type PropsT = {
  color: string
}

const InfoIcon: React.FC<PropsT> = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47754 2 2 6.47915 2 12C2 17.5241 6.47754 22 12 22C17.5225 22 22 17.5241 22 12C22 6.47915 17.5225 2 12 2ZM12 20.0645C7.54306 20.0645 3.93548 16.4584 3.93548 12C3.93548 7.54456 7.54323 3.93548 12 3.93548C16.4553 3.93548 20.0645 7.54319 20.0645 12C20.0645 16.4569 16.4584 20.0645 12 20.0645ZM12 6.43548C12.9353 6.43548 13.6935 7.19371 13.6935 8.12903C13.6935 9.06435 12.9353 9.82258 12 9.82258C11.0647 9.82258 10.3065 9.06435 10.3065 8.12903C10.3065 7.19371 11.0647 6.43548 12 6.43548ZM14.2581 16.6774C14.2581 16.9446 14.0414 17.1613 13.7742 17.1613H10.2258C9.95859 17.1613 9.74194 16.9446 9.74194 16.6774V15.7097C9.74194 15.4425 9.95859 15.2258 10.2258 15.2258H10.7097V12.6452H10.2258C9.95859 12.6452 9.74194 12.4285 9.74194 12.1613V11.1935C9.74194 10.9263 9.95859 10.7097 10.2258 10.7097H12.8065C13.0737 10.7097 13.2903 10.9263 13.2903 11.1935V15.2258H13.7742C14.0414 15.2258 14.2581 15.4425 14.2581 15.7097V16.6774Z"
        fill={color}
      />
    </svg>
  )
}

export default InfoIcon
