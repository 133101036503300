import { Switch } from '@thanx/uikit/switch'
import ImageHelperText from 'components/Form/ImageHelperText'
import ImageValidationMessage from 'components/Form/ImageValidationMessage'
import Input from 'components/Form/Input'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'

type Props = {
  imageUrl: string | undefined
  linkUrl: string
  linkInputName: string
  title: string
  height?: number
  width?: number
  minWidth?: number
  minHeight?: number
  maxHeight?: number
  maxWidth?: number
  onClick?: () => void
  isValid?: boolean
  uploadHeight?: number
  uploadWidth?: number
  isLoading?: boolean
  setLoading?: (value: boolean) => void
}

const ImageControls: React.FC<Props> = props => {
  const {
    imageUrl,
    linkUrl,
    linkInputName,
    title,
    height,
    width,
    minWidth,
    minHeight,
    maxHeight,
    maxWidth,
    onClick,
    isValid,
    uploadHeight = 0,
    uploadWidth = 0,
    isLoading,
    setLoading,
  } = props

  const [linkModalOpen, setLinkModalOpen] = useState(false)
  const previewImage = useRef<HTMLImageElement>(null)
  let linkInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!!previewImage.current) {
      //@ts-ignore
      previewImage.current.onload = () => {
        if (setLoading) {
          setLoading(false)
        }
      }
      //@ts-ignore
      previewImage.current.onerror = () => {
        if (setLoading) {
          setLoading(false)
        }
      }
    }
  }, [setLoading])

  function closeModal() {
    //@ts-ignore
    if (linkInput.props.isValid) setLinkModalOpen(false)
  }

  function clearLink() {
    //@ts-ignore
    linkInput.clear()
  }

  function onUploadImage() {
    if (!!onClick) onClick()
  }

  return (
    <Row>
      <Col sm={4} className="text-center">
        <div className="grey-10-bkg relative">
          <img
            src={imageUrl}
            alt={title}
            className={`img-responsive center-block cursor-pointer ${
              isLoading ? 'opacity-25' : ''
            }`}
            ref={previewImage}
            onClick={onUploadImage}
          />
          <Switch condition={!!isLoading}>
            <div className="absolute left-0 top-0 full-width full-height">
              <div className="display-flex full-width full-height flex-justify-center flex-align-center">
                <i className="fa fa-spin fa-circle-o-notch grey-70 fa-2x" />
              </div>
            </div>
          </Switch>
        </div>
        <em className="grey-50 margin-top-tiny inline-block">
          <ImageHelperText
            width={width}
            height={height}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            minWidth={minWidth}
            minHeight={minHeight}
            prefix="Image must be "
          />
        </em>
      </Col>
      <Col sm={8}>
        <ImageValidationMessage
          isValid={!!isValid}
          imageWidth={uploadWidth}
          imageHeight={uploadHeight}
          width={width}
          height={height}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          minWidth={minWidth}
          minHeight={minHeight}
        />
        <div>
          <Button
            bsStyle="link"
            bsSize="large"
            className="padding-left-none"
            onClick={onUploadImage}
          >
            <Translate value="thanx_campaigns.builder.forms.email.image_control.upload" />
          </Button>
          <Button
            bsStyle="link"
            bsSize="large"
            onClick={() => setLinkModalOpen(true)}
          >
            <Translate value="thanx_campaigns.builder.forms.email.image_control.link" />
          </Button>

          <div className="modal-container">
            <Input
              type="hidden"
              name={linkInputName}
              trimValue={true}
              value={linkUrl}
              validations={{ isUrl: true }}
              validationErrors={{ isUrl: I18n.t('validations.is_url') }}
            />
            <Modal
              show={linkModalOpen}
              onHide={closeModal}
              className="fs-unmask"
            >
              <Modal.Body>
                <div className="clearfix">
                  <div className="pull-right">
                    <Button
                      onClick={closeModal}
                      className="btn-borderless padding-right-none"
                    >
                      <span className="font-size-40 line-height-30 grey-60 light">
                        &times;
                      </span>
                    </Button>
                  </div>
                </div>

                <h6 className="text-center margin-bottom-large">
                  <Translate value="thanx_campaigns.builder.forms.email.image_control.link" />
                  <span>&nbsp;to the&nbsp;</span>
                  {title}
                </h6>
                <Row className="margin-bottom-large">
                  <Col xs={10}>
                    <Input
                      name={linkInputName}
                      value={linkUrl}
                      placeholder={I18n.t('defaults.placeholders.url')}
                      trimValue={true}
                      validations={{ isUrl: true }}
                      validationErrors={{ isUrl: I18n.t('validations.is_url') }}
                      innerRef={ref =>
                        ((linkInput as React.Ref<HTMLInputElement>) = ref)
                      }
                    />
                  </Col>
                  <Col xs={2}>
                    <Button bsSize="large" onClick={clearLink}>
                      <Translate value="thanx_campaigns.builder.forms.email.image_control.modal.clear_btn" />
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={10} />
                  <Col xs={2}>
                    <Button
                      onClick={closeModal}
                      bsStyle="primary"
                      bsSize="large"
                    >
                      <Translate value="thanx_campaigns.builder.forms.email.image_control.modal.primary_btn" />
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </div>
        </div>
        <Switch condition={!!linkUrl}>
          <span>
            <Translate
              value="thanx_campaigns.builder.forms.email.image_control.image_link"
              dangerousHTML
            />
            <span className="peter-river">{linkUrl}</span>
          </span>
        </Switch>
      </Col>
    </Row>
  )
}

export default ImageControls
