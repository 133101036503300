import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import Header from 'scenes/Cms/components/Header'
import { getExtension } from 'scenes/Cms/helper'
import { selectApp } from 'selectors/app'
import { cmsBrandedAppConfigUrl } from 'utilities/urlUtils'
import Asset from '../Asset'
import ImageAsset from '../ImageAsset'

const t = buildTranslate('cms.brand.app_store_assets')

const AppIcon: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const { icon_image_urls } = app

  return (
    <Section className="p-xl mt-l">
      <Header
        title={t('app_icon.title')}
        linkText={t('where_customers')}
        link={cmsBrandedAppConfigUrl}
      />
      <Asset
        className="mt-s"
        description={t('app_icon.description')}
        badges={[t('app_icon.size'), getExtension(icon_image_urls?.small)]}
      >
        <ImageAsset image={icon_image_urls?.small} />
      </Asset>
    </Section>
  )
}

export default AppIcon
