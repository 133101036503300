import { useStyletron } from '@thanx/uikit/theme'
import Input from 'components/Form/Input'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import HoursDaysSelector from 'scenes/CampaignCenter/Builder/components/Incentive/RestrictionCreator/NdrRewardRestrictions/HoursDaysSelector'
import RewardLength from '../RewardLength'

type PropsT = {
  campaign: Campaign
  config: VariantOrCampaign
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator.golden_ticket'
)

const GoldenTicketRestrictions: React.FC<PropsT> = props => {
  const { campaign, config } = props
  const [css] = useStyletron()
  const isMessage = campaign.type === 'message'
  // This value is set on backend for message type but to make validation work we need this
  const value = isMessage ? '14' : campaign.redeem_retire_after_days
  return (
    <>
      <div className="border-1 grey-30-border border-radius-5 p-m mb-m">
        <div className="font-size-18 bold mb-m">{t('activate.title')}</div>
        <div className="d-flex flex-row grey-70 align-items-baseline">
          <span>{t('activate.info')}</span>
          <Input
            className="inline-block col-xs-2 px-s"
            disabled={isMessage}
            name="redeem_retire_after_days"
            value={value}
            placeholder="14"
            trimValue={true}
            inputProps={{
              className: `grey-70 ${css({
                fontSize: '16px !important',
              })}`,
            }}
            validations={{
              greaterThan: 0,
              isNumeric: true,
              isRequired: true,
            }}
            validationErrors={{
              greaterThan: I18n.t('validations.greater_than', {
                value: 0,
              }),
              isNumeric: I18n.t('validations.is_numeric'),
              isRequired: I18n.t('validations.is_required'),
            }}
          />
          <span>{t('activate.info2')}</span>
        </div>
      </div>
      <RewardLength
        campaign={campaign}
        notRollingName="redeem_experience_expires_in_days"
        rollingTimeInput={
          <HoursDaysSelector
            name="redeem_experience_expires_in_days"
            days={config.redeem_experience_expires_in_days}
          />
        }
        t={buildTranslate(
          'thanx_campaigns.builder.steps.incentive.restriction_creator.golden_ticket.after_activate'
        )}
      />
    </>
  )
}

export default GoldenTicketRestrictions
