import useIsDemo from 'hooks/useIsDemo'
import React, { ReactElement } from 'react'
import { Nav, NavItem, OverlayTrigger, Tab, Tooltip } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'

type Props = {
  children: React.ReactNode
  navItems?: React.ReactNode
  emailOnly: boolean
  tabClick?: (tab: string) => void
  showTooltips?: boolean
  disabled?: boolean
  showValidationErrors?: () => void
  triggerValidationOnClick?: boolean
  className: string
}

const MessageTab: React.FC<Props> = props => {
  const {
    children,
    navItems,
    emailOnly = false,
    showTooltips = false,
    disabled = false,
    showValidationErrors,
    triggerValidationOnClick = false,
    className = '',
  } = props
  const isDemo = useIsDemo()

  function tooltip(key: string): React.ReactNode {
    if (!disabled && key === 'all') {
      return <Tooltip id={`${key}-tooltip`} bsClass="hide" />
    }
    return (
      <Tooltip id={`${key}-tooltip`}>
        <Translate
          value={`thanx_campaigns.builder.forms.messaging_tabs.disabled.${key}`}
        />
      </Tooltip>
    )
  }

  function navItem(
    key: string,
    item: ReactElement<typeof NavItem>
  ): ReactElement<typeof NavItem> | ReactElement<typeof OverlayTrigger> {
    if (!emailOnly || !showTooltips) return item
    return (
      <OverlayTrigger placement="top" overlay={tooltip(key)}>
        {item}
      </OverlayTrigger>
    )
  }

  function disabledProps() {
    if (disabled) return { disabled: true }
    if (showTooltips) {
      return emailOnly ? { className: 'disabled' } : {}
    }

    return emailOnly ? { disabled: true } : {}
  }

  function tabClick(currentTab: string) {
    if (triggerValidationOnClick && showValidationErrors) {
      showValidationErrors()
    }

    if (props.tabClick) props.tabClick(currentTab)
  }

  function nav() {
    return (
      <OverlayTrigger placement="top" overlay={tooltip('all')}>
        <Nav bsClass="btn-nav margin-bottom-none" className="font-lato">
          <NavItem eventKey="email" disabled={disabled}>
            <i className="fa fa-envelope-open-o margin-right-small font-size-20 vertical-middle" />
            <Translate
              value="thanx_campaigns.builder.forms.messaging_tabs.email"
              className="vertical-middle small-caps font-size-12"
            />
          </NavItem>

          {navItem(
            'push',
            <NavItem eventKey="push" {...disabledProps()}>
              <i className="fa fa-mobile relative nudge-down margin-right-small font-size-24 vertical-middle" />
              <Translate
                value="thanx_campaigns.builder.forms.messaging_tabs.push"
                className="vertical-middle small-caps font-size-12"
              />
            </NavItem>
          )}

          {isDemo
            ? null
            : navItem(
                'sms',
                <NavItem eventKey="sms" {...disabledProps()}>
                  <i className="fa fa-commenting-o margin-right-small font-size-20 vertical-middle" />
                  <Translate
                    value="thanx_campaigns.builder.forms.messaging_tabs.sms"
                    className="vertical-middle small-caps font-size-12"
                  />
                </NavItem>
              )}
        </Nav>
      </OverlayTrigger>
    )
  }

  return (
    <Tab.Container
      id="message-tab"
      defaultActiveKey="email"
      onSelect={tabClick}
    >
      <div>
        <div
          className={`display-flex flex-align-center flex-space-between margin-bottom-small ${className}`}
        >
          {nav()}
          {navItems}
        </div>
        {children}
      </div>
    </Tab.Container>
  )
}

export default MessageTab
