import offerIcon from 'assets/images/campaign-center/ic_star_checkmark.png'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { textUtils } from 'utilities/textUtils'

type Props = {
  campaign: Campaign
}

const LoyaltyReward: React.FC<Props> = ({ campaign }) => {
  return (
    <div className="border-bottom-1 grey-30-border padding-medium">
      <Row className="margin-bottom-medium align-items-center">
        <Col xs={2}>
          <img src={offerIcon} alt="offer" className="reward-icon" />
        </Col>
        <Col xs={10}>
          <div className="d-flex flex-column">
            <div className="font-size-14">
              {textUtils.initialCapital(campaign.redeem_description)}
            </div>
            <div className="font-size-14 grey-40">
              {textUtils.initialCapital(campaign.earn_description)}
              <span className="text-right pull-right">25%</span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <span className="block grey-20-bkg full-width">
            <span className="block spearmint-bkg padding-top-tiny width-25" />
          </span>
        </Col>
      </Row>
    </div>
  )
}

export default LoyaltyReward
