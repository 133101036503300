import { Fields } from 'models/FileUpload'
import { ValidationTypes } from 'models/Validation'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export enum SourcePath {
  COUPON_POOLS = 'coupon_pools',
  MERCHANTS = 'merchants',
}

export const GET_FILE_UPLOADS = 'fileUpload/GET_ALL'
export const GET_FILE_UPLOADS_SUCCESS = 'fileUpload/GET_ALL_SUCCESS'
export const GET_FILE_UPLOADS_FAIL = 'fileUpload/GET_ALL_FAIL'

export const GET_FILE_UPLOAD = 'fileUpload/GET'
export const GET_FILE_UPLOAD_SUCCESS = 'fileUpload/GET_SUCCESS'
export const GET_FILE_UPLOAD_FAIL = 'fileUpload/GET_FAIL'

export const CREATE_FILE_UPLOAD = 'fileUpload/CREATE'
export const CREATE_FILE_UPLOAD_SUCCESS = 'fileUpload/CREATE_SUCCESS'
export const CREATE_FILE_UPLOAD_FAIL = 'fileUpload/CREATE_FAIL'

export const PROCESS_FILE_UPLOAD = 'fileUpload/PROCESS'
export const PROCESS_FILE_UPLOAD_SUCCESS = 'fileUpload/PROCESS_SUCCESS'
export const PROCESS_FILE_UPLOAD_FAIL = 'fileUpload/PROCESS_FAIL'

export function getAllFileUploads(
  source_path: SourcePath,
  source_id: number
): AxiosAction<typeof GET_FILE_UPLOADS, { file_uploads: Fields[] }> {
  return {
    type: GET_FILE_UPLOADS,
    payload: {
      client: 'default',
      request: {
        url: `${source_path}/${source_id}/file_uploads`,
        method: 'get',
      },
    },
  }
}

export function getFileUpload(
  source_path: SourcePath,
  source_id: number,
  id: number
): AxiosAction<typeof GET_FILE_UPLOAD, { file_upload: Fields }> {
  return {
    type: GET_FILE_UPLOAD,
    payload: {
      client: 'default',
      request: {
        url: `${source_path}/${source_id}/file_uploads/${id}`,
        method: 'get',
      },
    },
  }
}

interface CreatePayload {
  file_type: string // Extension e.g. 'csv'
  acl?: 'private' | 'public_read' // Defaults to `private`
  settings?: Record<string, any>
  record_type?: ValidationTypes
}

export function createFileUpload(
  source_path: SourcePath,
  source_id: number,
  payload: CreatePayload
): AxiosAction<typeof CREATE_FILE_UPLOAD, { file_upload: Fields }> {
  const { file_type, acl, settings = {}, record_type } = payload
  return {
    type: CREATE_FILE_UPLOAD,
    payload: {
      client: 'default',
      request: {
        url: `${source_path}/${source_id}/file_uploads`,
        method: 'post',
        data: {
          file_upload: {
            file_type,
            acl,
            settings,
            record_type,
          },
        },
      },
    },
  }
}

export function processFileUpload(
  source_path: SourcePath,
  source_id: number,
  id: number
): AxiosAction<typeof PROCESS_FILE_UPLOAD, { file_upload: Fields }> {
  return {
    type: PROCESS_FILE_UPLOAD,
    payload: {
      client: 'default',
      request: {
        url: `${source_path}/${source_id}/file_uploads/${id}/actions/process`,
        method: 'patch',
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getAllFileUploads>>
  | ResolvedAxiosAction<ReturnType<typeof getFileUpload>>
  | ResolvedAxiosAction<ReturnType<typeof createFileUpload>>
  | ResolvedAxiosAction<ReturnType<typeof processFileUpload>>
