import { defaultTheme } from '@thanx/uikit/theme'
import ConfirmModal from 'components/ConfirmModal'
import { buildTranslate } from 'locales'
import React from 'react'
import { textUtils } from 'utilities/textUtils'

type PropsT = {
  isOpen: boolean
  isSubmitting: boolean
  selectionAction: string | null
  onClose: () => void
  onConfirm: () => void
}

const t = buildTranslate('ordering_management.location_list.modal')

const ActionModal: React.FC<PropsT> = ({
  isOpen,
  isSubmitting,
  selectionAction,
  onClose,
  onConfirm,
}) => {
  let htmlContent = t(`content_${selectionAction}`)
  if (selectionAction === 'disable') {
    htmlContent = `
      ${htmlContent}
      <div style="color: ${defaultTheme.colors.negative}" class="mt-m">
        ${t('disable_warning')}
      </div>
    `
  }
  const content = {
    title: t('title', {
      action: textUtils.initialCapital(selectionAction || ''),
    }),
    description: htmlContent,
    confirmText: t('confirm', {
      action: textUtils.initialCapital(selectionAction || ''),
    }),
  }
  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      isJustified
      isLoading={isSubmitting}
      onConfirm={onConfirm}
      {...content}
    />
  )
}

export default ActionModal
