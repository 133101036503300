import ProtectedPageHeader from 'components/ProtectedPageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import CampaignActivityTab from './CampaignActivityTab'
import EstimatedCostsTab from './EstimatedCostsTab'
import RewardUsageTab from './RewardUsageTab'

const t = buildTranslate('reports.reward_activity')
enum Tabs {
  REWARD_USAGE = 'reward_usage',
  CAMPAIGN_ACTIVITY = 'campaign_activity',
  ESTIMATED_COSTS = 'estimated_costs',
}

const RewardActivityReport: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    'reward_usage' | 'campaign_activity' | 'estimated_costs'
  >('reward_usage')
  const pageTitle = t(`tabs.${activeTab}.title_tag`)

  return (
    <ProtectedPageHeader
      title={t('title')}
      resource="LocationReports"
      action="access"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Tab.Container
        activeKey={activeTab}
        transition={false}
        onSelect={(tab: string | null) => {
          if (!tab) return
          setActiveTab(tab as Tabs)
        }}
      >
        <TabNav>
          <TabItem
            state={Tabs.REWARD_USAGE}
            title={
              <div className="d-flex align-content-center">
                <span className="mr-xxs">{t('tabs.reward_usage.title')}</span>
              </div>
            }
          />
          <TabItem
            state={Tabs.CAMPAIGN_ACTIVITY}
            title={t('tabs.campaign_activity.title')}
          />
          <TabItem
            state={Tabs.ESTIMATED_COSTS}
            title={t('tabs.estimated_costs.title')}
          />
        </TabNav>
        <Tab.Content className="pb-l h-100 grey-05-bkg">
          <TabPane state={Tabs.REWARD_USAGE}>
            <RewardUsageTab />
          </TabPane>
          <TabPane state={Tabs.CAMPAIGN_ACTIVITY}>
            <CampaignActivityTab />
          </TabPane>
          <TabPane state={Tabs.ESTIMATED_COSTS}>
            <EstimatedCostsTab />
          </TabPane>
        </Tab.Content>
      </Tab.Container>
    </ProtectedPageHeader>
  )
}

export default RewardActivityReport
