import moment from 'moment-timezone'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

type Props = {
  initialActive: boolean
  day: string
  onChange: (day: string, active: boolean) => void
  isLastDay: boolean
}
const Day: React.FC<Props> = props => {
  const { initialActive, day, onChange, isLastDay } = props
  const [active, setActive] = useState(initialActive)

  function onClick() {
    if (isLastDay) return
    if (!!onChange) {
      onChange(day, !active)
    }
    setActive(!active)
  }

  return (
    <Button
      className={`full-width ${active ? 'active' : ''}`}
      onClick={() => onClick()}
    >
      <span className="hidden-xs">{moment().day(day).format('ddd')}</span>
      <span className="visible-xs">{moment().day(day).format('dd')}</span>
    </Button>
  )
}

export default Day
