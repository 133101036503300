import fromEntries from 'core-js-pure/es/object/from-entries'

export const timeUtils = {
  hourLabel: function (
    hour: number = 0,
    isEnd: boolean = false,
    timezone?: string
  ): string {
    let postfix = isEnd ? ':59' : ':00'
    let label = ''
    let tzLabel = timezone ? ` ${timezone}` : ''
    switch (hour) {
      case 0:
        if (isEnd) {
          label = `12:59am${tzLabel}`
        } else {
          label = 'Midnight'
        }
        break
      case 12:
        if (isEnd) {
          label = `12:59pm${tzLabel}`
        } else {
          label = 'Noon'
        }
        break
      default:
        if (hour < 12) {
          label = `${hour}${postfix}am${tzLabel}`
        } else {
          label = `${hour - 12}${postfix}pm${tzLabel}`
        }
    }

    return label
  },
}

export const fractionalDays = (inverted = false) => {
  const hours = [...Array.from(Array(23).keys()).map(i => i + 1)]
  const hoursOptions = hours.map(hour => {
    if (inverted) return [Number((hour / 24).toFixed(4)), hour]
    return [hour, Number((hour / 24).toFixed(4))]
  })
  return fromEntries(hoursOptions)
}
