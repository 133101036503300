import { useStyletron } from '@thanx/uikit/theme'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as UserReward } from 'models/UserReward'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.view.activity.rewards')

type PropsT = {
  rewards: Array<UserReward>
  onPressRetire: (reward: UserReward) => void
  onPressReissue: (reward: UserReward) => void
}

const Table: React.FC<PropsT> = ({
  rewards,
  onPressRetire,
  onPressReissue,
}: PropsT) => {
  const [css] = useStyletron()

  return (
    <table
      className={`full-width ${css({
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
      })}`}
    >
      <thead>
        <tr>
          <HeaderCell
            className={`${css({
              width: '40%',
            })}`}
          >
            {t('reward')}
          </HeaderCell>
          <HeaderCell
            className={`${css({
              width: '15%',
            })}`}
          >
            {t('delivered_at')}
          </HeaderCell>
          <HeaderCell
            className={`${css({
              width: '15%',
            })}`}
          >
            {t('redeemed_at')}
          </HeaderCell>
          <HeaderCell
            className={`${css({
              width: '15%',
            })}`}
          >
            {t('status')}
          </HeaderCell>
          <HeaderCell
            className={`${css({
              width: '15%',
            })}`}
          />
        </tr>
      </thead>
      <tbody>
        {rewards.map(reward => (
          <Row
            key={reward.id}
            reward={reward}
            onPressRetire={onPressRetire}
            onPressReissue={onPressReissue}
          />
        ))}
      </tbody>
    </table>
  )
}

export default Table
