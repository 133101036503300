import React from 'react'

type Props = {
  className?: string
  value: React.ReactNode
}

const Badge = ({ className, value }: Props) => {
  if (!value) return null

  return (
    <div className={`py-xxs px-xs rounded small-caps-3 d-inline ${className}`}>
      {value}
    </div>
  )
}

export default Badge
