import { Text } from '@thanx/uikit/text'
import Modal from 'components/ModalContainer'
import Pagination from 'components/Pagination'
import usePaginateArray from 'hooks/usePaginateArray'
import { buildTranslate } from 'locales'
import { Fields as Location } from 'models/Location'
import Body3Html from 'public/common/Body3Html'
import Table from './Table'

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.multi_pos.location_config'
)

type Props = {
  locations: Location[]
  isOpen: boolean
  onClose: () => void
}

function LocationPosModal(props: Props) {
  const { locations, isOpen, onClose } = props
  const {
    currentPage,
    setPage,
    paginatedData: paginatedLocations,
    totalPages,
  } = usePaginateArray(locations, 5)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onProceed={onClose}
      hideCancel={true}
      showBottomProceed={false}
      zIndex={100}
      dialogStyle={{ marginTop: '100px' }}
    >
      <Text.Header3>{t('title')}</Text.Header3>
      <Body3Html tag="p">{t('contact_us')}</Body3Html>
      <Table locations={paginatedLocations} />
      <div className={'d-flex justify-content-center mt-s'}>
        <Pagination
          onChangePage={page => setPage(page)}
          currentPage={currentPage}
          numPages={totalPages}
        />
      </div>
    </Modal>
  )
}

export default LocationPosModal
