import { Switch } from '@thanx/uikit/switch'
import Checkbox from 'components/Form/CheckboxWithFormsy'
import Spinner from 'components/Spinner'
import numeral from 'numeral'
import pluralize from 'pluralize'
import React from 'react'
import { textUtils } from 'utilities/textUtils'

type Props = {
  inputName: string
  isChecked: boolean
  title: string
  subtitle: React.ReactNode
  count: number | undefined
  unit: string
  isLoading: boolean
  onChange: (value: any) => void
  validations: { [value: string]: any }
  isLast: boolean
  disabled: boolean
}

const CheckboxListItem: React.FC<Props> = props => {
  const {
    inputName,
    isChecked,
    title,
    subtitle,
    count,
    unit,
    isLoading,
    onChange,
    validations,
    isLast,
    disabled,
  } = props

  const titleRowColorClass = disabled ? 'grey-40' : 'grey-90'
  const subtitleRowColorClass = disabled ? 'grey-40' : 'grey-50'
  const hasValidUserCount = count !== undefined && !isNaN(count)

  return (
    <div>
      <div className="display-table full-width">
        <div className="display-table-row">
          <div
            className="display-table-cell"
            style={{ width: '20px', paddingRight: '12px' }}
          >
            <Checkbox
              name={inputName}
              checkedValue={true}
              uncheckedValue={false}
              isChecked={isChecked}
              onChange={onChange}
              validations={validations}
              isDisabled={disabled}
            />
          </div>
          <div className="display-table-cell">
            <div className={`font-size-18 ${titleRowColorClass} inline-block`}>
              {title}
            </div>
          </div>
          {hasValidUserCount && (
            <div className="display-table-cell text-right">
              <div className={`font-size-18 ${titleRowColorClass}`}>
                <Spinner isLoading={isLoading}>
                  {numeral(count).format('0,0')}
                </Spinner>
              </div>
            </div>
          )}
        </div>
        <div className="display-table-row">
          <div className="display-table-cell" />
          <div className="display-table-cell">
            <div
              className={`pr-xl align-top font-size-14 inline-block ${subtitleRowColorClass}`}
            >
              {subtitle}
            </div>
          </div>
          {hasValidUserCount && (
            <div className="display-table-cell text-right align-top">
              <div className={`font-size-14 ${subtitleRowColorClass}`}>
                {pluralize(textUtils.initialCapital(unit), count)}
              </div>
            </div>
          )}
        </div>
        <Switch condition={!isLast}>
          <div className="display-table-row">
            <div className="display-table-cell" />
            <div className="display-table-cell">
              <hr className="border-1 grey-30-border margin-top-large margin-bottom-large" />
            </div>
            <div className="display-table-cell">
              <hr className="border-1 grey-30-border margin-top-large margin-bottom-large" />
            </div>
          </div>
        </Switch>
      </div>
    </div>
  )
}

export default CheckboxListItem
