import CampaignVariant, { Fields as Variant } from 'models/CampaignVariant'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const CREATE_CAMPAIGN_VARIANT = 'campaigns/CREATE_CAMPAIGN_VARIANT'
export const CREATE_CAMPAIGN_VARIANT_SUCCESS =
  'campaigns/CREATE_CAMPAIGN_VARIANT_SUCCESS'
export const CREATE_CAMPAIGN_VARIANT_FAIL =
  'campaigns/CREATE_CAMPAIGN_VARIANT_FAIL'

export const GET_CAMPAIGN_VARIANTS = 'campaigns/GET_CAMPAIGN_VARIANTS'
export const GET_CAMPAIGN_VARIANTS_SUCCESS =
  'campaigns/GET_CAMPAIGN_VARIANTS_SUCCESS'
export const GET_CAMPAIGN_VARIANTS_FAIL = 'campaigns/GET_CAMPAIGN_VARIANTS_FAIL'

export const GET_CAMPAIGN_VARIANT = 'campaigns/GET_CAMPAIGN_VARIANT'
export const GET_CAMPAIGN_VARIANT_SUCCESS =
  'campaigns/GET_CAMPAIGN_VARIANT_SUCCESS'
export const GET_CAMPAIGN_VARIANT_FAIL = 'campaigns/GET_CAMPAIGN_VARIANT_FAIL'

export const UPDATE_CAMPAIGN_VARIANT = 'campaigns/UPDATE_CAMPAIGN_VARIANT'
export const UPDATE_CAMPAIGN_VARIANT_SUCCESS =
  'campaigns/UPDATE_CAMPAIGN_VARIANT_SUCCESS'
export const UPDATE_CAMPAIGN_VARIANT_FAIL =
  'campaigns/UPDATE_CAMPAIGN_VARIANT_FAIL'

export const INITIALIZE_CAMPAIGN_VARIANT =
  'campaigns/INITIALIZE_CAMPAIGN_VARIANT'
export const INITIALIZE_CAMPAIGN_VARIANT_SUCCESS =
  'campaigns/INITIALIZE_CAMPAIGN_VARIANT_SUCCESS'
export const INITIALIZE_CAMPAIGN_VARIANT_FAIL =
  'campaigns/INITIALIZE_CAMPAIGN_VARIANT_FAIL'

export const DELETE_CAMPAIGN_VARIANT = 'campaigns/DELETE_CAMPAIGN_VARIANT'
export const DELETE_CAMPAIGN_VARIANT_SUCCESS =
  'campaigns/DELETE_CAMPAIGN_VARIANT_SUCCESS'
export const DELETE_CAMPAIGN_VARIANT_FAIL =
  'campaigns/DELETE_CAMPAIGN_VARIANT_FAIL'

export function createCampaignVariant(
  campaign_id: number,
  internal_type?: 'control'
): AxiosAction<typeof CREATE_CAMPAIGN_VARIANT, { variant: Variant }> {
  let data

  if (internal_type === 'control') {
    data = {
      variant: {
        internal_type,
      },
    }
  } else {
    data = {}
  }

  return {
    type: CREATE_CAMPAIGN_VARIANT,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${campaign_id}/variants`,
        method: 'post',
        data,
      },
    },
  }
}

export function getCampaignVariants(
  campaign_id: number
): AxiosAction<typeof GET_CAMPAIGN_VARIANTS, { variants: Variant[] }> {
  return {
    type: GET_CAMPAIGN_VARIANTS,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${campaign_id}/variants`,
        method: 'get',
      },
    },
  }
}

export function getCampaignVariant(
  campaign_id: number,
  variant_id: number
): AxiosAction<typeof GET_CAMPAIGN_VARIANT, { variant: Variant }> {
  return {
    type: GET_CAMPAIGN_VARIANT,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${campaign_id}/variants/${variant_id}`,
        method: 'get',
      },
    },
  }
}

export function updateCampaignVariant(
  campaign_id: number,
  variant_id: number,
  variantFields: any
): AxiosAction<typeof UPDATE_CAMPAIGN_VARIANT, { variant: Variant }> {
  const { variant_name, internal_type, ...variant } = variantFields
  return {
    type: UPDATE_CAMPAIGN_VARIANT,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${campaign_id}/variants/${variant_id}`,
        method: 'patch',
        data: {
          variant: {
            name: variant_name,
            ...variant,
          },
        },
      },
    },
  }
}

type InitializeParams = {
  channel?: 'email' | 'push' | 'sms'
}

export function initializeCampaignVariant(
  campaign_id: number,
  variant_id: number,
  params: InitializeParams = {}
): AxiosAction<typeof INITIALIZE_CAMPAIGN_VARIANT, { variant: Variant }> {
  return {
    type: INITIALIZE_CAMPAIGN_VARIANT,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${campaign_id}/variants/${variant_id}/initialize`,
        method: 'post',
        data: params,
      },
    },
  }
}

type DeleteAction = AxiosAction<typeof DELETE_CAMPAIGN_VARIANT> & {
  variantId: number
  campaignId: number
}
export type DeleteResolvedAction =
  | FailureAction<typeof DELETE_CAMPAIGN_VARIANT_FAIL>
  | SuccessAction<
      typeof DELETE_CAMPAIGN_VARIANT_SUCCESS,
      { variant: CampaignVariant },
      DeleteAction
    >
export function deleteCampaignVariant(
  campaignId: number,
  variantId: number
): DeleteAction {
  return {
    type: DELETE_CAMPAIGN_VARIANT,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${campaignId}/variants/${variantId}`,
        method: 'delete',
      },
    },
    campaignId,
    variantId,
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof createCampaignVariant>>
  | ResolvedAxiosAction<ReturnType<typeof getCampaignVariants>>
  | ResolvedAxiosAction<ReturnType<typeof getCampaignVariant>>
  | ResolvedAxiosAction<ReturnType<typeof updateCampaignVariant>>
  | ResolvedAxiosAction<ReturnType<typeof initializeCampaignVariant>>
  | DeleteResolvedAction
