import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type PropsT = {
  progress: number
  children: React.ReactNode | React.ReactNode[]
}

const ProgressTooltip: React.FC<PropsT> = ({ progress, children }) => {
  const [css] = useStyletron()
  const minWidth = progress > 92 ? 140 : 70
  const center = `calc(${progress}% - ${minWidth}px)`

  return (
    <div
      className={css({
        position: 'absolute',
        color: defaultTheme.colors.grey70,
        backgroundColor: defaultTheme.colors.white,
        display: 'block',
        padding: '10px',
        left: center,
        bottom: '55px',
        boxShadow: '0px 1px 2px rgba(54, 66, 78, 0.3)',
        width: '140px',
        borderRadius: '4px',
      })}
    >
      {children}
      <span
        className={css({
          position: 'absolute',
          bottom: '-10px',
          left: `${minWidth - minWidth / 12}px`,
          width: 0,
          height: 0,
          borderLeft: '5px solid transparent',
          borderRight: '5px solid transparent',
          borderTop: `10px solid ${defaultTheme.colors.white}`,
        })}
      ></span>
    </div>
  )
}

export default ProgressTooltip
