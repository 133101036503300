import { Button, Kind } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import { greys } from '@thanx/uikit/theme/colors'
import impactImage from 'assets/images/redeem-manager/ic_rewardsManager.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { useStyletron } from 'styletron-react'

type PropsT = {
  show: boolean
  onClose: VoidFunction
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.warning_modal'
)

const WarningModal: React.FC<PropsT> = (props: PropsT) => {
  const { show, onClose } = props
  const [css] = useStyletron()
  const dispatch = useDispatch()

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      size="auto"
      closeType="small"
      zIndex={1200}
    >
      <div className={`fs-unmask text-center p-xl ${css({ width: '600px' })}`}>
        <h3 className="mb-xl">{t('title')}</h3>
        <img
          className={`mb-s ${css({ height: '250px' })}`}
          src={impactImage}
          alt={t('ctas.image_alt')}
        />
        <p className={`mb-xl ${css({ color: greys.grey70 })}`}>{t('body')}</p>
        <div className="d-flex">
          <Button
            kind={Kind.SECONDARY}
            className="w-100 mr-xs"
            onClick={onClose}
          >
            {t('ctas.cancel')}
          </Button>
          <Button
            className="w-100 ml-xs"
            onClick={() => dispatch(push('/rewards'))}
          >
            {t('ctas.primary')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default WarningModal
