import { Button } from '@thanx/uikit/button'
import { getPointsExperiences } from 'actions/pointsExperiences'
import { getRedeemTemplates } from 'actions/redeemTemplates'
import PopoverInfo from 'components/PopoverInfo'
import PromoBanner from 'components/PromoBanner'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { selectRedeemTemplatesByState } from 'selectors/redeemTemplate'
import { useStyletron } from 'styletron-react'
import { useAbility } from 'utilities/ability'
import { useIsDesktop } from 'utilities/responsive'
import RedeemTemplateTabs from './RedeemTemplateTabs'
import WelcomeCard from './WelcomeCard'

const t = buildTranslate('redeem_manager.landing')

const Landing: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const isDesktop = useIsDesktop()
  const [css] = useStyletron()
  const ability = useAbility()
  const disableCreate = ability.cannot('manage', 'RedeemManager')

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await dispatch(getRedeemTemplates())
      await dispatch(getPointsExperiences())
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  const activeTemplates = useSelector(state =>
    selectRedeemTemplatesByState(state, 'published')
  )
  const draftTemplates = useSelector(state =>
    selectRedeemTemplatesByState(state, 'unpublished')
  )
  const archivedTemplates = useSelector(state =>
    selectRedeemTemplatesByState(state, 'archived')
  )

  return (
    <Container fluid className="p-0 white-bkg">
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <Container>
        <Row className="pt-xl pb-l">
          <Col md={6}>
            <h2 className="mt-0 mb-0 mr-2 inline-block">{t('title')}</h2>
            <PopoverInfo
              title="managing reward templates"
              text={t('title_info')}
              className={`${css({
                verticalAlign: 'super',
              })} font-size-18`}
            />
          </Col>
          {!disableCreate && (
            <Col
              className={`d-flex align-items-center ${
                isDesktop ? 'justify-content-end' : ''
              }`}
            >
              <LinkContainer to="/rewards/create">
                <Button className="px-l ">{t('create_reward')}</Button>
              </LinkContainer>
            </Col>
          )}
        </Row>
        <Row>
          <Col>
            <PromoBanner storageKey={'welcomeCardDismissed'}>
              <WelcomeCard />
            </PromoBanner>
          </Col>
        </Row>
      </Container>
      <Spinner
        isLoading={isLoading}
        size="4x"
        className="text-center margin-top-huge"
      >
        <RedeemTemplateTabs
          activeTemplates={activeTemplates}
          draftTemplates={draftTemplates}
          archivedTemplates={archivedTemplates}
        />
      </Spinner>
    </Container>
  )
}

export default Landing
