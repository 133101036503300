import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import moment from 'moment'

const t = buildTranslate('dateRangeFilter')

export const customDatepickerOverrides = {
  CalendarHeader: {
    style: () => ({
      backgroundColor: palette.white,
      color: palette.grey90,
    }),
  },
  MonthHeader: {
    style: () => ({
      backgroundColor: palette.white,
      color: palette.grey90,
    }),
  },
  MonthYearSelectButton: {
    style: () => ({
      color: palette.grey90,
    }),
  },
  PrevButton: {
    style: () => ({
      color: palette.grey90,
    }),
  },
  NextButton: {
    style: () => ({
      color: palette.grey90,
    }),
  },
  Day: {
    style: props => {
      const {
        $selected,
        $isHovered,
        $isHighlighted,
        $startOfMonth,
        $endOfMonth,
        $hasRangeSelected,
        $outsideMonth,
      } = props
      const hoveredOrHighlighted = $isHovered || $isHighlighted
      const edgeDaySelectedAsStart =
        $selected &&
        !$outsideMonth &&
        !$hasRangeSelected &&
        ($startOfMonth || $endOfMonth)

      const afterFillColor = (() => {
        if ($outsideMonth && !$selected) return palette.white

        return hoveredOrHighlighted && !$selected
          ? palette.grey90
          : palette.belizeHole
      })()

      const beforeFillColor = palette.belizeHole10

      return {
        color:
          $selected || hoveredOrHighlighted ? palette.white : palette.grey90,
        backgroundColor: edgeDaySelectedAsStart
          ? palette.belizeHole
          : palette.white,
        borderTopLeftRadius: '100%',
        borderTopRightRadius: '100%',
        borderBottomLeftRadius: '100%',
        borderBottomRightRadius: '100%',
        // selected range
        ':before': {
          borderTopColor: beforeFillColor,
          borderRightColor: beforeFillColor,
          borderBottomColor: beforeFillColor,
          borderLeftColor: beforeFillColor,
          backgroundColor: beforeFillColor,
        },
        // selected start & end dates
        ':after': {
          borderTopColor: afterFillColor,
          borderRightColor: afterFillColor,
          borderBottomColor: afterFillColor,
          borderLeftColor: afterFillColor,
          backgroundColor: afterFillColor,
        },
      }
    },
  },
}

export type DateRangeGranularity =
  | 'anniversary'
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'quarterly'
  | 'yearly'

export type DateRangeFilterType =
  | 'last_30'
  | 'last_90'
  | 'last_365'
  | 'all_time'
  | 'custom'

export const dateRangeFilterOptions: Array<{
  label: string
  value: DateRangeFilterType
}> = (
  [
    'last_30',
    'last_90',
    'last_365',
    'all_time',
    'custom',
  ] as Array<DateRangeFilterType>
).map((value: DateRangeFilterType) => {
  return {
    value,
    label: t(`filter.${value}`) as string,
  }
})

export type DateRangeFilter = {
  start: string | null
  end: string | null
  type: DateRangeFilterType
  range: 'custom' | 'all_time'
  granularity: DateRangeGranularity | null
}

export const getDateFilterRange = (
  type: DateRangeFilterType,
  timeZone: string,
  startDate: string | null = null,
  endDate: string | null = null
): { start: string | null; end: string | null } => {
  const endMoment = endDate ? moment.tz(endDate, timeZone) : moment.tz(timeZone)
  const end = endMoment.format('YYYY-MM-DD')
  switch (type) {
    case 'last_30':
      return {
        start: endMoment.subtract(30, 'days').format('YYYY-MM-DD'),
        end,
      }
    case 'last_90':
      return {
        start: endMoment.subtract(90, 'days').format('YYYY-MM-DD'),
        end,
      }
    case 'last_365':
      return {
        start: endMoment.subtract(365, 'days').format('YYYY-MM-DD'),
        end,
      }
    case 'all_time':
      return {
        start: null,
        end: null,
      }
    case 'custom':
      return {
        start: startDate,
        end: endDate,
      }
  }
}

export const getDateFilterGranularity = (
  startDate: string | null,
  endDate: string | null,
  timeZone: string
): DateRangeGranularity | null => {
  if (!startDate || !endDate) return null

  const numDays = moment
    .tz(endDate, timeZone)
    .diff(moment.tz(startDate, timeZone), 'days')

  if (numDays > 365 * 7) return 'yearly'

  if (numDays > 365 * 2) return 'quarterly'

  if (numDays > 180) return 'monthly'

  if (numDays > 31) return 'weekly'

  return 'daily'
}

export const getDateFilter = (
  type: DateRangeFilterType,
  timeZone: string,
  startDate: string | null = null,
  endDate: string | null = null
): DateRangeFilter => {
  const { start, end } =
    type === 'custom' || type === 'all_time'
      ? { start: startDate, end: endDate }
      : getDateFilterRange(type, timeZone, startDate, endDate)
  const range = type === 'all_time' ? 'all_time' : 'custom'
  const granularity = getDateFilterGranularity(start, end, timeZone)
  return {
    start,
    end,
    type,
    range,
    granularity,
  }
}
