import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { RevenueMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import InfoRow from 'scenes/CampaignCenter/Report/v3/Test/components/InfoRow'
import { selectCampaignVariantMetricByType } from 'selectors/campaignMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3')

type PropsT = {
  campaignId: number
  variantIds: number[]
  variantTypes: (string | undefined)[]
}

const Purchases: React.FC<PropsT> = props => {
  const { campaignId, variantIds, variantTypes } = props

  const metrics =
    (useSelector(state =>
      selectCampaignVariantMetricByType(
        state,
        campaignId,
        'revenue',
        variantIds
      )
    ) as RevenueMetric[]) || {}
  const { attributionWindow } = useAttributionWindow()

  const variants = variantIds.map((_, i) => {
    const isControl = variantTypes[i] === 'control'
    const primarySections = isControl
      ? [
          {
            content: t('test.tooltips.purchases.percent_control1', {
              count: attributionWindow,
            }),
          },
          {
            title: t('summary.how_its_calc'),
            content: t('test.tooltips.purchases.percent_control2'),
          },
        ]
      : [
          {
            content: t('summary.revenue.tooltips.percent_purchases', {
              count: attributionWindow,
            }),
          },
          {
            title: t('summary.how_its_calc'),
            content: t('summary.revenue.tooltips.percent_purchases_calc'),
          },
        ]
    const secondarySections = isControl
      ? [
          {
            content: t('test.tooltips.purchases.number_control1', {
              count: attributionWindow,
            }),
          },
          {
            title: t('summary.how_its_calc'),
            content: t('test.tooltips.purchases.number_control2', {
              count: attributionWindow,
            }),
          },
          {
            title: t('summary.example'),
            content: t('test.tooltips.purchases.number_control3', {
              days: attributionWindow,
            }),
          },
          {
            content: t('test.tooltips.purchases.number_control4', {
              days: attributionWindow,
            }),
          },
        ]
      : [
          {
            content: t('summary.revenue.tooltips.number_purchases', {
              count: attributionWindow,
            }),
          },
          {
            title: t('summary.how_its_calc'),
            content: t('summary.revenue.tooltips.number_purchases_calc', {
              count: attributionWindow,
            }),
          },
          {
            title: t('summary.example'),
            content: t(
              'summary.revenue.tooltips.number_purchases_example_content1',
              { days: attributionWindow }
            ),
          },
          {
            content: t(
              'summary.revenue.tooltips.number_purchases_example_content2',
              { days: attributionWindow }
            ),
          },
        ]
    return {
      primaryContent: (
        <Metric
          metric={percentageFormatter(metrics[i]?.data?.purchases_rate, 1, 1)}
        >
          <PopoverContent sections={primarySections} />
        </Metric>
      ),
      secondaryContent: (
        <Metric
          primary={false}
          metric={numberFormatter(metrics[i]?.data?.count)}
        >
          <PopoverContent sections={secondarySections} />
        </Metric>
      ),
      isErrored: metrics[i]?.isErrored,
      isLoading: metrics[i]?.isLoading,
    }
  })
  return <InfoRow title={t('summary.revenue.purchases')} variants={variants} />
}

export default Purchases
