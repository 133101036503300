import { Error } from 'components/Flash/Message'
import { errorHandler } from 'utilities/errorHandler'

export const UPDATED = 'flash/UPDATED'

export type Alert = {
  key?: string
  message?: string
  error?: Error | null
  timeout?: number
  displayDetails?: boolean
  hasReloadButton?: boolean
}

export function alert(alert: Alert): any {
  return (dispatch, getState) => {
    const state = getState()
    let alerts = state.flash?.alerts
    if (alert.error) {
      const context = {
        currentMerchantId: state.auth?.current_merchant_id,
        currentMerchantUserId: state.auth?.current_merchant_user_id,
        isThanxAdmin: state.auth?.is_thanx_admin,
      }
      errorHandler.capture(alert.error, context)
    }

    var alertIndex = alerts.findIndex(element => {
      return element.key === alert.key && element.message === alert.message
    })

    if (alertIndex >= 0) {
      alerts.splice(alertIndex, 1, alert)
    } else {
      alerts.push(alert)

      if (alert.timeout) {
        setTimeout(() => {
          dispatch(dismiss(alert))
        }, alert.timeout * 1000)
      }
    }

    dispatch({ type: UPDATED, alerts: alerts })
  }
}

export const dismiss = (alert: Alert): any => {
  return (dispatch, getState) => {
    var state = getState().flash
    var alerts = state.alerts

    var alertIndex = alerts.findIndex(element => {
      return element.key === alert.key && element.message === alert.message
    })

    alerts.splice(alertIndex, 1)
    dispatch({ type: UPDATED, alerts: alerts })
  }
}
