import { buildTranslate } from 'locales'
import React from 'react'
import { useStyletron } from 'styletron-react'

type Props = {
  data: string[][]
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes.upload.table'
)

/**
 * A table of the uploaded csv data
 */
const CsvPreview: React.FC<Props> = props => {
  const { data } = props

  const [css] = useStyletron()

  if (data.length === 0) return null

  return (
    <div className="overflow-scroll">
      <table className={`mb-m`}>
        <thead>
          <tr>
            {data[0].map((_, i) => (
              <th
                className={`
                px-s py-xs grey-90 body-text-4 bold
                grey-10-bkg border-1 grey-30-border
                ${css({ whiteSpace: 'nowrap' })}
              `}
                key={i}
              >
                {t('header', { count: i + 1 })}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => (
            <tr key={i}>
              {row.map((column, j) => (
                <td
                  className={`
                    px-s py-xs grey-70 body-text-5
                    border-1 grey-30-border
                    ${css({ whiteSpace: 'nowrap' })}
                  `}
                  key={j}
                >
                  {column}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CsvPreview
