import Incentive from 'scenes/CampaignCenter/Builder/Steps/Shared/Incentive'
import Messaging from 'scenes/CampaignCenter/Builder/Steps/Shared/Messaging'
import Success from 'scenes/CampaignCenter/Builder/Steps/Shared/Success'
import Review from 'scenes/CampaignCenter/Builder/Steps/Winback/Review'

export default {
  steps: {
    required: {
      review: Review,
      success: Success,
    },
    optional: {
      incentive: Incentive,
      messaging: Messaging,
    },
  },
  options: {
    initialStep: 'review',
    disableNavFor: {
      success: true,
    },
  },
}
