export default {
  title: 'Rewards marketplace',
  page_title: 'Reward in the marketplace | Thanx',
  exit: 'Exit without saving',
  reward: 'Reward',
  can_redeem: 'What reward the customer redeems with %{currency}',
  edit_reward: 'Edit reward template',
  how_many: 'How many %{currency} it costs',
  conversion:
    'Based on your points configuration, a customer would have to spend %{cost} during a regular purchase to earn enough %{currency} for this reward',
  publish: {
    button: 'Publish',
    title: 'Show this reward in the marketplace',
    subtitle:
      'Customers will see this reward immediately in your app and web experience. You can remove the reward later if you change your mind.',
    show: 'Show in marketplace',
    failed: 'Your reward failed to be added to the marketplace.',
    success: 'Your reward has been successfully added to the marketplace.',
  },
  publish_changes: {
    button: 'Publish changes',
    title: 'Publish changes',
    subtitle:
      "Customers will see these changes immediately in your app and web experience. You can't undo these changes.",
    show: 'Publish changes',
    failed: 'Your changes failed to update.',
    success: 'Your changes have been published.',
  },
  cancel: 'Cancel',
  reward_error: 'You must select a reward to publish',
  cost_required_error: 'You must have a cost to publish',
  value_error: 'Cost can be set to any integer from 0 to 1,000,000',
  remove: 'Remove from marketplace',
  restrictions: {
    title: 'Restrictions',
    edit: 'Edit',
    modal: {
      title: 'Restrictions',
      subtitle:
        'Add specific restrictions around how customers can exchange %{currency} for rewards.',
      when: 'When customers can exchange %{currency} for this reward',
      maximum: {
        title: 'Maximum discount',
        subtitle:
          'Set the maximum discount customers can receive e.g. Customer can get up to $50 off.',
        placeholder: '0',
      },
      any_title: 'Any time',
      any_subtitle:
        'Customers will see this reward and will be able to exchange %{currency} for it as soon as you publish it.',
      within_title: 'Within a date range',
      within_subtitle:
        'Customers will see this reward as soon as you publish, but can only exchange %{currency} for it within the date range below. If the start date is in the future, the reward will be marked with a “Coming Soon” badge.',
      start_date: 'Start date (optional)',
      end_date: 'End date (optional)',
      add_start: 'Add start date',
      add_end: 'Add end date',
      remove: 'Remove',
      start_tooltip_1:
        'Customers will see this reward as soon as you publish it. You don’t need to add a start date.',
      start_tooltip_2:
        'Adding a start date that\'s in the future means customers will see the reward but won\'t be able to exchange %{currency} for it until that date. The reward will be marked with a "Coming Soon" badge.',
      end_tooltip_1:
        'Add an end date to schedule when this reward should be automatically removed from the marketplace.',
      end_tooltip_2:
        'Without an end date, this reward will be visible to customers until you manually remove it.',
      at_start: 'At 12:00 AM PST',
      at_end: 'At 11:59 PM PST',
      channels: 'Channels where this reward can be redeemed',
      instore_title: 'In-store',
      instore_subtitle:
        'This reward was configured in the rewards manager to only be redeemable in-store',
      online_title: 'Online',
      online_subtitle:
        'This reward was configured in the rewards manager to only be redeemable online',
      save: 'Save',
      cancel: 'Cancel',
      how_long: 'How long customers see this reward after activation',
      after_activate:
        'After customers activate this reward, they can see it for',
      days: 'days.',
      date_error: 'End date must occur after start date',
    },
  },
}
