import {
  PointsExperienceUpdates,
  ResolvedAction,
  updatePointsExperience,
  UPDATE_FAIL,
} from 'actions/pointsExperiences'
import { ImagePickerValue } from 'components/ImagePicker'
import useDispatch from 'hooks/useDispatch'
import { Fields as PointsExperience } from 'models/PointsExperience'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPointsExperience } from 'selectors/pointsExperience'
import { err, ok, Result } from 'utilities/result'

export type FormModel = Omit<
  PointsExperienceUpdates,
  'earn_image' | 'currency_primary_icon' | 'currency_secondary_icon'
> & {
  earn_image: ImagePickerValue | null
  currency_primary_icon: ImagePickerValue | null
  currency_secondary_icon: ImagePickerValue | null
}

export default function useUpdate<FM extends Partial<FormModel>>(
  id: string | number,
  initFormModel: (pointsExperience: PointsExperience) => FM
) {
  const pointsExperienceId = Number(id)

  const [isUpdating, setIsUpdating] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [formModel, setFormModel] = useState<FM | null>(null)
  const [formState, setFormState] = useState<'valid' | 'invalid'>('invalid')
  const pointsExperience = useSelector(state =>
    selectPointsExperience(state, pointsExperienceId)
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (pointsExperience && !formModel && !isLoaded) {
      setIsLoaded(true)
      setFormModel(initFormModel(pointsExperience))
    }
  }, [isLoaded, pointsExperience, formModel, initFormModel])

  async function publish(
    transform?: (formModel: FM) => FM
  ): Promise<Result<PointsExperience, null>> {
    if (!formModel) {
      return err(null)
    }

    setIsUpdating(true)

    const transformedModel = transform ? transform(formModel) : formModel

    const payload = {
      ...transformedModel,
      earn_image:
        transformedModel.earn_image === null
          ? null
          : transformedModel.earn_image?.record_id ?? undefined,
      currency_primary_icon:
        transformedModel.currency_primary_icon === null
          ? null
          : transformedModel.currency_primary_icon?.record_id ?? undefined,
      currency_secondary_icon:
        transformedModel.currency_secondary_icon === null
          ? null
          : transformedModel.currency_secondary_icon?.record_id ?? undefined,
    }
    const result: ResolvedAction = (await dispatch(
      updatePointsExperience(pointsExperienceId, payload)
    )) as any

    setIsUpdating(false)

    if (result.type === UPDATE_FAIL) {
      return err(null)
    }

    // @ts-ignore
    return ok(result.payload.data.pointsExperience)
  }

  function updateFormModel(values: Partial<FormModel>): void {
    if (!formModel) {
      return
    }

    setFormModel({
      ...formModel,
      ...values,
      conversion_rate: values.conversion_rate
        ? Number(values.conversion_rate)
        : formModel.conversion_rate,
    })
  }

  return {
    pointsExperience,
    isUpdating,
    formModel,
    updateFormModel,
    formState,
    setFormState,
    publish,
  }
}
