import {
  GetMerchantMetricByType,
  GetMerchantMetricTimeSeries,
  GET_MERCHANT_METRIC_BY_TYPE,
  GET_MERCHANT_METRIC_BY_TYPE_FAIL,
  GET_MERCHANT_METRIC_BY_TYPE_SUCCESS,
  GET_MERCHANT_METRIC_TIME_SERIES,
  GET_MERCHANT_METRIC_TIME_SERIES_FAIL,
  GET_MERCHANT_METRIC_TIME_SERIES_SUCCESS,
  ResolvedAction,
} from 'actions/merchantMetrics'
import { RESET_DB } from 'actions/orm'
import isEmpty from 'lodash/isEmpty'
import { AxiosAction } from 'redux-axios-middleware'
import { attr, fk, Model, ModelType } from 'redux-orm'
import { EmptyObject } from 'utilities/types'

export enum Type {
  USER_COUNT = 'merchant_metric_user_count',
  BIRTHDAY_COUNT = 'merchant_metric_birthday_count',
  REVIEWER_COUNT = 'merchant_metric_reviewer_count',
  FREQUENCY_COUNT = 'merchant_metric_frequency_count',
  VIP_COUNT = 'merchant_metric_vip_count',
  USER_STATE = 'merchant_metric_user_state',
  USER_STATS = 'merchant_metric_user_stats',
  TRANSACTION_STATS = 'merchant_metric_transaction_stats',
  REWARD_STATS = 'merchant_metric_reward_stats',
  VIP_SPEND = 'merchant_metric_vip_spend',
  DAY_PART_COUNT = 'merchant_metric_day_part_count',
  WEEK_PART_COUNT = 'merchant_metric_week_part_count',
  CATEGORY_QUANTITY = 'merchant_metric_category_quantity',
  LIFECYCLE = 'merchant_metric_member_lifecycle_state',
  LIFECYCLE_RECENT_JOINED = 'merchant_metric_member_lifecycle_recent_state_joined',
  LIFECYCLE_RECENT_FIRST_PURCHASED = 'merchant_metric_member_lifecycle_recent_state_first_purchased',
  LIFECYCLE_RECENT_SECOND_PURCHASED = 'merchant_metric_member_lifecycle_recent_state_second_purchased',
  LIFECYCLE_RECENT_THIRD_PURCHASED = 'merchant_metric_member_lifecycle_recent_state_third_purchased',
  OVERTIME_CONVERSION_TIME_SERIES_JOINED = 'merchant_metric_joined',
  OVERTIME_CONVERSION_TIME_SERIES_FIRST_PURCHASED = 'merchant_metric_first_purchased',
  OVERTIME_CONVERSION_TIME_SERIES_SECOND_PURCHASED = 'merchant_metric_second_purchased',
  OVERTIME_CONVERSION_TIME_SERIES_THIRD_PURCHASED = 'merchant_metric_third_purchased',
}

export enum ShortType {
  USER_COUNT = 'user_count',
  BIRTHDAY_COUNT = 'birthday_count',
  REVIEWER_COUNT = 'reviewer_count',
  FREQUENCY_COUNT = 'frequency_count',
  VIP_COUNT = 'vip_count',
  USER_STATE = 'user_state',
  USER_STATS = 'user_stats',
  TRANSACTION_STATS = 'transaction_stats',
  REWARD_STATS = 'reward_stats',
  VIP_SPEND = 'vip_spend',
  DAY_PART_COUNT = 'day_part_count',
  WEEK_PART_COUNT = 'week_part_count',
  CATEGORY_QUANTITY = 'category_quantity',
  LIFECYCLE = 'member_lifecycle_state',
  LIFECYCLE_RECENT_JOINED = 'member_lifecycle_recent_state_joined',
  LIFECYCLE_RECENT_FIRST_PURCHASED = 'member_lifecycle_recent_first_purchased',
  LIFECYCLE_RECENT_SECOND_PURCHASED = 'member_lifecycle_recent_second_purchased',
  LIFECYCLE_RECENT_THIRD_PURCHASED = 'member_lifecycle_recent_state_third_purchased',
  OVERTIME_CONVERSION_TIME_SERIES_JOINED = 'joined',
  OVERTIME_CONVERSION_TIME_SERIES_FIRST_PURCHASED = 'first_purchased',
  OVERTIME_CONVERSION_TIME_SERIES_SECOND_PURCHASED = 'second_purchased',
  OVERTIME_CONVERSION_TIME_SERIES_THIRD_PURCHASED = 'third_purchased',
}

export type LifecycleRecentShortType =
  | ShortType.LIFECYCLE_RECENT_JOINED
  | ShortType.LIFECYCLE_RECENT_FIRST_PURCHASED
  | ShortType.LIFECYCLE_RECENT_SECOND_PURCHASED
  | ShortType.LIFECYCLE_RECENT_THIRD_PURCHASED

export type LifecycleShortType = ShortType.LIFECYCLE | LifecycleRecentShortType

export type OvertimeConversionTimeSeriesShotType =
  | ShortType.OVERTIME_CONVERSION_TIME_SERIES_JOINED
  | ShortType.OVERTIME_CONVERSION_TIME_SERIES_FIRST_PURCHASED
  | ShortType.OVERTIME_CONVERSION_TIME_SERIES_SECOND_PURCHASED
  | ShortType.OVERTIME_CONVERSION_TIME_SERIES_THIRD_PURCHASED

interface BaseMetric {
  merchant_id: number
  type: Type
  data: Record<string, any>
  isLoading?: boolean
  isErrored?: boolean
}

export type UserCountData = {
  subscriber_count: number
  estimated_subscriber_count: number
  member_count: number
}

export type VipSpendData = {
  vip_spend: number
  avg_spend: number
  vip_lift: number
}

export type ReviewerCountData = {
  promoter_count: number
  passive_count: number
  detractor_count: number
}

export type UserStateData = {
  at_risk_count: number
  lost_count: number
}

export type WeekPartCountData = {
  weekday_count: number
  weekend_count: number
  other_count: number
}

export type DayPartCountData = {
  morning_count: number
  midday_count: number
  afternood_count: number
  evening_count: number
  night_count: number
  other_count: number
}

export type BirthdayCountData = {
  birthday_count: number
}

export type FrequencyCountData = {
  daily_count: number
  weekly_count: number
  biweekly_count: number
  monthly_count: number
  bimonthly_count: number
  quarterly_count: number
}

export type VipCountData = {
  active_count: number
}

export type CategoryQuantityData = {
  categories: {
    [label: string]: number
  }
  total: number
}

export type DayBucketCountData = {
  total_count: number
  '30_day_count': number
  '60_day_count': number
  '90_day_count': number
  '180_day_count': number
  '365_day_count': number
}

export type DayBucketAverageAmountData = {
  average_amount: number
  '30_day_amount': number
  '60_day_amount': number
  '90_day_amount': number
  '180_day_amount': number
  '365_day_amount': number
}

export type DayBucketPercentageData = {
  total_percent: number
  '30_day_percent': number
  '60_day_percent': number
  '90_day_percent': number
  '180_day_percent': number
  '365_day_percent': number
}

export type DayBucketAmountData = {
  total_amount: number
  '30_day_amount': number
  '60_day_amount': number
  '90_day_amount': number
  '180_day_amount': number
  '365_day_amount': number
}

export type UserMetricData = {
  total_users: number
  [key: string]: number
}

export type AverageCheckData = {
  overall: DayBucketAverageAmountData
  quartile_1: DayBucketAverageAmountData
  quartile_2: DayBucketAverageAmountData
  quartile_3: DayBucketAverageAmountData
}

export type UserFunnelData = {
  total_member_count: number
  subscriber_count: number
  loyalty_count: number
  connected_count: number
  purchaser_count: number
}

export type FunnelConversionRatesData = {
  industry: {
    loyalty: string
    linked: string
    purchased: string
  }
  merchant: {
    loyalty: string
    linked: string
    purchased: string
  }
}

export type UserStatsData = {
  new_membership: DayBucketCountData
  purchasers: DayBucketCountData
  earned_loyalty_rewards: DayBucketCountData
  redeemed_loyalty_rewards: DayBucketCountData
  membership_funnel: UserFunnelData
  conversion_rates: FunnelConversionRatesData
}

export type TransactionStatsData = {
  average_check: AverageCheckData
  average_purchases: DayBucketCountData
  membership_purchases: DayBucketPercentageData
  average_spend: DayBucketAmountData
  purchases_per_user_counts: UserMetricData
  total_spend_per_user: UserMetricData
}

export type AvgRedemptionDaysData = {
  overall: number
  earn_loyalty: number
  other_programs: number
}

export type RewardStatsData = {
  earned_loyalty_rewards: DayBucketCountData
  redeemed_loyalty_rewards: DayBucketCountData
  loyalty_rewards_per_user_counts: UserMetricData
  outstanding_rewards_count: number
  outstanding_loyalty_rewards_count: number
  reward_liability_amount: string
  average_discount_amount: string
  average_redemption_days: AvgRedemptionDaysData
}

export type LifecycleData = {
  user_count: number
  joined_user_count: number
  first_purchased_user_count: number
  second_purchased_user_count: number
  third_purchased_user_count: number
}

export type LifecycleRecentData = {
  user_count: number
  user_converted_count: number
  user_converted_fraction: number | null
}

type OverTimeConversionTimeSeriesEntry = {
  date_time: string
  user_converted_fraction: number
}

export type OverTimeConversionTimeSeriesData =
  Array<OverTimeConversionTimeSeriesEntry>

export interface UserCountMetric extends BaseMetric {
  type: Type.USER_COUNT
  data: UserCountData
}

export interface BirthdayCountMetric extends BaseMetric {
  type: Type.BIRTHDAY_COUNT
  data: BirthdayCountData
}

export interface ReviewerCountMetric extends BaseMetric {
  type: Type.REVIEWER_COUNT
  data: ReviewerCountData
}

export interface FrequencyCountMetric extends BaseMetric {
  type: Type.FREQUENCY_COUNT
  data: FrequencyCountData
}

export interface VipCountMetric extends BaseMetric {
  type: Type.VIP_COUNT
  data: VipCountData
}

export interface UserStateMetric extends BaseMetric {
  type: Type.USER_STATE
  data: UserStateData
}

export interface UserStatsMetric extends BaseMetric {
  type: Type.USER_STATS
  data: UserStatsData
}

export interface TransactionStatsMetric extends BaseMetric {
  type: Type.TRANSACTION_STATS
  data: TransactionStatsData
}

export interface RewardStatsMetric extends BaseMetric {
  type: Type.REWARD_STATS
  data: RewardStatsData
}

export interface VipSpendMetric extends BaseMetric {
  type: Type.VIP_SPEND
  data: VipSpendData
}

export interface DayPartCountMetric extends BaseMetric {
  type: Type.DAY_PART_COUNT
  data: DayPartCountData
}

export interface WeekPartCountMetric extends BaseMetric {
  type: Type.WEEK_PART_COUNT
  data: WeekPartCountData
}

export interface CategoryQuantityMetric extends BaseMetric {
  type: Type.CATEGORY_QUANTITY
  data: CategoryQuantityData
}

export interface LifecycleMetric extends BaseMetric {
  type: Type.LIFECYCLE
  data: LifecycleData
}

export interface LifecycleRecentJoinedMetric extends BaseMetric {
  type: Type.LIFECYCLE_RECENT_JOINED
  data: LifecycleRecentData
}

export interface LifecycleRecentFirstPurchasedMetric extends BaseMetric {
  type: Type.LIFECYCLE_RECENT_FIRST_PURCHASED
  data: LifecycleRecentData
}

export interface LifecycleRecentSecondPurchasedMetric extends BaseMetric {
  type: Type.LIFECYCLE_RECENT_SECOND_PURCHASED
  data: LifecycleRecentData
}

export interface LifecycleRecentThirdPurchasedMetric extends BaseMetric {
  type: Type.LIFECYCLE_RECENT_THIRD_PURCHASED
  data: LifecycleRecentData
}

export type LifecycleRecentTypes =
  | Type.LIFECYCLE_RECENT_JOINED
  | Type.LIFECYCLE_RECENT_FIRST_PURCHASED
  | Type.LIFECYCLE_RECENT_SECOND_PURCHASED
  | Type.LIFECYCLE_RECENT_THIRD_PURCHASED

export interface LifecycleRecentMetric extends BaseMetric {
  type: LifecycleRecentTypes
  data: LifecycleRecentData
}

export type OverTimeConversionTimeSeriesTypes =
  | Type.OVERTIME_CONVERSION_TIME_SERIES_JOINED
  | Type.OVERTIME_CONVERSION_TIME_SERIES_FIRST_PURCHASED
  | Type.OVERTIME_CONVERSION_TIME_SERIES_SECOND_PURCHASED
  | Type.OVERTIME_CONVERSION_TIME_SERIES_THIRD_PURCHASED

export type OverTimeConversionTimeSeriesTimespanTypes =
  | '6_month'
  | '12_month'
  | '24_month'
  | 'all_time'

export interface OverTimeConversionSeriesMetric extends BaseMetric {
  time_zone: OverTimeConversionTimeSeriesTimespanTypes
  type: OverTimeConversionTimeSeriesTypes
  data: OverTimeConversionTimeSeriesData
}

export type Fields =
  | UserCountMetric
  | BirthdayCountMetric
  | ReviewerCountMetric
  | FrequencyCountMetric
  | VipCountMetric
  | UserStateMetric
  | UserStatsMetric
  | TransactionStatsMetric
  | RewardStatsMetric
  | VipSpendMetric
  | DayPartCountMetric
  | WeekPartCountMetric
  | CategoryQuantityMetric
  | LifecycleMetric
  | LifecycleRecentMetric
  | OverTimeConversionSeriesMetric

export type Metrics = {
  user_count?: UserCountMetric
  birthday_count?: BirthdayCountMetric
  reviewer_count?: ReviewerCountMetric
  frequency_count?: FrequencyCountMetric
  vip_count?: VipCountMetric
  user_state_count?: UserStateMetric
  user_stats?: UserStatsMetric
  transaction_stats?: TransactionStatsMetric
  reward_stats?: RewardStatsMetric
  vip_spend?: VipSpendMetric
  day_part_count?: DayPartCountMetric
  week_part_count?: WeekPartCountMetric
  category_quantity?: CategoryQuantityMetric
  lifecycle_data?: LifecycleMetric
  lifecycle_recency_data?: LifecycleRecentMetric
  time_series_data?: OverTimeConversionSeriesMetric
}

const getTypeFromPath = url => {
  const paths: string[] = url.split('/') || []
  return `merchant_metric_${paths[1]}` as Type | undefined
}

export default class MerchantMetric extends Model<
  // @ts-ignore
  typeof MerchantMetric,
  Fields
> {
  static modelName: string = 'MerchantMetric'

  static get fields(): any {
    return {
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'metrics',
      }),
      type: attr(),
      data: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action:
      | ResolvedAction
      | { type: typeof RESET_DB }
      | AxiosAction<typeof GET_MERCHANT_METRIC_BY_TYPE>
      | AxiosAction<typeof GET_MERCHANT_METRIC_TIME_SERIES>,
    // @ts-ignore
    MerchantMetric: ModelType<MerchantMetric>
  ) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_MERCHANT_METRIC_BY_TYPE:
        const url: string = action.payload?.request?.url || ''
        const type = getTypeFromPath(url)
        const merchantId = action.payload.request.params.merchant_id

        if (merchantId) {
          MerchantMetric.upsert({
            id: `${merchantId}${type}`,
            type,
            isLoading: true,
            isErrored: false,
          })
        }
        break

      case GET_MERCHANT_METRIC_BY_TYPE_SUCCESS:
        MerchantMetric.upsert({
          ...action.payload.data.metric,
          id: `${action.payload.data.metric.merchant_id}${action.payload.data.metric.type}`,
          isLoading: false,
          isErrored: false,
        })
        break

      case GET_MERCHANT_METRIC_BY_TYPE_FAIL:
        if (!isEmpty(action.meta.previousAction)) {
          const previousAction = action.meta.previousAction as
            | GetMerchantMetricByType
            | EmptyObject
          const url: string = previousAction.payload?.request?.url || ''
          const type = getTypeFromPath(url)
          const merchantId = previousAction.payload.request.params.merchant_id
          MerchantMetric.upsert({
            id: `${merchantId}${type}`,
            type,
            isLoading: false,
            isErrored: true,
          })
        }
        break

      case GET_MERCHANT_METRIC_TIME_SERIES: {
        const merchantId = action.payload.request.params.merchant_id
        const type =
          `merchant_metric_${action.payload.request.params.type}` as OverTimeConversionTimeSeriesTypes
        const id = `${merchantId}${type}`

        MerchantMetric.upsert({
          id,
          type,
          isLoading: true,
          isErrored: false,
        })
        break
      }

      case GET_MERCHANT_METRIC_TIME_SERIES_SUCCESS: {
        const seriesData = action.payload.data.series_data
        const merchantId = seriesData.merchant_id
        const type =
          `merchant_metric_${seriesData.type}` as OverTimeConversionTimeSeriesTypes
        const id = `${merchantId}${type}`

        MerchantMetric.upsert({
          ...seriesData,
          id,
          type,
          isLoading: false,
          isErrored: false,
        })
        break
      }

      case GET_MERCHANT_METRIC_TIME_SERIES_FAIL: {
        if (!isEmpty(action.meta.previousAction)) {
          const previousAction = action.meta.previousAction as
            | GetMerchantMetricTimeSeries
            | EmptyObject
          const merchantId = previousAction.payload.request.params.merchant_id
          const type =
            `merchant_metric_${previousAction.payload.request.params.type}` as OverTimeConversionTimeSeriesTypes
          const id = `${merchantId}${type}`
          MerchantMetric.upsert({
            id,
            type,
            isLoading: false,
            isErrored: true,
          })
        }
        break
      }

      case RESET_DB:
        MerchantMetric.all().delete()
        break
      default:
        break
    }
  }
}
