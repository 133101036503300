import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'

const t = buildTranslate('terms.thanx')

function ThanxTerms() {
  return (
    <div className="p-xl pt-xl">
      <Text.Header2>{t('title')}</Text.Header2>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('welcome'),
          }}
        />
      </Text.BodyText3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('accessing'),
          }}
        />
      </Text.BodyText3>

      <Text.Header3>{t('eligibility.title')}</Text.Header3>
      <Text.BodyText3>{t('eligibility.in_order')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>{t('eligibility.person')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('eligibility.possess')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('eligibility.not_be')}</Text.BodyText3>
        </li>
      </ul>

      <Text.Header3>{t('creating.title')}</Text.Header3>
      <Text.BodyText3>{t('creating.in_order')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('creating.you_may')}</Text.BodyText3>
      <Text.Header3>{t('privacy.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('privacy.refer'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('using.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('using.provides'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('using.to_use'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('using.qualifying'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('notes.title')}</Text.Header3>
      <Text.BodyText3>{t('notes.registering')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.payment')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.merchant')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.pin')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.service')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('notes.reward'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.acknowledge')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>{t('notes.redemption')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.work')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.purchase')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.providers')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.redeem')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.progress')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.use')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.liable')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.not_redeemable')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.responsible')}</Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>{t('notes.remedy')}</Text.BodyText3>
      <Text.Header3>{t('liability.title')}</Text.Header3>
      <Text.BodyText3>{t('liability.dispute')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('liability.general'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('liability.short')}</Text.BodyText3>
      <Text.Header3>{t('mobile.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('mobile.terms'),
          }}
        />
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>{t('mobile.concluded')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('mobile.obligation')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('mobile.event')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('mobile.provider')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('mobile.third_party')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('mobile.subsidiaries')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('mobile.comply')}</Text.BodyText3>
        </li>
      </ul>
      <Text.Header3>{t('unauthorized.title')}</Text.Header3>
      <Text.BodyText3>{t('unauthorized.lawful')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('unauthorized.additionally')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>{t('unauthorized.fraud')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.accessing')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.tampering')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.soliciting')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.reselling')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.attempting')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.overwhelming')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.hyperlinks')}</Text.BodyText3>
        </li>
      </ul>
      <Text.Header3>{t('account.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('account.cancel'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('account.remove')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('account.suspend')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('account.inactive'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('ownership.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('ownership.copyright'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('links.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('links.contains'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('special.title')}</Text.Header3>
      <Text.BodyText3>{t('special.product')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('special.may')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('special.order'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('special.intend')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('special.reserves')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('special.agree'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('special.reserves')}</Text.BodyText3>
      <Text.Header4>{t('offer_disclaimer.title')}</Text.Header4>
      <Text.BodyText3>{t('offer_disclaimer.platform')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('offer_disclaimer.liability')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>{t('offer_disclaimer.any_goods')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('offer_disclaimer.redemption')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('offer_disclaimer.action')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('offer_disclaimer.failure')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('offer_disclaimer.honor')}</Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>{t('offer_disclaimer.exclusively')}</Text.BodyText3>
      <Text.Header3>{t('statement.title')}</Text.Header3>
      <Text.BodyText3>{t('statement.may_be')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('statement.credits')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('statement.no_event'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('indemnification.title')}</Text.Header3>
      <Text.BodyText3>{t('indemnification.indemnify')}</Text.BodyText3>
      <Text.Header3>{t('disclaimer.title')}</Text.Header3>
      <Text.BodyText3>{t('disclaimer.as_is')}</Text.BodyText3>
      <Text.Header3>{t('limitation.title')}</Text.Header3>
      <Text.BodyText3>{t('limitation.maximum')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('limitation.maximum_two'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('exclusions.title')}</Text.Header3>
      <Text.BodyText3>{t('exclusions.some')}</Text.BodyText3>
      <Text.Header3>{t('force.title')}</Text.Header3>
      <Text.BodyText3>{t('force.neither')}</Text.BodyText3>
      <Text.Header3>{t('general.title')}</Text.Header3>
      <Text.BodyText3>{t('general.terms')}</Text.BodyText3>
      <Text.Header3>{t('questions.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('questions.have'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('questions.last')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{`© ${t('copyright', {
        year: new Date().getFullYear(),
      })}`}</Text.BodyText3>
    </div>
  )
}

export default ThanxTerms
