import React from 'react'

type Props = {
  className?: string
  color?: string
}

const BullHornRectangleIcon: React.FC<Props> = props => {
  const { className, color = 'currentColor' } = props
  return (
    <svg
      width="62"
      height="46"
      viewBox="0 0 62 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '62px' }}
      className={className}
    >
      <path
        d="M1 4.71111C1 2.66152 2.66152 1 4.71111 1H57.2889C59.3385 1 61 2.66152 61 4.71111V41.2889C61 43.3385 59.3385 45 57.2889 45H4.71111C2.66152 45 1 43.3385 1 41.2889V4.71111Z"
        fill="white"
        fillOpacity="0"
        stroke={color}
        strokeWidth="2.35556"
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.583 20.2002C43.3252 20.6299 43.833 21.4502 43.7939 22.3877C43.7939 23.2861 43.3252 24.1064 42.5439 24.5361V30.4736C42.5439 30.8643 42.2705 31.7236 41.2939 31.7236C41.0205 31.7236 40.7471 31.6455 40.5518 31.4502L37.1924 28.7939C35.5518 27.4658 33.4424 26.7236 31.333 26.7236H30.2002C30.1221 27.1533 30.0439 27.5439 30.0439 27.9736C30.0439 29.1455 30.4346 30.1611 31.0596 31.0205C31.6846 31.8408 31.0596 32.9736 30.0439 32.9736H25.9033C25.4346 32.9736 25.0049 32.7393 24.8096 32.3096C24.1455 31.0205 23.7939 29.5361 23.7939 27.9736L23.833 26.7236C22.4268 26.7236 21.333 25.6299 21.333 24.2236V20.4736C21.333 19.1064 22.4268 17.9736 23.833 17.9736H31.333C33.4424 17.9736 35.5518 17.2705 37.1924 15.9424L40.5518 13.2861C40.7471 13.0908 41.0205 12.9736 41.333 12.9736C42.3096 12.9736 42.583 13.9111 42.583 14.2627V20.2002ZM26.2939 31.0986H28.9111C28.4424 30.1611 28.1689 29.1064 28.1689 27.9736C28.1689 27.583 28.208 27.1533 28.3252 26.7236H25.7861C25.7471 26.9971 25.6689 27.4268 25.6689 27.9736C25.6689 29.0674 25.8643 30.1221 26.2939 31.0986ZM30.708 24.8486V19.8486H23.833C23.4814 19.8486 23.208 20.1611 23.208 20.4736V24.2236C23.208 24.5752 23.4814 24.8486 23.833 24.8486H30.708ZM40.708 29.1846V15.5518L38.3643 17.3877C36.7236 18.7158 34.6924 19.5361 32.583 19.7705V24.9658C34.6924 25.2002 36.7236 26.0205 38.3643 27.3486L40.708 29.1846Z"
        fill={color}
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
    </svg>
  )
}

export default BullHornRectangleIcon
