import notEnabledImage from 'assets/images/cms/web-ordering-not-enabled.png'
import { buildTranslate } from 'locales'
import React from 'react'
import CmsPage from 'scenes/Cms/components/CmsPage'

const t = buildTranslate('cms.content.web_hero_image.not_enabled_page')

const NotEnabledPage: React.FC = () => {
  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      contactButtonTitle={t('contact_our_team')}
    >
      <img
        className="max-width-100 mb-l"
        src={notEnabledImage}
        alt={t('image_alt')}
      />
    </CmsPage>
  )
}

export default NotEnabledPage
