// @flow
import React, { Component } from 'react'

type Props = {}
type State = {}

export default class Unauthorized extends Component<Props, State> {
  render() {
    return (
      <p style={{ marginTop: '30px' }} className="text-center">
        Unauthorized
      </p>
    )
  }
}
