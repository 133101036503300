import { Select } from '@thanx/uikit/select'
import { useStyletron } from '@thanx/uikit/theme'
import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import React, { useContext } from 'react'
import { getLabel } from './helpers'

const t = buildTranslate('capture_rate')

type OptionsType = {
  label: string
  value: number | null
}

type PropsT = {
  hideAll?: boolean
}
const Locations: React.FC<PropsT> = ({ hideAll = false }) => {
  const { locations, currentLocation, setCurrentLocationId } =
    useContext(LocationContext)

  const options: OptionsType[] = locations.map(location => ({
    label: getLabel(location),
    value: location.id,
  }))
  const allLocation = {
    label: t('all_locations'),
    value: null,
  }

  if (!hideAll) options.unshift(allLocation)

  const value =
    options.find(option => option.value === currentLocation?.id) || allLocation

  const [css] = useStyletron()
  return (
    <div
      className={`my-xl ${css({
        maxWidth: '500px',
      })}`}
    >
      <Select
        options={options}
        value={value}
        maxDropdownHeight="200px"
        onChange={option => setCurrentLocationId(option[0].value)}
      />
    </div>
  )
}

export default Locations
