import { useStyletron } from '@thanx/uikit/theme'
import logo from 'assets/images/Thanx-logo-dark.png'
import Flash from 'components/Flash'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import { Redirect, Route, Switch } from 'react-router-dom'
import ExpiredSession from './Components/ExpiredSession'
import PasswordLogin from './Components/PasswordLogin'
import RequestPassword from './Components/RequestPassword'
import ResetPassword from './Components/ResetPassword'

const t = buildTranslate('auth')

const Auth: React.FC = () => {
  const [css] = useStyletron()

  return (
    <div className="grey-05-bkg full-height">
      <Flash />
      <Container
        className={`pt-xxxl px-0 text-center ${css({ maxWidth: '400px' })}`}
      >
        <div className="text-center mb-xl">
          <img src={logo} width={109} alt="Thanx" />
        </div>
        <Switch>
          <Route exact path="/auth/login" component={PasswordLogin} />
          <Route
            exact
            path="/auth/forgot_password"
            component={RequestPassword}
          />
          <Route
            exact
            path="/auth/reset_password/:passwordResetCode"
            component={ResetPassword}
          />
          <Route
            exact
            path="/auth/expired_session"
            component={ExpiredSession}
          />
          <Route path="*">
            <Redirect to="/auth/login" />
          </Route>
        </Switch>
      </Container>
      <p className="body-text-4 grey-70-text text-center fixed-bottom mb-m">
        {t('need_help')}{' '}
        <a href="mailto:merchant.support@thanx.com">{t('contact_us')}</a>
      </p>
    </div>
  )
}

export default Auth
