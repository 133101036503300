import CalendarClockIcon from 'components/Icons/CalendarClock'
import EnvelopeIcon from 'components/Icons/Envelope'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React from 'react'
import UnavailableButton from 'scenes/Home/Metrics/UnavailableButton'
import UnlockButton from 'scenes/Home/Metrics/UnlockButton'
import { ReportAccessStateT } from 'scenes/Points/Landing/EffectiveDiscountRate/helpers'

type PropsT = {
  reportAccessState: ReportAccessStateT
}

const t = buildTranslate('home.metrics')

const Button: React.FC<PropsT> = ({ reportAccessState }) => {
  if (reportAccessState === 'points_inactive') {
    return (
      <UnlockButton
        text={t('effective_discount_rate.requires_points')}
        icon={<EnvelopeIcon fillColor={palette.belizeHole} className="mr-xs" />}
        onClick={() => window.open('mailto:points@thanx.com', '_blank')}
      />
    )
  }

  return (
    <UnavailableButton
      icon={<CalendarClockIcon className="mr-xs" />}
      text={
        reportAccessState === 'coming_soon'
          ? t('shared.available_after_launch')
          : t('shared.available_in_x_days', { days: reportAccessState })
      }
    />
  )
}

export default Button
