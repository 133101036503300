export const getColorClass = type => {
  switch (type) {
    case 'message':
      return 'orange'
    case 'custom':
      return 'wisteria'
    default:
      return 'spearmint-50'
  }
}
