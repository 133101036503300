import { defaultTheme } from '@thanx/uikit/theme'
import { fonts } from '@thanx/uikit/theme/typography'
import React, { useMemo } from 'react'
import ReactSelect, { components as rsComponents } from 'react-select'

type ReactSelectProps = typeof ReactSelect
// Compromise to avoid dealing with react-select's OptionType
type Overrides = {
  defaultValue?: any
  onChange?: (event: any) => void
  value?: any
}
export type Props = ReactSelectProps & Overrides

const defaultStyles = {
  option: (base: any, state: any) => {
    let backgroundColor = 'white'
    if (state.isFocused) backgroundColor = '#f2f4f5'
    if (state.isSelected) backgroundColor = '#e5f0f8'
    return {
      ...base,
      minHeight: '38px',
      paddingLeft: '16px',
      color: '#848c98',
      backgroundColor: backgroundColor,
    }
  },
  indicatorSeparator: () => ({}),
  control: (base: any, state: any) => {
    let borderColor = '#c2c8ce'
    if (state.isDisabled) {
      borderColor = '#e9eaec'
    } else if (state.isFocused) {
      borderColor = '#0073BF'
    }
    return {
      ...base,
      backgroundColor: state.isDisabled ? '#f2f4f5' : 'transparent',
      paddingLeft: '8px',
      borderWidth: '1px',
      fontSize: '14px',
      minHeight: '48px',
      boxShadow: 'none',
      borderColor: borderColor,
      color: state.isDisabled ? '#c2c8ce' : '#36424e',
    }
  },
  groupHeading: (base: any) => ({
    ...base,
    maxHeight: '38px',
    paddingBottom: '5px',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    marginRight: '8px',
    color: '#c2c8ce',
  }),
  menu: (base: any) => ({
    ...base,
    top: '40px',
    padding: 'none',
    boxShadow: 'none',
    borderStyle: 'solid',
    borderColor: '#c2c8ce',
    borderWidth: '0 2px 2px 2px',
    borderRadius: '0 0 4px 4px',
  }),
  singleValue: (base: any) => ({
    ...base,
    fontSize: fonts.bodyText2.fontSize,
    color: defaultTheme.colors.grey70,
  }),
}

const defaultDropdownIndicator = (props: Object) => (
  <rsComponents.DropdownIndicator {...props}>
    <i className="fa fa-caret-down fa-2x inline-block" />
  </rsComponents.DropdownIndicator>
)

/*
 * A wrapper around ReactSelect that applies our default styles and
 * dropdown indicator
 */
const Select: React.FC<Props> = ({
  components,
  styles,
  ...reactSelectProps
}) => {
  const computedStyles = useMemo(
    () => Object.assign({}, defaultStyles, styles || {}),
    [styles]
  )

  const computedComponents = Object.assign(
    {},
    { DropdownIndicator: defaultDropdownIndicator },
    components
  )

  return (
    <ReactSelect
      {...reactSelectProps}
      styles={computedStyles}
      components={computedComponents}
    />
  )
}

export default Select
