import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import IncentiveContainer from 'scenes/CampaignCenter/Builder/components/Forms/Incentive'
import IncentivePreview from 'scenes/CampaignCenter/Builder/components/Previews/Incentive'
import Template from './Template'

type Props = {
  campaign: Campaign
  canProceed: boolean
  showValidationErrors?: () => void
  isSaving: boolean
  triggerSave: () => Promise<void>
}

const t = buildTranslate('thanx_campaigns.builder.steps.incentive')

const Incentive: React.FC<Props> = props => {
  const { campaign, canProceed, showValidationErrors, isSaving, triggerSave } =
    props
  const { config } = useCampaignConfig()

  return (
    <Switch
      condition={config.redeem && !config.redeem_template_id}
      fallback={<Template campaign={campaign} />}
    >
      <Row>
        <Col md={6} className="pr-xl pt-xl">
          <h2 className="grey-90-text mb-xs">{t('title')}</h2>
          <div className="body-text-3 grey-70-text">{t('subtitle')}</div>
          <IncentiveContainer
            campaign={campaign}
            canProceed={canProceed}
            showValidationErrors={showValidationErrors}
            isSaving={isSaving}
            triggerSave={triggerSave}
          />
        </Col>
        <Col
          md={6}
          className="grey-10-bkg padding-top-huge"
          style={{ height: '900px' }}
        >
          <IncentivePreview campaign={campaign} />
        </Col>
      </Row>
    </Switch>
  )
}

export default Incentive
