import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Chart from 'components/Charts/Cohort'
import { CohortYAxisOption } from 'components/Charts/helpers'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import Highcharts from 'highcharts'
import React, { useMemo } from 'react'

type PropsT<T> = {
  values: T
  granularity: DateRangeGranularity
  timezone: string
  isLoading: boolean
  isErrored: boolean
  title: string
  subtitle: string
  cohortSeriesFormatter: (v: number) => string
  cohortTopAxisFormatter: (v: number | string) => string
  getCohortSeriesData: (
    v: T,
    timezone: string
  ) => Array<Highcharts.PointOptionsObject>
  getCohortYAxisOptions: (
    v: T,
    granularity: DateRangeGranularity,
    timezone: string
  ) => Array<CohortYAxisOption>
}

function CohortChart<T>({
  values,
  granularity,
  isErrored,
  isLoading,
  timezone,
  title,
  subtitle,
  cohortSeriesFormatter,
  cohortTopAxisFormatter,
  getCohortSeriesData,
  getCohortYAxisOptions,
}: PropsT<T>) {
  const [css] = useStyletron()
  const yAxisOptions = useMemo(
    () => getCohortYAxisOptions(values, granularity, timezone),
    [values, granularity, timezone, getCohortYAxisOptions]
  )

  const seriesData = useMemo(
    () => getCohortSeriesData(values, timezone),
    [values, timezone, getCohortSeriesData]
  )

  return (
    <div className="mt-m">
      <Text.Header3 className="mb-xs mt-xxl" tag="div">
        {title}
      </Text.Header3>
      <Text.BodyText3 tag="div">{subtitle}</Text.BodyText3>
      <div
        className={`w-100 mt-m h-100 px-m py-l white-bkg border-1 grey-20-border position-relative ${css(
          {
            borderRadius: '4px',
          }
        )}`}
      >
        <Chart
          yAxisOptions={yAxisOptions}
          seriesData={seriesData}
          seriesFormatter={cohortSeriesFormatter}
          topAxisFormatter={cohortTopAxisFormatter}
          isLoading={isLoading}
          isErrored={isErrored}
        />
      </div>
    </div>
  )
}

export default CohortChart
