import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import bookIcon from 'assets/images/img_book.svg'
import captureRateImg from 'assets/images/stubs/img_stub_captureRate.png'
import purchasesImg from 'assets/images/stubs/img_stub_purchases.png'
import retentionRateImg from 'assets/images/stubs/img_stub_retentionRate.png'
import Badge from 'components/Badge'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { merchantTheme } from 'theme'
import { mx4UpdatesUrl, retentionRateUrl } from 'utilities/urlUtils'

type PropsT = {
  type: 'revenue' | 'captureRate' | 'retentionRate'
}

type StubT = {
  translateSection: string
  image: string
  helpUrl?: string
}

const t = buildTranslate('reports.stubs')
const titles = buildTranslate('titles')

const Stub: React.FC<PropsT> = props => {
  const { type } = props

  const [css] = useStyletron()

  const config: Record<string, StubT> = {
    captureRate: {
      translateSection: 'capture_rate',
      image: captureRateImg,
    },
    revenue: {
      translateSection: 'revenue',
      image: purchasesImg,
    },
    retentionRate: {
      translateSection: 'retention_rate',
      image: retentionRateImg,
      helpUrl: retentionRateUrl,
    },
  }
  const translatePath = config[type].translateSection
  const helpUrl = config[type].helpUrl ?? mx4UpdatesUrl
  // TODO
  return (
    <ProtectedPageHeader
      title={t(`${translatePath}.title`)}
      resource="OperationalReports"
      description={t(`${translatePath}.subtitle`)}
      topComponent={
        <Badge
          value={t('coming_soon')}
          className={css({
            backgroundColor: merchantTheme.colors.grey20,
            color: merchantTheme.colors.grey70,
          })}
        />
      }
    >
      <Helmet>
        <title>{titles(type)}</title>
      </Helmet>
      <div
        className={`grey-05-bkg border-top-1 pb-xxl grey-20-border mt-l ${css({
          minHeight: `calc(100% - 50px)`,
        })}`}
      >
        <Container className="pt-xl d-flex flex-column">
          <a target="_blank" href={helpUrl} rel="noreferrer">
            <img
              src={config[type].image}
              alt="coming soon"
              className={`mb-s ${css({
                width: '100%',
              })}`}
            />
          </a>
          <a
            target="_blank"
            href={helpUrl}
            rel="noreferrer"
            className="align-self-end d-flex align-items-center"
          >
            <Text.BodyText3 color="primary">{t('help_link')}</Text.BodyText3>
            <img src={bookIcon} alt={t('icon_alt')} className="ml-xs" />
          </a>
        </Container>
      </div>
    </ProtectedPageHeader>
  )
}

export default Stub
