import { Switch } from '@thanx/uikit/switch'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import RestrictionSummary from 'scenes/CampaignCenter/Builder/components/Incentive/RestrictionSummary'
import { useVariant } from 'scenes/CampaignCenter/Builder/components/VariantContext'
import HasIncentive from './HasIncentive'
import NoIncentive from './NoIncentive'

type Props = {
  campaign: Campaign
  isEditable: boolean
  isControlVariant?: boolean
  setCurrentStep?: (step: string) => void
}

const Incentive: React.FC<Props> = ({
  campaign,
  isEditable,
  isControlVariant = false,
  setCurrentStep,
}) => {
  const variant = useVariant()
  const { config } = useCampaignConfig()
  const variantConfig = variant || config
  const onClick = () => {
    if (isEditable) {
      setCurrentStep && setCurrentStep('incentive')
    }
  }
  return (
    <Switch
      condition={!isControlVariant && variantConfig.redeem}
      fallback={
        <NoIncentive
          isEditable={!isControlVariant && isEditable}
          onAdd={onClick}
        />
      }
    >
      <HasIncentive
        campaign={campaign}
        isEditable={isEditable}
        onEdit={onClick}
      >
        <div className="margin-bottom-large">
          <Translate
            tag="div"
            value="thanx_campaigns.review.incentive.reward.title"
            className="bold grey-80 font-size-14 margin-bottom-medium"
          />
          <div className="grey-70 font-size-14">
            {variantConfig.redeem_description}
          </div>
        </div>
        <div className="grey-70">
          <RestrictionSummary campaign={campaign} titleColor="grey-80" />
        </div>
        <Switch condition={!!variantConfig.redeem_fine_print}>
          <div className="margin-top-large">
            <Translate
              tag="div"
              value="thanx_campaigns.review.incentive.fine_print.title"
              className="bold grey-80 font-size-14 margin-bottom-medium"
            />
            <div className="grey-70 font-size-14">
              {variantConfig.redeem_fine_print}
            </div>
          </div>
        </Switch>
      </HasIncentive>
    </Switch>
  )
}

export default Incentive
