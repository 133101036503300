import { Switch } from '@thanx/uikit/switch'
import Input from 'components/Form/Input'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'

type Props = {
  campaign: Campaign
}

const HiddenInputs: React.FC<Props> = ({ campaign }) => {
  let inputStart: null | React.ReactElement = null
  let inputEnd: null | React.ReactElement = null
  const { config } = useCampaignConfig()

  if (campaign.redeem_start_at) {
    inputStart = (
      <Input
        name="redeem_start_at"
        type="hidden"
        value={campaign.redeem_start_at}
      />
    )
  }

  if (campaign.redeem_end_at) {
    inputEnd = (
      <Input
        name="redeem_end_at"
        type="hidden"
        value={campaign.redeem_end_at}
      />
    )
  }

  return (
    <div>
      <Switch condition={config.redeem_type !== 'manual/item'}>
        <Input name="redeem_retail_value" type="hidden" />
      </Switch>
      <div>
        <Switch condition={campaign.config_target_subscriber}>
          <Input
            name="config_signup_required"
            type="hidden"
            value={campaign.config_signup_required}
          />
        </Switch>
        <Switch condition={!!inputStart || !!inputEnd}>
          <div>
            {inputStart}
            {inputEnd}
          </div>
        </Switch>
        <Switch
          condition={
            config.redeem_type === 'automatic/percent' &&
            !!config.redeem_maximum
          }
        >
          <Input
            name="redeem_maximum"
            type="hidden"
            value={config.redeem_maximum}
          />
        </Switch>
        <Switch condition={!!config.redeem_type && !!config.redeem_minimum}>
          <Input
            name="redeem_minimum"
            type="hidden"
            value={config.redeem_minimum}
          />
        </Switch>
        <div>
          <Input
            name="redeem_restriction_description"
            type="hidden"
            value={config.redeem_restriction_description}
          />
        </div>
      </div>
    </div>
  )
}
export default HiddenInputs
