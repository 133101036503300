import { Button } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import ArrowLeft from 'components/Icons/ArrowLeft'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import palette from '../constants/palette'
import Spinner from './Spinner'

type Props = {
  canProceed?: boolean
  className?: string
  hideCancel?: boolean
  title?: string
  subtitle?: string
  children?: React.ReactNode
  buttonGroupChildren?: React.ReactNode
  onProceed?: () => void
  onClose?: () => void
  proceedText?: string
  isOpen: boolean
  isLoading?: boolean
  width?: string
  onBack?: VoidFunction
  showTopProceed?: boolean
  showBottomProceed?: boolean
  subtitleClassName?: string
  dialogStyle?: object
  zIndex?: number
  zeroSpacing?: boolean
}

const ModalContainer: React.FC<Props> = ({
  canProceed = true,
  children = null,
  buttonGroupChildren = null,
  className = '',
  hideCancel = false,
  onClose,
  onProceed,
  title,
  subtitle,
  proceedText,
  isOpen,
  isLoading = false,
  width = '600px',
  onBack,
  showTopProceed = false,
  showBottomProceed = true,
  subtitleClassName = '',
  dialogStyle = {},
  zIndex = 3,
  zeroSpacing = false,
}) => {
  const [css] = useStyletron()
  return (
    <Modal
      overrides={{
        Root: {
          style: {
            zIndex,
          },
        },
        Dialog: {
          style: () => ({
            borderRadius: '4px',
            border: 'none',
            boxShadow: '0px 4px 8px rgba(54, 66, 78, 0.3)',
            width,
            ...dialogStyle,
          }),
        },
      }}
      closeType="small"
      size="auto"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={`${!zeroSpacing ? 'p-xl' : ''} ${className}`}>
        <Switch condition={!!onBack}>
          <Button
            kind="minimal"
            className={`pl-0 mb-s ${css({ color: palette.grey90 })}`}
            onClick={onBack}
          >
            <span className="mr-s">
              <ArrowLeft />
            </span>
            <Translate value="general.buttons.back" />
          </Button>
        </Switch>
        <Switch condition={!!title}>
          <Text.Header3 tag="div" color="grey90" className="mb-xs">
            {title}
          </Text.Header3>
        </Switch>
        <Switch condition={!!subtitle}>
          <Text.BodyText4
            tag="div"
            color="grey70"
            className={subtitleClassName}
          >
            {subtitle}
          </Text.BodyText4>
        </Switch>
        <Switch condition={showTopProceed}>
          <Button
            disabled={!canProceed}
            className="mt-s"
            type="button"
            kind="primary"
            onClick={onProceed}
          >
            <Spinner isLoading={isLoading}>{proceedText}</Spinner>
          </Button>
        </Switch>
        {children}
        <Switch
          condition={
            !!buttonGroupChildren || !!showBottomProceed || !hideCancel
          }
        >
          <div className="mt-xl d-flex justify-content-end flex-align-center">
            {buttonGroupChildren}
            <Switch condition={!hideCancel}>
              <Button type="button" kind="secondary" onClick={onClose}>
                <Translate value="thanx_campaigns.builder.variants.cancel" />
              </Button>
            </Switch>
            {showBottomProceed && (
              <Button
                disabled={!canProceed}
                className="ml-s"
                type="button"
                kind="primary"
                onClick={onProceed}
                isLoading={isLoading}
              >
                {proceedText}
              </Button>
            )}
          </div>
        </Switch>
      </div>
    </Modal>
  )
}

export default ModalContainer
