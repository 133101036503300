import { getReceiptConfig } from 'actions/config'
import HeaderCell from 'components/HeaderCell'
import Section from 'components/Section'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CmsPage from 'scenes/Cms/components/CmsPage'
import { selectReceiptConfig } from 'selectors/config'
import Item from './Item'

const t = buildTranslate('cms.settings.receipt_settings')

const ReceiptSettings: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const config = useSelector(selectReceiptConfig)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await dispatch(getReceiptConfig())
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      isLoading={isLoading}
    >
      <Section className="p-m mt-l">
        <table className="full-width campaign-table">
          <thead>
            <tr>
              <HeaderCell>{t('receipt_type')}</HeaderCell>
              <HeaderCell>{t('accepted')}</HeaderCell>
            </tr>
          </thead>
          <tbody>
            <Item title={t('cash')} value={config?.cash} />
            <Item title={t('gift_card')} value={config?.gift_card} />
            <Item title={t('online')} value={config?.online} />
            <Item title={t('third_party')} value={config?.third_party} />
            <Item title={t('store_credit')} value={config?.store_credit} />
          </tbody>
        </table>
      </Section>
    </CmsPage>
  )
}

export default ReceiptSettings
