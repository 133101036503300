import React from 'react'
import Head from './Head'
import Header, { HeaderContextProvider } from './Header'
import ScrollToTop from './ScrollToTop'

type Props = {
  children: React.ReactNode
}

const AppLayout = ({ children }: Props) => (
  <ScrollToTop>
    <HeaderContextProvider>
      <div className="full-height">
        <Head />
        <Header />
        <main className="full-height">{children}</main>
      </div>
    </HeaderContextProvider>
  </ScrollToTop>
)

export default AppLayout
