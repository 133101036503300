import { Fields as LandingHeader } from 'models/LandingHeader'
import LandingLink from 'models/LandingLink'
import { Fields } from 'models/LandingPage'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_LANDING_PAGE = 'landingPage/GET_LANDING_PAGE'
export const GET_LANDING_PAGE_SUCCESS = 'landingPage/GET_LANDING_PAGE_SUCCESS'
export const GET_LANDING_PAGE_FAIL = 'landingPage/GET_LANDING_PAGE_FAIL'

export const CREATE_LANDING_PAGE = 'landingPage/CREATE_LANDING_PAGE'
export const CREATE_LANDING_PAGE_SUCCESS =
  'landingPage/CREATE_LANDING_PAGE_SUCCESS'
export const CREATE_LANDING_PAGE_FAIL = 'landingPage/CREATE_LANDING_PAGE_FAIL'

type LandingPageResource = Fields & {
  headers: LandingHeader[]
  links: LandingLink[]
}

export function getLandingPage(
  appId: number
): AxiosAction<
  typeof GET_LANDING_PAGE,
  { app_landing_page: LandingPageResource }
> {
  return {
    type: GET_LANDING_PAGE,
    payload: {
      client: 'default',
      request: {
        url: `apps/${appId}/landing_page`,
        method: 'get',
      },
    },
  }
}

export function createLandingPage(
  appId: number
): AxiosAction<
  typeof CREATE_LANDING_PAGE,
  { app_landing_page: LandingPageResource }
> {
  return {
    type: CREATE_LANDING_PAGE,
    payload: {
      client: 'default',
      request: {
        url: `apps/${appId}/landing_page`,
        method: 'post',
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getLandingPage>>
  | ResolvedAxiosAction<ReturnType<typeof createLandingPage>>
