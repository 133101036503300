import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import { EffectiveDiscountRateCostEfficacyMetric } from 'models/EffectiveDiscountRateMetric'
import moment from 'moment-timezone'
import React from 'react'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import { reportCurrency, reportPercentage } from 'scenes/Reports/helpers'
import { getDateLabel } from 'utilities/date'
import { numberFormatter } from 'utilities/formatters'
import NotInMarketplace from './NotInMarketplace'

const popoversT = buildTranslate(
  'points.effective_discount_rate.table.popovers'
)

type Props = {
  data: EffectiveDiscountRateCostEfficacyMetric['values'][number]
  isErrored: boolean
  isLoading: boolean
}

const Row: React.FC<Props> = props => {
  const { data, isErrored, isLoading } = props
  const timeZone = moment.tz.guess()
  const [css] = useStyletron()
  const borderClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: palette.grey30,
  })
  if (!data) {
    return null
  }

  const amountSpent = reportCurrency(data.amount_spent_to_earn, true)
  const discountRate = reportPercentage(data.configured_discount_rate)
  const costPerRedemption = reportCurrency(data.cost_per_redemption, true)
  const rewardRedemptionCount = numberFormatter(data.reward_redemptions_count)
  const rewardRedemptionCost = reportCurrency(data.reward_redemption_cost, true)
  const dateLabel = getDateLabel(data.date_time, 'monthly', timeZone)

  return (
    <tr>
      <BodyCell
        isErrored={isErrored}
        isLoading={isLoading}
        className={borderClass}
        values={[{ value: data.name }]}
      />
      <Switch
        condition={data.live_in_marketplace}
        fallback={
          <NotInMarketplace
            isErrored={isErrored}
            isLoading={isLoading}
            borderClass={`${borderClass} text-right`}
          />
        }
      >
        <BodyCell
          isErrored={isErrored}
          isLoading={isLoading}
          className={`${borderClass} text-right`}
          values={[
            {
              value: amountSpent,
              sections: [
                {
                  content: popoversT('amount_spent')[0],
                },
                {
                  title: popoversT('what_this_means'),
                  content: popoversT('amount_spent', { amountSpent })[1],
                },
              ],
            },
          ]}
        />
      </Switch>
      <BodyCell
        isErrored={isErrored}
        isLoading={isLoading}
        className={`${borderClass} text-right`}
        values={[
          {
            value: costPerRedemption,
            sections: [
              { content: popoversT('cost_per_redemption')[0] },
              {
                title: popoversT('what_this_means'),
                content: popoversT('cost_per_redemption', {
                  costPerRedemption,
                })[1],
              },
            ],
          },
        ]}
      />
      <Switch
        condition={data.live_in_marketplace}
        fallback={
          <NotInMarketplace
            isErrored={isErrored}
            isLoading={isLoading}
            borderClass={`${borderClass} text-right`}
          />
        }
      >
        <BodyCell
          isErrored={isErrored}
          isLoading={isLoading}
          className={`${borderClass} text-right`}
          values={[
            {
              value: discountRate,
              sections: [
                { content: popoversT('discount_rate')[0] },
                {
                  title: popoversT('how_its_calculated'),
                  content: popoversT('discount_rate')[1],
                },
                {
                  title: popoversT('what_this_means'),
                  content: popoversT('discount_rate', {
                    costPerRedemption,
                    discountRate,
                    amountSpent,
                  })[2],
                },
              ],
            },
          ]}
        />
      </Switch>
      <BodyCell
        isErrored={isErrored}
        isLoading={isLoading}
        className={`${borderClass} text-right`}
        values={[
          {
            value: rewardRedemptionCount,
            sections: [
              { content: popoversT('rewards_redeemed')[0] },
              {
                title: popoversT('what_this_means'),
                content: popoversT('rewards_redeemed', {
                  dateLabel,
                  rewardRedemptionCount,
                })[1],
              },
            ],
          },
        ]}
      />
      <BodyCell
        isErrored={isErrored}
        isLoading={isLoading}
        className={`${borderClass} text-right`}
        values={[
          {
            value: rewardRedemptionCost,
            sections: [
              { content: popoversT('cost_of_rewards_redeemed')[0] },
              {
                title: popoversT('how_its_calculated'),
                content: popoversT('cost_of_rewards_redeemed')[1],
              },
              {
                title: popoversT('what_this_means'),
                content: popoversT('cost_of_rewards_redeemed', {
                  dateLabel,
                  rewardRedemptionCount,
                  rewardRedemptionCost,
                  costPerRedemption,
                })[2],
              },
            ],
          },
        ]}
      />
    </tr>
  )
}

export default Row
