import { Button, Kind } from '@thanx/uikit/button'
import { Notification } from '@thanx/uikit/notification'
import { RadioGroup } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import { getTargetSegment } from 'actions/targetSegments'
import RichSelector from 'components/Form/RichSelector'
import Book from 'components/Icons/Book'
import BullsEyeRectangleIcon from 'components/Icons/BullsEyeRectangle'
import Person from 'components/Icons/Person'
import RotateRectangleIcon from 'components/Icons/RotateRectangle'
import Modal from 'components/ModalContainer'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import type { Fields as TargetSegment } from 'models/TargetSegment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAbility } from 'utilities/ability'
import UserCountDisplay from './UserCountDisplay'

const t = buildTranslate('thanx_campaigns.newCampaign')

type Props = {
  isOpen: boolean
  onClose: () => void
  segment: TargetSegment | null
  onCreate: (
    segment: TargetSegment,
    type: 'targeted' | 'automated'
  ) => Promise<void>
}

const NewSegmentCampaignModal: React.FC<Props> = ({
  isOpen,
  onClose,
  segment,
  onCreate,
}) => {
  const [selected, setSelected] = useState<'targeted' | 'automated'>('targeted')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [userCount, setUserCount] = useState<number | null>(null)
  const [isLoadingUserCount, setIsLoadingUserCount] = useState(false)
  const ability = useAbility()
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchUserCount = async () => {
      if (!segment) return

      try {
        setIsLoadingUserCount(true)
        const result = await dispatch(getTargetSegment(segment.id))
        setUserCount(
          result.error ? null : result.payload.data.target_segment.count
        )
      } catch (error) {
        setUserCount(null)
      } finally {
        setIsLoadingUserCount(false)
      }
    }

    fetchUserCount()
  }, [segment, dispatch])

  const handleContinue = async () => {
    if (!segment) return

    setIsSubmitting(true)
    await onCreate(segment, selected)
    setIsSubmitting(false)
  }

  if (!segment) return null

  return (
    <Modal
      canProceed={!isSubmitting}
      isOpen={isOpen}
      title={t('title')}
      onClose={onClose}
      onProceed={handleContinue}
      proceedText={t('start_draft')}
      buttonGroupChildren={
        <div className="flex-1">
          <Link
            to={{
              pathname:
                'https://help.thanx.com/hc/en-us/categories/9020361665303-Marketing',
            }}
            target="_blank"
          >
            <Button kind={Kind.MINIMAL} size="small" className="p-0">
              {t('learn_more')}
              <Book className="ml-xs" />
            </Button>
          </Link>
        </div>
      }
    >
      <div className="my-l">
        <Notification
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                backgroundColor: $theme.colors.mono200,
                width: '100%',
              }),
            },
          }}
        >
          <div className="d-flex w-100 p-xs align-items-start">
            <span className="mr-xs grey-70">
              <Person size={18} />
            </span>
            <div className="flex-2">
              <Text.BodyText3 bold tag="p" className="mb-xs" color="grey70">
                {segment.name}
              </Text.BodyText3>
              {segment.description && (
                <Text.BodyText4 tag="p" className="mb-0" color="grey70">
                  {segment.description}
                </Text.BodyText4>
              )}
              <div className="mt-xs grey-70">
                <UserCountDisplay
                  isLoading={isLoadingUserCount}
                  count={userCount}
                />
              </div>
            </div>
          </div>
        </Notification>
      </div>

      <RadioGroup value={selected}>
        <RichSelector
          value="targeted"
          image={<BullsEyeRectangleIcon />}
          imageClassName="ml-xs"
          className="mb-s"
          onClick={() => setSelected('targeted')}
        >
          <Text.Header4 bold className="mt-0">
            {t('targeted.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p" className="mb-0" color="grey70">
            {t('targeted.description')}
          </Text.BodyText4>
        </RichSelector>

        <RichSelector
          disabled={ability.cannot('manage', 'AutomatedCampaign')}
          value="automated"
          image={<RotateRectangleIcon />}
          imageClassName="ml-xs"
          className="mb-s"
          onClick={() => setSelected('automated')}
        >
          <Text.Header4 bold className="mt-0">
            {t('automated.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p" className="mb-0" color="grey70">
            {t('automated.description')}
          </Text.BodyText4>
        </RichSelector>
      </RadioGroup>
    </Modal>
  )
}

export default NewSegmentCampaignModal
