const validations = {
  images: {
    current_image_size: '(This image is %{uploadWidth}px by %{uploadHeight}px)',
    fixed_width: 'exactly %{value}px wide',
    minimum_width: 'at least %{value}px wide',
    maximum_width: 'no more than %{value}px wide',
    fixed_height: 'exactly %{value}px high',
    minimum_height: 'at least %{value}px high',
    maximum_height: 'no more than %{value}px high',
  },
  hex: {
    message: 'a valid hexadecimal color must be provided',
  },
  min_length: 'please enter at least %{minLength} characters',
  max_length: 'this field cannot contain more than %{maxLength} characters',
  is_required: 'this field is required',
  is_email: 'this must be a valid email address',
  is_phone: 'this must be a valid phone number',
  is_url: 'this must be a valid secure URL e.g. https://www.thanx.com/',
  is_numeric: 'this value must be a number',
  is_int: 'this value must be a whole number',
  must_not_end_in_punctuation:
    'descriptions must not end in punctuation. Punctuation is added automatically',
  must_not_end_in_whitespace: 'descriptions must not end in whitespace',
  must_not_begin_in_whitespace: 'descriptions must not begin with whitespace',
  must_not_be_whitespace: 'must not contain only whitespace',
  must_not_begin_with_causative_verb:
    'descriptions must not begin with words like "get" or "have". These are added automatically',
  less_than: 'the value must be less than %{value}',
  less_than_or_equal: 'the value must be less than or equal to %{value}',
  greater_than: 'the value must be greater than %{value}',
  greater_than_or_equal: 'the value must be greater than or equal to %{value}',
  greater_than_or_equal_reward_amount:
    'must be greater than or equal to reward amount',
  greater_than_date: 'Must be greater than %{dateName}',
  less_than_date: 'Must be less than %{dateName}',
  no_emojis: 'must not include emojis',
  must_be_delimited_emails:
    'Must be a list of emails separated by commas or spaces',
  is_int_point_reward:
    'Partial points cannot be granted. The number of points must be an integer, e.g., 10 points not 10.1 points',
  is_int_point_clawback:
    'Partial points cannot be removed. The number of points must be an integer, e.g., 10 points not 10.1 points',
}

export default validations
