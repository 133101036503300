import { StyleableButton } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import React from 'react'
import { useStyletron } from 'styletron-react'
import { useIsMobile } from 'utilities/responsive'

type Props = {
  name: string
  disabled?: boolean
  isCurrent: boolean
  isDone?: boolean
  onClick: () => void
  colorClass?: string
}

const StepStatus: React.FC<Props> = ({
  colorClass = 'spearmint-50',
  name,
  disabled,
  isCurrent,
  isDone,
  onClick,
}) => {
  const displayCheck = isCurrent || !!isDone
  const [css] = useStyletron()
  const isMobile = useIsMobile()

  return (
    <StyleableButton
      className={`d-flex ${isMobile ? 'mr-s' : 'mr-m'}`}
      disabled={disabled}
      onClick={onClick}
    >
      <div
        className={`${css({
          width: '16px',
          height: '16px',
          border: '2px solid white',
          borderRadius: '8px',
          fontSize: '8px',
          backgroundColor: isCurrent ? 'white' : 'transparent',
        })} d-flex justify-content-center align-items-center mr-xs`}
      >
        <Switch condition={displayCheck}>
          <i className={`fa fa-check ${isCurrent ? colorClass : 'white'}`} />
        </Switch>
      </div>
      <span className="small-caps-3 white">{name}</span>
    </StyleableButton>
  )
}

export default StepStatus
