import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { notAvailable } from 'scenes/CampaignCenter/Report/v3/helpers'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
  redeemTypes: (string | undefined)[]
}

const Redeemed: React.FC<PropsT> = props => {
  const { campaignId, redeemTypes } = props

  const { attributionWindow } = useAttributionWindow()
  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'redeem')
    ) as RedeemMetric) || {}
  const showRedeem = redeemTypes.some(type => type !== 'experience/hidden_menu')
  const notAvailableContent = notAvailable()
  const primaryContent = showRedeem ? (
    <Metric metric={percentageFormatter(metric?.data?.redeemed_fraction, 1, 1)}>
      <PopoverContent
        sections={[
          {
            content: t('revenue.tooltips.percent_of_redeemed', {
              count: attributionWindow,
            }),
          },
          {
            title: t('revenue.tooltips.how_its_redeemed'),
            content: (
              <ul
                className="ml-s pl-0"
                dangerouslySetInnerHTML={{
                  __html: t('revenue.tooltips.how_its_redeemed_content'),
                }}
              />
            ),
          },
          {
            title: t('how_its_calc'),
            content: t('revenue.tooltips.percent_redeemed_calc'),
          },
        ]}
      />
    </Metric>
  ) : (
    notAvailableContent.primaryContent
  )
  const secondaryContent = showRedeem ? (
    <Metric
      primary={false}
      metric={numberFormatter(metric?.data?.redeemed_count)}
    >
      <PopoverContent
        sections={[
          {
            content: t('revenue.tooltips.number_of_redeemed', {
              count: attributionWindow,
            }),
          },
          {
            title: t('revenue.tooltips.how_its_redeemed'),
            content: (
              <ul
                className="ml-s pl-0"
                dangerouslySetInnerHTML={{
                  __html: t('revenue.tooltips.how_its_redeemed_content'),
                }}
              />
            ),
          },
          {
            title: t('how_its_calc'),
            content: t('revenue.tooltips.number_redeemed_calc', {
              count: attributionWindow,
            }),
          },
        ]}
      />
    </Metric>
  ) : (
    notAvailableContent.secondaryContent
  )
  return (
    <InfoRow
      title={t('revenue.redeemed')}
      primaryContent={primaryContent}
      secondaryContent={secondaryContent}
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default Redeemed
