import { buildTranslate } from 'locales'
import type { UserMetricData } from 'models/MerchantMetric'
import React from 'react'
import ColumnChartMetric from 'scenes/Reports/ActivityReport/components/ColumnChartMetric'
import { avgSpendFormatter, numberFormatter } from 'utilities/formatters'

const t = buildTranslate(
  'reports.activity.sections.transactions.metrics.total_spend_per_user'
)

type Props = {
  data: UserMetricData
}

export const TotalSpendPerUser: React.FC<Props> = (props: Props) => {
  return (
    <ColumnChartMetric
      metricData={props.data}
      title={t('title')}
      summaryText={t('summary_text')}
      xAxisTitle={t('x_axis_title')}
      yAxisTitle={t('y_axis_title')}
      totalKey="total_users"
      xAxisFormatter={avgSpendFormatter}
      yAxisFormatter={numberFormatter}
      backgroundClass="grey-background"
      consolidateMinColumns={7}
    />
  )
}

export default TotalSpendPerUser
