import union from 'lodash/union'
import { EventsMetric } from 'models/CampaignMetric'
import moment from 'moment-timezone'
import {
  getData,
  getDateFormat,
} from 'scenes/CampaignCenter/Report/v3/Summary/Sections/Chart/helpers'

export const maxOfCategories = (metrics: EventsMetric[]) => {
  const categories = metrics.map(metric => {
    return metric.data ? metric.data[0].values.map(v => v.date_time) : []
  })
  return categories.reduce((left, right) => union(left, right), [])
}

export const formatXAxisCumulative = (metrics, timezone, granularity) => {
  const categories = maxOfCategories(metrics)
  const formatted = categories.map(category => {
    const date = moment.tz(category, timezone)
    return { xAxis: date.format(getDateFormat(granularity)), date: date }
  })
  return formatted
}

export const getVariantSeries = (
  metric: EventsMetric,
  categories: string[],
  timezone: string,
  name: string
) => {
  const values = metric.data?.[0]?.values
  const dataCategories = categories || []
  const data: Highcharts.SeriesLineOptions = {
    marker: { enabled: false, symbol: 'circle' },
    custom: {
      description: metric.meta?.letter,
    },
    name,
    type: 'line',
    visible: true,
    color: metric.meta?.color,
    data: metric.isLoading
      ? dataCategories.map(_ => null)
      : getData(values, categories),
    id: metric.variant_id?.toString(),
  }
  return data
}
