import navFooter from 'assets/images/campaign-center/app_bottom_nav.jpg'
import statusBar from 'assets/images/campaign-center/status_bar.png'
import type { Fields as Campaign } from 'models/Campaign'
import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import FormModelContext from 'scenes/CampaignCenter/Builder/components/FormModelContext'
import { selectMerchant } from 'selectors/merchant'
import { secondaryLogoUrl } from 'utilities/merchantUtilities'
import AppReward from '../AppReward'
import NoRedeem from './NoRedeem'

type Props = {
  campaign: Campaign
}

const Incentive: React.FC<Props> = ({ campaign }) => {
  const formModel: any = useContext(FormModelContext)
  const campaignMerchant = useSelector(state =>
    selectMerchant(state, campaign.merchant_id)
  )
  if (!formModel.redeem) {
    return <NoRedeem />
  }

  return (
    <div className="padding-huge padding-top-none">
      <div className="phone-bkg">
        <div className="incentive">
          <div
            className="cover-img"
            style={{
              backgroundImage: `url(${campaignMerchant?.cover_image_urls.default})`,
            }}
          >
            <div className="gradient text-center">
              <img
                src={statusBar}
                alt="status bar"
                className="full-width margin-bottom-medium"
              />
              <p className="white margin-bottom-large">
                {campaignMerchant?.nickname}
              </p>
              {!!campaignMerchant && (
                <img
                  src={secondaryLogoUrl(campaignMerchant)}
                  alt="logo"
                  className="logo"
                />
              )}
            </div>
          </div>
          <div className="white-bkg rewards relative">
            <Row className="padding-top-medium padding-bottom-small text-center small-caps-6 extra-bold grey-70 padding-left-medium padding-right-medium border-bottom-1 grey-30-border margin-left-none margin-right-none">
              <Col xs={4} className="spearmint-50">
                <Translate
                  value="thanx_campaigns.builder.previews.incentive.nav.rewards"
                  className="inline-block padding-bottom-tiny border-bottom-2 spearmint-50-border"
                />
              </Col>
              <Col xs={4}>
                <Translate value="thanx_campaigns.builder.previews.incentive.nav.locations" />
              </Col>
              <Col xs={4}>
                <Translate value="thanx_campaigns.builder.previews.incentive.nav.more" />
              </Col>
            </Row>
            <AppReward redeemDescription={formModel.redeem_description} />
            <img
              src={navFooter}
              alt="nav"
              className="absolute bottom-0 full-width"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo<Props>(Incentive)
