import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import * as icons from 'assets/images/nav'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { merchantTheme } from 'theme'
import { mx4UpdatesUrl } from 'utilities/urlUtils'

type PropsT = {
  text: string
  iconName: string
  isLegacy?: boolean
  learnMore?: boolean
  isLeftNav?: boolean
}

const SectionHeader: React.FC<PropsT> = props => {
  const {
    isLeftNav = false,
    text,
    iconName,
    isLegacy = false,
    learnMore = false,
  } = props
  const [css] = useStyletron()
  const xPadding = isLeftNav ? 'px-m' : 'px-s'

  return (
    <div
      className={`${xPadding} d-flex align-items-center justify-content-between ${css(
        {
          height: '40px',
          whiteSpace: 'nowrap',
          backgroundColor: isLegacy
            ? merchantTheme.colors.grey10
            : merchantTheme.colors.white,
        }
      )}`}
    >
      <div>
        <img src={icons[iconName].default} alt={iconName} className="mr-xs" />
        <Text.SmallCaps3 color="grey90" bold>
          {text}
        </Text.SmallCaps3>
      </div>
      {learnMore && (
        // TODO link to help.thanx.com
        <a href={mx4UpdatesUrl} target="_blank" rel="noreferrer">
          <Text.BodyText4 tag="span" className="ml-xs mb-0" color="primary">
            {I18n.t('nav.legacy.learn_more')}
          </Text.BodyText4>
        </a>
      )}
    </div>
  )
}

export default SectionHeader
