import { useStyletron } from '@thanx/uikit/theme'
import { getTiersConfig } from 'actions/config'
import { getTiers } from 'actions/tiers'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import Spinner from 'components/Spinner'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'
import { selectTiersConfig } from 'selectors/config'
import { selectTiers } from 'selectors/tier'
import Tiers from './components/Tiers'

const t = buildTranslate('looker')

enum Tabs {
  OVERVIEW = 'overview',
  ACTIVITY = 'activity',
  CONFIGURATION = 'configuration',
}

const TiersReport: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const config = useSelector(selectTiersConfig)
  const tiers = useSelector(selectTiers)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)

      const promises = [dispatch(getTiers({})), dispatch(getTiersConfig())]
      await Promise.all(promises)
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  const merchant = useCurrentMerchant()
  const [css] = useStyletron()
  const [activeTab, setActiveTab] = useState<
    'overview' | 'activity' | 'configuration'
  >('overview')

  if (!merchant || !config) return null

  const title = t('urls.dashboards/240.title')
  const overviewHeight = 1150
  const activityHeight = 3000
  const configurationHeight = 800

  return (
    <>
      <Helmet>
        <title>{I18n.t(`titles./reports/tiers#${activeTab}`)}</title>
      </Helmet>
      <ProtectedPageHeader title={title} resource="all" action="manage">
        <Spinner
          isLoading={isLoading}
          size="4x"
          className="text-center padding-top-huge"
        >
          <Tab.Container
            activeKey={activeTab}
            transition={false}
            onSelect={(tab: string | null) => {
              if (!tab) return
              setActiveTab(tab as Tabs)
            }}
          >
            <TabNav>
              <TabItem
                state={Tabs.OVERVIEW}
                title={t(`urls.dashboards/240.tabs.overview`)}
              />
              <TabItem
                state={Tabs.ACTIVITY}
                title={t(`urls.dashboards/240.tabs.activity`)}
              />
              <TabItem
                state={Tabs.CONFIGURATION}
                title={t(`urls.dashboards/240.tabs.configuration`)}
              />
            </TabNav>
            <Tab.Content className="pb-l h-100 grey-05-bkg">
              <TabPane state={Tabs.OVERVIEW}>
                <Container
                  className={`px-0 ${css({
                    minHeight: `${overviewHeight + 300}px`,
                  })}`}
                >
                  <LookerIframe
                    path={'dashboards/240'}
                    merchant={merchant}
                    height={`${overviewHeight}px`}
                  />
                  <LookerFooter className="my-xl" />
                </Container>
              </TabPane>
              <TabPane state={Tabs.ACTIVITY}>
                <Container
                  className={`px-0 ${css({
                    minHeight: `${activityHeight + 300}px`,
                  })}`}
                >
                  <LookerIframe
                    path={'dashboards/241'}
                    merchant={merchant}
                    height={`${activityHeight}px`}
                  />
                  <LookerFooter className="my-xl" />
                </Container>
              </TabPane>
              <TabPane state={Tabs.CONFIGURATION}>
                <Container
                  className={`px-0 ${css({
                    minHeight: `${configurationHeight + 300}px`,
                  })}`}
                >
                  <Tiers tiers={tiers} />
                </Container>
              </TabPane>
            </Tab.Content>
          </Tab.Container>
        </Spinner>
      </ProtectedPageHeader>
    </>
  )
}

export default TiersReport
