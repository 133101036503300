import type { LocationT as MapLocation } from 'components/GoogleMap'
import type { Fields as Campaign } from 'models/Campaign'
import type { Fields as Location } from 'models/Location'
import { calculateDistance } from 'utilities/locationUtils'

export function getMapLocations(
  locations: Location[],
  campaign: Campaign
): MapLocation[] {
  const mapLocations: MapLocation[] = []
  locations.forEach(location => {
    mapLocations.push({
      id: location.id,
      name: location.fullName(),
      position: {
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude),
      },
      promoted: location.id === campaign.config_location_id,
      selected: campaign.config_target_location_ids.includes(location.id),
    })
  })

  return mapLocations
}

export function getMultiSelectLocations(
  locations: Location[],
  campaign: Campaign
): Location[] {
  const selectedLocation = locations.find(
    location => location.id === campaign.config_location_id
  )
  if (!selectedLocation) return locations

  const sortedLocations = locations
    .filter(location => location.id !== selectedLocation.id)
    .sort((locationA, locationB) => {
      const distanceA = calculateDistance(selectedLocation, locationA)
      const distanceB = calculateDistance(selectedLocation, locationB)
      return distanceA - distanceB
    })

  return sortedLocations
}
