export default {
  page_title: '%{name} - Campaign report | Thanx',
  summary_tab: 'Summary',
  configuration_tab: 'Configuration',
  test_tab: 'Test',
  stop_campaign: 'Stop campaign',
  errors: {
    load_campaign: 'We had trouble loading your campaign report. Try again?',
  },
  no_end_date: 'Ongoing (no end date)',
  attribution: {
    attribution: 'Attribution',
    days: '%{count} days',
    days_1: '1 day',
    days_long: '%{count} days after receiving campaign',
    days_long_1: '1 day after receiving campaign',
    all_time: 'All time',
  },
  summary: {
    summary: 'Summary',
    counter_metrics: {
      delivered: 'Delivered',
      revenue: 'Revenue',
      purchases: 'Purchase rate',
    },
    number_of_delivered:
      'The total number of number of times this campaign was successfully delivered to a customer across any channel.',
    how_delivered:
      'Total count of times that this campaign was successfully delivered (email, SMS and/or push message).',
    example_delivered_content1:
      'An email message was sent to Susie and did not bounce. This counts as one successfully delivered campaign.',
    example_delivered_content2:
      'An email and a push message were sent to Mollie. This counts as one successfully delivered campaign.',
    example_delivered_content3:
      'An SMS was sent to John but his phone number was invalid. This doesn’t count as a delivered message.',
    how_its_calc: 'How It’s Calculated',
    example: 'Example',
    learn_more: 'Learn more',
    unique_count: 'Unique count of successfully delivered emails.',
    active_info: 'Your campaign is active. The report will be generated soon.',
    scheduled_info:
      'This campaign is scheduled to start on %{startDate}. Check back after it starts to view results.',
    complete_info:
      'This campaign started on %{startDate} and ended on %{endDate}. It is now complete and no more data is being collected.',
    reward_count_warning_1:
      'Most data in this report is attributed to customers that received your campaign. A small portion of customers may have been issued a reward but did not receive this campaign because they unsubscribed from marketing communications. Their data is not displayed in this report and may lead to discrepancies with other reports.',
    reward_count_warning_2:
      'Data in the Rewards accounting section of this report is attributed to all customers that were issued a reward.',
    reward_count_warning_3: 'Consult your SFTP report for custom reporting.',
    engagement: {
      title: 'Engagement',
      attribution: {
        tooltip:
          'Data is attributed to customers that interacted with your message any time after receiving it.',
      },
      no_sms_subtitle:
        'Customers may receive your message across one or more delivery channels (email and/or push notifications) based on their preferred method of communication.',
      subtitle:
        'Customers may receive your message across one or more delivery channels (email, SMS and/or push notifications) based on their preferred method of communication.',
      email: {
        title: 'Email',
        delivered: 'Delivered',
        sent: 'Sent',
        opened: 'Opened',
        unsubscribed: 'Unsubscribed',
        clicked: 'Clicked',
        tooltips: {
          number_of_sent: 'The total number of emails that were sent',
          number_sent_calc: 'Count of customers that were sent an email.',
          percent_of_delivered:
            'The percentage of emails that were successfully delivered.',
          percent_delivered_calc: '(Emails Delivered / Emails Sent) × 100',
          number_of_delivered:
            'The total number of emails that were successfully delivered.',
          number_delivered_calc: 'Count of delivered emails',
          percent_of_opened: 'The percentage of emails that were opened.',
          percent_opened_calc: '(Emails Opened / Emails Delivered) × 100',
          number_of_opened: 'The total number of emails that were opened.',
          number_opened_calc: 'Count of opened emails',
          percent_of_clicked:
            'The percentage of emails that a customer clicked on any link.',
          percent_clicked_calc: '(Emails Clicked / Emails Delivered) × 100',
          number_of_clicked: 'The total number of emails that were clicked.',
          number_clicked_calc: 'Count of clicked emails',
          percent_of_unsubscribed:
            'The percentage of customers who unsubscribed as a result of this campaign.',
          percent_unsubscribed_calc:
            '(Customers who unsubscribed via email / Emails delivered) × 100',
          number_of_unsubscribed:
            'The number of customers who unsubscribed as a result of this campaign.',
          number_unsubscribed_calc:
            'Count of customers who unsubscribed from this email.',
          percent_example_title: 'Example',
          percent_example_content:
            '100 customers received an email and 10 unsubscribed. Your unsubscribe rate is 10%.',
        },
        link_activity: {
          title: 'Link activity',
          headers: {
            type: 'Type',
            url: 'URL',
            text: 'Text',
            clicked: 'Clicked',
          },
          tooltips: {
            count: {
              primary: 'Total number of emails where this link was clicked.',
              secondary_title: 'HOW IT’S CALCULATED',
              secondary_text: 'Count of emails where this link was clicked.',
              example_content_1:
                'James receives their Birthday email and clicks twice on the Order Now button. That counts as 1 email where this link was clicked. Next year James receives their Birthday email again and clicks once on the Order Now button. That also counts as 1 email where this link was clicked. Now in total the count is 2 emails where this link was clicked.',
            },
          },
          unsubscribe: {
            type: 'Hyperlink',
            text: 'Unsubscribe from this campaign',
            url: 'https://mobile.thanx.com/unsubscribe',
          },
          preferences: {
            type: 'Hyperlink',
            text: 'Update subscription preferences',
            url: 'https://mobile.thanx.com/preferences/',
          },
        },
      },
      push: {
        title: 'Push notifications',
        delivered: 'Delivered',
        sent: 'Sent',
        tooltips: {
          number_of_sent: 'The total number of sent push messages.',
          number_sent_calc: 'Count of push messages sent',
          percent_of_delivered:
            'The percentage of push messages that were successfully delivered.',
          percent_delivered_calc: '(Push Delivered / Push Sent) × 100',
          number_of_delivered:
            'The total number of push messsages that were successfully delivered.',
          number_delivered_calc: 'Count of delivered push messages',
        },
      },
      sms: {
        title: 'SMS',
        delivered: 'Delivered',
        sent: 'Sent',
        tooltips: {
          number_of_sent: 'The total number of sent SMS messages.',
          number_sent_calc: 'Count of SMS messages sent',
          percent_of_delivered:
            'The percentage of SMS messages that were successfully delivered.',
          percent_delivered_calc: '(SMS Delivered / SMS Sent) × 100',
          number_of_delivered:
            'The total number of SMS messsages that were successfully delivered.',
          number_delivered_calc: 'Count of delivered SMS messages',
        },
      },
    },
    revenue: {
      title: 'Revenue and costs',
      attribution: {
        tooltip:
          'Data is attributed to customers that made a purchase or redeemed the reward within %{count} days of receiving the campaign.',
        tooltip_1:
          'Data is attributed to customers that made a purchase or redeemed the reward within 1 day of receiving the campaign.',
      },
      redeemed: 'Reward redemptions',
      purchased: 'Customers that purchased',
      purchases: 'Purchases',
      revenue: 'Revenue',
      revenue_subtext: '(Gross revenue minus reward discount)',
      discount: 'Rewards discount',
      cogs: 'COGS of rewards',
      not_available: 'Not available for this reward type',
      avg_check: '%{value} avg check',
      avg_discount: '%{value} avg discount',
      per_reward: '%{value} per reward',
      tooltips: {
        number_purchased:
          'The number of customers who made a purchase within %{count} days of receiving the campaign.',
        number_purchased_1:
          'The number of customers who made a purchase within 1 day of receiving the campaign.',
        number_purchased_calc:
          'Count of customers that received the campaign and made a purchase within %{count} days.',
        number_purchased_calc_1:
          'Count of customers that received the campaign and made a purchase within 1 day.',
        number_purchased_example_content1:
          'Susie received an SMS notifying her that she has a reward to use. 20 days later she decides to use it. This purchase is not attributed to the campaign because it happened outside the %{days} day window.',
        number_purchased_example_content2:
          'Mollie received an email message celebrating her Birthday. 7 days later she made a purchase. This purchase is attributed to the campaign. A year later Mollie received another email message celebrating her Birthday. 3 days later she made a purchase. The second purchase is also attributed to this campaign because it happened within the %{days} day window of the second time she received the campaign.',
        number_purchased_example_content3:
          'John received an email announcing a store opening. That weekend (1 day later) he decides to visit the new location. This purchase is attributed to the campaign because it happened within the %{days} day window.',
        number_purchases:
          'The total number of purchases that were made by customers within %{count} days of receiving the campaign.',
        number_purchases_1:
          'The total number of purchases that were made by customers within 1 day of receiving the campaign.',
        number_purchases_calc:
          'Count of purchases made by customers that received the campaign and made a purchase within %{count} days.',
        number_purchases_calc_1:
          'Count of purchases made by customers that received the campaign and made a purchase within 1 day.',
        number_purchases_example_content1:
          'Susie received an SMS notifying her that she has a reward to use. 20 days later she decides to use it. This purchase is not attributed to the campaign because it happened outside the %{days} day window.',
        number_purchases_example_content2:
          'Mollie received an email message celebrating her Birthday. 7 days later she made a purchase. This purchase is attributed to the campaign. A year later Mollie received another email message celebrating her Birthday. 3 days later she made a purchase. The second purchase is also attributed to this campaign because it happened within the %{days} day window of the second time she received the campaign.',
        number_purchases_example_content3:
          'John received an email announcing a store opening. That weekend (1 day later) he decides to visit the new location. This purchase is attributed to the campaign because it happened within the %{days} day window.',
        percent_purchased:
          'The percentage of customers that made a purchase within %{count} days of receiving the campaign.',
        percent_purchased_1:
          'The percentage of customers that made a purchase within 1 day of receiving the campaign.',
        percent_purchased_calc: '(Customers that purchased / Delivered) × 100',
        percent_purchases:
          'The percentage of campaigns that were followed by a purchase within %{count} days campaign delivery.',
        percent_purchases_1:
          'The percentage of campaigns that were followed by a purchase within 1 day campaign delivery.',
        percent_purchases_calc: '(Purchases / Delivered campaigns) × 100',
        number_of_redeemed:
          'The total number of rewards that were redeemed by customers within %{count} days of receiving the campaign.',
        number_of_redeemed_1:
          'The total number of rewards that were redeemed by customers within 1 day of receiving the campaign.',
        how_its_redeemed: 'How rewards are redeemed',
        how_its_redeemed_content: `
          <li>Hidden menu rewards: If configured for online redemption, they are redeemed automatically when the customer logs in to the app or web experience.</li>
          <li>Bonus point rewards: Redeemed automatically when the customer logs in to the app or web experience.</li>
          <li>Access pass rewards: Customer must manually redeem from the Rewards page in the app.</li>
          <li>All other rewards: Customer must manually redeem in cart during checkout in app or web, or from the Rewards page in app during in-store checkout.</li>
        `,
        number_redeemed_calc:
          'Count of redemptions made by customers that received the campaign and redeemed their reward within %{count} days.',
        number_redeemed_calc_1:
          'Count of redemptions made by customers that received the campaign and redeemed their reward within 1 day.',
        percent_of_redeemed:
          'The percentage of rewards that were redeemed within %{count} days.',
        percent_of_redeemed_1:
          'The percentage of rewards that were redeemed within 1 day.',
        percent_redeemed_calc: '(Redeemed / Delivered campaigns) x 100',
        number_of_revenue:
          'The total ticket size (includes tax, discounts, and excludes COGs and tips) of customers who purchased within %{count} days of receiving the campaign.',
        number_of_revenue_1:
          'The total ticket size (includes tax, discounts, and excludes COGs and tips) of customers who purchased within 1 day of receiving the campaign.',
        calc_of_number_revenue: 'Revenue - reward discount',
        number_revenue_example_content1:
          'Susie receives an email promoting a “$5 off“ reward. The next day she buys a $15 burger and redeems her reward. The revenue is $10.',
        number_revenue_example_content2:
          'John receives an email promoting a “10% off“ reward. The next day he buys a $20 meal and redeems his reward. The revenue is $18.',
        number_revenue_example_content3:
          'Molly receives an email promoting a “free salad” reward. The next day she buys a $7 salad and redeems her reward. The revenue is $0.',
        percent_of_revenue:
          'The average check size of customers who made a purchase. This is the amount they paid after reward discounts (where applicable).',
        calc_of_percent_revenue: 'Revenue / Total purchases',
        percent_revenue_example_content:
          'After sending your campaign, 98 customers made 100 purchases resulting in $14,302. The average check for this campaign is $143.02.',
        number_of_cogs:
          'The total costs of goods sold for rewards that were redeemed by customers within %{count} days of receiving the campaign. This is based on what you configured in the reward template. This field is only available for select reward types.',
        number_of_cogs_1:
          'The total costs of goods sold for rewards that were redeemed by customers within 1 day of receiving the campaign. This is based on what you configured in the reward template. This field is only available for select reward types.',
        number_of_cogs_v2:
          'What it costs your business to produce this item. For example, if you’re selling a burger, the cost to the business would be the cost of the buns, meat, cheese, veggies. If your reward doesn’t have costs configured, we’ll assume a 50% margin.',
        calc_of_number_cogs: 'Reward COGS × Rewards redeemed',
        number_cogs_example_content:
          'You create the reward “get a free burger” and configure the COGS as $3 (how much it costs to make the burger). 10 customers redeem this reward. Your total COGS is $30.',
        number_cogs_example_v2_item_1:
          "You configure a 'free burger' reward and configure the cost to business as $5. The reward COGS is $5.",
        number_cogs_example_v2_item_2:
          "You configure a '$5 off' reward. The reward COGS after the margin is $2.5.",
        number_cogs_example_v2_item_3:
          "You configure a '10% off' reward. The reward COGS will be 50% of the discount depending on the amount the user spent.",
        number_cogs_example_v2_item_4:
          "You configure a 'hidden menu' reward. The reward COGS will be $0.",
        per_of_cogs:
          'The average COGS of rewards that were redeemed by customers within %{count} days of receiving the campaign. This field is only available for select reward types.',
        per_of_cogs_1:
          'The average COGS of rewards that were redeemed by customers within 1 day of receiving the campaign. This field is only available for select reward types.',
        calc_of_per_cogs: 'Reward COGS (as configured in the reward template)',
        per_cogs_example_content:
          'You create the reward “get a free burger” and configure the COGS as $3. Your reward COGS are $3.',
        total_discount:
          'The total discount amount from rewards that were redeemed within %{count} days of receiving the campaign. This amount is already deducted from revenue. If your reward doesn’t have discount configured, we will estimate it based on the purchase total.',
        total_discount_1:
          'The total discount amount from rewards that were redeemed within 1 day of receiving the campaign. This amount is already deducted from revenue. If your reward doesn’t have discount configured, we will estimate it based on the purchase total.',
        number_of_discount:
          'The total discount amount from rewards that were redeemed within %{count} days of receiving the campaign. This amount is already deducted from revenue. If your reward doesn’t have discount configured, we will estimate it based on the purchase total.',
        number_of_discount_1:
          'The total discount amount from rewards that were redeemed within 1 day of receiving the campaign. This amount is already deducted from revenue. If your reward doesn’t have discount configured, we will estimate it based on the purchase total.',
        calc_of_number_discount:
          'Discount amount as configured in the reward template.',
        number_discount_example_content1:
          'You configure a ‘free burger’ reward and configure the retail cost as $10. The reward discount is $10.',
        number_discount_example_content2:
          'You configure a ‘$5 off’ reward. The reward discount is $5.',
        number_discount_example_content3:
          'You configure a ‘10% off’ reward. The reward discount will depend on the amount the user spent.',
        number_discount_example_content4:
          'You configure a ‘$20 cashback’ reward. The reward discount is ‘$20’',
        per_of_discount:
          'The average discount received by each customer that redeemed your reward within %{count} days of receiving the campaign.',
        per_of_discount_1:
          'The average discount received by each customer that redeemed your reward within 1 day of receiving the campaign.',
        calc_of_per_discount: 'Reward discount cost / Redeemed',
      },
    },
    rewards_accounting: {
      accordion: {
        show: 'Show rewards accounting',
        hide: 'Hide rewards accounting',
      },
      title: 'Rewards accounting',
      attribution: {
        tooltip:
          'Data is attributed to customers that redeemed the reward any time after receiving the campaign.',
      },
      redeemed: 'Reward redemptions',
      discount: 'Rewards discount',
      avg_discount: '%{value} avg discount',
      per_reward: '%{value} per reward',
      cogs: 'COGS of rewards',
      not_available: 'Not available for this reward type',
      tooltips: {
        number_of_redeemed:
          'The unique number of customers that redeemed a reward any time after receiving the campaign.',
        number_redeemed_calc:
          'Count of customers that received the campaign and redeemed the reward (all time).',
        number_of_cogs:
          'The total costs of goods sold for rewards that were redeemed by customers any time after receiving the campaign. This is based on what you configured in the reward template. This field is only available for select reward types.',
        calc_of_number_cogs: 'Reward COGS × Rewards redeemed',
        number_cogs_example_content:
          'You create the reward “get a free burger” and configure the COGS as $3 (how much it costs to make the burger). 10 customers redeem this reward. Your total COGS is $30.',
        per_of_cogs:
          'The average COGS of rewards that were redeemed by customers any time after receiving the campaign. This field is only available for select reward types.',
        calc_of_per_cogs: 'Reward COGS (as configured in the reward template)',
        per_cogs_example_content:
          'You create the reward “get a free burger” and configure the COGS as $3. Your reward COGS are $3.',
        number_of_discount:
          'The total cost of discounts from rewards that were redeemed any time after receiving the campaign.',
        calc_of_number_discount:
          'Discount amount as configured in the reward template.',
        number_discount_example_content1:
          'You configure a ‘free burger’ reward and configure the retail cost as $10. The reward discount is $10.',
        number_discount_example_content2:
          'You configure a ‘$5 off’ reward. The reward discount is $5.',
        number_discount_example_content3:
          'You configure a ‘10% off’ reward. The reward discount will depend on the amount the user spent.',
        number_discount_example_content4:
          'You configure a ‘$20 cashback’ reward. The reward discount is ‘$20’',
        per_of_discount:
          'The average discount received by each customer that redeemed your reward any time after receiving the campaign.',
        calc_of_per_discount: 'Reward discount cost / Redeemed',
      },
    },
    chart: {
      title: 'Campaign activity',
      attribution: {
        tooltip:
          'Data is attributed to customers that made a purchase or redeemed the reward within %{count} days of receiving the campaign.',
        tooltip_1:
          'Data is attributed to customers that made a purchase or redeemed the reward within 1 day of receiving the campaign.',
      },
      activity: 'Activity',
      date: 'Date',
      last_30: 'Last 30 days',
      last_90: 'Last 90 days',
      last_365: 'Past year',
      all_time: 'All time',
      program_metric_events_purchase: 'Purchases',
      program_metric_events_redeem: 'Reward redemptions',
      program_metric_events_message: 'Delivered',
      timezone: 'Data shown in your timezone (%{timezone})',
      data_has_failed: '%{type} has failed to load',
      tooltip_program_metric_events_message_1:
        'Number of time this campaign was successfully delivered across any channel',
      tooltip_program_metric_events_message:
        'Number of time this campaign was successfully delivered across any channel',
      tooltip_program_metric_events_redeem_1: 'Reward redeemed',
      tooltip_program_metric_events_redeem: 'Rewards redeemed',
      test_start: 'Test start',
      test_end: 'Test end',
    },
    signups: {
      title: 'Signups',
      attribution: {
        tooltip:
          'Data attributed to customers who redeemed ‘signup reward’ or clicked on any link in the campaign that took them to a signup page.',
      },
      signed_up: 'Signed up',
      tooltips: {
        signup_rate:
          'The percentage of customers who created an account after receiving the campaign.',
        signup_rate_calc: '(Signups / Delivered-All-Channels) × 100',
        signup_rate_example_content1:
          'Your campaign was delivered to 100 customers and 10 signed up. Your signup rate is 10%.',
        signup_count:
          'The number of customers who created an account after receiving the campaign.',
        signup_count_calc:
          'Count of customers who redeemed your signup reward or clicked on any link in the campaign that took them to a signup page.',
        signup_count_example_content1:
          'Susie received an intro offer promoting her to create a loyalty account to receive $5 off her next order.',
        signup_count_example_content2:
          'John received an email promoting the new loyalty program. He clicked the CTA which took him to the signup page.',
      },
    },
  },
  configuration: {
    configuration: 'Configuration',
    audience: 'Audience',
    custom_automated: {
      customers_receive:
        'Customers receive this campaign when they enter the segment ',
    },
    custom: {
      audience_description: 'This campaign targeted customers in the segment ',
    },
    message_all: {
      audience_description: 'This campaign targeted ',
      loyalty_members: 'Loyalty members',
      audience_description_and: ' and ',
      email_subcribers: 'Email subscribers',
    },
    time_to_receive:
      'It may take up to 60 minutes for a customer to receive this campaign',
    restrictions_text: 'Restrictions',
    customer_restriction:
      'A customer will not receive this campaign more than once',
    reward: 'Reward',
    no_reward: 'No reward',
    message: 'Message',
    no_message: 'No message',
    control_group: 'Control group',
    control_group_description:
      'These customers do not receive a message or reward. They act as a control group allowing you to measure the impact of your marketing.',
    learn_more: 'Learn more',
    fine_print: 'Fine print',
    restrictions: {
      retire_after_days:
        'Customers will have %{days} days to redeem this reward',
      min_amount: 'Customers must spend at least $%{amount}',
      max_amount: 'Customers cannot redeem more than $%{amount}',
      locations: 'Customers can only redeem this at %{locations}.',
      signup_required:
        'Customers can only redeem if they join your loyalty program',
      day_part: {
        joined:
          'Customers can redeem <b>%{description}</b> (%{startAt}%{endAt}%{restrictionDays}%{restrictionTime})',
        start_at: 'beginning on %{startAt}',
        generic: 'only during certain periods',
        end_at: ' until %{endAt}',
        day_string: ' on %{dayString}',
        start_time_string: ' from %{startTimeString}',
        end_time_string: ' to %{endTimeString}',
        time_range_joined: 'Customers can redeem%{restrictionTime}',
      },
      redemption_venue: {
        all: 'Customers can redeem this in-store and online',
        instore: 'Customers can only redeem this in-store',
        online: 'Customers can only redeem this online',
      },
    },
    schedule: 'Schedule',
    start: 'Start',
    end: 'End',
    recurring: {
      title: 'How many times a customer can receive this campaign',
      once: 'Exactly once',
      multiple: 'Multiple times',
      multiple_text:
        'Customers will receive this campaign each time they fall into the segment. Exclude customers if they already received this campaign in the past %{count} days.',
    },
    messages: {
      email: {
        title: 'Email',
        empty: 'No Email',
      },
      push: {
        title: 'Push',
        empty: 'No push',
      },
      sms: {
        title: 'SMS',
        empty: 'No SMS',
      },
    },
  },
  test: {
    title: 'Test',
    cumulative_revenue: 'Cumulative revenue',
    delivered: 'Delivered',
    received_variant: 'Customers that received variant %{type} spent this much',
    test_active: 'The test is active',
    active_explain_1:
      '1. Review the report below to see how your variants are doing.',
    active_explain_2:
      '2. End the test when you’re happy with the results—you’ll be able to keep the campaign running and only send the winning variant to everyone going forward.',
    learn_more: 'Learn more',
    end: 'End test',
    cancel: 'Cancel',
    campaign_complete:
      'This campaign started on %{start} and ended on %{end}. It is now complete and no more data is being collected.',
    ended_title: 'The test is no longer running',
    ended_description:
      'You ran a test from %{start} to %{end}. (Data for each variant is displayed for %{count} days after the test is stopped)',
    ended_description_1:
      'You ran a test from %{start} to %{end}. (Data for each variant is displayed for 1 day after the test is stopped)',
    test_ran: 'You ran a test from %{start} to %{end}.',
    winning_variant: 'You selected Variant %{name} as the winner.',
    no_winner:
      'You did not select a winning variant. The test ended because you stopped this campaign.',
    no_test_title: 'This campaign is not using an A/B test',
    no_test_completed_title: 'This campaign did not use an A/B test',
    no_test_description:
      'Learn how campaigns change customer behavior. A/B test your next campaign to discover which subject lines, rewards, and designs impact customer behavior.',
    no_test_multivariant_description:
      'You can test up to 4 variants at once and you can add a control group to learn if your campaign was the reason your customers made a purchase.',
    learn_button: 'Learn about A/B testing',
    new_automated: 'Create a new campaign',
    start_sending: 'Start sending variant %{name} to everyone',
    variant_sending:
      'Variant %{name} is now sending to everyone who enters your segment.',
    no_variant:
      'You did not select a winning variant. The test ended because you stopped this campaign.',
    scheduled:
      'This campaign is scheduled to start on %{start}. Check back after it starts to view results.',
    not_running: 'The test is not running',
    customers_in_variant: 'Customers in this variant',
    end_this_test:
      'End this test and start sending one variant to 100% of customers that enter your segment going forward.',
    another_variant:
      'Test another variant to see if you can get even better engagement',
    another_variant_subtitle:
      'You can test multiple subject lines, the size of the reward, the design of your message, and more.',
    another_button: 'Start a new automated campaign to run a new test',
    test_started: 'You started a test on %{start}',
    tooltips: {
      delivered: {
        variant1:
          'The total number of number of times this campaign was successfully delivered to a customer across any channel.',
        variant2:
          'Total count of times that this campaign was successfully delivered (email, SMS and/or push message).',
        variant3:
          'An email message was sent to Susie and did not bounce. This counts as one successfully delivered campaign.',
        variant4:
          'An email and a push message were sent to Mollie. This counts as one successfully delivered campaign.',
        variant5:
          'An SMS was sent to John but his phone number was invalid. This doesn’t count as a delivered message.',
        control:
          'Customers in the control group did not receive this campaign.',
      },
      customers: {
        number_variant1:
          'The total number of customers assigned to this campaign variant.',
        number_variant2:
          'Total count of customers who were assigned to each campaign variant.',
        number_variant3:
          '100,000 customers were sent Variant A - message only.',
        number_variant4: '100,000 customers were sent Variant B - $5 off.',
        number_variant5: '100,000 customers were sent Variant C - 10% off.',
        number_variant6:
          '100,000 customers were in the control group - no message.',
        number_control1:
          'The total number of customers that were in the control group.',
        number_control2:
          'Total count of customers that were targeted by this campaign and assigned to the control group.',
        number_control3:
          'Susie entered your segment and was randomly assigned to the control group. She did not receive this campaign. This counts as one customer who is in the control group.',
        number_control4:
          'Molly entered your segment and was randomly assigned to variant A. She received an email and push message. This does not count as a customer who is in the control group.',
        percent_control1:
          'The percentage of customers that were in the control group.',
        percent_control2:
          '(Customers in the control group / Customers assigned to any variant in this campaign ) × 100',
        percent_variant1:
          'The percentage of customers assigned to this campaign variant.',
        percent_variant2:
          '(Customers in this variant / Customers assigned to any variant in this campaign ) × 100',
        percent3: '25% of customers were sent Variant A - message only.',
        percent4: '25% of customers were sent Variant B - $5 off.',
        percent5: '25% of customers were sent Variant C - 10% off.',
        percent6: '25% of customers were in the control group - no message.',
      },
      revenue: {
        number1:
          'The total revenue spent by customers who made a purchase within %{count} days of being placed in this variant.',
        number1_1:
          'The total revenue spent by customers who made a purchase within 1 day of being placed in this variant.',
        number2: 'Gross revenue',
        number3: 'Susie buys a $15 burger. The revenue is $15.',
        percent1:
          'The average check size of customers who made a purchase. This is the amount they paid after reward discounts (where applicable).',
        percent2: 'Gross revenue / Total purchases',
        percent3:
          'After sending your campaign, 98 customers made 100 purchases resulting in $14,302. The average check for this campaign is $143.02.',
      },
      purchased: {
        percent_control1:
          'The percentage of customers that made a purchase within %{count} days of being placed in this variant. ',
        percent_control1_1:
          'The percentage of customers that made a purchase within 1 day of being placed in this variant. ',
        percent_control2:
          'The number of customers in the control group may be larger than the number of customers in another variant because customers in other variants may have unsubscribed from your messages.',
        percent_control3: '(Purchases / Delivered campaigns) × 100',
        number_control1:
          'The number of customers who made a purchase within %{count} days of being placed in this variant.',
        number_control1_1:
          'The number of customers who made a purchase within 1 day of being placed in this variant.',
        number_control2:
          'The number of customers in the control group may be larger than the number of customers in another variant because customers in other variants may have unsubscribed from your messages.',
        number_control3:
          'Count of customers that were placed in this variant and made a purchase within %{count} days.',
        number_control3_1:
          'Count of customers that were placed in this variant and made a purchase within 1 day.',
        number_control4:
          'Susie was placed in this variant and 20 days later she makes a purchase. This purchase is not attributed to this variant because it happened outside the %{days} day window.',
        number_control5:
          'John was placed in this variant and 1 day later he makes a purchase. This purchase is attributed to this variant because it happened within the %{days} day window.',
      },
      purchases: {
        percent_control1:
          'The percentage of times that customers fell into the control group and went on to make a purchase within %{count} days.',
        percent_control1_1:
          'The percentage of times that customers fell into the control group and went on to make a purchase within 1 day.',
        percent_control2:
          '(Purchases / Number of times fallen into control group) × 100',
        number_control1:
          'The total number of purchases that were made by customers within %{count} days of falling into this variant.',
        number_control1_1:
          'The total number of purchases that were made by customers within 1 day of falling into this variant.',
        number_control2:
          'Count of purchases made by customers that fell into this variant and made a purchase within %{count} days.',
        number_control2_1:
          'Count of purchases made by customers that fell into this variant and made a purchase within 1 day.',
        number_control4:
          'Susie fell into the control group and so she did not receive a message. 20 days later she made a purchase. This purchase is not attributed to the control group because it happened outside the %{days} days window.',
        number_control5:
          'John fell into the control group and so he did not receive a message. That weekend (1 day later) he made a purchase. This purchase is attributed to the control group because it happened within the %{days} day window.',
      },
      no_reward: 'Customers in the control group did not receive a reward.',
    },
    winning_variant_modal: {
      title: 'Select the winning variant and end your test',
      subtitle:
        'The A/B test will stop but the campaign will keep running. The campaign will start sending the variant you choose to 100% of customers that will enter your segment going forward.',
      footer:
        'You won’t be able to resume the test after selecting the winning variant.',
      info_box: [
        'If the control group is the winner, then customers that did not receive your campaign spent more. In this case you can ',
        'stop this campaign',
        '.',
      ],
    },
    chart: {
      attribution: {
        tooltip:
          'Data is attributed to customers that made a purchase within %{count} days of receiving the campaign.',
        tooltip_1:
          'Data is attributed to customers that made a purchase within 1 day of receiving the campaign.',
      },
      heading: {
        content: 'Spent by customers that received variant %{name}',
        control_content:
          'Spent by customers that did not receive your campaign',
        tooltip: {
          total:
            'The total revenue spent by customers who made a purchase within %{count} days of receiving this variant.',
          total_1:
            'The total revenue spent by customers who made a purchase within 1 day of receiving this variant.',
          how_its_calc: 'How It’s Calculated',
          example: 'Example',
          expression: 'Gross revenue - Reward discount',
          susie:
            'Susie receives an email promoting a “$5 off“ reward. The next day she buys a $15 burger and redeems her reward. The revenue is $10.',
          john: 'John receives an email promoting a “10% off“ reward. The next day he buys a $20 meal and redeems his reward. The revenue is $18.',
          molly:
            'Molly receives an email promoting a “free salad” reward. The next day she buys a $7 salad and redeems her reward. The revenue is $0.',
        },
      },
      net_revenue: 'Revenue',
      date: 'Date',
      timezone: 'Data shown in your timezone (%{timezone})',
      tooltip:
        'Cumulative revenue from customers that received variant %{name}',
      control_tooltip:
        'Cumulative revenue from customers that were targeted by this campaign but did not receive a message',
    },
  },
}
