import { RedeemTemplateState } from 'models/RedeemTemplate'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type PropsT = {
  state: RedeemTemplateState
}

const RedeemTemplateStatus: React.FC<PropsT> = ({ state }) => {
  return (
    <span
      className={`
      small-caps-3 bold text-center px-xs py-xxs nephritis-20-bkg
      ${
        state === 'published'
          ? 'rounded nephritis-20-bkg nephritis-50'
          : 'rounded grey-10-bkg grey-70'
      }
    `}
    >
      {I18n.t(`redeem_manager.templates_status.${state}`)}
    </span>
  )
}

export default RedeemTemplateStatus
