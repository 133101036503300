import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import React from 'react'

type PropsT = {
  disabled?: boolean
  disabledText?: string
}

const DisabledBadge: React.FC<PropsT> = props => {
  const { disabled, disabledText } = props
  const [css] = useStyletron()
  if (!disabled || !disabledText) return null

  return (
    <Badge
      className={`ml-xs grey-20-bkg grey-70 ${css({
        marginTop: '-3px',
        marginBottom: '-2px',
      })}`}
      value={disabledText}
    />
  )
}

export default DisabledBadge
