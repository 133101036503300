import React from 'react'

const TargetImage: React.FC = () => (
  <svg
    width="365"
    height="365"
    viewBox="0 0 365 365"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="182.5" cy="182.5" r="182.5" fill="#8E44AD" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.248 337.208C267.988 337.208 337.495 267.701 337.495 181.96C337.495 96.2196 267.988 26.7129 182.248 26.7129C96.5067 26.7129 27 96.2196 27 181.96C27 267.701 96.5067 337.208 182.248 337.208Z"
      fill="#E8E1DC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.247 306.317C250.927 306.317 306.603 250.641 306.603 181.96C306.603 113.28 250.927 57.604 182.247 57.604C113.567 57.604 57.8906 113.28 57.8906 181.96C57.8906 250.641 113.567 306.317 182.247 306.317Z"
      fill="#32475B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.248 275.426C233.867 275.426 275.713 233.58 275.713 181.96C275.713 130.341 233.867 88.4951 182.248 88.4951C130.628 88.4951 88.7822 130.341 88.7822 181.96C88.7822 233.58 130.628 275.426 182.248 275.426Z"
      fill="#2EB9D2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.247 244.535C216.806 244.535 244.821 216.519 244.821 181.96C244.821 147.402 216.806 119.386 182.247 119.386C147.688 119.386 119.673 147.402 119.673 181.96C119.673 216.519 147.688 244.535 182.247 244.535Z"
      fill="#F45344"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M182.248 212.852C199.308 212.852 213.139 199.021 213.139 181.96C213.139 164.9 199.308 151.069 182.248 151.069C165.187 151.069 151.356 164.9 151.356 181.96C151.356 199.021 165.187 212.852 182.248 212.852Z"
      fill="#FFCC59"
    />
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.0941453">
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="27"
        y="26"
        width="311"
        height="312"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M182.248 337.208C267.988 337.208 337.495 267.701 337.495 181.96C337.495 96.2196 267.988 26.7129 182.248 26.7129C96.5067 26.7129 27 96.2196 27 181.96C27 267.701 96.5067 337.208 182.248 337.208Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect
          width="3.9604"
          height="217.822"
          rx="1.9802"
          transform="matrix(-0.707107 0.707107 0.707107 0.707107 181.088 181.168)"
          fill="#36424E"
          style={{ mixBlendMode: 'multiply' }}
        />
      </g>
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M301.349 60.9262C301.349 48.8601 304.02 35.9204 313.497 28.4575C322.973 20.9947 329.794 30.4223 328.315 33.6784C326.836 36.9345 310.649 54.5335 301.349 60.9262Z"
      fill="#F45344"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M302.643 62.5774C314.702 62.9985 327.727 60.7804 335.516 51.5699C343.305 42.3594 334.121 35.2137 330.816 36.5785C327.51 37.9433 309.357 53.5062 302.643 62.5774Z"
      fill="#2B3C4D"
    />
    <rect
      x="343.512"
      y="18"
      width="3.9604"
      height="233.663"
      rx="1.9802"
      transform="rotate(45 343.512 18)"
      fill="#32475B"
    />
    <rect
      x="332.311"
      y="29.0891"
      width="3.9604"
      height="217.822"
      rx="1.9802"
      transform="rotate(45 332.311 29.0891)"
      fill="#FE8B27"
    />
  </svg>
)

export default TargetImage
