import { api } from './index'

import type { ExpirationTypes } from 'models/PointsExperience'

export type TermsOfService = {
  merchant_name: string
  points_experience: {
    currency_name_plural: string
    conversion_rate: number
    points_expires_in_months: number
    expiration_type: ExpirationTypes
  }
}

export const termsOfServiceApi = api.injectEndpoints({
  endpoints: builder => ({
    getTermsOfService: builder.query<TermsOfService, string>({
      query: handle => ({
        url: `/${handle}`,
        method: 'GET',
      }),
      providesTags: ['TermsOfService'],
    }),
  }),
})
