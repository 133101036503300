import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'

const t = buildTranslate('terms.susieCakes')

function SusieCakesTerms() {
  return (
    <div className="p-xl pt-xl">
      <Text.Header3>{t('title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('welcome'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('service'),
          }}
        />
      </Text.BodyText3>
      <Text.BodyText3>{t('accessing')}</Text.BodyText3>
      <Text.Header3>{t('eligibility.title')}</Text.Header3>
      <Text.BodyText3>{t('eligibility.order')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>{t('eligibility.individual')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('eligibility.possess')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('eligibility.not')}</Text.BodyText3>
        </li>
      </ul>
      <Text.Header3>{t('creating.title')}</Text.Header3>
      <Text.BodyText3>{t('creating.in')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('creating.you')}</Text.BodyText3>
      <Text.Header3>{t('participating.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('participating.our'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('participating.to'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('participating.making'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('notes.title')}</Text.Header3>
      <Text.BodyText3>{t('notes.registering')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.not')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.in_addition')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.pin')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.loyalty')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('notes.reward'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.acknowledge')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>{t('notes.redemption')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.we')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.your')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.neither')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.rewards')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('notes.you')}</Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('notes.remedy'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('notes.hereby')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('notes.general'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('notes.short'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('mobile.title')}</Text.Header3>
      <Text.BodyText3>{t('mobile.subject')}</Text.BodyText3>
      <Text.Header3>{t('alerts.title')}</Text.Header3>
      <Text.BodyText3>{t('alerts.as')}</Text.BodyText3>
      <Text.Header3>{t('unauthorized.title')}</Text.Header3>
      <Text.BodyText3>{t('unauthorized.service')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('unauthorized.additionally')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>{t('unauthorized.fraud')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.accessing')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.tampering')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.soliciting')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.reselling')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.attempting')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.overwhelming')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('unauthorized.hyperlinks')}</Text.BodyText3>
        </li>
      </ul>
      <Text.Header3>{t('account.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('account.cancel'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('account.remove')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('account.continue')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('account.inactive')}</Text.BodyText3>
      <Text.Header3>{t('ownership.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('ownership.copyright'),
          }}
        />
      </Text.BodyText3>
      <Text.Header3>{t('links.title')}</Text.Header3>
      <Text.BodyText3>{t('links.contains')}</Text.BodyText3>
      <Text.Header3>{t('special.title')}</Text.Header3>
      <Text.BodyText3>{t('special.exclusive')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('special.merchant')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('special.certain')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('special.order'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('special.intend')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('special.reserves')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('special.agree'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('special.authorize')}</Text.BodyText3>
      <Text.Header4>{t('offer_disclaimers.title')}</Text.Header4>
      <Text.BodyText3>{t('offer_disclaimers.platform')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('offer_disclaimers.liability')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>{t('offer_disclaimers.associated')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('offer_disclaimers.honoring')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('offer_disclaimers.inaction')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('offer_disclaimers.failure')}</Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>{t('offer_disclaimers.refund')}</Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>{t('offer_disclaimers.exclusively')}</Text.BodyText3>
      <Text.Header3>{t('indemnification.title')}</Text.Header3>
      <Text.BodyText3>{t('indemnification.indemnify')}</Text.BodyText3>
      <Text.Header3>{t('disclaimer.title')}</Text.Header3>
      <Text.BodyText3>{t('disclaimer.as_is')}</Text.BodyText3>
      <Text.Header3>{t('limitation.title')}</Text.Header3>
      <Text.BodyText3>{t('limitation.maximum')}</Text.BodyText3>
      <Text.Header3>{t('exclusion.title')}</Text.Header3>
      <Text.BodyText3>{t('exclusion.some')}</Text.BodyText3>
      <Text.Header3>{t('general.title')}</Text.Header3>
      <Text.BodyText3>{t('general.terms')}</Text.BodyText3>
      <Text.Header3>{t('questions.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('questions.have'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('questions.effective')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{`© ${t('copyright', {
        year: new Date().getFullYear(),
      })}`}</Text.BodyText3>
    </div>
  )
}

export default SusieCakesTerms
