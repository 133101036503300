import openLink from 'assets/images/campaign-center/reports/icons/ic-openLink.svg'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type Props = {
  data: any
  totalUniqueViews?: number | null
}

const ActivityRow: React.FC<Props> = ({ data, totalUniqueViews }) => {
  const emptyValue = '~'
  const uniqueClicks = data.unique_count || data.count || 0

  function percentage() {
    if (!totalUniqueViews) return null
    const val = I18n.l(uniqueClicks / totalUniqueViews, {
      style: 'percent',
      maximumFractionDigits: 0,
    })
    return <span> ({val}) </span>
  }
  function openLinkIcon() {
    return (
      <a href={data.url} target="_blank" rel="noopener noreferrer">
        <img className="open-link" alt="link" src={openLink} />
      </a>
    )
  }

  return (
    <tr>
      {/* @ts-ignore */}
      <td className="text-capitalize" width="10%">
        {data.link_type || data.type || emptyValue}
      </td>
      {/* @ts-ignore */}
      <td width="20%">{data.link_text || data.text || emptyValue}</td>
      <td>
        {data.url}
        {openLinkIcon()}
      </td>
      {/* @ts-ignore */}
      <td width="100px">
        {I18n.l(uniqueClicks)} {percentage()}
      </td>
    </tr>
  )
}

export default ActivityRow
