import { marketplaceUrls } from 'utilities/urlUtils'

export default {
  page_title: 'Rewards marketplace - Points | Thanx',
  title: 'Rewards marketplace',
  subtitle:
    'What customers can redeem with %{currency}. Customers can see the rewards below in the marketplace.',
  add: 'Add a reward to the marketplace',
  empty: {
    title: 'You don’t have any rewards in your marketplace',
    subtitle: 'Rewards that are in your marketplace will appear here.',
  },
  help: {
    title: 'Help',
    article: {
      title: 'How to configure your rewards marketplace',
      url: marketplaceUrls.pointsProgram,
    },
  },
}
