import { getEmailProviderLists } from 'actions/emailProviderLists'
import { alert } from 'actions/flash'
import palette from 'constants/palette'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { push } from 'react-router-redux'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import CheckboxList from './components/CheckboxList'

import type { Fields as Campaign } from 'models/Campaign'
import { Type as MetricType, UserCountMetric } from 'models/CampaignMetric'
import type { CheckboxItemObject } from './components/CheckboxList'

type Props = {
  campaign: Campaign
  isLoading: boolean
}

const AudienceContainer: React.FC<Props> = ({ campaign, isLoading }) => {
  const [isLoadingEmailLists, setIsLoadingEmailLists] = useState(true)
  const [emailListPresent, setEmailListPresent] = useState(true)
  const [targetMember, setTargetMember] = useState(
    campaign.config_target_member
  )
  const [targetSubscriber, setTargetSubscriber] = useState(
    campaign.config_target_subscriber
  )
  const [isValid, setIsValid] = useState(
    campaign.config_target_member || campaign.config_target_subscriber
  )

  const userCountMetric = useSelector(
    state =>
      selectCampaignMetricByType(
        state,
        campaign.id,
        MetricType.USER_COUNT
      ) as UserCountMetric | null
  )

  const dispatch = useDispatch()

  useEffect(() => {
    async function getProviderLists() {
      //Fix Axios types https://thanxapp.atlassian.net/browse/MRC-886
      const response = await dispatch(getEmailProviderLists())
      setIsLoadingEmailLists(false)
      //@ts-ignore
      if (response.error) {
        //@ts-ignore
        const error = response.error.response?.data?.error
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t(
              'thanx_campaigns.builder.forms.audience.get_metrics_error'
            ),
            error,
            displayDetails: false,
          })
        )
      } else {
        setEmailListPresent(
          //@ts-ignore
          !isEmpty(response.payload.data.email_provider_lists)
        )
      }
    }

    getProviderLists()
  }, [dispatch])

  useEffect(() => {
    setIsValid(targetMember || targetSubscriber)
  }, [targetMember, targetSubscriber])

  function subscribersSubtitle() {
    if (isLoadingEmailLists)
      return (
        <ContentLoader
          height={15}
          width={400}
          speed={2}
          primaryColor={palette.grey20}
          secondaryColor={palette.grey30}
        >
          <rect x="0" y="4" rx="5" ry="5" width="300" height="9" />
        </ContentLoader>
      )

    const emailListSetupUrl = `/subscribers/setup?redirect=%2Fthanx_campaigns%2Fmessaging%2Fmessage%2F${campaign.id}`
    if (emailListPresent) {
      return (
        <div>
          <Translate value="thanx_campaigns.builder.forms.audience.subscribers.list_present.subtitle" />
          &nbsp;
          <span
            className="a"
            onClick={() => {
              dispatch(push(emailListSetupUrl))
            }}
          >
            <Translate value="thanx_campaigns.builder.forms.audience.subscribers.list_present.link" />
          </span>
        </div>
      )
    }
    return (
      <div>
        <span
          className="a"
          onClick={() => {
            dispatch(push(emailListSetupUrl))
          }}
        >
          <Translate value="thanx_campaigns.builder.forms.audience.subscribers.no_list_present.link" />
        </span>
        &nbsp;
        <Translate value="thanx_campaigns.builder.forms.audience.subscribers.no_list_present.subtitle" />
      </div>
    )
  }

  function items(): CheckboxItemObject[] {
    return [
      {
        inputName: 'config_target_member',
        isChecked: targetMember,
        title: I18n.t('thanx_campaigns.builder.forms.audience.members.title'),
        subtitle: I18n.t(
          'thanx_campaigns.builder.forms.audience.members.subtitle'
        ),
        count: userCountMetric?.data?.member_count,
        unit: I18n.t('thanx_campaigns.builder.forms.audience.unit'),
        isLoading,
        validations: {
          atLeastOneCheckboxIsRequired: [
            'config_target_subscriber',
            'config_target_member',
          ],
        },
        onItemChanged: value => setTargetMember(value),
      },
      {
        inputName: 'config_target_subscriber',
        isChecked: emailListPresent && targetSubscriber,
        title: I18n.t(
          'thanx_campaigns.builder.forms.audience.subscribers.title'
        ),
        subtitle: subscribersSubtitle(),
        count: userCountMetric?.data?.subscriber_count,
        unit: I18n.t('thanx_campaigns.builder.forms.audience.unit'),
        isLoading,
        validations: {
          atLeastOneCheckboxIsRequired: [
            'config_target_subscriber',
            'config_target_member',
          ],
        },
        onItemChanged: value => setTargetSubscriber(value),
        disabled: !emailListPresent || isLoadingEmailLists,
      },
    ]
  }

  return (
    <CheckboxList
      items={items()}
      sum={{
        title: I18n.t('thanx_campaigns.builder.forms.audience.sum.title'),
        unit: I18n.t('thanx_campaigns.builder.forms.audience.unit'),
      }}
      errorMessage={
        isValid
          ? null
          : I18n.t('thanx_campaigns.builder.forms.audience.validation_error')
      }
    />
  )
}

export default AudienceContainer
