import { Button, Kind } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { getMerchantCouponPools } from 'actions/merchantCouponPools'
import Help from 'components/Help'
import EditNavbar from 'components/Navbar/EditNavbar'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import {
  CouponPoolGenerationType,
  Fields as CouponPool,
} from 'models/CouponPool'
import { RedemptionProvider } from 'models/RedemptionProvider'
import React, { useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { isNDR, mapCouponPoolToSelect } from 'scenes/RedeemManager/helpers'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import { merchantTheme } from 'theme'
import { FormModel, useFormModel } from '../../FormModel'
import useAutosave from '../../useAutosave'
import useCouponPools from '../../useCouponPools'
import { getConfigureProviderHelpArticles } from '../helpers'
import Form from './Form'

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.configure_provider'
)

type Props = {
  templateId: number
  selectedProvider: RedemptionProvider
  couponPools?: CouponPool[] | null
  setSelectedProvider: (provider: RedemptionProvider | null) => void
  setConfigureProviderStep: (state: boolean) => void
  isDraft: boolean
}

function ConfigurePos(props: Props) {
  const {
    templateId,
    selectedProvider,
    couponPools,
    setSelectedProvider,
    setConfigureProviderStep,
    isDraft,
  } = props
  const [css] = useStyletron()

  const { getCouponPools } = useCouponPools()
  const template = useSelector(store => selectRedeemTemplate(store, templateId))
  const dispatch = useDispatch()
  const { forceSave } = useAutosave(templateId)

  const { model: baseFormModel, setModel: setFormModel } = useFormModel()

  const { posFields } = baseFormModel
  const providerFormModel = posFields[selectedProvider.value]
  const providerCouponPools = couponPools?.filter(
    pool => pool.redemption_provider?.value === selectedProvider.value
  )

  const onSave = () => {
    forceSave(baseFormModel, selectedProvider)
    setSelectedProvider(null)
    setConfigureProviderStep(false)
    setFormModel(baseFormModel)
  }

  const onCancel = () => {
    setFormModel(originalState)
    setSelectedProvider(null)
    setConfigureProviderStep(false)
  }

  // Take a snapshot of the form state, and if the user does not press save
  // restore the context to the state before the configure POS view
  const [originalState] = useState<FormModel>(baseFormModel)

  const [isValidForm, setIsValidForm] = useState(true)
  const saveEnabled = isValidForm && !!providerFormModel.instoreRedemptionType
  const formRef = useRef() as React.MutableRefObject<HTMLFormElement>

  async function onFormChange(newForm: FormModel) {
    if (!templateId) return
    const updatedCouponCodes = newForm.couponCodes
    let formCouponPools = providerCouponPools

    if (
      !!updatedCouponCodes?.generationType &&
      providerFormModel?.couponCodes?.generationType !==
        updatedCouponCodes.generationType
    ) {
      const result = await getCouponPools(
        templateId,
        updatedCouponCodes?.generationType
      )
      formCouponPools = result?.filter(
        pool => pool.redemption_provider?.value === selectedProvider.value
      )
    }

    let selectedCouponPools: any = !!formCouponPools?.length
      ? formCouponPools
          .filter(
            cp => cp.generation_type === updatedCouponCodes?.generationType
          )
          .map(cp => mapCouponPoolToSelect(cp))
      : []
    let staticCode = ''

    if (
      !!templateId &&
      baseFormModel.typeItem &&
      !isNDR(baseFormModel.typeItem.types[0])
    ) {
      if (
        !updatedCouponCodes?.generationType ||
        updatedCouponCodes?.generationType ===
          CouponPoolGenerationType.STATIC ||
        newForm.instoreRedemptionType === 'manager_comp'
      ) {
        selectedCouponPools = []
      } else if (!!newForm.selectedCouponPools) {
        selectedCouponPools =
          newForm.selectedCouponPools?.filter(
            cp =>
              cp.generation_type ===
              (updatedCouponCodes?.generationType ||
                CouponPoolGenerationType.DYNAMIC)
          ) || []
      }

      if (
        updatedCouponCodes?.generationType === CouponPoolGenerationType.STATIC
      ) {
        staticCode = updatedCouponCodes.staticCode
      }
    }

    const form = {
      ...baseFormModel,
      posFields: {
        ...posFields,
        [selectedProvider.value]: {
          ...providerFormModel,
          ...newForm,
          instoreRedemptionType: newForm.instoreRedemptionType,
          couponCodes: {
            ...providerFormModel.couponCodes,
            ...updatedCouponCodes,
            staticCode,
          },
          selectedCouponPools,
        },
      },
    }
    setFormModel(form)

    // if we change the generation type, we should
    // re-fetch available pools
    if (
      template?.id &&
      !isNDR(template.type) &&
      !!newForm.couponCodes &&
      providerFormModel?.couponCodes?.generationType !==
        newForm?.couponCodes?.generationType
    ) {
      await getCouponPools(template.id)
      dispatch(
        getMerchantCouponPools(
          newForm?.couponCodes?.generationType ||
            CouponPoolGenerationType.DYNAMIC
        )
      )
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div
        className={`h-300 ${css({
          marginTop: '30px',
          backgroundColor: merchantTheme.colors.grey05,
        })}`}
      >
        <EditNavbar
          title={
            <>
              <Text.SmallCaps3 tag="div" className="mb-xs" color="white">
                {t('configure_title')}
              </Text.SmallCaps3>
              <Text.Header4 tag="div" bold color="white">
                {t('title')}
              </Text.Header4>
            </>
          }
        >
          <div>
            <Button
              kind={Kind.MINIMAL_SECONDARY}
              className="mr-s"
              onClick={onCancel}
            >
              {t('cancel')}
            </Button>
            <Button
              kind={Kind.SECONDARY_REVERSE}
              onClick={onSave}
              disabled={!saveEnabled}
            >
              {t('save_and_return')}
            </Button>
          </div>
        </EditNavbar>
        <Container className="h-100">
          <Row>
            <Col className="p-xl">
              <div className="mb-xl">
                <Text.Header2 className="mb-xl mt-0">
                  {selectedProvider.label}
                </Text.Header2>
              </div>
              <Formsy
                //@ts-ignore
                ref={formRef}
                className="text-left form-group form-group-lg"
                onValid={() => setIsValidForm(true)}
                onInvalid={() => setIsValidForm(false)}
                onChange={onFormChange}
              >
                <Form
                  redemptionProvider={selectedProvider}
                  templateId={templateId}
                />
              </Formsy>
              <Switch condition={!isDraft}>
                <Text.BodyText3 className="mb-s">
                  {t('updating_instore_method')}
                </Text.BodyText3>
              </Switch>
              <Help
                title={t('help_title')}
                articles={getConfigureProviderHelpArticles(selectedProvider)}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default ConfigurePos
