import { getLocation } from 'actions/location'
import useDispatch from 'hooks/useDispatch'
import capitalize from 'lodash/capitalize'
import { Fields as Campaign } from 'models/Campaign'
import { Metrics } from 'models/CampaignMetric'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'
import configs from 'scenes/CampaignCenter/Report/configs'
import { selectLocation } from 'selectors/location'
import View from '../../Shared/ControlGroup/View'

type Props = {
  campaign: Campaign
  hasDivider?: boolean
  metrics?: Metrics
}

export function notEnoughData(config: any, props: Props): boolean {
  return (
    !config.getTargetFraction(props) ||
    config.getTargetFraction(props) < 0.01 ||
    !config.getControlFraction(props)
  )
}

export function targetFraction(
  config: any,
  props: Props,
  hasControlGroup: boolean
): number {
  if (notEnoughData(config, props) || !hasControlGroup) {
    return 0.7
  }

  return config.getTargetFraction(props)
}

export function controlFraction(
  config: any,
  props: Props,
  hasControlGroup: boolean
): number {
  if (notEnoughData(config, props) || !hasControlGroup) {
    return 0.4
  }

  return config.getControlFraction(props)
}

const ControlGroup: React.FC<Props> = props => {
  const { campaign, hasDivider } = props
  const location = useSelector(state =>
    selectLocation(state, campaign.config_location_id)
  )
  const locationId = campaign.config_location_id
  const config = configs[campaign.type]
  const hasControlGroup = !!campaign.config_control_percent
  const comparisonDescription = capitalize(
    I18n.t(
      'thanx_campaigns.report.campaign_types.' +
        campaign.type +
        '.control_group.description',
      { location: location && location.street }
    )
  )
  const successPhrase = I18n.t(
    'thanx_campaigns.report.campaign_types.' +
      campaign.type +
      '.success_phrase.present_tense',
    { location: location && location.fullName() }
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (!location && locationId) {
      dispatch(getLocation(locationId))
    }
  }, [locationId, location, dispatch])

  return (
    <View
      hasControlGroup={hasControlGroup}
      notEnoughData={notEnoughData(config, props)}
      targetFraction={targetFraction(config, props, hasControlGroup)}
      controlFraction={controlFraction(config, props, hasControlGroup)}
      comparisonDescription={comparisonDescription}
      successPhrase={successPhrase}
      hasDivider={!!hasDivider}
      color={config.color}
    />
  )
}

export default withMetrics(props => {
  return [configs[props.campaign.type].successMetric]
})(ControlGroup)
