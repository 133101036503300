import { Switch } from '@thanx/uikit/switch'
import { alert } from 'actions/flash'
import {
  CtaLinkData,
  updateLandingLinks,
  UPDATE_LANDING_LINKS_FAIL,
} from 'actions/landingLink'
import ConfirmModal from 'components/ConfirmModal'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import uniqueId from 'lodash/uniqueId'
import { LandingLinkSection, LandingLinkStyle } from 'models/LandingLink'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Blocks from 'scenes/Cms/components/Blocks'
import CmsPage from 'scenes/Cms/components/CmsPage'
import DraftBanner from 'scenes/Cms/components/DraftBanner'
import PublishBanner from 'scenes/Cms/components/PublishBanner'
import { UrlType, UrlValue } from 'scenes/Cms/components/UrlPicker'
import { getUrlValue } from 'scenes/Cms/helper'
import { selectApp } from 'selectors/app'
import { selectCtaLinks } from 'selectors/landingLink'
import { selectLandingPage } from 'selectors/landingPage'
import BlockItem from './BlockItem'
import Sidebar from './Sidebar'

export interface BlockData {
  url: UrlValue
  title: string
  style: LandingLinkStyle
}

interface FormModel {
  [id: string]: BlockData
}

const t = buildTranslate('cms.content.fixed_buttons')

const FixedButtons: React.FC = () => {
  const dispatch = useDispatch()
  const links = useSelector(selectCtaLinks)
  const app = useSelector(selectApp)
  const landingPage = useSelector(selectLandingPage)
  const isDraft = landingPage.state === 'inactive'
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [formModel, setFormModel] = useState<FormModel>(() => {
    if (links.length === 0) return {}

    return links.reduce((acc, obj) => {
      const { id, url, title, style, authenticable } = obj

      acc[id] = {
        url: getUrlValue(url, authenticable),
        title,
        style,
      }
      return acc
    }, {})
  })
  const [order, setOrder] = useState<string[]>(() => {
    if (links.length === 0) return []
    return links.sort((a, b) => a.position - b.position).map(l => `${l.id}`)
  })
  const [isLoading, setIsLoading] = useState(false)

  function onNewBlock() {
    const newId = uniqueId('content-')
    const newBlock = {
      [newId]: {
        url: {
          inapp: '',
          external: '',
          type: UrlType.External,
          authenticable: false,
        },
        title: '',
        style: LandingLinkStyle.Solid,
      },
    }
    setFormModel({
      ...formModel,
      ...newBlock,
    })
    setOrder([...order, newId])
  }

  async function handleSubmit() {
    const data: CtaLinkData[] = []
    order.forEach((orderId, index) => {
      const { title, url, style } = formModel[orderId]
      const link = links.find(l => `${l.id}` === orderId)

      data.push({
        id: link?.id,
        position: index + 1,
        title,
        url: url.type === UrlType.External ? url.external : url.inapp,
        section: LandingLinkSection.CTA,
        style,
      })
    })

    // destroy links which are deleted
    links.forEach(link => {
      // a link is deleted if it doesn't exist on the order array anymore
      if (order.indexOf(`${link.id}`) === -1) {
        data.push({
          ...link,
          section: LandingLinkSection.CTA,
          destroy: true,
        })
      }
    })

    setIsLoading(true)
    const response = await dispatch(updateLandingLinks(app.id, data))
    setIsLoading(false)
    setIsModalOpen(false)

    if (response.type === UPDATE_LANDING_LINKS_FAIL) {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
          timeout: 5,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: isDraft ? t('submit_draft_success') : t('submit_success'),
        timeout: 5,
      })
    )
  }

  return (
    <Formsy
      onChange={newFormModel => setFormModel(newFormModel)}
      onValidSubmit={() => (isDraft ? handleSubmit() : setIsModalOpen(true))}
      onInvalidSubmit={() =>
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('cms.content.form_error'),
            timeout: 5,
          })
        )
      }
    >
      <CmsPage
        className="mr-m"
        title={t('title')}
        description={t('description')}
        sidebar={<Sidebar isLoading={isLoading} />}
      >
        <Blocks
          type="button"
          order={order}
          setOrder={setOrder}
          maxBlockCount={2}
          renderItem={(itemId, index) => (
            <BlockItem
              key={itemId}
              index={index}
              id={itemId}
              data={formModel[itemId]}
            />
          )}
          onNewBlock={onNewBlock}
        />
        <Switch
          condition={isDraft}
          fallback={
            <PublishBanner buttonText={t('publish')} isLoading={isLoading} />
          }
        >
          <DraftBanner isLoading={isLoading} />
        </Switch>
      </CmsPage>
      <ConfirmModal
        title={t('modal.publish_changes')}
        description={t('modal.you_are_publishing')}
        confirmText={t('modal.publish')}
        isOpen={isModalOpen}
        isLoading={isLoading}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => handleSubmit()}
      />
    </Formsy>
  )
}

export default FixedButtons
