import { Button, Kind } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import { deleteCampaignVariant } from 'actions/campaignVariants'
import Book from 'components/Icons/Book'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import { Fields as CampaignVariant } from 'models/CampaignVariant'
import React from 'react'
import { useDispatch } from 'react-redux'
import { abTestingHelpUrl } from 'utilities/urlUtils'

type Props = {
  isOpen: boolean
  letter: string
  variant: CampaignVariant | null
  variantsLength?: number
  onClose: () => void
  shouldNotDelete: boolean
}
const t = buildTranslate('thanx_campaigns.builder.variants.delete')

const DeleteModal: React.FC<Props> = ({
  isOpen,
  letter,
  onClose,
  shouldNotDelete,
  variant,
  variantsLength = 0,
}) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const onApply = () => {
    if (variant) {
      if (!shouldNotDelete) {
        dispatch(deleteCampaignVariant(variant.campaign_id, variant.id))
      }
      onClose()
    }
  }
  const title = shouldNotDelete ? t('one_required') : t('title', { letter })
  const subtitle = shouldNotDelete
    ? t('delete_control')
    : variantsLength > 2
    ? t('subtitle_2')
    : `${t('subtitle')} ${t('subtitle_2')}`
  const proceedText = shouldNotDelete ? t('okay') : t('apply')
  return (
    <Modal
      buttonGroupChildren={
        <Switch condition={shouldNotDelete}>
          <div className="flex-1">
            <Button
              className={`font-weight-normal ${css({
                marginLeft: '-1rem',
              })}`}
              kind={Kind.MINIMAL}
              size="small"
              onClick={() => window.open(abTestingHelpUrl)}
            >
              {t('learn_more')}
              <Book className="ml-xs" />
            </Button>
          </div>
        </Switch>
      }
      hideCancel={shouldNotDelete}
      isOpen={isOpen}
      title={title}
      subtitle={subtitle}
      proceedText={proceedText}
      onProceed={onApply}
      onClose={onClose}
    />
  )
}

export default DeleteModal
