import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { tooltipCurrency, tooltipPercentage } from 'scenes/Reports/helpers'
import { generateUikitTheme } from 'theme'

type PropsT = {
  captureRate: number | string
  date?: string
  loyalty: number
  all: number
}

const t = buildTranslate('capture_rate.rate_chart')

const Tooltip: React.FC<PropsT> = ({ all, captureRate, date, loyalty }) => {
  const styletronEngine = new StyletronEngine({ prefix: 'st-ctt-' })
  const uikitTheme = generateUikitTheme()

  return (
    <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {date}
      </Text.BodyText4>
      <Text.Header3 className="mb-xs mt-0" tag="div">
        {tooltipPercentage(captureRate, 1, 1)}
      </Text.Header3>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {t('capture_rate')}
      </Text.BodyText4>
      <Text.Header4 className="mt-0" tag="div">
        {tooltipCurrency(loyalty)}
      </Text.Header4>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {t('tooltip.loyalty')}
      </Text.BodyText4>
      <Text.Header4 className="mt-0" tag="div">
        {tooltipCurrency(all)}
      </Text.Header4>
      <Text.BodyText4 color="grey70" tag="div">
        {t('tooltip.all')}
      </Text.BodyText4>
    </StyleProvider>
  )
}

export default Tooltip
