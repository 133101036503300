import { withFormsy } from 'formsy-react'
import { File } from 'formsy-react-components'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import React, { Component } from 'react'
import { FormGroup } from 'react-bootstrap'
import { textUtils } from 'utilities/textUtils'

type Props = {
  height?: number
  width?: number
  minWidth?: number
  minHeight?: number
  maxHeight?: number
  maxWidth?: number
  setImageURL: any
  setIsLoading: any
  fieldName: string
  disabled?: boolean
  children?: any
  defaultText: string
  handleUpdate?: boolean
} & PassDownProps<string | null>

type State = {
  isValid: boolean
  isLoading: boolean
  text: string
  uploadWidth: number
  uploadHeight: number
}

class ImageUploader extends Component<Props, State> {
  static defaultProps = {
    defaultText: 'Click to change image',
  }

  fileInputHandle: any = React.createRef()

  constructor(props: Props) {
    super(props)
    this.state = {
      isValid: true,
      isLoading: false,
      text: this.props.defaultText,
      uploadWidth: 0,
      uploadHeight: 0,
    }
  }

  setLoading = (loading: boolean) => {
    this.props.setIsLoading(loading)
    this.setState({ isLoading: loading })
  }

  fileDidChange = (_name: string, files: FileList) => {
    this.setLoading(true)
    let image = files[0]

    if (!image) {
      this.setLoading(false)
      return
    }
    const fileName = image.name
    let reader = new window.FileReader()
    reader.onloadend = () => {
      // @ts-ignore
      this.loadImage(reader.result, fileName)
    }
    reader.readAsDataURL(image)
  }

  loadImage = (input: string, fileName: string) => {
    let image = new window.Image()
    image.src = input
    image.onload = function (this: any) {
      if (
        !this.isValidDimension(image.width, 'width') ||
        !this.isValidDimension(image.height, 'height')
      ) {
        this.props.resetValue()
        this.setState({
          isValid: false,
          uploadWidth: image.width,
          uploadHeight: image.height,
        })
        this.setLoading(false)
      } else {
        this.setState({
          isValid: true,
          text: fileName.split('\\').slice(-1)[0],
        })
        this.props.setImageURL(input)
        if (this.props.handleUpdate) this.props.setValue(input)
      }
    }.bind(this)
  }

  clickPassthrough = () => {
    if (this.fileInputHandle?.current) {
      this.fileInputHandle.current.click()
    }
  }

  isValidDimension(value: number, dimension: string) {
    let valid = true
    let capitalDimension = textUtils.initialCapital(dimension)
    if (this.props[`max${capitalDimension}`]) {
      valid = value <= this.props[`max${capitalDimension}`]
    }
    if (valid && this.props[`min${capitalDimension}`]) {
      valid = value >= this.props[`min${capitalDimension}`]
    }
    if (valid && this.props[dimension]) {
      valid = value === this.props[dimension]
    }

    return valid
  }

  child = () => {
    if (!this.props.children) return null
    return React.cloneElement(this.props.children, {
      onClick: this.clickPassthrough.bind(this),
      width: this.props.width,
      height: this.props.height,
      maxWidth: this.props.maxWidth,
      maxHeight: this.props.maxHeight,
      minWidth: this.props.minWidth,
      minHeight: this.props.minHeight,
      isValid: this.state.isValid,
      uploadWidth: this.state.uploadWidth,
      uploadHeight: this.state.uploadHeight,
      text: this.state.text,
      disabled: this.props.disabled,
      setLoading: this.setLoading,
      isLoading: this.state.isLoading,
    })
  }

  render() {
    return (
      <div>
        <div>{this.child()}</div>
        <FormGroup className="hide">
          <File
            name={this.props.fieldName}
            changeCallback={this.fileDidChange}
            disabled={this.props.disabled}
            style={{ display: 'none' }}
            elementRef={this.fileInputHandle}
          />
        </FormGroup>
      </div>
    )
  }
}

export default withFormsy(ImageUploader)
