export default {
  title: 'Reward',
  page_title: 'Edit campaign reward | Thanx',
  subtitle: 'Increase customer engagement with an offer',
  select_placeholder: 'Select one',
  include_incentive_label: 'Include a reward',
  required_incentive_label: 'Choose a reward for this campaign',
  confirmation_alert: 'Your offer has been created successfully.',
  new_option: '+ Create new offer',
  template: {
    new: '+ Create new reward template',
    details: {
      show: 'View details',
      hide: 'Hide details',
      sections: {
        general: {
          name: 'Reward Details',
        },
        online: {
          name: 'Online',
        },
        instore: {
          name: 'In-Store',
        },
      },
    },
    not_enough_codes: 'Why are some rewards marked with a warning symbol',
    not_enough_codes_info:
      'Rewards marked with the warning symbol don’t have enough coupon codes to reward everyone in your audience.',
    some_templates_hidden: 'Why some rewards are hidden',
    some_templates_hidden_info:
      'All variants within an A/B test must use the same type of reward.',
    no_enabled_templates_placeholder: 'Not enough coupon codes',
  },
  empty_templates: {
    select_placeholder: 'No reward templates',
    info_box: {
      admin: {
        label:
          'There are no active reward templates available. To add a reward to this campaign, go to the rewards page and activate a reward template.',
        button: 'Go to rewards',
      },
      location_manager: {
        label:
          'There are no active reward templates available. To add a reward to this campaign, ask an administrator to activate a reward template.',
      },
    },
  },
  template_change_warning:
    'Your restrictions may have changed. Double check them below.',
  reward_creator: {
    title: 'Create new offer',
    button: 'Edit',
    name: {
      title: 'What do you want to call your offer?',
      label: 'Offer name',
      placeholder: 'Free slice of lemon cheesecake',
    },
    redeem_type: {
      title: 'What do you want to offer?',
      item: 'Free item',
      discount: 'Discount',
      discounts: {
        amount: {
          label: '$',
        },
        percent: {
          label: '%',
        },
      },
    },
    redeem_description: {
      title: 'How should it appear to customers?',
      label: 'Offer text',
      item: {
        placeholder: 'free item',
        add_on: 'Get a / an',
      },
      discount: {
        add_on: 'Get',
        amount: {
          placeholder: '$5 off your next order',
        },
        percent: {
          placeholder: '20% off your next order',
        },
      },
    },
    cost_value_title: 'What is the cost and value of the reward?',
    cost: {
      label: 'Cost to business',
      placeholder: '0',
    },
    value: {
      label: 'Retail price',
      placeholder: '0',
    },
    create_button: 'Create offer',
    cancel_button: 'Cancel',
    automatic: {
      label: 'Use statement credits',
      detail:
        'This option applies credits back to consumers’ credit cards automatically.',
      alert_text:
        'Choosing this option means that our team will need to review any campaigns sent to ensure you have sufficient funds in your account',
    },
    coupon: {
      label: 'Use coupon codes',
      detail:
        'This option requires you export one-use coupon codes from your POS and upload them to Thanx. Rewards will be accompanied by these codes as well as bar codes if your system uses them.',
      alert_text:
        'If you want to use coupon codes, please <a class="underline bold" href="mailto:merchant.support@thanx.com">send them to us here</a> and we will notify you via email when they are attached to your campaign.',
    },
  },
  restriction_creator: {
    button: 'Edit',
    title: 'Reward restrictions',
    subtitle:
      'Add specific restrictions around how customers can redeem their reward. These will automatically be shown in the fine print.',
    okay: 'Okay',
    signup_required: {
      title: 'Restrict reward to customers who join your loyalty program',
      body: 'In order to start capturing data about who your customers are, you can require them to create a loyalty account in order to redeem their reward.',
    },
    day_part: {
      title: 'Restrict reward to specific times or days of the week',
      subtitle: 'At least one day must be selected.',
      time_start_label: 'From',
      time_end_label: 'Until',
      description: {
        label: 'Describe this period (visible to customers)',
        placeholder: 'Happy hour weekend',
      },
    },
    start_end: {
      title: 'Restrict reward to a specific date range',
      subtitle: 'The reward will only be visible for this period of time.',
      start_label: 'Start',
      end_label: 'End',
    },
    minimum: {
      title: 'Minimum spent',
      subtitle:
        'Require customers to spend a certain amount in order to receive the discount e.g. Get a free burger when you spend $20 or more.',
      placeholder: '0',
    },
    maximum: {
      title: 'Maximum discount',
      subtitle:
        'Set the maximum discount customers can receive e.g. Customer can get up to $50 off.',
      placeholder: '0',
    },
    retireAfterDays: {
      title:
        'This reward will expire %{count} days after the customer receives it',
      title_1:
        'This reward will expire %{count} day after the customer receives it',
      label: 'Days',
      help: 'The customer will have fewer days to redeem this reward if the date range above is shorter than the number of days configured in this section.',
    },
    redemption_venue: {
      title: 'Online / In-store',
      instore: 'in-store',
      online: 'online',
      restriction: {
        all: 'Customers can redeem this in-store and online',
        instore: 'Customers can only redeem this in-store',
        online: 'Customers can only redeem this online',
      },
      subtitle: {
        editable:
          'By default this reward is redeemable both online and in-store. Limit where this reward can be redeemed using the restrictions below.',
        uneditable:
          'This reward was configured in the rewards manager to only be redeemable %{venue}',
      },
      radio_online: 'Redeemable only online',
      radio_instore: 'Redeemable only in-store',
    },
    golden_ticket: {
      hours_days_selector: {
        title: 'After customers activate this reward, they can see it for',
        hours: 'Hours',
        days: 'Days',
      },
      activate: {
        title: 'How long customers have to activate this reward',
        info: 'Customers have',
        info2: 'days to redeem this reward.',
      },
      after_activate: {
        title: 'How long customers see this reward after they activate it',
        rolling_time_window: {
          label: 'A rolling time window',
        },
        specific_date: {
          label: 'Until a specific date',
          info: 'After customers activate this reward, they can see it until',
        },
      },
    },
    hidden_menu: {
      title: 'How long customers see this reward',
      rolling_time_window: {
        label: 'A rolling time window',
        info: 'Customers can see this reward for',
        info2: 'days.',
      },
      specific_date: {
        label: 'Until a specific date',
        info: 'Customers can see this reward until',
      },
    },
    general: {
      title: 'How long customers have to redeem this reward',
      rolling_time_window: {
        label: 'A rolling time window',
        info: 'Customers have',
        info2: 'days to redeem this reward.',
      },
      specific_date: {
        label: 'Until a specific date',
        info: 'Customers can redeem this reward until',
      },
    },
  },
  restriction_summary: {
    title: 'Restrictions',
    locations: 'Customers can only redeem this at %{locations}.',
    signup_required:
      'Customers can only redeem if they join your loyalty program',
    day_part: {
      joined:
        'Customers can redeem <b>%{description}</b> (%{startAt}%{endAt}%{restrictionDays}%{restrictionTime})',
      start_at: 'beginning on %{startAt}',
      generic: 'only during certain periods',
      end_at: ' until %{endAt}',
      day_string: ' on %{dayString}',
      start_time_string: ' from %{startTimeString}',
      end_time_string: ' to %{endTimeString}',
      day_join: ' and ',
      last_day: ' and %{lastDay}',
      every_day: 'every day',
      time_range_joined: 'Customers can redeem%{restrictionTime}',
    },
    start_end: 'Customers can only redeem between %{startAt} and %{endAt}',
    min: 'Customers must spend at least $%{minAmount} to redeem',
    max: 'Customers cannot redeem more than $%{maxAmount}',
    experience_expiry:
      'After customers activate this reward, they can see it for %{time}',
    day: 'day',
    days: 'days',
    hour: 'hour',
    hours: 'hours',
    empty: 'You haven’t configured any restrictions',
    retire_after_days: 'Customers will have %{days} days to redeem this reward',
    retire_after_days_hidden_menu:
      'Customers can see this reward for %{days} days',
    optimizations: {
      title: 'Thanx Optimization',
      locations:
        'Drive customers to this location by only allowing the reward to be redeemed there.',
      retire_after_days:
        'We’ve found that the best performing rewards for this %{campaignName} campaign are only available for %{days} days.',
      signup_required:
        'Start collecting data on customers by requiring them to join your loyalty program to redeem their reward',
    },
  },
  fine_print: {
    title: 'Preview fine print',
    missing: 'No fine print generated for this campaign.',
    button_input_title: 'Add custom fine print',
  },
  warning_modal: {
    title: 'You’re leaving this campaign',
    image_alt: 'presents',
    body: 'You’re leaving this campaign to create a new reward template. We’ll save this campaign so you can come back and finish it once you’re done creating the reward template.',
    ctas: {
      cancel: 'Cancel',
      primary: 'Save & go to rewards',
    },
  },
  coupon_codes_modal: {
    not_enough: 'Not enough coupon codes',
    this_reward:
      'This reward template doesn’t have enough coupon codes to reward everyone in your audience.',
    select_reward:
      'Select this reward template anyway. I understand that if everyone in the audience attempted to redeem this reward, some customers would not be able to do so.',
    select_button: 'Select reward template',
    add_more: 'Add more coupon codes',
  },
}
