import { buildTranslate } from 'locales'

import useFlag from 'hooks/useFlag'

import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'

const t = buildTranslate('shared_words')

type PropsT = {
  setIsEditModalOpen: (value: boolean) => void
}

const EditButton = ({ setIsEditModalOpen }: PropsT) => {
  const isEditableLocationPhoneEnabled = useFlag(
    'MX-Editable-Location-PhoneNumber',
    false
  )

  return (
    <Switch condition={isEditableLocationPhoneEnabled}>
      <Button
        className="ml-s"
        type="button"
        kind="secondary"
        onClick={() => setIsEditModalOpen(true)}
      >
        {t('edit')}
      </Button>
    </Switch>
  )
}

export default EditButton
