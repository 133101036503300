import { Switch } from '@thanx/uikit/switch'
import useCurrentMerchantUser from 'hooks/useCurrentMerchantUser'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React, { useEffect, useRef } from 'react'
import Alert from 'react-bootstrap-five/Alert'
import { useFormManagement } from 'scenes/CampaignCenter/Builder/components/FormManagementContext'
import FormsyDatetimePicker from 'scenes/CampaignCenter/components/FormsyDatetimePicker'
import ScheduleAlert from 'scenes/CampaignCenter/components/ScheduleAlert'
import { useAbility } from 'utilities/ability'
import { useUserTimeZone } from 'utilities/userTimeZone'

import type { Fields as Campaign } from 'models/Campaign'

type Props = {
  campaign: Campaign
  shouldShowEndDate?: boolean
}

const t = buildTranslate('thanx_campaigns.review.schedule')

const ScheduleContainer: React.FC<Props> = ({
  campaign,
  shouldShowEndDate = false,
}) => {
  const merchantUser = useCurrentMerchantUser()
  const userTimeZone = useUserTimeZone()
  const ability = useAbility()
  const { triggerSave } = useFormManagement()

  const isNullable = campaign.type === 'custom_automated'
  // Use a ref so this doesn't change between renders
  const defaultEndAt = useRef(moment.tz(userTimeZone).add(30, 'days'))

  //@ts-ignore
  const isApprover = merchantUser && merchantUser.isApproverFor(campaign)
  const canSchedule = ability.can('schedule', 'Campaign') && !isApprover

  // HACK: If campaign.end_at is null, the end_at form element gets set to
  // the default value, but won't automatically trigger an update. We need
  // to do that manually here.
  // We can't update the campaign ourselves because the underlying DatePicker
  // component doesn't work as a controlled form component, so it would still
  // show the initial value rather than the updated value.
  // Custom automated now supports end_at = null
  useEffect(() => {
    if (!isNullable && !campaign.end_at && shouldShowEndDate) {
      triggerSave()
    }
  }, [campaign.end_at, isNullable, triggerSave, shouldShowEndDate])

  const error = (function () {
    const startAt = moment.tz(campaign.start_at, userTimeZone)
    const endAt = campaign.end_at
      ? moment.tz(campaign.end_at, userTimeZone)
      : isNullable
      ? null
      : defaultEndAt.current

    if (startAt.isBefore(moment.tz(userTimeZone))) {
      return t('errors.past')
    }

    if (
      shouldShowEndDate &&
      ((!endAt && !isNullable) ||
        (endAt && endAt.isBefore(startAt || moment.tz(userTimeZone))))
    ) {
      return t('errors.endBeforeStart')
    }

    return null
  })()

  const endValue =
    campaign.end_at || isNullable ? campaign.end_at : defaultEndAt.current
  return (
    <div className="mt-xl">
      <Switch condition={!!error}>
        <Alert variant="danger">
          <i className="fa fa-lg fa-exclamation-circle" />
          <span className="ml-s" dangerouslySetInnerHTML={{ __html: error }} />
        </Alert>
      </Switch>

      <FormsyDatetimePicker
        className="mb-xl"
        name="start_at"
        label={t('send_label')}
        calendarPlacement="top"
        value={campaign.start_at}
        nullLabel={t('send_now')}
        nonNullLabel={t('send_later')}
        // Non schedulers can't select send now
        isNullable={canSchedule}
      />

      <Switch condition={shouldShowEndDate}>
        <FormsyDatetimePicker
          className="mb-xl"
          name="end_at"
          label={t('stop_label')}
          calendarPlacement="top"
          value={endValue}
          nullLabel={t('ongoing')}
          nonNullLabel={t('on_date')}
          isNullable={isNullable}
        />
      </Switch>

      <Switch condition={!canSchedule}>
        <ScheduleAlert className="mt-l" isApprover={!!isApprover} />
      </Switch>
    </div>
  )
}

export default ScheduleContainer
