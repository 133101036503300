import React from 'react'

type Props = {
  title: string
  image: string
  alt: string
  className: string
}

const TabBox: React.FC<Props> = props => {
  const { title, image, alt, className } = props
  return (
    <div
      className={`tab-box padding-top-extra-large padding-bottom-extra-large ${className}`}
    >
      <div className="display-flex flex-justify-center">
        <img src={image} alt={alt} className="tab-box-icon" />
      </div>
      <div className="display-flex flex-justify-center">
        <p className="white deprecated__body-text-3 text-center padding-right-large padding-left-large">
          {title}
        </p>
      </div>
      <div className="shadow black-bkg padding-huge margin-top-extra-large opacity-25" />
    </div>
  )
}

export default TabBox
