import { RESET_DB } from 'actions/orm'
import {
  GetPurchasesMetricsAction,
  GET_PURCHASES_METRICS,
  GET_PURCHASES_METRICS_FAIL,
  GET_PURCHASES_METRICS_SUCCESS,
  ResolvedAction,
} from 'actions/purchasesMetrics'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import { attr, Model, ModelType } from 'redux-orm'
import { EmptyObject } from 'utilities/types'

export enum Type {
  ALL_PURCHASES = 'purchases_metric_all_purchases_count',
  FIRST_PURCHASES = 'purchases_metric_first_purchases_count',
}

export enum ShortType {
  ALL_PURCHASES = 'all_purchases_count',
  FIRST_PURCHASES = 'first_purchases_count',
}

export type MetricTypes = Array<ShortType>

export interface LocationPurchaseData {
  location_name: string | null
  location_address: string | null
  location_id: number | null
  purchases_count: number
  purchases_rate: number
}

interface PurchaseData {
  purchases_count: number
}

interface BaseMetric {
  id: string
  type: Type
  isLoading: boolean
  isErrored: boolean
}

export interface FirstPurchasesMetric extends BaseMetric {
  type: Type.FIRST_PURCHASES
  values: Array<LocationPurchaseData>
  total_purchases_count: number
  timezone: string
}

export interface AllPurchasesMetric extends BaseMetric {
  type: Type.ALL_PURCHASES
  values: Array<PurchaseData>
  timezone: string
  granularity: DateRangeGranularity
}

export type Fields = FirstPurchasesMetric | AllPurchasesMetric

const getTypeFromPath = url => {
  const paths: string[] = url.split('/') || []
  const type = `purchases_metric_${paths[paths.length - 1]}` as Type

  return type
}

export default class PurchasesMetric extends Model<
  // @ts-ignore
  typeof PurchasesMetric,
  Fields
> {
  static modelName: string = 'PurchasesMetric'

  static get fields(): any {
    return {
      type: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action:
      | ResolvedAction
      | { type: typeof RESET_DB }
      | GetPurchasesMetricsAction,
    // @ts-ignore
    PurchasesMetric: ModelType<PurchasesMetric>
  ) {
    switch (action.type) {
      case GET_PURCHASES_METRICS: {
        const params = action.payload.request?.params || {}
        const url: string = action.payload?.request?.url || ''
        const type = getTypeFromPath(url)
        PurchasesMetric.upsert({
          id: `${params.merchant_id}_${type}`,
          type: type,
          isLoading: true,
          isErrored: false,
        })
        break
      }
      case GET_PURCHASES_METRICS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetPurchasesMetricsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const url: string = previousAction.payload?.request?.url || ''
        const type = getTypeFromPath(url)
        PurchasesMetric.upsert({
          ...action.payload.data,
          id: `${params.merchant_id}_${type}`,
          merchant_id: params.merchant_id,
          type: type,
          isLoading: false,
          isErrored: false,
        })
        break
      }
      case GET_PURCHASES_METRICS_FAIL: {
        const previousAction = action.meta.previousAction as
          | GetPurchasesMetricsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const url: string = previousAction.payload?.request?.url || ''
        const type = getTypeFromPath(url)
        PurchasesMetric.upsert({
          id: `${params.merchant_id}_${type}`,
          type: type,
          isLoading: false,
          isErrored: true,
        })
        break
      }
      case RESET_DB:
        PurchasesMetric.all().delete()
        break
    }
  }
}
