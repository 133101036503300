import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

type Props = {
  children: React.ReactNode
  showText: string
  hideText: string
}

const Accordion: React.FC<Props> = ({ children, showText, hideText }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  if (!isExpanded) {
    return (
      <Button
        bsStyle="link"
        onClick={() => setIsExpanded(true)}
        className="mt-m mb-0 pl-0"
      >
        {showText}
        <i className="fa fa-caret-down ml-xs" />
      </Button>
    )
  }

  return (
    <>
      <Button
        bsStyle="link"
        onClick={() => setIsExpanded(false)}
        className="mt-m mb-m pl-0"
      >
        {hideText}
        <i className="fa fa-caret-up ml-xs" />
      </Button>
      {children}
    </>
  )
}

export default Accordion
