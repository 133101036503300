import { buildTranslate } from 'locales'
import React from 'react'
import Helmet from 'react-helmet'
import BasicMessage from './BasicMessage'

const t = buildTranslate('auth.expired_session')

const ExpiredSession: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <BasicMessage
        title={t('title')}
        message={t('message')}
        buttonText={t('back_to_login')}
        buttonPath="/auth/login"
      />
    </>
  )
}

export default ExpiredSession
