import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectBrands } from 'selectors/brand'
import CmsPage from '../../components/CmsPage'
import Item from './Item'

const t = buildTranslate('cms.brand.concept_assets')

const Brands: React.FC = () => {
  const brands = useSelector(selectBrands)
  return (
    <CmsPage title={t('title')} description={t('description')}>
      {brands.map(brand => (
        <Item key={brand.id} brand={brand} />
      ))}
    </CmsPage>
  )
}

export default Brands
