import { Text } from '@thanx/uikit/text'
import React from 'react'

type Props = {
  values?: string[]
  className?: string
}

const BadgeDescriptions = ({ values, className = '' }: Props) => {
  if (!values?.length) return null

  return (
    <div className={`flex-row mt-xs mb-m ${className}`}>
      {values.map(item => (
        <Text.BodyText5 tag="p" color="grey70" className="mb-0">
          {item}
        </Text.BodyText5>
      ))}
    </div>
  )
}

export default BadgeDescriptions
