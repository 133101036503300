import React from 'react'

const HelpIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>help</title>
      <path
        d="M10 13.3871C9.35484 13.3871 8.87097 13.9113 8.87097 14.5161C8.87097 15.1613 9.35484 15.6452 10 15.6452C10.6048 15.6452 11.129 15.1613 11.129 14.5161C11.129 13.9113 10.6048 13.3871 10 13.3871ZM10.2823 12.4194C10.5645 12.4194 10.7661 12.2177 10.7661 11.9355C10.7661 10.2016 13.9113 10.3226 13.9113 7.62097C13.9113 5.56452 12.0565 4.35484 9.95968 4.35484C8.14516 4.35484 7.1371 5.08065 6.29032 6.16935C6.12903 6.41129 6.16935 6.69355 6.41129 6.85484L6.93548 7.25806C7.1371 7.37903 7.41935 7.33871 7.58064 7.1371C8.18548 6.37097 8.79032 5.96774 9.95968 5.96774C11.5726 5.96774 12.2984 6.81452 12.2984 7.62097C12.2984 9.35484 9.15323 9.1129 9.15323 11.9355C9.15323 12.2177 9.39516 12.4194 9.6371 12.4194H10.2823ZM10 1.29032C14.7581 1.29032 18.7097 5.20161 18.7097 10C18.7097 14.8387 14.7984 18.7097 10 18.7097C5.16129 18.7097 1.29032 14.8387 1.29032 10C1.29032 5.20161 5.16129 1.29032 10 1.29032ZM10 0C4.47581 0 0 4.51613 0 10C0 15.5242 4.47581 20 10 20C15.4839 20 20 15.5242 20 10C20 4.51613 15.4839 0 10 0Z"
        fill="currentColor"
        shapeRendering="optimizeQuality"
      />
    </svg>
  )
}

export default HelpIcon
