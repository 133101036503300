import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { useStyletron } from 'styletron-react'
import Form from './Form'
import RightSide from './RightSide'

type PropsT = {
  campaign: Campaign
}

const t = buildTranslate('thanx_campaigns.builder.steps.incentive')

const Template: React.FC<PropsT> = (props: PropsT) => {
  const { campaign } = props
  const [css] = useStyletron()
  const { config } = useCampaignConfig()

  return (
    <div className="row">
      <div className="p-xl pb-0 col-md-6">
        <Text.Header2 color="grey90" className="mb-xs">
          {t('title')}
        </Text.Header2>
        <Text.BodyText3 color="grey70" tag="div">
          {t('subtitle')}
        </Text.BodyText3>
        <Form campaign={campaign} />
      </div>
      <div
        className={`grey-10-bkg padding-top-huge col-md-6 ${css({
          height: '900px',
        })}`}
      >
        <RightSide config={config} />
      </div>
    </div>
  )
}

export default Template
