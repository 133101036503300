import { resetPasswordAndAuthorize, RESET_PASSWORD_FAIL } from 'actions/auth'
import { alert } from 'actions/flash'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import BasicMessage from './BasicMessage'

const t = buildTranslate('auth')

type Props = RouteComponentProps<{ passwordResetCode: string }>

const ResetPassword: React.FC<Props> = props => {
  const { passwordResetCode } = props.match.params

  const dispatch = useDispatch()
  const [message, setMessage] = useState<{
    title: string
    message: string
  } | null>(null)
  const [action, setAction] = useState<{ text: string; path: string } | null>(
    null
  )

  useEffect(() => {
    async function validateCode() {
      const response = await dispatch(
        resetPasswordAndAuthorize(passwordResetCode)
      )
      if (response.type === RESET_PASSWORD_FAIL) {
        if (response.error.response?.status === 404) {
          setMessage({
            title: t('reset_password.expired_title'),
            message: t('reset_password.expired_message'),
          })
          setAction({
            text: t('reset_password.new_link'),
            path: '/auth/forgot_password',
          })
        } else {
          dispatch(
            alert({
              key: 'danger',
              message: I18n.t('errors.thanx'),
              error: response.error?.message,
            })
          )
        }
      } else {
        dispatch(push('/choose'))
      }
    }

    validateCode()
  }, [dispatch, passwordResetCode])

  if (message) {
    const messageProps = {
      ...(action === null
        ? { buttonAction: () => setMessage(null) }
        : {
            buttonText: action.text,
            buttonPath: action.path,
          }),
      ...message,
    }
    return (
      <>
        <Helmet>
          <title>{t('reset_password.page_title')}</title>
        </Helmet>
        <BasicMessage {...messageProps} />
      </>
    )
  }

  return (
    <Spinner
      className="text-center margin-top-huge"
      size="4x"
      isLoading={true}
    />
  )
}

export default ResetPassword
