import { Fields as LocationCategory } from 'models/LocationCategory'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const INDEX = 'locationCategory/GET_INDEX'
export const INDEX_SUCCESS = 'locationCategory/GET_INDEX_SUCCESS'
export const INDEX_FAIL = 'locationCategory/GET_INDEX_FAIL'

export function getLocationCategories(): AxiosAction<
  typeof INDEX,
  { location_categories: LocationCategory[] }
> {
  return {
    type: INDEX,
    payload: {
      client: 'default',
      request: {
        url: 'location_categories',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getLocationCategories>
>
