import contentPreview from 'assets/images/cms/menu_category_preview.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import CmsPage from 'scenes/Cms/components/CmsPage'
import PublishBanner from 'scenes/Cms/components/PublishBanner'
import ProductCardForm from './components/ProductCardForm'
import { FormModel } from './hooks'
import PageForm from './PageForm'
import Sidebar from './Sidebar'

const t = buildTranslate('cms.content.menu_category')

type Props = {
  data: FormModel
  isFetching: boolean
  isLoading: boolean
}

const MenuCategoryPage = ({ isFetching, isLoading, data }: Props) => {
  return (
    <CmsPage
      className="mr-m"
      title={t('content.title')}
      description={t('content.description')}
      sidebar={
        <Sidebar
          image={contentPreview}
          isLoading={isFetching}
          disabled={isLoading || isFetching}
        />
      }
    >
      <PageForm isLoading={isLoading} data={data} />
      <ProductCardForm
        isLoading={isLoading}
        data={data.product_card}
        type="product_card"
      />
      <ProductCardForm
        isLoading={isLoading}
        data={data.unavailable_product_card}
        type="unavailable_product_card"
      />

      <PublishBanner
        buttonText={t('footer.publish')}
        isLoading={isLoading}
        disabled={isLoading || isFetching}
        buttonType={'submit'}
      />
    </CmsPage>
  )
}

export default MenuCategoryPage
