const exclusiveDealsTerms = {
  title: 'Thanx Exclusive Deals Terms of Service',
  intro:
    'These Thanx Exclusive Deals Terms of Service (these “Terms of Service”), which are a part of the Thanx Merchant Agreement (the “Agreement”) between you and Thanx, Inc. (“Thanx”), apply to your use of the Exclusive Deals Functionality including, without limitation, the use of the Thanx Platform to market Merchant Products, offer Merchant Products for sale, or distribute digital codes redeemable for Merchant Products (“Redemption Codes”). Capitalized terms used, but not defined, in these Terms of Service, shall have the meanings ascribed to such terms in the Agreement. In the event of a conflict between these Terms of Service and the Agreement, these Terms of Service will govern.',
  changes: {
    title: 'Changes to these Terms of Service.',
    content:
      'Thanx may change these Terms of Service from time to time. The most current version of these Terms of Service, which will be posted at <a href="http://www.thanx.com/exclusive-deals-terms" >www.thanx.com/exclusive-deals-terms</a> will govern the Exclusive Deals Functionality and your use thereof. If you do not agree to any changes Thanx makes to these Terms of Service, you should discontinue using Exclusive Deals Functionality as your continued use of the Exclusive Deals Functionality shall constitute your acceptance of such changes.',
  },
  payment_processing: {
    title: 'Payment Processing.',
    content:
      'Thanx is not a bank or money transmitter and does not process payments for Merchant Products sold on the Thanx Platform. As a result, in order to use the Exclusive Deals Functionality and sell Merchant Products to, or otherwise collect payments from, Program Participants on the Thanx Platform, you must have an account with Stripe, the Payment Processor, and connect your Stripe account to the Thanx Platform. You acknowledge and agree that all payments made by Program Participants for Merchant Products are handled by the Payment Processor in accordance with the Processor Terms. Without limiting the foregoing, you shall be solely responsible for any and all fees charged by the Payment Processor (“Processor Fees”).',
  },
  functionality: {
    title: 'Exclusive Deals Functionality.',
    items: [
      'Merchant Products. You agree to use the Exclusive Deals Functionality in a lawful manner and to obey all laws, rules, and regulations applicable to your use of the Exclusive Deals Functionality. You expressly agree that you will not use the Exclusive Deals Functionality for, or to facilitate, any illegal activity or for, or to facilitate, any activity that Thanx or the Processor identifies, from time to time, as a “prohibited business or activity” including, without limitation, any activities specified in the Processor Terms.',
      'Exclusive Deals. You hereby grant Thanx the right to market the Merchant Products and distribute Redemption Codes to Program Participants through the Thanx Platform, the Thanx App and any Branded App.',
      'Redemption Codes. You hereby agree to securely supply Redemption Codes to Thanx for distribution through the Thanx Platform, the Thanx App and any Branded App. You further agree that the Redemption Codes will be redeemable for designated physical product(s) or real world service(s) and will conform to the requirements set forth in the Agreement and applicable law. The Redemption Codes and related materials provided by the Merchant must conform to those certain specifications provided by Thanx to Merchant in writing and shall be provided in a manner that enables Thanx to distribute the Redemption Codes through the Thanx Platform, the Thanx App and any Branded App.',
      'Redemption. The Exclusive Deals Functionality enables you to accept payment for specified, Merchant Products (as described in Section 2) and distribute Redemption Codes for redemption for said Merchant Products at brick-and-mortar retail locations you own and operate (“Merchant Stores”) and any other “Redemption Locations” described in the Thanx Exclusive Deals Schedule (“Online Redemption Locations”).',
      'Merchant Product Fulfillment and Management. You are solely responsible for: (a) providing the Merchant Products to any consumer who presents a Redemption Code for redemption at any Merchant Store or Online Redemption Locations; (b) properly recording and tracking unclaimed Merchant Products associated with outstanding Merchant Codes; and (c) providing customer support to consumers for Redemption Codes and Merchant Products. You expressly agree that your responsibilities with respect to the above shall survive any termination of the Agreement or the suspension of your use of the Exclusive Deals Functionality.',
      'Compliance. Thanx reserves the right to accept, reject or limit the Merchant Products you may market and offer for sale on the Thanx Platform. Without limiting the foregoing, Thanx may immediately discontinue marketing, distribution or sales of Merchant Products or Redemption Codes, at your sole cost and expense, if Merchant Products or any Merchant Product Terms (as defined below): (a) fail to conform to any representation, warranty, or other requirement in these Terms of Service; or (b) fail to comply with applicable law, as determined in the sole discretion of Thanx. Without limiting the foregoing, if a regulatory authority of competent jurisdiction: (x) determines that any programs or services offered under these Terms of Service or the Agreement are unlawful; or (y) changes an applicable law that materially affects the Exclusive Deals Functionality, the parties will work together in good faith to modify the Exclusive Deals Functionality in a manner that complies with applicable law. If the parties are unable to overcome or resolve any such issue, Thanx may discontinue your ability to offer the Exclusive Deals Functionality.',
    ],
  },
  representations: {
    title: 'Representations and Warranties.',
    items: [
      'You represent, warrant, covenant, and agree that: (a) you are authorized to enter into, and perform under, these Terms of Service, and your entry into and performance of your obligations under these Terms of Service do not violate, and will not cause a default under, any other agreement to which you are or become a party; (b) you will fully perform your obligations under the Agreement and these Terms of Service in compliance with all applicable federal, state, and local laws, rules, and regulations, including interpretations thereof (collectively, “Applicable Law”); (c) the Merchant Products, Redemption Codes, any associated packaging, disclosures, and all point-of-sale materials (including, without limitation, marketing materials, redemption instructions) created by you or at your direction and any “Merchant Product Terms” set forth on the Thanx Exclusive Deals Schedule (“Merchant Product Terms”) have been or will be produced and delivered in full compliance with all Applicable Law and will not infringe upon, misappropriate or violate any other party’s intellectual property rights including, without limitation, any patent, trademark, or copyright; (d) to the extent your, or any of your affiliates, trade names, service marks, brands, and trademarks (collectively, the “Merchant Marks”) are used in furtherance of a party’s obligations under the Agreement or these Terms of Service, you have the right to use, whether by ownership or license, such Merchant Marks, and the use thereof does not violate any agreement binding upon you nor any patent, copyright, trademark, service mark, or other right of any person; and (e) you have all licenses required to provide and sell the Merchant Products as necessary to perform under the Agreement and these Terms of Service.',
    ],
  },
  taxes: {
    title: 'Taxes and Application Fees.',
    items: [
      'Taxes. You are solely responsible (i) for determining what, if any, taxes apply to your use of the Exclusive Deals Functionality (including, without limitation, the sale of Merchant Products); and (ii) assessing, collecting, reporting, or remitting any such taxes to the appropriate tax authority. Without limiting the foregoing, Thanx is not obligated to nor will Thanx determine the applicability of any taxes, or calculate, report, or remit any taxes to any tax authority arising from your use of the Exclusive Deals Functionality and you shall bear sole financial responsibility for any and all sales, use, or other similar taxes, including any interest penalties and additions related thereto (“Transaction Taxes”) imposed on or arising from the sale of Merchant Products and redemption of Redemption Codes, and will hold Thanx harmless from any liability for, or arising from, any such Transaction Taxes.',
      'Application Fees. Thanx will charge the Application Fee (as set forth in the Thanx Exclusive Deals Schedule) for Merchant Products sold through the Thanx Platform. You hereby authorize and direct the Payment Processor to collect and/or withhold the Application Fee from the amounts collected in connection with each Merchant Product transaction and to transfer the same to Thanx. For the avoidance of doubt, you understand that the Application Fee is separate from, and does not include, any Payment Processor Fees.',
    ],
  },
  disclaimers: {
    title: 'Disclaimers.',
    content:
      'The Thanx Platform (including the activity of the Payment Processor) including the Exclusive Deals Functionality and related functionality are provided “AS IS” and on an “AS AVAILABLE” basis. Thanx does not guarantee the delivery of Redemption Codes or Exclusive Deals Functionality will be error free or uninterrupted and THANX HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. Thanx will not be liable to you or Program Participants for any failures, delays and/or interruptions in the operation of the Exclusive Deals Functionality, failures or errors in connection with Redemption Codes, including without limitation failure to process or error in processing a Redemption Code, or any event outside the control of Thanx.',
  },
  indemnification: {
    title: 'Indemnification.',
    content:
      'You will defend, indemnify and hold Thanx its affiliates and affiliated entities harmless from any settlement, damage award, liability or cost incurred by any of them in connection with a claim brought by a third party arising out of or related to: (a) the Exclusive Deals Functionality including, without limitation, any Merchant Product or your redemption of (or failure to redeem) Redemption Codes therefore; (b) a violation by you of your representations and warranties; or (c) a breach by you of the Agreement or these Terms of Service.',
  },
  liability: {
    title: 'Limitation of Liability.',
    content:
      'IN NO EVENT WILL THANX BE LIABLE TO YOU OR ANYONE ELSE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE THANX PLATFORM, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE THANX PLATFORM, OR FOR ANY INFORMATION OBTAINED FROM OR THROUGH THE THANX PLATFORM, ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. In no event will Thanx’s aggregate liability exceed the Fees payable in the 12 month period prior to the event that gave rise to the claim.',
  },
}

export default exclusiveDealsTerms
