import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import PageHeader from 'components/PageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'
import OneClickBox from './components/OneClickBox'

const t = buildTranslate('looker')
enum Tabs {
  CART_CONVERSION = 'cart_conversion',
  UNACCEPTED_CARTS = 'unaccepted_carts',
}

const OrderingConversionReport: React.FC = () => {
  const merchant = useCurrentMerchant()
  const [css] = useStyletron()
  const [activeTab, setActiveTab] = useState<
    'cart_conversion' | 'unaccepted_carts'
  >('cart_conversion')

  if (!merchant) return null

  const title = t('urls.dashboards/86.title')
  const conversionHeight = 2550
  const unacceptedHeight = 2500

  return (
    <PageHeader title={title}>
      <Helmet>
        <title>
          {I18n.t(`titles./reports/ordering_conversion#${activeTab}`)}
        </title>
      </Helmet>
      <Tab.Container
        activeKey={activeTab}
        transition={false}
        onSelect={(tab: string | null) => {
          if (!tab) return
          setActiveTab(tab as Tabs)
        }}
      >
        <TabNav>
          <TabItem
            state={Tabs.CART_CONVERSION}
            title={t(`urls.dashboards/86.tabs.cart_conversion`)}
          />
          <TabItem
            state={Tabs.UNACCEPTED_CARTS}
            title={t(`urls.dashboards/86.tabs.unaccepted_carts`)}
          />
        </TabNav>
        <Tab.Content className="pb-l h-100 grey-05-bkg">
          <TabPane state={Tabs.CART_CONVERSION}>
            <Container
              className={`px-0 ${css({
                minHeight: `${conversionHeight + 300}px`,
              })}`}
            >
              <LookerIframe
                path={'dashboards/225'}
                merchant={merchant}
                height={`${conversionHeight}px`}
              />
              <OneClickBox className="mt-xl" />
              <LookerFooter className="my-xl" />
            </Container>
          </TabPane>
          <TabPane state={Tabs.UNACCEPTED_CARTS}>
            <Switch
              condition={
                !!merchant?.ordering_provider &&
                ['olo', 'toast'].includes(merchant?.ordering_provider)
              }
              fallback={
                <Container>
                  <Text.Header4 bold color="grey90" className="text-center">
                    {t('olo_toast_only')}
                  </Text.Header4>
                </Container>
              }
            >
              <Container
                className={`px-0 ${css({
                  minHeight: `${unacceptedHeight + 300}px`,
                })}`}
              >
                <LookerIframe
                  path={'dashboards/215'}
                  merchant={merchant}
                  height={`${unacceptedHeight}px`}
                />
                <LookerFooter className="my-xl" />
              </Container>
            </Switch>
          </TabPane>
        </Tab.Content>
      </Tab.Container>
    </PageHeader>
  )
}

export default OrderingConversionReport
