import { Switch } from '@thanx/uikit/switch'
import Input from 'components/Form/Input'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React, { useState } from 'react'
import RestrictionSection from './RestrictionSection'
import RestrictionStartEnd from './RestrictionStartEnd'

type Props = {
  campaign: Campaign
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator'
)

const DateRange: React.FC<Props> = ({ campaign }) => {
  const [toggleDateRange, setToggleDateRange] = useState(
    !!campaign.redeem_start_at || !!campaign.redeem_end_at
  )
  return (
    <>
      <RestrictionSection
        title={t('start_end.title')}
        subtitle={t('start_end.subtitle')}
        toggleValue={toggleDateRange}
        onChange={(value: boolean) => setToggleDateRange(value)}
      >
        <RestrictionStartEnd campaign={campaign} />
      </RestrictionSection>
      {/* Hack to set the values to null when the section is closed */}
      <Switch condition={!toggleDateRange}>
        <Input type="hidden" name="redeem_start_at" value={null} />
        <Input type="hidden" name="redeem_end_at" value={null} />
      </Switch>
    </>
  )
}

export default DateRange
