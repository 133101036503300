import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React, { useContext } from 'react'
import HoursDaysSelector from 'scenes/CampaignCenter/Builder/components/Incentive/RestrictionCreator/NdrRewardRestrictions/HoursDaysSelector'
import { EditContext } from 'scenes/Points/CreateEdit/EditContext'
import HiddenMenu from './HiddenMenu'

const t = buildTranslate('points.create_edit.restrictions.modal')

const Length: React.FC = () => {
  const { redeemTemplate, template } = useContext(EditContext)
  const type = redeemTemplate?.type || ''
  if (!type.includes('experience')) {
    return null
  }
  return (
    <div className="border-1 grey-30-border border-radius-5 p-m mt-m">
      <Text.Header4 className="mt-0 mb-m">{t('how_long')}</Text.Header4>
      {type === 'experience/golden_ticket' && (
        <HoursDaysSelector
          name="redeem_experience_expires_in_days"
          days={template?.redeem_experience_expires_in_days}
        />
      )}
      {type === 'experience/hidden_menu' && (
        <HiddenMenu value={template?.redeem_retire_after_days} />
      )}
    </div>
  )
}

export default Length
