import { FormGroup } from '@thanx/uikit/form-group'
import { FormsySelect } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import eye from 'assets/images/cms/eye.png'
import { buildTranslate } from 'locales'
import React from 'react'
import { UrlValue } from 'scenes/Cms/components/UrlPicker'
import {
  MenuCategory,
  useUniqueMenuCategories,
} from 'scenes/Cms/Content/AppHomePage/hooks'

type Props = {
  id: string
  url: UrlValue
}

const t = buildTranslate(
  'cms.content.app_home_page.content.edit_block.content.featured_content'
)

const MenuCategorySelector = ({ url, id }: Props) => {
  const [css] = useStyletron()
  const { menuCategories, isLoading } = useUniqueMenuCategories()
  const getMenuOptions = (categories: MenuCategory[]) => {
    const menuOptions = categories.map(category => ({
      label: category.name,
      value: category.slug,
    }))
    menuOptions.unshift({
      label: t('first_menu_category'),
      value: '',
    })
    return menuOptions
  }

  const menuOptions = getMenuOptions(menuCategories)
  const urlQueryParam = url.queryParams?.split('category=')[1]

  return (
    <FormGroup label={t('menu_category_selector')} className={`mt-s`}>
      <FormsySelect
        name={`${id}.url.queryParams`}
        value={urlQueryParam || ''}
        options={menuOptions}
        isLoading={isLoading}
      />

      {urlQueryParam && (
        <div className="d-flex mt-m align-items-center">
          <img
            alt={t('eye_icon')}
            className={`mr-s ${css({
              height: '16px',
            })}`}
            src={eye}
          />
          <Text.BodyText4 color={'grey70'}>
            {t('menu_category_disclaimer')}
          </Text.BodyText4>
        </div>
      )}
    </FormGroup>
  )
}

export default MenuCategorySelector
