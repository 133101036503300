import orm from 'models/orm'
import type { Fields } from 'models/UserTagValue'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectUserTagValues = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.UserTagValue.all().toRefArray()
  }
)

export const selectCurrentMerchantUserTagValues = createSelector(
  selectUserTagValues,
  (state: any) => state.auth,
  (_state: any, key: string) => key,
  (userTagValues: Fields[], auth: any, key: string): Fields[] => {
    return userTagValues.filter(
      tagValue =>
        tagValue.parent === key &&
        tagValue.merchant_id === auth.current_merchant_id
    )
  }
)
