import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields } from 'models/User'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.user_list.table')

type PropsT = {
  users: Fields[]
  isLoading: boolean
}

const Table: React.FC<PropsT> = ({ users, isLoading }: PropsT) => {
  return (
    <table className="full-width">
      <thead>
        <tr>
          <HeaderCell className={'w-75'}>{t('name')}</HeaderCell>
          <HeaderCell key="last" />
        </tr>
      </thead>
      <tbody>
        {users.map(user => (
          <Row key={user.id} user={user} isLoading={isLoading} />
        ))}
      </tbody>
    </table>
  )
}

export default Table
