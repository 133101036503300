import { buildTranslate } from 'locales'
import { LinkActivityMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import Row from './Row'

type PropsT = {
  campaignId: number
}

const t = buildTranslate(
  'thanx_campaigns.report.v3.summary.engagement.email.link_activity'
)

const Rows: React.FC<PropsT> = props => {
  const { campaignId } = props

  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'link_activity')
    ) as LinkActivityMetric) || {}

  const { isLoading, isErrored, data } = metric

  if (data?.link_clicks && data.link_clicks.length > 0) {
    return (
      <>
        {data.link_clicks.map((linkInfo, index) => {
          return (
            <Row
              type={linkInfo.type}
              text={linkInfo.text}
              url={linkInfo.url}
              count={linkInfo.count}
              key={index}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      <Row
        type={t('unsubscribe.type')}
        text={t('unsubscribe.text')}
        url={t('unsubscribe.url')}
        count={0}
        isLoading={!!isLoading}
        isErrored={!!isErrored}
      />
      <Row
        type={t('preferences.type')}
        text={t('preferences.text')}
        url={t('preferences.url')}
        count={0}
        isLoading={!!isLoading}
        isErrored={!!isErrored}
      />
    </>
  )
}

export default Rows
