import { Fields } from 'models/SmsTrigger'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_SMS_TRIGGERS = 'smsTriggers/GET_SMS_TRIGGERS'
export const GET_SMS_TRIGGERS_SUCCESS = 'smsTriggers/GET_SMS_TRIGGERS_SUCCESS'
export const GET_SMS_TRIGGERS_FAIL = 'smsTriggers/GET_SMS_TRIGGERS_FAIL'

export function getSmsTriggers(): AxiosAction<
  typeof GET_SMS_TRIGGERS,
  { sms_triggers: Fields[] }
> {
  return {
    type: GET_SMS_TRIGGERS,
    payload: {
      client: 'default',
      request: {
        url: 'sms_triggers',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getSmsTriggers>
>
