import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as UserItem } from 'models/UserItem'
import React from 'react'
import { Button } from 'react-bootstrap'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
// import ItemPicture from './ItemPicture'

const t = buildTranslate('users.view.activity.purchased_items')

type PropsT = {
  item: UserItem
  onDetailsClick?: (item: UserItem) => void
}

const Row: React.FC<PropsT> = ({ item, onDetailsClick }: PropsT) => {
  function handleDetailsClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    onDetailsClick?.(item)
  }

  return (
    <tr>
      <BodyCellContainer
        isLoading={false}
        isErrored={false}
        className="align-middle"
      >
        <div className="d-flex align-items-center">
          {/* TODO: uncomment when images are available */}
          {/*<ItemPicture src={item.image} />*/}
          <Text.BodyText4 color="grey70">
            <div>{item.label}</div>
          </Text.BodyText4>
        </div>
      </BodyCellContainer>
      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className="text-right"
      >
        <Text.BodyText4 color="grey70" className="ml-s">
          {item.purchase_count}
        </Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className="text-right"
      >
        <Text.BodyText4 color="grey70" className="ml-s">
          <Button onClick={handleDetailsClick} bsStyle="link">
            {t('details')}
          </Button>
        </Text.BodyText4>
      </BodyCellContainer>
    </tr>
  )
}

export default Row
