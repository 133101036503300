import FetchableMetricSection from 'components/FetchableMetricSection'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import {
  ActivationRateCumulativeMetric,
  ShortType,
} from 'models/ActivationRateMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import { AVAILABLE_IN_DAYS } from 'scenes/Reports/ActivationRateReport/helpers'
import { daysUntilReportAccess, getBenchmarkRate } from 'scenes/Reports/helpers'
import { selectActivationMetricByType } from 'selectors/activationRateMetric'
import AvailableAfterButton from '../AvailableAfterButton'
import Benchmark from '../Benchmark'
import Card from '../Card'
import MetricCounter from '../MetricCounter'
import { MetricCardT } from './index'

const t = buildTranslate('home.metrics')

const ActivationRateMetric: React.FC<MetricCardT> = ({ merchant }) => {
  const dispatch = useDispatch()
  const cumulativeMetrics = useSelector(state =>
    selectActivationMetricByType(state, merchant?.id, ShortType.CUMULATIVE)
  ) as ActivationRateCumulativeMetric

  const { rate: benchmarkRate } = getBenchmarkRate(
    merchant.merchant_type,
    'activation_rate'
  )

  const remainingDays = daysUntilReportAccess(merchant, AVAILABLE_IN_DAYS)
  const shouldFetch = Boolean(remainingDays !== null && remainingDays < 1)

  return (
    <Card
      title={t('activation_rate.title')}
      onClick={() => dispatch(push('/reports/activation#activation_rate'))}
      sections={[
        { content: t('activation_rate.popovers.section_1') },
        {
          title: t('shared.popovers.how_its_calculated'),
          content: t('activation_rate.popovers.section_2'),
        },
        {
          title: t('shared.popovers.example'),
          content: t('activation_rate.popovers.section_3'),
        },
      ]}
    >
      <FetchableMetricSection
        merchantId={merchant?.id}
        shouldFetch={shouldFetch}
        type="activation_rate_metric"
        metricTypes={[ShortType.CUMULATIVE]}
      >
        {shouldFetch || cumulativeMetrics?.isLoading ? (
          <MetricCounter
            currentRate={cumulativeMetrics?.current?.rate}
            previousRate={cumulativeMetrics?.previous?.rate}
            isLoading={cumulativeMetrics?.isLoading}
            isErrored={cumulativeMetrics?.isErrored}
          />
        ) : (
          <AvailableAfterButton
            willBeAvailableAfterLaunch={remainingDays === null}
            remainingDays={remainingDays}
          />
        )}
        <Benchmark benchmarkRate={benchmarkRate} />
      </FetchableMetricSection>
    </Card>
  )
}

export default ActivationRateMetric
