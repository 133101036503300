// @flow
import React, { Component } from 'react'
import ConstantContactImage from 'assets/images/mail-providers/ConstantContact.png'
import MailchimpImage from 'assets/images/mail-providers/Mailchimp.png'
import Provider from './Provider'
import { EmailProviderType } from '../constants'

type Props = {
  providers: Array<string>,
  onChange: string => void,
}
type State = {
  selected: string,
}

export default class ProviderPicker extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selected: '',
    }
  }

  providers() {
    const providerInfos = {
      [EmailProviderType.CONSTANT_CONTACT]: {
        img: ConstantContactImage,
        name: 'ConstantContact',
      },
      [EmailProviderType.MAILCHIMP]: {
        img: MailchimpImage,
        name: 'Mailchimp',
      },
    }

    let components = []
    this.props.providers.forEach((providerType, index) => {
      const providerInfo = providerInfos[providerType]
      components.push(
        <Provider
          key={index}
          selected={this.state.selected === providerType}
          onClick={this.select.bind(this)}
          value={providerType}
          {...providerInfo}
        />
      )
      if (index % 2 === 1) {
        components.push(
          <div
            className="clearfix visible-xs-block visible-sm-block"
            key={`clearfix-${index}`}
          />
        )
      }
    })
    return components
  }

  select(providerType: string) {
    this.setState({
      selected: providerType,
    })
    this.props.onChange(providerType)
  }

  render() {
    return this.providers()
  }
}
