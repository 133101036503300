import { Text } from '@thanx/uikit/text'
import Section from 'components/Section'
import React from 'react'

type Props = {
  title: string
  description: string
  className?: string
}

function EmptySection(props: Props) {
  const { title, description, className = '' } = props

  return (
    <Section className={`p-xl ${className}`} kind="secondary">
      <Text.Header4 className="mb-s">{title}</Text.Header4>
      <Text.BodyText4 color="grey70">{description}</Text.BodyText4>
    </Section>
  )
}

export default EmptySection
