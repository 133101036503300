import { Fields as Merchant } from 'models/Merchant'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_MERCHANTS = 'merchant/GET_MERCHANTS'
export const GET_MERCHANTS_SUCCESS = 'merchant/GET_MERCHANTS_SUCCESS'
export const GET_MERCHANTS_FAIL = 'merchant/GET_MERCHANTS_FAIL'

export const GET_MERCHANT = 'merchant/GET_MERCHANT'
export const GET_MERCHANT_SUCCESS = 'merchant/GET_MERCHANT_SUCCESS'
export const GET_MERCHANT_FAIL = 'merchant/GET_MERCHANT_FAIL'

export const REQUEST_CUSTOM_CAMPAIGN = 'merchant/REQUEST_CUSTOM_CAMPAIGN'
export const REQUEST_CUSTOM_CAMPAIGN_SUCCESS =
  'merchant/REQUEST_CUSTOM_CAMPAIGN_SUCCESS'
export const REQUEST_CUSTOM_CAMPAIGN_FAIL =
  'merchant/REQUEST_CUSTOM_CAMPAIGN_FAIL'

export function getMerchants(): AxiosAction<
  typeof GET_MERCHANTS,
  { merchants: Merchant[] }
> {
  return {
    type: GET_MERCHANTS,
    payload: {
      client: 'auth',
      request: {
        url: 'merchants/',
        method: 'get',
      },
    },
  }
}

export function getMerchant(
  id: number
): AxiosAction<typeof GET_MERCHANT, { merchant: Merchant }> {
  return {
    type: GET_MERCHANT,
    payload: {
      client: 'auth',
      request: {
        url: `merchants/${id}`,
        method: 'get',
      },
    },
  }
}

export function requestCustomCampaign(data: {
  message: string
  name: string
}): AxiosAction<typeof REQUEST_CUSTOM_CAMPAIGN> {
  return {
    type: REQUEST_CUSTOM_CAMPAIGN,
    payload: {
      client: 'default',
      request: {
        url: `actions/request_custom_campaign`,
        method: 'post',
        data: data,
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getMerchants>>
  | ResolvedAxiosAction<ReturnType<typeof getMerchant>>
  | ResolvedAxiosAction<ReturnType<typeof requestCustomCampaign>>
