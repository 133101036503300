import { buildTranslate } from 'locales'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React from 'react'
import Card from './Card'

type PropsT = {
  template: RedeemTemplate
}

const t = buildTranslate('redeem_manager.detail.cards.customer_support')

const CustomerSupportCard: React.FC<PropsT> = ({ template }) => {
  const { granted_program_visible } = template

  return (
    <Card title={t('title')} isActive={granted_program_visible}>
      <p className="grey-70">
        {t(granted_program_visible ? 'available' : 'not_available')}
      </p>
    </Card>
  )
}

export default CustomerSupportCard
