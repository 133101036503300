import { useStyletron } from '@thanx/uikit/theme'
import SelectWithFormsy from 'components/Form/SelectWithFormsy'
import GoogleMap from 'components/GoogleMap'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import type { Fields as Campaign } from 'models/Campaign'
import type { Fields as Location } from 'models/Location'
import numeral from 'numeral'
import React from 'react'
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import LocationSelect from 'scenes/CampaignCenter/Builder/components/LocationSelect'
import StatBox from 'scenes/CampaignCenter/Builder/components/StatBox'
import { locationsToSelectOptions } from 'utilities/locationUtils'
import { textUtils } from 'utilities/textUtils'
import { getMapLocations, getMultiSelectLocations } from './helper'

const t = buildTranslate(
  'thanx_campaigns.campaigns.location_promote.steps.setup.audience'
)

type Props = {
  isLoading: boolean
  campaign: Campaign
  locations: Location[]
  selectedLocation?: Location | null
  selectedLocations: Location[]
  userCount: number
}

const View: React.FC<Props> = props => {
  const {
    isLoading,
    campaign,
    locations,
    selectedLocation,
    selectedLocations,
    userCount,
  } = props

  function optimization() {
    if (
      isEmpty(selectedLocations) ||
      selectedLocations.length < 2 ||
      !selectedLocation
    )
      return null

    const targetedList = textUtils.toSentence({
      array: selectedLocations.map(location => location.street),
      punctuation: '',
    })

    return (
      <Translate
        value="thanx_campaigns.campaigns.location_promote.steps.setup.audience.stat.tooltip"
        promotedLocation={selectedLocation}
        targetedList={targetedList}
        dangerousHTML
      />
    )
  }

  function stat() {
    if (!selectedLocation || isEmpty(selectedLocations)) return null

    const message = (
      <Translate
        value="thanx_campaigns.campaigns.location_promote.steps.setup.audience.stat.message"
        promotedLocation={selectedLocation.street}
        targetedLocation={selectedLocations[0].street}
        userCount={numeral(userCount).format('0,0')}
        count={selectedLocations.length}
        dangerousHTML
      />
    )

    const optimizationText = optimization()
    const statProps = optimizationText ? { tooltip: optimizationText } : {}

    return (
      <StatBox
        {...statProps}
        title={t('stat.supertitle')}
        message={message}
        className="category-targeted-bkg white"
      />
    )
  }

  function initialPosition(): google.maps.LatLngLiteral | null {
    if (!selectedLocation) return null

    const { latitude, longitude } = selectedLocation
    return {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    }
  }

  const mapLocations = getMapLocations(locations, campaign)
  const multiSelectLocations = getMultiSelectLocations(locations, campaign)
  const [css] = useStyletron()
  return (
    <div className="container">
      <Row>
        <Col
          md={6}
          className="padding-top-huge padding-right-huge padding-bottom-huge"
          style={{
            paddingBottom: '96px',
          }}
        >
          <div className="padding-right-huge">
            <Translate
              value="thanx_campaigns.campaigns.location_promote.steps.setup.audience.title"
              tag="p"
              className="grey-90 font-size-32 margin-bottom-micro bold"
            />
            <Translate
              value="thanx_campaigns.campaigns.location_promote.steps.setup.audience.subtitle"
              tag="em"
              className="grey-50 font-size-20 margin-bottom-extra-large block"
            />
            <div className="margin-bottom-extra-large">{stat()}</div>
            <Spinner
              isLoading={isLoading}
              size="4x"
              className="text-center padding-top-huge"
            >
              <FormGroup>
                <ControlLabel className="body-text-4 bold">
                  {t('selector.title')}
                </ControlLabel>
                <SelectWithFormsy
                  options={locationsToSelectOptions(locations)}
                  placeholder={I18n.t(
                    'thanx_campaigns.campaigns.location_close.steps.setup.select.placeholder'
                  )}
                  defaultValue={campaign.config_location_id}
                  name="config_location_id"
                  validations="isRequired"
                  validationErrors={{
                    isRequired: I18n.t('validations.is_required'),
                  }}
                />
              </FormGroup>
              <div className="padding-top-large">
                <LocationSelect
                  locations={multiSelectLocations}
                  defaultLocationIds={campaign.config_target_location_ids}
                  label={t('multiselect.title')}
                  placeholder={t('multiselect.placeholder')}
                  name="config_target_location_ids"
                  isDisabled={isEmpty(selectedLocation)}
                  validations={{ isRequired: true }}
                  validationErrors={{
                    isRequired: I18n.t('validations.is_required'),
                  }}
                />
              </div>
            </Spinner>
          </div>
        </Col>
        <Col
          md={6}
          className={`grey-10-bkg p-0 ${css({
            height: '900px',
          })}`}
        >
          <GoogleMap
            className={css({
              height: '100%',
            })}
            locations={mapLocations}
            center={initialPosition()}
          />
        </Col>
      </Row>
    </div>
  )
}

export default View
