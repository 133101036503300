import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import CcpaTable from './ccpatable'

const t = buildTranslate('privacy.ccpanotice')

function CcpaNotice() {
  return (
    <div className="p-xl pt-xl">
      <Text.Header2>{t('title')}</Text.Header2>
      <Text.BodyText3 className="italic">{t('effective')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('header')}</Text.BodyText3>

      {/* Section 1: PII */}
      <Text.Header2>{t('pii.title')}</Text.Header2>
      <Text.BodyText3>{t('pii.uses')}</Text.BodyText3>
      <br></br>
      <CcpaTable />
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('pii.third_party'),
          }}
        />
      </Text.BodyText3>

      {/* Section 2: Sale of Personal Information */}
      <Text.Header2>{t('sale.title')}</Text.Header2>
      <Text.BodyText3>{t('sale.does_not')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('sale.when_merchants')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('sale.transact'),
          }}
        />
      </Text.BodyText3>

      {/* Section 3: Consumer Rights */}
      <Text.Header2>{t('rights.title')}</Text.Header2>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('rights.may'),
          }}
        />
      </Text.BodyText3>

      <Text.Header4>{t('disclose_no_charge.title')}</Text.Header4>
      <ul>
        <li>
          <Text.BodyText4>{t('disclose_no_charge.specific')}</Text.BodyText4>
        </li>
        <li>
          <Text.BodyText4>{t('disclose_no_charge.pi')}</Text.BodyText4>
        </li>
        <li>
          <Text.BodyText4>{t('disclose_no_charge.sources')}</Text.BodyText4>
        </li>
        <li>
          <Text.BodyText4>{t('disclose_no_charge.business')}</Text.BodyText4>
        </li>
        <li>
          <Text.BodyText4>
            {t('disclose_no_charge.third_parties')}
          </Text.BodyText4>
        </li>
      </ul>
      <Text.BodyText3>{t('disclose_no_charge.rights_to_know')}</Text.BodyText3>

      <Text.Header4>{t('delete_no_charge.title')}</Text.Header4>
      <Text.BodyText3>{t('delete_no_charge.except')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('delete_no_charge.deletion'),
          }}
        />
      </Text.BodyText3>

      <Text.Header4>{t('verified.title')}</Text.Header4>
      <Text.BodyText3>{t('verified.verify')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('verified.under')}</Text.BodyText3>

      <Text.Header4>{t('limitations.title')}</Text.Header4>
      <Text.BodyText3>{t('limitations.please')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText4>
            <span
              dangerouslySetInnerHTML={{
                __html: t('limitations.obligated'),
              }}
            />
          </Text.BodyText4>
        </li>
        <li>
          <Text.BodyText4>{t('limitations.consumers')}</Text.BodyText4>
        </li>
        <li>
          <Text.BodyText4>{t('limitations.deletion')}</Text.BodyText4>
        </li>
      </ul>
      <Text.BodyText3>{t('limitations.confirm')}</Text.BodyText3>

      {/* Section 4. Non-Discrimination Policy */}
      <Text.Header2>{t('discrimination.title')}</Text.Header2>
      <Text.BodyText3>{t('discrimination.right')}</Text.BodyText3>

      {/* Section 5. Privacy Rights */}
      <Text.Header2>{t('civil_code.title')}</Text.Header2>
      <Text.BodyText3>{t('civil_code.permits')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('civil_code.addition')}</Text.BodyText3>

      {/* Section 6. Accessibility */}
      <Text.Header2>{t('accessibility.title')}</Text.Header2>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('accessibility.download'),
          }}
        />
      </Text.BodyText3>

      {/* Section 7. Contact Us */}
      <Text.Header2>{t('contact.title')}</Text.Header2>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('contact.detail'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('contact.questions'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3 bold>{t('contact.last_updated')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{`© ${t('copyright', {
        year: new Date().getFullYear(),
      })}`}</Text.BodyText3>
    </div>
  )
}

export default CcpaNotice
