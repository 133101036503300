import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type Props = {
  checked: boolean
  title?: string
  subtitle?: string
  value?: any
  onClick?: any
  className?: string
  disabled?: boolean
}

const RadioButton: React.FC<Props> = props => {
  const {
    checked,
    title = '',
    subtitle = '',
    value,
    className = 'inline-block',
    disabled = false,
  } = props

  const [css] = useStyletron()

  const extraClass = checked
    ? 'fa-dot-circle-o peter-river'
    : 'fa-circle-thin grey-30'
  const checkClass = `fa inline-block fa-lg ${extraClass}`
  const labelClass = `${className} ${
    disabled ? 'cursor-not-allowed' : 'cursor-pointer'
  }`

  function onClick() {
    if (disabled) return
    if (!props.onClick) return
    props.onClick(value)
  }

  return (
    <label className={labelClass} onClick={onClick}>
      <span
        className={`${checkClass} ${css({
          marginRight: '10px',
        })}`}
      />
      {title}
      <p
        className={`${css({
          marginLeft: '30px',
        })}`}
      >
        {subtitle}
      </p>
    </label>
  )
}

export default RadioButton
