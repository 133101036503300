import React from 'react'

type Props = {
  children?: React.ReactNode
  paddingTop?: string
  paddingBottom?: string
  paddingLeft?: string
  paddingRight?: string
  className?: string
  style?: React.CSSProperties
}

const Spacer: React.FC<Props> = props => {
  const {
    children,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    className = '',
    style = {},
  } = props
  const spacerStyle = {
    ...style,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  }

  return (
    <div style={spacerStyle} className={className}>
      {children}
    </div>
  )
}

export default Spacer
