import { Text } from '@thanx/uikit/text'
import React from 'react'
import Nav from 'react-bootstrap-five/Nav'

type Props = {
  state: string
  title: React.ReactNode
  disabled?: boolean
}

const TabItem: React.FC<Props> = props => {
  const { state, title, disabled } = props

  return (
    <Nav.Item key={state}>
      <Nav.Link
        className="flex-justify-center px-m"
        eventKey={state}
        disabled={disabled}
      >
        <Text.SmallCaps1 color="inherit" bold={true}>
          {title}
        </Text.SmallCaps1>
      </Nav.Link>
    </Nav.Item>
  )
}

export default TabItem
