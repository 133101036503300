import React from 'react'

interface PropsT {
  className?: string
}

const MagnifyGlassDollarIcon: React.FC<PropsT> = props => {
  const { className = '' } = props

  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 17 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15.75 14.7188L11.5625 10.5312C12.4688 9.4375 13 8.03125 13 6.5C13 2.9375 10.0625 0 6.5 0C2.90625 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.5 13C8 13 9.40625 12.5 10.5312 11.5938L14.7188 15.7812C14.8438 15.9375 15.0312 16 15.25 16C15.4375 16 15.625 15.9375 15.75 15.7812C16.0625 15.5 16.0625 15.0312 15.75 14.7188ZM6.5 11.5C3.71875 11.5 1.5 9.28125 1.5 6.5C1.5 3.75 3.71875 1.5 6.5 1.5C9.25 1.5 11.5 3.75 11.5 6.5C11.5 9.28125 9.25 11.5 6.5 11.5ZM6.65625 5.90625L6.46875 5.84375C5.625 5.59375 5.625 5.46875 5.65625 5.28125C5.71875 4.96875 6.25 4.875 6.75 4.96875C6.90625 5 7.125 5.03125 7.375 5.125C7.6875 5.25 8.0625 5.0625 8.15625 4.75C8.28125 4.40625 8.09375 4.0625 7.78125 3.9375C7.53125 3.875 7.3125 3.8125 7.125 3.78125V3.5C7.125 3.15625 6.84375 2.875 6.5 2.875C6.125 2.875 5.875 3.15625 5.875 3.5V3.78125C5.09375 3.90625 4.5625 4.375 4.4375 5.09375C4.1875 6.5 5.53125 6.875 6.125 7.0625L6.3125 7.125C7.3125 7.40625 7.34375 7.5 7.3125 7.75C7.25 8.03125 6.71875 8.125 6.21875 8.03125C5.96875 8 5.65625 7.90625 5.40625 7.8125L5.25 7.75C4.90625 7.625 4.5625 7.8125 4.4375 8.125C4.3125 8.4375 4.5 8.8125 4.8125 8.9375L4.96875 8.96875C5.25 9.0625 5.5625 9.1875 5.875 9.25V9.5C5.875 9.875 6.125 10.125 6.5 10.125C6.84375 10.125 7.125 9.84375 7.125 9.5V9.28125C7.875 9.125 8.40625 8.65625 8.53125 7.9375C8.78125 6.53125 7.4375 6.125 6.65625 5.90625Z"
        fill="#36424E"
      />
    </svg>
  )
}

export default MagnifyGlassDollarIcon
