import { Switch } from '@thanx/uikit/switch'
import Checkbox from 'components/Form/CheckboxWithFormsy'
import Spacer from 'components/Spacer'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import type { Fields as Redeem } from 'models/Redeem'
import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.reward_creator'
)

type Props = {
  redeem: Redeem | Campaign
  showAutomaticOption: boolean
  setManual: (value: boolean) => void
  automaticChecked: boolean
}

const AdditionalOptions: React.FC<Props> = props => {
  const { redeem, showAutomaticOption, setManual } = props
  const [couponChecked, setCouponChecked] = useState(
    (redeem as Campaign).redeem_uses_coupon_codes
  )
  const [automaticChecked, setAutomaticChecked] = useState(
    props.automaticChecked
  )

  function onCouponChange(value: boolean) {
    setCouponChecked(value)
    if (value === true) {
      setAutomaticChecked(false)
      setManual(true)
    }
  }

  function alert(type: string, show: boolean) {
    if (show)
      return (
        <Spacer paddingTop="18px">
          <Alert bsStyle="info">
            <Translate
              value={`thanx_campaigns.builder.steps.incentive.reward_creator.${type}.alert_text`}
              dangerousHTML
            />
          </Alert>
        </Spacer>
      )

    return null
  }

  function onAutomaticChange(value: boolean) {
    setAutomaticChecked(value)
    setManual(!value)
    if (value) {
      setCouponChecked(false)
    }
  }

  return (
    <div>
      <Spacer paddingBottom={showAutomaticOption ? '20px' : '40px'}>
        <Checkbox
          name="redeem_uses_coupon_codes"
          checkedValue={true}
          uncheckedValue={false}
          isChecked={couponChecked}
          onChange={onCouponChange}
          labelText={t('coupon.label')}
        />
        <p className="padding-left-small body-text-4 grey-60">
          {t('coupon.detail')}
        </p>
        {alert('coupon', couponChecked)}
      </Spacer>

      <Switch condition={showAutomaticOption}>
        <Spacer paddingBottom="40px">
          <Checkbox
            name="automatic"
            checkedValue={true}
            uncheckedValue={false}
            onChange={onAutomaticChange}
            isChecked={automaticChecked}
            labelText={t('automatic.label')}
          />
          <p className="padding-left-small body-text-4 grey-60">
            {t('automatic.detail')}
          </p>
          {alert('automatic', automaticChecked)}
        </Spacer>
      </Switch>
    </div>
  )
}

export default AdditionalOptions
