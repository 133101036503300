import { api } from './index'

type UpdateRewardParams = {
  userUid: string
  rewardId: number
}

export const rewardsApi = api.injectEndpoints({
  endpoints: builder => ({
    retire: builder.mutation<any, UpdateRewardParams>({
      query: ({ userUid, rewardId }) => ({
        url: `users/${userUid}/rewards/${rewardId}/retire`,
        method: 'POST',
      }),
    }),
    reissue: builder.mutation<any, UpdateRewardParams>({
      query: ({ userUid, rewardId }) => ({
        url: `users/${userUid}/rewards/${rewardId}/reissue`,
        method: 'POST',
      }),
    }),
  }),
})
