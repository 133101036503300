import { Notification } from '@thanx/uikit/notification'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import {
  CouponPoolFields as CouponPoolFileUpload,
  State as FileUploadState,
} from 'models/FileUpload'
import React, { useEffect } from 'react'

type Props = {
  fileUpload: CouponPoolFileUpload
  fromDetailsPage?: boolean
  onComplete?: (state: FileUploadState) => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes.upload'
)

const Processing: React.FC<Props> = props => {
  const { fileUpload, fromDetailsPage, onComplete } = props

  // Polling the file upload state is handled in PoolUploadForm/index
  useEffect(() => {
    if (
      [FileUploadState.FINALIZED, FileUploadState.ERRORED].includes(
        fileUpload.state
      )
    ) {
      onComplete?.(fileUpload.state)
    }
  }, [fileUpload.state, onComplete])

  return (
    <Notification kind="info" size="full">
      <div className="d-flex">
        <div>
          <Spinner style={{ height: 24, width: 24 }} isLoading />
        </div>
        <div className="body-text-4">
          <div className="mb-s">{t('processing_1')}</div>
          <div>
            {t(
              fromDetailsPage ? 'processing_2_template_detail' : 'processing_2'
            )}
          </div>
        </div>
      </div>
    </Notification>
  )
}

export default Processing
