import { RESET_DB } from 'actions/orm'
import {
  CREATE_SUCCESS,
  DELETE_SUCCESS,
  GET_ALL_SUCCESS,
  GET_SUCCESS,
  PREVIEW_FINE_PRINT_SUCCESS,
  ResolvedAction,
  UPDATE_SUCCESS,
} from 'actions/pointsProducts'
import { fk, Model, ModelType } from 'redux-orm'
import { ImageUrlsT } from 'utilities/types'

export interface Fields {
  cost: number
  exchange_end_at: string
  exchange_start_at: string
  fine_print: string
  id: number
  image: ImageUrlsT
  non_discount_reward: boolean
  points_experience_id: number
  redeem_description: string
  redeem_experience_expires_in_days?: number
  redeem_fine_print?: string
  redeem_long_description: string
  redeem_maximum?: number
  redeem_retire_after_days?: number
  redeem_sub_type: string
  redeem_template_id: number
  redeem_type: string
  redeem_window: string
  redemption_venue?: string
  state: string
}

// @ts-ignore
export default class PointsProduct extends Model<typeof PointsProduct, Fields> {
  static modelName: string = 'PointsProduct'

  static get fields(): any {
    return {
      points_experience_id: fk({
        to: 'PointsExperience',
        as: 'pointsExperience',
        relatedName: 'pointsProducts',
      }),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    PointsProduct: ModelType<PointsProduct>
  ): void {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_ALL_SUCCESS:
        action.payload.data.points_products.forEach(product => {
          PointsProduct.upsert(product)
        })
        break
      case GET_SUCCESS:
      case CREATE_SUCCESS:
      case UPDATE_SUCCESS:
      case PREVIEW_FINE_PRINT_SUCCESS:
        PointsProduct.upsert(action.payload.data.points_product)
        break
      case DELETE_SUCCESS:
        const pointsProduct = PointsProduct.withId(
          action.meta.previousAction.pointsProductId
        )
        if (pointsProduct) pointsProduct.delete()
        break
      case RESET_DB:
        PointsProduct.all().delete()
        break
      default:
        break
    }
  }
}
