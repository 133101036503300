import { DateRangeGranularity } from 'components/DateFilter/utilities'
import { buildTranslate } from 'locales'
import moment, { Moment } from 'moment-timezone'

const t = buildTranslate('dateFormat')

export const getFormattedDate = (
  dateTime: Moment,
  granularity: DateRangeGranularity
): string => {
  switch (granularity) {
    case 'anniversary':
      return dateTime.format('MMM D')
    case 'daily':
      return dateTime.format('MMM D, YYYY')
    case 'weekly':
      return t('weekOf', {
        date: dateTime.format('MMM D, YYYY'),
      })
    case 'monthly':
      return t('monthYear', {
        month: dateTime.format('MMM'),
        year: dateTime.format('YYYY'),
      })
    case 'quarterly':
      return t('quarterYear', {
        quarter: dateTime.format('Q'),
        year: dateTime.format('YYYY'),
      })
    case 'yearly':
      return dateTime.format('YYYY')
  }
}

export const getDateLabel = (
  dateTime: string,
  granularity: DateRangeGranularity,
  timeZone: string
): string => {
  const dateMoment = getMomentFromSeriesCategory(
    dateTime,
    granularity,
    timeZone
  )
  switch (granularity) {
    case 'quarterly':
      return getFormattedDate(dateMoment, 'quarterly')
    case 'monthly': {
      const [year] = dateTime.split('-')
      return t('monthYear', { month: dateMoment.format('MMM'), year: year })
    }
    case 'weekly':
    case 'anniversary':
    case 'daily': {
      const [, , day] = dateTime.split('-')
      return !!day
        ? t('monthDay', {
            month: dateMoment.format('MMM'),
            day: Number(day),
          })
        : ''
    }
    case 'yearly':
      return dateTime
  }
}

export const getMomentFromSeriesCategory = (
  date: string,
  granularity: DateRangeGranularity,
  timeZone: string
): Moment => {
  switch (granularity) {
    case 'anniversary':
    case 'daily':
    case 'weekly':
      return moment.tz(date, timeZone)
    case 'monthly':
    case 'quarterly':
      return moment.tz(`${date}-01`, timeZone)
    case 'yearly':
      return moment.tz(`${date}-01-01`, timeZone)
  }
}

export const getDateWithTimeAndZone = (
  date: string,
  timeZone: string
): string => {
  const momentDate = moment.tz(date, timeZone)
  const datePart = momentDate.format('MMM D, YYYY')
  const timePart = momentDate.format('hh:mma z')
  return `${datePart} at ${timePart}`
}
