import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import DateRange from './DateRange'
import RewardLength from './RewardLength'

type Props = {
  onClose: () => void
  campaign: Campaign
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator'
)

const DiscountRewardRestrictions: React.FC<Props> = props => {
  const { onClose, campaign } = props

  const showNewRewardLength = [
    'message',
    'custom',
    'custom_automated',
  ].includes(campaign.type)

  return (
    <>
      <Switch
        condition={showNewRewardLength}
        fallback={<DateRange campaign={campaign} />}
      >
        <RewardLength
          campaign={campaign}
          className="mb-xl"
          t={buildTranslate(
            'thanx_campaigns.builder.steps.incentive.restriction_creator.general'
          )}
        />
      </Switch>
      <div className="text-right margin-top-extra-large">
        <Button onClick={onClose}>{t('okay')}</Button>
      </div>
    </>
  )
}
export default DiscountRewardRestrictions
