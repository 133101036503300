import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import { updateCampaignVariant } from 'actions/campaignVariants'
import Input from 'components/Form/Input'
import { buildTranslate } from 'locales'
import { configTypes } from 'models/Campaign'
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCampaignVariant } from 'selectors/campaignVariant'
import { StyleObject } from 'styletron-standard'

const t = buildTranslate('thanx_campaigns.builder.name')

const getOptions = size =>
  size === 'large'
    ? {
        borderSize: 2,
        fontSize: 32,
        lineHeight: 36,
        size: 18,
        textAreaHeight: 42,
      }
    : {
        borderSize: 1,
        fontSize: 18,
        lineHeight: 24,
        size: 5,
        textAreaHeight: 26,
      }

type Props = {
  className?: string
  campaignId?: number
  name: string | undefined
  onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void
  styleSize: 'small' | 'large'
  showPlaceholder?: boolean
  showEdit?: boolean
  showAsInput?: boolean
  type: configTypes | 'redeem' | undefined
  variantId?: number
}

const EditableName: React.FC<Props> = ({
  campaignId,
  className = '',
  onFocus = () => null,
  variantId,
  name,
  styleSize,
  showPlaceholder = false,
  showEdit = false,
  showAsInput = false,
  type,
}) => {
  const variant = useSelector(state => selectCampaignVariant(state, variantId))
  const options = getOptions(styleSize)
  const { borderSize, fontSize, lineHeight, size, textAreaHeight } = options
  const dispatch = useDispatch()
  let inputRef = useRef<HTMLInputElement>(null)
  const [rows, setRows] = useState(1)
  const [tempValue, setTempValue] = useState(name)
  const [value, setValue] = useState(name)
  const [css] = useStyletron()

  const onChange = useCallback(
    e => {
      const target = e.target || e
      // Prevent autosave on variants
      if (target.value) {
        target.value = target.value.replace(/\n/, '')
      }
      if (!showAsInput) {
        const maxRows = 3
        const previousRows = target.rows
        target.rows = 1 // reset number of rows in textarea

        const currentRows = ~~(target.scrollHeight / textAreaHeight)

        if (currentRows === previousRows) {
          target.rows = currentRows
        }

        if (currentRows >= maxRows) {
          target.rows = maxRows
          target.scrollTop = target.scrollHeight
        }
        let rows: number
        if (!currentRows) {
          rows = 1
        } else if (currentRows < maxRows) {
          rows = currentRows
        } else {
          rows = maxRows
        }
        setRows(rows)
      }
      setValue(target.value)
    },
    [textAreaHeight, showAsInput]
  )

  const onClick = () => {
    // This logic is to move the cursor to the end of the name
    //@ts-ignore
    const clickInput = inputRef?.inputRef
    if (clickInput) {
      const caretPos = name?.length || 0
      if (clickInput.createTextRange) {
        var range = clickInput.createTextRange()
        range.move('character', caretPos)
        range.select()
      } else {
        if (typeof clickInput.selectionStart === 'number') {
          clickInput.focus()
          clickInput.setSelectionRange(caretPos, caretPos)
        } else {
          clickInput.focus()
        }
      }
    }
  }

  useEffect(() => {
    //@ts-ignore
    if (inputRef.inputRef) {
      //@ts-ignore
      onChange(inputRef.inputRef)
    }
  }, [inputRef, onChange])

  useEffect(() => {
    setValue(name)
  }, [name])

  const onBlur = e => {
    let value = e.target.value
    if (value.length < 1) {
      setValue(tempValue)
    } else {
      setTempValue(value)
      if (type === 'variant' && value !== name && variantId && campaignId)
        dispatch(
          updateCampaignVariant(campaignId, variantId, {
            ...variant,
            name: value,
          })
        )
    }
  }

  const getHoverFocus = color => ({
    border: `${borderSize}px solid ${color} !important`,
    padding: `${size - borderSize}px !important`,
    backgroundColor: 'white',
  })

  const placeholder = showPlaceholder ? t('placeholder') : undefined
  const editClass =
    styleSize === 'large'
      ? `${css({
          marginLeft: `${size}px`,
          width: 'fit-content',
        })}`
      : `ml-m ${css({
          width: 'fit-content',
        })}`

  const flexClass = styleSize === 'large' ? '' : 'd-flex'
  const inputClass: StyleObject = {
    lineHeight: `${lineHeight}px !important`,
    borderColor: `${defaultTheme.colors.grey30} !important`,
    padding: `${size}px !important`,
    backgroundColor: 'inherit',
    border: 'none',
    wordWrap: 'break-word',
    maxWidth: `calc(100% + ${size * 2}px)`,
    fontSize: `${fontSize}px !important`,
    fontWeight: 'bold',
    ':hover': getHoverFocus(defaultTheme.colors.grey30),
    ':focus': getHoverFocus(defaultTheme.colors.primary),
  }
  if (showAsInput) {
    inputClass.whiteSpace = 'nowrap'
    inputClass.overflow = 'hidden'
    inputClass.textOverflow = 'ellipsis'
  }

  return (
    <div
      className={`${flexClass} ${css({
        marginLeft: `-${size}px`,
        marginRight: `-${size}px`,
        position: 'relative',
      })} ${className}`}
    >
      <Input
        className="flex-1"
        name={type === 'variant' ? `variant_name_${variantId}` : 'name'}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        isHeaderInput
        width="100%"
        innerRef={ref => ((inputRef as React.Ref<HTMLInputElement>) = ref)}
        inputProps={{
          componentClass: showAsInput ? 'input' : 'textarea',
          placeholder,
          rows,
          className: css(inputClass),
          value,
        }}
      />
      <Switch condition={showEdit}>
        <div
          className={`cursor-pointer align-self-center ${editClass}`}
          onClick={onClick}
        >
          <Text.BodyText4 bold color="primary">
            {t(styleSize === 'large' ? 'edit' : 'rename')}
          </Text.BodyText4>
        </div>
      </Switch>
    </div>
  )
}

export default EditableName
