import { buildTranslate } from 'locales'
import React from 'react'

type PropsT = {
  url: string
}

const t = buildTranslate('general')

const PopoverLearnMore: React.FC<PropsT> = props => {
  const { url } = props
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {t('learn_more')}
    </a>
  )
}

export default PopoverLearnMore
