import { GET_BRANDS_SUCCESS, ResolvedAction } from 'actions/brands'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'
import { ImageUrlsT } from 'utilities/types'

export interface Fields {
  id: number
  merchant_id: number
  name: string
  cover_image_urls: ImageUrlsT
  logo_image_urls: ImageUrlsT
}

// @ts-ignore
export default class Brand extends Model<typeof Brand, Fields> {
  static modelName: string = 'Brand'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Brand: ModelType<Brand>
  ) {
    switch (action.type) {
      case GET_BRANDS_SUCCESS:
        action.payload.data.merchant_brands.forEach(brand => {
          Brand.upsert(brand)
        })
        break
      case RESET_DB:
        Brand.all().delete()
        break
      default:
        break
    }
  }
}
