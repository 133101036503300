import { Fields, ShortType } from 'models/ActivationRateMetric'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

export const GET_ACTIVATION_RATE_METRICS = 'activation_rate_metrics/GET_METRICS'
export const GET_ACTIVATION_RATE_METRICS_SUCCESS =
  'activation_rate_metrics/GET_METRICS_SUCCESS'
export const GET_ACTIVATION_RATE_METRICS_FAIL =
  'activation_rate_metrics/GET_METRICS_FAIL'

export type GetActivationRateMetricsAction = AxiosAction<
  typeof GET_ACTIVATION_RATE_METRICS
>

export function getActivationRateMetrics(
  type: ShortType,
  params: {} = {}
): GetActivationRateMetricsAction {
  return {
    type: GET_ACTIVATION_RATE_METRICS,
    payload: {
      client: 'default',
      request: {
        url: `activation_rate/${type}`,
        method: 'get',
        params,
      },
    },
  }
}

export type ResolvedAction =
  | FailureAction<typeof GET_ACTIVATION_RATE_METRICS_FAIL>
  | SuccessAction<
      typeof GET_ACTIVATION_RATE_METRICS_SUCCESS,
      { metric: Fields },
      GetActivationRateMetricsAction
    >
