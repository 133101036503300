import { getCards } from 'actions/cards'
import { updatePaginationCurrentPage } from 'actions/pagination'
import useDispatch from 'hooks/useDispatch'
import { Fields as UserProfile } from 'models/UserProfile'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCards } from 'selectors/card'
import { selectPagination } from 'selectors/pagination'

export const useCards = (profile: UserProfile) => {
  const dispatch = useDispatch()
  const [isFetchingCards, setIsFetchingCards] = useState(false)
  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'user_cards', profile.uid)
  )
  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const cardIds = paginationInfo?.record_ids?.[currentPage] ?? []
  const cards = useSelector(selectCards).filter(card =>
    cardIds.includes(card.id)
  )

  const fetchCards = useCallback(
    async (page: number) => {
      setIsFetchingCards(true)
      await dispatch(getCards(profile.uid, { page }))
      setIsFetchingCards(false)
    },
    [dispatch, profile.uid]
  )

  const onChangePage = useCallback(
    async (page: number) => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // cards for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'user_cards',
            id: profile.uid,
            page,
          })
        )
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      fetchCards(page)
    },
    [dispatch, paginationInfo, fetchCards, profile.uid]
  )

  useEffect(() => {
    onChangePage(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangePage])

  return {
    isFetchingCards,
    currentPage,
    numPages,
    cards,
    onChangePage,
  }
}
