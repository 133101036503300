import Spinner from 'components/Spinner'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type Props = {
  value: number
  description: string
  isLoading?: boolean
  className?: string
}

const MetricSection: React.FC<Props> = props => {
  const { value, description, isLoading = false, className = '' } = props

  if (!value) {
    return <hr className="width-75" />
  }

  return (
    <div
      className={`
        display-flex flex-column flex-align-center
        text-center padding-top-huge padding-bottom-huge
        ${className}
      `}
    >
      <div className="margin-top-huge margin-bottom-huge font-size-130 blue-chrome-60">
        <Spinner isLoading={isLoading}>
          <p className="font-size-130 bold spearmint margin-bottom-none">
            {I18n.l(value)}
          </p>
          <p className="font-size-30 bold" style={{ maxWidth: '600px' }}>
            {description}
          </p>
        </Spinner>
      </div>
    </div>
  )
}

export default MetricSection
