import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}

const CalendarClock: React.FC<PropsT> = ({
  className = '',
  fillColor = 'currentColor',
}) => (
  <svg
    className={className}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.96875 0C4.40625 0 4.71875 0.34375 4.71875 0.75V2H9.25V0.75C9.25 0.34375 9.5625 0 10 0C10.4062 0 10.75 0.34375 10.75 0.75V2H12C13.0938 2 14 2.90625 14 4V6H1.46875V14C1.46875 14.2812 1.71875 14.5 1.96875 14.5H8.875C9.25 15.0938 9.75 15.625 10.3125 16H2C0.875 16 0 15.125 0 14V4C0 2.90625 0.875 2 2 2H3.25V0.75C3.25 0.34375 3.5625 0 4 0H3.96875ZM18 11.5C18 14 15.9688 16 13.5 16C11 16 8.96875 14 8.96875 11.5C8.96875 9.03125 11 7 13.5 7C15.9688 7 18 9.03125 18 11.5ZM12.9688 9.5V11.5C12.9688 11.7812 13.2188 12 13.4688 12H15C15.25 12 15.5 11.7812 15.5 11.5C15.5 11.25 15.25 11 15 11H13.9688V9.5C13.9688 9.25 13.75 9 13.4688 9C13.2188 9 12.9688 9.25 12.9688 9.5Z"
      fill={fillColor}
    />
  </svg>
)

export default CalendarClock
