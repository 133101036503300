import { Fields } from 'models/OrderingConfig'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectOrderingConfigs = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.OrderingConfig.all().toRefArray()
  }
)

export const selectOrderingConfig = createSelector(
  (state: any) => selectOrderingConfigs(state),
  (_: any, id?: string | number): string | number | undefined => id,
  (orderingConfigs: any, id?: string | number): Fields => {
    return orderingConfigs.find(config => config.id === Number(id)) ?? null
  }
)
