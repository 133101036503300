// @flow
import React, { Component } from 'react'
import identity from 'lodash/identity'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import { I18n } from 'react-redux-i18n'
// $FlowFixMe
import { textUtils } from 'utilities/textUtils'
// $FlowFixMe
import imageDimensions from 'utilities/imageDimensions'

type Props = {
  isValid: boolean,
  imageWidth: number,
  imageHeight: number,
  width?: number,
  height?: number,
  maxWidth?: number,
  maxHeight?: number,
  minWidth?: number,
  minHeight?: number,
}

export default class ImageValidationMessage extends Component<Props> {
  currentImageSize(): string {
    return I18n.t('validations.images.current_image_size', {
      uploadWidth: this.props.imageWidth,
      uploadHeight: this.props.imageHeight,
    })
  }

  message(): string {
    if (this.props.isValid) return ''
    let msg = []
    let dimensions = omit(this.props, ['isValid', 'imageWidth', 'imageHeight'])
    dimensions = pickBy(dimensions, identity) // remove empty keys
    for (let key of Object.keys(dimensions)) {
      msg.push(imageDimensions(key, dimensions[key]))
    }
    return `This image must be ${textUtils.toSentence({
      array: msg,
      punctuation: '',
    })} ${this.currentImageSize()}`
  }

  render() {
    return <span className="text-danger block">{this.message()}</span>
  }
}
