import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.signup.onboarding_slideshow.content.empty_state')

const EmptyState: React.FC = () => {
  return (
    <div className={'mt-xl mb-m'}>
      <Text.Header4 className={'mb-xs'} tag={'p'} color="grey90">
        {t('title')}
      </Text.Header4>
      <Text.BodyText4 tag={'p'} color="grey70">
        {t('description')}
      </Text.BodyText4>
    </div>
  )
}

export default EmptyState
