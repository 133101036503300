import { Switch } from '@thanx/uikit/switch'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import configs from 'scenes/CampaignCenter/Report/configs'
import ConversionMetricContainer from './components/ConversionMetricContainer'
import PerformanceMetricsContainer from './components/PerformanceMetricsContainer'
import RevenueMetricContainer from './components/RevenueMetricContainer'

type Props = {
  campaign: Campaign
  hasDivider: boolean
}

const Summary: React.FC<Props> = ({ campaign, hasDivider }) => {
  const config = configs[campaign.type]
  const objective = I18n.t(
    `thanx_campaigns.report.campaign_types.${campaign.type}.objective`
  )

  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <div className="summary-section">
          <p className="body-text-1 margin-bottom-extra-large">
            <Translate
              value="thanx_campaigns.report.summary.title"
              objective={objective}
            />
          </p>
          <div
            className="
              display-flex flex-wrap negative-margin-left-small
              negative-margin-right-small margin-bottom-large
            "
          >
            <Switch condition={config.hasRevenue}>
              <div className="header-box flex-1 margin-small">
                <RevenueMetricContainer campaign={campaign} />
              </div>
            </Switch>
            <div className="header-box flex-2 margin-small">
              <ConversionMetricContainer campaign={campaign} />
            </div>
          </div>
          <div>
            <PerformanceMetricsContainer campaign={campaign} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Summary
