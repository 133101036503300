import { useState } from 'react'

export default function useSteps(
  steps: string[],
  initialStep: string | null = null
) {
  let initialStepIndex = 0
  if (initialStep) {
    const index = steps.indexOf(initialStep)
    if (index !== -1) initialStepIndex = index
  }
  const [stepIndex, setStepIndex] = useState(initialStepIndex)

  function prev() {
    setStepIndex(i => (i <= 0 ? i : i - 1))
  }

  function next() {
    setStepIndex(i => (i >= steps.length - 1 ? i : i + 1))
  }

  function setStep(stepName: string) {
    const index = steps.indexOf(stepName)
    if (index === -1) return
    setStepIndex(index)
  }

  function setStepByIndex(index: number) {
    if (!steps[index]) return
    setStepIndex(index)
  }

  return {
    step: steps[stepIndex],
    stepIndex,
    prev,
    next,
    setStep,
    setStepByIndex,
  }
}
