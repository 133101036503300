import React from 'react'

type Props = {
  color: string
}

const ArrowDown: React.FC<Props> = props => {
  const { color } = props

  return (
    <svg
      width="12"
      height="15"
      viewBox="0 0 12 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.207045 8.29971L5.4535 13.812C5.60964 13.9686 5.79701 14.0312 6.01561 14.0312C6.20299 14.0312 6.39036 13.9686 6.5465 13.812L11.793 8.29971C12.074 7.98651 12.074 7.51671 11.7617 7.23483C11.4807 6.95295 10.981 6.95295 10.6999 7.26615L6.76511 11.4317V0.782928C6.76511 0.34445 6.42159 0.031251 6.01561 0.031251C5.64087 0.031251 5.26612 0.34445 5.26612 0.782928V11.4317L1.30006 7.26615C1.019 6.95295 0.519334 6.95295 0.238274 7.23483C-0.0740144 7.51671 -0.0740144 8.01783 0.207045 8.29971Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowDown
