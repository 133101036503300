import PopoverMenu, { PopoverOption } from 'components/PopoverMenu'
import React from 'react'

type PropsT = {
  title: string
  onRemove: () => void
}

const Remove: React.FC<PropsT> = ({ title, onRemove }) => {
  function handleRemove(close: () => void) {
    onRemove()
    close()
  }

  return (
    <PopoverMenu
      dismissOnClickOutside
      placement="bottomRight"
      testId="channelOptions"
    >
      {({ close }) => (
        <PopoverOption onClick={() => handleRemove(close)}>
          {title}
        </PopoverOption>
      )}
    </PopoverMenu>
  )
}

export default Remove
