import { Switch } from '@thanx/uikit/switch'
import { getCouponPools } from 'actions/couponPools'
import { locationsApi } from 'api/locations'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useFlag from 'hooks/useFlag'
import { CampaignConfig } from 'models/Campaign'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getInstoreRedemptionType,
  getUniqueRedemptionProviders,
  mapProviderPoolsToCouponCodeDisplay,
} from 'scenes/RedeemManager/helpers'
import ProviderInstoreRedemptionDisplay from 'scenes/RedeemManager/shared/ProviderInstoreRedemptionDisplay'
import { selectCouponPoolsByTemplateId } from 'selectors/couponPool'
import Instore from './Instore'

type Props = {
  config: CampaignConfig
  template?: RedeemTemplate
}

function MultipleInstore({ config, template }: Props) {
  const dispatch = useDispatch()

  const { redeem_type } = config

  const locationSpecificPosEnabled = useFlag('provider-redemption', false)
  const merchant = useCurrentMerchant()
  const multiplePosSystems =
    locationSpecificPosEnabled && merchant?.has_multi_pos

  const { data: locationsData, isSuccess: loadLocationsSuccess } =
    locationsApi.useGetLocationsQuery({ full_visibility: true })
  const merchantLocations = locationsData?.locations || []
  const redemptionProviders = getUniqueRedemptionProviders(
    merchantLocations,
    merchant?.redemption_provider
  )

  const couponPools = useSelector(state => {
    if (template?.id) {
      return selectCouponPoolsByTemplateId(state, template.id)
    }
  })

  useEffect(() => {
    async function fetchData() {
      if (template?.id) {
        await dispatch(getCouponPools(template.id))
      }
    }

    if (template?.uses_coupon_codes && !couponPools?.length) {
      fetchData()
    }
  }, [template, couponPools, dispatch])

  // Fallback to showing only a single card if it's an experience reward
  const isGoldenTicket = redeem_type === 'experience/golden_ticket'
  const isHiddenMenu = redeem_type === 'experience/hidden_menu'
  const isBonusPoints = redeem_type === 'points/static'
  const isExperience = isGoldenTicket || isHiddenMenu || isBonusPoints

  return (
    <Switch
      condition={
        !isExperience &&
        multiplePosSystems &&
        loadLocationsSuccess &&
        !!template
      }
      fallback={<Instore config={config} template={template} />}
    >
      {redemptionProviders.map(provider => (
        <ProviderInstoreRedemptionDisplay
          key={provider.value}
          redemptionProvider={provider}
          redemptionType={getInstoreRedemptionType(
            //@ts-ignore
            template,
            couponPools?.filter(
              pool => pool.redemption_provider?.value === provider.value
            )
          )}
          couponCodeDisplay={mapProviderPoolsToCouponCodeDisplay(
            couponPools,
            provider
          )}
        />
      ))}
    </Switch>
  )
}

export default MultipleInstore
