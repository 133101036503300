import { Button } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import onboardingSlideshowPreview from 'assets/images/cms/onboarding-slideshow-preview.png'
import ConfirmModal from 'components/ConfirmModal'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.signup.onboarding_slideshow.sidebar')

type PropsT = {
  isPublishDisabled: boolean
  isSubmitting: boolean
  isConfirmModalVisible: boolean
  isDraft: boolean
  onSubmit: VoidFunction
  onCancel: VoidFunction
}

const Sidebar: React.FC<PropsT> = ({
  isPublishDisabled,
  isSubmitting,
  isConfirmModalVisible,
  onSubmit,
  onCancel,
  isDraft,
}: PropsT) => {
  const [css] = useStyletron()

  return (
    <>
      <Button
        className="mb-xxxl px-s"
        type="submit"
        isLoading={isSubmitting}
        disabled={isPublishDisabled}
      >
        {isDraft ? t('save_draft') : t('publish')}
      </Button>
      <img
        className={`mb-xl ${css({
          width: '200px',
          height: '397px',
        })}`}
        src={onboardingSlideshowPreview}
        alt={t('onboarding_slideshow_preview')}
      />
      <ConfirmModal
        title={t('modal.publish_changes')}
        description={t('modal.you_are_publishing')}
        confirmText={t('modal.publish')}
        isOpen={isConfirmModalVisible}
        isLoading={isSubmitting}
        onClose={onCancel}
        onConfirm={onSubmit}
      />
    </>
  )
}

export default Sidebar
