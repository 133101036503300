import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import ColorPicker from 'components/Form/ColorPicker'
import Section from 'components/Section'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { FormModel } from '../hooks'

const t = buildTranslate('cms.content.menu_category')

type Props = {
  type: 'product_card' | 'unavailable_product_card'
  isLoading: boolean
  data: FormModel['product_card'] | FormModel['unavailable_product_card']
}

const styleKeys = [
  'background_color',
  'header_text_color',
  'body_text_color',
  'border_color',
]

const borderStyles = ['border_width', 'border_radius']

const ProductCardForm = ({ isLoading, data, type }: Props) => {
  return (
    <Section className="p-m my-m">
      <Spinner
        isLoading={isLoading}
        size="3x"
        className={'d-flex justify-content-center'}
      >
        <Text.Header4>{t(`${type}.title`)}</Text.Header4>
        {styleKeys.map(key => (
          <ColorPicker
            className={'mt-m'}
            key={key}
            name={`${type}.${key}`}
            label={t(`${type}.${key}`)}
            validations={{
              hex: true,
              isRequired: true,
            }}
            value={data?.[key]}
            validationErrors={{
              hex: I18n.t('validations.hex.message'),
              isRequired: I18n.t('validations.is_required'),
            }}
            startEnhancer
          />
        ))}
        {type === 'product_card' &&
          borderStyles.map(style => (
            <FormsyInput
              key={style}
              className="my-m"
              name={`${type}.${style}`}
              label={t(`${type}.${style}`)}
              type="number"
              value={data[style] === 0 ? '0' : data[style]}
              endEnhancer="px"
              validations={{
                isNumeric: true,
                isRequired: true,
                greaterThanOrEqual: 0,
              }}
              validationErrors={{
                isNumeric: I18n.t('validations.is_numeric.message'),
                isRequired: I18n.t('validations.is_required'),
                greaterThanOrEqual: I18n.t(
                  'validations.greater_than_or_equal',
                  {
                    value: 0,
                  }
                ),
              }}
            />
          ))}
      </Spinner>
    </Section>
  )
}

export default ProductCardForm
