import type { Fields as Campaign } from 'models/Campaign'
import type { Metrics } from 'models/CampaignMetric'
import numeral from 'numeral'
import pluralize from 'pluralize'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'

type Props = {
  metrics?: Metrics
  campaign: Campaign
}

export const Audience: React.FC<Props> = ({ metrics, campaign }) => {
  const campaingScheduledOrInReview = ['in_review', 'scheduled'].includes(
    campaign.state
  )

  let userCount = metrics?.message_count?.sent_count || 0
  let key = 'thanx_campaigns.review.customers.text.past'

  if (campaingScheduledOrInReview) {
    userCount = metrics?.user_count?.count || 0
    key = 'thanx_campaigns.review.customers.text.future'
  }

  return (
    <Row className="section">
      <Col className="col-header" sm={2}>
        <Translate value="thanx_campaigns.review.customers.title" />
      </Col>
      <Col sm={8}>
        <div className="margin-bottom-medium font-size-16 grey-70">
          <p>
            <Translate value={key} />
            &nbsp;
            <strong>
              {numeral(userCount).format('0,0')}
              &nbsp;
              {pluralize(
                I18n.t('thanx_campaigns.review.customers.unit'),
                userCount
              )}
            </strong>
          </p>
        </div>
      </Col>
    </Row>
  )
}

export default withMetrics(['message_count', 'user_count'])(Audience)
