import { Fields } from 'models/LandingPage'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const UPDATE_LANDING_HEADERS = 'landingPage/UPDATE_LANDING_HEADERS'
export const UPDATE_LANDING_HEADERS_SUCCESS =
  'landingPage/UPDATE_LANDING_HEADERS_SUCCESS'
export const UPDATE_LANDING_HEADERS_FAIL =
  'landingPage/UPDATE_LANDING_HEADERS_FAIL'

export type HeaderData = {
  id?: number
  position: number
  destroy?: boolean
  file_uploads?: {
    image: number
  }
}

export function updateLandingHeaders(
  appId: number,
  headers: HeaderData[]
): AxiosAction<typeof UPDATE_LANDING_HEADERS, { headers: Fields[] }> {
  return {
    type: UPDATE_LANDING_HEADERS,
    payload: {
      client: 'default',
      request: {
        url: `apps/${appId}/landing_page/headers`,
        method: 'put',
        data: {
          headers,
        },
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof updateLandingHeaders>
>
