export default {
  page_title: 'Retention rate | Thanx',
  title: 'Retention',
  subtitle:
    'Retain customers that you’ve already activated to prevent them from churning.',
  notifications: {
    zero_engaged_customers:
      'No engaged customers made a purchase (i.e. this wasn’t their 1st or 2nd purchase) during the most recent available month. Because there were no customers to retain, the retention rate is 0%.',
  },
  retention_rate: 'Retention rate',
  description: 'Customers that returned within 180 days',
  counters: {
    retention_rate: 'Retention rate %{counterDateLabel}',
    retained_customers: 'Retained customers (%{retentionWindow} days)',
    engaged_customers: 'Engaged customers %{counterDateLabel}',
    industry_benchmark: 'Industry benchmark is %{rate}',
  },
  table: {
    date_time: 'Month',
    engaged_users_count: 'Engaged customers',
    retention_rate: 'Returned within 180 days',
    churned_users_count: 'Have not returned within 180 days',
    engaged_customers: [
      'Activated customers (made 3+ purchases over all time) that have made at least 1 purchase in %{dateLabel}',
      '%{engagedUsersCount} activated customers made a purchase in %{dateLabel}.',
    ],
    returned_customers: [
      'Engaged customers that have made another purchase within %{retentionWindow} days.',
      '%{engagedUsersCount} activated customers made a purchase in %{dateLabel}. %{returningUsersCount} of those customers returned within %{retentionWindow} days to make another purchase.',
    ],
    churned_customers: [
      'Engaged customers that have not made another purchase within %{retentionWindow} days.',
      '%{engagedUsersCount} activated customers made a purchase in %{dateLabel}. %{churnedUsersCount} of those customers did not return within %{retentionWindow} days to make another purchase.',
    ],
    churn_rate: [
      'The percentage of activated customers (Activated means this wasn’t their 1st or 2nd purchase) that made a purchase in %{dateLabel} and have not returned to make another purchase within %{retentionWindow} days.',
      '%{engagedUsersCount} activated customers made a purchase in %{dateLabel}. %{churnedUsersCount} of those customers have not returned within %{retentionWindow} days to make another purchase. The %{retentionWindow} day churn rate for %{dateLabel} is %{churnRate}.',
    ],
  },
  popovers: {
    what_this_means: 'What this means',
    example: 'Example',
    sample_month: 'July',
    sample_engaged_users: '1,000',
    sample_returning_users: '800',
    sample_retention_rate: '80%',
    in_given_month: 'a given month',
    retention_rate: [
      'The percentage of activated customers (activated means this wasn’t their 1st or 2nd purchase) that made a purchase in %{dateLabel} and then returned to make another purchase within %{retentionWindow} days.',
      '%{engagedUsersCount} activated customers made a purchase in %{dateLabel}. %{returningUsersCount} of those customers returned within %{retentionWindow} days to make another purchase. The %{retentionWindow} day retention rate for %{dateLabel} is %{retentionRate}.',
    ],
    retained_customers: [
      'Engaged customers that have made another purchase within %{retentionWindow} days.',
      '%{engagedUsersCount} activated customers made a purchase in %{dateLabel}. %{returningUsersCount} of those customers returned within %{retentionWindow} days to make another purchase.',
    ],
    engaged_customers: [
      'Activated customers (activated means this wasn’t their 1st or 2nd purchase) that have made at least 1 purchase in %{dateLabel}.',
      '%{engagedUsersCount} activated customers made a purchase in %{dateLabel}.',
    ],
  },
  chart: {
    title: 'Retention rate over time',
    x_axis: 'Date of last purchase',
    y_axis: 'Retention rate',
    industry_benchmark: 'Industry benchmark',
    tooltip: {
      retention_rate: 'Retention rate',
      engaged_users: 'Engaged customers',
      returning_users: 'Returned within %{retentionWindow} days',
      churned_users: 'Have not returned within %{retentionWindow} days',
    },
  },
  cohort_chart: {
    title: 'How engaged customers return',
    sub_title:
      'The percentage of engaged customers that return after making a purchase and the amount of time they take to return.',
    coming_soon: 'Coming soon',
    engaged_customers: 'Engaged customers',
    days: '%{days} days',
  },
}
