import { Switch } from '@thanx/uikit/switch'
import Input from 'components/Form/Input'
import Select from 'components/Form/Select'
import Spinner from 'components/Spinner'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import { buildTranslate } from 'locales'
import type { Fields as Location } from 'models/Location'
import numeral from 'numeral'
import React, { useRef } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import StatBox from 'scenes/CampaignCenter/Builder/components/StatBox'
import {
  getOptionFromLocation,
  locationsToSelectOptions,
} from 'utilities/locationUtils'

const t = buildTranslate(
  'thanx_campaigns.campaigns.location_message.steps.setup.audience'
)

type Props = {
  locations: Location[]
  selectedLocation?: Location | null
  userCount: number
  isLoading: boolean
}

const View: React.FC<Props> = props => {
  const { locations, selectedLocation, userCount, isLoading } = props
  const hiddenInputRef = useRef<PassDownProps<string | null>>(null)

  const handleSelectChange = (option: { value: string }) => {
    if (hiddenInputRef.current) {
      // @ts-ignore
      hiddenInputRef.current.props.setValue(option.value)
    }
  }

  return (
    <div className="container">
      <Row>
        <Col md={6} className="padding-top-huge padding-right-huge">
          <div className="padding-right-huge">
            <Translate
              value="thanx_campaigns.campaigns.location_message.steps.setup.audience.title"
              tag="p"
              className="grey-90 font-size-32 margin-bottom-micro bold"
            />
            <Translate
              value="thanx_campaigns.campaigns.location_message.steps.setup.audience.subtitle"
              tag="em"
              className="grey-50 font-size-20 margin-bottom-extra-large block"
            />
            <div className="margin-bottom-extra-large">
              <Switch condition={!isNaN(userCount) && !!selectedLocation}>
                <StatBox
                  title={t('stat.supertitle')}
                  message={
                    <Translate
                      value="thanx_campaigns.campaigns.location_message.steps.setup.audience.stat.message"
                      location={selectedLocation?.street}
                      count={numeral(userCount).format('0,0')}
                      dangerousHTML
                    />
                  }
                  className="category-targeted-bkg white"
                />
              </Switch>
            </div>
            <Spinner isLoading={isLoading}>
              <Select
                isDisabled={false}
                placeholder={t('selector.placeholder')}
                options={locationsToSelectOptions(locations)}
                value={
                  selectedLocation
                    ? getOptionFromLocation(selectedLocation)
                    : null
                }
                onChange={handleSelectChange}
              />
              <Input
                name="config_location_id"
                type="hidden"
                value={selectedLocation?.id}
                //@ts-ignore
                innerRef={hiddenInputRef}
                validations={{ isRequired: true }}
              />
            </Spinner>
          </div>
        </Col>
        <Col md={6} className="grey-10-bkg" style={{ height: '900px' }}>
          <div className="padding-left-large padding-top-huge" />
        </Col>
      </Row>
    </div>
  )
}

export default View
