import { Button, Kind } from '@thanx/uikit/button'
import Book from 'components/Icons/Book'
import React from 'react'

type PropsT = {
  children: React.ReactNode
  url: string
  className?: string
}

const HelpLink: React.FC<PropsT> = ({ children, url, className }) => {
  return (
    <Button
      kind={Kind.MINIMAL}
      size="small"
      onClick={() => window.open(url)}
      className={`font-weight-normal ${className}`}
    >
      {children}
      <Book className="ml-xs" />
    </Button>
  )
}

export default HelpLink
