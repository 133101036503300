import arrow from 'assets/images/campaign-center/opportunity/arrow.png'
import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'

type Props = {
  campaignType: string
  children?: React.ReactNode
  className?: string
}
const Solution: React.FC<Props> = props => {
  const { campaignType, children, className = '' } = props

  let stepIcons: React.ReactNode[] = []
  let stepText: React.ReactNode[] = []
  React.Children.forEach(children, child => {
    if (stepIcons.length > 0) {
      stepIcons.push(
        <div
          className="display-table-cell vertical-middle padding-small"
          key={stepIcons.length}
        >
          <img
            src={arrow}
            className="img-responsive"
            alt={I18n.t(
              `thanx_campaigns.opportunity.${campaignType}.solution.steps.arrow_alt`
            )}
          />
        </div>
      )
      stepText.push(
        <div className="display-table-cell" key={stepText.length} />
      )
    }
    stepIcons.push(<div key={stepIcons.length}>{child}</div>)

    //@ts-ignore
    const childText = child?.props.text
    stepText.push(
      <div
        className="display-table-cell font-size-14 blue-chrome-40"
        style={{ width: '100px' }}
        key={stepText.length}
      >
        {childText}
      </div>
    )
  })

  return (
    <div
      className={`padding-large padding-top-huge margin-bottom-huge ${className}`}
    >
      <Translate
        tag="div"
        className="margin-bottom-large text-center font-size-12 letter-spacing-1 uppercase spearmint-50 bold"
        value={`thanx_campaigns.opportunity.${campaignType}.solution.title`}
      />
      <div className="text-center">
        <div className="display-table inline-block">
          <div className="display-table-row">{stepIcons}</div>
          <div className="display-table-row">{stepText}</div>
        </div>
      </div>
    </div>
  )
}

export default Solution
