import thanxLogoImage from 'assets/images/campaign-center/ic_thanx_push_mock.png'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React, { useMemo } from 'react'

const t = buildTranslate('thanx_campaigns.builder.previews.push')

type Props = {
  text: string | null
}

const Push = ({ text }: Props) => {
  const campaignMerchant = useCurrentMerchant()

  const pushText = useMemo(() => {
    if (!text) {
      return ''
    }

    if (!campaignMerchant || campaignMerchant.branded_app) {
      return text
    }

    return t('custom', {
      merchant: campaignMerchant.nickname,
      customText: text,
    })
  }, [text, campaignMerchant])

  const source = campaignMerchant?.branded_app
    ? campaignMerchant.logo_image_urls.default
    : thanxLogoImage
  const appIcon = <img src={source} alt="app icon" className="app-icon" />

  const appName = campaignMerchant?.branded_app
    ? campaignMerchant?.nickname
    : 'Thanx'

  return (
    <div className="padding-huge padding-top-none">
      <div className="phone-bkg">
        <div className="push padding-small">
          <div className="notice display-table full-width">
            <div className="display-table-cell vertical-top padding-top-micro">
              {appIcon}
            </div>
            <div className="display-table-cell padding-left-small full-width">
              <p className="margin-bottom-small font-size-17">{appName}</p>
              <span
                className="push-message-content block"
                style={{
                  overflowWrap: 'break-word',
                  maxWidth: '224px',
                }}
              >
                {pushText}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo<Props>(Push)
