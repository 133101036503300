import { Switch } from '@thanx/uikit/switch'
import type { Metrics } from 'models/CampaignMetric'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'
import ActivityTable from './components/ActivityTable'

type Props = {
  metrics?: Metrics
  hasDivider?: boolean
}

export const LinkActivity: React.FC<Props> = props => {
  const { metrics, hasDivider = false } = props
  if (!metrics?.link_activity?.link_clicks) {
    return null
  }

  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <p className="deprecated__small-caps-3 margin-bottom-huge">
          <Translate value="thanx_campaigns.report.link_activity.title" />
        </p>
        <div>
          <ActivityTable
            linkActivity={metrics.link_activity}
            messageCount={metrics.message_count}
          />
        </div>
      </div>
    </div>
  )
}

export default withMetrics(['link_activity', 'message_count'])(LinkActivity)
