export default {
  upgrade_modal: {
    title: 'This campaign has not been activated on your plan',
    secondary: 'Speak with our team to get started now',
    preview: 'Preview campaign',
    contact: 'Contact us',
  },
  contact_modal: {
    title: {
      birthday: 'Want to configure your automated birthday campaign?',
      vip: 'Want to configure your automated VIP campaign?',
    },
    secondary:
      'Contact our team to coordinate your configuration and get your program live.',
    contact: 'Request campaign',
  },
  upcoming: {
    footer: {
      title: 'This campaign is coming soon',
      subtitle: 'We can notify you when it is available',
      button: 'Notify me',
    },
  },
  reputation: {
    title: 'Reputation Manager',
    subtitle: 'Get happy customers to share positive reviews online',
    example: {
      header: 'Shiny happy people',
      image_alt: 'NPS Review',
      title: 'Tim loves you.',
      text_one: 'Get happy customers to share positive reviews online',
      text_two: 'Get Tim to share this <span class="alizarin">online</span>.',
    },
    metric: 'customers have given you a positive rating',
    solution: {
      title: 'The reputation manager solution',
      steps: {
        arrow_alt: 'Arrow',
        one: 'You receive a positive review through Thanx',
        image_one_alt: 'Thumbs up icon',
        two: 'Customer receives email urging them to share positive review online',
        image_two_alt: 'Review icon',
        three: 'Online reputation increases',
        image_three_alt: 'Star icon',
      },
    },
    how_it_works: {
      header: 'What happens when you turn on Reputation Manager',
      text: '<p>When customers send you positive feedback, <strong class="grey-80">this automated campaign emails your happy customers to ask them to share their opinion on Google</strong> — where every rating counts.</p><p class="margin-top-medium">We’ll show you how many happy customers were driven to it.</p>',
      image_alt: 'Email review',
    },
    footer: {
      title: 'Improve your online rating just by saying Thanx. Act now.',
      button: 'Set up Reputation Manager',
    },
  },
  bounceback: {
    title: 'Bounceback',
    subtitle:
      'Turn <em>new</em> customers into <em>true</em> customers that see the value in returning sooner',
    example: {
      header: 'Fear of commitment',
      image_alt: 'Bounceback',
      title: 'Meet Sue.',
      text_one:
        '<p>She visited <strong>once</strong>.</p><p>She had a great time.</p>',
      text_two:
        'You haven’t seen her in <span class="alizarin">2 months</span>.',
    },
    solution: {
      title: 'The bounceback solution',
      steps: {
        arrow_alt: 'Arrow',
        one: 'Customer makes first purchase',
        image_one_alt: 'Dollar',
        two: 'Customer doesn’t return in a short period',
        image_two_alt: 'Customer face',
        three: 'Customer receives fast-expiring offer',
        image_three_alt: 'Reward envelope',
        four: 'Customer returns sooner',
        image_four_alt: 'Store',
        five: 'Customer is more likely to stick around',
        image_five_alt: 'Happy customer',
      },
    },
    how_it_works: {
      header: 'What happens when you turn on Bounceback',
      text: '<p>When customers complete a first visit, <strong class="grey-80">this automated campaign sends them a short-lived incentive to return again within 7 days</strong>, sooner than they otherwise would.</p><p class="margin-top-medium">Control groups help you see how this campaign gets customers back sooner and the incremental revenue it creates.</p>',
      image_alt: 'Email review',
    },
    footer: {
      title: 'Make a connection. Minimize bounce. Act now.',
      button: 'Set up Bounceback',
    },
  },
  birthday: {
    title: 'Birthday',
    subtitle:
      'Send a “Happy Birthday” gift to customers so they spend it with you',
    example: {
      header: 'Hello party people',
      image_alt: 'Birthday',
      title: 'Max had a great day.',
      text_one:
        '<p>Yesterday was his birthday.</p><p>He went out to celebrate.</p>',
      text_two:
        '<span class="alizarin">But he didn’t hear from</span> %{merchantName}.',
    },
    metric: 'of your customers had a birthday last month',
    solution: {
      title: 'The birthday solution',
      steps: {
        arrow_alt: 'Arrow',
        one: 'Send message to customer on their birthday',
        image_one_alt: 'Phone message',
        two: 'A birthday gift from you is on their home screen',
        image_two_alt: 'Phone reward',
        three: 'They spend their birthday with you feeling special',
        image_three_alt: 'Store',
      },
    },
    how_it_works: {
      header: 'What happens when you turn on Birthday',
      text: '<p>Thanx prompts customers to enter their birth date and delivers their rewards. <strong class="grey-80">When customers birthday arrives this automated campaign sends your birthday reward to customers</strong>.</p><p>Revenue is measured as customers come in to redeem that offer and supplement it with with additional spending on their visit.</p>',
      image_alt: 'Email',
    },
    footer: {
      title: 'Don’t miss another birthday. Act now.',
      button: 'Set up Birthday',
    },
  },
  tiers: {
    title: 'Tiers',
    subtitle: 'Give dedicated customers the recognition they deserve',
    example: {
      header: 'Always glad you came',
      image_alt: 'Tiers',
      title: 'Hi again, Uni!',
      text_one:
        '<p>Uni has built a history with you.</p><p>She’s the definition of loyal.</p>',
      text_two: 'Make her feel <span class="jungle">special</span>.',
    },
    metric: 'customers visit your business monthly or more',
    solution: {
      title: 'The tiers solution',
      steps: {
        arrow_alt: 'Arrow',
        one: 'You define annual spending tiers and perks',
        image_one_alt: 'Dollar',
        two: 'Customer is encouraged to unlock rewards',
        image_two_alt: 'Customer face',
        three: 'Customers achieve Bronze, Silver, and Gold',
        image_three_alt: 'Reward envelope',
        four: 'Each tier’s perks make them feel great',
        image_four_alt: 'Store',
      },
    },
    how_it_works: {
      header: 'What happens when you turn on Tiers',
      text: '<p>Our team will work with you to design annual spending tiers for consumers that augment your loyalty program’s normal earned reward. <strong class="grey-80">Bronze, Silver, and Gold status is earned annually and retained for 12 months, allowing you to create custom campaigns aimed at your long-term customers</strong>.',
      image_alt: 'Email',
    },
    footer: {
      title: 'Build a stronger brand and long term relationships. Act now.',
      button: 'Contact us to setup Tiers',
    },
  },
  conversion: {
    title: 'Loyalty Membership',
    subtitle: 'Convert your email subscribers into loyalty members.',
    metric:
      'email subscribers you’ve synced with Thanx aren’t in your loyalty program yet',
    example: {
      header: 'Know thy customer',
      image_alt: 'Loyalty Membership',
      title: 'Dan’s a fan.',
      text_one:
        '<p>He loves your business.</p><p>He joined your email list.</p>',
      text_two:
        'But you still don’t <span class="alizarin">know</span> Dan. Yet.',
    },
    solution: {
      title: 'The loyalty membership solution',
      steps: {
        arrow_alt: 'Arrow',
        one: 'Sync your email list',
        image_one_alt: 'email list',
        two: 'Customer invited to join your loyalty program',
        image_two_alt: 'loyalty invitation',
        three: 'Customers signs up to redeem reward',
        image_three_alt: 'Reward envelope',
        four: 'They get rewarded and you get to know them better',
        image_four_alt: 'phone reward',
        five: 'Customer loyalty is now enhanced with personalized marketing',
        image_five_alt: 'Happy customer',
      },
    },
    how_it_works: {
      header: 'What happens when you turn on Loyalty Membership',
      text: '<p>This automated email campaign engages consumers when they join your mailing list, <strong class="grey-80">sending them a series of customizable emails that remind them about your loyalty program</strong>. You get more segmented customers and insights while they get rewarded.',
      image_alt: 'Email review',
    },
    footer: {
      title: 'Convert your emails into revenue. Act now.',
      button: 'Set up Loyalty Membership',
    },
  },
  vip: {
    title: 'VIP',
    subtitle:
      'Get personal with your elite customers and drive huge bottom-line lift.',
    metric: 'of your customers are VIPs',
    example: {
      header: 'The sky is the limit',
      image_alt: 'vip',
      title: 'Meet Sky.',
      text_one:
        '<p>She loves your business.</p><p>She’s one of your top spenders.</p>',
      text_two: 'Don’t <span class="alizarin">lose</span> her.',
    },
    churn: {
      title: 'Understanding scale',
      header: '5% of your customers, 42% of your revenue',
      text: 'It’s seven times more cost effective to gain a visit from an existing customer than to acquire a new one. <strong class="grey-80">Know who your VIPs are</strong>.',
    },
    solution: {
      title: 'The vip solution',
      steps: {
        arrow_alt: 'Arrow',
        one: 'Customer is inspired to spend big',
        image_one_alt: 'Dollar',
        two: 'Customer spends big',
        image_two_alt: 'Customer spends',
        three: 'Killer VIP reward is unlocked',
        image_three_alt: 'Reward envelope',
        four: 'Customer reminded to spend again next month',
        image_four_alt: 'Calendar',
        five: 'Customer now spends more frequently',
        image_five_alt: 'Happy customer',
      },
    },
    how_it_works: {
      header: 'What happens when you turn on VIP',
      text: '<p>Thanx has already analyzed your customers to recommend a spending threshold that will automatically target your top 5% active, loyal customers. <strong class="grey-80">Those who spend over this threshold in two consecutive months automatically earn your VIP reward</span>. Automated reminders tell customers when they’re at risk of losing this awesome perk, ensuring you keep them active and engaged.</p>',
      image_alt: 'Email',
    },
    footer: {
      title: 'Keep VIPs happy. Roll out the red carpet. Act now.',
      button: 'Set up VIP',
      unavailable: {
        unit: 'more day',
        title: 'VIP will unlock in %{days} %{unit}',
        subtitle:
          'We’re still determining who your best customers are. We’ll notify you when it’s ready.',
        button: 'Notify me',
      },
    },
  },
  referrals: {
    title: 'Referrals',
    subtitle: 'Turn your best customers into a viral marketing army',
    metric: 'customers say they would refer you to a friend based on NPS',
    example: {
      header: 'Always glad you came',
      image_alt: 'Referrals',
      title: 'Meet Casey.',
      text_one:
        '<p>She loves your business.</p><p>She has a LOT of friends.</p>',
      text_two:
        'But her friends aren’t your customers. <span class="jungle">Yet</span>.',
    },
    solution: {
      title: 'The bounceback solution',
      steps: {
        arrow_alt: 'Arrow',
        one: 'Customer rewarded for inviting friends to join',
        image_one_alt: 'Phone',
        two: 'Friends rewarded for joining',
        image_two_alt: 'Reward envelope',
        three: 'New referrals make a purchase',
        image_three_alt: 'Dollar',
        four: 'You get a new customer',
        image_four_alt: 'Happy customer',
      },
    },
    how_it_works: {
      header: 'What happens when you turn on Referrals',
      text: '<p>Thanx prompts customers invite their friends in the app and via SMS. <strong class="grey-80">Customers get reward progress towards their own reward, and their friends get a reward that you choose<strong>.</p><p>Neither inviter or invitee receive their benefits until the invited friend makes their first purchase, thereby guaranteeing you 3 visits (1 to unlock the reward, one to redeem it, plus one from the inviter to redeem their reward when they complete it).</p><p>We’ll let you see how many consumers join and how much money they spend when they do.</p>',
      image_alt: 'Email review',
    },
    footer: {
      title: 'Grow your business. Reward your advocates. Act now.',
      button: 'Set up Referrals',
    },
  },
}
