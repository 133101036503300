import { createCampaign } from 'actions/campaigns'
import { getTargetSegments } from 'actions/targetSegments'
import Spacer from 'components/Spacer'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { Fields as TargetSegment } from 'models/TargetSegment'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { push } from 'react-router-redux'
import CustomCampaignCards from './CustomCampaignCards'
import CustomCampaignsModal from './CustomCampaignsModal'

const t = buildTranslate('thanx_campaigns.landing.overview.targeted')

const TargetContent: React.FC = () => {
  const merchant = useCurrentMerchant()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [segmentId, setSegmentId] = useState<number | null>(null)

  async function handleCreate(targetSegment?: TargetSegment) {
    const params = {
      campaign: {
        type: 'custom',
        target_segment_id: targetSegment?.id,
      },
    }
    const action = await dispatch(createCampaign(params))
    // @ts-ignore
    if (action.error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('custom.modal.errors.submit'),
        })
      )
      return
    }
    // @ts-ignore
    const campaignId = action.payload?.data?.campaign?.id
    if (campaignId) {
      dispatch(push(`/thanx_campaigns/targeted/custom/${campaignId}`))
    } else {
      dispatch(
        alert({
          key: 'danger',
          message: t('custom.modal.errors.submit'),
        })
      )
    }
    return
  }

  useEffect(() => {
    if (!merchant) return
    dispatch(getTargetSegments())
  }, [dispatch, merchant])
  return (
    <div className="padding-left-huge padding-right-huge padding-bottom-huge content-box white-bkg border-radius-2 display-flex flex-column flex-justify-center">
      <h4 className="deprecated__h4 text-center">{t('title')}</h4>
      <Spacer paddingTop="55px" />
      <div className="display-flex flex-space-between flex-wrap">
        <>
          <CustomCampaignCards
            onNewClick={() => {
              setSegmentId(null)
              setIsOpen(true)
            }}
            onExistingClick={segmentId => {
              setSegmentId(segmentId)
              setIsOpen(true)
            }}
            imageCard={false}
            limit={3}
          />

          <CustomCampaignsModal
            title={t('custom.modal.title')}
            show={isOpen}
            onModalClose={() => {
              setSegmentId(null)
              setIsOpen(false)
            }}
            onCreate={handleCreate}
            defaultSegmentId={segmentId}
          />
        </>
      </div>
      <Spacer paddingTop="75px" />
      <div className="display-flex flex-justify-center">
        <LinkContainer to="/thanx_campaigns/targeted">
          <Button bsStyle="primary" className="btn-huge">
            {t('button_text')}
          </Button>
        </LinkContainer>
      </div>
    </div>
  )
}

export default TargetContent
