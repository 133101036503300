import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import { Fields as Campaign } from 'models/Campaign'
import moment from 'moment-timezone'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { textUtils } from 'utilities/textUtils'
import { timeUtils } from 'utilities/timeUtils'
import { useUserTimeZone } from 'utilities/userTimeZone'

const t = buildTranslate(
  'thanx_campaigns.report.v3.configuration.restrictions.day_part'
)

const dayMapping = {
  redeem_restriction_monday: 'Mondays',
  redeem_restriction_tuesday: 'Tuesdays',
  redeem_restriction_wednesday: 'Wednesdays',
  redeem_restriction_thursday: 'Thursdays',
  redeem_restriction_friday: 'Fridays',
  redeem_restriction_saturday: 'Saturdays',
  redeem_restriction_sunday: 'Sundays',
}

type Props = {
  campaign: Campaign
}
const DayPart: React.FC<Props> = ({ campaign }) => {
  const {
    redeem_start_at,
    redeem_end_at,
    redeem_restriction_time_range,
    redeem_restriction_description,
  } = campaign
  const timeZone = useUserTimeZone()

  let startString = ''
  let endString = ''
  let dayString = ''
  let startTimeString = ''
  let endTimeString = ''
  let days: (string | undefined)[] = []

  if (redeem_start_at) {
    startString = t('start_at', {
      startAt: moment
        .tz(redeem_start_at, timeZone)
        .format('ddd[,] MMM Do YYYY'),
    })
  }

  if (redeem_end_at) {
    endString = t('end_at', {
      endAt: moment.tz(redeem_end_at, timeZone).format('ddd[,] MMM Do YYYY'),
    })
  }

  if (redeem_restriction_time_range) {
    const restrictions: { [key: string]: number[] } = pickBy(
      pick(campaign, Object.keys(dayMapping)),
      value => {
        return !isEmpty(value)
      }
    ) as { [key: string]: number[] }
    const restrictionKeys = Object.keys(restrictions)

    if (restrictionKeys.length > 0) {
      const timeRange: number[] = Object.values(restrictions)[0]

      if (timeRange.length > 0) {
        startTimeString = t('start_time_string', {
          startTimeString: timeUtils.hourLabel(timeRange[0], false),
        })
        endTimeString = t('end_time_string', {
          endTimeString: timeUtils.hourLabel(
            last(timeRange),
            true,
            moment.tz(timeZone).format('z')
          ),
        })
      }

      days = Object.values(pick(dayMapping, restrictionKeys))
      if (!isEmpty(days)) {
        const daySentence = textUtils.toSentence({
          array: days as string[],
          punctuation: '',
        })
        if (startString || endString) {
          dayString = t('day_string', {
            dayString: daySentence,
          })
        } else {
          dayString = daySentence as string
        }
      }
    }
  }

  if (
    !startString &&
    !endString &&
    !dayString &&
    !startTimeString &&
    !endTimeString
  ) {
    return null
  }

  const translation =
    days.length === 7 ? (
      <Translate
        value="thanx_campaigns.report.v3.configuration.restrictions.day_part.time_range_joined"
        restrictionTime={startTimeString + endTimeString}
        dangerousHTML
      />
    ) : (
      <Translate
        value="thanx_campaigns.report.v3.configuration.restrictions.day_part.joined"
        description={
          redeem_restriction_description
            ? `“${redeem_restriction_description}“`
            : t('generic')
        }
        startAt={startString}
        endAt={endString}
        restrictionDays={dayString}
        restrictionTime={startTimeString + endTimeString}
        dangerousHTML
      />
    )
  return (
    <Text.BodyText4 tag="div" color="grey70" className="mb-xs">
      {translation}
    </Text.BodyText4>
  )
}

export default DayPart
