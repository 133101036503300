import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import MetricSummary from 'scenes/Reports/ActivityReport/components/MetricSummary'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate(
  'reports.activity.sections.rewards.metrics.outstanding_rewards'
)

type Props = {
  allCount?: number
  loyaltyCount?: number
  liabilityAmount: number | string
}

export const OutstandingRewards: React.FC<Props> = ({
  allCount,
  loyaltyCount,
  liabilityAmount,
}) => {
  const secondaryValue = loyaltyCount
    ? `(${I18n.l(loyaltyCount)} ${t('count_loyalty_text')})`
    : ''

  return (
    <div>
      <MetricSummary
        title={t('title')}
        summaryValue={I18n.l(allCount)}
        summaryText={t('count_summary_text')}
        secondarySummaryValue={secondaryValue}
      />
      <MetricSummary
        summaryValue={currencyFormatter(liabilityAmount)}
        summaryText={t('amount_summary_text')}
      />
    </div>
  )
}

export default OutstandingRewards
