import { Fields as NotifyEmail } from 'models/NotifyEmail'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'
import { CampaignStateT } from '../models/Campaign'

export const GET_ALL = 'notifyEmail/GET_ALL'
export const GET_ALL_SUCCESS = 'notifyEmail/GET_ALL_SUCCESS'
export const GET_ALL_FAIL = 'notifyEmail/GET_ALL_FAIL'

export type GetNotifyEmailsAction = AxiosAction<
  typeof GET_ALL,
  { notify_emails: NotifyEmail[] }
>

export function getNotifyEmails(params?: {
  state: CampaignStateT
}): GetNotifyEmailsAction {
  return {
    type: GET_ALL,
    payload: {
      client: 'default',
      request: {
        url: 'notify_emails',
        method: 'get',
        params,
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getNotifyEmails>
>
