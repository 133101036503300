import { getLocations } from 'actions/location'
import { DateRangeFilterProvider } from 'components/DateFilter/DateFilterContext'
import { getDateFilter } from 'components/DateFilter/utilities'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import { LocationContextProvider } from 'contexts/LocationContext'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import React, { useEffect, useMemo, useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectCurrentMerchantLocations } from 'selectors/location'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import AllPurchasesTab from './AllPurchasesTab'
import FirstPurchasesTab from './FirstPurchasesTab'

const t = buildTranslate('purchases')
enum Tabs {
  ALL_PURCHASES = 'all_purchases',
  FIRST_PURCHASES = 'first_purchases',
}

const PurchasesReport: React.FC = () => {
  const merchant = useCurrentMerchant()!
  const [activeTab, setActiveTab] = useState<
    'all_purchases' | 'first_purchases'
  >('all_purchases')
  const merchantUser = useSelector(selectCurrentMerchantUser)
  const merchantId = useSelector((state: any) => state.auth.current_merchant_id)
  const roles = merchantUser?.rolesForMerchantId(merchantId)
  const locations = useSelector(selectCurrentMerchantLocations)
  const dispatch = useDispatch()
  const timezone = moment.tz.guess()
  const pageTitle = t(`tabs.${activeTab}.title_tag`)

  useEffect(() => {
    dispatch(getLocations({ coming_soon: false }))
  }, [dispatch])

  const bounds = useMemo(
    () => ({
      startDate: moment.tz(merchant.live_at, timezone),
      endDate: moment.tz(timezone),
    }),
    [timezone, merchant]
  )
  const filter = useMemo(() => getDateFilter('last_30', timezone), [timezone])

  if (isEmpty(locations)) return null

  return (
    <ProtectedPageHeader
      title={t('title')}
      resource="LocationDetailReports"
      action="access"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <LocationContextProvider
        initialLocation={roles?.includes('manager') ? locations[0] : null}
      >
        <Tab.Container
          activeKey={activeTab}
          transition={false}
          onSelect={(tab: string | null) => {
            if (!tab) return
            setActiveTab(tab as Tabs)
          }}
        >
          <TabNav>
            <TabItem
              state={Tabs.ALL_PURCHASES}
              title={
                <div className="d-flex align-content-center">
                  <span className="mr-xxs">
                    {t('tabs.all_purchases.title')}
                  </span>
                </div>
              }
            />
            <TabItem
              state={Tabs.FIRST_PURCHASES}
              title={t('tabs.first_purchases.title')}
            />
          </TabNav>
          <Tab.Content className="pb-l h-100 grey-05-bkg">
            <TabPane state={Tabs.ALL_PURCHASES}>
              <AllPurchasesTab merchant={merchant} />
            </TabPane>
            <TabPane state={Tabs.FIRST_PURCHASES}>
              <DateRangeFilterProvider
                bounds={bounds}
                timeZone={timezone}
                initialFilter={filter}
              >
                <FirstPurchasesTab merchant={merchant} />
              </DateRangeFilterProvider>
            </TabPane>
          </Tab.Content>
        </Tab.Container>
      </LocationContextProvider>
    </ProtectedPageHeader>
  )
}

export default PurchasesReport
