export default {
  title: 'Effective discount rate',
  page_title: 'Effective discount rate - Points | Thanx',
  empty_state: {
    title: 'This report is not ready yet',
    points_inactive:
      'You’ll be able to view this report in the next calendar month following the activation of your Points program.',
    coming_soon:
      'You’ll be able to view this report in the next calendar month following your launch date.',
    available_in_x_days:
      'You’ll be able to view this report in %{remainingDays} days.',
  },
  counters: {
    industry_benchmark: 'Industry benchmark is %{rate}',
    effective_discount_rate: 'Effective discount rate %{counterDateLabel}',
    cost_of_rewards: 'Cost of rewards redeemed %{counterDateLabel}',
    net_revenue: 'Net revenue %{counterDateLabel}',
  },
  popovers: {
    how_its_calculated: 'How it’s calculated',
    what_this_means: 'What this means',
    example: 'Example',
    effective_discount_rate: [
      'The cost of loyalty rewards that loyalty members redeemed relative to the revenue they generated for you.',
      'Cost of loyalty rewards / Total revenue attributed to loyalty members',
      'Loyalty members exchanged their points for rewards this month. The total cost of those rewards was %{costOfRewardsRedeemed}. The total amount spent by loyalty members this month was %{netRevenue}. The effective discount rate for this month was %{effectiveDiscountRate}.',
      'Loyalty members exchanged their points for rewards in July. The total cost of those rewards was $1,000. The total amount spent by loyalty members this month was $100,000. The effective discount rate for July was 1.0%.',
    ],
    cost_of_rewards: [
      'The total cost of loyalty rewards redeemed in the rewards marketplace.',
      'Loyalty members exchanged their points for rewards this month. The total cost of those rewards was %{costOfRewardsRedeemed}.',
    ],
    net_revenue: [
      'The total revenue attributed to loyalty members.',
      'The total amount spent by loyalty members this month was %{netRevenue}.',
    ],
  },
  chart: {
    industry_benchmark: 'Industry benchmark',
    title: 'Effective discount rate over time',
    x_axis: 'Date',
    y_axis: 'Discount rate',
    tooltip: {
      effective_discount_rate: 'Effective discount rate',
      cost_of_rewards: 'Cost of redemptions',
      net_revenue: 'Net revenue',
    },
  },
  explainer_card: {
    title: 'Increase the profitability of your program',
    subtitle:
      'Effective Discount Rate (EDR) refers to the ratio of total money spent by loyalty customers that generated reward progress to the total cost of discounts given by the loyalty program. Reduce your EDR to create a more cost-effective program, while maintaining its impact and reach.',
    edr_101: 'Effective discount rate 101',
  },
  table: {
    title: 'Cost efficacy of marketplace rewards',
    subtitle: 'Redemptions during %{dateLabel}',
    name: 'Reward',
    amount_spent_to_earn: 'Amount spent to earn',
    cost_per_redemption: 'Cost per redemption',
    configured_discount_rate: 'Configured discount rate',
    reward_redemptions_count: 'Rewards redeemed',
    reward_redemption_cost: 'Cost of rewards redeemed',
    no_longer_in_marketplace: 'No longer in the marketplace',
    learn_more: 'Learn more',
    total_redeemed_cost:
      'Total cost of all redemptions from the rewards marketplace: %{totalCost}',
    popovers: {
      how_its_calculated: 'How it’s calculated',
      what_this_means: 'What this means',
      amount_spent: [
        'The amount customers need to spend to earn this reward.',
        'Loyalty members need to spend %{amountSpent} in order to earn enough points that can be exchanged for this reward.',
      ],
      cost_per_redemption: [
        'The cost (discount and COGS) of this reward.',
        'Each time a loyalty member exchanges points for this reward, the cost is %{costPerRedemption}.',
      ],
      discount_rate: [
        'The discount rate is based on your configuration.',
        'Cost per redemption / Amount spent to earn',
        `A customer had to spend %{amountSpent} to get enough points for this reward. When they exchange points for this reward, the cost of the reward is %{costPerRedemption}. The configured discount rate is %{discountRate}.`,
      ],
      rewards_redeemed: [
        'The count of rewards redeemed.',
        'This reward was redeemed %{rewardRedemptionCount} times from the rewards marketplace in %{dateLabel}.',
      ],
      cost_of_rewards_redeemed: [
        'The sum of costs for all redemptions of this reward.',
        'Rewards redeemed * Cost per redemption',
        'This reward was redeemed %{rewardRedemptionCount} times from the rewards marketplace in %{dateLabel}. The cost per redemption is %{costPerRedemption}. The cost of rewards redeemed was %{rewardRedemptionCost}.',
      ],
      summary: [
        'The sum of costs for all rewards redeemed.',
        'All rewards redeemed in the rewards marketplaces in %{dateLabel} had a total cost of %{currentCostAmount}',
        'If you transitioned from the legacy Thanx loyalty program, any rewards issued by that program are not part of the rewards marketplace, but they did contribute to your total cost of %{totalCost} in %{dateLabel}.',
      ],
    },
  },
}
