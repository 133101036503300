import React from 'react'

const Checkmark = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 4.47714 4.47714 0 10 0C15.5229 0 20 4.47714 20 10C20 15.5229 15.5229 20 10 20C4.47714 20 0 15.5229 0 10ZM18.0645 9.99994C18.0645 5.54232 14.4569 1.93542 10 1.93542C5.54238 1.93542 1.93549 5.54301 1.93549 9.99994C1.93549 14.4576 5.54307 18.0645 10 18.0645C14.4576 18.0645 18.0645 14.4569 18.0645 9.99994ZM15.6534 7.18819L14.7447 6.27214C14.5565 6.08242 14.2501 6.08117 14.0604 6.2694L8.36073 11.9233L5.94976 9.49274C5.76157 9.30302 5.4552 9.30177 5.26548 9.48996L4.34939 10.3987C4.15968 10.5869 4.15843 10.8932 4.34665 11.083L8.00718 14.7731C8.19536 14.9629 8.50173 14.9641 8.69145 14.7759L15.6507 7.8725C15.8404 7.68427 15.8416 7.3779 15.6534 7.18819Z"
      fill="#27AE60"
    />
  </svg>
)

export default Checkmark
