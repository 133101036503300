// TODO. Use css modules to get the sass variables
// when we eject or upgrade to CRAv2

export default {
  screenXsMax: 703,
  screenSmMin: 704,
  screenSmMax: 991,
  screenMdMin: 992,
  screenMdMax: 1399,
  screenLgMin: 1400,
}
