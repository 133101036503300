import { FormsySelect } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { externalFormsApi } from 'api/externalForms'
import ImagePicker from 'components/ImagePicker'
import { buildTranslate } from 'locales'
import React from 'react'
import { FormGroup, FormLabel } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ContentBlockData } from 'scenes/Cms/Content/AppHomePage/hooks'
import { selectAppHomePageValidation } from 'selectors/validation'

type Props = {
  blockId: string
  contentBlock: ContentBlockData
}

const t = buildTranslate(
  'cms.content.app_home_page.content.edit_block.content.external_form'
)

const ExternalFormContent = ({ blockId, contentBlock }: Props) => {
  const { data, isLoading } = externalFormsApi.useGetExternalFormsQuery()
  const forms = data?.external_forms || []
  const options = forms.map(form => ({
    value: form.id,
    label: form.name,
  }))

  const imageValidation = useSelector(selectAppHomePageValidation)
  if (!imageValidation) return <></>
  const { type } = contentBlock

  if (type !== 'external_form') return <></>
  const {
    image,
    settings: { form_id },
  } = contentBlock

  return (
    <>
      <ImagePicker
        className="mb-l"
        name={`${blockId}.image`}
        value={image}
        validations={{
          image: true,
        }}
        validationErrors={{
          image: I18n.t('validations.is_required'),
        }}
        imageValidations={imageValidation}
      />

      <FormGroup>
        <FormLabel className="body-text-4 bold mb-xs">
          {t('form_description')}
        </FormLabel>
        <FormsySelect
          options={options}
          value={form_id || options[0].value}
          zIndex={1002}
          maxDropdownHeight="200px"
          name={`${blockId}.settings.form_id`}
          placeholder={t('select_a_form')}
          disabled={forms.length === 0}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
          }}
          isLoading={isLoading}
        />
      </FormGroup>
      <Text.BodyText3 color="grey70" tag="p" className="mt-m">
        {t('description_1')}
      </Text.BodyText3>
      <Text.BodyText3 color="grey70" tag="p" className="mt-s">
        {t('description_2')}
        <a
          className="ml-xs"
          href={`mailto:merchant.support@thanx.com?subject=${t(
            'email_subject'
          )}`}
        >
          {t('contact_us')}
        </a>
      </Text.BodyText3>
    </>
  )
}

export default ExternalFormContent
