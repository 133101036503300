import { Switch } from '@thanx/uikit/switch'
import React, { useState } from 'react'
import { Translate } from 'react-redux-i18n'

type Props = {
  isOpen?: boolean
  isDisabled?: boolean
  children?: React.ReactNode[] | React.ReactNode
}

const Refinements: React.FC<Props> = props => {
  const { isOpen = false, isDisabled, children } = props
  const [showChildren, setShowChildren] = useState(isOpen)

  function toggleShowChildren() {
    if (isDisabled) return

    setShowChildren(!showChildren)
  }

  return (
    <div>
      <div
        className={`
          margin-bottom-huge
          ${isDisabled ? '' : 'cursor-pointer'}
        `}
        onClick={toggleShowChildren}
      >
        <p className={`font-size-18 ${isDisabled ? 'grey-40' : ''}`}>
          <strong>
            <Translate value="thanx_campaigns.builder.forms.audience.refinements.title" />
            <i className="fa fa-cog margin-left-small" />
          </strong>
        </p>
      </div>
      <Switch condition={showChildren}>{children}</Switch>
    </div>
  )
}

export default Refinements
