import { alert } from 'actions/flash'
import { useDispatch } from 'react-redux'

export const useDisplayMessage = (reset?: VoidFunction) => {
  const dispatch = useDispatch()

  return (message: string, key: 'danger' | 'success') => {
    dispatch(
      alert({
        message,
        key,
      })
    )
    reset?.()
  }
}
