import { Fields } from 'models/UserTagValue'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_USER_TAG_VALUES = 'tags/GET_USER_TAG_VALUES'
export const GET_USER_TAG_VALUES_SUCCESS = 'tags/GET_USER_TAG_VALUES_SUCCESS'
export const GET_USER_TAG_VALUES_FAIL = 'tags/GET_USER_TAG_VALUES_FAIL'

export function getUserTagValues(
  key: string
): AxiosAction<typeof GET_USER_TAG_VALUES, { values: Fields[] }> {
  return {
    type: GET_USER_TAG_VALUES,
    payload: {
      client: 'default',
      request: {
        url: `user_tag_keys/${key}/values`,
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getUserTagValues>
>
