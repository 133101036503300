import { getOrderingConfigs } from 'actions/ordering_configs'
import { updatePaginationCurrentPage } from 'actions/pagination'
import useDispatch from 'hooks/useDispatch'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectOrderingConfigs } from 'selectors/orderingConfigs'
import { selectPagination } from 'selectors/pagination'

export const useOrderingConfigsTable = () => {
  const dispatch = useDispatch()
  const [isFetchingOrderingConfigData, setIsFetchingOrderingConfigData] =
    useState(false)

  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'ordering_configs', 'all')
  )

  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const locationIds = paginationInfo?.record_ids?.[currentPage] ?? []
  const orderingConfigs = (
    useSelector(state => selectOrderingConfigs(state)) ?? []
  ).filter(location => locationIds.includes(location.id))

  const fetchOrderingConfigs = useCallback(
    async (page: number) => {
      setIsFetchingOrderingConfigData(true)
      const params: Parameters<typeof getOrderingConfigs>[0] = {
        page,
      }
      await dispatch(getOrderingConfigs(params))
      setIsFetchingOrderingConfigData(false)
    },
    [dispatch]
  )

  const onChangePage = useCallback(
    async (page: number) => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // users for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'ordering_configs',
            id: 'all',
            page,
          })
        )
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      fetchOrderingConfigs(page)
    },
    [dispatch, paginationInfo, fetchOrderingConfigs]
  )

  useEffect(() => {
    fetchOrderingConfigs(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOrderingConfigs])

  return {
    isFetchingOrderingConfigData,
    onChangePage,
    currentPage,
    numPages,
    orderingConfigs,
  }
}
