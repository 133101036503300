import { useStyletron } from '@thanx/uikit/theme'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
const t = buildTranslate('cms.brand.brand_assets')

type Props = {
  color?: string
}

const Asset: React.FC<Props> = props => {
  const { color } = props
  const [css] = useStyletron()

  return (
    <Section
      className={`d-flex justify-content-center align-items-center
          ${css({
            backgroundColor: color || 'white',
            height: '72px',
            width: '150px',
            minWidth: '150px',
          })}`}
    >
      <p className={`small-caps-3 grey-40 m-0`}>
        {color ? '' : t('not_configured')}
      </p>
    </Section>
  )
}

export default Asset
