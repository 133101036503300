import { buildTranslate } from 'locales'
import { ValidationTypes } from 'models/Validation'
import React from 'react'
import { useSelector } from 'react-redux'
import ImageUploader from 'scenes/RedeemManager/Builder/ImageUploader'
import { selectRedeemAdvertisingValidation } from 'selectors/validation'

const t = buildTranslate('redeem_manager.builder.steps.configuration.images')

type Props = {
  existingId?: number
  templateId: number
}

const CategoryImage: React.FC<Props> = ({ templateId }) => {
  const imageValidations = useSelector(selectRedeemAdvertisingValidation)
  const badgeValues = [t('app'), t('web'), t('online_size'), t('jpg'), t('png')]

  return (
    <ImageUploader
      className="pl-l w-100 pt-xs"
      badgeValues={badgeValues}
      imageValidations={imageValidations}
      type={ValidationTypes.RedeemAdvertising}
      templateId={templateId}
    />
  )
}

export default CategoryImage
