import { Switch } from '@thanx/uikit/switch'
import { getSmsTriggers } from 'actions/smsTriggers'
import Section from 'components/Section'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectSmsTriggers } from 'selectors/smsTrigger'
import CmsPage from '../components/CmsPage'

const t = buildTranslate('cms.signup.sms_keywords')

const SmsKeywords: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const smsTriggers = useSelector(selectSmsTriggers)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await dispatch(getSmsTriggers())
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      isLoading={isLoading}
    >
      <Switch condition={smsTriggers.length > 0}>
        <Section className="p-xl mt-l">
          {smsTriggers.map(smsTrigger => (
            <p key={smsTrigger.id} className="grey-70">
              {smsTrigger.keyword}
            </p>
          ))}
        </Section>
      </Switch>
    </CmsPage>
  )
}

export default SmsKeywords
