import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('merchant_users.form.email')
const vt = buildTranslate('validations')

type Props = { value: string; onChange: (value: string) => void }

const EmailCard = ({ value, onChange }: Props) => {
  return (
    <>
      <Text.Header3 bold className="mb-s mt-0">
        {t('header')}
      </Text.Header3>
      <FormsyInput
        type="email"
        name="email"
        label={t('title')}
        value={value}
        onChange={onChange}
        validations={{
          isRequired: true,
          isEmail: true,
        }}
        validationErrors={{
          isRequired: vt('is_required'),
          isEmail: vt('is_email'),
        }}
      />
    </>
  )
}

export default EmailCard
