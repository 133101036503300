import {
  GET_LOCATIONS_SUCCESS,
  GET_LOCATION_SUCCESS,
  ResolvedAction,
} from 'actions/location'
import { RESET_DB } from 'actions/orm'
import { Fields as Link } from 'models/Link'
import { attr, fk, Model, ModelType } from 'redux-orm'
import { RedemptionProvider } from './RedemptionProvider'

export interface Fields {
  merchant_id: number
  category_id: number
  id: number
  name: string
  street: string
  city: string
  state: string
  zip: string
  latitude: string
  longitude: string
  phone: string
  time_zone: string
  opentable_link?: Link
  website_link?: Link
  visibility: string
  redemption_provider: RedemptionProvider | null

  fullName(): string
}

export default class Location extends Model<typeof Location, Fields> {
  static modelName: string = 'Location'

  static get fields(): any {
    return {
      id: attr(),
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'locations',
      }),
      category_id: fk({
        to: 'LocationCategory',
        as: 'category',
        relatedName: 'locations',
      }),
      name: attr(),
      street: attr(),
      city: attr(),
      state: attr(),
      zip: attr(),
      latitude: attr(),
      longitude: attr(),
      phone: attr(),
      time_zone: attr(),
      redemption_provider: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    Location: ModelType<Location>
  ) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_LOCATIONS_SUCCESS:
        action.payload.data.locations.forEach(location => {
          return Location.upsert(location)
        })
        break
      case GET_LOCATION_SUCCESS:
        Location.upsert(action.payload.data.location)
        break
      case RESET_DB:
        Location.all().delete()
        break
      default:
        break
    }
  }

  fullName() {
    // @ts-ignore
    return `${this.street}, ${this.city}, ${this.state}`
  }
}
