export default {
  brand_assets: {
    title: 'Brand assets',
    description:
      'Your logos, colors, and cover image apply globally throughout your app, your web ordering experience as well as your emails.<br/><br/>Need to update your brand assets? <a href="mailto:merchant.support@thanx.com">Contact us</a>',
    not_configured: 'NOT CONFIGURED',
    where_customers: 'Where customers see this',
    colors: {
      title: 'Colors',
      primary: 'Primary',
      primary_description:
        'Used throughout your experience, including buttons and links and backgrounds, to represent your brand. It should contrast well with white text to ensure legibility.',
      secondary: 'Secondary',
      secondary_description:
        'Used as the default background color for alert banners. (No longer applies if you configure a custom color for alert banners).',
      tertiary: 'Tertiary',
      tertiary_description: 'Used as a fallback for light backgrounds.',
    },
    logos: {
      title: 'Logos',
      logo_size: '900 x 360px',
      primary_logo: 'Primary logo',
      primary_logo_description:
        'Your brand’s main logo. Used on light backgrounds such as on the app menu, or in the navigation bar if web ordering is present. It also appears in the header of your rewards and feedback pages when the secondary logo isn’t present.',
      secondary_logo: 'Secondary logo',
      secondary_logo_description:
        'Your secondary logo is a reverse (usually white) variant of your brand logo used on dark backgrounds. It appears in the header of your rewards and feedback pages.',
      app_icon: 'App icon',
      app_icon_description:
        'Your branded app’s icon in the app store and on your customer’s device. It’s also used in the Thanx app to represent your brand at sign-up as well as on the Find Merchants page.',
      app_icon_size: '1200 x 1200 px',
      jpg: 'jpg',
      png: 'png',
    },
    reward_icon: {
      title: 'Reward icon',
      description:
        'Your reward icon appears almost everywhere rewards are mentioned, and is the image your customers will associate with rewards. By default this icon is a badge with a checkmark but can be replaced with a custom icon. Simpler designs and solid shapes read better.<br/><br/>If no icon is provided this will default to a generic reward icon (in your primary color).',
      size: '140 x 140 px',
      png: 'png',
    },
    cover_image: {
      title: 'Cover image',
      description:
        'Your cover image is used as both a hero graphic and a background image that conveys your brand. Some places you’ll see this image appear are the rewards page in the app and as the default hero image in email templates.',
      size: '1125 x 960 px',
      jpg: 'jpg',
    },
  },
  concept_assets: {
    title: 'Concept assets',
    description:
      'Each brand concept can have its own square logo and cover image. These can be displayed as a carousel on your rewards page.',
    brand_item: {
      logo: 'Logo',
      cover_image: 'Cover image',
      logo_size: '1200 x 1200 px',
      cover_size: '1125 x 960 px',
      jpg: 'jpg',
      png: 'png',
    },
  },
  app_store_assets: {
    title: 'App store assets',
    description:
      'Need to update your app store listings? <a href="mailto:merchant.support@thanx.com">Contact us</a>',
    where_customers: 'Where customers see this',
    app_icon: {
      title: 'App icon',
      description:
        'Your app’s icon appears in the app store listing and on the customer’s device. Use the same image for the Apple App Store and the Android Play Store.',
      size: '1200 x 1200 px',
    },
    jpg: 'jpg',
    android_notification_icon: {
      title: 'Android notification icon',
      description:
        'Your Android notification icon is what customers see positioned next to the notification text on Android devices. It must be white with a transparent background.',
      size: '96 x 96 px',
      png: 'png',
    },
    app_store_screens: {
      title: 'Apple App Store screens',
      link_text: 'Download the Figma template',
      description:
        'Your App Store listing images are displayed when customers search for your app on their iOS device.',
      screens_6: '6.5 Screens',
      screens_6_size: '1242 x 2688 px',
      screens_5: '5.5 Screens',
      screens_5_size: '1242 x 2208 px',
    },
    play_store_screens: {
      title: 'Google Play Store screens',
      link_text: 'Download the Figma template',
      description:
        'Your Play Store listing images are displayed when customers search for your app on their Android device.',
      size: '750 x 1334 px',
    },
    android_feature: {
      title: 'Android feature graphic',
      description:
        'Your Android feature graphic is used to promote your app in the Google Play Store.',
      size: '1024 x 500 px',
    },
    metadata: {
      title: 'Metadata',
      description:
        'Your app’s metadata is information about your app found in the Google Play Store and the Apple App Store.',
      listing_name: 'Listing name',
      icon_name: 'Icon name',
      short_description: 'Short description',
      full_description: 'Full description',
      keywords: 'Keywords',
      marketing_url: 'Marketing URL',
      ios_primary_category: 'iOS primary category',
      ios_secondary_category: 'iOS secondary category',
      android_category: 'Android category',
    },
  },
  social_links: {
    title: 'Social media links',
    description:
      'Your social media links can be found on the “about” page of your app. Configure these to point your customers to your website and your social media channels.',
    image_alt: 'Where to find social links',
  },
  tiers: {
    title: 'Tiers',
    description:
      'Your tiers assets allow you to customize the look of your tier program. Customers will see their progress toward the next tier on the rewards page of the app. They will also receive notification emails when they’ve reached a new tier.<br/><br/>Need to update your tiers? <a href="mailto:merchant.support@thanx.com" target="_blank">Contact us</a>',
    tier_bronze: 'Bronze',
    tier_silver: 'Silver',
    tier_gold: 'Gold',
    background_image: 'Background image',
    background_size: '1076 x 750 px',
    icon: 'Icon',
    icon_size: '110 x 110 px',
    color: 'Color',
    perks_description: 'Perks description',
    requirements: 'Earn requirements',
    jpg: 'jpg',
    png: 'png',
    additional_assets: 'Additional assets',
    status_bar: 'Status bar',
    status_size: '650 x 34 px',
    status_description:
      'Shows how far the user has progressed in their tiers journey.',
    progress_indicator: 'Progress indicator',
    progress_size: '72 x 72 px',
    progress_description:
      "Marks the user's current tiers status on the status bar.",
    not_enabled_page: {
      title: 'Tiers',
      description:
        'You can create stronger brand affinity by making guests feel special. Offer your customers the opportunity for experiential incentives that go beyond what can be bought.<br/><br/>A tiered loyalty program is a type of membership program where customers enjoy permanent benefits based upon their annual spend. Tiers programs reward regular customers who have a long lifetime engagement with the goal of increasing their spend.',
      contact_our_team: 'Contact our team to turn on Tiers',
      image_alt: 'Example of Tiers for native app',
    },
  },
  launch_screens: {
    title: 'Launch screens',
    description:
      'Your launch screen displays momentarily while your app loads. It must include the "Powered by Thanx" logo. <a href="https://duisjpf052nxj.cloudfront.net/assets/mx/PoweredByThanx_logo.zip">Download logo</a><br/><br/>Need to update your launch screens? <a href="mailto:merchant.support@thanx.com">Contact us</a>',
    size_320: '200 x 320 px',
    size_800: '480 x 800 px',
    size_960: '640 x 960 px',
    size_1136: '640 x 1136 px',
    size_1334: '750 x 1334 px',
    size_2208: '1242 x 2208 px',
    size_2436: '1125 x 2436 px',
    size_2688: '1242 x 2688 px',
    size_2820: '1440 x 2820 px',
    jpg: 'jpg',
  },
  custom_fonts: {
    title: 'Custom fonts',
    description:
      'Use your brand’s fonts to customize your app’s body copy, headlines, labels and button text style.',
    configuring:
      'Configuring custom fonts requires additional development and is not included with every subscription.',
    button: 'Contact our team about changing your app’s fonts',
    info: 'Info',
  },
}
