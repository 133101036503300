import inactiveIntroIcon from 'assets/images/campaigns/intro_inactive.png'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Button } from 'react-bootstrap'
import CampaignDisplay from './CampaignDisplay'
import InactiveContent from './InactiveContent'

const t = buildTranslate('loyalty.intro')

type Props = {
  campaign: Campaign
  inactiveButtonAction: any
}

const IntroProgram: React.FC<Props> = ({ campaign, inactiveButtonAction }) => {
  if (campaign) {
    return (
      <CampaignDisplay
        title={t('title')}
        subtext={t('subtext')}
        campaign={campaign}
      />
    )
  }

  return (
    <InactiveContent
      title={t('title')}
      subtext={t('subtext')}
      actionContent={
        <Button bsStyle="primary" bsSize="large" onClick={inactiveButtonAction}>
          {t('button_text')}
        </Button>
      }
      icon={inactiveIntroIcon}
    />
  )
}

export default IntroProgram
