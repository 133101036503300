import { TextArea } from '@thanx/uikit/textarea'
import { withFormsy } from 'formsy-react'
import { splitByComma } from 'scenes/RedeemManager/helpers'

type PropsT = {
  name: string
  label: string
  value?: string[]
  className?: string
  disabled?: boolean
  setValue: any
}

const ProductInput: React.FC<PropsT> = props => {
  const {
    name,
    label,
    value: selectedIds = [],
    className = '',
    disabled = false,
    setValue,
  } = props

  return (
    <div>
      <label id={name} className="body-text-4 bold">
        {label}
      </label>
      <TextArea
        className={className}
        aria-labelledby={name}
        name={name}
        value={selectedIds}
        disabled={disabled}
        onChange={newVal => setValue(splitByComma(newVal))}
      />
    </div>
  )
}

export default withFormsy(ProductInput)
