import filter from 'lodash/filter'
import React, { useEffect } from 'react'
import { LocationT } from './index'

type MapT = {
  className?: string
  center: google.maps.LatLngLiteral | null
  locations: LocationT[]
}

export const Map: React.FC<MapT> = ({
  children,
  locations,
  center,
  className,
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const [map, setMap] = React.useState<google.maps.Map>()
  useEffect(() => {
    if (map && locations.length > 0) {
      const bounds = () => {
        let bounds = new google.maps.LatLngBounds()
        let filteredLocations = locations
        if (center) {
          bounds.extend(center)

          filteredLocations = filter(locations, (location: LocationT) => {
            return location.selected || location.promoted
          })
        }
        filteredLocations.forEach(location => {
          bounds.extend(location.position)
        })
        return bounds
      }
      map.fitBounds(bounds())
    }
  }, [center, locations, map])

  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center,
        })
      )
    }
  }, [center, ref, map])

  return (
    <>
      <div className={className} ref={ref} />
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map })
        }
      })}
    </>
  )
}

export default Map
