import { Button } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import birthdayIcon from 'assets/images/campaigns/birthday.png'
import vipIcon from 'assets/images/campaigns/vip.png'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type PropsT = {
  show: boolean
  onClose: VoidFunction
  type: string
}

const ContactModal: React.FC<PropsT> = (props: PropsT) => {
  const { show, onClose, type } = props
  return (
    <Modal isOpen={show} onClose={onClose} size="auto" zIndex={1200}>
      <div
        className="fs-unmask text-center w-100 p-xl"
        style={{ width: '600px' }}
      >
        <h3 className="text-center mb-xl w-75 center-block">
          {I18n.t(`thanx_campaigns.opportunity.contact_modal.title.${type}`)}
        </h3>
        <img src={type === 'birthday' ? birthdayIcon : vipIcon} alt={type} />
        <p className="body-text-2 text-center w-75 center-block grey-60 mt-l mb-xl">
          {I18n.t('thanx_campaigns.opportunity.contact_modal.secondary')}
        </p>
        <Button
          onClick={() =>
            window.open('mailto:merchant.support@thanx.com', '_blank')
          }
        >
          {I18n.t('thanx_campaigns.opportunity.contact_modal.contact')}
        </Button>
      </div>
    </Modal>
  )
}

export default ContactModal
