import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import { MinimalVariant } from 'models/Campaign'
import React, { useState } from 'react'
import WinnerModal from 'scenes/CampaignCenter/Report/v3/Test/components/WinnerModal'

const t = buildTranslate('thanx_campaigns.report.v3.test')

type Props = {
  campaignId: number
  variants: MinimalVariant[]
}
const TestActive: React.FC<Props> = ({ campaignId, variants }) => {
  const [showModal, setShowModal] = useState(false)
  const [css] = useStyletron()
  return (
    <>
      <Text.BodyText4 color="primary" bold>
        {t('test_active')}
      </Text.BodyText4>
      <Text.BodyText4 color="primary" tag="div">
        {t('active_explain_1')}
      </Text.BodyText4>
      <Text.BodyText4 color="primary" tag="div">
        {t('active_explain_2')}
      </Text.BodyText4>
      <Text.BodyText4 color="primary" tag="div" className="mb-m">
        <a
          className={css({
            textDecoration: 'underline',
          })}
          href="http://help.thanx.com/en/articles/5644455-a-b-tests"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('learn_more')}
        </a>
      </Text.BodyText4>
      <div className="d-flex justify-content-end">
        <Button onClick={() => setShowModal(true)}>{t('end')}</Button>
      </div>
      <WinnerModal
        campaignId={campaignId}
        variants={variants}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}

export default TestActive
