import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import RewardPreviewCard from 'components/RewardPreviewCard'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { getRedeemTemplateType } from 'scenes/RedeemManager/helpers'
import { useFormModel } from '../../FormModel'
import { Card } from '../index'
import PreviewCard from './PreviewCard'

type Props = {
  currentCard: Card
  isExperience: boolean
  templateId: number
  pointsExperience?: PointsExperience | null
}

const t = buildTranslate('redeem_manager.builder.preview')

const Preview: React.FC<Props> = ({
  currentCard,
  isExperience,
  templateId,
  pointsExperience,
}) => {
  const {
    description,
    typeItem,
    availableOnline,
    availableInstore,
    availableCustomerSupport,
    discount,
    discountedProductIds,
    requiredProductIds,
    instoreRedemptionType,
    numberOfPoints,
  } = useFormModel().model
  const templateType = typeItem
    ? getRedeemTemplateType(typeItem, instoreRedemptionType)
    : null

  const onlineRedemptionMessages = [
    t(availableOnline ? 'online_available' : 'online_not_available'),
  ]
  if (availableOnline) {
    if (requiredProductIds.length > 0) {
      onlineRedemptionMessages.push(
        t('must_buy', {
          count: requiredProductIds.length,
        })
      )
    }

    if (discountedProductIds.length > 0) {
      onlineRedemptionMessages.push(
        t('limited_to', {
          count: discountedProductIds.length,
        })
      )
    }
  }

  const [css] = useStyletron()
  const isBonusPoints = templateType === 'points/static'

  return (
    <div
      className={`${css({
        top: '146px',
        maxWidth: '332px',
      })} position-sticky ml-auto`}
    >
      <p className="small-caps-3 bold">{t('title')}</p>
      <RewardPreviewCard description={description} templateId={templateId} />
      <p className="small-caps-3 bold">{t('summary')}</p>
      <div className="card overflow-hidden">
        <Switch
          condition={!isBonusPoints}
          fallback={
            <PreviewCard
              title={t('number_of_bonus_points')}
              isConfigured={Number(numberOfPoints) > 0}
              isDisabled={false}
              messages={[
                I18n.t(`redeem_manager.types.${typeItem?.key}.preview`, {
                  points: numberOfPoints,
                  currencyName: pointsExperience?.currency_name_plural,
                }),
              ]}
            />
          }
        >
          <Switch
            condition={templateType === 'manual/item' || isExperience}
            fallback={
              <PreviewCard
                title={t('discount')}
                isConfigured={Number(discount) > 0}
                isDisabled={false}
                messages={[
                  I18n.t(`redeem_manager.types.${typeItem?.key}.preview`, {
                    discount,
                  }),
                ]}
              />
            }
          >
            <PreviewCard
              title={t('reward_type')}
              isConfigured
              isDisabled={false}
              messages={[
                I18n.t(`redeem_manager.types.${typeItem?.key}.preview`),
              ]}
            />
          </Switch>
        </Switch>
        <Switch condition={!isBonusPoints}>
          <PreviewCard
            title={t('online_redemption')}
            isConfigured={currentCard >= Card.ONLINE_REDEMPTION}
            isDisabled={!availableOnline}
            messages={onlineRedemptionMessages}
          />
          <PreviewCard
            title={t('instore_redemption')}
            isConfigured={currentCard >= Card.INSTORE_REDEMPTION}
            isDisabled={!availableInstore}
            messages={[
              t(
                availableInstore ? 'instore_available' : 'instore_not_available'
              ),
            ]}
          />
        </Switch>
        <PreviewCard
          title={t('customer_support')}
          isConfigured={currentCard >= Card.CUSTOMER_SUPPORT || isBonusPoints}
          isDisabled={!availableCustomerSupport}
          messages={[
            t(
              availableCustomerSupport
                ? 'support_available'
                : 'support_not_available'
            ),
          ]}
        />
      </div>
    </div>
  )
}

export default Preview
