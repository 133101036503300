import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import Review from 'scenes/CampaignCenter/Builder/Steps/Shared/Review'
import AudienceDisabled from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Audience/components/AudienceDisabled'

type Props = {
  campaign: Campaign
  setCurrentStep: (step: string) => void
}

const WinbackReview: React.FC<Props> = props => {
  const { campaign, setCurrentStep } = props
  return (
    <Review
      campaign={campaign}
      setCurrentStep={setCurrentStep}
      audience={
        <AudienceDisabled
          text={I18n.t('thanx_campaigns.review.customers.disabled.winback')}
        />
      }
    />
  )
}

export default WinbackReview
