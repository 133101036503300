import React from 'react'
import { numberFormatter } from 'utilities/formatters'
import BucketTable from './BucketTable'
import MetricSummary from './MetricSummary'
import type { Direction } from './TrajectoryArrow'

export type Props = {
  title: string
  summaryText: string | React.ReactNode
  bucketText: string
  keySuffix: string
  metric: Object
  valueFormatter?: (value: number) => string
  trajectoryCalculator?: (Object, string) => Direction
}

const BucketMetric: React.FC<Props> = props => {
  const {
    title,
    summaryText,
    bucketText,
    keySuffix,
    metric,
    valueFormatter,
    trajectoryCalculator,
  } = props
  const totalValue = metric[`total_${keySuffix}`]

  return (
    <div>
      <MetricSummary
        title={title}
        summaryValue={valueFormatter ? valueFormatter(totalValue) : undefined}
        summaryText={summaryText}
      />
      <div>
        <span className="bold">{bucketText}:</span>
        <BucketTable
          keySuffix={keySuffix}
          metrics={[{ data: metric }]}
          valueFormatter={valueFormatter}
          trajectoryCalculator={trajectoryCalculator}
        />
      </div>
    </div>
  )
}

BucketMetric.defaultProps = {
  valueFormatter: numberFormatter,
}

export default BucketMetric
