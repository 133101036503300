import { Notification } from '@thanx/uikit/notification'
import hollowInfo from 'assets/images/hollowInfo.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import CmsPage from '../components/CmsPage'

const t = buildTranslate('cms.brand.custom_fonts')

const CustomFonts: React.FC = () => {
  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      contactButtonTitle={t('button')}
    >
      <div className="mb-l">
        <Notification kind="info" size="full">
          <div className="d-flex">
            <img
              className="mr-xs align-self-start"
              src={hollowInfo}
              alt={t('info')}
            />
            <span className="body-text-4 pt-xxs">{t('configuring')}</span>
          </div>
        </Notification>
      </div>
    </CmsPage>
  )
}

export default CustomFonts
