import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { IosAppCategories } from 'models/App'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import Header from '../../components/Header'
import MetadataCell from './MetadataCell'

const t = buildTranslate('cms.brand.app_store_assets')

const Metadata: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const {
    listing_name,
    icon_name,
    short_description,
    full_description,
    keywords,
    marketing_url,
    ios_primary_category,
    ios_secondary_category,
    android_category,
  } = app

  return (
    <Section className="p-xl mt-l">
      <Header
        title={t('metadata.title')}
        description={t('metadata.description')}
      />
      <table className="full-width campaign-table mt-m">
        <tbody>
          <MetadataCell
            label={t('metadata.listing_name')}
            value={listing_name}
          />
          <MetadataCell label={t('metadata.icon_name')} value={icon_name} />
          <MetadataCell
            label={t('metadata.short_description')}
            value={short_description}
          />
          <MetadataCell
            label={t('metadata.full_description')}
            value={full_description}
          />
          <MetadataCell label={t('metadata.keywords')} value={keywords} />
          <MetadataCell
            label={t('metadata.marketing_url')}
            value={marketing_url}
          />
          <MetadataCell
            label={t('metadata.ios_primary_category')}
            value={
              ios_primary_category &&
              (IosAppCategories[ios_primary_category] || ios_primary_category)
            }
          />
          <MetadataCell
            label={t('metadata.ios_secondary_category')}
            value={
              ios_secondary_category &&
              (IosAppCategories[ios_secondary_category] ||
                ios_secondary_category)
            }
          />
          <MetadataCell
            label={t('metadata.android_category')}
            value={android_category}
          />
        </tbody>
      </table>
    </Section>
  )
}

export default Metadata
