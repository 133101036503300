import orm from 'models/orm'
import { Fields, RedeemTemplateState } from 'models/RedeemTemplate'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectRedeemTemplates = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.RedeemTemplate.all().toRefArray()
  }
)

export const selectRedeemTemplate = createSelector(
  selectRedeemTemplates,
  (_, id: number | null): number | null => id,
  (redeemTemplates, id): Fields | null => {
    return redeemTemplates.find(t => t.id === id) ?? null
  }
)

export const selectRedeemTemplatesByState = createSelector(
  selectRedeemTemplates,
  (_, templateState): RedeemTemplateState => templateState,
  (redeemTemplates, templateState): Fields[] => {
    return redeemTemplates.filter(t => {
      return t.state === templateState
    })
  }
)
