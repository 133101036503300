import { Text } from '@thanx/uikit/text'
import AudienceIcon from 'assets/images/campaign-center/builder/img_audience.svg'
import ReviewSection from 'components/review/ReviewSection'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  isEditable?: boolean
  onEdit: VoidFunction
}

const t = buildTranslate('thanx_campaigns.review.customers')

const EmptyAudience: React.FC<Props> = props => {
  const { isEditable = true, onEdit } = props

  return (
    <ReviewSection
      icon={AudienceIcon}
      isEditable={isEditable}
      onEdit={onEdit}
      isEmpty
      type="customers"
    >
      <Text.Header4 tag="div" className="mb-xs mt-xxs">
        {t('title')}
      </Text.Header4>
      <Text.BodyText4>{t('select')}</Text.BodyText4>
    </ReviewSection>
  )
}

export default EmptyAudience
