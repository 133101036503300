import { useStyletron } from '@thanx/uikit/theme'
import blocksPreview from 'assets/images/cms/fixed-buttons.png'
import { buildTranslate } from 'locales'
import React from 'react'
import PublishButton from '../PublishButton'

type Props = {
  isLoading: boolean
}

const t = buildTranslate('cms.content.fixed_buttons')

const Sidebar: React.FC<Props> = ({ isLoading }) => {
  const [css] = useStyletron()

  return (
    <>
      <PublishButton isLoading={isLoading} publishText={t('publish')} />
      <img
        className={`mb-xl ${css({
          width: '200px',
          height: '397px',
        })}`}
        src={blocksPreview}
        alt={t('locations_preview')}
      />
    </>
  )
}

export default Sidebar
