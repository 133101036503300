import { Fields as GrantableCampaign } from 'models/GrantableCampaign'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_GRANTABLE_CAMPAIGNS = 'users/GET_GRANTABLE_CAMPAIGNS'
export const GET_GRANTABLE_CAMPAIGNS_SUCCESS =
  'users/GET_GRANTABLE_CAMPAIGNS_SUCCESS'
export const GET_GRANTABLE_CAMPAIGNS_FAIL = 'users/GET_GRANTABLE_CAMPAIGNS_FAIL'

export type GetGrantableCampaignsAction = AxiosAction<
  typeof GET_GRANTABLE_CAMPAIGNS,
  { campaigns: GrantableCampaign[] }
>

export function getGrantableCampaigns(): GetGrantableCampaignsAction {
  return {
    type: GET_GRANTABLE_CAMPAIGNS,
    payload: {
      client: 'default',
      request: {
        url: 'campaigns/grantable',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getGrantableCampaigns>
>
