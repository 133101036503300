import { Popover } from '@thanx/uikit/popover'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import times from 'lodash/times'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap-five'

type OptionProps = {
  children: React.ReactNode
  onClick?: () => void
}

export const PopoverOption: React.FC<OptionProps> = ({
  children,
  onClick = () => null,
}) => {
  return (
    <Button
      className="d-flex grey-70 pr-m font-size-14 belize-hole-10-hover-bkg grey-70-hover border-radius-0 width-100 no-underline"
      variant="link"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

type MenuProps = {
  children: React.ReactNode
  dismissOnClickOutside?: boolean
  closeOnClick?: boolean
  placement?:
    | 'bottom'
    | 'auto'
    | 'topLeft'
    | 'top'
    | 'topRight'
    | 'rightTop'
    | 'right'
    | 'rightBottom'
    | 'bottomRight'
    | 'bottomLeft'
    | 'leftBottom'
    | 'left'
    | 'leftTop'
    | undefined
  testId?: string
}

const PopoverMenu: React.FC<MenuProps> = ({
  children,
  dismissOnClickOutside = false,
  closeOnClick = false,
  placement = 'bottom',
  testId,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [css] = useStyletron()

  const content = ({ close }) => {
    return React.Children.map(children, child => (
      <span onClick={close}>{child}</span>
    ))
  }

  return (
    <Popover
      triggerType="click"
      placement={placement}
      content={closeOnClick ? content : children}
      showArrow={false}
      dismissOnClickOutside={dismissOnClickOutside}
      overrides={{
        Inner: {
          style: {
            paddingTop: '8px',
            paddingRight: '0',
            paddingBottom: '8px',
            paddingLeft: '0',
            backgroundColor: defaultTheme.colors.white,
            border: `1px solid ${defaultTheme.colors.grey30}`,
            borderRadius: '4px',
            boxShadow: '0px 4px 8px rgba(54, 66, 78, 0.3)',
          },
        },
      }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <div
        className={`d-flex px-xs py-s grey-10-hover-bkg border-radius-5 cursor-pointer ${
          isOpen ? 'grey-10-bkg' : ''
        }`}
        role="button"
        data-testid={testId}
      >
        {times(3, i => (
          <div
            key={i}
            className={`${css({
              width: '4px',
              height: '4px',
              borderRadius: '4px',
              marginRight: '2px',
              marginLeft: '2px',
            })} grey-70-bkg`}
          />
        ))}
      </div>
    </Popover>
  )
}

export default PopoverMenu
