import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { alert } from 'actions/flash'
import { rewardsApi } from 'api/rewards'
import { isError } from 'api/utils'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import { Fields as UserReward } from 'models/UserReward'
import React, { useState } from 'react'
import ConfirmActionModal, { ActionType } from './ConfirmActionModal'
import { useRewardsTable } from './hooks'
import Table from './Table'

const t = buildTranslate('users.view.activity.rewards')

type PropsT = {
  profile: UserProfile
}

const Rewards: React.FC<PropsT> = ({ profile }: PropsT) => {
  const dispatch = useDispatch()
  const {
    refetchCurrentPage,
    isFetchingRewards,
    currentPage,
    numPages,
    onChangePage,
    rewards,
  } = useRewardsTable(profile)
  const [actionModalType, setActionModalType] = useState<ActionType | null>(
    null
  )
  const [selectedReward, setSelectedReward] = useState<UserReward | null>(null)
  const [retireReward, { isLoading: isRetiringReward }] =
    rewardsApi.useRetireMutation()
  const [reissueReward, { isLoading: isReissuingReward }] =
    rewardsApi.useReissueMutation()

  function onPressRetire(reward: UserReward) {
    setActionModalType('retire')
    setSelectedReward(reward)
  }

  function onPressReissue(reward: UserReward) {
    setActionModalType('reissue')
    setSelectedReward(reward)
  }

  function hideActionModal() {
    setSelectedReward(null)
    setActionModalType(null)
  }

  function onConfirmAction() {
    if (!selectedReward) return

    switch (actionModalType) {
      case 'retire': {
        handleRetireReward(selectedReward)
        return
      }
      case 'reissue': {
        handleReissueReward(selectedReward)
        return
      }
    }
  }

  async function handleRetireReward(reward: UserReward) {
    const result = await retireReward({
      userUid: profile.uid,
      rewardId: reward.id,
    })
    handleUpdateRewardResult(result, 'reissue')
  }

  async function handleReissueReward(reward: UserReward) {
    const result = await reissueReward({
      userUid: profile.uid,
      rewardId: reward.id,
    })

    handleUpdateRewardResult(result, 'reissue')
  }

  function handleUpdateRewardResult(result, actionType: ActionType) {
    if (isError(result)) {
      dispatch(
        alert({
          key: 'danger',
          message: t(`action_modal.${actionType}.failure`),
          timeout: 3,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t(`action_modal.${actionType}.success`),
        timeout: 3,
      })
    )
    refetchCurrentPage()
    hideActionModal()
  }

  return (
    <div>
      <Text.Header4 className={'mb-m'}>{t('title')}</Text.Header4>
      <Spinner
        className={'d-flex justify-content-center'}
        size={'3x'}
        isLoading={isFetchingRewards}
      >
        <Switch
          condition={rewards.length > 0}
          fallback={
            <Text.BodyText3 color="grey70">{t('empty_state')}</Text.BodyText3>
          }
        >
          <Table
            rewards={rewards}
            onPressRetire={onPressRetire}
            onPressReissue={onPressReissue}
          />
          <div className={'d-flex justify-content-center margin-top-huge'}>
            <Pagination
              currentPage={currentPage}
              onChangePage={onChangePage}
              numPages={numPages}
            />
          </div>
        </Switch>
      </Spinner>
      <ConfirmActionModal
        reward={selectedReward}
        actionType={actionModalType}
        onConfirm={onConfirmAction}
        onCancel={hideActionModal}
        isLoading={isRetiringReward || isReissuingReward}
      />
    </div>
  )
}

export default Rewards
