import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'

const t = buildTranslate('components.side_nav')

type Props = {
  items: string[]
  current: string
  all?: string
  onClick: (item: string) => void
}

const Item = ({ onClick, value, active }) => {
  const [css] = useStyletron()

  return (
    <button
      type="button"
      onClick={onClick}
      className={`p-m text-left border border-0 border-radius-5 ${css({
        background: active ? merchantTheme.colors.belizeHole10 : 'transparent',
        color: active
          ? merchantTheme.colors.grey90
          : merchantTheme.colors.grey70,
        fontWeight: active ? 'bold' : 'normal',
      })}`}
    >
      {value || t('other')}
    </button>
  )
}

const SideNav: React.FC<Props> = ({ items, current, all, onClick }) => {
  return (
    <div className="d-flex flex-column">
      <Switch condition={!!all}>
        <Item
          onClick={() => onClick('all')}
          value={all}
          active={current === 'all'}
        />
      </Switch>
      {items.map(item => (
        <Item
          key={item}
          onClick={() => onClick(item)}
          value={item}
          active={current === item}
        />
      ))}
    </div>
  )
}

export default SideNav
