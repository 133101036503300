import { Switch } from '@thanx/uikit/switch'
import React from 'react'
import Alert from 'react-bootstrap-five/Alert'
import { Translate } from 'react-redux-i18n'

type PropsT = {
  display: boolean
}

const RestrictionWarning: React.FC<PropsT> = props => {
  const { display } = props
  return (
    <Switch condition={display}>
      <Alert variant="info" className="p-m mt-m">
        <div className="d-flex flex-align-center">
          <i className="fa fa-info-circle font-size-22 mr-xs" />
          <Translate
            value={
              'thanx_campaigns.builder.steps.incentive.template_change_warning'
            }
          />
        </div>
      </Alert>
    </Switch>
  )
}

export default RestrictionWarning
