import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import Body3Html from './common/Body3Html'
import LegalContainer from './common/LegalContainer'
import List from './common/List'

const t = buildTranslate('legal.exclusive_deals_terms')

function ExclusiveDealsTerms() {
  return (
    <LegalContainer>
      <Text.Header2>{t('title')}</Text.Header2>
      <Body3Html tag="p">{t('intro')}</Body3Html>

      <Text.Header3>1. {t('changes.title')}</Text.Header3>
      <Body3Html tag="p">{t('changes.content')}</Body3Html>

      <Text.Header3>2. {t('payment_processing.title')}</Text.Header3>
      <Body3Html tag="p">{t('payment_processing.content')}</Body3Html>

      <Text.Header3>3. {t('functionality.title')}</Text.Header3>
      <List prefix="3." items={Object.values(t('functionality.items'))} />

      <Text.Header3>4. {t('representations.title')}</Text.Header3>
      <List prefix="4." items={Object.values(t('representations.items'))} />

      <Text.Header3>5. {t('taxes.title')}</Text.Header3>
      <List prefix="5." items={Object.values(t('taxes.items'))} />

      <Text.Header3>6. {t('disclaimers.title')}</Text.Header3>
      <Body3Html tag="p">{t('disclaimers.content')}</Body3Html>

      <Text.Header3>7. {t('indemnification.title')}</Text.Header3>
      <Body3Html tag="p">{t('indemnification.content')}</Body3Html>

      <Text.Header3>8. {t('liability.title')}</Text.Header3>
      <Body3Html tag="p">{t('liability.content')}</Body3Html>
    </LegalContainer>
  )
}

export default ExclusiveDealsTerms
