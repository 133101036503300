import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import home from 'assets/images/cms/home.png'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.signup.onboarding_slideshow.content.footer')

const Footer: React.FC = () => {
  const [css] = useStyletron()

  return (
    <div className={'grey-10-bkg p-m mt-m'}>
      <div className={'d-flex'}>
        <div className={'mr-s'}>
          <img
            alt={t('home')}
            className={css({
              height: '18px',
            })}
            src={home}
          />
        </div>
        <div>
          <Text.BodyText5
            className={`line-height-10 font-weight-bold ${css({
              letterSpacing: '3px',
              fontSize: '10px',
            })}`}
            color="grey90"
          >
            {t('title')}
          </Text.BodyText5>
          <Text.BodyText4 className={'mb-s mt-s'} tag={'p'} color="grey70">
            {t('subtitle')}
          </Text.BodyText4>
          <Text.BodyText4 color="grey70">{t('description')}</Text.BodyText4>
        </div>
      </div>
    </div>
  )
}

export default Footer
