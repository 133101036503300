import { Button } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type PropsT = {
  title: string
  onEdit: () => void
}

const Edit: React.FC<PropsT> = ({ title, onEdit }) => {
  const [css] = useStyletron()

  return (
    <Button
      className={css({
        height: '32px',
        fontSize: '14px',
        marginLeft: '16px',
      })}
      kind="secondary"
      onClick={onEdit}
    >
      {title}
    </Button>
  )
}

export default Edit
