import Input from 'components/Form/Input'
import useFlag from 'hooks/useFlag'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { selectCampaignTypeByType } from 'selectors/campaignType'

type Props = {
  campaign: Campaign
}

const Sms: React.FC<Props> = ({ campaign }) => {
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const { config } = useCampaignConfig()

  const msgRequired = useFlag('mx-allow-no-reward-messages', false)

  const campaignPlaceholders = campaignType?.placeholders
  return (
    <div>
      <Input
        label={I18n.t('thanx_campaigns.builder.forms.sms.text')}
        value={config.notify_sms_text || ''}
        name="notify_sms_text"
        placeholder={campaignPlaceholders?.notify_sms_text}
        validations={{
          maxLength: 220,
          noEmojis: true,
          isRequired: msgRequired,
        }}
        validationErrors={{
          maxLength: I18n.t('validations.max_length', { maxLength: 220 }),
          noEmojis: I18n.t('validations.no_emojis'),
          isRequired: I18n.t('validations.is_required'),
        }}
        componentClass="textarea"
        helpBlock={
          <em className="font-size-12 grey-50 block margin-top-tiny">
            {I18n.t('thanx_campaigns.builder.forms.sms.help_text')}
          </em>
        }
      />
    </div>
  )
}
export default Sms
