import { buildTranslate } from 'locales'
import { SpecialOcassionTag } from 'models/UserProfile'
import React from 'react'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'

const t = buildTranslate('users.view.profile.custom_tags')

type PropsT = {
  tag: SpecialOcassionTag
}

function getValues(tag: SpecialOcassionTag) {
  const day = tag.day > 9 ? tag.day : `0${tag.day}`
  const month = tag.month > 9 ? tag.month : `0${tag.month}`
  return [
    tag.occasion,
    `${month}/${day}`,
    `${t('whos_celebrating')}: ${tag.name}`,
  ]
}

const SpecialOcassionContent: React.FC<PropsT> = ({ tag }) => {
  return (
    <BodyCell
      isErrored={false}
      isLoading={false}
      values={getValues(tag).map(t => ({ value: t }))}
    />
  )
}

export default SpecialOcassionContent
