import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { getPurchaseDetails, ResolvedAction } from 'actions/orders'
import Modal from 'components/ModalContainer'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { OrderDetails } from 'models/Order'
import { Fields as UserItem } from 'models/UserItem'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import OrderDetailsModal from '../OrderDetailsModal'
import { useItemsTable } from './hooks'
import ItemDetails from './ItemDetails'
import Table from './Table'

const t = buildTranslate('users.view.activity.purchased_items')

type PropsT = {
  profile: UserProfile
}

const ItemsPurchased: React.FC<PropsT> = ({ profile }: PropsT) => {
  const { isFetchingItems, items, currentPage, numPages, onChangePage } =
    useItemsTable(profile)
  const [selectedItem, setSelectedItem] = useState<UserItem>()
  const [selectedOrder, setSelectedOrder] = useState<OrderDetails>()
  const dispatch = useDispatch()

  function handleDetailsClick(item: UserItem) {
    setSelectedItem(item)
  }

  async function handleOrderDetailsClick(orderId: number) {
    const result = (await dispatch(
      getPurchaseDetails(orderId)
    )) as unknown as ResolvedAction
    if (result.error) {
      return
    }
    setSelectedItem(undefined)
    setSelectedOrder(result.payload.data.purchase)
  }

  return (
    <div>
      <div className="d-flex justify-content-between">
        <Text.Header4 className={'mb-m'}>{t('title')}</Text.Header4>
      </div>
      <Spinner
        className="d-flex justify-content-center"
        size="3x"
        isLoading={isFetchingItems}
      >
        <Switch
          condition={items.length > 0}
          fallback={
            <Text.BodyText3 color="grey70">{t('empty_state')}</Text.BodyText3>
          }
        >
          <Table items={items} onDetailsClick={handleDetailsClick} />
          <div className={'d-flex justify-content-center margin-top-huge'}>
            <Pagination
              currentPage={currentPage}
              onChangePage={onChangePage}
              numPages={numPages}
            />
          </div>
        </Switch>
      </Spinner>
      {!!selectedItem && (
        <Modal
          isOpen
          title={selectedItem.label}
          subtitle={t('purchased_times', {
            count: selectedItem.purchase_count,
            by: `${profile.first_name} ${profile.last_name}`,
          })}
          proceedText={t('okay')}
          className="fs-unmask"
          hideCancel
          zIndex={900}
          onClose={() => setSelectedItem(undefined)}
          onProceed={() => setSelectedItem(undefined)}
        >
          <ItemDetails
            profile={profile}
            item={selectedItem}
            onOrderDetailsClick={handleOrderDetailsClick}
          />
        </Modal>
      )}
      {!!selectedOrder && (
        <OrderDetailsModal
          show
          order={selectedOrder}
          onHide={() => setSelectedOrder(undefined)}
        />
      )}
    </div>
  )
}

export default ItemsPurchased
