import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import { Fields as UserProfile } from 'models/UserProfile'
import React from 'react'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { merchantTheme } from 'theme'
import { useUserTimeZone } from 'utilities/userTimeZone'
import { formatDate } from '../../helpers'

type Props = {
  pointsProvision: UserProfile['points_provisions'][number]
}

const Row = ({ pointsProvision }: Props) => {
  const [css] = useStyletron()

  const { amount, created_at, claimed_at } = pointsProvision
  const { colors } = merchantTheme
  const timezone = useUserTimeZone()

  return (
    <tr>
      <BodyCellContainer>
        <Text.BodyText4>{Number(amount)}</Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer>
        <Text.BodyText4>{formatDate(timezone, created_at)?.day}</Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer>
        <Text.BodyText4>{formatDate(timezone, claimed_at)?.day}</Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer>
        <Badge
          value={claimed_at ? 'Issued' : 'Pending'}
          className={`ml-xs ${css({
            backgroundColor: claimed_at ? colors.nephritis20 : colors.grey20,
            color: claimed_at ? colors.nephritis50 : colors.grey70,
          })}`}
        />
      </BodyCellContainer>
    </tr>
  )
}

export default Row
