import React from 'react'
import { numberFormatter } from 'utilities/formatters'
import { avgDayTrajectory } from '../utilities'
import BucketTable from './BucketTable'
import type { Direction } from './TrajectoryArrow'

type MetricT = {
  data: Object
  header?: string
}

export type Props = {
  title: string
  summaryText: string
  bucketText: string
  keySuffix: string
  metrics: MetricT[]
  valueFormatter?: (value: number) => string
  trajectoryCalculator?: (Object, string) => Direction
}

const BucketMultiMetric: React.FC<Props> = props => {
  const {
    title,
    summaryText,
    bucketText,
    keySuffix,
    metrics,
    valueFormatter,
    trajectoryCalculator,
  } = props
  return (
    <div>
      <div className="small-caps-5 margin-bottom-large">
        <span>{title}</span>
      </div>
      <div>
        <BucketTable
          keySuffix={keySuffix}
          metrics={metrics}
          valueFormatter={valueFormatter}
          includeHeaders={true}
          includeSummary={true}
          summaryText={summaryText}
          bucketText={bucketText}
          trajectoryCalculator={trajectoryCalculator}
        />
      </div>
    </div>
  )
}

BucketMultiMetric.defaultProps = {
  valueFormatter: numberFormatter,
  trajectoryCalculator: avgDayTrajectory,
}

export default BucketMultiMetric
