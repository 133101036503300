import { Switch } from '@thanx/uikit/switch'
import EditButton from 'components/review/EditButton'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { useVariant } from 'scenes/CampaignCenter/Builder/components/VariantContext'
import MessageContent from './MessageContent'
import NoMessage from './NoMessage'

type Props = {
  campaign: Campaign
  isEditable: boolean
  isControlVariant?: boolean
  setCurrentStep?: (step: string) => void
}

const Messaging: React.FC<Props> = ({
  campaign,
  isEditable,
  isControlVariant = false,
  setCurrentStep,
}) => {
  const variant = useVariant()
  const { config } = useCampaignConfig()
  const variantConfig = variant || config

  const onClick = () => {
    if (isEditable) {
      setCurrentStep && setCurrentStep('messaging')
    }
  }

  return (
    <Switch
      condition={
        !isControlVariant &&
        (variantConfig.notify_email ||
          variantConfig.notify_sms ||
          variantConfig.notify_push)
      }
      fallback={
        <NoMessage
          isEditable={!isControlVariant && isEditable}
          onAdd={onClick}
        />
      }
    >
      <Row className="section">
        <Col className="col-content">
          <MessageContent campaign={campaign} />
        </Col>
        <Col sm={1} className="text-right">
          <Switch condition={isEditable}>
            <EditButton onClick={onClick} />
          </Switch>
        </Col>
      </Row>
    </Switch>
  )
}

export default Messaging
