import { Switch } from '@thanx/uikit/switch'
import InfoBox from 'components/InfoBox'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import { getLetter, sortVariants } from 'models/CampaignVariant/helpers'
import React from 'react'
import AddVariant from './AddVariant'
import Section from './components/Section'

type Props = {
  campaign: Campaign
  setCurrentStep?: (step: string) => void
  isOpen: boolean
  setIsOpen?: (isOpen) => void
}

const t = buildTranslate('thanx_campaigns.review.content')

export const formatSections = ({
  campaign,
  variants,
  variantsLength,
  shouldShowKebab = true,
}) => {
  if (variantsLength > 0) {
    return variants.sort(sortVariants).map((variant, i) => {
      let title
      let subtitle
      let isEditable = false
      const percent = Math.round((1 / variantsLength) * 100)
      const letter = variant.meta?.letter || getLetter(variant.internal_type, i)
      if (variantsLength === 1) {
        title = t('title')
      } else {
        isEditable = true
        subtitle = t('percent_audience', { percent })
      }
      return {
        campaign,
        isEditable,
        letter,
        name: variant.name,
        subtitle,
        title,
        variantId: variant.id,
        key: variant.id,
        showKebab: shouldShowKebab && variantsLength > 1,
        internal_type: variant.internal_type,
      }
    })
  }

  return [
    {
      campaign,
      title: t('title'),
      key: campaign.id,
    },
  ]
}

const ContentContainer: React.FC<Props> = ({
  campaign,
  setCurrentStep,
  isOpen,
  setIsOpen,
}) => {
  const { variants } = campaign
  const variantsLength = variants?.length || 0
  const sections = formatSections({ variants, campaign, variantsLength })

  return (
    <>
      {sections.map(section => (
        <Section
          {...section}
          setCurrentStep={setCurrentStep}
          setIsOpen={setIsOpen}
        />
      ))}
      <AddVariant
        campaign={campaign}
        variantsLength={variantsLength}
        isOpen={isOpen}
        setIsOpen={setIsOpen || (() => null)}
      />
      <Switch
        condition={variantsLength > 1 && campaign.type === 'custom_automated'}
      >
        <InfoBox content={t('stop_variant_tip')} className="pt-l" />
      </Switch>
    </>
  )
}

export default ContentContainer
