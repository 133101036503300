import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import { Link } from 'react-router-dom'

const t = buildTranslate('feedbacks.responses.modal.headers')

type Props = {
  userUid?: string
  userName?: string
  feedbackIcon?: string
  feedbackIconColor?: string
  feedbackText?: string
}

const Header = (props: Props) => {
  const [css] = useStyletron()
  const { userUid, userName, feedbackIcon, feedbackIconColor, feedbackText } =
    props

  return (
    <div className="d-flex flex-row px-xl py-l white-bkg">
      <div className="d-flex flex-1 flex-column">
        <Text.BodyText4 tag="div" bold>
          {t('from')}
        </Text.BodyText4>
        <div>
          <Text.BodyText4 className="mr-xs">{userName}</Text.BodyText4>
          <Link to={`/users/${userUid}`}>
            <Text.BodyText4 tag="span" className="mb-0" color="primary">
              {t('view_profile')}
            </Text.BodyText4>
          </Link>
        </div>
      </div>
      <div className="d-flex flex-row align-self-end align-items-center">
        <i
          className={`mr-xxs fa ${feedbackIcon} ${css({
            color: feedbackIconColor,
          })}`}
        />
        <Text.BodyText4
          className={`${css({
            color: `${feedbackIconColor} !important`,
          })}`}
        >
          {feedbackText}
        </Text.BodyText4>
      </div>
    </div>
  )
}

export default Header
