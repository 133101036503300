import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'

const t = buildTranslate('privacy.previous')

function Previous() {
  return (
    <div className="p-xl pt-xl">
      <Text.Header2>{t('title')}</Text.Header2>
      <Text.BodyText3 className="italic">{t('effective_date')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('policy.header'),
          }}
        />
      </Text.BodyText3>
      {/* Revisions */}
      <Text.Header3>{t('revisions.title')}</Text.Header3>
      <Text.BodyText3>{t('revisions.revisions_one')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('revisions.revisions_two')}</Text.BodyText3>
      {/* Information Collection and Use */}
      <Text.Header3>{t('info.title')}</Text.Header3>
      <Text.BodyText3>{t('info.header')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('info.pii')}</Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('info.voluntary'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('info.collect'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('info.use_pii'),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('info.txn'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('info.related'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('info.party'),
          }}
        />
      </Text.BodyText3>

      {/* Information Collected Using Cookies and other Web Technologies */}
      <Text.Header3>{t('cookies.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('cookies.cookies'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('cookies.becaons'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('cookies.location'),
          }}
        />
      </Text.BodyText3>

      {/* Information Sharing and Disclosure */}
      <Text.Header3>{t('disclose.title')}</Text.Header3>
      <Text.BodyText3>{t('disclose.we')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('disclose.social'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('disclose.network'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('disclose.branded'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('disclose.require'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('disclose.third_parties'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('disclose.services'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('disclose.protect'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('disclose.txn'),
          }}
        />
      </Text.BodyText3>

      {/* Your Choices */}
      <Text.Header3>{t('choices.title')}</Text.Header3>
      <Text.BodyText3>{t('choices.offer')}</Text.BodyText3>
      <br></br>
      <br></br>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('choices.opt'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('choices.delete'),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>

      {/* Security */}
      <Text.Header3>{t('security.title')}</Text.Header3>
      <Text.BodyText3>{t('security.one')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('security.two')}</Text.BodyText3>

      {/* International Transfer */}
      <Text.Header3>{t('transfer.title')}</Text.Header3>
      <Text.BodyText3>{t('transfer.info')}</Text.BodyText3>

      {/* ccpa */}
      <Text.Header3>{t('ccpa.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('ccpa.header'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('ccpa.verify'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('ccpa.request'),
          }}
        />
      </Text.BodyText3>

      {/* Phishing */}
      <Text.Header3>{t('phishing.title')}</Text.Header3>
      <Text.BodyText3>{t('phishing.theft')}</Text.BodyText3>

      {/* Links */}
      <Text.Header3>{t('links.title')}</Text.Header3>
      <Text.BodyText3>{t('links.contains')}</Text.BodyText3>

      {/* Policy towards children */}
      <Text.Header3>{t('children.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('children.directed'),
          }}
        />
      </Text.BodyText3>

      {/* Contacting Us */}
      <Text.Header3>{t('contacting.title')}</Text.Header3>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('contacting.questions'),
          }}
        />
      </Text.BodyText3>

      {/* Footer */}
      <Text.Header3 bold>{t('footer.last_updated')}</Text.Header3>
      <Text.BodyText3>{`© ${t('copyright', {
        year: new Date().getFullYear(),
      })}`}</Text.BodyText3>
    </div>
  )
}

export default Previous
