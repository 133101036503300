import React from 'react'
import styles from './CampaignSection.module.scss'

type Props = {
  title: string
  description: string
  image: string
}

const CampaignSectionImageCard: React.FC<Props> = props => {
  const { title, description, image } = props
  return (
    <div className="full-height padding-bottom-medium padding-top-medium">
      <div
        className={`${styles.imageCard} border-radius-5`}
        style={{ backgroundImage: `url(${image})` }}
      >
        <h4 className="deprecated__h4 white margin-bottom-large">{title}</h4>
        <p className="white body-text-2">{description}</p>
      </div>
    </div>
  )
}

export default CampaignSectionImageCard
