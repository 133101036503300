import { logout } from 'actions/auth'
import Bill from 'components/Icons/Bill'
import IntegrationsIcon from 'components/Icons/Integrations'
import LocationMarker from 'components/Icons/LocationMarker'
import Lock from 'components/Icons/Lock'
import Logout from 'components/Icons/Logout'
import ThanxLogo from 'components/Icons/ThanxLogo'
import UserSupport from 'components/Icons/UserSupport'
import useDispatch from 'hooks/useDispatch'
import useIsThanxAdmin from 'hooks/useIsThanxAdmin'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import React from 'react'
import { useAbility } from 'utilities/ability'
import Divider from '../Bar/Divider'
import NavLink from '../Bar/NavLink'
import MerchantSelector from './MerchantSelector'

const t = buildTranslate('nav.profile_menu')

type Props = {
  merchant: Merchant
  isLeftNav?: boolean
  onClick?: () => void
}

const Body = (props: Props) => {
  const { isLeftNav = false, merchant, onClick = () => null } = props

  const isThanxAdmin = useIsThanxAdmin()
  const ability = useAbility()
  const dispatch = useDispatch()
  const onLogout = () => {
    onClick()
    dispatch(logout())
  }

  return (
    <>
      {isThanxAdmin && (
        <NavLink
          onClick={onClick}
          isLeftNav={isLeftNav}
          text={t('thanx_admin')}
          Icon={ThanxLogo}
          colorName="spearmint10"
          link="/choose"
        />
      )}
      <NavLink
        onClick={onClick}
        isLeftNav={isLeftNav}
        text={t('locations')}
        Icon={LocationMarker}
        link="/locations"
        disabled={ability.cannot('manage', 'MerchantUsers')}
      />
      <NavLink
        onClick={onClick}
        isLeftNav={isLeftNav}
        text={t('integrations')}
        Icon={IntegrationsIcon}
        link="/integrations"
      />

      <NavLink
        onClick={onClick}
        isLeftNav={isLeftNav}
        text={t('user_support')}
        Icon={UserSupport}
        link="/user_support"
        disabled={ability.cannot('manage', 'UserSupport')}
      />
      <NavLink
        onClick={onClick}
        isLeftNav={isLeftNav}
        text={t('billing')}
        Icon={Bill}
        link="/billing"
        disabled={ability.cannot('access', 'Billing')}
      />
      <NavLink
        onClick={onClick}
        isLeftNav={isLeftNav}
        text="Dashboard access"
        Icon={Lock}
        link="/merchant_users"
        disabled={ability.cannot('manage', 'MerchantUsers')}
      />
      {!isThanxAdmin && (
        <MerchantSelector
          currentMerchantId={merchant.id}
          onClick={() => {
            // Empty onClick required for MenuItem logic
          }}
        />
      )}
      <Divider />
      <NavLink
        onClick={onLogout}
        isLeftNav={isLeftNav}
        text={t('log_out')}
        Icon={Logout}
      />
    </>
  )
}

export default Body
