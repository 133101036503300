import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}

const Logout: React.FC<PropsT> = ({
  className = '',
  fillColor = 'currentColor',
}) => (
  <svg
    className={className}
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 13.25C6 13.6875 5.65625 14 5.25 14H3C1.3125 14 0 12.6875 0 11V3C0 1.34375 1.3125 0 3 0H5.25C5.65625 0 6 0.34375 6 0.75C6 1.1875 5.65625 1.5 5.25 1.5H3C2.15625 1.5 1.5 2.1875 1.5 3V11C1.5 11.8438 2.15625 12.5 3 12.5H5.25C5.65625 12.5 6 12.8438 6 13.25ZM15.7812 6.5L11.8125 2.25C11.5312 1.9375 11.0625 1.9375 10.75 2.21875C10.4375 2.5 10.4375 2.96875 10.7188 3.28125L13.5 6.25H5.71875C5.3125 6.25 5 6.59375 5 7C5 7.4375 5.3125 7.75 5.71875 7.75H13.4688L10.6562 10.75C10.375 11.0625 10.375 11.5312 10.6875 11.8125C10.875 11.9375 11.0625 12 11.2188 12C11.4062 12 11.5938 11.9375 11.75 11.7812L15.7188 7.53125C16.0625 7.25 16.0625 6.78125 15.7812 6.5Z"
      fill={fillColor}
    />
  </svg>
)

export default Logout
