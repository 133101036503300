import ProtectedPageHeader from 'components/ProtectedPageHeader'
import StatusBadge from 'components/StatusBadge'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { history } from 'store'
import { activationRateUrl } from 'utilities/urlUtils'
import ActivationFunnel from './ActivationFunnel/Overview'
import Main from './Main'

const t = buildTranslate('activation_rate')
export enum Tabs {
  ActivationRate = 'activation_rate',
  ActivationFunnel = 'activation_funnel',
}

const ActivationRateReport: React.FC = () => {
  const hash = history.location.hash
  const initialTab = hash.includes(Tabs.ActivationFunnel)
    ? Tabs.ActivationFunnel
    : Tabs.ActivationRate
  const [activeTab, setActiveTab] = useState<Tabs>(initialTab as Tabs)

  const handleSwitchTab = (tab: Tabs) => {
    history.replace({ hash: tab })
    setActiveTab(tab)
  }

  return (
    <>
      <Helmet>
        <title>{t(`tabs.${activeTab}.page_title`)}</title>
      </Helmet>
      <ProtectedPageHeader
        title={t('title')}
        resource="LocationReports"
        description={t('subtitle')}
        action="access"
        learnMoreUrl={activationRateUrl}
      >
        <Tab.Container
          activeKey={activeTab}
          transition={false}
          onSelect={(tab: string | null) => {
            handleSwitchTab(tab as Tabs)
          }}
        >
          <TabNav>
            <TabItem
              state={Tabs.ActivationRate}
              title={
                <div className="d-flex align-content-center">
                  {t('tabs.activation_rate.title')}
                  <StatusBadge type="new" />
                </div>
              }
            />
            <TabItem
              state={Tabs.ActivationFunnel}
              title={t('tabs.activation_funnel.title')}
            />
          </TabNav>
          <Tab.Content className="pb-l h-100 grey-05-bkg">
            <TabPane state={Tabs.ActivationRate}>
              <Main onSwitchTabAction={handleSwitchTab} />
            </TabPane>
            <TabPane state={Tabs.ActivationFunnel}>
              <ActivationFunnel onSwitchTabAction={handleSwitchTab} />
            </TabPane>
          </Tab.Content>
        </Tab.Container>
      </ProtectedPageHeader>
    </>
  )
}

export default ActivationRateReport
