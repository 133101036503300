import React, { useState } from 'react'

type Options = {
  visible: boolean
  hideImportedPoints: boolean
}

type AdvancedOptions = {
  options: Options
  setOptions: (options: Partial<Options>) => void
}

const initialState: Options = {
  visible: false,
  hideImportedPoints: false,
}

export const AdvancedOptionsContext = React.createContext<AdvancedOptions>({
  options: initialState,
  setOptions: () => {},
})

type Props = {
  children: React.ReactNode
}

export const AdvancedOptionsProvider: React.FC<Props> = props => {
  const { children } = props
  const [options, setOptions] = useState<Options>(initialState)

  const updateOptions = (updatedOptions: Partial<Options>) => {
    setOptions(options => ({
      ...options,
      ...updatedOptions,
    }))
  }

  return (
    <AdvancedOptionsContext.Provider
      value={{ options, setOptions: updateOptions }}
    >
      {children}
    </AdvancedOptionsContext.Provider>
  )
}
