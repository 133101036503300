import { Fields } from 'models/Location'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_LOCATIONS = 'locations/GET_LOCATIONS'
export const GET_LOCATIONS_SUCCESS = 'locations/GET_LOCATIONS_SUCCESS'
export const GET_LOCATIONS_FAIL = 'locations/GET_LOCATIONS_FAIL'

export const GET_LOCATION = 'locations/GET_LOCATION'
export const GET_LOCATION_SUCCESS = 'locations/GET_LOCATION_SUCCESS'
export const GET_LOCATION_FAIL = 'locations/GET_LOCATION_FAIL'

type GetLocationsParams = {
  coming_soon?: boolean
  full_visibility?: boolean
}

export function getLocations(
  params: GetLocationsParams = {}
): AxiosAction<typeof GET_LOCATIONS, { locations: Fields[] }> {
  return {
    type: GET_LOCATIONS,
    payload: {
      client: 'default',
      request: {
        url: 'locations',
        params: params,
      },
    },
  }
}

export function getLocation(
  id: number
): AxiosAction<typeof GET_LOCATION, { location: Fields }> {
  return {
    type: GET_LOCATION,
    payload: {
      client: 'default',
      request: {
        url: `locations/${id}`,
        method: 'get',
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getLocations>>
  | ResolvedAxiosAction<ReturnType<typeof getLocation>>
