import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import Messaging from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Content/Messaging'

const t = buildTranslate('thanx_campaigns.report.v3.configuration')

type Props = {
  className?: string
  campaign: Campaign
  isControlVariant?: Boolean
}

const LegacyMessageSection: React.FC<Props> = ({
  campaign,
  className = '',
  isControlVariant = false,
}) => {
  return (
    <Section
      className={`p-xl ${className}`}
      kind={!isControlVariant ? 'primary' : 'secondary'}
    >
      <Text.Header4 className={`m-0 mb-${!isControlVariant ? 'm' : 's'}`}>
        {t('message')}
      </Text.Header4>

      <Switch
        condition={!isControlVariant}
        fallback={
          <Text.BodyText4 color="grey70">{t('no_message')}</Text.BodyText4>
        }
      >
        <div className="mx-s">
          <Messaging campaign={campaign} isEditable={false} />
        </div>
      </Switch>
    </Section>
  )
}

export default LegacyMessageSection
