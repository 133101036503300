import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { RedemptionProvider as RedemptionProviderType } from 'models/RedemptionProvider'
import ProviderInstoreRedemptionDisplay from 'scenes/RedeemManager/shared/ProviderInstoreRedemptionDisplay'
import { useFormModel } from '../../FormModel'

type Props = {
  redemptionProvider: RedemptionProviderType
  setConfigureProviderStep: (boolean) => void
  setSelectedProvider: (redemptionProvider: RedemptionProviderType) => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card'
)

function RedemptionProvider(props: Props) {
  const { redemptionProvider, setConfigureProviderStep, setSelectedProvider } =
    props
  const { model: formModel } = useFormModel()
  const { posFields } = formModel

  const {
    instoreRedemptionType,
    couponCodes,
    selectedCouponPools,
    instoreDiscountedProductIds,
    instoreRequiredProductIds,
  } = posFields[redemptionProvider.value] ?? {}

  const couponCodeDisplay = {
    couponCode: couponCodes,
    formattedCouponPools: selectedCouponPools,
  }

  const editButton = () => {
    return (
      <Button kind="minimal" onClick={handleClick}>
        {t('multi_pos.edit')}
      </Button>
    )
  }

  const handleClick = () => {
    setConfigureProviderStep(true)
    setSelectedProvider(redemptionProvider)
  }

  return (
    <>
      {!instoreRedemptionType && (
        <div
          className="d-flex align-items-start justify-content-between p-4 mb-4"
          style={{
            backgroundColor: '#F2F4F5',
            borderRadius: '4px',
            border: '1px solid #D6DADE',
          }}
        >
          <div>
            <Text.BodyText3 bold>{t('multi_pos.pos')}</Text.BodyText3>
            <br></br>
            <Text.BodyText3 color="grey70">
              {redemptionProvider.label}
            </Text.BodyText3>
          </div>

          <Button kind="primary" onClick={handleClick}>
            {t('multi_pos.configure')}
          </Button>
        </div>
      )}
      {!!instoreRedemptionType && (
        <ProviderInstoreRedemptionDisplay
          redemptionProvider={redemptionProvider}
          redemptionType={instoreRedemptionType}
          couponCodeDisplay={couponCodeDisplay}
          instoreDiscountedProviderProductIds={instoreDiscountedProductIds}
          instoreRequiredProviderProductIds={instoreRequiredProductIds}
          editButton={editButton}
        />
      )}
    </>
  )
}

export default RedemptionProvider
