import { Button } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import contentPreview from 'assets/images/cms/app_homepage_content_preview.svg'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  isLoading: boolean
  disabled?: boolean
  onPublish: VoidFunction
  type: 'content' | 'style'
}

const t = buildTranslate('cms.content.app_home_page')

const Sidebar = ({ isLoading, disabled, onPublish, type }: Props) => {
  const [css] = useStyletron()

  return (
    <>
      <Button
        className={`mb-xxxl px-s ${css({ width: '200px' })}`}
        isLoading={isLoading}
        disabled={disabled}
        onClick={onPublish}
        type={type === 'content' ? 'button' : 'submit'}
      >
        {t('sidebar.publish')}
      </Button>
      <img
        className={`mb-xl ${css({
          width: '200px',
        })}`}
        src={contentPreview}
        alt={t('sidebar.preview')}
      />
    </>
  )
}

export default Sidebar
