import { Button, Kind } from '@thanx/uikit/button'
import { RadioGroup } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import RichSelector from 'components/Form/RichSelector'
import Book from 'components/Icons/Book'
import ControlGroupIcon from 'components/Icons/ControlGroup'
import VariantIcon from 'components/Icons/NoABTest'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React, { useState } from 'react'
import { abTestingHelpUrl } from 'utilities/urlUtils'
import { useVariantSelectionHandler, VariantAction } from './helpers'

type PropsT = {
  campaign: Campaign
  isOpen: boolean
  onClose: () => void
  hasControlGroup: boolean
}

const t = buildTranslate('thanx_campaigns.review.content.variant_selector')

const VariantSelectModal: React.FC<PropsT> = props => {
  const { campaign, isOpen, onClose, hasControlGroup } = props

  const { submitVariant } = useVariantSelectionHandler()
  const [variantAction, setVariantAction] = useState<VariantAction>('variant')

  const onContinueClick = async () => {
    await submitVariant({
      action: variantAction,
      campaignId: campaign.id,
    })
    onClose()
    setVariantAction('variant')
  }

  return (
    <Modal
      title={t('title')}
      subtitle={t('subtitle')}
      proceedText={t('buttons.continue')}
      isOpen={isOpen}
      onClose={onClose}
      onProceed={onContinueClick}
      buttonGroupChildren={
        <div className="flex-1">
          <Button
            kind={Kind.MINIMAL}
            size="small"
            onClick={() => window.open(abTestingHelpUrl)}
            className="font-weight-normal"
          >
            {t('buttons.learn_more')}
            <Book className="ml-xs" />
          </Button>
        </div>
      }
    >
      <div className="mt-xl">
        <RadioGroup value={variantAction} className="mb-l">
          <RichSelector
            onClick={() => {
              setVariantAction('variant')
            }}
            value="variant"
            image={<VariantIcon />}
            className="mb-s"
            imageClassName="ml-xs"
          >
            <Text.Header4 bold className="mt-0">
              {t('variant_title')}
            </Text.Header4>
            <Text.BodyText4 tag="p" color="grey70">
              {t('variant_description')}
            </Text.BodyText4>
          </RichSelector>
          <RichSelector
            onClick={() => {
              setVariantAction('control')
            }}
            value="control"
            image={<ControlGroupIcon />}
            disabled={hasControlGroup}
            imageClassName="ml-xs"
          >
            <Text.Header4 bold className="mt-0">
              {t('control_variant_title')}
            </Text.Header4>
            <Text.BodyText4 tag="p">
              {t('control_variant_description')}
            </Text.BodyText4>
          </RichSelector>
        </RadioGroup>
      </div>
    </Modal>
  )
}

export default VariantSelectModal
