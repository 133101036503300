import { Button } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import heroImagePreview from 'assets/images/cms/hero-image.svg'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  isLoading: boolean
  disabled?: boolean
}

const t = buildTranslate('cms.content.web_hero_image')

const Sidebar: React.FC<Props> = ({ isLoading, disabled }) => {
  const [css] = useStyletron()

  return (
    <>
      <Button
        type="submit"
        className={`mb-xxxl ${css({ width: '200px' })}`}
        isLoading={isLoading}
        disabled={disabled}
      >
        {t('publish')}
      </Button>
      <img
        className={`mb-xl ${css({
          width: '200px',
          height: '166px',
        })}`}
        src={heroImagePreview}
        alt={t('hero_image_preview')}
      />
    </>
  )
}

export default Sidebar
