import Loading from 'components/Loading'
import withMerchantMetrics, {
  MetricsProps,
} from 'components/metrics/withMerchantMetrics'
import React from 'react'
import View from './View'

export const ActivityReport: React.FC<MetricsProps> = ({
  merchantMetrics = {},
  merchantMetricStatuses = {},
}) => {
  if (
    Object.keys(merchantMetricStatuses).some(
      key => merchantMetricStatuses[key].isLoading === true
    )
  ) {
    return <Loading />
  }
  return <View metrics={merchantMetrics} />
}

export default withMerchantMetrics([
  'user_stats',
  'transaction_stats',
  'reward_stats',
])(ActivityReport)
