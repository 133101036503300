import GenericChart from 'components/Charts/Generic'
import { DateRangeFilter } from 'components/DateFilter/utilities'
import Highcharts from 'highcharts'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { RateTimeSeriesMetric } from 'models/CaptureRateMetric'
import React, { useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import { useTimezoneBenchmarkCaption } from 'scenes/Reports/hooks/useTimezoneBenchmarkCaption'
import {
  getKnownRevenueSeries,
  getRateChartSeries,
  getXAxisCategories,
} from './helpers'
import Tooltip from './Tooltip'

export interface NamedRateTimeSeriesMetric extends RateTimeSeriesMetric {
  locationName: string
  color: string
}

type PropsT = {
  metrics?: NamedRateTimeSeriesMetric[]
  timezone: string
  dateRangeFilter: DateRangeFilter
  dataType?: 'captureRate' | 'knownRevenue'
}
const t = buildTranslate('capture_rate.rate_chart')

const LineChart: React.FC<PropsT> = ({
  metrics,
  timezone,
  dateRangeFilter,
  dataType = 'captureRate',
}) => {
  const leftAxisFormatter = label => {
    return `${label.value}%`
  }
  const merchant = useCurrentMerchant()
  const caption = useTimezoneBenchmarkCaption(merchant, timezone)

  const categories = useMemo(
    () => getXAxisCategories(dateRangeFilter, timezone),
    [dateRangeFilter, timezone]
  )
  const series: Highcharts.SeriesLineOptions[] = useMemo(() => {
    if (!metrics || !categories) {
      return []
    }

    return dataType === 'captureRate'
      ? getRateChartSeries(categories, metrics)
      : getKnownRevenueSeries(categories, metrics)
  }, [categories, metrics, dataType])

  if (!metrics) return null

  return (
    <GenericChart
      chartType="line"
      categories={categories}
      isLoading={metrics.some(m => m.isLoading)}
      isErrored={metrics.some(m => m.isErrored)}
      tooltipFormatter={function () {
        return renderToString(
          <Tooltip
            captureRate={this.point.y ?? 0}
            date={this.point.category}
            loyalty={this.point.custom.member_purchases_total_amount ?? 0}
            all={this.point.custom.total_amount ?? 0}
          />
        )
      }}
      leftAxisFormatter={leftAxisFormatter}
      series={series}
      xAxisTitle={t('date')}
      yAxisTitle={t('capture_rate')}
      caption={caption}
    />
  )
}

export default LineChart
