import ColorPicker from 'components/Form/ColorPicker'
import Section from 'components/Section'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { FormModel } from 'scenes/Cms/Content/Banners/hooks'

const t = buildTranslate('cms.content.banners')

const styleKeys = [
  'background_color',
  'header_text_color',
  'body_text_color',
  'button_color',
]

type Props = {
  type: 'location_banner' | 'order_placed_banner' | 'error_banner'
  data: FormModel
  isLoading: boolean
}

const BannerForm = ({ data, type, isLoading }: Props) => {
  return (
    <Section className="p-m my-m">
      <Spinner
        isLoading={isLoading}
        size={'3x'}
        className={'d-flex justify-content-center'}
      >
        {styleKeys.map(
          (key, index) =>
            key in data?.[type] && (
              <ColorPicker
                className={index !== 0 ? 'mt-m' : undefined}
                key={`${type}.${key}`}
                name={`${type}.${key}`}
                label={t(`${type}.${key}`)}
                validations={{
                  hex: true,
                  isRequired: true,
                }}
                value={data?.[type][key]}
                validationErrors={{
                  hex: I18n.t('validations.hex.message'),
                  isRequired: I18n.t('validations.is_required'),
                }}
                startEnhancer
              />
            )
        )}
      </Spinner>
    </Section>
  )
}

export default BannerForm
