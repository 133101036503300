import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.view.profile.custom_tags')

type PropsT = {
  profile: UserProfile
}

const CustomTags: React.FC<PropsT> = ({ profile }: PropsT) => {
  return (
    <div>
      <Text.Header4 className={'mb-m'}>{t('title')}</Text.Header4>
      <Switch
        condition={profile.custom_tags.length > 0}
        fallback={
          <Text.BodyText3 color="grey70">{t('empty_state')}</Text.BodyText3>
        }
      >
        <table className="full-width">
          <thead>
            <tr>
              <HeaderCell className={'w-50'}>{t('key')}</HeaderCell>
              <HeaderCell className={'w-50'}>{t('values')}</HeaderCell>
            </tr>
          </thead>
          <tbody>
            {profile.custom_tags.map(tag => (
              <Row key={tag.key} tag={tag} />
            ))}
          </tbody>
        </table>
      </Switch>
    </div>
  )
}

export default CustomTags
