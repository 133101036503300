import { api } from './index'

type ImageUrls = {
  small: string
  default: string
  large: string
}

type ContentStyles = {
  background_color: string
  header_text_color: string
  body_text_color: string
}

type BlockStyles = {
  padding_top: number
  padding_right: number
  padding_bottom: number
  padding_left: number
}

type BorderStyles = {
  border_radius: number
  border_width: number
  border_color: string
}

type CarouselStyles = {
  item_spacing: number
}

type FeaturedLinkSettings = {
  url?: string
}

export type FeaturedContentBlock = {
  type: 'featured_content'
  image_urls: ImageUrls
  settings: FeaturedLinkSettings & BlockStyles & BorderStyles
}

export type ExternalFormBlock = {
  type: 'external_form'
  image_urls: ImageUrls
  settings: BlockStyles &
    BorderStyles & {
      form_id?: number
    }
}

export type RecentOrdersBlock = {
  type: 'recent_orders'
  settings: BlockStyles &
    BorderStyles &
    ContentStyles &
    CarouselStyles & {
      button_text_color: string
      button_bg_color: string
    }
}

export type MenuCategoriesBlock = {
  type: 'menu_categories'
  settings: BlockStyles &
    BorderStyles &
    Omit<ContentStyles, 'body_text_color'> &
    CarouselStyles
}

export type RewardsBlock = {
  type: 'rewards'
  settings: BlockStyles &
    BorderStyles &
    ContentStyles &
    CarouselStyles & {
      button_text_color: string
      button_border_color: string
      link_color: string
    }
}

export type StoredValueBalanceBlock = {
  type: 'stored_value_balance'
  settings: BlockStyles &
    BorderStyles &
    ContentStyles & {
      chevron_color: string
    }
}

export type MissingLoyaltyBenefitsCardBlock = {
  type: 'missing_loyalty_prompt'
  settings: BlockStyles &
    BorderStyles &
    ContentStyles & {
      button_text_color: string
      button_border_color: string
      close_icon_color: string
    }
}

export type ContentBlock = {
  id: number
  position: number
} & (
  | RecentOrdersBlock
  | MenuCategoriesBlock
  | RewardsBlock
  | StoredValueBalanceBlock
  | MissingLoyaltyBenefitsCardBlock
  | FeaturedContentBlock
  | ExternalFormBlock
)

export type ContentBlockTypes = ContentBlock['type']

export type MenuCategoryStyles = {
  background_color: string
  header_text_color: string
  body_text_color: string
  link_color: string
  product_card: ProductCardStyles
  unavailable_product_card: Omit<
    ProductCardStyles,
    'border_width' | 'border_radius'
  >
}

export type ProductCardStyles = {
  background_color: string
  header_text_color: string
  body_text_color: string
  border_color: string
  border_width: number
  border_radius: number
}

export type BannerSettings = {
  background_color: string
  header_text_color: string
  body_text_color: string
  button_color: string
}

export type BannerStyles = {
  location_banner: BannerSettings
  order_placed_banner: BannerSettings
  error_banner: Omit<BannerSettings, 'header_text_color'>
}

export type Settings = {
  link_color: string
} & ContentStyles &
  BannerStyles & {
    menu_category_page: MenuCategoryStyles
  }

type CreateContentBlockPayload = {
  id?: number
  position: number
  type: ContentBlockTypes
  settings: ContentBlock['settings']
  file_uploads?: {
    image: {
      file_upload_id: number
    }
  }
}

type DeleteContentBlockPayload = {
  id: number
  destroy: boolean
}

type UpdateContentBlockPayload =
  | CreateContentBlockPayload
  | DeleteContentBlockPayload

export type LandingPageThemeUpdatePayload = {
  id: number
  landing_page_theme: {
    id?: number
    content_blocks: Array<UpdateContentBlockPayload>
    settings: Settings
  }
}

export type LandingPageTheme = {
  id: number
  content_blocks: Array<ContentBlock>
  settings: Settings
}

export const landingPageThemeApi = api.injectEndpoints({
  endpoints: build => ({
    getLandingPageTheme: build.query<
      { app_landing_page_theme: LandingPageTheme },
      number
    >({
      query: appId => `apps/${appId}/landing_page_themes`,
      providesTags: ['LandingPageTheme'],
    }),
    updateLandingPageTheme: build.mutation<
      { app_landing_page_theme: LandingPageTheme },
      LandingPageThemeUpdatePayload
    >({
      query: payload => ({
        url: `apps/${payload.id}/landing_page_themes`,
        method: 'PUT',
        body: {
          landing_page_theme: payload.landing_page_theme,
        },
      }),
      invalidatesTags: ['LandingPageTheme'],
    }),
  }),
})
