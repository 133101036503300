import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import measureGraph from 'assets/images/reports/efective-discount-rate/money_transfer_flatline.svg'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'
import { useIsMobile } from 'utilities/responsive'
import { effectiveDiscountRateUrl } from 'utilities/urlUtils'

const t = buildTranslate('points.effective_discount_rate')

const ExplainerCard: React.FC = () => {
  const isMobile = useIsMobile()
  const [css] = useStyletron()

  return (
    <Section
      className={`d-flex flex-wrap mb-xl ${isMobile ? 'm-auto' : 'pl-l'} ${css({
        minHeight: '308px',
        backgroundColor: merchantTheme.colors.wisteria10,
        boxShadow: '0 1rem 3rem rgba(54, 66, 78, 0.2)',
      })}`}
    >
      <div className={`mt-xl ${isMobile ? 'ml-auto mr-auto' : ''}`}>
        <img src={measureGraph} alt="" />
      </div>
      <div
        className={`pl-l pt-l pb-m d-flex flex-column justify-content-between ${css(
          { width: isMobile ? '90%' : '70%' }
        )}`}
      >
        <section className="mb-s">
          <Text.Header4>{t('explainer_card.title')}</Text.Header4>
          <Text.BodyText3 color="grey70">
            {t('explainer_card.subtitle')}
          </Text.BodyText3>
        </section>
        <section className="mb-xl">
          <Button onClick={() => window.open(effectiveDiscountRateUrl)}>
            {t('explainer_card.edr_101')}
          </Button>
        </section>
      </div>
    </Section>
  )
}

export default ExplainerCard
