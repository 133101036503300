import { useFlags } from 'launchdarkly-react-client-sdk'

// Convenience method to ensure we gracefully handle missing flags and if we
// have issues initializing LaunchDarkly, forcing users to always consider
// what the "default" value should be
const useFlag = (flagKey, defaultValue) => {
  const flags = useFlags()
  return flags[flagKey] ?? defaultValue
}

export default useFlag
