import { Select } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { getNotifyEmails } from 'actions/notifyEmails'
import Modal from 'components/ModalContainer'
import useDispatch from 'hooks/useDispatch'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import type { CampaignStateT, Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import { Fields as NotifyEmail } from 'models/NotifyEmail'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import VariantRadio from 'scenes/CampaignCenter/Builder/components/VariantRadio'
import { useCopyEmail } from 'scenes/CampaignCenter/Builder/hooks'
import { selectCampaignVariantsByCampaignId } from 'selectors/campaignVariant'
import { selectNotifyEmailsByState } from 'selectors/notifyEmail'
import CampaignList from './CampaignList'

type Props = {
  config: VariantOrCampaign
  campaign: Campaign
  show: boolean
  onModalClose: () => void
  onSelectPreview: (notifyEmail: NotifyEmail) => void
}

type CampaignOptionId = 'variant' | CampaignStateT

interface CampaignGroup {
  value: CampaignOptionId
  label: string
}

const t = buildTranslate(
  'thanx_campaigns.builder.forms.messaging_tabs.import.modal'
)

const ImportModal: React.FC<Props> = props => {
  const { config, campaign, show, onModalClose, onSelectPreview } = props
  const variants = campaign.variants || []
  const otherVariants = variants.filter(v => v.id !== config?.id)
  const isABTest = otherVariants.length > 0

  const [selectedVariant, setSelectedVariant] = useState<number | undefined>()
  const [selectedGroup, setSelectedGroup] = useState<CampaignOptionId>(() =>
    isABTest ? 'variant' : 'draft'
  )
  const [isLoadingEmails, setIsLoadingEmails] = useState<boolean>(false)

  const campaignVariants = useSelector(state =>
    selectCampaignVariantsByCampaignId(state, campaign.id)
  )
  const { copyEmail } = useCopyEmail(config, campaign.id)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!show) {
      setSelectedVariant(undefined)
    }
  }, [show])

  useEffect(() => {
    ;(async () => {
      if (selectedGroup === 'variant') {
        return
      }
      setIsLoadingEmails(true)
      await dispatch(getNotifyEmails({ state: selectedGroup }))
      setIsLoadingEmails(false)
    })()
  }, [selectedGroup, dispatch])

  const onProceed = () => {
    if (selectedVariant) {
      const importSource =
        campaignVariants?.find(v => v.id === selectedVariant) ?? null
      copyEmail(importSource)
      onModalClose()
    }
  }

  const handleSelectGroup = selected => setSelectedGroup(selected[0].value)

  const disabledIds = variants.filter(v => !v.notify_email).map(v => v.id)

  const isEmailCopyingEnabled = useFlag('MX4-Copy-An-Email', false)
  const proceedText = isEmailCopyingEnabled ? t('proceed_v2') : t('proceed')
  const title = isEmailCopyingEnabled ? t('title_v2') : t('title')
  const radioTextSource = isEmailCopyingEnabled
    ? 'thanx_campaigns.builder.forms.messaging_tabs.import.modal.from_v2'
    : 'thanx_campaigns.builder.forms.messaging_tabs.import.modal.from'

  const campaignGroups = (): CampaignGroup[] => {
    const options: CampaignGroup[] = [
      { value: 'active', label: t('campaign_group_options.active') },
      { value: 'complete', label: t('campaign_group_options.complete') },
      {
        value: 'draft',
        label: t('campaign_group_options.draft'),
      },
      { value: 'scheduled', label: t('campaign_group_options.scheduled') },
      { value: 'in_review', label: t('campaign_group_options.in_review') },
    ]

    if (isABTest) {
      options.unshift({
        value: 'variant' as CampaignOptionId,
        label: t('campaign_group_options.variant'),
      })
    }

    return options
  }

  const importableEmails = useSelector(state =>
    selectNotifyEmailsByState(
      state,
      selectedGroup === 'variant' ? 'active' : selectedGroup
    )
  ).filter(email => !!email.campaign_name)

  const hasVariantsRadios =
    (isEmailCopyingEnabled && isABTest && selectedGroup === 'variant') ||
    (!isEmailCopyingEnabled && isABTest)

  return (
    <Modal
      canProceed={!!selectedVariant}
      title={title}
      subtitle={t('subtitle')}
      proceedText={proceedText}
      isOpen={show}
      onClose={onModalClose}
      onProceed={onProceed}
      showBottomProceed={
        !isEmailCopyingEnabled ||
        (isEmailCopyingEnabled && selectedGroup === 'variant')
      }
      subtitleClassName={isEmailCopyingEnabled ? 'mb-m' : ''}
      zIndex={1001}
      width={isEmailCopyingEnabled ? '760px' : '600px'}
    >
      {isEmailCopyingEnabled && (
        <Select
          searchable={false}
          options={campaignGroups()}
          onChange={handleSelectGroup}
          value={campaignGroups().find(
            option => option.value === selectedGroup
          )}
          // zIndex must be greater than modal's zIndex
          zIndex={1002}
        />
      )}
      {hasVariantsRadios && (
        <VariantRadio
          className="mt-m"
          disabledIds={disabledIds}
          setSelectedVariant={setSelectedVariant}
          translate={radioTextSource}
          variants={otherVariants}
        />
      )}
      {isEmailCopyingEnabled && selectedGroup !== 'variant' && (
        <CampaignList
          emails={importableEmails}
          isLoading={isLoadingEmails}
          currentVariant={config}
          campaignId={campaign.id}
          onModalClose={onModalClose}
          onSelectPreview={onSelectPreview}
        />
      )}
      <Switch condition={hasVariantsRadios || importableEmails.length > 0}>
        <Text.BodyText5 className="mt-m" color="grey70" tag="div">
          {t('footer')}
        </Text.BodyText5>
      </Switch>
    </Modal>
  )
}

export default ImportModal
