export default {
  title: 'Revenue capture rate',
  subtitle: 'How much of your monthly revenue comes from your loyalty members',
  page_title: 'Revenue capture rate | Thanx',
  crr_video_card: {
    watch: 'Watch',
    crr_101: 'Capture Rate 101',
  },
  explainer_card: {
    title: 'Measure the reach of your loyalty program',
    subtitle:
      'The more customers are enrolled and engaged, the greater their impact on your business',
    why_tracking_matters: 'Why tracking this metric matters',
    progress_labels: {
      low: 'Low',
      moderate: 'Moderate',
      high: 'High',
      exceptional: 'Exceptional',
    },
    unlock_your_rate: 'Unlock your rate',
  },
  csv_upload: {
    pos_data_source: 'Point of sale data source',
    manual_import: 'Manual import',
    automated_import: 'Automated import',
    automated_import_description:
      'Note: Historical data from before the automated import began is limited by your POS. e.g., Capture rate for older date ranges will appear as 0.',
    import_pos_data: 'Import POS data',
    upload_your_pos_data: 'Upload POS data to update your capture rate report.',
    how_to_import_data: 'How to import your data',
    upload_csv: 'Upload CSV',
    import_in_progress: 'Import in progress',
    import_errored: 'Import failed',
    something_went_wrong:
      'Something went wrong during the import process. Try again',
    data_processing:
      'Your data is being processed. Check back later to see the updated report.',
    imported_successfully: 'Your data was imported successfully!',
  },
  missing_revenue_tip: {
    title: 'Include all revenue',
    body: 'We noticed that some of your revenue is missing or underreported. This will impact your capture rate and may result in negative revenue being displayed for some channels below. Make sure to include all of your revenue centers including catering, group orders, etc.',
  },
  learn_more: 'Learn more',
  your_capture_rate: 'Your capture rate',
  current_rate: 'Current capture rate',
  revenue_rate_1: 'Revenue capture rate (%{month})',
  revenue_rate_2: '%{rate} with 3rd party orders included',
  missing_data: 'Missing point of sale data.',
  net_revenue_loyalty: 'Net revenue attributed to loyalty members (%{month})',
  net_revenue_all_1: 'Net revenue from all customers (%{month})',
  net_revenue_all_2: 'Excludes 3rd party orders (Doordash, UberEats, Shopify)',
  location: 'Location',
  all_locations: 'All locations',
  rate_chart: {
    capture_rate: 'Capture rate',
    all_locations: 'Capture rate for all locations',
    selected_location: 'Capture rate for selected location',
    tooltip: {
      loyalty: 'Net revenue attributed to loyalty members',
      all: 'Net revenue from all customers (excludes 3PD)',
    },
    date: 'Date',
  },
  revenue_by_channel: {
    title: 'Revenue by channel',
    table: {
      date_time: 'Month',
      member_purchases_instore_percent: 'In-store (loyalty)',
      member_purchases_total: 'Total',
      non_member_purchases_instore_percent: 'In-store (unknown)',
      member_purchases_digital_percent: 'Digital (loyalty)',
      non_member_purchases_digital_percent: 'Digital (unknown)',
      third_party_percent: '3PD (unknown)',
    },
    chart: {
      date: 'Date',
      net_revenue: 'Net revenue attributed by channel',
      instore_loyalty: 'In-store (loyalty)',
      instore_unknown: 'In-store (unknown)',
      digital_loyalty: 'Digital (loyalty)',
      digital_unknown: 'Digital (unknown)',
      third_party: '3PD (unknown)',
    },
  },
  empty: {
    title: 'This report is not ready yet',
    subtitle:
      'You’ll be able to view this report and to upload revenue data in %{count} days.',
    subtitle_1:
      'You’ll be able to view this report and to upload revenue data in %{count} day.',
    subtitle_not_launched:
      'You’ll be able to view this report in the next calendar month following your launch date.',
    learn_more: 'Learn more',
  },
  popovers: {
    example: 'EXAMPLE',
    how_its_calculated: "HOW IT'S CALCULATED",
    revenue_rate_popover: {
      section_1:
        'The percentage of your revenue attributed to loyalty members.',
      section_2:
        'This rate is for the latest complete calendar month where POS data for total revenue was provided.',
      section_3:
        'You import POS data for July shows that you made $10,000 in net revenue. Thanx can see that $3,000 of this was attributed to loyalty members giving you a capture rate of 30% for that month.',
    },
    net_revenue_loyalty_popover: {
      section_1:
        'Revenue across all channels that was attributed to loyalty members.',
      section_2: 'This revenue is for the latest complete calendar month.',
      section_3: 'Thanx counted that loyalty members spent $3,000 in July.',
    },
    net_revenue_all_popover: {
      section_1:
        'Total revenue across all channels (excluding 3PD) that was spent by all customers.',
      section_2:
        'This revenue is for the latest complete calendar month where POS data for total revenue was provided.',
      section_3:
        'You uploaded POS data for the month of July that shows a total revenue of $10,000 across all channels from all customers (excluding 3PD).',
    },
    revenue_by_channel: {
      in_store: {
        loyalty: {
          percent: {
            section_1:
              'The percentage of your revenue attributed to in-store purchases made by loyalty members.',
            section_2:
              'In-store revenue counted by Thanx / Total revenue from POS data',
            section_3:
              '$10,000 was spent by all customers across all channels this month. $1,000 of that revenue came from in-store purchases made by loyalty members. That’s 10% of your revenue for the month.',
          },
          amount: {
            section_1:
              'Revenue attributed to in-store purchases made by loyalty members.',
            section_2: 'In-store revenue counted by Thanx',
            section_3:
              '$1,000 of this month’s revenue came from in-store purchases made by loyalty members.',
          },
        },
        unknown: {
          percent: {
            section_1:
              'The percentage of your revenue attributed to in-store purchases made by unknown customers.',
            section_2:
              '(In-store revenue from POS data - In-store revenue counted by Thanx) / Total revenue from POS data',
            section_3:
              '$10,000 was spent by all customers across all channels this month. $1,000 of that revenue came from in-store purchases made by unknown customers. That’s 10% of your revenue for the month.',
          },
          amount: {
            section_1:
              'Revenue attributed to in-store purchases made by unknown customers.',
            section_2:
              'In-store revenue from POS data - In-store revenue counted by Thanx',
            section_3:
              'POS data shows $3,000 of revenue from in-store purchases. Thanx counted $2,000 of in-store revenue spent by loyalty members. $1,000 of this month’s revenue came from in-store purchases made by unknown customers.',
          },
        },
      },
      digital: {
        loyalty: {
          percent: {
            section_1:
              'The percentage of your revenue attributed to digital purchases (i.e., app and web) made by loyalty members.',
            section_2:
              'Digital revenue counted by Thanx / Total revenue from POS data',
            section_3:
              '$10,000 was spent by all customers across all channels this month. $1,000 of that revenue came from digital purchases made by loyalty members. That’s 10% of your revenue for the month.',
          },
          amount: {
            section_1:
              'Revenue attributed to digital purchases (i.e., app and web) made by loyalty members.',
            section_2: 'Digital revenue counted by Thanx',
            section_3:
              '$1,000 of this month’s revenue came from digital purchases made by loyalty members.',
          },
        },
        unknown: {
          percent: {
            section_1:
              'The percentage of your revenue attributed to digital purchases made by unknown customers (e.g., catering).',
            section_2:
              '(Digital revenue from POS data - Digital revenue counted by Thanx) / Total revenue from POS data',
            section_3:
              '$10,000 was spent by all customers across all channels this month. $1,000 of that revenue came from digital purchases made by unknown customers. That’s 10% of your revenue for the month.',
          },
          amount: {
            section_1:
              'Revenue attributed to digital purchases made by unknown customers (e.g., catering).',
            section_2:
              'Digital revenue from POS data - Digital revenue counted by Thanx',
            section_3:
              'POS data shows $3,000 of revenue from digital purchases. Thanx counted $2,000 of digital revenue spent by loyalty members. $1,000 of this month’s revenue came from digital purchases made by unknown customers.',
          },
        },
      },
      third_party: {
        unknown: {
          percent: {
            section_1:
              'The percentage of your revenue attributed to purchases made on 3rd party channels (e.g., Doordash, UberEats, etc.) by unknown customers.',
            section_2:
              '3rd party revenue from POS data / Total revenue from POS data',
            section_3:
              '$10,000 was spent by all customers across all channels this month. $1,000 of that revenue came from purchases made on a 3PD channel (e.g., Doordash, UberEats, etc.) by unknown customers. That’s 10% of your revenue for the month.',
          },
          amount: {
            section_1:
              'Revenue attributed to purchases made on 3rd party channels (e.g., Doordash, UberEats, etc.) by unknown customers.',
            section_2: '3rd party revenue from POS data',
            section_3:
              '$1,000 of this month’s revenue came from purchases made on a 3PD channel (e.g., Doordash, UberEats, etc.) by unknown customers. ',
          },
        },
      },
    },
  },
}
