import { alert } from 'actions/flash'
import { getValidation } from 'actions/validations'
import {
  Config,
  OnboardingSlideshow,
  onboardingSlideshowApi,
  OnboardingSlideshowUpdatePayload,
  Slide,
} from 'api/onboardingSlideshow'
import { isError } from 'api/utils'
import { ImagePickerValue } from 'components/ImagePicker'
import { TabT } from 'components/Tabs/InnerTabs'
import palette from 'constants/palette'
import Formsy from 'formsy-react'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import sortBy from 'lodash/sortBy'
import uniqueId from 'lodash/uniqueId'
import { ValidationTypes } from 'models/Validation'
import React, { useEffect, useMemo, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import OnboardingSlideshowContent from './Content'
import Sidebar from './Sidebar'
import OnboardingSlideshowStyle from './Style'

const t = buildTranslate('cms.signup.onboarding_slideshow')
const accessibilityT = buildTranslate(
  'cms.signup.onboarding_slideshow.content.block_item.accessibility'
)

export interface BlockData
  extends Pick<Slide, 'type' | 'button_description' | 'content_description'> {
  image: ImagePickerValue
}

const EMPTY_BLOCK_DATA: BlockData = {
  type: 'simple' as const,
  button_description: '',
  content_description: '',
  image: {
    id: null,
    url: '',
    uploading: false,
  },
}

type FormSlideData = {
  [id: string]: BlockData
}

export interface FormModel {
  slides: FormSlideData
  config: Config
}

const initialFormState: FormModel = {
  slides: {},
  config: {
    controller: {
      enabled_arrow_color: '#FFFFFF',
      disabled_arrow_color: '#FFE1E1',
      active_dot_color: '#BE2227',
      inactive_dot_color: '#FFE1E1',
    },
    settings: {
      frequency: 'multiple',
    },
  },
}

type FormAction =
  | { type: 'UPDATE_SLIDE'; payload: { key: string; data: BlockData } }
  | { type: 'UPDATE_CONFIG'; payload: Partial<Config> }
  | { type: 'RESET'; payload: FormModel }

const formReducer = (state: FormModel, action: FormAction) => {
  switch (action.type) {
    case 'UPDATE_SLIDE':
      const { key, data } = action.payload
      return {
        ...state,
        slides: {
          ...state.slides,
          [key]: data,
        },
      }
    case 'UPDATE_CONFIG':
      return {
        ...state,
        config: {
          ...state.config,
          ...action.payload,
        },
      }
    case 'RESET':
      return action.payload
    default:
      return state
  }
}

interface useOnboardingSlideshowT {
  tab: TabT
}

export enum Tabs {
  OnboardingSlideshowContent = 'onboarding_slideshow_content',
  OnboardingSlideshowStyle = 'onboarding_slideshow_style',
}

export function useOnboardingSlideshow(): useOnboardingSlideshowT {
  const merchant = useCurrentMerchant()
  const app = useSelector(selectApp)
  const appId = app?.id
  const dispatch = useDispatch()
  const [formModel, updateForm] = useReducer(formReducer, initialFormState)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [blockOrder, setBlockOrder] = useState<string[]>([])
  const isDraft = !merchant?.custom_onboarding_enabled
  const defaultConfig = useMemo(
    () =>
      app
        ? ({
            controller: {
              enabled_arrow_color: app.primary_color,
              disabled_arrow_color: palette.grey30,
              active_dot_color: app.primary_color,
              inactive_dot_color: palette.grey50,
            },
            settings: {
              frequency: 'multiple',
            },
          } as Config)
        : initialFormState.config,
    [app]
  )

  const { data: onboardingSlideshowData, isFetching } =
    onboardingSlideshowApi.useGetOnboardingSlideshowQuery(appId)
  const onboardingSlideshow = onboardingSlideshowData
    ? onboardingSlideshowData.app_onboarding_slideshow
    : null

  const [updateOnboardingSlideshow, { isLoading: isFormSubmitting }] =
    onboardingSlideshowApi.useUpdateOnboardingSlideshowMutation()

  function updateSlide(key: string, data: BlockData) {
    updateForm({
      type: 'UPDATE_SLIDE',
      payload: {
        key,
        data,
      },
    })
  }

  function updateConfig(payload: Partial<FormModel['config']>) {
    updateForm({
      type: 'UPDATE_CONFIG',
      payload,
    })
  }

  function resetForm({
    slides,
    config,
  }: {
    slides: Array<Slide>
    config: Config
  }) {
    const formattedSlides: FormSlideData = slides.reduce((acc, obj) => {
      acc[obj.id] = {
        id: obj.id,
        type: obj.type,
        position: obj.position,
        content_description: obj.content_description,
        button_description: obj.button_description,
        image: {
          id: null,
          url: obj.image_urls?.small || '',
          uploading: false,
        },
      }
      return acc
    }, {})

    updateForm({
      type: 'RESET',
      payload: {
        slides: formattedSlides,
        config,
      },
    })
    setBlockOrder(sortBy(slides, ['position']).map(slide => `${slide.id}`))
  }

  function onNewBlock() {
    const newId = uniqueId('content-')
    updateSlide(newId, EMPTY_BLOCK_DATA)
    setBlockOrder(blockOrder => [...blockOrder, newId])
  }

  async function handleSubmit() {
    const payload = getSubmitPayload({
      blockOrder,
      formModel,
      onboardingSlideshow,
    })
    const response = await updateOnboardingSlideshow({
      id: appId,
      onboarding_slideshow: payload,
    })
    setIsConfirmModalVisible(false)

    if (isError(response)) {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
          timeout: 5,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('submit_success'),
        timeout: 5,
      })
    )
  }

  useEffect(() => {
    if (!onboardingSlideshow) return

    const { slides, config: slideshowConfig } = onboardingSlideshow
    const config = !!slideshowConfig?.controller?.enabled_arrow_color
      ? slideshowConfig
      : defaultConfig

    resetForm({ slides, config })
  }, [defaultConfig, onboardingSlideshow])

  useEffect(() => {
    async function fetchData() {
      dispatch(getValidation({ type: ValidationTypes.OnboardingSlide }))
    }

    fetchData()
  }, [dispatch])

  const isImageUploading = blockOrder.some(
    blockId => formModel.slides[blockId]?.image?.uploading
  )

  const sidebar = (
    <Sidebar
      isPublishDisabled={isImageUploading || isFormSubmitting}
      isSubmitting={isFormSubmitting}
      isConfirmModalVisible={isConfirmModalVisible}
      onSubmit={handleSubmit}
      onCancel={() => setIsConfirmModalVisible(false)}
      isDraft={isDraft}
    />
  )

  const tab: TabT = {
    key: Tabs.OnboardingSlideshowContent,
    title: t('title'),
    subtabs: [
      {
        key: Tabs.OnboardingSlideshowContent,
        component: (
          <Formsy
            onChange={(data: FormSlideData & Config, formChanged: boolean) => {
              if (!formChanged) return
              blockOrder.forEach(orderId => {
                updateSlide(orderId, data[orderId])
              })
              updateConfig({ settings: data.settings })
            }}
            onValidSubmit={() => setIsConfirmModalVisible(true)}
          >
            <OnboardingSlideshowContent
              slides={formModel.slides}
              config={formModel.config}
              blockOrder={blockOrder}
              setBlockOrder={setBlockOrder}
              onNewBlock={onNewBlock}
              sidebar={sidebar}
              isDraft={isDraft}
              isFetching={isFetching}
            />
          </Formsy>
        ),
        title: t('content.title'),
      },
      {
        key: Tabs.OnboardingSlideshowStyle,
        component: (
          <Formsy
            onChange={(data: Config['controller'], formChanged: boolean) => {
              if (!formChanged) return
              updateConfig({ controller: data })
            }}
            onValidSubmit={() => setIsConfirmModalVisible(true)}
          >
            <OnboardingSlideshowStyle
              config={formModel.config}
              sidebar={sidebar}
              isFetching={isFetching}
            />
          </Formsy>
        ),
        title: t('style.title'),
      },
    ],
  }

  return {
    tab,
  }
}

type getSubmitPayloadT = {
  blockOrder: Array<string>
  formModel: FormModel
  onboardingSlideshow?: OnboardingSlideshow | null
}

function getSubmitPayload({
  blockOrder,
  formModel,
  onboardingSlideshow,
}: getSubmitPayloadT): OnboardingSlideshowUpdatePayload['onboarding_slideshow'] {
  const slideUpdates: OnboardingSlideshowUpdatePayload['onboarding_slideshow']['slides'] =
    []

  blockOrder.forEach((orderId, index) => {
    const {
      image: { id: imageId },
      type,
      content_description,
      button_description,
    } = formModel.slides[orderId]
    const slide = (onboardingSlideshow?.slides ?? []).find(
      s => `${s.id}` === orderId
    )

    const file_uploads = (() => {
      if (!imageId) return

      return {
        image: {
          file_upload_id: imageId,
        },
      }
    })()

    slideUpdates.push({
      id: slide?.id,
      position: index + 1,
      slide_type: type,
      content_description: content_description
        ? content_description
        : accessibilityT(`defaults.${type}.content`),
      button_description: button_description
        ? button_description
        : accessibilityT(`defaults.${type}.button`),
      file_uploads,
    })
  })

  const deletedSlides = (onboardingSlideshow?.slides ?? [])
    .filter(({ id }) => blockOrder.indexOf(`${id}`) === -1)
    .map(({ id }) => ({
      id,
      destroy: true,
    }))

  return {
    slides: slideUpdates.concat(deletedSlides),
    config: formModel.config,
  }
}
