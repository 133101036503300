import { Switch } from '@thanx/uikit/switch'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

type Props = {
  leftMetric: React.ReactNode
  rightMetric?: React.ReactNode
}

const MetricSection: React.FC<Props> = ({ leftMetric, rightMetric }) => {
  let leftSection = leftMetric || rightMetric
  let rightSection = rightMetric !== leftSection ? rightMetric : null

  if (!leftSection) return null
  return (
    <div className="container padding-bottom-large">
      <Row>
        <Col
          md={rightSection ? 5 : 12}
          className="d-flex flex-column justify-content-start"
        >
          {leftSection}
        </Col>
        <Switch condition={!!rightSection}>
          <Col md={2} />
          <Col
            md={5}
            className="padding-top-huge-md d-flex flex-column justify-content-start"
          >
            {rightSection}
          </Col>
        </Switch>
      </Row>
    </div>
  )
}

export default MetricSection
