export default {
  title: 'Stop this campaign',
  description:
    'This campaign will stop sending to customers immediately. (Issued rewards will still be redeemable until they expire)',
  cancelButton: 'Cancel',
  continueButton: 'Stop campaign',
  error: 'Something went wrong. Please try again or contact support.',
  alert:
    'Your campaign “%{name}” was stopped. You can find it in the “Complete” tab on the “Reporting” page for campaigns.',
}
