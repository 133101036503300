import notConfiguredImage from 'assets/images/cms/not-configured.png'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import CmsPage from 'scenes/Cms/components/CmsPage'

const t = buildTranslate('cms.landing.not_configured')

const NotConfiguredPage: React.FC = () => {
  return (
    <div className="grey-05-bkg border-top-1 pb-xxl grey-20-border mt-l">
      <Container className="pt-xl">
        <CmsPage
          title={t('title')}
          description={t('description')}
          contactButtonTitle={t('contact')}
        >
          <img
            className="max-width-100 mb-xl"
            src={notConfiguredImage}
            alt={t('image_alt')}
          />
        </CmsPage>
      </Container>
    </div>
  )
}

export default NotConfiguredPage
