import ArrowDown from 'components/ArrowDown'
import ArrowUp from 'components/ArrowUp'
import React from 'react'

type Props = {
  pointsValue: number
  className: string
  color: string
}

const Arrow: React.FC<Props> = props => {
  const { pointsValue, className, color } = props

  if (pointsValue === 0) {
    return null
  }

  const ArrowComponent = pointsValue > 0 ? ArrowUp : ArrowDown

  return (
    <span className={className}>
      <ArrowComponent color={color} />
    </span>
  )
}

export default Arrow
