import { Text } from '@thanx/uikit/text'
import Counter from 'components/Counter'
import { buildTranslate } from 'locales'
import { LifecycleMetric, ShortType } from 'models/MerchantMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  metrics,
  stagesMetricsMappingWithDetailedReport,
  stagesWithDetailedReport,
} from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { selectMerchantMetricByType } from 'selectors/merchantMetric'
import { numberFormatter } from 'utilities/formatters'

const t = buildTranslate('lifecycle_report.detail.all_time_conversion')

type Props = {
  stage: typeof stagesWithDetailedReport[number]
}

const AllTimeConversion: React.FC<Props> = ({ stage }) => {
  const metric = stagesMetricsMappingWithDetailedReport[stage]
  const memberLifecycleState = useSelector(state =>
    selectMerchantMetricByType(state, ShortType.LIFECYCLE)
  ) as LifecycleMetric
  if (!memberLifecycleState) {
    return null
  }

  const count = memberLifecycleState.data && memberLifecycleState.data[metric]
  let conversionRate = ''

  if (memberLifecycleState.data) {
    const previousStageIndex = metrics.indexOf(metric) - 1
    const previousStage = metrics[previousStageIndex]
    const previousCount = memberLifecycleState.data[previousStage]
    conversionRate = ((100 * count) / previousCount).toFixed(1)
  }

  return (
    <>
      <Text.Header3 bold color="grey90" className="mt-0 mb-m">
        {t('title')}
      </Text.Header3>
      <div className="d-flex">
        <div className="mr-xxl">
          <Counter
            primaryValue={`${conversionRate}%`}
            isLoading={!!memberLifecycleState.isLoading}
            isErrored={!!memberLifecycleState.isErrored}
            name={
              <>
                {t(`${stage}.conversion_rate.description`)}
                <br />
                {t('all_time')}
              </>
            }
            sections={[
              {
                content: t(`${stage}.conversion_rate.tooltips.description`),
              },
              {
                title: t('tooltips.title.how_it_works'),
                content: t(`${stage}.conversion_rate.tooltips.how_it_works`),
              },
              {
                title: t('tooltips.title.recency'),
                content: t('tooltips.recency'),
              },
            ]}
          />
        </div>
        <div>
          <Counter
            primaryValue={numberFormatter(count)}
            isLoading={!!memberLifecycleState.isLoading}
            isErrored={!!memberLifecycleState.isErrored}
            name={
              <>
                {t(`${stage}.count.description`)}
                <br />
                {t('all_time')}
              </>
            }
            sections={[
              {
                content: t(`${stage}.conversion_rate.tooltips.description`),
              },
              {
                title: t('tooltips.title.recency'),
                content: t('tooltips.recency'),
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default AllTimeConversion
