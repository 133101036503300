import { Switch } from '@thanx/uikit/switch'
import { getLocation } from 'actions/location'
import useDispatch from 'hooks/useDispatch'
import type { Fields as Campaign } from 'models/Campaign'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import configs from 'scenes/CampaignCenter/Report/configs'
import { selectLocation } from 'selectors/location'
import PerformanceMetricsContainer from '../../Shared/Summary/components/PerformanceMetricsContainer'
import RevenueMetricContainer from '../../Shared/Summary/components/RevenueMetricContainer'
import ConversionMetric from './components/ConversionMetric'

type Props = {
  campaign: Campaign
  hasDivider: boolean
}

const Summary: React.FC<Props> = ({ campaign, hasDivider }) => {
  const location = useSelector(state =>
    selectLocation(state, campaign.config_location_id)
  )
  const config = configs[campaign.type]
  const locationId = campaign.config_location_id
  const locationName = (!!location && location.fullName()) || ''

  const dispatch = useDispatch()

  useEffect(() => {
    if (!location && locationId) {
      dispatch(getLocation(locationId))
    }
  }, [locationId, location, dispatch])

  const objective = I18n.t(
    `thanx_campaigns.report.campaign_types.${campaign.type}.objective`,
    { location: locationName }
  )

  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <div className="summary-section">
          <p className="body-text-1 margin-bottom-extra-large">
            {
              <Translate
                value="thanx_campaigns.report.summary.title"
                objective={objective}
              />
            }
          </p>
          <div
            className="
        display-flex flex-wrap negative-margin-left-small
        negative-margin-right-small margin-bottom-large
        "
          >
            <Switch condition={!!config.hasRevenue}>
              <div className="header-box flex-1 margin-small">
                <RevenueMetricContainer campaign={campaign} />
              </div>
            </Switch>
            <div className="header-box flex-2 margin-small">
              <ConversionMetric
                campaign={campaign}
                locationName={locationName}
              />
            </div>
          </div>
          <div>
            <PerformanceMetricsContainer campaign={campaign} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Summary
