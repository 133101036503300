import {
  GET_CAMPAIGN_REQUIREMENTS_SUCCESS,
  ResolvedAction,
} from 'actions/campaignRequirements'
import { RESET_DB } from 'actions/orm'
import { attr, fk, Model, ModelType } from 'redux-orm'

export interface Fields {
  id: string
  campaign_id: number
  variant_id: number
  name: string
  description: string
}

// Contains changes required for a campaign to be scheduled
// @attr campaign [Campaign]
// @attr name [string] name of the requirement
// @attr description [string] description of the requirement
export default class CampaignRequirement extends Model<
  // @ts-ignore
  typeof CampaignRequirement,
  Fields
> {
  static modelName: string = 'CampaignRequirement'

  static get fields(): any {
    return {
      campaign_id: fk({
        to: 'Campaign',
        as: 'campaign',
        relatedName: 'requirements',
      }),
      name: attr(),
      description: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    CampaignRequirement: ModelType<CampaignRequirement>
  ) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_CAMPAIGN_REQUIREMENTS_SUCCESS:
        // clear out all requirements with every request to ensure that we
        // don't have on requirements that could invalidly prevent a campaign
        // from being scheduled
        CampaignRequirement.all().delete()
        action.payload.data.requirements.forEach(requirement => {
          let id: string

          if (requirement.variant_id) {
            id = `${requirement.campaign_id}-${requirement.variant_id}${requirement.name}`
          } else {
            id = `${requirement.campaign_id}${requirement.name}`
          }

          CampaignRequirement.upsert({
            ...requirement,
            id: id,
            name: requirement.name,
            description: requirement.description,
          })
        })
        break
      case RESET_DB:
        CampaignRequirement.all().delete()
        break
      default:
        break
    }
  }
}
