import { useStyletron } from '@thanx/uikit/theme'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import BillingPage from 'scenes/Billing/components/BillingPage'

const t = buildTranslate('billing')

const Billing = () => {
  const [css] = useStyletron()
  return (
    <ProtectedPageHeader
      title={t('title')}
      resource={'Billing'}
      action={'access'}
    >
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div
        className={`grey-05-bkg border-top-1 pb-xxl grey-20-border mt-l ${css({
          minHeight: `calc(100% - 50px)`,
        })}`}
      >
        <Container className="pt-xl">
          <BillingPage />
        </Container>
      </div>
    </ProtectedPageHeader>
  )
}

export default Billing
