import { Checkbox } from '@thanx/uikit/checkbox'
import FormsyCheckbox from '@thanx/uikit/checkbox/FormsyCheckbox'
import { Radio } from '@thanx/uikit/radio'
import { getRadioMarkOuterStyle } from '@thanx/uikit/radio/style'
import { useStyletron } from '@thanx/uikit/theme'
import React, { SyntheticEvent } from 'react'
import { merchantTheme } from 'theme'

type PropsT = {
  image?: React.ReactElement
  imageClassName?: string
  type?: 'radio' | 'checkbox' | 'formsycheckbox'
  children: React.ReactNode
  onClick?: (event: SyntheticEvent<HTMLLabelElement>) => void
  checked?: boolean
  className?: string
  disabled?: boolean
  value: string | boolean
  name?: string
  onChange?: (event: SyntheticEvent<HTMLLabelElement>) => void
}

const RichSelector: React.FC<PropsT> = props => {
  const {
    image,
    imageClassName = 'ml-xxxl',
    type = 'radio',
    children,
    checked,
    className,
    disabled,
    name,
    value,
    onChange,
    onClick,
  } = props

  const isRadio = type === 'radio'

  const dynamicImageClassName = `${imageClassName} ${
    disabled ? 'opacity-50' : ''
  }`
  const backgroundColor = disabled
    ? merchantTheme.colors.grey10
    : merchantTheme.colors.white
  const borderColor = disabled
    ? merchantTheme.colors.grey20
    : checked
    ? merchantTheme.colors.primary
    : merchantTheme.colors.grey30
  const imageColor = disabled
    ? merchantTheme.colors.grey90
    : checked
    ? merchantTheme.colors.primary
    : undefined

  const [css] = useStyletron()
  const ClonedImage = !!image
    ? React.cloneElement(image, {
        color: imageColor,
        className: dynamicImageClassName,
      })
    : null

  let Component
  switch (type) {
    case 'radio':
      const sharedOverrides = {
        RadioMarkOuter: {
          width: '20px',
          height: '20px',
        },
      }
      const overrides = disabled
        ? {
            RadioMarkOuter: {
              style: () => ({
                backgroundColor: merchantTheme.colors.grey10,
                outline: `${merchantTheme.colors.grey40} solid 1px`,
                ...sharedOverrides.RadioMarkOuter,
              }),
            },
          }
        : {
            RadioMarkOuter: {
              style: styleArgs => ({
                ...getRadioMarkOuterStyle(styleArgs),
                ...sharedOverrides.RadioMarkOuter,
              }),
            },
          }

      Component = (
        <div className="mt-n2">
          <Radio
            disabled={disabled}
            overrides={overrides}
            value={`${value}`}
            checked={checked}
          />
        </div>
      )
      break
    case 'checkbox':
      Component = <Checkbox disabled={disabled} checked={checked} />
      break
    case 'formsycheckbox':
      Component = (
        <FormsyCheckbox
          // @ts-ignore
          disabled={disabled}
          value={value}
          name={name!}
          checked={checked}
        />
      )
      break
    default:
      break
  }

  return (
    <label
      onClick={e => {
        if (type !== 'radio') e.preventDefault()
        if (!disabled) {
          onChange?.(e)
          onClick?.(e)
        }
      }}
      className={`${className} w-100 d-flex p-m align-items-start ${css({
        transition:
          'border-color 300ms ease-out; background-color 300ms ease-out;',
        borderRadius: '4px',
        backgroundColor,
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor,
        ':hover': {
          borderColor:
            !checked && !disabled ? merchantTheme.colors.grey90 : borderColor,
        },
      })}`}
    >
      {Component}
      <div
        className={`${disabled ? 'opacity-50' : ''} w-100 ${
          isRadio ? 'pl-xs' : ''
        }`}
      >
        {children}
      </div>
      {ClonedImage}
    </label>
  )
}

export default RichSelector
