import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { BenchmarkT, tooltipPercentage } from 'scenes/Reports/helpers'
import { generateUikitTheme } from 'theme'

const t = buildTranslate('reports.benchmark_tooltip')

type PropsT = {
  benchmark: BenchmarkT
  merchantType: string
}

const BenchmarkTooltip: React.FC<PropsT> = ({ benchmark, merchantType }) => {
  const styletronEngine = new StyletronEngine({ prefix: 'st-ctt-' })
  const uikitTheme = generateUikitTheme()
  const { rate, isGeneric } = benchmark

  return (
    <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
      <Text.Header3 className="mb-xs mt-0" tag="div">
        {tooltipPercentage(rate, 1, 1)}
      </Text.Header3>
      <Switch
        condition={!isGeneric}
        fallback={
          <Text.BodyText4 color="grey70" tag="div">
            {t('caption')[2]}
          </Text.BodyText4>
        }
      >
        <Text.BodyText4 color="grey70" tag="div">
          {t('caption')[0]}
        </Text.BodyText4>
        <Text.BodyText4 color="grey70" tag="div" bold>
          {merchantType}
        </Text.BodyText4>
        <Text.BodyText4 color="grey70" tag="div">
          {t('caption')[1]}
        </Text.BodyText4>
      </Switch>
    </StyleProvider>
  )
}

export default BenchmarkTooltip
