import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { DateRangeFilterContext } from 'components/DateFilter/DateFilterContext'
import Error from 'components/Icons/Error'
import Spinner from 'components/Spinner'
import palette from 'constants/palette'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import {
  ActivityTimeSeriesMetric,
  ShortType,
} from 'models/PointsExperienceMetric'
import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { renderToString } from 'react-dom/server'
import { useSelector } from 'react-redux'
import ChartTooltip from 'scenes/Points/Landing/Activity/components/ChartTooltip'
import {
  getActivityChartSeriesGroups,
  tooltipWidth,
} from 'scenes/Points/Landing/Activity/utilities'
import { selectPointsExperienceMetricByType } from 'selectors/pointsExperienceMetric'
import { getDateLabel } from 'utilities/date'
import AdvancedOptions from './AdvancedOptions'
import { AdvancedOptionsContext } from './AdvancedOptionsContext'

const t = buildTranslate('points.activity.activity_chart')

const ActivityChart: React.FC = () => {
  const showExpiredPoints = useFlag('show-points-expired-report', false)
  const { options: advancedOptions } = useContext(AdvancedOptionsContext)
  const { filter } = useContext(DateRangeFilterContext)
  const { range } = filter
  const [css] = useStyletron()

  const activityTimeSeriesMetric = useSelector(state =>
    selectPointsExperienceMetricByType(
      state,
      range === 'all_time'
        ? ShortType.ACTIVITY_TIME_SERIES_ALL_TIME
        : ShortType.ACTIVITY_TIME_SERIES_CUSTOM
    )
  ) as ActivityTimeSeriesMetric

  if (!activityTimeSeriesMetric) {
    return null
  }

  const { isErrored, isLoading, granularity, data, time_zone } =
    activityTimeSeriesMetric

  const unformattedSeriesData = data?.values ?? []

  const categories = unformattedSeriesData.map(record => record.date_time)
  const seriesGroups = getActivityChartSeriesGroups(
    unformattedSeriesData,
    advancedOptions.hideImportedPoints
  )
  const hasImportedData = seriesGroups.imported.some(amount => amount > 0)
  const seriesData: Highcharts.SeriesOptionsType[] = [
    {
      id: 'issued',
      name: t('issued'),
      data: seriesGroups.issued,
      color: palette.wisteria,
      type: 'column',
    },
    {
      id: 'redeemed',
      name: t('redeemed'),
      data: seriesGroups.redeemed,
      color: palette.spearmint,
      type: 'column',
    },
  ]
  if (showExpiredPoints) {
    seriesData.push({
      id: 'expired',
      name: t('expired'),
      data: seriesGroups.expired,
      color: palette.belizeHole,
      type: 'column',
    })
  }

  const caption = time_zone
    ? t('timezone', { timeZone: moment.tz(time_zone).format('z') })
    : ''

  const options: Highcharts.Options = {
    series: seriesData,
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Lato',
        overflow: 'visible !important',
      },
    },
    caption: {
      text: caption,
      style: { color: palette.grey70, fontFamily: 'Lato', fontSize: '12px' },
      align: 'right',
      floating: true,
    },
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return renderToString(
          <ChartTooltip
            date_time={this.point.category}
            granularity={granularity}
            counterValue={this.point.y ?? 0}
            counterTitle={t(this.series.userOptions.id ?? '')}
            timeZone={time_zone}
          />
        )
      },
      backgroundColor: palette.white,
      borderWidth: 1,
      borderColor: palette.grey20,
      borderRadius: 4,
      outside: true,
      useHTML: true,
      shadow: false,
      padding: 0,
      style: {
        width: tooltipWidth,
        whiteSpace: 'normal',
      },
    },
    xAxis: {
      categories,
      title: {
        text: t('x_axis_title'),
        margin: 25,
        style: { 'font-weight': 'bold', color: palette.grey90 },
      },
      labels: {
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject
        ) {
          return getDateLabel(String(this.value), granularity, time_zone)
        },
        style: {
          fontSize: '12px',
          color: palette.grey70,
        },
      },
      tickLength: 0,
      lineColor: palette.grey30,
    },
    yAxis: {
      type: 'linear',
      title: {
        text: t('y_axis_title'),
        margin: 25,
        style: {
          'font-weight': 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          fontSize: '12px',
          color: palette.grey70,
        },
      },
      gridLineDashStyle: 'LongDash',
      gridLineColor: palette.grey40,
    },
    legend: {
      align: 'left',
      symbolHeight: 12,
      symbolRadius: 0,
      x: 30,
      itemStyle: {
        color: palette.grey90,
        fontSize: '14px',
      },
      itemHiddenStyle: {
        color: palette.grey30,
        fontSize: '14px',
      },
    },
    title: undefined,
    credits: { enabled: false },
  }

  return (
    <div className="mt-m">
      <div
        className={`w-100 h-100 pl-0 pr-s py-l white-bkg border-1 grey-20-border position-relative ${css(
          {
            borderRadius: '4px',
          }
        )}`}
      >
        <div className="d-flex flex-align-center flex-space-between mb-l">
          <div className="d-flex ml-l align-items-center mr-s">
            <Spinner isLoading={isLoading} />
            {isErrored && (
              <div style={{ lineHeight: '20px' }}>
                <Error />
                <Text.BodyText4
                  className="ml-xs align-middle"
                  color={palette.cayenne50}
                >
                  {t('error')}
                </Text.BodyText4>
              </div>
            )}
          </div>
        </div>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <AdvancedOptions hasImportedData={hasImportedData} />
      </div>
    </div>
  )
}

export default ActivityChart
