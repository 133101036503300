import ExpandableList from 'components/ExpandableList'
import MultiSelectWithFormsy from 'components/Form/MultiSelectWithFormsy'
import SearchPlaceholder from 'components/Form/MultiSelectWithFormsy/SearchPlaceholder'
import { buildTranslate } from 'locales'
import { Fields as Location } from 'models/Location'
import React, { useState } from 'react'
import { ControlLabel, FormGroup } from 'react-bootstrap'
import { locationsToSelectOptions } from 'utilities/locationUtils'

type MultiSelectConfig = React.ComponentProps<typeof MultiSelectWithFormsy>
type Props = MultiSelectConfig & {
  label?: string
  locations: Location[]
  onChange?: (values: number[]) => void
  defaultLocationIds: number[]
}

const translate = buildTranslate(
  'thanx_campaigns.builder.forms.audience.locationRefinement'
)

const LocationPlaceholder = props => (
  <SearchPlaceholder
    getText={count => translate('location.placeholder', { count })}
    {...props}
  />
)

/**
 * Formsy connected select for locations that includes
 * a list of selected locations below the input
 */
const LocationSelect: React.FC<Props> = props => {
  const {
    defaultLocationIds = [],
    label,
    locations,
    onChange,
    ...multiSelectProps
  } = props

  const [selectedLocationIds, setSelectedLocationIds] =
    useState(defaultLocationIds)

  const selectedLocations = locations.filter(location =>
    selectedLocationIds.includes(location.id)
  )

  const onSelectChange = (value: Array<number>) => {
    setSelectedLocationIds(value)
    if (onChange) {
      onChange(value)
    }
  }

  const select = (
    <FormGroup>
      <ControlLabel className="body-text-4 bold">{label}</ControlLabel>
      <MultiSelectWithFormsy
        {...multiSelectProps}
        options={locationsToSelectOptions(props.locations)}
        defaultValue={props.defaultLocationIds}
        onChange={onSelectChange}
        components={{
          Placeholder: LocationPlaceholder,
        }}
      />
    </FormGroup>
  )

  return (
    <div>
      {select}
      <ExpandableList
        maxItems={10}
        getShowMoreText={count => translate('location.expand', { count })}
      >
        {selectedLocations.map(location => (
          <p
            key={location.id}
            className="margin-top-large margin-bottom-none font-size-14 grey-70"
          >
            {location.fullName()}
          </p>
        ))}
      </ExpandableList>
    </div>
  )
}

export default LocationSelect
