import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { pointsApi } from 'api/points'
import Help from 'components/Help'
import PointsMultiplierCard from 'components/PointsMultiplierCard'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { helpArticles } from './helpers'

type Props = { canEdit: boolean; pointsExperience: PointsExperience }

const t = buildTranslate('points.configuration.multipliers')

function Multipliers(props: Props) {
  const { canEdit, pointsExperience } = props
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const { data, isLoading } = pointsApi.useGetMultipliersQuery(
    pointsExperience.id
  )

  const pointsMultipliers = data?.points_multipliers ?? []

  return (
    <section className="grey-90">
      <div className="d-flex align-items-start justify-content-between">
        <div className="mb-xl">
          <Text.Header4>{t('title')}</Text.Header4>
          <Text.BodyText3 color="grey70">
            {t('several_multipliers_rule')}
          </Text.BodyText3>
        </div>
        {canEdit && (
          <Button
            className={`px-l ${css({
              width: '210px',
            })}`}
            onClick={() =>
              dispatch(
                push(
                  `/points/experiences/${pointsExperience.id}/multipliers/new`
                )
              )
            }
          >
            {t('add')}
          </Button>
        )}
      </div>

      {isLoading && (
        <Spinner className="mt-xl text-center" isLoading size="2x" />
      )}
      {!isLoading && (
        <Switch
          condition={pointsMultipliers.length > 0}
          fallback={<Text.BodyText3>{t('empty')}</Text.BodyText3>}
        >
          {pointsMultipliers.map(pointsMultiplier => (
            <PointsMultiplierCard
              pointsMultiplier={pointsMultiplier}
              pointsExperience={pointsExperience}
              isEditable={canEdit}
              key={pointsMultiplier.id}
            />
          ))}
        </Switch>
      )}
      <Help title={t('help_title')} articles={helpArticles.multipliers} />
    </section>
  )
}

export default Multipliers
