import { Switch } from '@thanx/uikit/switch'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap-five'
import Nav from 'react-bootstrap-five/Nav'
import Tab from 'react-bootstrap-five/Tab'
import { history } from 'store'
import Item from './Item'
import TabGroup from './TabGroup'

export interface TabT {
  key: string
  title: string
  component?: React.ReactNode
  subtabs?: TabT[]
}

type Props = {
  tabs: TabT[]
}

const InnerTabs: React.FC<Props> = props => {
  const { tabs } = props
  const [activeTab, setActiveTab] = useState<string>(() => {
    const param = history.location.hash.replace('#', '')
    const defaultTab =
      tabs.find(t => {
        if (t.key === param) return true
        return t.subtabs?.some(t => t.key === param)
      }) || tabs[0]

    if (defaultTab.subtabs) {
      const defaultSubtab =
        defaultTab.subtabs.find(t => t.key === param) || defaultTab.subtabs[0]
      return defaultSubtab.key
    }

    return defaultTab.key
  })

  const setTab = tab => {
    history.replace({ hash: tab })
    setActiveTab(tab)
  }

  return (
    <Tab.Container
      activeKey={activeTab}
      transition={false}
      onSelect={tab => setTab(tab)}
    >
      <Row className="mt-m">
        <Col className="p-0" md="2">
          <Nav className="flex-column border-none">
            {tabs.map(tab => (
              <Switch
                key={tab.key}
                condition={!tab.subtabs}
                fallback={<TabGroup tab={tab} activeTab={activeTab} />}
              >
                <Item
                  className="px-m border-radius-5"
                  tabKey={tab.key}
                  title={tab.title}
                  isActive={tab.key === activeTab}
                />
              </Switch>
            ))}
          </Nav>
        </Col>
        <Col className="pl-xl" md="9">
          <Tab.Content>
            {tabs.map(({ key, component, subtabs }) => (
              <React.Fragment key={key}>
                <Switch condition={!!component}>
                  <Tab.Pane key={key} className="grey-05-bkg" eventKey={key}>
                    {component}
                  </Tab.Pane>
                </Switch>
                {subtabs?.map(({ key, component }) => (
                  <Tab.Pane key={key} className="grey-05-bkg" eventKey={key}>
                    {component}
                  </Tab.Pane>
                ))}
              </React.Fragment>
            ))}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default InnerTabs
