import PopoverInfo from 'components/PopoverInfo'
import React from 'react'
import { useIsMobile } from 'utilities/responsive'

type Props = {
  title: string
  info?: string
  isActive?: boolean
  children: React.ReactNode
  className?: string
}

const Card: React.FC<Props> = ({
  title,
  info,
  isActive = true,
  children,
  className = '',
}) => {
  const isMobile = useIsMobile()

  return (
    <div
      className={`card mb-m ${isMobile ? 'p-m' : 'p-xl'} ${
        isActive ? 'white-bkg' : 'grey-10-bkg'
      } ${className}`}
    >
      <div className={`d-flex align-items-center ${isActive ? 'mb-s' : ''}`}>
        <h4 className="extra-bold mr-xs">{title}</h4>
        {!!info && <PopoverInfo text={info} />}
      </div>
      {children}
    </div>
  )
}

export default Card
