import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
}

const Discount: React.FC<PropsT> = props => {
  const { campaignId } = props

  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'unscoped_redeem')
    ) as RedeemMetric) || {}
  const primaryValue = currencyFormatter(
    metric?.data?.total_discount || 0,
    false
  )
  const secondaryValue = t('rewards_accounting.avg_discount', {
    value: currencyFormatter(metric?.data?.avg_discount || 0),
  })

  return (
    <InfoRow
      title={t('rewards_accounting.discount')}
      primaryContent={
        <Metric metric={primaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('rewards_accounting.tooltips.number_of_discount'),
              },
              {
                title: t('how_its_calc'),
                content: t(
                  'rewards_accounting.tooltips.calc_of_number_discount'
                ),
              },
              {
                title: t('example'),
                content: t(
                  'rewards_accounting.tooltips.number_discount_example_content1'
                ),
              },
              {
                content: t(
                  'rewards_accounting.tooltips.number_discount_example_content2'
                ),
              },
              {
                content: t(
                  'rewards_accounting.tooltips.number_discount_example_content3'
                ),
              },
              {
                content: t(
                  'rewards_accounting.tooltips.number_discount_example_content4'
                ),
              },
            ]}
          />
        </Metric>
      }
      secondaryContent={
        <Metric primary={false} metric={secondaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('rewards_accounting.tooltips.per_of_discount'),
              },
              {
                title: t('how_its_calc'),
                content: t('rewards_accounting.tooltips.calc_of_per_discount'),
              },
            ]}
          />
        </Metric>
      }
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default Discount
