import React from 'react'
import { Table } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import ActivityRow from './ActivityRow'

import type {
  LinkActivityData,
  MessageCountMetric,
} from 'models/CampaignMetric'

type Props = {
  linkActivity: LinkActivityData
  messageCount?: MessageCountMetric['data']
}

const ActivityTable: React.FC<Props> = ({ linkActivity, messageCount }) => {
  const rows = () => {
    const totalUniqueViews = messageCount?.viewed_count

    return linkActivity.link_clicks
      .sort((a, b) => {
        return (
          (b.unique_count || b.count || 0) - (a.unique_count || a.count || 0)
        )
      })
      .map((linkData, i) => {
        return (
          <ActivityRow
            key={i}
            data={linkData}
            totalUniqueViews={totalUniqueViews}
          />
        )
      })
  }

  return (
    <Table className="link-activity" responsive>
      <thead>
        <tr>
          <th>
            <Translate value="thanx_campaigns.report.link_activity.activity_table.header.column_one" />
          </th>
          <th>
            <Translate value="thanx_campaigns.report.link_activity.activity_table.header.column_two" />
          </th>
          <th>
            <Translate value="thanx_campaigns.report.link_activity.activity_table.header.column_three" />
          </th>
          <th>
            <Translate value="thanx_campaigns.report.link_activity.activity_table.header.column_four" />
          </th>
        </tr>
      </thead>
      <tbody>{rows()}</tbody>
    </Table>
  )
}

export default ActivityTable
