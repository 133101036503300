import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import ContentContainer from 'components/ContentContainer'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import flatMap from 'lodash/flatMap'
import sum from 'lodash/sum'
import React from 'react'
import { numberFormatter } from 'utilities/formatters'

const t = buildTranslate(
  'thanx_campaigns.review.customers.custom.breakout_modal'
)

type items = {
  description: string
  count: number
}[]

type sections = {
  title: string
  items: items
  id: string
}[]

type Props = {
  show: boolean
  sections: sections
  isLoading?: boolean
  isErrored?: boolean
  onModalCloseClicked: () => void
}

const AudienceBreakoutModalv3: React.FC<Props> = props => {
  const { show, sections, isLoading, isErrored, onModalCloseClicked } = props

  const [css] = useStyletron()

  let total = numberFormatter(
    sum(
      flatMap(sections, section => {
        return section.items.map(item => {
          return item.count
        })
      })
    )
  )

  return (
    <Modal
      title={t('title')}
      subtitle={t('subtitle', { customer_count: total })}
      proceedText={t('next')}
      onProceed={onModalCloseClicked}
      isOpen={show}
      onClose={onModalCloseClicked}
      hideCancel={true}
    >
      <div className="pt-m">
        {sections.map(section => {
          return (
            <div className="pb-m" key={section.id}>
              <Text.BodyText4 bold color="grey90" tag="p">
                {section.title}
              </Text.BodyText4>
              {section.items.map(item => {
                return (
                  <div
                    className={`d-flex p-m ${css({
                      borderBottom: `1px solid ${defaultTheme.colors.grey30}`,
                    })}`}
                    key={item.description}
                  >
                    <Text.BodyText4
                      color="grey70"
                      className={`${css({ minWidth: '220px' })}`}
                    >
                      {item.description}
                    </Text.BodyText4>
                    <div className="d-flex flex-1 justify-content-end">
                      <ContentContainer
                        isErrored={isLoading || false}
                        isLoading={isErrored || false}
                      >
                        <Text.BodyText4 color="grey70">
                          {numberFormatter(item.count)}
                        </Text.BodyText4>
                      </ContentContainer>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div className="d-flex pr-m">
        <Text.BodyText4 bold color="grey90">
          {t('total')}
        </Text.BodyText4>
        <div className="d-flex flex-1 text-right justify-content-end">
          <ContentContainer
            isErrored={isLoading || false}
            isLoading={isErrored || false}
          >
            <Text.BodyText4 bold color="grey90">
              {total}
            </Text.BodyText4>
          </ContentContainer>
        </div>
      </div>
    </Modal>
  )
}

export default AudienceBreakoutModalv3
