import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React from 'react'
import { useFormManagement } from 'scenes/CampaignCenter/Builder/components/FormManagementContext'
import GoldenTicketRestrictions from 'scenes/CampaignCenter/Builder/components/Incentive/RestrictionCreator/NdrRewardRestrictions/GoldenTicketRestrictions'
import RewardLength from '../RewardLength'

type Props = {
  onClose: () => void
  campaign: Campaign
  config: VariantOrCampaign
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator'
)

const NdrRewardRestrictions: React.FC<Props> = props => {
  const { onClose, campaign, config } = props
  const { canProceed } = useFormManagement()
  const isHiddenMenu = config.redeem_type === 'experience/hidden_menu'
  return (
    <>
      <Switch
        condition={isHiddenMenu}
        fallback={
          <GoldenTicketRestrictions campaign={campaign} config={config} />
        }
      >
        <RewardLength
          campaign={campaign}
          t={buildTranslate(
            'thanx_campaigns.builder.steps.incentive.restriction_creator.hidden_menu'
          )}
        />
      </Switch>
      <div className="text-right margin-top-extra-large">
        <Button disabled={!canProceed} onClick={onClose}>
          {t('okay')}
        </Button>
      </div>
    </>
  )
}
export default NdrRewardRestrictions
