import { Text } from '@thanx/uikit/text'
import CRRPromo from 'assets/images/banners/CRRpromo.png'
import FetchableMetricSection from 'components/FetchableMetricSection'
import Locations from 'components/LocationSelector'
import PromoLink from 'components/PromoLink'
import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Table from 'scenes/Reports/components/Table'
import { selectChannelTimeSeriesMetric } from 'selectors/captureRateMetric'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import { useAbility } from 'utilities/ability'
import Chart from './components/Chart'
import Row from './components/Row'
import Stats from './components/Stats'

type PropsT = {
  merchant
}

const RevenueTab: React.FC<PropsT> = props => {
  const { merchant } = props
  const { currentLocation } = useContext(LocationContext)
  const merchantUser = useSelector(selectCurrentMerchantUser)
  const [reFetchAt, setReFetchAt] = useState<number | null>(null)

  useEffect(() => {
    setReFetchAt(new Date().getTime())
  }, [currentLocation?.id])

  const ability = useAbility()

  const metrics = useSelector(state =>
    selectChannelTimeSeriesMetric(state, merchant.id, currentLocation?.id)
  )

  const { isErrored, isLoading, values = [] } = metrics ?? {}

  return (
    <>
      <Helmet>
        <title>{I18n.t('titles.revenue')}</title>
      </Helmet>
      <Container>
        {merchant.merchant_type !== 'Mall' && (
          <Locations
            hideAll={merchantUser
              ?.rolesForMerchantId(merchant.id)
              .includes('manager')}
          />
        )}
        <Text.Header3 bold className="mb-xl">
          {I18n.t('revenue.tabs.revenue.title')}
        </Text.Header3>
        <FetchableMetricSection
          type="capture_rate_time_series_metric"
          merchantId={merchant.id}
          shouldFetch
          reFetchAt={reFetchAt}
          filterByChannel
          locationId={currentLocation?.id}
        >
          <Stats merchant={merchant} />
          <Chart merchant={merchant} />
          <Table
            isErrored={!!isErrored}
            isLoading={!!isLoading}
            values={values}
            headers={[
              'date_time',
              'member_purchases_instore_amount',
              'member_purchases_digital_amount',
              'member_purchases_total_amount',
            ]}
            t={buildTranslate('revenue.tabs.revenue.table')}
            headerClassNames={['w-50']}
            initialSortAttribute={'date_time'}
          >
            {Row}
          </Table>
        </FetchableMetricSection>
        <div className="mt-xl">
          {ability.can('manage', 'all') && (
            <PromoLink
              image={CRRPromo}
              alt="capture rate report"
              link="/reports/capture_rate"
            />
          )}
        </div>
      </Container>
    </>
  )
}

export default RevenueTab
