import { withFormsy as formsyWrapper } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import React, { Component } from 'react'
import Checkbox from './Checkbox'

type Props = {
  isChecked?: boolean
  checkedValue: any
  uncheckedValue: any
  labelText?: string
  onChange?: (value: any) => void
  checkedClass?: string
  disabledClass?: string
  uncheckedClass?: string
  labelClass?: string
  isDisabled?: boolean
} & PassDownProps<string | null>

type State = {
  isChecked: boolean | undefined
}

export class CheckboxWithFormsy extends Component<Props, State> {
  static defaultProps = {
    uncheckedValue: '',
  }

  constructor(props: Props) {
    super(props)
    const self: any = this
    self.state = {
      isChecked: !!this.props.isChecked,
    }
  }

  componentWillMount() {
    const { setValue, checkedValue, uncheckedValue } = this.props
    setValue(this.state.isChecked ? checkedValue : uncheckedValue)
  }

  componentWillReceiveProps(nextProps: Props) {
    const { checkedValue, setValue, isChecked, uncheckedValue } = this.props
    if (this.state.isChecked && nextProps.checkedValue !== checkedValue) {
      setValue(nextProps.checkedValue)
    }

    if (isChecked !== nextProps.isChecked) {
      this.setState({ isChecked: nextProps.isChecked }, () => {
        let value = this.state.isChecked ? checkedValue : uncheckedValue
        setValue(value)
      })
    }
  }

  onChange() {
    this.setState({ isChecked: !this.state.isChecked }, this.toggle)
  }

  toggle() {
    const { checkedValue, uncheckedValue, setValue, onChange } = this.props
    let value = this.state.isChecked ? checkedValue : uncheckedValue
    setValue(value)
    if (onChange) onChange(value)
  }

  render() {
    const {
      isDisabled,
      disabledClass,
      uncheckedClass,
      checkedClass,
      labelClass,
      labelText,
    } = this.props
    return (
      <Checkbox
        isDisabled={!!isDisabled}
        disabledClass={disabledClass}
        isChecked={!!this.state.isChecked}
        checkedClass={checkedClass}
        uncheckedClass={uncheckedClass}
        labelText={labelText}
        labelClass={labelClass}
        onChange={this.onChange.bind(this)}
      />
    )
  }
}

export default formsyWrapper(CheckboxWithFormsy)
