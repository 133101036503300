import { Switch } from '@thanx/uikit/switch'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import sortBy from 'lodash/sortBy'
import { CampaignStateT, Fields as Campaign } from 'models/Campaign'
import React from 'react'
import Tab from 'react-bootstrap-five/Tab'
import { useCampaignStatePagination } from '../hooks'
import Table from './Table'
import { useTabsState } from './TabsState'

type Props = {
  state: CampaignStateT
  campaigns: Campaign[]
  isLoading: boolean
}

const CampaignTabPane: React.FC<Props> = ({ state, campaigns, isLoading }) => {
  const { isFetchingPage, currentPage, numPages, onChangePage, campaignIds } =
    useCampaignStatePagination(state)

  const campaignsOnPage = campaigns.filter(campaign =>
    campaignIds.includes(campaign.id)
  )

  const sortedCampaigns = sortBy(
    campaignsOnPage,
    campaign => -new Date(campaign.start_at || campaign.updated_at).valueOf()
  )

  const { setActiveTab } = useTabsState()
  const spinner = (
    <Spinner
      isLoading={isLoading || isFetchingPage}
      size="4x"
      className="d-flex justify-content-center pt-xl"
    />
  )
  return (
    <Tab.Pane eventKey={state} className="pt-xxl px-m">
      <Switch condition={!isLoading && !isFetchingPage} fallback={spinner}>
        <div className="table-responsive margin-bottom-huge padding-bottom-huge">
          <Table
            campaigns={sortedCampaigns}
            state={state}
            onStop={() => setActiveTab('complete')}
          />
          <Switch condition={sortedCampaigns.length > 0}>
            <div className={'d-flex justify-content-center margin-top-huge'}>
              <Pagination
                currentPage={currentPage}
                onChangePage={page => onChangePage(page)}
                numPages={numPages}
              />
            </div>
          </Switch>
        </div>
      </Switch>
    </Tab.Pane>
  )
}

export default CampaignTabPane
