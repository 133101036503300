'use strict'
var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i]
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
        }
        return t
      }
    return __assign.apply(this, arguments)
  }
var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value)
          })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator['throw'](value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done
          ? resolve(result.value)
          : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
var __generator =
  (this && this.__generator) ||
  function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function () {
          if (t[0] & 1) throw t[1]
          return t[1]
        },
        trys: [],
        ops: [],
      },
      f,
      y,
      t,
      g
    return (
      (g = { next: verb(0), throw: verb(1), return: verb(2) }),
      typeof Symbol === 'function' &&
        (g[Symbol.iterator] = function () {
          return this
        }),
      g
    )
    function verb(n) {
      return function (v) {
        return step([n, v])
      }
    }
    function step(op) {
      if (f) throw new TypeError('Generator is already executing.')
      while (_)
        try {
          if (
            ((f = 1),
            y &&
              (t =
                op[0] & 2
                  ? y['return']
                  : op[0]
                  ? y['throw'] || ((t = y['return']) && t.call(y), 0)
                  : y.next) &&
              !(t = t.call(y, op[1])).done)
          )
            return t
          if (((y = 0), t)) op = [op[0] & 2, t.value]
          switch (op[0]) {
            case 0:
            case 1:
              t = op
              break
            case 4:
              _.label++
              return { value: op[1], done: false }
            case 5:
              _.label++
              y = op[1]
              op = [0]
              continue
            case 7:
              op = _.ops.pop()
              _.trys.pop()
              continue
            default:
              if (
                !((t = _.trys), (t = t.length > 0 && t[t.length - 1])) &&
                (op[0] === 6 || op[0] === 2)
              ) {
                _ = 0
                continue
              }
              if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                _.label = op[1]
                break
              }
              if (op[0] === 6 && _.label < t[1]) {
                _.label = t[1]
                t = op
                break
              }
              if (t && _.label < t[2]) {
                _.label = t[2]
                _.ops.push(op)
                break
              }
              if (t[2]) _.ops.pop()
              _.trys.pop()
              continue
          }
          op = body.call(thisArg, _)
        } catch (e) {
          op = [6, e]
          y = 0
        } finally {
          f = t = 0
        }
      if (op[0] & 5) throw op[1]
      return { value: op[0] ? op[1] : void 0, done: true }
    }
  }
exports.__esModule = true
var jsx_runtime_1 = require('react/jsx-runtime')
var campaigns_1 = require('actions/campaigns')
var flash_1 = require('actions/flash')
var useDispatch_1 = require('hooks/useDispatch')
var react_bootstrap_1 = require('react-bootstrap')
var react_redux_1 = require('react-redux')
var react_redux_i18n_1 = require('react-redux-i18n')
var react_router_bootstrap_1 = require('react-router-bootstrap')
var hooks_1 = require('scenes/CampaignCenter/Builder/hooks')
var campaignType_1 = require('selectors/campaignType')
var ability_1 = require('utilities/ability')
var campaignUtils_1 = require('utilities/campaignUtils')
var ReportLink = function (_a) {
  var campaign = _a.campaign
  var dispatch = (0, useDispatch_1['default'])()
  var ability = (0, ability_1.useAbility)()
  var showV3 = (0, hooks_1.useShowV3)(campaign.type)
  var campaignType = (0, react_redux_1.useSelector)(function (state) {
    //@ts-ignore
    return (0,
    campaignType_1.selectCampaignTypeByType__deprecated)(state.orm, campaign.type)
  })
  function clickCancelButton() {
    return __awaiter(this, void 0, void 0, function () {
      var action
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [
              4 /*yield*/,
              dispatch((0, campaigns_1.cancelCampaign)(campaign.id)),
            ]
          case 1:
            action = _a.sent()
            if (action.error) {
              dispatch(
                (0, flash_1.alert)({
                  key: 'danger',
                  message: react_redux_i18n_1.I18n.t(
                    'thanx_campaigns.landing.reporting.cancel_error'
                  ),
                  error: action.error.message ? action.error.message : null,
                })
              )
            }
            return [2 /*return*/]
        }
      })
    })
  }
  var alternateButton =
    campaign.state !== 'scheduled'
      ? null
      : (0, jsx_runtime_1.jsx)(
          react_bootstrap_1.Button,
          __assign(
            { bsStyle: 'link', onClick: clickCancelButton },
            {
              children: react_redux_i18n_1.I18n.t(
                'thanx_campaigns.landing.reporting.cancel_button'
              ),
            }
          )
        )
  var linkInfo = !!campaignType
    ? (0, campaignUtils_1.reportLink)(campaign, campaignType, showV3)
    : null
  var textSource =
    linkInfo === null || linkInfo === void 0 ? void 0 : linkInfo.state
  if (ability.cannot('review', 'Campaign') && textSource === 'in_review') {
    textSource = 'draft'
  }
  var text = react_redux_i18n_1.I18n.t(
    'thanx_campaigns.landing.reporting.table.cta.'.concat(textSource)
  )
  if (
    (linkInfo === null || linkInfo === void 0 ? void 0 : linkInfo.state) ===
    'unavailable'
  )
    return null
  if (
    (linkInfo === null || linkInfo === void 0 ? void 0 : linkInfo.type) ===
    'linkcontainer'
  ) {
    return (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, {
      children: [
        alternateButton,
        (0, jsx_runtime_1.jsx)(
          react_router_bootstrap_1.LinkContainer,
          __assign(
            { to: linkInfo.href },
            {
              children: (0, jsx_runtime_1.jsx)(
                react_bootstrap_1.Button,
                __assign({ bsStyle: 'link' }, { children: text })
              ),
            }
          )
        ),
      ],
    })
  }
  return (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, {
    children: [
      alternateButton,
      (0, jsx_runtime_1.jsx)(
        react_bootstrap_1.Button,
        __assign(
          {
            bsStyle: 'link',
            href:
              linkInfo === null || linkInfo === void 0 ? void 0 : linkInfo.href,
          },
          { children: text }
        )
      ),
    ],
  })
}
exports['default'] = ReportLink
