import { useStyletron } from '@thanx/uikit/theme'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as UserItem } from 'models/UserItem'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.view.activity.purchased_items')

type PropsT = {
  items: Array<UserItem>
  onDetailsClick?: (item: UserItem) => void
}

const Table: React.FC<PropsT> = ({ items, onDetailsClick }: PropsT) => {
  const [css] = useStyletron()

  return (
    <table
      className={`full-width ${css({
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
      })}`}
    >
      <thead>
        <tr>
          <HeaderCell className={`${css({ width: '45%' })}`}>
            {t('item')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '30%' })} text-right`}>
            {t('number_of_times')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '25%' })}`} key={'last'} />
        </tr>
      </thead>
      <tbody>
        {items.map(item => (
          <Row
            key={`purchased_items_${item.id}`}
            item={item}
            onDetailsClick={onDetailsClick}
          />
        ))}
      </tbody>
    </table>
  )
}

export default Table
