import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { externalFormsApi } from 'api/externalForms'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import React from 'react'
import { ContentBlockData } from '../hooks'
import AddContentButton from './AddContentButton'
import Description from './Description'
import { getImageUrl } from './helper'

type Props = {
  data: ContentBlockData
  onEdit: () => void
}

const t = buildTranslate(
  'cms.content.app_home_page.content.block_item.external_form'
)

const ExternalFormDescription = ({ data, onEdit }: Props) => {
  const [css] = useStyletron()
  const { data: formResponse, isLoading } =
    externalFormsApi.useGetExternalFormsQuery()
  const forms = formResponse?.external_forms || []

  if (data.type !== 'external_form') return null

  const {
    image,
    settings: { form_id },
  } = data
  const form = forms.find(form => form.id === form_id)

  const imageSrc = getImageUrl(image)
  if (imageSrc && form_id) {
    return (
      <div>
        <img
          src={imageSrc}
          className={`mb-s ${css({ height: '115px' })}`}
          alt={t('title')}
        />
        <Text.BodyText4 tag="p" color="grey90" bold className="mb-s">
          {t('form_description')}
        </Text.BodyText4>
        <Text.BodyText4 tag="p" color="grey70" className="mb-s">
          <Spinner isLoading={isLoading}>
            {form_id ? form?.name : t('no_form_id')}
          </Spinner>
        </Text.BodyText4>
      </div>
    )
  }

  return (
    <>
      <Description type={data.type} />
      <AddContentButton onClick={onEdit} />
    </>
  )
}

export default ExternalFormDescription
