export const avgDayTrajectory = (data, keySuffix) => {
  const oneYear = data[`365_day_${keySuffix}`]
  return (
    oneYear &&
    (data[`30_day_${keySuffix}`] > (oneYear / 365) * 30 ? 'up' : 'down')
  )
}

export const oneYearTrajectory = (data, keySuffix) => {
  const oneYear = data[`365_day_${keySuffix}`]
  return oneYear && (data[`30_day_${keySuffix}`] > oneYear ? 'up' : 'down')
}
