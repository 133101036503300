import { updatePaginationCurrentPage } from 'actions/pagination'
import { getUserRewards } from 'actions/users'
import useDispatch from 'hooks/useDispatch'
import { Fields as UserProfile } from 'models/UserProfile'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPagination } from 'selectors/pagination'
import { selectUserRewards } from 'selectors/userReward'

export const useRewardsTable = (profile: UserProfile) => {
  const dispatch = useDispatch()
  const [isFetchingRewards, setIsFetchingRewards] = useState(false)
  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'user_reward', profile.uid)
  )
  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const rewardIds = paginationInfo?.record_ids?.[currentPage] ?? []
  const rewards = useSelector(state =>
    selectUserRewards(state, profile.id)
  ).filter(reward => rewardIds.includes(reward.id))

  const fetchRewards = useCallback(
    async (page: number) => {
      setIsFetchingRewards(true)
      await dispatch(getUserRewards(profile.uid, { page }))
      setIsFetchingRewards(false)
    },
    [dispatch, profile.uid]
  )

  const refetchCurrentPage = useCallback(async () => {
    return fetchRewards(currentPage)
  }, [dispatch, profile.uid, currentPage])

  const onChangePage = useCallback(
    async (page: number) => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // rewards for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'user_reward',
            id: profile.uid,
            page,
          })
        )
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      fetchRewards(page)
    },
    [dispatch, paginationInfo, fetchRewards, profile.uid]
  )

  useEffect(() => {
    onChangePage(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangePage])

  return {
    isFetchingRewards,
    currentPage,
    numPages,
    rewards,
    onChangePage,
    refetchCurrentPage,
  }
}
