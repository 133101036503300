import { Button, Kind } from '@thanx/uikit/button'
import React from 'react'
import { merchantTheme } from 'theme'
import { Assign } from 'utility-types'

type PropsT = Assign<
  React.ComponentProps<typeof Button>,
  {
    children
  }
>

const InlineButton: React.FC<PropsT> = props => {
  const { children, ...restProps } = props
  return (
    <Button
      kind={Kind.MINIMAL}
      size="mini"
      {...restProps}
      overrides={{
        BaseButton: {
          style: {
            padding: 0,
            backgroundColor: 'transparent',
            color: merchantTheme.colors.primary,
            fontSize: 'inherit',
            transition: 'all 0.3s ease',
            ':hover': {
              backgroundColor: 'transparent',
              color: merchantTheme.colors.primary200,
            },
            ':active': {
              backgroundColor: 'transparent',
              color: merchantTheme.colors.primary200,
            },
            ':visited': {
              backgroundColor: 'transparent',
              color: merchantTheme.colors.primary200,
            },
            ':disabled': {
              backgroundColor: 'transparent',
              color: merchantTheme.colors.primary200,
            },
          },
        },
      }}
    >
      {children}
    </Button>
  )
}

export default InlineButton
