import { Switch } from '@thanx/uikit/switch'
import React from 'react'
import { I18n, Localize, Translate } from 'react-redux-i18n'
import Comparison from './components/Comparison'

type Props = {
  hasControlGroup: boolean
  notEnoughData: boolean
  targetFraction: number
  controlFraction: number
  comparisonDescription: string
  successPhrase: string
  hasDivider: boolean
  color: string
}

const View: React.FC<Props> = props => {
  const {
    hasControlGroup,
    notEnoughData,
    targetFraction,
    controlFraction,
    comparisonDescription,
    successPhrase,
    hasDivider,
    color,
  } = props
  const greyscale = !hasControlGroup || notEnoughData

  function controlMetric() {
    if (!hasControlGroup) {
      return <span className="grey">$</span>
    }

    if (notEnoughData) {
      return <span className="grey">~</span>
    }
    const fraction = controlFraction
    const minimumFractionDigits = fraction < 0.1 ? 1 : 0
    return (
      <Localize
        value={fraction}
        options={{ style: 'percent', minimumFractionDigits }}
      />
    )
  }

  function targetMetric() {
    if (!hasControlGroup) {
      return <span className="grey">$$</span>
    }

    if (notEnoughData) {
      return <span className="grey">~</span>
    }

    const fraction = targetFraction
    const minimumFractionDigits = fraction < 0.1 ? 1 : 0
    return (
      <Localize
        value={fraction}
        options={{ style: 'percent', minimumFractionDigits }}
      />
    )
  }

  function getComparisonDescription() {
    if (notEnoughData || !hasControlGroup) {
      return null
    }

    return <span className="body-text-2">{comparisonDescription}</span>
  }

  function description() {
    if (!hasControlGroup) {
      return (
        <Translate value="thanx_campaigns.report.control_group.prompt_enable" />
      )
    }

    if (notEnoughData) {
      return (
        <Translate value="thanx_campaigns.report.control_group.not_enough_data" />
      )
    }

    if (targetFraction <= controlFraction) {
      return (
        <Translate value="thanx_campaigns.report.control_group.description_negative" />
      )
    }

    return (
      <Translate
        dangerousHTML
        value="thanx_campaigns.report.control_group.description"
        color={color}
        success_phrase={successPhrase}
        multiple={I18n.l(multiple(), {
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
        })}
      />
    )
  }

  function multiple() {
    if (controlFraction && targetFraction) {
      return targetFraction / controlFraction
    }
  }

  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <div
          className={`control-group-section ${greyscale ? 'grayscale' : ''}`}
        >
          <div className="margin-bottom-huge">
            <Comparison
              color={color}
              controlMetric={controlMetric()}
              controlFraction={controlFraction}
              targetMetric={targetMetric()}
              targetFraction={targetFraction}
              description={getComparisonDescription()}
            />
          </div>
          <h4 className="deprecated__h4 text-center">{description()}</h4>
        </div>
      </div>
    </div>
  )
}

export default View
