import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import Badges from 'components/Badges'
import Spinner from 'components/Spinner'
import React from 'react'
import { Col, Row } from 'react-bootstrap-five'

type Props = {
  title: string
  description: string
  isLoading?: boolean
  contactButtonTitle?: string
  badges?: string[]
  badgeDescriptions?: string[]
  className?: string
  sidebar?: React.ReactNode
  children: React.ReactNode
}

const CmsPage: React.FC<Props> = props => {
  const {
    title,
    description,
    isLoading = false,
    contactButtonTitle,
    badges,
    badgeDescriptions,
    className = '',
    sidebar,
    children,
  } = props

  const [css] = useStyletron()

  return (
    <div className={className}>
      <h3 className="mt-0">{title}</h3>
      <Spinner
        isLoading={isLoading}
        size="4x"
        className="text-center margin-top-huge"
      >
        <Row>
          <Col md={sidebar ? 9 : 12}>
            <p
              className="mb-m grey-70"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            <Badges
              className="mb-m"
              values={badges}
              badgeDescriptions={badgeDescriptions}
            />
            <div>{children}</div>
            <Switch condition={!!contactButtonTitle}>
              <Button
                onClick={() =>
                  window.open('mailto:merchant.support@thanx.com', '_blank')
                }
              >
                {contactButtonTitle}
              </Button>
            </Switch>
          </Col>
          <Switch condition={!!sidebar}>
            <Col md={3}>
              <div className={css({ width: '200px' })}>{sidebar}</div>
            </Col>
          </Switch>
        </Row>
      </Spinner>
    </div>
  )
}

export default CmsPage
