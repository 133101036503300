import { LifecycleMetric, ShortType } from 'models/MerchantMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { calculateLifecycleOpportunityMetrics } from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { selectMerchantMetricByType } from 'selectors/merchantMetric'
import Carousel from './Carousel'

type PropsT = {
  onSwitchTabAction: (tab: any) => void
}

const OpportunityCarousel: React.FC<PropsT> = ({ onSwitchTabAction }) => {
  const memberLifecycleState = useSelector(state =>
    selectMerchantMetricByType(state, ShortType.LIFECYCLE)
  ) as LifecycleMetric

  if (!memberLifecycleState) {
    return null
  }

  const { isLoading, isErrored, data } = memberLifecycleState

  const opportunityMetrics = calculateLifecycleOpportunityMetrics(data!)

  return (
    <Carousel
      metrics={opportunityMetrics}
      isLoading={!!isLoading}
      isErrored={!!isErrored}
      onSwitchTabAction={onSwitchTabAction}
    />
  )
}

export default OpportunityCarousel
