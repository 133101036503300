import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import type { Fields as Merchant } from '../models/Merchant'
import orm from '../models/orm'

export const selectMerchants = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Merchant[] => session.Merchant.all().toModelArray()
)

export const selectMerchant = createSelector(
  selectMerchants,
  (_, id): number => id,
  (merchants, id): Merchant | null =>
    merchants.find(m => m.id === Number(id)) ?? null
)

export const selectCurrentMerchant = state => {
  const merchantId = state.auth.current_merchant_id
  return selectMerchant(state, merchantId) || ({} as Merchant)
}
