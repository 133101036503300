import { getTiersConfig } from 'actions/config'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTiersConfig } from 'selectors/config'
import CmsPage from '../../components/CmsPage'
import AdditionalAssets from './AdditionalAssets'
import Item from './Item'
import NotEnabledPage from './NotEnabledPage'

const t = buildTranslate('cms.brand.tiers')

const Tiers: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const config = useSelector(selectTiersConfig)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await dispatch(getTiersConfig())
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  if (!config) {
    return <NotEnabledPage />
  }

  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      isLoading={isLoading}
    >
      <Item key={t('tier_bronze')} tier={config?.bronze_tier} />
      <Item key={t('tier_silver')} tier={config?.silver_tier} />
      <Item key={t('tier_gold')} tier={config?.gold_tier} />
      <AdditionalAssets
        progressBarImage={config?.progress_bar_image_urls?.small}
        paddleImage={config?.gold_tier?.progress_paddle_image_urls?.small}
      />
    </CmsPage>
  )
}

export default Tiers
