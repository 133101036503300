import ProtectedPageHeader from 'components/ProtectedPageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import AppDownloadsTab from './AppDownloadsTab'
import PurchasesTab from './PurchasesTab'
import RedemptionsTab from './RedemptionsTab'
import SignupsTab from './SignupsTab'

const t = buildTranslate('reports.daily_activity')
enum Tabs {
  SIGNUPS = 'signups',
  PURCHASES = 'purchases',
  REDEMPTIONS = 'redemptions',
  APP_DOWNLOADS = 'app_downloads',
}

const DailyActivityReport: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    'signups' | 'purchases' | 'redemptions' | 'app_downloads'
  >('signups')
  const pageTitle = t(`tabs.${activeTab}.title_tag`)

  return (
    <ProtectedPageHeader
      title={t('title')}
      resource="LocationReports"
      action="access"
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Tab.Container
        activeKey={activeTab}
        transition={false}
        onSelect={(tab: string | null) => {
          if (!tab) return
          setActiveTab(tab as Tabs)
        }}
      >
        <TabNav>
          <TabItem
            state={Tabs.SIGNUPS}
            title={
              <div className="d-flex align-content-center">
                <span className="mr-xxs">{t('tabs.signups.title')}</span>
              </div>
            }
          />
          <TabItem state={Tabs.PURCHASES} title={t('tabs.purchases.title')} />
          <TabItem
            state={Tabs.REDEMPTIONS}
            title={t('tabs.redemptions.title')}
          />
          <TabItem
            state={Tabs.APP_DOWNLOADS}
            title={t('tabs.app_downloads.title')}
          />
        </TabNav>
        <Tab.Content className="pb-l h-100 grey-05-bkg">
          <TabPane state={Tabs.SIGNUPS}>
            <SignupsTab />
          </TabPane>
          <TabPane state={Tabs.PURCHASES}>
            <PurchasesTab />
          </TabPane>
          <TabPane state={Tabs.REDEMPTIONS}>
            <RedemptionsTab />
          </TabPane>
          <TabPane state={Tabs.APP_DOWNLOADS}>
            <AppDownloadsTab />
          </TabPane>
        </Tab.Content>
      </Tab.Container>
    </ProtectedPageHeader>
  )
}

export default DailyActivityReport
