import { useStyletron } from '@thanx/uikit/theme'
import { Order } from 'api/orders'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.view.activity.orders')

type PropsT = {
  orders: Array<Order>
  onDetailsClick?: (order: Order) => void
}

const Table: React.FC<PropsT> = ({ orders, onDetailsClick }: PropsT) => {
  const [css] = useStyletron()

  return (
    <table
      className={`full-width ${css({
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
      })}`}
    >
      <thead>
        <tr>
          <HeaderCell>{t('provider_order_uid')}</HeaderCell>
          <HeaderCell>{t('handoff')}</HeaderCell>
          <HeaderCell>{t('status')}</HeaderCell>
          <HeaderCell>{t('total')}</HeaderCell>
          <HeaderCell>{t('placed_at')}</HeaderCell>
          <HeaderCell></HeaderCell>
        </tr>
      </thead>
      <tbody>
        {orders.map(order => (
          <Row
            key={`orders_${order.id}`}
            order={order}
            onDetailsClick={onDetailsClick}
          />
        ))}
      </tbody>
    </table>
  )
}

export default Table
