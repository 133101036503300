import { Text } from '@thanx/uikit/text'
import ContentContainer from 'components/ContentContainer'
import React from 'react'
import { useStyletron } from 'styletron-react'
import { merchantTheme } from 'theme'

type Props = {
  title: string
  subtitle?: string
  isErrored?: boolean
  isLoading?: boolean
  primaryContent: JSX.Element
  secondaryContent?: JSX.Element
}
const InfoRow: React.FC<Props> = props => {
  const {
    title,
    subtitle,
    isErrored = false,
    isLoading = false,
    primaryContent,
    secondaryContent,
  } = props
  const [css] = useStyletron()

  return (
    <div
      className={`p-m ${css({
        borderBottomColor: merchantTheme.colors.grey30,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
      })}`}
    >
      <div className="d-flex justify-content-between">
        <Text.BodyText4 bold color="grey70" className="flex-1">
          {title}
          <Text.BodyText5 className="ml-xs" color="inherit">
            {subtitle}
          </Text.BodyText5>
        </Text.BodyText4>
        <ContentContainer isErrored={isErrored} isLoading={isLoading}>
          <div className="d-flex flex-column align-items-end">
            <div>{primaryContent}</div>
            <div>{secondaryContent}</div>
          </div>
        </ContentContainer>
      </div>
    </div>
  )
}

export default InfoRow
