'use strict'
var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i]
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
        }
        return t
      }
    return __assign.apply(this, arguments)
  }
exports.__esModule = true
var jsx_runtime_1 = require('react/jsx-runtime')
var button_1 = require('@thanx/uikit/button')
var text_1 = require('@thanx/uikit/text')
var locales_1 = require('locales')
var TabsState_1 = require('../TabsState')
var t = (0, locales_1.buildTranslate)('thanx_campaigns.landing.reporting.empty')
var Empty = function (props) {
  var state = props.state
  var setActiveTab = (0, TabsState_1.useTabsState)().setActiveTab
  return (0, jsx_runtime_1.jsxs)(
    'div',
    __assign(
      { className: 'text-center' },
      {
        children: [
          (0, jsx_runtime_1.jsx)(
            text_1.Text.Header4,
            __assign(
              { color: 'grey90', bold: true, className: 'mt-0' },
              {
                children:
                  state === 'in_review'
                    ? t('title.in_review')
                    : t('title.general', { state: state }),
              }
            )
          ),
          (0, jsx_runtime_1.jsx)(
            text_1.Text.BodyText3,
            __assign(
              { color: 'grey70' },
              { children: t('body.'.concat(state)) }
            )
          ),
          (0, jsx_runtime_1.jsx)(
            'div',
            __assign(
              { className: 'mt-m' },
              {
                children:
                  state !== 'draft' &&
                  (0, jsx_runtime_1.jsx)(
                    button_1.Button,
                    __assign(
                      {
                        onClick: function () {
                          return setActiveTab('draft')
                        },
                        kind: button_1.Kind.SECONDARY,
                      },
                      { children: t('button') }
                    )
                  ),
              }
            )
          ),
        ],
      }
    )
  )
}
exports['default'] = Empty
