import { pointsApi, PointsMultiplierPayload } from 'api/points'
import { isError } from 'api/utils'
import Loading from 'components/Loading'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import Helmet from 'react-helmet'
import Form from './Form'
import { useDisplayError, useGetPointsMultipler } from './hooks'

const t = buildTranslate('points.multipliers.edit_multiplier')

type Props = {
  pointsExperience: PointsExperience
  multiplierId: number
}

const EditMultiplier = ({ pointsExperience, multiplierId }: Props) => {
  const displayError = useDisplayError()
  const { multiplier, isFetching } = useGetPointsMultipler(
    multiplierId,
    pointsExperience
  )

  const [updateMultiplier, { isLoading }] =
    pointsApi.useUpdateMultiplierMutation()

  const handleSubmit = async (
    payload: PointsMultiplierPayload
  ): Promise<Boolean> => {
    const response = await updateMultiplier(payload)
    displayError(response, t('error_generic'))
    return !isError(response)
  }

  return (
    <>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      {isFetching && <Loading />}
      {!isFetching && multiplier && (
        <Form
          multiplier={multiplier}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          confirmTitle={t('confirmation.title')}
          confirmSubtitle={t('confirmation.subtitle')}
          confirmProceedText={t('confirmation.proceed_text')}
        />
      )}
    </>
  )
}

export default EditMultiplier
