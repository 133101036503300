import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import Rows from './Rows'

const t = buildTranslate(
  'thanx_campaigns.report.v3.summary.engagement.email.link_activity'
)

type PropsT = {
  campaignId: number
}

const LinkActivity: React.FC<PropsT> = props => {
  const { campaignId } = props

  return (
    <div id="link-activity" className="mb-xl">
      <Text.BodyText4 color="grey90" bold tag="h4" className="mb-l">
        {t('title')}
      </Text.BodyText4>
      <table className="w-100">
        <thead>
          <tr>
            <th className="px-m pb-xs" style={{ width: '15%' }}>
              <Text.BodyText4 color="grey90" bold>
                {t('headers.type')}
              </Text.BodyText4>
            </th>
            <th className="px-m pb-xs" style={{ width: '35%' }}>
              <Text.BodyText4 color="grey90" bold>
                {t('headers.text')}
              </Text.BodyText4>
            </th>
            <th className="px-m pb-xs">
              <Text.BodyText4 color="grey90" bold>
                {t('headers.url')}
              </Text.BodyText4>
            </th>
            <th className="text-right px-m pb-xs">
              <Text.BodyText4 color="grey90" bold>
                {t('headers.clicked')}
              </Text.BodyText4>
            </th>
          </tr>
        </thead>
        <tbody>{<Rows campaignId={campaignId} />}</tbody>
      </table>
    </div>
  )
}

export default LinkActivity
