import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { RevenueMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
}

const Revenue: React.FC<PropsT> = props => {
  const { campaignId } = props

  const { attributionWindow } = useAttributionWindow()
  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'revenue')
    ) as RevenueMetric) || {}
  const primaryValue = currencyFormatter(metric?.data?.net_revenue, false)
  const secondaryValue = t('revenue.avg_check', {
    value: currencyFormatter(metric?.data?.avg_revenue),
  })
  return (
    <InfoRow
      title={t('revenue.revenue')}
      subtitle={t('revenue.revenue_subtext')}
      primaryContent={
        <Metric metric={primaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('revenue.tooltips.number_of_revenue', {
                  count: attributionWindow,
                }),
              },
              {
                title: t('how_its_calc'),
                content: t('revenue.tooltips.calc_of_number_revenue'),
              },
              {
                title: t('example'),
                content: t('revenue.tooltips.number_revenue_example_content1'),
              },
              {
                content: t('revenue.tooltips.number_revenue_example_content2'),
              },
              {
                content: t('revenue.tooltips.number_revenue_example_content3'),
              },
            ]}
          />
        </Metric>
      }
      secondaryContent={
        <Metric primary={false} metric={secondaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('revenue.tooltips.percent_of_revenue'),
              },
              {
                title: t('how_its_calc'),
                content: t('revenue.tooltips.calc_of_percent_revenue'),
              },
              {
                title: t('example'),
                content: t('revenue.tooltips.percent_revenue_example_content'),
              },
            ]}
          />
        </Metric>
      }
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default Revenue
