import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { useAbility } from 'utilities/ability'
import Content from './Content'

const t = buildTranslate('users.user_list')

const Landing: React.FC = () => {
  const [css] = useStyletron()
  const ability = useAbility()
  const canImportEmailSubscribers = ability.can('manage', 'LegacyReports')

  return (
    <div className="w-100 h-100 pt-m pb-xxl px-0 white-bkg border-bottom-1 grey-20-border">
      <Container className="pt-m pb-xl">
        <div className="d-flex justify-content-between">
          <Text.Header2 className="m-0 mr-xl">{t('title')}</Text.Header2>
          <div>
            {canImportEmailSubscribers && (
              <Link
                to="/subscribers/setup/connect"
                className="btn btn-primary btn-lg"
              >
                {t('import_email_subscribers')}
              </Link>
            )}
          </div>
        </div>
      </Container>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div
        className={`grey-05-bkg border-top-1 pb-xxl grey-20-border pt-xl ${css({
          minHeight: 'calc(100% - 75px)',
        })}`}
      >
        <Container>
          <Content />
        </Container>
      </div>
    </div>
  )
}

export default Landing
