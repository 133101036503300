import palette from 'constants/palette'
import React from 'react'

type Props = {
  color?: string
}

const ControlWrapped: React.FC<Props> = ({ color = palette.grey90 }) => {
  return (
    <svg
      width="80"
      height="41"
      viewBox="0 0 88 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H84C85.1046 2 86 2.89543 86 4V54C86 55.1046 85.1046 56 84 56H4C2.89543 56 2 55.1046 2 54V4C2 2.89543 2.89543 2 4 2ZM0 4C0 1.79086 1.79086 0 4 0H84C86.2091 0 88 1.79086 88 4V54C88 56.2091 86.2091 58 84 58H4C1.79086 58 0 56.2091 0 54V4ZM57.3306 40.8335C57.6118 41.1147 58.0804 41.021 58.3616 40.6929L58.8302 40.1306C59.1114 39.8026 59.0177 39.334 58.7365 39.0997L54.3322 35.6532C54.3327 35.6351 54.333 35.6169 54.333 35.5986V24.3486C54.333 23.333 53.4736 22.4736 52.458 22.4736H37.4906L30.7129 17.1698C30.3849 16.8886 29.9163 16.9823 29.6351 17.2635L29.1665 17.8726C28.8853 18.2007 28.979 18.6692 29.3071 18.9035L34.7537 23.1658C34.4895 23.4912 34.333 23.905 34.333 24.3486V35.5986C34.333 36.6533 35.1533 37.4736 36.208 37.4736H52.458C52.6283 37.4736 52.7941 37.4506 52.9523 37.4072L57.3306 40.8335ZM52.458 28.3721V34.1865L48.7604 31.2929C50.4542 29.9461 51.6243 29.0245 52.458 28.3721ZM49.3702 28.4069C48.7636 28.8846 48.0577 29.4406 47.2292 30.0946L39.8866 24.3486H52.458V25.9502C51.8163 26.4803 50.9156 27.1897 49.3717 28.4057L49.3702 28.4069ZM45.3941 31.4925L36.2652 24.3486H36.208V25.9502C36.9071 26.554 37.9681 27.3904 39.9379 28.9432C40.3894 29.2992 40.8887 29.6928 41.4424 30.1299C41.5646 30.2233 41.7079 30.3419 41.8663 30.4729C42.5689 31.054 43.5681 31.8805 44.333 31.8486C44.6525 31.8625 45.0215 31.7134 45.3941 31.4925ZM50.6412 35.5986L46.9491 32.7094C46.1858 33.2552 45.2858 33.7463 44.333 33.7236C42.8942 33.7571 41.5701 32.6728 40.6798 31.9437C40.5307 31.8216 40.3938 31.7095 40.2705 31.6143C39.7279 31.1824 39.2356 30.7914 38.789 30.4367L38.7854 30.4338C37.6584 29.5387 36.8231 28.8753 36.208 28.3721V35.5986H50.6412Z"
        fill={color}
      />
    </svg>
  )
}

export default ControlWrapped
