import PopoverContent, { Sections } from 'components/PopoverContent'
import React from 'react'

type Props = {
  label: React.ReactNode
  sections?: Sections
  tooltip?: string
  className?: string
}

/**
 * A label with an adjacent tooltip popover
 */
const TooltipLabel: React.FC<Props> = props => {
  const { label, sections, tooltip, className = '' } = props
  const newSections = sections ? sections : [{ content: tooltip || '' }]
  return (
    <span className={className}>
      <span className="mr-xs">{label}</span>
      <PopoverContent sections={newSections} />
    </span>
  )
}

export default TooltipLabel
