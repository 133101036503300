import { useStyletron } from '@thanx/uikit/theme'
import { Feedback } from 'api/feedbacks'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import Row from './Row'

const t = buildTranslate('feedbacks.responses')

type Props = {
  responses: Feedback[]
  merchantName?: string
  onView: (response: Feedback) => void
}

const Table = (props: Props) => {
  const [css] = useStyletron()
  const { responses, merchantName, onView } = props

  return (
    <table className="full-width">
      <thead>
        <tr>
          <HeaderCell className={`${css({ width: '5%' })}`}>
            {t('table.headers.nps')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '10%' })}`}>
            {t('table.headers.urgency')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '10%' })}`}>
            {t('table.headers.response')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '10%' })}`}>
            {t('table.headers.customer')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '45%' })}`}>
            {t('table.headers.feedback')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '15%' })}`}>
            {t('table.headers.date')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '5%' })}`} />
        </tr>
      </thead>
      <tbody>
        {responses.map(response => (
          <Row
            response={response}
            merchantName={merchantName}
            onView={onView}
          />
        ))}
      </tbody>
    </table>
  )
}

export default Table
