import React from 'react'
import { Translate } from 'react-redux-i18n'
import PercentMetric from './PercentMetric'

type Props = {
  color: string
  controlMetric: React.ReactNode
  controlFraction: number
  targetMetric: React.ReactNode
  targetFraction: number
  description: React.ReactNode
}

const Comparison: React.FC<Props> = props => {
  const {
    color,
    controlMetric,
    controlFraction,
    targetMetric,
    targetFraction,
    description,
  } = props

  return (
    <div className="display-flex flex-justify-center flex-align-center flex-wrap">
      <div className="percent-metric display-flex flex-column flex-align-center">
        <h4 className="deprecated__h4 text-center margin-bottom-extra-large">
          <Translate value="thanx_campaigns.report.control_group.with_campaign" />
        </h4>
        <PercentMetric
          fraction={targetFraction}
          color={color}
          title={targetMetric}
          subtitle={description}
        />
      </div>
      <div className="margin-left-large margin-right-large margin-top-huge">
        <h4 className="deprecated__h4 text-center">
          <Translate value="thanx_campaigns.report.control_group.vs" />
        </h4>
      </div>
      <div className="percent-metric grayscale display-flex flex-column flex-align-center">
        <h4 className="deprecated__h4 text-center margin-bottom-extra-large">
          <Translate value="thanx_campaigns.report.control_group.without_campaign" />
        </h4>
        <PercentMetric
          fraction={controlFraction}
          color={color}
          title={controlMetric}
          subtitle={description}
        />
      </div>
    </div>
  )
}

export default Comparison
