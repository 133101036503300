import { Text } from '@thanx/uikit/text'
import MagnifyGlassDollarIcon from 'components/Icons/MagnifyGlassDollarIcon'
import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import { ChannelTimeSeriesMetric } from 'models/CaptureRateMetric'
import { Fields as Merchant } from 'models/Merchant'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectChannelTimeSeriesMetric } from 'selectors/captureRateMetric'

type PropsT = {
  merchant: Merchant
}

const MissingRevenueTip: React.FC<PropsT> = props => {
  const { merchant } = props

  const { currentLocation } = useContext(LocationContext)
  const captureRateMetrics = useSelector(state =>
    selectChannelTimeSeriesMetric(state, merchant.id, currentLocation?.id)
  ) as ChannelTimeSeriesMetric

  if (
    !captureRateMetrics ||
    captureRateMetrics.isErrored ||
    captureRateMetrics.isLoading
  ) {
    return null
  }

  const metricsContainNegative = captureRateMetrics?.values.some(
    dayMetrics =>
      dayMetrics.member_purchases_digital_amount < 0 ||
      dayMetrics.member_purchases_instore_amount < 0 ||
      dayMetrics.member_purchases_total_amount < 0 ||
      dayMetrics.non_member_purchases_digital_amount < 0 ||
      dayMetrics.non_member_purchases_instore_amount < 0 ||
      dayMetrics.third_party_amount < 0
  )

  if (!metricsContainNegative) {
    return null
  }

  const t = buildTranslate('capture_rate.missing_revenue_tip')

  return (
    <div className="grey-10-bkg d-flex flex-row justify-content-start p-m pr-xxl mt-m">
      <MagnifyGlassDollarIcon className="mr-s" />
      <div>
        <Text.SmallCaps3 tag="div" className="mb-s">
          {t('title')}
        </Text.SmallCaps3>
        <Text.BodyText4 tag="div" color="grey70">
          {t('body')}
        </Text.BodyText4>
      </div>
    </div>
  )
}

export default MissingRevenueTip
