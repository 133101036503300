import { Text } from '@thanx/uikit/text'
import { PointsMultiplier } from 'api/points'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import ActionsNav from './ActionsNav'
import DateRangeStatusBadge from './DateRangeStatusBadge'
import { dateRangeText, formatFactor } from './helpers'
import PreviewBadge from './PreviewBadge'

type Props = {
  pointsMultiplier: PointsMultiplier
  pointsExperience: PointsExperience
  isEditable: boolean
}

const t = buildTranslate('points.configuration.multipliers')

function PointsMultiplierCard(props: Props) {
  const { pointsMultiplier, pointsExperience, isEditable } = props

  return (
    <div className="card p-m white-bkg mt-s d-flex flex-column">
      <div className="d-flex justify-content-between">
        <DateRangeStatusBadge pointsMultiplier={pointsMultiplier} />
        {isEditable && (
          <ActionsNav
            pointsExperience={pointsExperience}
            pointsMultiplier={pointsMultiplier}
          />
        )}
      </div>
      <Text.BodyText4 bold>{t('list.appearance')}</Text.BodyText4>
      <div className="mb-m">
        <PreviewBadge
          pointsMultiplier={pointsMultiplier}
          pointsExperience={pointsExperience}
        />
      </div>
      <Text.BodyText4 className="mb-xs" bold tag="div">
        {t('list.what_you_configured')}
      </Text.BodyText4>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {`${t('list.available')} ${dateRangeText(
          pointsMultiplier.date_range_starts_on,
          pointsMultiplier.date_range_ends_on,
          'complete'
        )}`}
      </Text.BodyText4>
      <Text.BodyText4 color="grey70" tag="div">
        {t('list.factor', { factor: formatFactor(pointsMultiplier.factor) })}
      </Text.BodyText4>
    </div>
  )
}

export default PointsMultiplierCard
