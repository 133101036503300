import { Switch } from '@thanx/uikit/switch'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { useFormModel } from '../../FormModel'
import RequiresOlo from '../../RequiresOlo'
import Card from '../Card'
import NextButton from '../NextButton'
import HiddenMenu from './HiddenMenu'
import NotPurchase from './NotPurchase'

type Props = {
  isDraft: boolean
  isEnabled: boolean
  isOpen: boolean
  isCurrent: boolean
  isValidForm: boolean
  onNext: () => void
  templateId: number
  hideToggle?: boolean
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.online_redemption_card'
)

const OnlineRedemptionCard: React.FC<Props> = ({
  isDraft,
  isEnabled,
  isOpen,
  isCurrent,
  isValidForm,
  onNext,
  templateId,
  hideToggle,
}) => {
  const [isImageValid, setIsImageValid] = useState(false)
  const merchant = useCurrentMerchant()
  const { ordering_enabled } = merchant || {}
  const {
    onlineDiscountedProductIds,
    onlineRequiredProductIds,
    typeItem,
    availableInstore,
    hiddenMenuKey,
  } = useFormModel().model

  const subtype = typeItem?.subtype
  const key = typeItem?.key
  const isHiddenMenu = key === 'hidden_menu'
  // TODO: Remove this check once toast is enabled
  const showEnabled = isEnabled && !!ordering_enabled
  const displayDiscountedProducts =
    subtype === 'item' ||
    subtype === 'bogo/item' ||
    (subtype === null && !isEmpty(onlineDiscountedProductIds))
  const displayRequiredProducts =
    subtype === 'bogo/item' ||
    subtype === 'bogo/purchase' ||
    (subtype === null && !isEmpty(onlineRequiredProductIds))
  const isNextDisabled =
    !isValidForm || (!hiddenMenuKey && isHiddenMenu && !isImageValid)

  return (
    <>
      <Card
        className={isHiddenMenu && isOpen && showEnabled ? '' : 'mb-m'}
        title={t('title')}
        subtitle={isHiddenMenu && t('hidden_menu.subtitle')}
        infoSections={
          !isHiddenMenu
            ? [
                {
                  content: t('info_text'),
                },
              ]
            : undefined
        }
        isEnabled={showEnabled}
        isOpen={isOpen}
        buttonName={
          ordering_enabled && availableInstore && !hideToggle
            ? 'availableOnline'
            : undefined
        }
        disableToggleButton={!isDraft}
        onToggle={value => {
          if (isCurrent && !value) {
            onNext()
          }
        }}
      >
        <Switch
          condition={showEnabled}
          fallback={
            <div className="grey-70">
              <Switch
                condition={!ordering_enabled}
                fallback={t('not_redeemable')}
              >
                {t('not_available')}
                <a href="mailto:merchant.support@thanx.com">
                  {t('contact_us')}
                </a>
              </Switch>
              {isHiddenMenu && <RequiresOlo className="mt-m" />}
            </div>
          }
        >
          {isHiddenMenu && (
            <HiddenMenu
              templateId={templateId}
              setCanProceed={setIsImageValid}
            />
          )}
          <Switch
            condition={subtype !== 'purchase'}
            fallback={<p className="grey-70">{t('will_be_able_to_redeem')}</p>}
          >
            <NotPurchase
              discountedProductIds={onlineDiscountedProductIds}
              requiredProductIds={onlineRequiredProductIds}
              typeItem={typeItem}
            />
          </Switch>
          <Switch
            condition={displayRequiredProducts && !displayDiscountedProducts}
          >
            <p className="body-text-14 bold">{t('then_entire_purchase')}</p>
          </Switch>
          <Switch condition={isCurrent}>
            <NextButton disabled={isNextDisabled} onClick={onNext} />
          </Switch>
        </Switch>
      </Card>
      {isHiddenMenu && isOpen && showEnabled && (
        <RequiresOlo className="mb-m text-center" />
      )}
    </>
  )
}

export default OnlineRedemptionCard
