import { Button } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import React from 'react'

type PropsT = {
  text: string
  icon: React.ReactNode
  onClick: () => void
}

const UnlockButton: React.FC<PropsT> = ({ text, icon, onClick }) => {
  const [css] = useStyletron()

  return (
    <Button
      className={`mb-xs px-s py-xs ${css({
        fontWeight: 400,
        border: 0 + ' !important',
        color: palette.belizeHole + ' !important',
        backgroundColor: palette.belizeHole10 + ' !important',
      })}`}
      onClick={onClick}
    >
      <span className="mr-xs">{icon}</span>
      {text}
    </Button>
  )
}

export default UnlockButton
