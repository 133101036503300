import { api } from './index'

export type PointsMultiplier = {
  id: number
  date_range_starts_on: string | null
  date_range_ends_on: string | null
  factor: number
  points_experience_id: number
}

export type PointsMultiplierPayload = Partial<PointsMultiplier>

export const pointsApi = api.injectEndpoints({
  endpoints: builder => ({
    getMultipliers: builder.query<
      { points_multipliers: PointsMultiplier[] },
      number
    >({
      query: pointsExperienceId => ({
        url: 'points_multipliers',
        params: { points_experience_id: pointsExperienceId },
      }),
      providesTags: ['PointsMultiplier'],
    }),
    createMultiplier: builder.mutation<
      PointsMultiplier,
      PointsMultiplierPayload
    >({
      query: payload => ({
        url: 'points_multipliers',
        method: 'POST',
        body: {
          points_multiplier: payload,
        },
      }),
      invalidatesTags: ['PointsMultiplier'],
    }),
    updateMultiplier: builder.mutation<
      PointsMultiplier,
      PointsMultiplierPayload
    >({
      query: payload => ({
        url: `points_multipliers/${payload.id}`,
        method: 'PATCH',
        body: {
          points_multiplier: payload,
        },
      }),
      invalidatesTags: ['PointsMultiplier'],
    }),
    deleteMultiplier: builder.mutation<undefined, number>({
      query: id => ({
        url: `points_multipliers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['PointsMultiplier'],
    }),
  }),
})
