import { Button } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import { Popover } from '@thanx/uikit/popover'
import { Switch } from '@thanx/uikit/switch'
import { getCampaignMetricByType } from 'actions/campaignMetrics'
import { scheduleCampaign } from 'actions/campaigns'
import approvalImage from 'assets/images/campaign-center/img_approval_required.svg'
import automatedImage from 'assets/images/campaign-center/img_launch.png'
import scheduleImage from 'assets/images/campaign-center/img_schedule.png'
import sendImage from 'assets/images/campaign-center/img_send.png'
import useCurrentMerchantUser from 'hooks/useCurrentMerchantUser'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { Type as MetricType, UserCountMetric } from 'models/CampaignMetric'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import {
  Alert,
  Button as BSButton,
  Col,
  OverlayTrigger,
  Row,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import ContentWithSave from 'scenes/CampaignCenter/Builder/components/ContentWithSave'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import { Can, useAbility } from 'utilities/ability'
import userTimeZone from 'utilities/userTimeZone'
import Approvers from './Approvers'

type Props = {
  campaign: Campaign
  disabled: boolean
  isSaving: boolean
  triggerSave: () => Promise<void>
  setCurrentStep: (step: string) => void
}

const t = buildTranslate('thanx_campaigns.builder.confirm_modal')

const Help: React.FC<{ sendType: string }> = ({ sendType }) => {
  if (sendType !== 'schedule') return null
  const popover = <Popover id="info">{t('schedule.info')}</Popover>
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      overlay={popover}
      placement="top"
    >
      <i className="fa fa-question-circle grey-60" />
    </OverlayTrigger>
  )
}

const Confirmation: React.FC<Props> = props => {
  const { campaign, triggerSave, setCurrentStep, disabled, isSaving } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [errored, setErrored] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const ability = useAbility()
  const dispatch = useDispatch()
  const currentMerchantUser = useCurrentMerchantUser()
  const userCountMetric = useSelector(
    state =>
      selectCampaignMetricByType(
        state,
        campaign.id,
        MetricType.USER_COUNT
      ) as UserCountMetric | null
  )
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign.type)
  )

  const category = campaignType?.category
  const sendType = getSendType()

  function getSendType() {
    if (ability.cannot('schedule', 'Campaign')) {
      if (campaign.type === 'custom_automated') return 'custom_automated_review'
      return 'request_review'
    }
    if (
      campaign.state === 'in_review' &&
      //@ts-ignore
      currentMerchantUser.isApproverFor(campaign)
    ) {
      return 'approve'
    }
    if (campaign.type === 'custom_automated') return 'custom_automated'
    if (category === 'automated') return 'automated'
    return campaign.start_at ? 'schedule' : 'send_now'
  }

  function handleOpenClick() {
    triggerSave()
    setModalOpen(true)

    if (!userCountMetric) {
      dispatch(getCampaignMetricByType(campaign.id, 'user_count'))
    }
  }

  function closeModal() {
    setModalOpen(false)
  }

  function formatTime(time: string): string {
    return moment(time).tz(userTimeZone()).format('LL [at] LT z')
  }

  async function getScheduleCampaign() {
    setSubmitting(true)

    const response = await dispatch(scheduleCampaign(campaign.id))
    setSubmitting(false)
    //@ts-ignore
    if (response.error) {
      setErrored(true)
      return
    }
    closeModal()
    setCurrentStep('success')
  }

  function onCancelClick() {
    closeModal()
  }

  const hasValidUserCount =
    userCountMetric?.data?.count !== undefined &&
    !isNaN(userCountMetric.data.count)
  const countText = !userCountMetric
    ? '<i class="fa fa-spin fa-circle-o-notch"></i>'
    : `<strong class="grey-90">
      ${I18n.l(userCountMetric?.data?.count)}
    </strong>`

  let imgSrc: undefined | string
  let text: React.ReactNode | null = null

  switch (sendType) {
    case 'custom_automated_review':
      imgSrc = approvalImage
      text = (
        <div className="text-left mb-s">
          <div
            className="body-text-3 grey-70-text mb-m"
            dangerouslySetInnerHTML={{
              __html: t('custom_automated.body', {
                segment: campaign.config_target_name,
              }),
            }}
          />
          <Row>
            <Col sm={6}>
              <div className="small-caps-6 bold grey-90-text mb-xs">
                {t('custom_automated.start')}
              </div>
              <div className="body-text-4 grey-90-text">
                {campaign.start_at
                  ? formatTime(campaign.start_at)
                  : t('custom_automated.now')}
              </div>
            </Col>
            <Col sm={6}>
              <div className="small-caps-6 bold grey-90-text mb-xs">
                {t('custom_automated.stop')}
              </div>
              <div className="body-text-4 grey-90-text">
                {campaign.end_at
                  ? formatTime(campaign.end_at)
                  : t('custom_automated.ongoing')}
              </div>
            </Col>
          </Row>
        </div>
      )
      break
    case 'request_review':
      imgSrc = approvalImage
      text = (
        <div className="width-66 ">
          <p className="margin-bottom-medium">
            <Translate
              value={
                hasValidUserCount
                  ? 'thanx_campaigns.builder.confirm_modal.request_review.body'
                  : 'thanx_campaigns.builder.confirm_modal.request_review.body_no_count'
              }
              count={countText}
              sendAtWithTime={formatTime(campaign.start_at)}
              dangerousHTML
            />
            &nbsp;
            <Help sendType={sendType} />
          </p>
        </div>
      )
      break
    case 'approve':
      imgSrc = approvalImage
      text = (
        <div className="width-66 ">
          <p className="margin-bottom-medium">
            <Translate
              value={
                hasValidUserCount
                  ? 'thanx_campaigns.builder.confirm_modal.approve.body'
                  : 'thanx_campaigns.builder.confirm_modal.approve.body_no_count'
              }
              count={countText}
              sendAtWithTime={formatTime(campaign.start_at)}
              dangerousHTML
            />
            &nbsp;
            <Help sendType={sendType} />
          </p>
        </div>
      )
      break
    case 'send_now':
      imgSrc = sendImage
      text = (
        <div className="width-66 ">
          <p className="margin-bottom-medium">
            <Translate
              value={
                hasValidUserCount
                  ? 'thanx_campaigns.builder.confirm_modal.send_now.body'
                  : 'thanx_campaigns.builder.confirm_modal.send_now.body_no_count'
              }
              count={countText}
              dangerousHTML
            />
            &nbsp;
            <Help sendType={sendType} />
          </p>
          <Translate
            tag="p"
            value="thanx_campaigns.builder.confirm_modal.send_now.sub_text"
            dangerousHTML
          />
        </div>
      )
      break
    case 'schedule':
      imgSrc = scheduleImage
      text = (
        <div className="width-66 ">
          <p className="margin-bottom-medium">
            <Translate
              value={
                hasValidUserCount
                  ? 'thanx_campaigns.builder.confirm_modal.schedule.body'
                  : 'thanx_campaigns.builder.confirm_modal.schedule.body_no_count'
              }
              count={countText}
              sendAtWithTime={formatTime(campaign.start_at)}
              dangerousHTML
            />
            &nbsp;
            <Help sendType={sendType} />
          </p>
          <Translate
            tag="p"
            value="thanx_campaigns.builder.confirm_modal.schedule.sub_text"
            dangerousHTML
          />
        </div>
      )
      break
    case 'automated':
      imgSrc = automatedImage
      text = (
        <div className="width-66 ">
          <p className="margin-bottom-medium">
            <Translate
              value={`thanx_campaigns.builder.confirm_modal.automated.body.${campaign.type}`}
              sendAt={moment(campaign.start_at).tz(userTimeZone()).format('L')}
              dangerousHTML
            />
            &nbsp;
            <Help sendType={sendType} />
          </p>
          <Translate
            tag="p"
            value={`thanx_campaigns.builder.confirm_modal.automated.sub_text.${campaign.type}`}
            dangerousHTML
          />
        </div>
      )
      break
    case 'custom_automated':
      imgSrc = automatedImage
      text = (
        <div className="text-left mb-s">
          <div
            className="body-text-3 grey-70-text mb-m"
            dangerouslySetInnerHTML={{
              __html: t('custom_automated.body', {
                segment: campaign.config_target_name,
              }),
            }}
          />
          <Row>
            <Col sm={6}>
              <div className="small-caps-6 bold grey-90-text mb-xs">
                {t('custom_automated.start')}
              </div>
              <div className="body-text-4 grey-90-text">
                {campaign.start_at
                  ? formatTime(campaign.start_at)
                  : t('custom_automated.now')}
              </div>
            </Col>
            <Col sm={6}>
              <div className="small-caps-6 bold grey-90-text mb-xs">
                {t('custom_automated.stop')}
              </div>
              <div className="body-text-4 grey-90-text">
                {campaign.end_at
                  ? formatTime(campaign.end_at)
                  : t('custom_automated.ongoing')}
              </div>
            </Col>
          </Row>
        </div>
      )
      break
    default:
      break
  }

  return (
    <div className="inline-block">
      <BSButton
        bsSize="large"
        className="btn-inverted"
        disabled={disabled}
        onClick={handleOpenClick}
      >
        <ContentWithSave
          value={`thanx_campaigns.builder.steps.nav.buttons.${sendType}`}
          isSaving={isSaving}
        />
      </BSButton>
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        size="auto"
        closeType="small"
        zIndex={1200}
      >
        <div
          className="fs-unmask text-center w-100 p-xl"
          style={{ width: '600px' }}
        >
          <h3 className="text-center margin-bottom-huge">
            {t(`${sendType}.title`, {
              campaignName: `${sendType}.title_campaign_name.${campaignType}`,
            })}
          </h3>
          <Switch condition={!!errored}>
            <Alert bsStyle="danger">{t('error')}</Alert>
          </Switch>
          <img
            src={imgSrc}
            alt="campaign type"
            className="margin-bottom-huge"
          />

          <div className="font-size-18 text-center grey-60 padding-bottom-medium">
            <div className="d-flex justify-content-center">{text}</div>
            <Can not I="schedule" a="Campaign">
              <Approvers
                onCancelClick={onCancelClick}
                closeModal={closeModal}
                campaignId={campaign.id}
                onApprove={() => setCurrentStep('success')}
              />
            </Can>
          </div>
          <Can I="schedule" a="Campaign">
            <Row className="mt-xxl">
              <Col sm={6}>
                <Button
                  className="w-100"
                  kind="secondary"
                  onClick={onCancelClick}
                >
                  {t(`${sendType}.buttons.exit`)}
                </Button>
              </Col>
              <Col sm={6}>
                <Button
                  onClick={() => getScheduleCampaign()}
                  className="w-100"
                  isLoading={submitting}
                >
                  {t(`${sendType}.buttons.finish`)}
                </Button>
              </Col>
            </Row>
          </Can>
        </div>
      </Modal>
    </div>
  )
}

export default Confirmation
