import { Notification } from '@thanx/uikit/notification'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Checkmark from 'components/Icons/Checkmark'
import Error from 'components/Icons/Error'
import Information from 'components/Icons/Information'
import React from 'react'
import { merchantTheme } from 'theme'

const TYPE_MAPPING = {
  info: 'info',
  success: 'positive',
  alert: 'negative',
}

type PropsT = {
  content: React.ReactNode
  title?: string
  children?: React.ReactNode
  type?: 'tip' | 'info' | 'alert' | 'success'
  isClosable?: boolean
  className?: string
  iconOverride?: React.ReactNode
}

const InfoBox: React.FC<PropsT> = props => {
  const {
    content,
    title,
    children,
    type = 'tip',
    isClosable = props.type === 'alert',
    className = '',
    iconOverride,
  } = props

  const [css] = useStyletron()

  const titleTag = !!title ? (
    <Text.SmallCaps3 tag="p" className="pb-s m-0" color="inherit" bold>
      {title}
    </Text.SmallCaps3>
  ) : null

  let icon = iconOverride
  let styleOverride = {}
  switch (type) {
    case 'tip':
      styleOverride = {
        backgroundColor: merchantTheme.colors.grey10,
      }
      if (!icon) icon = <i className="fa fa-lightbulb-o font-size-24" />
      break
    case 'info':
      styleOverride = {
        backgroundColor: merchantTheme.colors.belizeHole10,
      }
      if (!icon) icon = <Information type="info" />
      break
    case 'success':
      styleOverride = {
        backgroundColor: merchantTheme.colors.nephritis10,
      }
      if (!icon) icon = <Checkmark />
      break
    case 'alert':
      styleOverride = {
        backgroundColor: merchantTheme.colors.cayenne10,
      }
      if (!icon) icon = <Error />
      break

    default:
      break
  }

  return (
    <div className={className}>
      <Notification
        kind={TYPE_MAPPING[type]}
        closeable={isClosable}
        overrides={{
          Body: {
            style: {
              ...styleOverride,
              color: merchantTheme.colors.grey70,
              width: 'auto',
              marginTop: '0px',
              padding: '24px',
              marginBottom: '0px',
              lineHeight: 1.43,
            },
          },
          InnerContainer: { style: { display: 'flex', flexGrow: 1 } },
        }}
      >
        <div className="pr-s">{icon}</div>
        <div className={css({ marginTop: '2px' })}>
          {titleTag}
          <Text.BodyText4 tag="div" className="m-0" color="inherit">
            {content}
          </Text.BodyText4>
          {children}
        </div>
      </Notification>
    </div>
  )
}

export default InfoBox
