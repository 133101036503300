import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { RedemptionProvider as RedemptionProviderType } from 'models/RedemptionProvider'
import { Button } from 'react-bootstrap'
import { useFormModel } from '../../FormModel'
import Card from '../Card'
import { instoreRedemptionInfoSections } from '../helpers'
import NextButton from '../NextButton'
import RedemptionProvider from './RedemptionProvider'

type Props = {
  redemptionProviders: RedemptionProviderType[]
  isEnabled: boolean
  isOpen: boolean
  isCurrent: boolean
  isDraft: boolean
  isValidForm: boolean
  onNext: () => void
  setLocationModalOpen: (locationModalOpen: boolean) => void
  setConfigureProviderStep: (configureProviderStep: boolean) => void
  setSelectedProvider: (redemptionProvider: RedemptionProviderType) => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card'
)

function MultipleRedemptionProviders(props: Props) {
  const {
    redemptionProviders,
    isEnabled,
    isOpen,
    isCurrent,
    isDraft,
    isValidForm,
    onNext,
    setLocationModalOpen,
    setConfigureProviderStep,
    setSelectedProvider,
  } = props
  const merchant = useCurrentMerchant()
  const { model: formModel } = useFormModel()
  const { posFields, availableOnline } = formModel

  const displayFields = isEnabled && !merchant?.online_only
  const isNextDisabled =
    Object.values(posFields).every(
      provider => provider.instoreRedemptionType !== null
    ) || !isValidForm

  return (
    <Card
      title={t('multi_pos.title')}
      subtitle={t('multi_pos.subtitle')}
      infoSections={instoreRedemptionInfoSections}
      isEnabled={displayFields}
      isOpen={isOpen}
      buttonName={
        !merchant?.online_only && availableOnline
          ? 'availableInstore'
          : undefined
      }
      disableToggleButton={!isDraft}
      onToggle={value => {
        if (isCurrent && !value) {
          onNext()
        }
      }}
    >
      <Switch
        condition={displayFields}
        fallback={<p className="grey-70">{t('not_redeemable')}</p>}
      >
        <Button
          onClick={() => setLocationModalOpen(true)}
          className="p-0 mb-m text-left"
          bsStyle="link"
        >
          <Text.BodyText4 tag="span" color="primary">
            {t('multi_pos.which_locations')}
          </Text.BodyText4>
        </Button>
        {redemptionProviders.map(provider => (
          <RedemptionProvider
            key={provider.value}
            redemptionProvider={provider}
            setConfigureProviderStep={setConfigureProviderStep}
            setSelectedProvider={setSelectedProvider}
          />
        ))}
        <Switch condition={isCurrent && !!onNext}>
          <NextButton onClick={onNext} disabled={!isNextDisabled} />
        </Switch>
      </Switch>
    </Card>
  )
}

export default MultipleRedemptionProviders
