import {
  GET_ORDERING_CONFIGS_SUCCESS,
  GET_ORDERING_CONFIG_SUCCESS,
  PUT_ORDERING_STATUSES_SUCCESS,
  ResolvedAction,
} from 'actions/ordering_configs'
import { RESET_DB } from 'actions/orm'
import { attr, Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  name?: string
  street: string
  city: string
  state: string
  zip: string
  ordering_status: 'enabled' | 'disabled' | 'not_configured'
  config_errors: Array<string>
  menu_last_synced_at: string | null
  location_last_synced_at: string | null
}

export default class OrderingConfig extends Model<
  // @ts-ignore
  typeof OrderingConfig,
  Fields
> {
  static modelName: string = 'OrderingConfig'

  static get fields(): any {
    return {
      id: attr(),
      name: attr(),
      street: attr(),
      city: attr(),
      state: attr(),
      zip: attr(),
      ordering_status: attr(),
      menu_last_synced_at: attr(),
      location_last_synced_at: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    OrderingConfig: ModelType<OrderingConfig>
  ) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case PUT_ORDERING_STATUSES_SUCCESS:
      case GET_ORDERING_CONFIGS_SUCCESS:
        action.payload.data.ordering_configs.forEach(config => {
          return OrderingConfig.upsert(config)
        })
        break
      case GET_ORDERING_CONFIG_SUCCESS:
        OrderingConfig.upsert(action.payload.data.ordering_config)
        break
      case RESET_DB:
        OrderingConfig.all().delete()
        break
      default:
        break
    }
  }
}
