import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import ColorPicker from 'components/Form/ColorPicker'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import {
  getHasButtonStyles,
  getHasContentStyles,
  getIsCarousel,
} from 'scenes/Cms/Content/AppHomePage/Content/helper'
import { ContentBlockData } from 'scenes/Cms/Content/AppHomePage/hooks'

const t = buildTranslate('cms.content.app_home_page.content.edit_block.style')

type Props = {
  blockId: string
  contentBlock: ContentBlockData
}

const Header = ({ children }: { children: React.ReactNode }) => (
  <Text.Header4 className="mb-l">{children}</Text.Header4>
)

const InputFieldPx = ({ type, style, blockId, contentBlock }) => {
  const { settings } = contentBlock
  return (
    <FormsyInput
      className="my-m"
      name={`${blockId}.settings.${style}`}
      label={t(`${type}.${style}`)}
      type="number"
      value={settings[style].toString()}
      endEnhancer="px"
      validations={{
        isNumeric: true,
        isRequired: true,
        greaterThanOrEqual: 0,
      }}
      validationErrors={{
        isNumeric: I18n.t('validations.is_numeric.message'),
        isRequired: I18n.t('validations.is_required'),
        greaterThanOrEqual: I18n.t('validations.greater_than_or_equal', {
          value: 0,
        }),
      }}
    />
  )
}

const Style = ({ blockId, contentBlock }: Props) => {
  const { settings, type } = contentBlock
  const borderStyles = ['border_width', 'border_radius']
  const paddingStyles = [
    'item_spacing',
    'padding_top',
    'padding_bottom',
    'padding_left',
    'padding_right',
  ]
  const buttonStyles = [
    'button_text_color',
    'button_bg_color',
    'button_border_color',
    'close_icon_color',
  ]
  const contentStyles = [
    'background_color',
    'header_text_color',
    'body_text_color',
    'chevron_color',
    'link_color',
  ]

  const isCarousel = getIsCarousel(type)
  const hasContentStyles = getHasContentStyles(type)
  const hasButtonStyles = getHasButtonStyles(type)

  return (
    <div>
      <Text.Header3 className="mb-l">{t('title')}</Text.Header3>
      <Section className="flex-1 mb-l mr-xs p-l">
        <Header>
          {isCarousel ? t('border.carousel_title') : t('border.title')}
        </Header>
        {'border_color' in settings && (
          <ColorPicker
            key={'border_color'}
            name={`${blockId}.settings.border_color`}
            label={t('border.border_color')}
            validations={{
              hex: true,
              isRequired: true,
            }}
            value={settings?.border_color}
            validationErrors={{
              hex: I18n.t('validations.hex.message'),
              isRequired: I18n.t('validations.is_required'),
            }}
            startEnhancer
          />
        )}
        {borderStyles.map(
          style =>
            style in settings && (
              <InputFieldPx
                key={style}
                type="border"
                style={style}
                blockId={blockId}
                contentBlock={contentBlock}
              />
            )
        )}
      </Section>

      {hasContentStyles && (
        <Section className="flex-1 mb-l mr-xs p-l relative">
          <Header>
            {isCarousel ? t('content.carousel_title') : t('content.title')}
          </Header>
          {contentStyles.map(
            style =>
              style in settings && (
                <ColorPicker
                  key={style}
                  className="my-m"
                  name={`${blockId}.settings.${style}`}
                  label={t(`content.${style}`)}
                  validations={{
                    hex: true,
                    isRequired: true,
                  }}
                  value={settings[style]}
                  validationErrors={{
                    hex: I18n.t('validations.hex.message'),
                    isRequired: I18n.t('validations.is_required'),
                  }}
                  startEnhancer
                />
              )
          )}
        </Section>
      )}

      {hasButtonStyles && (
        <Section className="flex-1 mb-l mr-xs p-l relative">
          <Header>
            {isCarousel ? t('button.carousel_title') : t('button.title')}
          </Header>
          {buttonStyles.map(
            style =>
              style in settings && (
                <ColorPicker
                  key={style}
                  className="my-m"
                  name={`${blockId}.settings.${style}`}
                  label={t(`button.${style}`)}
                  validations={{
                    hex: true,
                    isRequired: true,
                  }}
                  value={settings[style]}
                  validationErrors={{
                    hex: I18n.t('validations.hex.message'),
                    isRequired: I18n.t('validations.is_required'),
                  }}
                  startEnhancer
                />
              )
          )}
        </Section>
      )}

      <Section className="flex-1 mb-l mr-xs p-l">
        <Header>
          {isCarousel ? t('padding.carousel_title') : t('padding.title')}
        </Header>
        {paddingStyles.map(
          style =>
            style in settings && (
              <InputFieldPx
                key={style}
                type="padding"
                style={style}
                blockId={blockId}
                contentBlock={contentBlock}
              />
            )
        )}
      </Section>
    </div>
  )
}

export default Style
