import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import { EventsMetric } from 'models/CampaignMetric'
import React from 'react'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import Attribution from 'scenes/CampaignCenter/Report/v3/Summary/components/Attribution'
import MetricSummary from './MetricSummary'

type PropsT = {
  metrics: EventsMetric[]
  winningVariantId?: number | null
}

const t = buildTranslate('thanx_campaigns.report.v3.test')

const Heading: React.FC<PropsT> = props => {
  const { metrics, winningVariantId } = props
  const [css] = useStyletron()
  const { attributionWindow } = useAttributionWindow()

  return (
    <div className="ml-xl mb-m">
      <Text.Header4 className="mb-xs mt-0">
        {t('cumulative_revenue')}
      </Text.Header4>
      <Attribution
        attributionWindow={attributionWindow}
        tooltipText={t('chart.attribution.tooltip', {
          count: attributionWindow,
        })}
        className="mb-m"
      />
      <div
        className={`d-flex max-width-100 ${css({
          overflowX: 'auto',
        })}`}
      >
        {metrics.map((metric, i) => {
          const lastItem = metric.data ? [...metric.data[0].values].pop() : null
          const status = metric.isErrored
            ? 'error'
            : metric.isLoading
            ? 'loading'
            : null
          return (
            <MetricSummary
              key={`${metric.id}-${i}`}
              value={lastItem?.count || 0}
              status={status}
              meta={metric.meta}
              isWinner={metric.id === winningVariantId}
              isControl={metric.meta?.isControl}
              isLast={i === metrics.length - 1}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Heading
