import React from 'react'
import { Col, Row } from 'react-bootstrap'

type Props = {
  title: string
  subtext: string
  actionContent?: React.ReactNode
  icon: string
}

const InactiveContent: React.FC<Props> = props => {
  const { title, subtext, actionContent, icon } = props
  return (
    <Row className="text-center-md">
      <Col md={6} xs={12} className="margin-bottom-large">
        <div className="font-size-24 margin-bottom-medium bold">{title}</div>
        <div className="font-size-16 margin-bottom-huge full-width-on-sm width-66 grey-70">
          {subtext}
        </div>
        {actionContent}
      </Col>
      <Col md={6} xs={12}>
        <div>
          <img
            src={icon}
            alt="watermark"
            style={{ width: '400px', height: '400px' }}
          />
        </div>
      </Col>
    </Row>
  )
}

export default InactiveContent
