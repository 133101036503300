import type { UserCountMetric } from 'models/CampaignMetric'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import View from 'scenes/CampaignCenter/Builder/components/AudienceBreakoutModalView'

type Props = {
  show: boolean
  userCountMetric?: UserCountMetric
  onModalCloseClicked: () => void
}

const AudienceBreakoutModal: React.FC<Props> = props => {
  const { userCountMetric } = props
  return (
    <View
      {...props}
      targetedItems={[
        {
          label: I18n.t(
            'thanx_campaigns.review.customers.breakout_modal.table.targeting.members'
          ),
          value: userCountMetric?.data?.member_count || 0,
        },
        {
          label: I18n.t(
            'thanx_campaigns.review.customers.breakout_modal.table.targeting.subscribers'
          ),
          value: userCountMetric?.data?.subscriber_count || 0,
        },
      ]}
      excludedItems={[
        {
          label: I18n.t(
            'thanx_campaigns.review.customers.breakout_modal.table.excluded.excluded'
          ),
          value: userCountMetric?.data?.excluded_count || 0,
        },
      ]}
    />
  )
}

export default AudienceBreakoutModal
