import React from 'react'
import { Translate } from 'react-redux-i18n'

import vipImage from 'assets/images/campaign-center/opportunity/vip/churn.png'
import winbackImage from 'assets/images/campaign-center/opportunity/winback/churn.png'

type Props = {
  campaignType: string
}
const Churn: React.FC<Props> = ({ campaignType }) => {
  let image

  switch (campaignType) {
    case 'winback':
      image = winbackImage
      break
    case 'vip':
      image = vipImage
      break
    default:
      break
  }

  return (
    <div className="margin-top-huge margin-bottom-huge padding-large">
      <div className="display-flex flex-justify-center flex-wrap flex-align-center">
        <div className="col-md-6 col-sm-12 padding-large">
          <img src={image} className="img-responsive" alt="Churn" />
        </div>

        <div className="col-md-3 col-sm-12 padding-large relative">
          <Translate
            tag="div"
            className="font-size-12 letter-spacing-1 uppercase spearmint-50 bold"
            value={`thanx_campaigns.opportunity.${campaignType}.churn.title`}
          />
          <hr className="pipe spearmint-50-bkg inline-block margin-top-medium margin-bottom-medium" />
          <Translate
            tag="h5"
            className="blue-chrome-60"
            value={`thanx_campaigns.opportunity.${campaignType}.churn.header`}
          />
          <Translate
            tag="div"
            className="grey-70 font-size-16"
            value={`thanx_campaigns.opportunity.${campaignType}.churn.text`}
            dangerousHTML
          />
        </div>
      </div>
    </div>
  )
}

export default Churn
