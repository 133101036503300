import { Button, Kind } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import EditNavbar from 'components/Navbar/EditNavbar'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import { LinkContainer } from 'react-router-bootstrap'

const t = buildTranslate('points.configuration')

type Props = {
  children: React.ReactNode
  backLink: string
  canPublish: boolean
  onPublish?: () => void
}

function Layout(props: Props) {
  const [css] = useStyletron()

  return (
    <div
      className={`h-100 ${css({
        marginTop: '30px',
      })}`}
    >
      <EditNavbar title={t('title')}>
        <div>
          <LinkContainer to={props.backLink}>
            <Button kind={Kind.SECONDARY_REVERSE} className="mr-s">
              {t('edit.exit')}
            </Button>
          </LinkContainer>
          <Button
            kind={Kind.PRIMARY_REVERSE}
            onClick={props.onPublish}
            disabled={!props.canPublish}
          >
            {t('edit.publish')}
          </Button>
        </div>
      </EditNavbar>
      <Container className="h-100">
        <Row>
          <Col className="p-xl">{props.children}</Col>
        </Row>
      </Container>
    </div>
  )
}

export default Layout
