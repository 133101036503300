import {
  GET_GRANTABLE_CAMPAIGNS_SUCCESS,
  ResolvedAction,
} from 'actions/grantableCampaigns'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'
import { CampaignStateT } from './Campaign'

export interface Fields {
  id: number
  merchant_id: number
  type: string
  name: string
  state: CampaignStateT
}

export default class GrantableCampaign extends Model<
  // @ts-ignore
  typeof GrantableCampaign,
  Fields
> {
  static modelName: string = 'GrantableCampaign'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    GrantableCampaign: ModelType<GrantableCampaign>
  ): void {
    switch (action.type) {
      case GET_GRANTABLE_CAMPAIGNS_SUCCESS:
        action.payload.data.campaigns.forEach(campaign =>
          GrantableCampaign.upsert(campaign)
        )
        break
      case RESET_DB:
        GrantableCampaign.all().delete()
        break
      default:
        break
    }
  }
}
