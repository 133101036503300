import { Fields as MerchantUser } from 'models/MerchantUser'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const INSERT_AUTH_MERCHANT_USER =
  'merchant_user/INSERT_AUTH_MERCHANT_USER'

type InsertMerchantUser = {
  type: typeof INSERT_AUTH_MERCHANT_USER
  merchant_user: MerchantUser
}

export const GET_MERCHANT_USER = 'merchant_user/GET_MERCHANT_USER'
export const GET_MERCHANT_USER_SUCCESS =
  'merchant_user/GET_MERCHANT_USER_SUCCESS'
export const GET_MERCHANT_USER_FAIL = 'merchant_user/GET_MERCHANT_USER_FAIL'
export const GET_MERCHANT_USERS = 'merchant_user/GET_MERCHANT_USERS'
export const GET_MERCHANT_USERS_FAIL = 'merchant_user/GET_MERCHANT_USERS_FAIL'
export const UPDATE_MERCHANT_USER = 'merchant_user/UPDATE_MERCHANT_USER'
export const UPDATE_MERCHANT_USER_SUCCESS =
  'merchant_user/UPDATE_MERCHANT_USER_SUCCESS'
export const UPDATE_MERCHANT_USER_FAIL =
  'merchant_user/UPDATE_MERCHANT_USER_FAIL'

export function getCurrentMerchantUser(): AxiosAction<
  typeof GET_MERCHANT_USER,
  { merchant_user: MerchantUser }
> {
  return {
    type: GET_MERCHANT_USER,
    payload: {
      client: 'default',
      request: {
        url: `merchant_users/me`,
        method: 'get',
      },
    },
  }
}

export function updateCurrentMerchantUser(
  data: Partial<MerchantUser>
): AxiosAction<typeof UPDATE_MERCHANT_USER, { merchant_user: MerchantUser }> {
  return {
    type: UPDATE_MERCHANT_USER,
    payload: {
      client: 'default',
      request: {
        url: `merchant_users/me`,
        method: 'patch',
        data,
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getCurrentMerchantUser>>
  | ResolvedAxiosAction<ReturnType<typeof updateCurrentMerchantUser>>
  | InsertMerchantUser
