// @flow
import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import { goBack } from 'react-router-redux'
// $FlowFixMe
import store from 'store'

import Spacer from 'components/Spacer'
import CSVSuccess from './CSVUpload/CSVSuccess'
import CSVUpload from './CSVUpload'
import DataMapper from './DataMapper'
// $FlowFixMe
import { textUtils } from 'utilities/textUtils'
import MxUpdateBanner from 'components/MxUpdateBanner'

type Props = {}
type State = {
  showDataMapper: boolean,
  csvFile: any,
  csvContents: Array<string>,
  csvHeader: boolean,
  csvSuccess: boolean,
}

export default class ConnectProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      showDataMapper: false,
      csvFile: null,
      csvContents: [],
      csvHeader: false,
      csvSuccess: false,
    }
  }

  onClick() {
    store.dispatch(goBack())
  }

  subscriberCount() {
    let count = this.state.csvContents.length
    if (count < 1000) count = this.state.csvContents.filter(String).length // remove empty strings
    if (this.state.csvHeader) count -= 1
    return textUtils.humanizeNumber(count, 1000)
  }

  csvUpload() {
    if (this.state.csvSuccess) {
      return <CSVSuccess subscriberCount={this.subscriberCount()} />
    }

    return <CSVUpload handleCSVIngestion={this.handleCSVIngestion.bind(this)} />
  }

  handleCSVIngestion(
    hasHeader: boolean,
    file: any,
    csvContents: Array<string>
  ) {
    this.setState({
      csvContents: csvContents,
      csvHeader: hasHeader,
      csvFile: file,
      showDataMapper: true,
    })
  }

  handleCSVSuccess() {
    this.setState({
      csvSuccess: true,
      showDataMapper: false,
    })
  }

  cancelDataMapping() {
    this.setState({
      showDataMapper: false,
      csvFile: null,
      csvContents: [],
      csvHeader: false,
    })
  }

  render() {
    if (this.state.showDataMapper) {
      return (
        <DataMapper
          hasHeader={this.state.csvHeader}
          file={this.state.csvFile}
          contents={this.state.csvContents}
          onCancel={this.cancelDataMapping.bind(this)}
          onSuccess={this.handleCSVSuccess.bind(this)}
        />
      )
    }

    return (
      <div className="container">
        <MxUpdateBanner
          type="update"
          className="mt-xl"
          subtitle={I18n.t('components.update_banners.update.subtitle')}
        />
        <Spacer paddingTop="40px" paddingBottom="30px">
          <Row>
            <Col sm={9}>
              <h2 className="deprecated__h2">
                {I18n.t('emails.recipients.setup.title')}
              </h2>
            </Col>
            <Col sm={3} className="text-right" style={{ paddingTop: '25px' }}>
              <Button bsStyle="link" onClick={this.onClick.bind(this)}>
                <Translate
                  value="emails.recipients.setup.back_button"
                  dangerousHTML
                />
              </Button>
            </Col>
          </Row>
        </Spacer>
        <label>{I18n.t('emails.recipients.setup.choices.upload.title')}</label>
        <div style={{ marginBottom: '60px' }}>{this.csvUpload()}</div>
        <Translate value="emails.recipients.unsubscribe_notice" dangerousHTML />
      </div>
    )
  }
}
