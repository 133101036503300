import { FormModel } from './LinkGenerationForm'
import { AppLink } from './LinkGenerationForm/AppFields'
import { WebOrderingLocation } from './LinkGenerationForm/WebFields'

export function generateUrl(
  model: FormModel,
  options: { merchantHandle: string }
): string {
  const { merchantHandle } = options

  const baseUrl = `${process.env.REACT_APP_SMART_LINKS_URL}/${merchantHandle}`
  const params = new URLSearchParams()

  const fallbackMobile = generateMobileFallback(model, options)

  const fallbackDesktop = generateDesktopFallback(model, options)

  const appPath =
    model.appLink &&
    generateAppPath(model.appLink, {
      category: model.appLinkCategory,
      product: model.appLinkProduct,
    })

  if (appPath) {
    params.set('app_path', appPath)
  }

  if (fallbackMobile) {
    params.set('fallback_mobile', fallbackMobile)
  }

  if (fallbackDesktop) {
    params.set('fallback_desktop', fallbackDesktop)
  }

  return `${baseUrl}?${params.toString()}`
}

function generateMobileFallback(
  model: FormModel,
  options: { merchantHandle: string }
) {
  const { merchantHandle } = options

  if (model.mobileFallback === 'store') {
    return null
  }

  if (model.mobileFallback === 'custom') {
    return model.mobileCustomUrl ?? null
  }

  if (!model.mobileWebOrderingLocation) {
    return null
  }

  return generateWebOrderingUrl(model.mobileWebOrderingLocation, {
    merchantHandle,
    category: model.mobileWebOrderingCategory,
    product: model.mobileWebOrderingProduct,
  })
}

function generateDesktopFallback(
  model: FormModel,
  options: { merchantHandle: string }
) {
  const { merchantHandle } = options

  if (model.desktopFallback === 'custom') {
    return model.desktopCustomUrl ?? null
  }

  if (!model.desktopWebOrderingLocation) {
    return null
  }

  return generateWebOrderingUrl(model.desktopWebOrderingLocation, {
    merchantHandle,
    category: model.desktopWebOrderingCategory,
    product: model.desktopWebOrderingProduct,
  })
}

function generateAppPath(
  location: AppLink,
  options: { product?: string; category?: string }
) {
  const { product, category } = options

  switch (location) {
    case 'about':
      return 'u/about'
    case 'account':
      return 'u/account'
    case 'locations':
      return 'u/locations'
    case 'notifications':
      return 'u/notifications'
    case 'nps':
      return 'u/nps'
    case 'orderHistory':
      return 'u/order-history'
    case 'orderingMenu':
      return category ? `u/ordering-menu?category=${category}` : null
    case 'productDetails':
      return product ? `u/product-details?item=${product}` : null
    case 'registeredCards':
      return 'u/registered_cards'
    case 'rewards':
      return 'u/rewards'
    case 'support':
      return 'u/support'
  }
}

function generateWebOrderingUrl(
  location: WebOrderingLocation,
  options: {
    merchantHandle: string
    product?: string
    category?: string
  }
): string | null {
  const { merchantHandle, product, category } = options
  const baseUrl = `${process.env.REACT_APP_WEB_ORDERING_URL}/${merchantHandle}`

  switch (location) {
    case 'home-page':
      return baseUrl
    case 'rewards':
      return `${baseUrl}?displayRewards=true`
    case 'account':
      return `${baseUrl}?displayAccount=true`
    case 'saved-cards':
      return `${baseUrl}?displayRegisteredCards=true`
    case 'order-history':
      return `${baseUrl}?displayOrderHistory=true`
    case 'ordering-menu':
      return category ? `${baseUrl}#${category}` : null
    case 'items-on-the-menu':
      return `${baseUrl}?item=${product}`
  }
}
