import Term from 'components/Term'
import { buildTranslate } from 'locales'
import type { DayBucketPercentageData } from 'models/MerchantMetric'
import React from 'react'
import BucketMetric from 'scenes/Reports/ActivityReport/components/BucketMetric'
import { percentageFormatter } from 'utilities/formatters'

const t = buildTranslate(
  'reports.activity.sections.transactions.metrics.membership_purchases'
)

type Props = {
  data: DayBucketPercentageData
}

export const MembershipPurchases: React.FC<Props> = ({ data }) => {
  const summaryText = (
    <Term search="connected customers">{t('summary_text')}</Term>
  )

  return (
    <BucketMetric
      title={t('title')}
      summaryText={summaryText}
      bucketText={t('bucket_text')}
      keySuffix="percent"
      metric={data}
      valueFormatter={percentageFormatter}
    />
  )
}

export default MembershipPurchases
