import { buildTranslate } from 'locales'
import React from 'react'
import CmsPage from '../../components/CmsPage'
import Colors from './Colors'
import CoverImage from './CoverImage'
import Logos from './Logos'
import RewardIcon from './RewardIcon'

const t = buildTranslate('cms.brand.brand_assets')

type Props = {
  isLoading: boolean
}

const BrandAssets: React.FC<Props> = ({ isLoading }) => {
  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      isLoading={isLoading}
    >
      <Colors />
      <Logos />
      <RewardIcon />
      <CoverImage />
    </CmsPage>
  )
}

export default BrandAssets
