import { Text } from '@thanx/uikit/text'
import InfoRow from 'components/InfoRow'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { automatedCampaignHelpUrl } from 'utilities/urlUtils'
import ManualItem from './ManualItem'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

const redeemTypesWithCosts = [
  'manual/item',
  'automatic/amount',
  'automatic/percent',
  'manual/amount',
  'manual/percent',
  'experience/goldenticket',
]

type PropsT = {
  campaignId: number
  redeemTypes: (string | undefined)[]
}

const Cogs: React.FC<PropsT> = props => {
  const { campaignId, redeemTypes } = props

  const isEDREnabled = useFlag('DATA-633', false)
  if (isEDREnabled) {
    if (!isEmpty(intersection(redeemTypesWithCosts, redeemTypes))) {
      return <ManualItem campaignId={campaignId} />
    }
  } else {
    if (redeemTypes.includes('manual/item')) {
      return <ManualItem campaignId={campaignId} />
    }
  }

  return (
    <InfoRow
      title={t('rewards_accounting.cogs')}
      primaryContent={
        <Text.BodyText4 color="grey70">
          {t('rewards_accounting.not_available')}
        </Text.BodyText4>
      }
      secondaryContent={
        <a
          className="font-size-12"
          href={automatedCampaignHelpUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('learn_more')}
        </a>
      }
    />
  )
}

export default Cogs
