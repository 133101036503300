import {
  DateRangeFilter,
  DateRangeGranularity,
} from 'components/DateFilter/utilities'
import { Fields as PointsExperience } from 'models/PointsExperience'
import {
  ActivityCountersData,
  ActivityCountersMetric,
  ActivityTimeSeriesEntry,
  ActivityTimeSeriesMetric,
} from 'models/PointsExperienceMetric'
import moment, { Moment } from 'moment-timezone'
import { getMomentFromSeriesCategory } from 'utilities/date'

type ActivityChartSeriesGroups = {
  issued: Array<number>
  redeemed: Array<number>
  expired: Array<number>
  imported: Array<number>
}
export const getActivityChartSeriesGroups = (
  series: Array<ActivityTimeSeriesEntry>,
  hideImportedData: boolean
): ActivityChartSeriesGroups => {
  return series.reduce(
    (acc: ActivityChartSeriesGroups, data) => {
      const imported = data.total_imported_amount ?? 0
      const issued = data.total_issued_amount ?? 0
      const calculatedIssued = hideImportedData ? issued - imported : issued

      acc.issued.push(calculatedIssued)
      acc.redeemed.push(data.total_redeemed_amount ?? 0)
      acc.expired.push(data.total_expired_amount ?? 0)
      acc.imported.push(imported)

      return acc
    },
    { issued: [], redeemed: [], expired: [], imported: [] }
  )
}

export const tooltipWidth = 228

export type OutstandingLiabilityDateRecord = {
  date_time: Moment
  amount: number
}
const getOutstandingLiabilitySeries = (
  allTimeSeries: Array<ActivityTimeSeriesEntry>,
  filterStartDate: string | null,
  granularity: DateRangeGranularity,
  timeZone: string
): Array<ActivityTimeSeriesEntry> => {
  const startOfUnit = (() => {
    switch (granularity) {
      case 'anniversary':
      case 'daily':
        return 'day'
      case 'weekly':
        return 'isoWeek'
      case 'monthly':
      case 'quarterly':
        return 'month'
      case 'yearly':
        return 'year'
    }
  })()

  const startDate = filterStartDate
    ? moment.tz(filterStartDate, timeZone).startOf(startOfUnit)
    : null

  return allTimeSeries.filter(record => {
    if (!startDate) return true

    return getMomentFromSeriesCategory(
      record.date_time,
      granularity,
      timeZone
    ).isSameOrAfter(startDate)
  })
}

const getOutstandingDateRange = ({
  counterCustomData,
  counterAllTimeData,
  filter,
  granularity,
  allTimeSeries,
  timeZone,
}: {
  counterCustomData: ActivityCountersData
  counterAllTimeData: ActivityCountersData
  filter: DateRangeFilter
  granularity: DateRangeGranularity
  allTimeSeries: Array<ActivityTimeSeriesEntry>
  timeZone: string
}): {
  outstandingAtStart: OutstandingLiabilityDateRecord | null
  outstandingAtEnd: OutstandingLiabilityDateRecord | null
} => {
  const {
    start: filterStartDate,
    end: filterEndDate,
    range: filterRange,
  } = filter

  const outstandingAtStart = (() => {
    if (!counterCustomData || !counterAllTimeData) {
      return null
    }

    if (filterRange === 'all_time') {
      return {
        date_time: getMomentFromSeriesCategory(
          allTimeSeries[0]?.date_time,
          granularity,
          timeZone
        ),
        amount: 0,
      }
    }

    return {
      date_time: filterStartDate
        ? moment.tz(filterStartDate, timeZone)
        : getMomentFromSeriesCategory(
            allTimeSeries[0]?.date_time,
            granularity,
            timeZone
          ),
      amount:
        counterAllTimeData.total_outstanding_amount -
        counterCustomData.total_outstanding_amount,
    }
  })()

  const outstandingAtEnd = (() => {
    if (!counterCustomData || !counterAllTimeData) {
      return null
    }

    if (filterRange === 'all_time') {
      return {
        date_time: moment.tz(timeZone),
        amount: counterAllTimeData.total_outstanding_amount,
      }
    }

    return {
      date_time: filterEndDate
        ? moment.tz(filterEndDate, timeZone)
        : getMomentFromSeriesCategory(
            allTimeSeries[allTimeSeries.length - 1]?.date_time,
            granularity,
            timeZone
          ),
      amount: counterAllTimeData.total_outstanding_amount,
    }
  })()

  return {
    outstandingAtStart,
    outstandingAtEnd,
  }
}

const getOutstandingAmounts = (
  counterAllTimeData: ActivityCountersData,
  counterCustomData: ActivityCountersData,
  filter: DateRangeFilter
): {
  totalOutstandingAmount: number
  changeInOutstandingAmount: number
} => {
  const { range } = filter
  const totalOutstandingAmount =
    counterAllTimeData?.total_outstanding_amount ?? 0
  const changeInOutstandingAmount = (() => {
    if (range === 'all_time') {
      return counterAllTimeData?.total_outstanding_amount ?? 0
    }

    return counterCustomData?.total_outstanding_amount ?? 0
  })()

  return {
    totalOutstandingAmount,
    changeInOutstandingAmount,
  }
}

type OutstandingLiability = {
  isLoading: boolean
  isErrored: boolean
  timeZone: string
  seriesData: Array<ActivityTimeSeriesEntry>
  granularity: DateRangeGranularity
  outstandingAtStart: OutstandingLiabilityDateRecord | null
  outstandingAtEnd: OutstandingLiabilityDateRecord | null
  totalOutstandingAmount: number
  changeInOutstandingAmount: number
}
export const getOutstandingLiability = ({
  counterCustomState,
  counterAllTimeState,
  timeSeriesAllTimeState,
  filter,
}: {
  counterCustomState: ActivityCountersMetric
  counterAllTimeState: ActivityCountersMetric
  timeSeriesAllTimeState: ActivityTimeSeriesMetric
  filter: DateRangeFilter
}): OutstandingLiability => {
  const { data: timeSeriesAllTimeData } = timeSeriesAllTimeState
  const { data: counterCustomData } = counterCustomState
  const { data: counterAllTimeData } = counterAllTimeState
  const { start: filterStartDate } = filter

  const isLoading = [
    counterCustomState.isLoading,
    counterAllTimeState.isLoading,
    timeSeriesAllTimeState.isLoading,
  ].some(Boolean)

  const isErrored = [
    counterCustomState.isErrored,
    counterAllTimeState.isErrored,
    timeSeriesAllTimeState.isErrored,
  ].some(Boolean)

  const timeZone = timeSeriesAllTimeState.time_zone
  const granularity = timeSeriesAllTimeState.granularity
  const allTimeSeries = timeSeriesAllTimeData?.values ?? []

  const series = getOutstandingLiabilitySeries(
    allTimeSeries,
    filterStartDate,
    granularity,
    timeZone
  )

  const { totalOutstandingAmount, changeInOutstandingAmount } =
    getOutstandingAmounts(counterAllTimeData, counterCustomData, filter)

  const { outstandingAtStart, outstandingAtEnd } = getOutstandingDateRange({
    counterCustomData,
    counterAllTimeData,
    allTimeSeries,
    filter,
    granularity,
    timeZone,
  })

  return {
    isLoading,
    isErrored,
    timeZone,
    seriesData: series,
    granularity,
    outstandingAtStart,
    outstandingAtEnd,
    totalOutstandingAmount,
    changeInOutstandingAmount,
  }
}

export type ActivitySummaryData = {
  issued: number
  redeemed: number
  expired: number
  outstandingAtStart: number
  outstandingAtEnd: number
  dateTime: string
}

type ActivitySummary = {
  granularity: DateRangeGranularity
  activityData: Array<ActivitySummaryData>
}

export const getSummaryActivity = ({
  timeSeriesAllTimeState,
  filter,
}: {
  timeSeriesAllTimeState: ActivityTimeSeriesMetric
  filter: DateRangeFilter
}): ActivitySummary => {
  const {
    data: timeSeriesAllTimeData,
    granularity,
    time_zone,
  } = timeSeriesAllTimeState
  const allTimeSeries = timeSeriesAllTimeData?.values ?? []
  const { start: filterStartDate } = filter

  const series = getOutstandingLiabilitySeries(
    allTimeSeries,
    filterStartDate,
    granularity,
    time_zone
  )

  const activityData = series.map(record => {
    const totalOutstanding = record.total_outstanding_amount ?? 0
    const cumulativeOutstanding = record.cumulative_outstanding_amount ?? 0

    return {
      dateTime: record.date_time,
      issued: record.total_issued_amount ?? 0,
      redeemed: record.total_redeemed_amount ?? 0,
      expired: record.total_expired_amount ?? 0,
      outstandingAtStart: cumulativeOutstanding - totalOutstanding,
      outstandingAtEnd: cumulativeOutstanding,
    }
  })

  return {
    granularity,
    activityData,
  }
}

export type SortFilterAttribute =
  | 'issued'
  | 'redeemed'
  | 'expired'
  | 'outstandingAtStart'
  | 'outstandingAtEnd'
  | 'dateTime'

export type SortFilter = {
  direction: 'asc' | 'desc'
  attribute: SortFilterAttribute
}
export const sortSummaryActivityData = (
  activityData: Array<ActivitySummaryData>,
  sortFilter: SortFilter
): Array<ActivitySummaryData> => {
  const { direction, attribute } = sortFilter
  return activityData.sort((a, b) => {
    if (direction === 'asc') {
      if (attribute === 'dateTime') {
        return moment(a[attribute]).valueOf() - moment(b[attribute]).valueOf()
      }
      return a[attribute] - b[attribute]
    } else {
      if (attribute === 'dateTime') {
        return moment(b[attribute]).valueOf() - moment(a[attribute]).valueOf()
      }
      return b[attribute] - a[attribute]
    }
  })
}

export const getPointsReportingBounds = (
  pointsExperience: PointsExperience | null,
  timeZone: string
) => {
  if (!pointsExperience) {
    return {
      startDate: null,
      endDate: null,
    }
  }

  const { activated_at, archived_at } = pointsExperience

  return {
    startDate: activated_at ? moment.tz(activated_at, timeZone) : null,
    endDate: archived_at
      ? moment.tz(archived_at, timeZone)
      : moment.tz(timeZone),
  }
}
