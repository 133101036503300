import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import idx from 'idx'
import { I18n } from 'react-redux-i18n'

import { alert } from 'actions/flash'
import store from 'store'

export const makeApiClient = (config?: AxiosRequestConfig): AxiosInstance => {
  const client = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/merchants/`,
    headers: {
      'Content-Type': 'application/json',
      Accept: process.env.REACT_APP_ACCEPT_HEADER,
    },
    ...config,
  })

  client.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${store.getState().auth.token}`
    return config
  })

  client.interceptors.response.use(
    response => response,
    error => {
      // Globally handle 5xx errors
      const status = idx(error, _ => _.response.status)

      if (status >= 500 && status < 600) {
        store.dispatch(
          alert({
            key: 'danger',
            message: I18n.t(`errors.${status === 503 ? 503 : 500}`, {
              code: status,
              thanx: I18n.t('errors.thanx'),
            }),
            error: error.message,
          })
        )
      } else {
        return Promise.reject(error)
      }
    }
  )

  return client
}

export default makeApiClient
