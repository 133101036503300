import { Switch } from '@thanx/uikit/switch'
import Info from 'components/Info'
import ProductList from 'components/ProductList'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React from 'react'
import { getDiscountedProductsLabel } from 'scenes/RedeemManager/helpers'
import Card from '../Card'
import HiddenMenu from './HiddenMenu'

type PropsT = {
  template: RedeemTemplate
}

const t = buildTranslate('redeem_manager.detail.cards.online_redemption')

const OnlineRedemptionCard: React.FC<PropsT> = ({ template }) => {
  const { product_ids, redemption_venue, type } = template
  const isOnlineVenue =
    redemption_venue === 'online' || redemption_venue === 'all'

  const requiredProductIds = product_ids?.digital?.required || []
  const discountedProductIds = product_ids?.digital?.discounted || []

  const merchant = useCurrentMerchant()
  const { ordering_enabled } = merchant || {}
  const isAvailable = isOnlineVenue && !!ordering_enabled
  const isFreeItem = template.type === 'manual/item'
  const displayRequiredProducts = !isEmpty(requiredProductIds)
  const displayDiscountedProducts =
    !isEmpty(discountedProductIds) && !!discountedProductIds?.[0]
  const isHiddenMenu = type === 'experience/hidden_menu'
  const ableToRedeem =
    !displayRequiredProducts && !displayDiscountedProducts && !isHiddenMenu

  return (
    <Card title={t('title')} info={t('info')} isActive={isAvailable}>
      <Switch
        condition={isAvailable}
        fallback={
          <p className="grey-70">
            <Switch
              condition={!ordering_enabled}
              fallback={t('not_redeemable')}
            >
              {t('not_available')}
              <a href="mailto:merchant.support@thanx.com">{t('contact_us')}</a>
            </Switch>
          </p>
        }
      >
        <Switch condition={isHiddenMenu}>
          <HiddenMenu
            categoryName={template.category_name}
            imageSrc={template?.images?.advertising_image?.small}
          />
        </Switch>
        <Switch condition={ableToRedeem}>
          <p className="mb-s grey-70">{t('able_to_redeem')}</p>
        </Switch>
        {displayRequiredProducts && (
          <Info
            className="mb-m"
            label={t('items_required')}
            value={<ProductList productIds={requiredProductIds} />}
          />
        )}
        <Switch
          condition={displayRequiredProducts && !displayDiscountedProducts}
        >
          <p className="body-text-14 bold">{t('then_entire_purchase')}</p>
        </Switch>
        {displayDiscountedProducts && (
          <>
            <Info
              label={getDiscountedProductsLabel(isFreeItem, template.subtype)}
              value={<ProductList productIds={discountedProductIds} />}
            />
            <p className="grey-70">
              {isFreeItem
                ? t('will_apply_lowest_free_item')
                : t('will_apply_lowest')}
            </p>
          </>
        )}
      </Switch>
    </Card>
  )
}

export default OnlineRedemptionCard
