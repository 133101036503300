import { Text } from '@thanx/uikit/text'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'
import { getDateWithTimeAndZone } from 'utilities/date'
import { textUtils } from 'utilities/textUtils'
import { useUserTimeZone } from 'utilities/userTimeZone'
import StatusBadge from './StatusBadge'

const t = buildTranslate('users.view.activity.orders.detail')

const OrderDetailsModal = ({ show = true, order, onHide }) => {
  const { colors } = merchantTheme
  const timeZone = useUserTimeZone()

  return (
    <Modal
      isOpen={show}
      title={t('order')}
      proceedText={t('okay')}
      className="fs-unmask"
      hideCancel
      onClose={onHide}
      onProceed={onHide}
      zIndex={3000}
    >
      <div>
        <div className="mt-m">
          <Text.Header4 className="mb-m" tag="div" bold>
            {t('title')}
          </Text.Header4>

          <div className="d-flex flex-row">
            <div className="d-flex flex-column mr-xxxl">
              <Text.BodyText4 className="mb-xs" tag="div" bold>
                {t('provider_order_uid')}
              </Text.BodyText4>
              <Text.BodyText4 tag="div">
                {order.provider_order_uid}
              </Text.BodyText4>

              <Text.BodyText4 className="mb-xs mt-s" tag="div" bold>
                {t('location')}
              </Text.BodyText4>

              {[
                order.location.name,
                order.location.street,
                `${order.location.city}, ${order.location.state} ${order.location.zip}`,
              ].map(locationItem => (
                <Text.BodyText4
                  key={locationItem}
                  tag="div"
                  color={colors.grey70}
                >
                  {locationItem}
                </Text.BodyText4>
              ))}

              <Text.BodyText4 className="mb-xs mt-s" tag="div" bold>
                {t('status')}
              </Text.BodyText4>

              <Text.BodyText4 tag="div">
                {<StatusBadge status={order.status} />}
              </Text.BodyText4>

              <Text.BodyText4 className="mb-xs mt-s" tag="div" bold>
                {t('handoff')}
              </Text.BodyText4>

              <Text.BodyText4 tag="div">
                {textUtils.titleize(order.handoff)}
              </Text.BodyText4>

              <Text.BodyText4 className="mb-xs mt-s" tag="div" bold>
                {t('placed_at')}
              </Text.BodyText4>

              <Text.BodyText4 tag="div">
                {getDateWithTimeAndZone(order.placed_at, timeZone)}
              </Text.BodyText4>

              <Text.BodyText4 className="mb-xs mt-s" tag="div" bold>
                {t('amounts')}
              </Text.BodyText4>

              <Text.BodyText4 tag="div">
                {t('subtotal')}: ${order.price_breakdown.subtotal}
              </Text.BodyText4>

              <Text.BodyText4 tag="div">
                {t('tax')}: ${order.price_breakdown.total_tax}
              </Text.BodyText4>

              <Text.BodyText4 tag="div">
                {t('tip')}: ${order.price_breakdown.tip}
              </Text.BodyText4>

              <Text.BodyText4 tag="div">
                {t('total')}: ${order.price_breakdown.total}
              </Text.BodyText4>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default OrderDetailsModal
