import { dismiss } from 'actions/flash'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import SuccessIcon from '../Icons/Checkmark'
import ErrorIcon from '../Icons/Error'
import ErrorList from './ErrorList'

export type Error = string | string[]

type Alert = {
  key: string
  message: string
  displayDetails: boolean
  error?: Error
  hasReloadButton?: boolean
}

type Props = {
  alert: Alert
}

/**
 * Displays a single flash alert message.
 */
const Message: React.FC<Props> = ({ alert }) => {
  const { key, message, displayDetails, error, hasReloadButton = false } = alert
  const dispatch = useDispatch()
  const Icon = key.includes('success') ? SuccessIcon : ErrorIcon
  const flashMessage = (
    <div className="pull-left alert-flex-1">
      <Icon />
      <span className="pl-2">{message}</span>
      {displayDetails && !!error && <ErrorList error={error} />}
    </div>
  )

  const reloadButton = (
    <div className="pull-right">
      <Button
        className="reload-button"
        onClick={() => window.location.assign(window.location.href)}
      >
        Reload
      </Button>
    </div>
  )

  const closeButton = (
    <button
      type="button"
      className="close pl-2"
      aria-label="Close"
      onClick={() => dispatch(dismiss(alert))}
    >
      <span aria-hidden="true">&times;</span>
    </button>
  )

  return (
    <div className={`alert alert-${key}`}>
      {flashMessage}
      {hasReloadButton && reloadButton}
      {closeButton}
    </div>
  )
}

export default Message
