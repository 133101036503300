// @flow
import { createSelector } from 'redux-orm'
// $FlowFixMe
import orm from 'models/orm'

import type { TemplateDesignVariableT } from 'models/TemplateDesignVariable'

export const selectDesignVariablesByCampaignType: (
  Object,
  string
) => Array<TemplateDesignVariableT> = createSelector(
  orm,
  orm => orm,
  (orm, campaignType) => campaignType,
  (session, campaignType) =>
    session.TemplateDesignVariable.filter(
      variable => variable.campaign_type === campaignType
    ).toModelArray()
)
