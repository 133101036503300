const ccpanotice = {
  title: 'California Consumer Privacy Rights Notice',
  effective: 'Effective: January 1, 2020',
  header:
    'This California Consumer Privacy Rights Notice ("Notice") sets forth Thanx, Inc.’s disclosure obligations under California law, including the California Consumer Privacy Act of 2018 ("CCPA") and the California Civil Code section 1798.83. This Notice provides the additional details regarding the information defined as ‘personal information’ under applicable California law and related to a California resident or household ("Consumers" and "You") further referred to as "Personal Information."',
  pii: {
    title: '1. Personal Information Collected and Disclosed:',
    uses: 'Thanx collects, uses and/or discloses Personal Information for purpose of its Loyalty Reward Program ("Program") as follows:',
    third_party:
      '<b>Other Potential Third Party Disclosures</b>: Personal Information may also be disclosed to third parties to serve our legitimate business interests as follows: (1) as required by law, such as to comply with a subpoena, or similar legal process, (2) as part of a merger, acquisition, bankruptcy or other transaction in which a third party assumes control of all or part of the business, (3) to investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies as required by law; (4) enforce our agreements with you, and/or (5) investigate and defend ourselves against any third-party claims or allegations.',
  },
  sale: {
    title: '2. Sale of Personal Information',
    does_not:
      'Thanx does not sell and will not sell your Personal Information. Thanx does not engage in interest-based advertising. However, Merchants with whom you transact may do so and such interest-based advertising may constitute a sale of personal information under the CCPA from which you may opt out.',
    when_merchants:
      'When Merchants engage in interest-based advertising, you will be presented with an opportunity to opt out. If you want to opt out of the use by a Merchant of interest-based advertising, click the "Online-Tracking Opt Out Guide" link at the bottom of the Merchant website or applicable mobile application.',
    transact:
      'If you transact with multiple Merchants, you will need to submit an opt-out request <b><i>with each</i></b> Merchant with whom you wish to opt out.',
  },
  rights: {
    title:
      '3. Your Consumer Rights Under CCPA – Right To Know About Personal Information Collected, Disclosed Or Sold.',
    may: 'California Consumers may contact Thanx through their account, by email at <a href="mailto:support@thanx.com?subject=Your California Privacy Rights">support@thanx.com</a> with "Your California Privacy Rights" in the subject line to exercise the following CCPA Rights:',
  },
  disclose_no_charge: {
    title: 'A. Request Thanx Disclose At No Charge:',
    specific:
      'Specific pieces of personal information it has collected about you;',
    pi: 'categories of Personal Information collected, used, and/or disclosed about you;',
    sources:
      'categories of sources from which Personal Information is collected;',
    business:
      'business and/or commercial purposes for collecting and disclosing your Personal Information;',
    third_parties:
      'categories of third parties with whom your Personal Information has been disclosed/shared; and',
    rights_to_know:
      'Right to Know Requests can be submitted to Thanx by email at support@thanx.com.',
  },
  delete_no_charge: {
    title: 'B. Request Thanx to Delete At No Charge:',
    except:
      'Except as exempted pursuant to CCPA 1798.105, to request Thanx delete Personal Information.',
    deletion:
      'Deletion Requests can be submitted to Thanx be email at <a href="mailto:support@thanx.com">support@thanx.com</a> or by mail to Thanx at:  Thanx, Inc., Attention: Privacy Request to Delete, 1700 Van Ness Ave #1066, San Francisco, CA 94109',
  },
  verified: {
    title: 'D. Verified Request Process',
    verify:
      'Thanx will verify all consumer requests prior to taking any action in response to such request. For consumers that maintain an account with Thanx, it may verify the identity of the consumer making the request by either matching with the account information on file or through existing account authentication credentials. For non-account holders, Thanx shall verify to a reasonably high degree of certainty, including matching at least three pieces of consumer personal information with that maintained by Thanx and/or if necessary, by a signed statement by consumer under penalty of perjury that the requestor is the consumer for which personal information is sought.',
    under:
      'Under the CCPA, you may exercise these rights yourself or you may designate an authorized agent to make these requests on your behalf. Authorized agents must demonstrate they have written authorization from you to make requests on your behalf. Thanx may additionally require the consumer to confirm their identity and verify the authorized agent’s permission before complying with any request.',
  },
  limitations: {
    title: 'E. Consumer Request Limitations',
    please:
      'Please note that these rights are not absolute and in certain cases are subject to conditions or limitations as specified in the CCPA, including, but not limited to:',
    obligated:
      'Thanx is obligated to disclose/delete only upon a <b>verifiable</b> Consumer request from the consumer or an authorized agent acting on behalf of Consumer.',
    consumers:
      'Consumers may only make a personal information request twice in a 12-month period.',
    deletion:
      'Deletion is not required because it is necessary for Thanx to maintain the Personal Information to fulfill the purposes enumerated in CCPA Section 1798.105',
    confirm:
      'Thanx will confirm and respond to all requests within the timeframe required under the CCPA. In responding to any request to disclose/delete, Thanx shall maintain a record of the requests as required under the CCPA.',
  },
  discrimination: {
    title: '4. Non-Discrimination Policy',
    right:
      'You have the right not to receive discriminatory treatment for exercising any rights conferred by the CCPA. Thanx shall not discriminate against a consumer for exercising any rights under the CCPA, including, but not limited to, (a) denying goods or services, (b) charging different prices or rates (including discounts/penalties) that is not directly related to the value provided to Thanx for the Personal Information, or (c) suggesting Consumer will receive a different rate/price or different level of quality of goods/services',
  },
  civil_code: {
    title:
      '5. Your California Privacy Rights under California Civil Code Section 1798.83 & Business and Professions Code Section 22581',
    permits:
      'California law permits Consumers to request and obtain from once a year, free of charge, certain information about their Personally Identifiable Information ("PII") (as defined by California law) disclosed to third parties for direct marketing purposes in the preceding calendar year (if any). If applicable, this information would include a list of the categories of PII that was shared and the names and addresses of all third parties with which we shared information in the immediately preceding calendar year.',
    addition:
      'In addition, a business subject to California Business and Professions Code Section 22581 must allow California residents under age 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted. Your request should include a detailed description of the specific content or information to be removed. Please be aware that your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.',
  },
  accessibility: {
    title: '6. Accessibility of this CCPA Policy.',
    download:
      '<p>You can download and print a copy of this Notice here: <a href="#" id="print-button" onclick="window.print();return false;">here</a>.',
  },
  contact: {
    title: '7. Contact Us',
    detail:
      'Additional detail regarding our collection, use and disclosure of Personal Information, as well as general opt-out rights and other privacy disclosures, is set forth in our general <a href="https://dashboard.thanx.com/privacy">Privacy Policy</a>.',
    questions:
      'If you have any questions regarding your Personal Information or about our privacy practices, please contact us at: <a href="mailto:support@thanx.com">support@thanx.com</a>',
    last_updated: 'This Notice was last updated: December 31, 2021',
  },
  copyright: '%{year} Thanx',
}

export default ccpanotice
