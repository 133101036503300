import orm from 'models/orm'
import { Fields as Tier } from 'models/Tier'
import { createSelector } from 'redux-orm'

export const selectTiers = createSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Tier[] => {
    return session.Tier.all().toRefArray()
  }
)
