import { useStyletron } from '@thanx/uikit/theme'
import { getDateFilter } from 'components/DateFilter/utilities'
import FetchableMetricSection from 'components/FetchableMetricSection'
import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import moment from 'moment'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectRateTimeSeriesMetrics } from 'selectors/captureRateMetric'
import { merchantTheme } from 'theme'
import { useUserTimeZone } from 'utilities/userTimeZone'
import { useUploadState } from '../../UploadStateContext'
import LineChart from './LineChart'

type PropsT = {
  merchant: Merchant
}

const t = buildTranslate('capture_rate')

const Chart: React.FC<PropsT> = ({ merchant }) => {
  const { currentLocation } = useContext(LocationContext)
  const metrics = useSelector(state =>
    selectRateTimeSeriesMetrics(state, merchant.id, currentLocation?.id)
  )
  const [css] = useStyletron()
  const userTimeZone = useUserTimeZone()
  const timezone = metrics[0]?.time_zone || userTimeZone
  const filter = useMemo(() => {
    return getDateFilter(
      'all_time',
      timezone,
      moment(merchant.live_at).format('YYYY-MM-DD'),
      moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    )
  }, [merchant, timezone])
  const { onFileProcessed } = useUploadState()
  const [reFetchAt, setReFetchAt] = useState<number | null>(null)

  useEffect(() => {
    setReFetchAt(new Date().getTime())
  }, [currentLocation?.id])

  useEffect(() => {
    onFileProcessed(() => setReFetchAt(new Date().getTime()))
  }, [onFileProcessed])

  const formattedMetrics = metrics.map(metric => {
    let locationName: string
    let color: string
    if (metric.id.endsWith('_all')) {
      locationName = t('rate_chart.all_locations')
      color = merchantTheme.colors.spearmint50
    } else {
      locationName = t('rate_chart.selected_location')
      color = merchantTheme.colors.belizeHole50
    }
    return {
      ...metric,
      locationName,
      color,
    }
  })

  return (
    <FetchableMetricSection
      type="capture_rate_time_series_metric"
      merchantId={merchant.id}
      locationId={currentLocation?.id}
      shouldFetch
      reFetchAt={reFetchAt}
      className={`mb-xxl py-m pr-m pb-xxs pl-xxs ${css({
        backgroundColor: merchantTheme.colors.white,
        border: '1px solid ' + merchantTheme.colors.grey20,
        borderRadius: '4px',
      })}`}
    >
      <LineChart
        metrics={formattedMetrics}
        timezone={timezone}
        dateRangeFilter={filter}
      />
    </FetchableMetricSection>
  )
}

export default Chart
