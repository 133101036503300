import palette from 'constants/palette'
import Highcharts from 'highcharts'
import { useEffect, useRef } from 'react'

export const useManageSeries = (
  isLoading: boolean | undefined,
  series: Highcharts.SeriesOptionsType[] | undefined
) => {
  // This is a fix so that dynamic series will update properly
  const chartRef = useRef()
  useEffect(() => {
    // @ts-ignore
    const chart = chartRef.current?.chart
    if (!chart) return

    if (isLoading) {
      while (chart.series.length) {
        chart.series[0].remove()
      }
    } else if (series) {
      for (let i = 0; i < (series.length || 0); i++) {
        chart.addSeries(series[i])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  return {
    chartRef,
  }
}

export type CohortYAxisOption = {
  title: string
  categories: Array<string>
  options?: Partial<Highcharts.YAxisOptions>
  labelOptions?: any
}

export function formatCohortSeriesData(
  seriesData: Array<Highcharts.PointOptionsObject>
): Array<Highcharts.PointOptionsObject> {
  return seriesData.map(seriesDatum => ({
    ...seriesDatum,
    color: seriesDatum.value ? '' : palette.white,
  }))
}
