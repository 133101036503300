import { Fields as TargetSegment } from 'models/TargetSegment'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_TARGET_SEGMENTS = 'merchant/GET_TARGET_SEGMENTS'
export const GET_TARGET_SEGMENTS_SUCCESS =
  'merchant/GET_TARGET_SEGMENTS_SUCCESS'
export const GET_TARGET_SEGMENTS_FAIL = 'merchant/GET_TARGET_SEGMENTS_FAIL'

export const GET_TARGET_SEGMENT = 'merchant/GET_TARGET_SEGMENT'
export const GET_TARGET_SEGMENT_SUCCESS = 'merchant/GET_TARGET_SEGMENT_SUCCESS'
export const GET_TARGET_SEGMENT_FAIL = 'merchant/GET_TARGET_SEGMENT_FAIL'

export function getTargetSegments(): AxiosAction<
  typeof GET_TARGET_SEGMENTS,
  { target_segments: TargetSegment[] }
> {
  return {
    type: GET_TARGET_SEGMENTS,
    payload: {
      client: 'default',
      request: {
        url: 'target_segments',
        method: 'get',
      },
    },
  }
}

export function getTargetSegment(
  id: number
): AxiosAction<typeof GET_TARGET_SEGMENT, { target_segment: TargetSegment }> {
  return {
    type: GET_TARGET_SEGMENT,
    payload: {
      client: 'default',
      request: {
        url: `target_segments/${id}`,
        method: 'get',
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getTargetSegments>>
  | ResolvedAxiosAction<ReturnType<typeof getTargetSegment>>
