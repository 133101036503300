import {
  AnalyticsConfig,
  Fields,
  PreferencesModalConfig,
  ReceiptConfig,
  TiersConfig,
} from 'models/Config'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectConfigs = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.Config.all().toRefArray()
  }
)

export const selectReceiptConfig = createSelector(
  selectConfigs,
  (configs: Fields[]): ReceiptConfig | undefined => {
    return configs.find(
      config => config.id === 'receipt_config'
    ) as ReceiptConfig
  }
)

export const selectTiersConfig = createSelector(
  selectConfigs,
  (configs: Fields[]): TiersConfig | undefined => {
    return configs.find(config => config.id === 'tiers_config') as TiersConfig
  }
)

export const selectAnalyticsConfig = createSelector(
  selectConfigs,
  (configs: Fields[]): AnalyticsConfig | undefined => {
    return configs.find(
      config => config.id === 'ordering_analytics'
    ) as AnalyticsConfig
  }
)

export const selectPreferencesModalSetting = createSelector(
  selectConfigs,
  (configs: Fields[]): PreferencesModalConfig | undefined => {
    return configs.find(
      config => config.id === 'preferences_modal'
    ) as PreferencesModalConfig
  }
)
