import { Notification } from '@thanx/uikit/notification'
import { completeCampaign } from 'actions/campaigns'
import Modal from 'components/ModalContainer'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'

type Props = {
  campaignId: number
  isOpen: boolean
  onClose?: () => void
  onStop?: () => void
}

const t = buildTranslate('thanx_campaigns.components.stopCampaignModal')

const StopCampaignModal: React.FC<Props> = props => {
  const { campaignId, isOpen, onClose, onStop } = props

  const [error, setError] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const dispatch = useDispatch()

  function handleClose() {
    onClose && onClose()
    setError(null)
  }

  async function stopCampaign() {
    setIsSubmitting(true)
    const result = await dispatch(completeCampaign(campaignId))
    setIsSubmitting(false)

    if (result.error) {
      setError(t('error'))
      return
    }

    onStop && onStop()
  }

  return (
    <Modal
      canProceed={!isSubmitting}
      title={t('title')}
      subtitle={t('description')}
      proceedText={t('continueButton')}
      isOpen={isOpen}
      onClose={handleClose}
      onProceed={stopCampaign}
    >
      <div className="mt-m">
        {error && (
          <Notification kind="negative" size="full">
            {error}
          </Notification>
        )}
      </div>
    </Modal>
  )
}

export default StopCampaignModal
