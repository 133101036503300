import {
  Fields as RedeemTemplate,
  RedeemTemplateSubtype,
  RedeemType,
} from 'models/RedeemTemplate'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

type CreateTemplateBody = {
  name: string
  type: RedeemType
  subtype: RedeemTemplateSubtype
}

export const GET_ALL = 'redeemTemplate/GET_ALL'
export const GET_ALL_SUCCESS = 'redeemTemplate/GET_ALL_SUCCESS'
export const GET_ALL_FAIL = 'redeemTemplate/GET_ALL_FAIL'

export const GET_ONE = 'redeemTemplate/GET_ONE'
export const GET_ONE_SUCCESS = 'redeemTemplate/GET_ONE_SUCCESS'
export const GET_ONE_FAIL = 'redeemTemplate/GET_ONE_FAIL'

export const CREATE = 'redeemTemplate/CREATE'
export const CREATE_SUCCESS = 'redeemTemplate/CREATE_SUCCESS'
export const CREATE_FAIL = 'redeemTemplate/CREATE_FAIL'

export const UPDATE = 'redeemTemplate/UPDATE'
export const UPDATE_SUCCESS = 'redeemTemplate/UPDATE_SUCCESS'
export const UPDATE_FAIL = 'redeemTemplate/UPDATE_FAIL'

export const DELETE = 'redeemTemplate/DELETE'
export const DELETE_SUCCESS = 'redeemTemplate/DELETE_SUCCESS'
export const DELETE_FAIL = 'redeemTemplate/DELETE_FAIL'

export const SEND = 'redeemTemplate/SEND'
export const SEND_SUCCESS = 'redeemTemplate/SEND_SUCCESS'
export const SEND_FAIL = 'redeemTemplate/SEND_FAIL'

export function getRedeemTemplates(): AxiosAction<
  typeof GET_ALL,
  { redeem_templates: RedeemTemplate[] }
> {
  return {
    type: GET_ALL,
    payload: {
      client: 'default',
      request: {
        url: 'redeem_templates',
        method: 'get',
      },
    },
  }
}

export function getRedeemTemplate(
  templateId: number
): AxiosAction<typeof GET_ONE, { redeem_template: RedeemTemplate }> {
  return {
    type: GET_ONE,
    payload: {
      client: 'default',
      request: {
        url: `redeem_templates/${templateId}`,
        method: 'get',
      },
    },
  }
}

export function createRedeemTemplate(
  template: CreateTemplateBody
): AxiosAction<typeof CREATE, { redeem_template: RedeemTemplate }> {
  return {
    type: CREATE,
    payload: {
      client: 'default',
      request: {
        url: 'redeem_templates',
        method: 'post',
        data: {
          redeem_template: template,
        },
      },
    },
  }
}

export function updateRedeemTemplate(
  templateId: number,
  template: Partial<RedeemTemplate>
): AxiosAction<typeof UPDATE, { redeem_template: RedeemTemplate }> {
  return {
    type: UPDATE,
    payload: {
      client: 'default',
      request: {
        url: `redeem_templates/${templateId}`,
        method: 'patch',
        data: {
          redeem_template: template,
        },
      },
    },
  }
}

type DeleteAction = AxiosAction<'redeemTemplate/DELETE'> & {
  templateId: number
}
export type DeleteResolvedAction =
  | FailureAction<'redeemTemplate/DELETE_FAIL'>
  | SuccessAction<
      'redeemTemplate/DELETE_SUCCESS',
      { redeem_template: RedeemTemplate },
      DeleteAction
    >
export function deleteRedeemTemplate(templateId: number): DeleteAction {
  return {
    type: 'redeemTemplate/DELETE',
    payload: {
      client: 'default',
      request: {
        url: `redeem_templates/${templateId}`,
        method: 'delete',
      },
    },
    templateId,
  }
}

type SendAction = AxiosAction<'redeemTemplate/SEND'> & { templateId: number }
export type SendResolvedAction =
  | FailureAction<
      'redeemTemplate/SEND_FAIL',
      { errors: { messages: string[] } }
    >
  | SuccessAction<
      'redeemTemplate/SEND_SUCCESS',
      { errors?: { messages: string[] } },
      SendAction
    >
export function sendRedeemTemplateTest(
  templateId: number,
  emails: string[]
): SendAction {
  return {
    type: 'redeemTemplate/SEND',
    payload: {
      client: 'default',
      request: {
        url: `redeem_templates/${templateId}/actions/grant`,
        method: 'post',
        data: {
          emails,
        },
      },
    },
    templateId,
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getRedeemTemplates>>
  | ResolvedAxiosAction<ReturnType<typeof getRedeemTemplate>>
  | ResolvedAxiosAction<ReturnType<typeof createRedeemTemplate>>
  | ResolvedAxiosAction<ReturnType<typeof updateRedeemTemplate>>
  | DeleteResolvedAction
  | SendResolvedAction
