import React, { useContext } from 'react'

type ContextT = {
  triggerSave: () => Promise<any>
  showValidationErrors: () => void
  setCurrentStep: (stepName: string) => void
  canProceed: boolean
  isSaving: boolean
}

const Context = React.createContext<ContextT | null>(null)

export function useFormManagement() {
  const value = useContext(Context)

  if (value === null) {
    const errorInfo =
      'useFormManagement must be used within a FormManagementContext.Provider'
    throw errorInfo
  }

  return value
}

export default Context
