import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Variants from 'components/Icons/Variants'
import PopoverContent from 'components/PopoverContent'
import Status from 'components/Status'
import useWindowSize from 'hooks/useWindowSize'
import { buildTranslate } from 'locales'
import { VariantMeta } from 'models/CampaignVariant'
import React from 'react'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { currencyFormatter } from 'utilities/formatters'

type PropsT = {
  value: number
  status: 'error' | 'loading' | null
  meta: VariantMeta
  isWinner: boolean
  isControl: boolean
  isLast: boolean
}

const t = buildTranslate('thanx_campaigns.report.v3.test.chart.heading')

const MetricSummary: React.FC<PropsT> = props => {
  const { value, meta, status, isControl, isLast, isWinner } = props
  const { color, letter } = meta || {}
  const [css] = useStyletron()

  const statusClass = `ml-xs ${css({
    height: '45px',
    paddingTop: '12px',
  })}`
  const { isLarge, isMedium } = useWindowSize()
  const { attributionWindow } = useAttributionWindow()

  const statusComponent = <Status status={status} className={statusClass} />
  const text = isControl ? t('control_content') : t('content', { name: letter })
  let width: string
  if (isLarge) {
    width = isControl ? '210px' : '185px'
  } else {
    width = isControl ? '175px' : '150px'
  }
  const className = isLast ? '' : isLarge || isMedium ? 'mr-xl' : 'mr-l'
  return (
    <div className={className}>
      <div className="d-flex mb-xxs align-items-center">
        <Variants
          isWinner={isWinner}
          className="mr-xs pt-xxs"
          variantLetter={letter}
          color={color}
          isControl={isControl}
        />
        <Switch condition={!status} fallback={statusComponent}>
          <Text.BodyText1 bold color="grey90" tag="p" className="mb-0">
            {currencyFormatter(value, false)}
          </Text.BodyText1>
        </Switch>
      </div>
      <div
        className={`pl-xs mr-xxs ${css({
          borderLeft: '2px solid ' + color,
          width,
        })}`}
      >
        <span className="mr-xxs width-100">{text}</span>
        <PopoverContent
          sections={[
            {
              content: t('tooltip.total', { count: attributionWindow }),
            },
            {
              title: t('tooltip.how_its_calc'),
              content: t('tooltip.expression'),
            },
            {
              title: t('tooltip.example'),
              content: t('tooltip.susie'),
            },
            {
              content: t('tooltip.john'),
            },
            {
              content: t('tooltip.molly'),
            },
          ]}
        />
      </div>
    </div>
  )
}

export default MetricSummary
