import { getPointsExperiences } from 'actions/pointsExperiences'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectExperience } from 'selectors/pointsExperience'
import { useAbility } from 'utilities/ability'
import AccordionButton from '../Bar/AccordionButton'
import MenuItem from '../Bar/MenuItem'
import LoyaltyContent from './Content'

const t = buildTranslate('nav.loyalty_menu')

type PropsT = {
  isLeftNav?: boolean
  onClick?: () => void
  paths: string[]
}

const Loyalty: React.FC<PropsT> = ({
  isLeftNav = false,
  onClick = () => {},
  paths,
}) => {
  const ability = useAbility()
  const pointsExperience = useSelector(selectExperience)
  const dispatch = useDispatch()
  const merchant = useCurrentMerchant()

  useEffect(() => {
    if (!pointsExperience) {
      dispatch(getPointsExperiences())
    }
  }, [dispatch, pointsExperience])

  const exclusiveDealsDisabled =
    ability.cannot('manage', 'ExclusiveDeals') || !merchant?.exclusive_deals

  const menuDisabled =
    ability.cannot('manage', 'LegacyReports') &&
    ability.cannot('manage', 'ExclusiveDeals') &&
    exclusiveDealsDisabled

  const Parent = isLeftNav ? AccordionButton : MenuItem
  return (
    <Parent disabled={menuDisabled} paths={paths} buttonContents={t('button')}>
      <LoyaltyContent isLeftNav={isLeftNav} onClick={onClick} />
    </Parent>
  )
}

export default Loyalty
