import { Text } from '@thanx/uikit/text'
import { getTiers } from 'actions/tiers'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectTiers } from 'selectors/tier'
import { useStyletron } from 'styletron-react'
import { merchantTheme } from 'theme'

const t = buildTranslate('users.view')

type PropsT = {
  profile: UserProfile
}

const TierBadge: React.FC<PropsT> = ({ profile }) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const { current_tier } = profile
  const tiers = useSelector(state => selectTiers(state))

  useEffect(() => {
    if (tiers.length > 0) return

    dispatch(getTiers({}))
  }, [dispatch, tiers])

  if (!current_tier) return null

  const selectedTier = tiers.find(tier => tier.name === current_tier)
  if (!selectedTier) return null

  return (
    <div
      className={`mb-m d-inline-flex align-items-center grey-10-bkg p-s ${css({
        borderRadius: '4px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: merchantTheme.colors.grey30,
      })}`}
    >
      <div className={'align-items-center'}>
        <img
          src={selectedTier.icon_image_urls?.large}
          alt={t('background_image')}
          className={`${css({
            objectFit: 'cover',
            width: '32px',
            height: '32px',
          })}`}
        />
      </div>
      <div className={'d-block ml-xs'}>
        <Text.SmallCaps3
          tag="p"
          className={`m-0 font-size-10 bold ${css({
            lineHeight: '12px',
          })}`}
        >
          {t('tier')}
        </Text.SmallCaps3>
        <Text.BodyText3
          tag="p"
          className={'font-weight-normal m-0'}
          color="grey70"
        >
          {current_tier}
        </Text.BodyText3>
      </div>
    </div>
  )
}

export default TierBadge
