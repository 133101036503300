import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import StatusBadge from 'components/StatusBadge'
import React from 'react'
import { NavLink as RNavLink } from 'react-router-dom'
import { history } from 'store'
import { merchantTheme, merchantThemeT } from 'theme'

type colorsObj = merchantThemeT['colors']
type colorNames = keyof colorsObj

type IconPropsT = {
  className?: string
  fillColor?: string
}

type TagWrapperPropsT = {
  className: string
  children: React.ReactNode
  tag?: string
  url?: string
  onClick?: VoidFunction
}
const TagWrapper: React.FC<TagWrapperPropsT> = props => {
  const { children, className, tag = 'div', url, onClick } = props

  if (tag === 'a') {
    return (
      <a className={className} href={url}>
        {children}
      </a>
    )
  }
  return (
    <div tabIndex={0} className={className} onClick={onClick}>
      {children}
    </div>
  )
}

type MainPropsT = {
  text: string
  colorName?: colorNames
  link?: string
  Icon?: React.FC<IconPropsT> | string
  status?: 'coming_soon' | 'beta' | 'new'
  disabled?: boolean
  url?: string
  onClick?: VoidFunction
  textClassName?: string
  isLegacy?: boolean
  isLeftNav?: boolean
}
const NavLink: React.FC<MainPropsT> = props => {
  const {
    colorName = 'white',
    text,
    link,
    Icon,
    status,
    disabled = false,
    url,
    onClick,
    isLegacy = false,
    isLeftNav = false,
  } = props
  const [css] = useStyletron()
  const isActive = history.location.pathname === link?.split('#')[0]
  const LinkComponent = !!link ? RNavLink : TagWrapper
  const color = disabled
    ? merchantTheme.colors['grey50']
    : merchantTheme.colors['grey90']
  const xPadding = isLeftNav ? 'px-m' : 'px-s'
  let backgroundColor
  let hoverFocusBackgroundColor
  if (disabled) {
    const disabledBkgColor = isLegacy
      ? merchantTheme.colors.grey10
      : merchantTheme.colors[colorName]
    backgroundColor = disabledBkgColor
    hoverFocusBackgroundColor = disabledBkgColor
  } else if (isActive) {
    backgroundColor = isLegacy
      ? merchantTheme.colors.grey30
      : merchantTheme.colors.belizeHole10
    hoverFocusBackgroundColor = merchantTheme.colors.grey10
  } else if (isLegacy) {
    backgroundColor = merchantTheme.colors.grey10
    hoverFocusBackgroundColor = merchantTheme.colors.grey20
  } else {
    backgroundColor = merchantTheme.colors[colorName]
    hoverFocusBackgroundColor = merchantTheme.colors.grey10
  }
  const childProps: {
    className: string
    activeClassName?: string
    to?: string
    url?: string
    tag?: string
    onClick?: VoidFunction
    disabled?: boolean
  } = {
    onClick: disabled
      ? undefined
      : () => {
          const activeElement = document.activeElement as HTMLElement
          if (activeElement) activeElement.blur()
          onClick?.()
        },
    className: `align-items-center d-flex ${css({
      pointerEvents: disabled ? 'none' : 'inherit',
    })} ${css({
      whiteSpace: 'nowrap',
      height: isLeftNav ? '44px' : '40px',
      color: color,
      backgroundColor,
      cursor: disabled ? 'default' : 'pointer',
      ':hover': {
        backgroundColor: hoverFocusBackgroundColor,
        textDecoration: 'none',
      },
      ':focus': {
        backgroundColor: hoverFocusBackgroundColor,
        textDecoration: 'none',
      },
    })} ${xPadding}`,
  }

  if (!!link) {
    childProps.to = disabled ? '' : link
    childProps.disabled = disabled
  }
  if (!!url) {
    childProps.url = disabled ? '' : url
    childProps.tag = 'a'
  }

  return (
    // @ts-ignore
    <LinkComponent {...childProps}>
      {Icon && <Icon className="mr-xs" fillColor={color} />}
      <Text.BodyText4 tag="span" color={disabled ? 'grey50' : 'grey90'}>
        {text}
      </Text.BodyText4>
      {status && <StatusBadge type={status} />}
    </LinkComponent>
  )
}

export default NavLink
