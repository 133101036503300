import { Fields } from 'models/UserTagKey'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_USER_TAG_KEYS = 'tags/GET_USER_TAG_KEYS'
export const GET_USER_TAG_KEYS_SUCCESS = 'tags/GET_USER_TAG_KEYS_SUCCESS'
export const GET_USER_TAG_KEYS_FAIL = 'tags/GET_USER_TAG_KEYS_FAIL'

export function getUserTagKeys(): AxiosAction<
  typeof GET_USER_TAG_KEYS,
  { user_tag_keys: Fields[] }
> {
  return {
    type: GET_USER_TAG_KEYS,
    payload: {
      client: 'default',
      request: {
        url: 'user_tag_keys',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getUserTagKeys>
>
