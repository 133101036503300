import React from 'react'

import { Button } from '@thanx/uikit/button'
import orderingNotEnabledImage from 'assets/images/cms/ordering-not-enabled.png'

type Props = {
  t: any
  onContactClick: (e: any) => void
}

const OrderingDisabled: React.FC<Props> = props => {
  const { t, onContactClick } = props
  return (
    <div>
      <img
        className="full-width"
        src={orderingNotEnabledImage}
        alt="ordering disabled"
      />
      <div className="grey-70 font-size-12 text-right mt-xs">
        {t('example')}
      </div>
      <Button className="mt-m" onClick={onContactClick}>
        {t('contact_disabled')}
      </Button>
    </div>
  )
}

export default OrderingDisabled
