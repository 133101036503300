import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import noIcon from 'assets/images/points/img_noIcon.svg'
import Help from 'components/Help'
import Modal from 'components/ModalContainer'
import Formsy from 'formsy-react'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import { useState } from 'react'
import Helmet from 'react-helmet'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { push } from 'react-router-redux'
import { helpArticles } from 'scenes/Points/Landing/Configuration/helpers'
import { currencyFormatter } from 'utilities/formatters'
import Card from './Card'
import Layout from './Layout'
import useUpdate from './useUpdate'

const MIN_CONVERSION_RATE = 0.0001
const MAX_CONVERSION_RATE = 10000

const t = buildTranslate('points.configuration')
const vt = buildTranslate('validations')

type Props = {} & RouteComponentProps<{ id: string }>

function Collect(props: Props) {
  const [css] = useStyletron()

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)

  const dispatch = useDispatch()

  const experienceId = props.match?.params?.id
  const {
    pointsExperience,
    formModel,
    updateFormModel,
    formState,
    setFormState,
    publish,
    isUpdating,
  } = useUpdate(experienceId, (pe: PointsExperience) => ({
    conversion_rate: pe.conversion_rate,
  }))

  if (!formModel) return null

  const showSpendMessage =
    formModel.conversion_rate < 1 && formModel.conversion_rate > 0

  async function doPublish() {
    const result = await publish()

    if (result.isErr()) {
      dispatch(
        alert({
          key: 'danger',
          message: t('edit.publish_failed'),
        })
      )
      return
    }

    dispatch(push('/points#instructions'))
    dispatch(
      alert({
        key: 'success',
        message: t('edit.publish_success'),
        timeout: 5,
      })
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('edit.collect.page_title')}</title>
      </Helmet>
      <Layout
        backLink="/points#collect"
        canPublish={formState === 'valid'}
        onPublish={() => setIsConfirmModalOpen(true)}
      >
        <Formsy
          onChange={updateFormModel}
          onInvalid={() => setFormState('invalid')}
          onValid={() => setFormState('valid')}
          role="form"
        >
          <Text.Header2 className="mb-xl mt-0">
            {t('edit.amount_spent')}
          </Text.Header2>
          <Card>
            <FormsyInput
              // @ts-ignore
              type="number"
              name="conversion_rate"
              value={formModel.conversion_rate}
              label={
                <Text.BodyText3 color="grey70" tag="span">
                  {t('edit.when_customer_spends')}
                </Text.BodyText3>
              }
              className={css({
                display: 'grid',
                gridTemplateColumns: '300px 150px 300px',
                gridGap: '10px',
                alignItems: 'center',
              })}
              min={1}
              startEnhancer={
                <img
                  height="16px"
                  src={
                    pointsExperience?.currency_primary_icon.default || noIcon
                  }
                  alt={pointsExperience?.currency_name}
                />
              }
              validations={{
                isRequired: true,
                isNumeric: true,
                greaterThanOrEqual: MIN_CONVERSION_RATE,
                lessThanOrEqual: MAX_CONVERSION_RATE,
              }}
              validationErrors={{
                greaterThanOrEqual: vt('greater_than_or_equal', {
                  value: MIN_CONVERSION_RATE,
                }),
                lessThanOrEqual: vt('less_than_or_equal', {
                  value: MAX_CONVERSION_RATE,
                }),
                isRequired: vt('is_required'),
                isNumeric: vt('is_numeric'),
              }}
            />
          </Card>
          {showSpendMessage && (
            <Text.BodyText5 color="grey70" className="mt-xs" tag="div">
              {pointsExperience
                ? t('edit.collect.customer_must_spend', {
                    cost: currencyFormatter(
                      1 / pointsExperience.conversion_rate
                    ),
                    currencyName: pointsExperience.currency_name,
                  })
                : ''}
            </Text.BodyText5>
          )}
          <Text.BodyText5 color="grey70" className="mt-xs" tag="div">
            {t('edit.inform_your_customers')}
          </Text.BodyText5>
          <Help title={t('help.title')} articles={helpArticles.collect} />
        </Formsy>
      </Layout>
      <Modal
        title={t(`edit.collect.publish_changes_title`)}
        proceedText={t(`edit.collect.publish_changes_proceed`)}
        isOpen={isConfirmModalOpen}
        onProceed={async () => {
          await doPublish()
          setIsConfirmModalOpen(false)
        }}
        onClose={() => setIsConfirmModalOpen(false)}
        isLoading={isUpdating}
      >
        {
          // map two consecutive newlines to a "paragraph" separator
          t(`edit.collect.publish_changes_content`)
            .split(`\n\n`)
            .map((p, i) => (
              <Text.BodyText4
                key={`publish-content-${i}`}
                tag="div"
                color="grey70"
                className="mb-s"
              >
                {p}
              </Text.BodyText4>
            ))
        }
      </Modal>
    </>
  )
}

export default Collect
