import { getCampaigns } from 'actions/campaigns'
import type { GetCampaignsAction } from 'actions/campaigns'
import { getCampaignTypes } from 'actions/campaignTypes'
import { updatePaginationCurrentPage } from 'actions/pagination'
import axios, { CancelTokenSource } from 'axios'
import useDispatch from 'hooks/useDispatch'
import { CampaignStateT } from 'models/Campaign'
import { CampaignPaginationT } from 'models/Pagination'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectCampaignsByQuery,
  selectReportableCampaignsByState,
} from 'selectors/campaign'
import { selectPagination } from 'selectors/pagination'
import { useDebouncedCallback } from 'use-debounce'
import type { ResolvedAxiosAction } from 'utilities/types'

type AxiosCancellationError = {
  error?: { data: string }
}

const useCampaignPagination = (
  paginationId: CampaignPaginationT['id'],
  fetchCallback: (page: number) => Promise<void>
) => {
  const dispatch = useDispatch()
  const [isFetchingPage, setIsFetchingPage] = useState(false)

  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'campaign', paginationId)
  )

  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const campaignIds = paginationInfo?.record_ids?.[currentPage] ?? []

  const onChangePage = useCallback(
    async (page: number) => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'campaign',
            id: paginationId,
            page,
          })
        )
        return
      }

      window.scrollTo(0, 0)
      setIsFetchingPage(true)
      await fetchCallback(page)
      setIsFetchingPage(false)
    },
    [dispatch, paginationInfo, paginationId, fetchCallback]
  )

  return {
    isFetchingPage,
    currentPage,
    onChangePage,
    numPages,
    campaignIds,
  }
}

export const useCampaignStatePagination = (state: CampaignStateT) => {
  const dispatch = useDispatch()
  const paginationId = `${state}-all` as CampaignPaginationT['id']

  const fetchPage = useCallback(
    async (page: number) => {
      await dispatch(getCampaigns({ state, page }))
    },
    [dispatch, state]
  )

  return useCampaignPagination(paginationId, fetchPage)
}

export const useCampaignSearchPagination = (query?: string) => {
  const dispatch = useDispatch()
  const paginationId = `search-${query || 'all'}` as CampaignPaginationT['id']

  const fetchPage = useCallback(
    async (page: number) => {
      await dispatch(getCampaigns({ query, page }))
    },
    [dispatch, query]
  )

  return useCampaignPagination(paginationId, fetchPage)
}

// Fetches and manages campaigns grouped by their states (draft, active, etc.)
export const useFetchCampaignsByState = () => {
  const reviewCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'in_review')
  )
  const scheduledCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'scheduled')
  )
  const activeCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'active')
  )
  const draftCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'draft')
  )
  const completeCampaigns = useSelector(store =>
    selectReportableCampaignsByState(store, 'complete')
  )

  const [reviewIsLoading, setReviewIsLoading] = useState<boolean>(
    reviewCampaigns.length === 0
  )
  const [scheduledIsLoading, setScheduledIsLoading] = useState<boolean>(
    scheduledCampaigns.length === 0
  )
  const [activeIsLoading, setActiveIsLoading] = useState<boolean>(
    activeCampaigns.length === 0
  )
  const [draftIsLoading, setDraftIsLoading] = useState<boolean>(
    draftCampaigns.length === 0
  )
  const [completeIsLoading, setCompleteIsLoading] = useState<boolean>(
    completeCampaigns.length === 0
  )

  const dispatch = useDispatch()

  // Keep track of the current fetch request for each state to cancel it if needed
  const abortControllerRef = useRef<{
    [key in CampaignStateT]: CancelTokenSource | null
  }>({
    active: null,
    in_review: null,
    draft: null,
    complete: null,
    scheduled: null,
    archived: null,
  })

  const fetchData = useCallback(
    async (state: CampaignStateT) => {
      const requestCancelledMessage = 'Request was canceled due to new one.'
      // If there's already an existing request for this state, cancel it
      if (abortControllerRef.current[state]) {
        abortControllerRef.current[state]?.cancel(requestCancelledMessage)
      }

      // Create a new cancel token for the current request
      const cancelTokenSource = axios.CancelToken.source()

      // Store the new cancel token source for the current state
      abortControllerRef.current[state] = cancelTokenSource

      const response = (await dispatch(
        getCampaigns({
          state,
          page: 1,
          cancelToken: cancelTokenSource.token,
        })
      )) as ResolvedAxiosAction<GetCampaignsAction> & AxiosCancellationError

      if (response?.error?.data === requestCancelledMessage) {
        return
      } else {
        handleResults(state)
      }
    },
    [dispatch]
  )

  function handleResults(state) {
    switch (state) {
      case 'active':
        setActiveIsLoading(false)
        break
      case 'in_review':
        setReviewIsLoading(false)
        break
      case 'draft':
        setDraftIsLoading(false)
        break
      case 'complete':
        setCompleteIsLoading(false)
        break
      case 'scheduled':
        setScheduledIsLoading(false)
        break
      default:
        break
    }
  }

  useEffect(() => {
    async function fetchTypes() {
      await dispatch(getCampaignTypes())
    }

    fetchTypes()
  }, [dispatch])

  useEffect(() => {
    fetchData('draft')
    fetchData('active')
    fetchData('complete')
    fetchData('scheduled')
    fetchData('in_review')
  }, [fetchData])

  return {
    review: {
      campaigns: reviewCampaigns,
      loading: reviewIsLoading,
    },
    scheduled: {
      campaigns: scheduledCampaigns,
      loading: scheduledIsLoading,
    },
    active: {
      campaigns: activeCampaigns,
      loading: activeIsLoading,
    },
    draft: {
      campaigns: draftCampaigns,
      loading: draftIsLoading,
    },
    complete: {
      campaigns: completeCampaigns,
      loading: completeIsLoading,
    },
  }
}

// Handles searching/filtering campaigns across all states
export const useSearchCampaigns = (query?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const dispatch = useDispatch()
  const { isFetchingPage, currentPage, numPages, onChangePage, campaignIds } =
    useCampaignSearchPagination(query)

  const abortControllerRef = useRef<CancelTokenSource | null>(null)

  const [debouncedFetchData] = useDebouncedCallback(
    async (searchQuery: string, page: number = 1) => {
      const requestCancelledMessage = 'Request was canceled due to new one.'

      if (abortControllerRef.current) {
        abortControllerRef.current.cancel(requestCancelledMessage)
      }

      const cancelTokenSource = axios.CancelToken.source()
      abortControllerRef.current = cancelTokenSource

      try {
        const response = (await dispatch(
          getCampaigns({
            query: searchQuery,
            page,
            cancelToken: cancelTokenSource.token,
          })
        )) as ResolvedAxiosAction<GetCampaignsAction> & AxiosCancellationError

        if (response?.error?.data === requestCancelledMessage) {
          return
        }
      } finally {
        setIsLoading(false)
      }
    },
    500
  )

  useEffect(() => {
    if (!query) {
      return
    }

    setIsLoading(true)
    debouncedFetchData(query, 1)
  }, [query, debouncedFetchData])

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.cancel('Component unmounted')
      }
    }
  }, [])

  const campaigns = useSelector(store =>
    selectCampaignsByQuery(store, { query })
  ).filter(campaign => campaignIds.includes(campaign.id))

  return {
    campaigns,
    isLoading: isLoading || isFetchingPage,
    pagination: {
      currentPage,
      numPages,
      onChangePage,
    },
  }
}
