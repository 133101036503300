import { Switch } from '@thanx/uikit/switch'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import {
  ACTIVE_CAMPAIGN_STATES,
  INACTIVE_CAMPAIGN_STATES,
} from 'constants/thanxCampaigns'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import CampaignsTableSection from 'scenes/CampaignCenter/Landing/CampaignsTableSection'
import { selectCampaigns } from 'selectors/campaign'
import { useCampaigns } from '../Automated/hooks'

type Props = {
  className?: string
}

const t = buildTranslate('thanx_campaigns.landing.targeted.campaignsTable')

const ExistingCampaignsTable: React.FC<Props> = ({ className = '' }) => {
  const { isFetchingPage, currentPage, numPages, onChangePage, campaignIds } =
    useCampaigns('targeted')

  const campaigns = useSelector(state => selectCampaigns(state)).filter(
    campaign => campaignIds.includes(campaign.id)
  )

  const states = [...ACTIVE_CAMPAIGN_STATES, ...INACTIVE_CAMPAIGN_STATES]
  const displayableCampaigns = campaigns
    .filter(campaign => states.includes(campaign.state))
    .sort((campaignA, campaignB) => {
      // sort by start date or last updated
      const dateA = new Date(
        campaignA.start_at || campaignA.updated_at
      ).valueOf()
      const dateB = new Date(
        campaignB.start_at || campaignB.updated_at
      ).valueOf()
      return dateB - dateA
    })

  if (displayableCampaigns.length === 0) return null

  return (
    <>
      <Spinner className="text-center" isLoading={isFetchingPage} size={'3x'}>
        <CampaignsTableSection
          campaigns={displayableCampaigns}
          title={t('title')}
          columns={['name', 'createdBy', 'status']}
          className={className}
        />
      </Spinner>
      <Switch condition={campaigns.length > 0}>
        <div className={'d-flex justify-content-center mt-l mb-l'}>
          <Pagination
            currentPage={currentPage}
            onChangePage={onChangePage}
            numPages={numPages}
          />
        </div>
      </Switch>
    </>
  )
}

export default ExistingCampaignsTable
