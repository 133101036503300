import { Text } from '@thanx/uikit/text'
import { TextColorKeys } from '@thanx/uikit/theme/colors'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import { Fields as UserReward } from 'models/UserReward'
import React from 'react'

export type ActionType = 'retire' | 'reissue'

type Props = {
  reward: UserReward | null
  actionType: ActionType | null
  onConfirm: VoidFunction
  onCancel: VoidFunction
  isLoading: boolean
}

const t = buildTranslate('users.view.activity.rewards.action_modal')

const ConfirmActionModal = ({
  reward,
  actionType,
  onCancel,
  onConfirm,
  isLoading,
}: Props) => {
  if (!reward || !actionType) return null
  return (
    <Modal
      isOpen
      title={t(`${actionType}.title`, { name: reward.redeem_description })}
      proceedText={t(`${actionType}.proceed`)}
      onProceed={onConfirm}
      onClose={onCancel}
      isLoading={isLoading}
    >
      <Text.BodyText4 color={TextColorKeys.grey70}>
        {t(`${actionType}.description`)}
      </Text.BodyText4>
    </Modal>
  )
}

export default ConfirmActionModal
