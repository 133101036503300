import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import { InputGroup } from 'react-bootstrap'

type Props = {
  value: string
  disabled?: boolean
  onClick: () => void
}

const Enhancer: React.FC<Props> = ({ value, disabled = false, onClick }) => {
  const [css] = useStyletron()
  return (
    <InputGroup.Addon
      className={css({
        backgroundColor: value,
        width: '46px',
        cursor: disabled ? 'not-allowed' : 'pointer',
      })}
      onClick={onClick}
    />
  )
}

export default Enhancer
