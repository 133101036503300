import type { Fields as Campaign } from 'models/Campaign'

export type UserTypeT = 'member' | 'subscriber'
export type VariantT = 'reward' | 'loyalty'

export function getUserTypes(campaign: Campaign): UserTypeT[] {
  if (campaign.type === 'message') {
    let types: UserTypeT[] = []
    if (campaign.config_target_member) {
      types.push('member')
    }
    if (campaign.config_target_subscriber) {
      types.push('subscriber')
    }
    return types
  }

  return ['member']
}
