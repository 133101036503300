import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { getApp } from 'actions/app'
import Help from 'components/Help'
import Formsy from 'formsy-react'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import { Fields as Location } from 'models/Location'
import { RedemptionProvider } from 'models/RedemptionProvider'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import EditableName from 'scenes/CampaignCenter/Builder/components/EditableName'
import { getUniqueRedemptionProviders } from 'scenes/RedeemManager/helpers'
import SendTestModal from 'scenes/RedeemManager/Modals/SendTestModal'
import { selectCouponPoolsByTemplateId } from 'selectors/couponPool'
import { selectUploadsByCouponPoolId } from 'selectors/fileUpload'
import { useAbility } from 'utilities/ability'
import { useIsMobile } from 'utilities/responsive'
import ActivateModal from '../../Modals/ActivateModal'
import { FormModel, useFormModel } from '../FormModel'
import { Step } from '../index'
import Success from '../Success'
import TopBar from '../TopBar'
import AppearanceCard from './AppearanceCard'
import CustomerSupportCard from './CustomerSupportCard'
import DescriptionCard from './DescriptionCard'
import DiscountCard from './DiscountCard'
import { getHelp } from './helpers'
import InstoreRedemptionCard from './InstoreRedemptionCard'
import ItemValueCard from './ItemValueCard'
import MultipleRedemptionProviders from './MultipleRedemptionProviders'
import LocationPosModal from './MultipleRedemptionProviders/LocationPosModal'
import OnlineRedemptionCard from './OnlineRedemptionCard'
import Preview from './Preview'
import Restrictions from './Restrictions'

export enum Card {
  BONUS_POINTS,
  REWARD_DESCRIPTION,
  REWARD_APPEARANCE,
  ITEM_VALUE,
  ONLINE_REDEMPTION,
  INSTORE_REDEMPTION,
  CUSTOMER_SUPPORT,
}

type Props = {
  templateId: number
  isLoading: boolean
  isDraft: boolean
  isValidForm: boolean
  setIsValidForm: (isValid: boolean) => void
  isValidCouponPool: boolean
  setIsValidCouponPool: (isValid: boolean) => void
  onStepSelected: (step: Step) => void
  onFormChange: (newFormModel: FormModel) => void
  onSuccessExit: () => void
  saveForm: () => void
  locations: Location[]
  setConfigureProviderStep: (configureProviderStep: boolean) => void
  setSelectedProvider: (redemptionProvider: RedemptionProvider) => void
}

const t = buildTranslate('redeem_manager.builder.steps.configuration')

const ConfigurationStep: React.FC<Props> = ({
  templateId,
  isLoading,
  isDraft,
  isValidForm,
  setIsValidForm,
  isValidCouponPool,
  setIsValidCouponPool,
  onStepSelected,
  onFormChange,
  onSuccessExit,
  saveForm,
  locations,
  setConfigureProviderStep,
  setSelectedProvider,
}) => {
  const formModel = useFormModel().model
  const {
    typeItem,
    name,
    description,
    availableOnline,
    availableInstore,
    availableCustomerSupport,
    discountedProductIds,
    requiredProductIds,
    instoreRedemptionType,
    couponCodes,
    hiddenMenuKey,
    longDescription,
    selectedCouponPools,
  } = formModel
  const isMobile = useIsMobile()
  const merchant = useCurrentMerchant()
  const ability = useAbility()
  const canAccessFeedback = ability.can('schedule', 'Campaign')
  const subtype = typeItem?.subtype
  const key = typeItem?.key
  const isForWholePurchase = subtype === 'purchase'
  const dispatch = useDispatch()
  const formRef = React.useRef() as React.MutableRefObject<HTMLFormElement>

  // Loading app so that the reward icon and colors exist
  useEffect(() => {
    dispatch(getApp())
  }, [dispatch])

  const couponPools = useSelector(state =>
    selectCouponPoolsByTemplateId(state, templateId)
  )
  const fileUploads = useSelector(state => {
    return !!couponPools?.length
      ? selectUploadsByCouponPoolId(state, couponPools?.[0].id)
      : []
  })
  const isHiddenMenu = key === 'hidden_menu'
  const isGoldenTicket = key === 'golden_ticket'
  const isExperience = isHiddenMenu || isGoldenTicket

  const locationSpecificPosEnabled = useFlag('provider-redemption', false)
  const multiplePosSystems =
    locationSpecificPosEnabled && merchant?.has_multi_pos
  const redemptionProviders = getUniqueRedemptionProviders(
    locations,
    merchant?.redemption_provider
  )

  const [currentCard, setCurrentCard] = useState<Card>(() => {
    if (!isDraft) return Card.CUSTOMER_SUPPORT
    if (!description) return Card.REWARD_DESCRIPTION
    let isOnlineRedemptionFilled = true
    if (availableOnline) {
      if (key === 'hidden_menu') {
        isOnlineRedemptionFilled = !isEmpty(hiddenMenuKey?.[0])
      } else if (subtype === 'bogo/item') {
        isOnlineRedemptionFilled =
          !!discountedProductIds && !!requiredProductIds
      } else if (subtype !== 'purchase') {
        isOnlineRedemptionFilled = !!discountedProductIds
      }
    }

    if (!isOnlineRedemptionFilled) return Card.ONLINE_REDEMPTION
    // If this code is reached we are editing the template
    // If a merchant does not have multiple POS, we can't know if instore card is filled
    // as it has a default value. So simply start from customer support card.
    if (multiplePosSystems) return Card.INSTORE_REDEMPTION
    if (canAccessFeedback) return Card.CUSTOMER_SUPPORT
    return isHiddenMenu ? Card.CUSTOMER_SUPPORT : Card.INSTORE_REDEMPTION
  })
  const [isPublished, setIsPublished] = useState(false)
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false)
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false)
  const [isLocationsPosModalOpen, setIsLocationsPosModalOpen] = useState(false)
  let isAllValid = isValidForm
  if (currentCard >= Card.INSTORE_REDEMPTION) {
    isAllValid =
      isValidForm &&
      (isValidCouponPool || !!merchant?.supports_loyalty_integration)
  }

  let isLastCard = currentCard === Card.CUSTOMER_SUPPORT
  if (!canAccessFeedback) {
    isLastCard = currentCard === Card.INSTORE_REDEMPTION
  }
  const isComplete = isLastCard && isAllValid

  function handleFormChange(newFormModel: FormModel, isChanged: boolean) {
    const { availableOnline, availableInstore } = newFormModel

    // move to instore-redemption card when online-redemption is disabled
    if (
      currentCard === Card.ONLINE_REDEMPTION &&
      ((!availableOnline && !merchant?.online_only) ||
        !merchant?.ordering_enabled)
    ) {
      setCurrentCard(Card.INSTORE_REDEMPTION)
    }

    // move to support card when instore-redemption is disabled
    if (
      currentCard === Card.INSTORE_REDEMPTION &&
      canAccessFeedback &&
      availableInstore === false
    ) {
      setCurrentCard(Card.CUSTOMER_SUPPORT)
    }

    // this method is triggered by formsy when new inputs are attached
    // to the form. So I'm filtering them here before updating the model.
    if (
      isChanged ||
      (newFormModel.longDescription === null &&
        longDescription !== newFormModel.longDescription)
    ) {
      onFormChange(newFormModel)
    }
  }

  // validate coupon pools here as they are not validated within the form
  useEffect(() => {
    let isValid = true
    if (
      availableInstore &&
      instoreRedemptionType === 'coupon_codes' &&
      couponCodes.generationType === 'variable' &&
      (isDraft || !isEmpty(fileUploads))
    ) {
      // first, check if we have an existing pool selected
      if (!!selectedCouponPools?.length) {
        isValid = true
      } else {
        // form is valid if we have at least one finalized
        // and none processing file uploads.
        isValid =
          fileUploads.some(u => u.state === 'finalized') &&
          !fileUploads.some(u => u.state === 'processing')
      }
    }
    setIsValidCouponPool(isValid)
  }, [
    availableInstore,
    instoreRedemptionType,
    couponCodes,
    fileUploads,
    isValidCouponPool,
    setIsValidCouponPool,
    isDraft,
    selectedCouponPools,
  ])
  if (!typeItem) return null

  if (isPublished) {
    return <Success />
  }

  const getCardOrder = () => {
    const cardOrder = [Card.REWARD_DESCRIPTION, Card.REWARD_APPEARANCE]
    if (!isHiddenMenu) {
      cardOrder.push(Card.ITEM_VALUE)
    }
    if (merchant?.ordering_enabled && !isForWholePurchase && !isGoldenTicket) {
      cardOrder.push(Card.ONLINE_REDEMPTION)
    }
    if (!merchant?.online_only) {
      cardOrder.push(Card.INSTORE_REDEMPTION)
    }
    if (canAccessFeedback) {
      cardOrder.push(Card.CUSTOMER_SUPPORT)
    }
    return cardOrder
  }

  const handleNext = () => {
    const cardOrder = getCardOrder()
    const index = cardOrder.indexOf(currentCard)
    if (index < cardOrder.length) setCurrentCard(cardOrder[index + 1])
  }

  const helpArticles = getHelp(typeItem.key)

  return (
    <>
      <ActivateModal
        templateId={templateId}
        name={name}
        isOpen={isActivateModalOpen}
        onClose={() => setIsActivateModalOpen(false)}
        onActivate={() => setIsPublished(true)}
      />
      <LocationPosModal
        locations={locations}
        isOpen={isLocationsPosModalOpen}
        onClose={() => setIsLocationsPosModalOpen(false)}
      />
      <SendTestModal
        templateId={templateId}
        isOpen={isSendTestModalOpen}
        isLoading={isLoading}
        onClose={() => setIsSendTestModalOpen(false)}
        onBeforeSend={async () => {
          // save template to make sure the data synced with the backend
          await saveForm()
        }}
        onSuccess={() => setIsSendTestModalOpen(false)}
        showHiddenMenuInfo={key === 'hidden_menu'}
      />
      <TopBar
        step="configuration"
        templateId={templateId}
        hideTypeStep={!isDraft}
        onStepSelected={onStepSelected}
      >
        <Button
          className="mr-m"
          kind="secondary_outline"
          disabled={!isDraft && !isAllValid}
          isLoading={isLoading}
          onClick={async () => {
            await saveForm()
            onSuccessExit()
          }}
        >
          {t('save_exit')}
        </Button>
        <Button
          className="mr-m"
          kind="secondary_outline"
          disabled={!isComplete}
          isLoading={isLoading}
          onClick={() => setIsSendTestModalOpen(true)}
        >
          {t('send_a_test')}
        </Button>
        <Switch condition={isDraft}>
          <Button
            className={isMobile ? 'mt-xs' : ''}
            kind="secondary_outline"
            disabled={!isComplete}
            isLoading={isLoading}
            onClick={async () => {
              await saveForm()
              setIsActivateModalOpen(true)
            }}
          >
            {t('activate')}
          </Button>
        </Switch>
      </TopBar>
      <Container>
        <Formsy
          // @ts-ignore
          ref={formRef}
          className="text-left form-group form-group-lg"
          onValid={() => setIsValidForm(true)}
          onInvalid={() => setIsValidForm(false)}
          onChange={handleFormChange}
        >
          <Row>
            <Col className="my-xl">
              <EditableName
                name={formModel.name}
                styleSize="large"
                type="redeem"
              />
              <p className="body-text-2 grey-70">
                {I18n.t(`redeem_manager.types.${typeItem.key}.title`)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <DescriptionCard
                isDisabled={!isDraft && formModel.availableOnline}
                description={description}
                longDescription={longDescription}
                example={I18n.t(
                  `redeem_manager.types.${typeItem.key}.description_example`
                )}
                isCurrent={currentCard === Card.REWARD_DESCRIPTION}
                isValidForm={isAllValid}
                onNext={handleNext}
              />
              <AppearanceCard
                description={description}
                isEnabled
                isCurrent={currentCard === Card.REWARD_APPEARANCE}
                isOpen={currentCard >= Card.REWARD_APPEARANCE}
                onNext={handleNext}
                templateId={templateId}
              />
              {!isHiddenMenu && (
                <Switch
                  condition={
                    typeItem.types.includes('manual/item') || isGoldenTicket
                  }
                  fallback={
                    <DiscountCard
                      isDisabled={!isDraft}
                      isOpen={currentCard >= Card.ITEM_VALUE}
                      isCurrent={currentCard === Card.ITEM_VALUE}
                      isValidForm={isAllValid}
                      onNext={handleNext}
                    />
                  }
                >
                  <ItemValueCard
                    isOpen={currentCard >= Card.ITEM_VALUE}
                    isCurrent={currentCard === Card.ITEM_VALUE}
                    isValidForm={isAllValid}
                    onNext={handleNext}
                  />
                </Switch>
              )}
              <OnlineRedemptionCard
                templateId={templateId}
                isDraft={isDraft}
                isEnabled={availableOnline && !isGoldenTicket}
                isOpen={currentCard >= Card.ONLINE_REDEMPTION}
                isCurrent={currentCard === Card.ONLINE_REDEMPTION}
                isValidForm={isAllValid}
                hideToggle={isGoldenTicket}
                onNext={handleNext}
              />
              <Switch
                // Fallback to showing only a single card if it's an experience reward
                condition={!isExperience && multiplePosSystems}
                fallback={
                  <InstoreRedemptionCard
                    templateId={templateId}
                    isEnabled={availableInstore}
                    isOpen={currentCard >= Card.INSTORE_REDEMPTION}
                    isCurrent={currentCard === Card.INSTORE_REDEMPTION}
                    isDraft={isDraft}
                    isValidForm={isAllValid}
                    onNext={handleNext}
                  />
                }
              >
                <MultipleRedemptionProviders
                  redemptionProviders={redemptionProviders}
                  isEnabled={availableInstore}
                  isOpen={currentCard >= Card.INSTORE_REDEMPTION}
                  isCurrent={currentCard === Card.INSTORE_REDEMPTION}
                  isDraft={isDraft}
                  isValidForm={isAllValid}
                  onNext={handleNext}
                  setLocationModalOpen={setIsLocationsPosModalOpen}
                  setConfigureProviderStep={setConfigureProviderStep}
                  setSelectedProvider={setSelectedProvider}
                />
              </Switch>
              <Switch condition={canAccessFeedback}>
                <CustomerSupportCard
                  isEnabled={availableCustomerSupport && !isExperience}
                  isOpen={currentCard >= Card.CUSTOMER_SUPPORT}
                  hideToggle={isExperience}
                />
              </Switch>
              <Restrictions />
              <Help
                articles={helpArticles}
                title={I18n.t(
                  'redeem_manager.builder.steps.configuration.help.title'
                )}
              />
              <Switch condition={isComplete}>
                <Button
                  className="w-100"
                  isLoading={isLoading}
                  onClick={() => setIsSendTestModalOpen(true)}
                >
                  {t('send_a_test')}
                </Button>
              </Switch>
            </Col>
            <Col
              className="d-sm-none d-md-block"
              lg={{
                span: 4,
                offset: 1,
              }}
            >
              <Preview
                currentCard={currentCard}
                isExperience={isExperience}
                templateId={templateId}
              />
            </Col>
          </Row>
        </Formsy>
      </Container>
    </>
  )
}

export default ConfigurationStep
