import { Switch } from '@thanx/uikit/switch'
import { Fields as Campaign } from 'models/Campaign'
import { Fields as NotifyEmail } from 'models/NotifyEmail'
import React from 'react'
import { getUserTypes } from 'utilities/emailUtils'
import { useLoadPreview } from './helpers'
import Preview from './Preview'
import TabContainer from './TabContainer'

type Props = {
  campaign: Campaign
  notifyEmail: NotifyEmail
  navStyle?: 'tab' | 'radio'
  showDescription?: boolean
}

const ImportPreview: React.FC<Props> = ({
  campaign,
  notifyEmail,
  navStyle = 'tab',
  showDescription = true,
}) => {
  const variantId =
    notifyEmail.type === 'variant' ? notifyEmail.variant_id : null
  const userTypes = getUserTypes(campaign)

  const { memberContent, subscriberContent } = useLoadPreview(
    userTypes,
    notifyEmail.campaign_id,
    variantId
  )

  const previewWidth =
    //@ts-ignore
    notifyEmail?.notify_email_configuration?.body?.values?.contentWidth

  return (
    <Switch
      condition={userTypes.length === 1}
      fallback={
        <TabContainer
          userTypes={userTypes}
          memberContent={memberContent}
          subscriberContent={subscriberContent}
          navStyle={navStyle}
          previewWidth={previewWidth}
          showDescription={showDescription}
        />
      }
    >
      <Preview
        userType={userTypes[0]}
        navStyle={navStyle}
        userContent={
          userTypes[0] === 'member' ? memberContent : subscriberContent
        }
        width={previewWidth}
        showDescription={showDescription}
      />
    </Switch>
  )
}

export default ImportPreview
