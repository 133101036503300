import React from 'react'

type Props = {
  color?: string
}

const ArrowLeft: React.FC<Props> = props => {
  const { color = 'currentColor' } = props

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.04754 13.0623L7.35387 13.774C7.06015 14.0753 6.5852 14.0753 6.29461 13.774L0.220288 7.545C-0.0734291 7.24365 -0.0734291 6.75635 0.220288 6.45821L6.29461 0.226013C6.58833 -0.0753374 7.06327 -0.0753374 7.35387 0.226013L8.04754 0.937715C8.34438 1.24227 8.33813 1.73918 8.03504 2.03733L4.26984 5.71766L13.2501 5.71766C13.6657 5.71766 14 6.06068 14 6.48706L14 7.51294C14 7.93932 13.6657 8.28234 13.2501 8.28234H4.26984L8.03504 11.9627C8.34126 12.2608 8.34751 12.7577 8.04754 13.0623Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowLeft
