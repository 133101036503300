import { buildTranslate } from 'locales'
import type { DayBucketAmountData } from 'models/MerchantMetric'
import React from 'react'
import MetricSummary from 'scenes/Reports/ActivityReport/components/MetricSummary'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate(
  'reports.activity.sections.transactions.metrics.total_spend'
)

type Props = {
  data: DayBucketAmountData
}

export const AvgSpend: React.FC<Props> = ({ data }) => {
  const secondarySummaryValue =
    data['365_day_amount'] > 0
      ? `(${currencyFormatter(data['365_day_amount'])} ${t(
          'overall_qualifier'
        )})`
      : undefined

  return (
    <MetricSummary
      title={t('title')}
      summaryValue={currencyFormatter(data.total_amount)}
      secondarySummaryValue={secondarySummaryValue}
      summaryText={t('summary_text')}
    />
  )
}

export default AvgSpend
