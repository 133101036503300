import { buildTranslate } from 'locales'
import { CampaignStateT, MinimalVariant } from 'models/Campaign'
import { RedeemType } from 'models/RedeemTemplate'
import React from 'react'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import Section from '../Section'
import Cogs from './Cogs'
import Delivered from './Delivered'
import Discount from './Discount'
import Purchases from './Purchases'
import Redeemed from './Redeemed'
import Revenue from './Revenue'

const t = buildTranslate('thanx_campaigns.report.v3.summary.revenue')

type Props = {
  campaignId: number
  campaignState: CampaignStateT
  variantIds: number[]
  variantTypes: (string | undefined)[]
  variants: MinimalVariant[]
  winningVariantId: number | null
}
const RevenueCosts: React.FC<Props> = ({
  campaignId,
  campaignState,
  winningVariantId,
  variantIds,
  variantTypes,
  variants,
}) => {
  const redeemTypes = variants.map(v => v.redeem_type) as RedeemType[]
  const { attributionWindow } = useAttributionWindow()

  return (
    <Section
      title={t('title')}
      attributionWindow={attributionWindow}
      attributionTooltip={t('attribution.tooltip', {
        count: attributionWindow,
      })}
      campaignId={campaignId}
      metricTypes={['redeem', 'revenue', 'message_count']}
      campaignState={campaignState}
      winningVariantId={winningVariantId}
      variants={variants}
    >
      <Delivered
        campaignId={campaignId}
        variantIds={variantIds}
        variantTypes={variantTypes}
      />
      <Revenue
        campaignId={campaignId}
        variantIds={variantIds}
        variantTypes={variantTypes}
      />
      <Purchases
        campaignId={campaignId}
        variantIds={variantIds}
        variantTypes={variantTypes}
      />
      <Redeemed
        campaignId={campaignId}
        variantIds={variantIds}
        variantTypes={variantTypes}
        redeemTypes={redeemTypes}
      />
      <Discount
        campaignId={campaignId}
        variantIds={variantIds}
        variantTypes={variantTypes}
        redeemTypes={redeemTypes}
      />
      <Cogs
        campaignId={campaignId}
        variantIds={variantIds}
        variantTypes={variantTypes}
        redeemTypes={redeemTypes}
      />
    </Section>
  )
}

export default RevenueCosts
