import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { Fields as Link } from 'models/Link'
import React from 'react'
import Header from '../../components/Header'
import Links from '../../components/Links'

const t = buildTranslate('cms.content.custom_links.rewards_links')

type Props = {
  links: Link[]
}

const RewardsLinks: React.FC<Props> = ({ links }) => {
  if (links.length === 0) return null

  return (
    <Section className="p-m mt-l">
      <Header
        title={t('title')}
        description={t('description')}
        className="mb-xxl"
      />
      <Links links={links} showAuthenticable />
    </Section>
  )
}

export default RewardsLinks
