export default {
  responses: {
    title: 'Customer feedback',
    view_report: 'View report',
    filters: {
      location: {
        default_option: 'All locations',
      },
      unread: 'Unread (30 days)',
      has_review: 'Has feedback',
      critical: 'Critical / Important',
      view_all: 'View all',
    },
    no_responses: {
      title: 'There is no feedback yet',
      subtitle: 'Check back soon',
      title_with_filter: 'There are no more results that match your filter',
      subtitle_with_filter: 'Remove your filters to see all of your feedback',
    },
    table: {
      headers: {
        nps: 'NPS',
        urgency: 'Urgency',
        response: 'Response',
        customer: 'Customer',
        feedback: 'Feedback',
        date: 'Date',
        view: 'View',
      },
      popover: {
        important: {
          title: 'Important',
          text: 'This is a valuable customer who has historically given you positive reviews until this negative one.',
        },
        critical: {
          title: 'Critical',
          text: 'This is the first rating made by this customer at %{merchantName}. Initial feedback that is negative is a strong indicator of a lost opportunity to make a good first impression.',
        },
      },
    },
    helpers: {
      title: 'Help',
      content: 'What is a net promoter score (NPS)?',
    },
    modal: {
      headers: {
        from: 'From',
        view_profile: 'View profile',
        promoter: 'Promoter',
        passive: 'Passive',
        detractor: 'Detractor',
      },
      alert_banner: {
        title: 'CRITICAL',
        text: 'This is the first rating made by this customer at %{merchantName}. Initial feedback that is negative is a strong indicator of a lost opportunity to make a good first impression. A sincere reply now can make a difference',
      },
      order_details: {
        title: 'This Visit',
        subtitleOne: 'Order',
        subtitleTwo: 'Location',
        view_details: 'View order details',
      },
      message: {
        title: 'Message to you',
        description:
          'Sometimes transactions and credits are delayed. If a customer brings to your attention that they did not receive credit a purchase, please send the details to our support team and we’ll make sure that the credit is applied.',
        contact_us: 'Contact us',
      },
      respond: {
        title: 'Your reply',
        title_with_response: "%{responderName}'s reply",
        submit: 'Send reply',
        customer_email: 'Replies to your message will go directly to %{email}',
        grant_reward: {
          title: 'Reward',
          optional: '(Optional)',
          include_reward: 'Include a reward',
          exclude_reward: "Don't include a reward",
          how_to_add_a_reward: 'How to add a new reward',
          progress_toward_loyalty: 'Progress toward the loyalty reward',
          earn_progress: 'How much progress the customer gets',
          reward_default: 'This reply did not grant a reward',
          reward_default_note:
            'If you granted a reward, it has not been issued yet. The reward will appear here once it is issued.',
        },
      },
    },
    errors: {
      update: 'An error happened while trying to update the feedback.',
      grant: 'An error happened while trying to grant the user reward.',
    },
  },
}
