import React from 'react'
import { ChromePicker } from 'react-color'

type Props = {
  onChangeSlider: (color) => void
  value: string
  onClick: () => void
}

const ChromePickerComponent: React.FC<Props> = ({
  onChangeSlider,
  value,
  onClick,
}) => {
  return (
    <div className="color-picker-container">
      <div className="fixed top-0 bottom-0 left-0 right-0" onClick={onClick} />
      <ChromePicker
        onChangeComplete={onChangeSlider}
        color={value}
        disableAlpha={true}
      />
    </div>
  )
}

export default ChromePickerComponent
