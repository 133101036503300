// @flow
import { createSelector } from 'redux-orm'
// $FlowFixMe
import orm from '../models/orm'
// $FlowFixMe
import type { State } from '../models/orm'
import type { EmailProviderT } from '../models/EmailProvider'

const getOrmState = ormState => ormState
const getProviderType = (ormState, providerType) => providerType

export const selectActiveProviders = createSelector<State, EmailProviderT[]>(
  orm,
  getOrmState,
  session => {
    return session.EmailProvider.all()
      .filter(provider => {
        return ['enabled', 'pending'].includes(provider.state)
      })
      .toModelArray()
  }
)

export const selectActiveProviderByType = createSelector<
  State,
  EmailProviderT | null
>(
  orm,
  [getOrmState, selectActiveProviders, getProviderType],
  (session, providers, providerType) => {
    return (
      providers.find(provider => {
        return provider.type === providerType
      }) || null
    )
  }
)
