import { Notification } from '@thanx/uikit/notification'
import { Switch } from '@thanx/uikit/switch'
import FlagIcon from 'components/FlagIcon'
import Spinner from 'components/Spinner'
import useProducts from 'hooks/useProducts'
import { buildTranslate } from 'locales'
import React from 'react'
import ProductItem from './Item'

const t = buildTranslate('redeem_manager.detail.cards.online_redemption')

type Props = {
  productIds: string[]
}

const ProductList: React.FC<Props> = ({ productIds }) => {
  const { products, isLoading, shouldDisplayIds } = useProducts()

  if (isLoading) {
    return <Spinner isLoading className="text-center margin-top-huge" />
  }

  const anyFound = productIds.some(
    productId => !!products.find(product => product.chain_uid === productId)
  )

  return (
    <div>
      <Switch
        condition={!shouldDisplayIds && anyFound}
        fallback={
          <>
            <p>{productIds.join(', ')}</p>
            <Notification kind="warning" size="full">
              <FlagIcon color="#e7b41a" />
              <span className="ml-xs body-text-4">
                {t('items_not_available')}
              </span>
            </Notification>
          </>
        }
      >
        {productIds.map(id => {
          const product = products.find(p => p.chain_uid === id)
          if (!product) return null
          return <ProductItem key={product.chain_uid} product={product} />
        })}
      </Switch>
    </div>
  )
}

export default ProductList
