import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import noAssetImage from 'assets/images/cms/no_asset.svg'
import Info from 'components/Info'
import { buildTranslate } from 'locales'
import { Fields as Link } from 'models/Link'
import React from 'react'

const t = buildTranslate('cms.content.custom_links.premium_links')

type Props = {
  link: Link
}
const LinkRow: React.FC<Props> = ({ link }) => {
  const {
    title = '-',
    cell_subtitle = '-',
    cell_title = '-',
    cell_background_image_urls,
  } = link
  const [css] = useStyletron()

  return (
    <div className="grey-30-border border-top p-m">
      <div className="row">
        <div className="col">
          <Info
            className="mb-m"
            label={t('cover_image')}
            value={
              <Switch
                condition={!!cell_background_image_urls?.small}
                fallback={
                  <img
                    className={css({ width: '100px' })}
                    src={noAssetImage}
                    alt={t('cover_image')}
                  />
                }
              >
                <img
                  className={`${css({
                    width: '150px',
                    height: '130px',
                    objectFit: 'cover',
                  })} mt-s`}
                  src={cell_background_image_urls?.small || noAssetImage}
                  alt={t('cover_image')}
                />
              </Switch>
            }
          />
        </div>
        <div className="col">
          <Info className="mb-m" label={t('title_name')} value={title} />
          <Info className="mb-m" label={t('subtitle')} value={cell_subtitle} />
        </div>
        <div className="col">
          {/* TODO: find out which property to print for banner text */}
          <Info className="mb-m" label={t('banner_text')} value={title} />
        </div>
        <div className="col">
          <Info className="mb-m" label={t('button_text')} value={cell_title} />
        </div>
      </div>
    </div>
  )
}

export default LinkRow
