import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import NotAvailable from 'scenes/CampaignCenter/Report/v3/Summary/components/NotAvailable'
import ManualItem from './ManualItem'

const t = buildTranslate('thanx_campaigns.report.v3.summary')
const redeemTypesWithCosts = [
  'manual/item',
  'automatic/amount',
  'automatic/percent',
  'manual/amount',
  'manual/percent',
  'experience/goldenticket',
]

type PropsT = {
  campaignId: number
  redeemTypes: (string | undefined)[]
}

const Cogs: React.FC<PropsT> = props => {
  const { campaignId, redeemTypes } = props

  const isEDREnabled = useFlag('DATA-633', false)
  if (isEDREnabled) {
    if (!isEmpty(intersection(redeemTypesWithCosts, redeemTypes))) {
      return <ManualItem campaignId={campaignId} />
    }
  } else {
    if (redeemTypes.includes('manual/item')) {
      return <ManualItem campaignId={campaignId} />
    }
  }

  return <NotAvailable title={t('revenue.cogs')} />
}

export default Cogs
