(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("formsy-react"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "formsy-react"], factory);
	else if(typeof exports === 'object')
		exports["formsy-react-components"] = factory(require("react"), require("formsy-react"));
	else
		root["formsy-react-components"] = factory(root["React"], root["formsy-react"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 