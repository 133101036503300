import { Switch } from '@thanx/uikit/switch'
import PopoverInfo from 'components/PopoverInfo'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { getDiscountedProductsLabel } from 'scenes/RedeemManager/helpers'
import { RedeemTypeItem } from 'scenes/RedeemManager/types'
import ProductSelect from './ProductSelect'

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.online_redemption_card'
)

type Props = {
  discountedProductIds: string[]
  requiredProductIds: string[]
  typeItem: RedeemTypeItem | null
}

const NotPurchase: React.FC<Props> = ({
  discountedProductIds,
  requiredProductIds,
  typeItem,
}) => {
  const subtype = typeItem?.subtype
  const displayDiscountedProducts =
    subtype === 'item' ||
    subtype === 'bogo/item' ||
    (subtype === null && !isEmpty(discountedProductIds))
  const displayRequiredProducts =
    subtype === 'bogo/item' ||
    subtype === 'bogo/purchase' ||
    (subtype === null && !isEmpty(requiredProductIds))
  const isFreeItem = typeItem?.types.includes('manual/item')

  return (
    <>
      <Switch condition={displayRequiredProducts}>
        <ProductSelect
          className="mb-m"
          name="onlineRequiredProductIds"
          label={t('required_label')}
          fallbackLabel={t('required_label_fallback')}
          value={requiredProductIds}
          validations={{ isRequired: true }}
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
          }}
        />
      </Switch>
      <Switch condition={displayDiscountedProducts}>
        <ProductSelect
          label={getDiscountedProductsLabel(isFreeItem, subtype)}
          fallbackLabel={t(
            isFreeItem
              ? 'discounted_label_free_item_fallback'
              : 'discounted_label_fallback'
          )}
          name="onlineDiscountedProductIds"
          value={discountedProductIds}
          validations={{ isRequired: true }}
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
          }}
        />
        <p className="mt-xs body-text-4 grey-70">
          {isFreeItem
            ? t('will_apply_lowest_free_item')
            : t('will_apply_lowest')}
        </p>
        <p className="mt-xs body-text-4 grey-70">
          {t('including_addons')}
          <PopoverInfo
            className="ml-xxs"
            text={
              <>
                <p className="small-caps-3 mt-l mb-s">{t('example')}</p>
                <p>{t('example_description')}</p>
              </>
            }
          />
        </p>
      </Switch>
    </>
  )
}

export default NotPurchase
