import { Fields } from 'models/PointsExperienceMetric'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

export const GET_POINTS_EXPERIENCE_METRIC_BY_TYPE =
  'points_experience_metrics/GET_BY_TYPE'
export const GET_POINTS_EXPERIENCE_METRIC_BY_TYPE_SUCCESS =
  'points_experience_metrics/GET_BY_TYPE_SUCCESS'
export const GET_POINTS_EXPERIENCE_METRIC_BY_TYPE_FAIL =
  'points_experience_metrics/GET_BY_TYPE_FAIL'
export type GetPointsExperienceMetricsByType = AxiosAction<
  typeof GET_POINTS_EXPERIENCE_METRIC_BY_TYPE,
  { metric: Fields }
>

export type GetAction = AxiosAction<
  typeof GET_POINTS_EXPERIENCE_METRIC_BY_TYPE
> & { isAllTime: boolean }
type GetResolvedAction =
  | FailureAction<typeof GET_POINTS_EXPERIENCE_METRIC_BY_TYPE_FAIL>
  | SuccessAction<
      typeof GET_POINTS_EXPERIENCE_METRIC_BY_TYPE_SUCCESS,
      { metric: Fields },
      GetAction
    >

export function getPointsExperienceMetricByType(
  type: string,
  pointsExperienceId: number,
  isAllTime: boolean,
  params: {} = {}
): GetAction {
  return {
    type: GET_POINTS_EXPERIENCE_METRIC_BY_TYPE,
    payload: {
      client: 'default',
      request: {
        url: `points_experiences/${pointsExperienceId}/metrics/${type}`,
        method: 'get',
        params,
      },
    },
    isAllTime,
  }
}

export type ResolvedAction = GetResolvedAction
