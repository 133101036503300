import { Notification } from '@thanx/uikit/notification'
import orderingConfigImage from 'assets/images/cms/ordering-configuration.png'
import hollowInfo from 'assets/images/hollowInfo.svg'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import CmsPage from 'scenes/Cms/components/CmsPage'
import NotEnabledPage from './NotEnabledPage'

const t = buildTranslate('cms.settings.ordering_configuration')

const OrderingConfiguration: React.FC = () => {
  const merchant = useCurrentMerchant()

  if (!merchant?.ordering_enabled) {
    return <NotEnabledPage />
  }

  return (
    <CmsPage title={t('title')} description={t('description')}>
      <img
        className="max-width-100 mb-l"
        src={orderingConfigImage}
        alt={t('image_alt')}
      />
      <ul className="mb-m grey-70 font-size-14">
        <li>{t('list.menu_item')}</li>
        <li>{t('list.category_order')}</li>
        <li>{t('list.ordering_categories')}</li>
        <li>{t('list.pricing')}</li>
        <li>{t('list.handoff')}</li>
        <li>{t('list.location_availability')}</li>
        <li>{t('list.location_hours')}</li>
      </ul>
      <Notification kind="info" size="full">
        <div className="d-flex m-s">
          <img className="mr-xs" src={hollowInfo} alt={t('info')} />
          <span className="body-text-4">{t('contact_your_provider')}</span>
        </div>
      </Notification>
    </CmsPage>
  )
}

export default OrderingConfiguration
