import { RESET_DB } from 'actions/orm'
import { GET_VALIDATION_SUCCESS, ResolvedAction } from 'actions/validations'
import { Model, ModelType } from 'redux-orm'

export enum ValidationTypes {
  AppHeader = 'image/app/landing_page/header',
  AppLinkPressed = 'image/app/landing_page/link_pressed',
  AppLinkUnpressed = 'image/app/landing_page/link_unpressed',
  OrderingBanner = 'image/merchant/ordering/banner',
  RedeemAdvertising = 'image/program/redeem/experience/advertising',
  RedeemDetail = 'image/program/redeem/experience/detail',
  RedeemIndex = 'image/program/redeem/index',
  PointsEarn = 'image/points/experience/earn',
  PointsPrimaryIcon = 'image/points/experience/currency/primary_icon',
  PointsSecondaryIcon = 'image/points/experience/currency/secondary_icon',
  OnboardingSlide = 'image/app/onboarding/slide',
  AppHomePage = 'image/app/landing_page/theme/featured_content',
}

export interface ValidationFields {
  min_width: number | null
  max_width: number | null
  min_height: number | null
  max_height: number | null
  extensions: string[]
  aspect_ratio: string | null
}

export interface Fields extends ValidationFields {
  id: ValidationTypes
}

// @ts-ignore
export default class Validation extends Model<typeof Validation, Fields> {
  static modelName: string = 'Validation'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Validation: ModelType<Validation>
  ) {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case GET_VALIDATION_SUCCESS:
        Validation.upsert({
          // @ts-ignore
          id: action.payload.config.params.type,
          ...action.payload.data.validations,
        })
        break
      case RESET_DB:
        Validation.all().delete()
        break
      default:
        break
    }
  }
}
