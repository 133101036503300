import { FormsyRadioGroup, Radio } from '@thanx/uikit/radio'
import {
  getPreferencesModalSetting,
  GET_PREFERENCES_MODAL_CONFIG_SUCCESS,
  updatePreferencesModalSetting,
  UPDATE_PREFERENCES_MODAL_CONFIG_SUCCESS,
} from 'actions/config'
import { alert } from 'actions/flash'
import ConfirmModal from 'components/ConfirmModal'
import Section from 'components/Section'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import {
  PreferencesModalConfigApiFields,
  PreferencesModalSetting as SelectionType,
} from 'models/Config'
import React, { useCallback, useEffect, useState } from 'react'
import CmsPage from 'scenes/Cms/components/CmsPage'
import PublishBanner from 'scenes/Cms/components/PublishBanner'
import { Tabs } from '..'
import ExistingOrderTip from './ExisitingOrderTip'
import Sidebar from './Sidebar'

const t = buildTranslate('cms.content.preferences_modal_setting')

type FormData = {
  webSelection: SelectionType | undefined
}

const PreferencesModalSetting: React.FC = () => {
  const dispatch = useDispatch()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [formData, setFormData] = useState<FormData>({
    webSelection: undefined,
  })

  const displayAlert = useCallback(
    (key: 'danger' | 'success', messageKey: string) => {
      dispatch(
        alert({
          key,
          message: t(messageKey),
          timeout: 5,
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const response = await dispatch(getPreferencesModalSetting())
      if (response.type === GET_PREFERENCES_MODAL_CONFIG_SUCCESS) {
        const preferences_modal: PreferencesModalConfigApiFields =
          response.payload.data.preferences_modal
        setFormData({
          webSelection: preferences_modal.setting,
        })
      } else {
        // Only display error if user is on the preferences modal setting tab
        if (window.location.hash === `#${Tabs.PreferencesModalSetting}`) {
          displayAlert('danger', 'fetch_error')
        }
      }
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch, displayAlert])

  const handlePublish = async () => {
    setIsSubmitting(true)

    if (!formData.webSelection) {
      displayAlert('danger', 'form_error')
    } else {
      const response = await dispatch(
        updatePreferencesModalSetting(formData.webSelection)
      )

      if (response.type === UPDATE_PREFERENCES_MODAL_CONFIG_SUCCESS) {
        displayAlert('success', 'submit_success')
      } else {
        displayAlert('danger', 'submit_error')
      }
    }

    setIsModalOpen(false)
    setIsSubmitting(false)
  }

  return (
    <Formsy
      onValidSubmit={() => {
        setIsModalOpen(true)
      }}
      onChange={(newFormData: FormData) => {
        setFormData(newFormData)
      }}
      onInvalidSubmit={() =>
        dispatch(
          alert({
            key: 'danger',
            message: t('form_error'),
            timeout: 5,
          })
        )
      }
    >
      <CmsPage
        title={t('title')}
        description={t('description')}
        sidebar={<Sidebar isLoading={isSubmitting} disabled={false} />}
        isLoading={isLoading}
      >
        <ExistingOrderTip />

        <Section className="p-m mb-m">
          <h4 className="mb-s">{t('app_selection.title')}</h4>
          <FormsyRadioGroup name="appSelection" value={SelectionType.NEVER}>
            <Radio value={SelectionType.NEVER}>
              {t('app_selection.never')}
            </Radio>
            <p className="body-text-4 grey-70 ml-l mt-xxs">
              {t('app_selection.explanation')}
            </p>
          </FormsyRadioGroup>
        </Section>

        <Section className="p-m mb-m">
          <h4 className="mb-s">{t('web_selection.title')}</h4>
          <FormsyRadioGroup name="webSelection" value={formData.webSelection}>
            <Radio value={SelectionType.NEVER}>
              {t('web_selection.never')}
            </Radio>
            {formData.webSelection === SelectionType.NEVER && (
              <p className="body-text-4 grey-70 ml-l mt-xxs">
                {t('web_selection.never_explanation')}
              </p>
            )}
            <Radio value={SelectionType.ALWAYS} className="mt-s">
              {t('web_selection.always')}
            </Radio>
            {formData.webSelection === SelectionType.ALWAYS && (
              <p className="body-text-4 grey-70 ml-l mt-xxs">
                {t('web_selection.always_explanation')}
              </p>
            )}
            <Radio value={SelectionType.FIRST_ORDER} className="mt-s">
              {t('web_selection.first_order')}{' '}
              <span className="grey-50">({t('recommended')})</span>
            </Radio>
            {formData.webSelection === SelectionType.FIRST_ORDER && (
              <p className="body-text-4 grey-70 ml-l mt-xxs">
                {t('web_selection.first_order_explanation')}
              </p>
            )}
          </FormsyRadioGroup>
        </Section>

        <PublishBanner
          buttonText={t('publish')}
          isLoading={isSubmitting}
          disabled={false}
        />
      </CmsPage>
      <ConfirmModal
        title={t('modal.publish_changes')}
        description={t('modal.you_are_publishing')}
        confirmText={t('modal.publish')}
        isOpen={isModalOpen}
        isLoading={isSubmitting}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => handlePublish()}
      />
    </Formsy>
  )
}

export default PreferencesModalSetting
