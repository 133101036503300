const emails = {
  names: {
    short_name: 'Emails',
  },
  list: {
    text: 'Send out an email to all your customers to let them know about updates, announce a new product, tell them about an event or just say “hi”.',
  },
  footer: `
    You are receiving this email because you’re part of the %{merchantName} mailing
    list.
    <div>
      <u style="display: inline-block; margin-right: 8px">unsubscribe from this campaign</u>
      <u style="display: inline-block; margin-left: 8px">update subscription preferences</u>
    </div>
    Thanx, Inc. &middot 1700 Van Ness Ave. #1066 &middot San Francisco, CA 94109 &middot USA
  `,
  form: {
    errors: {
      title_message:
        'You need to update the following things before you can save:',
      link_color:
        'Button background color must be a valid color (like #CCCCCC)',
      button_text_color:
        'Button text color must be a valid color (like #FFFFFF)',
      subject: 'Add a subject line',
      preview_text: 'Add preview text',
      heading: 'Create a heading for your email',
      body: 'Create content for your email',
      button_text: 'CTA text can’t be blank',
      button_url: 'CTA URL must be valid (like https://www.thanx.com)',
    },
    creation: {
      title: 'Tell us about your campaign',
      name: {
        label: 'Campaign Name',
        placeholder: 'New products - summer edition',
      },
      objective: {
        label: 'Objective',
        placeholder:
          'Increase awareness about our new summer range and encourage customers to try our new products.',
      },
      blast: {
        button_url: 'https://yourhomepage.com',
        button_text: 'Click me!',
      },
      segments: {
        title: 'Customers',
        subtitle: 'Which customers do you want to send this to?',
        choices: {
          all: {
            title: 'Send to everyone',
            subtitle: 'Communicate with all customers in your loyalty program.',
            info: {
              title: 'Thanx tip',
              subtitle: 'Did you know that segmented campaigns drive up to a',
              stat: '760% increase in revenue',
              tooltip:
                'According to the Data & Marketing Association, marketers have found a 760% increase in email revenue from segmented campaigns.',
            },
          },
          segments: {
            title: 'Target a segment',
            subtitle:
              'Target a specific group of customers based on their purchase behavior.',
          },
        },
        form: {
          placeholder: 'Choose a segment',
        },
        advanced: {
          show: 'Show advanced options',
          hide: 'Hide advanced options',
          control_group: {
            title: 'Use a control group',
            subtitle: 'Set aside 10% of recipients as a control group.',
            disabled: {
              title: 'Your audience is too small for a control group',
              subtitle:
                'When your aduience is too small then the control group won’t be big enough to accurately say whether the campaign actually changed customer behavior.',
            },
          },
        },
      },
    },
    labels: {
      link_color: 'Button Background Color',
      button_text_color: 'Button Text Color',
      subject: 'Subject Line',
      preview: 'Preview Text',
      heading: 'Email Heading',
      content: 'Email Body',
      submit: 'Save',
      cancel: 'Cancel',
      edit: 'edit',
      header_image: 'Brand Image',
      hero_image: 'Hero Image',
      button_url: 'Button URL',
      button_text: 'Button Text',
    },
    help_text: {
      hero_image:
        'Upload a jpeg, gif or png to display at the top of your email.',
      header_image: 'Add a brand logo or image.',
      subject:
        'Create an engaging subject line to get people to open your email.',
      preview:
        'This is the snippet of text that appears after the email subject line.',
    },
    submission_error: 'There was a problem saving your changes.',
    submission_success: 'Changes saved.',
    sections: {
      branding: 'Branding',
      content: 'Content',
    },
    custom_fields: {
      insert: 'Insert a custom field',
      first_name: 'Customer First Name',
      last_name: 'Customer Last Name',
      intro_reward: 'Intro Reward Description',
      merchant_name: 'Merchant Name',
    },
  },
  submission: {
    invalid_blast: 'You still need to add copy to your email.',
    review_button: 'Review & Send',
    save_button: 'Finish Later',
    disable_button: 'Pause Campaign',
    confirm: {
      buttons: {
        confirm: 'Start sending',
        cancel: 'Cancel',
      },
      one_time: {
        title: 'Ready to send?',
        recipients: {
          title: 'Customers',
          text: 'You’re sending this to ‘%{segment}’ (%{count} customers)',
        },
        campaign: {
          title: 'Email',
        },
        schedule: {
          title: 'Schedule',
          immediate: 'We’ll queue your email to go out in the next hour.',
          scheduled:
            'Your email will be sent on %{scheduledAtDate} at %{scheduledAtHour} (%{scheduledAtZone}).',
        },
        help_message:
          'Review your campaign to make sure everything is correct before you send. If you’re still unsure about anything, then just <a href="mailto: merchant.support@thanx.com">ask our team for help</a>.',
      },
      automated: {
        title: 'Ready to send? Review your campaign:',
        recipients: {
          title: 'Recipients',
          non_pending:
            'Send to all subscribers who are not already members of your program (%{subscriberCount})',
          pending:
            'We are still processing your list(s). Don’t worry, we’ll still send this campaign to those users and as new customers subscribe to your email list.',
        },
        campaign: {
          title: 'Campaign',
        },
        help_message:
          'Need help? If you’re still unsure, simply <a href="mailto: merchant.support@thanx.com">ask your success manager</a> to review your campaign for you.',
      },
    },
    success: {
      title: 'Nice job!',
      automated: {
        subtitle:
          'Sit back and relax while we queue your emails. We’ll start sending them shortly.',
        button: 'View Report',
      },
      one_time: {
        immediate_subtitle:
          'Your campaign is in our queue and will start going out shortly.',
        scheduled_subtitle: 'Your campaign is saved. %{scheduledAt}',
        report_button: 'View Campaign Reports',
        ideas_button: 'View Campaign Ideas',
      },
      text: 'Done',
      close: 'Close',
    },
  },
  types: {
    automated: {
      name: 'Automated Emails',
      subscribers_to_loyal: {
        title: 'Signup Email',
        subtitle:
          'Convert your email list into your loyalty program with a series of automated emails inviting your subscribers to join your loyalty program.',
        button: {
          create: 'Create',
          enabled: 'See Report',
          disabled: 'Configure',
        },
        emails: {
          immediate: {
            subtitle:
              '<strong>Trigger:</strong> Subscriber is added to your email list',
          },
          email_2: {
            subtitle: '<strong>Trigger:</strong> Wait 7 days',
            name: 'Email #2',
          },
          email_3: {
            subtitle: '<strong>Trigger:</strong> Wait 14 days',
            name: 'Email #3',
          },
        },
        form: {
          color_picker: '',
        },
      },
    },
    sections: {
      campaign: 'Campaign',
      recipients: 'Recipients',
      scheduling: 'Schedule',
    },
  },
  scheduling: {
    choices: {
      send_now: {
        title: 'Send Now',
      },
      send_later: {
        title: 'Send Later',
        subtitle: 'When do you want to send this campaign?',
        timezone:
          'This campaign will be sent at %{scheduledAt} time in your local timezone (%{zone}). We’ll also recalculate your segment before we send the campaign to ensure it’s being sent to the right people.',
      },
    },
    errors: {
      past: 'You can’t schedule a campaign in the past.',
    },
  },
  recipients: {
    status: {
      title: {
        csv_uploaded: 'you have uploaded %{csvCount} CSVs',
        enabled_list: 'you have connected %{listName}',
        connect_or_upload:
          'connect to an email provider or upload a CSV to get started',
      },
      subtitle: {
        enabled: '%{unconvertedCount} subscribers.',
        in_progress_list:
          'We are still processing your subscribers and this can take a few minutes. In the meantime, you can edit your emails and send your campaign.',
        no_unconverted: 'Add subscribers to expand your program.',
      },
      button: 'Edit Subscribers',
    },
    unsubscribe_notice:
      '<strong>Unsubscribes:</strong> Customers can choose to stop receiving these emails at any time. This will not unsubscribe them from your mailing list.',
    add_recipients: 'Add subscribers',
    setup: {
      title: 'Add your subscribers',
      back_button: '<i class="fa fa-chevron-left"></i> Go Back',
      choices: {
        upload: {
          title: 'Import subscribers from a csv',
          button_text: 'upload',
          placeholder: 'Click to upload a CSV',
          error:
            'This CSV appears to be invalid. Please make sure that your CSV includes email addresses.',
          successful_upload: {
            title: 'Upload Successful',
            content:
              'Great job, you uploaded approximately %{subscriberCount} emails. You’re all set to start sending email campaigns to your subscribers.',
            button_text: 'Continue',
          },
        },
      },
    },
    data_mapper: {
      title: 'Map your data',
      file_name: 'Preview of %{fileName}',
      default_column_name: 'Column %{columnNumber}',
      cancel_button: 'Cancel',
      submit_button: 'Finish importing',
      subscription_fields: {
        title: 'Choose which columns belong to which custom field',
        email_field: 'Email Address',
        first_name_field: 'First Name',
        last_name_field: 'Last Name',
        duplicate_warning:
          'You can’t assign multiple columns to the same value.',
      },
      unavailable: 'I don’t have this data',
      submission: {
        config_failure: 'We are having trouble communicating with our server.',
        list_creation_failure:
          'We are having trouble updating your subscriber list.',
        s3_upload_failure: 'We are having trouble uploading your file.',
      },
    },
  },
  subscribers: {
    setup: {
      settings: {
        submit_error_message:
          'We had trouble updating your email lists. Please try again.',
        load_lists_error_message:
          'We had trouble loading your email lists. Please try again.',
      },
      success: {
        title: 'Import Successful',
        description:
          'Great job. You’re all set to start sending email campaigns to your subscribers.',
        button: 'Continue',
      },
    },
    export: {
      choose_provider: {
        title: 'Export subscribers',
        description:
          'Sync with your email service provider to automatically add any new subscribers from Thanx to and email list',
      },
      choose_list: {
        title: 'Choose a list',
        description: 'Select which list you want to add Thanx subscribers to.',
        new_list: 'Create a new list',
        existing_list: 'Choose an existing list',
        submit_button: 'Export Subscribers',
        submit_button_submitting: 'Submitting...',
      },
      success: {
        title: 'Export Successful',
        description_fallback:
          "You’ve synced Thanx with your email service provider. Every time a member opts in to receive emails, we'll automatically sync them to your sign up list.",
        description:
          'You’ve synced Thanx with %{providerName}. Every time a member opts in to receive emails, we\'ll automatically sync them to your "%{signupListName}" list.',
        button: 'Continue',
      },
    },
  },
  oauth: {
    authorize_url: {
      error_message:
        'We had trouble connecting to your email provider. Please try again.',
    },
    connect: {
      button_text: 'Continue',
      error_message:
        'We had trouble connecting to your email provider. Please try again.',
    },
  },
}

export default emails
