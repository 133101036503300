import { Button } from '@thanx/uikit/button'
import Counter from 'components/Counter'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React from 'react'
import ArrowComponent from 'scenes/Points/Landing/Activity/components/ArrowComponent'
import { numberFormatter } from 'utilities/formatters'

type Props = {
  totalOutstandingPoints: number
  changeInOutstandingPoints: number
  isErrored: boolean
  isLoading: boolean
  onToggleTable: VoidFunction
  showTable: boolean
}

const t = buildTranslate('points.activity.outstanding_points.counters')

const Counters: React.FC<Props> = ({
  totalOutstandingPoints,
  changeInOutstandingPoints,
  isErrored,
  isLoading,
  onToggleTable,
  showTable,
}) => {
  const counterValue = (value: number | undefined): string => {
    return numberFormatter(Math.abs(Math.round(value ?? 0)))
  }

  return (
    <div className="d-flex">
      <Counter
        className="mr-xxl"
        primaryValue={counterValue(totalOutstandingPoints)}
        name={t('total_outstanding_points')}
        isErrored={isErrored}
        isLoading={isLoading}
        sections={[{ content: t('total_outstanding_popover') }]}
      />
      <div>
        <Counter
          className="mr-xxl"
          primaryValue={counterValue(changeInOutstandingPoints)}
          name={t('change_outstanding_points')}
          isErrored={isErrored}
          isLoading={isLoading}
          sections={[
            { content: t('change_in_outstanding_popover_1') },
            { content: t('change_in_outstanding_popover_2') },
          ]}
          primaryIcon={
            <ArrowComponent
              color={palette.grey90}
              className="pr-xxs"
              pointsValue={changeInOutstandingPoints}
            />
          }
        />
        <div>
          <Button
            kind={'minimal'}
            size="small"
            className="border-0 pt-0 font-size-16 font-weight-normal pl-0"
            onClick={onToggleTable}
          >
            {showTable ? t('hide_details') : t('view_details')}
            <i
              className={`fa ${
                showTable ? 'fa-caret-up' : 'fa-caret-down'
              } btn-link font-size-14 padding-left-tiny`}
            />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Counters
