import { Text } from '@thanx/uikit/text'
import {
  CreateMerchantUserPayload,
  merchantUsersApi,
  Role,
} from 'api/merchantUsers'
import Formsy from 'formsy-react'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { useStyletron } from 'styletron-react'
import { useDisplayMessage } from '../../hooks/useDisplayMessage'
import { buildTranslate } from '../../locales'
import EmailCard from './EmailCard'
import FormLayout from './FormLayout'
import MerchantUserForm from './MerchantUserForm'

const t = buildTranslate('merchant_users')

const NewMerchantUser = () => {
  const dispatch = useDispatch()

  const [createMerchantUser, { reset }] =
    merchantUsersApi.useCreateMerchantUserMutation({
      fixedCacheKey: 'create',
    })

  const displayMessage = useDisplayMessage(reset)

  const [merchantUser, setMerchantUser] = useState<CreateMerchantUserPayload>({
    email: '',
    role: 'admin',
    location_ids: [],
  })

  const [css] = useStyletron()

  return (
    <FormLayout
      onFinish={async () => {
        try {
          await createMerchantUser(merchantUser).unwrap()
          dispatch(push('/merchant_users'))
        } catch (err) {
          displayMessage(t('new.error_generic'), 'danger')
        }
      }}
      canProceed={Boolean(merchantUser.email)}
    >
      <Formsy role="form">
        <EmailCard
          value={merchantUser.email}
          onChange={value => {
            setMerchantUser(prev => ({ ...prev, email: value }))
          }}
        />
        <Text.Header3
          className={css({
            marginTop: '40px',
            marginBottom: '20px',
          })}
        >
          {t('form.access.header')}
        </Text.Header3>
        <MerchantUserForm
          merchantUser={merchantUser}
          onChange={nextMerchantUser => {
            setMerchantUser(prev => ({
              ...prev,
              location_ids: nextMerchantUser.location_ids,
              role: nextMerchantUser.role as Role,
            }))
          }}
        />
      </Formsy>
    </FormLayout>
  )
}

export default NewMerchantUser
