import { StyleableButton } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import AddImageButton from './AddImageButton'
import UploadIcon from './UploadIcon'
import UploadProgress from './UploadProgress'

const t = buildTranslate('cms.components.image_picker')

type Props = {
  imageWidth?: string
  thumbnailClassName?: string
  uploaderClassName?: string
  onClick: () => void
  src?: string
  fileName?: string
  uploadPercentage: number | null
  processing: boolean
}

const ImageButton: React.FC<Props> = props => {
  const {
    imageWidth,
    thumbnailClassName = '',
    uploaderClassName = '',
    onClick,
    src,
    fileName = '',
    uploadPercentage,
    processing,
  } = props
  const [showModal, setShowModal] = useState(false)
  const [css] = useStyletron()

  if (uploadPercentage !== null) {
    return (
      <UploadProgress
        value={uploadPercentage}
        processing={processing}
        timeout={1000}
      />
    )
  }

  return (
    <Switch
      condition={!isEmpty(src)}
      fallback={<AddImageButton onClick={onClick} />}
    >
      <div
        className={`d-flex flex-align-center border-1 grey-50-border ${uploaderClassName}`}
      >
        <div className=" w-100 d-flex flex-row justify-content-between align-items-center">
          <img
            className={`${thumbnailClassName} ${css({
              maxWidth: '200px',
              objectFit: 'contain',
            })}`}
            src={src}
            alt={fileName}
          />
          <div className="d-flex flex-column align-self-center p-m">
            <p className="body-text-4 grey-70 text-break">{fileName}</p>
            <div className="d-flex justify-content-end">
              <StyleableButton
                onClick={() => setShowModal(true)}
                className="d-inline-flex justify-content-center mr-s belize-hole-50"
              >
                <i className="fa fa-eye mr-xxs" />
                <p className="body-text-4 belize-hole-50">{t('preview')}</p>
              </StyleableButton>
              <StyleableButton
                onClick={onClick}
                className="d-inline-flex justify-content-center"
              >
                <UploadIcon
                  color="#0073BF"
                  className={`mr-xxs ${css({
                    width: '14px',
                    height: '19px',
                  })}`}
                />
                <p className="body-text-4 belize-hole-50">{t('change')}</p>
              </StyleableButton>
            </div>
          </div>
        </div>
      </div>
      <Modal
        overrides={{
          Dialog: {
            style: {
              width: imageWidth,
            },
          },
        }}
        zIndex={1000}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <img alt="" className="p-m pt-xxxl w-100" src={src} />
      </Modal>
    </Switch>
  )
}

export default ImageButton
