import { updateCampaign } from 'actions/campaigns'
import ColorPicker from 'components/Form/ColorPicker'
import Input from 'components/Form/Input'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import ToggleContainer from 'scenes/CampaignCenter/Builder/components/ToggleContainer'
import MessagingForm from 'scenes/CampaignCenter/Builder/Steps/Shared/Messaging'
import { selectCampaignTypeByType } from 'selectors/campaignType'

const t = buildTranslate('thanx_campaigns.builder.forms.email.button')

type Props = {
  campaign: Campaign
  canProceed: boolean
}

const Messaging: React.FC<Props> = ({ campaign, canProceed }) => {
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )

  const dispatch = useDispatch()

  const campaignPlaceholders = campaignType?.placeholders
  useEffect(() => {
    const enableEmail = () => {
      const updatedCampaign = { ...campaign, notify_email: true }
      dispatch(updateCampaign(campaign.id, { campaign: updatedCampaign }))
    }

    if (!campaign.notify_email) enableEmail()
  }, [campaign, dispatch])
  function customButtonToggleGroup() {
    return (
      <ToggleContainer title={t('title')} openOnStart={true} disabled={true}>
        <Input
          className="margin-bottom-medium"
          label={t('text')}
          value={campaign.notify_email_button_text}
          name="notify_email_button_text"
          placeholder={campaignPlaceholders?.notify_email_button_text}
          validations="isRequired"
          validationErrors={{ isRequired: I18n.t('validations.is_required') }}
        />
        <Row className="margin-bottom-medium">
          <Col xs={6}>
            <ColorPicker
              name="notify_email_link_color"
              label={t('bkg_color')}
              value={campaign.notify_email_link_color || '#818a91'}
              validations={{
                isRequired: true,
                hex: true,
              }}
              validationErrors={{
                isRequired: I18n.t('validations.is_required'),
                hex: I18n.t('validations.hex.message'),
              }}
            />
          </Col>
          <Col xs={6}>
            <ColorPicker
              name="notify_email_button_text_color"
              label={t('text_color')}
              value={campaign.notify_email_button_text_color || palette.white}
              validations={{
                isRequired: true,
                hex: true,
              }}
              validationErrors={{
                isRequired: I18n.t('validations.is_required'),
                hex: I18n.t('validations.hex.message'),
              }}
            />
          </Col>
        </Row>
        <Row className="padding-large">
          <Col xs={1}>
            <i
              className="fa fa-link margin-top-small"
              style={{ fontSize: '1.5em' }}
            />
          </Col>
          <Col xs={10} className="padding-left-small">
            <p>{t('reputation_message')}</p>
          </Col>
        </Row>
      </ToggleContainer>
    )
  }

  return (
    <div className="container">
      <MessagingForm
        campaign={campaign}
        canProceed={canProceed}
        disableButton={true}
        disableRedeem={true}
        customButtonToggleGroup={customButtonToggleGroup()}
      />
    </div>
  )
}

export default Messaging
