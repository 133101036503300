import { Button, Kind } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { FormsySelect } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { getPointsExperiences } from 'actions/pointsExperiences'
import {
  createPointsProduct,
  CREATE_SUCCESS,
  getPointsProduct,
} from 'actions/pointsProducts'
import { getRedeemTemplates } from 'actions/redeemTemplates'
import noIcon from 'assets/images/points/img_noIcon.svg'
import Help from 'components/Help'
import Modal from 'components/ModalContainer'
import EditNavbar from 'components/Navbar/EditNavbar'
import DeleteModal from 'components/PointsProduct/DeleteModal'
import RichReward from 'components/RewardPreviewCard/RichReward'
import Formsy from 'formsy-react'
import { buildTranslate } from 'locales'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { formatCurrency } from 'scenes/RedeemManager/helpers'
import { selectExperience } from 'selectors/pointsExperience'
import { selectRedeemTemplatesByState } from 'selectors/redeemTemplate'
import { history } from 'store'
import { EditContext } from './EditContext'
import Restrictions from './Restrictions'

const t = buildTranslate('points.create_edit')
const marketplaceT = buildTranslate('points.marketplace')

const Content: React.FC = () => {
  const [css] = useStyletron()
  const [isLoading, setIsLoading] = useState(false)
  const [showPublish, setShowPublish] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const redeemTemplates = useSelector(state =>
    selectRedeemTemplatesByState(state, 'published')
  )
  const activeExperience = useSelector(selectExperience)
  //@ts-ignore
  const routing = useSelector(state => state.routing)
  const pathname = routing?.location?.pathname || ''
  const {
    id,
    onPublish,
    pointsProduct,
    template,
    updateTemplate,
    redeemTemplate,
  } = useContext(EditContext)

  const options = redeemTemplates.map(rt => ({
    value: rt.id,
    label: rt.description,
  }))

  const publish =
    pointsProduct?.state === 'active' ? 'publish_changes' : 'publish'

  useEffect(() => {
    let isSubscribed = true
    async function fetchData() {
      setIsLoading(true)
      await Promise.all([
        dispatch(getRedeemTemplates()),
        dispatch(getPointsExperiences()),
      ])
      if (isSubscribed) {
        setIsLoading(false)
      }
    }
    fetchData()
    return () => {
      isSubscribed = false
    }
  }, [dispatch])

  const activeId = activeExperience?.id

  useEffect(() => {
    const createProduct = async () => {
      if (activeId) {
        const response = (await dispatch(createPointsProduct(activeId))) as any
        if (response.type === CREATE_SUCCESS) {
          const id = response.payload.data.points_product.id
          history.replace(`/points/${id}/edit`)
        }
      }
    }

    if (pathname === '/points/create') {
      createProduct()
    } else if (id && !pointsProduct) {
      dispatch(getPointsProduct(id))
    }
  }, [activeId, dispatch, id, pathname, pointsProduct])

  const costConversion =
    template && activeExperience
      ? template.cost / Number(activeExperience.conversion_rate)
      : 1

  const checkPublish = () => {
    if (template?.redeem_template_id && template?.cost) {
      setShowPublish(true)
    } else {
      setShowErrors(true)
    }
  }

  return (
    <div
      className={`h-100 ${css({
        marginTop: '30px',
      })}`}
    >
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <EditNavbar title={t('title')}>
        <div>
          {pointsProduct?.state === 'active' && (
            <Button
              kind={Kind.MINIMAL_SECONDARY}
              className="mr-s"
              onClick={() => setShowDeleteModal(true)}
            >
              <span className="white">{t('remove')}</span>
            </Button>
          )}
          <LinkContainer to="/points#marketplace">
            <Button kind={Kind.SECONDARY_REVERSE} className="mr-s">
              {t('exit')}
            </Button>
          </LinkContainer>
          <Button
            kind={Kind.PRIMARY_REVERSE}
            disabled={
              !template?.redeem_template_id ||
              (!template?.cost && template?.cost !== 0)
            }
            onClick={checkPublish}
          >
            {t(`${publish}.button`)}
          </Button>
        </div>
      </EditNavbar>
      <Container className="h-100">
        <Row className="h-100">
          <Col className="p-xl white-bkg" md={6}>
            <Formsy onChange={updateTemplate}>
              <Text.Header2 className="mb-xl mt-0">{t('reward')}</Text.Header2>
              <Text.BodyText4 bold className="mb-xs" tag="div">
                {t('can_redeem', {
                  currency: activeExperience?.currency_name_plural,
                })}
              </Text.BodyText4>
              <FormsySelect
                // @ts-ignore
                disabled={pointsProduct?.state === 'active'}
                options={options}
                name="redeem_template_id"
                value={template?.redeem_template_id ?? undefined}
                isLoading={isLoading}
                multi={false}
                searchable
                validations={{
                  isRequired: showErrors,
                }}
                validationErrors={{
                  isRequired: t('reward_error'),
                }}
              />
              {template?.redeem_template_id && (
                <Link
                  className="mt-xs font-size-14 d-inline-block"
                  to={`/rewards/${template?.redeem_template_id}/edit`}
                >
                  {t('edit_reward')}
                </Link>
              )}
              <FormsyInput
                // @ts-ignore
                type="number"
                label={
                  <Text.BodyText4 bold className="pb-xs mt-m" tag="div">
                    {t('how_many', {
                      currency: activeExperience?.currency_name_plural,
                    })}
                  </Text.BodyText4>
                }
                name="cost"
                startEnhancer={
                  <img
                    height="16px"
                    src={
                      activeExperience?.currency_primary_icon.default || noIcon
                    }
                    alt={activeExperience?.currency_name}
                  />
                }
                min={1}
                value={template?.cost ?? undefined}
                validations={{
                  isRequired: showErrors,
                  isInt: true,
                  greaterThan: -1,
                  lessThan: 1000001,
                }}
                validationErrors={{
                  isRequired: t('cost_required_error'),
                  isInt: t('value_error'),
                  greaterThan: t('value_error'),
                  lessThan: t('value_error'),
                }}
              />
              <Text.BodyText5 className="mt-xs" color="grey70" tag="div">
                {t('conversion', {
                  cost: formatCurrency(costConversion),
                  currency: activeExperience?.currency_name_plural,
                })}
              </Text.BodyText5>
              <Restrictions />
            </Formsy>
          </Col>
          <Col className="grey-10-bkg" md={6}>
            <div
              className={`d-flex justify-content-center ${css({
                marginTop: '134px',
              })}`}
            >
              <RichReward
                className={`text-left grey-05-bkg mb-m ${css({
                  boxShadow: '0px 1px 2px 0px rgba(54, 66, 78, 0.3)',
                })}`}
                description={redeemTemplate?.description || ''}
                width="345px"
                imageSrc={redeemTemplate?.images?.index_image?.small}
                showRedeemButton={false}
              />
            </div>
          </Col>
        </Row>
        <Help
          title={marketplaceT('help.title')}
          articles={[
            {
              title: marketplaceT('help.article.title'),
              url: marketplaceT('help.article.url'),
            },
          ]}
        />
      </Container>
      <Modal
        title={t(`${publish}.title`)}
        proceedText={t(`${publish}.show`)}
        isOpen={showPublish}
        onProceed={onPublish}
        onClose={() => setShowPublish(false)}
        subtitle={t(`${publish}.subtitle`)}
      />
      <DeleteModal
        pointsProduct={pointsProduct}
        setIsOpen={setShowDeleteModal}
        isOpen={showDeleteModal}
        onSuccess={() => dispatch(push('/points#marketplace'))}
      />
    </div>
  )
}

export default Content
