import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import { Fields as UserFeedback } from 'models/UserFeedback'
import React from 'react'
import { Button } from 'react-bootstrap'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { formatDate } from 'scenes/Users/View/helpers'
import { useUserTimeZone } from 'utilities/userTimeZone'
import StatusBadge from './StatusBadge'

const t = buildTranslate('users.view.activity.feedback')

type PropsT = {
  feedback: UserFeedback
  onOrderDetailsClick: (feedback: UserFeedback) => void
}

const Row: React.FC<PropsT> = ({ feedback, onOrderDetailsClick }: PropsT) => {
  const [css] = useStyletron()
  const timezone = useUserTimeZone()
  const createdAt = formatDate(timezone, feedback.created_at)

  function handleOrderDetailsClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    onOrderDetailsClick(feedback)
  }

  return (
    <tr>
      <BodyCellContainer isErrored={false} isLoading={false}>
        <Text.BodyText4 tag={'p'} className={'mb-0'} color="grey70">
          {createdAt?.day}
        </Text.BodyText4>
        <Text.BodyText5 color="grey70">{createdAt?.time}</Text.BodyText5>
      </BodyCellContainer>
      <BodyCellContainer isErrored={false} isLoading={false}>
        <StatusBadge status={feedback.status} score={feedback.score} />
      </BodyCellContainer>
      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className={`${css({
          wordWrap: 'break-word',
        })}`}
      >
        <Text.BodyText4 color="grey70" tag={'p'}>
          {feedback.comment}
        </Text.BodyText4>
      </BodyCellContainer>
      <BodyCell
        isLoading={false}
        isErrored={false}
        values={[{ value: feedback.location_name }]}
      />
      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className={'text-right'}
      >
        <Switch condition={!!feedback.purchase_id}>
          <Button
            onClick={handleOrderDetailsClick}
            className="d-flex align-items-center"
            bsStyle="link"
          >
            {t('order_details')}
          </Button>
        </Switch>
      </BodyCellContainer>
    </tr>
  )
}

export default Row
