import { Button } from '@thanx/uikit/button'
import { KIND, Notification } from 'baseui/notification'
import Modal from 'components/Modal'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { useStyletron } from 'styletron-react'

import { Switch } from '@thanx/uikit/switch'

type Props = {
  isOpen: boolean
  isSubmitting: boolean
  error: string | null
  image: string
  title: string
  headline?: string
  description: string
  submitText: string
  onClose: () => void
  onSubmit: () => void
}

const ActionModal: React.FC<Props> = ({
  isOpen,
  isSubmitting,
  image,
  error,
  title,
  headline,
  description,
  submitText,
  onClose,
  onSubmit,
}) => {
  const [css] = useStyletron()

  return (
    <Modal centered show={isOpen} onModalClose={onClose}>
      <div className="d-flex flex-column align-items-center p-xl text-center">
        <h3 className="mt-0 mb-xs extra-bold">{title}</h3>
        <Switch condition={!!headline}>
          <p className="mb-l grey-70">{headline}</p>
        </Switch>
        <img
          className={`${css({
            height: '250px',
            width: '250px',
          })} mb-l`}
          src={image}
          alt=""
        />
        <p className="mb-xl body-text-4 grey-70">{description}</p>
        <Switch condition={!!error}>
          <Notification kind={KIND.negative}>{error}</Notification>
        </Switch>
        <div className="d-flex w-100">
          <Button className="flex-1 mr-m" kind="secondary" onClick={onClose}>
            {I18n.t('redeem_manager.actions.cancel')}
          </Button>
          <Button
            className="flex-1"
            isLoading={isSubmitting}
            onClick={onSubmit}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ActionModal
