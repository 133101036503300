import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import FetchableMetricSection from 'components/FetchableMetricSection'
import InfoBox from 'components/InfoBox'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import {
  RetentionRateCohortMetric,
  RetentionRateCumulativeMetric,
  RetentionRateTimeSeriesMetric,
  ShortType,
} from 'models/RetentionRateMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import CohortChart from 'scenes/Reports/components/CohortChart'
import CohortStub from 'scenes/Reports/components/CohortStub'
import Table from 'scenes/Reports/components/Table'
import { selectRetentionRateMetricByType } from 'selectors/retentionRateMetric'
import { useIsMobile } from 'utilities/responsive'
import { getBenchmarkRate } from '../helpers'
import Chart from './Chart'
import Counters from './components/Counters'
import Row from './components/Row'
import {
  cohortSeriesFormatter,
  cohortTopAxisFormatter,
  filterRecentValues,
  getCohortSeriesData,
  getCohortYAxisOptions,
} from './helpers'

const t = buildTranslate('retention_rate')

const Content: React.FC = () => {
  const merchant = useCurrentMerchant()
  const showCohortChart = useFlag('MX4-RetentionRateReport-R2', false)

  const timeSeriesMetrics = useSelector(state =>
    selectRetentionRateMetricByType(state, merchant?.id, ShortType.TIME_SERIES)
  ) as RetentionRateTimeSeriesMetric
  const benchmark = getBenchmarkRate(merchant?.merchant_type, 'retention_rate')
  const cumulativeMetrics = useSelector(state =>
    selectRetentionRateMetricByType(state, merchant?.id, ShortType.CUMULATIVE)
  ) as RetentionRateCumulativeMetric

  const cohortMetrics = useSelector(state =>
    selectRetentionRateMetricByType(state, merchant?.id, ShortType.COHORTS)
  ) as RetentionRateCohortMetric

  const {
    isErrored: isCumulativeErrored,
    isLoading: isCumulativeLoading,
    previous,
    current,
    timezone: cumulativeTimezone,
  } = cumulativeMetrics ?? {}

  const {
    isErrored: isTimeSeriesErrored,
    isLoading: isTimeSeriesLoading,
    timezone: seriesTimezone,
    granularity,
    values,
  } = timeSeriesMetrics ?? {}

  const {
    isErrored: isCohortErrored,
    isLoading: isCohortLoading,
    timezone: cohortTimezone,
    granularity: cohortGranularity,
    values: cohortValues,
  } = cohortMetrics ?? {}

  const isMobile = useIsMobile()
  const timeSeriesValues = (values ?? []).filter(filterRecentValues)

  const metricTypes = [ShortType.CUMULATIVE, ShortType.TIME_SERIES]
  if (showCohortChart) {
    metricTypes.push(ShortType.COHORTS)
  }

  return (
    <FetchableMetricSection
      className={isMobile ? 'mx-m' : 'mb-l'}
      merchantId={merchant?.id}
      shouldFetch
      type={'retention_rate_metric'}
      metricTypes={metricTypes}
    >
      {!isCumulativeLoading &&
        current?.engaged_users_count === 0 &&
        current?.retention_rate === 0 && (
          <InfoBox
            type="info"
            content={t('notifications.zero_engaged_customers')}
            className="mt-xl mb-xl"
          />
        )}
      <Text.Header3 className="mt-xl" tag="div">
        {t('retention_rate')}
      </Text.Header3>
      <Text.BodyText3 className="mt-xs mb-m" tag="div">
        {t('description')}
      </Text.BodyText3>
      <Counters
        isErrored={isCumulativeErrored}
        isLoading={isCumulativeLoading}
        currentRateData={current}
        previousRateData={previous}
        benchmark={benchmark}
        timezone={cumulativeTimezone}
      />
      <Chart
        isErrored={!!isTimeSeriesErrored}
        isLoading={!!isTimeSeriesLoading}
        granularity={granularity}
        values={timeSeriesValues}
        timezone={seriesTimezone}
        benchmark={benchmark}
      />
      {merchant && (
        <div className="mt-l">
          <Table
            values={timeSeriesValues}
            isErrored={!!isTimeSeriesErrored}
            isLoading={!!isTimeSeriesLoading}
            headers={[
              'date_time',
              'engaged_users_count',
              'retention_rate',
              'churned_users_count',
            ]}
            headerClassNames={['w-50']}
            t={buildTranslate('retention_rate.table')}
            initialSortAttribute={'date_time'}
          >
            {Row}
          </Table>
        </div>
      )}
      <Switch
        condition={showCohortChart}
        fallback={
          <CohortStub
            title={t('cohort_chart.title')}
            subtitle={t('cohort_chart.sub_title')}
          />
        }
      >
        <CohortChart
          values={cohortValues}
          isErrored={!!isCohortErrored}
          isLoading={!!isCohortLoading}
          granularity={cohortGranularity}
          timezone={cohortTimezone}
          title={t('cohort_chart.title')}
          subtitle={t('cohort_chart.sub_title')}
          cohortSeriesFormatter={cohortSeriesFormatter}
          cohortTopAxisFormatter={cohortTopAxisFormatter}
          getCohortSeriesData={getCohortSeriesData}
          getCohortYAxisOptions={getCohortYAxisOptions}
        />
      </Switch>
    </FetchableMetricSection>
  )
}

export default Content
