import RewardImage from 'components/RewardPreviewCard/RewardImage'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import Header from 'scenes/Cms/components/Header'
import { selectApp } from 'selectors/app'
import Asset from '../Asset'
import ImageAsset from '../ImageAsset'

const t = buildTranslate('cms.brand.brand_assets')

const RewardIcon: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const { reward_icon_image_urls } = app

  return (
    <Section className="p-xl mt-l">
      <Header title={t('reward_icon.title')} />
      <Asset
        className="mt-s"
        description={t('reward_icon.description')}
        badges={[t('reward_icon.size'), t('reward_icon.png')]}
      >
        <ImageAsset
          image={
            <RewardImage
              height="70px"
              width="70px"
              rewardIcon={reward_icon_image_urls?.default}
            />
          }
        />
      </Asset>
    </Section>
  )
}

export default RewardIcon
