const segments = {
  landing: {
    all_segments: 'All segments',
    create_new_segment: 'Create a new segment',
    fetch_error: 'Failed to load segments. Please try again.',
    modal: {
      request_segment: {
        title: 'Request a new custom segment',
        subtitle:
          "Fill out the form to tell us which customers you want to target. We'll work with you to create a custom segment.",
      },
    },
    new_segment_campaign_modal: {
      errors: {
        no_segment: 'No segment selected',
        submit: 'Failed to create campaign',
      },
    },
    other: 'Other',
    page_title: 'Segments',
    table: {
      create_campaign: 'Create a campaign',
      name: 'Name',
    },
    title: 'Segments',
    search_placeholder: 'Search segments by name',
    total_loyalty_members: 'Total loyalty members',
    total_loyalty_members_popover: 'All the loyalty members in your database',
    total_users: 'Total customers in your database',
    total_users_popover: 'All the customers in your database',
    type_filter: {
      all: 'Pre-built and Custom segments',
      merchant: 'Custom segments only',
      global: 'Pre-built segments only',
    },
  },
}

export default segments
