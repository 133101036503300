import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Help from 'components/Help'
import PointsProduct from 'components/PointsProduct'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Row } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { selectExperience } from 'selectors/pointsExperience'
import { selectActivePointsProducts } from 'selectors/pointsProduct'
import { useAbility } from 'utilities/ability'
import { useIsMobile } from 'utilities/responsive'
import Empty from './Empty'

const t = buildTranslate('points.marketplace')

const Marketplace: React.FC = () => {
  const isMobile = useIsMobile()
  const pointsExperience = useSelector(selectExperience)
  const pointsProducts = useSelector(selectActivePointsProducts)
  const ability = useAbility()
  const canEdit = ability.can('manage', 'Points')

  const [css] = useStyletron()

  return (
    <TabPane state="marketplace">
      <Row className="pb-l">
        <Col md={7}>
          <Text.Header3 tag="div">{t('title')}</Text.Header3>
          <Text.BodyText3 color="grey70" tag="div">
            {t('subtitle', {
              currency: pointsExperience?.currency_name_plural,
            })}
          </Text.BodyText3>
        </Col>
        <Switch condition={canEdit}>
          <Col
            className={`d-flex align-items-center ${
              isMobile ? 'pt-m' : 'justify-content-end'
            }`}
          >
            <LinkContainer to="/points/create">
              <Button
                className={`px-l ${css({
                  width: '310px',
                })}`}
              >
                {t('add')}
              </Button>
            </LinkContainer>
          </Col>
        </Switch>
      </Row>
      <Switch condition={pointsProducts.length > 0} fallback={<Empty />}>
        {pointsProducts.map(pointsProduct => (
          <Row
            key={pointsProduct.id}
            className={`mb-m pr-0 mr-0 ml-0 ${css({ width: '100%' })}`}
          >
            <Col className="pr-0 pl-0">
              <PointsProduct
                pointsProduct={pointsProduct}
                isEditable={canEdit}
              />
            </Col>
          </Row>
        ))}
      </Switch>

      <Help
        title={t('help.title')}
        articles={[
          { title: t('help.article.title'), url: t('help.article.url') },
        ]}
      />
    </TabPane>
  )
}

export default Marketplace
