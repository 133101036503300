import { Text } from '@thanx/uikit/text'
import { alert } from 'actions/flash'
import { getUserProfile } from 'actions/users'
import { usersApi } from 'api/users'
import { getErrorMessage, isError } from 'api/utils'
import Input from 'components/Form/Input'
import Modal from 'components/ModalContainer'
import Form from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'

type Props = {
  visible: boolean
  onClose: VoidFunction
  profile: UserProfile
}

const t = buildTranslate('users.view.actions.remove_points_modal')
const validationT = buildTranslate('validations')

const ClawbackPointsModal = ({ visible, onClose, profile }: Props) => {
  const dispatch = useDispatch()
  const [amount, setAmount] = useState(0)
  const [formIsValid, setFormIsValid] = useState(false)
  const [clawbackPoints, { isLoading: isSubmitting }] =
    usersApi.useClawbackPointsMutation()

  async function onProceed() {
    if (amount < 1) return

    const result = await clawbackPoints({
      user_uid: profile.uid,
      amount,
    })

    if (isError(result)) {
      dispatch(
        alert({
          key: 'danger',
          message: getErrorMessage(result.error, t('alert_fail')),
          timeout: 5,
        })
      )
      onClose()
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('alert_success', { amount }),
        timeout: 5,
      })
    )

    await dispatch(getUserProfile(profile.uid))
    onClose()
  }

  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      proceedText={t('title')}
      onProceed={onProceed}
      canProceed={formIsValid}
      isLoading={isSubmitting}
    >
      <h3 className="mt-0 mb-xs extra-bold">{t('title')}</h3>
      <Text.BodyText3 color="grey70" className="mb-xs">
        {t('subtitle')}
      </Text.BodyText3>

      <div className="mt-xl">
        <Form
          onValid={() => setFormIsValid(true)}
          onInvalid={() => setFormIsValid(false)}
        >
          <div className="d-flex flex-column align-items-start">
            <div className="w-100 mb-m">
              <Text.BodyText4 bold>{t('amount')}</Text.BodyText4>
              <Input
                name="total"
                value={amount}
                addOnAfter={
                  <Text.BodyText3 className="d-flex justify-content-center align-center">
                    {t('points')}
                  </Text.BodyText3>
                }
                onChange={event => {
                  const value = Number(event.currentTarget.value)
                  if (isNaN(value)) return
                  setAmount(value)
                }}
                className="w-100 mt-xs"
                validations={{
                  greaterThan: 0,
                  isRequired: true,
                  isNumeric: true,
                  isInt: true,
                }}
                validationErrors={{
                  greaterThan: I18n.t('validations.greater_than', {
                    value: 0,
                  }),
                  isRequired: I18n.t('validations.is_required'),
                  isNumeric: I18n.t('validations.is_numeric'),
                  isInt: validationT('is_int_point_clawback'),
                }}
              />
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default ClawbackPointsModal
