import {
  EventRanges,
  EventTypes,
  Fields,
  ShortType,
} from 'models/CampaignMetric'
import { AxiosAction, FailureAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'
import urlUtils from 'utilities/urlUtils'

export const GET_CAMPAIGN_METRIC_BY_TYPE = 'campaign_metrics/GET_BY_TYPE'
export const GET_CAMPAIGN_METRIC_BY_TYPE_SUCCESS =
  'campaign_metrics/GET_BY_TYPE_SUCCESS'
export const GET_CAMPAIGN_METRIC_BY_TYPE_FAIL =
  'campaign_metrics/GET_BY_TYPE_FAIL'

export const GET_CAMPAIGN_METRIC_EVENTS = 'campaign_metrics/GET_EVENTS'
export const GET_CAMPAIGN_METRIC_EVENTS_SUCCESS =
  'campaign_metrics/GET_EVENTS_SUCCESS'
export const GET_CAMPAIGN_METRIC_EVENTS_FAIL =
  'campaign_metrics/GET_EVENTS_FAIL'

export type GetCampaignType = AxiosAction<
  typeof GET_CAMPAIGN_METRIC_BY_TYPE,
  { metric: Fields }
>
export type GetCampaignTypeFailure = FailureAction<
  typeof GET_CAMPAIGN_METRIC_BY_TYPE_FAIL
>

export type GetCampaignEventsType = AxiosAction<
  typeof GET_CAMPAIGN_METRIC_EVENTS,
  { event_data: Fields }
>
export type GetCampaignEventsTypeFailure = FailureAction<
  typeof GET_CAMPAIGN_METRIC_EVENTS_FAIL
>

// Seems like there should be a better way here, I'm open to suggestions
export type EventRangeTypes =
  | EventRanges.LAST_30
  | EventRanges.LAST_90
  | EventRanges.LAST_365
  | EventRanges.ALL_TIME

export type EventParams = {
  type?: EventTypes
  range?: 'all_time' | 'custom'
  variant_id?: number
}

export type MetricParams = {
  variant_id?: number
  attribution_window?: number
}

export function getCampaignMetricByType(
  id: number,
  type: ShortType,
  params?: MetricParams
): GetCampaignType {
  let url = `campaigns/${id}/metrics/${type}`
  if (params) {
    const addedParams = urlUtils.formatParams(params)
    url += `?${addedParams}`
  }
  return {
    type: GET_CAMPAIGN_METRIC_BY_TYPE,
    payload: {
      client: 'default',
      request: {
        url,
        method: 'get',
      },
    },
  }
}

export function getCampaignMetricEvents(
  id: number,
  params: EventParams
): GetCampaignEventsType {
  let url = `campaigns/${id}/metrics/events`
  if (params) {
    const addedParams = urlUtils.formatParams(params)
    url += `?${addedParams}`
  }
  return {
    type: GET_CAMPAIGN_METRIC_EVENTS,
    payload: {
      client: 'default',
      request: {
        url,
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getCampaignMetricByType | typeof getCampaignMetricEvents>
>
