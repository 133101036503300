import { Switch } from '@thanx/uikit/switch'
import { getApp } from 'actions/app'
import { getBrands } from 'actions/brands'
import PageHeader from 'components/PageHeader'
import Spinner from 'components/Spinner'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useMemo, useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import { history } from 'store'
import Brand, { Tabs as BrandTabs } from '../Brand'
import Content from '../Content'
import Settings, { Tabs as SettingsTabs } from '../Settings'
import Signup, { Tabs as SignupTabs } from '../Signup'
import NotConfiguredPage from './NotConfiguredPage'
import TabContext, { Tabs } from './TabContext'

const t = buildTranslate('cms.landing')
const titles = buildTranslate('titles')

const Landing: React.FC = () => {
  const { ordering_enabled, branded_app } = useCurrentMerchant() || {}
  const app = useSelector(selectApp)
  const pathname = history.location.pathname
  const hash = history.location.hash
  const param = hash.replace('#', '')

  const [activeTab, setActiveTab] = useState<Tabs>(() => {
    const isActiveTab = tabs => Object.values(tabs).some(t => t === param)
    if (isActiveTab(BrandTabs)) return Tabs.Brand
    if (isActiveTab(SettingsTabs)) return Tabs.Settings
    if (isActiveTab(SignupTabs)) return Tabs.Signup

    return Tabs.Content
  })

  const [isAppLoading, setIsAppLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBrands())
  }, [dispatch])

  useEffect(() => {
    async function fetchData() {
      setIsAppLoading(true)
      await dispatch(getApp())
      setIsAppLoading(false)
    }

    fetchData()
  }, [dispatch])

  const showCms = (app?.allow_downloads && branded_app) || !!ordering_enabled
  const title = useMemo(() => titles(`${pathname}${hash}`), [pathname, hash])

  if (isAppLoading) {
    return (
      <Spinner isLoading size="4x" className="text-center margin-top-huge" />
    )
  }

  const defaultTabs = {
    brand: Object.values(BrandTabs)[0],
    settings: Object.values(SettingsTabs)[0],
    signup: Object.values(SignupTabs)[0],
  }
  const setTab = tab => {
    history.replace({ hash: defaultTabs[tab] })
    setActiveTab(tab)
  }

  return (
    <PageHeader title={t('title')}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Switch condition={showCms} fallback={<NotConfiguredPage />}>
        <Tab.Container
          activeKey={activeTab}
          transition={false}
          onSelect={setTab}
        >
          <TabNav>
            <TabItem state={Tabs.Content} title={t('tabs.content')} />
            <TabItem state={Tabs.Brand} title={t('tabs.brand')} />
            <TabItem state={Tabs.Settings} title={t('tabs.settings')} />
            <TabItem state={Tabs.Signup} title={t('tabs.signup')} />
          </TabNav>
          <TabContext.Provider value={{ activeTab }}>
            <Tab.Content className="h-100 grey-05-bkg">
              <TabPane state={Tabs.Content}>
                <Content />
              </TabPane>
              <TabPane state={Tabs.Brand}>
                <Brand isAppLoading={isAppLoading} />
              </TabPane>
              <TabPane state={Tabs.Settings}>
                <Settings />
              </TabPane>
              <TabPane state={Tabs.Signup}>
                <Signup />
              </TabPane>
            </Tab.Content>
          </TabContext.Provider>
        </Tab.Container>
      </Switch>
    </PageHeader>
  )
}

export default Landing
