import React from 'react'
type PropsT = {
  color?: string
  className?: string
}

const UploadIcon: React.FC<PropsT> = ({
  color = '#36424E',
  className = '',
}) => {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3391 4.59281L13.4072 0.660938C12.9853 0.239063 12.4134 0 11.8186 0H2.24953C1.00781 0.0046875 0 1.0125 0 2.25422V21.7505C0 22.9922 1.00781 24 2.24953 24H15.7505C16.9922 24 18 22.9922 18 21.7505V6.18609C18 5.59125 17.7609 5.01469 17.3391 4.59281ZM12.0014 1.52766C12.1327 1.56047 12.2498 1.62609 12.3483 1.72453L16.2802 5.65641C16.3786 5.75484 16.4442 5.87203 16.477 6.00328H12.0014V1.52766ZM16.5005 21.7505C16.5005 22.163 16.163 22.5005 15.7505 22.5005H2.24953C1.83703 22.5005 1.49953 22.163 1.49953 21.7505V2.25422C1.49953 1.84172 1.83703 1.5 2.24953 1.5H10.5014V6.37828C10.5014 7.00172 11.003 7.5 11.6264 7.5H16.5005V21.7505ZM8.05828 10.1573L4.86516 13.5206C4.49906 13.905 4.39922 14.4727 4.61016 14.9658C4.81687 15.4491 5.26641 15.7495 5.78203 15.7495H8.25V19.875C8.25 20.0822 8.41781 20.25 8.625 20.25H9.375C9.58219 20.25 9.75 20.0822 9.75 19.875V15.7491H12.2184C12.7341 15.7491 13.1836 15.4486 13.3903 14.9653C13.6012 14.4722 13.5019 13.905 13.1353 13.5211L9.94219 10.1564C9.44109 9.63188 8.55891 9.63141 8.05828 10.1573ZM6.24188 14.2491L9 11.3428L11.7581 14.2491H6.24188Z"
        fill={color}
      />
    </svg>
  )
}

export default UploadIcon
