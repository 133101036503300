import { createCampaignVariant } from 'actions/campaignVariants'
import { AxiosResponse } from 'axios'
import useDispatch from 'hooks/useDispatch'
import { useState } from 'react'

export type VariantAction = 'variant' | 'control'

export const useVariantSelectionHandler = () => {
  const dispatch = useDispatch()
  const [response, setResponse] = useState<AxiosResponse | undefined>()

  const submitVariant = async ({ action, campaignId }) => {
    let response
    switch (action) {
      case 'control':
        response = await dispatch(createCampaignVariant(campaignId, 'control'))
        setResponse(response)
        break
      default:
        response = await dispatch(createCampaignVariant(campaignId))
        setResponse(response)
    }
  }

  return { submitVariant, response }
}
