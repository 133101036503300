import { deleteRedeemTemplate } from 'actions/redeemTemplates'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'

import deleteImg from 'assets/images/redeem-manager/delete.svg'
import ActionModal from './ActionModal'

type Props = {
  templateId: number
  name: string
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}

const t = buildTranslate('redeem_manager.actions.delete')

const DeleteModal: React.FC<Props> = ({
  templateId,
  name,
  isOpen,
  onDelete,
  onClose,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const dispatch = useDispatch()

  async function deleteDraft() {
    setIsSubmitting(true)
    const result = await dispatch(deleteRedeemTemplate(templateId))
    setIsSubmitting(false)

    if (result.error) {
      setError(
        t('error', {
          name,
        })
      )
      return
    }

    onDelete()
  }

  return (
    <ActionModal
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      image={deleteImg}
      title={t('title')}
      description={t('description')}
      submitText={t('submit_button')}
      error={error}
      onClose={onClose}
      onSubmit={deleteDraft}
    />
  )
}

export default DeleteModal
