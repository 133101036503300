import { Switch } from '@thanx/uikit/switch'
import map from 'lodash/map'
import type { Metrics } from 'models/CampaignMetric'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'

type Props = {
  metrics?: Metrics
  hasDivider?: boolean
}

export const ItemsSold: React.FC<Props> = ({ metrics, hasDivider = false }) => {
  function getItems() {
    const items = metrics?.item_quantity?.items

    if (!items) return []

    return map(items, (count, item) => ({
      name: item,
      count: count,
    }))
      .sort((itemA, itemB) => itemA.count - itemB.count)
      .reverse()
  }

  const items = getItems()
  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <div className="repeat-customers-section">
          <p className="deprecated__small-caps-3 margin-bottom-large">
            <Translate value="thanx_campaigns.report.items_sold.title" />
          </p>
          <Switch
            condition={items.length > 0}
            fallback={
              <div className="font-size-32 bold text-center">
                <Translate value="thanx_campaigns.report.items_sold.no_data" />
              </div>
            }
          >
            <table className="campaign-table full-width font-size-17">
              <tbody>
                {items.map((item, index) => {
                  return (
                    <tr
                      className="padding-bottom-medium padding-top-medium vertical-middle"
                      key={index}
                    >
                      <td>{item.name}</td>
                      <td className="text-center">
                        {item.count}
                        <p className="grey-50 font-size-14">sold</p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default withMetrics(['item_quantity'])(ItemsSold)
