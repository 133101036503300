import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import { TabT } from './index'
import Item from './Item'

type Props = {
  activeTab: string
  tab: TabT
}

const TabGroup: React.FC<Props> = props => {
  const { tab, activeTab } = props
  const { key, title, subtabs } = tab
  const [css] = useStyletron()

  const isGroupActive = !!subtabs?.find(t => t.key === activeTab)

  return (
    <div className="overflow-hidden border-radius-5">
      <Item
        key={key}
        tabKey={key}
        title={title}
        isActive={isGroupActive}
        className="pl-m"
      />
      <Switch condition={isGroupActive}>
        <div className="belize-hole-10-bkg">
          {subtabs?.map(({ key, title }) => (
            <Item
              key={key}
              className="pl-xl pr-xs"
              activeBackgroundClass={css({
                // this color is not in our color palette
                backgroundColor: '#D8E6F0',
              })}
              tabKey={key}
              title={title}
              isActive={key === activeTab}
            />
          ))}
        </div>
      </Switch>
    </div>
  )
}

export default TabGroup
