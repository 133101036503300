import { Button } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  isLoading: boolean
  disabled?: boolean
}

const t = buildTranslate('cms.content.preferences_modal_setting')

const Sidebar: React.FC<Props> = ({ isLoading, disabled }) => {
  const [css] = useStyletron()

  return (
    <Button
      type="submit"
      className={`mb-xxxl ${css({ width: '200px' })}`}
      isLoading={isLoading}
      disabled={disabled}
    >
      {t('publish')}
    </Button>
  )
}

export default Sidebar
