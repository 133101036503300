import { RESET_DB } from 'actions/orm'
import {
  GET_USER_TAG_VALUES_SUCCESS,
  ResolvedAction,
} from 'actions/userTagValues'
import { attr, fk, Model, ModelType } from 'redux-orm'

export interface Fields {
  merchant_id: number
  key_id: string
  value: string
  label: string
  parent: string
}

// @ts-ignore
export default class UserTagValue extends Model<typeof UserTagValue, Fields> {
  static modelName: string = 'UserTagValue'

  static get fields(): any {
    return {
      id: attr(),
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'userTagValues',
      }),
      key_id: fk({
        to: 'UserTagKey',
        as: 'key',
        relatedName: 'values',
      }),
      value: attr(),
      label: attr(),
      parent: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    UserTagValue: ModelType<UserTagValue>
  ) {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case GET_USER_TAG_VALUES_SUCCESS:
        action.payload.data.values.forEach(userTagValue => {
          return UserTagValue.upsert({
            ...userTagValue,
            id: `${userTagValue.merchant_id}.${userTagValue.parent}.${userTagValue.value}`,
            key_id: `${userTagValue.merchant_id}.${userTagValue.parent}`,
          })
        })
        break
      case RESET_DB:
        UserTagValue.all().delete()
        break
      default:
        break
    }
  }
}
