import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React from 'react'
import { useUikitTheme } from 'theme'
import { BenchmarkT } from '../helpers'

type BenchmarkData = {
  benchmark: BenchmarkT
  merchantType: string
}

type PropsT = {
  benchmarkData?: BenchmarkData | null
  timezone: string
}

const styletronEngine = new StyletronEngine({ prefix: 'st-tt-' })
const benchmarkTooltipT = buildTranslate('reports.benchmark_tooltip')

const TimezoneBenchmarkCaption: React.FC<PropsT> = ({
  benchmarkData,
  timezone,
}) => {
  const { benchmark, merchantType } = benchmarkData ?? {}
  const uikitTheme = useUikitTheme()

  return (
    <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
      <div className={'text-right'}>
        <Text.BodyText5>
          {benchmarkTooltipT('timezone', {
            timeZone: moment.tz(timezone).format('z'),
          })}
        </Text.BodyText5>

        {benchmark && (
          <Switch
            condition={!benchmark.isGeneric}
            fallback={
              <Text.BodyText5>{benchmarkTooltipT('caption')[2]}</Text.BodyText5>
            }
          >
            <div className={'d-block mt-xs'}>
              <Text.BodyText5>{benchmarkTooltipT('caption')[0]}</Text.BodyText5>
              <Text.BodyText5 bold>{merchantType}</Text.BodyText5>
              <Text.BodyText5>{benchmarkTooltipT('caption')[1]}</Text.BodyText5>
            </div>
          </Switch>
        )}
      </div>
    </StyleProvider>
  )
}

export default TimezoneBenchmarkCaption
