import numeral from 'numeral'
import pluralize from 'pluralize'
import React from 'react'
import { Button, Modal, Well } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'

type Item = {
  label: React.ReactNode
  value: number
}

type Props = {
  show: boolean
  targetedItems: Item[]
  excludedItems: Item[]
  onModalCloseClicked: () => void
}

type BuildSectionProps = {
  header: string
  items: Item[]
}

const BuildSection: React.FC<BuildSectionProps> = ({ header, items }) => {
  const total = items.reduce((sum, item) => sum + item.value, 0)

  return (
    <>
      <div className="display-table-row">
        <div className="display-table-cell bold font-size-17 padding-bottom-medium padding-top-medium">
          {header}
        </div>
        <div className="display-table-cell text-right bold font-size-17">
          {numeral(total).format('0,0')}
        </div>
      </div>

      {items.map(({ label, value }, i) => {
        return (
          <div key={i} className="display-table-row padding-right-medium">
            <div className="display-table-cell padding-bottom-small padding-top-small">
              {label}
            </div>
            <div className="display-table-cell text-right">
              {numeral(value).format('0,0')}
            </div>
          </div>
        )
      })}
    </>
  )
}

const AudienceBreakoutModal: React.FC<Props> = props => {
  const { show, targetedItems, excludedItems, onModalCloseClicked } = props

  const total =
    targetedItems.reduce((sum, item) => sum + item.value, 0) +
    excludedItems.reduce((sum, item) => sum + item.value, 0)

  const header = (
    <>
      <Translate
        value="thanx_campaigns.review.customers.breakout_modal.title"
        className="text-center margin-bottom"
        tag="h4"
      />
      <div className="font-size-18 margin-bottom center-block text-center grey-70 padding-bottom-medium">
        <Translate
          tag="p"
          value="thanx_campaigns.review.customers.breakout_modal.subtitle"
          count={numeral(total).format('0,0')}
          unit={pluralize(
            I18n.t('thanx_campaigns.review.customers.unit'),
            total
          )}
          dangerousHTML
        />
      </div>
    </>
  )

  const content = (
    <Well className="thanx_campaigns_review grey-80">
      <div className="display-table full-width">
        <BuildSection
          header={I18n.t(
            'thanx_campaigns.review.customers.breakout_modal.table.targeting.title'
          )}
          items={targetedItems}
        />
        <BuildSection
          header={I18n.t(
            'thanx_campaigns.review.customers.breakout_modal.table.excluded.title'
          )}
          items={excludedItems}
        />
        <div className="display-table-row">
          <div className="display-table-cell">
            <hr className="grey-40" />
          </div>
          <div className="display-table-cell">
            <hr className="grey-40" />
          </div>
        </div>

        <div className="display-table-row">
          <div className="display-table-cell bold font-size-17">
            <Translate value="thanx_campaigns.review.customers.breakout_modal.table.footer.title" />
          </div>
          <div className="display-table-cell text-right bold font-size-17">
            {numeral(total).format('0,0')}
          </div>
        </div>
      </div>
    </Well>
  )

  return (
    <Modal
      className="fs-unmask"
      bsClass="modal"
      show={show}
      onHide={onModalCloseClicked}
    >
      <Modal.Body>
        <div className="clearfix">
          <div className="pull-right">
            <Button
              onClick={onModalCloseClicked}
              className="btn-borderless padding-right-none"
            >
              <span className="font-size-40 line-height-30 grey-60 light">
                &times;
              </span>
            </Button>
          </div>
        </div>
        {header}
        {content}
      </Modal.Body>
    </Modal>
  )
}

export default AudienceBreakoutModal
