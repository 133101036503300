import { DateRangeGranularity } from 'components/DateFilter/utilities'
import isNil from 'lodash/isNil'
import { ActivationRateTimeSeriesMetric } from 'models/ActivationRateMetric'
import { EffectiveDiscountRateTimeSeriesMetric } from 'models/EffectiveDiscountRateMetric'
import { Fields as Merchant } from 'models/Merchant'
import { RetentionRateTimeSeriesMetric } from 'models/RetentionRateMetric'
import moment from 'moment'
import { getDateLabel } from 'utilities/date'
import { currencyFormatter, percentageFormatter } from 'utilities/formatters'

export type BenchmarkT = {
  rate: number
  isGeneric: boolean
}

const benchmarks = {
  'Food: Quick Serve': {
    activation_rate: 23.5,
    retention_rate: 86.0,
    effective_discount_rate: 3.2,
  },
  'Food: Casual': {
    activation_rate: 39.0,
    retention_rate: 86.0,
    effective_discount_rate: 3.2,
  },
  'Food: Coffee/Snack': {
    activation_rate: 36.0,
    retention_rate: 90.0,
    effective_discount_rate: 5.8,
  },
  'Food: Full Service': {
    activation_rate: 26.0,
    retention_rate: 79.0,
    effective_discount_rate: 0.9,
  },
  'Car Wash': {
    retention_rate: 91.0,
    effective_discount_rate: 1.8,
  },
  Mall: {
    retention: 91.0,
    effective_discount_rate: 0.8,
  },
  Default: {
    revenue_capture_rate: 15.0,
    activation_rate: 33.25,
    retention_rate: 88.0,
    effective_discount_rate: 2.7,
  },
}

export const getBenchmarkRate = (merchantType, metric): BenchmarkT => {
  const industrySpecificRate = benchmarks?.[merchantType]?.[metric]
  return {
    rate: industrySpecificRate
      ? industrySpecificRate
      : benchmarks['Default'][metric],
    isGeneric: !industrySpecificRate,
  }
}

export const reportCurrency = (value, showCents = false) =>
  isNil(value) ? '$-' : currencyFormatter(value, showCents)

export const reportPercentage = value =>
  isNil(value) ? '-%' : percentageFormatter(value || 0, 1, 1)

export const tooltipCurrency = value =>
  isNil(value) || value === 0 ? '$-' : currencyFormatter(value, false)

export const tooltipPercentage = (value, maxDigits = 1, minDigits = 1) =>
  percentageFormatter((value || 0) / 100, maxDigits, minDigits)

const diffFormatter = (
  newValue,
  oldValue,
  formatter,
  inverted = false,
  threshold = 0.001
): { text: string; color: string } | undefined => {
  if (isNil(newValue) || isNil(oldValue)) return

  let difference = newValue - oldValue
  let color
  if (Math.abs(difference) < threshold) {
    color = 'grey70'
    difference = 0
  } else if (difference > 0) {
    color = !inverted ? 'nephritis50' : 'cayenne50'
  } else {
    color = !inverted ? 'cayenne50' : 'nephritis50'
  }

  return {
    text: (difference > 0 ? '+' : '') + formatter(difference),
    color: color,
  }
}

export const diffCurrency = (
  newValue,
  oldValue,
  inverted = false
): { text: string; color: string } | undefined => {
  if (!isNil(newValue) && !isNil(oldValue)) {
    return diffFormatter(
      newValue,
      oldValue,
      value => currencyFormatter(value, false),
      inverted
    )
  }
}

export const diffPercentage = (
  newValue,
  oldValue,
  inverted = false
): { text: string; color: string } | undefined => {
  if (!isNil(newValue) && !isNil(oldValue)) {
    return diffFormatter(
      newValue,
      oldValue,
      value => percentageFormatter(value, 1, 1),
      inverted,
      0.001
    )
  }
}

export function daysUntilReportAccess(
  merchant: Merchant | null,
  minimumDaysAfterLaunch: number
): number | null {
  if (!merchant?.live_at) return null
  const liveAt = moment(merchant.live_at)
  const targetDate = liveAt
    .clone()
    .add(1, 'month')
    .startOf('month')
    .add(minimumDaysAfterLaunch, 'days')
  return Math.ceil(targetDate.diff(moment(), 'days', true))
}

export function getXAxisCategories(
  values:
    | RetentionRateTimeSeriesMetric['values']
    | EffectiveDiscountRateTimeSeriesMetric['values']
    | ActivationRateTimeSeriesMetric['values'],
  granularity: DateRangeGranularity,
  timezone: string
): Array<string> {
  if (!values) return []

  return values.map(value =>
    getDateLabel(value.date_time, granularity, timezone)
  )
}
