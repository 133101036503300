import Spinner from 'components/Spinner'
import numeral from 'numeral'
import pluralize from 'pluralize'
import React from 'react'
import { textUtils } from 'utilities/textUtils'

type Props = {
  title: string
  count: number
  unit: string
  isLoading: boolean
}

const CheckboxListSum: React.FC<Props> = props => {
  const { title, count, unit, isLoading } = props

  return (
    <div>
      <div className="display-table full-width">
        <div className="display-table-row">
          <div
            className="display-table-cell"
            style={{ width: '32px', paddingRight: '12px' }}
          />
          <div className="display-table-cell">
            <div className="font-size-20 grey-90 inline-block bold">
              {title}
            </div>
          </div>
          <div className="display-table-cell text-right">
            <div className="font-size-18 grey-90 bold">
              <Spinner isLoading={isLoading}>
                {numeral(count).format('0,0')}
              </Spinner>
            </div>
          </div>
        </div>
        <div className="display-table-row">
          <div className="display-table-cell" />
          <div className="display-table-cell" />
          <div className="display-table-cell text-right">
            <div className="font-size-14 grey-50">
              {pluralize(textUtils.initialCapital(unit), count)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckboxListSum
