import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as UserEvent } from 'models/UserEvent'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.view.activity.overview.table')

type PropsT = {
  events: UserEvent[]
  isLoading: boolean
  onOrderDetailsClick: (event: UserEvent) => void
}

const Table: React.FC<PropsT> = ({
  events,
  isLoading,
  onOrderDetailsClick,
}: PropsT) => {
  return (
    <table className="full-width">
      <thead>
        <tr>
          <HeaderCell>{t('date')}</HeaderCell>
          <HeaderCell>{t('activity')}</HeaderCell>
          <HeaderCell>{t('location')}</HeaderCell>
          <HeaderCell key="last" />
        </tr>
      </thead>
      <tbody>
        {events.map(event => (
          <Row
            key={event.id}
            event={event}
            isLoading={isLoading}
            onOrderDetailsClick={onOrderDetailsClick}
          />
        ))}
      </tbody>
    </table>
  )
}

export default Table
