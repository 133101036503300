import { PLACEMENT, Popover } from '@thanx/uikit/popover'
import { Text } from '@thanx/uikit/text'
import React, { useState } from 'react'
import { useStyletron } from 'styletron-react'

type Props = {
  text: React.ReactNode | string
  title?: string
  className?: string
  bodyClassName?: string
  placement?: keyof PLACEMENT
}

const PopoverInfo: React.FC<Props> = props => {
  const {
    title,
    text,
    placement = 'rightTop',
    className = '',
    bodyClassName,
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const [css] = useStyletron()

  const content = (
    <div
      className={`${css({
        marginTop: '-8px',
      })} px-m pb-s grey-10-bkg`}
    >
      {title && (
        <Text.SmallCaps3 tag="div" className="mt-l" bold color="grey70">
          {title}
        </Text.SmallCaps3>
      )}
      {typeof text === 'string'
        ? text.split('\n').map(line => (
            <Text.BodyText5
              tag="p"
              key={line}
              className={`mt-m mb-0 ${bodyClassName}`}
              color="grey70"
            >
              {line}
            </Text.BodyText5>
          ))
        : text}
    </div>
  )

  return (
    <Popover
      triggerType="hover"
      placement={placement}
      content={content}
      overrides={{
        Body: {
          style: {
            maxWidth: '334px',
            borderRadius: '4px',
            backgroundColor: '#f2f4f5',
            zIndex: 9999,
          },
        },
        Inner: {
          style: {
            padding: 0,
          },
        },
        Arrow: {
          style: {
            backgroundColor: '#f2f4f5',
          },
        },
      }}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <i
        className={`${
          isOpen ? 'grey-70' : 'grey-50'
        } fa fa-question-circle font-size-14 ${className}`}
      />
    </Popover>
  )
}

export default PopoverInfo
