import { ResolvedAction } from 'actions/couponPools'
import { RESET_DB } from 'actions/orm'
import { fk, Model, ModelType } from 'redux-orm'
import { RedemptionProvider } from './RedemptionProvider'

export enum CouponPoolGenerationType {
  STATIC = 'static',
  VARIABLE = 'variable',
  DYNAMIC = 'dynamic',
}

export enum CouponPoolType {
  RAW = 'raw',
  QRCODE = 'qrcode',
  BARCODE_39 = 'barcode39',
  BARCODE_39_EXT = 'barcode39extended',
  BARCODE_93 = 'barcode93',
  BARCODE_128 = 'barcode128',
  BARCODE_UPC_A = 'barcode_upc_a',
  BARCODE_EAN_8 = 'barcode_ean_8',
  BARCODE_EAN_13 = 'barcode_ean_13',
  BARCODE_25_INTER = 'barcode25interleaved',
}

export enum CouponPoolState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface ApiFields {
  id: number
  generation_type: CouponPoolGenerationType
  type: CouponPoolType
  state: CouponPoolState
  static_code: string | null
  created_at: string
  redemption_provider: RedemptionProvider | null
}

export interface Fields extends ApiFields {
  redeem_template_id: number
}

// @ts-ignore
export default class CouponPool extends Model<typeof CouponPool, Fields> {
  static modelName: string = 'CouponPool'

  static get fields(): any {
    return {
      redeem_template_id: fk({
        to: 'RedeemTemplate',
        as: 'redeem_template',
        relatedName: 'coupon_pools',
      }),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    CouponPool: ModelType<CouponPool>,
    session: any
  ): void {
    switch (action.type) {
      case 'couponPool/GET_ALL_SUCCESS': {
        // clean pool list before getting updated list
        CouponPool.all().delete()
        action.payload.data.coupon_pools.forEach(couponPool => {
          CouponPool.upsert({
            ...couponPool,
            redeem_template_id: action.meta.previousAction.redeemTemplateId,
          })
        })
        break
      }
      case 'couponPool/CREATE_SUCCESS': {
        CouponPool.create({
          ...action.payload.data.coupon_pool,
          redeem_template_id: action.meta.previousAction.redeemTemplateId,
        })
        // also add the created pool to the merchant pools
        session.MerchantCouponPool.upsert(action.payload.data.coupon_pool)
        break
      }
      case 'couponPool/UPDATE_SUCCESS': {
        CouponPool.update({
          ...action.payload.data.coupon_pool,
        })
        break
      }
      case RESET_DB:
        CouponPool.all().delete()
        break
    }
  }
}
