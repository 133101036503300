import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import ContentContainer from 'components/ContentContainer'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { merchantTheme } from 'theme'
import { numberFormatter } from 'utilities/formatters'
const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  type: string
  text: string
  url: string
  count: number
  isLoading?: boolean
  isErrored?: boolean
}

const Row: React.FC<PropsT> = props => {
  const { type, text, url, count, isLoading, isErrored } = props

  const [css] = useStyletron()
  const borderClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: merchantTheme.colors.grey30,
  })

  return (
    <tr>
      <td className={`p-m max-width-0 ${borderClass}`}>
        <ContentContainer isErrored={!!isErrored} isLoading={!!isLoading}>
          <Text.BodyText4
            color="grey70"
            className="ellipsis d-inline-block max-width-100"
          >
            {capitalize(type)}
          </Text.BodyText4>
        </ContentContainer>
      </td>
      <td className={`p-m max-width-0 ${borderClass}`}>
        <ContentContainer isErrored={!!isErrored} isLoading={!!isLoading}>
          <Text.BodyText4
            color="grey70"
            className="ellipsis d-inline-block max-width-100"
          >
            {capitalize(text)}
          </Text.BodyText4>
        </ContentContainer>
      </td>
      <td className={`p-m max-width-0 ${borderClass}`}>
        <ContentContainer isErrored={!!isErrored} isLoading={!!isLoading}>
          <a href={url}>
            <Text.BodyText4
              color="grey70"
              className="ellipsis d-inline-block max-width-80"
            >
              {url}
            </Text.BodyText4>
            <Text.BodyText2 color="grey70" className="ml-xs">
              <i
                className="fa fa-external-link vertical-bottom"
                aria-hidden="true"
              />
            </Text.BodyText2>
          </a>
        </ContentContainer>
      </td>
      <td className={`text-right p-m ${borderClass}`}>
        <ContentContainer isErrored={!!isErrored} isLoading={!!isLoading}>
          <Metric metric={numberFormatter(count)}>
            <PopoverContent
              sections={[
                {
                  content: t(
                    'engagement.email.link_activity.tooltips.count.primary'
                  ),
                },
                {
                  title: t(
                    'engagement.email.link_activity.tooltips.count.secondary_title'
                  ),
                  content: t(
                    'engagement.email.link_activity.tooltips.count.secondary_text'
                  ),
                },
                {
                  title: t('example'),
                  content: t(
                    'engagement.email.link_activity.tooltips.count.example_content_1'
                  ),
                },
              ]}
            />
          </Metric>
        </ContentContainer>
      </td>
    </tr>
  )
}

export default Row
