import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import { ProgressBar } from 'baseui/progress-bar'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'

const t = buildTranslate('cms.components.image_picker.upload_progress')

type Props = {
  value: number
  processing: boolean
  timeout: number
}

const UploadProgress: React.FC<Props> = ({ value, processing, timeout }) => {
  const [showProgress, setShowProgress] = useState(false)
  const [css] = useStyletron()
  const [firstValue] = useState(value)
  const [firstTimeout] = useState(timeout)

  useEffect(() => {
    let timer = setTimeout(() => {
      if (firstValue < 95) setShowProgress(true) // this way it won't start showing it if is already almost done.
    }, firstTimeout)

    // clear timeout on unmount
    return () => {
      clearTimeout(timer)
    }
  }, [firstTimeout, firstValue])

  const showUploadBar = showProgress && !processing
  let title
  if (processing) {
    title = t('processing')
  } else if (showProgress) {
    title = t('uploading')
  } else {
    title = t('reading_file')
  }

  return (
    <div
      className={`${css({
        height: '130px',
      })} d-flex flex-column align-items-center border-1 grey-30-border px-m pt-m pb-s`}
    >
      <p className="body-text-4 bold mb-xs">{title}</p>
      <div
        className={`${css({
          minHeight: '32px',
        })} w-100 mb-xs d-flex justify-content-center`}
      >
        <Switch
          condition={showUploadBar}
          fallback={
            <Spinner
              className="belize-hole-50 text-center"
              isLoading={true}
              size="2x"
            />
          }
        >
          <ProgressBar
            value={value}
            successValue={100}
            getProgressLabel={value => t('percentage', { value })}
            showLabel
            overrides={{
              BarProgress: {
                style: ({ $theme }) => {
                  return {
                    backgroundColor: $theme.colors.primary,
                  }
                },
              },
              Bar: {
                style: () => ({
                  height: '8px',
                  margin: '0px',
                }),
              },
            }}
          />
        </Switch>
      </div>
      <p className="body-text-4 grey-70 mb-0">{t('please_wait')}</p>
    </div>
  )
}

export default UploadProgress
