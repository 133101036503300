import { Fields } from 'models/App'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectApps = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => session.App.all().toRefArray()
)

export const selectApp = createSelector(
  selectApps,
  (apps: Fields[]): Fields => {
    return apps?.[0]
  }
)

export const selectIsBrandedApp = createSelector(
  selectApp,
  (app: Fields): boolean => {
    return !!app?.id && (app.state === 'active' || app.state === 'beta')
  }
)
