import { useStyletron } from '@thanx/uikit/theme'
import backgroundBronze from 'assets/images/tiers/background_bronze.png'
import backgroundGold from 'assets/images/tiers/background_gold.png'
import backgroundSilver from 'assets/images/tiers/background_silver.png'
import iconBronze from 'assets/images/tiers/ic_bronze.png'
import iconGold from 'assets/images/tiers/ic_gold.png'
import iconSilver from 'assets/images/tiers/ic_silver.png'
import Badges from 'components/Badges'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { Fields as TierType } from 'models/Tier'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Markdown from 'react-markdown'

const t = buildTranslate('cms.brand.tiers')

type Props = {
  tier: TierType | null
}

const Item: React.FC<Props> = ({ tier }) => {
  const [css] = useStyletron()

  if (!tier) return null

  const {
    name,
    type,
    background_image_urls,
    icon_image_urls,
    color,
    description,
    requirements,
  } = tier

  let defaultBackground, defaultIcon, defaultColor, defaultName

  switch (type) {
    case 'tier_bronze':
      defaultBackground = backgroundBronze
      defaultIcon = iconBronze
      defaultColor = '#BD7346'
      defaultName = t('tier_bronze')
      break
    case 'tier_silver':
      defaultBackground = backgroundSilver
      defaultIcon = iconSilver
      defaultColor = '#B5B5B5'
      defaultName = t('tier_silver')
      break
    case 'tier_gold':
      defaultBackground = backgroundGold
      defaultIcon = iconGold
      defaultColor = '#CBB54E'
      defaultName = t('tier_gold')
      break
    default:
      break
  }

  return (
    <Section className="px-xl pt-xl mt-l">
      <p className="small-caps-1 grey-90">{name || defaultName}</p>
      <Row className="mt-m body-text-4 grey-90">
        <Col md={4} sm={6} className="mb-xl">
          <p className="bold">{t('background_image')}</p>
          <Badges values={[t('background_size'), t('jpg')]} className="mt-xs" />
          <img
            src={background_image_urls?.small || defaultBackground}
            alt={t('background_image')}
            className={`${css({
              objectFit: 'cover',
              width: '105px',
              height: '151px',
            })} mt-m`}
          />
        </Col>
        <Col md={4} sm={6} className="mb-xl">
          <p className="bold">{t('icon')}</p>
          <Badges values={[t('icon_size'), t('png')]} className="mt-xs" />
          <img
            src={icon_image_urls?.small || defaultIcon}
            alt={t('icon')}
            className={`${css({
              objectFit: 'cover',
              width: '110px',
              height: '110px',
            })} mt-m`}
          />
        </Col>
        <Col md={4} sm={6} className="mb-xl">
          <p className="bold">{t('color')}</p>
          <Badges values={[color || defaultColor]} className="mt-xs" />
          <div
            className={`${css({
              backgroundColor: color || defaultColor,
              width: '150px',
              height: '72px',
            })} mt-m rounded`}
          />
        </Col>
        <Col md={4} sm={6} className="mb-xl">
          <p className="bold">{t('perks_description')}</p>
          <div className="grey-70 markdown-blob">
            <Markdown>{description}</Markdown>
          </div>
        </Col>
        <Col md={4} sm={6} className="mb-xl">
          <p className="bold">{t('requirements')}</p>
          <p className="body-text-4 grey-70 mt-xs">{requirements}</p>
        </Col>
      </Row>
    </Section>
  )
}

export default Item
