export default {
  title: 'End User Support',
  page_title: 'End User Support | Thanx',
  not_available_title: 'This report is not available',
  not_available_subtitle: 'You are managing end-user support yourself',
  help: 'Help',
  learn_more: 'Learn more about Thanx Managed User Support',
  explainer_card: {
    title:
      'You are in the trial period for Thanx-managed end-user support until July 01, 2024',
    subtitle:
      'Supports your customers with Thanx’s best-in-class, US-based support, covering user account management, loyalty point inquiries, receipt upload reconciliation for cash purchases, and more. On July 01, 2024 your subscription will automatically renew unless you choose to opt-out and handle customer support yourself. Paid subscribers can see industry-leading CSAT and response times in the reporting below.',
    learn_more: 'Learn about this subscription',
    opt_out: 'How to opt-out',
    not_available_title: 'Let us help with end-user support',
    not_available_subtitle:
      "You are currently managing end-user support yourself. Thanx's best-in-class US-based support with industry-leading CSAT and rapid response times can support your customers’ questions on everything from ordering inquires, reward redemption, loyalty points, general questions about how the program works, and more.",
    help: 'Help',
    contact_us: 'Contact us for help with end-user support',
    learn_more_secondary: 'Learn more',
  },
}
