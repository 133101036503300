import {
  createTheme,
  defaultTheme,
  Theme as UikitTheme,
} from '@thanx/uikit/theme'

export type merchantThemeT = UikitTheme & {
  colors: UikitTheme['colors'] & {
    nephritis10: string
    nephritis20: string
    nephritis50: string
    belizeHole10: string
    belizeHole50: string
    eggYolk10: string
    eggYolk50: string
    spearmint10: string
    spearmint30: string
    spearmint50: string
    cayenne10: string
    cayenne50: string
    grey100: string
    grey05: string
    wisteria: string
    wisteria10: string
    wisteria30: string
  }
}

export function generateUikitTheme(): merchantThemeT {
  return createTheme(
    {},
    {
      colors: {
        ...defaultTheme.colors,
        nephritis10: '#E9F6EF',
        nephritis20: '#D6F5E4',
        nephritis50: '#27AE60',
        belizeHole10: '#E5F0F8',
        belizeHole50: '#0073BF',
        eggYolk10: '#FAF0D1',
        eggYolk50: '#E7B41A',
        spearmint10: '#E3F7F7',
        spearmint30: '#89E0DF',
        spearmint50: '#13C1BF',
        cayenne10: '#FBDFD6',
        cayenne50: '#F26335',
        grey100: '#232B33',
        grey05: '#F8F9FA',
        wisteria: '#8e44ad',
        wisteria10: '#eff0ff',
        wisteria30: '#BA86D0',
      },
    }
  ) as merchantThemeT
}

export function useUikitTheme(): merchantThemeT {
  return createTheme(
    {},
    {
      colors: {
        ...defaultTheme.colors,
        nephritis10: '#E9F6EF',
        nephritis20: '#D6F5E4',
        nephritis50: '#27AE60',
        belizeHole10: '#E5F0F8',
        belizeHole50: '#0073BF',
        eggYolk10: '#FAF0D1',
        eggYolk50: '#E7B41A',
        spearmint10: '#E3F7F7',
        spearmint30: '#89E0DF',
        spearmint50: '#13C1BF',
        cayenne10: '#FBDFD6',
        cayenne50: '#F26335',
        grey100: '#232B33',
        grey05: '#F8F9FA',
        wisteria: '#8e44ad',
        wisteria30: '#BA86D0',
      },
    }
  ) as merchantThemeT
}

export const merchantTheme = generateUikitTheme()
