import { buildTranslate } from 'locales'
import React from 'react'
import CmsPage from '../../components/CmsPage'
import AndroidFeature from './AndroidFeature'
import AndroidNotificationIcon from './AndroidNotificationIcon'
import AppIcon from './AppIcon'
import AppStoreScreens from './AppStoreScreens'
import Metadata from './Metadata'
import PlayStoreScreens from './PlayStoreScreens'

const t = buildTranslate('cms.brand.app_store_assets')

type Props = {
  isLoading: boolean
}

const AppStoreAssets: React.FC<Props> = ({ isLoading }) => {
  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      isLoading={isLoading}
    >
      <AppIcon />
      <AndroidNotificationIcon />
      <AppStoreScreens />
      <PlayStoreScreens />
      <AndroidFeature />
      <Metadata />
    </CmsPage>
  )
}

export default AppStoreAssets
