import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import revenueImage from 'assets/images/reports/lifecycle/revenue.svg'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'
import { useIsMobile } from 'utilities/responsive'
import { activationRateUrl } from 'utilities/urlUtils'

const t = buildTranslate('activation_rate.tabs.activation_rate')

const ExplainerCard: React.FC = () => {
  const [css] = useStyletron()
  const isMobile = useIsMobile()

  return (
    <Section
      className={`d-flex flex-wrap mb-xl ${isMobile ? 'm-auto' : 'pl-l'} ${css({
        minHeight: '308px',
        backgroundColor: merchantTheme.colors.wisteria10,
        boxShadow: '0 1rem 3rem rgba(54, 66, 78, 0.2)',
      })}`}
    >
      <div className={`mt-xl ${isMobile ? 'ml-auto mr-auto' : ''}`}>
        <img src={revenueImage} alt={t('')} />
      </div>
      <div
        className={`pl-l pt-l pb-m d-flex flex-column ${css({
          width: isMobile ? '95%' : '70%',
        })}`}
      >
        <Text.Header4>{t('explainer_card.title')}</Text.Header4>
        <Text.BodyText3
          color={merchantTheme.colors.grey70}
          className={css({ lineHeight: '24px', fontWeight: 400 })}
        >
          {t('explainer_card.description')}
        </Text.BodyText3>
        <Button
          className={`mt-l ${css({
            width: isMobile ? '100%' : '200px',
          })}`}
          onClick={() => window.open(activationRateUrl)}
        >
          {t('explainer_card.activation_rate_101')}
        </Button>
      </div>
    </Section>
  )
}

export default ExplainerCard
