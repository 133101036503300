import { useStyletron } from '@thanx/uikit/theme'
import { getCumulativeCaptureRateMetrics } from 'actions/captureRateMetrics'
import Counter from 'components/Counter'
import { LocationContext } from 'contexts/LocationContext'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import isNil from 'lodash/isNil'
import { Fields as Merchant } from 'models/Merchant'
import moment from 'moment-timezone'
import React, { useCallback, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  diffPercentage,
  reportCurrency,
  reportPercentage,
} from 'scenes/Reports/helpers'
import { selectCumulativeMetric } from 'selectors/captureRateMetric'
import { useIsMobile } from 'utilities/responsive'
import Header from '../Header'
import { useUploadState } from '../UploadStateContext'
import Chart from './Chart'
import ValueName from './ValueName'

type PropT = {
  merchant: Merchant
}

const t = buildTranslate('capture_rate')

const Current: React.FC<PropT> = ({ merchant }) => {
  const { currentLocation } = useContext(LocationContext)
  const dispatch = useDispatch()
  const cumulativeMetrics = useSelector(state =>
    selectCumulativeMetric(state, merchant?.id, currentLocation?.id)
  )
  const { onFileProcessed } = useUploadState()

  const fetchMetrics = useCallback(() => {
    if (merchant.id) {
      dispatch(
        getCumulativeCaptureRateMetrics({
          merchant_id: merchant.id,
          location_id: currentLocation?.id,
        })
      )
    }
  }, [currentLocation?.id, merchant.id, dispatch])

  useEffect(() => {
    fetchMetrics()
  }, [dispatch, merchant.id, fetchMetrics, currentLocation?.id])

  useEffect(() => {
    onFileProcessed(fetchMetrics)
  }, [fetchMetrics, onFileProcessed])

  const [css] = useStyletron()
  const isMobile = useIsMobile()
  const { isErrored, isLoading } = cumulativeMetrics || {}
  const lastMonthData = cumulativeMetrics?.current
  const currentPercent = lastMonthData?.capture_rate
  const previousPercent = cumulativeMetrics?.previous?.capture_rate
  const month = lastMonthData?.date_time
    ? moment(lastMonthData?.date_time).format('MMM YYYY')
    : moment().subtract(1, 'month').format('MMM YYYY')

  const rateDiffToPreviousPeriod = diffPercentage(
    currentPercent,
    previousPercent
  )

  return (
    <>
      <Header className="mt-xl mb-m" title={t('current_rate')} />
      <div
        className={`mb-m ${css({
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr',
          columnGap: '64px',
          rowGap: '16px',
        })}`}
      >
        <Counter
          primaryValue={reportPercentage(lastMonthData?.capture_rate)}
          name={
            <ValueName
              isLoading={isLoading}
              isErrored={isErrored}
              title={t('revenue_rate_1', { month })}
              subtitle={t('revenue_rate_2', {
                rate: reportPercentage(
                  lastMonthData?.capture_rate_with_third_party
                ),
              })}
              hasNilValue={isNil(lastMonthData?.capture_rate)}
            />
          }
          isErrored={isErrored}
          isLoading={isLoading}
          sections={[
            { content: t('popovers.revenue_rate_popover.section_1') },
            { content: t('popovers.revenue_rate_popover.section_2') },
            {
              title: t('popovers.example'),
              content: t('popovers.revenue_rate_popover.section_3'),
            },
          ]}
          secondaryValue={rateDiffToPreviousPeriod?.text}
          secondaryValueColor={rateDiffToPreviousPeriod?.color}
        />
        <Counter
          primaryValue={reportCurrency(
            lastMonthData?.member_purchases_total_amount
          )}
          name={t('net_revenue_loyalty', { month })}
          isErrored={isErrored}
          isLoading={isLoading}
          sections={[
            { content: t('popovers.net_revenue_loyalty_popover.section_1') },
            { content: t('popovers.net_revenue_loyalty_popover.section_2') },
            {
              title: t('popovers.example'),
              content: t('popovers.net_revenue_loyalty_popover.section_3'),
            },
          ]}
        />
        <Counter
          primaryValue={reportCurrency(lastMonthData?.total_amount)}
          name={
            <ValueName
              isLoading={isLoading}
              isErrored={isErrored}
              title={t('net_revenue_all_1', { month })}
              subtitle={t('net_revenue_all_2')}
              hasNilValue={isNil(lastMonthData?.total_amount)}
            />
          }
          isErrored={isErrored}
          isLoading={isLoading}
          sections={[
            { content: t('popovers.net_revenue_all_popover.section_1') },
            { content: t('popovers.net_revenue_all_popover.section_2') },
            {
              title: t('popovers.example'),
              content: t('popovers.net_revenue_all_popover.section_3'),
            },
          ]}
        />
      </div>
      <Chart merchant={merchant} />
    </>
  )
}

export default Current
