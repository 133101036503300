import { Notification } from '@thanx/uikit/notification'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Information from 'components/Icons/Information'
import UserSupport from 'components/Icons/UserSupport'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React from 'react'
import Table from './Table'

const t = buildTranslate('users.view.profile.transferred_points')

type Props = {
  profile: UserProfile
}

const TransferredPoints = ({ profile }: Props) => {
  return (
    <div>
      <Text.Header4 className={'mb-m'}>{t('title')}</Text.Header4>
      <Switch
        condition={profile.points_provisions.length > 0}
        fallback={
          <>
            <div className="mt-m mb-m">
              <Notification kind="info" size="full">
                <div className="d-flex w-100 p-xs align-items-center belize-hole-50">
                  <span className="mr-s">
                    <Information type="info" />
                  </span>
                  <p className="flex-2 body-text-4 mr-m">
                    {t('empty_state.note')}
                  </p>
                </div>
              </Notification>
            </div>
            <Text.BodyText3 color="grey70">
              {t('empty_state.description')}
            </Text.BodyText3>
            <div className="mt-m mb-m">
              <Notification
                overrides={{
                  Body: {
                    style: ({ $theme }) => ({
                      backgroundColor: $theme.colors.mono200,
                      width: '100%',
                    }),
                  },
                }}
              >
                <div className="d-flex w-100 p-xs align-items-center grey-70">
                  <span className="mr-s">
                    <UserSupport />
                  </span>
                  <p className="flex-2 body-text-4 mr-m">
                    {t('empty_state.troubleshoot')}
                  </p>
                </div>
              </Notification>
            </div>
          </>
        }
      >
        <Text.BodyText3 className={'mb-m'} color="grey70" tag={'div'}>
          {t('points_transferred')}
        </Text.BodyText3>
        <div className="mt-m mb-m">
          <Notification kind="info" size="full">
            <div className="d-flex w-100 p-xs align-items-center belize-hole-50">
              <span className="mr-s">
                <Information type="info" />
              </span>
              <p className="flex-2 body-text-4 mr-m">{t('description')}</p>
            </div>
          </Notification>
        </div>
        <Table pointsProvisions={profile.points_provisions} />
        <Text.BodyText5 color="grey70">{t('delay_note')}</Text.BodyText5>
      </Switch>
    </div>
  )
}

export default TransferredPoints
