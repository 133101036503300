import Input from 'components/Form/Input'
import Spacer from 'components/Spacer'
import { buildTranslate } from 'locales'
import type { Fields as Redeem } from 'models/Redeem'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.reward_creator'
)

type Props = {
  redeem: Redeem
}

const VALIDATIONS = {
  offerText: {
    validations: {
      minLength: 5,
      maxLength: 47,
      isRequired: true,
      mustNotBeginInWhitespace: true,
      mustEndInPunctuation: false,
      mustNotBeginWithCausativeVerb: true,
    },
    messages: {
      minLength: I18n.t('validations.min_length', { minLength: 5 }),
      maxLength: I18n.t('validations.max_length', { maxLength: 47 }),
      isRequired: I18n.t('validations.is_required'),
      mustNotBeginInWhitespace: I18n.t(
        'validations.must_not_begin_in_whitespace'
      ),
      mustEndInPunctuation: I18n.t('validations.must_not_end_in_punctuation'),
      mustNotBeginWithCausativeVerb: I18n.t(
        'validations.must_not_begin_with_causative_verb'
      ),
    },
  },
  positiveNumber: {
    validations: {
      isNumeric: true,
      greaterThan: 0,
      isRequired: true,
    },
    messages: {
      isNumeric: I18n.t('validations.is_numeric'),
      greaterThan: I18n.t('validations.greater_than', { value: 0 }),
      isRequired: I18n.t('validations.is_required'),
    },
  },
}

const Item: React.FC<Props> = ({ redeem }) => {
  return (
    <div>
      <Spacer paddingBottom="40px">
        <div className="body-text-2 margin-bottom-small">
          {t('redeem_description.title')}
        </div>
        <Input
          name="redeem_description"
          value={redeem.redeem_description}
          placeholder={t('redeem_description.item.placeholder')}
          addOnBefore={t('redeem_description.item.add_on')}
          trimValue={true}
          validations={VALIDATIONS.offerText.validations}
          validationErrors={VALIDATIONS.offerText.messages}
        />
      </Spacer>
      <Spacer paddingBottom="40px">
        <div className="body-text-2 margin-bottom-small">
          {t('cost_value_title')}
        </div>
        <Row>
          <Col xs={6}>
            <Input
              name="redeem_discount"
              value={redeem.redeem_discount}
              label={t('cost.label')}
              placeholder={t('cost.placeholder')}
              addOnBefore="$"
              validations={VALIDATIONS.positiveNumber.validations}
              validationErrors={VALIDATIONS.positiveNumber.messages}
            />
          </Col>
          <Col xs={6}>
            <Input
              name="redeem_retail_value"
              value={redeem.redeem_retail_value}
              label={t('value.label')}
              placeholder={t('value.placeholder')}
              addOnBefore="$"
              validations={VALIDATIONS.positiveNumber.validations}
              validationErrors={VALIDATIONS.positiveNumber.messages}
            />
          </Col>
        </Row>
      </Spacer>
    </div>
  )
}

export default Item
