import Input from 'components/Form/Input'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type Props = {
  value: number
}

const t = buildTranslate('points.create_edit.restrictions.modal')

const Maximum: React.FC<Props> = ({ value }) => {
  return (
    <div className="py-xl" data-testid="restriction-section">
      <div className="d-flex justify-content-between">
        <div className="mr-m">
          <div className="body-text-2">{t('maximum.title')}</div>
          <div className="grey-50 body-text-4">{t('maximum.subtitle')}</div>
        </div>
      </div>
      <div className="width-80 mt-m">
        <Input
          name="redeem_maximum"
          value={value}
          label={t('maximum.title')}
          placeholder={t('maximum.placeholder')}
          addOnBefore="$"
          validations={{
            isNumeric: true,
            greaterThanOrEqual: 0,
          }}
          validationErrors={{
            isNumeric: I18n.t('validations.is_numeric'),
            greaterThanOrEqual: I18n.t('validations.greater_than', {
              value: 0,
            }),
          }}
        />
      </div>
    </div>
  )
}

export default Maximum
