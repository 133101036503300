import { MerchantUser } from 'api/merchantUsers'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import DeleteModal from './DeleteModal'
import Row from './Row'

const t = buildTranslate('merchant_users.merchant_users_list.table')

type Props = {
  merchantUsers: MerchantUser[]
}

const Table = ({ merchantUsers }: Props) => {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [selectedMerchantUser, setSelectedMerchantUser] =
    useState<MerchantUser>()

  return (
    <>
      <table className="full-width">
        <thead>
          <tr>
            <HeaderCell key="name" className={'w-75'}>
              {t('name')}
            </HeaderCell>
            <HeaderCell key="role">{t('role')}</HeaderCell>
            <HeaderCell key="status">{t('status')}</HeaderCell>
            <HeaderCell key="edit"></HeaderCell>
            <HeaderCell key="remove"></HeaderCell>
          </tr>
        </thead>
        <tbody data-testid="table-body">
          {merchantUsers.map(merchantUser => (
            <Row
              key={merchantUser.id}
              merchantUser={merchantUser}
              onRemove={() => {
                setSelectedMerchantUser(merchantUser)
                setDeleteModalVisible(true)
              }}
            />
          ))}
        </tbody>
      </table>
      <DeleteModal
        visible={deleteModalVisible}
        setIsVisible={setDeleteModalVisible}
        merchantUser={selectedMerchantUser}
      />
    </>
  )
}

export default Table
