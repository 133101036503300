import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import GenericChart from 'components/Charts/Generic'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import PopoverContent from 'components/PopoverContent'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { RetentionRateTimeSeriesMetric } from 'models/RetentionRateMetric'
import React, { useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import BenchmarkTooltip from 'scenes/Reports/components/BenchmarkTooltip'
import { BenchmarkT, getXAxisCategories } from 'scenes/Reports/helpers'
import { useTimezoneBenchmarkCaption } from 'scenes/Reports/hooks/useTimezoneBenchmarkCaption'
import { getRateChartSeries, RETENTION_WINDOW_DAYS } from '../helpers'
import Tooltip from './Tooltip'

const t = buildTranslate('retention_rate.chart')
const popoversT = buildTranslate('retention_rate.popovers')

type PropsT = {
  isErrored: boolean
  isLoading: boolean
  granularity: DateRangeGranularity
  timezone: string
  values: RetentionRateTimeSeriesMetric['values']
  benchmark: BenchmarkT
}

const Chart: React.FC<PropsT> = ({
  isErrored,
  isLoading,
  granularity,
  timezone,
  values,
  benchmark,
}) => {
  const [css] = useStyletron()
  const merchant = useCurrentMerchant()
  const caption = useTimezoneBenchmarkCaption(merchant, timezone, benchmark)

  const leftAxisFormatter = label => {
    return `${label.value}%`
  }
  const categories = useMemo(
    () => getXAxisCategories(values, granularity, timezone),
    [values, granularity, timezone]
  )
  const series = useMemo(
    () => getRateChartSeries(values, benchmark.rate),
    [values, benchmark]
  )

  if (!merchant) return null

  return (
    <div className="mt-m">
      <div
        className={`w-100 h-100 pl-0 pr-s py-l white-bkg border-1 grey-20-border position-relative ${css(
          {
            borderRadius: '4px',
          }
        )}`}
      >
        <div className="d-flex flex-align-center flex-space-between mb-l">
          <div className="ml-l">
            <Text.Header4 bold className="mt-0 mb-0">
              <span className="mr-xs">{t('title')}</span>
              <PopoverContent
                sections={[
                  {
                    content: popoversT('retention_rate', {
                      dateLabel: popoversT('in_given_month'),
                      retentionWindow: RETENTION_WINDOW_DAYS,
                    })[0],
                  },
                  {
                    title: popoversT('example'),
                    content: popoversT('retention_rate', {
                      retentionWindow: RETENTION_WINDOW_DAYS,
                      dateLabel: popoversT('sample_month'),
                      engagedUsersCount: popoversT('sample_engaged_users'),
                      returningUsersCount: popoversT('sample_returning_users'),
                      churnedUsers: popoversT('sample_churned_users'),
                      retentionRate: popoversT('sample_retention_rate'),
                    })[1],
                  },
                ]}
              />
            </Text.Header4>
          </div>
        </div>
        <GenericChart
          chartType={'line'}
          categories={categories}
          isLoading={isLoading}
          isErrored={isErrored}
          series={series}
          xAxisTitle={t('x_axis')}
          yAxisTitle={t('y_axis')}
          leftAxisFormatter={leftAxisFormatter}
          tooltipFormatter={function () {
            if (this.point.custom.is_benchmark) {
              return renderToString(
                <BenchmarkTooltip
                  benchmark={benchmark}
                  merchantType={merchant.merchant_type}
                />
              )
            }
            return renderToString(
              <Tooltip
                date={this.point.category}
                retentionRate={this.point.y ?? 0}
                engagedUsers={this.point.custom.engaged_users_count}
                returningUsers={this.point.custom.returning_users_count}
                churnedUsers={this.point.custom.churned_users_count}
              />
            )
          }}
          caption={caption}
        />
      </div>
    </div>
  )
}

export default Chart
