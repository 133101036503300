import { Button } from '@thanx/uikit/button'
import { buildTranslate } from 'locales'
import type { Fields as Merchant } from 'models/Merchant'
import React, { useState } from 'react'
import { Col, Container, Row, Tab } from 'react-bootstrap-five'
import { RedeemTypeItem } from '../../types'
import TopBar from '../TopBar'
import Discount from './Discount'
import NonDiscount from './NonDiscount'

import { Step } from '../index'
import NavBar from './NavBar'

type Props = {
  templateId: number | null
  typeItem: RedeemTypeItem | null
  setRedeemType: (typeItem: RedeemTypeItem) => void
  onContinue: () => void
  onStepSelected: (step: Step) => void
  merchant: Merchant | null
}

const t = buildTranslate('redeem_manager.builder.steps.type')

const TypeStep: React.FC<Props> = ({
  templateId,
  typeItem,
  setRedeemType,
  onContinue,
  onStepSelected,
  merchant,
}) => {
  const initialActive =
    !typeItem?.key ||
    typeItem?.key === 'golden_ticket' ||
    typeItem?.key === 'bonus_points' ||
    typeItem?.key === 'hidden_menu'
      ? 'non_discount'
      : 'discount'
  const [activeTab, setActiveTab] = useState(initialActive)

  return (
    <>
      <TopBar
        step="type"
        templateId={templateId}
        onStepSelected={onStepSelected}
      >
        <Button
          kind="secondary_outline"
          disabled={!typeItem}
          onClick={onContinue}
        >
          {t('continue')}
        </Button>
      </TopBar>
      <Container className="pt-l">
        <Row>
          <Col>
            <h2 className="mb-xl">{t('title')}</h2>
          </Col>
        </Row>
        <Tab.Container
          activeKey={activeTab}
          onSelect={tab => setActiveTab(tab!)}
        >
          <NavBar tabs={['non_discount', 'discount']} />
          <Tab.Content>
            <Tab.Pane eventKey="non_discount">
              <NonDiscount
                merchant={merchant}
                typeItem={typeItem}
                setRedeemType={setRedeemType}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="discount">
              <Discount
                merchant={merchant}
                typeItem={typeItem}
                setRedeemType={setRedeemType}
              />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <Row className="justify-content-center">
          <p className="grey-70">
            {t('need_different')}
            <a href="mailto:merchant.support@thanx.com">{t('contact_us')}</a>
          </p>
        </Row>
      </Container>
    </>
  )
}

export default TypeStep
