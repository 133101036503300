import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import Review from 'scenes/CampaignCenter/Builder/Steps/Shared/Review'
import Audience from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Audience'
import AudienceBreakoutModal from './components/AudienceBreakoutModal'

type Props = {
  campaign: Campaign
  setCurrentStep: (string) => void
}

const CustomReview = (props: Props) => {
  const { campaign, setCurrentStep } = props
  return (
    <Review
      campaign={campaign}
      setCurrentStep={setCurrentStep}
      audience={
        <Audience
          editStep="audience"
          campaign={campaign}
          isEditable
          setCurrentStep={setCurrentStep}
          renderAudienceBreakoutModal={renderProps => (
            <AudienceBreakoutModal {...renderProps} />
          )}
        />
      }
    />
  )
}

export default React.memo<Props>(CustomReview)
