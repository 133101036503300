import Review from 'scenes/CampaignCenter/Builder/Steps/Message/Review'
import Setup from 'scenes/CampaignCenter/Builder/Steps/Message/Setup'
import Audience from 'scenes/CampaignCenter/Builder/Steps/Message/Setup/Substeps/Audience'
import Incentive from 'scenes/CampaignCenter/Builder/Steps/Shared/Incentive'
import Name from 'scenes/CampaignCenter/Builder/Steps/Shared/Name'
import Success from 'scenes/CampaignCenter/Builder/Steps/Shared/Success'
import VariantSelector from 'scenes/CampaignCenter/Builder/Steps/Shared/VariantSelector'
import Messaging from 'scenes/CampaignCenter/Builder/Steps/Shared/WrappedMessaging'

export default {
  steps: {
    required: {
      setup: Setup,
      review: Review,
      success: Success,
    },
    optional: {
      name: Name,
      variantSelect: VariantSelector,
      audience: Audience,
      incentive: Incentive,
      messaging: Messaging,
    },
  },
  options: {
    initialStep: 'setup',
    disableNavFor: {
      setup: true,
      success: true,
      variantSelect: true,
      name: true,
    },
  },
}
