import React from 'react'
import styles from './CampaignSection.module.scss'

type Props = {
  url: string
}

const LogoBadge: React.FC<Props> = ({ url }) => (
  <div
    data-testid={`logo-badge`}
    className={styles.segmentCardLogo}
    style={{
      backgroundImage: `url(${url})`,
    }}
  />
)

export default LogoBadge
