import { Text } from '@thanx/uikit/text'
import { StoredValueConfig } from 'api/storedValue'
import Section from 'components/Section'
import { buildTranslate } from 'locales'

const t = buildTranslate('stored_value.stored_value_configuration.auto_reload')

type Props = {
  config: StoredValueConfig
}

const Item = ({ label, value }) => (
  <div className="mb-m">
    <div className="mb-xs">
      <Text.BodyText4 bold>{label}</Text.BodyText4>
    </div>

    <div>
      <Text.BodyText4>${value}</Text.BodyText4>
    </div>
  </div>
)

const AutoReload = ({ config }: Props) => {
  return (
    <Section className="pt-m px-m mb-m">
      <Text.Header4 className="mb-m mt-0">{t('title')}</Text.Header4>
      <div className="mb-xs">
        <Text.BodyText4>{t('description')}</Text.BodyText4>
      </div>
      <Item
        label={t('min_threshold')}
        value={config.minimum_auto_reload_threshold}
      />
      <Item label={t('min_amount')} value={config.minimum_auto_reload_amount} />
      <Item label={t('max_amount')} value={config.maximum_auto_reload_amount} />
    </Section>
  )
}

export default AutoReload
