import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'

type Props = {
  variantOf?: string
  search?: string
  children: string
}

const Term: React.FC<Props> = props => {
  const { variantOf, search = '', children } = props

  const term = variantOf || search || children || ''
  const definition = I18n.t(`glossary.${term.toLowerCase()}`)
  if (!definition || definition === term.toLowerCase()) return <>{children}</>

  if (term === children) {
    return _termWithPopover(term, children)
  }

  if (search && children.toLowerCase().includes(search.toLowerCase())) {
    let substrings = children.split(search)
    return (
      <span>
        <span>{substrings[0] || ''}</span>
        {_termWithPopover(term, search)}
        <span>{substrings[1] || ''}</span>
      </span>
    )
  }

  if (variantOf) {
    return _termWithPopover(term, children)
  }

  return <>{children}</>
}

const _termWithPopover = (term: string, display: string) => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      placement="top"
      overlay={
        <Popover id="term-definition">
          {<Translate value={`glossary.${term.toLowerCase()}`} dangerousHTML />}
        </Popover>
      }
    >
      <abbr>{display}</abbr>
    </OverlayTrigger>
  )
}

export default Term
