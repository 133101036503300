import { PaginationOverrides } from 'baseui/pagination'
import React from 'react'
import { merchantTheme } from 'theme'
import PrevNextButton from './PrevNextButton'

export const paginationOverrides: PaginationOverrides = {
  DropdownContainer: {
    style: () => ({
      outline: 'none',
    }),
  },
  Select: {
    props: {
      overrides: {
        ControlContainer: {
          style: ({ $isFocused }) => {
            const borderRadius = '4px'

            return {
              paddingLeft: '4px',
              backgroundColor: $isFocused
                ? merchantTheme.colors.grey10
                : 'none',
              outline: `1px ${
                $isFocused
                  ? merchantTheme.colors.grey90
                  : merchantTheme.colors.grey30
              } solid !important`,
              border: 'none !important',
              borderTopLeftRadius: borderRadius,
              borderTopRightRadius: borderRadius,
              borderBottomLeftRadius: borderRadius,
              borderBottomRightRadius: borderRadius,
            }
          },
        },
        SingleValue: {
          style: () => ({
            color: merchantTheme.colors.grey90,
          }),
        },
      },
    },
  },
  MaxLabel: {
    style: () => ({
      color: merchantTheme.colors.grey90,
    }),
  },
  PrevButton: {
    component: ({ onClick, disabled }) => {
      return (
        <PrevNextButton type={'prev'} isDisabled={disabled} onClick={onClick} />
      )
    },
  },
  NextButton: {
    component: ({ onClick, disabled }) => {
      return (
        <PrevNextButton type={'next'} isDisabled={disabled} onClick={onClick} />
      )
    },
  },
}
