export default {
  error: 'We had trouble updating your campaign. Try again?',
  schedule: {
    title: 'Schedule your campaign',
    body: 'You’re about to schedule this campaign to %{count} customers <strong class="grey-90">on %{sendAtWithTime}</strong>.',
    body_no_count:
      'You’re about to schedule this campaign <strong class="grey-90">on %{sendAtWithTime}</strong>.',
    sub_text: 'Let’s do this.',
    info: 'We will recalculate your audience when this campaign is sent out which may change the final count.',
    buttons: {
      exit: 'I’m Not Ready',
      finish: 'Schedule Campaign',
    },
  },
  send_now: {
    title: 'Send your campaign',
    body: 'You’re about to send this campaign to %{count} customers.',
    body_no_count: 'You’re about to send this campaign to your customers.',
    sub_text: 'This is your moment to shine.',
    buttons: {
      exit: 'I’m Not Ready',
      finish: 'Send Campaign',
    },
  },
  request_review: {
    title: 'Submit for review',
    body: 'An admin needs to review and approve this campaign before it can be sent to %{count} customers on <strong class="grey-90"> %{sendAtWithTime}</strong>.',
    body_no_count:
      'An admin needs to review and approve this campaign before it can be sent on <strong class="grey-90"> %{sendAtWithTime}</strong>.',
    sub_text: 'This is your moment to shine.',
    buttons: {
      exit: 'I’m Not Ready',
      finish: 'Submit For Review',
    },
    error:
      'We are having trouble fetching admin users to approve your campaign. Try again later.',
    no_approvers:
      'We can’t find any admin users to approve your campaign. Contact your program administrator to create some.',
    label: 'Who should review this campaign?',
  },
  approve: {
    title: 'Approve this campaign',
    body: 'This campaign will be sent to %{count} customers <strong class="grey-90">on %{sendAtWithTime}</strong>.',
    body_no_count:
      'This campaign will be sent <strong class="grey-90">on %{sendAtWithTime}</strong>.',
    info: 'We will recalculate your audience when this campaign is sent out which may change the final count.',
    buttons: {
      exit: 'I’m Not Ready',
      finish: 'Approve Campaign',
    },
  },
  automated: {
    title: 'Start %{campaignName}',
    body: {
      reputation:
        'This campaign will be sent to <strong class="grey-90">customers who leave a positive review</strong> and ask them to share this on Google.',
      winback:
        'This campaign will be sent to <strong class="grey-90">your lost customers</strong>.',
    },
    sub_text: {
      reputation: 'Start improving your online reputation today!',
      winback: 'Don’t waitFor, start winning them back today!',
    },
    buttons: {
      exit: 'I’m Not Ready',
      finish: 'Start Campaign',
    },
    title_campaign_name: {
      reputation: 'Reputation Manager',
      winback: 'Winback',
    },
  },
  custom_automated_review: {
    title: 'Submit for review',
  },
  custom_automated: {
    title: 'Start your campaign',
    body: 'This campaign will target customers when they enter the segment <strong>%{segment}</strong>',
    start: 'Start',
    stop: 'Stop',
    now: 'Now',
    buttons: {
      exit: 'Cancel',
      finish: 'Start campaign',
    },
    ongoing: 'Ongoing (no end date)',
  },
}
