import { Button } from '@thanx/uikit/button'
import infoIcon from 'assets/images/campaign-center/ic_confirmation_pg_info.svg'
import approvalImage from 'assets/images/campaign-center/img_approval_required.svg'
import launchedImage from 'assets/images/campaign-center/img_launched.png'
import scheduledImage from 'assets/images/campaign-center/img_scheduled.png'
import sentImage from 'assets/images/campaign-center/img_sent.png'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import { Fields as MerchantUserFields } from 'models/MerchantUser'
import moment from 'moment-timezone'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import { useShowV3 } from 'scenes/CampaignCenter/Builder/hooks'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import { Can, useAbility } from 'utilities/ability'
import { reportLink } from 'utilities/campaignUtils'
import { useUserTimeZone } from 'utilities/userTimeZone'

type Props = {
  campaign: Campaign
}

const t = buildTranslate('thanx_campaigns.builder.steps.success')

const Success: React.FC<Props> = ({ campaign }) => {
  const ability = useAbility()
  const userTimeZone = useUserTimeZone()

  const dispatch = useDispatch()
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const showV3 = useShowV3(campaign.type)
  if (!campaignType) return null

  const hasNoStart = !campaign.start_at
  const isActive = campaign.state === 'active'
  const startInPast = moment(campaign.start_at).isBefore(moment.now())
  const isSendNow = hasNoStart || isActive || startInPast

  const canScheduleCampaign = ability.can('schedule', 'Campaign')

  const imageSrc = (function (): string {
    if (!canScheduleCampaign) return approvalImage
    if (campaignType.category === 'automated') return launchedImage
    if (isSendNow) return sentImage
    return scheduledImage
  })()

  const contentText = (function () {
    let type = isSendNow ? 'send_now' : 'schedule'
    if (campaignType.category === 'automated') type = 'automated'
    const approver = campaign.approver || ({} as MerchantUserFields)

    const sendAt = isSendNow
      ? null
      : moment(campaign.start_at).tz(userTimeZone).format('lll')

    return (
      <div className="body-text-3 grey-70-text">
        <Can I="schedule" a="Campaign">
          <p
            className="margin-bottom-huge font-size-18"
            dangerouslySetInnerHTML={{
              __html: t(`${type}.content`, {
                sendAt: sendAt,
              }),
            }}
          />
        </Can>
        <Can not I="schedule" a="Campaign">
          <p
            className="margin-bottom-huge font-size-18 fs-mask"
            dangerouslySetInnerHTML={{
              __html: t('request_review.content', {
                sendAtWithTime: sendAt,
                approver: `${approver.first_name} ${approver.last_name}`,
                campaignName: campaign.name,
              }),
            }}
          />
        </Can>
      </div>
    )
  })()

  const creditReview = (function () {
    if (campaignType?.category === 'automated') return null
    if (!campaign.redeem_type) return null
    if (!campaign.redeem_type.includes('automatic')) return null

    return (
      <Row>
        <Col xs={10}>
          <div className="display-flex grey-10-bkg margin-top-huge padding-medium">
            <img
              className="padding-right-medium"
              src={infoIcon}
              alt="success"
            />
            <p className="font-size-14 grey-50">{t('credit_review.content')}</p>
          </div>
        </Col>
      </Row>
    )
  })()

  return (
    <div className="grey-70 pt-xxl">
      <Row className="justify-content-center align-items-center">
        <Col
          sm="auto"
          className="padding-extra-large d-flex justify-content-center align-self-start"
        >
          <img src={imageSrc} alt="success" />
        </Col>
        <Col sm={5} className="padding-extra-large">
          <h2 className="mb-xs grey-90">
            <Can I="schedule" a="Campaign">
              {t('title')}
            </Can>
            <Can not I="schedule" a="Campaign">
              {t('request_review.title')}
            </Can>
          </h2>
          <div className="mb-xl">{contentText}</div>
          <div>
            {isSendNow && (
              <Button
                className="mr-s"
                kind="secondary"
                onClick={() => {
                  dispatch(
                    push(reportLink(campaign, campaignType, showV3).href)
                  )
                }}
              >
                {t('buttons.preview')}
              </Button>
            )}
            <Button
              onClick={() => {
                dispatch(push('/thanx_campaigns'))
              }}
            >
              {t('buttons.campaign')}
            </Button>
          </div>
          {creditReview}
        </Col>
      </Row>
    </div>
  )
}

export default Success
