import { Switch } from '@thanx/uikit/switch'
import { alert } from 'actions/flash'
import {
  HeaderData,
  updateLandingHeaders,
  UPDATE_LANDING_HEADERS_FAIL,
} from 'actions/landingHeader'
import { getValidation } from 'actions/validations'
import ConfirmModal from 'components/ConfirmModal'
import { ImagePickerValue } from 'components/ImagePicker'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import uniqueId from 'lodash/uniqueId'
import { ValidationTypes } from 'models/Validation'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Blocks from 'scenes/Cms/components/Blocks'
import CmsPage from 'scenes/Cms/components/CmsPage'
import DraftBanner from 'scenes/Cms/components/DraftBanner'
import PublishBanner from 'scenes/Cms/components/PublishBanner'
import { selectApp } from 'selectors/app'
import { selectLandingHeaders } from 'selectors/landingHeader'
import { selectLandingPage } from 'selectors/landingPage'
import Sidebar from './Sidebar'
import Slide from './Slide'

interface FormModel {
  [id: string]: ImagePickerValue
}

const t = buildTranslate('cms.content.hero_carousel')

const HeroCarousel: React.FC = () => {
  const app = useSelector(selectApp)
  const headers = useSelector(selectLandingHeaders)
  const landingPage = useSelector(selectLandingPage)
  const isDraft = landingPage.state === 'inactive'
  const dispatch = useDispatch()

  const [formModel, setFormModel] = useState<FormModel>(resetForm(headers))
  const [order, setOrder] = useState<string[]>(sortForm(formModel, headers))
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    async function fetchImageValidation() {
      setIsLoading(true)
      await dispatch(getValidation({ type: ValidationTypes.AppHeader }))
      setIsLoading(false)
    }

    fetchImageValidation()
  }, [dispatch])

  function resetForm(newHeaders): FormModel {
    if (newHeaders.length > 0) {
      return newHeaders.reduce((acc, obj) => {
        acc[obj.id] = {
          id: null,
          url: obj.image_urls?.small,
          uploading: false,
        }
        return acc
      }, {})
    }

    return {
      [uniqueId('carousel-')]: {
        id: null,
        url: '',
        uploading: false,
      },
    }
  }

  function sortForm(form: FormModel, newHeaders): string[] {
    if (newHeaders.length > 0) {
      return newHeaders
        .sort((a, b) => a.position - b.position)
        .map(h => `${h.id}`)
    }
    return Object.keys(form)
  }

  function onNewBlock() {
    const newId = uniqueId('carousel-')
    const newBlock = {
      [newId]: {
        id: null,
        url: '',
        uploading: false,
      },
    }
    setFormModel({
      ...formModel,
      ...newBlock,
    })
    setOrder([...order, newId])
  }

  async function handleSubmit() {
    const data: HeaderData[] = []
    order.forEach((orderId, index) => {
      const { id: imageId } = formModel[orderId]
      const header = headers.find(h => `${h.id}` === orderId)

      data.push({
        id: header?.id,
        position: index + 1,
        file_uploads: imageId
          ? {
              image: imageId,
            }
          : undefined,
      })
    })

    // destroy headers which are deleted
    headers.forEach(({ id, position }) => {
      // a header is deleted if it doesn't exist on the order array anymore
      if (order.indexOf(`${id}`) === -1) {
        data.push({
          id,
          position,
          destroy: true,
        })
      }
    })

    setIsSubmitting(true)
    const response = await dispatch(updateLandingHeaders(app.id, data))
    setIsSubmitting(false)
    setIsModalOpen(false)

    if (response.type === UPDATE_LANDING_HEADERS_FAIL) {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
          timeout: 5,
        })
      )
      return
    }
    const newHeaders = response.payload.data.headers
    if (newHeaders.length > 0) {
      setFormModel({})
      setOrder([])

      const newForm = resetForm(newHeaders)
      const newOrder = sortForm(newForm, newHeaders)

      setFormModel(newForm)
      setOrder(newOrder)
    }

    dispatch(
      alert({
        key: 'success',
        message: isDraft ? t('submit_draft_success') : t('submit_success'),
        timeout: 5,
      })
    )
  }

  let imageUploading = false
  order.forEach(orderId => {
    if (formModel[orderId]?.uploading) imageUploading = true
  })

  return (
    <Formsy
      onChange={newFormModel => setFormModel(newFormModel)}
      onValidSubmit={() => (isDraft ? handleSubmit() : setIsModalOpen(true))}
      onInvalidSubmit={() =>
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('cms.content.form_error'),
            timeout: 5,
          })
        )
      }
    >
      <CmsPage
        title={t('title')}
        description={t('description')}
        badges={[
          t('badges.app_only'),
          t('badges.size'),
          t('badges.jpg'),
          t('badges.png'),
        ]}
        sidebar={<Sidebar isLoading={isSubmitting} disabled={imageUploading} />}
        isLoading={isLoading}
      >
        <Blocks
          type="slide"
          order={order}
          minBlockCount={1}
          setOrder={setOrder}
          renderItem={(itemId, index) => (
            <Switch condition={!!formModel[itemId]}>
              <Slide
                key={itemId}
                itemId={itemId}
                index={index}
                image={formModel[itemId]}
              />
            </Switch>
          )}
          onNewBlock={onNewBlock}
        />
        <Switch
          condition={isDraft}
          fallback={
            <PublishBanner
              buttonText={t('publish')}
              isLoading={isSubmitting}
              disabled={imageUploading}
            />
          }
        >
          <DraftBanner isLoading={isSubmitting} disabled={imageUploading} />
        </Switch>
      </CmsPage>
      <ConfirmModal
        title={t('modal.publish_changes')}
        description={t('modal.you_are_publishing')}
        confirmText={t('modal.publish')}
        isOpen={isModalOpen}
        isLoading={isSubmitting}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => handleSubmit()}
      />
    </Formsy>
  )
}

export default HeroCarousel
