import { Switch } from '@thanx/uikit/switch'
import { DateRangeFilterProvider } from 'components/DateFilter/DateFilterContext'
import TabPane from 'components/Tabs/TabPane'
import moment from 'moment-timezone'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectExperience } from 'selectors/pointsExperience'
import ReportingNotActive from './sections/ReportingNotActive'
import { getPointsReportingBounds } from './utilities'
import View from './View'

const Activity: React.FC = () => {
  const pointsExperience = useSelector(selectExperience)

  const timeZone = moment.tz.guess()
  const bounds = getPointsReportingBounds(pointsExperience, timeZone)
  const isReportingActive =
    pointsExperience?.state === 'active' ||
    pointsExperience?.state === 'archived'

  return (
    <TabPane state="activity">
      <DateRangeFilterProvider bounds={bounds} timeZone={timeZone}>
        <Switch
          condition={isReportingActive}
          fallback={<ReportingNotActive pointsExperience={pointsExperience} />}
        >
          <View timeZone={timeZone} pointsExperience={pointsExperience!} />
        </Switch>
      </DateRangeFilterProvider>
    </TabPane>
  )
}

export default Activity
