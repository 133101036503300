import { Fields } from 'models/OrderingConfig'
import { PaginationT } from 'models/Pagination'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_ORDERING_CONFIGS = 'menus/GET_ORDERING_CONFIGS'
export const GET_ORDERING_CONFIGS_SUCCESS = 'menus/GET_ORDERING_CONFIGS_SUCCESS'
export const GET_ORDERING_CONFIGS_FAIL = 'menus/GET_ORDERING_CONFIGS_FAIL'

export const GET_ORDERING_CONFIG = 'menus/GET_ORDERING_CONFIG'
export const GET_ORDERING_CONFIG_SUCCESS = 'menus/GET_ORDERING_CONFIG_SUCCESS'
export const GET_ORDERING_CONFIG_FAIL = 'menus/GET_ORDERING_CONFIG_FAIL'

export const PUT_ORDERING_STATUSES = 'menus/PUT_ORDERING_STATUSES'
export const PUT_ORDERING_STATUSES_SUCCESS =
  'menus/PUT_ORDERING_STATUSES_SUCCESS'
export const PUT_ORDERING_STATUSES_FAIL = 'menus/PUT_ORDERING_STATUSES_FAIL'

export const PUT_ORDERING_REFRESH = 'menus/PUT_ORDERING_REFRESH'
export const PUT_ORDERING_REFRESH_SUCCESS = 'menus/PUT_ORDERING_REFRESH_SUCCESS'
export const PUT_ORDERING_REFRESH_FAIL = 'menus/PUT_ORDERING_REFRESH_FAIL'

export type GetOrderingConfigsAction = AxiosAction<
  typeof GET_ORDERING_CONFIGS,
  {
    ordering_configs: Fields[]
    pagination: PaginationT
  }
>

export function getOrderingConfigs(params: {
  page: number
}): GetOrderingConfigsAction {
  return {
    type: GET_ORDERING_CONFIGS,
    payload: {
      client: 'default',
      request: {
        url: 'locations/ordering_management',
        params: params,
      },
    },
  }
}

export function putOrderingStatuses(
  action: 'enable' | 'disable',
  locationIds: number[]
): AxiosAction<typeof PUT_ORDERING_STATUSES, { ordering_configs: Fields[] }> {
  return {
    type: PUT_ORDERING_STATUSES,
    payload: {
      client: 'default',
      request: {
        url: 'locations/ordering_management',
        params: { action, location_ids: locationIds },
        method: 'put',
      },
    },
  }
}

export function putOrderingRefresh(
  locationId: number
): AxiosAction<typeof PUT_ORDERING_REFRESH, { ordering_configs: Fields[] }> {
  return {
    type: PUT_ORDERING_REFRESH,
    payload: {
      client: 'default',
      request: {
        url: `locations/${locationId}/refresh_ordering_config`,
        params: { location_id: locationId },
        method: 'put',
      },
    },
  }
}

export function getOrderingConfig(
  location_id: number
): AxiosAction<typeof GET_ORDERING_CONFIG, { ordering_config: Fields }> {
  return {
    type: GET_ORDERING_CONFIG,
    payload: {
      client: 'default',
      request: {
        url: `locations/${location_id}/ordering_config`,
        params: {},
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getOrderingConfigs>>
  | ResolvedAxiosAction<ReturnType<typeof getOrderingConfig>>
  | ResolvedAxiosAction<ReturnType<typeof putOrderingStatuses>>
  | ResolvedAxiosAction<ReturnType<typeof putOrderingRefresh>>
