import { Button, Kind } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import {
  updateMerchantUserPassword,
  UPDATE_PASSWORD_FAIL,
} from 'actions/accounts'
import { alert } from 'actions/flash'
import EditNavbar from 'components/Navbar/EditNavbar'
import Section from 'components/Section'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as MerchantUser } from 'models/MerchantUser'
import React, { useState } from 'react'
import { Col, Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { push, replace } from 'react-router-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import { rootUrl } from 'utilities/urlUtils'

const t = buildTranslate('profile.password')
const validations = buildTranslate('validations')

interface Passwords extends Partial<MerchantUser> {
  old_password?: string
  new_password: string
  confirm_password: string
}

const ChangePassword: React.FC = () => {
  const merchantUser = useSelector(selectCurrentMerchantUser)
  const isForceUpdate = merchantUser?.force_password_change || false
  const [css] = useStyletron()
  const [template, setTemplate] = useState<Passwords | null>(null)
  const [isValid, setIsValid] = useState(true)
  const dispatch = useDispatch()
  // @ts-ignore
  const pathname = useSelector(state => state.redirect?.pathname || rootUrl)

  const updateTemplate = values => {
    setTemplate({
      ...template,
      ...values,
    })
  }

  const updatePassword = async () => {
    if (template) {
      var params = {}
      params['password'] = template.new_password
      params['confirm_password'] = template.confirm_password

      if (!isForceUpdate) {
        params['old_password'] = template.old_password
      }
      const result = await dispatch(updateMerchantUserPassword(params))
      if (result.type === UPDATE_PASSWORD_FAIL) {
        dispatch(
          alert({
            key: 'danger',
            message: t('update_failed'),
          })
        )
        return
      }
      if (isForceUpdate) {
        dispatch(push(pathname))
      } else {
        dispatch(push('/profile'))
      }
      // display success message and close the modal
      dispatch(
        alert({
          key: 'success',
          message: t('update_success'),
          timeout: 5,
        })
      )
    }
  }

  return (
    <Formsy
      className={`h-100 ${css({ marginTop: '30px' })}`}
      onChange={updateTemplate}
      onValidSubmit={updatePassword}
      onValid={() => setIsValid(true)}
      onInvalid={() => setIsValid(false)}
    >
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <EditNavbar title={t('title')}>
        <div>
          <Button
            kind={Kind.SECONDARY_REVERSE}
            onClick={() => dispatch(replace('/profile'))}
            className="mr-s"
          >
            {t('cancel')}
          </Button>
          <Button kind={Kind.PRIMARY_REVERSE} type="submit" disabled={!isValid}>
            {t('change_password')}
          </Button>
        </div>
      </EditNavbar>
      <div
        className={`${css({
          minHeight: '100%',
        })} grey-05-bkg`}
      >
        <Container>
          <Section className="my-xl py-m px-xs">
            <Col>
              <Text.Header4 className="mb-m mt-0" tag="div">
                {t('change_password')}
              </Text.Header4>
              {!isForceUpdate && (
                <FormsyInput
                  className="mb-m"
                  name="old_password"
                  label={t('old_password')}
                  type="password"
                  value={template?.old_password}
                  validations={{
                    isRequired: true,
                  }}
                  validationErrors={{
                    isRequired: validations('is_required'),
                  }}
                />
              )}
              <FormsyInput
                className="mb-m"
                name="new_password"
                label={t('new_password')}
                type="password"
                value={template?.new_password}
                validations={{
                  isRequired: true,
                  minLength: 6,
                }}
                validationErrors={{
                  isRequired: validations('is_required'),
                  minLength: validations('min_length', { minLength: 6 }),
                }}
              />
              <FormsyInput
                className="mb-m"
                name="confirm_password"
                label={t('new_confirm')}
                type="password"
                value={template?.confirm_password}
                validations={{
                  isRequired: true,
                  equalsField: 'new_password',
                }}
                validationErrors={{
                  isRequired: validations('is_required'),
                  equalsField: t('should_equal'),
                }}
              />
            </Col>
          </Section>
        </Container>
      </div>
    </Formsy>
  )
}

export default ChangePassword
