import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { limitedSeriesValues } from 'scenes/Reports/CaptureRateReport/RevenueByChannel/Chart/helpers'
import TooltipLine from 'scenes/Reports/CaptureRateReport/RevenueByChannel/Chart/TooltipLine'
import { tooltipCurrency } from 'scenes/Reports/helpers'
import { generateUikitTheme } from 'theme'

type ShowT = {
  digital_loyalty: boolean
  instore_loyalty: boolean
}

type PropsT = {
  date?: string
  digital_loyalty?: number
  instore_loyalty?: number
  total_loyalty?: number
  showValues: ShowT
}

const t = buildTranslate('capture_rate.revenue_by_channel.chart')

const Tooltip: React.FC<PropsT> = props => {
  const styletronEngine = new StyletronEngine({ prefix: 'st-pr-tt-' })
  const uikitTheme = generateUikitTheme()
  return (
    <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
      <Text.BodyText4 className="mb-xs" color="grey70">
        {props.date}
      </Text.BodyText4>
      <Text.BodyText1 className="mb-xs" color="grey90" bold tag="p">
        {tooltipCurrency(props.total_loyalty)}
      </Text.BodyText1>
      {limitedSeriesValues.map(series => (
        <TooltipLine
          key={series.key}
          name={t(series.name)}
          value={props[series.name]}
          color={series.color}
          shouldShow={props.showValues[series.name]}
        />
      ))}
    </StyleProvider>
  )
}

export default Tooltip
