import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useCurrentMerchantUser from 'hooks/useCurrentMerchantUser'
import useIsThanxAdmin from 'hooks/useIsThanxAdmin'
import React, { useEffect, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Window {
  pendo: pendo.Pendo
}
declare global {
  interface Window {
    pendo?: pendo.Pendo
  }
}

const Pendo: React.FC = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const merchantUser = useCurrentMerchantUser()
  const isThanxAdmin = useIsThanxAdmin()
  const merchant = useCurrentMerchant()

  // run the pendo script. from: https://developers.pendo.io/docs/?bash#installation
  useEffect(() => {
    ;(function (p, e, n, d) {
      let v, w, x, y, z
      let o = (p[d] = p[d] || {})
      o._q = o._q || []
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push'](
                [m].concat([].slice.call(arguments, 0))
              )
            }
        })(v[w])
      y = e.createElement(n)
      y.async = !0
      y.onload = () => setIsLoaded(true)
      y.src = `https://cdn.pendo.io/agent/static/${process.env.REACT_APP_PENDO_API_KEY}/pendo.js`
      z = e.getElementsByTagName(n)[0]
      if (z) z.parentNode.insertBefore(y, z)
    })(window, document, 'script', 'pendo')
  }, [])

  // initialize pendo with config after we are sure it has loaded
  useEffect(() => {
    if (!isLoaded || !merchant || !merchantUser) {
      return
    }

    if (window.pendo) {
      window.pendo?.initialize({
        visitor: {
          id: `${merchantUser.id}`,
          thanx_admin: isThanxAdmin,
          merchant_id: merchant.id,
          environment: process.env.REACT_APP_ENVIRONMENT || 'local',
        },
        account: {
          id: 'merchant-dashboard',
        },
      })
    }
  }, [isLoaded, merchant, merchantUser, isThanxAdmin])

  return null
}

export default Pendo
