import { Button } from '@thanx/uikit/button'
import { Select } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { GrantableCampaign } from 'api/campaigns'
import Input from 'components/Form/Input'
import RadioButton from 'components/Form/RadioButton'
import Section from 'components/Section'
import Form from 'formsy-react'
import { buildTranslate } from 'locales'
import { useState } from 'react'
import { useAbility } from 'utilities/ability'
import { grantRewardUrl } from 'utilities/urlUtils'
import { UpdateResponsePayload } from './index'

const t = buildTranslate('feedbacks.responses.modal.respond')
const validationT = buildTranslate('validations')

type Props = {
  responseId: number
  responseContent?: string | null
  responderName: string
  grantedRewardDescription?: string | null
  userUid?: string
  userEmail?: string
  grantableCampaigns: GrantableCampaign[]
  updateResponse: (updateResponsePayload: UpdateResponsePayload) => void
}

type OptionsType = {
  label: string
  value: number | null
}

const Respond = (props: Props) => {
  const [css] = useStyletron()
  const {
    responseId,
    responseContent,
    responderName,
    grantedRewardDescription,
    userUid,
    userEmail,
    grantableCampaigns,
    updateResponse,
  } = props

  const ability = useAbility()
  const [formIsValid, setFormIsValid] = useState(false)
  const [newResponse, setNewResponse] = useState('')
  const [showRewardSelector, setShowRewardSelector] = useState(false)
  const [grantCampaignId, setGrantCampaignId] = useState<number | null>(null)
  const [progressType, setProgessType] = useState<'dollar' | 'percentage'>(
    'percentage'
  )
  const [progress, setProgress] = useState(0)
  const canRespond = ability.can('manage', 'Feedback')

  const options: OptionsType[] = grantableCampaigns.map(campaign => ({
    label:
      campaign.type === 'loyalty'
        ? t('grant_reward.progress_toward_loyalty')
        : campaign.name || '',
    value: campaign.id,
  }))
  const value = options.find(option => option.value === grantCampaignId)
  const selectedCampaign = grantableCampaigns.find(
    campaign => campaign.id === grantCampaignId
  )
  const grantProgress = selectedCampaign?.type === 'loyalty'
  const progressOptions = [
    { value: 'percentage', label: '%' },
    { value: 'dollar', label: '$' },
  ]
  const progressValue = progressOptions.find(
    option => option.value === progressType
  )

  const onSubmit = async () => {
    if (!userUid) return

    let payload: UpdateResponsePayload = {
      updatedPayload: {
        id: responseId,
        response: newResponse,
        send_email: true,
      },
    }

    if (selectedCampaign?.type === 'loyalty') {
      payload.updatedPayload.send_email = false
      payload.rewardPayload = {
        user_uid: userUid,
        grant_payload: {
          grant_type: 'progress',
          grant_progress: {
            grant_progress_type: progressType,
            grant_value: progress,
          },
          rating_id: responseId,
        },
      }
    } else if (!!selectedCampaign) {
      payload.updatedPayload.send_email = false
      payload.rewardPayload = {
        user_uid: userUid,
        grant_payload: {
          grant_type: 'reward',
          grant_reward: { program_id: selectedCampaign.id },
          rating_id: responseId,
        },
      }
    }

    await updateResponse(payload)
    return
  }

  return (
    <div className="d-flex flex-column mx-xl">
      {!!responseContent && (
        <div className="mb-l flex-row">
          <Text.Header4 className="mt-0 mb-xs">
            {t('title_with_response', { responderName })}
          </Text.Header4>
          <Section className={'d-flex flex-column mb-xl'}>
            <Text.BodyText3
              className={`p-m grey-05-bkg ${css({
                minHeight: '210px',
              })}`}
            >
              {responseContent}
            </Text.BodyText3>
          </Section>
          <div className="flex-row mb-s">
            <Text.Header4 className="pr-xs m-0 d-inline-flex">
              {t('grant_reward.title')}
            </Text.Header4>
            <Text.BodyText4 className="d-inline-flex" color="grey70">
              {t('grant_reward.optional')}
            </Text.BodyText4>
          </div>
          <Text.BodyText4 className="pb-xs" color="grey70" tag="div">
            {grantedRewardDescription || t('grant_reward.reward_default')}
          </Text.BodyText4>
          {!grantedRewardDescription && (
            <Text.BodyText4
              className={`${css({
                fontSize: '12px !important',
              })}`}
              color="grey70"
              tag="div"
            >
              {t('grant_reward.reward_default_note')}
            </Text.BodyText4>
          )}
        </div>
      )}
      {canRespond && !responseContent && (
        <>
          <Form
            className="mb-l"
            onValid={() => setFormIsValid(true)}
            onInvalid={() => setFormIsValid(false)}
          >
            <div className="mb-xl">
              <Text.Header4 className="mt-0 mb-xs">{t('title')}</Text.Header4>
              <Input
                name="response"
                componentClass={'textarea'}
                value={newResponse}
                onChange={event => setNewResponse(event.currentTarget.value)}
                inputProps={{
                  rows: 10,
                }}
                validations={{ isRequired: true }}
                validationErrors={{ isRequired: validationT('is_required') }}
              />
            </div>
            <div className="d-flex flex-column">
              <div className="flex-row mb-s">
                <Text.Header4 className="pr-xs m-0 d-inline-flex">
                  {t('grant_reward.title')}
                </Text.Header4>
                <Text.BodyText4 className="d-inline-flex" color="grey70">
                  {t('grant_reward.optional')}
                </Text.BodyText4>
              </div>
              <div className="mb-m flex-row">
                <RadioButton
                  className="mr-l font-size-14 grey-70"
                  key={'hide'}
                  title={t('grant_reward.exclude_reward')}
                  onClick={() => setShowRewardSelector(false)}
                  checked={!showRewardSelector}
                />
                <RadioButton
                  className="font-size-14 grey-70"
                  key={'show'}
                  title={t('grant_reward.include_reward')}
                  onClick={() => setShowRewardSelector(true)}
                  checked={showRewardSelector}
                />
              </div>
              <div>
                {showRewardSelector && (
                  <div className="d-flex flex-column">
                    <Text.BodyText3 bold className="mb-xs d-inline-flex">
                      {'Select a reward'}
                    </Text.BodyText3>
                    <div className="mb-xs">
                      <Select
                        options={options}
                        value={value}
                        maxDropdownHeight="200px"
                        onChange={option => setGrantCampaignId(option[0].value)}
                      />
                    </div>
                    <a
                      className="body-text-4 align-self-end"
                      href={grantRewardUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('grant_reward.how_to_add_a_reward')}
                    </a>
                  </div>
                )}
                {grantProgress && (
                  <>
                    <Text.BodyText4 className="mt-m mb-xs" tag={'p'} bold>
                      {t('grant_reward.earn_progress')}
                    </Text.BodyText4>
                    <div className="d-flex">
                      <div
                        className={`mr-l ${css({
                          width: '95px',
                        })}`}
                      >
                        <Select
                          onChange={option => setProgessType(option[0].value)}
                          value={progressValue}
                          options={progressOptions}
                          zIndex={1002}
                          maxDropdownHeight={'200px'}
                        />
                      </div>
                      <div className="w-100">
                        <Input
                          name="grantValue"
                          className={'w-100'}
                          value={progress}
                          onChange={event =>
                            setProgress(Number(event.currentTarget.value))
                          }
                          type="number"
                          validations={{
                            isRequired: true,
                            greaterThan: 0,
                            lessThanOrEqual: !grantProgress ? 100 : Infinity,
                          }}
                          validationErrors={{
                            isRequired: validationT('is_required'),
                            greaterThan: validationT('greater_than', {
                              value: 0,
                            }),
                            lessThanOrEqual: validationT('less_than_or_equal', {
                              value: 100,
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Form>
          <div className="mb-m">
            <Button
              disabled={!formIsValid}
              type="button"
              kind="primary"
              onClick={() => onSubmit()}
            >
              {t('submit')}
            </Button>
          </div>
          <Text.BodyText5 className="mb-xl" color="grey70">
            {t('customer_email', { email: userEmail })}
          </Text.BodyText5>
        </>
      )}
    </div>
  )
}

export default Respond
