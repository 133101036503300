import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { Role } from 'api/merchantUsers'
import { buildTranslate } from 'locales'
import React from 'react'
import RadioButton from './RadioButton'

const t = buildTranslate('merchant_users.form.roles.customer_service')

type Props = {
  role: Role
  visible: boolean
  onChange: (role: Role) => void
}

const CustomerServiceCardDetails = ({ role, visible, onChange }: Props) => {
  return (
    <Switch condition={visible}>
      <RadioButton
        checked={role === Role.customer_service_write}
        value={Role.customer_service_write}
        border={false}
        onChange={value => {
          onChange?.(value as Role)
        }}
      >
        <Text.BodyText4 color="grey70" tag="p">
          {t('write')}
        </Text.BodyText4>
      </RadioButton>
      <RadioButton
        checked={role === Role.customer_service_read}
        value={Role.customer_service_read}
        border={false}
        onChange={value => {
          onChange(value as Role)
        }}
      >
        <Text.BodyText4 color="grey70" tag="p">
          {t('read')}
        </Text.BodyText4>
      </RadioButton>
    </Switch>
  )
}

export default CustomerServiceCardDetails
