import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Container } from 'react-bootstrap-five'

const t = buildTranslate('stored_value')

const StoredValueActivity: React.FC = () => {
  return (
    <Container>
      <Text.Header3>{t('tabs.activity')}</Text.Header3>
    </Container>
  )
}

export default StoredValueActivity
