import { useStyletron } from '@thanx/uikit/theme'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

const t = buildTranslate('looker')
enum Tabs {
  RATINGS = 'ratings',
  REVIEWS = 'reviews',
}

const NpsReport: React.FC = () => {
  const merchant = useCurrentMerchant()
  const [css] = useStyletron()
  const [activeTab, setActiveTab] = useState<'ratings' | 'reviews'>('ratings')

  if (!merchant) return null

  const title = t('urls.dashboards/106.title')
  const ratingsHeight = 1750
  const reviewsHeight = 1450

  return (
    <>
      <Helmet>
        <title>{I18n.t(`titles./reports/feedback#${activeTab}`)}</title>
      </Helmet>
      <ProtectedPageHeader title={title} resource="NpsReports" action="access">
        <Tab.Container
          activeKey={activeTab}
          transition={false}
          onSelect={(tab: string | null) => {
            if (!tab) return
            setActiveTab(tab as Tabs)
          }}
        >
          <TabNav>
            <TabItem
              state={Tabs.RATINGS}
              title={t(`urls.dashboards/106.tabs.ratings`)}
            />
            <TabItem
              state={Tabs.REVIEWS}
              title={t(`urls.dashboards/106.tabs.reviews`)}
            />
          </TabNav>
          <Tab.Content className="pb-l h-100 grey-05-bkg">
            <TabPane state={Tabs.RATINGS}>
              <Container
                className={`px-0 ${css({
                  minHeight: `${ratingsHeight + 300}px`,
                })}`}
                data-testid="tab-ratings"
              >
                <LookerIframe
                  path={'dashboards/106'}
                  merchant={merchant}
                  height={`${ratingsHeight}px`}
                />
                <LookerFooter className="my-xl" />
              </Container>
            </TabPane>
            <TabPane state={Tabs.REVIEWS}>
              <Container
                className={`px-0 ${css({
                  minHeight: `${reviewsHeight + 300}px`,
                })}`}
                data-testid="tab-reviews"
              >
                <LookerIframe
                  path={'dashboards/147'}
                  merchant={merchant}
                  height={`${reviewsHeight}px`}
                />
                <LookerFooter className="my-xl" />
              </Container>
            </TabPane>
          </Tab.Content>
        </Tab.Container>
      </ProtectedPageHeader>
    </>
  )
}

export default NpsReport
