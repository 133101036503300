import { Switch } from '@thanx/uikit/switch'
import { getRedeemTemplate } from 'actions/redeemTemplates'
import PageHeader from 'components/PageHeader'
import Spinner from 'components/Spinner'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import { useAbility } from 'utilities/ability'
import { useUserTimeZone } from 'utilities/userTimeZone'
import ActionsMenu from '../ActionsMenu'
import SendTestModal from '../Modals/SendTestModal'
import RedeemTemplateStatus from '../RedeemTemplateStatus'
import ConfigurationTab from './ConfigurationTab'
import UsageTab from './UsageTab'

enum Tabs {
  Detail = 'detail',
  Usage = 'usage',
}

type Props = RouteComponentProps<{ id: string }>

const t = buildTranslate('redeem_manager.detail')

const Detail: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const { id } = props.match.params
  const templateId = Number(id)
  const template = useSelector(store => selectRedeemTemplate(store, templateId))
  const timeZone = useUserTimeZone()

  const [isLoading, setIsLoading] = useState<boolean>(!template)
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Detail)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const ability = useAbility()
  const disableCreate = ability.cannot('manage', 'RedeemManager')

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await dispatch(getRedeemTemplate(templateId))
      setIsLoading(false)
    }

    if (!template) {
      fetchData()
    }
  }, [template, templateId, dispatch])

  if (isLoading) {
    return (
      <Spinner isLoading size="4x" className="text-center margin-top-huge" />
    )
  }
  if (!template) return null

  const { state, created_at, archived_at } = template
  const menu = disableCreate ? undefined : <ActionsMenu template={template} />
  return (
    <PageHeader
      title={template.name}
      backPath="/rewards"
      description={
        <>
          <div className="d-flex flex-row">
            <p className="mb-0 mr-s grey-70">
              {t('created_on', {
                date: moment.tz(created_at, timeZone).format('MM/DD/YYYY'),
              })}
            </p>
            <RedeemTemplateStatus state={state} />
          </div>
          <Switch condition={!!archived_at}>
            <p className="grey-70">
              {t('archived_on', {
                date: moment.tz(archived_at, timeZone).format('MM/DD/YYYY'),
              })}
            </p>
          </Switch>
        </>
      }
      buttonAction={() => setIsModalOpen(true)}
      buttonText={t('send_a_test')}
      menu={menu}
    >
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <Tab.Container
        activeKey={activeTab}
        transition={false}
        onSelect={(tab: string | null) => {
          if (!tab) return
          setActiveTab(tab as Tabs)
        }}
      >
        <TabNav>
          <TabItem state={Tabs.Detail} title={t('configuration')} />
          <TabItem state={Tabs.Usage} title={t('usage_redemption')} />
        </TabNav>
        <Tab.Content className="h-100 grey-05-bkg">
          <TabPane state={Tabs.Detail}>
            <ConfigurationTab template={template} />
          </TabPane>
          <TabPane state={Tabs.Usage}>
            <UsageTab templateId={templateId} />
          </TabPane>
        </Tab.Content>
      </Tab.Container>
      <SendTestModal
        templateId={template.id}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSuccess={() => setIsModalOpen(false)}
      />
    </PageHeader>
  )
}

export default Detail
