import Locations from 'components/LocationSelector'
import VideoLinkCard from 'components/VideoLinkCard'
import { LocationContextProvider } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import React, { useState } from 'react'
import { captureRateReportUrl } from 'utilities/urlUtils'
import CsvUpload from './CsvUpload'
import Current from './Current'
import ExplainerCard from './ExplainerCard'
import Importer from './Importer'
import MissingRevenueTip from './MissingRevenueTip'
import RevenueByChannel from './RevenueByChannel'

type PropsT = {
  merchant: Merchant
  hasIntegrations?: boolean
}

const t = buildTranslate('capture_rate')

const Content: React.FC<PropsT> = ({ merchant, hasIntegrations = false }) => {
  const [isImporterOpen, setIsImporterOpen] = useState(false)
  const [isImporterLoading, setIsImporterLoading] = useState(false)

  const onImportOpen = () => {
    setIsImporterLoading(true)
    setIsImporterOpen(true)
  }

  return (
    <LocationContextProvider>
      <VideoLinkCard
        title={t('crr_video_card.watch')}
        description={t('crr_video_card.crr_101')}
        linkUrl={captureRateReportUrl}
      />
      <ExplainerCard
        merchant={merchant}
        onImportOpen={onImportOpen}
        isImporterLoading={isImporterLoading}
      />
      <CsvUpload
        onImportOpen={onImportOpen}
        isImporterLoading={isImporterLoading}
        hasIntegrations={hasIntegrations}
      />
      <MissingRevenueTip merchant={merchant} />
      {merchant.merchant_type !== 'Mall' && <Locations />}
      <Current merchant={merchant} />
      <RevenueByChannel merchant={merchant} />
      <Importer
        onReady={() => setIsImporterLoading(false)}
        onRequestClose={() => setIsImporterOpen(false)}
        isOpen={isImporterOpen}
      />
    </LocationContextProvider>
  )
}

export default Content
