import clamp from 'lodash/clamp'
import React from 'react'
import { Table } from 'react-bootstrap'
import BucketRow from './BucketRow'
import type { Direction } from './TrajectoryArrow'

type MetricT = {
  data: any
  header?: string
}

type Props = {
  keySuffix: string
  metrics: Array<MetricT>
  valueFormatter?: (value: number) => string
  includeHeaders?: boolean
  includeSummary?: boolean
  summaryText?: string
  bucketText?: string
  trajectoryCalculator?: (Object, string) => Direction
}

const BucketTable: React.FC<Props> = props => {
  const {
    keySuffix,
    metrics,
    valueFormatter,
    includeHeaders,
    includeSummary,
    summaryText,
    bucketText,
    trajectoryCalculator,
  } = props
  const headerCount = metrics.length

  const headerCols = metrics.map((metric, i) => {
    // Dynamically determine column width by taking the smaller of 15 or percentage of the whole represented
    // by the total number of headers + the data column.
    const width = clamp((1 / (headerCount + 1)) * 100, 15)
    return [
      <th key={`arrow-hr-${i}`} />,
      //@ts-ignore
      <th className="header-cell" key={`bucket-hr-${i}`} width={`${width}%`}>
        {metric.header}
      </th>,
    ]
  })

  const summaryCols = metrics.map((metric, i) => {
    return [
      <td key={`arrow-${i}`} />,
      <td key={`bucket-sr-${i}`} className="bucket-value">
        <span className="trajectory-arrow" />
        {valueFormatter
          ? valueFormatter(metric.data[`total_${keySuffix}`])
          : ''}
      </td>,
    ]
  })

  const bucketDays = metric => {
    return Object.keys(metric)
      .filter(key => key !== `total_${keySuffix}`)
      .map(key => parseInt(key.substr(0, key.indexOf('_'))))
      .sort((a, b) => a - b)
  }

  const metricData = metrics.map(metric => metric.data)

  const bodyRows = bucketDays(metrics[0].data).map((days, r) => {
    return (
      <BucketRow
        key={`bucket-${days}-row-${r}`}
        days={days}
        columnData={metricData}
        keySuffix={keySuffix}
        valueFormatter={valueFormatter}
        trajectoryCalculator={trajectoryCalculator}
      />
    )
  })

  return (
    <Table className="bucket-table" responsive>
      {includeHeaders && (
        <thead>
          <tr>
            <td />
            {headerCols}
          </tr>
        </thead>
      )}
      <tbody>
        {includeSummary && (
          <tr>
            <td>{summaryText}</td>
            {summaryCols}
          </tr>
        )}
        {bucketText && (
          <tr className="border-none">
            <td className="bold">{bucketText}:</td>
          </tr>
        )}
        {metrics[0] && bodyRows}
      </tbody>
    </Table>
  )
}

export default BucketTable
