import { Button, Kind } from '@thanx/uikit/button'
import { Checkbox } from '@thanx/uikit/checkbox'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import PopoverMenu from 'components/PopoverMenu'
import { buildTranslate } from 'locales'
import { Fields as OrderingConfig } from 'models/OrderingConfig'
import React from 'react'
import { Link } from 'react-router-dom'
import { merchantTheme } from 'theme'
import StatusBadge from '../StatusBadge'

const t = buildTranslate('ordering_management')

type PropsT = {
  location: OrderingConfig
  selected?: boolean
  onSelect: (l: OrderingConfig, checked: boolean) => void
  onAction: (l: OrderingConfig, action: string) => void
}

const Row: React.FC<PropsT> = ({
  selected = false,
  location,
  onSelect,
  onAction,
}) => {
  const [css] = useStyletron()
  const cellClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: merchantTheme.colors.grey30,
    paddingLeft: 0,
    paddingRight: 0,
    height: '80px',
  })

  const errorCount = location?.config_errors.length ?? 0
  const status = errorCount ? 'error' : 'success'
  const value =
    status === 'error' ? t('errors', { count: errorCount }) : t('no_errors')
  const orderingStatusLabel = t(`order_status.${location?.ordering_status}`)
  const orderingStatus =
    location?.ordering_status === 'enabled' ? 'success' : 'disabled'

  const optionButtonClass = css({
    backgroundColor: merchantTheme.colors.white,
    border: 'none',
    color: merchantTheme.colors.grey70,
    fontSize: '14px',
    fontWeight: '400',
  })

  const handleAction = () => {
    const action =
      location?.ordering_status === 'enabled' ? 'disable' : 'enable'
    onAction(location, action)
  }

  return (
    <>
      <tr key={location.id} data-testid={`location-row-${location.id}`}>
        <td className={cellClass}>
          <div className="d-flex align-items-center flex-column">
            <Checkbox
              checked={selected}
              disabled={location?.ordering_status === 'not_configured'}
              onChange={c => onSelect(location, c)}
            />
          </div>
        </td>
        <td className={cellClass}>
          <Text.BodyText4 bold tag="div">
            {location.name}
          </Text.BodyText4>
          <Text.BodyText4 tag="div" color="grey70">
            {`${location.street}, ${location.city}, ${location.zip}, ${location.state}`}
          </Text.BodyText4>
        </td>
        <td className={cellClass}>
          <StatusBadge value={value} status={status} />
        </td>
        <td className={cellClass}>
          <StatusBadge value={orderingStatusLabel} status={orderingStatus} />
        </td>
        <td className={cellClass}>
          <Link to={`/ordering_management/${location.id}`}>{t('view')}</Link>
        </td>
        <td className={cellClass}>
          <PopoverMenu
            closeOnClick
            dismissOnClickOutside
            placement="bottomRight"
          >
            <Button
              kind={Kind.MINIMAL}
              className={optionButtonClass}
              disabled={location?.ordering_status === 'not_configured'}
              onClick={handleAction}
            >
              {location?.ordering_status === 'enabled'
                ? t('disable_ordering_at_location')
                : t('enable_ordering_at_location')}
            </Button>
          </PopoverMenu>
        </td>
      </tr>
    </>
  )
}

export default Row
