import { buildTranslate } from 'locales'
import React from 'react'
import PublishBanner from 'scenes/Cms/components/PublishBanner'

const t = buildTranslate('cms.content.banners')

type Props = {
  isLoading: boolean
  disabled: boolean
}

const Footer = ({ isLoading, disabled }: Props) => {
  return (
    <PublishBanner
      buttonText={t('footer.publish')}
      isLoading={isLoading}
      disabled={disabled}
      buttonType={'submit'}
    />
  )
}

export default Footer
