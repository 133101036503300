import { useStyletron } from '@thanx/uikit/theme'
import Cell from 'components/Cell'
import React from 'react'

type Props = {
  label: string
  value?: string | null
}

const Metadata: React.FC<Props> = ({ label, value }) => {
  const [css] = useStyletron()
  return (
    <tr className="border-bottom-1 grey-30-border">
      <td
        className={`body-text-4 py-m px-m grey-90-text bold align-top
        ${css({
          width: '202px',
        })}`}
      >
        {label}
      </td>
      <Cell
        className={`py-m
        ${css({
          whiteSpace: 'pre-line',
        })}`}
      >
        {value ?? '-'}
      </Cell>
    </tr>
  )
}

export default Metadata
