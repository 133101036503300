import { Text } from '@thanx/uikit/text'
import BellSlash from 'components/Icons/BellSlash'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate(
  'cms.content.preferences_modal_setting.existing_order_tip'
)

const ExistingOrderTip: React.FC = () => {
  return (
    <div className="grey-10-bkg d-flex flex-row justify-content-start p-m pr-xxl my-m">
      <BellSlash className="mr-s" />
      <div>
        <Text.BodyText4 tag="div" color="grey70">
          {t('body')}
        </Text.BodyText4>
      </div>
    </div>
  )
}

export default ExistingOrderTip
