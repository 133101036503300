import { buildTranslate } from 'locales'
import React from 'react'
import PublishBanner from 'scenes/Cms/components/PublishBanner'

const t = buildTranslate('cms.content.app_home_page')

type Props = {
  isLoading: boolean
  disabled: boolean
  onPublish: VoidFunction
  type: 'content' | 'style'
}

const Footer = ({ isLoading, disabled, onPublish, type }: Props) => {
  const buttonType = type === 'content' ? 'button' : 'submit'
  return (
    <PublishBanner
      buttonText={t('footer.publish')}
      isLoading={isLoading}
      disabled={disabled}
      buttonType={buttonType}
      onClick={onPublish}
    />
  )
}

export default Footer
