import { storedValueApi } from 'api/storedValue'
import Loading from 'components/Loading'
import PageHeader from 'components/PageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import EmptyState from './EmptyState'
import StoredValueActivity from './StoredValueActivity'
import StoredValueConfiguration from './StoredValueConfiguration'

const t = buildTranslate('stored_value')

type TabName = 'configuration' | 'activity'

const StoredValue = () => {
  const storedValueEnabled = useFlag('stored-value-enabled', false)

  const merchant = useCurrentMerchant()
  const [activeTab, setActiveTab] = useState<TabName>('configuration')

  const { data, isLoading } = storedValueApi.useGetConfigQuery(undefined, {
    skip: !storedValueEnabled || !merchant,
  })

  if (!storedValueEnabled || !merchant) return null

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <PageHeader title={t('title')}>
        <Tab.Container
          activeKey={activeTab}
          transition={false}
          onSelect={(tab: string | null) => {
            if (!tab) return
            setActiveTab(tab as TabName)
          }}
        >
          <TabNav>
            <TabItem state={'configuration'} title={t('tabs.configuration')} />
            <TabItem
              state={'activity'}
              disabled={true}
              title={t('tabs.activity')}
            />
          </TabNav>
          <Tab.Content className="h-100 grey-05-bkg">
            {isLoading && <Loading />}

            {!isLoading && data?.state === 'inactive' && <EmptyState />}
            {!isLoading && data?.state === 'active' && (
              <>
                <TabPane state={'configuration'}>
                  <StoredValueConfiguration config={data} />
                </TabPane>
                <TabPane state={'activity'}>
                  <StoredValueActivity />
                </TabPane>
              </>
            )}
          </Tab.Content>
        </Tab.Container>
      </PageHeader>
    </>
  )
}

export default StoredValue
