import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import { RedemptionProvider as RedemptionProviderType } from 'models/RedemptionProvider'
import { InstoreRedemptionType } from 'scenes/RedeemManager/types'
import { CouponPoolDisplayType } from '../helpers'
import CouponCodesDisplay from './CouponCodesDisplay'

type Props = {
  redemptionProvider: RedemptionProviderType
  redemptionType: InstoreRedemptionType
  template?: RedeemTemplate
  couponCodeDisplay: CouponPoolDisplayType
  instoreDiscountedProviderProductIds?: string[]
  instoreRequiredProviderProductIds?: string[]
  editButton?: () => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card'
)

function ProviderInstoreRedemptionDisplay(props: Props) {
  const {
    redemptionProvider,
    redemptionType,
    template,
    couponCodeDisplay,
    instoreDiscountedProviderProductIds,
    instoreRequiredProviderProductIds,
    editButton,
  } = props

  const { couponCode, formattedCouponPools } = couponCodeDisplay

  const discountedIdsForPos =
    instoreDiscountedProviderProductIds ??
    (template?.product_ids?.[redemptionProvider.value]?.instore?.discounted ||
      [])
  const requiredIdsForPos =
    instoreRequiredProviderProductIds ??
    (template?.product_ids?.[redemptionProvider.value]?.instore?.required || [])

  return (
    <div
      className="d-flex flex-column p-4 mb-4"
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '4px',
        border: '1px solid #D6DADE',
      }}
    >
      <div className="d-flex align-items-start justify-content-between mb-4">
        <div className="d-flex flex-column">
          <Text.BodyText3 bold>{t('multi_pos.pos')}</Text.BodyText3>
          <Text.BodyText3 color="grey70">
            {redemptionProvider.label}
          </Text.BodyText3>
        </div>

        {!!editButton && editButton()}
      </div>

      <div className="d-flex flex-column">
        <Text.BodyText3 bold>{t('multi_pos.configuration')}</Text.BodyText3>
        {redemptionType === InstoreRedemptionType.MANAGER_COMP && (
          <>
            <Text.BodyText3 color="grey70">
              {t('type.manager_comp')}
            </Text.BodyText3>
            <Text.BodyText3 color="grey70">
              {t('type.manager_comp_info')}
            </Text.BodyText3>
          </>
        )}
        {redemptionType === InstoreRedemptionType.COUPON_CODES && (
          <>
            <CouponCodesDisplay
              redemptionProvider={redemptionProvider}
              couponCodes={couponCode}
              selectedCouponPools={formattedCouponPools}
            />
            <Switch condition={!!discountedIdsForPos.length}>
              <Text.BodyText3 className="mt-s" bold>
                {t('multi_pos.discounted_ids_for_pos')}
              </Text.BodyText3>
              <Text.BodyText3 color="grey70">
                {discountedIdsForPos.join(',')}
              </Text.BodyText3>
            </Switch>
            <Switch condition={!!requiredIdsForPos.length}>
              <Text.BodyText3 className="mt-s" bold>
                {t('multi_pos.required_ids_for_pos')}
              </Text.BodyText3>
              <Text.BodyText3 color="grey70">
                {requiredIdsForPos.join(',')}
              </Text.BodyText3>
            </Switch>
          </>
        )}
        {redemptionType === InstoreRedemptionType.CASH_BACK && (
          <>
            <Text.BodyText3 className="mb-4" color="grey70">
              {t('type.automatic_cash_back')}
            </Text.BodyText3>
          </>
        )}
      </div>
    </div>
  )
}

export default ProviderInstoreRedemptionDisplay
