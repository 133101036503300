import ImportPreview from 'components/CampaignEmailPreview/ImportPreview'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import compact from 'lodash/compact'
import type { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import type { Fields as NotifyEmail } from 'models/NotifyEmail'
import React from 'react'
import {
  useCopyEmail,
  useNotifyEmailVariantMeta,
} from 'scenes/CampaignCenter/Builder/hooks'

type Props = {
  show: boolean
  onBack: VoidFunction
  onClose: VoidFunction
  campaign: Campaign
  config: VariantOrCampaign
  notifyEmail: NotifyEmail | null
}

const t = buildTranslate('thanx_campaigns.builder.forms.messaging_tabs')

const PreviewNotifyEmailModal: React.FC<Props> = ({
  show,
  onBack,
  onClose,
  campaign,
  config,
  notifyEmail,
}) => {
  const { copyEmail } = useCopyEmail(config, campaign.id)
  const { getEmailVariantMeta } = useNotifyEmailVariantMeta()

  if (!notifyEmail) return null

  const subtitle = (() => {
    if (notifyEmail.type === 'campaign') {
      return notifyEmail.campaign_name
    }

    const variantMeta = getEmailVariantMeta(notifyEmail)
    const variantName = variantMeta ? `Variant ${variantMeta.name}` : null

    return compact([notifyEmail.campaign_name, variantName]).join(' - ')
  })()

  function onProceed() {
    copyEmail(notifyEmail)
    onClose()
  }

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      title={t('preview_notify_email.title')}
      subtitle={subtitle}
      proceedText={t('preview_notify_email.copy')}
      width={'760px'}
      onBack={onBack}
      onProceed={onProceed}
      showTopProceed
      zIndex={1001}
    >
      <div className={'mt-l'}>
        <ImportPreview
          campaign={campaign}
          notifyEmail={notifyEmail}
          showDescription={false}
        />
      </div>
    </Modal>
  )
}

export default PreviewNotifyEmailModal
