import calendarSpearmint from 'assets/images/campaign-center/opportunity/icons/calendar_spearmint.png'
import customerSpendIcon from 'assets/images/campaign-center/opportunity/icons/customer_spend.png'
import customerSpendYellow from 'assets/images/campaign-center/opportunity/icons/customer_spend_yellow.png'
import purchaseIcon from 'assets/images/campaign-center/opportunity/icons/purchase.png'
import rewardIcon from 'assets/images/campaign-center/opportunity/icons/reward.png'
import withMerchantMetrics, {
  MetricsProps,
} from 'components/metrics/withMerchantMetrics'
import { buildTranslate } from 'locales'
import React from 'react'
import MetricSection from '../components/MetricSection'
import Churn from '../components/Shared/Churn'
import Header from '../components/Shared/Header'
import HowItWorks from '../components/Shared/HowItWorks'
import Setup from '../components/Shared/Setup'
import Solution from '../components/Shared/Solution'
import SolutionStep from '../components/Shared/SolutionStep'
import UseCase from '../components/Shared/UseCase'

const t = buildTranslate('thanx_campaigns.opportunity.vip')

export const Vip: React.FC<MetricsProps> = props => {
  const {
    handleClick,
    merchantMetrics,
    merchantMetricStatuses,
    isCampaignTypeLoading,
    campaignType,
  } = props
  const vipCount = merchantMetrics?.vip_count?.data?.active_count || 0

  return (
    <div>
      <Header campaignType="vip" />
      <UseCase campaignType="vip" />
      <Churn campaignType="vip" />
      <MetricSection
        value={vipCount}
        description={t('metric')}
        isLoading={merchantMetricStatuses?.vip_count.isLoading}
        className="spearmint-10-bkg"
      />
      <Solution campaignType="vip">
        <SolutionStep
          icon={purchaseIcon}
          iconAlt={t('solution.steps.image_one_alt')}
          text={t('solution.steps.one')}
        />
        <SolutionStep
          icon={customerSpendYellow}
          iconAlt={t('solution.steps.image_two')}
          text={t('solution.steps.two')}
        />
        <SolutionStep
          icon={rewardIcon}
          iconAlt={t('solution.steps.image_three_alt')}
          text={t('solution.steps.three')}
        />
        <SolutionStep
          icon={calendarSpearmint}
          iconAlt={t('solution.steps.image_four_alt')}
          text={t('solution.steps.four')}
        />
        <SolutionStep
          icon={customerSpendIcon}
          iconAlt={t('solution.steps.image_five_alt')}
          text={t('solution.steps.five')}
        />
      </Solution>
      <hr className="width-75" />
      <HowItWorks campaignType="vip" />
      <Setup
        type="vip"
        isLoading={isCampaignTypeLoading}
        handleClick={handleClick}
        campaignType={campaignType}
      />
    </div>
  )
}

export default withMerchantMetrics(['vip_count'])(Vip)
