import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { Fields as TargetSegment } from 'models/TargetSegment'
import React from 'react'
import { Col, Row } from 'react-bootstrap-five'
import CampaignSection from '../CampaignSection'
import LogoBadge from '../CampaignSection/LogoBadge'

type Props = {
  onClick: (value: number) => void
  segments: TargetSegment[]
  cols?: number
}

const Badge = () => {
  const merchant = useCurrentMerchant()
  return <LogoBadge url={merchant?.logo_image_urls?.small ?? ''} />
}

const Cards: React.FC<Props> = ({ onClick, segments, cols = 3 }) => {
  return (
    <Row md={cols}>
      {segments.map(targetSegment => (
        <Col key={targetSegment.id} className="p-s">
          <CampaignSection.Card
            key={targetSegment.id}
            title={targetSegment.name}
            badge={<Badge />}
            onClick={() => onClick(targetSegment.id)}
          />
        </Col>
      ))}
    </Row>
  )
}

export default Cards
