import React from 'react'
import { Col, Row } from 'react-bootstrap'

type Props = {
  title: string
}

const SectionHeader: React.FC<Props> = ({ title }) => {
  return (
    <div className="container padding-bottom-medium margin-bottom-huge">
      <Row>
        <Col md={6}>
          <h4 className="deprecated__h4 margin-top-huge padding-top-medium">
            {title}
          </h4>
        </Col>
      </Row>
    </div>
  )
}

export default SectionHeader
