import { Fields } from 'models/CouponPool'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

type GetAllAction = AxiosAction<'merchantCouponPool/GET_ALL'>
export type GetAllResolvedAction =
  | FailureAction<'merchantCouponPool/GET_ALL_FAIL'>
  | SuccessAction<
      'merchantCouponPool/GET_ALL_SUCCESS',
      { coupon_pools: Fields[] },
      GetAllAction
    >
export function getMerchantCouponPools(generationType?: string): GetAllAction {
  return {
    type: 'merchantCouponPool/GET_ALL',
    payload: {
      client: 'default',
      request: {
        url: !generationType
          ? 'coupon_pools'
          : `coupon_pools?generation_type=${generationType}`,
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = GetAllResolvedAction
