import { RESET_DB } from 'actions/orm'
import { GET_SMS_TRIGGERS_SUCCESS, ResolvedAction } from 'actions/smsTriggers'
import { Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  merchant_id: number
  location_id: number
  location_name: string
  keyword: string
}

// @ts-ignore
export default class SmsTrigger extends Model<typeof SmsTrigger, Fields> {
  static modelName: string = 'SmsTrigger'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    SmsTrigger: ModelType<SmsTrigger>
  ) {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case GET_SMS_TRIGGERS_SUCCESS:
        action.payload.data.sms_triggers.forEach(smsTrigger => {
          SmsTrigger.upsert(smsTrigger)
        })
        break
      case RESET_DB:
        SmsTrigger.all().delete()
        break
      default:
        break
    }
  }
}
