import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}

const Import: React.FC<PropsT> = ({
  className = '',
  fillColor = 'currentColor',
}) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5312 3.0625L12.9062 0.46875C12.625 0.1875 12.25 0 11.875 0H5.46875C4.65625 0.03125 4 0.6875 4 1.53125V8.28125H0.375C0.15625 8.28125 0 8.4375 0 8.65625V9.40625C0 9.59375 0.15625 9.78125 0.375 9.78125H9V11.2812C9 11.625 9.375 11.7812 9.625 11.5625L11.875 9.3125C12.0312 9.15625 12.0312 8.90625 11.875 8.75L9.625 6.46875C9.375 6.25 9 6.40625 9 6.75V8.28125H5.46875V1.53125H10.4688V4.78125C10.4688 5.1875 10.8125 5.53125 11.2188 5.53125H14.5V14.5312H5.46875V11.2812H4V14.5312C4 15.3438 4.65625 16 5.46875 16H14.4688C15.3125 16 16 15.3438 16 14.5312V4.125C16 3.75 15.8125 3.34375 15.5312 3.0625ZM11.9688 4.03125V1.625L14.375 4.03125H11.9688Z"
      fill={fillColor}
    />
  </svg>
)

export default Import
