import { useStyletron } from '@thanx/uikit/theme'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React from 'react'
import { ActivitySummaryData } from 'scenes/Points/Landing/Activity/utilities'
import { getFormattedDate } from 'utilities/date'
import { numberFormatter } from 'utilities/formatters'
import BodyCell from './BodyCell'

type PropsT = {
  activitySummaryData: ActivitySummaryData
  dateTime: string
  granularity: DateRangeGranularity
  timeZone: string
  isErrored: boolean
  isLoading: boolean
  showExpiredPoints: boolean
}

const t = buildTranslate('points.activity.summary_table.tooltips')

const Row: React.FC<PropsT> = props => {
  const {
    activitySummaryData,
    dateTime,
    granularity,
    timeZone,
    isLoading,
    isErrored,
    showExpiredPoints,
  } = props
  const { outstandingAtStart, outstandingAtEnd, issued, redeemed, expired } =
    activitySummaryData

  const [css] = useStyletron()
  const borderClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: palette.grey30,
  })

  const displayDateTime = getFormattedDate(
    moment.tz(dateTime, timeZone),
    granularity
  )

  return (
    <tr>
      <BodyCell
        className={borderClass}
        isErrored={isErrored}
        isLoading={isLoading}
        value={displayDateTime}
      />
      <BodyCell
        className={`${borderClass} text-right`}
        isErrored={isErrored}
        isLoading={isLoading}
        value={numberFormatter(outstandingAtStart)}
        sections={[{ content: t('outstandingAtStart.desc1') }]}
      />
      <BodyCell
        className={`${borderClass} text-right`}
        isErrored={isErrored}
        isLoading={isLoading}
        value={numberFormatter(issued)}
        sections={[
          { content: t('issued.desc1') },
          { title: t('howCalculated'), content: t('issued.desc2') },
          { title: t('example'), content: t('issued.desc3') },
        ]}
      />
      <BodyCell
        className={`${borderClass} text-right`}
        isErrored={isErrored}
        isLoading={isLoading}
        value={numberFormatter(redeemed)}
        sections={[
          { content: t('redeemed.desc1') },
          { title: t('howCalculated'), content: t('redeemed.desc2') },
          { title: t('example'), content: t('redeemed.desc3') },
        ]}
      />
      {showExpiredPoints && (
        <BodyCell
          className={`${borderClass} text-right`}
          isErrored={isErrored}
          isLoading={isLoading}
          value={numberFormatter(expired)}
          sections={[
            { content: t('expired.desc1') },
            { title: t('example'), content: t('expired.desc2') },
          ]}
        />
      )}
      <BodyCell
        className={`${borderClass} text-right`}
        isErrored={isErrored}
        isLoading={isLoading}
        value={numberFormatter(outstandingAtEnd)}
        sections={[
          { content: t('outstandingAtEnd.desc1') },
          { title: t('example'), content: t('outstandingAtEnd.desc2') },
        ]}
      />
    </tr>
  )
}

export default Row
