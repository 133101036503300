import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import DateFilter from 'components/DateFilter'
import { DateRangeFilterContext } from 'components/DateFilter/DateFilterContext'
import FetchableMetricSection from 'components/FetchableMetricSection'
import SearchablePaginatedContent from 'components/SearchablePaginatedContent'
import usePaginateArray from 'hooks/usePaginateArray'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import {
  FirstPurchasesMetric,
  LocationPurchaseData,
  ShortType,
} from 'models/PurchasesMetric'
import React, { useContext, useState } from 'react'
import { Container } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import NotReady from 'scenes/Reports/components/NotReady'
import Table from 'scenes/Reports/components/Table'
import { selectPurchasesMetricByType } from 'selectors/purchasesMetric'
import Counter from './Counter'
import Row from './Row'

const t = buildTranslate('purchases.tabs.first_purchases')

type PropsT = {
  merchant: Merchant
}

const FirstPurchasesTab: React.FC<PropsT> = ({ merchant }) => {
  const { filter, bounds } = useContext(DateRangeFilterContext)
  const purchaseMetrics = useSelector(state =>
    selectPurchasesMetricByType(state, merchant?.id, ShortType.FIRST_PURCHASES)
  ) as FirstPurchasesMetric
  const [searchValue, setSearchValue] = useState('')

  const {
    isErrored,
    isLoading,
    total_purchases_count,
    values = [],
  } = purchaseMetrics ?? {}
  const unfilteredLocations = values
  const showEmptyState = !merchant?.live_at

  const searchIncludesLocation = (row: LocationPurchaseData) => {
    const { location_name, location_address } = row
    const searchableParams = [location_name, location_address]
    const searchString = searchableParams.join('').toLowerCase()

    return searchString.includes(searchValue.trim().toLowerCase())
  }

  const locations = searchValue
    ? unfilteredLocations.filter(searchIncludesLocation)
    : unfilteredLocations
  const {
    currentPage,
    setPage,
    paginatedData: paginatedValues,
    totalPages,
  } = usePaginateArray(locations, 10)

  const handleSearchInputChange = (value: string) => {
    setSearchValue(value)
    setPage(1) // Reset pagination when search changes
  }

  return (
    <>
      <Container>
        <Switch
          condition={!showEmptyState}
          fallback={
            <NotReady
              className="mt-xl"
              title={t('purchases.tabs.first_purchases.title')}
            />
          }
        >
          <FetchableMetricSection
            type={'purchases_metric'}
            shouldFetch
            metricTypes={[ShortType.FIRST_PURCHASES]}
            merchantId={merchant.id}
            dateRangeFilter={filter}
          >
            <DateFilter
              showDateLabel
              showCustomFilter
              isLoading={isLoading}
              allTimeStartDate={bounds.startDate}
              allTimeEndDate={bounds.endDate}
            />
            <Text.Header3 className="mb-xl mt-xl" tag="div">
              {t('title')}
            </Text.Header3>
            <div className="d-flex">
              <Counter
                isLoading={isLoading}
                isErrored={isErrored}
                count={total_purchases_count}
              />
            </div>

            <SearchablePaginatedContent
              contentIsLoading={isLoading}
              onChangePage={page => setPage(page)}
              onChangeSearchInput={handleSearchInputChange}
              currentPage={currentPage}
              numberOfPages={totalPages}
              searchPlaceholder={t('search_placeholder')}
            >
              <Table
                values={paginatedValues}
                isLoading={isLoading}
                isErrored={isErrored}
                headers={['location_name', 'purchases_count']}
                headerClassNames={['w-75']}
                t={buildTranslate('purchases.tabs.first_purchases.table')}
                showRowToggle={false}
                initialSortAttribute={'purchases_count'}
              >
                {Row}
              </Table>
            </SearchablePaginatedContent>
          </FetchableMetricSection>
        </Switch>
      </Container>
    </>
  )
}

export default FirstPurchasesTab
