import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { asyncWithLDProvider, useLDClient } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'

type Props = {
  children: React.ReactNode
}

/**
 * Non-functional dummy provider used if we encounter an exception during
 * initialization
 */
const DummyProvider: React.FC = ({ children }) => {
  return <>{children}</>
}

/**
 * Determines the LaunchDarkly user object from the application state
 * Returns null when the app is still initialising so we can wait and avoid
 * state changing immediately after loading, which can present as a "flicker"
 */
const UseLaunchDarklyUser = () => {
  const merchant = useCurrentMerchant()
  const user = useSelector(selectCurrentMerchantUser)
  if (merchant && user) {
    // Logged in
    return { key: `merchant-${merchant!.id}` }
  } else if (!user) {
    // Logged out
    return { key: `merchant-logged-out` }
  }
  // App is still initialising
  return null
}

/**
 * Updates the LaunchDarkly user if the user changes after initialization.
 * For example: when a logged out user logs in. This is a separate component
 * as useLDClient is only accessible in children of the FlagsProvider
 */
const UserUpdater: React.FC<Props> = ({ children }) => {
  const ldClient = useLDClient()
  const launchDarklyUser = UseLaunchDarklyUser()

  useEffect(() => {
    const identifyUser = async () => {
      if (ldClient && launchDarklyUser) {
        const currentUserKey = ldClient.getUser()?.key
        if (launchDarklyUser.key !== currentUserKey) {
          // Only update if the user has changed, to prevent "jitter" between
          // states
          try {
            await ldClient.identify(launchDarklyUser)
          } catch (e) {
            if (process.env.NODE_ENV !== 'test') {
              /* eslint-disable-next-line no-console */
              console.error('Could not update LaunchDarkly', e)
            }
          }
        }
      }
    }
    identifyUser()
  }, [ldClient, launchDarklyUser])
  return <>{children}</>
}

/**
 * Initializes LaunchDarkly, only once. Subsequent changes to the user
 * information just use the client to "identify" the user
 *
 * I'm not super happy with this imnplementation as it seems overly
 * complicated, but it satisfies the following use cases:
 *
 * 1. Whether logged in or logged out, we avoid a flicker on loading due to
 *    initialization. This can be tested by enabling a flag that should affect
 *    the rendering and reloading
 * 2. If an exception occurs during initialization, we still successfully render
 *    the application, with no feature flipping functionality. This can be
 *    tested by raising an exception within the try block
 * 3. Starting logged in and moving to logged out appropriately changes the flag
 *    state. This can be tested by enabling a flag for a specific user, logging
 *    out, reloading the app, logging in, and ensuring that whatever was gated
 *    by the flag is appropriately enabled
 * 4. We don't see any unexpected flickering between states when changing the
 *    flag, tested by enabling and disabling flags while viewing the app.
 * 5. Avoids creating any new warnings or errors in the console.
 * 6. Integrations tests pass even when LaunchDarkly is not configured. This is
 *    important as integration tests shouldn't be interacting with LaunchDarkly
 *    flipper state.
 *
 * As such, I feel this code is robust, which compensates for the complexity,
 * however, I'd love to see a cleaner implementation that handles the above
 * edge cases. Using the Javascript SDK instead of the React SDK may give us
 * more power here.
 */
const FlagsProvider: React.FC<Props> = ({ children }) => {
  const launchDarklyUser = UseLaunchDarklyUser()
  const [LDProvider, setLDProvider] = useState<null | any>(null)

  useEffect(() => {
    const initializeLaunchDarklyProvider = async user => {
      try {
        const Provider = await asyncWithLDProvider({
          clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID!,
          reactOptions: { useCamelCaseFlagKeys: false },
          options: { bootstrap: 'localStorage' },
          user: user,
        })
        setLDProvider(() => Provider)
      } catch (e) {
        // If an exception is thrown during initialization we should render
        // with a "dummy provider", so the page still loads albeit with
        // default/null flags
        if (process.env.NODE_ENV !== 'test') {
          /* eslint-disable-next-line no-console */
          console.error('Could not initalize LaunchDarkly', e)
        }
        setLDProvider(() => DummyProvider)
      }
    }

    if (LDProvider) {
      // We don't want to re-initialize the Launch Darkly provider if it
      // already has been initialized
      return
    }
    if (!launchDarklyUser) {
      // We don't want to initialize the Launch Darkly provider if the rest of
      // the app is still initializing
      return
    }
    initializeLaunchDarklyProvider(launchDarklyUser)
  }, [launchDarklyUser, LDProvider])

  if (!LDProvider) {
    // We want to avoid rendering anything until LD is initalized to avoid any
    // "flickering" caused by flag values changing upon initialization
    return null
  }

  return (
    <LDProvider>
      <UserUpdater>{children}</UserUpdater>
    </LDProvider>
  )
}

export default FlagsProvider
