import { Switch } from '@thanx/uikit/switch'
import CampaignEmailPreview from 'components/CampaignEmailPreview'
import EmailSubjectAndPreview from 'components/EmailSubjectAndPreview'
import { Fields as Campaign } from 'models/Campaign'
import React, { useState } from 'react'
import { Button, Tab } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import MessageTab from 'scenes/CampaignCenter/Builder/components/MessageTab'
import EmailPreview from 'scenes/CampaignCenter/Builder/components/Previews/Email'
import PushPreview from 'scenes/CampaignCenter/Builder/components/Previews/Push'
import SmsPreview from 'scenes/CampaignCenter/Builder/components/Previews/Sms'
import { useVariant } from 'scenes/CampaignCenter/Builder/components/VariantContext'
import SendTestModal from 'scenes/CampaignCenter/Builder/Steps/Shared/Messaging/components/SendTestModal'
import { selectCampaignTypeByType } from 'selectors/campaignType'

type Props = {
  campaign: Campaign
  hideTest?: boolean
}

const MessageContent: React.FC<Props> = ({ campaign, hideTest = false }) => {
  const variant = useVariant()
  const { config } = useCampaignConfig()
  const configVariant = variant || config
  const {
    notify_email_subject,
    notify_email_preview_text,
    redeem,
    notify_email_configuration,
  } = configVariant
  const [isModalOpen, setIsModalOpen] = useState(false)
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  if (!campaignType) return null

  const unlayer_enabled = !!campaignType.unlayer_enabled

  return (
    <>
      <MessageTab
        emailOnly={!redeem}
        className="border-bottom-1 grey-20-border margin-bottom-huge"
        navItems={
          <Switch condition={unlayer_enabled && !hideTest}>
            <Tab.Content animation={false}>
              <Tab.Pane eventKey="email">
                <div>
                  <Button
                    bsStyle="link"
                    className="font-size-16"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <i className="fa fa-external-link margin-right-small" />
                    <Translate value="thanx_campaigns.builder.forms.messaging_tabs.send_test.title" />
                  </Button>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Switch>
        }
      >
        <Tab.Content animation={false}>
          <Tab.Pane eventKey="email">
            <Switch
              condition={!!notify_email_configuration}
              fallback={
                <div className="email-preview-container">
                  <EmailPreview campaign={campaign} />
                </div>
              }
            >
              <>
                <div className="margin-bottom-huge">
                  <EmailSubjectAndPreview
                    subject={notify_email_subject}
                    previewText={notify_email_preview_text}
                  />
                </div>
                <CampaignEmailPreview
                  campaign={campaign}
                  config={configVariant}
                  navStyle="radio"
                />
              </>
            </Switch>
          </Tab.Pane>
          <Tab.Pane eventKey="push">
            <PushPreview text={configVariant.notify_push_text} />
          </Tab.Pane>
          <Tab.Pane eventKey="sms">
            <SmsPreview config={configVariant} />
          </Tab.Pane>
        </Tab.Content>
      </MessageTab>
      <Switch condition={unlayer_enabled}>
        <SendTestModal
          campaign={campaign}
          show={isModalOpen}
          onModalClose={() => setIsModalOpen(false)}
        />
      </Switch>
    </>
  )
}

export default MessageContent
