import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import ContentContainer from './ContentContainer'

export type Variants = {
  primaryContent: JSX.Element
  secondaryContent?: JSX.Element
  isErrored?: boolean
  isLoading?: boolean
}

type Props = {
  title: string
  subtitle?: string
  variants: Variants[]
}
const InfoRow: React.FC<Props> = props => {
  const { title, subtitle, variants } = props
  const [css] = useStyletron()

  return (
    <div className="p-0">
      <div className="d-flex justify-content-between">
        <Text.BodyText4
          bold
          color="grey70"
          className={`${css({
            borderBottom: `1px solid ${defaultTheme.colors.grey30}`,
            minWidth: '220px',
          })} flex-1 p-m`}
        >
          {title}
          <Switch condition={!!subtitle}>
            <Text.BodyText5 className="ml-xs" color="inherit">
              {subtitle}
            </Text.BodyText5>
          </Switch>
        </Text.BodyText4>
        {variants.map((variant, i) => (
          <ContentContainer
            key={`info-variant-${i}`}
            isErrored={variant.isErrored || false}
            isLoading={variant.isLoading || false}
          >
            <div
              className={`p-m
                d-flex flex-column align-items-end
                col-2
                  ${css({
                    borderLeft: `1px solid ${defaultTheme.colors.grey30}`,
                    borderBottom: `1px solid ${defaultTheme.colors.grey30}`,
                    margin: '0 0 0 auto',
                  })}
              `}
            >
              {variant.primaryContent}
              <div>{variant.secondaryContent}</div>
            </div>
          </ContentContainer>
        ))}
      </div>
    </div>
  )
}

export default InfoRow
