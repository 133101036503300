import { buildTranslate } from 'locales'
import React from 'react'
import { OutstandingLiabilityDateRecord } from 'scenes/Points/Landing/Activity/utilities'
import { getFormattedDate } from 'utilities/date'
import Row from './Row'

type Props = {
  start: OutstandingLiabilityDateRecord | null
  end: OutstandingLiabilityDateRecord | null
  changeInOutstandingPoints: number
  isErrored: boolean
  isLoading: boolean
}

const t = buildTranslate('points.activity.outstanding_points.table')

const OutstandingTable: React.FC<Props> = props => {
  const { start, end, changeInOutstandingPoints, isErrored, isLoading } = props

  const formattedStartDate = start?.date_time
    ? getFormattedDate(start.date_time.subtract(1, 'day'), 'daily')
    : ''
  const formattedEndDate = end?.date_time
    ? getFormattedDate(end.date_time, 'daily')
    : ''

  return (
    <div className="mt-s">
      <table className="w-100">
        <tbody>
          <Row
            label={t('outstanding_at', { date: formattedStartDate })}
            pointsValue={start?.amount ?? 0}
            isLoading={isLoading}
            isErrored={isErrored}
            sections={[{ content: t('outstanding_start_popover') }]}
          />
          <Row
            label={t('outstanding_at', { date: formattedEndDate })}
            pointsValue={end?.amount ?? 0}
            isLoading={isLoading}
            isErrored={isErrored}
            sections={[{ content: t('outstanding_end_popover') }]}
          />
          <Row
            label={t('outstanding_between', {
              from: formattedStartDate,
              to: formattedEndDate,
            })}
            pointsValue={changeInOutstandingPoints}
            showArrow
            isLoading={isLoading}
            isErrored={isErrored}
            sections={[{ content: t('outstanding_change_popover') }]}
          />
        </tbody>
      </table>
    </div>
  )
}

export default OutstandingTable
