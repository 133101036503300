import { api } from 'api'
import orm from 'models/orm'
import { i18nReducer } from 'react-redux-i18n'
import { RouteComponentProps } from 'react-router-dom'
import { routerReducer } from 'react-router-redux'
import { combineReducers } from 'redux'
import { createReducer } from 'redux-orm'
import auth from './auth'
import flash from './flash'
import redirect from './redirect'

export default combineReducers({
  routing: routerReducer as () => RouteComponentProps,
  flash,
  auth,
  orm: createReducer(orm),
  i18n: i18nReducer,
  redirect,
  api: api.reducer,
})
