import orm from 'models/orm'
import { Fields } from 'models/UserReward'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectUsersRewards = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.UserReward.all().toRefArray()
  }
)

export const selectUserRewards = createSelector(
  (state: any) => selectUsersRewards(state),
  (_: any, id: number): number => id,
  (usersRewards: any, id: number): Fields[] => {
    return usersRewards.filter(reward => reward.user_id === id)
  }
)
