import { Button } from '@thanx/uikit/button'
import { Checkbox } from '@thanx/uikit/checkbox'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React, { useContext, useEffect } from 'react'
import { AdvancedOptionsContext } from './AdvancedOptionsContext'

const t = buildTranslate('points.activity.activity_chart.advanced_options')

type Props = {
  hasImportedData: boolean
}

const AdvancedOptions: React.FC<Props> = props => {
  const { options, setOptions } = useContext(AdvancedOptionsContext)
  const { hasImportedData } = props
  const [css] = useStyletron()

  useEffect(() => {
    setOptions({
      visible: hasImportedData,
      hideImportedPoints: hasImportedData,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasImportedData])

  const toggleShowAdvancedOptions = () => {
    // cannot be modified if checkbox is checked
    if (options.hideImportedPoints) {
      return
    }
    setOptions({ visible: !options.visible })
  }

  const toggleHideImportedPoints = () => {
    setOptions({ hideImportedPoints: !options.hideImportedPoints })
  }

  return (
    <div className={'px-xl'}>
      <Button
        kind={'minimal'}
        size={'small'}
        className="border-0 pt-0 font-size-14 font-weight-normal pl-0"
        onClick={toggleShowAdvancedOptions}
      >
        <Text.BodyText4
          color={`${options.hideImportedPoints ? 'grey70' : 'belizeHole50'}`}
        >
          {t('title')}
          <i
            className={`fa ${
              options.visible ? 'fa-caret-up' : 'fa-caret-down'
            } btn-link font-size-14 padding-left-tiny ${
              options.hideImportedPoints ? 'grey-70' : 'belize-hole-50'
            }`}
          />
        </Text.BodyText4>
      </Button>
      {options.visible && (
        <div>
          <Checkbox
            checked={options.hideImportedPoints}
            onChange={toggleHideImportedPoints}
          >
            <Text.BodyText4 color={palette.grey70}>
              {t('hide_import_label')}
            </Text.BodyText4>
          </Checkbox>
          <div className={`${css({ marginLeft: '40px' })}`}>
            <Text.BodyText5 color={palette.grey70}>
              {t('hide_import_description')}
            </Text.BodyText5>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdvancedOptions
