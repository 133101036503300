import { buildTranslate } from 'locales'
import React from 'react'
import { useAbility } from 'utilities/ability'
import NavLink from '../Bar/NavLink'

const t = buildTranslate('nav.feedback_menu')

type PropsT = {
  onClick?: () => void
  isLeftNav?: boolean
}

const FeedbackContent: React.FC<PropsT> = ({
  isLeftNav = false,
  onClick = () => {},
}) => {
  const ability = useAbility()

  return (
    <>
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        text={t('respond')}
        link="/responses"
        disabled={ability.cannot('access', 'Feedback')}
      />
      <NavLink
        isLeftNav={isLeftNav}
        onClick={onClick}
        link="/reports/feedback"
        text={t('overview')}
        disabled={ability.cannot('access', 'NpsReports')}
      />
    </>
  )
}

export default FeedbackContent
