import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Email from 'components/Icons/Email'
import Variant from 'components/Icons/Variants'
import ReviewSection from 'components/review/ReviewSection'
import Formsy from 'formsy-react'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import EditableName from 'scenes/CampaignCenter/Builder/components/EditableName'
import Context from 'scenes/CampaignCenter/Builder/components/VariantContext'
import CampaignStatus from 'scenes/CampaignCenter/components/CampaignStatus'
import { selectCampaignVariant } from 'selectors/campaignVariant'
import LegacyMessageSection from './LegacyMessageSection'
import MessageSections from './MessageSections'
import RewardSection from './RewardSection'

const t = buildTranslate('thanx_campaigns.report.v3.configuration')

type Props = {
  campaign: Campaign
  isEditable: boolean
  letter: string
  name: string
  title: string
  subtitle?: string
  variantId: number | undefined
}

const Section: React.FC<Props> = props => {
  const { campaign, isEditable, letter, name, subtitle, title, variantId } =
    props

  const allowNoRewardMsg = useFlag('mx-allow-no-reward-messages', false)

  const variant = useSelector(state => selectCampaignVariant(state, variantId))
  const isControlVariant = variant?.internal_type === 'control'
  const showVariantStatus = !!variantId && campaign.type === 'custom_automated'
  const status =
    campaign.winning_variant_id && campaign.winning_variant_id !== variantId
      ? 'complete'
      : campaign.state

  const getIcon = () => {
    let variantIcon
    variantIcon =
      variant && campaign.variants.length > 1 ? (
        <Variant
          isControl={isControlVariant}
          paddingLeft="9px"
          variantLetter={letter}
        />
      ) : (
        <Email />
      )

    return variantIcon
  }

  const editableTitle =
    isEditable && !isControlVariant ? (
      <Formsy>
        <EditableName
          campaignId={campaign.id}
          className={showVariantStatus ? 'pr-xxxl' : ''}
          variantId={variantId}
          name={name}
          type={variant?.campaign_config_type}
          showEdit
          showPlaceholder
          styleSize="small"
        />
      </Formsy>
    ) : (
      <Text.Header4 className="mt-xxs" tag="div">
        {isControlVariant ? t('control_group') : title}
      </Text.Header4>
    )
  const [css] = useStyletron()

  return (
    <ReviewSection className="position-relative mb-m" icon={getIcon()}>
      <Context.Provider
        value={variant || (campaign as unknown as VariantOrCampaign)}
      >
        <div className="mb-m">
          {editableTitle}
          {subtitle && (
            <Text.BodyText4 className="mt-xs" color="grey70">
              {subtitle}
            </Text.BodyText4>
          )}
        </div>
        <RewardSection
          className="mb-m"
          campaign={campaign}
          isControlVariant={isControlVariant}
        />
        {allowNoRewardMsg ? (
          <MessageSections campaign={campaign} config={variant || campaign} />
        ) : (
          <LegacyMessageSection
            className="mb-m"
            campaign={campaign}
            isControlVariant={isControlVariant}
          />
        )}
        <Switch condition={isControlVariant}>
          <Text.BodyText4 className="mt-xs" color="grey70" tag="p">
            {t('control_group_description')}
          </Text.BodyText4>
          <Text.BodyText4>
            <Link
              className="font-size-14"
              to={{
                pathname:
                  'https://help.thanx.com/en/articles/5650795-how-to-a-b-test-an-automated-campaign',
              }}
              target="_blank"
            >
              {t('learn_more')}
            </Link>
          </Text.BodyText4>
        </Switch>
      </Context.Provider>
      <Switch condition={showVariantStatus}>
        <CampaignStatus
          className={`right-0 position-absolute ${css({ top: '8px' })}`}
          state={status}
        />
      </Switch>
    </ReviewSection>
  )
}

export default Section
