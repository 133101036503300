import { getCampaignMetricByType } from 'actions/campaignMetrics'
import { updateCampaign } from 'actions/campaigns'
import { alert } from 'actions/flash'
import { getLocations } from 'actions/location'
import useDispatch from 'hooks/useDispatch'
import type { Fields as Campaign } from 'models/Campaign'
import { Type as MetricType, UserCountMetric } from 'models/CampaignMetric'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { selectLocation } from 'selectors/location'
import { selectCurrentMerchantLocations } from 'selectors/location'
import View from './View'

type Props = {
  campaign: Campaign
}

const Audience: React.FC<Props> = ({ campaign }) => {
  const [isLoadingLocations, setIsLoadingLocations] = useState(true)
  const [isLoadingUserCount, setIsLoadingUserCount] = useState(true)
  const dispatch = useDispatch()
  const configLocationId = campaign?.config_location_id
  const campaignId = campaign?.id
  const userCountMetric = useSelector(
    state =>
      selectCampaignMetricByType(
        state,
        campaign.id,
        MetricType.USER_COUNT
      ) as UserCountMetric | null
  )
  const locations = useSelector(selectCurrentMerchantLocations)
  const selectedLocation = useSelector(state =>
    selectLocation(state, campaign.config_location_id)
  )

  useEffect(() => {
    async function fetchLocations() {
      const response = await dispatch(getLocations({ coming_soon: false }))
      setIsLoadingLocations(false)
      if (response.error) {
        const error = response.error?.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      } else {
        const locations = response.payload?.data?.locations
        if (!configLocationId && locations && locations.length > 0) {
          dispatch(
            updateCampaign(campaignId, {
              campaign: {
                config_location_id: locations[0].id,
              },
            })
          )
        }
      }
    }

    fetchLocations()
  }, [configLocationId, campaignId, dispatch])

  useEffect(() => {
    async function fetchUserCount() {
      const response = await dispatch(
        getCampaignMetricByType(campaignId, 'user_count')
      )
      setIsLoadingUserCount(false)

      if (response.error) {
        const error = response.error?.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }
    }
    // Refresh the user counts if the location changed.
    if (campaignId) {
      fetchUserCount()
    }
  }, [configLocationId, campaignId, dispatch])

  const isLoading = isLoadingLocations || isLoadingUserCount
  return (
    <View
      isLoading={isLoading}
      locations={locations}
      selectedLocation={selectedLocation}
      userCount={userCountMetric?.data?.count || 0}
    />
  )
}

export default Audience
