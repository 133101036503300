import offerIcon from 'assets/images/campaign-center/ic_present.png'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { textUtils } from 'utilities/textUtils'

type Props = {
  redeemDescription: string
}

const AppReward: React.FC<Props> = ({ redeemDescription }) => {
  return (
    <div className="border-bottom-1 grey-30-border padding-medium">
      <Row>
        <Col xs={2}>
          <img src={offerIcon} alt="offer" className="reward-icon" />
        </Col>
        <Col xs={6} className="font-size-15 padding-right-none">
          <Translate
            value="thanx_campaigns.builder.previews.incentive.tiny_header"
            className="small-caps-7 wisteria"
            tag="p"
          />
          <p className="line-height-17 margin-bottom-micro">
            {textUtils.initialCapital(redeemDescription)}
          </p>
        </Col>
        <Col xs={4} className="text-right font-size-10">
          <Translate
            value="thanx_campaigns.builder.previews.incentive.redeem_btn"
            className="wisteria border-2 widteria-border border-radius-10 inline-block padding-small extra-bold padding-top-micro padding-bottom-micro"
          />
        </Col>
      </Row>
    </div>
  )
}

export default AppReward
