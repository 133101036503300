import { I18n } from 'react-redux-i18n'
import activationRate from './activationRate'
import auth from './auth'
import billing from './billing'
import campaigns from './campaigns'
import captureRate from './captureRate'
import cms from './cms'
import common from './common'
import components from './components'
import dateFormat from './dateFormat'
import dateRangeFilter from './dateRangeFilter'
import defaults from './defaults'
import emails from './emails'
import errors from './errors'
import feedbacks from './feedbacks'
import glossary from './glossary'
import home from './home'
import integrationCenter from './integrationCenter'
import legal from './legal'
import lifecycleReport from './lifecycleReport'
import locations from './locations'
import looker from './looker'
import loyalty from './loyalty'
import merchantUsers from './merchantUsers'
import nav from './nav'
import orderingManagement from './orderingManagement'
import points from './points'
import privacy from './privacy'
import profile from './profile'
import purchases from './purchases'
import redeemManager from './redeemManager'
import reports from './reports'
import retentionRate from './retentionRate'
import revenue from './revenue'
import segments from './segments'
import sharedWords from './sharedWords'
import storedValue from './storedValue'
import targeting from './targeting'
import terms from './terms'
import thanxCampaigns from './thanxCampaigns'
import titles from './titles'
import users from './users'
import userSupport from './userSupport'
import validations from './validations'

export const translations = {
  en: {
    general: {
      learn_more: 'Learn more',
      buttons: {
        back: 'Back',
        create: 'Create',
        prev: 'Prev',
        next: 'Next',
      },
    },
    programs: {
      campaigns: campaigns,
      confirm: {
        send_now:
          'Your %{program_type} has been set up and will begin sending out rewards within an hour.',
        schedule: 'Your %{program_type} has been scheduled for %{start_at}.',
        pending:
          'Your %{program_type} needs just a little more magic from our merchant success team before it can go out to your customers. We’ll get back to you as soon as possible so that you can reward your customers.',
        state: {
          active: 'Done',
          merchant_approved: 'In Review',
        },
      },
      forms: {
        offers: {
          create_title: 'Create your %{program_name}',
          edit_title: 'Review and schedule your %{program_name}',
          footer: {
            title: '%{verb} your offer',
            subtitle:
              'Once you’ve finalized your offer, you can choose to go ahead and <strong>%{verb}</strong> it. Or if you’re still not sure, you can <strong>request a review</strong> and our team will check it for you.',
          },
          scheduling: {
            end_at: 'Offer end date',
            start_at: 'Offer start date',
            send_now_info:
              'Send your offer out <strong>immediately</strong>. We’ll notify customers that it is available within an hour and will be redeemable until the end date you select.',
            schedule_info:
              'Schedule your offer for a future date. We’ll notify customers that it is available on the start date and will be redeemable until the end date you select.',
            user_count_warning:
              'Your target audience is recalculated when your offer is sent on the start date. This means if you schedule an offer very far into the future your targeted audience numbers may change slightly.',
          },
          buttons: {
            request_review: 'Request review',
            send_now: 'Send now',
            schedule: 'Schedule',
            create_draft: 'Review and %{verb}',
          },
          tip_title: 'Quick tip',
          notifications: {
            title: 'Tell your customers about it',
            email: {
              label: 'Email (Optional)',
              placeholder: 'Come in this week for a limited time treat!',
              modalButtonText: 'preview email',
            },
            push: {
              label: 'Enable mobile push notifications',
              default:
                'You have a new reward from %{merchant}! Just activate it in the app when you’re ready to use it.',
            },
            quick_tip:
              'Tell customers that they’ve got a reward by sending an email or in-app message. If you <strong>don’t</strong> want to do this, simply leave these fields blank.',
          },
          incentive: {
            item: {
              placeholder: 'Free item when you visit next',
              help: 'This is what customers will see when they log in to the app',
            },
            quick_tip:
              'Create a brand new offer or select and customize one of your previous offers to get started.',
          },
          targeting: {
            title: 'Who do you want to target?',
            quick_tip:
              'Use segments to target the right group of people. Your offer will be sent to ',
            behavior: 'Customer behavior',
            behavior_subtitle: 'Based on the past 90 days',
            validation_warning: 'At least one group must be selected',
          },
          validation: {
            end_at: {
              before_start_at:
                '%{program_name} end date must be scheduled after the start date.',
            },
            start_at: {
              day_in_advance:
                '%{program_name} must be scheduled at least 1 day in advance.',
              after_end_at:
                '%{program_name} start date must be scheduled before the end date.',
            },
          },
          redemption_restrictions: {
            quick_tip: {
              text: 'Use day/time restrictions to drive business during off-peak hours.',
              value:
                'Your offer is currently valid on <strong>%{days}</strong> between <strong>%{start_time}</strong> and <strong>%{end_time}</strong>',
            },
          },
          naming: {
            title: 'Name your offer',
            quick_tip:
              'This info will only be visible to you for reporting on the performance of your offers.',
          },
          advanced: {
            show: 'Show advanced options',
            hide: 'Hide advanced options',
            control_group: {
              title: 'Use a control group',
              subtitle: 'Set aside 10% of recipients as a control group.',
            },
            coming_soon: {
              title: 'Coming soon!',
              subtitle: 'This feature is coming and will be available shortly.',
            },
          },
        },
      },
    },
    cms: cms,
    common,
    emails: emails,
    errors: errors,
    validations: validations,
    targeting: targeting,
    thanx_campaigns: thanxCampaigns,
    redeem_manager: redeemManager,
    defaults: defaults,
    nav: nav,
    glossary: glossary,
    loyalty: loyalty,
    reports: reports,
    lifecycle_report: lifecycleReport,
    components,
    points,
    dateRangeFilter,
    dateFormat,
    auth,
    profile,
    billing,
    home,
    titles,
    legal,
    locations,
    looker,
    ordering_management: orderingManagement,
    old_navbar: {
      beta: 'beta',
      logout: 'Logout',
      thanx_admin: 'Thanx Admin',
      return_old_dashboard: 'Return to Merchant Dashboard',
    },
    page_header: {
      back: 'Back',
      learn_more: 'Learn more',
    },
    capture_rate: captureRate,
    revenue: revenue,
    activation_rate: activationRate,
    retention_rate: retentionRate,
    purchases: purchases,
    integration_center: integrationCenter,
    users,
    segments,
    shared_words: sharedWords,
    stored_value: storedValue,
    feedbacks,
    privacy,
    terms,
    user_support: userSupport,
    merchant_users: merchantUsers,
  },
}

export function buildTranslate(prefix: string) {
  return (key: string, replacements?: Record<string, any>) =>
    I18n.t(`${prefix}.${key}`, replacements)
}
