import { Text } from '@thanx/uikit/text'
import LockCircled from 'assets/images/billing/img_lock-circled.svg'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('looker')

const LookerError: React.FC = () => {
  return (
    <div className="text-center">
      <div className="mb-m">
        <img src={LockCircled} alt="lock" width="32" />
      </div>
      <Text.Header4 bold color="grey90">
        {t('forbidden')}
      </Text.Header4>
    </div>
  )
}

export default LookerError
