// @flow
import React, { Component } from 'react'
// $FlowFixMe
import { alert } from 'actions/flash'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { getActiveProvider, getProviderLists } from 'actions/emailProviders'
import { updateProviderListsBulk } from 'actions/emailProviderLists'
import { selectActiveProviderByType } from 'selectors/emailProvider'
import { selectEmailProviderListsByProviderId } from 'selectors/emailProviderList'
import ImportSettings from './ImportSettings'
import Spinner from 'components/Spinner'
import { Col } from 'react-bootstrap'

import type { EmailProviderListT } from 'models/EmailProviderList'
import type { ReduxAxiosPromise } from 'utilities/types'

type Props = {
  providerType: string,

  alert: Function,
  getActiveProvider: string => ReduxAxiosPromise<*>,
  getProviderLists: number => ReduxAxiosPromise<*>,
  updateProviderListsBulk: (Array<any>) => ReduxAxiosPromise<*>,

  activeProvider: any,
  lists: Array<EmailProviderListT>,

  onSuccess: () => void,
}
type State = {
  isLoaded: boolean,
  isSubmitting: boolean,
}
type FormModel = {
  listIds: Array<string>,
}

export class ImportSettingsContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isLoaded: false,
      isSubmitting: false,
    }
  }

  async componentDidMount() {
    let action = await this.props.getActiveProvider(this.props.providerType)

    if (action.error) {
      this.props.alert({
        key: 'danger',
        message: I18n.t(
          'emails.subscribers.setup.settings.load_lists_error_message'
        ),
      })
      return
    }

    const providerId = action.payload.data.email_provider.id
    action = await this.props.getProviderLists(providerId)

    if (action.error) {
      this.props.alert({
        key: 'danger',
        message: I18n.t(
          'emails.subscribers.setup.settings.load_lists_error_message'
        ),
      })
      return
    }

    this.setState({
      isLoaded: true,
    })
  }

  selectedLists(): string[] {
    return this.props.lists
      .filter(list => list.state === 'enabled')
      .map(list => list.uid)
  }

  onSubmit(model: FormModel) {
    this.setState({
      isSubmitting: true,
    })
    const listsToEnable = this.props.lists.filter(list => {
      return model.listIds.includes(String(list.id))
    })
    const params = listsToEnable.map(list => {
      return {
        id: list.id,
        state: 'pending',
      }
    })
    this.props.updateProviderListsBulk(params).then(action => {
      if (action.error) {
        this.props.alert({
          key: 'danger',
          message: I18n.t(
            'emails.subscribers.setup.settings.submit_error_message'
          ),
        })

        this.setState({
          isSubmitting: false,
        })
        return
      }

      this.setState({
        isSubmitting: false,
      })

      this.props.onSuccess()
    })
  }

  render() {
    if (!this.state.isLoaded) {
      return (
        <Col sm={12}>
          <div className="text-center">
            <Spinner isLoading={true} size="5x" />
          </div>
        </Col>
      )
    }

    return (
      <ImportSettings
        isSubmitting={this.state.isSubmitting}
        selectedUids={this.selectedLists()}
        lists={this.props.lists}
        onSubmit={this.onSubmit.bind(this)}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const activeProvider = selectActiveProviderByType(
    state.orm,
    props.providerType
  )
  const providerId = activeProvider && activeProvider.id
  return {
    activeProvider,
    lists: selectEmailProviderListsByProviderId(state.orm, providerId),
  }
}

const mapDispatchToProps = {
  alert,
  getActiveProvider,
  getProviderLists,
  updateProviderListsBulk,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImportSettingsContainer)
