import { Radio } from '@thanx/uikit/radio'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import ThanxDatePicker from 'components/Form/DatePicker'
import Input from 'components/Form/Input'
import { Fields as Campaign } from 'models/Campaign'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import userTimeZone from 'utilities/userTimeZone'
import { mapEnd } from './RestrictionStartEnd'

type PropsT = {
  campaign: Campaign
  className?: string
  t: (value: string) => string
  rollingTimeInput?: React.ReactNode
  notRollingName?: string
}

const LengthTemplate: React.FC<PropsT> = props => {
  const {
    campaign,
    className = '',
    notRollingName = 'redeem_retire_after_days',
    rollingTimeInput,
    t,
  } = props

  const [isRollingTime, setIsRollingTime] = useState(!campaign.redeem_end_at)
  const [css] = useStyletron()
  const rollingTime = rollingTimeInput || (
    <Input
      className="inline-block col-xs-2 px-s"
      disabled={campaign.type === 'message'}
      name="redeem_retire_after_days"
      value={campaign.redeem_retire_after_days}
      placeholder="14"
      trimValue={true}
      inputProps={{
        className: `grey-70 ${css({
          fontSize: '16px !important',
        })}`,
        name: 'redeem_retire_after_days',
      }}
      validations={{
        greaterThan: 0,
        isNumeric: true,
        isRequired: isRollingTime,
      }}
      validationErrors={{
        greaterThan: I18n.t('validations.greater_than', {
          value: 0,
        }),
        isNumeric: I18n.t('validations.is_numeric'),
        isRequired: I18n.t('validations.is_required'),
      }}
    />
  )
  return (
    <>
      <div
        className={`border-1 grey-30-border border-radius-5 p-m ${className}`}
      >
        <div className="font-size-18 bold mb-m">{t('title')}</div>
        <div className="d-flex flex-column grey-70">
          <Radio
            className="mb-0"
            overrides={{
              Root: {
                style: {
                  width: '50%',
                  fontSize: '32pt',
                },
              },
            }}
            onClick={() => setIsRollingTime(true)}
            value={'rollingTime'}
            checked={isRollingTime}
          >
            <div className="font-size-16">{t('rolling_time_window.label')}</div>
          </Radio>
          {isRollingTime && (
            <div className="ml-l font-size-14 d-flex align-items-center">
              <span>{t('rolling_time_window.info')}</span>
              {rollingTime}
              <span>{t('rolling_time_window.info2')}</span>
            </div>
          )}
          <Radio
            className="mt-m"
            onClick={() => setIsRollingTime(false)}
            value={'endAt'}
            checked={!isRollingTime}
          >
            <div className="font-size-16">{t('specific_date.label')}</div>
          </Radio>
          {!isRollingTime && (
            <div className="ml-l font-size-14 d-flex align-items-center">
              <span className="mr-s">{t('specific_date.info')}</span>
              <ThanxDatePicker
                name={'redeem_end_at'}
                value={campaign.redeem_end_at}
                minDate={moment().tz(userTimeZone()).toISOString()}
                mapValue={mapEnd}
                validations={{
                  isRequired: !isRollingTime,
                }}
                validationErrors={{
                  isRequired: I18n.t('validations.is_required'),
                }}
              />
            </div>
          )}
        </div>
      </div>
      {/* Hack to achieve mutex fields; sets the value to null when the other is opened */}
      <Switch condition={isRollingTime}>
        <Input type="hidden" name="redeem_end_at" value={null} />
      </Switch>
      <Switch condition={!isRollingTime}>
        <Input type="hidden" name={notRollingName} value={null} />
      </Switch>
    </>
  )
}

export default LengthTemplate
