import { Switch } from '@thanx/uikit/switch'
import includes from 'lodash/includes'
import React from 'react'
import { Col, Row } from 'react-bootstrap-five'

type Props = {
  imageCard?: any
  cardsPerRow?: number
  lockImageCardHeight?: boolean
  children: React.ReactNode[]
}

/**
 * Layout component for the campaign cards
 */
const CampaignSectionCards: React.FC<Props> = props => {
  const {
    imageCard,
    cardsPerRow = 4,
    lockImageCardHeight = false,
    children: campaignCards,
  } = props

  const bsCardWidth = 12 / cardsPerRow
  const numCards = campaignCards.length
  let imageCardHeight = 1
  // never have more than 1 empty box in every row
  if (numCards > 2 && !lockImageCardHeight) {
    imageCardHeight = 2
    if (includes([1, 2], numCards % 4)) imageCardHeight = 1
  }
  const numCardsInImageRow = imageCard ? imageCardHeight * 2 : 0
  const imageRowCards = campaignCards.slice(0, numCardsInImageRow)
  const otherCards = campaignCards.slice(numCardsInImageRow)

  // TODO: The bootstrap 4 components don't use flex because the styles collide
  // with the bootstrap 3 css.
  return (
    <div>
      <Switch condition={!!imageCard}>
        <Row>
          <Col md={6}>{imageCard}</Col>
          <Col
            md={6}
            className="display-flex flex-wrap align-items-stretch p-0"
          >
            {imageRowCards.map((card, i) => {
              return (
                <Col key={i} md={bsCardWidth * 2} className="p-s">
                  {card}
                </Col>
              )
            })}
          </Col>
        </Row>
      </Switch>
      <Row>
        {otherCards.map((card, i) => {
          return (
            <Col key={i} md={bsCardWidth} className="p-s">
              {card}
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default CampaignSectionCards
