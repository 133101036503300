import {
  LandingPageTheme,
  LandingPageThemeUpdatePayload,
  MenuCategoryStyles,
  Settings,
} from 'api/landingPageTheme'
import { FormModel } from './hooks'

export const getDefaultMenuCategoryStyles = (
  primaryColor: string
): MenuCategoryStyles => ({
  background_color: '#FFFFFF',
  header_text_color: '#36424E',
  body_text_color: '#6A747F',
  link_color: primaryColor,
  product_card: {
    background_color: '#FFFFFF',
    header_text_color: '#4D4D4F',
    body_text_color: '#9FA1A4',
    border_color: '#DEDFE0',
    border_width: 1,
    border_radius: 2,
  },
  unavailable_product_card: {
    background_color: '#F3F4F4',
    header_text_color: '#BCBEC0',
    body_text_color: '#808285',
    border_color: '#DEDFE0',
  },
})

type GetSubmitPayloadT = {
  formModel: FormModel
  landingPageTheme: LandingPageTheme | null
  defaultSettings: Settings
}

export const getSubmitPayload = ({
  formModel,
  landingPageTheme,
  defaultSettings,
}: GetSubmitPayloadT): LandingPageThemeUpdatePayload['landing_page_theme'] => {
  const existingContentBlocks = (landingPageTheme?.content_blocks ?? []).map(
    ({ id, position, type, settings }) => ({
      id,
      position,
      type,
      settings,
    })
  )

  const themeStyles = !!landingPageTheme
    ? landingPageTheme.settings
    : defaultSettings

  const bannerStyles =
    !!landingPageTheme &&
    landingPageTheme.settings.location_banner.background_color
      ? {
          location_banner: landingPageTheme.settings.location_banner,
          order_placed_banner: landingPageTheme.settings.order_placed_banner,
          error_banner: landingPageTheme.settings.error_banner,
        }
      : {
          location_banner: defaultSettings.location_banner,
          order_placed_banner: defaultSettings.order_placed_banner,
          error_banner: defaultSettings.error_banner,
        }

  return {
    id: landingPageTheme?.id,
    content_blocks: existingContentBlocks,
    settings: {
      ...themeStyles,
      ...bannerStyles,
      menu_category_page: formModel,
    },
  }
}
