import CircleChart from 'components/graph/CircleChart'
import React from 'react'

type Props = {
  fraction: number
  title: React.ReactNode
  subtitle: React.ReactNode
  color: string
}

const PercentMetric: React.FC<Props> = props => {
  const { fraction, title, subtitle, color } = props
  const content = () => (
    <div className="text-center">
      <div className="bold font-size-64">{title}</div>
      <div className="deprecated__body-text-3" style={{ color: color }}>
        {subtitle}
      </div>
    </div>
  )

  return (
    <CircleChart
      radius={125}
      fraction={fraction}
      content={content()}
      strokeWidth={12}
      strokeColor={color}
    />
  )
}

export default PercentMetric
