import { FormsyInput } from '@thanx/uikit/input'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Input from 'components/Form/Input'
import PopoverContent, { Sections } from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useFormModel } from '../../FormModel'
import Card from '../Card'
import NextButton from '../NextButton'
import Optional from './Optional'

type Props = {
  description: string
  example: string
  isCurrent: boolean
  isValidForm: boolean
  longDescription: string
  onNext: () => void
  isDisabled?: boolean
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.description_card'
)

const MIN_DESCRIPTION_LENGTH = 5
const MAX_DESCRIPTION_LENGTH = 47

const DescriptionCard: React.FC<Props> = ({
  description,
  example,
  isCurrent,
  isValidForm,
  longDescription,
  onNext,
  isDisabled = false,
}) => {
  const { typeItem } = useFormModel().model
  const [showOptionalDescription, setShowOptionalDescription] = useState(
    !!longDescription
  )
  const startEnhancerLabel = typeItem?.types?.includes('manual/item')
    ? t('get_a_an')
    : t('get')
  const sections: Sections = [
    {
      content: t('label_info'),
    },
  ]
  const [css] = useStyletron()
  if (!isEmpty(example)) {
    sections.push({
      title: t('example'),
      content: example,
    })
  }

  return (
    <Card title={t('title')} isOpen>
      <FormsyInput
        // @ts-ignore
        label={
          <>
            <Text.BodyText4 bold className="mr-xs">
              {t('label')}
            </Text.BodyText4>
            <PopoverContent sections={sections} />
          </>
        }
        disabled={isDisabled}
        name="description"
        startEnhancer={startEnhancerLabel}
        value={description}
        validations={{
          isRequired: true,
          minLength: MIN_DESCRIPTION_LENGTH,
          maxLength: MAX_DESCRIPTION_LENGTH,
        }}
        validationErrors={{
          isRequired: t('required_error'),
          minLength: t('min_length_error', {
            minLength: MIN_DESCRIPTION_LENGTH,
          }),
          maxLength: t('max_length_error', {
            maxLength: MAX_DESCRIPTION_LENGTH,
          }),
        }}
      />
      <p className="mt-xs body-text-4 grey-70">{t('not_editable')}</p>
      <Button
        bsStyle="link"
        className={`p-0 mt-m text-left ${css({
          width: 'fit-content',
          ':focus': {
            textDecoration: 'none',
          },
        })}`}
        onClick={() => setShowOptionalDescription(!showOptionalDescription)}
      >
        {t(showOptionalDescription ? 'remove' : 'add')}
      </Button>
      <Switch condition={showOptionalDescription}>
        <Optional longDescription={longDescription} />
      </Switch>
      <Switch condition={!showOptionalDescription}>
        <Input type="hidden" name="longDescription" value={null} />
      </Switch>
      <Switch condition={isCurrent}>
        <NextButton disabled={!isValidForm} onClick={onNext} />
      </Switch>
    </Card>
  )
}

export default DescriptionCard
