import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { generateUikitTheme } from 'theme'
import { seriesValues } from './helpers'
import TooltipLine from './TooltipLine'

type ShowT = {
  digital_loyalty: boolean
  digital_unknown: boolean
  instore_loyalty: boolean
  instore_unknown: boolean
  third_party: boolean
}

type PropsT = {
  date?: string
  digital_loyalty?: number
  digital_unknown?: number
  instore_loyalty?: number
  instore_unknown?: number
  third_party?: number
  showValues: ShowT
}

const t = buildTranslate('capture_rate.revenue_by_channel.chart')

const Tooltip: React.FC<PropsT> = props => {
  const styletronEngine = new StyletronEngine({ prefix: 'st-rc-tt-' })
  const uikitTheme = generateUikitTheme()

  return (
    <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
      <Text.BodyText4 className="mb-xs" color="grey70">
        {props.date}
      </Text.BodyText4>
      {seriesValues
        .map(series => (
          <TooltipLine
            key={series.key}
            name={t(series.name)}
            value={props[series.name]}
            color={series.color}
            shouldShow={props.showValues[series.name]}
          />
        ))
        .reverse()}
    </StyleProvider>
  )
}

export default Tooltip
