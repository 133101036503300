const susieCakes = {
  title: 'SusieCakes LOYALTY PROGRAM TERMS',
  welcome:
    'Welcome to SusieCakes loyalty rewards program (our "<b>Loyalty Program</b>") sponsored by <b>SusieCakes, LLC</b> ("<b>MERCHANT</b>")!  You can access and participate in our Loyalty Program via the service and its related website located at <a href="https://www.thanx.com/susiecakes">https://www.thanx.com/susiecakes</a> (the "<b>Site</b>") and application for mobile devices (the "<b>App</b>"). These SusieCakes Loyalty Program Terms ("<b>Terms</b>") are an agreement between you and SusieCakes.  Please read these Terms carefully because they apply to your participation in our Loyalty Program and to your access and use of the Service.  To make these Terms easier to read, the service, Site and App are collectively called the "<b>Service.</b>"',
  service:
    'The Service is owned and operated on our behalf by Thanx Inc. ("<b>Thanx</b>"), a third-party service provider and business partner of SusieCakes. Thanx is a third-party beneficiary of these Terms and, when you agree to these Terms, both SusieCakes and Thanx will have the right to enforce these Terms against you, separately or jointly. In its capacity as our service provider, Thanx also collects information from users of the Service on our behalf. Please refer to the Thanx Privacy Policy located <a href="https://dashboard.thanx.com/privacy">here</a> for information on how it collects, uses and discloses information from users of the Service.',
  accessing:
    'By accessing or using the Service or participating in our Loyalty Program, you agree to be bound by these Terms. Certain Opportunities (defined below) part of our Loyalty Program may be governed by additional terms and conditions which are incorporated herein by reference. If there is a conflict between these Terms and the terms and conditions for a particular Opportunity, these Terms will apply.',
  eligibility: {
    title: 'Eligibility',
    order: 'In order to participate in our Loyalty Program, you must:',
    individual: 'Be an individual person at least 18 years of age;',
    possess:
      'Possess the authority and capacity to create a binding legal obligation in your state and/or country of residence; and',
    not: 'Not be a person barred from participating in the Loyalty Program under the laws of the United States or other applicable jurisdiction. The Loyalty Program is VOID WHERE PROHIBITED.',
  },
  creating: {
    title: 'Creating an Account',
    in: 'In order to participate in our Loyalty Program, you will have to create an account.',
    you: 'You may only create one account and it is non-transferable. When you create an account, you agree to provide accurate, current and complete information about yourself. You are responsible for safeguarding your account and are responsible for activities that occur under your account. Notify us immediately if your password is lost, stolen, or disclosed to an unauthorized third party, or otherwise may have been compromised.',
  },
  participating: {
    title: 'Participating in our Loyalty Program',
    our: 'Our Loyalty Program provides a personalized reward experience for consumers and includes loyalty rewards and offers, discounts, experiences, targeted promotions and general information (collectively, "<b>Opportunities</b>") via email, mobile device notifications and other channels. You can link one or multiple payment methods to enable automatic accrual and, in some cases, automatic redemption of rewards. We will try to deliver you the most relevant Opportunities to you.',
    to: 'To participate in our Loyalty Program via the Service, you must (1) link one or multiple payment cards with your account ("Linked Cards") and (2) make eligible purchases (as described in the "<b>Important Notes About Your Cards and Transactions</b>" section below) with your Linked Cards to earn progress and rewards (collectively, "<b>Qualifying Purchases</b>").',
    making:
      'Making Qualifying Purchases will help you progress towards earning rewards from us ("<b>Reward Progress</b>") and reaching the designated threshold (a "<b>Reward Threshold</b>") indicated within the App. When you reach a Reward Threshold, you will have access to the rewards that we offer which can be redeemed automatically to your Linked Card or by showing us the reward on your next visit to one of our participating locations, as applicable (collectively, "<b>Rewards</b>").',
  },
  notes: {
    title: 'Important Notes About Your Cards and Transactions',
    registering:
      'By registering for our Loyalty Program and linking a payment card, you agree that your payment network (e.g., AMEX, MasterCard or Visa), may examine transactions (including returns) on your Linked Card to identify Qualifying Purchases and share transaction data with us and Thanx as set forth in the Privacy Policy for the purposes of determining whether a transaction is a Qualifying Purchase, determining your Rewards Progress and when you have reached a Rewards Threshold, providing you with Rewards and notifying you about the status of your Qualifying Purchases, Rewards Progress, Rewards Thresholds and Rewards.',
    not: "Not all payment cards are eligible for use in connection with our Loyalty Program: for example, corporate and purchasing cards, Health Savings Account (HSA), Flexible Spending Account (FSA) cards, government-administered prepaid cards (including EBT cards), insurance prepaid cards, Visa Buxx and other cards whose transactions are not processed through the payment network's own system are not eligible.",
    in_addition:
      "In addition, not all of our locations locations may participate in our Loyalty Program and not all transactions with your Linked Card may be eligible for our Loyalty Program, including PIN-based purchases, payments made through third-party digital wallets or payment apps other than Apple Pay, and other transactions not processed through the payment network's own system.",
    pin: 'Do not use a Personal Identification Number (PIN) when paying for your purchases with your Linked Card if you want the transaction to count toward your Reward Progress.',
    loyalty:
      'Our Loyalty Program may offer additional Opportunities to you, based on your account settings and account history. At any point in time you can opt-out of receiving additional Opportunities through your account settings within the Service.',
    reward:
      'Reward Progress is not redeemable for cash or any other item of value. Your Reward Progress may be forfeited if you violate these Terms or any additional terms or conditions applicable to Rewards Progress for a specific Opportunity (e.g. if a Qualifying Purchase(s) must occur within a certain timeframe). Any such terms will be communicated with the Opportunity, as applicable. You are responsible for ensuring your Reward Progress from a Qualifying Transactions is properly added to your account. It may take up to 7 days after a Qualifying Transaction is made for your Reward Progress to be viewable via your account. You should email us at [<a href="mailto:merchant@thanx.com">Merchant@thanx.com</a>] if it has been more than 7 days and the Reward Progress has not been applied to your account. Your request must be received within 21 days of a Qualifying Transaction in order to credit the account. We will not be responsible for investigating any Reward Progress that has not been credited to your account 21 days after the Qualifying Purchase. Your Reward Progress can also be reversed if any of the items you purchased in connection with a Qualifying Transaction are subsequently returned.  We may also, at our discretion, delay any Reward Progress in order to validate or verify a Qualifying Transaction. Any attempt to earn Reward Progress through the return of merchandise in connection with a Qualifying Transaction will be considered possible grounds for termination of your account.',
    acknowledge: 'You acknowledge that:',
    redemption:
      'The redemption of your Rewards (including the granting of any Reward Progress) is subject to your compliance with these Terms (including any terms and conditions provided with an Opportunity);',
    we: 'We reserve the right to delay, withhold or invalidate accrual or redemption of Rewards in the event of technical errors, potential fraudulent activity, or similar issues. Specifically, we can invalidate Rewards from your account with notice if we determine in our sole discretion that such Rewards were improperly credited to your account;',
    your: 'Your use of the Service and your activities in connection with the Service must be lawful and in compliance with these Terms and you may not in any way inhibit any other user from using the Service or participating in our Loyalty Program;',
    neither:
      'Neither SusieCakes nor our service providers are responsible or liable for any unredeemed, unused or lost Rewards or Opportunities;',
    rewards:
      "Rewards do not constitute property, do not entitle you to a vested right or interest and have no cash value. Therefore, Rewards are not redeemable for cash, transferable, salable or assignable for any reason.  No cash will be exchanged for the unused portion of any member's Rewards; and",
    you: 'You are solely responsible for your participation in the Loyalty Program, your use of your Rewards and Opportunities and for use of and access to the Service and your account.',
    remedy:
      'Your only remedy for a failure of a Reward or Opportunity is to redeem that Reward or Opportunity at a later time and/or to contact us at <a href="mailto:susiecakes@thanx.com">SusieCakes@thanx.com</a> with any questions. You acknowledge and agree that our service providers, including Thanx are not a party to any disputes regarding your Loyalty Program.',
    hereby:
      'You hereby release us, our affiliates, and our officers, directors, employees, agents, successors, assigns, and service providers (including AXP and any other payment card networks and Thanx) from any and all claims, demands, liabilities, losses and damages of every kind and nature arising out of or in any way connected with such disputes or your use of the Services. In addition, you expressly waive the provisions of California Civil Code §1542, which says:',
    general:
      '"<b>A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which if not known by him must have materially affected his settlement with the debtor.</b>"',
    short:
      'In short, this means that you release us and other entities involved in providing services to us from any and all claims you may have arising out of disputes you may have with us or as a result of your use of the Services. As used herein "<b>AXP</b>" means American Express Travel Related Services Company, Inc. AXP provides certain AXP crediting services to Thanx and AXP is a third party beneficiary of the foregoing release.',
  },
  mobile: {
    title: 'Access via the Mobile App',
    subject:
      'Subject to your compliance with these Terms, we grant you a limited non-exclusive, non-transferable, non-sublicenseable license to download and install a copy of the App on a mobile device or computer that you own or control and to run such copy of the App solely for your own personal non-commercial purposes. You may not copy the App, except for making a reasonable number of copies for backup or archival purposes. Except as expressly permitted in these Terms, you may not: (i) copy, modify or create derivative works based on the App; (ii) distribute, transfer, sublicense, lease, lend or rent the App to any third party; (iii) reverse engineer, decompile or disassemble the App; or (iv) make the functionality of the App available to multiple users through any means. All rights in and to the App not expressly granted to you under these Terms are reserved.',
  },
  alerts: {
    title: 'Alerts and Notifications',
    as: 'As part of the Services we provide, you may (if enabled) receive push notifications, alerts, emails, or other types of messages directly sent to you outside or inside the App ("Push Messages").  You have control over the Push Messages settings, and can opt in or out of these Push Messages through the Services (with the possible exception of infrequent, important service announcements and administrative messages).  Please be aware that third party messaging fees may occur for some of the Push Messages depending on the message plan you have with your wireless carrier.',
  },
  unauthorized: {
    title: 'Unauthorized Activities',
    service:
      'The Service may be used and accessed for lawful purposes only. You agree that you will not do any of the following while using or accessing the Service: (1) access or tamper with the App, or try to access non-public areas of the Service, our computer systems, or the technical delivery systems of our providers; (2) gather and use information, such as user names, real names, email addresses or transmit any unsolicited advertising, junk mail, spam or other form of solicitation; (3) use the Service for any commercial purpose or for the benefit of any third party or in any manner not by these Terms; (4) violate any applicable law or regulation; (5) use any robot, spider, scraper, deep link or other similar automated data gathering or extraction tools, program, algorithm or methodology to access, acquire, copy or monitor the Service or any portion of the Service, without the our permission; or (6) encourage or enable any other individual to do any of the foregoing. We reserve the right to investigate and prosecute violations of any of the above and/or involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms.',
    additionally:
      'Additionally, the following activities are prohibited and constitute a violation of these Terms:',
    fraud: 'Providing fraudulent or false information;',
    accessing: 'Accessing the personal information of third parties;',
    tampering: 'Tampering with security we implement to protect the Service;',
    soliciting:
      'Soliciting Merchants or users for commercial purposes or otherwise or using information made available via the Service for commercial purposes;',
    reselling: 'Reselling Opportunities;',
    attempting: 'Attempting to use Opportunities more than once;',
    overwhelming:
      'Intentionally overwhelming our servers with excessive demand; and',
    hyperlinks: 'Adding any hyperlinks without our written consent.',
  },
  account: {
    title: 'Termination, Account Cancellation or Suspension',
    cancel:
      'You may cancel your account at any time through the Service or by sending an email to <a href="mailto:susiecakes@thanx.com">SusieCakes@thanx.com</a>',
    remove:
      'You can remove your Linked Card from the Services at any time by visiting your account, selecting "Registered Cards", clicking "Edit" and deleting any card(s) you prefer. When you do so we will no longer capture future transactions associated with the Linked Card, but we will retain the Transaction Data associated with your Linked Cards for historical transactions as necessary to maintain your rewards program progress and keep track of any applicable rewards.',
    continue:
      'Our Loyalty Program will continue until terminated at our sole discretion. We may terminate or suspend the Loyalty Program as well as your access to and use of the Service and your account, at our sole discretion, at any time for any reason. Reasons for such a termination or suspension include without limitation (1) you have violated or breached any provision of these Terms (or have acted in a manner which shows that you do not intend to, or are unable to comply with the provisions of these Terms), (2) we believe it is required to do so by law (for example, where the provision of the Service or Loyalty Program to you is, or becomes, unlawful); or (3) we provide notice to you. If your account is terminated, you may not later re-enroll in the Service.',
    inactive:
      'Accounts that remain inactive for more than 6 months may be subject to automatic cancellation.  Once your account is cancelled (whether by you or us), you will no longer be able to access your account and you will lose all of your accumulated Rewards and Reward Progress and you understand and acknowledge that we will have no further obligation to provide the Service to you or access to any of your account information. We will not be liable to you or to any third party for the suspension or termination of the Loyalty Program, or your account or your access or use of the Service.  Upon any termination, discontinuation or cancellation of the Loyalty Program, your access to and use of the Service and your account, all provisions of these Terms which by their nature should survive will survive, including, without limitation, ownership provisions, warranty disclaimers, limitations of liability, and dispute resolution provisions.',
  },
  ownership: {
    title: 'Ownership',
    copyright:
      'The Service is protected by copyright, trademark, and other laws of the United States and foreign countries.  SusieCakes and its licensors exclusively own all right, title and interest in and to the Service, including all associated intellectual property rights.  You may not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Service, except as expressly permitted in these Terms.  Any feedback, comments and suggestions you may provide for improvements to the Service or Loyalty Program ("<b>Feedback</b>") is given entirely voluntary and, even if designated as confidential, will not create any confidentiality obligation and we and our service providers will be free to use, disclose, reproduce, license, distribute, and otherwise exploit such Feedback as we see fit, entirely without obligation or restriction of any kind on account of intellectual property rights or otherwise.',
  },
  links: {
    title: 'Links to Third-Party Web Sites',
    contains:
      'The Service may contain links to other third party sites or resources. These links are provided as a convenience to you but not as an endorsement of the companies or the contents of these sites or their products and services.  We are not responsible for the content of such sites or resources.  If you decide to access any of the third-party web sites linked to the Service, you do this entirely at your own risk.',
  },
  special: {
    title: 'SPECIAL PROGRAMS',
    exclusive: 'Exclusive Offers.',
    merchant: 'Merchant Product Purchases',
    certain:
      'From time-to-time, certain merchants may offer goods or services ("Merchant Products") for purchase on our Site, our App, or otherwise through our Service (collectively, the "Thanx Platform") (such offers are referred to below as "Thanx Exclusive Deals").  The following terms, as well as any terms and conditions referenced by the applicable merchant or otherwise displayed via the Thanx Platform when you order the Merchant Products on the Thanx Platform ("Merchant Terms"), apply to Thanx Exclusive Deals and your purchase of Merchant Products on the Thanx Platform.',
    order:
      'To order Merchant Products, you will be required to provide certain information, such as your debit or credit card number, expiration date, and security code, to Stripe, a third party payments processor, via the Thanx Platform. For Stripe\'s terms and privacy policies, please go to: <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>. When you purchase a Merchant Product, you are purchasing such Merchant Products from the applicable merchant - not Thanx. Thanx is not a bank or money transmitter and does not process payments for Merchant Products sold on the Thanx Platform. When you purchase Merchant Products from a merchant via the Thanx Platform, you will receive a digital code (each, a "Redemption Code") which, subject to the Merchant Terms, is redeemable for Merchant Products as set forth in the Merchant Terms. Redemption Codes may not be exchanged for cash or merged with Thanx or other merchant offers or rewards.',
    intend:
      'If you did not intend to purchase a Merchant Products or want to cancel an order, you must contact the Merchant and/or Stripe.  Any decision to accept returns of, or issue refunds for, Merchant Products will be made by the merchant and/or Stripe.',
    reserves:
      'Thanx reserves the right to terminate the Thanx Exclusive Deals program or any specific Thanx Exclusive Deal at any time.  Thanx also reserves the right to cancel, revoke or otherwise prevent the issuance of Redemption Codes in cases of mistake and in any suspected case of unauthorized or fraudulent use. Thanx is not required to facilitate transfers of Merchant Products or Redemption Codes from you to other individuals.',
    agree:
      'You agree that Thanx may use, share and exchange information about your use of the Thanx Platform, including any purchase of Merchant Products, as permitted by these Terms or the <a href="https://dashboard.thanx.com/privacy">Privacy Policy</a> including, without limitation: to confirm the redemption of a Redemption Code; confirm whether you qualify for a Thanx Exclusive Deal; confirm whether a specific transaction has occurred; create a record of the transaction data to verify and maintain a record of your purchases; provide reports concerning activity as it relates to Thanx Exclusive Deals; perform analytics regarding the performance of Thanx Exclusive Deals; enable Thanx and/or Merchants communicate with you concerning transactions and provide general customer service and respond to your inquiries; and to respond to legal, regulatory and audit requests and resolve disputes and defend claims against or by Thanx concerning its services.',
    authorize:
      'You specifically authorize the sharing, exchange and use of transaction data in connection with your claimed merchant rewards and Thanx Exclusive Deals with Stripe, any other service providers of Thanx, and applicable merchants, as described herein and in the Privacy Policy.',
  },
  offer_disclaimers: {
    title: 'Disclaimers.',
    platform:
      'Thanx provides a platform for the purchase of goods and services sold by Thanx merchants, but we are not responsible for the goods or services sold by any merchant on the Thanx Platform.',
    liability:
      'Neither Thanx nor Stripe will have any liability to you in connection with your purchase of Merchant Products or redemption of Redemption Codes, including, without limitation, in connection with:',
    associated: 'any goods or services associated with a Thanx Exclusive Deal,',
    honoring:
      'honoring Redemption Codes, delivering Merchant Products, or fulfilling a Thanx Exclusive Deal,',
    inaction:
      'any action or inaction by Stripe or the merchant whose goods or services are the subject of a Thanx Exclusive Deal.',
    failure:
      'the failure of Thanx or Stripe to provide any notifications or engage in any monitoring described herein,',
    refund:
      'to honor or refund the Thanx Offer, or to provide any updates about claimed or redeemed Thanx Exclusive Offers.',
    exclusively:
      'This means that any liability regarding a Thanx Exclusive OfferDeal lies exclusively with the Thanx Network Merchant participating merchant.',
  },
  indemnification: {
    title: 'Indemnification',
    indemnify:
      "You agree to indemnify, defend and hold SusieCakes, its affiliated companies, its service providers and suppliers (including payment card networks and payment processors) and their respective officers, directors, employees, agents, successors and assigns harmless from and against all claims, liabilities, losses, expenses, damages, and costs, including but not limited to reasonable attorneys' fees, resulting from (1) an violation or breach of these Terms by you, (2) any activity related to access to or use of your account by you or any other person accessing or using your account, or (3) any activity related to your redemption of Rewards or Opportunities and participation in the Loyalty Program.",
  },
  disclaimer: {
    title: 'Disclaimer',
    as_is:
      'THE SERVICE AND LOYALTY PROGRAM ARE PROVIDED "AS IS" AND ON AN "AS AVAILABLE" BASIS, WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SUSIECAKES AND ITS SERVICE PROVIDERS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.  WE DO NOT WARRANT THAT THE LOYALTY PROGRAM OR THE USE OR QUALITY OF THE SERVICE WILL BE UNINTERRUPTED, ERROR-FREE, TIMELY, SECURE OR MEET YOUR REQUIREMENTS OR EXPECTATIONS.',
  },
  limitation: {
    title: 'Limitation of Liability',
    maximum:
      "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE OR OUR SERVICE PROVIDERS AND SUPPLIERS (INCLUDING PAYMENT CARD NETWORKS AND PAYMENT PROCESSORS) BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR: LOSS OF PROFITS, LOSS OF CONFIDENTIAL OR OTHER INFORMATION, BUSINESS INTERRUPTION, PERSONAL INJURY, LOSS OF PRIVACY, LOSS OF DATA, FAILURE OF DELIVERY OF GOODS, FAILURE TO MEET ANY DUTY, NEGLIGENCE, AND ANY OTHER PECUNIARY OR OTHER LOSS WHATSOEVER ARISING OUT OF OR IN ANY WAY RELATED TO YOUR PARTICIPATION IN THE LOYALTY PROGRAM OR THE USE OF OR INABILITY TO USE THE SERVICE EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AND OUR SUPPLIERS' (INCLUDING PAYMENT CARD NETWORKS AND PAYMENT PROCESSORS) TOTAL LIABILITY TO YOU FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY WILL BE LIMITED TO THE AMOUNT YOU PAID FOR THE SERVICE AND IN NO EVENT WILL IT EXCEED $50.",
  },
  exclusions: {
    title: 'Exclusions',
    some: 'Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.',
  },
  general: {
    title: 'General',
    terms:
      "These Terms are governed by the laws of the State of California, excluding its conflicts of laws principles.  Any claim or dispute between us arising out of the Loyalty Program or Service will be decided exclusively in the federal and/or state courts for Santa Clara County, California.  Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms (including any additional terms and conditions provided with an Opportunity) constitute the entire agreement between us regarding the Loyalty Program and the Service, and supersede and replace any prior agreements we might have between us regarding the Loyalty Program and Service. We may revise these Terms from time to time in our sole discretion. . If we do so, we'll let you know either by posting the modified Terms via the Service, or through other communications. By continuing to access or use the Service or to participate in the Loyalty Program after those revisions become effective, you agree to be bound by the revised terms.",
  },
  questions: {
    title: 'Questions',
    have: 'If you have any questions about these Terms, please contact us at <a href="mailto:susiecakes@thanx.com">SusieCakes@thanx.com</a>.',
    effective: 'Effective: November 4, 2019',
  },
  copyright: '%{year} Thanx, which powers the SusieCakes Rewards app',
}

export default susieCakes
