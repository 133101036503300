import { Button } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import React from 'react'

type PropsT = {
  icon: React.ReactNode
  text: string
  onClick?: VoidFunction
}

const UnavailableButton: React.FC<PropsT> = ({ icon, text, onClick }) => {
  const [css] = useStyletron()

  return (
    <Button
      className={`mb-xs px-s py-xs ${css({
        fontWeight: 400,
        border: 0 + '!important',
        color: palette.grey90 + '!important',
        backgroundColor: palette.grey05 + '!important',
        cursor: 'text !important',
      })}`}
      disabled
      onClick={() => onClick?.()}
    >
      {icon}
      {text}
    </Button>
  )
}

export default UnavailableButton
