import useCurrentMerchant from 'hooks/useCurrentMerchant'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectTargetSegmentsByCategories } from 'selectors/targetSegment'
import CampaignSection from '../CampaignSection'
import LogoBadge from '../CampaignSection/LogoBadge'
import NewSegmentCard from './NewSegmentCard'
import NoSegmentCard from './NoSegmentCard'

type Props = {
  onNewClick?: () => void
  onExistingClick?: (value: number) => void
  imageCard?: React.ReactNode
  badge?: React.ReactNode
  byline?: string
  category?: string
  hideNewCard?: boolean
  limit?: number
}

/**
 * Gets all the custom campaign cards that are used on the automated
 * and target CustomCampaigns components.
 */
const CustomCampaignCards: React.FC<Props> = props => {
  const merchant = useCurrentMerchant()
  const defaultBadge = (
    <LogoBadge url={merchant?.logo_image_urls?.small ?? ''} />
  )
  const defaultImageCard = (
    <CampaignSection.LogoImageCard
      coverImageUrl={merchant?.cover_image_urls?.large ?? ''}
      logoImageUrl={merchant?.logo_image_urls?.small ?? ''}
    />
  )
  const {
    badge = defaultBadge,
    byline = '',
    category = '',
    imageCard = defaultImageCard,
    hideNewCard = false,
    onExistingClick,
    onNewClick,
    limit,
  } = props

  let targetSegments = useSelector(state =>
    selectTargetSegmentsByCategories(state, [category])
  )

  if (limit && limit <= targetSegments.length)
    targetSegments = targetSegments.slice(0, limit)
  const onClickExisting = (targetSegmentId: number) => {
    if (onExistingClick) onExistingClick(targetSegmentId)
  }
  const bylineElement = <div className="grey-70">{byline}</div>

  const newCard = <NewSegmentCard key="new-segment" onClick={onNewClick} />

  // TODO: Add merchant user byline
  const cards =
    targetSegments.length === 0
      ? [<NoSegmentCard key="none" />]
      : targetSegments.map(targetSegment => {
          return (
            <CampaignSection.Card
              key={targetSegment.id}
              title={targetSegment.name}
              badge={badge}
              byline={byline ? bylineElement : null}
              onClick={() => onClickExisting(targetSegment.id)}
            />
          )
        })
  const cardList = hideNewCard ? cards : [newCard, ...cards]

  return (
    <div id="custom_campaign">
      <CampaignSection.Cards imageCard={imageCard} lockImageCardHeight={true}>
        {cardList}
      </CampaignSection.Cards>
    </div>
  )
}

export default CustomCampaignCards
