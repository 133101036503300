export default {
  title: 'Integrations',
  pageTitle: 'Integrations | Thanx',
  card: {
    via: 'via',
    enabled: 'Enabled',
    coming_soon: 'Coming soon',
    learn_more: 'Learn more',
    contact_us: 'Contact us to enable this integration',
  },
  help: 'Help',
  how_to_enable_integrations: 'How to enable integrations',
  requiresPaidAddon: 'Requires paid add-on.',
  managed_via_thanx: 'Managed via Thanx',
  managed_by_olo: 'Managed by Olo',
  posInstoreSkuData: {
    title: 'POS — In-store SKU data',
    emailSubject: 'I would like to enable %{pos} for in-store SKU data',
    toast: {
      title: 'Toast',
    },
    revel: {
      title: 'Revel',
    },
    oloOmnivore: {
      title: 'Olo Omnivore',
      description: 'Multiple POS integrations. Requires paid add-on.',
    },
  },
  posInstoreOfferManagement: {
    title: 'POS — In-store offer management',
    emailSubject: 'I would like to enable %{pos} for in-store offer management',
    thanx: {
      title: 'Thanx',
      description:
        'Manual offer management. This is always available to you but configuration options may change if another partner integration is enabled.',
    },
    toast: {
      title: 'Toast',
    },
    fishbowl: {
      title: 'Fishbowl',
      description: 'Multiple POS integrations. Requires paid add-on.',
    },
  },
  posInstoreRedemption: {
    title: 'POS — In-store redemption',
    emailSubject: 'I would like to enable %{pos} for in-store redemption',
    toast: {
      title: 'Toast',
    },
  },
  digitalOrderManagement: {
    title: 'Digital order management',
    description: 'Only 1 partner integration may be on.',
    olo: {
      title: 'Olo',
    },
    toast: {
      title: 'Toast',
    },
    openTender: {
      title: 'Open Tender',
    },
    deliverect: {
      title: 'Deliverect',
    },
  },
  delivery: {
    title: 'Delivery',
    oloDispatch: {
      title: 'Olo Dispatch',
    },
    doordash: {
      title: 'Doordash',
    },
    uber: {
      title: 'Uber',
    },
    vromo: {
      title: 'Vromo',
    },
  },
  cdp: {
    title: 'CDP',
    emailSubject: 'I would like to enable %{cdp} as my CDP',
    oloGdp: {
      title: 'Olo GDP',
    },
    bikky: {
      title: 'Bikky',
    },
    delightable: {
      title: 'Fishbowl Delightable',
    },
    klaviyo: {
      title: 'Klaviyo',
    },
  },
  emailMarketing: {
    title: 'Marketing automation - Email',
    description:
      'You may turn on up to 1 partner integration and still continue to use Thanx.',
    emailSubject:
      'I would like to enable %{integration} for email marketing automation',
    thanx: {
      title: 'Thanx',
      description:
        'Default marketing automation. This is always available to you.',
    },
    klaviyo: {
      title: 'Klaviyo',
    },
    braze: {
      title: 'Braze',
    },
    oloGdp: {
      title: 'Olo GDP',
    },
    fishbowl: {
      title: 'Fishbowl',
    },
  },
  smsMarketing: {
    title: 'Marketing automation - SMS',
    description:
      'You may turn on up to 1 partner integration and still continue to use Thanx.',
    emailSubject:
      'I would like to enable %{integration} for SMS marketing automation',
    thanx: {
      title: 'Thanx',
      description:
        'Default marketing automation. This is always available to you.',
    },
    klaviyo: {
      title: 'Klaviyo',
    },
    attentive: {
      title: 'Attentive',
    },
  },
  marketingAutomationWifi: {
    title: 'Marketing automation - WiFi',
    description: null,
    emailSubject:
      'I would like to enable %{integration} for email marketing automation',
    adentro: {
      title: 'Adentro',
      description:
        'Default marketing automation. This is always available to you.',
    },
  },
  feedback: {
    title: 'Feedback and reputation management',
    description:
      'You may turn on up to 1 partner integration. Turning on a partner integration will turn off Thanx feedback.',
    emailSubject: 'I would like to enable %{integration} for customer feedback',
    thanx: {
      title: 'Thanx',
      description:
        'Default feedback tool. This feature gets turned off when another feedback integration is enabled.',
    },
    tattle: {
      title: 'Tattle',
    },
    ovation: {
      title: 'Ovation',
    },
    yumpingo: {
      title: 'Yumpingo',
    },
    guestxm: {
      title: 'GuestXM',
    },
    momos: {
      title: 'Momos',
    },
  },
  customDataCapture: {
    title: 'Custom data capture',
    emailSubject:
      'I would like to enable %{integration} for custom data capture',
    jotform: {
      title: 'Jotform',
    },
  },
  locationIntelligence: {
    title: 'Location intelligence',
    emailSubject:
      'I would like to enable %{integration} for location intelligence',
    flybuy: {
      title: 'Flybuy',
    },
  },
  storedValue: {
    title: 'Stored value',
    ansa: {
      title: 'Ansa',
    },
  },
}
