import Button from '@thanx/uikit/button/Button'
import FormsyCheckbox from '@thanx/uikit/checkbox/FormsyCheckbox'
import { Switch } from '@thanx/uikit/switch'
import { getRedeemTemplates } from 'actions/redeemTemplates'
import CustomFinePrint from 'components/CustomFinePrint'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { useFormManagement } from 'scenes/CampaignCenter/Builder/components/FormManagementContext'
import RestrictionCreator from 'scenes/CampaignCenter/Builder/components/Incentive/RestrictionCreator'
import RestrictionSummary from 'scenes/CampaignCenter/Builder/components/Incentive/RestrictionSummary'
import { selectCampaignTypeByType__deprecated } from 'selectors/campaignType'
import { selectCampaignVariantsByCampaignId } from 'selectors/campaignVariant'
import { selectRedeemTemplatesByState } from 'selectors/redeemTemplate'
import Details from './Details'
import RestrictionWarning from './RestrictionWarning'
import Select from './Select'

type PropsT = {
  campaign: Campaign
}

const t = buildTranslate('thanx_campaigns.builder.steps.incentive')

const Form: React.FC<PropsT> = (props: PropsT) => {
  const { campaign } = props

  const { config } = useCampaignConfig()
  const { canProceed, isSaving, showValidationErrors, triggerSave } =
    useFormManagement()

  const [hasRedeem, setHasRedeem] = useState<boolean>(config.redeem)
  const [redeemChanged, setRedeemChanged] = useState<boolean>(false)
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [showRestrictions, setShowRestrictions] = useState<boolean>(false)
  const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false)
  const dispatch = useDispatch()

  const campaignType = useSelector((state: any) =>
    selectCampaignTypeByType__deprecated(state.orm, campaign.type)
  )
  const templates = useSelector(state =>
    selectRedeemTemplatesByState(state, 'published')
  )
  const variants = useSelector(state =>
    selectCampaignVariantsByCampaignId(state, campaign.id)
  )
  let restrictedType: null | string = null
  if (config.campaign_config_type === 'variant') {
    restrictedType =
      variants?.find(v => v.id !== config.id && v.redeem && !!v.redeem_type)
        ?.redeem_type || null
  }
  const campaignTemplate = templates.find(
    t => t.id === config.redeem_template_id
  )

  // Filtering templates so that variants cannot have mismatching reward types
  const filteredTemplates = restrictedType
    ? templates.filter(template => {
        const isExperienceType = restrictedType?.includes('experience')
        const includesExperience = template.type.includes('experience')
        return isExperienceType ? includesExperience : !includesExperience
      })
    : templates
  useEffect(() => {
    if (!hasRedeem) return
    async function fetchData() {
      setIsLoadingTemplates(true)
      await dispatch(getRedeemTemplates())
      setIsLoadingTemplates(false)
    }

    fetchData()
  }, [hasRedeem, dispatch])

  const header = campaignType?.redeem_required ? (
    <label>{t('required_incentive_label')}</label>
  ) : (
    <div>
      <FormsyCheckbox
        // @ts-ignore
        name="redeem"
        onChange={(value: boolean) => {
          setRedeemChanged(false)
          setHasRedeem(value)
        }}
        value={config.redeem}
      >
        {t('include_incentive_label')}
      </FormsyCheckbox>
    </div>
  )
  function handleRestrictionsClose() {
    triggerSave()
    if (canProceed) {
      setShowRestrictions(false)
    } else if (showValidationErrors) {
      showValidationErrors()
    }
  }

  return (
    <>
      <div className="mt-l">{header}</div>
      <Spinner
        isLoading={isLoadingTemplates && hasRedeem}
        size="4x"
        className="text-center margin-top-huge"
      >
        <Switch condition={hasRedeem}>
          <div className="d-flex align-items-start mb-l mt-m">
            <Select
              templates={filteredTemplates}
              isSomeTemplatesHidden={
                templates.length !== filteredTemplates.length
              }
              campaign={campaign}
              onChange={(value: number) => {
                if (
                  config.redeem_template_id &&
                  config.redeem_template_id !== value
                )
                  setRedeemChanged(true)
              }}
            />
            <Switch condition={filteredTemplates.length > 0}>
              <Button
                kind="minimal"
                className="w-50"
                onClick={() => setShowDetails(!showDetails)}
              >
                {showDetails
                  ? t('template.details.hide')
                  : t('template.details.show')}
              </Button>
            </Switch>
          </div>

          {showDetails && (
            <Details config={config} template={campaignTemplate} />
          )}

          <RestrictionWarning display={redeemChanged} />

          <hr className="my-xl border-bottom-1 border-top-0 grey-40-border" />

          <div className="mb-xxl">
            <RestrictionSummary
              campaign={campaign}
              onEdit={() => setShowRestrictions(!showRestrictions)}
              showEdit={true}
              alwaysShow={true}
              editDisabled={!canProceed}
              redeemType={campaignTemplate?.type}
              validate={showValidationErrors}
            />
          </div>
          <CustomFinePrint config={config} />
        </Switch>
      </Spinner>

      <RestrictionCreator
        campaign={campaign}
        showRewardRestrictions={showRestrictions}
        onClose={handleRestrictionsClose}
        isSaving={isSaving}
      />
    </>
  )
}

export default Form
