import orm from 'models/orm'
import type { Fields as Redeem } from 'models/Redeem'
import { createSelector } from 'redux-orm'

export const selectRedeems = createSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Redeem[] => {
    return session.Redeem.all().toRefArray()
  }
)
