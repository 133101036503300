import React from 'react'

type Props = {
  icon: string
  iconAlt: string
  text: string
}
const SolutionStep: React.FC<Props> = props => {
  const { icon, iconAlt } = props
  return (
    <div
      className="display-table-cell padding-small"
      style={{ width: '100px' }}
    >
      <img src={icon} className="img-responsive" alt={iconAlt} />
    </div>
  )
}

export default SolutionStep
