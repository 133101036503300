import { RadioGroup } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import RichSelector from 'components/Form/RichSelector'
import GenericVariant from 'components/Icons/GenericVariant'
import ControlWrapped from 'components/Icons/Variants/ControlWrapped'
import palette from 'constants/palette'
import { MinimalVariant } from 'models/Campaign'
import {
  getLetter,
  getVariantName,
  sortVariants,
} from 'models/CampaignVariant/helpers'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'

type PropsT = {
  className?: string
  disabledIds?: number[]
  setSelectedVariant: (id: number) => void
  variants: MinimalVariant[]
  translate?: string
}

const VariantRadio: React.FC<PropsT> = props => {
  const {
    className,
    disabledIds,
    setSelectedVariant,
    translate,
    variants = [],
  } = props

  const [selected, setSelected] = useState()
  const clickHandler = event => {
    const value = event.currentTarget.querySelector('input:first-of-type').value
    setSelected(value)
  }

  return (
    <RadioGroup className={className} value={selected}>
      {variants.sort(sortVariants).map((variant, i) => {
        const { id } = variant
        const name =
          variant.meta?.name ||
          getVariantName(variant.internal_type, variant.name, i)
        const letter =
          variant.meta?.letter || getLetter(variant.internal_type, i)
        const isControl = variant?.meta?.isControl
        const text = translate ? I18n.t(translate, { name }) : name
        const bottomClass = i !== variants.length - 1 ? 'mb-xs' : 'mb-0'
        const isDisabled = disabledIds?.includes(id)
        const image = isControl ? (
          <ControlWrapped color={palette.grey40} />
        ) : (
          <GenericVariant variantLetter={letter} />
        )
        return (
          <RichSelector
            className={`${bottomClass} align-items-center`}
            disabled={isDisabled}
            value={id.toString()}
            key={i}
            image={image}
            onClick={event => {
              setSelectedVariant(id)
              clickHandler(event)
            }}
          >
            <Text.Header4 bold color="grey90" className="my-0 r-m">
              {text}
            </Text.Header4>
          </RichSelector>
        )
      })}
    </RadioGroup>
  )
}

export default VariantRadio
