// TODO: Port all of the review page sections to this.
import { Switch } from '@thanx/uikit/switch'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import PopoverMenu from 'components/PopoverMenu'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import EditButton from './EditButton'

type Props = {
  children: React.ReactNode
  icon?: React.ReactNode
  isEditable?: boolean
  onEdit?: () => void
  isEmpty?: boolean
  type?: string
  popoverValues?: React.ReactNode
  className?: string
  paddingSize?: 'medium' | 'large'
}
/**
 * This is the layout for a campaign center program review page.
 */
const ReviewSection: React.FC<Props> = props => {
  const {
    children,
    className = '',
    icon,
    isEditable = false,
    onEdit,
    popoverValues = null,
    isEmpty = false,
    type,
    paddingSize = 'large',
  } = props
  const emptyClass = isEmpty ? 'is-empty' : ''
  const [css] = useStyletron()
  const Icon = typeof icon === 'string' ? <img src={icon} alt="" /> : icon
  const paddingClass = paddingSize === 'large' ? 'p-l' : 'p-m'
  return (
    <Row
      className={`section ${paddingClass} ${emptyClass} ${className} position-relative ${css(
        {
          border: `1px solid ${defaultTheme.colors.grey20}`,
          borderRadius: '4px',
          background: defaultTheme.colors.white,
          marginLeft: 0,
          marginRight: 0,
        }
      )}`}
      sm="auto"
    >
      {icon && (
        <Col
          className={`col-header pr-m ${css({
            width: '48px',
          })}`}
        >
          {Icon}
        </Col>
      )}
      <Col className="col-content flex-1">{children}</Col>
      <Switch condition={!!popoverValues}>
        <Col>
          <PopoverMenu dismissOnClickOutside placement="bottomRight">
            {popoverValues}
          </PopoverMenu>
        </Col>
      </Switch>
      {isEditable && (
        <Col className="text-right ml-s">
          <EditButton onClick={onEdit} isEmpty={isEmpty} type={type} />
        </Col>
      )}
    </Row>
  )
}

export default ReviewSection
