import { Text } from '@thanx/uikit/text'
import { FormsyTextArea } from '@thanx/uikit/textarea'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  longDescription: string
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.description_card'
)

const Optional: React.FC<Props> = ({ longDescription }) => {
  const value = longDescription || ''
  return (
    <FormsyTextArea
      // @ts-ignore
      className="mt-m"
      label={<Text.BodyText4 bold>{t('optional')}</Text.BodyText4>}
      name="longDescription"
      placeholder={t('optional_placeholder')}
      value={value}
      rows={4}
    />
  )
}

export default Optional
