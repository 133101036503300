import { Button } from '@thanx/uikit/button'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import HeaderCell from './HeaderCell'
import { sortChannelData, SortFilter, SortFilterAttribute } from './utilities'

const tTable = buildTranslate('reports.table')

export type RowProps<T> = {
  data: T
  isErrored: boolean
  isLoading: boolean
}

type PropT<T> = {
  children: React.JSXElementConstructor<RowProps<T>>
  headers: Array<SortFilterAttribute>
  values: Array<T>
  isLoading: boolean
  isErrored: boolean
  t: (string) => string
  headerClassNames?: string[]
  initialSortAttribute?: string
  showRowToggle?: boolean
}

function MetricsTable<T>({
  children,
  headers,
  values,
  t,
  isLoading,
  isErrored,
  headerClassNames = [],
  initialSortAttribute = 'date_time',
  showRowToggle = true,
}: PropT<T>) {
  const Row = children
  const [sortFilter, setSortFilter] = useState<SortFilter>({
    attribute: initialSortAttribute,
    direction: 'desc',
  })
  const [showAllRows, setShowAllRows] = useState(!showRowToggle)

  if (!values) {
    return null
  }
  const channelData: Array<T> = sortFilter
    ? sortChannelData(values, sortFilter, showAllRows)
    : values

  return (
    <div className={'table-responsive'}>
      <table className="w-100">
        <thead className="w-100">
          <tr className="w-100">
            {headers.map((attribute, index) => (
              <HeaderCell
                key={attribute}
                onSort={setSortFilter}
                attribute={attribute}
                label={t(attribute)}
                sortFilter={sortFilter}
                className={headerClassNames[index]}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {channelData.map((value, index) => (
            <Row
              key={`channels-report-${index}`}
              data={value}
              isErrored={!!isErrored}
              isLoading={!!isLoading}
            />
          ))}
        </tbody>
      </table>
      {showRowToggle && (
        <Button
          kind="minimal"
          size="small"
          className="border-0 pt-0 font-size-16 font-weight-normal mt-m"
          onClick={() => setShowAllRows(value => !value)}
        >
          {showAllRows ? tTable('show_less') : tTable('show_more')}
        </Button>
      )}
    </div>
  )
}

export default MetricsTable
