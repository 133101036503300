const orderingManagement = {
  location_list: {
    page_title: 'Ordering management | Thanx',
    title: 'Ordering management',
    locations: 'Locations',
    subtitle:
      'These locations are ingested from your ordering provider. View each location to troubleshoot errors.',
    table: {
      headers: {
        location: 'Location',
        menu_status: 'Menu status',
        ordering_status: 'Ordering status',
      },
      select_all: 'Select all',
      deselect_all: 'Deselect all',
    },
    enable_selected_locations: 'Enable selected locations',
    disable_selected_locations: 'Disable selected locations',
    modal: {
      title: '%{action} ordering at selected locations',
      content_enable:
        'Customers will immediately see these locations as available for ordering in your app or web experiences.',
      content_disable:
        'Customers will immediately not see these locations as available for ordering in your app or web experiences.',
      cancel: 'Cancel',
      confirm: '%{action} ordering',
      disable_warning: 'Orders currently in progress could be disrupted.',
    },
    alert: {
      enable_success: 'Ordering has been enabled at selected locations',
      disable_success: 'Ordering has been disabled at selected locations',
    },
    help: {
      title: 'Help',
      description: 'How to troubleshoot menu errors',
      enable_disable_locations: 'How to enable and disable locations',
    },
  },
  errors: '%{count} errors',
  no_errors: 'No errors',
  order_status: {
    enabled: 'Ordering enabled',
    disabled: 'Ordering disabled',
    not_configured: 'Ordering not configured',
  },
  view: 'View',
  disable_ordering_at_location: 'Disable ordering at this location',
  enable_ordering_at_location: 'Enable ordering at this location',
  detail: {
    title: '%{name} Ordering management | Thanx',
    header: {
      enabled: 'Ordering Enabled',
      disabled: 'Ordering Disabled',
      not_configured: 'Ordering not Configured',
      button: {
        enabled: 'Disable ordering at this location',
        disabled: 'Enable ordering at this location',
        not_configured: 'Enable ordering at this location',
      },
    },
    modal: {
      enable: {
        title: 'Enable ordering at selected locations',
        description:
          'Customers will immediately see these locations as available for ordering in your app or web experiences.',
        confirm_text: 'Enable ordering',
        success: 'Ordering has been enabled at this location',
      },
      disable: {
        title: 'Disable ordering at this location',
        description:
          '<p class="mb-m">Customers will immediately not see this location as available for ordering in your app or web experiences.</p> <p class="cayenne-50">Orders currently in progress could be disrupted.</p>',
        confirm_text: 'Disable ordering',
        success: 'Ordering has been disabled at this location',
      },
    },
    update: {
      last_updated: 'Last updated',
      update: 'Update',
    },
    popover:
      'Updates may take up to a minute after clicking “Update”. Automatic updates happen every 15 minutes.',
    errors: {
      title: 'Errors associated with this location',
    },
    menus: {
      menu: 'Menu',
      enabled: {
        title: 'Menus enabled for this location',
        subtitle:
          'These menus are associated with this location by your ordering provider and are enabled for use by the Thanx ordering experiences.',
        infobox_content:
          'These menus are associated with this location by your ordering provider and are enabled for use by the Thanx ordering experiences.',
      },
      disabled: {
        title: 'Menus not enabled for this location',
        subtitle:
          'These menus are associated with this location by your ordering provider but are not enabled for use by the Thanx ordering experiences.',
        infobox_content:
          'No menus are associated with this location but not enabled for use by the Thanx ordering experiences.',
      },
    },
    help: {
      help: 'Help',
      link: 'How to troubleshoot menu errors',
    },
  },
}

export default orderingManagement
