import { Switch } from '@thanx/uikit/switch'
import { getCouponPools } from 'actions/couponPools'
import { getAllFileUploads, SourcePath } from 'actions/fileUpload'
import Info from 'components/Info'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { CouponPoolGenerationType } from 'models/CouponPool'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PoolUploadForm from 'scenes/RedeemManager/Builder/ConfigurationStep/InstoreRedemptionCard/CouponCodesForm/PoolUploadForm'
import { selectCouponPoolsByTemplateId } from 'selectors/couponPool'
import { useIsMobile } from 'utilities/responsive'
import PoolDetails from './PoolDetails'

type PropsT = {
  template: RedeemTemplate
  displayUploadForm: boolean
}

const t = buildTranslate(
  'redeem_manager.detail.cards.instore_redemption.coupon_codes'
)

const CouponCodes: React.FC<PropsT> = ({ template, displayUploadForm }) => {
  const { id: templateId } = template
  const couponPools = useSelector(state =>
    selectCouponPoolsByTemplateId(state, templateId)
  )
  const canUpload =
    displayUploadForm &&
    couponPools?.[0]?.generation_type !== CouponPoolGenerationType.STATIC
  const couponPool = couponPools?.length
    ? couponPools?.[couponPools.length - 1]
    : null

  const isMobile = useIsMobile()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!couponPools?.length) {
      dispatch(getCouponPools(templateId))
    }

    if (canUpload && !!couponPool?.id) {
      dispatch(getAllFileUploads(SourcePath.COUPON_POOLS, couponPool.id))
    }
  }, [couponPools, couponPool?.id, canUpload, templateId, dispatch])

  if (!couponPools?.length || !couponPool) {
    return <Spinner isLoading />
  }

  return (
    <>
      <Info className="mb-m" label={t('redemption')} value={t('codes')} />
      <Info
        className="mb-m"
        label={t('type')}
        value={
          couponPool.generation_type === CouponPoolGenerationType.STATIC
            ? t('static_code')
            : t('pool_of_unique')
        }
      />
      <Info className="mb-m" label={t('format')} value={couponPool.type} />
      {couponPool.generation_type === CouponPoolGenerationType.STATIC && (
        <Info label={t('coupon_code')} value={couponPool.static_code} />
      )}

      <Switch
        condition={
          couponPool?.generation_type !== CouponPoolGenerationType.STATIC
        }
      >
        <div
          className={`grey-20-border ${
            displayUploadForm ? 'pt-xl border-top-1' : ''
          } ${isMobile ? 'mx-n4 px-m' : 'mx-n5 px-xl'}`}
        >
          <PoolDetails template={template} />
          <Switch condition={canUpload}>
            <div>
              <PoolUploadForm
                template={template}
                couponPool={couponPool}
                fromDetailsPage
              />
            </div>
          </Switch>
        </div>
      </Switch>
    </>
  )
}

export default CouponCodes
