import { AlertBanner, OrderingBanner } from 'models/Banner'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_ALERT_BANNER = 'banner/GET_ALERT_BANNER'
export const GET_ALERT_BANNER_SUCCESS = 'banner/GET_ALERT_BANNER_SUCCESS'
export const GET_ALERT_BANNER_FAIL = 'banner/GET_ALERT_BANNER_FAIL'

export const UPDATE_ALERT_BANNER = 'banner/UPDATE_ALERT_BANNER'
export const UPDATE_ALERT_BANNER_SUCCESS = 'banner/UPDATE_ALERT_BANNER_SUCCESS'
export const UPDATE_ALERT_BANNER_FAIL = 'banner/UPDATE_ALERT_BANNER_FAIL'

export const GET_ORDERING_BANNER = 'banner/GET_ORDERING_BANNER'
export const GET_ORDERING_BANNER_SUCCESS = 'banner/GET_ORDERING_BANNER_SUCCESS'
export const GET_ORDERING_BANNER_FAIL = 'banner/GET_ORDERING_BANNER_FAIL'

export const UPDATE_ORDERING_BANNER = 'banner/UPDATE_ORDERING_BANNER'
export const UPDATE_ORDERING_BANNER_SUCCESS =
  'banner/UPDATE_ORDERING_BANNER_SUCCESS'
export const UPDATE_ORDERING_BANNER_FAIL = 'banner/UPDATE_ORDERING_BANNER_FAIL'

export type BannerData = {
  id?: number
  text?: string
  file_uploads?: {
    banner_image: {
      file_upload_id: number
    }
  }
}

export function getAlertBanner(): AxiosAction<
  typeof GET_ALERT_BANNER,
  { alert_banner: AlertBanner }
> {
  return {
    type: GET_ALERT_BANNER,
    payload: {
      client: 'default',
      request: {
        url: 'alert_banner',
        method: 'get',
      },
    },
  }
}

export function updateAlertBanner(
  alertBanner: AlertBanner
): AxiosAction<typeof UPDATE_ALERT_BANNER, { alert_banner: AlertBanner }> {
  return {
    type: UPDATE_ALERT_BANNER,
    payload: {
      client: 'default',
      request: {
        url: 'alert_banner',
        method: 'put',
        data: {
          alert_banner: alertBanner,
        },
      },
    },
  }
}

export function getOrderingBanner(): AxiosAction<
  typeof GET_ORDERING_BANNER,
  { ordering_banner: OrderingBanner }
> {
  return {
    type: GET_ORDERING_BANNER,
    payload: {
      client: 'default',
      request: {
        url: 'ordering_banner',
        method: 'get',
      },
    },
  }
}

export function updateOrderingBanner(
  orderingBanner: BannerData
): AxiosAction<
  typeof UPDATE_ORDERING_BANNER,
  { ordering_banner: OrderingBanner }
> {
  return {
    type: UPDATE_ORDERING_BANNER,
    payload: {
      client: 'default',
      request: {
        url: 'ordering_banner',
        method: 'put',
        data: {
          ordering_banner: orderingBanner,
        },
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getAlertBanner>>
  | ResolvedAxiosAction<ReturnType<typeof updateAlertBanner>>
  | ResolvedAxiosAction<ReturnType<typeof getOrderingBanner>>
  | ResolvedAxiosAction<ReturnType<typeof updateOrderingBanner>>
