import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import { captureRateReportUrl } from 'utilities/urlUtils'

type PropsT = {
  remainingDays: number | null
}

const t = buildTranslate('capture_rate.empty')

const NoContent: React.FC<PropsT> = ({ remainingDays }) => {
  const [css] = useStyletron()
  const message =
    remainingDays !== null
      ? t('subtitle', { count: remainingDays })
      : t('subtitle_not_launched')

  return (
    <div className="w-100 d-flex flex-column align-items-center text-center">
      <Text.Header4 className="mb-xs" tag="div">
        {t('title')}
      </Text.Header4>
      <Text.BodyText3
        className={css({ maxWidth: '700px' })}
        color="grey70"
        tag="div"
      >
        {message}
      </Text.BodyText3>
      <div className="mt-s">
        <a href={captureRateReportUrl} target="_blank" rel="noreferrer">
          {t('learn_more')}
        </a>
      </div>
    </div>
  )
}

export default NoContent
