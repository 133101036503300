import { Button, Kind } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import EditNavbar from 'components/Navbar/EditNavbar'
import { buildTranslate } from 'locales'
import React, { ReactNode } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import { LinkContainer } from 'react-router-bootstrap'
import { merchantTheme } from 'theme'
import { useIsMobile } from 'utilities/responsive'

type Props = {
  isEditing?: boolean
  canProceed?: boolean
  canSendInvitation?: boolean
  onFinish?: () => void
  onSendInvitation?: () => void
  children: ReactNode
}

const FormLayout = ({
  isEditing = false,
  canProceed = true,
  canSendInvitation = true,
  onFinish,
  onSendInvitation,
  children,
}: Props) => {
  const t = buildTranslate(`merchant_users.${isEditing ? 'edit' : 'new'}`)
  const [css] = useStyletron()
  const isMobile = useIsMobile()

  return (
    <div
      className={`mt-m full-width ${css({
        backgroundColor: merchantTheme.colors.grey05,
      })}`}
    >
      <EditNavbar
        title={
          <Switch condition={!isMobile}>
            <Text.SmallCaps3 tag="div" className="mb-xs" color="white">
              {t('subtitle')}
            </Text.SmallCaps3>
            <Text.Header4 tag="div" bold color="white">
              {t('title')}
            </Text.Header4>
          </Switch>
        }
      >
        <LinkContainer to="/merchant_users">
          <Button kind={Kind.SECONDARY_REVERSE} className="mr-s">
            {t('cancel')}
          </Button>
        </LinkContainer>
        {isEditing && canSendInvitation ? (
          <Button
            kind={Kind.PRIMARY_REVERSE}
            onClick={onSendInvitation}
            className="mr-s"
          >
            {t('resend_invitation')}
          </Button>
        ) : null}
        <Button
          kind={Kind.PRIMARY_REVERSE}
          onClick={onFinish}
          disabled={!canProceed}
        >
          {t('continue')}
        </Button>
      </EditNavbar>
      <Container className="h-100">
        <Row>
          <Col className="p-xl">{children}</Col>
        </Row>
      </Container>
    </div>
  )
}

export default FormLayout
