import {
  initializeCampaignVariant,
  updateCampaignVariant,
} from 'actions/campaignVariants'
import noPushIcon from 'assets/images/campaign-center/builder/img_noPush.svg'
import pushIcon from 'assets/images/campaign-center/builder/img_push.svg'
import EmptyContentSection from 'components/review/EmptyContentSection'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import PushPreview from 'scenes/CampaignCenter/Builder/components/Previews/Push'
import { selectIsBrandedApp } from 'selectors/app'
import Channel from './Channel'
import Edit from './Options/Edit'
import Remove from './Options/Remove'
import RemoveModal from './RemoveModal'

const t = buildTranslate('thanx_campaigns.builder.steps.setup.messaging.push')

type PropsT = {
  campaign: Campaign
  config: VariantOrCampaign
}

const PushChannel: React.FC<PropsT> = ({ campaign, config }) => {
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const dispatch = useDispatch()
  const isBrandedApp = useSelector(selectIsBrandedApp)

  async function handleAdd() {
    setIsAdding(true)
    await dispatch(
      initializeCampaignVariant(campaign.id, config.id, { channel: 'push' })
    )
    setIsAdding(false)

    dispatch(push(`/thanx_campaigns/${campaign.id}/push/${config.id}`))
  }

  async function handleEdit() {
    setIsAdding(true)
    setIsAdding(false)

    dispatch(push(`/thanx_campaigns/${campaign.id}/push/${config.id}`))
  }

  async function handleRemovePrompt() {
    setRemoveModalOpen(true)
  }

  async function handleRemove() {
    setIsRemoving(true)
    await dispatch(
      updateCampaignVariant(campaign.id, config.id, {
        ...config,
        notify_push: false,
      })
    )
    setIsRemoving(false)
    setRemoveModalOpen(false)
  }

  const requiredRewardMissing = !isBrandedApp && !config.redeem

  if (!config.notify_push) {
    return (
      <EmptyContentSection
        icon={noPushIcon}
        isEditable={
          config.internal_type !== 'control' && !requiredRewardMissing
        }
        isAdding={isAdding}
        onAdd={handleAdd}
        addText={t('add')}
        text={t(requiredRewardMissing ? 'missing_reward' : 'missing')}
      />
    )
  }

  return (
    <>
      <Channel
        title={t('title')}
        icon={pushIcon}
        actions={
          <div className="d-flex">
            <Remove
              title={t('remove_modal.title')}
              onRemove={handleRemovePrompt}
            />
            <Edit title={t('edit')} onEdit={handleEdit} />
          </div>
        }
      >
        <PushPreview text={config.notify_push_text} />
      </Channel>
      <RemoveModal
        onRemove={handleRemove}
        isRemoving={isRemoving}
        campaign={campaign}
        variant={config}
        channel="push"
        isOpen={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
      />
    </>
  )
}

export default PushChannel
