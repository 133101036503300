'use strict'
var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i]
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
        }
        return t
      }
    return __assign.apply(this, arguments)
  }
exports.__esModule = true
var jsx_runtime_1 = require('react/jsx-runtime')
var react_redux_i18n_1 = require('react-redux-i18n')
var TrashIcon = function () {
  return (0, jsx_runtime_1.jsxs)(
    'svg',
    __assign(
      {
        width: '250',
        height: '250',
        viewBox: '0 0 250 250',
        fill: 'none',
        xmlns: 'http://www.w3.org/2000/svg',
      },
      {
        children: [
          (0, jsx_runtime_1.jsx)('title', {
            children: react_redux_i18n_1.I18n.t(
              'thanx_campaigns.landing.reporting.table.settings.draft.trash_icon'
            ),
          }),
          (0, jsx_runtime_1.jsxs)(
            'g',
            __assign(
              { clipPath: 'url(#clip0)' },
              {
                children: [
                  (0, jsx_runtime_1.jsx)('path', {
                    d: 'M125 250C194.036 250 250 194.036 250 125C250 55.9644 194.036 0 125 0C55.9644 0 0 55.9644 0 125C0 194.036 55.9644 250 125 250Z',
                    fill: '#FBDFD6',
                  }),
                  (0, jsx_runtime_1.jsx)('path', {
                    d: 'M60.08 231.838C79.0025 243.36 101.225 250 125 250C148.775 250 170.998 243.36 189.92 231.838L200 124.998H50L60.08 231.838Z',
                    fill: '#F26335',
                  }),
                  (0, jsx_runtime_1.jsx)('path', {
                    d: 'M50 124.998L52.0625 146.868C62.8175 161.808 91.405 172.495 125 172.495C158.595 172.495 187.185 161.808 197.938 146.865L200 124.998H50Z',
                    fill: '#9D3311',
                  }),
                  (0, jsx_runtime_1.jsx)('path', {
                    d: 'M197.47 114.93C203.767 121.228 202.5 128.513 202.5 128.745C202.5 150.148 167.802 167.495 125 167.495C82.1974 167.495 47.4999 150.148 47.4999 128.745C47.4999 128.598 46.3699 120.998 52.3924 114.975C56.8724 109.943 193.08 109.918 197.47 114.93Z',
                    fill: '#F26335',
                  }),
                  (0, jsx_runtime_1.jsx)('path', {
                    d: 'M125 84.9975C166.42 84.9975 200 101.228 200 121.248C200 141.268 166.42 157.498 125 157.498C83.58 157.498 50 141.27 50 121.248C50 101.228 83.58 84.9975 125 84.9975Z',
                    fill: '#BC3D15',
                  }),
                  (0, jsx_runtime_1.jsx)('path', {
                    d: 'M125 94.9975C162.91 94.9975 194.23 108.595 199.272 126.248C199.74 124.613 200 122.945 200 121.248C200 101.228 166.42 84.9975 125 84.9975C83.58 84.9975 50 101.228 50 121.248C50 122.945 50.26 124.613 50.7275 126.248C55.77 108.595 87.09 94.9975 125 94.9975Z',
                    fill: '#9D3311',
                  }),
                  (0, jsx_runtime_1.jsx)('path', {
                    d: 'M82.5 187.495C82.5 184.735 80.26 182.498 77.5 182.498C74.74 182.498 72.5 184.738 72.5 187.495L76.26 240.14C79.6075 241.558 83.0425 242.813 86.5325 243.943L82.5 187.495ZM105 192.495C105 189.735 102.76 187.495 100 187.495C97.24 187.495 95 189.735 95 192.495L97.09 246.833C100.402 247.588 103.758 248.225 107.163 248.71L105 192.495ZM172.5 182.498C169.74 182.498 167.5 184.738 167.5 187.495L163.47 243.94C166.96 242.813 170.393 241.558 173.74 240.138L177.5 187.495C177.5 184.738 175.26 182.498 172.5 182.498ZM130 249.873V194.995C130 192.235 127.76 189.995 125 189.995C122.24 189.995 120 192.235 120 194.995V249.873C121.662 249.938 123.322 250 125 250C126.678 250 128.34 249.94 130 249.873ZM150 187.495C147.24 187.495 145 189.735 145 192.495L142.837 248.71C146.242 248.225 149.6 247.588 152.91 246.833L155 192.495C155 189.738 152.76 187.495 150 187.495Z',
                    fill: '#9D3311',
                  }),
                  (0, jsx_runtime_1.jsx)(
                    'mask',
                    __assign(
                      {
                        id: 'mask0',
                        'mask-type': 'alpha',
                        maskUnits: 'userSpaceOnUse',
                        x: '49',
                        y: '27',
                        width: '151',
                        height: '131',
                      },
                      {
                        children: (0, jsx_runtime_1.jsx)('path', {
                          d: 'M125 27.5C197 27.5 200 32 200 121.248C200 141.268 166.42 157.498 125 157.498C83.58 157.498 50 141.27 50 121.248C50 39 46.5 27.5 125 27.5Z',
                          fill: '#BC3D15',
                        }),
                      }
                    )
                  ),
                  (0, jsx_runtime_1.jsxs)(
                    'g',
                    __assign(
                      { mask: 'url(#mask0)' },
                      {
                        children: [
                          (0, jsx_runtime_1.jsx)('rect', {
                            x: '118.047',
                            y: '53.5975',
                            width: '126.14',
                            height: '67.6109',
                            rx: '6',
                            transform: 'rotate(56.7853 118.047 53.5975)',
                            fill: 'white',
                          }),
                          (0, jsx_runtime_1.jsx)(
                            'mask',
                            __assign(
                              {
                                id: 'mask1',
                                'mask-type': 'alpha',
                                maskUnits: 'userSpaceOnUse',
                                x: '61',
                                y: '53',
                                width: '127',
                                height: '144',
                              },
                              {
                                children: (0, jsx_runtime_1.jsx)('rect', {
                                  x: '118.047',
                                  y: '53.5975',
                                  width: '126.14',
                                  height: '67.6109',
                                  rx: '6',
                                  transform: 'rotate(56.7853 118.047 53.5975)',
                                  fill: 'white',
                                }),
                              }
                            )
                          ),
                          (0, jsx_runtime_1.jsx)(
                            'g',
                            __assign(
                              { mask: 'url(#mask1)' },
                              {
                                children: (0, jsx_runtime_1.jsx)('path', {
                                  d: 'M117.704 48.9975L119.377 47.9019C118.886 47.1522 117.957 46.8205 117.102 47.0902C116.248 47.3598 115.677 48.1649 115.705 49.0606L117.704 48.9975ZM120.222 128.766L118.223 128.829C118.247 129.583 118.693 130.259 119.377 130.578L120.222 128.766ZM191.776 162.127L190.93 163.94C191.74 164.317 192.702 164.112 193.287 163.436C193.872 162.761 193.938 161.779 193.449 161.032L191.776 162.127ZM115.705 49.0606L118.223 128.829L122.221 128.702L119.703 48.9344L115.705 49.0606ZM119.377 130.578L190.93 163.94L192.621 160.315L121.068 126.953L119.377 130.578ZM193.449 161.032L119.377 47.9019L116.031 50.093L190.102 163.223L193.449 161.032Z',
                                  fill: '#E9EAEC',
                                }),
                              }
                            )
                          ),
                        ],
                      }
                    )
                  ),
                ],
              }
            )
          ),
          (0, jsx_runtime_1.jsx)('defs', {
            children: (0, jsx_runtime_1.jsx)(
              'clipPath',
              __assign(
                { id: 'clip0' },
                {
                  children: (0, jsx_runtime_1.jsx)('rect', {
                    width: '250',
                    height: '250',
                    fill: 'white',
                  }),
                }
              )
            ),
          }),
        ],
      }
    )
  )
}
exports['default'] = TrashIcon
