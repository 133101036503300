import ThanxDatePicker from 'components/Form/DatePicker'
import { Fields as CampaignFields } from 'models/Campaign'
import moment from 'moment-timezone'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import userTimeZone from 'utilities/userTimeZone'

type Props = {
  campaign: CampaignFields
}

export function mapStart(value?: string): string {
  return value ? moment(value).tz(userTimeZone()).startOf('day').format() : ''
}

export function mapEnd(value?: string): string {
  return value ? moment(value).tz(userTimeZone()).endOf('day').format() : ''
}

const RestrictionStartEnd: React.FC<Props> = ({ campaign }) => {
  return (
    <div>
      <Row>
        <Col lg={4} xs={4} className="padding-right-none">
          <ThanxDatePicker
            name="redeem_start_at"
            value={campaign.redeem_start_at}
            mapValue={mapStart}
            label={I18n.t(
              'thanx_campaigns.builder.steps.incentive.restriction_creator.start_end.start_label'
            )}
            validations={{
              lessThanDate: campaign.redeem_end_at,
            }}
            validationErrors={{
              lessThanDate: I18n.t('validations.less_than_date', {
                dateName: 'end date',
              }),
            }}
          />
        </Col>
        <Col
          lg={4}
          xs={4}
          lgOffset={1}
          xsOffset={1}
          className="padding-left-none"
        >
          <ThanxDatePicker
            name="redeem_end_at"
            value={campaign.redeem_end_at}
            mapValue={mapEnd}
            label={I18n.t(
              'thanx_campaigns.builder.steps.incentive.restriction_creator.start_end.end_label'
            )}
            validations={{
              greaterThanDate: campaign.redeem_start_at,
            }}
            validationErrors={{
              greaterThanDate: I18n.t('validations.greater_than_date', {
                dateName: 'start date',
              }),
            }}
          />
        </Col>
      </Row>
    </div>
  )
}

export default RestrictionStartEnd
