import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import Header from '../../components/Header'
import Screens from './Screens'

const t = buildTranslate('cms.brand.app_store_assets')

const AppStoreScreens: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const {
    ios_iphone_max_screenshots_image_urls: iosMaxScreens,
    ios_iphone_screenshots_image_urls: iosScreens,
  } = app

  return (
    <Section className="p-xl mt-l">
      <Header
        title={t('app_store_screens.title')}
        description={t('app_store_screens.description')}
        link="https://duisjpf052nxj.cloudfront.net/assets/mx/Launch+Screen+template.fig"
        linkText={t('app_store_screens.link_text')}
      />
      <Screens
        title={t('app_store_screens.screens_6')}
        size={t('app_store_screens.screens_6_size')}
        height={'325px'}
        screens={iosMaxScreens}
        className="mt-m"
      />
      <Screens
        title={t('app_store_screens.screens_5')}
        size={t('app_store_screens.screens_5_size')}
        height={'267px'}
        screens={iosScreens}
        className="mt-l"
      />
    </Section>
  )
}

export default AppStoreScreens
