import { Checkbox } from '@thanx/uikit/checkbox'
import { Select } from '@thanx/uikit/select'
import { useStyletron } from '@thanx/uikit/theme'
import { GetFeedbacksParams } from 'api/feedbacks'
import { Location } from 'api/locations'
import { buildTranslate } from 'locales'
import { Link } from 'react-router-dom'
import { merchantTheme } from 'theme'

const t = buildTranslate('feedbacks.responses')

type OptionsType = {
  label: string
  value: number | null
}

type Props = {
  filters: GetFeedbacksParams
  locations: Location[]
  updateFilters: (updatedFilters: GetFeedbacksParams) => void
}

const Filter = (props: Props) => {
  const { filters, updateFilters, locations } = props

  const [css] = useStyletron()
  const options: OptionsType[] = locations.map(location => ({
    label: location.street,
    value: location.id,
  }))
  options.unshift({
    label: t('filters.location.default_option'),
    value: null,
  })
  const value =
    options.find(option => option.value === filters.location_id) || options[0]

  function clearFilters() {
    updateFilters({
      unread: false,
      has_review: false,
      critical: false,
    })
  }

  return (
    <>
      <div
        className={`my-xl ${css({
          maxWidth: '500px',
          backgroundColor: merchantTheme.colors.white,
        })}`}
      >
        <Select
          options={options}
          value={value}
          maxDropdownHeight="200px"
          onChange={option => updateFilters({ location_id: option[0].value })}
        />
      </div>
      <div className="mb-xl d-flex">
        <Checkbox
          className="mr-xl d-inline-flex"
          name="unread"
          checked={filters.unread}
          onChange={unread => updateFilters({ unread: unread })}
        >
          <span>{t('filters.unread')}</span>
        </Checkbox>
        <Checkbox
          className="mr-xl d-inline-flex"
          name="has_review"
          checked={filters.has_review}
          onChange={hasReview => updateFilters({ has_review: hasReview })}
        >
          <span>{t('filters.has_review')}</span>
        </Checkbox>
        <Checkbox
          className="mr-xl d-inline-flex"
          name="unread"
          checked={filters.critical}
          onChange={critical => updateFilters({ critical: critical })}
        >
          <span>{t('filters.critical')}</span>
        </Checkbox>
        <div className="d-inline-flex">
          <Link to="#" onClick={clearFilters}>
            {t('filters.view_all')}
          </Link>
        </div>
      </div>
    </>
  )
}

export default Filter
