import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { PushMessageCountMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
}

const Delivered: React.FC<PropsT> = props => {
  const { campaignId } = props
  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'push_message_count')
    ) as PushMessageCountMetric) || {}
  return (
    <InfoRow
      title={t('engagement.push.delivered')}
      primaryContent={
        <Metric
          // Delivered is unique as it is the only % to 2 decimal places
          metric={percentageFormatter(metric?.data?.delivered_rate, 2, 2)}
        >
          <PopoverContent
            sections={[
              {
                content: t('engagement.push.tooltips.percent_of_delivered'),
              },
              {
                title: t('how_its_calc'),
                content: t('engagement.push.tooltips.percent_delivered_calc'),
              },
            ]}
          />
        </Metric>
      }
      secondaryContent={
        <Metric
          primary={false}
          metric={numberFormatter(metric?.data?.delivered_count)}
        >
          <PopoverContent
            sections={[
              {
                content: t('engagement.push.tooltips.number_of_delivered'),
              },
              {
                title: t('how_its_calc'),
                content: t('engagement.push.tooltips.number_delivered_calc'),
              },
            ]}
          />
        </Metric>
      }
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default Delivered
