import { Button } from '@thanx/uikit/button'
import { buildTranslate } from 'locales'
import React from 'react'
const t = buildTranslate('cms.content.app_home_page.content.block_item')

type Props = {
  onClick: VoidFunction
}

const AddContentButton = ({ onClick }: Props) => {
  return (
    <Button className="mt-m" onClick={onClick}>
      {t('add_content')}
    </Button>
  )
}

export default AddContentButton
