import { INDEX_SUCCESS, ResolvedAction } from 'actions/locationCategory'
import { RESET_DB } from 'actions/orm'
import { attr, Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  title: string
}

export default class LocationCategory extends Model<
  // @ts-ignore
  typeof LocationCategory,
  Fields
> {
  static modelName: string = 'LocationCategory'

  static fields = {
    id: attr(),
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    LocationCategory: ModelType<LocationCategory>
  ) {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case INDEX_SUCCESS:
        action.payload.data.location_categories.forEach(category =>
          LocationCategory.upsert(category)
        )
        break
      case RESET_DB:
        LocationCategory.all().delete()
        break
      default:
    }
  }
}
