import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import { cmsSignupWhereCustomersSeeUrl } from 'utilities/urlUtils'
import CmsPage from '../components/CmsPage'

const t = buildTranslate('cms.signup.signup_message')

const SignupMessage: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const { signup_welcome } = app

  return (
    <CmsPage title={t('title')} description={t('description')}>
      <Section className="mt-l p-xl d-flex justify-content-between">
        <p className="grey-70">{signup_welcome}</p>
        <a
          href={cmsSignupWhereCustomersSeeUrl}
          className="body-text-3"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('where_customers')}
        </a>
      </Section>
    </CmsPage>
  )
}

export default SignupMessage
