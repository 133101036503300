import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { Switch } from 'react-router-dom'
import EditMerchantUser from './EditMerchantUser'
import Landing from './Landing'
import NewMerchantUser from './NewMerchantUser'

const MerchantUsers = () => {
  return (
    <Switch>
      <ProtectedRoute
        redirect="/"
        resource="MerchantUsers"
        component={Landing}
        path="/merchant_users"
        exact
      />
      <ProtectedRoute
        redirect="/"
        resource="MerchantUsers"
        component={NewMerchantUser}
        path="/merchant_users/new"
        exact
      />
      <ProtectedRoute
        redirect="/"
        resource="MerchantUsers"
        component={EditMerchantUser}
        path="/merchant_users/edit/:id"
        exact
      />
    </Switch>
  )
}

export default MerchantUsers
