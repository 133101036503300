import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Content from './Content'
import { EditContextProvider } from './EditContext'

const CreateEdit: React.FC<RouteComponentProps<{ id?: string }>> = ({
  match,
}) => {
  const editMatch = match?.params?.id

  return (
    <div className="h-100">
      <EditContextProvider id={editMatch}>
        <Content />
      </EditContextProvider>
    </div>
  )
}

export default CreateEdit
