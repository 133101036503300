import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import keys from 'lodash/keys'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import transform from 'lodash/transform'
import { CampaignConfig, Fields as Campaign } from 'models/Campaign'
import { modelsAreEqual } from 'utilities/formUtils'
import userTimeZone from 'utilities/userTimeZone'

export function addAdditionalFields(campaignModel) {
  if (campaignModel.redeem_restriction_time_range) {
    campaignModel.redeem_restriction_time_zone = userTimeZone()
  }
  if (campaignModel.redeem_type) {
    let redeem_type = campaignModel.redeem_type

    if (
      campaignModel.automatic &&
      !campaignModel.redeem_type.includes('item')
    ) {
      campaignModel.redeem_type = redeem_type.replace('manual', 'automatic')
    }

    if (
      campaignModel.redeem_type.includes('automatic') &&
      !campaignModel.automatic
    ) {
      campaignModel.redeem_type = redeem_type.replace('automatic', 'manual')
    }
  }

  delete campaignModel['automatic']

  return campaignModel
}

export function filterFields(campaignModel) {
  return omitBy(
    campaignModel,
    (value, key: string) => /^[_]{2}/.test(key) || isUndefined(value)
  )
}

export function canSave(
  campaign: Campaign,
  config: CampaignConfig,
  model: any,
  isSaving: boolean
): boolean {
  if (isSaving) return false
  if (campaign && !includes(['draft', 'setup', 'in_review'], campaign.state))
    return false

  // only parts of the form will be available at any one time. compare what we
  // know about the state of the form (the model) to the matching
  // subset of the API response. if they are the same, no need to save.
  const accessibleCampaignFields = pick(campaign, keys(model))
  if (config.campaign_config_type === 'campaign')
    return !modelsAreEqual(accessibleCampaignFields, model)

  const accessibleCampaignConfigFields = pick(config, keys(model))

  return (
    (!isEmpty(accessibleCampaignFields) &&
      !modelsAreEqual(accessibleCampaignFields, model)) ||
    (!isEmpty(accessibleCampaignConfigFields) &&
      !modelsAreEqual(accessibleCampaignConfigFields, model))
  )
}

export const modelsToSave = (
  extractedCampaign,
  extractedVariant,
  campaignModel: any
): Array<string> => {
  const accessibleCampaignFields = pick(extractedCampaign, keys(campaignModel))
  const accessibleVariantFields = pick(extractedVariant, keys(campaignModel))
  let models: Array<string> = []
  if (!isEmpty(accessibleCampaignFields)) models.push('campaign')
  if (!isEmpty(accessibleVariantFields)) models.push('variant')
  return models
}

export const extractVariantFields = (campaignFields: Campaign) => {
  const variantFields = [
    'campaign_id',
    'variant_name',
    'campaign_config_type',
    'id',
    'fine_print',
    'internal_type',
    'notify_email',
    'notify_email_body',
    'notify_email_button_text',
    'notify_email_button_text_color',
    'notify_email_button_url',
    'notify_email_clickable',
    'notify_email_configuration',
    'notify_email_conversion',
    'notify_email_header_image_included',
    'notify_email_header_image_urls',
    'notify_email_header_url',
    'notify_email_heading',
    'notify_email_hero_image_included',
    'notify_email_hero_image_urls',
    'notify_email_hero_url',
    'notify_email_link_color',
    'notify_email_preview_text',
    'notify_email_redeem_box_color',
    'notify_email_redeem_box_text_color',
    'notify_email_redeem_button_color',
    'notify_email_redeem_button_text_color',
    'notify_email_subject',
    'notify_email_variant',
    'notify_push',
    'notify_push_text',
    'notify_sms',
    'notify_sms_text',
    'redeem',
    'redeem_description',
    'redeem_discount',
    'redeem_discounted_product_ids?',
    'redeem_experience_expires_in_days',
    'redeem_fine_print',
    'redeem_maximum',
    'redeem_minimum',
    'redeem_required_product_ids?',
    'redeem_restriction',
    'redeem_restriction_description',
    'redeem_restriction_friday',
    'redeem_restriction_location_ids',
    'redeem_restriction_locations_description',
    'redeem_restriction_monday',
    'redeem_restriction_saturday',
    'redeem_restriction_sunday',
    'redeem_restriction_thursday',
    'redeem_restriction_time_range',
    'redeem_restriction_time_range_description',
    'redeem_restriction_time_zone',
    'redeem_restriction_tuesday',
    'redeem_restriction_wednesday',
    'redeem_retail_value',
    'redeem_subtype',
    'redeem_template_id',
    'redeem_type',
    'redeem_uses_coupon_codes',
    'redeem_venue',
  ]
  return [
    omit(campaignFields, variantFields),
    pick(campaignFields, variantFields),
  ]
}

export const overrideFields = (config, model) =>
  transform(
    //@ts-ignore
    config,
    (result, value, key) => {
      includes(keys(model), key.toString())
        ? (result[key] = model[key])
        : (result[key] = value)
    },
    {}
  )
