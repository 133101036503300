import {
  CREATE_LANDING_PAGE_SUCCESS,
  GET_LANDING_PAGE_SUCCESS,
  ResolvedAction,
} from 'actions/landingPage'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  app_id: number
  state: 'active' | 'inactive'
}

export default class LandingPage extends Model<typeof LandingPage, Fields> {
  static modelName: string = 'LandingPage'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    LandingPage: ModelType<LandingPage>,
    session
  ) {
    switch (action.type) {
      case CREATE_LANDING_PAGE_SUCCESS:
      case GET_LANDING_PAGE_SUCCESS:
        LandingPage.upsert(action.payload.data.app_landing_page)
        action.payload.data.app_landing_page.headers.forEach(header => {
          session.LandingHeader.upsert(header)
        })
        action.payload.data.app_landing_page.links.forEach(link => {
          session.LandingLink.upsert(link)
        })
        break
      case RESET_DB:
        LandingPage.all().delete()
        break
      default:
        break
    }
  }
}
