import RewardPreviewCard from 'components/RewardPreviewCard'
import { CampaignConfig } from 'models/Campaign'
import React from 'react'
import NoRedeem from 'scenes/CampaignCenter/Builder/components/Previews/NoRedeem'

type PropsT = {
  config: CampaignConfig
}

const RightSide: React.FC<PropsT> = props => {
  const { config } = props

  if (!config.redeem) return <NoRedeem />
  const className =
    config.redeem_venue !== 'online' ? '' : 'text-left grey-05-bkg'
  return (
    <div className="text-center p-xxxl">
      <RewardPreviewCard
        className={className}
        description={config.redeem_description}
        templateId={config.redeem_template_id}
      />
    </div>
  )
}

export default RightSide
