import { Select } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import HelpLink from 'components/HelpLink'
import Error from 'components/Icons/Error'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import {
  OverTimeConversionSeriesMetric,
  OvertimeConversionTimeSeriesShotType,
} from 'models/MerchantMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  stagesSegmentsMapping,
  stagesWithDetailedReport,
} from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { selectMerchantMetricByType } from 'selectors/merchantMetric'
import { conversionOverTimeHelpUrl } from 'utilities/urlUtils'
import { useUserTimeZone } from 'utilities/userTimeZone'
import LineChart from './LineChart'

const t = buildTranslate('lifecycle_report.detail.over_time_chart')

type Props = {
  stage: typeof stagesWithDetailedReport[number]
  timespan: string
  setTimespan: Function
}

const OverTimeConversionChart: React.FC<Props> = ({
  stage,
  timespan,
  setTimespan,
}) => {
  const segment = stagesSegmentsMapping[stage]

  const [css] = useStyletron()
  const memberLifecycleOverTime = useSelector(state =>
    selectMerchantMetricByType(
      state,
      segment as OvertimeConversionTimeSeriesShotType
    )
  ) as OverTimeConversionSeriesMetric
  const userTimeZone = useUserTimeZone()
  const timezone = memberLifecycleOverTime?.time_zone || userTimeZone
  if (!memberLifecycleOverTime) {
    return null
  }
  const isLoading = !!memberLifecycleOverTime.isLoading
  const isErrored = !!memberLifecycleOverTime.isErrored

  const timespanOptions = [
    {
      label: t('timespan.six_months'),
      value: '6_months',
    },
    {
      label: t('timespan.twelve_months'),
      value: '12_months',
    },
    {
      label: t('timespan.twenty_four_months'),
      value: '24_months',
    },
    {
      label: t('timespan.all_time'),
      value: 'all_time',
    },
  ]

  const value = timespanOptions.find(option => option.value === timespan)

  return (
    <>
      <div
        className={`w-100 h-100 pl-0 pr-s py-l white-bkg border-1 grey-20-border position-relative ${css(
          {
            borderRadius: '4px',
          }
        )}`}
      >
        <div className="d-flex flex-align-center flex-space-between mb-l">
          <div className="ml-l">
            <Text.Header4 bold className="mt-0 mb-0">
              {t(`title.${stage}`)}
            </Text.Header4>
          </div>
          <div className="d-flex align-items-center mr-s ml-s">
            <Spinner isLoading={isLoading} />
            {isErrored && (
              <div className={`text-nowrap ${css({ lineHeight: '20px' })}`}>
                <Error />
                <span className="cayenne-50 ml-xs align-middle">
                  <Text.BodyText4 color="inherit">{t('error')}</Text.BodyText4>
                </span>
              </div>
            )}
            <span
              className={`ml-s
                ${css({ width: '200px' })}
              `}
            >
              <Select
                options={timespanOptions}
                value={value}
                disabled={isLoading}
                onChange={newTimespan => {
                  setTimespan(newTimespan[0].value)
                }}
                searchable={false}
              />
            </span>
          </div>
        </div>
        <LineChart
          stage={stage}
          timezone={timezone}
          series={memberLifecycleOverTime?.data || []}
        />
      </div>
      <div className="d-flex justify-content-end">
        <HelpLink className="pr-0 mt-xs" url={conversionOverTimeHelpUrl}>
          {t('help_link')}
        </HelpLink>
      </div>
    </>
  )
}

export default OverTimeConversionChart
