export const paths = {
  campaigns: {
    paths: [
      '/thanx_campaigns',
      '/thanx_campaigns/targeted',
      '/thanx_campaigns/automated',
      '/thanx_campaigns?selected=message',
      '/thanx_campaigns/reports',
    ],
    id: '0',
  },
  loyalty: {
    paths: ['/points', '/loyalty'],
    id: '1',
  },
  rewards: {
    paths: ['/rewards'],
    id: '2',
  },
  customers: {
    paths: [
      '/users',
      '/reports/customer',
      '/reports/lifecycle',
      '/reports/activation',
      '/reports/retention_rate',
      '/reports/revenue',
      '/reports/purchases',
      '/reports/capture_rate',
    ],
    id: '3',
  },
  feedback: {
    paths: ['/responses'],
    id: '4',
  },
  experiences: {
    paths: [
      '/brand_content',
      '/ordering_management',
      '/reports/ordering_conversion',
    ],
    id: '5',
  },
}
