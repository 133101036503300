import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import type { Fields as LocationCategory } from '../models/LocationCategory'

export const selectAllLocationCategories = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): LocationCategory[] => {
    return session.LocationCategory.all().toRefArray()
  }
)

export const selectLocationCategoriesById = createSelector(
  selectAllLocationCategories,
  (_, ids): number[] => ids,
  (locations, ids): LocationCategory[] =>
    locations.filter(location => ids.includes(location.id))
)
