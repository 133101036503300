import { FormGroup } from '@thanx/uikit/form-group'
import { Radio, RadioGroup } from '@thanx/uikit/radio'
import { getValidationErrorsString } from '@thanx/uikit/utils'
import { withFormsy } from 'formsy-react'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import DatetimePicker from 'scenes/CampaignCenter/components/DatetimePicker'
import { useUserTimeZone } from 'utilities/userTimeZone'

import { PassDownProps } from 'formsy-react/dist/withFormsy'

type Props = {
  className?: string
  label?: React.ReactNode
  isNullable?: boolean
  nullLabel?: React.ReactNode
  nonNullLabel?: React.ReactNode
  calendarPlacement?: 'top' | 'bottom'
}

/**
 * A formsy connected datetime picker that can also choose a null value.
 * If isNullable is true, this will have a radio button that selects null or
 * not null and shows the datetime picker if its not null.
 * This is intended for use on the review page schedule section and might not
 * be easily used in other areas, but hopefully should work like you'd expect
 * with isNullable=false.
 */
const FormsyDatetimePicker: React.FC<
  Props & PassDownProps<string | moment.Moment | null>
> = props => {
  const {
    className = '',
    isNullable = false,
    label,
    nullLabel,
    nonNullLabel,
    calendarPlacement,
    value,
    setValue,
  } = props

  const userTimeZone = useUserTimeZone()

  const [isNull, setIsNull] = useState(value === null)
  const [time, setTime] = useState<moment.Moment>(
    value
      ? moment.tz(value, userTimeZone)
      : moment.tz(userTimeZone).add(1, 'hour').startOf('hour')
  )

  // If the field is non nullable and the value is null, set the value to the
  // default value.
  useEffect(() => {
    if (isNullable || value != null) return

    setValue(time.toISOString())
  }, [value, setValue, time, isNullable])

  function handleRadioChange(
    value: React.ChangeEvent<HTMLInputElement> | 'null' | 'nonNull'
  ) {
    setIsNull(value === 'null')
    setValue(value === 'null' ? null : time.toISOString())
  }

  function handleTimeChange(value: moment.Moment) {
    setTime(value)
    setValue(value.toISOString())
  }

  const showPicker = !isNullable || !isNull

  return (
    <FormGroup
      className={className}
      label={label}
      errors={getValidationErrorsString(props)}
    >
      <div className="mt-s">
        {isNullable && (
          <RadioGroup
            className="mb-s"
            onChange={handleRadioChange}
            value={isNull ? 'null' : 'nonNull'}
          >
            <Radio value="null">{nullLabel}</Radio>
            <Radio value="nonNull">{nonNullLabel}</Radio>
          </RadioGroup>
        )}

        {showPicker && (
          <DatetimePicker
            defaultValue={time}
            onChange={handleTimeChange}
            calendarPlacement={calendarPlacement}
          />
        )}
      </div>
    </FormGroup>
  )
}

export default withFormsy(FormsyDatetimePicker)
