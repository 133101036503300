import palette from 'constants/palette'
import Highcharts from 'highcharts'
import { buildTranslate } from 'locales'
import { EffectiveDiscountRateTimeSeriesMetric } from 'models/EffectiveDiscountRateMetric'
import { Fields as Merchant } from 'models/Merchant'
import { Fields as PointsExperience } from 'models/PointsExperience'
import moment from 'moment/moment'

const AVAILABLE_IN_DAYS = 1
export type ReportAccessStateT =
  | number
  | 'points_inactive'
  | 'coming_soon'
  | 'available'

export function getReportAccessState(
  merchant: Merchant | null,
  pointsExperience: PointsExperience | null
): ReportAccessStateT {
  if (!merchant?.live_at) return 'coming_soon'

  const isReportingActive =
    pointsExperience?.state === 'active' ||
    pointsExperience?.state === 'archived'

  if (!isReportingActive) return 'points_inactive'

  const liveAt = moment.max(
    moment(merchant.live_at),
    moment(pointsExperience.activated_at)
  )
  const targetDate = liveAt
    .clone()
    .endOf('month')
    .add(AVAILABLE_IN_DAYS, 'days')
    .startOf('month')
  const now = moment()

  if (now.isSameOrAfter(targetDate)) return 'available'

  return Math.ceil(targetDate.diff(now, 'days', true))
}

const t = buildTranslate('points.effective_discount_rate')

type RateSeriesT = {
  benchmarks: Array<{
    y: number
    custom: { is_benchmark: true }
  }>
  rates: Array<{
    y: number
    custom: EffectiveDiscountRateTimeSeriesMetric['values'][number]
  }>
}

export function getRateChartSeries(
  values: EffectiveDiscountRateTimeSeriesMetric['values'],
  benchmarkRate: number
): Highcharts.SeriesOptionsType[] {
  if (!values) return []

  const initialSeries: RateSeriesT = { benchmarks: [], rates: [] }
  const series: RateSeriesT = values.reduce((acc, value) => {
    acc.benchmarks.push({
      y: benchmarkRate,
      custom: {
        is_benchmark: true,
      },
    })

    acc.rates.push({
      y: (value.effective_discount_rate ?? 0) * 100,
      custom: value ?? {},
    })

    return acc
  }, initialSeries)

  return [
    {
      id: 'benchmark',
      name: t('chart.industry_benchmark'),
      data: series.benchmarks,
      color: palette.wisteria,
      type: 'line',
      marker: { enabled: false, symbol: 'circle' },
    },
    {
      id: 'retention_rate',
      name: '',
      data: series.rates,
      color: palette.spearmint50,
      type: 'line',
      showInLegend: false,
      marker: { enabled: false, symbol: 'circle' },
    },
  ]
}
