import { alert } from 'actions/flash'
import { locationsApi, UpdateLocationPayload } from 'api/locations'
import { getErrorMessage, isError, isFetchBaseQueryError } from 'api/utils'
import PhoneNumberInput from 'components/Form/PhoneNumberInput'
import Modal from 'components/ModalContainer'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as Location } from 'models/Location'
import React, { useRef, useState } from 'react'
import { useDisplayError } from 'utilities/displayError'

const validations = buildTranslate('validations')

type Props = {
  isOpen: boolean
  onClose: () => void
  location: Location
}

const t = buildTranslate('locations.location_list.phone.edit_modal')

const EditPhoneNumberModal: React.FC<Props> = props => {
  const { isOpen, onClose, location } = props

  const [formIsValid, setFormIsValid] = useState(false)
  const [phone, setPhone] = useState(location.phone)
  const [updateLocation, { isLoading }] =
    locationsApi.useUpdateLocationMutation()
  const displayError = useDisplayError()
  const form = useRef<Formsy>(null)
  const dispatch = useDispatch()

  const handleSubmit = async () => {
    const payload: UpdateLocationPayload = {
      id: location.id,
      phone: phone,
    }

    const response = await updateLocation(payload)
    if (isError(response)) {
      const error = response.error
      if (isFetchBaseQueryError(error) && error.status === 400) {
        const errorMessage = getErrorMessage(error, t('errors.update'))
        form.current?.updateInputsWithError(
          {
            phone: errorMessage,
          },
          true
        )
      } else {
        displayError(response, t('errors.update'))
      }
    } else {
      dispatch(
        alert({
          key: 'success',
          message: t('success'),
          timeout: 5,
        })
      )

      onClose()
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      proceedText={t('submit')}
      onProceed={handleSubmit}
      canProceed={formIsValid}
      isLoading={isLoading}
    >
      <h3 className="mt-0 mb-xs extra-bold">{t('title')}</h3>
      <div className="mt-xl">
        <div className="flex-1 mr-m">
          <p className="mb-xs bold font-size-14">{t('address')}</p>
          <p className="font-size-12 grey-70">
            <p className="bold">{location.name}</p>
            <p>{location.street}</p>
            <p>{`${location.city}, ${location.state}, ${location.zip}`}</p>
          </p>
        </div>
        <Formsy
          ref={form}
          className="mt-m"
          onValid={() => setFormIsValid(true)}
          onInvalid={() => setFormIsValid(false)}
        >
          <PhoneNumberInput
            name="phone"
            value={phone}
            label={t('phone')}
            labelClassName="mb-xs bold font-size-14"
            validations="isPhone"
            validationError={validations('is_phone')}
            onChange={setPhone}
            required
          />
        </Formsy>
      </div>
    </Modal>
  )
}

export default EditPhoneNumberModal
