import Select from 'components/Form/Select'
import type { Props as SelectProps } from 'components/Form/Select'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import React, { useEffect } from 'react'
import type { SelectOptionT } from 'utilities/types'

type Props = {
  options: SelectOptionT[]
  onChange?: (event: any) => void
  defaultValue?: any
} & PassDownProps<string | null> &
  SelectProps

const SelectWithFormsy: React.FC<Props> = props => {
  const { defaultValue, onChange, value: _value, ...selectProps } = props

  useEffect(() => {
    props.setValue(props.defaultValue)
    // TODO TA-25291: Make this controlled when we revisit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const calculatedDefault = defaultValue
    ? props.options.find(option => option.value === props.defaultValue)
    : props.defaultValue

  // NOTE: react-select's api choice to have values be OptionType | OptionType[]
  // makes typing option cumbersome. We know option will always be SelectOptionT,
  // but we treat it as any to avoid a bunch of unnecessary type refinement checks
  const handleChange = (option: any) => {
    const value = option.value
    props.setValue(value)

    if (props.onChange) {
      props.onChange(value)
    }
  }

  // Cheating on props here so Select can have exact props.
  // We know that the additional FormsyProps in props are safe
  // to pass.
  return (
    <Select
      {...selectProps}
      defaultValue={calculatedDefault}
      onChange={handleChange}
    />
  )
}

export default withFormsy(SelectWithFormsy)
