import { Switch } from '@thanx/uikit/switch'
import DatePicker from 'components/Form/DatePicker'
import Input from 'components/Form/Input'
import Select from 'components/Form/SelectWithFormsy'
import Spinner from 'components/Spinner'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import type { Fields as Location } from 'models/Location'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, { useRef } from 'react'
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import StatBox from 'scenes/CampaignCenter/Builder/components/StatBox'
import { locationsToSelectOptions } from 'utilities/locationUtils'
import userTimeZone from 'utilities/userTimeZone'

const t = buildTranslate(
  'thanx_campaigns.campaigns.location_reopen.steps.setup'
)

type Props = {
  campaign: Campaign
  isLoading: boolean
  locations?: Location[]
  userCount?: number
}

const View: React.FC<Props> = props => {
  const { campaign, isLoading, locations, userCount } = props
  const reopenDatePicker = useRef<PassDownProps<string | null>>(null)
  const redeemStartAt = useRef<PassDownProps<string | null>>(null)
  const startAt = useRef<PassDownProps<string | null>>(null)

  function onDateChange(date: string) {
    if (date) {
      let modifiedDate = moment(date).tz(userTimeZone()).startOf('day')
      if (reopenDatePicker.current) {
        // @ts-ignore
        reopenDatePicker.current.props.setValue(modifiedDate.format())
      }
      if (redeemStartAt.current) {
        // @ts-ignore
        redeemStartAt.current.props.setValue(
          modifiedDate.add(9, 'hours').format()
        )
      }
      if (startAt.current) {
        // @ts-ignore
        startAt.current.props.setValue(calcStartDate(modifiedDate))
      }
    }
  }

  function calcStartDate(date: moment.Moment) {
    let sevenDaysPrior = date.subtract(7, 'days')
    let now = moment().tz(userTimeZone())
    if (sevenDaysPrior.isBefore(now)) return null
    return sevenDaysPrior.format()
  }

  return (
    <Spinner
      isLoading={isLoading}
      size="4x"
      className="text-center padding-top-huge"
    >
      <div className="container">
        <Row>
          <Col md={6} className="padding-top-huge padding-right-huge">
            <div className="padding-right-huge margin-bottom-huge">
              <Translate
                value="thanx_campaigns.campaigns.location_reopen.steps.setup.title"
                tag="p"
                className="grey-90 font-size-32 margin-bottom-micro bold line-height-40"
              />
              <Translate
                value="thanx_campaigns.campaigns.location_reopen.steps.setup.subtitle"
                tag="em"
                className="grey-50 font-size-20 margin-bottom-extra-large block"
              />
            </div>
            <Switch condition={!!userCount && !!campaign.config_location_id}>
              <StatBox
                title={t('stat.supertitle')}
                tooltip={t('stat.tooltip')}
                message={
                  <Translate
                    value="thanx_campaigns.campaigns.location_reopen.steps.setup.stat.message"
                    count={numeral(userCount).format('0,0')}
                    dangerousHTML
                  />
                }
                className="category-targeted-bkg white margin-bottom-huge"
              />
            </Switch>
            <FormGroup
              bsSize="lg"
              className="full-width-on-xs width-90 margin-bottom-extra-large"
            >
              <ControlLabel>{t('select.label')}</ControlLabel>
              <Select
                options={locationsToSelectOptions(locations)}
                className="padding-top-small"
                placeholder={t('select.placeholder')}
                defaultValue={campaign.config_location_id}
                name="config_location_id"
                validations="isRequired"
                validationErrors={{
                  isRequired: I18n.t('validations.is_required'),
                }}
              />
            </FormGroup>

            <Row>
              <Col md={6} lg={4}>
                <DatePicker
                  name="config_reopening_at"
                  value={campaign.config_reopening_at}
                  label={t('datepicker.label')}
                  validations="isRequired"
                  validationErrors={{
                    isRequired: I18n.t('validations.is_required'),
                  }}
                  onChange={onDateChange}
                />
                <Input
                  type="hidden"
                  name="config_reopening_at"
                  value={campaign.redeem_start_at}
                  //@ts-ignore
                  innerRef={reopenDatePicker}
                />
                <Input
                  type="hidden"
                  name="redeem_start_at"
                  value={campaign.redeem_start_at}
                  //@ts-ignore
                  innerRef={redeemStartAt}
                />
                <Input
                  type="hidden"
                  name="start_at"
                  value={campaign.start_at}
                  //@ts-ignore
                  innerRef={startAt}
                />
              </Col>
            </Row>
          </Col>
          <Col
            md={6}
            className="padding-huge grey-10-bkg"
            style={{ height: '900px' }}
          />
        </Row>
      </div>
    </Spinner>
  )
}

export default View
