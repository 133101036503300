import { Switch } from '@thanx/uikit/switch'
import { updateCampaign } from 'actions/campaigns'
import { updateCampaignVariant } from 'actions/campaignVariants'
import PopoverMenu, { PopoverOption } from 'components/PopoverMenu'
import EditButton from 'components/review/EditButton'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { useVariant } from 'scenes/CampaignCenter/Builder/components/VariantContext'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import IncentiveContent from './IncentiveContent'

type HasIncentiveProps = {
  campaign: Campaign
  isEditable: boolean
  onEdit: () => void
}

const t = buildTranslate('thanx_campaigns.review.incentive')

const HasIncentive: React.FC<HasIncentiveProps> = ({
  campaign,
  isEditable,
  onEdit,
}) => {
  const dispatch = useDispatch()
  const { config } = useCampaignConfig()
  const variant = useVariant()
  const variantConfig = variant || config
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )

  const onDelete = () => {
    if (variant && variantConfig.campaign_config_type === 'variant') {
      const updatedVariant = { ...variant, redeem: false }
      dispatch(updateCampaignVariant(campaign.id, variant.id, updatedVariant))
    } else {
      const updatedCampaign = { ...campaign, redeem: false }
      dispatch(updateCampaign(campaign.id, { campaign: updatedCampaign }))
    }
  }
  return (
    <Row className="section">
      <Col className="col-content">
        <IncentiveContent campaign={campaign} />
      </Col>
      <Switch condition={!campaignType?.redeem_required}>
        <Col>
          <PopoverMenu dismissOnClickOutside placement="bottomRight">
            <PopoverOption onClick={onDelete}>{t('delete')}</PopoverOption>
          </PopoverMenu>
        </Col>
      </Switch>
      <Switch condition={isEditable}>
        <Col className="text-right ml-s">
          <EditButton onClick={onEdit} />
        </Col>
      </Switch>
    </Row>
  )
}

export default HasIncentive
