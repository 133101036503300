import { Status, Wrapper } from '@googlemaps/react-wrapper'
import PromoteIcon from 'assets/images/campaign-center/ic_pin_large_star.png'
import NormalIcon from 'assets/images/campaign-center/ic_pin_normal.png'
import SelectedIcon from 'assets/images/campaign-center/ic_pin_small_check.png'
import Error from 'components/Icons/Error'
import Spinner from 'components/Spinner'
import React from 'react'
import Map from './Map'
import Marker from './Marker'

export type LocationT = {
  id: number
  name: string
  position: {
    lat: number
    lng: number
  }
  promoted: boolean
  selected: boolean
}

export type MapProps = {
  className?: string
  locations: Array<LocationT>
  center: google.maps.LatLngLiteral | null
}

const render = (status: Status): React.ReactElement => {
  if (status === Status.FAILURE) return <Error />
  return <Spinner />
}

const GoogleMap: React.FC<MapProps> = ({ className, locations, center }) => {
  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}
      render={render}
    >
      <Map locations={locations} center={center} className={className}>
        {locations.map((location, i) => {
          var image = NormalIcon
          if (location.selected) image = SelectedIcon
          if (location.promoted) image = PromoteIcon
          return (
            <Marker
              key={i}
              // @ts-ignore
              name={location.name}
              position={location.position}
              icon={image}
            />
          )
        })}
      </Map>
    </Wrapper>
  )
}

export default GoogleMap
