import Cell from 'components/Cell'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  title: string
  value?: boolean
}

const t = buildTranslate('cms.settings.receipt_settings')

const Item: React.FC<Props> = props => {
  const { title, value } = props

  return (
    <tr className="border-bottom-1 grey-30-border">
      <Cell className="py-m">{title}</Cell>
      <Cell className="py-m">{value ? t('yes') : t('no')}</Cell>
    </tr>
  )
}

export default Item
