import { FormsyInput } from '@thanx/uikit/input'
import { Switch } from '@thanx/uikit/switch'
import TooltipLabel from 'components/TooltipLabel'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { useFormModel } from '../FormModel'
import Card from './Card'
import NextButton from './NextButton'

type Props = {
  isDisabled: boolean
  isOpen: boolean
  isCurrent: boolean
  isValidForm: boolean
  onNext: () => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.discount_card'
)

const DiscountCard: React.FC<Props> = ({
  isDisabled,
  isOpen,
  isCurrent,
  isValidForm,
  onNext,
}) => {
  const merchant = useCurrentMerchant()
  const { discount, typeItem } = useFormModel().model
  const types = typeItem ? typeItem.types : []
  const isPercent =
    types.includes('manual/percent') || types.includes('automatic/percent')

  return (
    <Card title={t('title')} isOpen={isOpen}>
      <Switch condition={isPercent}>
        <FormsyInput
          // @ts-ignore
          className="mb-l"
          name="discount"
          disabled={isDisabled}
          label={
            <TooltipLabel
              className={isDisabled ? 'opacity-50' : ''}
              label={t('discount_amount')}
              tooltip={t('discount_info_percent')}
            />
          }
          endEnhancer={t('percent')}
          value={discount}
          validations={{
            greaterThan: 0,
            lessThan: 101,
            isRequired: true,
            isNumeric: true,
          }}
          validationErrors={{
            greaterThan: t('percent_must_be'),
            lessThan: t('percent_must_be'),
            isRequired: I18n.t('validations.is_required'),
            isNumeric: I18n.t('validations.is_numeric'),
          }}
        />
      </Switch>
      <Switch condition={!isPercent}>
        <FormsyInput
          // @ts-ignore
          name="discount"
          disabled={isDisabled}
          label={
            <TooltipLabel
              label={t('discount_amount')}
              tooltip={t('discount_info_dollar')}
            />
          }
          startEnhancer={t('dollar')}
          value={discount}
          validations={{
            greaterThan: 0,
            isRequired: true,
            isNumeric: true,
          }}
          validationErrors={{
            greaterThan: I18n.t('validations.greater_than', { value: 0 }),
            isRequired: I18n.t('validations.is_required'),
            isNumeric: I18n.t('validations.is_numeric'),
          }}
        />
        <Switch condition={!!merchant?.automatic_capable}>
          <p className="mt-xs mb-l body-text-4 grey-70">{t('limit_to_250$')}</p>
        </Switch>
      </Switch>

      <Switch condition={isCurrent}>
        <NextButton disabled={!isValidForm} onClick={onNext} />
      </Switch>
    </Card>
  )
}

export default DiscountCard
