import { Radio, RadioGroup } from '@thanx/uikit/radio'
import { Switch } from '@thanx/uikit/switch'
import { updateRedeemTemplate } from 'actions/redeemTemplates'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

export type HeaderType = 'default' | 'custom'

type Props = {
  customContent: React.ReactNode
  customText: string
  defaultContent: React.ReactNode
  defaultText: string
  headerType: HeaderType
  setHeaderType: (value: HeaderType) => void
  templateId: number
  uploadImageId?: number
}

const ImageRadio: React.FC<Props> = ({
  customContent,
  customText,
  defaultContent,
  defaultText,
  headerType,
  setHeaderType,
  templateId,
  uploadImageId,
}) => {
  const [tempId, setTempId] = useState(uploadImageId)
  const dispatch = useDispatch()
  const onDefaultSelect = () => {
    if (uploadImageId) {
      dispatch(
        updateRedeemTemplate(templateId, {
          file_upload: {
            id: uploadImageId,
            destroy: true,
          },
        })
      )
      setTempId(uploadImageId)
    }
  }

  const onCustomSelect = () => {
    if (tempId && !uploadImageId) {
      dispatch(
        updateRedeemTemplate(templateId, {
          file_upload: {
            id: tempId,
          },
        })
      )
    }
  }

  const onChange = value => {
    if (value !== headerType) {
      if (value === 'default') {
        onDefaultSelect()
      } else {
        onCustomSelect()
      }
      setHeaderType(value)
    }
  }
  return (
    <RadioGroup value={headerType} onChange={onChange}>
      <Radio value="default">{defaultText}</Radio>
      <Switch condition={headerType === 'default'}>{defaultContent}</Switch>
      <Radio value="custom">{customText}</Radio>
      {headerType === 'custom' ? customContent : null}
    </RadioGroup>
  )
}

export default ImageRadio
