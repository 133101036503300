import React from 'react'
import { useSelector } from 'react-redux'
import Message from './Message'

const Flash: React.FC = () => {
  //@ts-ignore
  const alerts = useSelector(state => state.flash.alerts)

  return (
    <div id="alerts">
      <div className="container-fluid">
        {alerts.map((alert, a) => {
          return (
            <Message
              key={alert.key + alert.message + alert.count + a}
              alert={alert}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Flash
