import React from 'react'

type Props = {
  className?: string
}

const Hamburger: React.FC<Props> = ({ className = '' }) => {
  return <i className={`${className} fa fa-bars white font-size-24`} />
}

export default Hamburger
