import { Text } from '@thanx/uikit/text'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import PushPreview from 'scenes/CampaignCenter/Builder/components/Previews/Push'
import EmptySection from './EmptySection'

const t = buildTranslate(
  'thanx_campaigns.report.v3.configuration.messages.push'
)

type Props = {
  campaign: Campaign
  config: VariantOrCampaign
  className?: string
}

function PushSection({ campaign, config, className = '' }: Props) {
  const allowNoRedeemPush = campaign.allow_non_reward_messages || config.redeem

  if (!config.notify_push || !allowNoRedeemPush) {
    return (
      <EmptySection
        title={t('title')}
        description={t('empty')}
        className={className}
      />
    )
  }

  return (
    <Section className={`p-xl ${className}`}>
      <Text.Header4 className="mb-m">{t('title')}</Text.Header4>
      <PushPreview text={config.notify_push_text} />
    </Section>
  )
}

export default PushSection
