import { RESET_DB } from 'actions/orm'
import {
  GetRetentionRateMetricsAction,
  GET_RETENTION_RATE_METRICS,
  GET_RETENTION_RATE_METRICS_FAIL,
  GET_RETENTION_RATE_METRICS_SUCCESS,
  ResolvedAction,
} from 'actions/retentionRateMetric'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import { attr, Model, ModelType } from 'redux-orm'
import { EmptyObject } from 'utilities/types'

export enum Type {
  CUMULATIVE = 'retention_rate_metric_cumulative',
  TIME_SERIES = 'retention_rate_metric_time_series',
  COHORTS = 'retention_rate_metric_cohorts',
}

export enum ShortType {
  CUMULATIVE = 'cumulative',
  TIME_SERIES = 'time_series',
  COHORTS = 'cohorts',
}

export type MetricTypes = Array<ShortType>

interface RateData {
  engaged_users_count: number
  returning_users_count: number
  churned_users_count: number
  retention_rate: number
  churn_rate: number
}

interface Data extends RateData {
  date_time: string
}

export type TimeSeriesInterval =
  | 'window_30d'
  | 'window_60d'
  | 'window_90d'
  | 'window_120d'
  | 'window_150d'
  | 'window_180d'
  | 'window_210d'

type CohortData = Pick<Data, 'date_time' | 'engaged_users_count'> &
  Record<TimeSeriesInterval, RateData>

interface BaseMetric {
  id: string
  type: Type
  isLoading: boolean
  isErrored: boolean
}

export interface RetentionRateCumulativeMetric extends BaseMetric {
  type: Type.CUMULATIVE
  current: Data
  previous: Data
  timezone: string
  granularity: DateRangeGranularity
}

export interface RetentionRateTimeSeriesMetric extends BaseMetric {
  type: Type.TIME_SERIES
  values: Array<Data>
  timezone: string
  granularity: DateRangeGranularity
}

export interface RetentionRateCohortMetric extends BaseMetric {
  type: Type.COHORTS
  values: Array<CohortData>
  timezone: string
  granularity: DateRangeGranularity
}

const getTypeFromPath = url => {
  const paths: string[] = url.split('/') || []
  const type = `retention_rate_metric_${paths[paths.length - 1]}` as Type

  return type
}

export type Fields =
  | RetentionRateCumulativeMetric
  | RetentionRateTimeSeriesMetric
  | RetentionRateCohortMetric

export default class RetentionRateMetric extends Model<
  // @ts-ignore
  typeof RetentionRateMetric,
  Fields
> {
  static modelName: string = 'RetentionRateMetric'

  static get fields(): any {
    return {
      merchant_id: attr(),
      type: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action:
      | ResolvedAction
      | { type: typeof RESET_DB }
      | GetRetentionRateMetricsAction,
    // @ts-ignore
    RetentionRateMetric: ModelType<RetentionRateMetric>
  ) {
    switch (action.type) {
      case GET_RETENTION_RATE_METRICS: {
        const params = action.payload.request?.params || {}
        const url: string = action.payload?.request?.url || ''
        const { type: paramsType } = params
        const type =
          paramsType === ShortType.COHORTS ? Type.COHORTS : getTypeFromPath(url)
        RetentionRateMetric.upsert({
          id: `${params.merchant_id}_${type}`,
          type: type,
          isLoading: true,
          isErrored: false,
        })
        break
      }
      case GET_RETENTION_RATE_METRICS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetRetentionRateMetricsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const url: string = previousAction.payload?.request?.url || ''
        const { type: paramsType } = params
        const type =
          paramsType === ShortType.COHORTS ? Type.COHORTS : getTypeFromPath(url)
        RetentionRateMetric.upsert({
          ...action.payload.data,
          id: `${params.merchant_id}_${type}`,
          merchant_id: params.merchant_id,
          type: type,
          isLoading: false,
          isErrored: false,
        })
        break
      }
      case GET_RETENTION_RATE_METRICS_FAIL: {
        const previousAction = action.meta.previousAction as
          | GetRetentionRateMetricsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const url: string = previousAction.payload?.request?.url || ''
        const { type: paramsType } = params
        const type =
          paramsType === ShortType.COHORTS ? Type.COHORTS : getTypeFromPath(url)
        RetentionRateMetric.upsert({
          id: `${params.merchant_id}_${type}`,
          type: type,
          isLoading: false,
          isErrored: true,
        })
        break
      }
      case RESET_DB:
        RetentionRateMetric.all().delete()
        break
      default:
        break
    }
  }
}
