import almostThereIcon from 'assets/images/campaign-center/opportunity/icons/almost_there.png'
import calendarIcon from 'assets/images/campaign-center/opportunity/icons/calendar.png'
import customerLoveIcon from 'assets/images/campaign-center/opportunity/icons/customer_love.png'
import phoneGoldIcon from 'assets/images/campaign-center/opportunity/icons/phone_gold.png'
import withMerchantMetrics, {
  MetricsProps,
} from 'components/metrics/withMerchantMetrics'
import { buildTranslate } from 'locales'
import React from 'react'
import MetricSection from '../components/MetricSection'
import Header from '../components/Shared/Header'
import HowItWorks from '../components/Shared/HowItWorks'
import Setup from '../components/Shared/Setup'
import Solution from '../components/Shared/Solution'
import SolutionStep from '../components/Shared/SolutionStep'
import UseCase from '../components/Shared/UseCase'

const t = buildTranslate('thanx_campaigns.opportunity.tiers')

export const Tiers: React.FC<MetricsProps> = props => {
  const {
    handleClick,
    isCampaignTypeLoading,
    campaignType,
    merchantMetrics,
    merchantMetricStatuses,
  } = props
  const monthlyCount =
    merchantMetrics?.frequency_count?.data?.monthly_count || 0

  return (
    <div>
      <Header campaignType="tiers" />
      <UseCase campaignType="tiers" />
      <MetricSection
        value={monthlyCount}
        description={t('metric')}
        isLoading={merchantMetricStatuses?.frequency_count.isLoading}
        className="spearmint-10-bkg"
      />
      <Solution campaignType="tiers">
        <SolutionStep
          icon={calendarIcon}
          iconAlt={t('solution.steps.image_one_alt')}
          text={t('solution.steps.one')}
        />
        <SolutionStep
          icon={almostThereIcon}
          iconAlt={t('solution.steps.image_two_alt')}
          text={t('solution.steps.two')}
        />
        <SolutionStep
          icon={phoneGoldIcon}
          iconAlt={t('solution.steps.image_three_alt')}
          text={t('solution.steps.three')}
        />
        <SolutionStep
          icon={customerLoveIcon}
          iconAlt={t('solution.steps.image_four_alt')}
          text={t('solution.steps.four')}
        />
      </Solution>
      <hr className="width-75" />
      <HowItWorks campaignType="tiers" />
      <Setup
        type="tiers"
        isLoading={isCampaignTypeLoading}
        handleClick={handleClick}
        campaignType={campaignType}
      />
    </div>
  )
}

export default withMerchantMetrics(['frequency_count'])(Tiers)
