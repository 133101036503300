const auth = {
  email: 'Email',
  password: 'Password',
  login: {
    page_title: 'Log in to Thanx',
    forgot_password: 'Forgot your password?',
    submit: 'Log in',
    error: 'Invalid username/password combination. Try again.',
  },
  request_password: {
    title: 'Reset your password',
    page_title: 'Reset your password  | Thanx',
    message: 'We’ll email instructions to create a new one',
    submit: 'Get new password',
    pending_title: 'Account pending',
    pending_message:
      'Sorry, it looks like you haven’t finished setting up your account. Look for your invitation email in your inbox.',
    not_found:
      'Sorry, we couldn’t find an account to match the email you entered',
    success_title: 'Success!',
    success_message: 'Check your email to update your password',
  },
  reset_password: {
    page_title: 'Reset your password | Thanx',
    expired_title: 'Your reset link has expired',
    expired_message: 'Request a new link and try again',
    new_link: 'Request a new link',
  },
  expired_session: {
    page_title: 'Session expired | Thanx',
    title: 'Your session has expired',
    message: 'Log in again to continue',
    back_to_login: 'Back to login',
  },
  go_back: 'Back to login',
  try_again: 'Try again',
  need_help: 'Need help logging in?',
  contact_us: 'Contact support',
  merchant_picker: {
    select_merchant: 'Go to dashboard',
    title: 'Select which brand’s dashboard you want to view',
    error: 'Something went wrong. Please try again.',
    help: 'Help',
    log_out: 'Log out',
  },
}

export default auth
