import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import flag from 'assets/images/cms/attention-flag.png'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.signup.onboarding_slideshow.content.draft_banner')

const DraftBanner: React.FC = () => {
  const [css] = useStyletron()

  return (
    <div className={'belize-hole-10-bkg p-m mt-m'}>
      <div className={'d-flex'}>
        <img
          alt={t('home')}
          className={css({
            marginRight: '10px',
            height: '24px',
          })}
          src={flag}
        />
        <div className={''}>
          <Text.BodyText4
            className={'font-weight-bold'}
            color="belizeHole50"
            tag={'p'}
          >
            {t('title')}
          </Text.BodyText4>
          <Text.BodyText4 color="belizeHole50" tag={'p'}>
            {t('description')}
          </Text.BodyText4>
        </div>
      </div>
      <div className={'d-flex justify-content-end mt-s'}>
        <Button
          className={'mr-s'}
          kind="secondary"
          onClick={() =>
            window.open(
              `mailto:merchant.support@thanx.com?subject=${t('email_subject')}`,
              '_blank'
            )
          }
        >
          {t('contact_us')}
        </Button>
        <Button kind="secondary" type="submit">
          {t('save_draft')}
        </Button>
      </div>
    </div>
  )
}

export default DraftBanner
