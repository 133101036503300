import Setup from 'scenes/CampaignCenter/Builder/Steps/Engage/Vip/Setup'
import Audience from 'scenes/CampaignCenter/Builder/Steps/Engage/Vip/Setup'
import Incentive from 'scenes/CampaignCenter/Builder/Steps/Shared/Incentive'
import Review from 'scenes/CampaignCenter/Builder/Steps/Shared/Review'
import Success from 'scenes/CampaignCenter/Builder/Steps/Shared/Success'
import Messaging from 'scenes/CampaignCenter/Builder/Steps/Shared/WrappedMessaging'

export default {
  steps: {
    required: {
      setup: Setup,
      review: Review,
      success: Success,
    },
    optional: {
      audience: Audience,
      incentive: Incentive,
      messaging: Messaging,
    },
  },
  options: {
    disableNavFor: {
      setup: true,
      success: true,
    },
  },
}
