import { I18n } from 'react-redux-i18n'

export const numberFormatter = value => {
  if (!value) return 0
  return I18n.l(value)
}

export const percentageFormatter = (value, maxDigits = 2, minDigits = 0) => {
  let updatedValue = value
  if (!value) updatedValue = 0
  return I18n.l(parseFloat(updatedValue), {
    style: 'percent',
    maximumFractionDigits: maxDigits,
    minimumFractionDigits: minDigits,
  })
}

export const currencyFormatter = (value, showCents = true) => {
  let updatedValue = value
  const digits = showCents ? 2 : 0
  if (!value) updatedValue = 0
  return I18n.l(Number(updatedValue), {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
}

export const largeCurrencyFormatter = value => {
  let updatedValue = value
  let afterValue = ''
  let digits = 0
  if (!value) updatedValue = 0
  if (updatedValue >= 1000000) {
    digits = 1
    afterValue = 'M'
    updatedValue = updatedValue / 1000000
  } else if (updatedValue >= 10000) {
    afterValue = 'K'
    updatedValue = updatedValue / 1000
  }
  return `${I18n.l(Number(updatedValue), {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })}${afterValue}`
}

export const avgSpendFormatter = value => {
  if (value.includes('-')) {
    return value
      .split('-')
      .map(x => currencyFormatter(x))
      .join('-')
  }
  return currencyFormatter(value.split('+')[0]) + '+'
}

// https://stackoverflow.com/a/57937087
export const phoneNumberFormatter = (number): string => {
  if (!number) return ''

  const match = number
    .replace(/\D+/g, '')
    .replace(/^1/, '')
    .match(/(\D*\d\D*){1,10}$/)[0]
  const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
  const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''
  return `${part1}${part2}${part3}`
}
