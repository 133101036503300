import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Color from 'color'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import Default from './Default'

type PropsT = {
  showDisabled?: boolean
  className?: string
  imageSrc?: string
  description: string
  width: string
  showRedeemButton?: boolean
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.appearance_card'
)

const RichReward: React.FC<PropsT> = ({
  showDisabled,
  className,
  imageSrc,
  description,
  width,
  showRedeemButton = true,
}) => {
  const app = useSelector(selectApp)
  const color = app?.primary_color
  const hoverColor = color ? Color(color).alpha(0.1) : undefined
  const [css] = useStyletron()

  const variableClass = imageSrc ? 'pb-xl' : 'border p-s pb-xl'
  const noButtonClass = !imageSrc ? 'border p-l' : ''
  if (showDisabled) {
    return (
      <div
        className={`border p-m mb-m mx-auto ${
          className ? className : 'grey-10-bkg'
        } ${css({
          maxWidth: '343px',
        })}`}
      >
        <Text.BodyText4 color="grey70" tag="div">
          {t('preview_disabled_1')}
        </Text.BodyText4>
        <Text.BodyText4 color="grey70" tag="div">
          {t('preview_disabled_2')}
        </Text.BodyText4>
      </div>
    )
  }

  return (
    <div
      className={`${
        showRedeemButton ? variableClass : noButtonClass
      } mb-s ${className} ${css({
        display: 'flex',
        alignItems: 'center',
        width,
        borderRadius: '2px',
        position: 'relative',
        backgroundColor: '#ffffff',
        boxShadow: imageSrc
          ? '0px 0.96793px 1.93586px rgba(54, 66, 78, 0.3)'
          : undefined,
      })}`}
    >
      {imageSrc && (
        <img
          alt="reward"
          className={css({
            width: '100%',
            aspectRatio: '15/7',
            objectFit: 'cover',
          })}
          src={imageSrc}
        />
      )}
      {!imageSrc && (
        <Default
          description={description}
          showRedeemButton={showRedeemButton}
        />
      )}
      {showRedeemButton && (
        <Button
          type="button"
          kind="secondary"
          className={css({
            borderColor: `${color} !important`,
            color: `${color} !important`,
            position: 'absolute',
            bottom: imageSrc ? '10px' : '16px',
            right: '16px',
            textTransform: 'uppercase',
            ':hover:enabled': {
              backgroundColor: `${hoverColor} !important`,
            },
          })}
          size="mini"
        >
          <Text.BodyText5 color="inherit">{t('redeem')}</Text.BodyText5>
        </Button>
      )}
    </div>
  )
}

export default RichReward
