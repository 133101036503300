import { Switch } from '@thanx/uikit/switch'
import React from 'react'

type Props = {
  value?: string | null
  description: string
  subValue?: string | null
  image: string
  alt: string
  color?: string
}

const PerformanceMetricBox: React.FC<Props> = props => {
  const { value, subValue, description, image, alt, color } = props

  return (
    <div
      className="
        performance-metric
        full-width full-height
        border-1 border-radius-5 grey-30-border
        display-flex flex-align-center padding-large
      "
    >
      <img src={image} alt={alt} />
      <div className="full-height display-flex flex-column margin-left-large">
        <p>
          <span
            className="deprecated__body-text-3"
            style={color && !!value ? { color } : {}}
          >
            <strong>{value || '~'}</strong>
          </span>
          <Switch condition={!!subValue}>
            <span className="margin-left-small grey">({subValue})</span>
          </Switch>
        </p>
        <p className="body-text-2 flex-grow-1">{description}</p>
      </div>
    </div>
  )
}

export default PerformanceMetricBox
