import sortBy from 'lodash/sortBy'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import CampaignsTableSection from 'scenes/CampaignCenter/Landing/CampaignsTableSection'

import { Fields as Campaign } from 'models/Campaign'

type Props = {
  className?: string
  campaigns: Campaign[]
}

const Table: React.FC<Props> = ({ campaigns, className = '' }) => {
  const displayCampaigns = sortBy(campaigns, c => c.name)

  if (displayCampaigns.length === 0) return null

  return (
    <div className={className}>
      <CampaignsTableSection
        className={'mb-l'}
        campaigns={displayCampaigns}
        state="active"
        title={I18n.t('thanx_campaigns.landing.reporting.automated_title')}
      />
    </div>
  )
}

export default Table
