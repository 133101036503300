import { RESET_DB } from 'actions/orm'
import { GET_USER_CAMPAIGNS_SUCCESS, ResolvedAction } from 'actions/users'
import { Fields as Campaign } from 'models/Campaign'
import { Model, ModelType } from 'redux-orm'

type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>
export type ReducedCampaign = AtLeast<Campaign, 'id' | 'state' | 'type'>

type Engagement =
  | 'bounced'
  | 'clicked'
  | 'delivered'
  | 'marked_as_spam'
  | 'opened'
  | 'sent'
  | 'server_rejected'

export interface Fields {
  id: number
  user_id: number
  message_type: 'email'
  campaign: ReducedCampaign
  variant: string | null
  delivered_at: string
  engagement: Engagement
}

// @ts-ignore
export default class UserCampaign extends Model<typeof UserCampaign, Fields> {
  static modelName: string = 'UserCampaign'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    UserCampaign: ModelType<UserCampaign>
  ): void {
    switch (action.type) {
      case GET_USER_CAMPAIGNS_SUCCESS:
        action.payload.data.campaign_messages.forEach(campaign =>
          UserCampaign.upsert(campaign)
        )
        break
      case RESET_DB:
        UserCampaign.all().delete()
        break
      default:
        break
    }
  }
}
