import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Counter from 'components/Counter'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import React from 'react'
import { useUikitTheme } from 'theme'
import { getFormattedDate, getMomentFromSeriesCategory } from 'utilities/date'
import { numberFormatter } from 'utilities/formatters'
import { tooltipWidth } from '../utilities'

type Props = {
  date_time: string
  timeZone: string
  granularity: DateRangeGranularity
  counterValue: number
  counterTitle: string
}

const styletronEngine = new StyletronEngine({ prefix: 'st-tt-' })

const ChartTooltip: React.FC<Props> = props => {
  const { date_time, timeZone, granularity, counterValue, counterTitle } = props
  const uikitTheme = useUikitTheme()
  const [css] = useStyletron()
  const title = getFormattedDate(
    getMomentFromSeriesCategory(date_time, granularity, timeZone),
    granularity
  )

  const displayCounterValue = numberFormatter(Math.round(counterValue))

  return (
    <div className={`${css({ width: `${tooltipWidth + 1}px` })}`}>
      <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
        <div className="p-m">
          <Text.BodyText4 color="grey70" tag="div" className="mb-xs">
            {title}
          </Text.BodyText4>
          <Counter primaryValue={displayCounterValue} name={counterTitle} />
        </div>
      </StyleProvider>
    </div>
  )
}

export default ChartTooltip
