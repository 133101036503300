import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { Switch } from 'react-router-dom'
import Landing from './Landing'

const Locations = () => {
  return (
    <Switch>
      <ProtectedRoute
        redirect="/"
        accessType="access"
        resource="Locations"
        exact
        path="/locations"
        component={Landing}
      />
    </Switch>
  )
}

export default Locations
