import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import {
  DefaultUrls,
  ExclusiveDealUrls,
  OrderUrls,
  UrlType,
  UrlValue,
} from 'scenes/Cms/components/UrlPicker'
import { ContentBlockData, useUniqueMenuCategories } from '../hooks'
import AddContentButton from './AddContentButton'
import Description from './Description'
import { getImageUrl } from './helper'

type Props = {
  data: ContentBlockData
  onEdit: () => void
}

const t = buildTranslate(
  'cms.content.app_home_page.content.block_item.featured_content'
)

const tUrl = buildTranslate('cms.components.url_picker')

const inappLinks = [...DefaultUrls, ...OrderUrls, ...ExclusiveDealUrls]

const FeaturedContentDescription = ({ data, onEdit }: Props) => {
  const [css] = useStyletron()
  const { menuCategories } = useUniqueMenuCategories()

  if (data.type !== 'featured_content') return null
  const url = data.url

  const menuCategoryName = menuCategories.find(
    cat => cat.slug === data.url.queryParams?.split('category=')[1]
  )?.name

  const getUrlHeader = (url: UrlValue): string => {
    if (
      url.type === UrlType.Inapp &&
      url.inapp.startsWith('thanx://ordering-menu')
    )
      return t('ordering_menu')
    if (url.type === UrlType.External) return t('external_url')
    if (url.type === UrlType.Inapp) return t('page_within_app')
    return ''
  }

  const getUrlDescription = (url: UrlValue): string | undefined => {
    if (url.type === UrlType.External) {
      return url[url.type]
    }
    if (
      url.type === UrlType.Inapp &&
      url.inapp.startsWith('thanx://ordering-menu')
    )
      return menuCategoryName || t('no_category_selected')
    if (url.type === UrlType.Inapp) {
      const inAppLink = inappLinks.find(l => l.link === url[url.type])
      return tUrl(`${inAppLink?.name}`) || ''
    }
    return ''
  }

  const renderLinkDescription = () => {
    if (url.type === UrlType.NoLink)
      return (
        <Text.BodyText4 tag="p" color="grey70">
          {t('no_link')}
        </Text.BodyText4>
      )

    return (
      <>
        <Text.BodyText4 tag="p" color="grey70" className="mb-s">
          {getUrlHeader(url)}
        </Text.BodyText4>
        <Text.BodyText4
          tag="p"
          color="grey70"
          className={css({ overflowWrap: 'anywhere' })}
        >
          {getUrlDescription(url)}
        </Text.BodyText4>
      </>
    )
  }

  const { image } = data
  if (!image?.url && !image?.id) {
    return (
      <>
        <Description type={data.type} />
        <AddContentButton onClick={onEdit} />
      </>
    )
  }

  const imageSrc = getImageUrl(image)
  return (
    <div>
      {imageSrc && (
        <img
          src={imageSrc}
          className={`mb-s ${css({ height: '115px' })}`}
          alt={t('title')}
        />
      )}
      <Text.BodyText4 tag="p" color="grey90" bold className="mb-s">
        {t('link_description')}
      </Text.BodyText4>
      {renderLinkDescription()}
    </div>
  )
}

export default FeaturedContentDescription
