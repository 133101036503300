import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import Error from 'components/Icons/Error'
import Spinner from 'components/Spinner'
import palette from 'constants/palette'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { buildTranslate } from 'locales'
import { ActivityTimeSeriesEntry } from 'models/PointsExperienceMetric'
import moment from 'moment-timezone'
import React from 'react'
import { renderToString } from 'react-dom/server'
import ChartTooltip from 'scenes/Points/Landing/Activity/components/ChartTooltip'
import { tooltipWidth } from 'scenes/Points/Landing/Activity/utilities'
import { getDateLabel } from 'utilities/date'

const t = buildTranslate('points.activity.outstanding_points')

type Props = {
  granularity: DateRangeGranularity
  timeZone: string
  seriesData: Array<ActivityTimeSeriesEntry>
  isErrored: boolean
  isLoading: boolean
}

const Chart: React.FC<Props> = props => {
  const { granularity, timeZone, seriesData, isErrored, isLoading } = props
  const [css] = useStyletron()

  const categories = seriesData.map(record => record.date_time)
  const outstandingData = seriesData.map(
    record => record.cumulative_outstanding_amount ?? 0
  )

  const series: Highcharts.SeriesLineOptions[] = [
    {
      name: t('title'),
      data: outstandingData,
      color: palette.spearmint50,
      type: 'line',
    },
  ]

  const caption = timeZone
    ? t('chart.timezone', {
        timeZone: moment.tz(timeZone).format('z'),
      })
    : ''

  // Use a box instead of a line for the legend
  // @ts-ignore
  Highcharts.seriesTypes.line.prototype.drawLegendSymbol =
    // @ts-ignore
    Highcharts.seriesTypes.area.prototype.drawLegendSymbol
  const options: Highcharts.Options = {
    chart: {
      type: 'line',
      style: {
        fontFamily: 'Lato',
        overflow: 'visible !important',
      },
    },
    caption: {
      text: caption,
      style: { color: palette.grey70, fontFamily: 'Lato', fontSize: '12px' },
      align: 'right',
      floating: true,
    },
    title: undefined,
    credits: { enabled: false },
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return renderToString(
          <ChartTooltip
            date_time={this.point.category}
            granularity={granularity}
            counterValue={this.point.y ?? 0}
            counterTitle={t('title')}
            timeZone={timeZone}
          />
        )
      },
      backgroundColor: palette.white,
      borderWidth: 1,
      borderColor: palette.grey20,
      borderRadius: 4,
      outside: true,
      useHTML: true,
      shadow: false,
      padding: 0,
      style: {
        width: tooltipWidth,
        whiteSpace: 'normal',
      },
    },
    xAxis: {
      categories,
      title: {
        text: t('chart.x_axis_title'),
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      labels: {
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject
        ) {
          return getDateLabel(String(this.value), granularity, timeZone)
        },
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
      tickLength: 0,
      lineColor: palette.grey30,
    },
    yAxis: {
      type: 'linear',
      allowDecimals: false,
      softMax: 1,
      title: {
        text: t('chart.y_axis_title'),
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      gridLineDashStyle: 'LongDash',
      gridLineColor: palette.grey30,
      labels: {
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
    },
    series,
    legend: {
      align: 'left',
      symbolHeight: 12,
      symbolRadius: 0,
      x: 30,
      itemStyle: {
        color: palette.grey90,
        fontSize: '14px',
      },
    },
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
  }
  return (
    <div className="pt-m">
      <div
        className={`w-100 h-100 pl-0 pr-s py-l white-bkg border-1 grey-20-border position-relative ${css(
          {
            borderRadius: '4px',
          }
        )}`}
      >
        <div className="d-flex flex-align-center flex-space-between mb-l">
          <div className="d-flex ml-l align-items-center mr-s">
            <Spinner isLoading={isLoading} />
            {isErrored && (
              <div style={{ lineHeight: '20px' }}>
                <Error />
                <Text.BodyText4
                  className="ml-xs align-middle"
                  color={palette.cayenne50}
                >
                  {t('chart.error')}
                </Text.BodyText4>
              </div>
            )}
          </div>
        </div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  )
}

export default Chart
