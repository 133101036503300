import palette from 'constants/palette'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { buildTranslate } from 'locales'
import { OverTimeConversionTimeSeriesData } from 'models/MerchantMetric'
import moment from 'moment-timezone'
import React from 'react'
import { renderToString } from 'react-dom/server'
import { stagesWithDetailedReport } from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { percentageFormatter } from 'utilities/formatters'
import Tooltip from './Tooltip'

const t = buildTranslate('lifecycle_report.detail.over_time_chart')

type Props = {
  stage: typeof stagesWithDetailedReport[number]
  timezone: string
  series: OverTimeConversionTimeSeriesData
}

const LineChart: React.FC<Props> = ({ stage, timezone, series }) => {
  const options = {
    chart: {
      style: {
        fontFamily: 'Lato',
        overflow: 'visible !important',
      },
    },
    title: undefined,
    plotOptions: {
      line: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return renderToString(
          <Tooltip
            timestamp={this.x}
            conversionRate={this.y}
            timezone={timezone}
          />
        )
      },
      backgroundColor: palette.white,
      borderWidth: 1,
      borderColor: palette.grey20,
      borderRadius: 4,
      outside: true,
      useHTML: true,
      shadow: true,
      padding: 24,
    },
    yAxis: {
      allowDecimals: false,
      min: 0,
      ceiling: 1,
      title: {
        text: t('labels.y_axis'),
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      gridLineDashStyle: 'LongDash',
      gridLineColor: palette.grey40,
      tickInterval: 0.1,
      labels: {
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject
        ) {
          return percentageFormatter(this.value, 0, 0)
        },
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        week: '%b %e',
      },
      tickLength: 0,
      title: {
        text: t(`labels.x_axis.${stage}`),
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
    },
    credits: { enabled: false },
    series: [
      {
        showInLegend: false,
        color: palette.spearmint50,
        data: series.map((d, _) => [
          moment.tz(d.date_time, 'YYYY-MM-DD', timezone).valueOf(),
          d.user_converted_fraction,
        ]),
      },
    ],
  }

  return (
    <HighchartsReact
      allowChartUpdate
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default LineChart
