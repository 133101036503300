export default {
  'email subscribers':
    'Customers who only have an email address and have not signed up for the loyalty program or enrolled their credit card.We need to extrapolate the behavior of these customers based on how enrolled customers behave.Going forwards this will extend to customers from other places such as facebook, catering database, etc...',
  'enrolled customers':
    'Customers who have signed up for the loyalty program or enrolled their credit card.We can measure every visit, dollar spent and NPS response about these customers.',
  recipients: 'Customers who received the campaign.',
  viewed: 'Customers who opened the campaign.',
  'view rate': 'viewed / sent',
  clicked: 'Customers who clicked on any link.',
  'click rate': 'clicks / viewed',
  redeemed: 'Customers who redeemed their reward.',
  'redemption rate': 'redemptions / viewed',
  purchased: 'The customer has made a purchase within the campaign period.',
  'extrapolated purchases':
    'The number of purchases we think email subscribers made based on the behavior of enrolled customers. e.g. If there are 100 loyalty members and 10% make a purchase. Then we can assume of the 1,000 email subscribers, 10% will also make a purchase.',
  'purchase rate': 'purchases / viewed',
  'signed up':
    'The non-enrolled customer has signed up to the loyalty program.',
  'signup rate': 'signups / viewed',
  'campaign period':
    'The length of time the campaign is active which restricts what we claim. The campaign period defaults to 14 days unless the reward has a time range e.g. “one day only” or “all of September”. That means that we only look at visits, engagement, and revenue during that time.',
  'average basket':
    'redeems / average spend of recipients during campaign period ',
  cogs: 'The total cost of goods or services associated with the reward (cost per reward x number of rewards redeemed).',
  'campaign lift':
    'The delta between the behavior of the control group and the behavior of customers who receive the campaign. e.g. If 10% of customers who receive the campaign make a purchase and 5% of customers who are in the control make a purchase, then the campaign has a 2x lift (twice and many people did the thing because of the campaign).',
  'gross revenue':
    'Total amount spent by customers in the campaign period after this campaign was sent.',
  'incremental revenue':
    'Revenue generated as a direct result of sending this campaign. This is calculated using control groups to compare the behavior of customers who received the campaign with those who didn’t.',
  'gross profit':
    'Gross profitis gross revenues minus thecost of goods sold. {gross profit} - {cogs}',
  'campaign revenue': 'Gross revenue from customers who received the campaign.',
  'control group':
    'Revenue spent by the control group (customers who <b>didn’t</b> receive the campaign). This shows how much customers would have spent had you done nothing.',
  'control revenue':
    'Gross revenue from customers who didn’t receive the campaign.',
  'additional revenue': 'Additional revenue if you include email subscribers.',
  roi: 'Campaign ROI (gross revenue - costs).',
  'min roi':
    'Campaign ROI excluding revenue from email subscribers (incremental revenue - costs).',
  'max roi':
    'Campaign ROI including revenue from email subscribers (incremental revenue + revenue from email subscribers - costs).',
  'connected customers':
    'Customers who have opted into your loyalty program whose purchases are automatically tracked',
}
