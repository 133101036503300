import { ContentBlockTypes } from 'api/landingPageTheme'
import Section from 'components/Section'
import React from 'react'
import Description from '../Description'
import Title from '../Title'
import SubmitButton from './SubmitButton'

type Props = {
  type: ContentBlockTypes
  isDisabled: boolean
  onAddNewBlock: (type: ContentBlockTypes) => void
  unavailable?: boolean
}

const Block = ({ type, isDisabled, onAddNewBlock, unavailable }: Props) => {
  return (
    <Section
      className="flex-1 mb-s p-s"
      kind={isDisabled || unavailable ? 'secondary' : 'primary'}
    >
      <div className="d-flex align-items-center">
        <div className="flex-1 mr-m">
          <div className="mb-xs">
            <Title
              type={type}
              isDisabled={isDisabled || unavailable}
              size="small"
            />
          </div>
          <Description
            type={type}
            isDisabled={isDisabled || unavailable}
            size="small"
            unavailable={unavailable}
          />
        </div>
        <SubmitButton
          type={type}
          onAddNewBlock={onAddNewBlock}
          isDisabled={isDisabled}
          unavailable={unavailable}
        />
      </div>
    </Section>
  )
}

export default Block
