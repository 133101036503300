import { Button, Kind } from '@thanx/uikit/button'
import { Checkbox } from '@thanx/uikit/checkbox'
import { FormsyInput } from '@thanx/uikit/input'
import { FormsySelect } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { alert } from 'actions/flash'
import {
  ResolvedAction,
  updateCurrentMerchantUser,
  UPDATE_MERCHANT_USER_FAIL,
} from 'actions/merchantUser'
import Section from 'components/Section'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import pick from 'lodash/pick'
import { Fields as MerchantUser } from 'models/MerchantUser'
import React, { useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import timezoneOptions from 'utilities/timezoneOptions'

const t = buildTranslate('profile')
const validations = buildTranslate('validations')

interface Template extends Partial<MerchantUser> {
  emails: Object
}

const convertToTemplate = (user: MerchantUser | Template | null) => {
  if (!user) return null
  const template: Template = {
    ...pick(user, [
      'first_name',
      'last_name',
      'title',
      'phone',
      'email',
      'time_zone',
    ]),
    emails: {},
  }
  if (user.gets_nps_emails) {
    user.gets_nps_emails.forEach(
      email => (template.emails[email.id] = email.gets_nps_emails || false)
    )
  }
  return template
}

const Profile: React.FC = () => {
  const [css] = useStyletron()
  const merchantUser = useSelector(selectCurrentMerchantUser)
  const [hasUpdated, setHasUpdated] = useState(false)
  const [template, setTemplate] = useState<Template | null>(
    convertToTemplate(merchantUser)
  )
  const [isValid, setIsValid] = useState(true)
  const dispatch = useDispatch()

  const updateTemplate = values => {
    setHasUpdated(true)
    setTemplate({
      ...template,
      ...values,
    })
  }

  const toggleCheckbox = (id, isChecked) => {
    setTemplate({
      ...template,
      emails: {
        ...(template?.emails ?? {}),
        [id]: isChecked,
      },
    })
  }

  const updateProfile = async () => {
    if (template) {
      const gets_nps_emails = merchantUser?.gets_nps_emails?.map(emails => ({
        ...emails,
        gets_nps_emails: template.emails[emails.id],
      }))
      const result: ResolvedAction = await dispatch(
        updateCurrentMerchantUser({
          phone: template.phone,
          first_name: template.first_name,
          last_name: template.last_name,
          title: template.title,
          email: template.email,
          gets_nps_emails,
          time_zone: template.time_zone,
        })
      )

      if (result.type === UPDATE_MERCHANT_USER_FAIL) {
        dispatch(
          alert({
            key: 'danger',
            message: t(`update_failed`),
          })
        )
        return
      }
      setHasUpdated(false)
      // display success message and close the modal
      dispatch(
        alert({
          key: 'success',
          message: t(`update_success`),
          timeout: 5,
        })
      )
    }
  }

  const getTemplatePhone = phone => {
    if (!phone) return ''
    return Array.isArray(phone) ? phone[0] : phone
  }

  const handleEmailCheckboxClick = (emails, checked) => {
    toggleCheckbox(emails.id, checked)
    setHasUpdated(true)
  }

  const options = useMemo(timezoneOptions, [])

  return (
    <div
      className={`${css({
        minHeight: '100%',
      })} grey-05-bkg`}
    >
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <Formsy
        onChange={updateTemplate}
        onValidSubmit={updateProfile}
        onValid={() => setIsValid(true)}
        onInvalid={() => setIsValid(false)}
      >
        <div className="p-0 white-bkg w-100 border-bottom">
          <Container>
            <Row className="my-xl">
              <Col>
                <Text.Header2 className="mt-0 mb-0 mr-2 inline-block">
                  {t('edit')}
                </Text.Header2>
              </Col>
              <Col className="text-right">
                <Button disabled={!isValid || !hasUpdated} type="submit">
                  {t('update')}
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <Section className="my-xl p-m">
            <Row>
              <Col>
                <Text.Header4 className="mb-m mt-0" tag="div">
                  {t('personal')}
                </Text.Header4>
                <FormsyInput
                  className="mb-m"
                  name="first_name"
                  label={t('first')}
                  value={template?.first_name}
                />
                <FormsyInput
                  className="mb-m"
                  name="last_name"
                  label={t('last')}
                  value={template?.last_name}
                />
                <FormsyInput
                  className="mb-m"
                  name="title"
                  label={t('title')}
                  value={template?.title}
                />
                <FormsyInput
                  className="mb-m"
                  name="phone"
                  label={t('phone')}
                  value={getTemplatePhone(template?.phone)}
                  validations={{
                    isPhone: true,
                  }}
                  validationErrors={{
                    isPhone: validations('is_phone'),
                  }}
                />
                <FormsyInput
                  className="mb-s"
                  name="email"
                  label={t('email')}
                  value={template?.email}
                  validations={{
                    isEmail: true,
                    isRequired: true,
                  }}
                  validationErrors={{
                    isEmail: validations('is_email'),
                    isRequired: validations('is_required'),
                  }}
                />
                {merchantUser?.gets_nps_emails &&
                  merchantUser?.gets_nps_emails.map(emails => (
                    <div key={emails.id} className="mt-s">
                      <Checkbox
                        checked={!!template?.emails[emails.id.toString()]}
                        onChange={checked =>
                          handleEmailCheckboxClick(emails, checked)
                        }
                      >
                        <Text.BodyText3 color="grey70">
                          {t('feedback', { merchant: emails.merchant_name })}
                        </Text.BodyText3>
                      </Checkbox>
                    </div>
                  ))}
                <FormsySelect
                  className="mt-s"
                  name="time_zone"
                  label={t('timezone')}
                  value={template?.time_zone}
                  options={options}
                />
              </Col>
            </Row>
          </Section>
          <Section className="mb-xl p-m">
            <Row>
              <Col>
                <Text.Header4 className="mb-m mt-0" tag="div">
                  {t('password.title')}
                </Text.Header4>
                <Text.BodyText2 color="grey70">••••••••••••••••</Text.BodyText2>
              </Col>
              <Col className="text-right">
                <Button
                  kind={Kind.SECONDARY}
                  onClick={() => dispatch(push('/change_password'))}
                >
                  {t('password.change_password')}
                </Button>
              </Col>
            </Row>
          </Section>
        </Container>
      </Formsy>
    </div>
  )
}

export default Profile
