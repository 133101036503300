import { Notification } from '@thanx/uikit/notification'
import Select from '@thanx/uikit/select/Select'
import { buildTranslate } from 'locales'
import React from 'react'
import { Link } from 'react-router-dom'
import { Can } from 'utilities/ability'

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.empty_templates'
)

const SelectEmpty: React.FC = () => {
  return (
    <>
      <div className="d-flex mt-m">
        <div className="w-100">
          <Select placeholder={t('select_placeholder')} disabled={true} />
        </div>
      </div>

      <div className="mt-m">
        <Notification size="full">
          <Can I="manage" a="RedeemManager">
            <div className="display-flex flex-row pb-m">
              <i className="fa fa-1x fa-info-circle mt-1 mr-2" />
              <div className="w-100">{t('info_box.admin.label')}</div>
            </div>
            <div className="mt-s display-flex flex-row flex-justify-end">
              <Link to="/rewards" className="btn btn-primary btn-lg">
                {t('info_box.admin.button')}
              </Link>
            </div>
          </Can>
          <Can not I="manage" a="RedeemManager">
            <div className="display-flex flex-row pb-m">
              <i className="fa fa-1x fa-info-circle mt-1 mr-2" />
              <div>{t('info_box.location_manager.label')}</div>
            </div>
          </Can>
        </Notification>
      </div>
    </>
  )
}

export default SelectEmpty
