import { api } from './index'

export type ExternalForm = {
  id: number
  type: string
  name: string
}

export const externalFormsApi = api.injectEndpoints({
  endpoints: build => ({
    getExternalForms: build.query<{ external_forms: ExternalForm[] }, void>({
      query: () => `/external_forms`,
      providesTags: ['ExternalForms'],
    }),
  }),
})
