import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Variants from 'components/Icons/Variants'
import Formsy from 'formsy-react'
import { MinimalVariant } from 'models/Campaign'
import React from 'react'
import EditableName from 'scenes/CampaignCenter/Builder/components/EditableName'

type Props = {
  title: React.ReactNode
  variants: MinimalVariant[]
  winningVariantId: number | null
}
const HeaderRow: React.FC<Props> = props => {
  const { title, winningVariantId, variants } = props
  const [css] = useStyletron()
  return (
    <div className="d-flex justify-content-between flex-align-end">
      <div
        className={`flex-1 ${css({
          minWidth: '220px',
        })}`}
      >
        {title}
      </div>
      {variants.map(variant => {
        const isControl = variant.meta?.isControl || false
        return (
          <div
            className="col-2 d-flex align-items-center p-0 mt-m mb-s"
            key={variant.id}
          >
            <Variants
              className="mr-xs"
              variantLetter={variant.meta?.letter || 'A'}
              color={variant.meta?.color}
              isWinner={variant.id === winningVariantId}
              isControl={isControl}
              paddingRight="9px"
            />
            <Switch condition={isControl}>
              <Text.Header4>{variant.meta?.name}</Text.Header4>
            </Switch>
            <Switch condition={!isControl}>
              <Formsy className="flex-1">
                <EditableName
                  campaignId={variant.campaign_id}
                  className="width-100 mr-xs"
                  variantId={variant.id}
                  name={variant.name}
                  type="variant"
                  styleSize="small"
                  showAsInput
                />
              </Formsy>
            </Switch>
          </div>
        )
      })}
    </div>
  )
}

export default HeaderRow
