import React from 'react'

type Props = {
  className?: string
  color?: string
}

const BullsEyeRectangleIcon: React.FC<Props> = props => {
  const { className, color = 'currentColor' } = props
  return (
    <svg
      width="62"
      height="46"
      viewBox="0 0 62 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '62px' }}
      className={className}
    >
      <path
        d="M1 4.71111C1 2.66152 2.66152 1 4.71111 1H57.2889C59.3385 1 61 2.66152 61 4.71111V41.2889C61 43.3385 59.3385 45 57.2889 45H4.71111C2.66152 45 1 43.3385 1 41.2889V4.71111Z"
        fill="white"
        fillOpacity="0"
        stroke={color}
        strokeWidth="2.35556"
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.2471 16.8408C33.208 16.7627 33.1689 16.6846 33.1689 16.6064C33.1689 16.3721 33.2861 16.1768 33.4424 16.0205L35.9033 13.5596C36.333 13.1299 37.0361 13.2861 37.2314 13.8721L37.9736 16.0205L40.1221 16.7627C40.708 16.958 40.8643 17.6611 40.4346 18.0908L37.9736 20.5518C37.7393 20.7861 37.4268 20.8643 37.1533 20.7471L35.2002 20.1221L31.6455 23.6377C31.2939 24.0283 30.708 24.0283 30.3564 23.6377C29.9658 23.2861 29.9658 22.7002 30.3564 22.3486L33.8721 18.7939L33.2471 16.8408ZM31.0205 18.9111C28.7549 18.9111 26.9189 20.7471 26.9189 22.9736C26.9189 25.2393 28.7549 27.0361 30.9814 27.0361C33.2471 27.0361 35.0439 25.2393 35.0439 22.9736C35.0439 22.7002 35.0439 22.3877 34.9658 22.1143L35.5518 21.5283L36.7627 21.958C36.7627 21.958 36.8018 21.958 36.8408 21.958C36.8799 22.3096 36.9189 22.6221 36.9189 22.9736C36.9189 26.2549 34.3018 28.9111 30.9814 28.9111C27.7002 28.9111 25.0439 26.2939 25.0439 22.9736C25.0439 19.6924 27.7002 17.0361 30.9814 17.0361C31.3721 17.0361 31.6846 17.1143 32.0361 17.1533C32.0361 17.1924 32.0361 17.2314 32.0361 17.2314L32.4658 18.4424L31.8799 19.0283C31.6064 18.9502 31.2939 18.9111 31.0205 18.9111ZM40.2393 20.083C40.5127 20.9814 40.708 21.958 40.708 22.9736C40.708 28.3252 36.333 32.6611 31.0205 32.6611C25.6689 32.6611 21.333 28.3252 21.333 22.9736C21.333 17.6611 25.6689 13.2861 31.0205 13.2861V13.3252C32.0361 13.3252 33.0127 13.4814 33.9111 13.7549L32.5439 15.1611C32.5049 15.2002 32.4658 15.2393 32.4268 15.3174C31.958 15.2393 31.4893 15.1611 31.0205 15.1611C26.6846 15.1611 23.208 18.6768 23.208 22.9736C23.208 27.3096 26.6846 30.7861 31.0205 30.7861C35.3174 30.7861 38.833 27.3096 38.833 22.9736C38.833 22.5049 38.7549 22.0361 38.6768 21.6064C38.7549 21.5283 38.7939 21.4893 38.833 21.4502L40.2393 20.083Z"
        fill={color}
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
    </svg>
  )
}

export default BullsEyeRectangleIcon
