import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import Import from 'components/Icons/Import'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React from 'react'
import { Button } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'

const t = buildTranslate(`thanx_campaigns.builder.steps.setup.messaging`)

type PropsT = {
  campaign: Campaign
  unlayerEnabled: boolean
  config: VariantOrCampaign
  isEmailCopyingEnabled: boolean
  setShowPreviewModal: (s: boolean) => void
  setShowImportModal: (s: boolean) => void
  setShowSendTestModal: (s: boolean) => void
}

const EmailOptions: React.FC<PropsT> = ({
  campaign,
  unlayerEnabled,
  config,
  isEmailCopyingEnabled,
  setShowPreviewModal,
  setShowImportModal,
  setShowSendTestModal,
}) => {
  const [css] = useStyletron()
  if (!unlayerEnabled || !config || !campaign?.variants) return null
  const isABTest =
    config.campaign_config_type === 'variant' && campaign.variants?.length > 1
  const importText = isABTest ? t('import.tooltip') : t('import.not_ab_tooltip')

  const isCopyButtonEnabled = isEmailCopyingEnabled || isABTest
  const copyButtonLabel = isEmailCopyingEnabled
    ? 'thanx_campaigns.builder.forms.messaging_tabs.import.title_v2'
    : 'thanx_campaigns.builder.forms.messaging_tabs.import.title'

  return (
    <div>
      <div className="inline-block padding-right-medium">
        <Button
          bsStyle="link"
          className="font-size-16"
          onClick={() => setShowPreviewModal(true)}
        >
          <i className="fa fa-eye margin-right-small" />
          <Translate value="thanx_campaigns.builder.forms.messaging_tabs.preview.title" />
        </Button>
      </div>
      <div className="inline-block padding-right-medium">
        <Button
          bsStyle="link"
          className={`font-size-16 ${css({
            ':disabled': {
              color: defaultTheme.colors.grey70,
              backgroundColor: 'transparent',
            },
          })}`}
          onClick={() => setShowImportModal(true)}
          disabled={!isCopyButtonEnabled}
        >
          <Import className="mr-xs mb-xxs" />
          <Translate value={copyButtonLabel} />
        </Button>
        {!isEmailCopyingEnabled && (
          <PopoverContent sections={[{ content: importText }]} />
        )}
      </div>
      <div className="inline-block">
        <Button
          bsStyle="link"
          className="font-size-16"
          onClick={() => setShowSendTestModal(true)}
        >
          <i className="fa fa-external-link margin-right-small" />
          <Translate value="thanx_campaigns.builder.forms.messaging_tabs.send_test.title" />
        </Button>
      </div>
    </div>
  )
}

export default EmailOptions
