import { Text } from '@thanx/uikit/text'
import InlineButton from 'components/InlineButton'
import get from 'lodash/get'
import React, { useEffect, useRef, useState } from 'react'

type PropsT = {
  pollAction: () => any
  pollSeconds: number
  comparison: {
    response: string
    previous: string | null
  }
  onClick: () => {}
}

const UpdateButton: React.FC<PropsT> = props => {
  const { pollAction, pollSeconds, comparison, onClick } = props
  const [isPolling, setIsPolling] = useState(false)
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [savedComparison] = useState(comparison.previous)

  const pollFuncWrapper = () => {
    const pollFunc = async () => {
      const response = await pollAction()
      // stop polling when we find a difference between what we get back from the
      // pollAction and the previous
      if (get(response, comparison.response) !== savedComparison) {
        setIsPolling(false)
        if (timer.current) clearInterval(timer.current)
      }
    }
    pollFunc()
  }
  const intervalFunc = () => {
    timer.current = setInterval(pollFuncWrapper, pollSeconds * 1000)
  }

  const onClickHandler = () => {
    setIsPolling(true)
    onClick()
    intervalFunc()
  }

  // stop polling on unmount
  useEffect(() => {
    return () => {
      if (timer.current) clearInterval(timer.current)
    }
  }, [])
  return (
    <InlineButton onClick={onClickHandler} disabled={isPolling}>
      {isPolling ? (
        <Text.BodyText4 bold color="grey90">
          Updating...
        </Text.BodyText4>
      ) : (
        'Update'
      )}
    </InlineButton>
  )
}

export default UpdateButton
