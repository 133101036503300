// @flow
import React, { Component } from 'react'
import { Row, Col, FormControl } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

type Props = {
  headers: Array<string>,
  setValid: any => void,
  setInvalid: any => void,
}
type State = {
  isValid: boolean,
  hasDuplicate: boolean,
}

export default class ColumnMapper extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isValid: true,
      hasDuplicate: false,
    }
  }

  onChange(event: any) {
    let currentSelect = event.target
    let currentValue = this.valueFromSelect(currentSelect)
    if (currentValue === '') {
      this.setState({
        isValid: true,
        hasDuplicate: false,
      })
      this.props.setValid({
        // $FlowFixMe
        email: this.valueFromSelect(this.email),
        // $FlowFixMe
        first_name: this.valueFromSelect(this.first_name),
        // $FlowFixMe
        last_name: this.valueFromSelect(this.last_name),
      })
      return
    }
    for (var selectName of ['email', 'first_name', 'last_name']) {
      // $FlowFixMe
      let otherSelectValue = this.valueFromSelect(this[selectName])
      // $FlowFixMe
      if (this[selectName] === currentSelect) continue
      if (otherSelectValue === '') continue
      if (currentValue === otherSelectValue) {
        this.setState({
          isValid: false,
          hasDuplicate: true,
        })
        this.props.setInvalid({})
        return
      }
    }
    this.setState({
      isValid: true,
      hasDuplicate: false,
    })
    this.props.setValid({
      // $FlowFixMe
      email: this.valueFromSelect(this.email),
      // $FlowFixMe
      first_name: this.valueFromSelect(this.first_name),
      // $FlowFixMe
      last_name: this.valueFromSelect(this.last_name),
    })
  }

  valueFromSelect(select: HTMLSelectElement) {
    return select.options[select.selectedIndex].value
  }

  column(name: string, title: string, includesNull: boolean = false) {
    return (
      <div>
        <p>{title}</p>
        <FormControl
          name={name}
          componentClass="select"
          style={{ width: '100%' }}
          // $FlowFixMe
          inputRef={select => (this[name] = select)}
          onChange={this.onChange.bind(this)}
          defaultValue={name === 'email' ? 0 : ''}
        >
          {this.props.headers.map((column, index) => (
            <option value={index} key={index}>
              {column}
            </option>
          ))}
          {includesNull ? (
            <option value="">
              {I18n.t('emails.recipients.data_mapper.unavailable')}
            </option>
          ) : null}
        </FormControl>
      </div>
    )
  }

  emailColumn() {
    return this.column(
      'email',
      I18n.t('emails.recipients.data_mapper.subscription_fields.email_field')
    )
  }

  firstNameColumn() {
    return this.column(
      'first_name',
      I18n.t(
        'emails.recipients.data_mapper.subscription_fields.first_name_field'
      ),
      true
    )
  }

  lastNameColumn() {
    return this.column(
      'last_name',
      I18n.t(
        'emails.recipients.data_mapper.subscription_fields.last_name_field'
      ),
      true
    )
  }

  duplicateWarning() {
    if (!this.state.hasDuplicate) return null
    return (
      <p className="text-danger absolute">
        {I18n.t(
          'emails.recipients.data_mapper.subscription_fields.duplicate_warning'
        )}
      </p>
    )
  }

  render() {
    return (
      <div>
        <Row className="fs-mask" style={{ marginBottom: '10px' }}>
          <Col sm={4}>{this.emailColumn()}</Col>
          <Col sm={4}>{this.firstNameColumn()}</Col>
          <Col sm={4}>{this.lastNameColumn()}</Col>
        </Row>
        {this.duplicateWarning()}
      </div>
    )
  }
}
