import { Fields } from 'models/Link'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_LINKS = 'link/GET_LINKS'
export const GET_LINKS_SUCCESS = 'link/GET_LINKS_SUCCESS'
export const GET_LINKS_FAIL = 'link/GET_LINKS_FAIL'

export function getLinks(): AxiosAction<typeof GET_LINKS, { links: Fields[] }> {
  return {
    type: GET_LINKS,
    payload: {
      client: 'default',
      request: {
        url: 'links',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<ReturnType<typeof getLinks>>
