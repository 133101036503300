import { api } from './index'

export type Order = {
  id: number
  provider_uid: string
  provider_order_uid: string
  payment_type: string
  card_id: number
  card: {
    type: string
    last4: string
  }
  location_id: number
  location: {
    name: string
    street: string
    city: string
    state: string
    zip: string
  } | null
  placed_at: string
  completed_at: string
  status: string
  handoff: string
  price_breakdown: {
    subtotal: number
    total_discount: number
    total_tax: number
    total_fee: number
    tip: number
    total: number
  }
}

export const ordersApi = api.injectEndpoints({
  endpoints: builder => ({
    getOrders: builder.query<{ orders: Order[] }, number>({
      query: userId => `orders?user_id=${userId}`,
    }),
  }),
})
