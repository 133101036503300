import { getPointsExperience } from 'actions/pointsExperiences'
import ProtectedRoute from 'components/ProtectedRoute'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { selectPointsExperience } from 'selectors/pointsExperience'
import Appearance from './Appearance'
import Collect from './Collect'
import Expiration from './Expiration'
import Instructions from './Instructions'

const PointsExperienceEdit: React.FC<
  RouteComponentProps<{
    id: string
    section: string
  }>
> = ({ match }) => {
  const dispatch = useDispatch()
  const experienceId = match?.params?.id
  const section = match?.params?.section

  const pointsExperience = useSelector(state =>
    selectPointsExperience(state, experienceId)
  )

  useEffect(() => {
    dispatch(getPointsExperience(experienceId))
  }, [dispatch, experienceId])

  if (!pointsExperience || !section) return null

  return (
    <>
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="Points"
        exact
        path="/points/experiences/:id/collect/edit"
        component={Collect}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="Points"
        exact
        path="/points/experiences/:id/expiration/edit"
        component={() => <Expiration pointsExperience={pointsExperience} />}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="Points"
        exact
        path="/points/experiences/:id/instructions/edit"
        component={() => <Instructions pointsExperience={pointsExperience} />}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="Points"
        exact
        path="/points/experiences/:id/appearance/edit"
        component={() => <Appearance pointsExperience={pointsExperience} />}
      />
    </>
  )
}

export default PointsExperienceEdit
