import { Switch } from '@thanx/uikit/switch'
import { sendTestNotifications } from 'actions/campaigns'
import Input from 'components/Form/Input'
import RadioButton from 'components/Form/RadioButton'
import Form from 'formsy-react'
import type { Fields as Campaign } from 'models/Campaign'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { useVariant } from 'scenes/CampaignCenter/Builder/components/VariantContext'
import type { UserTypeT } from 'utilities/emailUtils'
import { getUserTypes } from 'utilities/emailUtils'

type Props = {
  campaign: Campaign
  show: boolean
  onModalClose: () => void
}

const SendTestModal: React.FC<Props> = props => {
  const { campaign, show, onModalClose } = props
  const userTypes = getUserTypes(campaign)

  const [enableSubmit, setEnableSubmit] = useState(false)
  const [userType, setUserType] = useState<UserTypeT | null>(
    userTypes.length > 1 ? userTypes[0] : null
  )
  const dispatch = useDispatch()
  const variant = useVariant()
  const { config } = useCampaignConfig()
  const configVariant = variant || config

  function onValidSubmit({ emails }: { emails: string }) {
    const params: { emails: Array<string>; user_type?: string } = {
      emails: emails.split(/[\s,]+/),
    }
    if (userType) {
      params.user_type = userType
    }

    if (configVariant.campaign_config_type === 'variant') {
      params['variant_id'] = configVariant.id
    }

    dispatch(sendTestNotifications(campaign.id, params))
    onModalClose()
  }

  return (
    <Modal
      bsClass="modal"
      show={show}
      onHide={onModalClose}
      className="fs-unmask"
    >
      <Modal.Body>
        <div className="absolute right-0 top-0 text-center close-button">
          <span
            className="font-size-32 grey-60 light cursor-pointer"
            onClick={onModalClose}
          >
            &times;
          </span>
        </div>
        <div className="padding-huge">
          <div className="margin-bottom-huge text-center">
            <h6 className="text-center margin-bottom-small">
              <Translate value="thanx_campaigns.builder.forms.messaging_tabs.send_test.title" />
            </h6>
            <p className="grey-70 font-size-16">
              <Translate value="thanx_campaigns.builder.forms.messaging_tabs.send_test.description" />
            </p>
          </div>
          <Form
            onValidSubmit={onValidSubmit}
            onValid={() => setEnableSubmit(true)}
            onInvalid={() => setEnableSubmit(false)}
          >
            <div>
              <Input
                componentClass="textarea"
                name="emails"
                label={I18n.t(
                  'thanx_campaigns.builder.forms.messaging_tabs.send_test.email_label'
                )}
                helpBlock={
                  <em className="font-size-12 grey-50 block margin-top-tiny">
                    <Translate value="thanx_campaigns.builder.forms.messaging_tabs.send_test.email_help" />
                  </em>
                }
                validations={{
                  isRequired: true,
                  mustBeDelimitedEmails: true,
                }}
                validationErrors={{
                  isRequired: I18n.t('validations.must_be_delimited_emails'),
                  mustBeDelimitedEmails: I18n.t(
                    'validations.must_be_delimited_emails'
                  ),
                }}
                trimValue={true}
                inputProps={{ rows: 5 }}
              />
              <Switch condition={userTypes.length > 1}>
                <div className="margin-top-extra-large margin-bottom-extra-large">
                  <div className="margin-bottom-medium">
                    <Translate
                      className="bold font-size-14"
                      value="thanx_campaigns.builder.forms.messaging_tabs.send_test.variant.title"
                    />
                  </div>
                  {userTypes.map(userTypeItem => (
                    <RadioButton
                      className="margin-right-medium font-size-14"
                      key={userTypeItem}
                      title={I18n.t(
                        `thanx_campaigns.builder.forms.messaging_tabs.send_test.variant.${userTypeItem}`
                      )}
                      onClick={() => setUserType(userTypeItem)}
                      checked={userTypeItem === userType}
                    />
                  ))}
                </div>
              </Switch>
              <Button
                bsStyle="primary"
                bsSize="large"
                className="pull-right"
                disabled={!enableSubmit}
                type="submit"
              >
                <Translate value="thanx_campaigns.builder.forms.messaging_tabs.send_test.submit_button" />
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SendTestModal
