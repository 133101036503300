import { Fields, ShortType } from 'models/EffectiveDiscountRateMetric'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

export const GET_EFFECTIVE_DISCOUNT_RATE_METRICS =
  'effective_discount_rate_metrics/GET_METRICS'
export const GET_EFFECTIVE_DISCOUNT_RATE_METRICS_SUCCESS =
  'effective_discount_rate_metrics/GET_METRICS_SUCCESS'
export const GET_EFFECTIVE_DISCOUNT_RATE_METRICS_FAIL =
  'effective_discount_rate_metrics/GET_METRICS_FAIL'

export type GetEffectiveDiscountRateMetricsAction = AxiosAction<
  typeof GET_EFFECTIVE_DISCOUNT_RATE_METRICS
>

export function getEffectiveDiscountRateMetrics(
  type: ShortType,
  params: {} = {}
): GetEffectiveDiscountRateMetricsAction {
  return {
    type: GET_EFFECTIVE_DISCOUNT_RATE_METRICS,
    payload: {
      client: 'default',
      request: {
        url: `effective_discount_rate/${type}`,
        method: 'get',
        params,
      },
    },
  }
}

export type ResolvedAction =
  | FailureAction<typeof GET_EFFECTIVE_DISCOUNT_RATE_METRICS_FAIL>
  | SuccessAction<
      typeof GET_EFFECTIVE_DISCOUNT_RATE_METRICS_SUCCESS,
      { metric: Fields },
      GetEffectiveDiscountRateMetricsAction
    >
