import { Switch } from '@thanx/uikit/switch'
import { getCumulativeCaptureRateMetrics } from 'actions/captureRateMetrics'
import UnlockIcon from 'components/Icons/Unlock'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { CumulativeMetric } from 'models/CaptureRateMetric'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import { crrAccessState } from 'scenes/Reports/CaptureRateReport/utilities'
import { getBenchmarkRate } from 'scenes/Reports/helpers'
import { selectCumulativeMetric } from 'selectors/captureRateMetric'
import AvailableAfterButton from '../AvailableAfterButton'
import Benchmark from '../Benchmark'
import Card from '../Card'
import MetricCounter from '../MetricCounter'
import UnlockButton from '../UnlockButton'
import { MetricCardT } from './index'

const t = buildTranslate('home.metrics')

const CaptureRateMetric: React.FC<MetricCardT> = ({ merchant }) => {
  const dispatch = useDispatch()
  const reportAccessState = crrAccessState(merchant)
  const remainingDays =
    reportAccessState === 'available' || reportAccessState === 'coming_soon'
      ? null
      : reportAccessState

  useEffect(() => {
    if (merchant.id && reportAccessState === 'available') {
      dispatch(
        getCumulativeCaptureRateMetrics({
          merchant_id: merchant.id,
        })
      )
    }
  }, [dispatch, merchant.id, reportAccessState])

  const cumulativeMetrics = useSelector(state =>
    selectCumulativeMetric(state, merchant?.id)
  ) as CumulativeMetric
  const { status, current, previous, isErrored, isLoading } =
    cumulativeMetrics ?? {}

  const { rate: benchmarkRate } = getBenchmarkRate(
    merchant.merchant_type,
    'revenue_capture_rate'
  )

  return (
    <Card
      title={t('revenue_capture_rate.title')}
      onClick={() => dispatch(push('/reports/capture_rate'))}
      sections={[
        { content: t('revenue_capture_rate.popovers.section_1') },
        { content: t('revenue_capture_rate.popovers.section_2') },
        {
          title: t('shared.popovers.how_its_calculated'),
          content: t('revenue_capture_rate.popovers.section_3'),
        },
        {
          title: t('shared.popovers.example'),
          content: t('revenue_capture_rate.popovers.section_4'),
        },
      ]}
    >
      <Switch
        condition={reportAccessState === 'available'}
        fallback={
          <AvailableAfterButton
            willBeAvailableAfterLaunch={reportAccessState === 'coming_soon'}
            remainingDays={remainingDays}
          />
        }
      >
        <Switch
          condition={status === 'complete' || !!cumulativeMetrics?.isLoading}
          fallback={
            <UnlockButton
              onClick={() => dispatch(push('/reports/capture_rate'))}
              icon={<UnlockIcon />}
              text={t('revenue_capture_rate.unlock_your_rate')}
            />
          }
        >
          <MetricCounter
            currentRate={current?.capture_rate}
            previousRate={previous?.capture_rate}
            isLoading={isLoading}
            isErrored={isErrored}
          />
        </Switch>
      </Switch>
      <Benchmark benchmarkRate={benchmarkRate} />
    </Card>
  )
}

export default CaptureRateMetric
