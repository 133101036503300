import { Text } from '@thanx/uikit/text'
import React from 'react'

type PropsT = {
  metric: string | number
  children?: JSX.Element
  primary?: boolean
}

const Metric: React.FC<PropsT> = props => {
  const { metric, children, primary = true } = props
  const MetricText = primary ? Text.BodyText4 : Text.BodyText5
  return (
    <div className={primary ? 'text-nowrap' : 'text-nowrap mt-nxxs'}>
      <MetricText color="grey70" className="mr-xs">
        {metric}
      </MetricText>
      {children}
    </div>
  )
}

export default Metric
