const settlementCredits = {
  title: 'Thanx Statement Credit Terms of Service',
  intro:
    'These Thanx Statement Credit Terms of Service (these “Terms of Service”), which are a part of the Thanx Merchant Terms of Service Agreement (the “Agreement”) between you and Thanx, Inc. (“Thanx”), apply if you elect to offer Statement Credits as part of your Loyalty Program. Capitalized terms used, but not defined, in these Terms of Service, shall have the meanings ascribed to such terms in the Agreement. In the event of a conflict between these Terms of Service and the Agreement, these Terms of Service will govern.',
  changes: {
    title: 'Changes to these Terms of Service',
    content:
      'Thanx may change these Terms of Service from time to time. The most current version of these Terms of Service, which will be posted at <a href="http://www.thanx.com/statement-credit-terms">www.thanx.com/statement-credit-terms</a>, will govern the Statement Credit functionality and your use thereof. If you do not agree to any changes Thanx makes to these Terms of Service, you should discontinue offering Statement Credits as your continued use of the Statement Credit functionality shall constitute your acceptance of such changes.',
  },
  credits: {
    title: 'Statement Credits.',
    items: [
      'Statement Credits are available only with credit cards and not debit cards, prepaid cards or other means of payment. You authorize Thanx to direct the applicable payment card networks (e.g. Visa, Mastercard, American Express) (“Card Networks”) to apply Statement Credits offered as a part of your Loyalty Program to the applicable credit card accounts designated by Program Participants. The Statement Credit functionality will not affect the price paid at the point of sale.',
      'Once Thanx has directed the Card Network to issue a Statement Credit, you are responsible to Thanx for the full for the value of such Statement Credit. You acknowledge and agree that Statement Credits are not subject to reversal, adjustment, or offset under any circumstance. For example, a Statement Credit will not be reversed if the Program Participants returns a qualifying purchase, or if the qualifying purchase is charged back or otherwise disputed. Thanx shall not be responsible for any Statement Credits that cannot be posted or are retained by the Program Participants’ credit card issuer and such events shall not affect your obligation to pay Thanx for the full value of Statement Credits that Thanx directs the Card Networks to issue.',
      'Your brand name, possibly truncated or abbreviated, may appear on the user’s credit card statement along with the Statement Credit.',
      'Statement credits may not be used as evidence for representment of disputed charges. Thanx and the Card Networks are not responsible for and have no liability to you for Statement Credits resulting from fraudulent purchases or other transactions.',
      'Once a Card Network has issued a Statement Credit instruction, any such Statement Credit that cannot be posted or otherwise provided to the Program Participant by the applicable issuer may be retained by the issuer of the Program Participant’s credit card. By identifying qualifying purchases, processing any Statement Credits for fulfillment of offers, and by making information about the foregoing available, Card Networks are not issuing or maintaining any type of financial obligation or deposit or other asset account for you, or holding funds or other value for Program Participants for distribution to Program Participants. Any pending Statement Credits and any associated dollar values represent offer fulfillment rebates in process, and not your funds or balances maintained or held by the Card Network.',
      'Thanx or any Card Network may decide at any time to terminate the processing of Statement Credits for fulfillment of any or all of Merchant’s offers. The Card Network nor Thanx will not be liable to you or Program Participants for terminating the processing of Statement Credits, and you shall be solely responsible for fulfilling any offers that may be outstanding in the event of termination. Neither Thanx nor the Card Networks will be liable for any costs, losses, or damages as result of exercising its termination rights in this Agreement.',
    ],
  },
  credit_funding: {
    title: 'Statement Credit Funding.',
    items: [
      'You are required to pre-fund Statement Credits. To be eligible to offer Statement Credits, you must fund the Statement Credit campaign by providing Thanx with funds sufficient to cover the value of the Statement Credits that Thanx estimates, in its sole discretion, will be processed in the next 30 days. Thanx reserves the right, in its sole discretion, to impose additional requirements on your continued offering of Statement Credits including, without limitation, additional or alternative funding requirements. In the event that you discontinue offering Statement Credits, Thanx will refund any unused funds, less any Fees owing to Thanx.',
      'Funds provided to Thanx in connection with Statement Credits represent advance payments required to fulfill Statement Credits. For the avoidance of doubt, Thanx does not accept deposits or hold funds or other value for transmission.',
      'By electing to offer Statement Credits, you represent and warrant to Thanx that you are solvent, that your liabilities do not exceed your assets, and that you are financially capable of meeting your obligations to Thanx including, without limitation, to fund Statement Credits. You understand and agree that, by continuing to offer Statement Credits, the foregoing representation and warranty shall be deemed repeated and relied upon by Thanx in connection with each and every Statement Credit. You hereby grant Thanx a security interest in any and all funds that may be owed by Thanx to you, including, without limitation, any amounts paid to Thanx in connection with the funding of Statement Credits. You agree to execute and deliver such financing statements to Thanx as it may reasonably request.',
    ],
  },
  disclaimers: {
    title: 'Disclaimers.',
    content:
      ' The Thanx Platform (including the activity of the Card Networks) including Statement Credits and related functionality are provided “AS IS” and on an “AS AVAILABLE” basis. Thanx does not guarantee the delivery of Statement Credits will be error free or uninterrupted and THANX HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON- INFRINGEMENT AND WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. Thanx and the Card Networks will not be liable to you or your Program Participants for any failures, delays and/or interruptions in the operation of Statement Credits, failures or errors in connection with Statement Credits, including without limitation initiating or failing to initiate a Statement Credit, any card issuer’s failure to process or error in processing a Statement Credit, or any other event outside their respective control.',
  },
  indemnification: {
    title: 'Indemnification.',
    content:
      'You will defend, indemnify and hold Thanx, the Card Networks, and their respective affiliated entities harmless from any settlement, damage award, liability or cost incurred by any of them in connection with a claim brought by a third party arising out of or related to: (a) Statement Credits offered via Thanx as part of your Loyalty Program; (b) a violation by you of your representations and warranties; or (c) a breach by you of this Agreement.',
  },
  liability: {
    title: 'Limitation of Liability.',
    content:
      ' IN NO EVENT WILL THANX OR THE CARD NETWORKS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE THANX PLATFORM, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE THANX PLATFORM, OR FOR ANY INFORMATION OBTAINED FROM OR THROUGH THE THANX PLATFORM, ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. In no event will Thanx’s aggregate liability exceed the Fees payable in the 12 month period prior to the event that gave rise to the claim.',
  },
}

export default settlementCredits
