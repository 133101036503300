import { OrderDetails } from 'models/Order'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_PURCHASE_ORDER = 'users/GET_PURCHASE_ORDER'
export const GET_PURCHASE_ORDER_SUCCESS = 'users/GET_PURCHASE_ORDER_SUCCESS'
export const GET_PURCHASE_ORDER_FAIL = 'users/GET_PURCHASE_ORDER_FAIL'

export type GetEventOrderAction = AxiosAction<
  typeof GET_PURCHASE_ORDER,
  { purchase: OrderDetails }
>

export function getPurchaseDetails(purchaseId: number): GetEventOrderAction {
  return {
    type: GET_PURCHASE_ORDER,
    payload: {
      client: 'default',
      request: {
        url: `purchases/${purchaseId}`,
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getPurchaseDetails>
>
