import { UrlType, UrlValue } from './components/UrlPicker'

type ImageExtension = 'jpeg' | 'jpg' | 'png'

export function getExtension(fileName = ''): ImageExtension | null {
  if (typeof fileName === 'string') {
    //https://stackoverflow.com/a/47767860/1249958 It gets rid of hashes and query string to obtain extension.
    const extension = fileName.split(/[#?]/)[0].split('.').pop()?.trim() || ''
    if (['jpeg', 'jpg', 'png'].includes(extension)) {
      return extension as ImageExtension
    }
  }
  return null
}

export function getDisplayedUrl(url: string, authenticable: boolean): string {
  if (
    authenticable &&
    /^https:\/\/auth.thanx(.*?).com/.test(url) &&
    url.indexOf('redirect_uri') > -1
  ) {
    const redirectUri = new URL(url).searchParams.get('redirect_uri')
    if (redirectUri) return redirectUri
  }

  return url
}

export function getUrlValue(
  url: string = '',
  authenticable: boolean = false
): UrlValue {
  let type = UrlType.NoLink
  let queryParams: string | undefined
  if (url.startsWith('thanx://')) {
    type = UrlType.Inapp
    queryParams = url.split('?')[1]
  } else if (url) {
    type = UrlType.External
  }

  return {
    inapp: type === UrlType.Inapp ? url.split('?')[0] : '',
    external:
      type === UrlType.External ? getDisplayedUrl(url, authenticable) : '',
    fullUrl: url,
    type,
    authenticable,
    queryParams,
  }
}
