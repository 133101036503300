import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

type Props = {
  text: string
  icon: string
  iconAlt?: string
  addText?: boolean
  isEditable?: boolean
  isAdding?: boolean
  onAdd: () => void
}
/**
 * This is the layout for a content section in the campaign center program review page.
 */
const EmptyContentSection: React.FC<Props> = props => {
  const {
    addText,
    icon,
    iconAlt = '',
    isEditable = true,
    isAdding = false,
    onAdd,
    text,
  } = props

  return (
    <Row className="section d-flex align-items-center is-empty">
      <Col className="col-header">
        <img src={icon} alt={iconAlt} />
      </Col>
      <Col className="col-content">
        <Text.BodyText3 color="grey70">{text}</Text.BodyText3>
      </Col>
      {isEditable && (
        <Col className="text-right">
          <Button size="small" onClick={onAdd} isLoading={isAdding}>
            {addText}
          </Button>
        </Col>
      )}
    </Row>
  )
}

export default EmptyContentSection
