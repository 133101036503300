import orm from 'models/orm'
import { Fields } from 'models/PointsProduct'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectPointsProducts = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.PointsProduct.all()
      .toRefArray()
      .sort((a, b) => a.cost - b.cost)
  }
)

export const selectActivePointsProducts = createSelector(
  selectPointsProducts,
  (pointsProducts: any): Fields[] => {
    return pointsProducts.filter(pp => pp.state === 'active')
  }
)

export const selectPointsProduct = createSelector(
  (state: any) => selectPointsProducts(state),
  (_: any, id?: string | number): string | number | undefined => id,
  (pointsProducts: any, id?: string | number): Fields => {
    return pointsProducts.find(pp => pp.id === Number(id))
  }
)
