import { FormsySelect } from '@thanx/uikit/select'
import Modal from 'components/ModalContainer'
import Formsy from 'formsy-react'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { SelectOptionT } from 'utilities/types'
import { useAttributionWindow } from './AttributionWindowContext'

const t = buildTranslate('thanx_campaigns.report.v3.attribution')

type Props = {
  isOpen: boolean
  onClose: () => void
}

const AttributionModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { attributionWindow, setAttributionWindow } = useAttributionWindow()
  const applyChange = () => {
    setAttributionWindow(selectedWindow)
    onClose()
  }
  const [selectedWindow, setSelectedWindow] =
    useState<number>(attributionWindow)
  const attributionWindowOptions: Array<SelectOptionT> = Array.from(
    { length: 14 },
    (_, i) => ({ label: `${i + 1}`, value: i + 1 })
  )

  return (
    <Modal
      canProceed={true}
      title={t('attribution')}
      subtitle={
        'Attribute purchases and/or redemptions made within X days of receiving this campaign.'
      }
      proceedText={'Apply'}
      isOpen={isOpen}
      onClose={onClose}
      onProceed={applyChange}
    >
      <Formsy className="mt-xl">
        <FormsySelect
          name="days"
          label="Days"
          options={attributionWindowOptions}
          onChange={setSelectedWindow}
          value={selectedWindow}
          searchable={false}
          overrides={{
            Popover: {
              props: {
                overrides: {
                  Body: {
                    style: {
                      // Make sure the dropdown displays on top of the modal
                      zIndex: 20000,
                    },
                  },
                },
              },
            },
          }}
        />
      </Formsy>
    </Modal>
  )
}

export default AttributionModal
