import React from 'react'

type Props = {
  boxes: React.ReactNode[]
}

const PerformanceMetricsView: React.FC<Props> = ({ boxes }) => (
  <div className="display-flex flex-wrap negative-margin-small">
    {boxes.map((box, i) => (
      <div key={i} className="margin-small flex-1">
        {box}
      </div>
    ))}
  </div>
)

export default PerformanceMetricsView
