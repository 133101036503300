import React, { useState } from 'react'
import { Nav, NavItem, Tab } from 'react-bootstrap'
import { UserTypeT } from 'utilities/emailUtils'
import { UserTypeContent } from './helpers'
import Preview from './Preview'
import PreviewNavItem from './PreviewNavItem'

type Props = {
  userTypes: Array<UserTypeT>
  navStyle: 'tab' | 'radio'
  memberContent: UserTypeContent
  subscriberContent: UserTypeContent
  previewWidth?: string
  showDescription?: boolean
}

const TabContainer: React.FC<Props> = ({
  userTypes,
  memberContent,
  subscriberContent,
  navStyle,
  previewWidth,
  showDescription = true,
}) => {
  const [activeKey, setActiveKey] = useState(userTypes[0])

  return (
    <Tab.Container
      id="email-preview"
      defaultActiveKey={userTypes[0]}
      onSelect={setActiveKey}
    >
      <div>
        <Nav
          bsClass={`${
            navStyle === 'radio' ? 'radio-nav' : 'btn-nav'
          } margin-bottom-extra-large border-bottom-1 grey-20-border`}
        >
          {userTypes.map(userType => (
            <NavItem eventKey={userType} key={userType}>
              <PreviewNavItem
                navStyle={navStyle}
                userType={userType}
                activeKey={activeKey}
              />
            </NavItem>
          ))}
        </Nav>
        <Tab.Content animation={false}>
          {userTypes.map(userType => {
            return (
              <Tab.Pane eventKey={userType} key={userType}>
                <Preview
                  userType={userType}
                  navStyle={navStyle}
                  userContent={
                    userType === 'member' ? memberContent : subscriberContent
                  }
                  width={previewWidth}
                  showDescription={showDescription}
                />
              </Tab.Pane>
            )
          })}
        </Tab.Content>
      </div>
    </Tab.Container>
  )
}

export default TabContainer
