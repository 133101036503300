import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import cardActivationReport from 'assets/images/home/card_activation_report.svg'
import cardImageCopyEmail from 'assets/images/home/card_copy_email.svg'
import cardCRR from 'assets/images/home/card_crr.png'
import cardEDRReport from 'assets/images/home/card_edr_report.svg'
import cardEmojis from 'assets/images/home/card_emojis.svg'
import cardEngagementReport from 'assets/images/home/card_engagement_report.png'
import cardIntroToPoints from 'assets/images/home/card_intro_to_points.png'
import cardMtmEffectiveness from 'assets/images/home/card_mtm_effectiveness.svg'
import cardMx4Updates from 'assets/images/home/card_mx4_updates.svg'
import cardNonDiscountRewards from 'assets/images/home/card_non_discount_rewards.png'
import cardOrderConversionTargeting from 'assets/images/home/card_order_conversion_targeting.svg'
import cardRetentionReport from 'assets/images/home/card_retention_report.svg'
import cardToastOrderingManagement from 'assets/images/home/card_toast_ordering_management.svg'
import customizableApps from 'assets/images/home/img_card_customizableHomePages.png'
import cardManagedSupportImage from 'assets/images/home/img_card_managed_support.png'
import cardSmartLinks from 'assets/images/home/img_card_smartLinks.png'
import cardMTMWebinar from 'assets/images/home/img_card_webinarMTM.png'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { push } from 'react-router-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import { useAbility } from 'utilities/ability'
import {
  cardOrderConversionTargetingUrl,
  copyEmailUrl,
  customizableAppsUrl,
  emojisUrl,
  introToPointsUrl,
  mtmEffectivenessUrl,
  mtmWebinarUrl,
  mx4UpdatesUrl,
  orderingManagement,
  smartLinksUrl,
} from 'utilities/urlUtils'
import ButtonRow from './ButtonRow'
import Carousel from './Carousel'
import Metrics from './Metrics'

const t = buildTranslate('home')

const Home: React.FC = () => {
  const dispatch = useDispatch()
  const [css] = useStyletron()
  const merchantUser = useSelector(selectCurrentMerchantUser)
  const merchant = useCurrentMerchant()
  const ability = useAbility()
  const roles =
    merchant && merchantUser && merchantUser.roles
      ? merchantUser.roles[merchant.id]
      : []
  const welcome = merchantUser?.first_name
    ? t('welcome_name', { name: merchantUser?.first_name })
    : t('welcome')

  const showToastOrderingCards =
    merchant?.ordering_enabled &&
    merchant?.ordering_provider === 'toast' &&
    ability.can('manage', 'OrderingManagement')

  const showMTMCards = ability.can('manage', 'Insights')

  const toastOrderingManagementCards = [
    {
      image: cardToastOrderingManagement,
      onClick: () => {
        window.open(orderingManagement)
      },
    },
  ]

  const mtmCards = [
    {
      image: cardActivationReport,
      imageAlt: I18n.t('activation_rate.title'),
      onClick: () => {
        dispatch(push('/reports/activation'))
      },
    },
    {
      image: cardEDRReport,
      imageAlt: I18n.t('effective_discount_rate.title'),
      onClick: () => {
        dispatch(push('/points#effective_discount_rate'))
      },
    },
    {
      image: cardRetentionReport,
      imageAlt: I18n.t('retention_rate.title'),
      onClick: () => {
        dispatch(push('/reports/retention_rate'))
      },
    },
  ]

  const isEmailCopyingEnabled = useFlag('MX4-Copy-An-Email', false)
  const cardEmailCopying = {
    image: cardImageCopyEmail,
    imageAlt: I18n.t(
      'thanx_campaigns.builder.forms.messaging_tabs.import.title_v2'
    ),
    onClick: () => window.open(copyEmailUrl),
  }

  const cards = [
    ...(roles?.includes('admin')
      ? [
          {
            image: cardSmartLinks,
            imageAlt: I18n.t('smart_links.title'),
            onClick: () => {
              window.open(smartLinksUrl)
            },
          },
        ]
      : []),
    {
      image: cardManagedSupportImage,
      onClick: () => {
        dispatch(push('/user_support'))
      },
    },
    {
      image: customizableApps,
      onClick: () => {
        window.open(customizableAppsUrl)
      },
    },
    {
      image: cardEngagementReport,
      imageAlt: I18n.t('engagement.title'),
      onClick: () => {
        dispatch(push('/reports/engagement'))
      },
    },
    {
      image: cardOrderConversionTargeting,
      onClick: () => {
        window.open(cardOrderConversionTargetingUrl)
      },
    },
    {
      image: cardMtmEffectiveness,
      onClick: () => {
        window.open(mtmEffectivenessUrl)
      },
    },
    ...(isEmailCopyingEnabled ? [cardEmailCopying] : []),
    ...(showMTMCards ? mtmCards : []),
    {
      image: cardMTMWebinar,
      onClick: () => {
        window.open(mtmWebinarUrl)
      },
    },
    ...(showToastOrderingCards ? toastOrderingManagementCards : []),
    {
      image: cardEmojis,
      onClick: () => {
        window.open(emojisUrl)
      },
    },
    ...(roles?.includes('admin')
      ? [
          {
            image: cardCRR,
            imageAlt: I18n.t('capture_rate.title'),
            onClick: () => {
              dispatch(push('/reports/capture_rate'))
            },
          },
        ]
      : []),
    {
      image: cardMx4Updates,
      onClick: () => {
        window.open(mx4UpdatesUrl)
      },
    },
    {
      image: cardIntroToPoints,
      onClick: () => {
        window.open(introToPointsUrl)
      },
    },
    {
      image: cardNonDiscountRewards,
      onClick: () => {
        window.open(
          'https://www.thanx.com/wp-content/uploads/the-great-big-book-of-non-discount-rewards.pdf'
        )
      },
    },
  ]

  return (
    <div
      className={`${css({
        minHeight: '100%',
      })} grey-05-bkg`}
    >
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <Container>
        <Row className="my-xxl">
          <Col>
            <Text.Header1 className="mt-0">{welcome}</Text.Header1>
            <Text.BodyText2 color="grey70">{t('subtitle')}</Text.BodyText2>
          </Col>
        </Row>
        <ButtonRow roles={roles} />
        <Row>
          <Col>
            <Carousel cards={cards} />
          </Col>
        </Row>
        {merchant && roles?.includes('admin') ? (
          <Metrics merchant={merchant} />
        ) : null}
      </Container>
    </div>
  )
}

export default Home
