import isEmpty from 'lodash/isEmpty'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import type { Fields as CampaignVariant } from '../models/CampaignVariant'
import orm from '../models/orm'

export const selectAllCampaignVariants = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): CampaignVariant[] => {
    return session.CampaignVariant.all().toRefArray()
  }
)

export const selectCampaignVariant = createSelector(
  selectAllCampaignVariants,
  (_, id?: number): number | undefined => id,
  (variants, id): CampaignVariant | null =>
    variants.find(v => v.id === Number(id)) ?? null
)

export const selectCampaignVariants = createSelector(
  selectAllCampaignVariants,
  (_, ids): number[] => ids,
  (variants, ids): CampaignVariant[] | null =>
    variants.filter(v => ids.includes(v.id)) ?? null
)

export const selectCampaignVariantsByCampaignId = createSelector(
  selectAllCampaignVariants,
  (_, campaignId: number | undefined): number | undefined => campaignId,
  (variants, campaignId): CampaignVariant[] | null => {
    if (isEmpty(variants)) return null
    return variants.filter(v => v.campaign_id === campaignId)
  }
)
