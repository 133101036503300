import { alert } from 'actions/flash'
import { pointsApi } from 'api/points'
import { getErrorMessage, isError } from 'api/utils'
import { Fields as PointsExperience } from 'models/PointsExperience'
import { useDispatch } from 'react-redux'

export const useGetPointsMultipler = (
  multiplierId: number,
  pointsExperience: PointsExperience
) => {
  // It's very unlikely that loading state will be triggered,
  // as we already have fetched multiplers on the list page.
  const { data, isLoading: isFetching } = pointsApi.useGetMultipliersQuery(
    pointsExperience.id
  )
  const multipliers = data?.points_multipliers ?? []

  const multiplier = multipliers.find(
    multiplier => multiplier.id === multiplierId
  )

  return { multiplier, isFetching }
}

// Verify that the response is an error, and if so, dispatch an alert to display the message.
export const useDisplayError = () => {
  const dispatch = useDispatch()

  return (response: any, genericMessage: string) => {
    if (isError(response)) {
      dispatch(
        alert({
          key: 'danger',
          message: getErrorMessage(response.error, genericMessage),
        })
      )
    }
  }
}
