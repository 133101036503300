import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'
import { UserCountMetric } from 'models/CampaignMetric'

export type Category = { name: string; count: number }
type RawCategories = { [label: string]: number }

export function getCategories(rawCategories: RawCategories | {}): Category[] {
  const categories: { name: string; count: number }[] = []
  forEach(rawCategories, (count, category) =>
    categories.push({ name: category, count: count })
  )

  return sortBy(categories, 'count')
}

export function getCategoryPercent(
  userCountMetric: UserCountMetric | null,
  rawCategories: RawCategories | {},
  category: string
) {
  if (!userCountMetric?.data?.purchase_user_count) return null

  let userCount = userCountMetric.data.purchase_user_count
  let categoryCount = rawCategories?.[category]
  if (!categoryCount) return null

  return categoryCount / userCount
}
