import { AxiosAction } from 'redux-axios-middleware'
import type { UserTypeT } from 'utilities/emailUtils'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_PREVIEW = 'campaigns/GET_PREVIEW'
export const GET_PREVIEW_SUCCESS = 'campaigns/GET_PREVIEW_SUCCESS'
export const GET_PREVIEW_FAIL = 'campaigns/GET_PREVIEW_FAIL'

type PreviewT = 'email'

interface Fields {
  html: string
}

export function getPreview(
  campaignId: number,
  type: PreviewT,
  userType?: UserTypeT,
  variant_id?: number | null
): AxiosAction<typeof GET_PREVIEW, { preview: Fields }> {
  return {
    type: GET_PREVIEW,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${campaignId}/previews/${type}`,
        method: 'get',
        params: {
          user_type: userType,
          variant_id,
        },
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<ReturnType<typeof getPreview>>
