import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { push } from 'react-router-redux'

type Props = {
  triggerSave: () => Promise<void>
}

const SaveAndExit: React.FC<Props> = ({ triggerSave }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()

  function onClick() {
    triggerSave()
    setModalOpen(true)
  }

  function exit() {
    closeModal()
    dispatch(push('/thanx_campaigns'))
  }

  function closeModal() {
    setModalOpen(false)
  }

  return (
    <div className="inline-block">
      <Button
        bsSize="large"
        className="btn-inverted margin-right-medium"
        onClick={onClick}
      >
        <Translate value="thanx_campaigns.builder.steps.nav.buttons.exit" />
      </Button>

      <Modal className="fs-unmask" show={modalOpen} onHide={closeModal}>
        <Modal.Body>
          <div className="clearfix">
            <div className="pull-right">
              <Button
                onClick={closeModal}
                className="btn-borderless padding-right-none"
              >
                <span className="font-size-40 line-height-30 grey-60 light">
                  &times;
                </span>
              </Button>
            </div>
          </div>

          <Translate
            value="thanx_campaigns.builder.exit_modal.title"
            className="text-center margin-bottom-huge"
            tag="h6"
          />

          <Translate
            tag="div"
            className="width-75 center-block text-center margin-bottom-huge"
            value="thanx_campaigns.builder.exit_modal.body"
          />
          <div className="text-right">
            <Button
              bsSize="large"
              bsStyle="info"
              className="margin-right-medium"
              onClick={exit}
            >
              <Translate value="thanx_campaigns.builder.exit_modal.buttons.exit" />
            </Button>

            <Button onClick={closeModal} bsStyle="primary" bsSize="large">
              <Translate value="thanx_campaigns.builder.exit_modal.buttons.finish" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SaveAndExit
