import { Fields } from 'models/Menu'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectMenus = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.Menu.all().toRefArray()
  }
)

export const selectEnabledMenus = createSelector(
  selectMenus,
  (menus: any): Fields[] => {
    return menus.filter(menu => menu.enabled === true)
  }
)

export const selectDisabledMenus = createSelector(
  selectMenus,
  (menus: any): Fields[] => {
    return menus.filter(menu => menu.enabled !== true)
  }
)

export const selectMenusByLocationId = createSelector(
  selectMenus,
  (_, id): string | number => id,
  (menus, id: string | number): Fields[] => {
    return menus.filter(menu => menu.location_id === id)
  }
)
