import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import moment from 'moment-timezone'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { useUserTimeZone } from 'utilities/userTimeZone'

type Props = {
  campaign: Campaign
}

const t = buildTranslate('thanx_campaigns.report.details.send_at')

const SendAt: React.FC<Props> = ({ campaign }) => {
  const userTimeZone = useUserTimeZone()

  if (!campaign.start_at) return null

  function formatDate(date: string) {
    return moment.tz(date, userTimeZone).format('LL')
  }

  const content = (function () {
    if (campaign.end_at) {
      return (
        <>
          <div className="mb-m">
            <div className="mb-xs bold">{t('start')}</div>
            <div className="mb-xs">{formatDate(campaign.start_at)}</div>
          </div>
          <div>
            <div className="mb-xs bold">{t('stop')}</div>
            <div className="mb-xs">{formatDate(campaign.end_at)}</div>
          </div>
        </>
      )
    } else {
      if (campaign.state === 'scheduled' || campaign.state === 'in_review') {
        return t('description_future', {
          sendAt: formatDate(campaign.start_at),
        })
      } else {
        return t('description_past', { sendAt: formatDate(campaign.start_at) })
      }
    }
  })()

  return (
    <Row className="section">
      <Col className="col-header" sm={2}>
        <Translate value="thanx_campaigns.report.details.send_at.title" />
      </Col>
      <Col sm={8}>
        <div className="body-text-3 grey-70-text">{content}</div>
      </Col>
    </Row>
  )
}

export default SendAt
