import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { getExtension } from 'scenes/Cms/helper'
import { selectApp } from 'selectors/app'
import Header from '../../components/Header'
import Asset from '../Asset'
import ImageAsset from '../ImageAsset'

const t = buildTranslate('cms.brand.app_store_assets')

const AndroidFeature: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const { android_feature_graphic_image_urls: featureImage } = app

  return (
    <Section className="p-xl mt-l">
      <Header title={t('android_feature.title')} />
      <Asset
        className="mt-s"
        description={t('android_feature.description')}
        badges={[t('android_feature.size'), getExtension(featureImage?.small)]}
      >
        <ImageAsset width="180px" height="88px" image={featureImage?.small} />
      </Asset>
    </Section>
  )
}

export default AndroidFeature
