import { Button, Kind, Size } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import noIcon from 'assets/images/points/img_noIcon.svg'
import Help from 'components/Help'
import PopoverInfo from 'components/PopoverInfo'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import React from 'react'
import { push } from 'react-router-redux'
import { useStyletron } from 'styletron-react'
import { merchantTheme } from 'theme'
import { helpArticles } from './helpers'

type Props = {
  canEdit: boolean
  pointsExperience: PointsExperience
}

const t = buildTranslate('points.configuration.appearance')
const ht = buildTranslate('points.configuration.help')

const Appearance: React.FC<Props> = ({ canEdit, pointsExperience }) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()

  return (
    <section className="grey-90">
      <div className="mb-xl">
        <Text.Header4 className="mb-xs pl-0">{t('title')}</Text.Header4>
        <Text.BodyText3 color="grey70">{t('subtitle')}</Text.BodyText3>
      </div>

      <div className="card mb-m p-m white-bkg d-flex flex-row">
        <div className="flex-grow-1 d-flex flex-column">
          <Text.BodyText3 bold>{t('single_point_title')}</Text.BodyText3>
          <Text.BodyText3 color="grey70" className="mt-xs text-capitalize">
            {pointsExperience.currency_name}
          </Text.BodyText3>
          <Text.BodyText3 className="mt-m" bold>
            {t('multiple_points_title')}
          </Text.BodyText3>
          <Text.BodyText3 color="grey70" className="mt-xs text-capitalize">
            {pointsExperience.currency_name_plural}
          </Text.BodyText3>
          <Text.BodyText3 className="mt-m" bold>
            {t('primary_icon_title')}
            <PopoverInfo
              text={t('primary_icon_description')}
              bodyClassName={`${css({
                letterSpacing: '0.5px',
              })} font-size-12`}
              className="ml-xs"
            />
          </Text.BodyText3>

          <div className="mt-xs">
            <img
              width={48}
              className="border rounded"
              src={pointsExperience.currency_primary_icon.default || noIcon}
              alt="primary icon"
            />
          </div>
          <Text.BodyText3 className="mt-m" bold>
            {t('secondary_icon_title')}
            <PopoverInfo
              text={t('secondary_icon_description')}
              bodyClassName={`${css({
                letterSpacing: '0.5px',
              })} font-size-12`}
              className="ml-xs"
            />
          </Text.BodyText3>
          <div className="mt-xs">
            <img
              width={48}
              className={`border rounded ${css({
                backgroundColor: merchantTheme.colors.grey10,
              })}`}
              src={pointsExperience.currency_secondary_icon.default || noIcon}
              alt="secondary icon"
            />
          </div>
        </div>
        {canEdit && (
          <Button
            kind={Kind.SECONDARY}
            size={Size.SMALL}
            className={css({ height: '32px' })}
            onClick={() =>
              dispatch(
                push(
                  `/points/experiences/${pointsExperience.id}/appearance/edit`
                )
              )
            }
          >
            {t('edit')}
          </Button>
        )}
      </div>

      <Help title={ht('title')} articles={helpArticles.appearance} />
    </section>
  )
}
export default Appearance
