import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import AudienceSubstep from 'scenes/CampaignCenter/Builder/Steps/Location/Message/Setup/Substeps/Audience'

type Props = {
  campaign: Campaign
}

const Audience: React.FC<Props> = ({ campaign }) => {
  return (
    <div className="container">
      <AudienceSubstep campaign={campaign} />
    </div>
  )
}

export default Audience
