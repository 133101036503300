export default {
  send_message_past: 'This campaign was sent on %{date}.',
  send_message_future: 'This campaign will be sent on %{date}.',
  no_data: '~',
  header: {
    stopButton: 'Stop campaign',
    start: 'Start',
    end: 'End',
  },
  tabs: {
    impact: {
      name: 'impact',
      summary: {
        title: 'Campaign summary',
      },
      average_check: 'average check',
      successVerbs: {
        clicked: 'Clicked a link',
        viewed: 'Viewed your message',
        visited: 'Visited',
        tried_it: 'Tried it',
        enrolled: 'Signed up',
        redeemed: 'Redeemed the incentive',
        purchase: 'Made a purchase',
      },
      fallback_message:
        'There hasn’t been enough engagement to confidently show results yet',
      left_summary: {
        overview: 'Overview',
        interaction: {
          received: 'Received this campaign',
          opened: 'Opened the message',
          enrolled: 'Signed up',
          redeemed: 'Redeemed the offer',
          winback: 'Came back',
          upsell: 'Tried it',
          reputation: 'Left a review',
          made_a_purchase: 'Made a purchase',
        },
        spending: {
          expected:
            'What customers would have spent had you done nothing (control group)',
          net: 'The incremental revenue from this campaign',
          target_gross: 'Gross revenue measured',
          expected_warning:
            '<b class="block margin-bottom-medium">Include a control group</b>This campaign did not have control groups enabled. Include control groups in future campaigns to learn how much of this revenue would have happened anyway.',
        },
        roi: {
          control_warning:
            '<b class="block margin-bottom-medium">Include a control group</b>This campaign did not have control groups enabled. ROI is calculated using data from control groups. Include control groups in future campaigns to calculate ROI.',
        },
        roi_range: {
          min: 'Min ROI',
          max: 'Max ROI',
          additional: 'Additional revenue from email subscribers',
          summary:
            'Your ROI is at least %{min}, and including email subscribers ROI is as high as %{max}.',
        },
        cost: 'Campaign costs: costs of rewards (%{count} redeemed)',
      },
      sections: {
        overview: {
          title: 'Overview',
          read: 'read your message',
          redeem: 'redeemed your incentive',
          objective: '%{count} customers %{interaction}.',
        },
        funnel: {
          title: 'Engagement',
          open_message: '%{percent} of customers opened the message',
          bricks: {
            received: 'Customers received this campaign',
            opened: 'Opened the message',
            clicked: 'Clicked a link',
            visited: 'Visited',
            tried_it: 'Tried it',
            enrolled: 'Signed up',
            redeemed: 'Redeemed the incentive',
          },
          button: 'View Customer Experience',
        },
        control: {
          vs: 'vs',
          no_control: {
            campaign: '??',
            without: '?',
            message:
              'Include <em>control groups</em> in future campaigns to measure how effective your campaign was.',
          },
          with_data: {
            message: {
              purchase: {
                equal:
                  'The control group showed that customers who received this campaign were as likely to make a purchase as those who didn’t.',
                more: 'The control group showed that customers who received the campaign %{successVerb} <strong>%{stat}x more</strong> compared to those who didn’t. ',
                more_uncertain:
                  'The control group showed that your campaign definitively drove more customers to make a purchase.',
                less: 'The control group showed that customers who received this campaign were %{stat}x less likely to make a purchase than those who didn’t.',
              },
            },
          },
          circle_titles: {
            with: 'With campaign',
            without: 'Without campaign',
          },
          purchase: 'Made a purchase',
          upsell: 'Tried it',
        },
        revenue: {
          title: 'Revenue',
          chart: {
            gross: 'Gross revenue measured during this campaign',
            would_have_spent: 'What customers would have spent',
            net: 'Incremental revenue',
          },
          message: {
            gross:
              'Customers who received this campaign spent %{amount} in gross revenue',
            net: ', %{amount} of which is incremental',
          },
          summary: {
            profit:
              'Customers who saw this campaign went on to spend %{gross}. Comparing these customers to the control group (who received nothing) shows that of the %{gross} they spent, without this campaign they would have spent %{expected}, meaning that <b class="bold">%{net} in revenue was a direct result of your campaign</b>.',
            loss: 'Customers who saw this campaign went on to spend %{gross}. Comparing these customers to the control group (who received nothing) shows that without this campaign they would have spent %{expected}. This resulted in them spending %{net} less than they would have without this campaign.',
            break_even:
              'This campaign didn’t meaningfully change your customers’ behavior. Those who saw this campaign went on to spend %{gross}, which is roughly what they would have spent anyway based on the behavior of the control group.',
            no_data:
              'There hasn’t been enough engagement to confidently show results yet.',
            no_control:
              'Customers who saw this campaign went on to spend %{gross}. <b class="bold">Whenever possible, use a control group to measure how much revenue would have happened anyway</b>.',
            button: {
              with_control: 'How this is measured',
              without_control: 'Learn about control groups',
            },
          },
        },
        cost: {
          title: 'Costs',
          message: 'The campaign cost %{total}',
          boxes: {
            redeemed: 'Rewards redeemed',
            per_reward: 'Cost per reward',
            total: 'Cost of campaign',
          },
        },
        roi: {
          title: 'ROI',
          message: {
            no_control: 'Unlock true ROI by using a control group',
            control: 'This campaign’s ROI is %{amount}',
          },
          boxes: {
            no_control: {
              net_revenue: {
                title: 'Gross revenue',
                stat: '%{amount}',
              },
              cost: {
                title: 'Costs',
                stat: '%{amount}',
                stat_no_redeem: '$0 (no reward offered)',
              },
              roi: {
                title: 'ROI',
                stat: 'We are unable to calculate true ROI without a control group',
              },
            },
            with_control: {
              net_revenue: {
                title: 'Incremental revenue',
              },
              cost: {
                title: 'Costs',
                stat: '%{amount}',
                stat_no_redeem: '$0 (no reward offered)',
              },
              roi: {
                title: 'ROI',
              },
            },
          },
          revenue_breakdown: {
            rows: {
              first: 'Loyalty members',
              second: 'Email subscribers',
            },
            columns: {
              received: 'Received the message',
              engaged: 'Engagement rate',
              purchase: 'Made a purchase',
              incremental: 'Incremental revenue',
              gross: 'Total spending',
              incremental_per_purchaser: 'Incremental per purchaser',
              gross_per_purchaser: 'Spending per purchaser',
            },
            info: {
              header:
                '+ %{emailIncremental} additional revenue by email subscribers',
              explanation:
                'When customers like email subscribers haven’t enrolled their card, you can still measure what they spend by looking at the behavior of your enrolled customers and extrapolating that.',
              breakdown:
                'For instance, %{rate} of enrolled customers that received your message made a purchase. If you apply that same behavior to your email subscribers, then of the %{count} email subscribers that received your message, %{rate} went on to make a purchase <strong>resulting in an additional %{emailIncremental} in revenue</strong>.',
            },
          },
        },
      },
    },
    experience: {
      name: 'customer experience',
      impact_button: 'View Campaign Impact',
    },
  },
}
