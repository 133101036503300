import ProtectedPageHeader from 'components/ProtectedPageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import AllCustomersTab from './AllCustomersTab'
import LoyaltyMembersTab from './LoyaltyMembersTab'

const t = buildTranslate('reports.customer')
enum Tabs {
  ALL_CUSTOMERS = 'all_customers',
  LOYALTY_MEMBERS = 'loyalty_members',
}

const CustomerReport: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    'all_customers' | 'loyalty_members'
  >('loyalty_members')
  const pageTitle = t(`tabs.${activeTab}.title_tag`)

  return (
    <ProtectedPageHeader title={t('title')} resource="Insights" action="manage">
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Tab.Container
        activeKey={activeTab}
        transition={false}
        onSelect={(tab: string | null) => {
          if (!tab) return
          setActiveTab(tab as Tabs)
        }}
      >
        <TabNav>
          <TabItem
            state={Tabs.LOYALTY_MEMBERS}
            title={t('tabs.loyalty_members.title')}
          />
          <TabItem
            state={Tabs.ALL_CUSTOMERS}
            title={
              <div className="d-flex align-content-center">
                <span className="mr-xxs">{t('tabs.all_customers.title')}</span>
              </div>
            }
          />
        </TabNav>
        <Tab.Content className="pb-l h-100 grey-05-bkg">
          <TabPane state={Tabs.ALL_CUSTOMERS}>
            <AllCustomersTab />
          </TabPane>
          <TabPane state={Tabs.LOYALTY_MEMBERS}>
            <LoyaltyMembersTab />
          </TabPane>
        </Tab.Content>
      </Tab.Container>
    </ProtectedPageHeader>
  )
}

export default CustomerReport
