// @flow
import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { push } from 'react-router-redux'
import { I18n } from 'react-redux-i18n'
import ConnectProvider from './components/ConnectProvider'
import EmailSuccess from './components/EmailSuccess'
import ImportSettingsContainer from './components/ImportSettingsContainer'
import qs from 'qs'
// $FlowFixMe
import store from 'store'

import type { Match } from 'flow-typed'
import type { Location } from 'flow-typed'

type Props = {
  match: Match,
  location: Location,
}
type State = {
  redirect: ?string,
}

const oldDashboardPath = process.env.REACT_APP_MERCHANT_ROOT_URL || ''

export default class ProviderSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      redirect: null,
    }
  }

  componentDidMount() {
    const queryParams = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    })
    const redirect = queryParams.redirect

    if (redirect) {
      window.sessionStorage.setItem('email_setup_redirect', redirect)
    }

    this.setState({
      redirect: window.sessionStorage.getItem('email_setup_redirect'),
    })
  }

  settingsCallback = () => {
    store.dispatch(push(`${this.props.match.path}/success`))
  }

  successCallback = () => {
    if (this.state.redirect) {
      return store.dispatch(push(this.state.redirect))
    } else {
      window.location = `${oldDashboardPath}/subscriptions`
    }
  }

  render = () => {
    return (
      <Switch>
        <Route
          exact
          path={`${this.props.match.path}`}
          render={() => <Redirect to={`${this.props.match.path}/connect`} />}
        />
        <Route
          exact
          path={`${this.props.match.path}/connect`}
          render={() => <ConnectProvider />}
        />
        <Route
          exact
          path={`${this.props.match.path}/settings/:providerType`}
          render={props => (
            <ImportSettingsContainer
              providerType={props.match.params.providerType}
              onSuccess={this.settingsCallback}
            />
          )}
        />
        <Route
          exact
          path={`${this.props.match.path}/success`}
          render={() => (
            <EmailSuccess
              title={I18n.t('emails.subscribers.setup.success.title')}
              description={I18n.t(
                'emails.subscribers.setup.success.description'
              )}
              button={I18n.t('emails.subscribers.setup.success.button')}
              handleContinue={this.successCallback}
            />
          )}
        />
      </Switch>
    )
  }
}
