import React from 'react'
import Nav from 'react-bootstrap-five/Nav'

type Props = {
  children: React.ReactNode
}

const TabNav: React.FC<Props> = ({ children }) => {
  return (
    <div className="border-bottom-1 grey-20-border">
      <Nav className="container nav-tabs border-none">{children}</Nav>
    </div>
  )
}

export default TabNav
