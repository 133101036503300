import React from 'react'
import { ContentBlockData } from '../hooks'
import Description from './Description'
import ExternalFormDescription from './ExternalFormDescription'
import FeaturedContentDescription from './FeaturedContentDescription'
import Header from './Header'

type Props = {
  id: string
  index: number
  data: ContentBlockData
  onEdit: (id: string) => void
}

const BlockItem = ({ id, data, onEdit }: Props) => {
  const { type } = data

  const handleEdit = () => onEdit(id)

  const renderDescription = () => {
    switch (type) {
      case 'featured_content':
        return <FeaturedContentDescription data={data} onEdit={handleEdit} />
      case 'external_form':
        return <ExternalFormDescription data={data} onEdit={handleEdit} />
      default:
        return <Description type={type} />
    }
  }

  return (
    <>
      <Header type={type} onEdit={handleEdit} />
      {renderDescription()}
    </>
  )
}

export default BlockItem
