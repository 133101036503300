// @flow
import React, { Component } from 'react'
import { Table } from 'react-bootstrap'

import type { Element } from 'react'

type Props = {
  hasHeader: boolean,
  contents: Array<string>,
  headers: Array<string>,
}

export default class DataTable extends Component<Props> {
  headerRow() {
    let headerTags = this.props.headers.map((value, index) => {
      return <th key={index}>{value}</th>
    })
    return (
      <thead>
        <tr>{headerTags}</tr>
      </thead>
    )
  }

  contentRows() {
    // remove lines that are just empty strings, but don't worry about it for the entire file
    let trimmedContents: string[] = this.props.contents
      .slice(0, 10)
      .filter(e => {
        return e === 0 || e
      })
    let startIdx = 0
    let endIdx = Math.min(4, trimmedContents.length)
    if (this.props.hasHeader) {
      startIdx++
      endIdx++
    }
    return trimmedContents
      .slice(startIdx, endIdx)
      .map<Element<'tr'>>((row, rowKey) => {
        return (
          <tr key={rowKey}>
            {row.split(',').map((cell, cellKey) => {
              return <td key={cellKey}>{cell}</td>
            })}
          </tr>
        )
      })
  }

  render() {
    return (
      <Table responsive bordered>
        {this.headerRow()}
        <tbody>{this.contentRows()}</tbody>
      </Table>
    )
  }
}
