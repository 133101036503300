import { Text } from '@thanx/uikit/text'
import CustomFinePrint from 'components/CustomFinePrint'
import Restrictions from 'components/PointsProduct/Restrictions'
import { buildTranslate } from 'locales'
import React, { useContext, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { EditContext } from '../EditContext'
import RestrictionsModal from './Modal'

const t = buildTranslate('points.create_edit.restrictions')

const RestrictionSummary: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const { template } = useContext(EditContext)

  if (!template) return null
  const venue = template.redemption_venue
  return (
    <div className="border-top mt-l pt-l">
      <Row>
        <Col xs={6}>
          <Text.BodyText4 bold>{t('title')}</Text.BodyText4>
        </Col>
        <Col xs={6}>
          <div className="pull-right">
            <Button
              bsStyle="link"
              onClick={() => setShowModal(true)}
              className="padding-none"
            >
              {t('edit')}
            </Button>
          </div>
        </Col>
      </Row>
      <Restrictions
        className="mt-m"
        showAsBullet
        start={template.exchange_start_at}
        end={template.exchange_end_at}
        daysLength={
          template.redeem_retire_after_days ??
          template.redeem_experience_expires_in_days
        }
        venue={venue}
        maximum={template.redeem_maximum}
      />
      <CustomFinePrint className="mt-xl" config={template} />
      <RestrictionsModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  )
}

export default RestrictionSummary
