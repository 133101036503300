import { alert } from 'actions/flash'
import { getHiddenMenus } from 'actions/hiddenMenus'
import useDispatch from 'hooks/useDispatch'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectHiddenMenus } from 'selectors/hiddenMenu'
import { textUtils } from 'utilities/textUtils'

export default function useHiddenMenus() {
  const categories = useSelector(selectHiddenMenus)
  const [isLoaded, setIsLoaded] = useState<boolean>(categories.length > 0)
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchHiddenMenus() {
      const response = await dispatch(getHiddenMenus())
      if (response.error) {
        const errorMsg = response.error.response?.data?.error
        dispatch(
          alert({
            key: 'danger',
            message: textUtils.initialCapital(errorMsg),
          })
        )
      }
      setIsLoaded(true)
    }

    if (!isLoaded) {
      fetchHiddenMenus()
    }
  }, [isLoaded, dispatch])

  return {
    categories,
    isLoading: !isLoaded,
    // categories are not supported for this merchant when the API returns empty.
    // so we should fallback simply displaying category ids instead.
    showDisabled: isLoaded && categories.length === 0,
  }
}
