import { Text } from '@thanx/uikit/text'
import { getIntegrations } from 'actions/integrationsConfig'
import Help from 'components/Help'
import IntegrationCard from 'components/Integrations/Card'
import PageHeader from 'components/PageHeader'
import Spinner from 'components/Spinner'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Category, Integration } from 'models/IntegrationsConfig'
import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectIntegrationsConfig } from 'selectors/integrationsConfig'
import { merchantTheme } from 'theme'
import { integrationsList } from 'utilities/integrations'
import { integrationsCenterUrls } from 'utilities/urlUtils'

const t = buildTranslate('integration_center')

const Landing: React.FC = () => {
  const dispatch = useDispatch()
  const merchant = useCurrentMerchant()
  const integrationConfig = useSelector(state =>
    selectIntegrationsConfig(state, merchant?.id)
  )
  const { colors } = merchantTheme

  useEffect(() => {
    dispatch(getIntegrations())
  }, [])

  function getIntegrationState(category: Category, integration: Integration) {
    if (!integrationConfig) return 'disabled'

    return (
      integrationConfig.integrations_config?.[category.key]?.[
        integration.key
      ] ?? 'disabled'
    )
  }

  return (
    <>
      <PageHeader title="Integrations">
        <Helmet>
          <title>{t('pageTitle')}</title>
        </Helmet>
        <div className="grey-05-bkg border-top-1 pb-xxl grey-20-border mt-l">
          <Spinner
            isLoading={false}
            size="4x"
            className="text-center margin-top-huge"
          >
            <Container className="pl-0">
              {integrationsList.map(category => (
                <div key={category.key} data-testid={category.key}>
                  <Text.Header3
                    className={`mt-xl ${!category.description ? 'mb-m' : ''}`}
                  >
                    {category.title}
                  </Text.Header3>
                  {!!category.description && (
                    <Text.BodyText3
                      color={colors.grey70}
                      tag="div"
                      className="mb-m"
                    >
                      {category.description}
                    </Text.BodyText3>
                  )}
                  {category.integrations.map(integration => (
                    <div key={integration.key} className="mb-xs">
                      <IntegrationCard
                        state={getIntegrationState(category, integration)}
                        integration={integration}
                      />
                    </div>
                  ))}
                </div>
              ))}
              <Help
                title={t('help')}
                articles={[
                  {
                    title: t('how_to_enable_integrations'),
                    url: integrationsCenterUrls.help,
                  },
                ]}
              />
            </Container>
          </Spinner>
        </div>
      </PageHeader>
    </>
  )
}

export default Landing
