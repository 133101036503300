import { addValidationRule } from 'formsy-react'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import pick from 'lodash/pick'
import some from 'lodash/some'
import moment from 'moment-timezone'

// Copied from formsy react
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

addValidationRule('isRequired', (_values, value, enabled = true) => {
  if (!enabled) return true
  return isObject(value) ? !isEmpty(value) : !!value
})

addValidationRule(
  'isRequiredTextEditor',
  (_values, value: any, enabled = true) => {
    if (!enabled) return true
    return value && value !== '<p><br></p>'
  }
)

addValidationRule(
  'mustEndInPunctuation',
  (_values, value: any, enabled = true) => {
    if (!value) return true
    let regex = /^(.(?![!?.,;:]$))+$/
    if (enabled) regex = /.+[!?.,;:]$/
    return !!value.match(regex)
  }
)

addValidationRule(
  'mustNotBeginWithCausativeVerb',
  (_values, value: any, enabled = true) => {
    if (!value || !enabled) return true
    return !value.match(/^(get|have|win)/i)
  }
)

addValidationRule(
  'mustNotEndInWhitespace',
  (_values, value: any, enabled = true) => {
    if (!value || !enabled) return true
    return !value.match(/\s$/)
  }
)

addValidationRule(
  'mustNotBeginInWhitespace',
  (_values, value: any, enabled = true) => {
    if (!value || !enabled) return true
    return !value.match(/^\s/)
  }
)

addValidationRule(
  'mustNotBeWhitespace',
  (_values, value: any, enabled = true) => {
    if (!value || !enabled) return true
    return value.replace(/\s/g, '').length > 0
  }
)

addValidationRule('greaterThan', (_values, value: any, amount = 0) => {
  if (!value) return true
  return parseFloat(value.toString()) > amount
})

addValidationRule('greaterThanOrEqual', (_values, value: any, amount = 0) => {
  if (!value) return true
  return parseFloat(value.toString()) >= amount
})

addValidationRule('lessThan', (_values, value: any, amount = 0) => {
  if (!value) return true
  return parseFloat(value.toString()) < amount
})

addValidationRule('lessThanOrEqual', (_values, value: any, amount = 0) => {
  if (!value) return true
  return parseFloat(value.toString()) <= amount
})

addValidationRule('greaterThanDate', (_values, value: any, date) => {
  if (!value || !date) return true
  return moment(value).isAfter(date)
})

addValidationRule('lessThanDate', (_values, value: any, date) => {
  if (!value || !date) return true
  return moment(value).isBefore(date)
})

addValidationRule('maxLength', (_values, value: any, length) => {
  if (!length || !value) return true
  return value.length <= length
})

addValidationRule('noEmojis', (_values, value: any, enabled = true) => {
  if (!enabled) return true
  return !/[\uD800-\uDBFF]/.test(value)
})

export const hexValidation = (_values, value) => {
  if (!value) return true
  let regex = /^#[0-9A-F]{6}$/i
  return !!value.match(regex)
}
addValidationRule('hex', hexValidation)

export const mustHaveImageContent = (_values, value) => {
  if (!value) return true
  const hasIdOrUrl = !!(value.id || value.url)
  return hasIdOrUrl
}

addValidationRule('image', mustHaveImageContent)

addValidationRule(
  'atLeastOneCheckboxIsRequired',
  (values, _value, checkboxes) => {
    return some(pick(values, checkboxes), value => value === true)
  }
)

export const mustBeDelimitedEmails = (_values, value, enabled = true) => {
  if (!enabled) return true
  if (!value) return true
  const emails = value.trim().split(/[\s,]+/)

  return emails.length > 0 && every(emails, email => emailRegex.test(email))
}
addValidationRule('mustBeDelimitedEmails', mustBeDelimitedEmails)

export const areBothRequired = (values, value, otherField) => {
  if (value) return true
  return !values[otherField]
}

addValidationRule('areBothRequired', areBothRequired)

addValidationRule('isPhone', (_values, value, enabled = true) => {
  if (!enabled || isEmpty(value)) return true
  if (typeof value !== 'string') return false
  const number = value.replace(/\D/g, '')
  return !!number.match(/^\d{10}$/)
})
