import { Button } from '@thanx/uikit/button'
import { Notification } from '@thanx/uikit/notification'
import { Switch } from '@thanx/uikit/switch'
import FlagIcon from 'components/FlagIcon'
import { buildTranslate } from 'locales'
import React from 'react'
import { useIsMobile } from 'utilities/responsive'

const t = buildTranslate('cms.components.draft_banner')

type Props = {
  isLoading?: boolean
  disabled?: boolean
}

const DraftBanner: React.FC<Props> = ({ isLoading, disabled }) => {
  const isMobile = useIsMobile()

  return (
    <Notification
      overrides={{
        Body: { style: { width: 'auto' } },
        InnerContainer: { style: { flexGrow: 1 } },
      }}
      kind="info"
      size="full"
    >
      <div className="d-flex p-xs belize-hole-50">
        <FlagIcon />
        <div className="d-flex flex-column pl-s flex-grow-1">
          <p className="body-text-4 mr-m bold">{t('title')}</p>
          <p className="body-text-4 mr-m">{t('description')}</p>
          <div className="mt-m align-self-end">
            <Button
              kind="secondary"
              onClick={() =>
                window.open('mailto:merchant.support@thanx.com', '_blank')
              }
            >
              {t('contact_us')}
            </Button>
            <Switch condition={!isMobile}>
              <Button
                kind="secondary"
                type="submit"
                isLoading={isLoading}
                className="ml-s"
                disabled={disabled}
              >
                {t('save_draft')}
              </Button>
            </Switch>
          </div>
        </div>
      </div>
    </Notification>
  )
}

export default DraftBanner
