import orm from 'models/orm'
import type { Fields, Metrics, ShortType } from 'models/PointsExperienceMetric'

import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectAllPointsExperienceMetrics = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.PointsExperienceMetric.all().toRefArray()
  }
)

export const selectPointsExperienceMetrics = createSelector(
  selectAllPointsExperienceMetrics,
  (metrics: Fields[]): Metrics => {
    const formattedMetrics = metrics.reduce((acc, metric) => {
      const { type } = metric
      const typeName = type.replace('points_experience_metric_', '')
      return { ...acc, [typeName]: metric }
    }, {})

    return formattedMetrics
  }
)

export const selectPointsExperienceMetricByType = createSelector(
  (state: any) => selectPointsExperienceMetrics(state),
  (_: any, type: ShortType) => type,
  (metrics: Metrics, type: ShortType): Fields => metrics[type] || null
)
