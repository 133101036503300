import { Switch } from '@thanx/uikit/switch'
import { getLinks } from 'actions/links'
import socialLinksImage from 'assets/images/cms/social-links.png'
import Section from 'components/Section'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectSocialLinks } from 'selectors/link'
import CmsPage from '../components/CmsPage'
import Links from '../components/Links'

const t = buildTranslate('cms.brand.social_links')

const SocialLinks: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()

  const socialLinks = useSelector(selectSocialLinks)

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await dispatch(getLinks())
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      isLoading={isLoading}
    >
      <img className={'w-100'} src={socialLinksImage} alt={t('image_alt')} />
      <Switch condition={socialLinks.length > 0}>
        <Section className="p-m pt-xl mt-l">
          <Links links={socialLinks} />
        </Section>
      </Switch>
    </CmsPage>
  )
}

export default SocialLinks
