import { Switch } from '@thanx/uikit/switch'
import coins from 'assets/images/campaign-center/reports/icons/coins.svg'
import type { Metrics } from 'models/CampaignMetric'
import numeral from 'numeral'
import React from 'react'
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'

type Props = {
  metrics?: Metrics
  hasDivider?: boolean
}

export const CustomerValue: React.FC<Props> = ({
  metrics,
  hasDivider = false,
}) => {
  const value = metrics?.customer_value?.value

  function amount() {
    if (!value) return null
    return numeral(value).format('$0,0')
  }

  function subtitle() {
    const hasData = !!value
    if (hasData) {
      const tooltip = (
        <Popover id="tooltip">
          <Translate value="thanx_campaigns.report.customer_value.tooltip" />
        </Popover>
      )
      return (
        <span>
          <Translate
            value={'thanx_campaigns.report.customer_value.subtitle.default'}
            amount={amount()}
            dangerousHTML
          />
          &nbsp;
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={tooltip}
          >
            <i className="fa fa-question-circle font-size-25 grey-70" />
          </OverlayTrigger>
        </span>
      )
    } else {
      return (
        <Translate
          value={'thanx_campaigns.report.customer_value.subtitle.no_data'}
        />
      )
    }
  }

  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <Row className="margin-top margin-bottom padding-large border-radius-5">
          <Col md={1}>
            <img
              className="img-responsive margin-top-small margin-bottom-small"
              src={coins}
              alt={I18n.t('thanx_campaigns.report.customer_value.icon')}
            />
          </Col>
          <Col md={11}>
            <div className="deprecated__small-caps-3 normal margin-bottom-small">
              <Translate value="thanx_campaigns.report.customer_value.title" />
            </div>
            <div className="font-size-32 normal">{subtitle()}</div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default withMetrics(['customer_value'])(CustomerValue)
