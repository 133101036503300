import { buildTranslate } from 'locales'
import { Image } from 'models/App'
import React from 'react'
import ImageAsset from '../ImageAsset'

const t = buildTranslate('cms.brand.launch_screens')

type Props = {
  width: string
  sizeText: number
  image?: Image | null
}

const Item: React.FC<Props> = ({ width, sizeText, image }) => {
  return (
    <div className="flex-column mr-l mb-l small-caps-3 bold grey-70">
      <ImageAsset
        height="230px"
        width={image ? 'auto' : width}
        image={image?.small}
        objectFit="contain"
      />
      <p className="mt-xs">{sizeText}</p>
      <p>{t('jpg')}</p>
    </div>
  )
}

export default Item
