import { Button, Kind } from '@thanx/uikit/button'
import { Checkbox } from '@thanx/uikit/checkbox'
import { Modal } from '@thanx/uikit/modal'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { useStyletron } from 'styletron-react'

type Props = {
  show: boolean
  onClose: () => void
  onSelect: () => void
  templateId: number
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.coupon_codes_modal'
)

const CouponCodesModal: React.FC<Props> = props => {
  const { show, onClose, onSelect, templateId } = props
  const [consent, setConsent] = useState(false)
  const [css] = useStyletron()

  function navigateToEditTemplate() {
    window.location.href = `${process.env.REACT_APP_MERCHANT_ROOT_URL}/rewards/${templateId}/edit`
  }

  return (
    <Modal
      isOpen={show}
      onClose={onClose}
      size="auto"
      closeType="small"
      zIndex={1200}
    >
      <div
        className={`fs-unmask text-center p-xl ${css({ maxWidth: '800px' })}`}
      >
        <h3 className="mb-xs mt-0">{t('not_enough')}</h3>
        <p
          className={`mb-m body-text-3 grey-70 mx-auto ${css({
            maxWidth: '542px',
          })}`}
        >
          {t('this_reward')}
        </p>
        <div
          className={`grey-30-border rounded border-1 p-m text-left mb-xl ${css(
            { maxWidth: '624px' }
          )}`}
        >
          <Checkbox className="text-left" onChange={value => setConsent(value)}>
            {t('select_reward')}
          </Checkbox>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            kind={Kind.SECONDARY}
            className="mr-s"
            onClick={() => navigateToEditTemplate()}
            href={`${process.env.REACT_APP_MERCHANT_ROOT_URL}/rewards/${templateId}/edit`}
          >
            {t('add_more')}
          </Button>
          <Button onClick={() => onSelect()} disabled={!consent}>
            {t('select_button')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CouponCodesModal
