import React from 'react'

type PropsT = {
  color?: string
  className?: string
}

const NoABTest: React.FC<PropsT> = props => {
  const { color = '#36424E', className } = props
  return (
    <svg
      width="80"
      height="53"
      viewBox="0 0 80 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '80px' }}
      className={className}
    >
      <path
        d="M1.17778 4.71111C1.17778 2.75971 2.75971 1.17778 4.71111 1.17778H75.2889C77.2403 1.17778 78.8222 2.75971 78.8222 4.71111V48.2889C78.8222 50.2403 77.2403 51.8222 75.2889 51.8222H4.71111C2.75971 51.8222 1.17778 50.2403 1.17778 48.2889V4.71111Z"
        fill="white"
        stroke={color}
        strokeWidth="2.35556"
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        d="M50.75 19H31.25C29.9844 19 29 20.0312 29 21.25V34.75C29 36.0156 29.9844 37 31.25 37H50.75C51.9688 37 53 36.0156 53 34.75V21.25C53 20.0312 51.9688 19 50.75 19ZM50.75 21.25V23.1719C49.6719 24.0625 47.9844 25.375 44.4219 28.1875C43.625 28.7969 42.0781 30.2969 41 30.25C39.875 30.2969 38.3281 28.7969 37.5312 28.1875C33.9688 25.375 32.2812 24.0625 31.25 23.1719V21.25H50.75ZM31.25 34.75V26.0781C32.2812 26.9219 33.8281 28.1406 36.125 29.9688C37.1562 30.7656 38.9844 32.5469 41 32.5C42.9688 32.5469 44.75 30.7656 45.8281 29.9688C48.125 28.1406 49.6719 26.9219 50.75 26.0781V34.75H31.25Z"
        fill={color}
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
    </svg>
  )
}

export default NoABTest
