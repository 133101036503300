import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import { FirstPurchasesMetric } from 'models/PurchasesMetric'
import React from 'react'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { reportPercentage } from 'scenes/Reports/helpers'
import { numberFormatter } from 'utilities/formatters'

type PropsT = {
  data: FirstPurchasesMetric['values'][number]
  isErrored: boolean
  isLoading: boolean
}

const Row: React.FC<PropsT> = ({ data, isErrored, isLoading }) => {
  const [css] = useStyletron()
  const borderClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: palette.grey30,
  })
  if (!data) {
    return null
  }

  return (
    <tr>
      <BodyCellContainer
        className={borderClass}
        isErrored={isErrored}
        isLoading={isLoading}
      >
        <Text.BodyText4 bold={!!data.location_id} tag="div">
          {data.location_name}
        </Text.BodyText4>
        <Text.BodyText4 tag="div">{data.location_address}</Text.BodyText4>
      </BodyCellContainer>
      <BodyCell
        className={`${borderClass} text-right`}
        isErrored={isErrored}
        isLoading={isLoading}
        values={[
          { value: numberFormatter(data.purchases_count) },
          { value: reportPercentage(data.purchases_rate) },
        ]}
      />
    </tr>
  )
}

export default Row
