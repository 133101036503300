import { getPreview } from 'actions/campaignPreviews'
import { alert } from 'actions/flash'
import { sanitize } from 'dompurify'
import useDispatch from 'hooks/useDispatch'
import { useCallback, useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { UserTypeT } from 'utilities/emailUtils'

export type UserTypeContent = {
  isLoading: boolean
  emailHtml?: string | null
}
export function useLoadPreview(
  userTypes: Array<UserTypeT>,
  campaignId: number,
  variantId: number | null
): { subscriberContent: UserTypeContent; memberContent: UserTypeContent } {
  const dispatch = useDispatch()
  const userTypesKey = userTypes.join('-')
  const [memberContent, setMemberContent] = useState<UserTypeContent>({
    isLoading: true,
    emailHtml: undefined,
  })
  const [subscriberContent, setSubscriberContent] = useState<UserTypeContent>({
    isLoading: true,
    emailHtml: undefined,
  })

  const loadPreview = useCallback(
    async (userType: UserTypeT) => {
      const response = await dispatch(
        getPreview(campaignId, 'email', userType, variantId)
      )

      if (response.error) {
        const error = response.error.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.message.get_preview_error'),
            error,
            displayDetails: false,
          })
        )

        return null
      }

      const emailHtml = response.payload.data.preview.html
      const cleanHtml = sanitize(emailHtml)

      if (userType === 'member') {
        setMemberContent({
          isLoading: false,
          emailHtml: cleanHtml,
        })
      } else {
        setSubscriberContent({
          isLoading: false,
          emailHtml: cleanHtml,
        })
      }
    },
    [campaignId, variantId, dispatch]
  )

  useEffect(() => {
    userTypes.forEach(userType => {
      loadPreview(userType)
    })
    // When userTypes is passed as a dependency, the effect triggers infinitely
    // Converting the array to a string fixes the infinite trigger while still
    // allowing for a re-run when the contents of the array changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPreview, userTypesKey])

  return { memberContent, subscriberContent }
}
