import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { getApp } from 'actions/app'
import RewardImage from 'components/RewardPreviewCard/RewardImage'
import useHiddenMenus from 'hooks/useHiddenMenus'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import {
  customImageHiddenMenuUrl,
  customImageHiddenMenuVideoUrl,
  hiddenMenuOloHelpUrl,
} from 'utilities/urlUtils'
import CustomImageDescriptors from '../../CustomImageDescriptors'
import HowTo from '../../HowTo'
import ImageRadio, { HeaderType } from '../../ImageRadio'
import CategoryImage from './CategoryImage'
import CategorySelect from './CategorySelect'

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.online_redemption_card.hidden_menu'
)

type Props = {
  setCanProceed: (value: boolean) => void
  templateId: number
}

const HiddenMenu: React.FC<Props> = ({ setCanProceed, templateId }) => {
  const [css] = useStyletron()
  const template = useSelector(state => selectRedeemTemplate(state, templateId))
  const dispatch = useDispatch()
  const [headerType, setHeaderType] = useState<HeaderType>('default')
  const [hasInitialized, setHasInitialized] = useState(false)
  const { showDisabled } = useHiddenMenus()
  const uploadImageId = template?.images.advertising_image?.file_upload_id

  useEffect(() => {
    dispatch(getApp())
  }, [dispatch])

  useEffect(() => {
    if (!!uploadImageId && headerType === 'default' && !hasInitialized) {
      setHeaderType('custom')
      setHasInitialized(true)
    } else if (template && !uploadImageId) {
      setHasInitialized(true)
    }
    if (headerType === 'default') {
      setCanProceed(true)
    } else if (!uploadImageId) {
      setCanProceed(false)
    }
  }, [hasInitialized, headerType, setCanProceed, template, uploadImageId])

  return (
    <>
      <CategorySelect name="hiddenMenuKey" />
      <Switch
        condition={showDisabled}
        fallback={
          <Text.BodyText5 className="mt-xs mb-m" color="grey70">
            {t('select_description')}
          </Text.BodyText5>
        }
      >
        <Text.BodyText5 className="mt-xs mb-m">
          {t('create_category')}
          <a
            className="ml-xxs"
            target="_blank"
            rel="noopener noreferrer"
            href={hiddenMenuOloHelpUrl}
          >
            {t('learn_more')}
          </a>
        </Text.BodyText5>
      </Switch>
      <Text.BodyText4 className="pb-xs" bold>
        {t('menu_header')}
      </Text.BodyText4>
      <Text.BodyText4 className="pb-m" color="grey70">
        {t('menu_subtitle')}
      </Text.BodyText4>
      <ImageRadio
        customContent={
          <>
            <CustomImageDescriptors
              className="ml-l mb-xs"
              mainDescriptor={t('custom_img_descriptor_1')}
              linkDescriptor={t('custom_img_descriptor_2', {
                url: customImageHiddenMenuUrl,
              })}
            />
            <CategoryImage existingId={uploadImageId} templateId={templateId} />
            <HowTo
              className={`ml-l mt-s ${css({
                width: 'calc(100% - 32px)',
              })}`}
              type="hiddenMenu"
              imageLink={customImageHiddenMenuUrl.replace(/#.*$/, '')}
              videoLink={customImageHiddenMenuVideoUrl}
            />
          </>
        }
        customText={t('show_custom')}
        defaultContent={
          <div
            className={`border py-m ml-l ${css({
              display: 'flex',
              width: '432px',
              alignItems: 'center',
              flexDirection: 'column',
              borderRadius: '2px',
            })}`}
          >
            <RewardImage />
            <Text.BodyText3 className="py-xs" bold>
              {t('congrats')}
            </Text.BodyText3>
            <Text.BodyText3>{t('unlocked_menu')}</Text.BodyText3>
          </div>
        }
        defaultText={t('show_default')}
        headerType={headerType}
        setHeaderType={setHeaderType}
        templateId={templateId}
        uploadImageId={uploadImageId}
      />
    </>
  )
}

export default HiddenMenu
