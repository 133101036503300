import v2Reporting from './v2Reporting'
import v3Reporting from './v3Reporting'

export default {
  test_badge: 'Test - ',
  v2: v2Reporting,
  v3: v3Reporting,
  errors: {
    load_campaign: 'We had trouble loading your campaign report. Try again?',
    get_metric: 'We had trouble loading some reporting data. Please try again.',
  },
  campaign_types: {
    custom: {
      objective: 'Get customers to make a purchase',
      conversion: {
        description: '%{metric} customers made a purchase.',
      },
      success_phrase: {
        present_tense: 'make a purchase',
        past_tense: 'made a purchase',
      },
    },
    custom_automated: {
      objective: 'Get customers to click the email',
      conversion: {
        description: '%{metric} customers clicked the email.',
      },
      success_phrase: {
        present_tense: 'click the email',
        past_tense: 'clicked the email',
      },
    },
    message: {
      objective: 'Get customers to make a purchase',
      conversion: {
        description: '%{metric} customers made a purchase.',
      },
      success_phrase: {
        present_tense: 'make a purchase',
        past_tense: 'made a purchase',
      },
    },
    reputation: {
      objective: 'Get happy customers to share a positive review on Google',
      conversion: {
        description: '%{metric} customers clicked to leave a review on Google.',
      },
      success_phrase: {
        present_tense: 'share a positive review on Google',
        past_tense: 'clicked to leave a review on Google',
      },
    },
    winback: {
      objective: 'Get your lost customers to return',
      conversion: {
        description: '%{metric} of lost customers came back.',
        tooltip:
          '%{metric} of customers returned and redeemed their reward within 30 days of receiving the campaign.',
      },
    },
    'timeshift-immediate': {
      objective: 'Get customers to visit',
      conversion: {
        description: '%{metric} customers visited.',
      },
      success_phrase: {
        present_tense: 'visit',
        past_tense: 'visited',
      },
    },
    'timeshift-morning': {
      objective: 'Get customers to visit during the morning',
      conversion: {
        description: '%{metric} customers visited during the morning.',
      },
      success_phrase: {
        present_tense: 'visit during the morning',
        past_tense: 'visited during the morning',
      },
    },
    'timeshift-midday': {
      objective: 'Get customers to visit during midday',
      conversion: {
        description: '%{metric} customers visited during midday.',
      },
      success_phrase: {
        present_tense: 'visit during midday',
        past_tense: 'visited during midday',
      },
    },
    'timeshift-afternoon': {
      objective: 'Get customers to visit during the afternoon',
      conversion: {
        description: '%{metric} customers visited during the afternoon.',
      },
      success_phrase: {
        present_tense: 'visit during the afternoon',
        past_tense: 'visited during the afternoon',
      },
    },
    'timeshift-evening': {
      objective: 'Get customers to visit during the evening',
      conversion: {
        description: '%{metric} customers visited during the evening.',
      },
      success_phrase: {
        present_tense: 'visit during the evening',
        past_tense: 'visited during the evening',
      },
    },
    'timeshift-night': {
      objective: 'Get customers to visit during the night',
      conversion: {
        description: '%{metric} customers visited during the night.',
      },
      success_phrase: {
        present_tense: 'visit during the night',
        past_tense: 'visited during the night',
      },
    },
    'timeshift-weekday': {
      objective: 'Get customers to visit during the week',
      conversion: {
        description: '%{metric} customers visited during the week.',
      },
      success_phrase: {
        present_tense: 'visit during the week',
        past_tense: 'visited during the week',
      },
    },
    'timeshift-weekend': {
      objective: 'Get customers to visit during the weekend',
      conversion: {
        description: '%{metric} customers visited during the weekend.',
      },
      success_phrase: {
        present_tense: 'visit during the weekend',
        past_tense: 'visited during the weekend',
      },
    },
    'location-message': {
      objective: 'Get customers to visit %{location}',
      conversion: {
        description: '%{metric} customers visited %{location}.',
      },
      success_phrase: {
        present_tense: 'visit %{location}',
        past_tense: 'visited %{location}',
      },
      control_group: {
        description: 'Visited',
      },
    },
    'location-close': {
      objective:
        'Get customers to continue visiting after %{location} closes down',
      conversion: {
        description:
          '%{metric} customers made another purchase after %{location} closed down',
      },
      success_phrase: {
        present_tense: 'continue visiting your other locations',
        past_tense: 'visited your other locations',
      },
      control_group: {
        description: 'Visited',
      },
    },
    'location-reopen': {
      objective: 'Get customers to come back to %{location} when it reopens',
      conversion: {
        description:
          '%{metric} customers visited %{location} after it reopened',
      },
      success_phrase: {
        present_tense: 'visit %{location}',
        past_tense: 'visited %{location}',
      },
      control_group: {
        description: 'Visited',
      },
    },
    'location-promote': {
      objective: 'Drive traffic to %{location}',
      conversion: {
        description: '%{metric} customers visited %{location}',
      },
      success_phrase: {
        present_tense: 'visit %{location}',
        past_tense: 'visited %{location}',
      },
    },
    'engage-vip': {
      objective: 'Make your VIPs feel special with a gift',
      conversion: {
        description: '%{metric} VIPs redeemed their reward',
      },
      success_phrase: {
        present_tense: 'redeem their reward',
        past_tense: 'redeemed their reward',
      },
    },
    upsell: {
      objective: 'Get your customers to try something new',
      conversion: {
        description: '%{metric} tried something new',
      },
      success_phrase: {
        present_tense: 'try something new',
        past_tense: 'tried something new',
      },
    },
  },
  summary: {
    title: 'Conversion metric: %{objective}.',
    revenue: {
      default: {
        title: 'Revenue',
      },
      incremental_offer: {
        title: 'Incremental Revenue',
        tooltip:
          'Incremental spending by enrolled loyalty consumers during the eligible periods of this reward’s restrictions.',
      },
      incremental_no_offer: {
        title: 'Incremental Revenue',
        tooltip:
          'Incremental spending within 14 days by enrolled loyalty customers attributed to this email based on control groups.',
      },
      est_incremental_offer: {
        title: 'Est. Incremental Revenue',
        tooltip:
          'Incremental spending by enrolled loyalty consumers during the eligible periods of this reward’s restrictions extrapolated to include all consumers who redeemed the rewards, including those who aren’t enrolled.',
      },
      est_incremental_no_offer: {
        title: 'Est. Incremental Revenue',
        tooltip:
          'Incremental spending within 14 days by enrolled loyalty customers attributed to this email based on control groups extrapolated to include all consumers who read the message, including those who aren’t enrolled.',
      },
      no_control_offer: {
        title: 'Gross Revenue',
        tooltip:
          'This is the total amount spent when rewards were redeemed. Without control groups, it’s impossible to tell how much of this spending would have occurred without the incentive.',
      },
      no_control_no_offer: {
        title: 'Gross Revenue',
        tooltip:
          'Total spending within 14 days of email send. Without control groups, it’s impossible to tell how much of this spending would have occurred without the incentive.',
      },
      winback: {
        title: 'Revenue (90d)',
        tooltip:
          'This is the total amount spent by won-back customers in the last 90 days.',
      },
    },
    conversion: {
      title: 'Consumer Behavior',
      not_enough_data:
        'There hasn’t been enough engagement to confidently show results yet.',
    },
    performance: {
      cost: 'in reward costs',
      gross_revenue: 'total amount spent within 14 days of seeing your message',
      redeemed_count: 'customers redeemed their reward',
      sent_count: 'customers received this campaign',
      viewed_count: 'customers opened this campaign',
      clicked_count: 'customers clicked on this campaign',
    },
  },
  details: {
    title: 'Campaign Details',
    send_at: {
      title: 'Schedule',
      description_future: 'This campaign will be sent on %{sendAt}.',
      description_past: 'This campaign was sent on %{sendAt}.',
      start: 'Start',
      stop: 'Stop',
    },
  },
  info: {
    stars: 'Thanx Stars',
    customer_value: {
      title: 'Win back more customers',
      subtitle:
        'The best way to get more customers back is with a killer reward. Don’t be afraid to give them something bigger than usual.',
    },
    repeat_customers: {
      title: 'Increase your repeat visits',
      subtitle:
        'Winback is great to get customers back in the door, but it takes a memorable experience, product, and service to keep them coming back.',
    },
  },
  control_group: {
    with_campaign: 'With campaign',
    without_campaign: 'Without campaign',
    vs: 'vs',
    description:
      'Customers that received this campaign are <span style="color: %{color}">%{multiple}x more likely</span> to %{success_phrase}.',
    description_negative:
      'This campaign didn’t significantly change customer behavior. The control group, who did not receive it, performed roughly as well as those who did.',
    prompt_enable:
      'Measure the incremental lift of campaigns by enabling control groups.',
    not_enough_data:
      'There hasn’t been enough activity yet to confidently show a comparison to the control group.',
  },
  customer_value: {
    icon: 'Customer Value',
    title: 'Revenue Per Customer',
    subtitle: {
      default:
        'On average, each customer you win back is <span class="spearmint-50 bold">worth %{amount}</span>.',
      no_data:
        'There hasn’t been enough engagement to confidently show results yet.',
    },
    tooltip:
      'This is the average order value of these customers multiplied by the average visits since receiving Winback.',
  },
  repeat_customers: {
    title: 'Repeat Customers',
    subtitle: {
      default:
        'Most customers return more than <strong>%{timesPhrase}</strong> after receiving Winback.',
      no_data:
        'There hasn’t been enough engagement to confidently show results yet.',
      tooltip:
        'How many times a customer has continued to come back within 90 days of receiving Winback.',
    },
    times: {
      '1x': '1 time',
      '2x': '2 times',
      '3x': '3 times',
      '4x': '4 times',
      '5x+': '5+ times',
    },
  },
  customers: {
    title: 'Customers',
    button_text: 'Connect with %{firstName}',
    average_check: 'Avg. Check',
    average_frequency: 'Avg. Frequency',
    lifetime_value: 'Lifetime Value',
  },
  items_sold: {
    title: 'Items Sold In This Campaign',
    no_data:
      'There hasn’t been enough engagement to confidently show results yet.',
  },
  link_activity: {
    title: 'Link Activity',
    activity_table: {
      header: {
        column_one: 'Type',
        column_two: 'Text',
        column_three: 'URL',
        column_four: 'Unique Clicks',
      },
    },
  },
}
