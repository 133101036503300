import { Switch } from '@thanx/uikit/switch'
import type { Fields as Campaign } from 'models/Campaign'
import type { VariantOrCampaign } from 'models/CampaignVariant'
import React from 'react'
import { getUserTypes } from 'utilities/emailUtils'
import { useLoadPreview } from './helpers'
import Preview from './Preview'
import TabContainer from './TabContainer'

type Props = {
  campaign: Campaign
  config: VariantOrCampaign
  navStyle?: 'tab' | 'radio'
}

/*
 * Renders a tab for each email user type preview
 */
function CampaignEmailPreview(props: Props) {
  const { campaign, config, navStyle = 'tab' } = props

  const userTypes = getUserTypes(campaign)
  const { memberContent, subscriberContent } = useLoadPreview(
    userTypes,
    campaign.id,
    config.campaign_config_type === 'variant' ? config.id : null
  )

  const previewWidth =
    config?.notify_email_configuration?.body?.values?.contentWidth

  return (
    <Switch
      condition={userTypes.length === 1}
      fallback={
        <TabContainer
          userTypes={userTypes}
          memberContent={memberContent}
          subscriberContent={subscriberContent}
          navStyle={navStyle}
          previewWidth={previewWidth}
        />
      }
    >
      <Preview
        userType={userTypes[0]}
        navStyle={navStyle}
        userContent={
          userTypes[0] === 'member' ? memberContent : subscriberContent
        }
        width={previewWidth}
      />
    </Switch>
  )
}

export default CampaignEmailPreview
