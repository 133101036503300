import { Switch } from '@thanx/uikit/switch'
import Error from 'components/Icons/Error'
import Spinner from 'components/Spinner'
import React from 'react'

type PropsT = {
  isErrored: boolean
  isLoading: boolean
  children?: React.ReactNode
}

const ContentContainer: React.FC<PropsT> = props => {
  const { isErrored, isLoading, children } = props

  return (
    <Switch
      condition={!isLoading}
      fallback={<Spinner isLoading={true} style={{ lineHeight: '20px' }} />}
    >
      <Switch condition={!isErrored} fallback={<Error />}>
        {children}
      </Switch>
    </Switch>
  )
}

export default ContentContainer
