import { Fields as CampaignRequirement } from 'models/CampaignRequirement'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_CAMPAIGN_REQUIREMENTS =
  'campaign_requirements/GET_CAMPAIGN_REQUIREMENTS'
export const GET_CAMPAIGN_REQUIREMENTS_SUCCESS =
  'campaign_requirements/GET_CAMPAIGN_REQUIREMENTS_SUCCESS'
export const GET_CAMPAIGN_REQUIREMENTS_FAIL =
  'campaign_requirements/GET_CAMPAIGN_REQUIREMENTS_FAIL'

export function getCampaignRequirements(
  campaignId: number
): AxiosAction<
  typeof GET_CAMPAIGN_REQUIREMENTS,
  { requirements: CampaignRequirement[] }
> {
  return {
    type: GET_CAMPAIGN_REQUIREMENTS,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${campaignId}/requirements`,
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getCampaignRequirements>
>
