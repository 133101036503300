import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { Purchase } from 'api/purchases'
import HeaderCell from 'components/HeaderCell'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import { OrderDetails } from 'models/Order'
import moment from 'moment'
import React from 'react'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { merchantTheme } from 'theme'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate('users.view.activity.order_details')

type PropsT = {
  show?: boolean
  order: OrderDetails | Purchase
  onHide: () => void
}

function formatDate(date: string) {
  return {
    day: moment(date).format('MMM D, YYYY'),
    time: moment(date).format('h:mm a'),
  }
}

const OrderDetailsModal: React.FC<PropsT> = ({
  show = true,
  order,
  onHide,
}) => {
  const { colors } = merchantTheme
  const orderDate = formatDate(order.purchased_at)

  return (
    <Modal
      isOpen={show}
      title={`${t('order')} ${order.id}`}
      proceedText={t('okay')}
      className="fs-unmask"
      hideCancel
      onClose={onHide}
      onProceed={onHide}
      zIndex={3000}
    >
      <div>
        <div className="mt-m">
          {/* Order details section */}
          {/* Purchases can be imported from receipts without a location */}
          {!!order.location && (
            <>
              <Text.BodyText4 className="mb-xs" tag="div" bold>
                {t('location')}
              </Text.BodyText4>
              {[
                order.location.name,
                order.location.street,
                `${order.location.city}, ${order.location.state} ${order.location.zip}`,
              ].map(locationItem => (
                <Text.BodyText4
                  key={locationItem}
                  tag="div"
                  color={colors.grey70}
                >
                  {locationItem}
                </Text.BodyText4>
              ))}
            </>
          )}

          <Text.BodyText4 className="mb-xs mt-m" tag="div" bold>
            {t('date_and_time')}
          </Text.BodyText4>
          {[orderDate.day, orderDate.time].map(dateItem => (
            <Text.BodyText4 key={dateItem} tag="div" color={colors.grey70}>
              {dateItem}
            </Text.BodyText4>
          ))}

          <Text.BodyText4 className="mb-xs mt-m" tag="div" bold>
            {t('order_details')}
          </Text.BodyText4>
          {[
            // `${t('subtotal')}: ${currencyFormatter(order.prices.subtotal)}`,
            // `${t('tax')}: ${currencyFormatter(order.prices.tax)}`,
            `${t('total')}: ${currencyFormatter(order.prices.total)}`,
          ].map(orderItem => (
            <Text.BodyText4 key={orderItem} tag="div" color={colors.grey70}>
              {orderItem}
            </Text.BodyText4>
          ))}

          {/* Items section */}
          <Switch condition={!!order.items.length}>
            <Text.Header4 bold tag="div" className="mt-l mb-s">
              {t('items_in_this_order')}
            </Text.Header4>
            <table className="full-width">
              <thead>
                <tr>
                  <HeaderCell>{t('item')}</HeaderCell>
                  {/* TODO: uncomment next line when price data is available */}
                  <HeaderCell>{/*t('price')*/}</HeaderCell>
                </tr>
              </thead>
              <tbody>
                {order.items.map((item, index) => (
                  <tr key={`order-item-${index}`}>
                    <BodyCellContainer isErrored={false} isLoading={false}>
                      <Text.BodyText4 color={colors.grey70}>
                        {item.label}
                      </Text.BodyText4>
                    </BodyCellContainer>
                    <BodyCellContainer isErrored={false} isLoading={false}>
                      <Text.BodyText4 color={colors.grey70}>
                        {/* TODO: uncomment next line when data is available */}
                        {/* currencyFormatter(item.price) */}
                      </Text.BodyText4>
                    </BodyCellContainer>
                  </tr>
                ))}
              </tbody>
            </table>
          </Switch>
        </div>
      </div>
    </Modal>
  )
}

export default OrderDetailsModal
