import React from 'react'

const Push: React.FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="title">push message phone</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM16 20C16.5312 20 17 20.4688 17 21C17 21.5625 16.5312 22 16 22C15.4375 22 15 21.5625 15 21C15 20.4688 15.4375 20 16 20ZM19.5 8C20.3125 8 21 8.6875 21 9.5V22.5C21 23.3438 20.3125 24 19.5 24H12.5C11.6562 24 11 23.3438 11 22.5V9.5C11 8.6875 11.6562 8 12.5 8H19.5ZM19.3125 22.5C19.4062 22.5 19.5 22.4375 19.5 22.3125V9.6875C19.5 9.59375 19.4062 9.5 19.3125 9.5H12.6875C12.5625 9.5 12.5 9.59375 12.5 9.6875V22.3125C12.5 22.4375 12.5625 22.5 12.6875 22.5H19.3125Z"
        fill="#36424E"
      />
    </svg>
  )
}

export default Push
