import { RESET_DB } from 'actions/orm'
import { GET_USER_FEEDBACKS_SUCCESS, ResolvedAction } from 'actions/users'
import { Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  user_id: number
  score: number
  comment: string
  status: 'positive' | 'negative' | 'neutral'
  created_at: string
  location_name: string
  purchase_id: number | null
}

// @ts-ignore
export default class UserFeedback extends Model<typeof UserFeedback, Fields> {
  static modelName: string = 'UserFeedback'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    UserFeedback: ModelType<UserFeedback>
  ): void {
    switch (action.type) {
      case GET_USER_FEEDBACKS_SUCCESS:
        action.payload.data.feedbacks.forEach(feedback =>
          UserFeedback.upsert(feedback)
        )
        break
      case RESET_DB:
        UserFeedback.all().delete()
        break
      default:
        break
    }
  }
}
