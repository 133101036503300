import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import type { Metrics } from 'models/MerchantMetric'
import React from 'react'
import Helmet from 'react-helmet'
import { Translate } from 'react-redux-i18n'
import ReportHeader from '../components/ReportHeader'
import Customers from './sections/Customers'
import Rewards from './sections/Rewards'
import Transactions from './sections/Transactions'

const t = buildTranslate('reports.activity')

type Props = {
  metrics: Metrics
}

const View: React.FC<Props> = ({ metrics }) => {
  const { user_stats, transaction_stats, reward_stats } = metrics
  const hasUserStats = !isEmpty(user_stats?.data)
  const hasTransactionStats = !isEmpty(transaction_stats?.data)
  const hasRewardStats = !isEmpty(reward_stats?.data)
  const hasMetrics = hasUserStats || hasTransactionStats || hasRewardStats

  return (
    <div id="activity-report">
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <ReportHeader
        title={t('header.title')}
        description={t('header.description')}
      />
      {hasUserStats && <Customers userStats={user_stats} />}
      {hasTransactionStats && (
        <Transactions transactionStats={transaction_stats} />
      )}
      {hasRewardStats && (
        <div className="last-section">
          <Rewards rewardStats={reward_stats} />
        </div>
      )}
      {!hasMetrics && (
        <div className="text-center padding-top-huge">
          <span className="grey deprecated__body-text-2">
            <strong>
              <Translate value="reports.activity.not_enough_data" />
            </strong>
          </span>
        </div>
      )}
    </div>
  )
}

export default View
