import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import checklist from 'assets/images/campaign-center/ic_finePrint_preview.svg'
import Input from 'components/Form/Input'
import usePrevious from 'hooks/usePrevious'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import { Fields as Redeem } from 'models/Redeem'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'

type Config = {
  fine_print: string
  redeem_fine_print?: string
  redeem_template_id: number | null
}

type PropsT = {
  className?: string
  config: Config
  redeem?: Redeem
}

const t = buildTranslate('thanx_campaigns.builder.steps.incentive.fine_print')

const CustomFinePrint: React.FC<PropsT> = props => {
  const { className = '', config, redeem } = props

  const finePrint = config.fine_print
  const redeemFinePrint = redeem?.redeem_fine_print ?? config.redeem_fine_print
  const [isOpen, setIsOpen] = useState(!!redeemFinePrint)

  // hide the input if the template id changes, ignoring the change on first mount
  const previousTemplateId = usePrevious(config.redeem_template_id)
  useEffect(() => {
    if (
      previousTemplateId &&
      previousTemplateId !== config.redeem_template_id
    ) {
      setIsOpen(false)
    }
  }, [previousTemplateId, config.redeem_template_id])

  const onBlur = (event: SyntheticEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    if (!isEmpty(value) && value[value.length - 1] !== '.')
      event.currentTarget.value = `${value}.`
  }

  return (
    <div className={`grey-10-bkg p-m grey-70 ${className}`}>
      <p className="pb-m border-bottom-1 grey-30-border mb-m">
        <img src={checklist} alt="checklist" className="mr-xs" />
        <Text.SmallCaps1 bold>{t('title')}</Text.SmallCaps1>
      </p>
      <Text.BodyText3
        className="mb-l"
        color={!finePrint ? 'grey40' : 'grey70'}
        tag="div"
      >
        {finePrint || t('missing')}
      </Text.BodyText3>
      <Switch
        condition={isOpen}
        fallback={
          <div className="clearfix">
            <Button
              bsStyle="link"
              className="pull-right pr-0 pb-0"
              onClick={() => setIsOpen(true)}
            >
              {t('button_input_title')}
            </Button>
          </div>
        }
      >
        <div>
          <Input
            label={t('button_input_title')}
            value={redeemFinePrint || ''}
            name="redeem_fine_print"
            componentClass="textarea"
            onBlur={onBlur}
          />
        </div>
      </Switch>
    </div>
  )
}

export default CustomFinePrint
