import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'

type Props = {
  pending: boolean
}

const t = buildTranslate('merchant_users.merchant_users_list.table')

const StatusBadge = ({ pending }: Props) => {
  const [css] = useStyletron()

  const [value, color, backgroundColor] = pending
    ? [t('invited'), merchantTheme.colors.grey70, merchantTheme.colors.grey20]
    : [
        t('active'),
        merchantTheme.colors.nephritis50,
        merchantTheme.colors.nephritis20,
      ]

  return (
    <Badge
      value={value}
      className={css({
        backgroundColor,
        color,
      })}
    />
  )
}

export default StatusBadge
