import { Button } from '@thanx/uikit/button'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  disabled?: boolean
  onClick?: () => void
}

const t = buildTranslate('redeem_manager.builder.buttons')

const NextButton: React.FC<Props> = ({ disabled, onClick }) => {
  return (
    <Button
      className={`align-self-end mt-m ${disabled ? 'grey-40' : 'white'}`}
      disabled={disabled}
      onClick={onClick}
    >
      {t('next')}
      <i className="fa fa-arrow-down ml-xs" />
    </Button>
  )
}

export default NextButton
