import CalendarClockIcon from 'components/Icons/CalendarClock'
import { buildTranslate } from 'locales'
import React from 'react'
import UnavailableButton from './UnavailableButton'

const t = buildTranslate('home.metrics')

type PropsT = {
  remainingDays?: number | null
  willBeAvailableAfterLaunch?: boolean
}

const AvailableAfterButton: React.FC<PropsT> = ({
  remainingDays,
  willBeAvailableAfterLaunch,
}) => {
  const text = (() => {
    if (willBeAvailableAfterLaunch) return t('shared.available_after_launch')

    return t('shared.available_in_x_days', { days: remainingDays })
  })()

  return (
    <UnavailableButton
      icon={<CalendarClockIcon className="mr-xs" />}
      text={text}
    />
  )
}

export default AvailableAfterButton
