import { Settings } from 'api/landingPageTheme'
import ColorPicker from 'components/Form/ColorPicker'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import CmsPage from 'scenes/Cms/components/CmsPage'

const t = buildTranslate('cms.content.app_home_page.style_settings')

const styleKeys = [
  'background_color',
  'header_text_color',
  'body_text_color',
  'link_color',
]

type Props = {
  settings: Settings
  sidebar: React.ReactNode
  footer: React.ReactNode
  isFetching: boolean
}

const StyleSettingsPage = ({
  settings,
  sidebar,
  footer,
  isFetching,
}: Props) => {
  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      sidebar={sidebar}
    >
      <div className={'p-m white-bkg mb-l'}>
        <Spinner
          isLoading={isFetching}
          size={'3x'}
          className={'d-flex justify-content-center'}
        >
          {styleKeys.map((key, index) => (
            <ColorPicker
              className={index !== 0 ? 'mt-m' : undefined}
              key={key}
              name={key}
              label={t(key)}
              validations={{
                hex: true,
                isRequired: true,
              }}
              value={settings?.[key]}
              validationErrors={{
                hex: I18n.t('validations.hex.message'),
                isRequired: I18n.t('validations.is_required'),
              }}
              startEnhancer
            />
          ))}
        </Spinner>
      </div>
      {footer}
    </CmsPage>
  )
}

export default StyleSettingsPage
