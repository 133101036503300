// @flow
import React, { Component } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { bindActionCreators } from 'redux'

import ProviderPicker from '../../components/ProviderPicker'
// $FlowFixMe
import { alert } from 'actions/flash'
import Spacer from 'components/Spacer'
import { getProviders } from 'actions/emailProviders'
import { selectActiveProviders } from 'selectors/emailProvider'
// $FlowFixMe
import store from 'store'

import type { EmailProviderT } from 'models/EmailProvider'
import type { ReduxAxiosPromise } from 'utilities/types'

type Props = {
  getProviders: () => ReduxAxiosPromise<*>,
  onSubmit: string => void,

  activeProviderTypes: Array<string>,
  supportedProviders: Array<string>,
}
type State = {
  isLoaded: boolean,
  selectedProvider: string,
}

export class ChooseProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isLoaded: false,
      selectedProvider: '',
    }
  }

  componentDidMount() {
    this.props.getProviders().then(action => {
      if (action.error) {
        store.dispatch(
          alert({
            key: 'danger',
            message: I18n.t(
              'emails.subscribers.setup.settings.load_lists_error_message'
            ),
          })
        )
      }

      this.setState({
        isLoaded: true,
      })
    })
  }

  handleProviderSelect(providerType: string) {
    this.setState({
      selectedProvider: providerType,
    })
  }

  handleSubmit() {
    if (!this.isValid()) {
      throw Error('Invalid selection')
    }
    this.props.onSubmit(this.state.selectedProvider)
  }

  isValid() {
    const providerType = this.state.selectedProvider
    if (!this.props.supportedProviders.includes(providerType)) {
      return false
    }
    return true
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col sm={12}>
            <h3 className="deprecated__h3">
              {I18n.t('emails.subscribers.export.choose_provider.title')}
            </h3>
            <p className="body-text-2">
              {I18n.t('emails.subscribers.export.choose_provider.description')}
            </p>
          </Col>
        </Row>
        <Spacer paddingTop="30px" />
        <Row>
          <Col sm={12}>
            <ProviderPicker
              providers={this.props.activeProviderTypes}
              onChange={this.handleProviderSelect.bind(this)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Button bsStyle="primary" onClick={this.handleSubmit.bind(this)}>
              Continue
            </Button>
          </Col>
        </Row>
      </div>
    )
  }
}

const filterActiveProviderTypes = function (
  providers: Array<EmailProviderT>,
  types: Array<string>
): Array<string> {
  return providers
    .map(provider => provider.type)
    .filter(type => types.includes(type))
}

const mapStateToProps = (state, props) => {
  return {
    activeProviderTypes: filterActiveProviderTypes(
      selectActiveProviders(state.orm),
      props.supportedProviders
    ),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProviders,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ChooseProvider)
