import customerLostIcon from 'assets/images/campaign-center/opportunity/icons/customer_lost.png'
import customerSpendIcon from 'assets/images/campaign-center/opportunity/icons/customer_spend.png'
import merchantIcon from 'assets/images/campaign-center/opportunity/icons/merchant.png'
import purchaseIcon from 'assets/images/campaign-center/opportunity/icons/purchase.png'
import rewardIcon from 'assets/images/campaign-center/opportunity/icons/reward.png'
import { buildTranslate } from 'locales'
import { Fields as CampaignType } from 'models/CampaignType'
import React from 'react'
import Header from '../components/Shared/Header'
import HowItWorks from '../components/Shared/HowItWorks'
import Setup from '../components/Shared/Setup'
import Solution from '../components/Shared/Solution'
import SolutionStep from '../components/Shared/SolutionStep'
import UseCase from '../components/Shared/UseCase'

const t = buildTranslate('thanx_campaigns.opportunity.bounceback.solution')

type Props = {
  handleClick: () => void
  isCampaignTypeLoading: boolean
  campaignType: CampaignType
}
const Bounceback: React.FC<Props> = props => {
  const { handleClick, isCampaignTypeLoading, campaignType } = props

  return (
    <div>
      <Header campaignType="bounceback" />
      <UseCase campaignType="bounceback" />
      <Solution campaignType="bounceback" className="spearmint-10-bkg">
        <SolutionStep
          icon={purchaseIcon}
          iconAlt={t('steps.image_one_alt')}
          text={t('steps.one')}
        />
        <SolutionStep
          icon={customerLostIcon}
          iconAlt={t('steps.image_two')}
          text={t('steps.two')}
        />
        <SolutionStep
          icon={rewardIcon}
          iconAlt={t('steps.image_three_alt')}
          text={t('steps.three')}
        />
        <SolutionStep
          icon={merchantIcon}
          iconAlt={t('steps.image_four_alt')}
          text={t('steps.four')}
        />
        <SolutionStep
          icon={customerSpendIcon}
          iconAlt={t('steps.image_five_alt')}
          text={t('steps.five')}
        />
      </Solution>
      <HowItWorks campaignType="bounceback" />
      <Setup
        type="bounceback"
        isLoading={isCampaignTypeLoading}
        handleClick={handleClick}
        campaignType={campaignType}
      />
    </div>
  )
}

export default Bounceback
