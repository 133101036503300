import { ValidationFields, ValidationTypes } from 'models/Validation'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_VALIDATION = 'validation/GET_VALIDATION'
export const GET_VALIDATION_FAIL = 'validation/GET_VALIDATION_FAIL'
export const GET_VALIDATION_SUCCESS = 'validation/GET_VALIDATION_SUCCESS'

export function getValidation(params: {
  type: ValidationTypes
}): AxiosAction<typeof GET_VALIDATION, { validations: ValidationFields }> {
  return {
    type: GET_VALIDATION,
    payload: {
      client: 'default',
      request: {
        url: 'validations',
        method: 'get',
        params,
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getValidation>
>
