import { Fields } from 'models/HiddenMenu'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET = 'hiddenMenus/GET'
export const GET_SUCCESS = 'hiddenMenus/GET_SUCCESS'
export const GET_FAIL = 'hiddenMenus/GET_FAIL'

export function getHiddenMenus(): AxiosAction<
  typeof GET,
  { hidden_categories: Fields[] }
> {
  return {
    type: GET,
    payload: {
      client: 'default',
      request: {
        url: 'hidden_menu_categories',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getHiddenMenus>
>
