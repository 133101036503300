import { getCampaignType } from 'actions/campaignTypes'
import { alert } from 'actions/flash'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { Fields as CampaignType } from 'models/CampaignType'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { RouteComponentProps } from 'react-router-dom'
import { goBack, push } from 'react-router-redux'
import { selectCampaignTypeByType__deprecated } from 'selectors/campaignType'
import Birthday from './Birthday'
import Bounceback from './Bounceback'
import ContactModal from './ContactModal'
import Referrals from './Referrals'
import Reputation from './Reputation'
import Tiers from './Tiers'
import UpgradeModal from './UpgradeModal'
import Vip from './Vip'

const handledStates = [
  'in_use',
  'upcoming',
  'restricted',
  'unavailable',
  'available',
]

type Props = RouteComponentProps<{ type: string }>

const Opportunity: React.FC<Props> = props => {
  const { type } = props.match.params
  const merchant = useCurrentMerchant()
  const campaignType = useSelector((state: any) =>
    selectCampaignTypeByType__deprecated(state.orm, type)
  ) as CampaignType
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [isCampaignTypeLoading, setIsCampaignTypeLoading] = useState(true)
  const dispatch = useDispatch()

  const merchantName = merchant?.name

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(getCampaignType(type))
      setIsCampaignTypeLoading(false)

      if (response.error) {
        const error = response.error.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t(
              'thanx_campaigns.builder.errors.load_campaign_type'
            ),
            error,
          })
        )
      } else if (
        !handledStates.includes(response.payload.data.campaign_type?.state)
      ) {
        dispatch(goBack())
      }
    }

    fetchData()
  }, [dispatch, type])

  function handleClick(): void {
    if (!campaignType) return
    const campaignState = campaignType.state

    if (campaignState === 'upcoming' || campaignState === 'unavailable') {
      window.location.href = 'mailto:merchant.support@thanx.com'
      return
    }

    if (campaignState === 'restricted') {
      setShowUpgradeModal(true)
      return
    }

    if (campaignState === 'available' || campaignState === 'in_use') {
      switch (type) {
        case 'reputation':
        case 'winback':
          // Redirects to the new builder
          dispatch(push(`/thanx_campaigns/automated/${type}`))
          break
        case 'birthday':
        case 'vip': {
          setShowContactModal(true)
          break
        }
        case 'tier':
          window.location.href = 'mailto:merchant.support@thanx.com'
          break
        default:
          break
      }
    }
  }

  const opportunity = (): null | React.ReactNode => {
    const props = { handleClick, isCampaignTypeLoading, campaignType }
    switch (type) {
      case 'reputation':
        return <Reputation {...props} />
      case 'bounceback':
        return <Bounceback {...props} />
      case 'birthday':
        return <Birthday {...props} merchantName={merchantName} />
      case 'tier':
        return <Tiers {...props} />
      case 'vip':
        return <Vip {...props} />
      case 'invite':
        return <Referrals {...props} />
      default:
        dispatch(goBack())
        return null
    }
  }

  return (
    <div className="grey-10-bkg">
      <div
        className="full-width spearmint-60-bkg"
        style={{ height: '156px' }}
      />
      <div
        className="container relative white-bkg padding-left-none padding-right-none"
        style={{ top: '-60px', marginBottom: '-150px' }}
      >
        {opportunity()}
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
        <ContactModal
          show={showContactModal}
          onClose={() => setShowContactModal(false)}
          type={type}
        />
      </div>
      <div
        className="full-width blue-chrome-60-bkg"
        style={{ height: '275px' }}
      />
    </div>
  )
}

export default Opportunity
