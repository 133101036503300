import { Text } from '@thanx/uikit/text'
import CampaignEmailPreview from 'components/CampaignEmailPreview'
import EmailSubjectAndPreview from 'components/EmailSubjectAndPreview'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import EmptySection from './EmptySection'

const t = buildTranslate(
  'thanx_campaigns.report.v3.configuration.messages.email'
)

type Props = {
  campaign: Campaign
  config: VariantOrCampaign
  className?: string
}

function EmailSection(props: Props) {
  if (!props.config.notify_email) {
    return (
      <EmptySection
        title={t('title')}
        description={t('empty')}
        className={props.className ?? ''}
      />
    )
  }

  return (
    <Section className={`p-xl ${props.className ?? ''}`}>
      <Text.Header4 className="mb-m">{t('title')}</Text.Header4>

      <EmailSubjectAndPreview
        className="mb-s"
        subject={props.config.notify_email_subject}
        previewText={props.config.notify_email_preview_text}
      />

      <CampaignEmailPreview
        campaign={props.campaign}
        config={props.config}
        navStyle="radio"
      />
    </Section>
  )
}

export default EmailSection
