import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { merchantTheme } from 'theme'

const t = buildTranslate('feedbacks.responses.modal.message')

type Props = {
  message?: string | null
}

const OrderDetails = (props: Props) => {
  const [css] = useStyletron()
  const { message } = props

  return (
    <>
      {!!message && (
        <div className="d-flex flex-column mx-xl mb-xl">
          <Text.Header4 className="mb-xs" tag="div" bold>
            {t('title')}
          </Text.Header4>
          <Section
            className={`d-flex flex-column mb-xs ${css({
              justifyContent: 'center',
            })}`}
          >
            <Text.BodyText3 className="p-m grey-05-bkg">
              {message}
            </Text.BodyText3>
          </Section>
          <Text.BodyText5 color="grey70">
            {t('description')}
            <Button
              className="px-xs py-0 border-0"
              kind="minimal"
              onClick={() =>
                window.open(`mailto:merchant.support@thanx.com`, '_blank')
              }
            >
              <Text.BodyText5 color={merchantTheme.colors.belizeHole50}>
                {t('contact_us')}
              </Text.BodyText5>
            </Button>
          </Text.BodyText5>
        </div>
      )}
    </>
  )
}

export default OrderDetails
