import { RESET_DB } from 'actions/orm'
import { GET_USER_ITEMS_SUCCESS, ResolvedAction } from 'actions/users'
import { Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  label: string
  purchase_count: number
  image?: string
}

// @ts-ignore
export default class UserItem extends Model<typeof UserItem, Fields> {
  static modelName: string = 'UserItem'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    UserItem: ModelType<UserItem>
  ): void {
    switch (action.type) {
      case GET_USER_ITEMS_SUCCESS:
        action.payload.data.purchase_items.forEach(item =>
          UserItem.upsert(item)
        )
        break
      case RESET_DB:
        UserItem.all().delete()
        break
      default:
        break
    }
  }
}
