import GenericChart from 'components/Charts/Generic'
import { DateRangeFilter } from 'components/DateFilter/utilities'
import Highcharts from 'highcharts'
import { buildTranslate } from 'locales'
import { ChannelTimeSeriesMetric } from 'models/CaptureRateMetric'
import React, { useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import { largeCurrencyFormatter } from 'utilities/formatters'
import { getRateChartSeries, getXAxisCategories } from './helpers'
import Tooltip from './Tooltip'

type PropsT = {
  metrics?: ChannelTimeSeriesMetric
  timezone: string
  dateRangeFilter: DateRangeFilter
}
const t = buildTranslate('capture_rate.revenue_by_channel.chart')

const ColumnChart: React.FC<PropsT> = ({
  metrics,
  timezone,
  dateRangeFilter,
}) => {
  const leftAxisFormatter = label => {
    return largeCurrencyFormatter(label.value)
  }

  const categories = useMemo(
    () => getXAxisCategories(dateRangeFilter, timezone),
    [dateRangeFilter, timezone]
  )
  const series: Highcharts.SeriesColumnOptions[] = useMemo(() => {
    if (!metrics || !categories) {
      return []
    }
    return getRateChartSeries(categories, metrics)
  }, [categories, metrics])

  if (!metrics) return null

  return (
    <GenericChart
      chartType="column"
      categories={categories}
      isLoading={metrics.isLoading}
      isErrored={metrics.isErrored}
      tooltipFormatter={function () {
        const shouldShow = index => this.series.chart.series[index].visible
        return renderToString(
          <Tooltip
            date={this.point.category}
            showValues={{
              instore_loyalty: shouldShow(0),
              instore_unknown: shouldShow(1),
              digital_loyalty: shouldShow(2),
              digital_unknown: shouldShow(3),
              third_party: shouldShow(4),
            }}
            instore_loyalty={this.point.custom.member_purchases_instore_amount}
            instore_unknown={
              this.point.custom.non_member_purchases_instore_amount
            }
            digital_loyalty={this.point.custom.member_purchases_digital_amount}
            digital_unknown={
              this.point.custom.non_member_purchases_digital_amount
            }
            third_party={this.point.custom.third_party_amount}
          />
        )
      }}
      plotOptions={{
        column: {
          stacking: 'normal',
          borderWidth: 0,
          maxPointWidth: 64,
        },
      }}
      leftAxisFormatter={leftAxisFormatter}
      series={series}
      xAxisTitle={t('date')}
      yAxisTitle={t('net_revenue')}
    />
  )
}

export default ColumnChart
