import {
  GET_INTEGRATIONS_SUCCESS,
  ResolvedAction,
} from 'actions/integrationsConfig'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'

export type IntegrationProvider =
  | 'additional_pos'
  | 'adentro'
  | 'aloha'
  | 'ansa'
  | 'attentive'
  | 'bikky'
  | 'braze'
  | 'delightable'
  | 'deliverect'
  | 'doordash'
  | 'fishbowl'
  | 'guestxm'
  | 'jotform'
  | 'klaviyo'
  | 'momos'
  | 'olo'
  | 'olo_gdp'
  | 'olo_omnivore'
  | 'olo_dispatch'
  | 'open_tender'
  | 'oracle_micros'
  | 'ovation'
  | 'par_brink'
  | 'pos_itouch'
  | 'revel'
  | 'tattle'
  | 'thanx'
  | 'toast'
  | 'uber'
  | 'vromo'
  | 'yumpingo'
  | 'flybuy'

export type Integration = {
  key: IntegrationProvider
  title: string
  description: string | null
  comingSoon?: boolean
  helpUrl: string | null
  emailSubject: string | null
  partners: Array<IntegrationProvider>
}

type CategoryType =
  | 'pos_instore_sku_data'
  | 'pos_instore_offer_management'
  | 'pos_instore_redemption'
  | 'digital_order_management'
  | 'delivery'
  | 'cdp'
  | 'email_marketing'
  | 'marketing_automation_wifi'
  | 'sms_marketing'
  | 'feedback'
  | 'custom_data_capture'
  | 'stored_value'
  | 'location_intelligence'

export type Category = {
  key: CategoryType
  title: string
  description: string | null
  helpUrl: string | null
  integrations: Array<Integration>
}

type CategoryRecord = Record<
  IntegrationProvider,
  'enabled' | 'disabled' | 'auto'
>

export type Fields = {
  id: string
  integrations_config: Record<CategoryType, CategoryRecord>
}

export default class IntegrationsConfig extends Model<
  // @ts-ignore
  typeof IntegrationsConfig,
  Fields
> {
  static modelName: string = 'IntegrationsConfig'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    IntegrationsConfig: ModelType<IntegrationsConfig>
  ): void {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_INTEGRATIONS_SUCCESS: {
        IntegrationsConfig.upsert({
          id: `integrations_config_${action.payload.data.merchant_id}`,
          integrations_config: action.payload.data.integrations_config,
        })
        break
      }
      case RESET_DB:
        IntegrationsConfig.all().delete()
        break
      default:
        break
    }
  }
}
