import { buildTranslate } from 'locales'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

const t = buildTranslate('loyalty.notify_modal')

type Props = {
  show: boolean
  onHide: () => void
}

const NotifyModal: React.FC<Props> = ({ show, onHide }) => {
  return (
    <Modal className="fs-unmask" show={show} onHide={onHide}>
      <Modal.Header closeButton className="border-none" />
      <Modal.Body className="padding-top-none margin-bottom-medium margin-left-large margin-right-large">
        <h5 className="text-center grey-90">{t('title')}</h5>
        <p className="body-text-2 text-center grey-60 padding-top-large">
          {t('subtext')}
        </p>
      </Modal.Body>
      <Modal.Footer className="text-center border-none">
        <Button
          bsStyle="primary"
          bsSize="large"
          onClick={onHide}
          className="center-block"
        >
          {t('button')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NotifyModal
