import storedValue from './storedValue'

export default {
  user_list: {
    page_title: 'Customers | Thanx',
    title: 'All customers',
    total_users: 'Total customers in your database',
    total_users_popover:
      'Customers that have not opted in to share their PII (Personally Identifiable Information) may not appear in the search below.',
    import_email_subscribers: 'Import email subscribers',
    search_placeholder: 'Search customers by email, ID, or phone',
    table: {
      name: 'Name',
      view_profile: 'View profile',
    },
  },
  view: {
    blocked: 'BLOCKED',
    grant_reward: 'Grant reward',
    back: 'Back',
    title: 'User Name',
    member_since: 'Member since %{date}',
    tier: 'TIER',
    counters: {
      lifetime_spend: 'Lifetime spend',
      lifetime_visits: 'Lifetime visits',
      average_check: 'Avg. check',
      points_balance: 'Current points balance',
    },
    activity: {
      page_title: 'Activity | Thanx',
      title: 'Activity',
      panels: {
        overview: 'Overview',
        orders: 'Orders',
        items_purchased: 'Items purchased',
        rewards: 'Rewards',
        feedback: 'Feedback',
        campaigns: 'Emails',
      },
      overview: {
        title: 'Overview',
        empty_state: 'There is no activity for this customer',
        table: {
          date: 'Date',
          activity: 'Activity',
          location: 'Location',
          order_details: 'Order details',
          manually_added: 'Manually added via the Thanx dashboard',
        },
      },
      orders: {
        title: 'Orders',
        id: 'Thanx ID',
        provider_order_uid: 'Order ID from ordering provider',
        handoff: 'Handoff mode',
        status: 'Status',
        total: 'Total',
        placed_at: 'Placed at',
        details: 'Details',
        detail: {
          order: 'Order Details',
          okay: 'Okay',
          status: 'Status',
          handoff: 'Handoff',
          provider_order_uid: 'Order ID from ordering provider',
          location: 'Location',
          placed_at: 'Placed At',
          amounts: 'Amounts',
          total: 'Total',
          subtotal: 'Subtotal',
          tip: 'Tip',
          tax: 'Tax',
        },
        status_badge: {
          pending: 'Pending',
          placed: 'Placed',
          billed: 'Billed',
          completed: 'Completed',
          canceled: 'Canceled',
          refunded: 'Refunded',
          archived: 'Archived',
        },
        empty_state: 'There are no orders for this customer',
      },
      order_details: {
        order: 'Order',
        location: 'Location',
        date_and_time: 'Date and time',
        order_details: 'Order details',
        subtotal: 'Sub-total',
        tax: 'Tax',
        total: 'Total',
        item: 'Item',
        price: 'Price',
        items_in_this_order: 'Items in this order',
        okay: 'Okay',
      },
      rewards: {
        title: 'Rewards',
        reward: 'Reward',
        delivered_at: 'Delivered',
        redeemed_at: 'Redeemed',
        status: 'Status',
        state: {
          available: 'DELIVERED',
          active: 'ACTIVE',
          in_progress: 'IN PROGRESS',
          retired: 'RETIRED',
          used: 'USED',
          fraudulent: 'FRAUDULENT',
        },
        empty_state: 'There are no rewards for this customer',
        issuance_description: {
          granted: 'Granted reward',
          intro: 'Intro reward',
        },
        actions: {
          retire: 'Retire',
          reissue: 'Reissue',
        },
        action_modal: {
          retire: {
            title: 'Retire %{name}',
            proceed: 'Retire this reward',
            success: 'The reward has been retired',
            failure: 'Error retiring the reward',
            description:
              'This customer will no longer have access to this reward. If you change your mind you will need to grant a new reward to this customer.',
          },
          reissue: {
            title: 'Reissue %{name}',
            proceed: 'Reissue this reward',
            success:
              'The reward is being reissued. The user will receive it in a few minutes',
            failure: 'Error reissuing the reward',
            description: 'Grant this reward again to this customer.',
          },
        },
      },
      feedback: {
        title: 'Feedback',
        empty_state: 'There is no feedback from this customer',
        date: 'Date',
        rating: 'Rating',
        comment: 'Comment',
        location: 'Location',
        order_details: 'Order details',
      },
      campaigns: {
        title: 'Emails',
        empty_state:
          'There are no emails for this customer. (This data updates every hour)',
        email: 'Email',
        delivered: 'Delivered',
        engagement: 'Engagement',
        report_link: 'Report',
        not_live: 'This data updates every hour',
        engagement_types: {
          bounced: 'bounced',
          clicked: 'clicked',
          delivered: 'delivered',
          marked_as_spam: 'unsubscribed',
          opened: 'opened',
          sent: 'sent',
          server_rejected: 'rejected',
        },
      },
      purchased_items: {
        title: 'Items purchased',
        details: 'Details',
        item: 'Item',
        number_of_times: 'Number of times purchased',
        okay: 'Okay',
        empty_state: 'There are no purchases for this customer',
        purchased_times: 'Purchased %{count} times by %{by}',
        purchases: {
          date: 'Date',
          order_number: 'Order number',
          order_details: 'Order details',
        },
      },
    },
    profile: {
      page_title: 'Profile | Thanx',
      title: 'Profile',
      panels: {
        overview: 'Overview',
        custom_tags: 'Custom attributes',
        transferred_points: 'Transferred Points',
      },
      overview: {
        title: 'Overview',
        contact: 'Contact details',
        profile: 'Profile details',
        notification: 'Notification details',
        email: 'Email',
        phone: 'Phone',
        birthday: 'Birthday',
        marketing_campaigns: 'Marketing campaigns',
        transactional_via_push:
          'Transactional notifications via push (available if the user has the app installed)',
        transaction_via_email:
          'Transactional notifications via email (available if the user has the app installed)',
      },
      custom_tags: {
        title: 'Custom attributes',
        empty_state: 'There are no custom attributes for this customer',
        key: 'Attribute',
        values: 'Value',
        month: 'Month',
        day: 'Day',
        whos_celebrating: "Who's celebrating",
      },
      pii_banner: {
        pii_not_shared:
          'This customer has not opted in to sharing their PII (Personally Identifiable Information). Their last name, email, phone number, birthday and some custom data tags are not available.',
      },
      transferred_points: {
        title: 'Transferred points',
        points_transferred:
          'This customer’s points from your previous loyalty program have been transferred to points in your loyalty program on Thanx. ',
        description:
          'Customers automatically receive their transferred points when they sign up or log in to your app or web experience for the first time. Customers must use the email or phone number included in the transfer file that your provided to the Thanx team. The number of points customers receive is also determined by what you specified in the transfer file.',
        delay_note:
          'It may take up to 10 minutes for a customer to see their issued points in the app or web experience.',
        empty_state: {
          note: 'If you are transitioning from a previous loyalty program, this customer’s previous loyalty progress will appear here as transitioned points.',
          description:
            'This customer does not have transferred points on your Thanx loyalty program either because you are not transitioning from a previous loyalty program or because they did not have loyalty progress in the transfer file provided by you to the Thanx team.',
          troubleshoot:
            'If your customer believes that they should have transferred points, check that they are using the email or phone number associated with your previous loyalty program.',
        },
        table: {
          points: 'Points',
          transferred: 'Date points were transferred',
          claimed: 'Date account was claimed',
          status: 'Status of transferred points',
        },
      },
    },
    cards: {
      page_title: 'Cards | Thanx',
      title: 'Cards',
      card_type: 'Card type',
      last4: 'Last four digits',
      date_added: 'Date added',
      loyalty_status: 'Loyalty status',
      digital_ordering_status: 'Digital ordering status',
      empty_state: 'There are no cards for this customer',
      badge: {
        linked: 'Enrolled',
        unlinked: 'Not enrolled',
        archived: 'Archived',
        expired: 'Expired',
        errored: 'Errored',
        payment_linked: 'Enrolled',
        payment_unlinked: 'Not enrolled',
        payment_archived: 'Archived',
        payment_expired: 'Expired',
        payment_errored: 'Errored',
      },
    },
    stored_value: {
      page_title: 'Stored Value | Thanx',
      title: 'Stored Value',
    },
    grant_modal: {
      title: 'Grant reward to %{name}',
      subtitle: 'Select reward',
      number_points_to_grant: 'Number of points you want to grant',
      grant_a_reward: 'Grant a reward',
      grant_reward: 'Grant reward',
      grant_points: 'Grant points',
      progress_toward_loyalty: 'Progress toward the loyalty reward',
      delivery_note:
        'Customers will receive this message as a push notification. They will not receive an email.',
      add_message: 'Add a message (optional)',
      how_much_progress: 'How much progress the customer gets',
      alert_success: 'Reward granted successfully',
      alert_success_points: 'Points granted successfully',
      alert_fail: 'Something went wrong',
      points: 'Points',
    },
    actions: {
      edit_tier: 'Edit tier status',
      block_user: 'Block this user',
      grant_purchase: 'Add a purchase',
      remove_points: 'Remove points',
      grant_tier_modal: {
        title: 'Edit tier status',
        subtitle: 'Current tier status',
        upgrade_customer: "Upgrade this customer's tier status",
        select: 'Select tier',
        cancel: 'Cancel',
        submit: 'Upgrade tier status',
        upgrade_to: 'Upgrade to %{tierName}',
        at_gold_tier:
          'This customer is already at the highest tier and cannot be upgraded further',
      },
      confirmation: {
        title: 'Upgrade tier status to %{tierName}',
        message:
          "This customer's tier status will be upgraded from %{oldTier} to %{newTier}. You can't undo this.",
        submit: 'Upgrade',
        alert_success: 'Tier updated successfully.',
        alert_fail: 'Tier was unable to be updated.',
      },
      grant_purchase_modal: {
        title: 'Add a purchase',
        subtitle:
          'If this customer’s purchase wasn’t automatically detected via credit card transaction or via receipt upload, you can add the purchase record manually so this customer gets loyalty progress. You can’t undo this.',
        submit: 'Add this purchase',
        date: 'Date of purchase',
        datePlaceholder: 'MM/DD/YYYY',
        time: 'Time of purchase',
        timePlaceholder: 'Select a time...',
        location: 'Location',
        locationPlaceholder: 'Select a location...',
        total: 'Order total',
        dollar: '$',
        days: 'days',
        alert_fail: 'Error creating purchase',
        alert_success: `Success! A purchase record for $%{total} was added.`,
        appear_delay:
          'It may take several minutes for this purchase to appear on the customer’s activity page.',
      },
      remove_points_modal: {
        title: 'Remove points',
        subtitle:
          'Remove points from this customer’s points balance. You can always grant more points later by clicking the “Grant reward” button.',
        amount: 'Number of points you want to remove',
        points: 'points',
        alert_fail: 'There was an error removing this customer’s points.',
        alert_success:
          'Success! %{amount} points have been removed from this customer’s points balance.',
      },
    },
    block_user_modal: {
      title: 'Block user',
      error: 'Error blocking user',
      success: 'User successfully blocked',
      proceed: 'Block',
      description:
        "Revoke this customer's access to your consumer experiences and marketing. They will no longer be able to log in to your app or web experience, earn reward progress, redeem rewards, or make purchases using this email address. You won't be able to undo this.",
    },
  },
  stored_value: storedValue,
}
