import { createContext } from 'react'

export enum Tabs {
  Content = 'content',
  Brand = 'brand',
  Settings = 'settings',
  Signup = 'signup',
}

type TabContextT = {
  activeTab: Tabs
}

/*
 * Using this context to pass the active tab key to the Blocks/Item
 * component to disable/enable animation of block cards.
 * See https://thanxapp.atlassian.net/browse/MRCHNT-329 for detail.
 **/
const TabContext = createContext<TabContextT>({
  activeTab: Tabs.Content,
})

export default TabContext
