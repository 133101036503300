// @flow
import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import RichTextEditor, { ButtonGroup, Dropdown } from 'react-rte'
import { withFormsy } from 'formsy-react'
// $FlowFixMe: Not sure what's going on here
import { Modifier, EditorState } from 'draft-js'
import { ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap'
// $FlowFixMe
import { textUtils } from 'utilities/textUtils'

import type { FormsyComponentProps } from 'formsy-react'

type Props = {
  ...FormsyComponentProps,
  placeholder: string,
  label: string,
  allowCustomFields: boolean,
  disabled?: boolean,
  extraClassName: string,
  helpBlock?: React$Element<HelpBlock>,
}
type State = {
  value: any,
}

export class TextEditor extends Component<Props, State> {
  editorHandle: any
  mergeVarHandle: any

  static defaultProps = {
    label: '',
    extraClassName: '',
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      value: RichTextEditor.createValueFromString(
        this.props.value || '',
        'html'
      ),
    }
  }

  placeholder() {
    if (!this.props.placeholder) return null
    let div = document.createElement('div')
    div.innerHTML = this.props.placeholder
    let text = div.textContent || div.innerText || ''
    return text.replace(/\n(?!\n)/, '')
  }

  toolbarConfig() {
    return {
      display: ['INLINE_STYLE_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' },
      ],
    }
  }

  customControl() {
    if (!this.props.allowCustomFields) return null
    let prefix = 'emails.form.custom_fields.'
    return [
      (_: void, getValue: string => string) => {
        let choices = new Map([
          ['', { label: I18n.t(`${prefix}insert`) }],
          [
            '{{first_name}}',
            { label: I18n.t(`${prefix}first_name`), style: 'BOLD' },
          ],
          [
            '{{last_name}}',
            { label: I18n.t(`${prefix}last_name`), style: 'BOLD' },
          ],
          [
            '{{intro_reward}}',
            { label: I18n.t(`${prefix}intro_reward`), style: 'BOLD' },
          ],
          [
            '{{merchant_name}}',
            { label: I18n.t(`${prefix}merchant_name`), style: 'BOLD' },
          ],
        ])
        return (
          <ButtonGroup key={1}>
            <Dropdown
              choices={choices}
              selectedKey={getValue('merge-var')}
              onChange={this.addMergeVar.bind(this)}
              ref={el => (this.mergeVarHandle = el)}
            />
          </ButtonGroup>
        )
      },
    ]
  }

  onChange(value: any) {
    this.setState({ value })
    this.props.setValue(value.toString('html'))
  }

  addMergeVar(value: string) {
    if (value !== '') {
      let editorState = this.state.value.getEditorState()
      let selection = editorState.getSelection()
      let content = editorState.getCurrentContent()
      let newContent = Modifier.insertText(content, selection, value)
      let updatedState = EditorState.push(
        editorState,
        newContent,
        'insert-fragment'
      )
      this.editorHandle._onChange(updatedState)
    }
  }

  helpBlock() {
    if (!this.props.helpBlock) return null
    return this.props.helpBlock
  }

  validationErrors() {
    if (this.props.isPristine) return null

    return (
      <span className="text-danger">
        {textUtils.initialCapital(
          textUtils.toSentence({
            array: this.props.errorMessages,
            punctuation: '',
          })
        )}
      </span>
    )
  }

  render() {
    let className =
      this.props.showError && !this.props.isPristine
        ? 'relative form-group margin-bottom-none has-error'
        : 'relative form-group margin-bottom-none'
    return (
      <FormGroup className={className} bsSize="lg">
        <ControlLabel style={{ position: 'absolute', top: '8px' }}>
          {this.props.label}
        </ControlLabel>
        <RichTextEditor
          value={this.state.value}
          onChange={this.onChange.bind(this)}
          toolbarConfig={this.toolbarConfig()}
          customControls={this.customControl()}
          ref={el => (this.editorHandle = el)}
          toolbarClassName="wysiwygToolbar"
          className={`wysiwygEditor ${this.props.extraClassName}`}
          editorClassName="wysiwygContents"
          placeholder={this.placeholder()}
          disabled={this.props.disabled}
        />
        {this.validationErrors()}
        {this.helpBlock()}
      </FormGroup>
    )
  }
}

export default withFormsy(TextEditor)
