// @flow
import React, { Component } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { Button, Alert, Row, Col } from 'react-bootstrap'
// $FlowFixMe
import store from 'store'
import { goBack } from 'react-router-redux'

type Props = {
  subscriberCount: number,
}

export default class CSVSuccess extends Component<Props> {
  onClick() {
    store.dispatch(goBack())
  }

  render() {
    return (
      <Alert bsStyle="info">
        <Row>
          <Col sm={8}>
            <p>
              <strong>
                {I18n.t(
                  'emails.recipients.setup.choices.upload.successful_upload.title'
                )}
              </strong>
            </p>
            <Translate
              value="emails.recipients.setup.choices.upload.successful_upload.content"
              subscriberCount={this.props.subscriberCount}
            />
          </Col>
          <Col sm={4} className="text-right">
            <div className="visible-xs" style={{ marginBottom: '20px' }} />
            <Button
              bsSize="large"
              bsStyle="info"
              onClick={this.onClick.bind(this)}
            >
              {I18n.t(
                'emails.recipients.setup.choices.upload.successful_upload.button_text'
              )}
            </Button>
          </Col>
        </Row>
      </Alert>
    )
  }
}
