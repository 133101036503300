import { Fields, OverTimeConversionSeriesMetric } from 'models/MerchantMetric'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_MERCHANT_METRIC_BY_TYPE = 'merchant_metrics/GET_BY_TYPE'
export const GET_MERCHANT_METRIC_BY_TYPE_SUCCESS =
  'merchant_metrics/GET_BY_TYPE_SUCCESS'
export const GET_MERCHANT_METRIC_BY_TYPE_FAIL =
  'merchant_metrics/GET_BY_TYPE_FAIL'
export type GetMerchantMetricByType = AxiosAction<
  typeof GET_MERCHANT_METRIC_BY_TYPE,
  { metric: Fields }
>

export function getMerchantMetricByType(
  type: string,
  params: {} = {}
): GetMerchantMetricByType {
  return {
    type: GET_MERCHANT_METRIC_BY_TYPE,
    payload: {
      client: 'default',
      request: {
        url: `metrics/${type}`,
        method: 'get',
        params,
      },
    },
  }
}

export const GET_MERCHANT_METRIC_TIME_SERIES =
  'merchant_metrics/GET_TIME_SERIES'
export const GET_MERCHANT_METRIC_TIME_SERIES_SUCCESS =
  'merchant_metrics/GET_TIME_SERIES_SUCCESS'
export const GET_MERCHANT_METRIC_TIME_SERIES_FAIL =
  'merchant_metrics/GET_TIME_SERIES_FAIL'
export type GetMerchantMetricTimeSeries = AxiosAction<
  typeof GET_MERCHANT_METRIC_TIME_SERIES,
  { series_data: OverTimeConversionSeriesMetric }
>

export function getMerchantMetricTimeSeries(
  params: {} = {}
): GetMerchantMetricTimeSeries {
  return {
    type: GET_MERCHANT_METRIC_TIME_SERIES,
    payload: {
      client: 'default',
      request: {
        url: `metrics/lifecycle_time_series`,
        method: 'get',
        params,
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<
    typeof getMerchantMetricByType | typeof getMerchantMetricTimeSeries
  >
>
