import { buildTranslate } from 'locales'
import React from 'react'
import { useAbility } from 'utilities/ability'
import AccordionButton from '../Bar/AccordionButton'
import MenuItem from '../Bar/MenuItem'
import ExperiencesContent from './Content'

const t = buildTranslate('nav.experiences_menu')

type PropsT = {
  isLeftNav?: boolean
  onClick?: () => void
  paths: string[]
}

const Experiences: React.FC<PropsT> = ({
  isLeftNav = false,
  onClick = () => {},
  paths,
}) => {
  const ability = useAbility()

  const Parent = isLeftNav ? AccordionButton : MenuItem

  return (
    <Parent
      paths={paths}
      disabled={ability.cannot('manage', 'CMS')}
      buttonContents={t('button')}
    >
      <ExperiencesContent isLeftNav={isLeftNav} onClick={onClick} />
    </Parent>
  )
}

export default Experiences
