import { MultiSelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { TextArea } from '@thanx/uikit/textarea'
import { useStyletron } from '@thanx/uikit/theme'
import ProductItem from 'components/ProductList/Item'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import useProducts from 'hooks/useProducts'
import { buildTranslate } from 'locales'
import { Fields as Product } from 'models/Product'
import React, { useState } from 'react'
import { InputGroup } from 'react-bootstrap'

type Props = PassDownProps<string[]> & {
  label: string
  fallbackLabel: string
  className?: string
}

const t = buildTranslate('redeem_manager.product_select')

const ProductSelect: React.FC<Props> = props => {
  const {
    name,
    value: selectedIds = [],
    setValue,
    label,
    fallbackLabel,
    className = '',
  } = props
  const { products, isLoading, shouldDisplayIds } = useProducts()
  const [isFocused, setIsFocused] = useState(false)
  const selectedProducts = products.filter(product =>
    selectedIds.includes(product.chain_uid)
  )
  const [css] = useStyletron()

  function deleteItem(uid: string) {
    setValue(selectedIds.filter(id => id !== uid))
  }

  if (shouldDisplayIds) {
    return (
      <div>
        <label id={name} className="body-text-4 bold">
          {fallbackLabel}
        </label>
        <TextArea
          className={className}
          aria-labelledby={name}
          name={name}
          value={selectedIds}
          onChange={newVal => setValue(newVal.split(','))}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      <p className="body-text-14 bold">{label}</p>
      <div className="d-flex flex-row">
        <Switch condition={isFocused}>
          <InputGroup.Addon
            className={css({
              width: '46px',
              height: '48px',
            })}
          >
            <i className="fa fa-search pt-xxs"></i>
          </InputGroup.Addon>
        </Switch>
        <MultiSelect
          placeholder={isFocused ? t('search') : t('select_item')}
          maxDropdownHeight="400px"
          isLoading={isLoading}
          options={products}
          labelKey="name"
          valueKey="chain_uid"
          value={selectedProducts}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={selections => {
            const products = selections as Product[]
            setValue(products.map(p => p.chain_uid))
          }}
        />
      </div>
      <div className="mt-s">
        {selectedProducts.map(product => (
          <ProductItem
            key={product.chain_uid}
            product={product}
            displayDeleteIcon
            onDelete={() => {
              deleteItem(product.chain_uid)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default withFormsy(ProductSelect)
