import offerIcon from 'assets/images/campaign-center/ic_star_checkmark.png'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { textUtils } from 'utilities/textUtils'

type Props = {
  campaign: Campaign
}

const IntroReward: React.FC<Props> = ({ campaign }) => {
  return (
    <div className="border-bottom-1 grey-30-border padding-medium">
      <Row>
        <Col xs={2}>
          <img src={offerIcon} alt="offer" className="reward-icon" />
        </Col>
        <Col xs={6} className="font-size-15 padding-right-none">
          <p>{textUtils.initialCapital(campaign.redeem_description)}</p>
        </Col>
        <Col xs={4} className="text-right font-size-10">
          <Translate
            value="thanx_campaigns.builder.previews.incentive.redeem_btn"
            className="spearmint border-2 spearmint-border border-radius-10 inline-block padding-small extra-bold padding-top-micro padding-bottom-micro"
          />
        </Col>
      </Row>
    </div>
  )
}

export default IntroReward
