import { Text } from '@thanx/uikit/text'
import { alert } from 'actions/flash'
import { getUserProfile } from 'actions/users'
import { usersApi } from 'api/users'
import { isError } from 'api/utils'
import Modal from 'components/ModalContainer'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as Profile } from 'models/UserProfile'
import React from 'react'

type Props = {
  isOpen: boolean
  onClose: VoidFunction
  profile: Profile
}

const t = buildTranslate('users.view.block_user_modal')

const BlockUserModal = ({ isOpen, onClose, profile }: Props) => {
  const dispatch = useDispatch()
  const [blockUser, { isLoading: isBlocking }] = usersApi.useBlockMutation()
  async function onBlockUser() {
    const result = await blockUser(profile.uid)
    if (isError(result)) {
      dispatch(
        alert({
          key: 'danger',
          message: t('error'),
          timeout: 3,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('success'),
        timeout: 3,
      })
    )
    dispatch(getUserProfile(profile.uid))
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      title={t('title')}
      canProceed
      onClose={onClose}
      proceedText={t('proceed')}
      onProceed={onBlockUser}
      isLoading={isBlocking}
    >
      <Text.BodyText4 className="mb-xs" color="grey70" tag={'p'}>
        {t('description')}
      </Text.BodyText4>
    </Modal>
  )
}

export default BlockUserModal
