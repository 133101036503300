import { buildTranslate } from 'locales'
import type { DayBucketCountData } from 'models/MerchantMetric'
import React from 'react'
import BucketMetric from 'scenes/Reports/ActivityReport/components/BucketMetric'

const t = buildTranslate(
  'reports.activity.sections.transactions.metrics.average_visits'
)

type Props = {
  data: DayBucketCountData
}

export const AvgPurchases: React.FC<Props> = ({ data }) => {
  return (
    <BucketMetric
      title={t('title')}
      summaryText={t('summary_text')}
      bucketText={t('bucket_text')}
      keySuffix="count"
      metric={data}
    />
  )
}

export default AvgPurchases
