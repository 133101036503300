import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Formsy from 'formsy-react'
import { buildTranslate } from 'locales'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React, { useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import SmsForm from 'scenes/CampaignCenter/Builder/components/Forms/Sms'
import SmsPreview from 'scenes/CampaignCenter/Builder/components/Previews/Sms'
import CampaignConfigContext from '../Builder/CampaignConfigContext'
import EditTopBar from './EditTopBar'
import { useEditCampaign } from './hooks'

const t = buildTranslate(`thanx_campaigns.builder.steps.setup.messaging`)

type Props = RouteComponentProps<{
  id: string
  type: string
  variantId: string
}>

const EditSms: React.FC<Props> = ({ match }) => {
  const [css] = useStyletron()
  const [isValid, setIsValid] = useState<boolean>(true)
  const campaignId = Number(match.params.id)
  const variantId = Number(match.params.variantId)
  const dispatch = useDispatch()

  const { campaign, variant, config, isSaving, debouncedSave, triggerSave } =
    useEditCampaign(campaignId, variantId)

  const form = useRef<Formsy | null>(null)

  function onContinue() {
    if (!isValid) {
      form?.current?.setFormPristine?.(false)
      return
    }
    if (form.current) {
      triggerSave(form.current.getModel())
    }
    dispatch(push(`/thanx_campaigns/targeted/custom/${campaignId}`))
  }

  async function onRemove() {
    await triggerSave({
      ...config,
      notify_sms: false,
    })
    dispatch(push(`/thanx_campaigns/targeted/custom/${campaignId}`))
  }

  async function onFormChange(model: Object) {
    if (!isValid) return
    debouncedSave(model)
  }

  if (!campaign || !variant || !config) return null

  return (
    <div
      className={`${css({
        marginTop: '30px',
        minHeight: `calc(100% - 30px)`,
      })}`}
    >
      <CampaignConfigContext.Provider
        value={{
          config: config as unknown as VariantOrCampaign,
          setConfig: () => {},
        }}
      >
        <EditTopBar
          messageType="sms"
          campaign={campaign}
          variant={variant}
          isSaving={isSaving}
          onContinue={onContinue}
          onRemove={onRemove}
        />
        <div
          className={`d-flex align-center ${css({
            height: '64px',
            borderBottom: '1px solid #ddd',
            paddingLeft: '24px',
          })}`}
        >
          <Text.Header4 className={css({ lineHeight: '2' })}>
            {t('sms.title')}
          </Text.Header4>
        </div>
        <Formsy
          onChange={onFormChange}
          ref={form}
          onValid={() => setIsValid(true)}
          onInvalid={() => setIsValid(false)}
        >
          <Row>
            <Col
              md={6}
              className="padding-top-huge full-height-without-navs overflow-y"
            >
              <div className="display-flex flex-justify-center">
                <SmsPreview config={config} />
              </div>
            </Col>
            <Col
              md={6}
              className={`
                grey-10-bkg padding-top-huge
                padding-left-extra-large padding-right-extra-large
                full-height-without-navs overflow-y
              `}
            >
              <SmsForm campaign={campaign} />
            </Col>
          </Row>
        </Formsy>
      </CampaignConfigContext.Provider>
    </div>
  )
}

export default EditSms
