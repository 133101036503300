import CounterComponent from 'components/Counter'
import FetchableMetricSection from 'components/FetchableMetricSection'
import { Sections } from 'components/PopoverContent'
import { CampaignStateT } from 'models/Campaign'
import { MetricTypes } from 'models/CampaignMetric'
import React from 'react'

type Props = {
  primaryValue: string
  secondaryValue?: string
  name: string
  isErrored?: boolean
  isLoading?: boolean
  sections: Sections
  className?: string
  campaignId: number
  campaignState: CampaignStateT
  metricTypes?: MetricTypes
}

const Counter: React.FC<Props> = props => {
  const {
    primaryValue,
    secondaryValue,
    name,
    sections,
    className = '',
    isErrored = false,
    isLoading = false,
    campaignId,
    campaignState,
    metricTypes = [],
  } = props

  return (
    <FetchableMetricSection
      type={'campaign_metric'}
      className={className}
      campaignId={campaignId}
      metricTypes={metricTypes}
      shouldFetch={['active', 'complete'].includes(campaignState)}
    >
      <CounterComponent
        primaryValue={primaryValue}
        secondaryValue={secondaryValue}
        name={name}
        sections={sections}
        isErrored={isErrored}
        isLoading={isLoading}
      />
    </FetchableMetricSection>
  )
}

export default Counter
