import { FormsyInput } from '@thanx/uikit/input'
import { FormsySelect, Select } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { fractionalDays } from 'utilities/timeUtils'

type PropsT = {
  days?: number
  name: string
}
const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator.golden_ticket.hours_days_selector'
)
const HoursDaysSelector: React.FC<PropsT> = props => {
  let { days, name } = props
  if (!days) days = 1
  const [css] = useStyletron()
  const [hoursDays, setHoursDays] = useState<'hours' | 'days'>(
    days && days < 1 ? 'hours' : 'days'
  )
  const unitOptions = [
    {
      label: t('hours'),
      value: 'hours',
    },
    {
      label: t('days'),
      value: 'days',
    },
  ]

  const hoursOptions = Object.entries(fractionalDays()).map(entry => ({
    label: entry[0],
    value: entry[1],
  }))

  const daysInput = (
    <FormsyInput
      // @ts-ignore
      name={name}
      value={days >= 1 ? days : 1}
      placeholder="1"
      className={`ml-xs d-inline-block ${css({ width: '100px' })}`}
      overrides={{
        Input: {
          style: () => ({
            fontSize: '16px !important',
            color: palette.grey70,
          }),
        },
      }}
      validations={{
        greaterThanOrEqual: 1,
        isInt: true,
        isRequired: true,
      }}
      validationErrors={{
        greaterThanOrEqual: I18n.t('validations.greater_than_or_equal', {
          value: 1,
        }),
        isInt: I18n.t('validations.is_int'),
        isRequired: I18n.t('validations.is_required'),
      }}
    />
  )
  return (
    <div className="d-flex align-items-center">
      <Text.BodyText4 color="grey70">{t('title')}</Text.BodyText4>
      <Switch condition={hoursDays === 'hours'} fallback={daysInput}>
        <FormsySelect
          // @ts-ignore
          value={days < 1 ? days : hoursOptions[7].value}
          className={`ml-xs d-inline-block ${css({ width: '100px' })}`}
          options={hoursOptions}
          name={name}
          searchable={false}
          zIndex={10000}
          overrides={{
            Dropdown: {
              style: {
                height: '210px',
              },
            },
          }}
        />
      </Switch>
      <Select
        options={unitOptions}
        onChange={vals => setHoursDays(vals[0].value)}
        value={hoursDays === 'hours' ? [unitOptions[0]] : [unitOptions[1]]}
        searchable={false}
        zIndex={10000}
        overrides={{
          Root: {
            style: {
              display: 'inline-block',
              width: '100px',
              marginLeft: '8px',
            },
          },
        }}
      />
    </div>
  )
}

export default HoursDaysSelector
