import { GET_APP_SUCCESS, ResolvedAction } from 'actions/app'
import { RESET_DB } from 'actions/orm'
import { ValidationFields } from 'models/Validation'
import { Model, ModelType } from 'redux-orm'

export enum IosAppCategories {
  'Apps.Catalogs' = 'Catalogs',
  'Apps.Food_Drink' = 'Food & Drink',
  Healthcare_Fitness = 'Healthcare & Fitness',
  'Apps.Newsstand' = 'Newsstand',
  'Apps.Shopping' = 'Shopping',
  SocialNetworking = 'Social Networking',
}

export interface Image {
  small: string
  default: string
  large: string
  validations: ValidationFields
}

export interface Fields {
  id: number
  name: string
  handle: string
  mobile_url: string
  merchant_id: string
  state: string
  signup_welcome: string
  primary_color: string
  secondary_color: string
  tertiary_color: string
  icon_image_urls: Image | null
  android_notification_icon_image_urls: Image | null
  launch_screen_group_one_image_urls: Image | null
  launch_screen_group_two_image_urls: Image | null
  launch_screen_group_three_image_urls: Image | null
  launch_screen_group_four_image_urls: Image | null
  launch_screen_group_five_image_urls: Image | null
  launch_screen_group_six_image_urls: Image | null
  launch_screen_group_seven_image_urls: Image | null
  launch_screen_group_eight_image_urls: Image | null
  launch_screen_group_nine_image_urls: Image | null
  primary_image_urls: Image | null
  secondary_image_urls: Image | null
  banner_image_urls: Image | null
  reward_icon_image_urls: Image | null
  ios_iphone_max_screenshots_image_urls: Image[] | null
  ios_iphone_screenshots_image_urls: Image[] | null
  android_screenshots_image_urls: Image[] | null
  android_feature_graphic_image_urls: Image | null
  listing_name: string | null
  icon_name: string | null
  short_description: string | null
  full_description: string | null
  keywords: string | null
  marketing_url: string | null
  ios_primary_category: IosAppCategories | null
  ios_secondary_category: IosAppCategories | null
  android_category: string | null
  allow_downloads: boolean
  terms_of_service_url?: string
}
// @ts-ignore
export default class App extends Model<typeof App, Fields> {
  static modelName: string = 'App'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    App: ModelType<App>
  ) {
    switch (action.type) {
      case GET_APP_SUCCESS:
        App.upsert(action.payload.data)
        break
      case RESET_DB:
        App.all().delete()
        break
      default:
        break
    }
  }
}
