import { GET_LINKS_SUCCESS, ResolvedAction } from 'actions/links'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'

export enum SocialLinkTypes {
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  Youtube = 'Youtube',
  Link = 'Link',
  Website = 'Website',
}

export enum RegularLinkTypes {
  Premium = 'Premium',
  Featured = 'Featured',
  Opentable = 'Opentable',
}

export enum LinkSection {
  Featured = 'featured',
  Social = 'social',
}

export type LinkType = SocialLinkTypes | RegularLinkTypes

interface ImageUrls {
  small: string
  default: string
  large: string
}

export interface Fields {
  id: number
  type: LinkType
  section: LinkSection
  title: string
  handle: string
  url: string
  authenticable: boolean
  cell_text_color?: string
  cell_title?: string
  cell_subtitle?: string
  cell_url?: string
  cell_background_image_urls?: ImageUrls
}

// @ts-ignore
export default class Link extends Model<typeof Link, Fields> {
  static modelName: string = 'Link'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Link: ModelType<Link>
  ) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_LINKS_SUCCESS:
        action.payload.data.links.forEach(link => {
          Link.upsert(link)
        })
        break
      case RESET_DB:
        Link.all().delete()
        break
      default:
        break
    }
  }
}
