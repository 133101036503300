import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { RevenueMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import InfoRow from 'scenes/CampaignCenter/Report/v3/Test/components/InfoRow'
import { selectCampaignVariantMetricByType } from 'selectors/campaignMetric'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3')

type PropsT = {
  campaignId: number
  variantIds: number[]
  variantTypes: (string | undefined)[]
}

const Revenue: React.FC<PropsT> = props => {
  const { campaignId, variantIds, variantTypes } = props
  const metrics =
    (useSelector(state =>
      selectCampaignVariantMetricByType(
        state,
        campaignId,
        'revenue',
        variantIds
      )
    ) as RevenueMetric[]) || {}
  const { attributionWindow } = useAttributionWindow()

  const variants = variantIds.map((_, i) => {
    const primaryValue = currencyFormatter(metrics[i]?.data?.net_revenue, false)
    const secondaryValue = t('summary.revenue.avg_check', {
      value: currencyFormatter(metrics[i]?.data?.avg_revenue),
    })
    const isControl = variantTypes[i] === 'control'
    const primarySections = isControl
      ? [
          {
            content: t('test.tooltips.revenue.number1', {
              count: attributionWindow,
            }),
          },
          {
            title: t('summary.how_its_calc'),
            content: t('test.tooltips.revenue.number2'),
          },
          {
            title: t('summary.example'),
            content: t('test.tooltips.revenue.number3'),
          },
        ]
      : [
          {
            content: t('summary.revenue.tooltips.number_of_revenue', {
              count: attributionWindow,
            }),
          },
          {
            title: t('summary.how_its_calc'),
            content: t('summary.revenue.tooltips.calc_of_number_revenue'),
          },
          {
            title: t('summary.example'),
            content: t(
              'summary.revenue.tooltips.number_revenue_example_content1'
            ),
          },
          {
            content: t(
              'summary.revenue.tooltips.number_revenue_example_content2'
            ),
          },
          {
            content: t(
              'summary.revenue.tooltips.number_revenue_example_content3'
            ),
          },
        ]
    const secondarySections = isControl
      ? [
          {
            content: t('test.tooltips.revenue.percent1'),
          },
          {
            title: t('summary.how_its_calc'),
            content: t('test.tooltips.revenue.percent2'),
          },
          {
            title: t('summary.example'),
            content: t('test.tooltips.revenue.percent3'),
          },
        ]
      : [
          {
            content: t('test.tooltips.revenue.percent1'),
          },
          {
            title: t('summary.how_its_calc'),
            content: t('summary.revenue.tooltips.calc_of_percent_revenue'),
          },
          {
            title: t('summary.example'),
            content: t('test.tooltips.revenue.percent3'),
          },
        ]
    return {
      primaryContent: (
        <Metric metric={primaryValue}>
          <PopoverContent sections={primarySections} />
        </Metric>
      ),
      secondaryContent: (
        <Metric primary={false} metric={secondaryValue}>
          <PopoverContent sections={secondarySections} />
        </Metric>
      ),
      isErrored: metrics[i]?.isErrored,
      isLoading: metrics[i]?.isLoading,
    }
  })

  return (
    <InfoRow
      title={t('summary.revenue.revenue')}
      subtitle={t('summary.revenue.revenue_subtext')}
      variants={variants}
    />
  )
}

export default Revenue
