const targeting = {
  choice: {
    title: 'Choose a campaign type',
    offer: {
      title: 'Reward',
      text: 'Create a reason for customers to visit by offering them something when they do.',
    },
    email: {
      title: 'Email',
      text: 'Keep customers engaged by telling them what’s new or upcoming.',
    },
  },
  discovery_text:
    'Discover new ideas by seeing how other merchants use campaigns.',
  idea_button: 'Campaign ideas',
}

export default targeting
