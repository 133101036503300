import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'

type PropsT = {
  image: string
  alt: string
  link: string
}

const PromoLink: React.FC<PropsT> = props => {
  const { image, link, alt } = props
  return (
    <LinkContainer to={link}>
      <img src={image} alt={alt} className="w-100 cursor-pointer" />
    </LinkContainer>
  )
}

export default PromoLink
