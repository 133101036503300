import { Text } from '@thanx/uikit/text'
import { DateRangeFilterContext } from 'components/DateFilter/DateFilterContext'
import FetchableMetricSection from 'components/FetchableMetricSection'
import { buildTranslate } from 'locales'
import { Fields } from 'models/PointsExperience'
import { ShortType } from 'models/PointsExperienceMetric'
import React, { useContext } from 'react'
import ActivityChart from './sections/ActivityChart'
import { AdvancedOptionsProvider } from './sections/ActivityChart/AdvancedOptionsContext'
import DateFilter from './sections/DateFilter'
import OutstandingPoints from './sections/OutstandingPoints'
import SummaryCounters from './sections/SummaryCounters'
import SummaryTable from './sections/SummaryTable'

type Props = {
  pointsExperience: Fields
  timeZone: string
}

const t = buildTranslate('points.activity')

const View: React.FC<Props> = props => {
  const { pointsExperience, timeZone } = props
  const { filter } = useContext(DateRangeFilterContext)

  return (
    <FetchableMetricSection
      type="points_experience"
      timeZone={timeZone}
      pointsExperienceId={pointsExperience.id}
      pointsExperienceActivatedAt={pointsExperience.activated_at}
      dateRangeFilter={filter}
      metricTypes={[
        ShortType.ACTIVITY_COUNTERS,
        ShortType.ACTIVITY_TIME_SERIES,
      ]}
      shouldFetch
    >
      <section className="mb-xxl">
        <div className="d-flex mb-xl justify-content-between">
          <DateFilter pointsExperience={pointsExperience} />
          <Text.BodyText5 color="grey70">{t('data_freshness')}</Text.BodyText5>
        </div>
        <h3 className="mt-0 mb-xs mr-2 inline-block">{t('title')}</h3>
        <div className="mb-m">
          <Text.BodyText3 color="grey70">{t('description')}</Text.BodyText3>
        </div>
        <SummaryCounters />
        <AdvancedOptionsProvider>
          <ActivityChart />
        </AdvancedOptionsProvider>
        <OutstandingPoints />
        <SummaryTable />
      </section>
    </FetchableMetricSection>
  )
}

export default View
