import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { RevenueMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { numberFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
}

const Purchased: React.FC<PropsT> = props => {
  const { campaignId } = props

  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'revenue')
    ) as RevenueMetric) || {}

  const { attributionWindow } = useAttributionWindow()

  return (
    <InfoRow
      title={t('revenue.purchased')}
      primaryContent={
        <Metric metric={numberFormatter(metric?.data?.purchasers_count)}>
          <PopoverContent
            sections={[
              {
                content: t('revenue.tooltips.number_purchased', {
                  count: attributionWindow,
                }),
              },
              {
                title: t('how_its_calc'),
                content: t('revenue.tooltips.number_purchased_calc', {
                  count: attributionWindow,
                }),
              },
              {
                title: t('example'),
                content: t(
                  'revenue.tooltips.number_purchased_example_content1',
                  { days: attributionWindow }
                ),
              },
              {
                content: t(
                  'revenue.tooltips.number_purchased_example_content2',
                  { days: attributionWindow }
                ),
              },
            ]}
          />
        </Metric>
      }
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default Purchased
