import { Button } from '@thanx/uikit/button'
import { alert } from 'actions/flash'
import { KIND, Notification } from 'baseui/notification'
import Input from 'components/Form/Input'
import Modal from 'components/Modal'
import Formsy from 'formsy-react'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'

import { Switch } from '@thanx/uikit/switch'
import {
  sendRedeemTemplateTest,
  SendResolvedAction,
} from 'actions/redeemTemplates'
import InfoBox from 'components/InfoBox'

type Props = {
  templateId: number
  isOpen: boolean
  isLoading?: boolean
  onClose: () => void
  onBeforeSend?: () => void
  onSuccess: () => void
  showHiddenMenuInfo?: boolean
}

const t = buildTranslate('redeem_manager.send_a_test.modal')

const SendTestModal: React.FC<Props> = ({
  templateId,
  isOpen,
  isLoading,
  onClose,
  onBeforeSend,
  onSuccess,
  showHiddenMenuInfo = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const merchant = useCurrentMerchant()

  const dispatch = useDispatch()

  async function sendTest({ emails }) {
    const emailArray = emails.split(/[\s,]+/)

    if (onBeforeSend) {
      await onBeforeSend()
    }

    setIsSubmitting(true)
    const result: SendResolvedAction = (await dispatch(
      sendRedeemTemplateTest(templateId, emailArray)
    )) as any
    setIsSubmitting(false)

    if (result.type === 'redeemTemplate/SEND_FAIL') {
      const { messages } = result.error.response.data.errors
      setError(messages.join(', '))
      return
    }

    const { errors } = result.payload.data
    if (errors) {
      setError(errors.messages.join(', '))
      return
    }

    setError(null)
    dispatch(
      alert({
        key: 'success',
        message: t('successful', {
          merchantName: merchant?.name,
        }),
        timeout: 5,
      })
    )
    onSuccess()
  }

  return (
    <Modal centered show={isOpen} onModalClose={onClose}>
      <div className="d-flex flex-column align-items-center p-xl text-center">
        <h3 className="my-xs extra-bold">
          {t('title', {
            merchantName: merchant?.name,
          })}
        </h3>
        <p
          className="w-50 mb-xs grey-70"
          dangerouslySetInnerHTML={{
            __html: t('enter_emails', {
              handle: merchant?.handle,
              merchantName: merchant?.name,
            }),
          }}
        />
        <p className="mb-m body-text-4 grey-70">{t('it_may_take')}</p>
        <Formsy
          className="d-flex flex-column w-100 text-left"
          onValidSubmit={sendTest}
        >
          <Input
            componentClass="textarea"
            name="emails"
            label={t('email_address')}
            helpBlock={
              <span className="font-size-12 grey-50 block margin-top-tiny">
                {t('separate_emails')}
              </span>
            }
            validations={{
              isRequired: true,
              mustBeDelimitedEmails: true,
            }}
            validationErrors={{
              isRequired: I18n.t('validations.must_be_delimited_emails'),
              mustBeDelimitedEmails: I18n.t(
                'validations.must_be_delimited_emails'
              ),
            }}
            trimValue={true}
            inputProps={{ rows: 5 }}
          />
          <Switch condition={!!error}>
            <Notification kind={KIND.negative}>{error}</Notification>
          </Switch>
          <Switch condition={showHiddenMenuInfo}>
            <InfoBox
              title={t('info_box.title')}
              content={
                <>
                  <p className="mb-s">{t('info_box.content_1')}</p>
                  <p className="mb-0">{t('info_box.content_2')}</p>
                </>
              }
              type="info"
              className="mt-s"
            />
          </Switch>
          <div className="mt-xl align-self-end">
            <Button className="mr-m" kind="secondary" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button type="submit" isLoading={isLoading || isSubmitting}>
              {t('submit')}
            </Button>
          </div>
        </Formsy>
      </div>
    </Modal>
  )
}

export default SendTestModal
