import { useStyletron } from '@thanx/uikit/theme'
import left from 'assets/images/home/chevron_left.svg'
import React, { useEffect, useRef, useState } from 'react'
import CarouselItem from './CarouselItem'

type CardT = {
  image: string
  imageAlt?: string
  onClick?: () => void
}

type CarouselT = {
  cards: CardT[]
}

const formatCards = (card, i) => {
  return {
    image: card.image,
    imageAlt: card.imageAlt,
    onClick: card.onClick,
    index: i - 1,
    hidden: false,
  }
}

const Carousel: React.FC<CarouselT> = ({ cards }) => {
  const extraCards = [...cards]
  const firstCard = extraCards.pop()
  const [css] = useStyletron()
  const [formattedCards, setFormattedCards] = useState(
    [firstCard, ...cards, ...extraCards].map(formatCards)
  )
  const [isMoving, setIsMoving] = useState(false)
  const timeoutRef = useRef<number | null>(null)
  const setNotMoving = () => {
    timeoutRef.current = window.setTimeout(() => {
      setIsMoving(false)
    }, 1000)
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current)
    }
  }, [])

  const moveRight = () => {
    if (isMoving) return
    setIsMoving(true)
    const newCards = formattedCards.map(card => {
      if (card.index < 0) {
        return {
          ...card,
          index: formattedCards.length - 2,
          hidden: true,
        }
      } else {
        return {
          ...card,
          index: card.index - 1,
          hidden: false,
        }
      }
    })
    setFormattedCards(newCards)
    setNotMoving()
  }

  const moveLeft = () => {
    if (isMoving) return
    setIsMoving(true)

    const newCards = formattedCards.map(card => {
      if (card.index >= formattedCards.length - 2) {
        return {
          ...card,
          index: -1,
          hidden: true,
        }
      } else {
        return {
          ...card,
          index: card.index + 1,
          hidden: false,
        }
      }
    })
    setFormattedCards(newCards)
    setNotMoving()
  }

  return (
    <div
      className={`w-100 d-flex align-items-center ${css({
        maxWidth: 'calc(100vw - 124px)',
        margin: '0 auto',
      })}`}
    >
      <div
        className={css({
          width: '74px',
          minHeight: '100%',
          marginLeft: '-74px',
          cursor: 'pointer',
          padding: '32px',
          zIndex: 1,
        })}
        onClick={moveLeft}
      >
        <img src={left} alt="left" />
      </div>
      <div
        className={`w-100 position-relative d-flex ${css({
          height: '250px',
          overflowX: 'hidden',
          filter: 'drop-shadow(rgba(54, 66, 78, 0.2) 0px 8px 24px)',
        })}`}
      >
        {formattedCards.map((card, i) => (
          <CarouselItem
            hidden={card.hidden}
            image={card.image}
            imageAlt={card.imageAlt}
            index={card.index}
            key={`${card.image}-${i}`}
            onClick={card.onClick}
          />
        ))}
      </div>
      <div
        className={css({
          width: '74px',
          minHeight: '100%',
          marginRight: '-74px',
          cursor: 'pointer',
          padding: '32px',
          zIndex: 1,
        })}
        onClick={moveRight}
      >
        <img
          className={css({
            transform: 'rotate(180deg)',
          })}
          src={left}
          alt="right"
        />
      </div>
    </div>
  )
}

export default Carousel
