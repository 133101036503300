import { FormsyInput } from '@thanx/uikit/input'
import { FormsySelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import { useMemo } from 'react'

import type { FormModel } from 'scenes/Cms/Settings/SmartLinks/LinkGenerationForm'

const t = buildTranslate('cms.settings.smart_links.web_fallback')
const smartLinksT = buildTranslate('cms.settings.smart_links')

export type WebFallback = 'store' | 'ordering' | 'custom'

const webFallbackOptions: Array<{ label: string; value: WebFallback }> = [
  { label: t('fallback_labels.app_store'), value: 'store' },
  {
    label: t('fallback_labels.web_ordering'),
    value: 'ordering',
  },
  { label: t('fallback_labels.custom_url'), value: 'custom' },
]

export type WebOrderingLocation =
  | 'home-page'
  | 'rewards'
  | 'account'
  | 'saved-cards'
  | 'order-history'
  | 'ordering-menu'
  | 'items-on-the-menu'

const defaultWebOrderingLocations: {
  label: string
  value: WebOrderingLocation
}[] = [
  {
    label: t('web_ordering_locations.home_page'),
    value: 'home-page',
  },
  {
    label: t('web_ordering_locations.rewards'),
    value: 'rewards',
  },
  {
    label: t('web_ordering_locations.account'),
    value: 'account',
  },
  {
    label: t('web_ordering_locations.saved_cards'),
    value: 'saved-cards',
  },
  {
    label: t('web_ordering_locations.order_history'),
    value: 'order-history',
  },
]

type Props = {
  className?: string
  formModel: FormModel
  availableFallbacks: WebFallback[]
  name: string
  label: string
  menuCategoryOptions: { label: string; value: string }[]
  productOptions: { label: string; value: string }[]
}

const WebFields = (props: Props) => {
  const {
    className,
    formModel,
    availableFallbacks,
    name,
    label,
    menuCategoryOptions,
    productOptions,
  } = props

  const fallbackOptions = webFallbackOptions.filter(o =>
    availableFallbacks.includes(o.value)
  )

  const webOrderingLocations = useMemo(() => {
    let locations = [...defaultWebOrderingLocations]

    if (menuCategoryOptions.length) {
      locations.push({
        label: t('web_ordering_locations.ordering_menu'),
        value: 'ordering-menu',
      })
    }

    if (productOptions.length) {
      locations.push({
        label: t('web_ordering_locations.items_on_the_menu'),
        value: 'items-on-the-menu',
      })
    }

    return locations
  }, [menuCategoryOptions, productOptions])

  const selectedFallback = formModel[`${name}Fallback`]

  return (
    <div className={className}>
      <FormsySelect
        label={label}
        name={`${name}Fallback`}
        options={fallbackOptions}
        validations={{
          isRequired: true,
        }}
        validationErrors={{
          isRequired: buildTranslate('cms.settings.smart_links')('required'),
        }}
      />
      <Switch condition={selectedFallback === 'custom'}>
        <FormsyInput
          className="mt-m"
          name={`${name}CustomUrl`}
          placeholder={t('custom_url_placeholder')}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
        />
      </Switch>

      <Switch condition={selectedFallback === 'ordering'}>
        <FormsySelect
          aria-label={t('labels.select_ordering_location')}
          className="mt-s"
          name={`${name}WebOrderingLocation`}
          options={webOrderingLocations}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
        />
      </Switch>

      <Switch
        condition={
          selectedFallback === 'ordering' &&
          formModel[`${name}WebOrderingLocation`] === 'ordering-menu'
        }
      >
        <FormsySelect
          aria-label={t('labels.select_category')}
          className="mt-s"
          name={`${name}WebOrderingCategory`}
          placeholder={t('category_placeholder')}
          options={menuCategoryOptions}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
        />
      </Switch>

      <Switch
        condition={
          selectedFallback === 'ordering' &&
          formModel[`${name}WebOrderingLocation`] === 'items-on-the-menu'
        }
      >
        <FormsySelect
          aria-label={t('labels.select_product')}
          className="mt-s"
          name={`${name}WebOrderingProduct`}
          placeholder={t('product_placeholder')}
          options={productOptions}
          validations={{
            isRequired: true,
          }}
          validationErrors={{
            isRequired: smartLinksT('required'),
          }}
        />
      </Switch>
    </div>
  )
}

export default WebFields
