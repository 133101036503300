import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields } from 'models/Location'

import HeaderCell from 'components/HeaderCell'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.multi_pos.location_config'
)

type PropsT = {
  locations: Fields[]
}

const Table = ({ locations }: PropsT) => {
  return (
    <table className="full-width">
      <thead>
        <tr>
          <HeaderCell className={'w-75'}>{t('location_header')}</HeaderCell>
          <HeaderCell className={'w-75'}>{t('pos')}</HeaderCell>
        </tr>
      </thead>
      <tbody>
        {locations.map(
          ({ name, street, city, zip, state, redemption_provider }) => (
            <tr>
              <BodyCellContainer>
                <Text.BodyText4 tag="div">{name}</Text.BodyText4>
                <Text.BodyText4 tag="div">{street}</Text.BodyText4>
                <Text.BodyText4 tag="div">{`${city}, ${state}, ${zip}`}</Text.BodyText4>
              </BodyCellContainer>
              <BodyCellContainer>
                <Text.BodyText4 tag="div">
                  {redemption_provider?.label ?? ''}
                </Text.BodyText4>
              </BodyCellContainer>
            </tr>
          )
        )}
      </tbody>
    </table>
  )
}

export default Table
