import StepStatus from 'components/StepStatus'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { getColorClass } from './helpers'

type BreadcrumbsT = {
  campaign: Campaign
  steps: string[] | undefined
  stepName: string | undefined
  setCurrentStep: ((step: string) => void) | undefined
}

const Breadcrumbs: React.FC<BreadcrumbsT> = ({
  campaign,
  steps = [],
  stepName = '',
  setCurrentStep,
}) => {
  const t = buildTranslate(`thanx_campaigns.builder.nav.v3.steps`)
  const hasVariants = campaign.variants?.length > 0
  const hasName = !isEmpty(campaign.name)
  return (
    <div className="flex-1 d-flex w-100">
      {steps.map(step => {
        let isDone: boolean
        let isDisabled: boolean
        switch (step) {
          case 'name': {
            isDone = hasName
            isDisabled = false
            break
          }
          case 'variantSelect': {
            isDone = hasVariants
            isDisabled = !hasName
            break
          }
          case 'review': {
            isDone = false
            isDisabled = !hasVariants || !hasName
            break
          }
          default:
            isDone = false
            isDisabled = false
        }
        const onClick = setCurrentStep ? () => setCurrentStep(step) : () => null
        return (
          <StepStatus
            colorClass={getColorClass(campaign.type)}
            isCurrent={stepName === step}
            disabled={isDisabled}
            key={step}
            onClick={onClick}
            isDone={isDone}
            name={t(step)}
          />
        )
      })}
    </div>
  )
}

export default Breadcrumbs
