import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import Section from 'components/Section'
import Spinner from 'components/Spinner'
import Formsy from 'formsy-react'
import useProducts from 'hooks/useProducts'
import { buildTranslate } from 'locales'
import React from 'react'
import { useUniqueMenuCategories } from 'scenes/Cms/Content/AppHomePage/hooks'
import AppFields, { AppLink } from './AppFields'
import WebFields, { WebFallback, WebOrderingLocation } from './WebFields'

const t = buildTranslate('cms.settings.smart_links')

export type FormModel = {
  appLink?: AppLink
  appLinkCategory?: string
  appLinkProduct?: string

  desktopFallback?: WebFallback
  desktopCustomUrl?: string
  desktopWebOrderingLocation?: WebOrderingLocation
  desktopWebOrderingCategory?: string
  desktopWebOrderingProduct?: string

  mobileFallback?: WebFallback
  mobileCustomUrl?: string
  mobileWebOrderingLocation?: WebOrderingLocation
  mobileWebOrderingCategory?: string
  mobileWebOrderingProduct?: string
}

type Props = {
  onSubmit?: (model: FormModel) => Promise<void>
}

const LinkGenerationForm = (props: Props) => {
  const [formModel, setFormModel] = React.useState<FormModel>({})
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const { products, isLoading: productsLoading } = useProducts()
  const productOptions = (products || []).map(product => ({
    label: product.name,
    value: product.name.toLowerCase().replace(/\s+/g, '+'),
  }))
  const { menuCategories, isLoading: menuCategoriesLoading } =
    useUniqueMenuCategories()
  const menuCategoryOptions = (menuCategories || []).map(category => ({
    label: category.name,
    value: category.slug,
  }))
  const isLoading = productsLoading || menuCategoriesLoading

  if (isLoading) {
    return (
      <Spinner
        className="text-center margin-top-huge"
        size="4x"
        isLoading={true}
      />
    )
  }

  async function handleSubmit(model: FormModel) {
    if (!props.onSubmit) return

    setIsSubmitting(true)
    await props.onSubmit(model)
    setIsSubmitting(false)
  }

  return (
    <Formsy
      onChange={model => setFormModel(model)}
      onValidSubmit={model => handleSubmit(model)}
    >
      <div className="mb-m">
        <Section className="p-m mt-l">
          <AppFields
            productOptions={productOptions}
            menuCategoryOptions={menuCategoryOptions}
            formModel={formModel}
          />
        </Section>
        <Section className="p-m mt-l">
          <Text.Header4 className="mb-m">{t('no_app.title')}</Text.Header4>
          <WebFields
            className="mb-m"
            name="mobile"
            label={t('no_app.mobile_label')}
            availableFallbacks={['ordering', 'custom', 'store']}
            productOptions={productOptions}
            menuCategoryOptions={menuCategoryOptions}
            formModel={formModel}
          />
          <WebFields
            name="desktop"
            label={t('no_app.desktop_label')}
            availableFallbacks={['ordering', 'custom']}
            productOptions={productOptions}
            menuCategoryOptions={menuCategoryOptions}
            formModel={formModel}
          />
        </Section>
      </div>
      <Button type={'submit'} isLoading={isSubmitting}>
        {t('generate_link')}
      </Button>
    </Formsy>
  )
}

export default LinkGenerationForm
