import { useStyletron } from '@thanx/uikit/theme'
import ContentContainer from 'components/ContentContainer'
import palette from 'constants/palette'
import React from 'react'

type Props = {
  className?: string
  isErrored?: boolean
  isLoading?: boolean
  children: React.ReactNode
}

const BodyCellContainer: React.FC<Props> = props => {
  const {
    isErrored = false,
    isLoading = false,
    children,
    className = '',
  } = props
  const [css] = useStyletron()

  const cellClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: palette.grey30,
    paddingLeft: 0,
    paddingRight: 0,
  })

  return (
    <td className={`p-m align-top ${className} ${cellClass}`}>
      <ContentContainer isErrored={isErrored} isLoading={isLoading}>
        {children}
      </ContentContainer>
    </td>
  )
}

export default BodyCellContainer
