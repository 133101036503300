import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import { Fields as UserFeedback } from 'models/UserFeedback'
import React from 'react'
import { merchantTheme } from 'theme'

type PropsT = {
  status: UserFeedback['status']
  score: number
}

const StatusBadge: React.FC<PropsT> = ({ status, score }: PropsT) => {
  const [css] = useStyletron()
  let color, backgroundColor
  switch (status) {
    case 'negative':
      color = merchantTheme.colors.cayenne50
      backgroundColor = merchantTheme.colors.cayenne10
      break
    case 'neutral':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.grey20
      break
    case 'positive':
      color = merchantTheme.colors.nephritis50
      backgroundColor = merchantTheme.colors.nephritis20
      break
    default:
      break
  }

  return (
    <Badge
      value={score}
      className={`ml-xs ${css({
        backgroundColor,
        color,
      })}`}
    />
  )
}

export default StatusBadge
