import React from 'react'

type Props = {
  className?: string
  children: React.ReactNode
}

function Card(props: Props) {
  const { className = '', children } = props

  return (
    <div className={`card w-100 ${className}`}>
      <div className="card-body">{children}</div>
    </div>
  )
}

export default Card
