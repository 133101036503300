import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { tooltipPercentage } from 'scenes/Reports/helpers'
import { generateUikitTheme } from 'theme'
import { numberFormatter } from 'utilities/formatters'
import { RETENTION_WINDOW_DAYS } from '../helpers'

const t = buildTranslate('retention_rate')

type PropsT = {
  date: string
  retentionRate: number
  engagedUsers: number
  returningUsers: number
  churnedUsers: number
}

const Tooltip: React.FC<PropsT> = ({
  date,
  retentionRate,
  engagedUsers,
  returningUsers,
  churnedUsers,
}) => {
  const styletronEngine = new StyletronEngine({ prefix: 'st-ctt-' })
  const uikitTheme = generateUikitTheme()

  return (
    <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {date}
      </Text.BodyText4>
      <Text.Header3 className="mb-xs mt-0" tag="div">
        {tooltipPercentage(retentionRate, 1, 1)}
      </Text.Header3>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {t('chart.tooltip.retention_rate')}
      </Text.BodyText4>
      <Text.Header4 className="mt-0" tag="div">
        {numberFormatter(engagedUsers)}
      </Text.Header4>
      <Text.BodyText4 className="mb-s" color="grey70" tag="div">
        {t('chart.tooltip.engaged_users')}
      </Text.BodyText4>
      <Text.Header4 className="mt-0" tag="div">
        {numberFormatter(returningUsers)}
      </Text.Header4>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {t('chart.tooltip.returning_users', {
          retentionWindow: RETENTION_WINDOW_DAYS,
        })}
      </Text.BodyText4>
      <Text.Header4 className="mt-0" tag="div">
        {numberFormatter(churnedUsers)}
      </Text.Header4>
      <Text.BodyText4 color="grey70" tag="div">
        {t('chart.tooltip.churned_users', {
          retentionWindow: RETENTION_WINDOW_DAYS,
        })}
      </Text.BodyText4>
    </StyleProvider>
  )
}

export default Tooltip
