import inactiveTiersIcon from 'assets/images/campaigns/tiers_inactive.png'
import bronzeIcon from 'assets/images/tiers/ic_bronze.png'
import goldIcon from 'assets/images/tiers/ic_gold.png'
import silverIcon from 'assets/images/tiers/ic_silver.png'
import { buildTranslate } from 'locales'
import { Fields as Tier } from 'models/Tier'
import React from 'react'
import { Button, Row } from 'react-bootstrap'
import InactiveContent from './InactiveContent'
import TierInfo from './TierInfo'

const t = buildTranslate('loyalty.tiers')

type Props = {
  tiers: Tier[]
  inactiveButtonAction: () => void
}

const Tiers: React.FC<Props> = ({ tiers, inactiveButtonAction }) => {
  if (!tiers || tiers.length === 0) {
    return (
      <InactiveContent
        title={t('title')}
        subtext={t('subtext')}
        actionContent={
          <Button
            bsStyle="primary"
            bsSize="large"
            onClick={inactiveButtonAction}
          >
            {t('button_text')}
          </Button>
        }
        icon={inactiveTiersIcon}
      />
    )
  }

  const bronzeTier = tiers.filter(tier => {
    return tier.type === 'tier_bronze'
  })[0]
  const silverTier = tiers.filter(tier => {
    return tier.type === 'tier_silver'
  })[0]
  const goldTier = tiers.filter(tier => {
    return tier.type === 'tier_gold'
  })[0]

  return (
    <div>
      <div className="font-size-24 margin-bottom-medium bold">{t('title')}</div>
      <div
        className="font-size-16 margin-bottom-huge grey-70"
        style={{ width: '360px' }}
      >
        {t('subtext')}
      </div>
      <Row className="display-flex flex-row">
        <TierInfo tier={bronzeTier} icon={bronzeIcon} />
        <TierInfo tier={silverTier} icon={silverIcon} />
        <TierInfo tier={goldTier} icon={goldIcon} />
      </Row>
    </div>
  )
}

export default Tiers
