import { Switch } from '@thanx/uikit/switch'
import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'

type Props = {
  title: string | React.ReactNode
  message: string | React.ReactNode
  tooltip?: string | React.ReactNode
  className?: string
}
const StatBox: React.FC<Props> = props => {
  const { title, message, tooltip, className = '' } = props

  return (
    <div
      className={`padding-extra-large padding-top-large border-radius-5 relative ${className}`}
    >
      <div className="display-flex flex-space-between">
        <p className="small-caps font-size-16 margin-bottom-extra-large">
          {title}
        </p>
        <Switch condition={!!tooltip}>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={<Popover id="tooltip">{tooltip}</Popover>}
          >
            <i className="fa fa-info-circle font-size-22" />
          </OverlayTrigger>
        </Switch>
      </div>
      <p className="margin-bottom-large font-size-32 line-height-40">
        {message}
      </p>
    </div>
  )
}

export default StatBox
