import InnerTabs from 'components/Tabs/InnerTabs'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import { selectBrands } from 'selectors/brand'
import AppStoreAssets from './AppStoreAssets'
import BrandAssets from './BrandAssets'
import ConceptAssets from './ConceptAssets'
import CustomFonts from './CustomFonts'
import LaunchScreens from './LaunchScreens'
import SocialLinks from './SocialLinks'
import Tiers from './Tiers'

export enum Tabs {
  BrandAssets = 'brand_assets',
  ConceptAssets = 'concept_assets',
  AppStoreAssets = 'app_store_assets',
  LaunchScreens = 'launch_screens',
  Tiers = 'tiers',
  SocialLinks = 'social_links',
  CustomFonts = 'custom_fonts',
}

type Props = {
  isAppLoading: boolean
}

const t = buildTranslate('cms')

const Brand: React.FC<Props> = ({ isAppLoading }) => {
  const { branded_app } = useCurrentMerchant() || {}
  const app = useSelector(selectApp)

  const brands = useSelector(selectBrands)

  const brandedAppAvailable = app?.allow_downloads && !!branded_app

  const tabsComponents = [
    {
      key: Tabs.BrandAssets,
      component: <BrandAssets isLoading={isAppLoading} />,
      title: t('brand.brand_assets.title'),
    },
    ...(brands.length > 0
      ? [
          {
            key: Tabs.ConceptAssets,
            component: <ConceptAssets />,
            title: t('brand.concept_assets.title'),
          },
        ]
      : []),
    ...(brandedAppAvailable
      ? [
          {
            key: Tabs.AppStoreAssets,
            component: <AppStoreAssets isLoading={isAppLoading} />,
            title: t('brand.app_store_assets.title'),
          },
          {
            key: Tabs.LaunchScreens,
            component: <LaunchScreens isLoading={isAppLoading} />,
            title: t('brand.launch_screens.title'),
          },
        ]
      : []),
    {
      key: Tabs.Tiers,
      component: <Tiers />,
      title: t('brand.tiers.title'),
    },
    ...(brandedAppAvailable
      ? [
          {
            key: Tabs.SocialLinks,
            component: <SocialLinks />,
            title: t('brand.social_links.title'),
          },
          {
            key: Tabs.CustomFonts,
            component: <CustomFonts />,
            title: t('brand.custom_fonts.title'),
          },
        ]
      : []),
  ]

  return <InnerTabs tabs={tabsComponents} />
}

export default Brand
