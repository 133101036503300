import { Text } from '@thanx/uikit/text'
import SortToggle from 'components/SortToggle'
import React from 'react'
import {
  SortFilter,
  SortFilterAttribute,
} from 'scenes/Points/Landing/Activity/utilities'

type Props = {
  onSort: (filter: SortFilter) => void
  sortFilter: SortFilter
  attribute: SortFilterAttribute
  label: string
  style?: React.CSSProperties
  className: string
}

const HeaderCell: React.FC<Props> = props => {
  const { onSort, attribute, label, style, className, sortFilter } = props

  const handleHeaderClick = () => {
    onSort({
      attribute,
      direction:
        sortFilter.attribute !== attribute || sortFilter.direction === 'asc'
          ? 'desc'
          : 'asc',
    })
  }

  return (
    <th className="px-m pb-l align-top" style={style}>
      <div className={'d-flex'}>
        <div
          onClick={handleHeaderClick}
          className={`d-flex width-100 cursor-pointer ${className}`}
        >
          <Text.BodyText4 color="grey90" bold>
            {label}
          </Text.BodyText4>
        </div>
        <div className={'d-flex'}>
          <SortToggle
            onSort={direction =>
              onSort({
                attribute,
                direction,
              })
            }
          />
        </div>
      </div>
    </th>
  )
}

export default HeaderCell
