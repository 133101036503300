import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import NoRewardIcon from 'assets/images/campaign-center/builder/img-no_reward.svg'
import React from 'react'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'

type Props = {
  infoText?: string
}

const IncentiveDisabled: React.FC<Props> = ({ infoText }) => {
  return (
    <Row className="section d-flex align-items-center is-empty">
      <Col className="col-header">
        <img src={NoRewardIcon} alt="" />
      </Col>
      <Col className="col-content">
        <Text.BodyText3 tag="div" className="mb-xs mt-xxs">
          <Translate value="thanx_campaigns.review.incentive.missing" />
          <Switch condition={!!infoText}>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="incentive-disabled-tooltip">{infoText}</Tooltip>
              }
            >
              <i className="fa fa-question-circle padding-left-small" />
            </OverlayTrigger>
          </Switch>
        </Text.BodyText3>
      </Col>
    </Row>
  )
}

export default IncentiveDisabled
