// The script is from the Delighted Web Platform setup - https://app.delighted.com/integrations/web
// Project: "Thanx- Connected to SFDC"
// Guide on how to get the snippet is at
// https://help.delighted.com/article/540-the-web-platform (see "Setting up the Web platform" section)

import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useCurrentMerchantUser from 'hooks/useCurrentMerchantUser'
import useIsThanxAdmin from 'hooks/useIsThanxAdmin'
import { useEffect, useState } from 'react'

const delightedNamespace = 'delightedNps2'
const delightedProjectId = 'shLeJtZTINehsPsA'

const Delighted = () => {
  const [isLoaded, setIsLoaded] = useState(false)
  const merchantUser = useCurrentMerchantUser()
  const isThanxAdmin = useIsThanxAdmin()
  const merchant = useCurrentMerchant()

  useEffect(() => {
    ;(function (e, t, r, n) {
      if (!e[n]) {
        const a = (e[n] = []) as any
        const s = [
          'survey',
          'reset',
          'config',
          'init',
          'set',
          'get',
          'event',
          'identify',
          'track',
          'page',
          'screen',
          'group',
          'alias',
        ]
        for (let i = 0; i < s.length; i++) {
          const c = s[i]
          a[c] =
            a[c] ||
            (function (e) {
              return function () {
                const t = Array.prototype.slice.call(arguments)
                a.push([e, t])
              }
            })(c)
        }
        a.SNIPPET_VERSION = '1.0.1'
        const o = t.createElement('script')
        o.type = 'text/javascript'
        o.async = true
        o.onload = () => setIsLoaded(true)
        o.src =
          'https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/' +
          r +
          '/' +
          n +
          '.js'
        const p = t.getElementsByTagName('script')[0]
        if (p && p.parentNode) {
          p.parentNode.insertBefore(o, p)
        }
      }
    })(window, document, delightedProjectId, delightedNamespace)
  }, [])

  useEffect(() => {
    if (!isLoaded || !merchant || !merchantUser) return

    window[delightedNamespace]?.survey({
      email: merchantUser.email,
      name: `${merchantUser.first_name} ${merchantUser.last_name}`,
      properties: {
        merchant_id: merchant.id,
        is_admin: isThanxAdmin,
        merchant_name: merchant.name,
      },
    })
  }, [isLoaded, merchant, merchantUser, isThanxAdmin])

  return null
}

export default Delighted
