import { MetricsWithCustomAttribution } from 'models/CampaignMetric'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FetchableMetricPropsT, fetchableMetrics } from './helpers'

const FetchableMetricSection: React.FC<FetchableMetricPropsT> = props => {
  const {
    children,
    shouldFetch,
    className = '',
    reFetchAt = null,
    isCustomAutomated,
    ...typeProps
  } = props
  const dispatch = useDispatch()

  // allows some changes to cause data to refetch
  const refetchDependencies = (() => {
    switch (typeProps.type) {
      case 'campaign_metric':
        return typeProps.metricTypes.some(metricType =>
          MetricsWithCustomAttribution.includes(metricType)
        )
          ? [typeProps.attributionWindow]
          : []
      case 'campaign_event':
        return [
          typeProps.dateRangeFilter.start,
          typeProps.dateRangeFilter.end,
          typeProps.attributionWindow,
        ]
      case 'points_experience':
      case 'purchases_metric':
        return [typeProps.dateRangeFilter.start, typeProps.dateRangeFilter.end]
      case 'lifecycle_time_series_metric':
        return [typeProps.timespan]
    }

    return [reFetchAt]
  })()

  const [hasFetched, setHasFetched] = useState(false)

  useEffect(() => {
    if (shouldFetch) {
      setHasFetched(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch, ...refetchDependencies])

  useEffect(() => {
    async function fetchData() {
      await fetchableMetrics[typeProps.type]({
        dispatch,
        isCustomAutomated,
        ...typeProps,
      })
    }
    if (shouldFetch && !hasFetched) {
      fetchData()
      setHasFetched(true)
    }
  }, [dispatch, shouldFetch, hasFetched, typeProps])

  return <div className={className}>{children}</div>
}

export default FetchableMetricSection
