import { Radio } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import { alert } from 'actions/flash'
import { getValidation } from 'actions/validations'
import Badges from 'components/Badges'
import Help from 'components/Help'
import ImagePicker from 'components/ImagePicker'
import Modal from 'components/ModalContainer'
import Formsy from 'formsy-react'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import { ValidationTypes } from 'models/Validation'
import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import { helpArticles } from 'scenes/Points/Landing/Configuration/helpers'
import { selectPointsEarnValidation } from 'selectors/validation'
import Card from './Card'
import Layout from './Layout'
import useUpdate, { FormModel as BaseFormModel } from './useUpdate'

const t = buildTranslate('points.configuration')

type FormModel = Pick<BaseFormModel, 'earn_image'>

type Props = { pointsExperience: PointsExperience }

function InstructionsForCustomers(props: Props) {
  const { pointsExperience } = props

  const dispatch = useDispatch()
  const imageValidation = useSelector(selectPointsEarnValidation)

  const {
    formModel,
    updateFormModel,
    formState,
    setFormState,
    publish,
    isUpdating,
  } = useUpdate(
    pointsExperience.id,
    (pe: PointsExperience): FormModel => ({
      earn_image: {
        id: null,
        record_id: null,
        url: pe.earn_image.default,
        uploading: false,
      },
    })
  )

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [instructionsType, setInstructionsType] = useState<'text' | 'image'>(
    pointsExperience.earn_image.default ? 'image' : 'text'
  )

  useEffect(() => {
    if (!imageValidation) {
      dispatch(getValidation({ type: ValidationTypes.PointsEarn }))
    }
  }, [dispatch, imageValidation])

  if (!formModel) return null

  async function doPublish() {
    function transformModel(model: FormModel): FormModel {
      return {
        ...model,
        earn_image: instructionsType === 'image' ? model.earn_image : null,
      }
    }

    const result = await publish(transformModel)

    if (result.isErr()) {
      dispatch(
        alert({
          key: 'danger',
          message: t('edit.publish_failed'),
        })
      )
      return
    }

    dispatch(push('/points#instructions'))
    dispatch(
      alert({
        key: 'success',
        message: t('edit.publish_success'),
        timeout: 5,
      })
    )
  }

  return (
    <>
      <Helmet>
        <title>{t('edit.instructions.page_title')}</title>
      </Helmet>
      <Layout
        backLink="/points#instructions"
        canPublish={formState === 'valid'}
        onPublish={() => setIsConfirmModalOpen(true)}
      >
        <div className="mb-xl">
          <Text.Header2 className="mb-xs mt-0">
            {t('instructions.title')}
          </Text.Header2>
          <Text.BodyText3>{t('instructions.subtitle')}</Text.BodyText3>
        </div>
        <Card>
          <Formsy
            onChange={updateFormModel}
            onInvalid={() => setFormState('invalid')}
            onValid={() => setFormState('valid')}
            role="form"
          >
            <Radio
              value="text"
              checked={instructionsType === 'text'}
              onClick={() => setInstructionsType('text')}
            >
              {t('edit.instructions.show_text')}
            </Radio>
            <div className="mb-m">
              {instructionsType === 'text' && (
                <div className="ml-l">
                  <div className="mb-xs">
                    <Text.BodyText4 bold>
                      {t('edit.instructions.text_label')}
                    </Text.BodyText4>
                  </div>
                  <div>
                    <Text.BodyText3>
                      {pointsExperience.earn_description}
                    </Text.BodyText3>
                  </div>
                </div>
              )}
            </div>
            <Radio
              value="image"
              checked={instructionsType === 'image'}
              onClick={() => setInstructionsType('image')}
            >
              {t('edit.instructions.show_image')}
            </Radio>
            {!!imageValidation && (
              <div
                className={`
                  mt-m pl-l w-100
                  ${instructionsType !== 'image' ? 'd-none' : ''}
                `}
              >
                <Badges
                  className="mb-s"
                  values={[
                    t('edit.instructions.badge.app'),
                    t('edit.instructions.badge.web'),
                    t('edit.instructions.badge.width'),
                    t('edit.instructions.badge.jpg'),
                    t('edit.instructions.badge.png'),
                  ]}
                />
                <ImagePicker
                  value={formModel.earn_image ?? undefined}
                  name="earn_image"
                  imageValidations={imageValidation}
                  shouldValidate={instructionsType === 'image'}
                />
              </div>
            )}
          </Formsy>
        </Card>
        <Help title={t('help.title')} articles={helpArticles.instructions} />
      </Layout>

      <Modal
        title={t(`edit.instructions.publish_modal.title`)}
        proceedText={t(`edit.instructions.publish_modal.proceed`)}
        isOpen={isConfirmModalOpen}
        onProceed={async () => {
          await doPublish()
          setIsConfirmModalOpen(false)
        }}
        onClose={() => setIsConfirmModalOpen(false)}
        isLoading={isUpdating}
      >
        {t(`edit.instructions.publish_modal.message`)}
      </Modal>
    </>
  )
}

export default InstructionsForCustomers
