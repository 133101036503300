import { useStyletron } from '@thanx/uikit/theme'
import PageHeader from 'components/PageHeader'
import { LocationContext } from 'contexts/LocationContext'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import React, { useContext } from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectCumulativeMetric } from 'selectors/captureRateMetric'
import Stub from '../components/Stub'
import Content from './Content'
import NoContent from './NoContent'
import { UploadStateProvider } from './UploadStateContext'
import { crrAccessState } from './utilities'

const t = buildTranslate('capture_rate')

const CaptureRateReport: React.FC = () => {
  const merchant = useCurrentMerchant()
  const [css] = useStyletron()
  const { currentLocation } = useContext(LocationContext)
  const cumulativeMetrics = useSelector(state =>
    selectCumulativeMetric(state, merchant?.id, currentLocation?.id)
  )
  const revenueSource = cumulativeMetrics?.revenue_source || 'manual'

  // TODO: remove this flag for public release
  const usePosData = useFlag('use-pos-data-for-crr', false)

  if (!merchant) {
    return <Stub type="captureRate" />
  }

  const reportAccessState = crrAccessState(merchant)
  const remainingDays =
    reportAccessState === 'available' || reportAccessState === 'coming_soon'
      ? null
      : reportAccessState

  return (
    <UploadStateProvider>
      <PageHeader title={t('title')} description={t('subtitle')}>
        <Helmet>
          <title>{t('page_title')}</title>
        </Helmet>
        <div
          className={`grey-05-bkg border-top-1 pb-xxl grey-20-border pt-xl ${css(
            {
              minHeight: 'calc(100% - 75px)',
            }
          )}`}
        >
          <Container>
            {reportAccessState === 'available' ? (
              <Content
                merchant={merchant}
                hasIntegrations={usePosData && revenueSource === 'pos'}
              />
            ) : (
              <NoContent remainingDays={remainingDays} />
            )}
          </Container>
        </div>
      </PageHeader>
    </UploadStateProvider>
  )
}

export default CaptureRateReport
