import { Fields } from 'models/FileUpload'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'
import {
  CREATE_FILE_UPLOAD,
  CREATE_FILE_UPLOAD_FAIL,
  CREATE_FILE_UPLOAD_SUCCESS,
} from './fileUpload'

export const GET_IMPORTER_CONFIG = 'import/GET_IMPORTER_CONFIG'
export const GET_IMPORTER_CONFIG_SUCCESS = 'import/GET_IMPORTER_CONFIG_SUCCESS'
export const GET_IMPORTER_CONFIG_FAIL = 'import/GET_IMPORTER_CONFIG_FAIL'

export type ImporterConfig = {
  jwt: string
  client_id: string
  template_key: string
}

export type GetImporterConfigAction = AxiosAction<
  typeof GET_IMPORTER_CONFIG,
  ImporterConfig
>

type GetImporterConfigResolvedAction =
  | FailureAction<typeof GET_IMPORTER_CONFIG_FAIL>
  | SuccessAction<typeof GET_IMPORTER_CONFIG_SUCCESS>

export function getImporterConfig(): GetImporterConfigAction {
  return {
    type: GET_IMPORTER_CONFIG,
    payload: {
      client: 'default',
      request: {
        url: `capture_rate/importer_config`,
        method: 'get',
      },
    },
  }
}

type CreateImportResolvedAction =
  | FailureAction<typeof CREATE_FILE_UPLOAD_FAIL>
  | SuccessAction<typeof CREATE_FILE_UPLOAD_SUCCESS>

export function createImport(
  embedId: number,
  sheetId: number
): AxiosAction<typeof CREATE_FILE_UPLOAD, { file_upload: Fields }> {
  return {
    type: CREATE_FILE_UPLOAD,
    payload: {
      client: 'default',
      request: {
        url: `capture_rate/import`,
        method: 'post',
        data: {
          embed_id: embedId,
          sheet_id: sheetId,
        },
      },
    },
  }
}

export type ResolvedAction =
  | GetImporterConfigResolvedAction
  | CreateImportResolvedAction
