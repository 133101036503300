import { Switch } from '@thanx/uikit/switch'
import React, { useState } from 'react'

type Props = {
  color: string
  image?: string
}

type SvgProps = {
  color: string
}

const AvatarSvg: React.FC<SvgProps> = ({ color }) => {
  // Exported from sketch
  return (
    <svg viewBox="0 0 92 92">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <circle fill={color} cx="46" cy="46" r="46" />
          <path
            d="M46.3225806,17 C55.3370968,17 62.6451613,24.3080645 62.6451613,33.3225806 C62.6451613,42.3370968 55.3370968,49.6451613 46.3225806,49.6451613 C37.3080645,49.6451613 30,42.3370968 30,33.3225806 C30,24.3080645 37.3080645,17 46.3225806,17 Z"
            fill="#232B33"
            fillRule="nonzero"
            opacity="0.40"
          />
          <path
            d="M46.1733871,80.7419355 C35.2854839,80.7419355 25.5290323,75.8080645 19,68.0919355 C22.4870968,61.5258065 29.3129032,57 37.2701613,57 C37.7153226,57 38.1604839,57.0741935 38.5870968,57.2040323 C40.9983871,57.9830645 43.5209677,58.483871 46.1733871,58.483871 C48.8258065,58.483871 51.3669355,57.9830645 53.7596774,57.2040323 C54.1862903,57.0741935 54.6314516,57 55.0766129,57 C63.033871,57 69.8596774,61.5258065 73.3467742,68.0919355 C66.8177419,75.8080645 57.0612903,80.7419355 46.1733871,80.7419355 Z"
            fill="#232B33"
            fillRule="nonzero"
            opacity="0.40"
          />
        </g>
      </g>
    </svg>
  )
}

const Avatar: React.FC<Props> = ({ color, image }) => {
  const [imageError, setImageError] = useState(false)

  if (!image) return <AvatarSvg color={color} />
  return (
    <Switch condition={!imageError} fallback={<AvatarSvg color={color} />}>
      <div className="border-round full-height full-width overflow-hidden">
        <img
          className="cover full-height full-width"
          src={image}
          alt="avatar"
          onError={() => setImageError(true)}
        />
      </div>
    </Switch>
  )
}

export default Avatar
