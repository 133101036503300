import {
  CouponPoolGenerationType,
  CouponPoolState,
  CouponPoolType,
  Fields,
} from 'models/CouponPool'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

type GetAllAction = AxiosAction<'couponPool/GET_ALL'> & {
  redeemTemplateId: number
}
export type GetAllResolvedAction =
  | FailureAction<'couponPool/GET_ALL_FAIL'>
  | SuccessAction<
      'couponPool/GET_ALL_SUCCESS',
      { coupon_pools: Fields[] },
      GetAllAction
    >
export function getCouponPools(redeemTemplateId: number): GetAllAction {
  return {
    type: 'couponPool/GET_ALL',
    payload: {
      client: 'default',
      request: {
        url: `redeem_templates/${redeemTemplateId}/coupon_pools`,
        method: 'get',
      },
    },
    redeemTemplateId,
  }
}

type CreateAction = AxiosAction<'couponPool/CREATE'> & {
  redeemTemplateId: number
}
export type CreateResolvedAction =
  | FailureAction<'couponPool/CREATE_FAIL'>
  | SuccessAction<
      'couponPool/CREATE_SUCCESS',
      { coupon_pool: Fields },
      CreateAction
    >
type CreatePayload = {
  generation_type: CouponPoolGenerationType
  type: CouponPoolType
  static_code?: string
  redemption_provider: string | null
}
export function createCouponPool(
  redeemTemplateId: number,
  payload: CreatePayload
): CreateAction {
  return {
    type: 'couponPool/CREATE',
    payload: {
      client: 'default',
      request: {
        url: `redeem_templates/${redeemTemplateId}/coupon_pools`,
        method: 'post',
        data: { coupon_pool: payload },
      },
    },
    redeemTemplateId,
  }
}

type UpdateResolvedAction =
  | FailureAction<'couponPool/UPDATE_FAIL'>
  | SuccessAction<'couponPool/UPDATE_SUCCESS', { coupon_pool: Fields }>
type UpdatePayload = {
  generation_type: CouponPoolGenerationType
  type: CouponPoolType
  state: CouponPoolState
  static_code: string | null
  redemption_provider: string | null
}
export function updateCouponPool(
  redeemTemplateId: number,
  poolId: number,
  payload: Partial<UpdatePayload>
): AxiosAction<'couponPool/UPDATE'> {
  return {
    type: 'couponPool/UPDATE',
    payload: {
      client: 'default',
      request: {
        url: `redeem_templates/${redeemTemplateId}/coupon_pools/${poolId}`,
        method: 'patch',
        data: { coupon_pool: payload },
      },
    },
  }
}

export type ResolvedAction =
  | GetAllResolvedAction
  | CreateResolvedAction
  | UpdateResolvedAction
