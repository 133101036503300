import isArray from 'lodash/isArray'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

type Props = {
  children: React.ReactNode
  maxItems?: number
  getShowMoreText?: (count: number) => string
}

/**
 * Displays up to maxItems items, a button to show the rest.
 */
const ExpandableList: React.FC<Props> = props => {
  const { children, maxItems = 10, getShowMoreText } = props

  const [isExpanded, setIsExpanded] = useState(false)

  const items = isArray(children) ? children : [children]
  const visibleItems = isExpanded ? items : items.slice(0, maxItems)
  const extraCount = items.length - visibleItems.length

  const showMoreText = getShowMoreText
    ? getShowMoreText(extraCount)
    : I18n.t('components.expandableList.showMore', {
        count: extraCount,
      })

  return (
    <div>
      {visibleItems}
      {extraCount > 0 && (
        <Button
          bsStyle="link"
          onClick={() => setIsExpanded(true)}
          className="font-size-14 cursor-pointer mt-xs"
        >
          {showMoreText}
        </Button>
      )}
    </div>
  )
}

export default ExpandableList
