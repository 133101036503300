import { LifecycleMetric, ShortType } from 'models/MerchantMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import {
  stages,
  stagesMetricsMapping,
} from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { selectMerchantMetricByType } from 'selectors/merchantMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'
import Row from './Row'

const Rows: React.FC = () => {
  const memberLifecycleState =
    (useSelector(state =>
      selectMerchantMetricByType(state, ShortType.LIFECYCLE)
    ) as LifecycleMetric) || {}

  const { isLoading, isErrored, data } = memberLifecycleState

  return (
    <>
      {stages.map((stage, i) => {
        const count = data && data[stagesMetricsMapping[stage]]
        const totalCount = data && data[stagesMetricsMapping[stages[0]]]
        const previousStageCount =
          data && (data[stagesMetricsMapping[stages[i - 1]]] ?? count)
        const conversionRate = count / previousStageCount
        const totalPercentage = count / totalCount

        return (
          <Row
            stage={stage}
            count={numberFormatter(count)}
            conversionRate={percentageFormatter(conversionRate, 1)}
            totalPercentage={percentageFormatter(totalPercentage, 1)}
            isLoading={isLoading}
            isErrored={isErrored}
            key={i}
          />
        )
      })}
    </>
  )
}

export default Rows
