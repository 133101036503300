import React, { Component } from 'react'

export default class IconMagic extends Component {
  render() {
    return (
      <svg
        width="18px"
        height="16px"
        viewBox="0 0 18 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Send-A-Message-V5"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="16-Incentive"
            transform="translate(-526.000000, -543.000000)"
            fillRule="nonzero"
          >
            <g id="ic_magic" transform="translate(526.000000, 543.000000)">
              <path
                d="M7.875,3.42857143 L8.4375,2.28571429 L9.5625,1.71428571 L8.4375,1.14285714 L7.875,0 L7.3125,1.14285714 L6.1875,1.71428571 L7.3125,2.28571429 L7.875,3.42857143 Z M7.875,3.42857143 L8.4375,2.28571429 L9.5625,1.71428571 L8.4375,1.14285714 L7.875,0 L7.3125,1.14285714 L6.1875,1.71428571 L7.3125,2.28571429 L7.875,3.42857143 Z M2.8125,5.71428571 L3.74976562,3.80964286 L5.625,2.85714286 L3.74976562,1.90464286 L2.8125,0 L1.87523437,1.90464286 L0,2.85714286 L1.87523437,3.80964286 L2.8125,5.71428571 Z M2.8125,2.28571429 C3.12328125,2.28571429 3.375,2.54142857 3.375,2.85714286 C3.375,3.17285714 3.12328125,3.42857143 2.8125,3.42857143 C2.50171875,3.42857143 2.25,3.17285714 2.25,2.85714286 C2.25,2.54142857 2.50171875,2.28571429 2.8125,2.28571429 Z M15.1875,10.2857143 L14.2502344,12.1903571 L12.375,13.1428571 L14.2502344,14.0953571 L15.1875,16 L16.1247656,14.0953571 L18,13.1428571 L16.1247656,12.1903571 L15.1875,10.2857143 Z M15.1875,13.7142857 C14.8767187,13.7142857 14.625,13.4585714 14.625,13.1428571 C14.625,12.8271429 14.8767187,12.5714286 15.1875,12.5714286 C15.4982812,12.5714286 15.75,12.8271429 15.75,13.1428571 C15.75,13.4585714 15.4982812,13.7142857 15.1875,13.7142857 Z M13.4159766,7.10642857 L11.0042578,4.65642857 L14.4625781,1.14285714 L14.4636328,1.14285714 L16.875,3.5925 L13.4159766,7.10642857 Z"
                id="Shape"
                fill="#13C1BF"
              />
              <polygon
                id="Path-Copy-3"
                fill="#13C1BF"
                points="3 0 2.00025 1.999875 0 3 2.00025 4.000125 3 6 3.99975 4.000125 6 3 3.99975 1.999875"
              />
              <polygon
                id="Path-Copy-5"
                fill="#13C1BF"
                points="15 10 14.00025 11.999875 12 13 14.00025 14.000125 15 16 15.99975 14.000125 18 13 15.99975 11.999875"
              />
              <polygon
                id="Path"
                fill="#642B7B"
                points="4.30745 16 2 13.6926206 10.6922136 5 13 7.30771583"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
