import { FormsyRadioGroup, Radio } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import { SlideFrequency } from 'api/onboardingSlideshow'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.signup.onboarding_slideshow.content.frequency')

type Props = {
  frequency: SlideFrequency
}

const FrequencySelectors = ({ frequency }: Props) => {
  return (
    <div className="mb-m">
      <Text.Header4 tag={'p'} color="grey90">
        {t('header')}
      </Text.Header4>
      <FormsyRadioGroup name="settings.frequency" value={frequency}>
        {['multiple', 'once'].map(freq => (
          <Radio
            className="mb-m"
            value={freq}
            description={
              frequency === freq && t(`radio_buttons.${freq}.description`)
            }
            overrides={{
              Description: {
                style: {
                  maxWidth: '100%',
                  color: palette.grey70,
                },
              },
            }}
          >
            {t(`radio_buttons.${freq}.header`)}
          </Radio>
        ))}
      </FormsyRadioGroup>
    </div>
  )
}

export default FrequencySelectors
