import React from 'react'

type Props = {
  summaryValue?: string
  summaryText?: string | React.ReactNode
  title?: string
  secondarySummaryValue?: string
}

const MetricSummary: React.FC<Props> = props => {
  const { summaryValue, summaryText, title, secondarySummaryValue } = props
  return (
    <div>
      <div className="small-caps-5 margin-bottom-large">
        <span>{title}</span>
      </div>
      <div className="margin-bottom-large">
        <div className="hero-number margin-bottom-micro">
          {summaryValue}
          <span className="font-size-18 padding-left-small">
            {secondarySummaryValue}
          </span>
        </div>
        <span className="grey-70">{summaryText}</span>
      </div>
    </div>
  )
}

export default MetricSummary
