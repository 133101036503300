export default {
  title: 'Rewards',
  page_title: 'All reward templates | Thanx',
  create_reward: 'Create a new reward template',
  title_info:
    'This is where you define reusable templates for the rewards you want to give customers. You define the discount, display text, and more, but you’re not giving away a reward just yet.\nTo grant customers a reward you must add an active reward template to a campaign. Or you can grant a reward when responding to customer feedback.',
  empty_list: 'You currently have no rewards.',
  tabs: {
    create_reward: 'Create a new reward template',
    published: {
      title: 'active',
      subtitle: 'Active templates',
      description:
        'Reusable reward templates you can add to campaigns, loyalty programs, and customer feedback in order to grant rewards to customers.',
      no_template_title: 'You don’t have active reward templates',
      no_template_description:
        'These are reusable reward templates that you can add to campaigns, loyalty programs, and customer feedback in order to grant rewards to customers.',
    },
    unpublished: {
      title: 'drafts',
      subtitle: 'Draft templates',
      description:
        'Reusable reward templates you started creating but did not activate.',
      no_template_title: 'You don’t have any drafts',
      no_template_description:
        'Reward templates you started creating but did not activate.',
    },
    archived: {
      title: 'archived',
      subtitle: 'Archived templates',
      description:
        'Not available to use in campaigns, loyalty programs, and customer feedback.',
      no_template_title: 'You don’t have archived reward templates',
      no_template_description:
        'Archived reward templates are hidden from campaigns, loyalty programs, and customer feedback. You can’t add archived templates to campaigns, loyalty programs, or customer feedback.',
    },
    column_titles: {
      name: 'Reward name',
      date_created: 'Date created',
      status: 'Status',
    },
  },
  redeem_item: {
    edit: 'Edit',
    view: 'View',
  },
  welcome: {
    title: 'Welcome!',
    description_1:
      'A reward template is a pre-built definition for the type of reward you want to give to customers, for example $5 off an order.',
    description_2:
      'The template is not the actual reward. You can add the reward template to campaigns, loyalty programs, and customer feedback responses later to grant an actual reward to a customer, for example actually give a customer $5.',
  },
}
