import { Switch } from '@thanx/uikit/switch'
import { alert } from 'actions/flash'
import { getLocations } from 'actions/location'
import { getLocationCategories } from 'actions/locationCategory'
import ExpandableList from 'components/ExpandableList'
import MultiSelectWithFormsy from 'components/Form/MultiSelectWithFormsy'
import SearchPlaceholder from 'components/Form/MultiSelectWithFormsy/SearchPlaceholder'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { Fields as LocationCategory } from 'models/LocationCategory'
import React, { useEffect, useState } from 'react'
import { ControlLabel, FormGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectCurrentMerchantLocations } from 'selectors/location'
import { selectAllLocationCategories } from 'selectors/locationCategory'
import { Can, useAbility } from 'utilities/ability'
import {
  locationCategoriesToSelectOptions,
  locationsToSelectOptions,
} from 'utilities/locationUtils'
import { filterCategorizedLocations } from './helpers'

type Props = {
  campaign: Campaign
}

const translate = buildTranslate(
  'thanx_campaigns.builder.forms.audience.locationRefinement'
)

const LocationPlaceholder = props => (
  <SearchPlaceholder
    getText={count => translate('location.placeholder', { count })}
    {...props}
  />
)

const CategoryPlaceholder = props => (
  <SearchPlaceholder
    getText={count => translate('category.placeholder', { count })}
    {...props}
  />
)

const LocationRefinement: React.FC<Props> = props => {
  const { campaign } = props

  const defaultLocationIds = campaign.config_location_ids || []
  const defaultCategoryIds = campaign.config_location_category_ids || []

  const [isLocationsLoading, setIsLocationsLoading] = useState(true)
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true)
  const [selectedLocationIds, setSelectedLocationIds] =
    useState<number[]>(defaultLocationIds)
  const [selectedCategoryIds, setSelectedCategoryIds] =
    useState<number[]>(defaultCategoryIds)

  const ability = useAbility()
  const canTargetLocations = ability.can(
    'targetCategorizedLocations',
    'Campaign'
  )

  const dispatch = useDispatch()
  const locations = useSelector(state => selectCurrentMerchantLocations(state))
  const categories = useSelector(selectAllLocationCategories)

  let availableLocations = locations
  let availableCategories: LocationCategory[] = []
  if (!canTargetLocations) {
    //@ts-ignore
    ;[availableLocations, availableCategories] = filterCategorizedLocations(
      locations,
      categories
    )
  }

  const selectedLocations = availableLocations.filter(location =>
    selectedLocationIds.includes(location.id)
  )
  const selectedCategories = availableCategories.filter(category =>
    selectedCategoryIds.includes(category.id)
  )

  useEffect(() => {
    async function fetchLocations() {
      const response = await dispatch(getLocations())
      if (response.error) {
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.builder.errors.load_campaign'),
          })
        )
      }

      setIsLocationsLoading(false)
    }

    async function fetchCategories() {
      const response = await dispatch(getLocationCategories())
      if (response.error) {
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.builder.errors.load_campaign'),
          })
        )
      }

      setIsCategoriesLoading(false)
    }

    fetchLocations()
    fetchCategories()
  }, [dispatch])

  return (
    <Spinner isLoading={isLocationsLoading || isCategoriesLoading}>
      <Switch condition={availableCategories.length > 0}>
        <Can not I="targetCategorizedLocations" on="Campaign" this={undefined}>
          <div className="mb-s">
            <FormGroup>
              <ControlLabel className="body-text-4 bold">
                {translate('category.label')}
              </ControlLabel>
              <MultiSelectWithFormsy
                name="config_location_category_ids"
                defaultValue={defaultCategoryIds}
                options={locationCategoriesToSelectOptions(availableCategories)}
                onChange={value => setSelectedCategoryIds(value)}
                components={{
                  Placeholder: CategoryPlaceholder,
                }}
              />
            </FormGroup>
            <ExpandableList
              maxItems={4}
              getShowMoreText={count => translate('category.expand', { count })}
            >
              {selectedCategories.map(({ id, title }) => (
                <div key={id} className="mt-s font-size-14 grey-70">
                  {title}
                </div>
              ))}
            </ExpandableList>
          </div>
        </Can>
      </Switch>
      <Switch condition={availableLocations.length > 0}>
        <div>
          <FormGroup>
            <ControlLabel className="body-text-4 bold">
              {translate('location.label')}
            </ControlLabel>
            <MultiSelectWithFormsy
              name="config_location_ids"
              defaultValue={defaultLocationIds}
              options={locationsToSelectOptions(availableLocations)}
              onChange={value => setSelectedLocationIds(value)}
              components={{
                Placeholder: LocationPlaceholder,
              }}
            />
          </FormGroup>
          <ExpandableList
            maxItems={4}
            getShowMoreText={count => translate('location.expand', { count })}
          >
            {selectedLocations.map(location => (
              <div key={location.id} className="mt-s font-size-14 grey-70">
                {location.fullName()}
              </div>
            ))}
          </ExpandableList>
        </div>
      </Switch>
    </Spinner>
  )
}

export default LocationRefinement
