import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import React from 'react'
import { merchantTheme } from 'theme'

type PropsT = {
  status: 'success' | 'error' | 'disabled'
  value: string
}

const StatusBadge: React.FC<PropsT> = ({ status, value }) => {
  const [css] = useStyletron()
  let color: string = ''
  let backgroundColor: string = ''

  switch (status) {
    case 'success':
      color = merchantTheme.colors.nephritis50
      backgroundColor = merchantTheme.colors.nephritis20
      break
    case 'error':
      color = merchantTheme.colors.cayenne50
      backgroundColor = merchantTheme.colors.cayenne10
      break
    case 'disabled':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.grey20
      break
    default:
      break
  }

  return (
    <Badge
      value={value}
      className={css({
        backgroundColor,
        color,
      })}
    />
  )
}

export default StatusBadge
