// @flow
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'

type Props = {
  img?: any,
  name: string,
  value: string,
  selected: boolean,
  onClick: any,
}
type State = {
  selected: boolean,
}

export default class Provider extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selected: false,
    }
  }

  style() {
    return {
      padding: '0 30px',
      cursor: 'pointer',
      opacity: this.props.selected ? 1 : 0.75,
      filter: this.props.selected ? 'none' : 'grayscale(100%)',
      height: '100px',
      display: 'table',
      width: '100%',
    }
  }

  onClick() {
    this.props.onClick(this.props.value)
  }

  content() {
    if (this.props.img) {
      return (
        <img
          src={this.props.img}
          className="img-responsive"
          alt={this.props.name}
          style={{ margin: 'auto' }}
        />
      )
    }
    return <strong>{this.props.name}</strong>
  }
  render() {
    return (
      <Col
        sm={6}
        md={4}
        className="text-center"
        style={{ marginBottom: '30px' }}
      >
        <div
          onClick={this.onClick.bind(this)}
          style={this.style()}
          className="peter-river peter-river-border border-1"
        >
          <div style={{ display: 'table-cell', verticalAlign: 'middle' }}>
            {this.content()}
          </div>
        </div>
      </Col>
    )
  }
}
