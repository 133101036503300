import { Button, Kind } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import { Fields as OrderingConfig } from 'models/OrderingConfig'
import React from 'react'
import { merchantTheme } from 'theme'
import Row from './Row'

const t = buildTranslate('ordering_management.location_list.table')

type PropsT = {
  className?: string
  selectedLocations: OrderingConfig[]
  locations: OrderingConfig[]
  onSelect?: (l: OrderingConfig, checked: boolean) => void
  onSelectAll?: () => void
  onSelectNone?: () => void
  onAction: (l: OrderingConfig, action: string) => void
}

const LocationList: React.FC<PropsT> = ({
  className = '',
  selectedLocations,
  locations,
  onSelect,
  onSelectAll,
  onSelectNone,
  onAction,
}) => {
  const [css] = useStyletron()

  const headerClass = css({
    fontWeight: '700',
    fontSize: '14px',
  })

  const handleSelect = (location: OrderingConfig, checked: boolean) => {
    if (onSelect) {
      onSelect(location, checked)
    }
  }

  const handleSelectAll = () => {
    if (onSelectAll) {
      onSelectAll()
    }
  }

  const handleSelectNone = () => {
    if (onSelectNone) {
      onSelectNone()
    }
  }

  return (
    <>
      <div
        className={`d-flex mt-l ${css({
          color: merchantTheme.colors.belizeHole50,
        })}`}
      >
        <Button
          kind={Kind.MINIMAL}
          className="font-weight-normal p-0"
          onClick={handleSelectAll}
        >
          {t('select_all')}
        </Button>
        <Button
          kind={Kind.MINIMAL}
          className="font-weight-normal"
          onClick={handleSelectNone}
        >
          {t('deselect_all')}
        </Button>
      </div>
      <table className={`w-100 ${className}`}>
        <thead>
          <tr className={headerClass}>
            <td></td>
            <td>{t('headers.location')}</td>
            <td>{t('headers.menu_status')}</td>
            <td>{t('headers.ordering_status')}</td>
            <td></td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {locations.map(location => {
            return (
              <Row
                key={location.id}
                selected={!!selectedLocations.find(l => l.id === location.id)}
                location={location}
                onSelect={handleSelect}
                onAction={onAction}
              />
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default LocationList
