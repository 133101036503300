import { getApp } from 'actions/app'
import { updateCampaign } from 'actions/campaigns'
import useIsDemo from 'hooks/useIsDemo'
import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import EmailChannel from './EmailChannel'
import PushChannel from './PushChannel'
import SmsChannel from './SmsChannel'

type PropsT = {
  config: VariantOrCampaign
  campaign: Campaign
  isEditable: boolean
  isControlVariant: boolean
}

const Channels: React.FC<PropsT> = ({ campaign, config }) => {
  const [isUpdatingFlag, setIsUpdatingFlag] = useState(false)
  const dispatch = useDispatch()
  const isDemo = useIsDemo()

  useEffect(() => {
    dispatch(getApp())
  }, [dispatch])

  useEffect(() => {
    if (isUpdatingFlag) return
    if (!campaign || campaign.allow_non_reward_messages) return
    async function update() {
      setIsUpdatingFlag(true)
      await dispatch(
        updateCampaign(campaign.id, {
          campaign: {
            ...campaign,
            allow_non_reward_messages: true,
          },
        })
      )
      setIsUpdatingFlag(false)
    }
    update()
  }, [campaign, isUpdatingFlag, dispatch])

  return (
    <div>
      <EmailChannel campaign={campaign} config={config} />
      <PushChannel campaign={campaign} config={config} />
      {isDemo ? null : <SmsChannel campaign={campaign} config={config} />}
    </div>
  )
}

export default Channels
