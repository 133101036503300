import { useStyletron } from '@thanx/uikit/theme'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.view.profile.transferred_points.table')

type Props = {
  pointsProvisions: UserProfile['points_provisions']
}

const Table = ({ pointsProvisions }: Props) => {
  const [css] = useStyletron()

  return (
    <table
      className={`full-width ${css({
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
      })}`}
    >
      <thead>
        <tr>
          <HeaderCell>{t('points')}</HeaderCell>
          <HeaderCell>{t('transferred')}</HeaderCell>
          <HeaderCell>{t('claimed')}</HeaderCell>
          <HeaderCell>{t('status')}</HeaderCell>
        </tr>
      </thead>
      <tbody>
        {pointsProvisions.map(pointsProvision => (
          <Row key={pointsProvision.id} pointsProvision={pointsProvision} />
        ))}
      </tbody>
    </table>
  )
}

export default Table
