// @flow
import React, { Component } from 'react'
import { I18n } from 'react-redux-i18n'
import { Form, CheckboxGroup, Checkbox } from 'formsy-react-components'
import { Row, Col, Button } from 'react-bootstrap'
import Spacer from 'components/Spacer'

type Props = {
  isSubmitting: boolean,
  lists: Array<any>,
  onSubmit: any => void,
}

type State = {
  isAllSelected: boolean,
  selectedIds: Array<string>,
}

export default class ImportSettings extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const selectedListIds = props.lists
      .filter(list => list.state === 'enabled')
      .map(list => String(list.id))

    this.state = {
      isAllSelected: props.lists.length === selectedListIds.length,
      selectedIds: selectedListIds,
    }
  }

  toggleSelectAll(_: string, value: boolean) {
    const isAllSelected = value
    const selectedIds = isAllSelected
      ? this.props.lists.map(list => String(list.id))
      : []

    this.setState({
      isAllSelected,
      selectedIds,
    })
  }

  listsOnChange(name: string, value: Array<string>) {
    this.setState({
      selectedIds: value,
      isAllSelected: value.length === this.props.lists.length,
    })
  }

  renderForm() {
    return (
      <Form onValidSubmit={this.props.onSubmit}>
        <Row>
          <Col sm={12}>
            <Checkbox
              layout="elementOnly"
              name="selectAll"
              valueLabel="Select All"
              value={this.state.isAllSelected}
              onChange={this.toggleSelectAll.bind(this)}
            />
            <Spacer paddingTop="10px" />
            <CheckboxGroup
              layout="elementOnly"
              label="Sync Lists"
              name="listIds"
              value={this.state.selectedIds}
              options={this.props.lists.map(list => {
                return {
                  value: String(list.id),
                  label: `${list.name}`,
                }
              })}
              onChange={this.listsOnChange.bind(this)}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Spacer paddingTop="10px" />
            <Button
              bsStyle="primary"
              type="submit"
              disabled={this.props.isSubmitting}
            >
              {this.props.isSubmitting
                ? I18n.t(
                    'emails.subscribers.export.choose_list.submit_button_submitting'
                  )
                : I18n.t('emails.subscribers.export.choose_list.submit_button')}
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }

  renderContent() {
    return (
      <div>
        <Row>
          <Col sm={12}>
            <h3 className="deprecated__h3">
              {I18n.t('emails.subscribers.export.choose_list.title')}
            </h3>
            <p className="body-text-2">
              {I18n.t('emails.subscribers.export.choose_list.description')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>{this.renderForm()}</Col>
        </Row>
      </div>
    )
  }

  render() {
    return (
      <div className="container">
        <Spacer paddingTop="40px" paddingBottom="30px">
          {this.renderContent()}
        </Spacer>
      </div>
    )
  }
}
