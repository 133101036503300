export default {
  modal: {
    form: {
      name_label: 'Segment name',
      name_description:
        'Give your segment a name so you can reference it later.',
      message_label: 'Customer behavior',
      message_description:
        'Customers can be targeted by frequency, what they buy, spend, and more.',
      message_placeholder: 'Target these customers by finding people who...',
      submit_button: 'Request segment',
      cancel_button: 'Cancel',
      request_success: 'Your custom segment request has been submitted.',
      errors: {
        name_required: 'Segment name is required',
        message_required: 'Customer behavior description is required',
        submit:
          'There was an error in sending your custom campaign request. Please try again.',
      },
    },
  },
}
