import logo from 'assets/images/Thanx-logo-dark.png'
import Flash from 'components/Flash'
import Spinner from 'components/Spinner'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useStyletron } from 'styletron-react'

type Props = {
  children: React.ReactNode
  isLoading?: boolean
}

const InvitationLayout = ({ children, isLoading = false }: Props) => {
  const [css] = useStyletron()

  return (
    <>
      <Helmet>
        <title>Thanx</title>
      </Helmet>

      <div className="grey-05-bkg full-height">
        <Flash />
        <Container
          className={`pt-xxxl px-0 text-center ${css({ maxWidth: '400px' })}`}
        >
          <div className="text-center mb-xl">
            <img src={logo} width={109} alt="Thanx" />
          </div>
          <Spinner isLoading={isLoading}>{children}</Spinner>
        </Container>
      </div>
    </>
  )
}
export default InvitationLayout
