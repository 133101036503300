import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import DownloadIcon from 'components/Icons/Download'
import InfoIcon from 'components/Icons/Information'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React, { useEffect } from 'react'
import { useIsMobile } from 'utilities/responsive'

import { alert } from 'actions/flash'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { captureRateImportUrl } from 'utilities/urlUtils'
import { useUploadState } from '../UploadStateContext'

type Props = {
  onImportOpen: () => void
  isImporterLoading: boolean
  hasIntegrations?: boolean
}

const t = buildTranslate('capture_rate.csv_upload')

const CsvUpload: React.FC<Props> = props => {
  const { onImportOpen, isImporterLoading, hasIntegrations = false } = props
  const dispatch = useDispatch()
  const [css] = useStyletron()
  const isMobile = useIsMobile()

  const { uploadState, onFileProcessed } = useUploadState()
  const isProcessing = uploadState === 'processing'
  const isErrored = uploadState === 'errored'

  const bodyStyle = css({
    backgroundColor: isErrored ? palette.cayenne10 : palette.belizeHole10,
    flexDirection: isMobile ? 'column' : 'row',
    height: isMobile ? 'auto' : '100px',
  })

  const title = (() => {
    if (isProcessing) return t('import_in_progress')

    if (isErrored) return t('import_errored')

    return t('import_pos_data')
  })()

  const body = (() => {
    if (isProcessing) return t('data_processing')

    if (isErrored) return t('something_went_wrong')

    return t('upload_your_pos_data')
  })()

  useEffect(() => {
    onFileProcessed(() => {
      dispatch(
        alert({
          key: 'success',
          message: t('imported_successfully'),
          timeout: 5,
        })
      )
    })
  }, [onFileProcessed, dispatch])

  return (
    <div
      className={`w-100 ${css({
        border: `1px solid ${palette.grey30}`,
        borderRadius: '4px',
      })}`}
    >
      <div className="d-flex p-s">
        <DownloadIcon className="mr-xs" />
        <div>
          <Text.SmallCaps3 tag="div">{t('pos_data_source')}</Text.SmallCaps3>
          <div className={css({ letterSpacing: '0.5px' })}>
            <Text.BodyText5 tag="div" color="grey70" className="mt-xxs">
              {hasIntegrations ? t('automated_import') : t('manual_import')}
            </Text.BodyText5>
            {hasIntegrations && (
              <Text.BodyText5 tag="div" color="grey70">
                {t('automated_import_description')}
              </Text.BodyText5>
            )}
          </div>
        </div>
      </div>
      {!hasIntegrations && (
        <div
          className={`d-flex p-m justify-content-between align-items-center ${bodyStyle}`}
        >
          <div className={`d-flex ${css({ height: '46px' })}`}>
            {isProcessing ? (
              <Spinner isLoading />
            ) : (
              <InfoIcon type={isErrored ? 'error' : 'info'} />
            )}
            <div className="ml-xs d-flex flex-column justify-content-between">
              <Text.BodyText4 bold tag="div">
                {title}
              </Text.BodyText4>
              <Text.BodyText4 tag="div" color="grey70">
                {body}
              </Text.BodyText4>
            </div>
          </div>
          <div className={`${isMobile ? 'd-flex flex-column pt-s' : ''}`}>
            <Button
              kind={Kind.SECONDARY}
              className={`h-100 ${isMobile ? 'w-100' : ''}`}
              onClick={() => window.open(captureRateImportUrl)}
            >
              {t('how_to_import_data')}
            </Button>
            <Button
              className={`h-100 ${isMobile ? 'w-100 mt-xs' : 'ml-xs'}`}
              disabled={isProcessing}
              onClick={onImportOpen}
              isLoading={isImporterLoading}
            >
              {t('upload_csv')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CsvUpload
