import useSteps from 'hooks/useSteps'
import React from 'react'
import ContinueButton from 'scenes/CampaignCenter/Builder/components/ContinueButton'
import TopBar from 'scenes/CampaignCenter/Builder/components/TopBar'
import Incentive from 'scenes/CampaignCenter/Builder/Steps/Shared/Incentive'
import Messaging from 'scenes/CampaignCenter/Builder/Steps/Shared/Messaging'

import type { Fields as Campaign } from 'models/Campaign'

type Props = {
  campaign: Campaign
  isSaving: boolean
  canProceed: boolean
  triggerSave: () => Promise<void>
  setCurrentStep: (step: string) => void
  showValidationErrors: () => void
}

// TODO: MRC-1661 - Remove this file once `a-b-one-time` flag is fully enabled

const Setup: React.FC<Props> = props => {
  const {
    campaign,
    isSaving,
    canProceed,
    triggerSave,
    setCurrentStep,
    showValidationErrors,
  } = props
  const { step, next } = useSteps(['incentive', 'messaging'])

  const onContinueClick = () => {
    if (step === 'messaging') {
      setCurrentStep('review')
    } else {
      next()
    }
  }

  const substep = {
    incentive: (
      <Incentive
        campaign={campaign}
        canProceed={canProceed}
        showValidationErrors={showValidationErrors}
        isSaving={isSaving}
        triggerSave={triggerSave}
      />
    ),
    messaging: (
      <Messaging
        campaign={campaign}
        canProceed={canProceed}
        showValidationErrors={showValidationErrors}
      />
    ),
  }[step]

  return (
    <>
      <TopBar triggerSave={triggerSave} campaign={campaign}>
        <ContinueButton onClick={onContinueClick} />
      </TopBar>

      <div className="container">{substep}</div>
    </>
  )
}

export default Setup
