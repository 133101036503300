import { Switch } from '@thanx/uikit/switch'
import preview_image from 'assets/images/campaign-center/landing/overview/preview.png'
import Spacer from 'components/Spacer'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import numeral from 'numeral'
import qs from 'qs'
import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import InfoModal from 'scenes/CampaignCenter/components/InfoModal'

const t = buildTranslate('thanx_campaigns.landing.overview.message')

type Props = {
  numEmailSubscribers: number
  numLoyaltyMembers: number
  openModalOnLoad?: boolean
  onModalOpen: VoidFunction
  isLoading?: boolean
}

const MessageContent: React.FC<Props> = ({
  numEmailSubscribers,
  numLoyaltyMembers,
  openModalOnLoad = false,
  onModalOpen,
  isLoading,
}) => {
  const dispatch = useDispatch()
  const queryString = qs.stringify({ redirect: '/thanx_campaigns' })

  function onMessageClick() {
    dispatch(push('/thanx_campaigns/message/message'))
  }

  function prettyEmailCount(count: number): string {
    if (!count) return '0'
    return numeral(count).format('+0.[0]a').toUpperCase()
  }

  return (
    <Row className="content-box white-bkg border-radius-2 display-flex margin-left-none margin-right-none">
      <Col md={7} className="padding-extra-large margin-top-huge flex-1">
        <Row className="margin-bottom-extra-large">
          <Col xs={2} className="padding-right-small text-right">
            <Switch condition={numEmailSubscribers > 0} fallback={''}>
              <i className="fa fa-check fa-2x nephritis" />
            </Switch>
          </Col>
          <Col xs={6}>
            <p className="font-size-20 bold">
              {t('subscribers.title')}
              <span className="padding-left-medium">
                <Switch condition={numEmailSubscribers === 0} fallback={''}>
                  <Link
                    className="full-width-on-xs inline-block body-text-4 cayenne-60"
                    to={`/subscribers/setup?${queryString}`}
                  >
                    <u>{t('import_contacts')}</u>
                  </Link>
                </Switch>
              </span>
            </p>
            <p className="body-text-4 grey-50">{t('subscribers.subtitle')}</p>
          </Col>
          <Col xs={4} className="text-right">
            <p
              className={`font-size-24 margin-bottom-none ${
                numEmailSubscribers > 0 || isLoading ? '' : 'cayenne-60'
              }`}
            >
              <strong>
                {isLoading ? (
                  <Spinner isLoading />
                ) : (
                  prettyEmailCount(numEmailSubscribers)
                )}
              </strong>
            </p>
            <p className="body-text-4 grey-50">{t('recipients')}</p>
          </Col>
        </Row>
        <Row className="margin-bottom-extra-large">
          <Col xs={2} className="padding-right-small text-right">
            <Switch condition={numLoyaltyMembers > 0} fallback={''}>
              <i className="fa fa-check fa-2x nephritis" />
            </Switch>
          </Col>
          <Col xs={6}>
            <p className="font-size-20 bold">
              <strong>{t('members.title')}</strong>
            </p>
            <p className="body-text-4 grey-50">{t('members.subtitle')}</p>
          </Col>
          <Col xs={4} className="text-right">
            <p className="font-size-24 margin-bottom-none">
              <strong>
                {isLoading ? (
                  <Spinner isLoading />
                ) : (
                  prettyEmailCount(numLoyaltyMembers)
                )}
              </strong>
            </p>
            <p className="body-text-4 grey-50">{t('recipients')}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={1} xsOffset={1} className="padding-right-none">
            <hr />
          </Col>
          <Col xs={6} className="padding-left-none padding-right-none">
            <hr />
          </Col>
          <Col xs={4} className="padding-left-none">
            <hr />
          </Col>
        </Row>
        <Row className="margin-bottom-extra-large">
          <Col xs={2} />
          <Col xs={6}>
            <p className="deprecated__body-text-3">
              <strong>{t('total.title')}</strong>
            </p>
          </Col>
          <Col xs={4} className="text-right">
            <p className="deprecated__body-text-3 margin-bottom-none">
              <strong>
                {isLoading ? (
                  <Spinner isLoading />
                ) : isLoading ? (
                  <Spinner isLoading />
                ) : (
                  prettyEmailCount(numEmailSubscribers + numLoyaltyMembers)
                )}
              </strong>
            </p>
            <p className="body-text-4 grey-50">{t('individuals')}</p>
          </Col>
        </Row>
        <Spacer paddingTop="100px" />
        <div className="text-center">
          <InfoModal
            campaignType="message"
            campaignCategory="message"
            primaryButtonClick={onMessageClick}
            className="block"
            openOnInit={openModalOnLoad}
            onOpen={onModalOpen}
          >
            <Button
              bsStyle="primary"
              className="btn-huge width-66 full-width-on-xs"
            >
              {t('button_text')}
            </Button>
          </InfoModal>
        </div>
      </Col>
      <Col
        md={5}
        className="hidden-xs hidden-sm display-flex flex-align-end flex-justify-end padding-right-none"
      >
        <img src={preview_image} alt="preview" className="img-responsive" />
      </Col>
    </Row>
  )
}

export default MessageContent
