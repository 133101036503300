import compact from 'lodash/compact'
import React from 'react'
import { components } from 'react-select'

type PlaceholderProps = React.ComponentProps<typeof components.Placeholder>
type Props = PlaceholderProps & {
  getText: (count: number) => string
}

const SearchPlaceholder: React.FC<Props> = props => {
  const { getText, ...placeholderProps } = props

  const value = props.getValue()
  let count: number = 0
  if (!value) {
    count = 0
  } else if (!Array.isArray(value)) {
    count = 1
  } else {
    count = compact(value.map(option => option.value)).length
  }

  const selectedText = count <= 0 ? 'Search' : getText(count)

  return (
    <components.Placeholder {...placeholderProps}>
      <span className="grey-70">{selectedText}</span>
    </components.Placeholder>
  )
}

export default SearchPlaceholder
