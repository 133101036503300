import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Color from 'color'
import RewardImage from 'components/RewardPreviewCard/RewardImage'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'

type PropsT = {
  description: string
  showRedeemButton?: boolean
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.appearance_card'
)

const Default: React.FC<PropsT> = ({
  description,
  showRedeemButton = true,
}) => {
  const app = useSelector(selectApp)
  const color = app?.primary_color
  const hoverColor = color ? Color(color).alpha(0.1) : undefined
  const [css] = useStyletron()

  return (
    <>
      <RewardImage />
      <Text.BodyText3 className="pl-xs">{description}</Text.BodyText3>
      {showRedeemButton && (
        <Button
          type="button"
          kind="secondary"
          className={css({
            borderColor: `${color} !important`,
            color: `${color} !important`,
            position: 'absolute',
            bottom: '16px',
            right: '16px',
            textTransform: 'uppercase',
            ':hover:enabled': {
              backgroundColor: `${hoverColor} !important`,
            },
          })}
          size="mini"
        >
          <Text.BodyText5 color="inherit">{t('redeem')}</Text.BodyText5>
        </Button>
      )}
    </>
  )
}

export default Default
