import React from 'react'
import { useSelector } from 'react-redux'
import { rewardIsDisplayable } from 'scenes/RedeemManager/helpers'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import RichReward from './RichReward'

type PropsT = {
  className?: string
  description: string
  templateId: number | null
}

const RewardPreviewCard: React.FC<PropsT> = ({
  className = '',
  description,
  templateId,
}) => {
  const template = useSelector(state => selectRedeemTemplate(state, templateId))
  const showDisabled = !rewardIsDisplayable(template)

  return (
    <RichReward
      showDisabled={showDisabled}
      className={className}
      description={description}
      imageSrc={template?.images?.index_image?.small}
      width="100%"
    />
  )
}

export default RewardPreviewCard
