import useCurrentMerchantUser from 'hooks/useCurrentMerchantUser'
import React, { useEffect } from 'react'

const ZENDESK_BASE_URL = 'https://thanx.zendesk.com'

const ZendeskSSO: React.FC = () => {
  const merchantUser = useCurrentMerchantUser()

  useEffect(() => {
    if (!!merchantUser) {
      window.location.replace(
        `${ZENDESK_BASE_URL}/access/jwt?jwt=${merchantUser.zendesk_sso_jwt}`
      )
    }
  }, [])

  return null
}

export default ZendeskSSO
