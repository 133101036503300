import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}
const ComponentName: React.FC<PropsT> = props => {
  const { className, fillColor = 'currentColor' } = props
  return (
    <svg
      className={className}
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4375 4.09766C3.4375 4.50781 3.32031 4.89844 3.10547 5.25L2.32422 6.5H6.40625L5.625 5.25C5.41016 4.89844 5.3125 4.50781 5.3125 4.09766V1.1875H3.4375V4.09766ZM6.25 1.1875V4.09766C6.25 4.33203 6.30859 4.56641 6.42578 4.76172L8.53516 8.19922C8.67188 8.41406 8.75 8.64844 8.75 8.90234C8.75 9.64453 8.125 10.25 7.38281 10.25H1.34766C0.605469 10.25 0 9.64453 0 8.90234C0 8.64844 0.0585938 8.41406 0.195312 8.19922L2.30469 4.76172C2.42188 4.56641 2.5 4.33203 2.5 4.09766V1.1875H2.34375C2.07031 1.1875 1.875 0.992188 1.875 0.71875C1.875 0.464844 2.07031 0.25 2.34375 0.25H3.125H5.625H6.40625C6.66016 0.25 6.875 0.464844 6.875 0.71875C6.875 0.992188 6.66016 1.1875 6.40625 1.1875H6.25Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default ComponentName
