import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { defaultTheme } from '@thanx/uikit/theme'
import { getPointsExperiences } from 'actions/pointsExperiences'
import Info from 'components/Info'
import RewardPreviewCard from 'components/RewardPreviewCard'
import { buildTranslate } from 'locales'
import capitalize from 'lodash/capitalize'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React, { useEffect } from 'react'
import Markdown from 'react-markdown'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import {
  formatCurrency,
  getCostToBusiness,
  getDiscount,
} from 'scenes/RedeemManager/helpers'
import { RedeemTypeItem } from 'scenes/RedeemManager/types'
import { selectExperience } from 'selectors/pointsExperience'
import { useStyletron } from 'styletron-react'
import Card from './Card'

type PropsT = {
  template: RedeemTemplate
  typeItem: RedeemTypeItem | null
}

const t = buildTranslate('redeem_manager.detail.cards.reward_details')

const RewardDetailsCard: React.FC<PropsT> = ({ template, typeItem }) => {
  const { description, id, perceived_value, long_description } = template
  const [css] = useStyletron()
  const isGoldenTicket = typeItem?.key === 'golden_ticket'
  const isExperience = isGoldenTicket || typeItem?.key === 'hidden_menu'
  const pointsExperience = useSelector(selectExperience)
  const longDescription = long_description ? (
    <Markdown>{long_description || '-'}</Markdown>
  ) : null

  const dispatch = useDispatch()

  useEffect(() => {
    if (!pointsExperience) {
      dispatch(getPointsExperiences())
    }
  }, [dispatch, pointsExperience])

  const isBonusPoints = !!typeItem && typeItem.types.includes('points/static')

  return (
    <Card title={t('title')}>
      {(description || isBonusPoints) && (
        <div className={`${css({ width: '332px' })} mb-s`}>
          <p className="bold font-size-14">{t('how_it_appears')}</p>
          <Switch
            condition={!isBonusPoints}
            fallback={
              <Text.BodyText4
                tag="div"
                color="grey70"
                className={`p-m mt-xs ${css({
                  backgroundColor: '#f8f9fa',
                  border: `1px solid ${defaultTheme.colors.grey30}`,
                })}`}
              >
                {t('will_apply_instantly')}
              </Text.BodyText4>
            }
          >
            {description && (
              <RewardPreviewCard description={description} templateId={id} />
            )}
          </Switch>
        </div>
      )}
      {longDescription && (
        <Info
          className="mb-m"
          label={t('long_description')}
          value={longDescription}
        />
      )}
      {typeItem && (
        <Info
          className="mb-m"
          label={t('reward_type')}
          value={I18n.t(`redeem_manager.types.${typeItem.key}.title`)}
        />
      )}
      <Info
        className="mb-m"
        label={t('discount')}
        value={getDiscount(template.type, template.discount)}
      />
      <Switch
        condition={
          !!typeItem &&
          (typeItem.types.includes('manual/item') ||
            typeItem.key === 'golden_ticket')
        }
      >
        <Info
          className="mb-m"
          label={t('cost_to_business')}
          value={getCostToBusiness(template.type, template.discount)}
        />
        {(perceived_value || isExperience) && (
          <Info
            label={t('retail_price')}
            value={formatCurrency(perceived_value)}
          />
        )}
      </Switch>
      <Switch condition={isBonusPoints}>
        <Info
          className="mb-m"
          label={t('how_many_bonus_points', {
            currencyName: capitalize(pointsExperience?.currency_name_plural),
          })}
          value={
            <div className="d-flex align-items-center mt-xs">
              <img
                height={16}
                src={pointsExperience?.currency_primary_icon?.default}
                alt="point"
              />
              <Text.BodyText4 className="ml-xxs" color="grey70">
                {template.number_of_points}
              </Text.BodyText4>
            </div>
          }
        />
      </Switch>
    </Card>
  )
}

export default RewardDetailsCard
