import { useStyletron } from '@thanx/uikit/theme'
import Badges from 'components/Badges'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { Fields as BrandType } from 'models/Brand'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const t = buildTranslate('cms.brand.concept_assets.brand_item')
const MAX_WIDTH = '150px'

type Props = {
  brand: BrandType
}

const BrandItem: React.FC<Props> = ({ brand }) => {
  const { cover_image_urls, logo_image_urls, name } = brand
  const [css] = useStyletron()

  return (
    <Section className="p-xl mb-m">
      <p className="small-caps-1 bold">{name}</p>
      <Row className="m-0">
        <Col className="mt-s">
          <p className="font-size-4 bold">{t('logo')}</p>
          <div
            className={`${css({
              height: '186px',
            })} mt-xs d-flex flex-column justify-content-between`}
          >
            <img
              src={logo_image_urls?.small}
              alt={t('logo')}
              className={css({
                objectFit: 'cover',
                marginRight: '120px',
                width: MAX_WIDTH,
                height: '150px',
              })}
            />
            <Badges values={[t('logo_size'), t('png')]} />
          </div>
        </Col>
        <Col className="mt-s">
          <p className="font-size-4 bold">{t('cover_image')}</p>
          <div
            className={`${css({
              height: '186px',
            })} mt-xs d-flex flex-column justify-content-between`}
          >
            <img
              src={cover_image_urls?.small}
              alt={t('cover_image')}
              className={css({
                objectFit: 'cover',
                width: MAX_WIDTH,
                height: '130px',
              })}
            />
            <Badges values={[t('cover_size'), t('jpg')]} />
          </div>
        </Col>
      </Row>
    </Section>
  )
}

export default BrandItem
