import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { tooltipPercentage } from 'scenes/Reports/helpers'

const t = buildTranslate('home.metrics')

type PropsT = {
  benchmarkRate?: number
}

const Benchmark: React.FC<PropsT> = ({ benchmarkRate }) => {
  return (
    <Text.BodyText4 tag="p" className="m-0" color="grey70">
      {t('shared.benchmark', { rate: tooltipPercentage(benchmarkRate) })}
    </Text.BodyText4>
  )
}

export default Benchmark
