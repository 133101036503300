import { alert } from 'actions/flash'
import { BannerStyles, landingPageThemeApi } from 'api/landingPageTheme'
import { isError } from 'api/utils'
import ConfirmModal from 'components/ConfirmModal'
import { TabT } from 'components/Tabs/InnerTabs'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { useEffect, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDefaultLandingPageThemeSettings } from 'scenes/Cms/Content/AppHomePage/hooks'
import { selectApp } from 'selectors/app'
import AlertBanner from './AlertBanner'
import ErrorBanner from './ErrorBanner'
import { getDefaultBannerStyles, getSubmitPayload } from './helpers'
import OrderLocationBanner from './OrderLocationBanner'
import OrderPlacedBanner from './OrderPlacedBanner'

export enum Tabs {
  AlertBanner = 'alert_banner',
  LocationBanner = 'location_banner',
  OrderPlacedBanner = 'order_placed_banner',
  ErrorBanner = 'error_banner',
}

const t = buildTranslate('cms.content.banners')

export type FormModel = BannerStyles

type FormAction =
  | { type: 'RESET'; payload: FormModel }
  | { type: 'UPDATE_SETTINGS'; payload: BannerStyles }

const formReducer = (state: FormModel, action: FormAction): FormModel => {
  switch (action.type) {
    case 'RESET':
      return action.payload
    case 'UPDATE_SETTINGS':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const initialFormState: FormModel = {
  location_banner: {
    background_color: '#F26335',
    header_text_color: '#FFFFFF',
    body_text_color: '#FFFFFF',
    button_color: '#FFFFFF',
  },
  order_placed_banner: {
    background_color: '#F26335',
    header_text_color: '#FFFFFF',
    body_text_color: '#FFFFFF',
    button_color: '#FFFFFF',
  },
  error_banner: {
    background_color: '#F26335',
    body_text_color: '#FFFFFF',
    button_color: '#FFFFFF',
  },
}

const defaultBannerSettings = getDefaultBannerStyles()

export function useBanners() {
  const app = useSelector(selectApp)
  const appId = app?.id
  const dispatch = useDispatch()
  const [formModel, updateForm] = useReducer(formReducer, initialFormState)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const { data: landingPageThemeData, isFetching: isLoading } =
    landingPageThemeApi.useGetLandingPageThemeQuery(appId)
  const landingPageTheme = landingPageThemeData?.app_landing_page_theme ?? null
  const [updateLandingPageTheme, { isLoading: isFormSubmitting }] =
    landingPageThemeApi.useUpdateLandingPageThemeMutation()
  const defaultSettings = useDefaultLandingPageThemeSettings()

  function resetForm({ settings }: { settings: FormModel }) {
    updateForm({ type: 'RESET', payload: settings })
  }

  function updateSettings(settings: Partial<BannerStyles>) {
    Object.keys(settings).forEach(key => {
      updateForm({
        type: 'UPDATE_SETTINGS',
        payload: { [key]: settings[key] } as BannerStyles,
      })
    })
  }

  async function handleSubmit() {
    const payload = getSubmitPayload({
      formModel,
      landingPageTheme,
      defaultSettings,
    })

    const response = await updateLandingPageTheme({
      id: appId,
      landing_page_theme: payload,
    })

    setIsConfirmModalVisible(false)
    if (isError(response)) {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
          timeout: 5,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('submit_success'),
        timeout: 5,
      })
    )
  }

  function handleValidSubmit() {
    setIsConfirmModalVisible(true)
  }

  function handleInvalidSubmit() {
    dispatch(
      alert({
        key: 'danger',
        message: t('validation_error'),
        timeout: 5,
      })
    )
  }

  useEffect(() => {
    const landingPageThemeSettings = landingPageTheme?.settings
    if (!landingPageThemeSettings) return
    const hasExistingSettings =
      landingPageThemeSettings.location_banner.background_color

    const bannerStyles: FormModel = {
      location_banner: landingPageThemeSettings.location_banner,
      order_placed_banner: landingPageThemeSettings.order_placed_banner,
      error_banner: landingPageThemeSettings.error_banner,
    }

    const settings = hasExistingSettings ? bannerStyles : defaultBannerSettings

    resetForm({ settings })
  }, [landingPageTheme])

  const tab: TabT = {
    key: Tabs.AlertBanner,
    title: t('tab_title'),
    subtabs: [
      {
        key: Tabs.AlertBanner,
        title: t('alert_banner.subtab_title'),
        component: <AlertBanner />,
      },
      {
        key: Tabs.LocationBanner,
        title: t('location_banner.subtab_title'),
        component: (
          <Formsy
            onChange={(data: FormModel, formChanged: boolean) => {
              if (!formChanged) return
              updateSettings(data)
            }}
            onValidSubmit={handleValidSubmit}
            onInvalidSubmit={handleInvalidSubmit}
          >
            <OrderLocationBanner
              data={formModel}
              isLoading={isLoading}
              isFetching={isFormSubmitting}
            />
            <ConfirmModal
              title={t('modal.publish_changes')}
              description={t('modal.you_are_publishing')}
              confirmText={t('modal.publish')}
              isOpen={isConfirmModalVisible}
              isLoading={isFormSubmitting}
              onClose={() => setIsConfirmModalVisible(false)}
              onConfirm={handleSubmit}
            />
          </Formsy>
        ),
      },
      {
        key: Tabs.OrderPlacedBanner,
        title: t('order_placed_banner.subtab_title'),
        component: (
          <Formsy
            onChange={(data: FormModel, formChanged: boolean) => {
              if (!formChanged) return
              updateSettings(data)
            }}
            onValidSubmit={handleValidSubmit}
            onInvalidSubmit={handleInvalidSubmit}
          >
            <OrderPlacedBanner
              data={formModel}
              isLoading={isLoading}
              isFetching={isFormSubmitting}
            />
            ,
          </Formsy>
        ),
      },
      {
        key: Tabs.ErrorBanner,
        title: t('error_banner.subtab_title'),
        component: (
          <Formsy
            onChange={(data: FormModel, formChanged: boolean) => {
              if (!formChanged) return
              updateSettings(data)
            }}
            onValidSubmit={handleValidSubmit}
            onInvalidSubmit={handleInvalidSubmit}
          >
            <ErrorBanner
              data={formModel}
              isLoading={isLoading}
              isFetching={isFormSubmitting}
            />
            ,
          </Formsy>
        ),
      },
    ],
  }
  return { tab }
}
