import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useState } from 'react'
import ButtonPanel from '../ButtonPanel'
import CustomTags from './CustomTags'
import Overview from './Overview'
import TransferredPoints from './TransferredPoints'

const t = buildTranslate('users.view.profile')

type PropsT = {
  profile: UserProfile | null
}

type PanelType = 'overview' | 'custom_tags' | 'transferred_points'

const Profile: React.FC<PropsT> = ({ profile }) => {
  const [selectedPanel, setSelectedPanel] = useState<PanelType>('overview')
  const [css] = useStyletron()
  const panels: Array<PanelType> = [
    'overview',
    'custom_tags',
    'transferred_points',
  ]

  if (!profile) return null

  return (
    <TabPane state={'profile'}>
      <section
        className={`mb-l ${css({
          minHeight: 'calc(100vh - 300px)',
        })}`}
      >
        <Text.Header3 className="my-0">{t('title')}</Text.Header3>
        <div className={'d-flex mt-xl'}>
          <ButtonPanel
            selectedPanel={selectedPanel}
            onSelectPanel={setSelectedPanel}
            panels={panels.map(panel => ({
              type: panel,
              label: t(`panels.${panel}`),
            }))}
          />
          <div className={'w-100'}>
            {selectedPanel === 'overview' && <Overview profile={profile} />}
            {selectedPanel === 'custom_tags' && (
              <CustomTags profile={profile} />
            )}
            {selectedPanel === 'transferred_points' && (
              <TransferredPoints profile={profile} />
            )}
          </div>
        </div>
      </section>
    </TabPane>
  )
}

export default Profile
