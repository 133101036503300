import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import { defaultTheme } from '@thanx/uikit/theme'
import noAssetImage from 'assets/images/cms/no_asset.svg'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.brand.brand_assets')

type Props = {
  image?: string | React.ReactNode
  height?: string
  width?: string
  darkBackground?: boolean
  objectFit?: 'cover' | 'contain'
}

const ImageAsset: React.FC<Props> = props => {
  const {
    image,
    height = '150px',
    width = '150px',
    darkBackground = false,
    objectFit = 'cover',
  } = props
  const [css] = useStyletron()

  return (
    <Section
      className={`d-flex justify-content-center align-items-center
          ${css({
            backgroundColor: darkBackground
              ? defaultTheme.colors.grey30
              : 'white',
            minWidth: width,
            width,
            height,
          })}`}
    >
      <Switch condition={typeof image === 'string' || !image} fallback={image}>
        <img
          src={(image as string) || noAssetImage}
          alt={t('not_configured')}
          className={css({
            objectFit,
            width: image ? width : 'auto',
            height: image ? height : 'auto',
          })}
        />
      </Switch>
    </Section>
  )
}

export default ImageAsset
