import { FormsySelect } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { Slide } from 'api/onboardingSlideshow'
import ImagePicker from 'components/ImagePicker'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectOnboardingSlideValidation } from 'selectors/validation'
import { BlockData } from '../hooks'
import AccessibilityFields from './AccessibilityFields'
import Descriptions from './Descriptions'

type PropsT = {
  index: number
  id: string
  data: BlockData
}

const t = buildTranslate('cms.signup.onboarding_slideshow.content.block_item')

const BlockItem: React.FC<PropsT> = ({ id, data, index }: PropsT) => {
  const { image, type } = data
  const linkValidation = useSelector(selectOnboardingSlideValidation)
  const isPushNotificationEnabled = useFlag(
    'enable-notification-slide-mx',
    false
  )
  const isAuthenticationEnabled = useFlag(
    'enable-authentication-slide-mx',
    false
  )

  const blockTypes: Array<Slide['type']> = [
    'simple',
    isPushNotificationEnabled && 'push_notification',
    isAuthenticationEnabled && 'authentication',
  ].filter(Boolean)

  const options = blockTypes.map(blockType => ({
    value: blockType,
    label: t(`options.${blockType}`),
  }))

  return (
    <div>
      <p className="mb-m small-caps-2 bold">
        {t('title', { index: index + 1 })}
      </p>
      {linkValidation && (
        <ImagePicker
          className="mb-xs"
          name={`${id}.image`}
          value={image}
          validations={{
            image: true,
          }}
          validationErrors={{
            image: I18n.t('validations.is_required'),
          }}
          imageValidations={linkValidation}
        />
      )}
      <Text.BodyText4 className={'mb-l'} tag={'p'} color="grey70">
        {t('file_size_restrictions')}
      </Text.BodyText4>

      <FormsySelect
        options={options}
        value={type || options[0].value}
        zIndex={1002}
        maxDropdownHeight="200px"
        name={`${id}.type`}
      />
      <Descriptions type={type} />
      <AccessibilityFields id={id} formModel={data} />
    </div>
  )
}

export default BlockItem
