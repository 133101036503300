import { Switch } from '@thanx/uikit/switch'
import React from 'react'

type Props = {
  title: string
  description?: string
  link?: string
  linkText?: string
  className?: string
}

const Header: React.FC<Props> = props => {
  const { title, description, link, linkText, className = '' } = props
  return (
    <div className={className}>
      <div className="d-flex justify-content-between align-item align-items-center">
        <h4>{title}</h4>
        <Switch condition={!!linkText}>
          <a
            href={link}
            className="body-text-3"
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </a>
        </Switch>
      </div>
      <Switch condition={!!description}>
        <p className="font-size-4 grey-70 mt-xs">{description}</p>
      </Switch>
    </div>
  )
}

export default Header
