import { Text } from '@thanx/uikit/text'
import { ContentBlockTypes } from 'api/landingPageTheme'
import HelpIcon from 'assets/images/cms/app-home-page/help.svg'
import Book from 'components/Icons/Book'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  type: ContentBlockTypes
}

const t = buildTranslate(
  'cms.content.app_home_page.content.edit_block.content.helper_section'
)

const HelperSection = ({ type }: Props) => {
  if (type !== 'external_form') return null
  return (
    <div className="mt-l">
      <Text.BodyText4 tag="p" bold className="d-flex">
        <img src={HelpIcon} alt="help" className="mr-xs" />
        {t('help')}
      </Text.BodyText4>

      <a
        href="https://help.thanx.com/hc/en-us/articles/20632183015831-Jotform-Integration"
        target="_blank"
        rel="noreferrer"
      >
        <Text.BodyText4 tag="p" color="belizeHole50">
          {t('configure')}
          <Book className="ml-xs mb-xs" />
        </Text.BodyText4>
      </a>
    </div>
  )
}

export default HelperSection
