import Accordion from 'components/Accordion'
import { buildTranslate } from 'locales'
import { CampaignStateT } from 'models/Campaign'
import React from 'react'
import Section from 'scenes/CampaignCenter/Report/v3/Summary/Sections/Section'
import Cogs from './Cogs'
import Discount from './Discount'
import Redeemed from './Redeemed'

const t = buildTranslate('thanx_campaigns.report.v3.summary.rewards_accounting')

type Props = {
  campaignId: number
  campaignState: CampaignStateT
  redeemTypes: (string | undefined)[]
}
const RewardsAccounting: React.FC<Props> = ({
  campaignId,
  campaignState,
  redeemTypes,
}) => {
  return (
    <Accordion showText={t('accordion.show')} hideText={t('accordion.hide')}>
      <Section
        title={t('title')}
        attributionWindow={-1}
        attributionTooltip={t('attribution.tooltip')}
        subsection
        campaignId={campaignId}
        metricTypes={['unscoped_redeem']}
        campaignState={campaignState}
      >
        <Redeemed campaignId={campaignId} />
        <Discount campaignId={campaignId} />
        <Cogs campaignId={campaignId} redeemTypes={redeemTypes} />
      </Section>
    </Accordion>
  )
}

export default RewardsAccounting
