import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import { ChannelT, Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React from 'react'

const t = buildTranslate(`thanx_campaigns.builder.steps.setup.messaging`)

type PropsT = {
  campaign: Campaign
  variant: VariantOrCampaign
  channel: ChannelT
  isOpen: boolean
  isRemoving?: boolean
  onClose: () => void
  onRemove?: () => void
}

const RemoveModal: React.FC<PropsT> = ({
  channel,
  isOpen,
  isRemoving = false,
  onClose,
  onRemove,
}) => {
  return (
    <Modal
      isLoading={isRemoving}
      title={t(`${channel}.remove_modal.title`)}
      subtitle={t(`${channel}.remove_modal.description`)}
      proceedText={t(`${channel}.remove_modal.cta`)}
      isOpen={isOpen}
      onClose={onClose}
      onProceed={onRemove}
      canProceed
    />
  )
}

export default RemoveModal
