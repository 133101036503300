import { Text } from '@thanx/uikit/text'
import { storedValueApi } from 'api/storedValue'
import HeaderCell from 'components/HeaderCell'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import Row from './Row'

const t = buildTranslate('users.stored_value.transactions')

type Props = {
  profile: UserProfile
}

const Transactions = ({ profile }: Props) => {
  const { data, isLoading } = storedValueApi.useGetTransactionsQuery(
    profile.uid
  )

  const transactions = data?.transactions ?? []

  if (isLoading) {
    return <Spinner className="mt-xl text-center" isLoading size="2x" />
  }

  if (transactions.length === 0) {
    return (
      <div className="mt-xl">
        <Text.BodyText3 color="grey70">{t('empty_state')}</Text.BodyText3>
      </div>
    )
  }

  return (
    <table className="full-width mt-xl">
      <thead>
        <tr>
          <HeaderCell>{t('table.date')}</HeaderCell>
          <HeaderCell>{t('table.activity')}</HeaderCell>
          <HeaderCell key="last" />
        </tr>
      </thead>
      <tbody>
        {transactions.map(transaction => (
          <Row key={transaction.id} transaction={transaction} />
        ))}
      </tbody>
    </table>
  )
}

export default Transactions
