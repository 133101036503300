import React from 'react'

const StatusIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>status</title>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.375 2.44141C17.0703 4.00391 18.75 6.85547 18.75 9.98047C18.75 10.2733 18.7356 10.5634 18.7074 10.85H14.9864L13.1245 4.51985C12.7969 3.40589 11.2127 3.42415 10.9108 4.54537L8.3793 13.9481L7.22923 11.0729C7.05459 10.6363 6.63172 10.35 6.16148 10.35H1.25749C1.25251 10.2274 1.25 10.1042 1.25 9.98047C1.25 6.85547 2.89062 4.00391 5.625 2.44141C8.32031 0.839844 11.6406 0.839844 14.375 2.44141ZM1.40572 11.65H6.05993L7.3394 14.8487C7.74985 15.8748 9.2303 15.7878 9.51761 14.7206L12.0276 5.39763L13.7708 11.3245C13.9148 11.814 14.364 12.15 14.8741 12.15H18.4795C17.903 14.4155 16.4359 16.3639 14.375 17.5586C11.6406 19.1602 8.32031 19.1602 5.625 17.5586C3.38192 16.2768 1.87487 14.1276 1.40572 11.65ZM5 1.34766C1.875 3.14453 0 6.42578 0 9.98047C0 13.5742 1.875 16.8555 5 18.6523C8.08594 20.4492 11.875 20.4492 15 18.6523C18.0859 16.8555 20 13.5742 20 9.98047C20 6.42578 18.0859 3.14453 15 1.34766C11.875 -0.449219 8.08594 -0.449219 5 1.34766Z"
        fill="#D6DADE"
      />
    </svg>
  )
}

export default StatusIcon
