import arrowDown from 'assets/images/reports/icons/ic-arrow-down.svg'
import arrowUp from 'assets/images/reports/icons/ic-arrow-up.svg'
import React from 'react'

export type Direction = 'up' | 'down'

type Props = {
  direction: Direction
}

const TrajectoryArrow: React.FC<Props> = ({ direction }) => {
  return <img alt="arrow" src={direction === 'up' ? arrowUp : arrowDown} />
}

export default TrajectoryArrow
