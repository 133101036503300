import { useEffect, useState } from 'react'

export default function useWindowSize() {
  const { innerHeight, innerWidth } = window
  const [windowWidth, setWidth] = useState(innerWidth)
  const [windowHeight, setHeight] = useState(innerHeight)
  const [isSmall, setIsSmall] = useState(innerWidth < 992)
  const [isMedium, setIsMedium] = useState(
    innerWidth >= 992 && innerWidth < 1400
  )
  const [isLarge, setIsLarge] = useState(innerWidth >= 1400)

  useEffect(() => {
    const setMobileState = () => {
      const { innerHeight, innerWidth } = window
      setWidth(innerWidth)
      setHeight(innerHeight)
      setIsSmall(innerWidth < 992)
      setIsMedium(innerWidth >= 992 && innerWidth < 1400)
      setIsLarge(innerWidth >= 1400)
    }

    window.addEventListener('resize', setMobileState)

    return () => {
      window.removeEventListener('resize', setMobileState)
    }
  }, [])

  return { isLarge, isMedium, isSmall, windowWidth, windowHeight }
}
