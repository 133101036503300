import {
  getCampaignRequirements,
  GET_CAMPAIGN_REQUIREMENTS_FAIL,
} from 'actions/campaignRequirements'
import { alert } from 'actions/flash'
import useDispatch from 'hooks/useDispatch'
// eslint-disable-next-line no-restricted-imports
import { DebouncedFunc } from 'lodash'
import merge from 'lodash/merge'
import { Fields as Campaign } from 'models/Campaign'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import VariantSetup from 'scenes/CampaignCenter/Builder/Steps/Shared/Setup'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import { useShowV3 } from '../hooks'
import { canProceed, sharedConfigs } from './helper'
import stepConfigs from './StepConfigs'
import View from './View'

type Props = {
  campaign: Campaign
  triggerSave: DebouncedFunc<any>
  isSaving: boolean
}

const determineInitialStep = (campaign: Campaign, merchantUser, stepConfig) => {
  const campaignState = campaign.state

  if (
    campaignState === 'in_review' ||
    (merchantUser &&
      //@ts-ignore
      !merchantUser.canEditAudienceFor(campaign))
  ) {
    return 'review'
  }
  if (campaignState !== 'draft') {
    return 'success'
  }

  return stepConfig.options.initialStep || 'setup'
}

const StepManager: React.FC<Props> = props => {
  const { campaign, triggerSave, isSaving } = props
  const [steps, setSteps] = useState<any>({})
  const [currentStepName, setCurrentStepName] = useState('setup')
  const [hasRequirements, setHasRequirements] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [firstCampaign] = useState(campaign)

  const dispatch = useDispatch()
  const showV3 = useShowV3(firstCampaign?.type)
  const currentMerchantUser = useSelector(selectCurrentMerchantUser)
  const [firstCurrentMerchantUser] = useState(currentMerchantUser)
  const stepConfig = stepConfigs[sharedConfigs(firstCampaign.type)]

  useEffect(() => {
    const initialStep = determineInitialStep(
      firstCampaign,
      firstCurrentMerchantUser,
      stepConfig
    )
    let requiredSteps = stepConfig.steps.required
    if (showV3) {
      requiredSteps.setup = VariantSetup
    }

    setSteps(merge({}, stepConfig.steps.optional, requiredSteps))

    setCurrentStep(initialStep)
  }, [firstCampaign, firstCurrentMerchantUser, showV3, stepConfig])

  useEffect(() => {
    async function loadRequirements() {
      setHasRequirements(true)
      const response = await dispatch(getCampaignRequirements(campaign.id))

      if (response.type === GET_CAMPAIGN_REQUIREMENTS_FAIL) {
        const error = response.error.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_requirements_error'),
            error,
            displayDetails: false,
          })
        )
      } else {
        setHasRequirements(response.payload.data.requirements.length > 0)
      }
    }
    if (currentStepName === 'review') {
      loadRequirements()
    }
    // eslint-disable-next-line
  }, [campaign, ...(campaign.variants || []), currentStepName, dispatch])

  // transitions to the specified builder step. if on the review step, fetch
  // the outstanding campaign requirements
  function setCurrentStep(currentStepName: string) {
    setCurrentStepName(currentStepName)

    window.scrollTo(0, 0)
  }

  const canProceedResult = canProceed(isValid, currentStepName, hasRequirements)
  return (
    <View
      campaign={campaign}
      triggerSave={triggerSave}
      isSaving={isSaving}
      currentStep={steps[currentStepName]}
      setCurrentStep={setCurrentStep}
      currentStepName={currentStepName}
      canProceed={canProceedResult}
      excludeNav={stepConfig.options.disableNavFor?.[currentStepName]}
      setValid={() => setIsValid(true)}
      setInvalid={() => setIsValid(false)}
    />
  )
}

export default StepManager
