import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import type { Fields as MerchantUser } from '../models/MerchantUser'
import orm from '../models/orm'

export const selectMerchantUsers = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): MerchantUser[] => session.MerchantUser.all().toModelArray()
)

export const selectMerchantUser = createSelector(
  selectMerchantUsers,
  (_, id): number => id,
  (users, id): MerchantUser | null =>
    users.find(u => u.id === Number(id)) ?? null
)

export const selectCurrentMerchantUser = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (state: any) => state.auth.current_merchant_user_id,
  (session: any, merchantUserId): MerchantUser | null => {
    return session.MerchantUser.withId(merchantUserId)
  }
)

export const filterMerchantUsersByRoles = createSelector(
  selectMerchantUsers,
  (_, state: any) => state.auth.current_merchant_id,
  (_, merchantId, roles: string[]) => roles,
  (merchantUsers, merchantId, roles): MerchantUser[] => {
    return merchantUsers.filter(merchantUser => {
      return merchantUser.roles[merchantId]?.some(role => roles.includes(role))
    })
  }
)
