import { updateRedirect } from 'actions/redirect'
import { replace } from 'react-router-redux'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'
import { connectedReduxRedirect } from 'redux-auth-wrapper/history3/redirect'
import { selectUserIsAuthenticated } from 'selectors/auth'
import currentMerchant from 'utilities/currentMerchant'

import Loading from './components/Loading'

const userIsAuthenticatedDefaults = {
  authenticatedSelector: selectUserIsAuthenticated,
  authenticatingSelector: state =>
    !currentMerchant(state).id && !!state.auth.current_merchant_id,
  wrapperDisplayName: 'UserIsAuthenticated',
}

const userIsNotAuthenticatedDefaults = {
  authenticatedSelector: state =>
    !state.auth.current_merchant_user_id || !state.auth.current_merchant_id,
  wrapperDisplayName: 'UserIsNotAuthenticated',
}

export const userIsAuthenticated = connectedAuthWrapper(
  userIsAuthenticatedDefaults
)
export const userIsNotAuthenticated = connectedAuthWrapper(
  userIsNotAuthenticatedDefaults
)

export const userIsAuthenticatedRedirect = connectedReduxRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: Loading,
  redirectPath: state => {
    return state.auth.is_session_expired
      ? '/auth/expired_session'
      : '/auth/login'
  },
  redirectAction: newLocation => dispatch => {
    dispatch(updateRedirect(window.location.pathname))
    dispatch(replace(newLocation))
  },
})

export const userIsNotAuthenticatedRedirect = connectedReduxRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: '/',
  allowRedirectBack: false,
  redirectAction: newLocation => dispatch => {
    dispatch(replace(newLocation))
  },
})

export const userIsAdmin = connectedReduxRedirect({
  redirectPath: '/unauthorized',
  allowRedirectBack: false,
  authenticatedSelector: state => state.auth.is_admin === true,
  redirectAction: newLocation => dispatch => {
    dispatch(replace(newLocation))
  },
  wrapperDisplayName: 'userIsAdmin',
})

export const visibleOnlyLive = connectedAuthWrapper({
  authenticatedSelector: state => currentMerchant(state).state === 'live',
  wrapperDisplayName: 'VisibleOnlyLive',
})

export const visibleOnlyNotLive = connectedAuthWrapper({
  authenticatedSelector: state => currentMerchant(state).state !== 'live',
  wrapperDisplayName: 'VisibleOnlyNotLive',
})

export const merchantHasAllowedStateRedirect = connectedReduxRedirect({
  redirectPath: '/unauthorized',
  allowRedirectBack: false,
  authenticatedSelector: state =>
    ['live', 'demo', 'coming_soon'].includes(currentMerchant(state).state),
  redirectAction: newLocation => dispatch => {
    dispatch(replace(newLocation))
  },
  wrapperDisplayName: 'MerchantHasAllowedStateRedirect',
})
