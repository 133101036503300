import { Input } from '@thanx/uikit/input'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'

type Props = {
  value: string
  onChange: (value: string) => void
  className?: string
  placeholder?: string
}

const t = buildTranslate('components.search_input')

const SearchInput: React.FC<Props> = ({
  value,
  onChange,
  className = '',
  placeholder,
}) => {
  const [css] = useStyletron()

  return (
    <div className={`relative ${className}`}>
      <i
        className={`fa fa-search absolute ${css({
          top: '16px',
          left: '16px',
          color: merchantTheme.colors.grey90,
        })}`}
        aria-hidden="true"
      />
      <Input
        onChange={onChange}
        value={value}
        aria-label={t('label')}
        overrides={{
          Input: {
            style: () => ({
              paddingLeft: '48px',
              paddingRight: '48px',
            }),
          },
        }}
        placeholder={placeholder}
      />
      <Switch condition={value !== ''}>
        <button
          tabIndex={0}
          type="button"
          className={`position-absolute close ${css({
            top: '12px',
            right: '16px',
            opacity: 1,
            color: merchantTheme.colors.grey90,
            ':focus': {
              background: merchantTheme.colors.grey10,
              border: `1px solid ${merchantTheme.colors.grey90}`,
              borderRadius: '4px',
              padding: '0px 4px',
            },
            ':not(:focus)': {
              padding: '0px 4px',
            },
          })}`}
          aria-label={t('clear')}
          onClick={() => onChange('')}
        >
          &times;
        </button>
      </Switch>
    </div>
  )
}

export default SearchInput
