import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { setCurrentMerchantID } from 'actions/auth'
import { getMerchants } from 'actions/merchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectMerchants } from 'selectors/merchant'
import { merchantTheme } from 'theme'
import { rootUrl } from 'utilities/urlUtils'
import Divider from '../Bar/Divider'
import SectionHeader from '../Bar/SectionHeader'

const t = buildTranslate('nav.profile_menu.merchant_selector')

type PropsT = {
  currentMerchantId: number
  onClick?: VoidFunction
}

const MerchantSelector: React.FC<PropsT> = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { currentMerchantId, onClick } = props

  const [hasFetched, setHasFetched] = useState(false)
  const dispatch = useDispatch()
  const merchants = useSelector(selectMerchants)
  const [css] = useStyletron()

  const setCurrentMerchant = useCallback(
    async (id: number) => {
      dispatch(setCurrentMerchantID(id))
      window.location.assign(rootUrl)
    },
    [dispatch]
  )

  useEffect(() => {
    if (!hasFetched) {
      dispatch(getMerchants())
      setHasFetched(true)
    }
  }, [dispatch, hasFetched])

  if (!hasFetched || merchants.length < 2) return null
  return (
    <>
      <Divider />
      <SectionHeader text={t('title')} iconName="swap" />
      {merchants.map(merchant => {
        if (merchant.id === currentMerchantId) return null
        return (
          <button
            onClick={() => {
              setCurrentMerchant(merchant.id)
              onClick && onClick()
            }}
            key={merchant.id}
            className={`py-xs pl-xl pr-s d-flex w-100 align-items-center ${css({
              background: 'transparent',
              border: 'none',
              padding: 0,
              textAlign: 'left',
              ':hover': {
                backgroundColor: merchantTheme.colors.grey10,
                textDecoration: 'none',
              },
              ':focus': {
                backgroundColor: merchantTheme.colors.grey10,
                textDecoration: 'none',
              },
            })}`}
          >
            <img
              src={merchant.logo_image_urls.default}
              alt={`${merchant.handle}-logo`}
              className={`mr-xs ${css({ width: '16px', borderRadius: '4px' })}`}
            />
            <Text.BodyText4 tag="span" color="grey90">
              {merchant.name}
            </Text.BodyText4>
          </button>
        )
      })}
    </>
  )
}

export default MerchantSelector
