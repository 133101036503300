import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}

const Book: React.FC<PropsT> = ({ className, fillColor = 'currentColor' }) => (
  <svg
    className={className}
    width="14"
    height="16"
    viewBox="0 0 14 16"
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 4.75C4 4.90625 4.09375 5 4.25 5H10.75C10.875 5 11 4.90625 11 4.75V3.75C11 3.625 10.875 3.5 10.75 3.5H4.25C4.09375 3.5 4 3.625 4 3.75V4.75ZM4.25 7.5H10.75C10.875 7.5 11 7.40625 11 7.25V6.25C11 6.125 10.875 6 10.75 6H4.25C4.09375 6 4 6.125 4 6.25V7.25C4 7.40625 4.09375 7.5 4.25 7.5ZM13.5938 12.5C13.8125 12.4688 14 12.25 14 12V0.5C14 0.25 13.75 0 13.5 0H2.5C1.09375 0 0 1.125 0 2.5V13.5C0 14.9062 1.09375 16 2.5 16H13.5C13.75 16 14 15.7812 14 15.5V15C14 14.7812 13.8125 14.5625 13.5938 14.5312C13.4375 14.125 13.4375 12.9062 13.5938 12.5ZM12.3125 12.5C12.2188 13.0625 12.2188 13.9688 12.3125 14.5H2.5C1.9375 14.5 1.5 14.0625 1.5 13.5C1.5 12.9688 1.9375 12.5 2.5 12.5H12.3125ZM12.5 1.5V11H2.5C2.125 11 1.78125 11.0938 1.5 11.2188V2.5C1.5 1.96875 1.9375 1.5 2.5 1.5H12.5Z" />
  </svg>
)

export default Book
