import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import store from 'store'

const getState = () => {
  try {
    return store.getState()
  } catch (e) {
    return null
  }
}

export function useUserTimeZone() {
  const user = useSelector(selectCurrentMerchantUser)
  return user ? user.time_zone : moment.tz.guess()
}

// Deprecated. Use useUserTimeZone instead
export default () => {
  let state: any = getState()
  if (!state) return moment.tz.guess()
  const user = selectCurrentMerchantUser(state)
  return user ? user.time_zone : moment.tz.guess()
}
