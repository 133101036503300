import { FormsyRadioGroup, Radio } from '@thanx/uikit/radio'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { getMerchantCouponPools } from 'actions/merchantCouponPools'
import PopoverInfo from 'components/PopoverInfo'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { CouponPoolGenerationType } from 'models/CouponPool'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  isDirectLoyaltyIntegrationMerchant,
  isIndirectLoyaltyIntegrationMerchant,
} from 'scenes/RedeemManager/helpers'
import { InstoreRedemptionType } from 'scenes/RedeemManager/types'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import { useStyletron } from 'styletron-react'
import {
  customImageManualUrl,
  customImageManualVideoUrl,
} from 'utilities/urlUtils'
import { useFormModel } from '../../FormModel'
import Card from '../Card'
import CustomImageDescriptors from '../CustomImageDescriptors'
import { instoreRedemptionInfoSections } from '../helpers'
import HowTo from '../HowTo'
import NextButton from '../NextButton'
import CouponCodesForm from './CouponCodesForm'
import ThanxGeneratedEnabled from './CouponCodesForm/ThanxGeneratedEnabled'
import ImageUpload from './ImageUpload'
import Url from './Url'

type Props = {
  templateId: number
  isEnabled: boolean
  isOpen: boolean
  isCurrent: boolean
  isDraft: boolean
  isValidForm: boolean
  onNext: () => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card'
)

const InstoreRedemptionCard: React.FC<Props> = props => {
  const {
    templateId,
    isEnabled,
    isOpen,
    isCurrent,
    isDraft,
    isValidForm,
    onNext,
  } = props

  const merchant = useCurrentMerchant()
  const { model: formModel } = useFormModel()

  const {
    couponCodes,
    typeItem,
    instoreRedemptionType,
    availableOnline,
    selectedCouponPools,
  } = formModel
  const types = typeItem ? typeItem.types : []
  const hasAutomaticType =
    types.includes('automatic/amount') || types.includes('automatic/percent')
  const isBogoSubtype =
    typeItem?.subtype === 'bogo/purchase' || typeItem?.subtype === 'bogo/item'
  const displayAutoCashback =
    merchant?.automatic_capable && hasAutomaticType && !isBogoSubtype
  const displayFields = isEnabled && !merchant?.online_only

  const template = useSelector(store => selectRedeemTemplate(store, templateId))
  const hasImage = !!template?.images?.detail_image?.small
  const isGoldenTicket = typeItem?.key === 'golden_ticket'
  const isExperience = typeItem?.key === 'hidden_menu' || isGoldenTicket

  const supportsLoyaltyIntegration = !!merchant?.supports_loyalty_integration
  const hasDirectLoyaltyIntegration =
    isDirectLoyaltyIntegrationMerchant(merchant)
  const hasIndirectLoyaltyIntegration =
    isIndirectLoyaltyIntegrationMerchant(merchant)

  const dispatch = useDispatch()
  const [css] = useStyletron()

  const isCouponCode =
    instoreRedemptionType === InstoreRedemptionType.COUPON_CODES

  useEffect(() => {
    if (isCouponCode && couponCodes?.generationType) {
      dispatch(getMerchantCouponPools(couponCodes.generationType))
    }
  }, [dispatch, couponCodes?.generationType, isCouponCode])

  const isNextDisabled =
    (isCouponCode &&
      !supportsLoyaltyIntegration &&
      couponCodes.generationType === CouponPoolGenerationType.DYNAMIC) ||
    isGoldenTicket
      ? !hasImage
      : !isValidForm

  return (
    <Card
      title={t('title')}
      infoSections={instoreRedemptionInfoSections}
      isEnabled={displayFields}
      isOpen={isOpen}
      buttonName={
        !merchant?.online_only && availableOnline
          ? 'availableInstore'
          : undefined
      }
      disableToggleButton={!isDraft}
      onToggle={value => {
        if (isCurrent && !value) {
          onNext()
        }
      }}
    >
      <Switch
        condition={displayFields}
        fallback={<p className="grey-70">{t('not_redeemable')}</p>}
      >
        <FormsyRadioGroup
          // @ts-ignore
          name="instoreRedemptionType"
          value={instoreRedemptionType}
          disabled={!isDraft}
        >
          <Radio
            className="w-100"
            overrides={{
              Label: {
                style: {
                  width: '100%',
                },
              },
              Root: {
                style: {
                  alignItems: 'flex-start',
                },
              },
            }}
            value={InstoreRedemptionType.MANAGER_COMP}
            disabled={hasIndirectLoyaltyIntegration}
          >
            <Text.BodyText3 tag="p" color="grey70" className="mb-xs">
              {t('type.manager_comp')}
              <Switch condition={hasIndirectLoyaltyIntegration}>
                <PopoverInfo
                  text={t('manager_comp_unavailable')}
                  className="ml-xs"
                />
              </Switch>
            </Text.BodyText3>
            <Text.BodyText4 tag="p" color="grey70" className="mb-xs">
              {t('type.manager_comp_info')}
            </Text.BodyText4>

            {isExperience && (
              <>
                <CustomImageDescriptors
                  className="mb-s"
                  mainDescriptor={t('custom_img_descriptor_1')}
                  linkDescriptor={t('custom_img_descriptor_2', {
                    url: customImageManualVideoUrl,
                  })}
                />
                <ImageUpload
                  existingId={template?.images.detail_image?.file_upload_id}
                  templateId={templateId}
                />
                <Text.BodyText4 className="mb-s" tag="div" color="grey70">
                  {t('image_crop')}
                </Text.BodyText4>
                <HowTo
                  className="width-100 mb-m"
                  type="instore"
                  imageLink={customImageManualUrl}
                  videoLink={customImageManualVideoUrl}
                />
              </>
            )}
            <Switch condition={isGoldenTicket}>
              <Url url={template?.url} />
            </Switch>
          </Radio>
          <Radio
            disabled={isExperience}
            value={InstoreRedemptionType.COUPON_CODES}
          >
            {t('type.coupon_codes_enforced_by_pos')}
            <Switch condition={isExperience}>
              <PopoverInfo
                text={t('coupon_codes_enforced_by_pos_unavailable')}
                className="ml-xs"
              />
            </Switch>
          </Radio>
          <Switch
            condition={
              instoreRedemptionType === InstoreRedemptionType.COUPON_CODES
            }
          >
            <Switch
              condition={supportsLoyaltyIntegration}
              fallback={
                <>
                  <CouponCodesForm
                    selectedCouponPools={selectedCouponPools}
                    template={template}
                    className={css({ cursor: 'auto' })}
                  />
                </>
              }
            >
              <ThanxGeneratedEnabled
                isDirect={hasDirectLoyaltyIntegration}
                typeItem={typeItem}
                template={template}
              />
            </Switch>
          </Switch>
          <Radio
            value={InstoreRedemptionType.CASH_BACK}
            disabled={!displayAutoCashback}
          >
            {t('type.automatic_cash_back')}
            <Switch condition={!displayAutoCashback}>
              <PopoverInfo
                text={t('automatic_unavailable')}
                className="ml-xs"
              />
            </Switch>
          </Radio>
        </FormsyRadioGroup>
        <Switch condition={isCurrent && !!onNext}>
          <NextButton onClick={onNext} disabled={isNextDisabled} />
        </Switch>
      </Switch>
    </Card>
  )
}

export default InstoreRedemptionCard
