import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { getCouponPools } from 'actions/couponPools'
import Info from 'components/Info'
import { buildTranslate } from 'locales'
import { CampaignConfig } from 'models/Campaign'
import { CouponPoolGenerationType } from 'models/CouponPool'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CouponCodes from 'scenes/RedeemManager/Detail/ConfigurationTab/CouponCodes'
import DynamicCouponsInfo from 'scenes/RedeemManager/Detail/ConfigurationTab/DynamicCouponsInfo'
import { selectCouponPoolsByTemplateId } from 'selectors/couponPool'

type PropsT = {
  config: CampaignConfig
  template?: RedeemTemplate
}

const t = buildTranslate('redeem_manager.detail.cards.instore_redemption')

const Instore: React.FC<PropsT> = (props: PropsT) => {
  const { config, template } = props
  const { redeem_type, redeem_uses_coupon_codes } = config
  const notRedeemable = config.redeem_venue === 'online'
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const couponPools = useSelector(state => {
    if (template?.id) {
      return selectCouponPoolsByTemplateId(state, template.id)
    }
  })

  useEffect(() => {
    async function fetchData() {
      if (template?.id) {
        await dispatch(getCouponPools(template.id))
      }
    }

    if (template?.uses_coupon_codes && !couponPools?.length) {
      fetchData()
    }
  }, [template, couponPools, dispatch])

  if (notRedeemable) return <div className="mb-l">{t('not_available')}</div>

  const isGoldenTicket = redeem_type === 'experience/golden_ticket'
  const isHiddenMenu = redeem_type === 'experience/hidden_menu'
  const content = (() => {
    if (redeem_type.includes('automatic')) {
      return t('automatic_cash_back')
    } else if (isGoldenTicket || isHiddenMenu) {
      return (
        <>
          <Text.BodyText4 color="grey70" tag="div">
            {t('manager_comp')}:
          </Text.BodyText4>
          <Text.BodyText4 color="grey70" tag="div">
            {t('golden_ticket_info')}
          </Text.BodyText4>
          <Text.BodyText4 color="grey70" tag="div">
            {t('manager_comp_info')}
          </Text.BodyText4>
        </>
      )
    } else {
      return t('manager_comp')
    }
  })()

  if (redeem_type === 'points/static') {
    return (
      <Text.BodyText4 color="grey70" tag="div">
        {t('will_apply_instantly')}
      </Text.BodyText4>
    )
  }

  return (
    <Switch
      condition={!redeem_uses_coupon_codes}
      fallback={
        template && (
          <Switch
            condition={
              couponPools?.[0]?.generation_type ===
              CouponPoolGenerationType.DYNAMIC
            }
            fallback={
              <CouponCodes template={template} displayUploadForm={false} />
            }
          >
            <DynamicCouponsInfo template={template} />
          </Switch>
        )
      }
    >
      <Info label={t('redemption')} value={content} />
      <Switch condition={!!template?.images?.detail_image?.small}>
        <Info
          className="mt-m"
          label={t('redemption_image.title')}
          value={
            <img
              alt={template?.description!}
              className={css({
                width: '200px',
                height: 'auto',
              })}
              src={template?.images?.detail_image?.small}
            />
          }
        />
        <Switch condition={isGoldenTicket}>
          <Info
            className="mt-m"
            label={t('url.title')}
            value={
              <Text.BodyText4 color="grey70">
                {template?.url ? template?.url : t('url.no_url')}
              </Text.BodyText4>
            }
          />
        </Switch>
      </Switch>
    </Switch>
  )
}

export default Instore
