import { Text } from '@thanx/uikit/text'
import React from 'react'

type Props = {
  className?: string
  text: string
}

const Title: React.FC<Props> = ({ text, className = '' }) => (
  <Text.BodyText4 className={className} tag="div" bold>
    {text}
  </Text.BodyText4>
)

export default Title
