import { Fields } from 'models/LandingHeader'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'

export const selectLandingHeaders = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.LandingHeader.all().toRefArray()
  }
)
