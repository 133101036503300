import type { Fields as Campaign } from 'models/Campaign'
import React, { useState } from 'react'
import EditableName from 'scenes/CampaignCenter/Builder/components/EditableName'
import AudienceContainer from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Audience'
import ContentContainer from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Content'
import Header from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Header'
import ScheduleContainer from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Schedule'

type Props = {
  campaign: Campaign
  setCurrentStep: (step: string) => void
  audience?: any
  children?: React.ReactNode | string
  shouldShowEndDate?: boolean
}

const Review: React.FC<Props> = ({
  children,
  campaign,
  setCurrentStep,
  audience = null,
  shouldShowEndDate = false,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const addSetIsOpenProps = Component => {
    if (!!Component) return React.cloneElement(audience, { setIsOpen })
    return Component
  }

  return (
    <div className="container thanx_campaigns_review pb-xl">
      <Header campaign={campaign} />
      <div className="margin-top-large">
        <EditableName
          campaignId={campaign.id}
          className="my-l"
          name={campaign.name}
          type={campaign.campaign_config_type}
          styleSize="large"
          showEdit
        />
        {addSetIsOpenProps(audience) || (
          <AudienceContainer
            campaign={campaign}
            setCurrentStep={setCurrentStep}
            locationIds={campaign.config_location_ids}
            categoryIds={campaign.config_location_category_ids}
          />
        )}
        <ContentContainer
          campaign={campaign}
          setCurrentStep={setCurrentStep}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <ScheduleContainer
          campaign={campaign}
          shouldShowEndDate={shouldShowEndDate}
        />
        {children}
      </div>
    </div>
  )
}

export default Review
