import { Text } from '@thanx/uikit/text'
import { StoredValueConfig } from 'api/storedValue'
import Section from 'components/Section'
import { buildTranslate } from 'locales'

const t = buildTranslate('stored_value.stored_value_configuration.funding')

type Props = {
  config: StoredValueConfig
}

const PresetItem = ({ amount, bonus, count }) => (
  <div className="mb-m">
    <div className="mb-xs">
      <Text.BodyText4 bold>{t('increment', { count })}</Text.BodyText4>
    </div>

    <div className="mb-xs">
      <Text.BodyText4>${amount}</Text.BodyText4>
    </div>
    <div>
      <Text.BodyText4>
        ${bonus} {t('bonus')}
      </Text.BodyText4>
    </div>
  </div>
)

const Funding = ({ config }: Props) => {
  return (
    <Section className="pt-m px-m mb-m">
      <Text.Header4 className="mb-m mt-0">{t('title')}</Text.Header4>
      <div className="mb-xs">
        <Text.BodyText4>{t('description')}</Text.BodyText4>
      </div>
      {config.reload_presets.map(({ amount, bonus }, index) => (
        <PresetItem
          key={amount}
          amount={amount}
          bonus={bonus}
          count={index + 1}
        />
      ))}
    </Section>
  )
}

export default Funding
