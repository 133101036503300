import React from 'react'
import ControlGroup from './ControlGroup'
import Variant from './Variant'
import VariantWinner from './VariantWinner'

type PropsT = {
  className?: string
  variantLetter: string
  color?: string
  paddingLeft?: string
  paddingRight?: string
  isControl?: boolean
  isWinner?: boolean
}

const Variants: React.FC<PropsT> = props => {
  const { isControl, isWinner, ...remainingProps } = props
  if (isControl) return <ControlGroup {...remainingProps} />
  if (isWinner) return <VariantWinner {...remainingProps} />
  return <Variant {...remainingProps} />
}

export default Variants
