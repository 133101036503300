import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import cohortImage from 'assets/images/reports/cohort_chart.svg'
import { buildTranslate } from 'locales'
import React from 'react'

type PropsT = {
  title: string
  subtitle: string
}

const t = buildTranslate('reports')

const CohortStub: React.FC<PropsT> = ({ title, subtitle }) => {
  const [css] = useStyletron()

  return (
    <div className="mt-xxl">
      <Text.Header3 bold className="mt-0 mb-xs">
        {title}
      </Text.Header3>
      <Text.BodyText3>{subtitle}</Text.BodyText3>
      <div className={`mt-m`}>
        <img
          className={`${css({ width: '100%' })}`}
          src={cohortImage}
          alt={t('coming_soon')}
        />
      </div>
    </div>
  )
}

export default CohortStub
