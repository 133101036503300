import useSteps from 'hooks/useSteps'
import React from 'react'

type InjectedProps = {
  currentStepIndex: number | void
  currentStepName: string | void
  nextStep: (() => void) | void
  prevStep: (() => void) | void
  stepByIndex: ((stepIndex: number) => void) | void
  stepByName: ((stepName: string) => void) | void
}

/*
 * HOC that adds basic step wizard functionality.
 * Ex.
 *   export default withSteps(Setup)
 *
 * The Component will receive the following props to control
 * the step flow.
 *  currentStepIndex: number | void,
 *  currentStepName: string | void,
 *  nextStep: (() => void) | void,
 *  prevStep: (() => void) | void,
 */
const withSteps =
  (stepNames: string[]) =>
  <T extends object>(
    WrappedComponent: React.ComponentType<T>
  ): React.ComponentType<T & InjectedProps> => {
    const StepComponent: React.ComponentType<T & InjectedProps> = (
      props: T
    ) => {
      const { step, stepIndex, next, prev, setStep, setStepByIndex } =
        useSteps(stepNames)

      return (
        <WrappedComponent
          currentStepIndex={stepIndex}
          currentStepName={step}
          prevStep={prev}
          nextStep={next}
          stepByIndex={setStepByIndex}
          stepByName={setStep}
          {...props}
        />
      )
    }

    return StepComponent
  }

export default withSteps
