import { updateCampaignVariant } from 'actions/campaignVariants'
import useDispatch from 'hooks/useDispatch'
import pickBy from 'lodash/pickBy'
import startsWith from 'lodash/startsWith'
import { VariantOrCampaign } from 'models/CampaignVariant'
import { getMeta } from 'models/CampaignVariant/helpers'
import { Fields as NotifyEmail, VariantNotifyEmail } from 'models/NotifyEmail'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { selectAllNotifyEmails } from 'selectors/notifyEmail'

export const useShowV3 = (type: string) => {
  return type === 'custom' || type === 'message' || type === 'custom_automated'
}

export const useCopyEmail = (
  target: VariantOrCampaign,
  targetCampaignId: number
) => {
  const dispatch = useDispatch()

  const copyEmail = useCallback(
    async (source: VariantOrCampaign | NotifyEmail | null) => {
      if (!source) return

      // Grab all the notify_email fields from the selected variant or campaign
      // EXCEPT the email subject and preview as we don't want to copy those
      const copiedFields = pickBy(
        source,
        (_, key) =>
          startsWith(key, 'notify_email') &&
          key !== 'notify_email_subject' &&
          key !== 'notify_email_preview_text'
      )

      // We need to re-set the redeem info, to avoid redeem template to
      // be removed from the target
      // Also keep the original subject and preview
      const updatedFields = {
        ...copiedFields,
        notify_email_subject: target.notify_email_subject,
        notify_email_preview_text: target.notify_email_preview_text,
        redeem_template_id: target.redeem_template_id,
        redeem: target.redeem,
      }
      dispatch(
        updateCampaignVariant(targetCampaignId, target.id, updatedFields)
      )
    },
    [dispatch, targetCampaignId, target]
  )

  return { copyEmail }
}

export function useNotifyEmailVariantMeta() {
  const notifyEmails = useSelector(state => selectAllNotifyEmails(state))

  const getEmailVariantMeta = useCallback(
    (notifyEmailVariant: VariantNotifyEmail) => {
      const campaignNotifyEmails = notifyEmails.filter(
        notifyEmail =>
          notifyEmail.campaign_id === notifyEmailVariant.campaign_id
      )

      // when campaign only has one variant, we only need to show the campaign name
      if (campaignNotifyEmails.length === 1) return null

      const index = campaignNotifyEmails.findIndex(
        notifyEmail =>
          notifyEmail.type === 'variant' &&
          notifyEmail.variant_id === notifyEmailVariant.variant_id
      )

      if (index === -1) return null

      return getMeta(
        notifyEmailVariant.variant_type,
        notifyEmailVariant.variant_name,
        index
      )
    },
    [notifyEmails]
  )

  return { getEmailVariantMeta }
}
