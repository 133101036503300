import {
  GET_CAMPAIGN_TYPES_SUCCESS,
  GET_CAMPAIGN_TYPE_SUCCESS,
  ResolvedAction,
} from 'actions/campaignTypes'
import { RESET_DB } from 'actions/orm'
import { attr, Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  category: string
  name: string
  placeholders: Placeholders
  redeem_required: boolean
  state: string
  subcategory: string
  type: string
  unavailable_days: number
  unlayer_enabled: boolean

  isEnabled(): boolean
}

export interface Placeholders {
  notify_email_subject: string
  notify_email_preview_text: string
  notify_email_heading: string
  notify_email_body: string
  notify_push_text: string
  notify_sms_text: string
  notify_email_button_text: string
  notify_email_button_url: string
}

export default class CampaignType extends Model<typeof CampaignType, Fields> {
  static modelName: string = 'CampaignType'
  static options: Object = {
    idAttribute: 'type',
  }

  static get fields(): any {
    return {
      type: attr(),
      state: attr(),
      unlayer_enabled: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    CampaignType: ModelType<CampaignType>
  ) {
    switch (action.type) {
      case GET_CAMPAIGN_TYPES_SUCCESS:
        action.payload.data.campaign_types.forEach(campaignType =>
          CampaignType.upsert(campaignType)
        )
        break
      case GET_CAMPAIGN_TYPE_SUCCESS:
        CampaignType.upsert(action.payload.data.campaign_type)
        break
      case RESET_DB:
        CampaignType.all().delete()
        break
      default:
        break
    }
  }

  isEnabled(): boolean {
    // @ts-ignore
    return ['available', 'restricted'].includes(this.state)
  }
}
