import { FormsySelect } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { getCampaignMetricByType } from 'actions/campaignMetrics'
import { updateCampaign } from 'actions/campaigns'
import { updateCampaignVariant } from 'actions/campaignVariants'
import PopoverInfo from 'components/PopoverInfo'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { Type as MetricType, UserCountMetric } from 'models/CampaignMetric'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { useAbility } from 'utilities/ability'
import CouponCodesModal from '../CouponCodesModal'
import SelectEmpty from '../SelectEmpty'
import WarningModal from '../WarningModal'
import SelectItem from './SelectItem'

type PropsT = {
  templates: RedeemTemplate[]
  campaign: Campaign
  onChange: (value: number) => void
  isSomeTemplatesHidden: boolean
}

type OptionT = {
  value: number | null
  label: React.ReactNode
  disabled?: boolean
}

const t = buildTranslate('thanx_campaigns.builder.steps.incentive.template')

const Select: React.FC<PropsT> = (props: PropsT) => {
  const { templates, campaign, onChange, isSomeTemplatesHidden } = props

  const { config } = useCampaignConfig()
  const [showModal, setShowModal] = useState(false)
  const [activeRedeemTemplate, setActiveRedeemTemplate] = useState<
    number | null
  >(null)
  const isCustomAutomated = campaign.type === 'custom_automated'
  const ability = useAbility()

  const dispatch = useDispatch()
  const userCounts = useSelector(
    state =>
      selectCampaignMetricByType(
        state,
        campaign.id,
        MetricType.USER_COUNT
      ) as UserCountMetric | null
  )

  useEffect(() => {
    // We don't need to fetch user counts if there's no templates with coupon codes
    const usesCouponCodes = templates.some(t => t.uses_coupon_codes)
    if (!userCounts && usesCouponCodes) {
      dispatch(getCampaignMetricByType(campaign.id, 'user_count'))
    }
  }, [userCounts, campaign.id, dispatch, templates])

  const options: OptionT[] = templates.map(template => {
    // There's a concious decision here to enable all templates while the user counts are loading.
    // Normally they will already be loaded, but we don't want to block rendering if loading is slow.
    const {
      uses_coupon_codes,
      uses_variable_codes,
      available_coupon_code_count,
      name,
      id,
    } = template
    const userCount = userCounts?.data?.count ?? 0

    // On the automated campaigns we should not have the low coupon pool warning and warning modal.
    const notEnoughCodes =
      uses_coupon_codes &&
      uses_variable_codes &&
      available_coupon_code_count < userCount &&
      !isCustomAutomated

    return {
      label: (
        <SelectItem
          hasWarning={notEnoughCodes}
          onClick={() =>
            notEnoughCodes &&
            config.redeem_template_id !== id &&
            setActiveRedeemTemplate(id)
          }
        >
          {name}
        </SelectItem>
      ),
      value: id,
      disabled: notEnoughCodes,
    }
  })
  const hasDisabledOptions = options.some(opt => opt.disabled)
  const hasEnabledOptions = options.some(opt => !opt.disabled)

  let placeholder =
    templates.find(template => template.id === config.redeem_template_id)
      ?.name ?? templates[0]?.name
  if (!hasEnabledOptions) {
    placeholder = t('no_enabled_templates_placeholder')
  }

  if (ability.can('manage', 'RedeemManager')) {
    options.push({
      value: null,
      disabled: true,
      label: (
        <SelectItem onClick={() => setShowModal(true)}>
          <em className="belize-hole">{t('new')}</em>
        </SelectItem>
      ),
    })
  }

  if (templates.length === 0) {
    return (
      <div className="w-100">
        <SelectEmpty />
      </div>
    )
  }

  function updateRedeemTemplate() {
    if (config.campaign_config_type === 'campaign') {
      dispatch(
        updateCampaign(campaign.id, {
          campaign: {
            ...campaign,
            redeem_template_id: activeRedeemTemplate,
          },
        })
      )
    } else {
      dispatch(
        updateCampaignVariant(campaign.id, config.id, {
          ...config,
          redeem_template_id: activeRedeemTemplate,
        })
      )
    }
    setActiveRedeemTemplate(null)
  }

  return (
    <>
      <div className="w-100">
        <FormsySelect
          // @ts-ignore
          name="redeem_template_id"
          placeholder={placeholder}
          value={
            config.redeem_template_id ?? templates[templates.length - 1]?.id
          }
          options={options}
          onChange={onChange}
          overrides={{
            Dropdown: {
              style: {
                height: '400px',
              },
            },
            OptionContent: {
              style: ({ $theme, $selected }) => {
                return {
                  color: $theme.colors.grey70,
                  backgroundColor: $selected
                    ? $theme.colors.grey10
                    : 'transparent',
                  fontWeight: 'normal',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  cursor: 'pointer',
                }
              },
            },
            SingleValue: {
              style: () => {
                return {
                  flex: 1,
                }
              },
            },
          }}
        />
        <Switch condition={hasDisabledOptions}>
          <div className="mt-xs">
            <span className="body-text-4 grey-70 mr-xs">
              {t('not_enough_codes')}
            </span>
            <PopoverInfo text={t('not_enough_codes_info')} />
          </div>
        </Switch>
        <Switch condition={isSomeTemplatesHidden}>
          <div className="mt-xs">
            <span className="body-text-4 grey-70 mr-xs">
              {t('some_templates_hidden')}
            </span>
            <PopoverInfo text={t('some_templates_hidden_info')} />
          </div>
        </Switch>
      </div>

      <WarningModal show={showModal} onClose={() => setShowModal(false)} />
      {activeRedeemTemplate && (
        <CouponCodesModal
          show={true}
          onClose={() => setActiveRedeemTemplate(null)}
          onSelect={() => updateRedeemTemplate()}
          templateId={activeRedeemTemplate}
        />
      )}
    </>
  )
}

export default Select
