import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import RewardIcon from 'assets/images/campaign-center/builder/img_reward.svg'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import RestrictionSummary from 'scenes/CampaignCenter/Builder/components/Incentive/RestrictionSummary'
import { useVariant } from 'scenes/CampaignCenter/Builder/components/VariantContext'

type IncentiveProps = {
  campaign: Campaign
}

const IncentiveContent: React.FC<IncentiveProps> = ({ campaign }) => {
  const { config } = useCampaignConfig()
  const variant = useVariant()
  const variantConfig = variant || config

  return (
    <>
      <Text.SmallCaps1 tag="div" bold className="d-flex align-items-center">
        <img src={RewardIcon} alt="reward" />
        <Translate
          value="thanx_campaigns.review.incentive.title"
          className="ml-xs"
        />
      </Text.SmallCaps1>
      <Text.BodyText3 tag="div" className="mb-m mt-xs" color="grey70">
        {variantConfig.redeem_description}
      </Text.BodyText3>
      <div className="grey-70">
        <RestrictionSummary
          campaign={campaign}
          redeemType={variantConfig?.redeem_type}
          titleColor="grey-80"
        />
      </div>
      <Switch condition={!!variantConfig.fine_print}>
        <div className="margin-top-large">
          <Translate
            tag="div"
            value="thanx_campaigns.review.incentive.fine_print.title"
            className="bold grey-80 font-size-14 mb-xs"
          />
          <div className="grey-70 font-size-14">{variantConfig.fine_print}</div>
        </div>
      </Switch>
    </>
  )
}

export default IncentiveContent
