import { RESET_DB } from 'actions/orm'
import {
  CREATE_SUCCESS,
  DELETE_SUCCESS,
  GET_ALL_SUCCESS,
  GET_ONE_SUCCESS,
  ResolvedAction,
  UPDATE_SUCCESS,
} from 'actions/redeemTemplates'
import { fk, Model, ModelType } from 'redux-orm'
import { ImageUrlsT } from 'utilities/types'

export type RedeemTemplateState = 'published' | 'unpublished' | 'archived'

export type RedeemType =
  | 'manual/item'
  | 'manual/amount'
  | 'manual/percent'
  | 'automatic/amount'
  | 'automatic/percent'
  | 'experience/hidden_menu'
  | 'experience/golden_ticket'
  | 'points/static'

export type RedeemTemplateSubtype =
  | 'item'
  | 'purchase'
  | 'bogo/purchase'
  | 'bogo/item'
  | null

export type RedeemProductIdsType = {
  discounted?: string[]
  required?: string[]
}

export type RedeemProductIdsVenue = {
  digital?: RedeemProductIdsType
  instore?: RedeemProductIdsType
}

export type RedeemProductIds = {
  providerIds?: Record<string, RedeemProductIdsVenue>
  digital?: RedeemProductIdsType
  instore?: RedeemProductIdsType
}

export type RedemptionVenue = 'all' | 'instore' | 'online'

export type Images = {
  advertising_image?: ImageUrlsT
  index_image?: ImageUrlsT
  detail_image?: ImageUrlsT
}

type Upload = {
  id: number | null
  destroy?: boolean
}

export interface Fields {
  id: number
  name: string
  description: string | null
  type: RedeemType
  subtype: RedeemTemplateSubtype
  state: RedeemTemplateState
  redemption_venue: RedemptionVenue
  discount: number | null
  perceived_value: number | null
  discounted_product_ids?: string[]
  required_product_ids?: string[]
  granted_program_visible: boolean
  merchant_id: number
  created_at: string
  archived_at: string | null
  uses_coupon_codes: boolean
  uses_variable_codes: boolean
  available_coupon_code_count: number
  total_coupon_code_count: number
  unhides_category_ids?: number[]
  category_name?: string
  maximum?: number
  file_upload?: Upload
  images: Images
  url?: string
  long_description: string | null
  number_of_points?: number
  points_experience_id?: number
  product_ids?: RedeemProductIds
  coupon_pool_ids?: number[]
}

export default class RedeemTemplate extends Model<
  // @ts-ignore
  typeof RedeemTemplate,
  Fields
> {
  static modelName: string = 'RedeemTemplate'

  static get fields(): any {
    return {
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'redeemTemplates',
      }),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    RedeemTemplate: ModelType<RedeemTemplate>
  ): void {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_ALL_SUCCESS:
        action.payload.data.redeem_templates.forEach(template => {
          RedeemTemplate.upsert(template)
        })
        break
      case CREATE_SUCCESS:
      case GET_ONE_SUCCESS:
      case UPDATE_SUCCESS:
        RedeemTemplate.upsert(action.payload.data.redeem_template)
        break
      case DELETE_SUCCESS: {
        const template = RedeemTemplate.withId(
          action.meta.previousAction.templateId
        )
        if (template) template.delete()
        break
      }
      case RESET_DB:
        RedeemTemplate.all().delete()
        break
      default:
        break
    }
  }
}
