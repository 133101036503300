import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import PopoverLearnMore from 'components/PopoverLearnMore'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import {
  hasDiscount,
  notAvailable,
} from 'scenes/CampaignCenter/Report/v3/helpers'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { currencyFormatter } from 'utilities/formatters'
import { aboutRewardDiscountsAndCosts } from 'utilities/urlUtils'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
  redeemTypes: (string | undefined)[]
}

const Discount: React.FC<PropsT> = props => {
  const { campaignId, redeemTypes } = props

  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'redeem')
    ) as RedeemMetric) || {}
  const primaryValue = currencyFormatter(
    metric?.data?.total_discount || 0,
    false
  )
  const secondaryValue = t('revenue.avg_discount', {
    value: currencyFormatter(metric?.data?.avg_discount || 0),
  })
  const showRedeem = redeemTypes.some(hasDiscount)
  const notAvailableContent = notAvailable()

  const { attributionWindow } = useAttributionWindow()

  const sections = useFlag('DATA-633', false)
    ? [
        {
          content: t('revenue.tooltips.total_discount', {
            count: attributionWindow,
          }),
        },
        {
          content: <PopoverLearnMore url={aboutRewardDiscountsAndCosts} />,
        },
      ]
    : [
        {
          content: t('revenue.tooltips.number_of_discount', {
            count: attributionWindow,
          }),
        },
        {
          title: t('how_its_calc'),
          content: t('revenue.tooltips.calc_of_number_discount'),
        },
        {
          title: t('example'),
          content: t('revenue.tooltips.number_discount_example_content1'),
        },
        {
          content: t('revenue.tooltips.number_discount_example_content2'),
        },
        {
          content: t('revenue.tooltips.number_discount_example_content3'),
        },
        {
          content: t('revenue.tooltips.number_discount_example_content4'),
        },
      ]
  const primaryContent = showRedeem ? (
    <Metric metric={primaryValue}>
      <PopoverContent sections={sections} />
    </Metric>
  ) : (
    notAvailableContent.primaryContent
  )
  const secondaryContent = showRedeem ? (
    <Metric primary={false} metric={secondaryValue}>
      <PopoverContent
        sections={[
          {
            content: t('revenue.tooltips.per_of_discount', {
              count: attributionWindow,
            }),
          },
          {
            title: t('how_its_calc'),
            content: t('revenue.tooltips.calc_of_per_discount'),
          },
        ]}
      />
    </Metric>
  ) : (
    notAvailableContent.secondaryContent
  )
  return (
    <InfoRow
      title={t('revenue.discount')}
      primaryContent={primaryContent}
      secondaryContent={secondaryContent}
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default Discount
