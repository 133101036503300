import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { CampaignStateT } from 'models/Campaign'
import { SignupMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'
import Section from '../Section'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type Props = {
  campaignId: number
  campaignState: CampaignStateT
}
const Signups: React.FC<Props> = ({ campaignId, campaignState }) => {
  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'signup')
    ) as SignupMetric) || {}

  return (
    <Section
      title={t('signups.title')}
      attributionWindow={-1}
      attributionTooltip={t('signups.attribution.tooltip')}
      campaignId={campaignId}
      metricTypes={['signup']}
      campaignState={campaignState}
    >
      <InfoRow
        title={t('signups.signed_up')}
        primaryContent={
          <Metric metric={percentageFormatter(metric?.data?.rate, 1, 1)}>
            <PopoverContent
              sections={[
                {
                  content: t('signups.tooltips.signup_rate'),
                },
                {
                  title: t('how_its_calc'),
                  content: t('signups.tooltips.signup_rate_calc'),
                },
                {
                  title: t('example'),
                  content: t('signups.tooltips.signup_rate_example_content1'),
                },
              ]}
            />
          </Metric>
        }
        secondaryContent={
          <Metric primary={false} metric={numberFormatter(metric?.data?.count)}>
            <PopoverContent
              sections={[
                {
                  content: t('signups.tooltips.signup_count'),
                },
                {
                  title: t('how_its_calc'),
                  content: t('signups.tooltips.signup_count_calc'),
                },
                {
                  title: t('example'),
                  content: t('signups.tooltips.signup_count_example_content1'),
                },
                {
                  content: t('signups.tooltips.signup_count_example_content2'),
                },
              ]}
            />
          </Metric>
        }
        isErrored={metric.isErrored}
        isLoading={metric.isLoading}
      />{' '}
    </Section>
  )
}

export default Signups
