import { Text } from '@thanx/uikit/text'
import EditNavbar from 'components/Navbar/EditNavbar'
import { buildTranslate } from 'locales'
import { ChannelT, Fields as Campaign } from 'models/Campaign'
import { Fields as Variant } from 'models/CampaignVariant'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import ContentWithSave from '../Builder/components/ContentWithSave'
import RemoveModal from '../Builder/Steps/Shared/Review/components/Channels/RemoveModal'
import RemoveButton from './RemoveButton'

const t = buildTranslate(`thanx_campaigns.builder.nav`)

type PropsT = {
  campaign: Campaign
  variant: Variant
  isSaving: boolean
  messageType: ChannelT
  onContinue?: () => void
  onRemove?: () => Promise<void>
}

const EditTopBar: React.FC<PropsT> = ({
  campaign,
  variant,
  messageType,
  isSaving,
  onContinue,
  onRemove,
}) => {
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)

  function handleRemove() {
    setRemoveModalOpen(true)
  }

  async function doRemove() {
    setIsRemoving(true)
    await onRemove?.()
    setIsRemoving(false)
  }

  const name = !!variant.variant_name
    ? t('variant', { name: variant.variant_name })
    : campaign.name

  return (
    <EditNavbar
      title={
        <>
          <Text.SmallCaps3 tag="div" className="mb-xs" color="white">
            {t('config')}
          </Text.SmallCaps3>
          <Text.Header4 tag="div" bold color="white">
            {name}
          </Text.Header4>
        </>
      }
    >
      <RemoveButton messageType={messageType} onClick={handleRemove} />
      <Button
        bsSize="large"
        className="btn-inverted"
        disabled={isSaving}
        onClick={onContinue}
      >
        <ContentWithSave
          value={`thanx_campaigns.builder.nav.v3.save_button`}
          isSaving={isSaving}
        />
      </Button>
      <RemoveModal
        campaign={campaign}
        variant={variant}
        channel={messageType}
        isOpen={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        onRemove={doRemove}
        isRemoving={isRemoving}
      />
    </EditNavbar>
  )
}

export default EditTopBar
