import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import HeaderCell from 'components/HeaderCell'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { Fields as ItemPurchase } from 'models/ItemPurchase'
import { Fields as UserItem } from 'models/UserItem'
import { Fields as UserProfile } from 'models/UserProfile'
import moment from 'moment'
import React from 'react'
import { Button } from 'react-bootstrap'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { getFormattedDate } from 'utilities/date'

import { useItemPurchases } from './hooks'

const t = buildTranslate('users.view.activity.purchased_items.purchases')

type PropsT = {
  item: UserItem
  profile: UserProfile
  onOrderDetailsClick?: (order_id: number) => void
}

const ItemDetails: React.FC<PropsT> = ({
  profile,
  item,
  onOrderDetailsClick,
}) => {
  const [css] = useStyletron()
  const { isFetching, purchases, currentPage, numPages, onChangePage } =
    useItemPurchases(profile, item)

  function handleOrderDetailsClick(
    e: React.MouseEvent<HTMLElement>,
    item: ItemPurchase
  ) {
    e.preventDefault()
    onOrderDetailsClick?.(item.id)
  }

  return (
    <Spinner isLoading={isFetching}>
      <table
        className={`full-width mt-m ${css({
          borderCollapse: 'collapse',
          tableLayout: 'fixed',
        })}`}
      >
        <thead>
          <tr>
            <HeaderCell className={`${css({ width: '30%' })}`}>
              {t('date')}
            </HeaderCell>
            <HeaderCell className={`${css({ width: '30%' })} text-right`}>
              {t('order_number')}
            </HeaderCell>
            <HeaderCell className={`${css({ width: '40%' })}`} key={'last'} />
          </tr>
        </thead>
        <tbody>
          {purchases.map(item => (
            <tr key={`item_purchase_${item.id}`}>
              <BodyCellContainer
                isLoading={false}
                isErrored={false}
                className="align-middle"
              >
                <Text.BodyText4 color="grey70">
                  {getFormattedDate(moment(item.purchased_at), 'daily')}
                </Text.BodyText4>
              </BodyCellContainer>
              <BodyCellContainer
                isLoading={false}
                isErrored={false}
                className="align-middle text-right"
              >
                <Text.BodyText4 color="grey70">{item.id}</Text.BodyText4>
              </BodyCellContainer>
              <BodyCellContainer
                isLoading={false}
                isErrored={false}
                className="align-middle text-right"
              >
                <Button
                  onClick={e => handleOrderDetailsClick(e, item)}
                  bsStyle="link"
                >
                  {t('order_details')}
                </Button>
              </BodyCellContainer>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-center margin-top-huge">
        <Pagination
          currentPage={currentPage}
          numPages={numPages}
          onChangePage={onChangePage}
        />
      </div>
    </Spinner>
  )
}

export default ItemDetails
