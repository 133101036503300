import { useStyletron } from '@thanx/uikit/theme'
import lineChart from 'assets/images/home/img_chart.svg'
import chat from 'assets/images/home/img_chatBubble.svg'
import fileCheck from 'assets/images/home/img_fileCheck.svg'
import fileDollar from 'assets/images/home/img_fileDollar.svg'
import gift from 'assets/images/home/img_gift.svg'
import palette from 'assets/images/home/img_palette.svg'
import plane from 'assets/images/home/img_paperPlane.svg'
import star from 'assets/images/home/img_star.svg'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { Role } from 'models/MerchantUser'
import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import NewCampaignModal from 'scenes/CampaignCenter/Landing/NewCampaignModal'
import { selectExperience } from 'selectors/pointsExperience'
import { useIsMobile } from 'utilities/responsive'
import Button from './Button'

const t = buildTranslate('home')

type ButtonRowT = {
  roles?: Role[]
}

type ButtonT = {
  id: string
  text: string
  icon: string
  url?: string
  onClick?: () => void
}

const ButtonRow: React.FC<ButtonRowT> = ({ roles }) => {
  const pointsExperience = useSelector(selectExperience)
  const buttons: ButtonT[] = []
  const isMobile = useIsMobile()
  const isNewCampaignEnabled = useFlag('MX4-NewCampaignFlow-R2', false)

  const [showNewCampaignModal, setShowNewCampaignModal] = useState(false)
  const openNewCampaignModal = () => setShowNewCampaignModal(true)
  const closeNewCampaignModal = () => setShowNewCampaignModal(false)

  const [css] = useStyletron()
  if (roles?.includes('admin')) {
    buttons.push(
      {
        id: 'reports',
        text: t('buttons.view_campaign'),
        icon: lineChart,
        url: '/thanx_campaigns/reports',
      },
      {
        id: 'start',
        text: t('buttons.create_campaign'),
        icon: plane,
        onClick: isNewCampaignEnabled ? openNewCampaignModal : undefined,
        url: isNewCampaignEnabled ? undefined : '/thanx_campaigns/targeted',
      },
      {
        id: 'update',
        text: t('buttons.update_digital'),
        icon: palette,
        url: '/brand_content',
      }
    )
  } else if (roles?.includes('accountant')) {
    if (pointsExperience && pointsExperience.state !== 'archived') {
      buttons.push({
        id: 'points_performance',
        text: t('buttons.points_performance'),
        icon: star,
        url: '/points',
      })
    }
    buttons.push(
      {
        id: 'reward_performance',
        text: t('buttons.reward_performance'),
        icon: gift,
        url: '/rewards',
      },
      {
        id: 'billing',
        text: t('buttons.billing'),
        icon: fileDollar,
        url: '/billing',
      }
    )
  } else if (
    roles?.includes('customer_service_read') ||
    roles?.includes('customer_service_write')
  ) {
    buttons.push({
      id: 'read_feedback',
      text: t('buttons.read_feedback'),
      icon: chat,
      url: '/responses',
    })
    buttons.push({
      id: 'feedback_report',
      text: t('buttons.feedback_report'),
      icon: fileCheck,
      url: '/reports/feedback',
    })
  } else if (roles?.includes('manager') || roles?.includes('approver')) {
    buttons.push(
      {
        id: 'reports',
        text: t('buttons.view_campaign'),
        icon: lineChart,
        url: '/thanx_campaigns/reports',
      },
      {
        id: 'start',
        text: t('buttons.create_campaign'),
        icon: plane,
        onClick: isNewCampaignEnabled ? openNewCampaignModal : undefined,
        url: isNewCampaignEnabled ? undefined : '/thanx_campaigns/targeted',
      },
      {
        id: 'read_feedback',
        text: t('buttons.read_feedback'),
        icon: chat,
        url: '/responses',
      }
    )
  } else {
    return null
  }

  const styleObject = isMobile
    ? {}
    : {
        ':not(:first-child)': {
          paddingLeft: '12px',
        },
        ':not(:last-child)': {
          paddingRight: '12px',
        },
      }
  return (
    <>
      <Row className="mb-xxl">
        {buttons.map(button => (
          <Col
            className={`mb-xs ${css(styleObject)}`}
            key={button.id}
            md={true}
            sm={12}
          >
            {button.url ? (
              <Link
                className={css({
                  textDecoration: 'none !important',
                })}
                to={button.url}
              >
                <Button text={button.text} icon={button.icon} />
              </Link>
            ) : (
              <Button
                text={button.text}
                icon={button.icon}
                onClick={button.onClick}
              />
            )}
          </Col>
        ))}
      </Row>
      {isNewCampaignEnabled && (
        <NewCampaignModal
          isOpen={showNewCampaignModal}
          onClose={closeNewCampaignModal}
        />
      )}
    </>
  )
}

export default ButtonRow
