import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}

const Play: React.FC<PropsT> = ({ className, fillColor = 'currentColor' }) => (
  <svg
    className={className}
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 6.71874L2.5 0.218736C1.59375 -0.312514 0.25 0.218736 0.25 1.49999V14.5C0.25 15.6875 1.5 16.4062 2.5 15.8125L13.5 9.31249C14.4688 8.71874 14.4688 7.31249 13.5 6.71874ZM1.75 14.1875V1.84374C1.75 1.68749 1.90625 1.59374 2.03125 1.68749L12.4688 7.84374C12.5938 7.93749 12.5938 8.09374 12.4688 8.18749L2.03125 14.3437C1.90625 14.4062 1.75 14.3437 1.75 14.1875Z"
      fill={fillColor}
    />
  </svg>
)

export default Play
