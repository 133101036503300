import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { getCardImages } from 'assets/images/cards'
import { Fields as Card } from 'models/Card'
import React from 'react'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { useUserTimeZone } from 'utilities/userTimeZone'
import { formatDate } from '../helpers'
import StatusBadge from './StatusBadge'

type PropsT = {
  card: Card
}

const Row: React.FC<PropsT> = ({ card }) => {
  const timezone = useUserTimeZone()
  const createdAt = formatDate(timezone, card.created_at)
  const cardImages = getCardImages()

  const assetTypes = {
    visa: ['visa'],
    amex: ['amex'],
    mastercard: ['mastercard', 'maestro'],
    anon: ['anon', 'Card', 'unknown'],
    diners: ['diners', 'diners_club'],
    discover: ['discover'],
    jcb: ['jcb'],
  }
  let cardAsset = ''
  Object.keys(assetTypes).forEach(k => {
    if (assetTypes[k].includes(card.card_type)) {
      cardAsset = k
    }
  })

  return (
    <tr>
      <BodyCellContainer
        isLoading={false}
        isErrored={false}
        className="align-middle"
      >
        <div className="d-flex align-items-center">
          <Switch
            condition={!!cardAsset}
            fallback={
              <Text.BodyText4 color="grey70">{card.card_type}</Text.BodyText4>
            }
          >
            <img src={cardImages[cardAsset]} alt={card.card_type} width={36} />
          </Switch>
        </div>
      </BodyCellContainer>
      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className="text-right"
      >
        <Text.BodyText4 color="grey70" className="ml-s">
          {card.last4}
        </Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className="text-right"
      >
        <Text.BodyText4 color="grey70" tag="div">
          {createdAt?.day}
        </Text.BodyText4>
        <Text.BodyText5 color="grey70" tag="div">
          {createdAt?.time}
        </Text.BodyText5>
      </BodyCellContainer>
      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className="text-right"
      >
        <StatusBadge state={card.state} />
      </BodyCellContainer>
      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className="text-right"
      >
        <StatusBadge state={card.payment_state} />
      </BodyCellContainer>
    </tr>
  )
}

export default Row
