import { buildTranslate } from 'locales'
import { Fields as UserEvent } from 'models/UserEvent'
import { TransactedEvent } from 'models/UserEvent'
import { Fields as UserReward } from 'models/UserReward'

const t = buildTranslate('users.view.activity')

export function rewardIssuanceDescription(reward: UserReward): string | null {
  switch (reward.issuance_type) {
    case 'granted':
      return t('rewards.issuance_description.granted')
    case 'intro':
      return t('rewards.issuance_description.intro')
    default:
      return null
  }
}

export function getEventDescription(event: UserEvent): string | null {
  switch (event.type) {
    case 'reward_intro_delivered':
    case 'reward_ongoing_delivered':
    case 'reward_vip_delivered':
    case 'reward_used':
    case 'reward_purchased':
      return rewardIssuanceDescription(event.related_item)
    case 'transacted':
      return transactedDescription(event.related_item)
    default:
      return null
  }
}

function transactedDescription(
  item: TransactedEvent['related_item']
): string | null {
  if (item.granted) {
    return t('overview.table.manually_added')
  }

  return null
}
