import Spinner from 'components/Spinner'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { UserTypeT } from 'utilities/emailUtils'
import { UserTypeContent } from './helpers'

type Props = {
  userType: UserTypeT
  navStyle: 'tab' | 'radio'
  userContent: UserTypeContent
  width?: string
  showDescription?: boolean
}

const Preview: React.FC<Props> = ({
  navStyle,
  userType,
  userContent,
  width,
  showDescription = true,
}) => {
  let description: null | React.ReactElement = null
  if (navStyle === 'tab' && showDescription) {
    description = (
      <div className="padding-top-large padding-bottom-large text-center">
        <Translate
          className="font-size-16 grey-70"
          value={`thanx_campaigns.builder.forms.messaging_tabs.preview.tabs.${userType}.description`}
        />
      </div>
    )
  }
  return (
    <>
      {description}
      <div className="overflow-x-scroll">
        <Spinner isLoading={userContent.isLoading}>
          <div style={{ minWidth: width ?? '600px' }}>
            <div
              dangerouslySetInnerHTML={{ __html: userContent.emailHtml ?? '' }}
            />
          </div>
        </Spinner>
      </div>
    </>
  )
}

export default Preview
