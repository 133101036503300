import { RESET_DB } from 'actions/orm'
import { GET_USER_TAG_KEYS_SUCCESS, ResolvedAction } from 'actions/userTagKeys'
import { attr, fk, Model, ModelType } from 'redux-orm'

export interface Fields {
  id: string
  merchant_id: number
  value: string
  label: string
}

// @ts-ignore
export default class UserTagKey extends Model<typeof UserTagKey, Fields> {
  static modelName: string = 'UserTagKey'

  static get fields(): any {
    return {
      id: attr(),
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'userTagKeys',
      }),
      value: attr(),
      label: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    UserTagKey: ModelType<UserTagKey>
  ) {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case GET_USER_TAG_KEYS_SUCCESS:
        action.payload.data.user_tag_keys.forEach(userTagKey => {
          return UserTagKey.upsert({
            ...userTagKey,
            id: `${userTagKey.merchant_id}.${userTagKey.value}`,
          })
        })
        break
      case RESET_DB:
        UserTagKey.all().delete()
        break
      default:
        break
    }
  }
}
