import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import ContentContainer from 'components/ContentContainer'
import PopoverContent, { Sections } from 'components/PopoverContent'
import palette from 'constants/palette'
import React from 'react'
import ArrowComponent from 'scenes/Points/Landing/Activity/components/ArrowComponent'
import { merchantTheme } from 'theme'
import { numberFormatter } from 'utilities/formatters'

type PropsT = {
  label: string
  pointsValue: number
  showArrow?: boolean
  isErrored: boolean
  isLoading: boolean
  sections: Sections
}

const Row: React.FC<PropsT> = props => {
  const { label, pointsValue, showArrow, isErrored, isLoading, sections } =
    props

  const [css] = useStyletron()
  const borderClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: merchantTheme.colors.grey30,
  })

  const displayPointsValue = numberFormatter(Math.abs(Math.round(pointsValue)))

  return (
    <tr>
      <td className={`p-m ${borderClass} align-top`}>
        <ContentContainer isErrored={isErrored} isLoading={isLoading}>
          <Text.BodyText4
            bold
            color="grey70"
            className="ellipsis d-inline-block max-width-100"
          >
            {label}
          </Text.BodyText4>
        </ContentContainer>
      </td>
      <td className={`p-m ${borderClass} text-right align-top`}>
        <Text.BodyText4
          bold
          color="grey70"
          className="ellipsis d-inline-block max-width-100"
        >
          <ContentContainer isErrored={isErrored} isLoading={isLoading}>
            <span className="mr-xs">
              {showArrow && (
                <ArrowComponent
                  pointsValue={pointsValue}
                  color={palette.grey70}
                  className="align-text-bottom pr-xxs"
                />
              )}
              {displayPointsValue}
            </span>
            <PopoverContent sections={sections} />
          </ContentContainer>
        </Text.BodyText4>
      </td>
    </tr>
  )
}

export default Row
