import Switch from '@thanx/uikit/switch/Switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as TargetSegment } from 'models/TargetSegment'
import React from 'react'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'

const t = buildTranslate('segments.landing.table')

type Props = {
  segment: TargetSegment
  isLoading: boolean
  searchIsActive: boolean
  onExistingClick: (segment: TargetSegment) => void
}

const Row: React.FC<Props> = ({
  segment,
  isLoading,
  searchIsActive,
  onExistingClick,
}) => {
  return (
    <tr>
      <BodyCell
        isErrored={false}
        isLoading={isLoading}
        values={[{ value: segment.name }]}
      />
      <Switch condition={searchIsActive}>
        <BodyCell
          isErrored={false}
          isLoading={isLoading}
          values={[{ value: segment.category ?? '-' }]}
        />
      </Switch>
      <BodyCellContainer
        className="text-right"
        isErrored={false}
        isLoading={isLoading}
      >
        <span
          className="a"
          onClick={() => onExistingClick(segment)}
          role="button"
        >
          <Text.BodyText4 tag="span" className="ml-xs mb-0" color="primary">
            {t('create_campaign')}
          </Text.BodyText4>
        </span>
      </BodyCellContainer>
    </tr>
  )
}

export default Row
