import { PaginationType } from 'models/Pagination'

export const UPDATE_PAGINATION_CURRENT_PAGE = 'campaigns/UPDATE_CURRENT_PAGE'

export type UpdateCampaignsCurrentPageAction = {
  type: typeof UPDATE_PAGINATION_CURRENT_PAGE
  id: PaginationType['id']
  kind: PaginationType['type']
  page: number
}

type UpdatePaginationCurrentPageT = PaginationType & {
  page: number
}

export function updatePaginationCurrentPage({
  id,
  type,
  page,
}: UpdatePaginationCurrentPageT) {
  return {
    type: UPDATE_PAGINATION_CURRENT_PAGE,
    kind: type,
    id,
    page,
  }
}
