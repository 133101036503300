import IconMagic from 'assets/images/campaign-center/IconMagic'
import React from 'react'

type Props = {
  title: string
  body: string
}

const ThanxOptimization: React.FC<Props> = ({ title, body }) => {
  return (
    <div className="grey-70 font-size-14">
      <div className="letter-spacing-013 small-caps-6 margin-bottom-medium">
        <span className="margin-right-small">
          <IconMagic />
        </span>
        <span className="margin-left-tiny">{title}</span>
      </div>
      {body}
    </div>
  )
}

export default ThanxOptimization
