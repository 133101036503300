import React from 'react'
import TrajectoryArrow from './TrajectoryArrow'
import type { Direction } from './TrajectoryArrow'
import TrajectoryTip from './TrajectoryTip'

type Props = {
  days: number
  columnData: Array<{ string: number | string }>
  keySuffix: string
  valueFormatter?: (value: number) => string
  trajectoryCalculator?: (Object, string) => Direction
}

const BucketRow: React.FC<Props> = props => {
  const { days, columnData, keySuffix, valueFormatter, trajectoryCalculator } =
    props
  const trajectoryArrow = data => {
    const direction = trajectoryCalculator
      ? trajectoryCalculator(data, keySuffix)
      : null

    if (direction && days === 30) {
      return (
        <span>
          <TrajectoryArrow direction={direction} />
        </span>
      )
    }
  }

  const columns = columnData.map((data, i) => {
    const value = data[`${days}_day_${keySuffix}`]
    const arrow = trajectoryArrow(data)

    return [
      <td key={`arrow-${i}`} className="trajectory-arrow">
        {arrow}
      </td>,
      <td
        key={`bucket-${days}-col-${i}`}
        className="bucket-value"
        //@ts-ignore
        width="0px"
      >
        <span>
          {value === null || !valueFormatter ? '~' : valueFormatter(value)}
        </span>
        <span className="trajectory-tip">{arrow && <TrajectoryTip />}</span>
      </td>,
    ]
  })

  return (
    <tr>
      <td>{days === 365 ? '1 year' : `${days} days`}</td>
      {columns}
    </tr>
  )
}

export default BucketRow
