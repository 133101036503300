import { Text } from '@thanx/uikit/text'
import InfoBox from 'components/InfoBox'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import { Fields as Menu } from 'models/Menu'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type PropsT = {
  menus: Menu[]
  enabled?: boolean
}

const Menus: React.FC<PropsT> = props => {
  const { menus, enabled = false } = props
  const t = buildTranslate(
    `ordering_management.detail.menus.${enabled ? 'enabled' : 'disabled'}`
  )

  const filteredMenus = menus.filter(menu => menu.enabled === enabled)
  let content

  if (isEmpty(filteredMenus)) {
    content = <InfoBox type="info" content={t('infobox_content')} />
  } else {
    content = (
      <div>
        <Text.BodyText3 color="grey90" bold className="mb-l ml-m" tag="p">
          {I18n.t('ordering_management.detail.menus.menu')}
        </Text.BodyText3>
        {filteredMenus.map((menu, index) => (
          <div
            key={index}
            className="border-bottom-1 grey-20-border px-m pb-m mt-m"
          >
            <Text.BodyText4 color="grey70">{menu.name || '-'}</Text.BodyText4>
          </div>
        ))}
      </div>
    )
  }
  return (
    <div className="mb-xxl">
      <Text.Header3 bold color="grey90">
        {t('title')}
      </Text.Header3>
      <Text.BodyText3 color="grey70" tag="p" className="mb-l">
        {t('subtitle')}
      </Text.BodyText3>
      {content}
    </div>
  )
}

export default Menus
