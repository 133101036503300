import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import PopoverLearnMore from 'components/PopoverLearnMore'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import { RedeemType } from 'models/RedeemTemplate'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import {
  hasDiscount,
  notAvailable,
} from 'scenes/CampaignCenter/Report/v3/helpers'
import InfoRow from 'scenes/CampaignCenter/Report/v3/Test/components/InfoRow'
import { selectCampaignVariantMetricByType } from 'selectors/campaignMetric'
import { currencyFormatter } from 'utilities/formatters'
import { aboutRewardDiscountsAndCosts } from 'utilities/urlUtils'

const t = buildTranslate('thanx_campaigns.report.v3')

type PropsT = {
  campaignId: number
  redeemTypes: (RedeemType | undefined)[]
  variantIds: number[]
  variantTypes: (string | undefined)[]
}

const Discount: React.FC<PropsT> = props => {
  const { campaignId, redeemTypes, variantIds, variantTypes } = props

  const metrics =
    (useSelector(state =>
      selectCampaignVariantMetricByType(state, campaignId, 'redeem', variantIds)
    ) as RedeemMetric[]) || {}
  const { attributionWindow } = useAttributionWindow()

  const variants = variantIds.map((_, i) => {
    const redeemType = redeemTypes[i] || ''
    if (!hasDiscount(redeemType)) {
      return notAvailable()
    }

    const primaryValue = currencyFormatter(
      metrics[i]?.data?.total_discount || 0,
      false
    )
    const primarySections =
      variantTypes[i] === 'control'
        ? [
            {
              content: t('test.tooltips.no_reward'),
            },
          ]
        : [
            {
              content: t('summary.revenue.tooltips.total_discount', {
                count: attributionWindow,
              }),
            },
            {
              content: <PopoverLearnMore url={aboutRewardDiscountsAndCosts} />,
            },
          ]
    const secondaryValue = t('summary.revenue.avg_discount', {
      value: currencyFormatter(metrics[i]?.data?.avg_discount || 0),
    })
    const secondarySections =
      variantTypes[i] === 'control'
        ? [
            {
              content: t('test.tooltips.no_reward'),
            },
          ]
        : [
            {
              content: t('summary.revenue.tooltips.per_of_discount', {
                count: attributionWindow,
              }),
            },
            {
              title: t('summary.how_its_calc'),
              content: t('summary.revenue.tooltips.calc_of_per_discount'),
            },
          ]
    return {
      primaryContent: (
        <Metric metric={primaryValue}>
          <PopoverContent sections={primarySections} />
        </Metric>
      ),
      secondaryContent: (
        <Metric primary={false} metric={secondaryValue}>
          <PopoverContent sections={secondarySections} />
        </Metric>
      ),
      isErrored: metrics[i]?.isErrored,
      isLoading: metrics[i]?.isLoading,
    }
  })
  return <InfoRow title={t('summary.revenue.discount')} variants={variants} />
}

export default Discount
