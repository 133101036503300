import { api } from './index'

export type CompleteInvitationPayload = {
  invitation_code: string
  first_name: string
  last_name: string
  password: string
  confirm_password: string
}

export const accountsTags = ['Accounts']

export const accountsApi = api.injectEndpoints({
  endpoints: builder => ({
    validateInvitationCode: builder.query<void, string | null>({
      query: invitationCode => ({
        url: `accounts/invitation/${invitationCode}`,
      }),
    }),

    completeInvitation: builder.mutation<void, CompleteInvitationPayload>({
      query: payload => {
        const { invitation_code, ...rest } = payload
        return {
          url: `accounts/invitation/${invitation_code}`,
          method: 'POST',
          body: rest,
        }
      },
    }),
  }),
})
