import { RESET_DB } from 'actions/orm'
import Cookie from 'js-cookie'
import pickBy from 'lodash/pickBy'
import { Fields as MerchantUser } from 'models/MerchantUser'
import { push } from 'react-router-redux'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'
import urlUtils from 'utilities/urlUtils'

export const PASSWORD_AUTH = 'auth/PASSWORD_AUTH'
export const PASSWORD_AUTH_SUCCESS = 'auth/PASSWORD_AUTH_SUCCESS'
export const PASSWORD_AUTH_FAIL = 'auth/PASSWORD_AUTH_FAIL'

export const STORE_AUTH_DATA = 'auth/STORE_AUTH_DATA'

export const LOGOUT = 'auth/LOGOUT'
export const EXPIRE_SESSION = 'auth/EXPIRE_SESSION'

export const REQUEST_PASSWORD = 'auth/REQUEST_PASSWORD'
export const REQUEST_PASSWORD_SUCCESS = 'auth/REQUEST_PASSWORD_SUCCESS'
export const REQUEST_PASSWORD_FAIL = 'auth/REQUEST_PASSWORD_FAIL'

export const RESET_PASSWORD = 'auth/RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'auth/RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'auth/RESET_PASSWORD_FAIL'

export const IMPERSONATE_USER = 'auth/IMPERSONATE_USER'
export const IMPERSONATE_USER_SUCCESS = 'auth/IMPERSONATE_USER_SUCCESS'
export const IMPERSONATE_USER_FAIL = 'auth/IMPERSONATE_USER_FAIL'

type Auth = {
  access_token: string
  created_at: number
  merchant_user: MerchantUser
  role: string
  scope: string
  token_type: string
  is_thanx_admin: boolean
}

type AuthParams = {
  email: string
  password: string
  grant_type: string
  client_id?: string
}

export function authorize(
  params: AuthParams
): AxiosAction<typeof PASSWORD_AUTH, Auth> {
  return {
    type: PASSWORD_AUTH,
    payload: {
      client: 'auth',
      request: {
        url: 'oauth/token',
        method: 'post',
        data: params,
      },
    },
  }
}

export const setCurrentMerchantID = (id: number): any => {
  let currentAuth = Cookie.getJSON('thanx-auth-password')
  currentAuth.current_merchant_id = id
  Cookie.remove(
    'thanx-auth-password',
    pickBy({ domain: urlUtils.cookieDomain() })
  )
  Cookie.set('thanx-auth-password', currentAuth, {
    secure:
      process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test',
    domain: urlUtils.cookieDomain(),
  })
  return (dispatch: Function) => {
    return dispatch({
      ...currentAuth,
      type: STORE_AUTH_DATA,
    })
  }
}

export const expireSession = () => {
  Cookie.remove(
    'thanx-auth-password',
    pickBy({ domain: urlUtils.cookieDomain() })
  )

  return (dispatch: Function) => {
    Promise.all([
      dispatch({ type: EXPIRE_SESSION }),
      dispatch({ type: RESET_DB }),
    ])
  }
}

export const logout = () => {
  Cookie.remove(
    'thanx-auth-password',
    pickBy({ domain: urlUtils.cookieDomain() })
  )

  return (dispatch: Function) => {
    Promise.all([
      dispatch({
        type: LOGOUT,
      }),
      dispatch({
        type: RESET_DB,
      }),
    ]).then(() => {
      dispatch(push('/auth/logout'))
    })
  }
}

export function requestPassword(
  email: string
): AxiosAction<typeof REQUEST_PASSWORD> {
  return {
    type: REQUEST_PASSWORD,
    payload: {
      client: 'auth',
      request: {
        url: '/accounts/request_password',
        method: 'post',
        data: {
          email: email,
        },
      },
    },
  }
}

export function resetPasswordAndAuthorize(
  code: string
): AxiosAction<typeof RESET_PASSWORD, Auth> {
  return {
    type: RESET_PASSWORD,
    payload: {
      client: 'auth',
      request: {
        url: `/accounts/reset_password/${code}`,
        method: 'post',
      },
    },
  }
}

export function impersonateAndAuthorize(
  merchantId
): AxiosAction<typeof IMPERSONATE_USER, Auth> {
  return {
    type: IMPERSONATE_USER,
    payload: {
      client: 'auth',
      request: {
        url: `/merchants/impersonate`,
        method: 'post',
        data: {
          merchant_id: merchantId,
        },
      },
    },
  }
}

export type AuthorizeAction =
  | ResolvedAxiosAction<ReturnType<typeof authorize>>
  | ResolvedAxiosAction<ReturnType<typeof resetPasswordAndAuthorize>>
  | ResolvedAxiosAction<ReturnType<typeof impersonateAndAuthorize>>
export type ResolvedAction =
  | AuthorizeAction
  | {
      type: typeof STORE_AUTH_DATA
      access_token: string
      is_admin: boolean
      current_merchant_user_id: number
      current_merchant_id?: number
      is_thanx_admin?: boolean
      is_demo?: boolean
      role: string
    }
  | ResolvedAxiosAction<ReturnType<typeof requestPassword>>
  | {
      type: typeof LOGOUT
    }
  | {
      type: typeof EXPIRE_SESSION
    }
