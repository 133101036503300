import { CohortYAxisOption } from 'components/Charts/helpers'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import palette from 'constants/palette'
import Highcharts from 'highcharts'
import { buildTranslate } from 'locales'
import {
  ActivationRateCohortMetric,
  ActivationRateTimeSeriesMetric,
  TimeSeriesInterval,
} from 'models/ActivationRateMetric'
import moment from 'moment-timezone'
import { getDateLabel } from 'utilities/date'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('activation_rate')

export const AVAILABLE_IN_DAYS = 120

type RateSeriesT = {
  benchmarks: Array<{
    y: number
    custom: { is_benchmark: true }
  }>
  rates: Array<{
    y: number
    custom: ActivationRateTimeSeriesMetric['values'][number]
  }>
}

export function filterRecentValues(
  value: ActivationRateTimeSeriesMetric['values'][number]
): boolean {
  const timeZone = moment.tz.guess()
  return moment
    .tz(timeZone)
    .isAfter(
      moment
        .tz(value.date_time, timeZone)
        .endOf('month')
        .add(AVAILABLE_IN_DAYS, 'days')
    )
}

export function getRateChartSeries(
  values: ActivationRateTimeSeriesMetric['values'],
  benchmarkRate: number
): Highcharts.SeriesOptionsType[] {
  if (!values) return []

  const initialSeries: RateSeriesT = { benchmarks: [], rates: [] }
  const series: RateSeriesT = values.reduce((acc, value) => {
    acc.benchmarks.push({
      y: benchmarkRate,
      custom: {
        is_benchmark: true,
      },
    })

    acc.rates.push({
      y: (value.rate ?? 0) * 100,
      custom: value ?? {},
    })

    return acc
  }, initialSeries)

  return [
    {
      id: 'benchmark',
      name: t('tabs.activation_rate.chart.industry_benchmark'),
      data: series.benchmarks,
      color: palette.wisteria,
      type: 'line',
      marker: { enabled: false, symbol: 'circle' },
    },
    {
      id: 'activation_rate',
      name: '',
      data: series.rates,
      color: palette.spearmint50,
      type: 'line',
      showInLegend: false,
      marker: { enabled: false, symbol: 'circle' },
    },
  ]
}

export function getCohortYAxisOptions(
  values: ActivationRateCohortMetric['values'],
  granularity: DateRangeGranularity,
  timezone: string
): Array<CohortYAxisOption> {
  if (!values) return []

  const initialCategories: any = [
    {
      categories: [],
      title: t('tabs.activation_rate.cohort_chart.made_first_purchase'),
    },
    {
      categories: [],
      labelOptions: {
        align: 'left',
        x: -8,
      },
      options: {
        linkedTo: 0,
      },
    },
  ]

  return values.reduce((acc, value) => {
    acc[0].categories.push(numberFormatter(value.first_purchasers_count))
    acc[1].categories.push(getDateLabel(value.date_time, granularity, timezone))

    return acc
  }, initialCategories)
}

const rateMap: Record<TimeSeriesInterval, number> = {
  window_30d: 30,
  window_60d: 60,
  window_90d: 90,
  window_120d: 120,
  window_150d: 150,
  window_180d: 180,
}

export function getCohortSeriesData(
  values: ActivationRateCohortMetric['values'],
  timezone: string
): Array<Highcharts.PointOptionsObject> {
  if (!values) return []
  const now = moment.tz(timezone)

  return values
    .map((value, y) => {
      const date = moment.tz(value.date_time, timezone)
      const keys: TimeSeriesInterval[] = Object.keys(
        rateMap
      ) as TimeSeriesInterval[]

      return keys.map((ratePeriod: TimeSeriesInterval, x: number) => {
        const showValue = now.isAfter(
          date.clone().endOf('month').add(rateMap[ratePeriod], 'days')
        )
        return {
          x,
          y,
          value: showValue ? value[ratePeriod].rate : null,
        }
      })
    })
    .flat()
}

export function cohortSeriesFormatter(value: number): string {
  return percentageFormatter(value, 1, 1)
}

const cohortXAxisMap = {
  0: 30,
  1: 60,
  2: 90,
  3: 120,
  4: 150,
  5: 180,
}
export function cohortTopAxisFormatter(value: string | number): string {
  const val = String(value)
  if (!Object.keys(cohortXAxisMap).includes(val)) return ''

  return t('tabs.activation_rate.cohort_chart.days', {
    days: cohortXAxisMap[value],
  })
}
