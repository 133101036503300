export default {
  title: 'Purchases',
  tabs: {
    all_purchases: {
      title: 'All purchases',
      title_tag: 'All purchases | Thanx',
    },
    all_purchases_by_sku: {
      title: 'All purchases by SKU',
      title_tag: 'All purchases by SKU - Purchases | Thanx',
    },
    first_purchases: {
      search_placeholder: 'Search locations by name or address',
      title: '1st purchases by location',
      title_tag: '1st purchases by location | Thanx',
      counter: {
        subtitle:
          'Total 1st purchases attributed to loyalty members (Selected period)',
      },
      table: {
        location_name: 'Location',
        purchases_count: 'Purchases',
      },
    },
  },
}
