import React, { useEffect, useState } from 'react'

import { Button } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { updateAnalytics } from 'actions/config'
import fbLogo from 'assets/images/cms/fb_logo.png'
import gaLogo from 'assets/images/cms/ga_logo.png'
import gtmLogo from 'assets/images/cms/gtm_logo.png'
import ProgressCard from 'components/ProgressCard'
import Form from 'formsy-react'
import { useDispatch } from 'react-redux'
import Card from './Card'
import { analyticsOptions } from './constants'

type Props = {
  id: string
  t: any
  value: string
}

const logos = {
  facebook_pixel_id: fbLogo,
  google_measurement_id: gaLogo,
  google_tag_manager_id: gtmLogo,
}

const Option: React.FC<Props> = props => {
  const { id, t, value } = props
  const [newValue, setNewValue] = useState('')
  const [isError, setIsError] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldShowSuccess, setShouldShowSuccess] = useState(false)
  useEffect(() => {
    setNewValue(value)
  }, [value])

  useEffect(() => {
    setShouldShowSuccess(isSuccess)
    if (isSuccess) {
      setTimeout(() => setShouldShowSuccess(false), 4000)
    }
  }, [isSuccess])

  const dispatch = useDispatch()
  const { description, title, label } = t(`options.${id}`) || {}

  const howToText = t('how_to_text', { title })
  const whereToText = t('where_to_text')

  const { where_to_link: whereToLink, how_to_link: howToLink } =
    analyticsOptions[id]

  const onConnectUpdate = async () => {
    if (newValue !== value) {
      setIsLoading(true)
      setIsSuccess(false)
      setIsError(false)
      setErrorText('')
      const response = await dispatch(updateAnalytics({ [id]: newValue }))
      //@ts-ignore
      if (response.error) {
        setIsError(true)
        //@ts-ignore
        setErrorText(response.error.response?.data?.errors?.messages)
      } else {
        setIsSuccess(true)
      }
      setIsLoading(false)
    }
  }

  const onChange = value => {
    setNewValue(value)
  }

  const ctaButtonText = !value ? t('connect') : t('update')
  let progressText: string
  if (isError) {
    progressText = errorText
  } else if (isSuccess) {
    progressText = t('successful', { label })
  } else {
    progressText = t('processing', { label })
  }
  return (
    <Card
      image={logos[id]}
      title={title}
      description={description}
      kind="primary"
    >
      <Form>
        <FormsyInput
          // @ts-ignore
          name={`analytics-${id}`}
          label={label}
          value={newValue}
          onChange={onChange}
          autoComplete={undefined}
        />
        <a
          href={whereToLink}
          target="_blank"
          rel="noopener noreferrer"
          className="d-inline-block font-size-12 mt-xs"
        >
          {whereToText}
        </a>
        <ProgressCard
          className="my-m"
          text={progressText}
          isSuccess={isSuccess}
          isLoading={isLoading}
          isError={isError}
        />
        <div className="d-flex justify-content-end mt-s">
          <Button
            kind="secondary"
            className="mr-s"
            onClick={() => window.open(howToLink)}
          >
            {howToText}
          </Button>
          <Button
            onClick={onConnectUpdate}
            isLoading={isLoading}
            isSuccess={shouldShowSuccess}
          >
            {ctaButtonText}
          </Button>
        </div>
      </Form>
    </Card>
  )
}

export default Option
