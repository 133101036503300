import { Switch } from '@thanx/uikit/switch'
import {
  CohortYAxisOption,
  formatCohortSeriesData,
  useManageSeries,
} from 'components/Charts/helpers'
import Status from 'components/Status'
import palette from 'constants/palette'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsHeatmap from 'highcharts/modules/heatmap'
import React, { useMemo } from 'react'
import { merchantTheme } from 'theme'

HighchartsHeatmap(Highcharts)

type PropsT = {
  yAxisOptions: Array<CohortYAxisOption>
  seriesData: Array<Highcharts.PointOptionsObject>
  topAxisFormatter?: (value: string | number) => string
  seriesFormatter?: (value: number) => string
  isLoading: boolean
  isErrored: boolean
}

const CohortChart: React.FC<PropsT> = ({
  yAxisOptions,
  seriesData,
  topAxisFormatter,
  isLoading,
  isErrored,
  seriesFormatter,
}) => {
  const series: Array<Highcharts.SeriesOptionsType> = useMemo(
    () => [
      {
        type: 'heatmap',
        yAxis: 0,
        showInLegend: false,
        data: formatCohortSeriesData(seriesData),
      },
    ],
    [seriesData]
  )

  const { chartRef } = useManageSeries(isLoading, series)

  const yAxis: Array<Highcharts.YAxisOptions> | Highcharts.YAxisOptions =
    useMemo(
      () =>
        yAxisOptions.map(option => ({
          title: {
            align: 'high',
            offset: 0,
            text: option.title,
            rotation: 0,
            y: -16,
            x: -8,
            margin: 0,
            style: {
              fontWeight: 'bold',
              color: merchantTheme.colors.grey90,
              fontSize: '14px',
            },
          },
          labels: {
            style: {
              fontWeight: 'normal',
              color: merchantTheme.colors.grey70,
              fontSize: '14px',
            },
            ...option.labelOptions,
          },
          gridLineWidth: 0,
          reversed: true,
          endOnTick: false,
          categories: option.categories,
          ...option.options,
        })),
      [yAxisOptions]
    )

  const chartHeight = useMemo(() => {
    const option = yAxisOptions[0]
    if (!option) return 400

    // 64 height per row + 50 for axes headers and padding
    return option.categories.length * 64 + 50
  }, [yAxisOptions])

  const options: Highcharts.Options = {
    chart: {
      type: 'heatmap',
      height: chartHeight,
      style: {
        fontFamily: 'Lato',
        overflow: 'visible !important',
      },
    },
    tooltip: { enabled: false },
    title: undefined,
    credits: { enabled: false },
    yAxis,
    xAxis: {
      opposite: true,
      tickWidth: 0,
      lineWidth: 0,
      labels: {
        align: 'right',
        x: 16,
        formatter: function () {
          if (!topAxisFormatter) return ''
          const { value } = this
          if (value === null || value === undefined) return ''
          return topAxisFormatter(value)
        },
        style: {
          fontWeight: 'bold',
          color: merchantTheme.colors.grey90,
          fontSize: '14px',
        },
      },
    },
    plotOptions: {
      heatmap: {
        borderWidth: 1,
        borderColor: palette.grey30,
        enableMouseTracking: false,
        dataLabels: {
          align: 'right',
          x: -24,
          enabled: true,
          color: merchantTheme.colors.grey70,
          style: {
            textOutline: 'none',
            fontSize: '14px',
            fontWeight: 'normal',
          },
          formatter: function () {
            const { value } = this.point
            if (!seriesFormatter || !value) return value
            return seriesFormatter(value)
          },
        },
      },
    },
    series,
    legend: {
      align: 'left',
      symbolHeight: 12,
      symbolRadius: 0,
      x: 72,
      itemStyle: {
        color: merchantTheme.colors.grey90,
        fontSize: '14px',
      },
      itemDistance: 48,
    },
    colorAxis: {
      min: 0,
      minColor: palette.spearmint10,
      maxColor: palette.spearmint30,
      visible: false,
    },
  }

  const status = (() => {
    if (isLoading) return 'loading'
    if (isErrored) return 'error'

    return null
  })()

  // Currently, rendering a heatmap before data is loaded results in some axes
  // data not being formatted correctly. Wait until chart is ready to render.
  const showChart = status === null && yAxisOptions.length > 0

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <Switch condition={showChart}>
          <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={options}
            containerProps={{ className: 'w-100' }}
          />
        </Switch>
        <Status status={status} />
      </div>
    </>
  )
}

export default CohortChart
