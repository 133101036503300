import {
  GET_ITEM_PURCHASES_SUCCESS,
  ResolvedAction,
} from 'actions/itemPurchases'
import { RESET_DB } from 'actions/itemPurchases'
import { Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  purchased_at: string
}

// @ts-ignore
export default class ItemPurchase extends Model<typeof ItemPurchase, Fields> {
  static modelName: string = 'ItemPurchase'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    ItemPurchase: ModelType<ItemPurchase>
  ): void {
    switch (action.type) {
      case GET_ITEM_PURCHASES_SUCCESS:
        action.payload.data.purchases.forEach(purchase =>
          ItemPurchase.upsert(purchase)
        )
        break
      case RESET_DB:
        ItemPurchase.all().delete()
        break
      default:
        break
    }
  }
}
