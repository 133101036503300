export default {
  title: 'Activity',
  page_title: 'Activity - Points | Thanx',
  description: 'How points are being issued and redeemed',
  data_freshness: 'Data updates approximately every hour',
  summary_counters: {
    issued: 'Issued points',
    redeemed: 'Redeemed points',
    expired: 'Expired points',
    example: 'EXAMPLE',
    issued_popover:
      'The cumulative sum of points issued during the selected period.',
    issued_example:
      'Between June 1 and June 30, 900 points were issued to customers.',
    redeemed_popover:
      'The cumulative sum of points exchanged for rewards during the selected period.',
    redeemed_example:
      'Between June 1 and June 30, 100 points were exchanged for a reward.',
    expired_popover:
      'The cumulative sum of all the points that have expired during the selected period.',
    expired_example:
      '1,000 customers didn’t place any purchases for over 12 months. Their points have now expired.',
  },
  activity_chart: {
    x_axis_title: 'Date',
    y_axis_title: 'Points',
    issued: 'Issued points',
    timezone: 'Data is shown in your timezone (%{timeZone})',
    redeemed: 'Redeemed points',
    expired: 'Expired points',
    error: 'Activity data failed to load',
    advanced_options: {
      title: 'Advanced',
      hide_import_label: 'Hide points issued from import',
      hide_import_description:
        "If you've transitioned to a Points program then there will be a large spike in activity when customers " +
        'progress is first converted in to points. Hide this activity to remove that data from the chart so you ' +
        'can easily see everything that happened from that point onwards. When these points are hidden, this chart' +
        ' may no longer match the other data in this report.',
    },
  },
  outstanding_points: {
    title: 'Outstanding points',
    description:
      'Understand your total liability over time by looking at how many points have been issued but not redeemed or expired',
    counters: {
      total_outstanding_points: 'Total outstanding points',
      change_outstanding_points:
        'Change in outstanding points during the selected time period',
      view_details: 'View details',
      hide_details: 'Hide details',
      total_outstanding_popover:
        'The number of all outstanding points up to now.',
      change_in_outstanding_popover_1:
        'The difference between outstanding points at the start and at the end of the selected period.',
      change_in_outstanding_popover_2:
        'A positive number means you have issued more points in the selected period of time than your guests have used or expired. A negative number means that more points were used or expired, than issued in the selected period.',
    },
    chart: {
      x_axis_title: 'Date',
      y_axis_title: 'Outstanding points',
      error: 'Outstanding liability data failed to load',
      timezone: 'Data is shown in your timezone (%{timeZone})',
    },
    table: {
      outstanding_at: 'Outstanding points as of %{date}',
      outstanding_between:
        'Change in outstanding points between %{from} and %{to}',
      outstanding_start_popover:
        'A snapshot of your outstanding points at the beginning of the selected period calculated as the difference between issued and redeemed points and adjusted for expiration.',
      outstanding_end_popover:
        'A snapshot of your outstanding points at the end of the selected period calculated as the difference between issued and redeemed points and adjusted for expiration.',
      outstanding_change_popover:
        'The difference between the 2 numbers above. An arrow up means you have issued more points in the selected period of time than your guests have used or expired. An arrow down means that more points were used or expired, than issued in the selected period.',
    },
  },
  summary_table: {
    title: 'Summary',
    headers: {
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      quarter: 'Quarter',
      outstandingAtStart: 'Outstanding at start',
      outstandingAtEnd: 'Outstanding at end',
      issued: 'Issued',
      redeemed: 'Redeemed',
      expired: 'Expired',
    },
    tooltips: {
      example: 'Example',
      howCalculated: 'HOW IT’S CALCULATED',
      issued: {
        desc1: 'The cumulative sum of points issued during this period.',
        desc2:
          'Points generated by triggers etc - Clawback - Fraudulent accounts + Adjustments for negative balance',
        desc3:
          'Customers spent $1,200 during this period and were issued 1,200 points.',
      },
      redeemed: {
        desc1:
          'The cumulative sum of points exchanged for rewards during this period.',
        desc2: 'Points redeemed for rewards - Refunds',
        desc3:
          'Customers exchanged 1,000 points during this period for various rewards in your marketplace.',
      },
      expired: {
        desc1:
          'The cumulative sum of all the points that have expired during this period.',
        desc2:
          'As of this period, 1,000 customers didn’t place any purchases for over 12 months. Their points have now expired.',
      },
      outstandingAtStart: {
        desc1:
          'A snapshot of your outstanding points at the beginning of this period.',
      },
      outstandingAtEnd: {
        desc1:
          'A snapshot of your outstanding points at the end of this period.',
        desc2:
          'Outstanding points at the start of this period +  issued points - redeemed points - expired points',
      },
    },
  },
  report_not_active: {
    migrating: {
      title: 'This report is not ready yet',
      description:
        'A report on how many points are being issued and redeemed will appear here once your points program is active.',
    },
  },
}
