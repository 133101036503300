'use strict'
var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i]
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
        }
        return t
      }
    return __assign.apply(this, arguments)
  }
exports.__esModule = true
var jsx_runtime_1 = require('react/jsx-runtime')
var HeaderCell_1 = require('components/HeaderCell')
var locales_1 = require('locales')
var CampaignItem_1 = require('./CampaignItem')
var Empty_1 = require('./Empty')
var t = (0, locales_1.buildTranslate)('thanx_campaigns.landing.reporting')
var Table = function (props) {
  var campaigns = props.campaigns,
    state = props.state,
    onStop = props.onStop,
    _a = props.columns,
    columns =
      _a === void 0
        ? ['name', 'date', 'createdBy', 'status', 'reward', 'actions']
        : _a
  if (campaigns.length === 0 && state) {
    return (0, jsx_runtime_1.jsx)(Empty_1['default'], { state: state })
  }
  var dateString = (function () {
    if (state === 'draft') {
      return t('column_titles.date.created')
    } else if (!!state && ['scheduled', 'in_review'].includes(state)) {
      return t('column_titles.date.scheduled')
    } else {
      return t('column_titles.date.started')
    }
  })()
  function getHeaderCell(column) {
    if (column === 'name') {
      return (0, jsx_runtime_1.jsx)(
        HeaderCell_1['default'],
        { children: t('column_titles.name') },
        t('column_titles.name')
      )
    } else if (column === 'date') {
      return (0, jsx_runtime_1.jsx)(
        HeaderCell_1['default'],
        { children: dateString },
        dateString
      )
    } else if (column === 'createdBy') {
      return (0, jsx_runtime_1.jsx)(
        HeaderCell_1['default'],
        { children: t('column_titles.created_by') },
        t('column_titles.created_by')
      )
    } else if (column === 'status') {
      return (0, jsx_runtime_1.jsx)(
        HeaderCell_1['default'],
        { children: t('column_titles.status') },
        t('column_titles.status')
      )
    } else if (column === 'reward') {
      return (0, jsx_runtime_1.jsx)(
        HeaderCell_1['default'],
        { children: t('column_titles.reward') },
        t('column_titles.reward')
      )
    } else {
      return (0, jsx_runtime_1.jsx)(HeaderCell_1['default'], {}, 'empty')
    }
  }
  var header = (0, jsx_runtime_1.jsx)('thead', {
    children: (0, jsx_runtime_1.jsxs)('tr', {
      children: [
        columns.map(function (column) {
          return getHeaderCell(column)
        }),
        (0, jsx_runtime_1.jsx)(HeaderCell_1['default'], {}, 'last'),
      ],
    }),
  })
  return (0, jsx_runtime_1.jsxs)(
    'table',
    __assign(
      { className: 'full-width campaign-table' },
      {
        children: [
          header,
          (0, jsx_runtime_1.jsx)('tbody', {
            children: campaigns.map(function (campaign) {
              return (0, jsx_runtime_1.jsx)(
                CampaignItem_1['default'],
                {
                  campaign: campaign,
                  columns: columns,
                  onStop: function () {
                    return onStop && onStop(campaign)
                  },
                },
                campaign.id
              )
            }),
          }),
        ],
      }
    )
  )
}
exports['default'] = Table
