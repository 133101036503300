import { getCampaignMetricByType } from 'actions/campaignMetrics'
import { alert } from 'actions/flash'
import { getMerchantMetricByType } from 'actions/merchantMetrics'
import useDispatch from 'hooks/useDispatch'
import type { Fields as Campaign } from 'models/Campaign'
import { Type as MetricType, UserCountMetric } from 'models/CampaignMetric'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { selectMerchantMetrics } from 'selectors/merchantMetric'
import { getCategories, getCategoryPercent } from './helper'
import View from './View'

type Props = {
  campaign: Campaign
}

const AudienceContainer: React.FC<Props> = ({ campaign }) => {
  const [isLoadingUserCount, setIsLoadingUserCount] = useState(true)
  const [isLoadingCategoryQuantity, setIsLoadingCategoryQuantity] =
    useState(true)
  const campaignCategory = campaign.config_category
  const campaignLocationIds = campaign.config_location_ids
  const campaignId = campaign.id
  const merchantMetrics = useSelector(selectMerchantMetrics)
  const userCountMetric = useSelector(
    state =>
      selectCampaignMetricByType(
        state,
        campaign.id,
        MetricType.USER_COUNT
      ) as UserCountMetric | null
  )

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(
        getCampaignMetricByType(campaignId, 'user_count')
      )
      setIsLoadingUserCount(false)

      if (response.error) {
        const error = response.error.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }
    }

    fetchData()
  }, [campaignCategory, campaignLocationIds, dispatch, campaignId])

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(
        getMerchantMetricByType('category_quantity', {
          location_ids: campaignLocationIds,
        })
      )
      setIsLoadingCategoryQuantity(false)

      if (response.error) {
        const error = response.error.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }
    }

    fetchData()
  }, [campaignLocationIds, dispatch])

  const rawCategories =
    merchantMetrics?.category_quantity?.data?.categories || {}
  const isLoading = isLoadingUserCount || isLoadingCategoryQuantity
  return (
    <View
      campaign={campaign}
      isLoading={isLoading}
      categories={getCategories(rawCategories)}
      categoryPercent={getCategoryPercent(
        userCountMetric,
        rawCategories,
        campaign.config_category
      )}
    />
  )
}

export default AudienceContainer
