import { Button } from '@thanx/uikit/button'
import alertBannerMobile from 'assets/images/cms/alert-banner-mobile.svg'
import alertBannerWeb from 'assets/images/cms/alert-banner-web.svg'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.content.alert_banner')

const Sidebar: React.FC = () => {
  return (
    <>
      <Button className="px-s w-100" type="submit">
        {t('publish_alert')}
      </Button>
      <p className="body-text-4 mt-xxxl grey-70 text-center">
        {t('how_it_looks_app')}
      </p>
      <img
        className={`mt-x`}
        src={alertBannerMobile}
        alt={t('mobile_preview')}
      />
      <p className="body-text-4 grey-70 mt-xl text-center">
        {t('how_it_looks_desktop')}
      </p>
      <img className={`mt-x`} src={alertBannerWeb} alt={t('web_preview')} />
    </>
  )
}

export default Sidebar
