import { Radio } from '@thanx/uikit/radio'
import { useStyletron } from '@thanx/uikit/theme'
import { defaultTheme } from '@thanx/uikit/theme'
import PopoverInfo from 'components/PopoverInfo'
import { buildTranslate } from 'locales'
import React from 'react'
import { RedeemCategory, RedeemTypeItem } from '../../types'

type Props = {
  redeemCategory: RedeemCategory
  selectedTypeItem: RedeemTypeItem | null
  onItemSelect: (typeItem: RedeemTypeItem) => void
}

const t = buildTranslate('redeem_manager.types')

const Category: React.FC<Props> = ({
  redeemCategory,
  selectedTypeItem,
  onItemSelect,
}) => {
  const { key: categoryKey, items } = redeemCategory
  const [css] = useStyletron()

  return (
    <div className="mb-xl">
      <p className="small-caps-2 mb-m">{t(`${categoryKey}.title`)}</p>
      {items.map(item => {
        const { key: itemKey, subtype } = item
        if (subtype === null) return null
        const checked = selectedTypeItem?.key === itemKey

        return (
          <Radio
            key={itemKey}
            className={`${css({
              ':hover': {
                borderColor: checked ? '' : defaultTheme.colors.grey50,
              },
            })} w-100 mb-s px-s py-m white-bkg border-1 cursor-pointer
              ${checked ? 'belize-hole-50-border' : 'grey-30-border'}`}
            value={itemKey}
            checked={checked}
            onClick={() => onItemSelect(item)}
            overrides={{
              Label: {
                style: {
                  width: '100%',
                },
              },
            }}
          >
            <div className="d-flex justify-content-between align-items-center">
              {t(`${itemKey}.title`)}
              <PopoverInfo
                title={t('example')}
                text={
                  <p
                    className="mt-s mb-0"
                    dangerouslySetInnerHTML={{
                      __html: t(`${itemKey}.example`),
                    }}
                  />
                }
              />
            </div>
          </Radio>
        )
      })}
    </div>
  )
}

export default Category
