import type { Metrics } from 'models/CampaignMetric'
import React from 'react'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'
import View from './View'

type Props = {
  metrics?: Metrics
  hasDivider?: boolean
}

export const RepeatCustomers: React.FC<Props> = props => {
  const metrics = props.metrics?.repeat_count || {}

  return <View metrics={metrics} hasDivider={!!props.hasDivider} />
}

export default withMetrics(['repeat_count'])(RepeatCustomers)
