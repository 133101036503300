import { Text } from '@thanx/uikit/text'
import { TextColorKeys } from '@thanx/uikit/theme/colors'
import { alert } from 'actions/flash'
import { getUserProfile } from 'actions/users'
import { usersApi } from 'api/users'
import { isError } from 'api/utils'
import Modal from 'components/ModalContainer'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectTiers } from 'selectors/tier'

type Props = {
  isOpen: boolean
  onClose: () => void
  profile: UserProfile
  selectedTierType: string
}

const t = buildTranslate('users.view.actions.confirmation')

const GrantTierConfirmationModal: React.FC<Props> = props => {
  const { isOpen, onClose, profile, selectedTierType } = props

  const dispatch = useDispatch()

  const tiers = useSelector(selectTiers)

  const currentTier =
    profile.current_tier ||
    tiers.find(({ type }) => type === 'tier_bronze')?.name

  const newTier = tiers.find(tier => tier.type === selectedTierType)

  const [grantUserTier] = usersApi.useGrantUserTierMutation()

  async function grantTier() {
    const result = await grantUserTier({
      userUid: profile.uid,
      state: selectedTierType,
    })

    if (isError(result)) {
      dispatch(
        alert({
          key: 'danger',
          message: t('alert_fail'),
          timeout: 3,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('alert_success'),
        timeout: 3,
      })
    )

    await dispatch(getUserProfile(profile.uid))
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      proceedText={t('submit')}
      onProceed={grantTier}
      canProceed={true}
    >
      <h3 className="mt-0 mb-xs extra-bold">
        {t('title', { tierName: newTier?.name })}
      </h3>
      <Text.BodyText3 color={TextColorKeys.grey70} className="mb-l">
        {t('message', {
          oldTier: currentTier,
          newTier: newTier?.name,
        })}
      </Text.BodyText3>
    </Modal>
  )
}

export default GrantTierConfirmationModal
