import { alert } from 'actions/flash'
import {
  deletePointsProduct,
  DELETE_FAIL,
  ResolvedAction,
} from 'actions/pointsProducts'
import Modal from 'components/ModalContainer'
import { buildTranslate } from 'locales'
import { Fields as PointsProduct } from 'models/PointsProduct'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

type Props = {
  pointsProduct: PointsProduct | null
  setIsOpen: (value: boolean) => void
  isOpen: boolean
  onSuccess?: (value: boolean) => void
}

const t = buildTranslate('points.item_card')

const DeleteModal: React.FC<Props> = props => {
  const { onSuccess, pointsProduct, setIsOpen, isOpen } = props

  const handleDeleteModalClose = () => {
    setIsOpen(false)
    setIsDeleting(false)
  }

  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)

  const handleDelete = async () => {
    if (!pointsProduct) return
    setIsDeleting(true)
    const result: ResolvedAction = (await dispatch(
      deletePointsProduct(pointsProduct.id)
    )) as any

    // handle some error from the endpoint
    if (result.type === DELETE_FAIL) {
      dispatch(
        alert({
          key: 'danger',
          message: t('remove_error'),
        })
      )
      setIsOpen(false)
      setIsDeleting(false)
      return
    }

    if (onSuccess) onSuccess(false)
    // display success message and close the modal
    dispatch(
      alert({
        key: 'success',
        message: t('remove_success'),
        timeout: 5,
      })
    )
    setIsOpen(false)
    setIsDeleting(false)
  }

  return (
    <Modal
      title={t('remove_modal.title')}
      subtitle={t('remove_modal.body')}
      proceedText={t('remove_modal.confirm')}
      isOpen={isOpen}
      onClose={handleDeleteModalClose}
      onProceed={handleDelete}
      canProceed={!isDeleting}
    />
  )
}

export default DeleteModal
