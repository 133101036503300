import FullStory from 'components/FullStory'
import useZendesk from 'hooks/useZendesk'
import React, { useEffect } from 'react'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from 'store'
import { AbilityProvider } from 'utilities/ability'
import pageView from 'utilities/gaUtil'
import { Routes } from './Routes'

history.listen(location => {
  pageView(location.pathname)
})

const App: React.FC = () => {
  useEffect(() => {
    pageView(window.location.pathname)
  }, [])

  useZendesk()

  return (
    <ConnectedRouter history={history} store={store}>
      <AbilityProvider>
        <FullStory
          orgKey={process.env.REACT_APP_FULL_STORY_ORG_KEY}
          debug={false}
        />
        <div className="full-height fs-unmask">
          <Routes />
        </div>
      </AbilityProvider>
    </ConnectedRouter>
  )
}

export default App
