import { UPDATE } from 'actions/redirect'

export type Redirect = {
  pathname?: string | null
}

export default (state = {}, action): Redirect => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        pathname: action.pathname,
      }
    default:
      return state
  }
}
