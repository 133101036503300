import { api } from './index'

export type MenuCategory = {
  ids: string[]
  name: string
  hidden: boolean
}

export const menuCategoriesApi = api.injectEndpoints({
  endpoints: build => ({
    getMenuCategories: build.query<
      { menu_categories: MenuCategory[] },
      'ALL' | 'HIDDEN'
    >({
      query: state => `/menu_categories?state=${state || 'ALL'}`,
      providesTags: ['MenuCategories'],
    }),
  }),
})

export const { useGetMenuCategoriesQuery } = menuCategoriesApi
