import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import { Nav } from 'react-bootstrap-five'

type Props = {
  tabs: string[]
}

const t = buildTranslate('redeem_manager.builder.steps.type')

const NavBar: React.FC<Props> = ({ tabs }) => {
  const [css] = useStyletron()
  return (
    <Nav className="container nav-tabs mb-xl width-100">
      {tabs.map(tab => (
        <Nav.Item key={tab}>
          <Nav.Link
            eventKey={tab}
            className={css({
              background: 'transparent !important',
            })}
          >
            {t(tab)}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  )
}

export default NavBar
