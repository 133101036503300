import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type Props = {
  hasWarning?: boolean
  onClick?: () => void
}

const SelectItem: React.FC<Props> = props => {
  const { children, hasWarning = false, onClick } = props
  const [css] = useStyletron()

  return (
    <span
      className={`${css({
        cursor: 'pointer',
      })} d-flex justify-content-between align-items-center`}
      onClick={onClick}
    >
      <span className={'grey-70'}>{children}</span>
      {hasWarning && <span className="fa fa-exclamation-triangle cayenne-50" />}
    </span>
  )
}

export default SelectItem
