import { Text } from '@thanx/uikit/text'
import Email from 'components/Icons/Email'
import { buildTranslate } from 'locales'
import { CampaignStateT, MinimalVariant } from 'models/Campaign'
import React from 'react'
import Section from '../Section'
import Clicked from './Email/Clicked'
import EmailDelivered from './Email/Delivered'
import Opened from './Email/Opened'
import EmailSent from './Email/Sent'
import Unsubscribed from './Email/Unsubscribed'

const t = buildTranslate('thanx_campaigns.report.v3.summary.engagement')

type Props = {
  campaignId: number
  campaignState: CampaignStateT
  variants: MinimalVariant[]
  variantIds: number[]
  winningVariantId: number | null
}
const Engagement: React.FC<Props> = ({
  campaignId,
  campaignState,
  variantIds,
  variants,
  winningVariantId,
}) => {
  return (
    <Section
      title={t('title')}
      attributionWindow={-1}
      attributionTooltip={t('attribution.tooltip')}
      subtitle={t('subtitle')}
      campaignId={campaignId}
      metricTypes={['email_message_count']}
      campaignState={campaignState}
      variants={variants}
      winningVariantId={winningVariantId}
      headerLeft={
        <div className="d-flex flex-align-center mt-m mb-s">
          <Email />
          <Text.Header4 className="m-0 ml-s">{t('email.title')}</Text.Header4>
        </div>
      }
    >
      <div className="mb-xl">
        <EmailSent campaignId={campaignId} variantIds={variantIds} />
        <EmailDelivered campaignId={campaignId} variantIds={variantIds} />
        <Opened campaignId={campaignId} variantIds={variantIds} />
        <Clicked campaignId={campaignId} variantIds={variantIds} />
        <Unsubscribed campaignId={campaignId} variantIds={variantIds} />
      </div>
    </Section>
  )
}

export default Engagement
