import React from 'react'
import Spacer from './Spacer'
import Spinner from './Spinner'

const Loading: React.FC = () => {
  return (
    <Spacer paddingTop="60px" style={{ textAlign: 'center' }}>
      <Spinner isLoading={true} size="4x" />
    </Spacer>
  )
}
export default Loading
