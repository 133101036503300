import { useState } from 'react'

import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { Fields } from 'models/Location'
import { phoneNumberFormatter } from 'utilities/formatters'

import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { Button } from 'react-bootstrap'
import DetailsModal from 'scenes/Locations/Landing/DetailsModal'
import EditPhoneNumberModal from 'scenes/Locations/Landing/EditPhoneNumberModal'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'

const t = buildTranslate('locations.location_list.table')

type PropsT = {
  location: Fields
  isLoading: boolean
}

const Row = ({ location, isLoading }: PropsT) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const { name, city, street, state, zip } = location
  const isEditableLocationPhoneEnabled = useFlag(
    'MX-Editable-Location-PhoneNumber',
    false
  )
  const phoneNumber = location.phone
    ? `1 ${phoneNumberFormatter(location.phone)}`
    : '-'

  return (
    <tr>
      <BodyCellContainer isErrored={false} isLoading={isLoading}>
        <Text.BodyText4 tag="div">{name}</Text.BodyText4>
        <Text.BodyText4 tag="div">{`${street}, ${city}, ${zip}, ${state}`}</Text.BodyText4>
      </BodyCellContainer>
      <BodyCell
        className={'align-middle'}
        isErrored={false}
        isLoading={isLoading}
        values={[{ value: phoneNumber }]}
      />
      <BodyCellContainer
        className={'align-middle'}
        isErrored={false}
        isLoading={isLoading}
      >
        <Button
          onClick={() => setIsDetailsModalOpen(true)}
          className="p-0 text-left font-size-12"
          bsStyle="link"
        >
          <Text.BodyText4
            tag="span"
            className="ml-xs mb-0 ellipsis"
            color="primary"
          >
            {t('view_details')}
          </Text.BodyText4>
        </Button>
        <DetailsModal
          isOpen={isDetailsModalOpen}
          location={location}
          onClose={() => setIsDetailsModalOpen(false)}
        />
      </BodyCellContainer>
      <Switch condition={isEditableLocationPhoneEnabled}>
        <BodyCellContainer
          className={'text-right align-middle'}
          isErrored={false}
          isLoading={isLoading}
        >
          <Button
            onClick={() => setIsEditModalOpen(true)}
            className="p-0 text-left font-size-12"
            bsStyle="link"
          >
            <Text.BodyText4
              tag="span"
              className="ml-xs mb-0 ellipsis"
              color="primary"
            >
              {t('edit')}
            </Text.BodyText4>
          </Button>
          <EditPhoneNumberModal
            isOpen={isEditModalOpen}
            location={location}
            onClose={() => setIsEditModalOpen(false)}
          />
        </BodyCellContainer>
      </Switch>
    </tr>
  )
}

export default Row
