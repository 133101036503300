import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import store from 'store'
import { generateUikitTheme } from 'theme'
import FlagsProvider from './FlagsProvider'

type Props = {
  children: React.ReactNode
}

const styletronEngine = new StyletronEngine({ prefix: 'st-' })

const AppProviders: React.FC<Props> = ({ children }) => {
  const uikitTheme = generateUikitTheme()
  return (
    <StyleProvider
      styletronEngine={styletronEngine}
      theme={uikitTheme}
      overrides={{
        AppContainer: {
          style: {
            height: '100%',
          },
        },
      }}
    >
      <ReduxProvider store={store}>
        <FlagsProvider>{children}</FlagsProvider>
      </ReduxProvider>
    </StyleProvider>
  )
}

export default AppProviders
