import { updatePaginationCurrentPage } from 'actions/pagination'
import { getUsers } from 'actions/users'
import useDispatch from 'hooks/useDispatch'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPagination } from 'selectors/pagination'
import { selectUsers } from 'selectors/user'

export const useUsersTable = () => {
  const dispatch = useDispatch()
  const [isFetchingUsers, setIsFetchingUsers] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string | null>(null)

  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'user', 'all')
  )

  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const userIds = paginationInfo?.record_ids?.[currentPage] ?? []
  const [totalMembershipCount, setTotalMembershipCount] = useState(0)
  const users = (useSelector(state => selectUsers(state)) ?? []).filter(user =>
    userIds.includes(user.id)
  )

  const fetchUsers = useCallback(
    async (page: number, query?: string | null) => {
      setIsFetchingUsers(true)
      const params: Parameters<typeof getUsers>[0] = {
        page,
      }
      if (query && query.length > 0) {
        params.query = query
      }
      const response = await dispatch(getUsers(params))
      if (!response.error) {
        setTotalMembershipCount(response.payload.data.total_membership_count)
      }
      setIsFetchingUsers(false)
    },
    [dispatch]
  )

  const onChangePage = useCallback(
    async (page: number) => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // users for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(updatePaginationCurrentPage({ type: 'user', id: 'all', page }))
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      fetchUsers(page, searchQuery)
    },
    [dispatch, paginationInfo, fetchUsers, searchQuery]
  )

  useEffect(() => {
    fetchUsers(currentPage, searchQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchUsers, searchQuery])

  return {
    isFetchingUsers,
    onChangePage,
    currentPage,
    numPages,
    totalMembershipCount,
    users,
    setSearchQuery,
  }
}
