import type { Fields as Location } from 'models/Location'
import type { Fields as LocationCategory } from 'models/LocationCategory'
import type { SelectOptionT } from './types'

export const getOptionFromLocation = (location: Location): SelectOptionT => {
  return {
    label: location.fullName(),
    value: location.id,
  }
}

export const locationsToSelectOptions = (
  locations?: Location[]
): Array<SelectOptionT> => {
  if (!locations || !locations.length) return []
  return locations.map(location => getOptionFromLocation(location))
}

export const locationCategoriesToSelectOptions = (
  categories: LocationCategory[]
): Array<SelectOptionT> => {
  return categories.map(category => ({
    label: category.title,
    value: category.id,
  }))
}

/* Calculates distance between two locations */
export function calculateDistance(
  locationA: Location,
  locationB: Location
): number {
  const latA = parseFloat(locationA.latitude)
  const lngA = parseFloat(locationA.longitude)

  const latB = parseFloat(locationB.latitude)
  const lngB = parseFloat(locationB.longitude)

  return Math.sqrt((latA - latB) ** 2 + (lngA - lngB) ** 2)
}
