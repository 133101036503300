import {
  createCouponPool as createCouponPoolAction,
  GetAllResolvedAction,
  getCouponPools as getCouponPoolsAction,
  updateCouponPool as updateCouponPoolAction,
} from 'actions/couponPools'
import { getRedeemTemplate } from 'actions/redeemTemplates'
import {
  CouponPoolGenerationType,
  CouponPoolState,
  CouponPoolType,
  Fields as CouponPool,
} from 'models/CouponPool'
import { RedemptionProvider } from 'models/RedemptionProvider'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

type CreatePoolParams = {
  templateId: number
  staticCode?: string
  generationType: CouponPoolGenerationType
  type?: CouponPoolType
  returnExistingPool?: boolean
  redemptionProvider?: RedemptionProvider | null
}
type UpdatePoolParams = {
  templateId: number
  couponPool: {
    id: number
    generation_type: CouponPoolGenerationType // might have to underscore
    type: CouponPoolType
    state: CouponPoolState
    static_code: string | null
    redemptionProvider: RedemptionProvider | null
    // add in others? redeem_template_id, created_at
  }
}
interface Result {
  createCouponPool: (pool: CreatePoolParams) => Promise<CouponPool | undefined>
  getCouponPools: (
    templateId: number,
    generationType?: CouponPoolGenerationType
  ) => Promise<CouponPool[] | undefined>
  updateCouponPool: (
    params: UpdatePoolParams
  ) => Promise<CouponPool | undefined>
}

export default function (): Result {
  const dispatch = useDispatch()

  const createCouponPool = useCallback(
    async (params: CreatePoolParams) => {
      const {
        templateId,
        staticCode = '',
        generationType,
        type = CouponPoolType.RAW,
        returnExistingPool = false,
        redemptionProvider = null,
      } = params
      if (!templateId) return

      if (returnExistingPool) {
        const result: GetAllResolvedAction = (await dispatch(
          getCouponPoolsAction(templateId)
        )) as any
        if (result.type === 'couponPool/GET_ALL_SUCCESS') {
          const couponPools = result.payload.data.coupon_pools
          if (couponPools?.length) {
            const pools = redemptionProvider
              ? couponPools.filter(
                  pool =>
                    pool.redemption_provider?.value === redemptionProvider.value
                )
              : couponPools

            if (pools.length) {
              return pools[0]
            }
          }
        }
      }

      const response = (await dispatch(
        createCouponPoolAction(templateId, {
          generation_type: generationType,
          type: type,
          static_code: staticCode,
          redemption_provider: redemptionProvider?.value ?? null,
        })
      )) as any

      if (!response.error) {
        await dispatch(getRedeemTemplate(templateId))
        await dispatch(getCouponPoolsAction(templateId))

        return response.payload.data.coupon_pool as CouponPool
      }
      return
    },
    [dispatch]
  )

  const getCouponPools = useCallback(
    async (templateId: number, generationType?: CouponPoolGenerationType) => {
      const result = (await dispatch(getCouponPoolsAction(templateId))) as any
      if (
        result.type === 'couponPool/GET_ALL_SUCCESS' &&
        !!result.payload.data.coupon_pools?.length
      ) {
        const pools = result.payload.data.coupon_pools as CouponPool[]
        if (!!generationType) {
          return pools.filter(cp => cp.generation_type === generationType)
        }
        return pools
      }
    },
    [dispatch]
  )

  const updateCouponPool = useCallback(
    async (params: UpdatePoolParams) => {
      const { templateId, couponPool } = params
      if (!templateId) return

      const response = (await dispatch(
        updateCouponPoolAction(templateId, couponPool.id, {
          ...couponPool,
          redemption_provider: couponPool.redemptionProvider?.value,
        })
      )) as any

      if (!response.error) {
        await dispatch(getRedeemTemplate(templateId))
        await dispatch(getCouponPoolsAction(templateId))

        return response.payload.data.coupon_pool as CouponPool
      }
      return
    },
    [dispatch]
  )

  return {
    createCouponPool,
    getCouponPools,
    updateCouponPool,
  }
}
