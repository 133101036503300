import { buildTranslate } from 'locales'
import React from 'react'
import InfoModalContent from './InfoModalContent'

const t = buildTranslate('thanx_campaigns.builder.info_modal.location_reopen')

const LocationReopen: React.FC = () => {
  const rows = [
    {
      title: t('item_1.title'),
      subtitle: t('item_1.subtitle'),
    },
    {
      title: t('item_2.title'),
      subtitle: t('item_2.subtitle'),
    },
    {
      title: t('item_3.title'),
      subtitle: t('item_3.subtitle'),
    },
  ]

  return (
    <InfoModalContent
      title={t('title')}
      subtitle={t('subtitle')}
      rows={rows}
      checkClassName="category-targeted"
    />
  )
}

export default LocationReopen
