import {
  GET_EXCLUSIVE_DEALS_SUCCESS,
  ResolvedAction,
} from 'actions/exclusiveDeals'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'
import { ImageUrlsT } from 'utilities/types'

export interface Fields {
  id: number
  name: string
  title: string
  description: string | null
  price: number
  quantity: number
  long_description: string | null
  order: number
  cover_image_urls: ImageUrlsT | null
}
// @ts-ignore
export default class ExclusiveDeal extends Model<typeof ExclusiveDeal, Fields> {
  static modelName: string = 'ExclusiveDeal'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    ExclusiveDeal: ModelType<ExclusiveDeal>
  ) {
    switch (action.type) {
      case GET_EXCLUSIVE_DEALS_SUCCESS:
        action.payload.data.exclusive_deals.forEach(exclusiveDeal => {
          ExclusiveDeal.upsert(exclusiveDeal)
        })
        break
      case RESET_DB:
        ExclusiveDeal.all().delete()
        break
      default:
        break
    }
  }
}
