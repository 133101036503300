import { Text } from '@thanx/uikit/text'
import ContentContainer from 'components/ContentContainer'
import PopoverContent, { Sections } from 'components/PopoverContent'
import React from 'react'

type Props = {
  className: string
  isErrored: boolean
  isLoading: boolean
  value: number | string
  sections?: Sections
}

const BodyCell: React.FC<Props> = props => {
  const { isErrored, isLoading, value, sections, className } = props

  return (
    <td className={`p-m align-top ${className}`}>
      <ContentContainer isErrored={isErrored} isLoading={isLoading}>
        <Text.BodyText4
          color="grey70"
          className="ellipsis d-inline-block max-width-100"
        >
          <span className="mr-xs">{value}</span>
          {sections && <PopoverContent sections={sections} />}
        </Text.BodyText4>
      </ContentContainer>
    </td>
  )
}

export default BodyCell
