import { Switch } from '@thanx/uikit/switch'
import MtmEffectivenessBanner from 'components/banners/MtmEffectiveness'
import TabPane from 'components/Tabs/TabPane'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectExperience } from 'selectors/pointsExperience'
import Content from './components/Content'
import EmptyState from './components/EmptyState'
import { getReportAccessState } from './helpers'

const EffectiveDiscountRate: React.FC = () => {
  const merchant = useCurrentMerchant()
  const pointsExperience = useSelector(selectExperience)

  const remainingDays = getReportAccessState(merchant, pointsExperience)
  const showEmptyState =
    remainingDays === 'coming_soon' ||
    remainingDays === 'points_inactive' ||
    remainingDays > 0

  return (
    <TabPane state="effective_discount_rate">
      <Switch
        condition={!showEmptyState}
        fallback={<EmptyState remainingDays={remainingDays} />}
      >
        <Content />
      </Switch>
      <MtmEffectivenessBanner className="mt-xxxl" />
    </TabPane>
  )
}

export default EffectiveDiscountRate
