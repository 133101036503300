import { buildTranslate } from 'locales'
import React from 'react'
import InfoModalContent from './InfoModalContent'

const t = buildTranslate('thanx_campaigns.builder.info_modal.engage_vip')

type Props = {
  merchantName: string
}
const EngageVip: React.FC<Props> = ({ merchantName }) => {
  const rows = [
    {
      title: t('item_1.title'),
      subtitle: t('item_1.subtitle', { merchant: merchantName }),
    },
    {
      title: t('item_2.title'),
      subtitle: t('item_2.subtitle'),
    },
    {
      title: t('item_3.title'),
      subtitle: t('item_3.subtitle'),
    },
  ]

  return (
    <InfoModalContent
      title={t('title')}
      subtitle={t('subtitle')}
      rows={rows}
      checkClassName="category-targeted"
    />
  )
}

export default EngageVip
