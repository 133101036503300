import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { numberFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
}

const Redeemed: React.FC<PropsT> = props => {
  const { campaignId } = props

  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'unscoped_redeem')
    ) as RedeemMetric) || {}

  return (
    <InfoRow
      title={t('rewards_accounting.redeemed')}
      primaryContent={
        <Metric metric={numberFormatter(metric?.data?.redeemed_count)}>
          <PopoverContent
            sections={[
              {
                content: t('rewards_accounting.tooltips.number_of_redeemed'),
              },
              {
                title: t('how_its_calc'),
                content: t('rewards_accounting.tooltips.number_redeemed_calc'),
              },
            ]}
          />
        </Metric>
      }
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default Redeemed
