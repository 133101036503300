import { getCampaignMetricByType } from 'actions/campaignMetrics'
import { alert } from 'actions/flash'
import { getLocations } from 'actions/location'
import useDispatch from 'hooks/useDispatch'
import type { Fields as Campaign } from 'models/Campaign'
import { Type as MetricType, UserCountMetric } from 'models/CampaignMetric'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { selectCurrentMerchantLocations } from 'selectors/location'
import View from './View'

type Props = {
  campaign: Campaign
}

const ReopenContainer: React.FC<Props> = ({ campaign }) => {
  const [isLoading, setIsLoading] = useState(true)
  const configLocationId = campaign.config_location_id
  const dispatch = useDispatch()
  const userCountMetric = useSelector(
    state =>
      selectCampaignMetricByType(
        state,
        campaign.id,
        MetricType.USER_COUNT
      ) as UserCountMetric | null
  )
  const locations = useSelector(selectCurrentMerchantLocations)

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(getLocations({ coming_soon: false }))
      setIsLoading(false)

      if (response.error) {
        const error = response.error?.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }
    }

    fetchData()
  }, [dispatch])

  const campaignId = campaign.id
  useEffect(() => {
    if (campaignId && configLocationId) {
      dispatch(getCampaignMetricByType(campaignId, 'user_count'))
    }
  }, [campaignId, configLocationId, dispatch])

  return (
    <View
      isLoading={isLoading}
      campaign={campaign}
      locations={locations}
      userCount={userCountMetric?.data?.count}
    />
  )
}

export default ReopenContainer
