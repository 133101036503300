import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import ExclamationTriangleO from 'components/Icons/ExclamationTriangleO'
import { buildTranslate } from 'locales'
import { merchantTheme } from 'theme'

const t = buildTranslate('feedbacks.responses.modal.alert_banner')

type Props = {
  merchantName?: string
  textColor?: string
}

const AlertBanner = (props: Props) => {
  const [css] = useStyletron()
  const { merchantName, textColor } = props

  return (
    <div
      className={`d-flex flex-row ${css({
        backgroundColor: merchantTheme.colors.cayenne10,
      })}`}
    >
      <div className="d-flex flex-row px-xl py-m">
        <div className="d-flex">
          <ExclamationTriangleO
            className={`pr-xs fa fa-lg fa-flag-o ${css({
              height: '14px',
              width: '30px',
            })}`}
            pathColor={merchantTheme.colors.cayenne50}
            testId={'icon-exclamation'}
          />
        </div>
        <div className="d-flex flex-column">
          <Text.SmallCaps3 bold className="mb-xs">
            {t('title')}
          </Text.SmallCaps3>
          <Text.BodyText4
            className={`${css({
              color: `${textColor} !important`,
            })}`}
          >
            {t('text', { merchantName: merchantName })}
          </Text.BodyText4>
        </div>
      </div>
    </div>
  )
}

export default AlertBanner
