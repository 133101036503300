import { Fields as CampaignRequirement } from 'models/CampaignRequirement'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectCampaignRequirements = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): CampaignRequirement[] => {
    return session.CampaignRequirement.all().toRefArray()
  }
)

export const selectCampaignRequirementsByCampaign = createSelector(
  state => selectCampaignRequirements(state),
  (_state, campaignId): number => campaignId,
  (requirements, campaignId): CampaignRequirement[] =>
    requirements.filter(requirement => requirement.campaign_id === campaignId)
)
