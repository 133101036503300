import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import playImg from 'assets/images/reports/revenue-capture-rate/crr-play.svg'
import Section from 'components/Section'
import React from 'react'
import { merchantTheme } from 'theme'

type PropsT = {
  title: string
  description: string
  className?: string
  linkUrl: string
}

const VideoLinkCard: React.FC<PropsT> = ({
  title,
  description,
  linkUrl,
  className = '',
}) => {
  const [css] = useStyletron()

  return (
    <Section
      className={`d-flex flex-wrap mb-xl ${css({
        minHeight: '72px',
        backgroundColor: merchantTheme.colors.grey90,
        color: merchantTheme.colors.white,
        boxShadow: '0 1rem 3rem rgba(54, 66, 78, 0.2)',
      })} ${className}`}
    >
      <div
        onClick={() => window.open(linkUrl)}
        role="button"
        className="w-100 d-flex"
      >
        <img src={playImg} alt="capture-rate-video" />
        <div className="pl-m d-flex align-items-center">
          <div>
            <Text.SmallCaps3 tag="div" color={merchantTheme.colors.white}>
              {title}
            </Text.SmallCaps3>
            <Text.BodyText4 color={merchantTheme.colors.white} bold>
              {description}
            </Text.BodyText4>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default VideoLinkCard
