// TODO: This component should be moved to a higher level and be independent of CRR.

import OneSchemaImporter from '@oneschema/react'
import { CREATE_FILE_UPLOAD_FAIL } from 'actions/fileUpload'
import { alert } from 'actions/flash'
import {
  createImport,
  getImporterConfig,
  GET_IMPORTER_CONFIG_SUCCESS,
  ImporterConfig,
} from 'actions/import'
import useDispatch from 'hooks/useDispatch'
import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { useUploadState } from './UploadStateContext'

type Props = {
  onError?: (message) => void
  onCancel?: () => void
  onRequestClose: () => void
  onReady?: () => void
  isOpen: boolean
}

const Importer: React.FC<Props> = ({
  onError,
  onCancel,
  onRequestClose,
  onReady,
  isOpen = true,
}) => {
  const dispatch = useDispatch()
  const [importConfig, setImporterConfig] = useState<ImporterConfig>()
  const { onFileUploaded } = useUploadState()

  useEffect(() => {
    async function fetch() {
      const response = await dispatch(getImporterConfig())
      if (response.type === GET_IMPORTER_CONFIG_SUCCESS) {
        setImporterConfig(response.payload?.data)
      } else {
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('errors.thanx'),
            error: response.error?.message,
          })
        )
      }
    }

    if (!importConfig && isOpen) {
      fetch()
    }
  }, [dispatch, importConfig, isOpen])

  const onSuccess = async ({ embed_id, sheet_id }) => {
    const fileResponse = await dispatch(createImport(embed_id, sheet_id))
    if (fileResponse.type === CREATE_FILE_UPLOAD_FAIL) {
      onError?.(fileResponse.error?.response?.data?.error || null)
    } else {
      onFileUploaded(fileResponse.payload.data.file_upload.id)
    }
  }

  if (!importConfig) return null
  return (
    <OneSchemaImporter
      isOpen={isOpen}
      clientId={importConfig.client_id}
      userJwt={importConfig.jwt}
      templateKey={importConfig.template_key}
      importConfig={{ type: 'local', metadataOnly: true }}
      devMode={false}
      className="importer"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1000,
      }}
      onRequestClose={onRequestClose}
      onSuccess={onSuccess}
      onCancel={onCancel}
      onError={onError}
      onLaunched={onReady}
    />
  )
}

export default Importer
