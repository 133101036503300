import { Fields as Redeem } from 'models/Redeem'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_REDEEMS = 'redeems/GET_REDEEMS'
export const GET_REDEEMS_SUCCESS = 'redeems/GET_REDEEMS_SUCCESS'
export const GET_REDEEMS_FAIL = 'redeems/GET_REDEEMS_FAIL'

export function getRedeems(): AxiosAction<
  typeof GET_REDEEMS,
  { redeems: Redeem[] }
> {
  return {
    type: GET_REDEEMS,
    payload: {
      client: 'default',
      request: {
        url: 'redeems',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<ReturnType<typeof getRedeems>>
