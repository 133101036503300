import { Switch } from '@thanx/uikit/switch'
import withSteps from 'components/withSteps'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useSelector } from 'react-redux'
import ContinueButton from 'scenes/CampaignCenter/Builder/components/ContinueButton'
import TopBar from 'scenes/CampaignCenter/Builder/components/TopBar'
import Incentive from 'scenes/CampaignCenter/Builder/Steps/Shared/Incentive'
import Messaging from 'scenes/CampaignCenter/Builder/Steps/Shared/Messaging'
import InfoModal from 'scenes/CampaignCenter/components/InfoModal'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import Audience from './Substeps/Audience'

type Props = {
  campaign: Campaign
  isSaving: boolean
  canProceed: boolean
  triggerSave: () => Promise<void>
  setCurrentStep: (step: string) => void
  showValidationErrors: () => void
  nextStep: () => void
  currentStepName: string
}

const Setup: React.FC<Props> = props => {
  const {
    campaign,
    isSaving,
    canProceed,
    triggerSave,
    setCurrentStep,
    showValidationErrors,
    nextStep,
    currentStepName,
  } = props

  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )

  function onContinueClick() {
    if (currentStepName === 'incentive') {
      setCurrentStep('review')
    } else {
      nextStep()
    }
  }

  const campaignCategory = campaignType?.category
  return (
    <div>
      <TopBar
        triggerSave={triggerSave}
        campaign={campaign}
        infoModal={
          <InfoModal
            className="pull-left padding-top-micro margin-right-medium"
            campaignType={campaign?.type}
            campaignCategory={campaignCategory}
            withinBuilder
          >
            <i
              style={{ marginTop: '10px' }}
              className="fa fa-info-circle fa-2x cursor-pointer"
            />
          </InfoModal>
        }
      >
        <ContinueButton onClick={onContinueClick} />
      </TopBar>
      <div className="container">
        <Switch condition={currentStepName === 'audience'}>
          <Audience campaign={campaign} />
        </Switch>
        <Switch condition={currentStepName === 'incentive'}>
          <Incentive
            campaign={campaign}
            canProceed={canProceed}
            showValidationErrors={showValidationErrors}
            isSaving={isSaving}
            triggerSave={triggerSave}
          />
        </Switch>
        <Switch condition={currentStepName === 'messaging'}>
          <Messaging
            campaign={campaign}
            canProceed={canProceed}
            showValidationErrors={showValidationErrors}
          />
        </Switch>
      </div>
    </div>
  )
}

export default withSteps(['audience', 'incentive'])(Setup)
