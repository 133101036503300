import React from 'react'
import { I18n } from 'react-redux-i18n'
import styles from '../CampaignSection/CampaignSection.module.scss'

const NoSegmentCard: React.FC = () => {
  return (
    <div className="full-height">
      <div
        className={`
          ${styles.campaignCard} ${styles.disabled}
          full-height border-radius-5 z1up d-flex flex-column
        `}
      >
        <div className="flex-grow-1 padding-large d-flex flex-column justify-content-between text-center row padding-huge">
          <div className="body-text-2 line-height-30 align-self-center">
            {I18n.t('thanx_campaigns.landing.targeted.custom.none')}
          </div>
        </div>
      </div>
      <div className="border-radius-10-bottom padding-top-tiny grey-20-bkg relative nudge-up-2 z0up width-95 center-block flex-grow-0" />
    </div>
  )
}

export default NoSegmentCard
