import React from 'react'
import styles from './CampaignSection.module.scss'

type Props = {
  title: React.ReactNode
  description: React.ReactNode
  image: string
  alt: string
  badge: null | React.ReactNode
  onClick?: () => void
}

const CampaignCard: React.FC<Props> = props => {
  const { title, description, image, alt, badge, onClick } = props
  return (
    <div
      className={`
        ${styles.campaignCard} ${styles.automatedCard}
        full-height cursor-pointer
      `}
      onClick={onClick}
    >
      <div className={styles.campaignCardHeader}>
        <div className="pull-left margin-extra-large">{badge}</div>
        <img
          src={image}
          className={`${styles.cardImage} pull-right`}
          alt={alt}
        />
      </div>
      <div className="margin-extra-large padding-left-large">
        <h2 className="deprecated__h2 extra-bold font-size-44 margin-bottom-extra-large">
          {title}
        </h2>
        <hr className="pipe spearmint-50-bkg" />
        <p className="deprecated__body-text-2 grey-70 extra-bold">
          {description}
        </p>
      </div>
    </div>
  )
}

CampaignCard.defaultProps = {
  badge: null,
}

export default CampaignCard
