import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import { getApp } from 'actions/app'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'

type Props = {
  className?: string
}

const TermsOfService = ({ className = '' }: Props) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const app = useSelector(selectApp)

  useEffect(() => {
    dispatch(getApp())
  }, [dispatch])

  const tosUrl = app?.terms_of_service_url

  return tosUrl ? (
    <Text.BodyText4 tag="div" color="grey70" className={className}>
      Restrictions may apply.{' '}
      <a
        href={tosUrl}
        target="_blank"
        rel="noreferrer"
        className={css({
          color: defaultTheme.colors.grey70,
          textDecoration: 'underline',
        })}
      >
        See terms and conditions
      </a>
    </Text.BodyText4>
  ) : null
}

export default TermsOfService
