import React from 'react'

type Props = {
  label: string
  value?: string
  className?: string
}

const ItemCol: React.FC<Props> = props => {
  const { label, value, className = 'flex-1 mr-m' } = props

  return (
    <div className={className}>
      <p className="mb-xs bold font-size-14">{label}</p>
      <p className="font-size-12 grey-70">{value || '-'}</p>
    </div>
  )
}

export default ItemCol
