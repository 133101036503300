import { VariantOrCampaign } from 'models/CampaignVariant'
import { createContext, useContext } from 'react'

type ContextT = {
  config: VariantOrCampaign
  setConfig: (VariantOrCampaign) => void
}

const Context = createContext<ContextT | null>(null)

export function useCampaignConfig() {
  const value = useContext(Context)

  if (value === null) {
    const errorInfo =
      'useCampaignConfig must be used within a CampaignConfigContext.Provider'
    throw errorInfo
  }

  return value
}

export default Context
