import { getActivationRateMetrics } from 'actions/activationRateMetrics'
import {
  getCampaignMetricByType,
  getCampaignMetricEvents,
} from 'actions/campaignMetrics'
import { getTimeSeriesCaptureRateMetrics } from 'actions/captureRateMetrics'
import { getEffectiveDiscountRateMetrics } from 'actions/effectiveDiscountRate'
import {
  getMerchantMetricByType,
  getMerchantMetricTimeSeries,
} from 'actions/merchantMetrics'
import { getPointsExperienceMetricByType } from 'actions/pointsExperienceMetrics'
import { getPurchasesMetrics } from 'actions/purchasesMetrics'
import { getRetentionRateMetrics } from 'actions/retentionRateMetric'
import { DateRangeFilter } from 'components/DateFilter/utilities'
import { MetricTypes as ActivationRateMetricTypes } from 'models/ActivationRateMetric'
import { EventTypes, MetricsWithCustomAttribution } from 'models/CampaignMetric'
import { MetricTypes as CampaignMetricTypes } from 'models/CampaignMetric'
import { MetricTypes as EffectiveDiscountRateMetricTypes } from 'models/EffectiveDiscountRateMetric'
import { LifecycleShortType } from 'models/MerchantMetric'
import { MetricTypes as PointsExperienceMetricTypes } from 'models/PointsExperienceMetric'
import { MetricTypes as PurchasesMetricTypes } from 'models/PurchasesMetric'
import { MetricTypes as RetentionRateMetricTypes } from 'models/RetentionRateMetric'
import moment from 'moment-timezone'
import React, { Dispatch } from 'react'

type PointsExperienceMetricT = {
  type: 'points_experience'
  metricTypes: PointsExperienceMetricTypes
  pointsExperienceId: number
  pointsExperienceActivatedAt: string | null
  dateRangeFilter: DateRangeFilter
  timeZone: string
}

type CampaignT = {
  campaignId: number
  variantIds?: number[]
}

type CampaignMetricT = CampaignT & {
  type: 'campaign_metric'
  metricTypes: CampaignMetricTypes
  attributionWindow?: number | null
}

type CampaignEventT = CampaignT & {
  type: 'campaign_event'
  dateRangeFilter: DateRangeFilter
  attributionWindow: number
  types: EventTypes[]
}

type LifecycleMetricT = {
  type: 'lifecycle_metric'
  merchantId: number | undefined
  metricType: LifecycleShortType
}

type LifecycleTimeSeriesMetricT = {
  type: 'lifecycle_time_series_metric'
  merchantId: number | undefined
  metric: string
  timespan: string
}

type CaptureRateTimeSeriesMetricT = {
  type: 'capture_rate_time_series_metric'
  merchantId?: number
  locationId?: number
  filterByChannel?: boolean
}

type ActivationRateMetricT = {
  type: 'activation_rate_metric'
  merchantId?: number
  metricTypes: ActivationRateMetricTypes
}

type RetentionRateMetricT = {
  type: 'retention_rate_metric'
  merchantId?: number
  metricTypes: RetentionRateMetricTypes
}

type EffectiveDiscountRateT = {
  type: 'effective_discount_rate_metric'
  merchantId?: number
  metricTypes: EffectiveDiscountRateMetricTypes
}

type PurchasesMetricT = {
  type: 'purchases_metric'
  merchantId?: number
  metricTypes: PurchasesMetricTypes
  dateRangeFilter: DateRangeFilter
}

type CustomPropsT =
  | PointsExperienceMetricT
  | CampaignMetricT
  | CampaignEventT
  | LifecycleMetricT
  | LifecycleTimeSeriesMetricT
  | CaptureRateTimeSeriesMetricT
  | ActivationRateMetricT
  | RetentionRateMetricT
  | EffectiveDiscountRateT
  | PurchasesMetricT

type SharedPropsT = {
  children?: React.ReactNode
  shouldFetch: boolean
  className?: string
  // prop available to make it easy to force a refresh without
  // inputs changing
  reFetchAt?: number | null
  isCustomAutomated?: boolean
}

export type FetchableMetricPropsT = SharedPropsT & CustomPropsT

export const fetchCampaignMetrics = ({
  dispatch,
  metricTypes,
  campaignId,
  variantIds,
  attributionWindow,
}: {
  dispatch: Dispatch<any>
  metricTypes: any[]
  campaignId: number
  variantIds?: number[] | undefined
  attributionWindow?: number
}) => {
  const promises = metricTypes.map(metricType => {
    const params = MetricsWithCustomAttribution.includes(metricType)
      ? {
          attribution_window: attributionWindow,
        }
      : {}
    if (metricType !== 'variant_count' && variantIds) {
      return variantIds.map(variantId =>
        dispatch(
          getCampaignMetricByType(campaignId, metricType, {
            ...params,
            variant_id: variantId,
          })
        )
      )
    }

    return dispatch(getCampaignMetricByType(campaignId, metricType, params))
  })
  return Promise.all(promises)
}

const fetchPointsExperienceMetrics = ({
  dispatch,
  metricTypes,
  pointsExperienceId,
  dateRangeFilter,
  pointsExperienceActivatedAt,
  timeZone,
}: {
  dispatch: Dispatch<any>
  metricTypes: any[]
  pointsExperienceId: number
  dateRangeFilter: DateRangeFilter
  pointsExperienceActivatedAt: string | null
  timeZone: string
}) => {
  const {
    start: query_start_date,
    end: query_end_date,
    granularity,
    range,
  } = dateRangeFilter

  const allTimeStartDate = ((): string | null => {
    if (range === 'all_time' && pointsExperienceActivatedAt) {
      return moment
        .tz(pointsExperienceActivatedAt, timeZone)
        .format('YYYY-MM-DD')
    }

    if (query_start_date && pointsExperienceActivatedAt) {
      return moment
        .min(
          moment.tz(pointsExperienceActivatedAt, timeZone),
          moment.tz(query_start_date, timeZone)
        )
        .format('YYYY-MM-DD')
    }

    return null
  })()

  const promises = metricTypes.map(metricType => {
    const reqs = [
      dispatch(
        getPointsExperienceMetricByType(metricType, pointsExperienceId, true, {
          query_start_date: allTimeStartDate,
          query_end_date: range === 'all_time' ? null : query_end_date,
          granularity: range === 'all_time' ? null : granularity,
          range,
        })
      ),
    ]

    if (range === 'custom') {
      reqs.push(
        dispatch(
          getPointsExperienceMetricByType(
            metricType,
            pointsExperienceId,
            false,
            {
              query_start_date,
              query_end_date,
              granularity,
              range,
            }
          )
        )
      )
    }

    return reqs
  })

  return Promise.all(promises)
}

const fetchCampaignEventsMetrics = ({
  dispatch,
  campaignId,
  types,
  variantIds,
  dateRangeFilter,
  attributionWindow,
  isCustomAutomated = true,
}: {
  dispatch: any
  campaignId: number
  types: EventTypes[]
  variantIds?: number[] | undefined
  dateRangeFilter: DateRangeFilter
  attributionWindow: number
  isCustomAutomated?: boolean
}) => {
  const {
    start: query_start_date,
    end: query_end_date,
    granularity,
    range,
  } = dateRangeFilter ?? {}

  let params =
    query_start_date && query_end_date && granularity
      ? {
          granularity,
          query_start_date,
          query_end_date,
          range,
          attribution_window: attributionWindow,
        }
      : { attribution_window: attributionWindow }

  if (!isCustomAutomated) {
    params.query_end_date = moment(query_start_date)
      .add(attributionWindow, 'days')
      .format('YYYY-MM-DD')
  }

  if (variantIds) {
    return types.map(type =>
      variantIds.forEach(variantId =>
        dispatch(
          getCampaignMetricEvents(campaignId, {
            ...params,
            variant_id: variantId,
            type,
          })
        )
      )
    )
  } else {
    return types.map(type =>
      dispatch(getCampaignMetricEvents(campaignId, { ...params, type }))
    )
  }
}

const fetchLifecycleMetric = ({
  dispatch,
  metricType,
  merchantId,
}: {
  dispatch: Dispatch<any>
  metricType: LifecycleShortType
  merchantId?: number
}) => {
  const promise = dispatch(
    getMerchantMetricByType(metricType, { merchant_id: merchantId })
  )
  Promise.resolve(promise)
}

const fetchLifecycleTimeSeriesMetric = ({
  dispatch,
  merchantId,
  timespan,
  metric,
}: {
  dispatch: Dispatch<any>
  merchantId?: number
  timespan: string
  metric: string
}) => {
  const promise = dispatch(
    getMerchantMetricTimeSeries({
      merchant_id: merchantId,
      timespan,
      type: metric,
    })
  )
  Promise.resolve(promise)
}

const fetchCaptureRateTimeSeriesMetric = ({
  dispatch,
  locationId,
  filterByChannel = false,
  merchantId,
}: {
  dispatch: Dispatch<any>
  filterByChannel?: boolean
  merchantId?: number
  locationId?: number
}) => {
  const promise = dispatch(
    getTimeSeriesCaptureRateMetrics({
      merchant_id: merchantId,
      location_id: locationId,
      filter_channel: filterByChannel,
    })
  )
  Promise.resolve(promise)
}

const fetchActivationRateMetrics = ({
  dispatch,
  merchantId,
  metricTypes,
}: {
  dispatch: Dispatch<any>
  merchantId?: number
  metricTypes: ActivationRateMetricTypes
}) => {
  const promises = metricTypes.map(metricType =>
    dispatch(getActivationRateMetrics(metricType, { merchant_id: merchantId }))
  )
  Promise.all(promises)
}

const fetchRetentionRateMetrics = ({
  dispatch,
  merchantId,
  metricTypes,
}: {
  dispatch: Dispatch<any>
  merchantId?: number
  metricTypes: RetentionRateMetricTypes
}) => {
  const promises = metricTypes.map(metricType =>
    dispatch(getRetentionRateMetrics(metricType, { merchant_id: merchantId }))
  )
  Promise.all(promises)
}

const fetchEffectiveDiscountRateMetrics = ({
  dispatch,
  merchantId,
  metricTypes,
}: {
  dispatch: Dispatch<any>
  merchantId?: number
  metricTypes: EffectiveDiscountRateMetricTypes
}) => {
  const promises = metricTypes.map(metricType =>
    dispatch(
      getEffectiveDiscountRateMetrics(metricType, { merchant_id: merchantId })
    )
  )
  Promise.all(promises)
}

const fetchPurchasesMetrics = ({
  dispatch,
  merchantId,
  metricTypes,
  dateRangeFilter,
}: {
  dispatch: Dispatch<any>
  merchantId?: number
  metricTypes: PurchasesMetricTypes
  dateRangeFilter: DateRangeFilter
}) => {
  const {
    range,
    start: query_start_date,
    end: query_end_date,
  } = dateRangeFilter

  const promises = metricTypes.map(metricType =>
    dispatch(
      getPurchasesMetrics(metricType, {
        merchant_id: merchantId,
        range,
        query_start_date,
        query_end_date,
      })
    )
  )
  Promise.all(promises)
}

export const fetchableMetrics: Record<
  CustomPropsT['type'],
  (options: any) => void
> = {
  campaign_metric: fetchCampaignMetrics,
  campaign_event: fetchCampaignEventsMetrics,
  points_experience: fetchPointsExperienceMetrics,
  lifecycle_metric: fetchLifecycleMetric,
  lifecycle_time_series_metric: fetchLifecycleTimeSeriesMetric,
  capture_rate_time_series_metric: fetchCaptureRateTimeSeriesMetric,
  activation_rate_metric: fetchActivationRateMetrics,
  retention_rate_metric: fetchRetentionRateMetrics,
  effective_discount_rate_metric: fetchEffectiveDiscountRateMetrics,
  purchases_metric: fetchPurchasesMetrics,
}
