import { Button, Kind, Size } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Help from 'components/Help'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import React from 'react'
import { push } from 'react-router-redux'
import { helpArticles } from '../helpers'
import ImageInstructions from './ImageInstructions'
import TextInstructions from './TextInstructions'

type Props = {
  canEdit: boolean
  pointsExperience: PointsExperience
}

const t = buildTranslate('points.configuration.instructions')
const ht = buildTranslate('points.configuration.help')

const Instructions: React.FC<Props> = ({ canEdit, pointsExperience }) => {
  const { earn_description: earnDescription, earn_image: earnImage } =
    pointsExperience
  const [css] = useStyletron()

  const dispatch = useDispatch()

  return (
    <section className="grey-90">
      <Text.Header4 tag="div" className="mb-xs">
        {t('title')}
      </Text.Header4>
      <Text.BodyText3
        tag="div"
        className={`mb-xl ${css({ lineHeight: '24px' })}`}
        color="grey70"
      >
        {t('subtitle')}
      </Text.BodyText3>
      <div className="card mb-m p-m white-bkg d-flex flex-row">
        <div className="flex-grow-1">
          <Switch
            condition={!!earnImage?.large}
            fallback={
              <TextInstructions
                title={t('show_text_title')}
                description={earnDescription}
              />
            }
          >
            <ImageInstructions image={earnImage?.large} />
          </Switch>
        </div>
        {canEdit && (
          <Button
            kind={Kind.SECONDARY}
            size={Size.SMALL}
            className={css({ height: '32px' })}
            onClick={() =>
              dispatch(
                push(
                  `/points/experiences/${pointsExperience.id}/instructions/edit`
                )
              )
            }
          >
            {t('edit')}
          </Button>
        )}
      </div>

      <Help title={ht('title')} articles={helpArticles.instructions} />
    </section>
  )
}

export default Instructions
