import { Select } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import SearchInput from 'components/SearchInput'
import SideNav from 'components/SideNav'
import { useLocale } from 'hooks/useLocale'
import { buildTranslate } from 'locales'
import { Fields as TargetSegment } from 'models/TargetSegment'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { selectTargetSegments } from 'selectors/targetSegment'
import { sortCategoryNames } from 'utilities/segmentCategories'
import Cards from './Cards'
import CardsByCategory from './CardsByCategory'
import Header from './Header'

const t = buildTranslate('thanx_campaigns.landing.segments')

type Props = {
  onNewClick: () => void
  onExistingClick: (segmentId: number) => void
}

const types = {
  all: t('type_filter.all'),
  merchant: t('type_filter.merchant'),
  global: t('type_filter.global'),
}

const extractSearchTerms = (search: string) =>
  search.replace(/\s+/g, ' ').trim().toLowerCase().split(' ')

const useFilteredSegments = ({
  allSegments,
  search,
  type,
  category,
}): TargetSegment[] => {
  const [segments, setSegments] = useState(allSegments)
  if (search !== '') {
    type = 'all'
    category = 'all'
  }

  useEffect(() => {
    const searchTerms = extractSearchTerms(search)

    const filteredSegments = allSegments.filter(segment => {
      if (type !== 'all' && segment.type !== type) {
        return false
      }
      if (
        searchTerms.length > 0 &&
        !searchTerms.every(term => segment.name.toLowerCase().includes(term))
      ) {
        return false
      }
      const segmentCategory = segment.category ?? '' // category is nullable
      if (category !== 'all' && segmentCategory !== category) {
        return false
      }
      return true
    })

    setSegments(filteredSegments)
  }, [search, type, category, allSegments])

  return segments
}

const EmptyStateWrapper = ({ segments, children }) => (
  <Switch
    condition={segments.length > 0}
    fallback={<div className="my-xl">{t('empty')}</div>}
  >
    {children}
  </Switch>
)

const TypeFilter = ({ type, setType }) => {
  const options = Object.entries(types).map(([value, label]) => ({
    value,
    label,
  }))

  const currentOption = { value: type, label: types[type] }

  return (
    <Select
      options={options}
      value={currentOption}
      onChange={selectedOptions => {
        setType(selectedOptions[0]['value'])
      }}
      zIndex={2}
      searchable={false}
    />
  )
}

const Segments: React.FC<Props> = ({ onNewClick, onExistingClick }) => {
  const allSegments = useSelector(state => selectTargetSegments(state))

  const [type, setType] = useState('all')
  const [search, setSearch] = useState('')
  const categories = allSegments.reduce((all: string[], segment) => {
    const category = segment.category ?? ''
    if (all.includes(category)) {
      return all
    }
    return [...all, category]
  }, [])
  const [category, setCategory] = useState('all')

  const segments = useFilteredSegments({ allSegments, search, type, category })
  const locale = useLocale()

  return (
    <div className="grey-05-bkg pt-xl pb-m">
      <Container>
        <Header onNewClick={onNewClick} />

        <Row>
          <Col>
            <SearchInput value={search} onChange={setSearch} />
          </Col>
        </Row>

        <Switch
          condition={!search}
          fallback={
            <EmptyStateWrapper segments={segments}>
              <div className="mt-l">
                <Cards segments={segments} onClick={onExistingClick} cols={4} />
              </div>
            </EmptyStateWrapper>
          }
        >
          <Row className="mt-xl">
            <Col md={3} className="pr-l">
              <SideNav
                items={sortCategoryNames(categories, locale)}
                current={category}
                all={t('all_segments')}
                onClick={setCategory}
              />
            </Col>
            <Col>
              <Row>
                <Col>
                  <Text.Header3 className="mt-0 mb-s">
                    {category === 'all'
                      ? t('all_segments')
                      : category || t('other')}
                  </Text.Header3>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <TypeFilter type={type} setType={setType} />
                </Col>
              </Row>

              <EmptyStateWrapper segments={segments}>
                <CardsByCategory
                  segments={segments}
                  onClick={onExistingClick}
                  showCategory={category === 'all'}
                />
              </EmptyStateWrapper>
            </Col>
          </Row>
        </Switch>
      </Container>
    </div>
  )
}

export default Segments
