import range from 'lodash/range'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import DatePicker from 'react-16-bootstrap-date-picker'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useUserTimeZone } from 'utilities/userTimeZone'

type Props = {
  defaultValue?: moment.Moment
  isValid?: boolean
  onChange?: (datetime: moment.Moment) => void
  calendarPlacement?: 'top' | 'bottom'
}

/**
 * Uncontrolled component that displays time and date selects that allow
 * the user to select a datetime.
 * Currently this only allows choosing times in the future, but it should
 * be extended to have a min and max time, so feel free to do that.
 */
const DatetimePicker: React.FC<Props> = props => {
  const {
    defaultValue,
    isValid = true,
    onChange: propsOnChange,
    calendarPlacement = 'bottom',
  } = props

  const timeZone = useUserTimeZone()

  // DatePicker doesn't work correctly as a controlled component, so this
  // has to be uncontrolled.
  const initialValue = defaultValue?.isValid()
    ? defaultValue
    : moment.tz(timeZone)
  const [datetime, setDatetime] = useState<moment.Moment>(initialValue)
  const timeOptions = range(24).map(hour => {
    const time = datetime.clone().hour(hour).startOf('hour')
    const display = (function () {
      if (hour === 0) return time.format('[Midnight] z')
      if (hour === 12) return time.format('[Noon] z')
      return time.format('h A z')
    })()
    const disabled = time.isBefore(moment.tz(timeZone))

    return (
      <option
        key={hour}
        data-testid={`hour-${hour}`}
        value={time.toISOString()}
        disabled={disabled}
      >
        {display}
      </option>
    )
  })

  function handleTimeChange(event) {
    const isoString = event.target.value
    const newTime = moment.tz(isoString, timeZone)
    if (!newTime.isValid()) return
    propsOnChange && propsOnChange(newTime)
    setDatetime(newTime)
  }

  function handleDateChange(value: string): void {
    const date = moment.tz(value, timeZone)
    if (!date.isValid()) return

    const newTime = datetime
      .clone()
      .set({ year: date.year(), month: date.month(), date: date.date() })
    propsOnChange && propsOnChange(newTime)
    setDatetime(newTime)
  }

  // Our datepicker is janky and doesn't handle offsets in iso strings. It also
  // forces times to be noon, so we have to offset to get things working
  // properly
  function minDateOffset(value: moment.Moment): moment.Moment {
    const offset = value.utcOffset()
    return value.add(offset < 0 ? 1 : 0, 'days')
  }

  function toDatepickerValue(value: moment.Moment): string {
    return value.format('YYYY-MM-DD')
  }

  return (
    <div>
      <div className="mr-m inline-block" style={{ width: '180px' }}>
        <InputGroup className={isValid ? '' : 'has-error'}>
          <FormControl
            data-testid="time-select"
            componentClass="select"
            name="hour"
            defaultValue={datetime.clone().startOf('hour').toISOString()}
            onChange={handleTimeChange}
          >
            {timeOptions}
          </FormControl>
          <InputGroup.Addon>
            <i className="fa fa-clock-o" />
          </InputGroup.Addon>
        </InputGroup>
      </div>

      <div className="inline-block" style={{ width: '180px' }}>
        <InputGroup>
          <DatePicker
            minDate={toDatepickerValue(minDateOffset(moment.tz(timeZone)))}
            value={toDatepickerValue(datetime)}
            showClearButton={false}
            onChange={handleDateChange}
            calendarPlacement={calendarPlacement}
          />
          <InputGroup.Addon>
            <i className="fa fa-calendar" />
          </InputGroup.Addon>
        </InputGroup>
      </div>
    </div>
  )
}

export default DatetimePicker
