import palette from 'constants/palette'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import { selectMerchant } from 'selectors/merchant'
import { textUtils } from 'utilities/textUtils'

type Props = {
  campaign: Campaign
  includePlaceholders?: boolean
}

const Email: React.FC<Props> = ({ campaign, includePlaceholders = false }) => {
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const campaignMerchant = useSelector(state =>
    selectMerchant(state, campaign.merchant_id)
  )

  const campaignPlaceholders = campaignType?.placeholders

  const values = {
    notify_email_subject:
      campaign.notify_email_subject ||
      (includePlaceholders ? campaignPlaceholders?.notify_email_subject : ''),
    notify_email_preview_text:
      campaign.notify_email_preview_text ||
      (includePlaceholders
        ? campaignPlaceholders?.notify_email_preview_text
        : ''),
  }

  function heroImage() {
    if (!campaign.notify_email_hero_image_included) return null
    return (
      <img
        src={campaign.notify_email_hero_image_urls.default}
        className="hero-image"
        alt="Hero"
      />
    )
  }

  function headerImage() {
    if (!campaign.notify_email_header_image_included) return null
    return (
      <div className="text-center header-image">
        <img
          src={campaign.notify_email_header_image_urls.default}
          alt="Header"
        />
      </div>
    )
  }

  function headingText() {
    if (campaign.notify_email_heading === '') {
      return (
        <span className="grey-40">
          {campaignPlaceholders?.notify_email_heading}
        </span>
      )
    }
    return <span>{campaign.notify_email_heading}</span>
  }

  function body() {
    if (campaign.notify_email_body === '<p><br></p>') {
      return (
        <div
          className="content grey-40"
          dangerouslySetInnerHTML={{
            __html: `<p>${campaignPlaceholders?.notify_email_body}</p>`,
          }}
        />
      )
    }
    if (campaign.notify_email_body) {
      return (
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: campaign.notify_email_body,
          }}
        />
      )
    }
  }

  function button() {
    if (
      !campaign.notify_email_button_text ||
      !campaign.notify_email_button_url
    ) {
      return <div />
    }

    return (
      <div className="text-center bottom-container">
        <Button
          style={{
            backgroundColor: campaign.notify_email_link_color,
            color: campaign.notify_email_button_text_color,
          }}
        >
          {campaign.notify_email_button_text}
        </Button>
      </div>
    )
  }

  function conversionBody() {
    if (!campaign.notify_email_conversion || campaign.redeem) return null
    return (
      <div>
        {separator()}
        <div className="text-center">
          <div className="header-text font-size-20">
            <Translate value="thanx_campaigns.builder.previews.email.join_header" />
          </div>
          <div
            className="content font-size-16"
            style={{ marginBottom: '27px' }}
          >
            <Translate
              value="thanx_campaigns.builder.previews.email.join_body"
              redeemDescription={getRedeemDescription()}
            />
          </div>
        </div>
      </div>
    )
  }

  function separator() {
    // Do not display a separator if there arent any elements on top
    if (
      campaign.notify_email_heading === null &&
      !campaign.notify_email_body &&
      (!campaign.notify_email_button_text || !campaign.notify_email_button_url)
    ) {
      return null
    }

    return (
      <hr
        style={{
          marginLeft: '18px',
          marginRight: '18px',
          borderTop: '1px solid #dddddd',
        }}
        data-testid="separator"
      />
    )
  }

  function getRedeemDescription() {
    if (campaign.redeem) {
      return textUtils.articleize(campaign.redeem_description)
    } else if (
      campaign.notify_email_conversion &&
      campaignMerchant?.intro_description
    ) {
      return textUtils.articleize(campaignMerchant?.intro_description)
    }
    return null
  }

  function rewardContainer() {
    if (!campaign.redeem && !campaign.notify_email_conversion) return null
    let finePrint, buttonText, headerText, subheaderText
    const redeemDescription = getRedeemDescription()

    if (redeemDescription) {
      headerText = `Get ${redeemDescription}`
    }
    if (campaign.redeem) {
      finePrint = campaign.fine_print
      buttonText = I18n.t(
        'thanx_campaigns.builder.previews.email.button_incentive'
      )
      subheaderText = campaign.redeem_restriction_description
    } else if (campaign.notify_email_conversion) {
      finePrint = I18n.t(
        'thanx_campaigns.builder.previews.email.default_small_text'
      )
      buttonText = I18n.t('thanx_campaigns.builder.previews.email.button_intro')
      subheaderText = I18n.t(
        'thanx_campaigns.builder.previews.email.subheader_intro'
      )
    }

    return (
      <div className="reward-content bottom-container">
        <div
          className="box text-center"
          style={{
            backgroundColor:
              campaign.notify_email_redeem_box_color || '#818a91',
            color: campaign.notify_email_redeem_box_text_color || palette.white,
          }}
        >
          <div className="text-container">
            <div className="title bold">{headerText}</div>
            <div className="subtitle">{subheaderText}</div>
          </div>
          <Button
            style={{
              backgroundColor:
                campaign.notify_email_redeem_button_color || palette.white,
              color:
                campaign.notify_email_redeem_button_text_color || '#818a91',
            }}
          >
            {buttonText}
          </Button>
        </div>
        <span className="fine-print block">{finePrint}</span>
      </div>
    )
  }

  return (
    <div className="email-preview">
      <div className="padding-medium font-size-14 ellipsis border-1 grey-30-border">
        <i className="fa fa-square-o grey-50 margin-right-small" />
        <span className="inline-block margin-right-medium">
          {values.notify_email_subject}
        </span>
        <span className="grey-50">{values.notify_email_preview_text}</span>
      </div>
      {heroImage()}
      {headerImage()}
      <div className="text-center">
        <div className="header-text">{headingText()}</div>
        {body()}
      </div>
      <div className="bottom-content">
        {button()}
        {conversionBody()}
        {rewardContainer()}
      </div>
      <div className="text-center email-footer">
        <Translate
          value="emails.footer"
          className="text-center"
          merchantName={campaignMerchant?.name}
          dangerousHTML
        />
      </div>
    </div>
  )
}

export default Email
