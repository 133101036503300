import React from 'react'
import { Error } from './Message'

type Props = {
  error: Error
}

const ErrorList: React.FC<Props> = ({ error }) => {
  return (
    <ul>
      {typeof error === 'string' ? (
        <li>{error}</li>
      ) : (
        error.map((error, index) => {
          return <li key={index}>{error}</li>
        })
      )}
    </ul>
  )
}

export default ErrorList
