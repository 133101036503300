import { ResolvedAction } from 'actions/merchantCouponPools'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'
import { ApiFields } from './CouponPool'

export interface Fields extends ApiFields {}

// @ts-ignore
export default class MerchantCouponPool extends Model<
  // @ts-ignore
  typeof MerchantCouponPool,
  Fields
> {
  static modelName: string = 'MerchantCouponPool'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    MerchantCouponPool: ModelType<MerchantCouponPool>
  ): void {
    switch (action.type) {
      case 'merchantCouponPool/GET_ALL_SUCCESS': {
        MerchantCouponPool.all().delete()
        action.payload.data.coupon_pools.forEach(couponPool => {
          MerchantCouponPool.upsert(couponPool)
        })
        break
      }
      case RESET_DB:
        MerchantCouponPool.all().delete()
        break
      default:
        break
    }
  }
}
