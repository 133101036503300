import { Radio, RadioGroup } from '@thanx/uikit/radio'
import { Switch } from '@thanx/uikit/switch'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import { buildTranslate } from 'locales'
import type { CampaignConfig } from 'models/Campaign'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import RestrictionSection from './RestrictionSection'

type PropsT = { config: CampaignConfig } & PassDownProps<string>

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator.redemption_venue'
)

const RedemptionVenue: React.FC<PropsT> = (props: PropsT) => {
  const { config, setValue } = props
  const template = useSelector((state: any) =>
    selectRedeemTemplate(state, config.redeem_template_id)
  )

  if (!template) return null

  const isEditable = template.redemption_venue === 'all'
  const subtitle = isEditable
    ? t('subtitle.editable')
    : t('subtitle.uneditable', { venue: t(template.redemption_venue) })

  return (
    <RestrictionSection
      hasToggle={isEditable}
      title={t('title')}
      subtitle={subtitle}
      toggleValue={isEditable && config.redeem_venue !== 'all'}
      onChange={(value: boolean) => {
        if (!value) setValue('all')
      }}
      alwaysShowSubtitle={!isEditable}
    >
      <Switch condition={isEditable}>
        <RadioGroup
          name="redeem_venue"
          onChange={value => setValue(value)}
          initialValue={config.redeem_venue || 'instore'}
        >
          <Radio value="online">{t('radio_online')}</Radio>
          <Radio value="instore">{t('radio_instore')}</Radio>
        </RadioGroup>
      </Switch>
    </RestrictionSection>
  )
}

export default withFormsy(RedemptionVenue)
