import { Text } from '@thanx/uikit/text'
import thanxPlusOloOrToast from 'assets/images/redeem-manager/thanxPlusOloOrToast.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import { hiddenMenuOloHelpUrl } from 'utilities/urlUtils'

type Props = {
  className?: string
}

const t = buildTranslate('redeem_manager.builder.requires_olo')

const RequiresOlo: React.FC<Props> = ({ className = '' }) => {
  return (
    <div className={`py-s w-100 ${className}`}>
      <Text.BodyText5 className="mb-xs" color="grey70" tag="div">
        {t('title')}
        <a
          className="ml-xxs"
          target="_blank"
          rel="noopener noreferrer"
          href={hiddenMenuOloHelpUrl}
        >
          {t('learn_more')}
        </a>
      </Text.BodyText5>
      <img className="m-auto" src={thanxPlusOloOrToast} alt="olo or toast" />
    </div>
  )
}

export default RequiresOlo
