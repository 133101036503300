import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import { merchantTheme } from 'theme'

const t = buildTranslate('users.view.profile.pii_banner')

const PiiBanner = () => {
  const [css] = useStyletron()

  return (
    <div
      className={`d-flex p-m ${css({
        backgroundColor: merchantTheme.colors.primary50,
      })}`}
    >
      <span
        className={`pt-xxs fa fa-lg fa-eye-slash mr-s ${css({
          color: merchantTheme.colors.primary,
        })}
      `}
      />
      <Text.BodyText4 color="grey70">{t('pii_not_shared')}</Text.BodyText4>
    </div>
  )
}

export default PiiBanner
