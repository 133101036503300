import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const NOTIFY_THANX = 'contact/NOTIFY_THANX'
export const NOTIFY_THANX_SUCCESS = 'contact/NOTIFY_THANX_SUCCESS'
export const NOTIFY_THANX_FAIL = 'contact/NOTIFY_THANX_FAIL'

export function notifyThanx(params: {
  merchant_id: number
  subject: string
  source: string
}): AxiosAction<typeof NOTIFY_THANX> {
  return {
    type: NOTIFY_THANX,
    payload: {
      client: 'default',
      request: {
        url: 'contact',
        method: 'post',
        data: params,
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<ReturnType<typeof notifyThanx>>
