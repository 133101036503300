import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { ContentBlockTypes } from 'api/landingPageTheme'
import { buildTranslate } from 'locales'
import React from 'react'
import { getTitleIcon } from './helper'

type Props = {
  type: ContentBlockTypes
  size?: 'small' | 'medium'
  isDisabled?: boolean
}

const t = buildTranslate('cms.content.app_home_page.content.block_item')

const Title = ({ type, size = 'medium', isDisabled = false }: Props) => {
  const [css] = useStyletron()
  const image = getTitleIcon(type)
  const title = t(`${type}.title`)
  const iconSize = size === 'small' ? '14px' : '16px'
  const fontSize = size === 'small' ? '14px' : '18px'
  const opacity = isDisabled ? '50%' : '100%'

  return (
    <div className={`d-flex align-items-center ${css({ opacity })}`}>
      <div className={`d-flex ${size === 'small' ? 'mr-xs' : 'mr-s'}`}>
        <img alt={title} src={image} className={css({ height: iconSize })} />
      </div>
      <Text.Header4 className={css({ fontSize: fontSize })}>
        {title}
      </Text.Header4>
    </div>
  )
}

export default Title
