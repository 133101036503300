import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { tooltipPercentage } from 'scenes/Reports/helpers'
import { generateUikitTheme } from 'theme'
import { numberFormatter } from 'utilities/formatters'

const t = buildTranslate('activation_rate.tabs.activation_rate')

type PropsT = {
  date: string
  activationRate: number
  firstPurchaseCount: number
  thirdPurchaseCount: number
}

const Tooltip: React.FC<PropsT> = ({
  date,
  activationRate,
  firstPurchaseCount,
  thirdPurchaseCount,
}) => {
  const styletronEngine = new StyletronEngine({ prefix: 'st-ctt-' })
  const uikitTheme = generateUikitTheme()

  return (
    <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {date}
      </Text.BodyText4>
      <Text.Header3 className="mb-xs mt-0" tag="div">
        {tooltipPercentage(activationRate, 1, 1)}
      </Text.Header3>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {t('title')}
      </Text.BodyText4>
      <Text.Header4 className="mt-0" tag="div">
        {numberFormatter(thirdPurchaseCount)}
      </Text.Header4>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {t('chart.tooltip.third_purchase')}
      </Text.BodyText4>
      <Text.Header4 className="mt-0" tag="div">
        {numberFormatter(firstPurchaseCount)}
      </Text.Header4>
      <Text.BodyText4 color="grey70" tag="div">
        {t('chart.tooltip.first_purchase')}
      </Text.BodyText4>
    </StyleProvider>
  )
}

export default Tooltip
