import { useStyletron } from '@thanx/uikit/theme'
import { getDateFilter } from 'components/DateFilter/utilities'
import FetchableMetricSection from 'components/FetchableMetricSection'
import { Fields as Campaign } from 'models/Campaign'
import { EventsMetric, Type } from 'models/CampaignMetric'
import { sortVariants } from 'models/CampaignVariant/helpers'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { getCampaignReportingBounds } from 'scenes/CampaignCenter/Report/v3/helpers'
import { selectCampaignEventsMetricsByTypes } from 'selectors/campaignMetric'
import { merchantTheme } from 'theme'
import { useUserTimeZone } from 'utilities/userTimeZone'
import Heading from './Heading'
import LineChart from './LineChart'

type PropsT = {
  campaign: Campaign
}

const Chart: React.FC<PropsT> = ({ campaign }) => {
  const {
    id,
    variants,
    state: campaignState,
    winning_variant_id: winningVariantId,
  } = campaign
  const metrics =
    (useSelector(state =>
      selectCampaignEventsMetricsByTypes(state, id, [Type.EVENTS_NET_REVENUE])
    ) as EventsMetric[]) || []

  const [css] = useStyletron()
  const sortedVariants = variants.sort(sortVariants)
  const userTimeZone = useUserTimeZone()
  const timezone = metrics[0]?.time_zone || userTimeZone
  const decoratedMetrics = sortedVariants.map(variant => {
    const metric = metrics.find(metric => variant.id === metric.variant_id)
    return {
      ...metric,
      meta: variant.meta,
    } as EventsMetric
  })
  const { attributionWindow } = useAttributionWindow()

  const bounds = useMemo(
    () =>
      getCampaignReportingBounds(campaign, timezone, 'test', attributionWindow),
    [campaign, timezone, attributionWindow]
  )

  const filter = useMemo(
    () =>
      getDateFilter(
        'all_time',
        timezone,
        bounds.startDate.format('YYYY-MM-DD'),
        bounds.endDate.format('YYYY-MM-DD')
      ),
    [timezone, bounds.startDate, bounds.endDate]
  )

  return (
    <FetchableMetricSection
      type="campaign_event"
      campaignId={id}
      shouldFetch={['active', 'complete'].includes(campaignState)}
      types={['net_revenue']}
      variantIds={sortedVariants.map(variant => variant.id)}
      className={`mb-xxl pt-m pr-m pb-xxs pl-xxs ${css({
        backgroundColor: merchantTheme.colors.white,
        border: '1px solid ' + merchantTheme.colors.grey20,
        borderRadius: '4px',
      })}`}
      dateRangeFilter={filter}
      attributionWindow={attributionWindow}
    >
      <Heading metrics={decoratedMetrics} winningVariantId={winningVariantId} />
      <LineChart
        campaign={campaign}
        timezone={timezone}
        metrics={decoratedMetrics}
        dateRangeFilter={filter}
      />
    </FetchableMetricSection>
  )
}

export default Chart
