import React from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import AutomatedCard from './AutomatedCard'
import Card from './Card'
import Cards from './Cards'
import ImageCard from './ImageCard'
import LogoImageCard from './LogoImageCard'

type Props = {
  className?: string
  title: React.ReactNode
  subtitle: React.ReactNode
  description: React.ReactNode
  children: React.ReactNode
}

type CampaignComponents = {
  Cards: typeof Cards
  Card: typeof Card
  AutomatedCard: typeof AutomatedCard
  ImageCard: typeof ImageCard
  LogoImageCard: typeof LogoImageCard
}

const CampaignSection: React.FC<Props> & CampaignComponents = props => {
  const { className = '', title, subtitle, description, children } = props

  return (
    <Container className={className}>
      <Row className="mb-l">
        <Col md={6}>
          <h1 className="small-caps-1 mb-l grey-90">{title}</h1>
          <div className="h2 grey-90">{subtitle}</div>
          <div className="body-text-3 grey-70">{description}</div>
        </Col>
      </Row>
      {children}
    </Container>
  )
}

CampaignSection.Cards = Cards
CampaignSection.Card = Card
CampaignSection.AutomatedCard = AutomatedCard
CampaignSection.ImageCard = ImageCard
CampaignSection.LogoImageCard = LogoImageCard

export default CampaignSection
