import { buildTranslate } from 'locales'
import React from 'react'
import Card from './Card'

type Props = {
  hideToggle: boolean
  isEnabled: boolean
  isOpen: boolean
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.customer_support_card'
)

const CustomerSupportCard: React.FC<Props> = ({
  hideToggle,
  isEnabled,
  isOpen,
}) => {
  return (
    <Card
      title={t('title')}
      isEnabled={isEnabled}
      isOpen={isOpen}
      buttonName={hideToggle ? undefined : 'availableCustomerSupport'}
    >
      <p className="grey-70">
        {t(isEnabled ? 'available_to_grant' : 'not_available_to_grant')}
      </p>
    </Card>
  )
}

export default CustomerSupportCard
