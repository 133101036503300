import isEmpty from 'lodash/isEmpty'
import { Fields as Campaign } from 'models/Campaign'
import React, { useEffect } from 'react'

type Props = {
  campaign: Campaign
  isSaving: boolean
  canProceed: boolean
  triggerSave: () => Promise<void>
  setCurrentStep: (step: string) => void
  showValidationErrors: () => void
}

const Setup: React.FC<Props> = props => {
  const { campaign, setCurrentStep } = props
  useEffect(() => {
    if (isEmpty(campaign.name)) {
      setCurrentStep('name')
    } else if (!campaign.variants || campaign.variants?.length === 0) {
      setCurrentStep('variantSelect')
    } else {
      setCurrentStep('review')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default Setup
