import bannerBody from 'assets/images/banners/MtmEffectiveness/body.svg'
import bannerImage from 'assets/images/banners/MtmEffectiveness/image.png'
import React from 'react'
import { useStyletron } from 'styletron-react'
import { useIsMobile } from 'utilities/responsive'
import { mtmEffectivenessUrl } from 'utilities/urlUtils'

type PropsT = {
  className?: string
  onClick?: () => void
}

const MtmEffectivenessBanner: React.FC<PropsT> = ({ className = '' }) => {
  const [css] = useStyletron()
  const isMobile = useIsMobile()

  function handleClick() {
    window.open(mtmEffectivenessUrl)
  }

  return (
    <div
      className={`${css({
        backgroundColor: '#2d1e4b',
        width: '100%',
        height: isMobile ? 'auto' : '200px',
        cursor: 'pointer',
      })} ${className} d-flex flex-${isMobile ? 'column py-s' : 'row'}`}
      onClick={handleClick}
    >
      <div className="d-flex align-items-center flex-row h-100">
        <img
          src={bannerImage}
          className={css({
            borderRadius: '0px 88.794px 88.794px 0px',
            width: isMobile ? '50%' : 'auto',
          })}
        />
      </div>
      <div className="w-100 d-flex align-items-center">
        <div
          className={`d-flex align-items-center flex-column w-100 ${
            isMobile ? 'mt-m' : ''
          }`}
        >
          <img
            src={bannerBody}
            className={isMobile ? css({ width: '90%' }) : ''}
          />
        </div>
      </div>
    </div>
  )
}

export default MtmEffectivenessBanner
