import { PointsMultiplierPayload } from 'api/points'
import Help from 'components/Help'
import ModalContainer from 'components/ModalContainer'
import Formsy from 'formsy-react'
import { buildTranslate } from 'locales'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import { helpArticles } from '../Landing/Configuration/helpers'
import ConfigurationCard from './ConfigurationCard'
import Layout from './Layout'
import RestrictionsCard from './RestrictionsCard'

const t = buildTranslate('points.multipliers.form')

type Props = {
  multiplier: PointsMultiplierPayload
  onSubmit: (multiplier: PointsMultiplierPayload) => Promise<Boolean>
  isLoading: boolean
  confirmTitle: string
  confirmSubtitle: string
  confirmProceedText: string
}

function Form(props: Props) {
  const {
    multiplier: initialMultiplier,
    onSubmit,
    isLoading,
    confirmTitle,
    confirmSubtitle,
    confirmProceedText,
  } = props

  const dispatch = useDispatch()

  const [formState, setFormState] = useState<'valid' | 'invalid'>('valid')
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const updateMultiplier = (payload: PointsMultiplierPayload) =>
    setMultiplier({ ...multiplier, ...payload })

  const handlePublish = async () => {
    const result = await onSubmit(multiplier)

    if (result) {
      dispatch(push('/points#multipliers'))
    }
  }

  const canPublish = !isLoading && formState === 'valid'

  const [multiplier, setMultiplier] = useState(initialMultiplier)

  return (
    <>
      <Layout
        canPublish={canPublish}
        onPublish={() => setIsConfirmationModalOpen(true)}
      >
        <Formsy
          onChange={updateMultiplier}
          onInvalid={() => setFormState('invalid')}
          onValid={() => setFormState('valid')}
          role="form"
        >
          <RestrictionsCard multiplier={multiplier} />
          <ConfigurationCard multiplier={multiplier} />

          <Help title={t('help_title')} articles={helpArticles.multipliers} />
        </Formsy>
      </Layout>

      <ModalContainer
        title={confirmTitle}
        subtitle={confirmSubtitle}
        proceedText={confirmProceedText}
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onProceed={handlePublish}
        canProceed={!isLoading}
      />
    </>
  )
}

export default Form
