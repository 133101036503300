import { Button } from '@thanx/uikit/button'
import { getAnalytics } from 'actions/config'
import appPlusChart from 'assets/images/cms/app_plus_chart.svg'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CmsPage from 'scenes/Cms/components/CmsPage'
import { selectAnalyticsConfig } from 'selectors/config'
import Card from './Card'
import Option from './Option'
import OrderingDisabled from './OrderingDisabled'

const t = buildTranslate('cms.settings.analytics')

const analyticsOptions = [
  'facebook_pixel_id',
  'google_measurement_id',
  'google_tag_manager_id',
]

const Analytics: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { ordering_enabled: orderingEnabled } = useCurrentMerchant() || {}

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await dispatch(getAnalytics())
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  const onContactClick = () => {
    window.open('mailto:merchant.support@thanx.com')
  }
  const analyticsConfig = useSelector(selectAnalyticsConfig)
  const title = orderingEnabled ? t('enabled_title') : t('disabled_title')
  const description = orderingEnabled
    ? t('enabled_description')
    : t('disabled_description')
  return (
    <CmsPage title={title} description={description} isLoading={isLoading}>
      {!orderingEnabled && (
        <OrderingDisabled t={t} onContactClick={onContactClick} />
      )}
      {orderingEnabled &&
        analyticsOptions.map(option => {
          const value = analyticsConfig ? analyticsConfig[option] : ''
          return <Option key={option} id={option} t={t} value={value} />
        })}
      <Card
        key="contact"
        kind="secondary"
        image={appPlusChart}
        title={t('contact.title')}
        description={t('contact.description')}
        isIconLeft
      >
        <div className="d-flex justify-content-end mt-s">
          <Button onClick={onContactClick}>{t('contact.button')}</Button>
        </div>
      </Card>
    </CmsPage>
  )
}

export default Analytics
