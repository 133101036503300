import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { getCampaign } from 'actions/campaigns'
import { getCampaignType } from 'actions/campaignTypes'
import { alert } from 'actions/flash'
import Loading from 'components/Loading'
import PageHeader from 'components/PageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useDispatch from 'hooks/useDispatch'
import useFetchCampaignVariants from 'hooks/useFetchCampaignVariants'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { VariantOrCampaign } from 'models/CampaignVariant'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import CampaignConfigContext from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import CampaignStatus from 'scenes/CampaignCenter/components/CampaignStatus'
import CategoryIndicator from 'scenes/CampaignCenter/components/CategoryIndicator'
import StopCampaignModal from 'scenes/CampaignCenter/components/StopCampaignModal'
import { selectCampaign } from 'selectors/campaign'
import { selectCampaignConfig } from 'selectors/campaignConfig'
import { useStyletron } from 'styletron-react'
import { getFormattedDate } from 'utilities/date'
import { useUserTimeZone } from 'utilities/userTimeZone'
import AttributionSelector from './Attribution/AttributionSelector'
import { AttributionWindowProvider } from './Attribution/AttributionWindowContext'
import Configuration from './Configuration'
import Summary from './Summary'
import Test from './Test'

const t = buildTranslate('thanx_campaigns.report.v3')

enum Tabs {
  Summary = 'summary',
  Test = 'test',
  Configuration = 'configuration',
}

type Props = RouteComponentProps<{ id: string; type: string; category: string }>

const ReportV3: React.FC<Props> = props => {
  const { id, type, category } = props.match.params
  const campaignId = Number(id)
  const dispatch = useDispatch()
  const timeZone = useUserTimeZone()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Summary)
  const [css] = useStyletron()

  const campaign = useSelector(state => selectCampaign(state, campaignId))
  const [config, setConfig] = useState<VariantOrCampaign | null>(null)
  const campaignConfig = useSelector(state =>
    selectCampaignConfig(state, Number(id))
  )

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const isCustomAttributionEnabled = useFlag('MX4-CustomAttribution-R1', false)

  useEffect(() => {
    async function fetchData() {
      const responses = await Promise.all([
        dispatch(getCampaignType(type)),
        dispatch(getCampaign(campaignId)),
      ])
      responses.forEach(response => {
        const error = response?.error?.response?.data?.error || false
        if (error) {
          showError(error)
        }
      })
      setIsLoading(false)
    }

    if (isLoading) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, dispatch])

  useEffect(() => {
    setConfig(campaignConfig)
  }, [campaignConfig])

  const [didFetchVariants, isFetchingVariants] =
    useFetchCampaignVariants(campaign)

  function showError(error) {
    dispatch(
      alert({
        key: 'danger',
        message: t('errors.load_campaign'),
        error: error || null,
      })
    )
    setTimeout(() => dispatch(push('/thanx_campaigns/reports')), 3000)
  }

  if (!campaign || !didFetchVariants || !config || isFetchingVariants)
    return <Loading />

  const {
    name,
    start_at,
    end_at,
    state,
    type: campaignType,
    variants,
    winning_variant_id: winningVariantId,
  } = campaign

  function formatDate(date: string): string {
    return getFormattedDate(moment.tz(date, timeZone), 'daily')
  }

  function handleStop() {
    setIsModalOpen(false)
    dispatch(
      alert({
        key: 'success',
        message: I18n.t('thanx_campaigns.components.stopCampaignModal.alert', {
          name,
        }),
      })
    )
  }

  const endDate = !!end_at ? formatDate(end_at) : t('no_end_date')
  const isCustomAutomated = campaignType === 'custom_automated'
  const showTestStatus =
    variants?.length > 1 &&
    (state === 'active' || state === 'complete' || state === 'scheduled')
  const testStatus = winningVariantId ? 'complete' : state
  const description = isCustomAutomated
    ? `${formatDate(start_at)} - ${endDate}`
    : formatDate(start_at)

  if (isLoading) {
    return null
  }

  return (
    <CampaignConfigContext.Provider
      value={{
        config: config as unknown as VariantOrCampaign,
        setConfig,
      }}
    >
      <AttributionWindowProvider>
        <Helmet>
          <title>{t('page_title', { name })}</title>
        </Helmet>
        <PageHeader
          title={name}
          description={
            !isCustomAttributionEnabled ? (
              description
            ) : (
              <>
                <Text.BodyText3 tag="p" className="mt-xs mb-m">
                  {description}
                </Text.BodyText3>
                <AttributionSelector />
              </>
            )
          }
          buttonAction={() => setIsModalOpen(true)}
          buttonText={
            ['active', 'scheduled'].includes(state) && isCustomAutomated
              ? t('stop_campaign')
              : undefined
          }
          backPath="/thanx_campaigns/reports"
          topComponent={
            <div className="d-flex align-items-center">
              <CategoryIndicator
                category={category}
                className={`${css({
                  paddingBottom: '4px',
                })} mr-xs`}
              />
              <CampaignStatus state={state} />
            </div>
          }
        >
          <Tab.Container
            activeKey={activeTab}
            transition={false}
            onSelect={(tab: string | null) => {
              if (!tab) return
              setActiveTab(tab as Tabs)
            }}
          >
            <TabNav>
              <TabItem state={Tabs.Summary} title={t('summary_tab')} />
              <TabItem
                state={Tabs.Test}
                title={
                  <div className="d-flex align-content-center">
                    {t('test_tab')}
                    <Switch condition={showTestStatus}>
                      <CampaignStatus className="ml-xs" state={testStatus} />
                    </Switch>
                  </div>
                }
              />
              <TabItem
                state={Tabs.Configuration}
                title={t('configuration_tab')}
              />
            </TabNav>
            <Tab.Content className="pb-l h-100 grey-05-bkg">
              <TabPane state={Tabs.Summary}>
                <Summary campaign={campaign} />
              </TabPane>
              <TabPane state={Tabs.Test}>
                <Test campaign={campaign} />
              </TabPane>
              <TabPane state={Tabs.Configuration}>
                <Configuration campaign={campaign} />
              </TabPane>
            </Tab.Content>
          </Tab.Container>
          <StopCampaignModal
            campaignId={campaign.id}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onStop={handleStop}
          />
        </PageHeader>
      </AttributionWindowProvider>
    </CampaignConfigContext.Provider>
  )
}

export default ReportV3
