import { FormsyInput as Input } from '@thanx/uikit/input'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { selectCampaignTypeByType } from 'selectors/campaignType'
type Props = {
  campaign: Campaign
}

const SubjectLineForm: React.FC<Props> = ({ campaign }) => {
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const { config } = useCampaignConfig()
  const campaignPlaceholders = campaignType?.placeholders
  return (
    <div className="d-flex">
      <Input
        // @ts-ignore
        label={I18n.t('thanx_campaigns.builder.forms.email.subject')}
        value={config.notify_email_subject || null}
        name="notify_email_subject"
        placeholder={campaignPlaceholders?.notify_email_subject}
        validations={{ isRequired: true }}
        validationErrors={{ isRequired: I18n.t('validations.is_required') }}
        className="flex-1 margin-right-medium"
      />
      <Input
        // @ts-ignore
        label={I18n.t('thanx_campaigns.builder.forms.email.preview_text')}
        value={config.notify_email_preview_text || null}
        name="notify_email_preview_text"
        placeholder={campaignPlaceholders?.notify_email_preview_text}
        validations={{ isRequired: true }}
        validationErrors={{ isRequired: I18n.t('validations.is_required') }}
        className="flex-1 margin-right-medium"
      />
    </div>
  )
}

export default SubjectLineForm
