function importAll(r: any) {
  let images = {}
  r.keys().map((item: string) => {
    images[item.replace('./img_', '').replace(/(\.svg|\.png)$/, '')] = r(item)
  })
  return images
}

// get all image files from current directory
// and return an object with card keys
// and images as values
export const getCardImages = () => {
  return importAll(require.context('./', false, /(\.png|\.svg)$/))
}
