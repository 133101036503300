import moment from 'moment'

export type SortFilterAttribute = 'date_time' | string

export type SortFilter = {
  direction: 'asc' | 'desc'
  attribute: SortFilterAttribute
}

export const sortChannelData = <T,>(
  data: Array<T>,
  sortFilter: SortFilter,
  showAllRows: boolean
): Array<T> => {
  const { direction, attribute } = sortFilter
  const sortedData = [...data].sort((a, b) => {
    const aValue = a[attribute]
    const bValue = b[attribute]

    if (direction === 'asc') {
      if (attribute === 'date_time') {
        return moment(aValue).valueOf() - moment(bValue).valueOf()
      }
      return aValue > bValue ? 1 : bValue > aValue ? -1 : 0
    } else {
      if (attribute === 'date_time') {
        return moment(bValue).valueOf() - moment(aValue).valueOf()
      }
      return aValue > bValue ? -1 : bValue > aValue ? 1 : 0
    }
  })

  return showAllRows ? sortedData : sortedData.slice(0, 3)
}
