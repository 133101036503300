import { Fields as Tier } from 'models/Tier'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_TIERS = 'tiers/GET_TIERS'
export const GET_TIERS_SUCCESS = 'tiers/GET_TIERS_SUCCESS'
export const GET_TIERS_FAIL = 'tiers/GET_TIERS_FAIL'

export function getTiers(
  params?: Object
): AxiosAction<typeof GET_TIERS, { tiers: Tier[] }> {
  return {
    type: GET_TIERS,
    payload: {
      client: 'default',
      request: {
        url: 'tiers',
        method: 'get',
        params: params,
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<ReturnType<typeof getTiers>>
