import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import PopoverLearnMore from 'components/PopoverLearnMore'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import React from 'react'
import { currencyFormatter } from 'utilities/formatters'
import { aboutRewardDiscountsAndCosts } from 'utilities/urlUtils'

const t = buildTranslate('thanx_campaigns.report.v3')

type ManualTypes = {
  metric: RedeemMetric
  variantType: string | undefined
  // Why use hasData633Feature argument?
  // We want to leverage hooks/useFlag, which can only be used in a React component.
  // This module is not a React component.
  hasData633Feature: boolean
  attributionWindow: number
}

const manualItem = (props: ManualTypes) => {
  const { metric, variantType, hasData633Feature, attributionWindow } = props
  const primaryValue = currencyFormatter(metric?.data?.total_cost, false)

  const primaryTipDefault = hasData633Feature
    ? [
        {
          content: t('summary.revenue.tooltips.number_of_cogs_v2'),
        },
        {
          content: <PopoverLearnMore url={aboutRewardDiscountsAndCosts} />,
        },
        {
          title: t('summary.example'),
          content: t('summary.revenue.tooltips.number_cogs_example_v2_item_1'),
        },
        {
          content: t('summary.revenue.tooltips.number_cogs_example_v2_item_2'),
        },
        {
          content: t('summary.revenue.tooltips.number_cogs_example_v2_item_3'),
        },
        {
          content: t('summary.revenue.tooltips.number_cogs_example_v2_item_4'),
        },
      ]
    : [
        {
          content: t('summary.revenue.tooltips.number_of_cogs', {
            count: attributionWindow,
          }),
        },
        {
          title: t('summary.how_its_calc'),
          content: t('summary.revenue.tooltips.calc_of_number_cogs'),
        },
        {
          title: t('summary.example'),
          content: t('summary.revenue.tooltips.number_cogs_example_content'),
        },
      ]

  const primarySections =
    variantType === 'control'
      ? [
          {
            content: t('test.tooltips.no_reward'),
          },
        ]
      : primaryTipDefault
  const secondaryValue = t('summary.revenue.per_reward', {
    value: currencyFormatter(metric?.data?.average_cost),
  })
  const secondarySections =
    variantType === 'control'
      ? [
          {
            content: t('test.tooltips.no_reward'),
          },
        ]
      : [
          {
            content: t('summary.revenue.tooltips.per_of_cogs', {
              count: attributionWindow,
            }),
          },
          {
            title: t('summary.how_its_calc'),
            content: t('summary.revenue.tooltips.calc_of_per_cogs'),
          },
          {
            title: t('summary.example'),
            content: t('summary.revenue.tooltips.per_cogs_example_content'),
          },
        ]
  return {
    primaryContent: (
      <Metric metric={primaryValue}>
        <PopoverContent sections={primarySections} />
      </Metric>
    ),
    secondaryContent: (
      <Metric primary={false} metric={secondaryValue}>
        <PopoverContent sections={secondarySections} />
      </Metric>
    ),
    isErrored: metric?.isErrored,
    isLoading: metric?.isLoading,
  }
}

export default manualItem
