import React from 'react'

type PropsT = {
  color?: string
  className?: string
}

const ControlGroup: React.FC<PropsT> = props => {
  const { className, color = 'currentColor' } = props
  return (
    <svg
      width="80"
      height="53"
      viewBox="0 0 80 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '80px' }}
      className={className}
    >
      <path
        d="M1.17778 4.71111C1.17778 2.75971 2.75971 1.17778 4.71111 1.17778H75.2889C77.2403 1.17778 78.8222 2.75971 78.8222 4.71111V48.2889C78.8222 50.2403 77.2403 51.8222 75.2889 51.8222H4.71111C2.75971 51.8222 1.17778 50.2403 1.17778 48.2889V4.71111Z"
        fill="white"
        fillOpacity="0"
        stroke={color}
        strokeWidth="2.35556"
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.2339 40.8315C57.8965 41.2252 57.3342 41.3376 56.9968 41.0002L51.7428 36.8887C51.553 36.9407 51.3539 36.9684 51.1496 36.9684H31.6496C30.384 36.9684 29.3996 35.984 29.3996 34.7184V21.2184C29.3996 20.686 29.5874 20.1895 29.9044 19.799L23.3685 14.6842C22.9748 14.4031 22.8624 13.8408 23.1998 13.4472L23.7621 12.7162C24.0995 12.3788 24.6619 12.2663 25.0555 12.6037L33.1887 18.9684H51.1496C52.3684 18.9684 53.3996 19.9996 53.3996 21.2184V34.7184C53.3996 34.7403 53.3993 34.7621 53.3987 34.7838L58.6838 38.9197C59.0212 39.2008 59.1337 39.7632 58.7963 40.1568L58.2339 40.8315ZM51.1496 33.0238V26.0465C50.1491 26.8295 48.745 27.9354 46.7125 29.5515L51.1496 33.0238ZM44.875 28.1136C45.8697 27.3284 46.7172 26.6609 47.4454 26.0873C49.2985 24.6278 50.3795 23.7764 51.1496 23.1402V21.2184H36.0639L44.875 28.1136ZM31.7182 21.2184L42.673 29.7911C42.2258 30.056 41.783 30.235 41.3996 30.2184C40.4817 30.2566 39.283 29.2651 38.4399 28.5677C38.2497 28.4104 38.0776 28.268 37.9309 28.1559C37.2665 27.6313 36.6673 27.159 36.1255 26.7318C33.7618 24.8685 32.4885 23.8648 31.6496 23.1402V21.2184H31.7182ZM44.5389 31.2512L48.9694 34.7184H31.6496V26.0465C32.3877 26.6504 33.39 27.4464 34.7424 28.5205C35.2795 28.9471 35.8717 29.4175 36.5246 29.9371C36.6725 30.0514 36.8368 30.186 37.0157 30.3325C38.0841 31.2074 39.6731 32.5085 41.3996 32.4684C42.5429 32.4956 43.623 31.9063 44.5389 31.2512Z"
        fill={color}
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
    </svg>
  )
}

export default ControlGroup
