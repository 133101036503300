import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import StatBox from 'scenes/CampaignCenter/Builder/components/StatBox'

type Props = {
  category: string
  percent: number
}

const Stat: React.FC<Props> = ({ category, percent }) => {
  let fractionDigits = 0
  if (percent < 0.01) fractionDigits = 1
  return (
    <StatBox
      title={I18n.t(
        'thanx_campaigns.campaigns.upsell.steps.setup.stat.supertitle'
      )}
      message={
        <Translate
          value="thanx_campaigns.campaigns.upsell.steps.setup.stat.message"
          category={category}
          percent={I18n.l(percent, {
            style: 'percent',
            maximumFractionDigits: fractionDigits,
          })}
          dangerousHTML
        />
      }
      className="category-targeted-bkg white"
    />
  )
}

export default Stat
