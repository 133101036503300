import activity from './activity'
import configuration from './configuration'
import createEdit from './createEdit'
import effective_discount_rate from './effective_discount_rate'
import itemCard from './itemCard'
import landing from './landing'
import marketplace from './marketplace'
import multipliers from './multipliers'
import restrictions from './restrictions'

export default {
  create_edit: createEdit,
  landing,
  marketplace,
  multipliers,
  item_card: itemCard,
  configuration,
  restrictions,
  activity,
  effective_discount_rate,
}
