import type { CancelToken } from 'axios'
import { CampaignStateT, Fields as Campaign } from 'models/Campaign'
import { PaginationT } from 'models/Pagination'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const CREATE_CAMPAIGN = 'campaigns/CREATE_CAMPAIGN'
export const CREATE_CAMPAIGN_SUCCESS = 'campaigns/CREATE_CAMPAIGN_SUCCESS'
export const CREATE_CAMPAIGN_FAIL = 'campaigns/CREATE_CAMPAIGN_FAIL'

export const GET_CAMPAIGNS = 'campaigns/GET_CAMPAIGNS'
export const GET_CAMPAIGNS_SUCCESS = 'campaigns/GET_CAMPAIGNS_SUCCESS'
export const GET_CAMPAIGNS_FAIL = 'campaigns/GET_CAMPAIGNS_FAIL'

export const GET_CAMPAIGN = 'campaigns/GET_CAMPAIGN'
export const GET_CAMPAIGN_SUCCESS = 'campaigns/GET_CAMPAIGN_SUCCESS'
export const GET_CAMPAIGN_FAIL = 'campaigns/GET_CAMPAIGN_FAIL'

export const UPDATE_CAMPAIGN = 'campaigns/UPDATE_CAMPAIGN'
export const UPDATE_CAMPAIGN_SUCCESS = 'campaigns/UPDATE_CAMPAIGN_SUCCESS'
export const UPDATE_CAMPAIGN_FAIL = 'campaigns/UPDATE_CAMPAIGN_FAIL'

export const SCHEDULE_CAMPAIGN = 'campaigns/SCHEDULE_CAMPAIGN'
export const SCHEDULE_CAMPAIGN_SUCCESS = 'campaigns/SCHEDULE_CAMPAIGN_SUCCESS'
export const SCHEDULE_CAMPAIGN_FAIL = 'campaigns/SCHEDULE_CAMPAIGN_FAIL'

export const CANCEL_CAMPAIGN = 'campaigns/CANCEL_CAMPAIGN'
export const CANCEL_CAMPAIGN_SUCCESS = 'campaigns/CANCEL_CAMPAIGN_SUCCESS'
export const CANCEL_CAMPAIGN_FAIL = 'campaigns/CANCEL_CAMPAIGN_FAIL'

export const COMPLETE_CAMPAIGN = 'campaigns/COMPLETE_CAMPAIGN'
export const COMPLETE_CAMPAIGN_SUCCESS = 'campaigns/COMPLETE_CAMPAIGN_SUCCESS'
export const COMPLETE_CAMPAIGN_FAIL = 'campaigns/COMPLETE_CAMPAIGN_FAIL'

export const ARCHIVE_CAMPAIGN = 'campaigns/ARCHIVE_CAMPAIGN'
export const ARCHIVE_CAMPAIGN_SUCCESS = 'campaigns/ARCHIVE_CAMPAIGN_SUCCESS'
export const ARCHIVE_CAMPAIGN_FAIL = 'campaigns/ARCHIVE_CAMPAIGN_FAIL'

export const ADD_FIELDS_TO_CAMPAIGN = 'campaigns/ADD_FIELDS_TO_CAMPAIGN'

export const SEND_TEST = 'campaigns/SEND_TEST'

type CreateParamsT = {
  campaign: {
    type: string
    name?: string
    target_segment_id?: number
  }
}

export function createCampaign(
  params: CreateParamsT
): AxiosAction<typeof CREATE_CAMPAIGN, { campaign: Campaign }> {
  return {
    type: CREATE_CAMPAIGN,
    payload: {
      client: 'default',
      request: {
        url: 'campaigns',
        method: 'post',
        data: params,
      },
    },
  }
}

type getCampaignsT = {
  state?: CampaignStateT
  type?: string
  category?: string
  page?: number
  query?: string
  cancelToken?: CancelToken
}

export type GetCampaignsAction = AxiosAction<
  typeof GET_CAMPAIGNS,
  { campaigns: Campaign[]; pagination: PaginationT }
>

export function getCampaigns(params?: getCampaignsT): GetCampaignsAction {
  return {
    type: GET_CAMPAIGNS,
    payload: {
      client: 'default',
      request: {
        url: 'campaigns',
        method: 'get',
        params: params,
        cancelToken: params?.cancelToken,
      },
    },
  }
}

export function getCampaignsWithTemplateId(
  templateId: number
): AxiosAction<
  typeof GET_CAMPAIGNS,
  { campaigns: Campaign[]; pagination: PaginationT }
> {
  return {
    type: GET_CAMPAIGNS,
    payload: {
      client: 'default',
      request: {
        url: `redeem_templates/${templateId}/campaigns`,
        method: 'get',
      },
    },
    //@ts-ignore
    templateId,
  }
}

export function getCampaign(
  id: number
): AxiosAction<typeof GET_CAMPAIGN, { campaign: Campaign }> {
  return {
    type: GET_CAMPAIGN,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${id}`,
        method: 'get',
      },
    },
  }
}

export function updateCampaign(
  id: number,
  params: { campaign: {} }
): AxiosAction<typeof UPDATE_CAMPAIGN, { campaign: Campaign }> {
  return {
    type: UPDATE_CAMPAIGN,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${id}`,
        method: 'patch',
        data: params,
      },
    },
  }
}

export function scheduleCampaign(
  id: number,
  data?: Object
): AxiosAction<typeof SCHEDULE_CAMPAIGN, { campaign: Campaign }> {
  return {
    type: SCHEDULE_CAMPAIGN,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${id}/actions/schedule`,
        method: 'post',
        data: data,
      },
    },
  }
}

export function cancelCampaign(
  id: number
): AxiosAction<typeof CANCEL_CAMPAIGN, { campaign: Campaign }> {
  return {
    type: CANCEL_CAMPAIGN,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${id}/actions/cancel`,
        method: 'post',
      },
    },
  }
}

export function completeCampaign(
  id: number
): AxiosAction<typeof COMPLETE_CAMPAIGN, { campaign: Campaign }> {
  return {
    type: COMPLETE_CAMPAIGN,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${id}/actions/complete`,
        method: 'post',
      },
    },
  }
}

export function archiveCampaign(
  id: number
): AxiosAction<typeof ARCHIVE_CAMPAIGN, { campaign: Campaign }> {
  return {
    type: ARCHIVE_CAMPAIGN,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${id}/actions/archive`,
        method: 'post',
      },
    },
  }
}

export function addNonApiFieldsToCampaign(
  id: number,
  params: any
): { type: typeof ADD_FIELDS_TO_CAMPAIGN; campaign: Campaign } {
  return {
    type: ADD_FIELDS_TO_CAMPAIGN,
    campaign: {
      ...params,
      id: id,
    },
  }
}

export function sendTestNotifications(
  id: number,
  data: { emails: string[]; user_type?: string; variant_id?: number }
): AxiosAction<typeof SEND_TEST> {
  return {
    type: SEND_TEST,
    payload: {
      client: 'default',
      request: {
        url: `campaigns/${id}/actions/send_test`,
        method: 'post',
        data,
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof createCampaign>>
  | ResolvedAxiosAction<ReturnType<typeof getCampaigns>>
  | ResolvedAxiosAction<ReturnType<typeof getCampaignsWithTemplateId>>
  | ResolvedAxiosAction<ReturnType<typeof getCampaign>>
  | ResolvedAxiosAction<ReturnType<typeof updateCampaign>>
  | ResolvedAxiosAction<ReturnType<typeof scheduleCampaign>>
  | ResolvedAxiosAction<ReturnType<typeof cancelCampaign>>
  | ResolvedAxiosAction<ReturnType<typeof completeCampaign>>
  | ResolvedAxiosAction<ReturnType<typeof archiveCampaign>>
  | ResolvedAxiosAction<ReturnType<typeof sendTestNotifications>>
  | ReturnType<typeof addNonApiFieldsToCampaign>
