// @flow
import Callback from './components/Callback'
import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

export default class Oauth extends Component<{}, {}> {
  render() {
    return (
      <Switch>
        <Route
          path="/subscribers/oauth/callback/:providerType"
          component={Callback}
        />
      </Switch>
    )
  }
}
