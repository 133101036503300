import { api as rtkApi } from 'api'
import { createBrowserHistory } from 'history'
import { translations } from 'locales'
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n'
import { routerMiddleware } from 'react-router-redux'
import rootReducer from 'reducers'
import { applyMiddleware, compose, createStore } from 'redux'
import { multiClientMiddleware } from 'redux-axios-middleware'
import thunk from 'redux-thunk'
import api from 'utilities/api'

export const history = createBrowserHistory()

export const middleware = [
  thunk,
  multiClientMiddleware(api.clients, api.config),
  routerMiddleware(history),
  rtkApi.middleware,
]
const enhancers: any = []

const devToolsExtension =
  //@ts-ignore
  (window.__REDUX_DEVTOOLS_EXTENSION__ ||
    //@ts-ignore
    window.devToolsExtension) as typeof compose

if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension())
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

export function buildStore(initialState = {}) {
  return createStore(rootReducer, initialState, composedEnhancers)
}

const store = buildStore()

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translations))
store.dispatch(setLocale('en'))

export default store
