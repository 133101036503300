import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import leftChevron from 'assets/images/reports/icons/ic-thinner-chevron-left.svg'
import activationFunnelImage from 'assets/images/reports/lifecycle/activation_funnel_explainer.svg'
import ContentContainer from 'components/ContentContainer'
import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { Carousel as BootstrapCarousel } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import {
  LifecycleOpportunityMetrics,
  metricsStagesMapping,
} from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'

const t = buildTranslate('lifecycle_report.overview.opportunity_card')

type PropsT = {
  metrics: LifecycleOpportunityMetrics
  isLoading: boolean
  isErrored: boolean
  onSwitchTabAction: (tab: any) => void
}

const Carousel: React.FC<PropsT> = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [css] = useStyletron()
  const { isLoading, isErrored, metrics, onSwitchTabAction } = props
  const conversionStages = Object.keys(metrics)

  function nextItem() {
    const newIndex = (activeIndex + 1) % 3
    setActiveIndex(newIndex)
  }

  function prevItem() {
    const newIndex = (activeIndex + 3 - 1) % 3
    setActiveIndex(newIndex)
  }

  return (
    <div className="d-flex flex-column mb-l">
      <div className="d-flex flex-justify-center flex-align-center relative">
        <div
          className={`${css({
            position: 'absolute',
            left: '-6rem',
            padding: '2rem',
            ':hover': {
              cursor: 'pointer',
            },
            '@media screen and (max-width: 880px)': {
              position: 'inherit',
              left: 0,
            },
          })}`}
          onClick={prevItem}
        >
          <img src={leftChevron} alt="previous" className={'grey-90'} />
        </div>
        <BootstrapCarousel
          className={`lifecyle-opportunity-card full-width mb-m ${css({
            boxShadow: '0 1rem 3rem rgba(54, 66, 78, 0.2)',
          })}`}
          activeIndex={activeIndex}
          interval={null}
          controls={false}
          indicators={false}
          onSelect={index => setActiveIndex(index)}
        >
          {conversionStages.map((conversionStage, i) => {
            return (
              <BootstrapCarousel.Item key={i} className="text-left">
                <Section
                  className={`d-flex flex-wrap ${css({
                    minHeight: '19rem',
                    backgroundColor: '#EFF0FF',
                  })}`}
                >
                  <div className="d-flex pl-xl pb-xl align-self-end">
                    <img src={activationFunnelImage} alt="funnel" />
                  </div>
                  <div className="d-flex flex-column flex-1 pl-xxl mr-xl">
                    <div className="pt-xl pb-m">
                      <Text.SmallCaps2 bold color={'gray90'}>
                        {t('title')}
                      </Text.SmallCaps2>
                    </div>
                    <ContentContainer
                      isErrored={isErrored}
                      isLoading={isLoading}
                    >
                      <div>
                        <Text.Header4
                          bold
                          color={'grey90'}
                          className="m-0 pb-xs"
                        >
                          {t(`${conversionStage}.title`, {
                            ...metrics[conversionStage],
                          })}
                        </Text.Header4>
                        <Text.BodyText3
                          color={'grey70'}
                          tag="div"
                          className="mt-xxs"
                        >
                          {t(`${conversionStage}.content`, {
                            ...metrics[conversionStage],
                          })}
                        </Text.BodyText3>
                      </div>
                    </ContentContainer>
                    <div className="d-flex flex-column flex-1 align-self-end justify-content-end">
                      <div className="d-flex">
                        <Button
                          kind={Kind.SECONDARY}
                          className="mb-xl mr-m"
                          onClick={() => onSwitchTabAction('activation_rate')}
                        >
                          {t('view_activation_rate')}
                        </Button>
                        <LinkContainer
                          to={`/reports/lifecycle/${
                            metricsStagesMapping[
                              metrics[conversionStage].current_metric_name
                            ]
                          }`}
                        >
                          <Button className="mb-xl">{t('learn_more')}</Button>
                        </LinkContainer>
                      </div>
                    </div>
                  </div>
                </Section>
              </BootstrapCarousel.Item>
            )
          })}
        </BootstrapCarousel>
        <div
          onClick={nextItem}
          className={`${css({
            position: 'absolute',
            right: '-6rem',
            padding: '2rem',
            ':hover': {
              cursor: 'pointer',
            },
            '@media screen and (max-width: 880px)': {
              position: 'inherit',
              right: 0,
            },
          })}`}
        >
          <img
            src={leftChevron}
            alt="next"
            className={`grey-90 ${css({
              transform: 'rotate(180deg)',
            })}`}
          />
        </div>
      </div>
      <div>
        <ol
          className={`carousel-indicators ml-0 mr-0 mb-m ${css({
            position: 'inherit',
            width: 'auto',
          })}`}
        >
          {conversionStages.map((_, i) => {
            return (
              <li
                key={i}
                className={`border-0 ml-xxs mr-xxs
                    ${css({
                      backgroundColor: `${defaultTheme.colors.grey40} !important`,
                      opacity: `1 !important`,
                    })}
                    ${
                      i === activeIndex
                        ? `active ${css({
                            backgroundColor: `${defaultTheme.colors.grey70} !important`,
                          })} `
                        : undefined
                    }`}
                onClick={() => setActiveIndex(i)}
              />
            )
          })}
        </ol>
      </div>
    </div>
  )
}

export default Carousel
