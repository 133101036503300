import { Text } from '@thanx/uikit/text'
import Help from 'components/Help'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import Spinner from 'components/Spinner'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'
import { userSupportUrl } from 'utilities/urlUtils'
import ExplainerCard from './ExplainerCard'

const t = buildTranslate('user_support')

const LOOKER_REPORT_ID = 184

const Landing = () => {
  const merchant = useCurrentMerchant()
  if (!merchant) return null

  const isSupportManagedByThanx = merchant.support_managed_by === 'thanx'
  const height = 1750

  const isTrialPeriod = new Date() < new Date('2024-07-01')
  const shouldDisplayExplainerCard = isTrialPeriod || !isSupportManagedByThanx

  return (
    <ProtectedPageHeader
      title={t('title')}
      resource="UserSupport"
      action="access"
    >
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div className="grey-05-bkg border-top-1 pb-xxl grey-20-border mt-l">
        <Spinner
          isLoading={false}
          size="4x"
          className="text-center margin-top-huge"
        >
          <Container className="pl-0">
            {!isSupportManagedByThanx && (
              <>
                <Text.Header4 className="text-center mt-xl mb-s" tag="div">
                  {t('not_available_title')}
                </Text.Header4>
                <Text.BodyText4
                  className="text-center"
                  color="grey70"
                  tag="div"
                >
                  {t('not_available_subtitle')}
                </Text.BodyText4>
              </>
            )}
            {shouldDisplayExplainerCard && (
              <ExplainerCard
                isSupportManagedByThanx={isSupportManagedByThanx}
              />
            )}
            {isSupportManagedByThanx && (
              <LookerIframe
                path={`dashboards/${LOOKER_REPORT_ID}`}
                height={`${height}px`}
                merchant={merchant}
              />
            )}
            <Help
              title={t('help')}
              articles={[
                {
                  title: t('learn_more'),
                  url: userSupportUrl,
                },
              ]}
            />
          </Container>
        </Spinner>
      </div>
    </ProtectedPageHeader>
  )
}

export default Landing
