export default {
  overview: {
    page_title: 'Customer lifecycle | Thanx',
    header: {
      title: 'Customer lifecycle',
      help_link: 'Lifecycle Marketing 101',
    },
    opportunity_card: {
      title: 'Convert more customers at each step of your activation funnel',
      loyalty_to_first_purchase: {
        title:
          '%{unconverted_percentage} of people that created an account haven’t made a purchase',
        content:
          'Of the %{previous_members_count} people that created an account, %{unconverted_percentage} (%{unconverted}) haven’t made a purchase.',
      },
      first_purchase_to_second_purchase: {
        title:
          '%{unconverted_percentage} of customers who made their first purchase haven’t returned',
        content:
          'Of the %{previous_members_count} customers that made their first purchase, %{unconverted_percentage} (%{unconverted}) haven’t made a second.',
      },
      second_purchase_to_third_purchase: {
        title:
          '%{unconverted_percentage} of customers who made a second purchase, haven’t made a third',
        content:
          'Of the %{previous_members_count} people that made their second purchase, %{unconverted_percentage} (%{unconverted}) haven’t made a third.',
      },
      learn_more: 'Learn more',
      view_activation_rate: 'View activation rate',
    },
    chart: {
      title: 'Activation funnel (all time)',
      error: 'Activation funnel has failed to load',
      select_placeholder: 'Select stages...',
      view_details: 'View details',
      help_link: 'How to read this chart',
      labels: {
        y_axis: 'Customers',
        x_axis: {
          entered_database: 'Entered your database',
          created_account: 'Created account',
          made_first_purchase: 'Made 1st purchase',
          made_second_purchase: 'Made 2nd purchase',
          made_third_purchase: 'Made 3rd purchase',
        },
      },
      tooltips: {
        learn_more: [
          'To learn more, click ',
          'View details',
          ' in the table below',
        ],
        benchmark: 'Industry benchmark is %{benchmark}',
        entered_database: {
          title: 'Entered your database',
          converted_description:
            'Customers that entered your database. These customers entered the database when they created an account or when you manually imported their email.',
        },
        created_account: {
          title: 'Of customers that entered your database',
          converted_description: 'Created an account',
          didnt_convert_description: 'Did not create an account',
        },
        made_first_purchase: {
          title: 'Of customers that created an account',
          converted_description: 'Made their 1st purchase',
          didnt_convert_description: 'Did not make their 1st purchase',
        },
        made_second_purchase: {
          title: 'Of customers that made their 1st purchase',
          converted_description: 'Made their 2nd purchase',
          didnt_convert_description: 'Did not make their 2nd purchase',
        },
        made_third_purchase: {
          title: 'Of customers that made their 2nd purchase',
          converted_description: 'Made their 3rd purchase',
          didnt_convert_description: 'Did not make their 3rd purchase',
        },
      },
      series: {
        benchmark: {
          name: 'Industry benchmark',
        },
      },
      merchant_type_chart_label: [
        'Benchmark data for brands in the ',
        ' industry',
      ],
    },
    table: {
      header: {
        stage: 'Activation funnel stage',
        customers: {
          description: 'Customers',
          recency: '(all time)',
        },
        conversion_rate: {
          description: 'Conversion Rate',
          recency: '(all time)',
        },
        percentage: {
          description: 'Percentage of database',
          recency: '(all time)',
        },
      },
      stage: {
        entered_database: 'Entered your database',
        created_account: 'Created account',
        made_first_purchase: 'Made 1st purchase',
        made_second_purchase: 'Made 2nd purchase',
        made_third_purchase: 'Made 3rd purchase',
      },
      conversion_rate: {
        created_account: 'of everyone in your database',
        made_first_purchase: 'of customers who created an account',
        made_second_purchase: 'of 1st time purchasers',
        made_third_purchase: 'of 2nd time purchasers',
      },
      view_details: 'View details',
      tooltips: {
        title: {
          how_it_works: "How it's calculated",
          recency: 'Recency',
        },
        recency: 'All time.',
        stages: {
          entered_database: {
            customers: 'The number of customer that entered your database.',
            total_percentage: {
              description: 'The percentage of customers in your database.',
              how_it_works: 'Entered database / Entered database x 100',
            },
          },
          created_account: {
            customers: 'The number of customer that created an account.',
            conversion_rate: {
              description:
                'The percentage of customers in your database that created an account.',
              how_it_works: 'Created account / Entered database x 100',
            },
            total_percentage: {
              description:
                'The percentage of customers in your database that created an account.',
              how_it_works: 'Created account / Entered database x 100',
            },
          },
          made_first_purchase: {
            customers: 'The number of customers that made at least 1 purchase.',
            conversion_rate: {
              description:
                'The percentage of customers that made at least 1 purchase out of customers that created an account.',
              how_it_works: 'Made 1st purchase / Created account x 100',
            },
            total_percentage: {
              description:
                'The percentage of customers in your database that made at least 1 purchase.',
              how_it_works: 'Made 1st purchase / Entered database x 100',
            },
          },
          made_second_purchase: {
            customers:
              'The number of customers that made at least 2 purchases.',
            conversion_rate: {
              description:
                'The percentage of customers that made at least 2 purchases out of customers that made at least 1 purchase.',
              how_it_works: 'Made 2nd purchase / Made 1st purchase x 100',
            },
            total_percentage: {
              description:
                'The percentage of customers in your database that made at least 2 purchases.',
              how_it_works: 'Made 2nd purchase / Entered database x 100',
            },
          },
          made_third_purchase: {
            customers:
              'The number of customers that made at least 3 purchases.',
            conversion_rate: {
              description:
                'The percentage of customers that made at least 3 purchases out of customers that made at least 2 purchases.',
              how_it_works: 'Made 3rd purchase / Made 2nd purchase x 100',
            },
            total_percentage: {
              description:
                'The percentage of customers in your database that made at least 3 purchases.',
              how_it_works: 'Made 3rd purchase / Entered database x 100',
            },
          },
        },
      },
    },
    info_box: {
      title: 'Why the 3rd purchase matters',
      content:
        'Customer activation is the act of getting a customer engaged with your brand and realizing value so they are likely to come back on their own without the need for ongoing incentive marketing. The faster you can get customers to make their third purchase, the more likely they are to turn into high-value repeat customers.',
      help_link: 'Learn more',
    },
  },
  detail: {
    created_account: 'Created account',
    made_first_purchase: 'Made 1st purchase',
    made_second_purchase: 'Made 2nd purchase',
    made_third_purchase: 'Made 3rd purchase',
    all_time_conversion: {
      title: 'All time conversion',
      all_time: '(All time)',
      created_account: {
        conversion_rate: {
          description:
            'Percent of customers in your database that created an account',
          tooltips: {
            description:
              'Of all your customers that entered your database, this many went on to create an account.',
            how_it_works: 'Created account / Entered database',
          },
        },
        count: {
          description: 'Customers that created an account',
          tooltips: {
            description: 'The number of customer that created an account.',
          },
        },
      },
      made_first_purchase: {
        conversion_rate: {
          description:
            'Percent of customer that created an account that made their 1st purchase',
          tooltips: {
            description:
              'Of all your customers that created an account, this many went on to make at least 1 purchase.',
            how_it_works: 'Made 1st purchase / Created account',
          },
        },
        count: {
          description: 'Customers that made their 1st purchase',
          tooltips: {
            description:
              'The number of customers that made at least 1 purchase.',
          },
        },
      },
      made_second_purchase: {
        conversion_rate: {
          description:
            'Percent of 1st time purchasers that made their 2nd purchase',
          tooltips: {
            description:
              'Of all your customers that made at least 1 purchase, this many went on to make at least 2 purchases.',
            how_it_works: 'Made 2nd purchase / Made 1st purchase',
          },
        },
        count: {
          description: 'Customers that made their 2nd purchase',
          tooltips: {
            description:
              'The number of customers that made at least 2 purchases.',
          },
        },
      },
      made_third_purchase: {
        conversion_rate: {
          description:
            'Percent of 2nd time purchasers that made their 3rd purchase',
          tooltips: {
            description:
              'Of all your customers that made at least 2 purchases, this many went on to make at least 3 purchases.',
            how_it_works: 'Made 3rd purchase / Made 2nd purchase',
          },
        },
        count: {
          description: 'Customers that made their 3rd purchase',
          tooltips: {
            description:
              'The number of customers that made at least 3 purchases.',
          },
        },
      },
      tooltips: {
        title: {
          how_it_works: "How it's calculated",
          recency: 'Recency',
        },
        recency: 'All time.',
      },
    },
    current_conversion: {
      title: 'Current conversion',
      description:
        'All-time data is useful to understand the performance across all of your customers, but over time conversion rates become skewed based on old customer data. The data below is scoped using recency and conversion windows to show you how you’re currently doing at converting customers.',
      benchmark_description: 'Industry benchmark is %{rate}',
      help_link: 'Learn about industry benchmarks',
      info_box: {
        title: 'Conversion window',
        created_account: {
          description:
            'Customers that created an account within 60 days of entering your database',
        },
        made_first_purchase: {
          description:
            'Customers that made their 1st purchase within 60 days of creating an account',
        },
        made_second_purchase: {
          description:
            'Customers that made their 2nd purchase within 60 days of making their 1st purchase',
        },
        made_third_purchase: {
          description:
            'Customers that made their 3rd purchase within 60 days of making their 2nd purchase',
        },
        help_link: 'How this time range affects your data',
      },
      created_account: {
        previous_count: {
          description: 'Customers that entered your database (6m)',
          tooltips:
            'The number of customer that entered your database in the last 6 months.',
        },
        converted: {
          description:
            'Customers that created an account within 60 days of entering your database (6m)',
          tooltips:
            'The number of customer that created an account within 60 days of entering your database.',
        },
        radial_converted: {
          description:
            'Customers created an account within 60 days of entering your database (6m)',
        },
      },
      made_first_purchase: {
        previous_count: {
          description: 'Customers that created an account (6m)',
          tooltips:
            'The number of customer that created an account in the last 6 months.',
        },
        converted: {
          description:
            'Customers that made their 1st purchase within 60 days of creating an account (6m)',
          tooltips:
            'The number of customer that made their 1st purchase within 60 days of creating an account.',
        },
        radial_converted: {
          description:
            'Customers made their 1st purchase within 60 days of creating an account (6m)',
        },
      },
      made_second_purchase: {
        previous_count: {
          description: 'Customers that made their 1st purchase (6m)',
          tooltips:
            'The number of customers that made their 1st purchase in the last 6 months.',
        },
        converted: {
          description:
            'Customers that made their 2nd purchase within 60 days of making their 1st purchase (6m)',
          tooltips:
            'The number of customer that made their 2nd purchase within 60 days of making their 1st purchase.',
        },
        radial_converted: {
          description:
            'Customers made their 2nd purchase within 60 days of making their 1st purchase (6m)',
        },
      },
      made_third_purchase: {
        previous_count: {
          description: 'Customers that made their 2nd purchase (6m)',
          tooltips:
            'The number of customers that made their 2nd purchase in the last 6 months.',
        },
        converted: {
          description:
            'Customers that made their 3rd purchase within 60 days of making their 2nd purchase (6m)',
          tooltips:
            'The number of customer that made their 3rd purchase within 60 days of making their 2nd purchase.',
        },
        radial_converted: {
          description:
            'Customers made their 3rd purchase within 60 days of making their 2nd purchase (6m)',
        },
      },
      tooltips: {
        title: {
          recency: 'Recency',
        },
        recency: '6 months.',
      },
    },
    over_time_chart: {
      labels: {
        y_axis: '% of customers',
        x_axis: {
          created_account: 'Date that customers entered your database',
          made_first_purchase: 'Date that customers created an account',
          made_second_purchase: 'Date that customers made their 1st purchase',
          made_third_purchase: 'Date that customers made their 2nd purchase',
        },
      },
      title: {
        created_account:
          'Conversion rate of customers that created an account within 60 days of entering your database ',
        made_first_purchase:
          'Conversion rate of customers that made their 1st purchase within 60 days of creating an account',
        made_second_purchase:
          'Conversion rate of customers that made their 2nd purchase within 60 days of their 1st purchase',
        made_third_purchase:
          'Conversion rate of customers that made their 3rd purchase within 60 days of their 2nd purchase',
      },
      tooltip: {
        description: 'Conversion Rate',
      },
      timespan: {
        six_months: '6 months',
        twelve_months: '12 months',
        twenty_four_months: '24 months',
        all_time: 'All time',
      },
      error: 'Data failed to load',
      help_link: 'How to read this chart',
    },
  },
  one_click: {
    title: 'Convert more customers to this lifecycle stage',
    subtitle: 'Set up an automated campaign',
    button: 'Start draft',
    how_to: 'How to talk to these customers',
    going_forward:
      'Going forward, automatically message customers when they enter the segment:',
    submit_error:
      'There was an error creating your custom campaign. Please try again.',
    content: {
      created_account:
        'When a customer who has been added to your database doesn’t create an account within X days, then send them a campaign.',
      made_first_purchase:
        'When a customer who has created their account doesn’t make a purchase within X days, then send them a campaign.',
      made_second_purchase:
        'When a customer who has made their first purchase doesn’t make a second within X days, then send them a campaign.',
      made_third_purchase:
        'When a customer who has made their second purchase doesn’t make a third within X days, then send them a campaign.',
    },
  },
}
