import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { getUserProfile } from 'actions/users'
import { storedValueApi } from 'api/storedValue'
import Badge from 'components/Badge'
import Spinner from 'components/Spinner'
import TabsContainer, { TabT } from 'components/Tabs/Container'
import useDispatch from 'hooks/useDispatch'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectUserProfile } from 'selectors/userProfile'
import { history } from 'store'
import { merchantTheme } from 'theme'
import { useAbility } from 'utilities/ability'
import { getFormattedDate } from 'utilities/date'
import { useUserTimeZone } from 'utilities/userTimeZone'
import ActionsMenu from './ActionsMenu'
import Activity from './Activity'
import Cards from './Cards'
import GrantModal from './GrantModal'
import { getFullName } from './helpers'
import Profile from './Profile'
import StoredValue from './StoredValue'
import TierBadge from './TierBadge'

const t = buildTranslate('users.view')

const ALL_TABS = ['activity', 'profile', 'cards', 'stored_value'] as const
type Tab = typeof ALL_TABS[number]

type PropsT = {
  match: {
    params: {
      identifier: string
    }
  }
}

const isTab = (tab: unknown): tab is Tab => ALL_TABS.includes(tab as Tab)

const View: React.FC<PropsT> = ({ match }) => {
  const identifier = match.params.identifier

  const [css] = useStyletron()
  const storedValueEnabled = useFlag('stored-value-enabled', false)
  const userTimeZone = useUserTimeZone()
  const [isFetchingProfile, setIsFetchingProfile] = useState(false)
  const [showGrantModal, setShowGrantModal] = useState(false)
  const [activeTab, setActiveTab] = useState<Tab>(() => {
    const param = history.location.hash.replace('#', '') as Tab
    if (isTab(param)) {
      return param
    }
    return 'profile'
  })
  const { data: storedValueData } = storedValueApi.useGetConfigQuery(
    undefined,
    { skip: !storedValueEnabled }
  )
  const hasStoredValue =
    storedValueEnabled && storedValueData?.state === 'active'

  const dispatch = useDispatch()
  useEffect(() => {
    async function fetchProfile() {
      setIsFetchingProfile(true)
      await dispatch(getUserProfile(identifier))
      setIsFetchingProfile(false)
    }

    fetchProfile()
  }, [dispatch, identifier])

  const profile = useSelector(state => selectUserProfile(state, identifier))

  const title = (() => {
    if (!profile) return t('title')

    const name = getFullName(profile)
    return name || t('title')
  })()

  const ability = useAbility()

  const tabs: TabT[] = [
    {
      id: 'activity',
      name: t('activity.title'),
      content: <Activity profile={profile} />,
      disabled: ability.cannot('access', 'LocationDetailReports'),
    },
    {
      id: 'profile',
      name: t('profile.title'),
      content: <Profile profile={profile} />,
    },
  ]

  if (profile) {
    tabs.push({
      id: 'cards',
      name: t('cards.title'),
      content: <Cards profile={profile} />,
    })
  }

  if (hasStoredValue && profile) {
    tabs.push({
      id: 'stored_value',
      name: t('stored_value.title'),
      content: <StoredValue profile={profile} />,
    })
  }

  return (
    <Container fluid className="p-0 white-bkg">
      <Helmet>
        <title>{t(`${activeTab}.page_title`)}</title>
      </Helmet>
      <Row className="mb-s ml-s mt-s">
        <Link
          className="grey-90 grey-10-hover-bkg grey-90-hover bold border-radius-5 no-underline"
          to={'/users'}
        >
          <i className="mr-s fa fa-arrow-left grey-90" title={t('back')} />
          {t('back')}
        </Link>
      </Row>
      <Container>
        <div className="d-flex justify-content-between mb-m">
          <div>
            <Switch condition={profile?.membership_state === 'disabled'}>
              <div className={'mb-xs'}>
                <Badge
                  value={t('blocked')}
                  className={`${css({
                    backgroundColor: merchantTheme.colors.cayenne10,
                    color: merchantTheme.colors.cayenne50,
                  })}`}
                />
              </div>
            </Switch>
            <Spinner
              className={'d-flex justify-content-center'}
              isLoading={isFetchingProfile}
            >
              <Text.Header2 className="m-0 mr-xl">{title}</Text.Header2>
            </Spinner>
            {profile?.membership_created_at && (
              <div className={'mt-xs'}>
                <Text.BodyText3>
                  {t('member_since', {
                    date: getFormattedDate(
                      moment.tz(profile.membership_created_at, userTimeZone),
                      'daily'
                    ),
                  })}
                </Text.BodyText3>
              </div>
            )}
          </div>
          <div>
            <div
              className={`d-flex align-items-center ${css({
                columnGap: '16px',
                justifyContent: 'space-between',
                marginRight: '-16px',
                marginLeft: '-16px',
              })}`}
            >
              {!!profile && <ActionsMenu profile={profile} />}
              <Button onClick={() => setShowGrantModal(true)}>
                {t('grant_reward')}
              </Button>
            </div>
          </div>
        </div>
        <div>{profile && <TierBadge profile={profile} />}</div>
      </Container>
      <Spinner
        className={'d-flex justify-content-center'}
        size="3x"
        isLoading={isFetchingProfile}
      >
        <TabsContainer
          defaultTab={activeTab}
          shouldReplaceHash
          onSelect={tab => setActiveTab(tab as Tab)}
          tabs={tabs}
        />
      </Spinner>
      {!!profile && (
        <GrantModal
          visible={showGrantModal}
          onClose={() => setShowGrantModal(false)}
          profile={profile}
        />
      )}
    </Container>
  )
}

export default View
