import { Fields as Merchant } from 'models/Merchant'
export const primaryLogoUrl = (merchant: Merchant) => {
  if (merchant.branded_app) return merchant.app_primary_image_urls.default
  return merchant.logo_image_urls.default
}

export const secondaryLogoUrl = (merchant: Merchant) => {
  if (merchant.branded_app) {
    if (merchant.app_secondary_image_urls)
      return merchant.app_secondary_image_urls.default
  }
  return primaryLogoUrl(merchant)
}
