import { useStyletron } from '@thanx/uikit/theme'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

type PropsT = {
  templateId: number
}

function UsageTab(props: PropsT) {
  const merchant = useCurrentMerchant()
  const [css] = useStyletron()

  if (!merchant) return null

  const height = 800

  return (
    <>
      <Container className={`px-0 ${css({ minHeight: `${height + 300}px` })}`}>
        <LookerIframe
          path={'dashboards/174'}
          merchant={merchant}
          height={`${height}px`}
          urlParams={{
            'Template ID': props.templateId.toString(),
            hide_filter: 'Template ID',
          }}
        />
        <LookerFooter className="my-xl" />
      </Container>
    </>
  )
}

export default UsageTab
