import { api } from './index'

type SlideType = 'simple' | 'push_notification' | 'authentication'

interface ImageUrls {
  small: string
  default: string
  large: string
}

export type SlideFrequency = 'once' | 'multiple'

export interface Slide {
  id: number
  position: number
  type: SlideType
  image_urls: ImageUrls
  button_description: string
  content_description: string
}

export interface Config {
  controller: {
    enabled_arrow_color: string
    disabled_arrow_color: string
    active_dot_color: string
    inactive_dot_color: string
  }
  settings: {
    frequency: SlideFrequency
  }
}

export interface OnboardingSlideshow {
  id: number
  slides: Array<Slide>
  config: Config
}

interface CreateSlidePayload
  extends Pick<
    Slide,
    'position' | 'content_description' | 'button_description'
  > {
  id?: number
  slide_type: Slide['type']
  file_uploads?: {
    image: {
      file_upload_id: number
    }
  }
}

type DeleteSlidePayload = {
  id: number
  destroy: boolean
}

type UpdateSlidePayload = CreateSlidePayload | DeleteSlidePayload

export type OnboardingSlideshowUpdatePayload = {
  id: number
  onboarding_slideshow: {
    slides: Array<UpdateSlidePayload>
    config: Config
  }
}

export const onboardingSlideshowApi = api.injectEndpoints({
  endpoints: builder => ({
    getOnboardingSlideshow: builder.query<
      { app_onboarding_slideshow: OnboardingSlideshow },
      number
    >({
      query: appId => `apps/${appId}/onboarding_slideshow`,
      providesTags: [{ type: 'OnboardingSlideshow' }],
    }),
    updateOnboardingSlideshow: builder.mutation<
      { app_onboarding_slideshow: OnboardingSlideshow },
      OnboardingSlideshowUpdatePayload
    >({
      query: payload => ({
        url: `apps/${payload.id}/onboarding_slideshow`,
        method: 'PUT',
        body: {
          onboarding_slideshow: payload.onboarding_slideshow,
        },
      }),
      invalidatesTags: (_, error) =>
        error ? [] : [{ type: 'OnboardingSlideshow' }],
    }),
  }),
})
