import {
  GetActivationRateMetricsAction,
  GET_ACTIVATION_RATE_METRICS,
  GET_ACTIVATION_RATE_METRICS_FAIL,
  GET_ACTIVATION_RATE_METRICS_SUCCESS,
  ResolvedAction,
} from 'actions/activationRateMetrics'
import { RESET_DB } from 'actions/orm'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import { attr, Model, ModelType } from 'redux-orm'
import { EmptyObject } from 'utilities/types'

export enum Type {
  CUMULATIVE = 'activation_rate_metric_cumulative',
  TIME_SERIES = 'activation_rate_metric_time_series',
  COHORTS = 'activation_rate_metric_cohorts',
}

export enum ShortType {
  CUMULATIVE = 'cumulative',
  TIME_SERIES = 'time_series',
  COHORTS = 'cohorts',
}

export type MetricTypes = Array<ShortType>

interface RateData {
  rate: number
  first_purchasers_count: number
  third_purchasers_count: number
}

interface Data extends RateData {
  date_time: string
}

export type TimeSeriesInterval =
  | 'window_30d'
  | 'window_60d'
  | 'window_90d'
  | 'window_120d'
  | 'window_150d'
  | 'window_180d'

type CohortData = Pick<Data, 'date_time' | 'first_purchasers_count'> &
  Record<TimeSeriesInterval, RateData>

interface BaseMetric {
  id: string
  type: Type
  isLoading?: boolean
  isErrored?: boolean
}

export interface ActivationRateCumulativeMetric extends BaseMetric {
  type: Type.CUMULATIVE
  current?: Data
  previous?: Data
  timezone: string
  granularity: string
}

export interface ActivationRateTimeSeriesMetric extends BaseMetric {
  type: Type.TIME_SERIES
  values: Array<Data>
  timezone: string
  granularity: DateRangeGranularity
}

export interface ActivationRateCohortMetric extends BaseMetric {
  type: Type.COHORTS
  values: Array<CohortData>
  timezone: string
  granularity: DateRangeGranularity
}

const getTypeFromPath = url => {
  const paths: string[] = url.split('/') || []
  const type = `activation_rate_metric_${paths[paths.length - 1]}` as Type

  return type
}

export type Fields =
  | ActivationRateCumulativeMetric
  | ActivationRateTimeSeriesMetric
  | ActivationRateCohortMetric

export default class ActivationRateMetric extends Model<
  // @ts-ignore
  typeof ActivationRateMetric,
  Fields
> {
  static modelName: string = 'ActivationRateMetric'

  static get fields(): any {
    return {
      merchant_id: attr(),
      current: attr(),
      previous: attr(),
      timezone: attr(),
      granularity: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action:
      | ResolvedAction
      | { type: typeof RESET_DB }
      | GetActivationRateMetricsAction,
    // @ts-ignore
    ActivationRateMetric: ModelType<ActivationRateMetric>
  ) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_ACTIVATION_RATE_METRICS: {
        const params = action.payload.request?.params || {}
        const url: string = action.payload?.request?.url || ''
        const { type: paramsType } = params
        const type =
          paramsType === ShortType.COHORTS ? Type.COHORTS : getTypeFromPath(url)
        ActivationRateMetric.upsert({
          id: `${params.merchant_id}_${type}`,
          type: type,
          isLoading: true,
          isErrored: false,
        })
        break
      }
      case GET_ACTIVATION_RATE_METRICS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetActivationRateMetricsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const url: string = previousAction.payload?.request?.url || ''
        const { type: paramsType } = params
        const type =
          paramsType === ShortType.COHORTS ? Type.COHORTS : getTypeFromPath(url)
        ActivationRateMetric.upsert({
          ...action.payload.data,
          id: `${params.merchant_id}_${type}`,
          type: type,
          isLoading: false,
          isErrored: false,
        })
        break
      }
      case GET_ACTIVATION_RATE_METRICS_FAIL: {
        const previousAction = action.meta.previousAction as
          | GetActivationRateMetricsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const url: string = previousAction.payload?.request?.url || ''
        const { type: paramsType } = params
        const type =
          paramsType === ShortType.COHORTS ? Type.COHORTS : getTypeFromPath(url)
        ActivationRateMetric.upsert({
          id: `${params.merchant_id}_${type}`,
          type: type,
          isLoading: false,
          isErrored: true,
        })
        break
      }
      case RESET_DB:
        ActivationRateMetric.all().delete()
        break
      default:
        break
    }
  }
}
