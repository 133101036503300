import { ContentBlockTypes } from 'api/landingPageTheme'
import cartImage from 'assets/images/cms/app-home-page/cart.svg'
import customDataImage from 'assets/images/cms/app-home-page/customData.svg'
import giftImage from 'assets/images/cms/app-home-page/gift.svg'
import pictureImage from 'assets/images/cms/app-home-page/picture.svg'
import plateImage from 'assets/images/cms/app-home-page/plate.svg'
import transferImage from 'assets/images/cms/app-home-page/transfer.svg'
import walletImage from 'assets/images/cms/app-home-page/wallet.svg'
import { ImagePickerValue } from 'components/ImagePicker'

export const getTitleIcon = (type: string) => {
  switch (type) {
    case 'featured_content':
      return pictureImage
    case 'missing_loyalty_prompt':
      return transferImage
    case 'stored_value_balance':
      return walletImage
    case 'rewards':
      return giftImage
    case 'recent_orders':
      return cartImage
    case 'menu_categories':
      return plateImage
    case 'external_form':
      return customDataImage
    default:
      break
  }
}

export const getIsCarousel = (type: ContentBlockTypes) => {
  switch (type) {
    case 'recent_orders':
    case 'rewards':
    case 'menu_categories':
      return true
    default:
      return false
  }
}

export const getHasContentStyles = (type: ContentBlockTypes) => {
  switch (type) {
    case 'recent_orders':
    case 'rewards':
    case 'menu_categories':
    case 'missing_loyalty_prompt':
    case 'stored_value_balance':
      return true
    default:
      return false
  }
}

export const getHasButtonStyles = (type: ContentBlockTypes) => {
  switch (type) {
    case 'recent_orders':
    case 'rewards':
    case 'missing_loyalty_prompt':
      return true
    default:
      return false
  }
}

export const getImageUrl = (image: ImagePickerValue): string | null => {
  if (image.id) {
    return image.pendingImage || null
  } else if (image.url) {
    return image.url
  }
  return null
}
