import { Switch } from '@thanx/uikit/switch'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type Props = {
  title: string
  isConfigured: boolean
  isDisabled: boolean
  messages: string[]
}

const PreviewCard: React.FC<Props> = ({
  title,
  isConfigured,
  isDisabled,
  messages,
}) => {
  return (
    <div
      className={`p-s border-bottom border-bottom-1 ${
        isConfigured && !isDisabled ? 'white-bkg' : ' grey-10-bkg'
      }`}
    >
      <p
        className={`small-caps-3 mb-xs bold ${
          isConfigured ? 'grey-90' : 'grey-40'
        }`}
      >
        {title}
      </p>
      <ul className={`mb-xs pl-s ${isConfigured ? 'grey-70' : 'grey-50'}`}>
        <Switch
          condition={isConfigured}
          fallback={
            <li className="body-text-4">
              {I18n.t('redeem_manager.builder.preview.not_configured')}
            </li>
          }
        >
          {messages.map(message => (
            <li key={message} className="body-text-4">
              {message}
            </li>
          ))}
        </Switch>
      </ul>
    </div>
  )
}

export default PreviewCard
