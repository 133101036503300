import { Switch } from '@thanx/uikit/switch'
import bootstrap from 'constants/bootstrap'
import palette from 'constants/palette'
import capitalize from 'lodash/capitalize'
import type { CustomerData, Metrics } from 'models/CampaignMetric'
import React from 'react'
import { I18n, Localize, Translate } from 'react-redux-i18n'
import MediaQuery from 'react-responsive'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'
import CustomersCarousel from './components/CustomerCarousel'
import CustomerProfileView from './components/CustomerProfileView'

type Props = {
  hasDivider?: boolean
  metrics?: Metrics
}

const oldDashboardPath = process.env.REACT_APP_MERCHANT_ROOT_URL || ''

export const Customers: React.FC<Props> = props => {
  const { hasDivider = false, metrics } = props
  const topCustomers = metrics?.customers?.top_customers

  function onClick(userIdentifier: string) {
    const href = `${oldDashboardPath}/member_profile/${userIdentifier}`
    window.location.assign(href)
  }

  function customerBoxes() {
    const colors = [palette.wisteria, palette.spearmint, palette.orange]

    if (!topCustomers) return []

    return topCustomers.map((customer, i) => (
      <div key={i}>
        <div className="customer-box margin-small">
          <CustomerProfileView
            avatarColor={colors[i % colors.length]}
            avatarImage={customer.picture}
            button={I18n.t('thanx_campaigns.report.customers.button_text', {
              firstName: customer.first_name,
            })}
            title={customer.first_name}
            onClick={() => onClick(customer.identifier)}
          >
            {customerContent(customer)}
          </CustomerProfileView>
        </div>
      </div>
    ))
  }

  function customerContent(customer: CustomerData) {
    return (
      <div className="grey-70 font-size-20">
        <div className="display-flex flex-space-between margin-bottom-small">
          <span>
            <Translate value="thanx_campaigns.report.customers.average_check" />
            :
          </span>
          <Localize
            value={customer.average_check}
            options={{
              style: 'currency',
              currency: 'USD',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }}
          />
        </div>
        <div className="display-flex flex-space-between margin-bottom-small">
          <span>
            <Translate value="thanx_campaigns.report.customers.average_frequency" />
            :
          </span>
          <span>{capitalize(customer.average_frequency)}</span>
        </div>
        <div className="display-flex flex-space-between">
          <span>
            <Translate value="thanx_campaigns.report.customers.lifetime_value" />
            :
          </span>
          <span>
            <Localize
              value={customer.lifetime_value}
              options={{
                style: 'currency',
                currency: 'USD',
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }}
            />
          </span>
        </div>
      </div>
    )
  }

  if (!topCustomers || topCustomers.length < 4) {
    return null
  }

  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <div className="customers-section">
          <p className="deprecated__small-caps-3 margin-bottom-huge">
            <Translate value="thanx_campaigns.report.customers.title" />
          </p>
          <div>
            <MediaQuery minWidth={bootstrap.screenLgMin}>
              <CustomersCarousel itemsPerSlide={4}>
                {customerBoxes()}
              </CustomersCarousel>
            </MediaQuery>
            <MediaQuery
              minWidth={bootstrap.screenMdMin}
              maxWidth={bootstrap.screenMdMax}
            >
              <CustomersCarousel itemsPerSlide={3}>
                {customerBoxes()}
              </CustomersCarousel>
            </MediaQuery>
            <MediaQuery
              minWidth={bootstrap.screenSmMin}
              maxWidth={bootstrap.screenSmMax}
            >
              <CustomersCarousel itemsPerSlide={2}>
                {customerBoxes()}
              </CustomersCarousel>
            </MediaQuery>
            <MediaQuery maxWidth={bootstrap.screenXsMax}>
              <CustomersCarousel itemsPerSlide={1}>
                {customerBoxes()}
              </CustomersCarousel>
            </MediaQuery>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withMetrics(['customers'])(Customers)
