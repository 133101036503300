import { VariantOrCampaign } from 'models/CampaignVariant'
import React, { useContext } from 'react'

const Context = React.createContext<VariantOrCampaign | null>(null)

export function useVariant() {
  return useContext(Context)
}

export default Context
