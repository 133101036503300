import {
  EXPIRE_SESSION,
  IMPERSONATE_USER_SUCCESS,
  LOGOUT,
  PASSWORD_AUTH_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  ResolvedAction,
  STORE_AUTH_DATA,
} from 'actions/auth'
import Cookie from 'js-cookie'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import urlUtils from 'utilities/urlUtils'

export type Auth = {
  token?: string | null
  is_admin?: boolean | null
  current_merchant_user_id?: number | null
  current_merchant_id?: number | null
  is_thanx_admin?: boolean | null
  is_demo?: boolean | null
  role?: string | null
  is_session_expired?: boolean
}

const logoutDefaults = {
  token: null,
  is_admin: null,
  current_merchant_user_id: null,
  current_merchant_id: null,
  is_thanx_admin: null,
  is_demo: null,
  role: null,
}

export default (state = {}, action: ResolvedAction): Auth => {
  switch (action.type) {
    case PASSWORD_AUTH_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
    case IMPERSONATE_USER_SUCCESS: {
      Cookie.remove(
        'thanx-auth-password',
        pickBy({ domain: urlUtils.cookieDomain() })
      )
      Cookie.set(
        'thanx-auth-password',
        {
          ...omit(action.payload.data, 'merchant_user'),
          current_merchant_user_id: action.payload.data.merchant_user.id,
        },
        pickBy({
          secure:
            process.env.NODE_ENV !== 'development' &&
            process.env.NODE_ENV !== 'test',
          domain: urlUtils.cookieDomain(),
        })
      )

      const { access_token, merchant_user, role, is_thanx_admin } =
        action.payload.data
      return {
        ...state,
        token: access_token,
        is_admin: merchant_user.is_admin,
        current_merchant_user_id: merchant_user.id,
        is_thanx_admin: is_thanx_admin,
        is_session_expired: false,
        role,
      }
    }
    case STORE_AUTH_DATA:
      const {
        access_token,
        is_admin,
        current_merchant_user_id,
        current_merchant_id,
        is_thanx_admin,
        is_demo,
        role,
      } = action
      return {
        ...state,
        token: access_token,
        is_admin,
        current_merchant_user_id,
        current_merchant_id,
        is_thanx_admin,
        is_demo,
        role,
      }
    case EXPIRE_SESSION:
      return {
        ...state,
        ...logoutDefaults,
        is_session_expired: true,
      }
    case LOGOUT:
      return {
        ...state,
        ...logoutDefaults,
      }
    default:
      return state
  }
}
