import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import Header from '../../components/Header'
import Asset from '../Asset'
import ColorAsset from '../ColorAsset'

const t = buildTranslate('cms.brand.brand_assets.colors')

const Colors: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const { primary_color, secondary_color, tertiary_color } = app

  return (
    <Section className="p-xl mt-l">
      <Header title={t('title')} />
      <Asset
        className="mt-s"
        title={t('primary')}
        subtitle={primary_color}
        description={t('primary_description')}
      >
        <ColorAsset color={primary_color} />
      </Asset>
      <Asset
        className="mt-xl"
        title={t('secondary')}
        subtitle={secondary_color}
        description={t('secondary_description')}
      >
        <ColorAsset color={secondary_color} />
      </Asset>
      <Asset
        className="mt-xl"
        title={t('tertiary')}
        subtitle={tertiary_color}
        description={t('tertiary_description')}
      >
        <ColorAsset color={tertiary_color} />
      </Asset>
    </Section>
  )
}

export default Colors
