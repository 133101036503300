import { useStyletron } from '@thanx/uikit/theme'
import Spinner from 'components/Spinner'
import useFetch from 'hooks/useFetch'
import { Fields as Merchant } from 'models/Merchant'
import React, { useMemo } from 'react'
import LookerError from './components/LookerError'

type PropsT = {
  path: string
  merchant: Merchant
  height?: string
  urlParams?: Record<string, string>
}

const LookerIframe: React.FC<PropsT> = props => {
  const { path, merchant, height, urlParams } = props

  const elementID = `looker-${path}`

  // Constructing URL parameters
  let targetPath = path
  if (urlParams) {
    const params = new URLSearchParams(urlParams)
    targetPath += `?${params.toString()}`
  }

  const config = useMemo(
    () => ({
      method: 'GET',
      url: `${merchant.id}/looker/embed_url`,
      params: {
        target_path: targetPath,
      },
    }),
    [merchant, targetPath]
  )
  const { isLoading, response, errors } = useFetch(config)
  const [css] = useStyletron()
  if (errors) return <LookerError />

  const url = response?.data.url
  const heightClass = path.includes('dashboard') ? 'h-100' : 'h-50'

  return (
    <div className={!!height ? '' : 'h-100'}>
      <iframe
        id={elementID}
        src={url}
        title={path}
        className={`w-100 border-0 ${!height && heightClass} ${
          height && css({ height: height })
        }`}
      />
      <Spinner
        isLoading={isLoading}
        size="4x"
        className="text-center w-100 absolute t-0"
      />
    </div>
  )
}

export default LookerIframe
