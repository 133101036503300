import { expireSession, logout } from 'actions/auth'
import { alert } from 'actions/flash'
import axios from 'axios'
import { I18n } from 'react-redux-i18n'
//@ts-ignore
import { getActionTypes } from 'redux-axios-middleware'
import { selectUserIsAuthenticated } from 'selectors/auth'
import currentMerchant from 'utilities/currentMerchant'

export const clients = {
  old: {
    client: axios.create({
      baseURL: process.env.REACT_APP_OLD_API_URL,
    }),
  },
  default: {
    client: axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/merchants/`,
    }),
  },
  auth: {
    client: axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    }),
  },
}

export const config = {
  defaultClientName: 'old', // remove when old api deprecated
  interceptors: {
    request: [
      ({ getState }, req) => {
        let clientName = req.reduxSourceAction.payload.client || 'old'
        let state = getState()

        if (clientName === 'default' && state.auth.current_merchant_id) {
          req.baseURL = `${req.baseURL}${state.auth.current_merchant_id}`
        }
        if (clientName === 'old' && state.auth.current_merchant_id) {
          req.headers.common[
            'Thanx-Merchant-Id'
          ] = `${state.auth.current_merchant_id}`
        }
        var authToken = state.auth.token
        if (authToken) {
          req.headers.common['Authorization'] = 'Bearer ' + authToken
        }
        req.headers.common['Accept'] = process.env.REACT_APP_ACCEPT_HEADER
        return req
      },
    ],
    response: [
      ({ _getState, _dispatch, _getSourceAction }, rsp) => {
        return rsp
      },
    ],
  },
  onError: ({ action, next, error, getState, dispatch }, options) => {
    // based on parent onError behavior
    // https://github.com/svrcekmichal/redux-axios-middleware/blob/fe677b0cc756ccc3ffa65174ccfcb217b209d1d7/src/defaults.js
    // We explicitly handle 500-level statuses. Otherwise, individual requestors
    // are expected to handle errors (as they have context)
    let errorObject
    const response = error?.response
    const message = error?.message

    if (!response) {
      errorObject = {
        data: message,
        status: 0,
      }
    } else {
      const status = response.status

      // short circuit to globally handle 5xx errors
      if (status >= 500 && status < 600) {
        const state = getState()
        const errorText =
          currentMerchant(state)?.state === 'coming_soon'
            ? 'errors.coming_soon'
            : 'errors.thanx'
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t(errorText),
            error: message,
            displayDetails: false,
            hasReloadButton: true,
          })
        )
      }

      const errorMsg = response?.data?.errors?.messages?.[0]
      const userIsAuthenticated = selectUserIsAuthenticated(getState())
      if (
        status === 401 &&
        errorMsg === I18n.t('errors.invalid_token') &&
        userIsAuthenticated
      ) {
        dispatch(logout())
      }

      if (
        status === 401 &&
        errorMsg === I18n.t('errors.expired_token') &&
        userIsAuthenticated
      ) {
        dispatch(expireSession())
      }

      errorObject = error
    }

    const nextAction = {
      type: getActionTypes(action, options)[2],
      error: errorObject,
      meta: {
        previousAction: action,
      },
    }

    next(nextAction)
    return nextAction
  },
}

export default { clients, config }
