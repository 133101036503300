import orm from 'models/orm'
import { Fields } from 'models/UserEvent'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectUsersEvents = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.UserEvent.all().toRefArray()
  }
)

export const selectUserEvents = createSelector(
  (state: any) => selectUsersEvents(state),
  (_: any, id: number): number => id,
  (usersEvents: any, id: number): Fields[] => {
    return usersEvents.filter(event => event.user_id === id)
  }
)
