import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import { Fields as UserReward } from 'models/UserReward'
import React from 'react'
import { Button } from 'react-bootstrap'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { formatDate } from 'scenes/Users/View/helpers'
import { useUserTimeZone } from 'utilities/userTimeZone'
import { rewardIssuanceDescription } from '../helpers'
import StatusBadge from './StatusBadge'

type PropsT = {
  reward: UserReward
  onPressRetire: (reward: UserReward) => void
  onPressReissue: (reward: UserReward) => void
}

const t = buildTranslate('users.view.activity.rewards')

const Row: React.FC<PropsT> = ({
  reward,
  onPressRetire,
  onPressReissue,
}: PropsT) => {
  const [css] = useStyletron()
  const timezone = useUserTimeZone()
  const deliveredAt = formatDate(timezone, reward.delivered_at)
  const redeemedAt = formatDate(timezone, reward.redeemed_at)
  const description = rewardIssuanceDescription(reward)
  const showRedeemedAt = reward.state !== 'retired'
  const showRetire = ['available', 'active'].some(
    state => reward.state === state
  )
  const showReissue = ['retired', 'used'].some(state => reward.state === state)

  return (
    <tr>
      <BodyCellContainer isErrored={false} isLoading={false}>
        <Text.BodyText4
          tag={'p'}
          className={`capitalize ${css({
            wordWrap: 'break-word',
          })}`}
          color="grey70"
        >
          {reward.redeem_description}
        </Text.BodyText4>
        <Switch condition={!!description}>
          <Text.BodyText5 tag={'p'} color="grey70">
            {description}
          </Text.BodyText5>
        </Switch>
      </BodyCellContainer>

      <BodyCellContainer isErrored={false} isLoading={false}>
        <Text.BodyText4 tag={'p'} color="grey70">
          {deliveredAt?.day}
        </Text.BodyText4>
        <Text.BodyText5 color="grey70">{deliveredAt?.time}</Text.BodyText5>
      </BodyCellContainer>

      <BodyCellContainer isErrored={false} isLoading={false}>
        {showRedeemedAt && (
          <>
            <Text.BodyText4 tag={'p'} color="grey70">
              {redeemedAt?.day}
            </Text.BodyText4>
            <Text.BodyText5 color="grey70">{redeemedAt?.time}</Text.BodyText5>
          </>
        )}
      </BodyCellContainer>

      <BodyCellContainer isErrored={false} isLoading={false}>
        {reward.state && <StatusBadge status={reward.state} />}
      </BodyCellContainer>

      <BodyCellContainer isErrored={false} isLoading={false}>
        {showRetire && (
          <Button
            onClick={() => onPressRetire(reward)}
            className="d-flex align-items-center"
            bsStyle="link"
          >
            {t('actions.retire')}
          </Button>
        )}
        {showReissue && (
          <Button
            onClick={() => onPressReissue(reward)}
            className="d-flex align-items-center"
            bsStyle="link"
          >
            {t('actions.reissue')}
          </Button>
        )}
      </BodyCellContainer>
    </tr>
  )
}

export default Row
