import { ORM } from 'redux-orm'
import ActivationRateMetric from './ActivationRateMetric'
import App from './App'
import Banner from './Banner'
import Brand from './Brand'
import Campaign from './Campaign'
import CampaignMetric from './CampaignMetric'
import CampaignRequirement from './CampaignRequirement'
import CampaignType from './CampaignType'
import CampaignVariant from './CampaignVariant'
import CaptureRateMetric from './CaptureRateMetric'
import Card from './Card'
import Config from './Config'
import CouponPool from './CouponPool'
import EffectiveDiscountRateMetric from './EffectiveDiscountRateMetric'
import EmailProvider from './EmailProvider'
import EmailProviderList from './EmailProviderList'
import ExclusiveDeal from './ExclusiveDeal'
import FileUpload from './FileUpload'
import GrantableCampaign from './GrantableCampaign'
import HiddenMenu from './HiddenMenu'
import IntegrationsConfig from './IntegrationsConfig'
import ItemPurchase from './ItemPurchase'
import LandingHeader from './LandingHeader'
import LandingLink from './LandingLink'
import LandingPage from './LandingPage'
import Link from './Link'
import Location from './Location'
import LocationCategory from './LocationCategory'
import Menu from './Menu'
import Merchant from './Merchant'
import MerchantCouponPool from './MerchantCouponPool'
import MerchantMetric from './MerchantMetric'
import MerchantUser from './MerchantUser'
import NotifyEmail from './NotifyEmail'
import OrderingConfig from './OrderingConfig'
import Pagination from './Pagination'
import PointsExperience from './PointsExperience'
import PointsExperienceMetric from './PointsExperienceMetric'
import PointsProduct from './PointsProduct'
import Product from './Product'
import PurchasesMetric from './PurchasesMetric'
import Redeem from './Redeem'
import RedeemTemplate from './RedeemTemplate'
import RetentionRateMetric from './RetentionRateMetric'
import SmsTrigger from './SmsTrigger'
import TargetSegment from './TargetSegment'
import TemplateDesignVariable from './TemplateDesignVariable'
import Tier from './Tier'
import User from './User'
import UserCampaign from './UserCampaign'
import UserEvent from './UserEvent'
import UserFeedback from './UserFeedback'
import UserItem from './UserItem'
import UserProfile from './UserProfile'
import UserReward from './UserReward'
import UserTagKey from './UserTagKey'
import UserTagValue from './UserTagValue'
import Validation from './Validation'

export interface State {
  App: typeof App
  Banner: typeof Banner
  Brand: typeof Brand
  Campaign: typeof Campaign
  CampaignMetric: typeof CampaignMetric
  CampaignRequirement: typeof CampaignRequirement
  Pagination: typeof Pagination
  CampaignType: typeof CampaignType
  CampaignVariant: typeof CampaignVariant
  CaptureRateMetric: typeof CaptureRateMetric
  Card: typeof Card
  ActivationRateMetric: typeof ActivationRateMetric
  Config: typeof Config
  EffectiveDiscountRateMetric: typeof EffectiveDiscountRateMetric
  EmailProvider: typeof EmailProvider
  EmailProviderList: typeof EmailProviderList
  ExclusiveDeal: typeof ExclusiveDeal
  GrantableCampaign: typeof GrantableCampaign
  HiddenMenu: typeof HiddenMenu
  IntegrationsConfig: typeof IntegrationsConfig
  ItemPurchase: typeof ItemPurchase
  LandingPage: typeof LandingPage
  LandingHeader: typeof LandingHeader
  LandingLink: typeof LandingLink
  Link: typeof Link
  Location: typeof Location
  LocationCategory: typeof LocationCategory
  Menu: typeof Menu
  Merchant: typeof Merchant
  MerchantMetric: typeof MerchantMetric
  MerchantUser: typeof MerchantUser
  NotifyEmail: typeof NotifyEmail
  OrderingConfig: typeof OrderingConfig
  PointsExperienceMetric: typeof PointsExperienceMetric
  Redeem: typeof Redeem
  RedeemTemplate: typeof RedeemTemplate
  RetentionRateMetric: typeof RetentionRateMetric
  PointsExperience: typeof PointsExperience
  PointsProduct: typeof PointsProduct
  PurchasesMetric: typeof PurchasesMetric
  Product: typeof Product
  SmsTrigger: typeof SmsTrigger
  TargetSegment: typeof TargetSegment
  TemplateDesignVariable: typeof TemplateDesignVariable
  Tier: typeof Tier
  User: typeof User
  UserEvent: typeof UserEvent
  UserFeedback: typeof UserFeedback
  UserCampaign: typeof UserCampaign
  UserProfile: typeof UserProfile
  UserReward: typeof UserReward
  UserTagKey: typeof UserTagKey
  UserTagValue: typeof UserTagValue
  UserItem: typeof UserItem
  Validation: typeof Validation
  CouponPool: typeof CouponPool
  MerchantCouponPool: typeof MerchantCouponPool
  FileUpload: typeof FileUpload
}

const orm = new ORM<State>()
orm.register(
  App,
  Banner,
  Brand,
  Campaign,
  CampaignMetric,
  CampaignRequirement,
  Pagination,
  CampaignType,
  CampaignVariant,
  CaptureRateMetric,
  Card,
  ActivationRateMetric,
  CouponPool,
  MerchantCouponPool,
  Config,
  EffectiveDiscountRateMetric,
  EmailProvider,
  EmailProviderList,
  ExclusiveDeal,
  FileUpload,
  GrantableCampaign,
  HiddenMenu,
  IntegrationsConfig,
  LandingPage,
  LandingHeader,
  LandingLink,
  Link,
  Location,
  LocationCategory,
  Menu,
  Merchant,
  MerchantMetric,
  MerchantUser,
  NotifyEmail,
  OrderingConfig,
  PointsExperienceMetric,
  PurchasesMetric,
  Redeem,
  RedeemTemplate,
  RetentionRateMetric,
  PointsExperience,
  PointsProduct,
  Product,
  SmsTrigger,
  TargetSegment,
  TemplateDesignVariable,
  Tier,
  User,
  UserEvent,
  UserFeedback,
  UserCampaign,
  UserProfile,
  UserReward,
  UserTagKey,
  UserTagValue,
  UserItem,
  ItemPurchase,
  Validation
)

export default orm
