import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import LockCircled from 'assets/images/billing/img_lock-circled.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import { useAbility } from 'utilities/ability'
import PageHeader from './PageHeader'
import { Props as PageHeaderProps } from './PageHeader'

type Props = {
  children?: React.ReactNode
  resource: string
  action?: string
  forbiddenTitle?: string
  forbiddenMessage?: string
} & PageHeaderProps

const t = buildTranslate('errors')

const ProtectedPageHeader: React.FC<Props> = ({
  children,
  resource,
  action = 'manage',
  forbiddenTitle = t('forbidden_title'),
  forbiddenMessage = t('forbidden_message'),
  ...pageHeaderProps
}) => {
  const hasAbility = useAbility().can(action, resource)

  return (
    <Switch
      condition={!hasAbility}
      fallback={<PageHeader {...pageHeaderProps}>{children}</PageHeader>}
    >
      <PageHeader {...pageHeaderProps}>
        <div className="grey-05-bkg h-100 border-top-1 pb-xxl grey-20-border mt-l">
          <Container className="pt-xl">
            <div className="text-center">
              <div>
                <img src={LockCircled} alt="lock" width="32" />
              </div>
              <Text.Header4 bold className="mt-m mb-xs grey-90">
                {forbiddenTitle}
              </Text.Header4>
              <Text.BodyText3 className="grey70" tag="p">
                {forbiddenMessage}
              </Text.BodyText3>
            </div>
          </Container>
        </div>
      </PageHeader>
    </Switch>
  )
}

export default ProtectedPageHeader
