import { Switch } from '@thanx/uikit/switch'
import { getCampaignTypes } from 'actions/campaignTypes'
import { getTargetSegments } from 'actions/targetSegments'
import banner_image from 'assets/images/campaign-center/landing/automated/banner_image_dark.png'
import ABPromoGif from 'components/banners/ABPromoGif'
import Loading from 'components/Loading'
import Pagination from 'components/Pagination'
import PromoBanner from 'components/PromoBanner'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { selectCampaigns } from 'selectors/campaign'
import HeroBanner from '../HeroBanner'
import CustomCampaigns from './CustomCampaigns'
import { useCampaigns } from './hooks'
import Table from './Table'
import TargetImage from './TargetImage'

const t = buildTranslate('thanx_campaigns.landing.automated')

const Automated: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)

  const { isFetchingPage, currentPage, numPages, onChangePage, campaignIds } =
    useCampaigns('automated')

  const campaigns = useSelector(state => selectCampaigns(state)).filter(
    campaign => campaignIds.includes(campaign.id)
  )

  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      await Promise.all([
        dispatch(getCampaignTypes({ category: 'automated' })),
        dispatch(getTargetSegments()),
      ])
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  return (
    <div>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <HeroBanner
        title={t('title')}
        description={t('description')}
        image={banner_image}
        alt={t('title')}
        className="spearmint-bkg mb-xxl"
      />
      <Container>
        <PromoBanner storageKey="abPromoDismissedAutomated">
          <ABPromoGif />
        </PromoBanner>
      </Container>
      <Spinner className="text-center" isLoading={isFetchingPage} size={'3x'}>
        <Table campaigns={campaigns} />
      </Spinner>
      <Switch condition={campaigns.length > 0}>
        <div className={'d-flex justify-content-center mt-l mb-l'}>
          <Pagination
            currentPage={currentPage}
            onChangePage={onChangePage}
            numPages={numPages}
          />
        </div>
      </Switch>
      <Switch
        condition={!isLoading}
        fallback={
          <div className="mb-xl">
            <Loading />
          </div>
        }
      >
        <CustomCampaigns />
      </Switch>
      <div className="spearmint-10-bkg py-xxl">
        <Container>
          <Row>
            <Col md={6}>
              <div className="small-caps-1 mb-xl">{t('footer.title')}</div>
              <div className="h2 mb-xs">{t('footer.subtitle')}</div>
              <div className="body-text-1 grey-70-text mb-xl">
                {t('footer.description')}
              </div>
              <LinkContainer to="/thanx_campaigns/targeted">
                <Button size="lg" className="bold">
                  {t('footer.button')}
                </Button>
              </LinkContainer>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <TargetImage />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Automated
