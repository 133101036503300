import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import Body3Html from './common/Body3Html'
import LegalContainer from './common/LegalContainer'

const t = buildTranslate('legal.accessibility')

function Accessibility() {
  return (
    <LegalContainer>
      <Text.Header2>{t('title')}</Text.Header2>
      <Body3Html tag="p">{t('updated')}</Body3Html>

      <Text.Header3>{t('general.title')}</Text.Header3>
      <Body3Html tag="p">{t('general.content')}</Body3Html>

      <Text.Header3>{t('accessibility_on_order.title')}</Text.Header3>
      <Body3Html tag="p">{t('accessibility_on_order.content')}</Body3Html>

      <Text.Header3>{t('enabling_menu.title')}</Text.Header3>
      <Body3Html tag="p">{t('enabling_menu.content')}</Body3Html>

      <Text.Header3>{t('disclaimer.title')}</Text.Header3>
      <Body3Html tag="p">{t('disclaimer.content')}</Body3Html>

      <Text.Header3>{t('here_for_you.title')}</Text.Header3>
      <Body3Html tag="p">{t('here_for_you.content')}</Body3Html>

      <Text.Header3>{t('contact.title')}</Text.Header3>
      <Body3Html tag="p">{t('contact.content')}</Body3Html>
      <Body3Html tag="p">{t('contact.email')}</Body3Html>
    </LegalContainer>
  )
}

export default Accessibility
