import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import ContentContainer from 'components/ContentContainer'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { stages } from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { merchantTheme } from 'theme'
import ConversionCell from './ConversionCell'

const t = buildTranslate('lifecycle_report.overview.table')

type PropsT = {
  stage: typeof stages[number]
  count?: number
  conversionRate?: string
  totalPercentage?: string
  isLoading?: boolean
  isErrored?: boolean
}

const Row: React.FC<PropsT> = props => {
  const {
    stage,
    count,
    conversionRate,
    totalPercentage,
    isLoading,
    isErrored,
  } = props

  const [css] = useStyletron()
  const borderClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: merchantTheme.colors.grey30,
  })

  return (
    <tr>
      <td className={`p-m ${borderClass} align-top`}>
        <Text.BodyText4
          bold
          color="grey70"
          className="ellipsis d-inline-block max-width-100"
        >
          {t(`stage.${stage}`)}
        </Text.BodyText4>
      </td>
      <td className={`p-m ${borderClass} text-right align-top`}>
        <ContentContainer isErrored={!!isErrored} isLoading={!!isLoading}>
          <div className="d-flex align-items-center justify-content-end">
            <Text.BodyText4
              color="grey70"
              className="ellipsis d-inline-block max-width-100 pr-xs"
            >
              {count}
            </Text.BodyText4>
            <PopoverContent
              sections={[
                {
                  content: t(`tooltips.stages.${stage}.customers`),
                },
                {
                  title: t('tooltips.title.recency'),
                  content: t('tooltips.recency'),
                },
              ]}
            />
          </div>
        </ContentContainer>
      </td>
      <td className={`p-m ${borderClass} text-right align-top`}>
        <ContentContainer isErrored={!!isErrored} isLoading={!!isLoading}>
          <ConversionCell stage={stage} conversionRate={conversionRate} />
        </ContentContainer>
      </td>
      <td className={`p-m ${borderClass} text-right align-top`}>
        <ContentContainer isErrored={!!isErrored} isLoading={!!isLoading}>
          <div className="d-flex align-items-center justify-content-end">
            <Text.BodyText4
              color="grey70"
              className="ellipsis max-width-100 pr-xs"
            >
              {totalPercentage}
            </Text.BodyText4>
            <PopoverContent
              sections={[
                {
                  content: t(
                    `tooltips.stages.${stage}.total_percentage.description`
                  ),
                },
                {
                  title: t('tooltips.title.how_it_works'),
                  content: t(
                    `tooltips.stages.${stage}.total_percentage.how_it_works`
                  ),
                },
                {
                  title: t('tooltips.title.recency'),
                  content: t('tooltips.recency'),
                },
              ]}
            />
          </div>
        </ContentContainer>
      </td>
      <td className={`p-m ${borderClass} text-right align-top`}>
        {stage !== 'entered_database' && (
          <Text.BodyText4
            color="grey70"
            className="ellipsis d-inline-block max-width-100"
          >
            <LinkContainer to={`/reports/lifecycle/${stage}`}>
              <Button
                kind="minimal"
                size="small"
                className="border-0 pt-0 font-size-16 font-weight-normal"
              >
                {t('view_details')}
              </Button>
            </LinkContainer>
          </Text.BodyText4>
        )}
      </td>
    </tr>
  )
}

export default Row
