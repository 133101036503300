import React from 'react'

type Props = {
  className?: string
  color?: string
}

const RotateRectangleIcon: React.FC<Props> = props => {
  const { className, color = 'currentColor' } = props
  return (
    <svg
      width="62"
      height="46"
      viewBox="0 0 62 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '62px' }}
      className={className}
    >
      <path
        d="M1 4.71111C1 2.66152 2.66152 1 4.71111 1H57.2889C59.3385 1 61 2.66152 61 4.71111V41.2889C61 43.3385 59.3385 45 57.2889 45H4.71111C2.66152 45 1 43.3385 1 41.2889V4.71111Z"
        fill="white"
        fillOpacity="0"
        stroke={color}
        strokeWidth="2.35556"
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.8643 13.2861C41.0986 13.2861 41.333 13.5205 41.333 13.7549V21.2549C41.333 21.5283 41.0986 21.7236 40.8643 21.7236H33.3643C33.0908 21.7236 32.8955 21.5283 32.8955 21.2549V20.2002C32.8955 19.9268 33.0908 19.7314 33.3643 19.7314L38.2861 19.8486C37.0752 17.1924 34.4189 15.3174 31.333 15.3174C27.6221 15.3174 24.5752 17.9346 23.833 21.3721C23.7939 21.6064 23.5986 21.7236 23.3643 21.7236H22.2705C21.958 21.7236 21.7236 21.4893 21.8018 21.1768C22.6221 16.6846 26.5674 13.2861 31.333 13.2861C34.6924 13.2861 37.6611 15.0439 39.3799 17.6221L39.3018 13.7939C39.3018 13.5205 39.4971 13.2861 39.7705 13.2861H40.8643ZM40.3564 24.2236C40.6689 24.2236 40.9033 24.4971 40.8252 24.8096C40.0049 29.3018 36.0596 32.6611 31.333 32.6611C27.9346 32.6611 24.9658 30.9424 23.2471 28.3643L23.3252 32.1924C23.3252 32.4658 23.1299 32.6611 22.8564 32.6611H21.8018C21.5283 32.6611 21.333 32.4658 21.333 32.1924V24.6924C21.333 24.458 21.5283 24.2236 21.8018 24.2236H29.3018C29.5361 24.2236 29.7705 24.458 29.7705 24.6924V25.7861C29.7705 26.0596 29.5361 26.2549 29.2627 26.2549L24.3408 26.1377C25.5518 28.7939 28.208 30.6299 31.333 30.6299C35.0049 30.6299 38.0518 28.0518 38.7939 24.6143C38.833 24.3799 39.0283 24.2236 39.2627 24.2236H40.3564Z"
        fill={color}
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
    </svg>
  )
}

export default RotateRectangleIcon
