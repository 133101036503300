import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import View from 'scenes/CampaignCenter/Builder/components/AudienceBreakoutModalView'

import type { UserCountMetric } from 'models/CampaignMetric'

type Props = {
  show: boolean
  userCountMetric?: UserCountMetric
  onModalCloseClicked: () => void
  category: string
}

const AudienceBreakoutModal: React.FC<Props> = props => {
  const { userCountMetric, category } = props

  const targetedItems = [
    {
      label: (
        <Translate
          value="thanx_campaigns.campaigns.upsell.steps.review.audience.breakout_modal"
          category={category}
          dangerousHTML
        />
      ),
      value: userCountMetric?.data?.member_count || 0,
    },
  ]

  const excludedItems = [
    {
      label: I18n.t(
        'thanx_campaigns.review.customers.breakout_modal.table.excluded.excluded'
      ),
      value: userCountMetric?.data?.excluded_count || 0,
    },
  ]

  return (
    <View
      {...props}
      targetedItems={targetedItems}
      excludedItems={excludedItems}
    />
  )
}

export default AudienceBreakoutModal
