import React from 'react'
import { Button, Carousel, Col, Row } from 'react-bootstrap-five'
import { I18n, Translate } from 'react-redux-i18n'

import image1 from 'assets/images/campaign-center/opportunity/upsell-restricted/image1.png'
import image2 from 'assets/images/campaign-center/opportunity/upsell-restricted/image2.png'
import image3 from 'assets/images/campaign-center/opportunity/upsell-restricted/image3.png'

function image(index: number) {
  switch (index) {
    case 0:
      return image1
    case 1:
      return image2
    case 2:
      return image3
    default:
      return ''
  }
}

function item(index) {
  const prefix = `thanx_campaigns.builder.info_modal.upsell_restricted.item_${
    index + 1
  }.`
  return (
    <Carousel.Item key={index}>
      <Row className="row margin-bottom-large">
        <Col sm={12} md={6} className="padding-large text-left">
          <Translate
            value={prefix + 'title'}
            tag="p"
            className="font-size-20 bold grey-90 margin-bottom-medium"
          />
          <Translate
            value={prefix + 'subtitle'}
            tag="p"
            className="font-size-16 grey-70 margin-bottom-large"
          />
        </Col>
        <Col sm={12} md={6} className="padding-large text-center">
          <img src={image(index)} alt={I18n.t(prefix + 'title')} />
        </Col>
      </Row>
    </Carousel.Item>
  )
}

const CustomButton: React.FC = () => {
  return (
    <Button
      size="sm"
      className="full-width spearmint-50-bkg transparent-border"
      href="mailto:merchant.support@thanx.com"
    >
      <Translate
        value="thanx_campaigns.builder.info_modal.upsell_restricted.button.title"
        className="white"
      />
    </Button>
  )
}

const Separator: React.FC = () => {
  return <hr className="grey-40-border border-top-1 margin-bottom-large" />
}

const Title: React.FC = () => {
  return (
    <div>
      <Translate
        value="thanx_campaigns.builder.info_modal.upsell_restricted.title"
        tag="p"
        className="font-size-22 bold grey-90 margin-bottom-small"
      />
      <Translate
        value="thanx_campaigns.builder.info_modal.upsell_restricted.subtitle"
        tag="em"
        className="font-size-18 grey-60 margin-bottom-extra-large block"
      />
    </div>
  )
}
const UpsellRestricted: React.FC = () => {
  return (
    <div className="upsell-restricted">
      <Title />
      <Separator />
      <Carousel controls={false}>
        {item(0)}
        {item(1)}
        {item(2)}
      </Carousel>
      <Separator />
      <CustomButton />
    </div>
  )
}

export default UpsellRestricted
