import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'

const t = buildTranslate('thanx_campaigns.overview.empty_search_results')

const Empty = () => (
  <div className="text-center">
    <Text.Header4 color="grey90" bold className="mt-0">
      {t('title')}
    </Text.Header4>
    <Text.BodyText3 color="grey70">{t('body')}</Text.BodyText3>
  </div>
)

export default Empty
