import {
  Fields,
  LandingLinkSection,
  LandingLinkStyle,
} from 'models/LandingLink'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const UPDATE_LANDING_LINKS = 'landingLink/UPDATE_LANDING_LINKS'
export const UPDATE_LANDING_LINKS_SUCCESS =
  'landingLink/UPDATE_LANDING_LINKS_SUCCESS'
export const UPDATE_LANDING_LINKS_FAIL = 'landingLink/UPDATE_LANDING_LINKS_FAIL'

type LinkData = {
  id?: number
  position: number
  destroy?: boolean
  url: string
}

export type CtaLinkData = LinkData & {
  title: string
  section: LandingLinkSection.CTA
  style: LandingLinkStyle
}

export type MainLinkData = LinkData & {
  section: LandingLinkSection.Main
  file_uploads?: {
    unpressed_image: number
  }
}

export function updateLandingLinks(
  appId: number,
  links: CtaLinkData[] | MainLinkData[]
): AxiosAction<typeof UPDATE_LANDING_LINKS, { links: Fields[] }> {
  return {
    type: UPDATE_LANDING_LINKS,
    payload: {
      client: 'default',
      request: {
        url: `apps/${appId}/landing_page/links`,
        method: 'put',
        data: {
          links,
        },
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof updateLandingLinks>
>
