export default {
  no_restrictions: 'No restrictions',
  can_only_redeem: {
    online: 'Customers can only redeem this online',
    instore: 'Customers can only redeem this in-store',
  },
  exchange_dates: {
    can_exchange: 'Customers can exchange %{currencyName} for this reward',
    from: 'from %{from}',
    to: 'until %{to}',
  },
  day: 'day',
  day_plural: 'days',
  hour: 'hour',
  hour_plural: 'hours',
  after_activation:
    'After customers activate this reward, they can see it for %{count} %{unit}.',
  max_amount: 'Customers cannot redeem more than $%{amount}',
}
