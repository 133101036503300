import type { Fields as EffectiveDiscountRateMetric } from 'models/EffectiveDiscountRateMetric'
import {
  EffectiveDiscountRateCumulativeMetric,
  EffectiveDiscountRateTimeSeriesMetric,
  ShortType,
} from 'models/EffectiveDiscountRateMetric'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectAllEffectiveDiscountRateMetrics = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): EffectiveDiscountRateMetric[] => {
    return session.EffectiveDiscountRateMetric.all().toRefArray()
  }
)

export const selectEffectiveDiscountRateCumulativeMetric = createSelector(
  selectAllEffectiveDiscountRateMetrics,
  (_: any, merchantId?: number) => merchantId,
  (
    effectiveDiscountRateMetrics,
    merchantId
  ): EffectiveDiscountRateCumulativeMetric | null => {
    return (
      (effectiveDiscountRateMetrics.find(
        c => c.id === `${merchantId}_effective_discount_rate_metric_cumulative`
      ) as EffectiveDiscountRateCumulativeMetric) ?? null
    )
  }
)

export const selectEffectiveDiscountRateTimeSeriesMetric = createSelector(
  selectAllEffectiveDiscountRateMetrics,
  (_: any, merchantId?: number) => merchantId,
  (
    effectiveDiscountRateMetrics,
    merchantId
  ): EffectiveDiscountRateTimeSeriesMetric | null => {
    return (
      (effectiveDiscountRateMetrics.find(
        c => c.id === `${merchantId}_effective_discount_rate_metric_time_series`
      ) as EffectiveDiscountRateTimeSeriesMetric) ?? null
    )
  }
)

export const selectEffectiveDiscountRateMetricByType = createSelector(
  selectAllEffectiveDiscountRateMetrics,
  (_: any, merchantId?: number) => merchantId,
  (_: any, _merchantId?: number, type?: ShortType) => type,
  (
    activationMetrics,
    merchantId,
    type
  ): EffectiveDiscountRateMetric | undefined => {
    return activationMetrics.find(
      c => c.id === `${merchantId}_effective_discount_rate_metric_${type}`
    ) as EffectiveDiscountRateMetric
  }
)
