import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import CmsPage from '../../components/CmsPage'
import Item from './Item'

const t = buildTranslate('cms.brand.launch_screens')

type Props = {
  isLoading: boolean
}

const LaunchSreens: React.FC<Props> = ({ isLoading }) => {
  const app = useSelector(selectApp)

  if (!app) return null

  const {
    launch_screen_group_one_image_urls: launchScreen1,
    launch_screen_group_two_image_urls: launchScreen2,
    launch_screen_group_three_image_urls: launchScreen3,
    launch_screen_group_four_image_urls: launchScreen4,
    launch_screen_group_five_image_urls: launchScreen5,
    launch_screen_group_six_image_urls: launchScreen6,
    launch_screen_group_seven_image_urls: launchScreen7,
    launch_screen_group_eight_image_urls: launchScreen8,
    launch_screen_group_nine_image_urls: launchScreen9,
  } = app

  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      isLoading={isLoading}
    >
      <Section className="p-xl">
        <Row className="m-0">
          <Item sizeText={t('size_320')} image={launchScreen7} width="144px" />
          <Item sizeText={t('size_800')} image={launchScreen6} width="138px" />
          <Item sizeText={t('size_960')} image={launchScreen5} width="153px" />
          <Item sizeText={t('size_1136')} image={launchScreen8} width="130px" />
        </Row>
        <Row className="m-0">
          <Item sizeText={t('size_1334')} image={launchScreen4} width="129px" />
          <Item sizeText={t('size_2436')} image={launchScreen2} width="106px" />
          <Item sizeText={t('size_2208')} image={launchScreen3} width="129px" />
          <Item sizeText={t('size_2688')} image={launchScreen1} width="106px" />
          <Item sizeText={t('size_2820')} image={launchScreen9} width="117px" />
        </Row>
      </Section>
    </CmsPage>
  )
}

export default LaunchSreens
