import orm from 'models/orm'
import { Fields, State } from 'models/PointsExperience'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const STATE_PRECEDENCE: Record<State, number> = {
  active: 1,
  migrating: 2,
  inactive: 3,
  archived: 4,
}

export const selectPointsExperiences = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.PointsExperience.all().toRefArray()
  }
)

export const selectExperience = createSelector(
  selectPointsExperiences,
  (pointsExperiences: Fields[]): Fields | null => {
    // We only officially support one experience per merchant, but we may end up
    // in situations where we archive an old one and have multiple. This sorting
    // hueristic should get us the expected behavior almost all of the time.
    const sortedExperiences = pointsExperiences
      .filter(pe => pe.state !== 'archived')
      .sort((a, b) => STATE_PRECEDENCE[a.state] - STATE_PRECEDENCE[b.state])

    return sortedExperiences[0] ?? null
  }
)

export const selectPointsExperience = createSelector(
  (state: any) => selectPointsExperiences(state),
  (_: any, id: string | number): string | number => id,
  (pointsExperiences: Fields[], id: string | number): Fields | null => {
    return pointsExperiences.find(pe => pe.id === Number(id)) ?? null
  }
)
