import { Fields } from 'models/IntegrationsConfig'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectIntegrationsConfigs = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.IntegrationsConfig.all().toRefArray()
  }
)

export const selectIntegrationsConfig = createSelector(
  (state: any) => selectIntegrationsConfigs(state),
  (_: any, merchantId?: number): number | undefined => merchantId,
  (integrationsConfigs: Fields[], merchantId?: number): Fields | null => {
    return (
      integrationsConfigs.find(
        ic => ic.id === `integrations_config_${merchantId}`
      ) ?? null
    )
  }
)
