import * as actions from 'actions/campaignTypes/templates'
import { RESET_DB } from 'actions/orm'
import { attr, fk, Model, ModelType } from 'redux-orm'

export interface Fields {
  campaign_type: string
  name: string
  description: string
  value: string
}

export default class TemplateDesignVariable extends Model<
  // @ts-ignore
  typeof TemplateDesignVariable,
  Fields
> {
  static modelName: string = 'TemplateDesignVariable'

  static get fields(): any {
    return {
      campaign_type: fk('CampaignType', 'templateDesignVariables'),
      name: attr(),
      description: attr(),
      value: attr(),
    }
  }

  static reducer(
    action: any,
    /* eslint-disable no-use-before-define */
    // @ts-ignore
    TemplateDesignVariable: ModelType<TemplateDesignVariable>
    /* eslint-enable no-use-before-define */
  ) {
    switch (action.type) {
      case actions.GET_DESIGN_VARIABLES_SUCCESS:
        action.payload.data.template_variables.forEach(variable => {
          TemplateDesignVariable.upsert({
            ...variable,
            id: `${variable.campaign_type}/${variable.name}`,
          })
        })
        break
      case RESET_DB:
        TemplateDesignVariable.all().delete()
        break
      default:
        break
    }
  }
}
