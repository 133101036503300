import { Text } from '@thanx/uikit/text'
import MessageIcon from 'assets/images/campaign-center/builder/img_message.svg'
import ReviewSection from 'components/review/ReviewSection'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import AudienceDisabled from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Audience/components/AudienceDisabled'
import IncentiveDisabled from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Content/Incentive/IncentiveDisabled'
import Messaging from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Content/Messaging'
import Header from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Header'
import ScheduleContainer from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Schedule'

type Props = {
  campaign: Campaign
  setCurrentStep: (step: string) => void
}

const Review: React.FC<Props> = props => {
  const { campaign, setCurrentStep } = props

  return (
    <div className="container thanx_campaigns_review">
      <Header campaign={campaign} />
      <div className="margin-top-large">
        <Text.Header2 className="my-xl">{campaign.name}</Text.Header2>
        <AudienceDisabled
          text={I18n.t('thanx_campaigns.review.customers.disabled.reputation')}
          infoText={I18n.t('thanx_campaigns.review.customers.info.reputation')}
        />
        <ReviewSection icon={MessageIcon}>
          <Text.Header4 tag="div" className="mb-m mt-xxs">
            <Translate value="thanx_campaigns.review.content.title" />
          </Text.Header4>
          <IncentiveDisabled
            infoText={I18n.t(
              'thanx_campaigns.review.incentive.info.reputation'
            )}
          />
          <Messaging
            campaign={campaign}
            setCurrentStep={setCurrentStep}
            isEditable={true}
          />
        </ReviewSection>
        <ScheduleContainer campaign={campaign} />
      </div>
    </div>
  )
}

export default Review
