import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import NotReady from '../components/NotReady'
import { daysUntilReportAccess } from '../helpers'
import Content from './Content'
import { AVAILABLE_IN_DAYS } from './helpers'

const t = buildTranslate('activation_rate')

type PropsT = {
  onSwitchTabAction: (tab: any) => void
}

const Main: React.FC<PropsT> = ({ onSwitchTabAction }) => {
  const merchant = useCurrentMerchant()

  if (!merchant) return null

  const remainingDays = daysUntilReportAccess(merchant, AVAILABLE_IN_DAYS)
  const showEmptyState = remainingDays === null || remainingDays >= 1

  if (showEmptyState)
    return (
      <NotReady
        remainingDays={remainingDays}
        minimumDaysAfterLaunch={AVAILABLE_IN_DAYS}
        title={t('activation_rate')}
      />
    )

  return <Content onSwitchTabAction={onSwitchTabAction} />
}

export default Main
