import { Text } from '@thanx/uikit/text'
import Counter from 'components/Counter'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import { EffectiveDiscountRateCumulativeMetric } from 'models/EffectiveDiscountRateMetric'
import React from 'react'
import { BenchmarkT, tooltipPercentage } from 'scenes/Reports/helpers'
import { getDateLabel } from 'utilities/date'
import { currencyFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('points.effective_discount_rate')

type PropsT = {
  isErrored: boolean
  isLoading: boolean
  currentRateData: EffectiveDiscountRateCumulativeMetric['current']
  previousRateData: EffectiveDiscountRateCumulativeMetric['previous']
  benchmark: BenchmarkT
  timezone: string
}

const Counters: React.FC<PropsT> = ({
  isErrored,
  isLoading,
  currentRateData,
  previousRateData,
  benchmark,
  timezone,
}) => {
  const changeInRate = ((): number => {
    if (!currentRateData || !previousRateData) return 0

    return (
      currentRateData.effective_discount_rate -
      previousRateData.effective_discount_rate
    )
  })()

  const changeRateColor = (() => {
    if (changeInRate > 0) return palette.cayenne50
    if (changeInRate < 0) return palette.nephritis

    return ''
  })()

  const displayChangeInRate = `${
    changeInRate > 0 ? '+' : ''
  }${percentageFormatter(changeInRate, 1, 1)}`

  const dateLabel = currentRateData
    ? getDateLabel(currentRateData?.date_time, 'monthly', timezone)
    : null
  const counterDateLabel = dateLabel ? `(${dateLabel})` : ''

  const effectiveDiscountRate = percentageFormatter(
    currentRateData?.effective_discount_rate,
    1,
    1
  )
  const costOfRewardsRedeemed = currencyFormatter(
    currentRateData?.cost_amount,
    false
  )
  const netRevenue = currencyFormatter(
    currentRateData?.purchases_total_amount,
    false
  )

  return (
    <div>
      <div className="d-flex">
        <Counter
          primaryValue={effectiveDiscountRate}
          secondaryValueColor={changeRateColor}
          secondaryValue={displayChangeInRate}
          className="mr-xxl"
          isErrored={isErrored}
          isLoading={isLoading}
          name={t('counters.effective_discount_rate', { counterDateLabel })}
          sections={[
            {
              content: t('popovers.effective_discount_rate')[0],
            },
            {
              title: t('popovers.how_its_calculated'),
              content: t('popovers.effective_discount_rate')[1],
            },
            {
              title: t('popovers.what_this_means'),
              content: t('popovers.effective_discount_rate', {
                effectiveDiscountRate,
                costOfRewardsRedeemed,
                netRevenue,
              })[2],
            },
          ]}
        />
        <Counter
          primaryValue={costOfRewardsRedeemed}
          className="mr-xxl"
          isErrored={isErrored}
          isLoading={isLoading}
          name={t('counters.cost_of_rewards', { counterDateLabel })}
          sections={[
            {
              content: t('popovers.cost_of_rewards')[0],
            },
            {
              title: t('popovers.what_this_means'),
              content: t('popovers.cost_of_rewards', {
                costOfRewardsRedeemed,
              })[1],
            },
          ]}
        />
        <Counter
          primaryValue={netRevenue}
          className="mr-xxl"
          isErrored={isErrored}
          isLoading={isLoading}
          name={t('counters.net_revenue', { counterDateLabel })}
          sections={[
            {
              content: t('popovers.net_revenue')[0],
            },
            {
              title: t('popovers.what_this_means'),
              content: t('popovers.net_revenue', {
                netRevenue,
              })[1],
            },
          ]}
        />
      </div>
      <Text.BodyText4 className="my-xs" color="wisteria" tag="div">
        {t('counters.industry_benchmark', {
          rate: tooltipPercentage(benchmark.rate),
        })}
      </Text.BodyText4>
    </div>
  )
}

export default Counters
