import type { Fields as UserTagValueT } from 'models/UserTagValue'
import type { SelectOptionT } from './types'

export const getOptionFromUserTagValue = (
  userTagValue: UserTagValueT
): SelectOptionT => {
  return {
    label: userTagValue.label,
    value: userTagValue.value,
  }
}

export const userTagValuesToSelectOptions = (
  userTagValues: UserTagValueT[]
): SelectOptionT[] => {
  if (!userTagValues || !userTagValues.length) return []
  return userTagValues.map(userTagValue =>
    getOptionFromUserTagValue(userTagValue)
  )
}
