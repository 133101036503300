import { StyleableButton } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import trash from 'assets/images/cms/trash.png'
import ConfirmModal from 'components/ConfirmModal'
import Section from 'components/Section'
import { motion } from 'framer-motion'
import { buildTranslate } from 'locales'
import React, { useContext, useState } from 'react'
import TabContext, { Tabs } from '../TabContext'

type Props = {
  index: number
  type: string
  blockCount: number
  canDelete: boolean
  onDelete: () => void
  onSort: (newPosition: number) => void
  children: React.ReactNode
}

const t = buildTranslate('cms.components.blocks')

const BlockItem: React.FC<Props> = props => {
  const { index, type, blockCount, canDelete, onDelete, onSort, children } =
    props
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { activeTab } = useContext(TabContext)
  const [css] = useStyletron()

  return (
    <>
      <ConfirmModal
        title={t('delete_modal.title', { type })}
        description={t('delete_modal.description', { type })}
        confirmText={t('delete_modal.confirm', { type })}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={onDelete}
      />
      <motion.div
        className="d-flex"
        layout={activeTab === Tabs.Content ? 'position' : false}
        transition={{
          type: 'spring',
          damping: 25,
          stiffness: 120,
        }}
      >
        <Section className="flex-1 mb-l mr-xs p-l">{children}</Section>
        <div className="d-flex flex-column align-items-center">
          <StyleableButton
            className="p-0"
            title={t('move_up', { index: index + 1, type })}
            disabled={index <= 0}
            onClick={() => onSort(index - 1)}
          >
            <i className="fa fa-caret-up fa-2x" />
          </StyleableButton>
          <p className="m-0">{index + 1}</p>
          <StyleableButton
            className="p-0"
            title={t('move_down', { index: index + 1, type })}
            disabled={index + 1 >= blockCount}
            onClick={() => onSort(index + 1)}
          >
            <i className="fa fa-caret-down fa-2x" />
          </StyleableButton>
          <Switch condition={canDelete}>
            <StyleableButton
              data-testid={`delete-block-${index + 1}`}
              className={'mt-xs'}
              onClick={() => setIsModalOpen(true)}
            >
              <img
                alt={t('delete', {
                  type,
                })}
                className={`${css({
                  height: '16px',
                })}`}
                src={trash}
              />
            </StyleableButton>
          </Switch>
        </div>
      </motion.div>
    </>
  )
}

export default BlockItem
