import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { Switch } from 'react-router'
import Landing from './Landing'

const UserSupport: React.FC = () => {
  return (
    <div className="full-height grey-05-bkg">
      <Switch>
        <ProtectedRoute
          redirect="/"
          resource="UserSupport"
          exact
          path="/user_support"
          component={Landing}
        />
      </Switch>
    </div>
  )
}

export default UserSupport
