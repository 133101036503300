import { useStyletron } from '@thanx/uikit/theme'
import bookIcon from 'assets/images/img_book.svg'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('general')

type PropsT = {
  url: string
  className?: string
}

const LearnMoreLink: React.FC<PropsT> = ({ url, className = '' }) => {
  const [css] = useStyletron()

  return (
    <a href={url} target="_blank" className={className} rel="noreferrer">
      {t('learn_more')}
      <img
        src={bookIcon}
        alt={t('learn_more')}
        className={`ml-xs ${css({
          marginBottom: '5px',
        })}`}
      />
    </a>
  )
}

export default LearnMoreLink
