import InfoBox from 'components/InfoBox'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.content.app_home_page.content.empty_state')

const EmptyState = () => (
  <InfoBox
    type="tip"
    title={t('title')}
    content={t('description')}
    className="mb-4"
  />
)

export default EmptyState
