import { alert } from 'actions/flash'
import { putOrderingStatuses } from 'actions/ordering_configs'
import ConfirmModal from 'components/ConfirmModal'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'

type PropsT = {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  enable?: boolean
  locationId: number
}

const UpdateOrderingStatusModal: React.FC<PropsT> = props => {
  const { isOpen, setIsOpen, enable, locationId } = props
  const [isUpdating, setIsUpdating] = useState(false)
  const t = buildTranslate(
    `ordering_management.detail.modal.${enable ? 'enable' : 'disable'}`
  )
  const dispatch = useDispatch()

  const onConfirm = () => {
    const update = async () => {
      setIsUpdating(true)
      const response = await dispatch(
        putOrderingStatuses(enable ? 'enable' : 'disable', [locationId])
      )
      if (response.type === 'menus/PUT_ORDERING_STATUSES_SUCCESS')
        dispatch(
          alert({
            key: 'success',
            message: t('success'),
            timeout: 5,
          })
        )
      setIsUpdating(false)
      setIsOpen(false)
    }

    update()
  }

  const content = {
    title: t('title'),
    description: t('description'),
    confirmText: t('confirm_text'),
  }
  return (
    <ConfirmModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onConfirm={onConfirm}
      isJustified
      isLoading={isUpdating}
      {...content}
    />
  )
}

export default UpdateOrderingStatusModal
