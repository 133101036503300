import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'

const t = buildTranslate('privacy.ccpaoptout')

function CcpaOptOut() {
  return (
    <div className="p-xl pt-xl">
      <Text.Header2>{t('title')}</Text.Header2>
      <Text.BodyText3>{t('services')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{t('number')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('changing'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('blocking_cookies'),
          }}
        />
      </Text.BodyText3>{' '}
      <br></br>
      <br></br>
      <Text.BodyText3>{t('blocking_ads')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('privacy'),
          }}
        />
      </Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('platform'),
          }}
        />
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('google'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('facebook'),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>
        <span
          dangerouslySetInnerHTML={{
            __html: t('advertising'),
          }}
        />
      </Text.BodyText3>
      <ul>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('digital'),
              }}
            />
          </Text.BodyText3>
        </li>
        <li>
          <Text.BodyText3>
            <span
              dangerouslySetInnerHTML={{
                __html: t('network'),
              }}
            />
          </Text.BodyText3>
        </li>
      </ul>
      <Text.BodyText3>{t('last_updated')}</Text.BodyText3>
      <br></br>
      <br></br>
      <Text.BodyText3>{`© ${t('copyright', {
        year: new Date().getFullYear(),
      })}`}</Text.BodyText3>
    </div>
  )
}

export default CcpaOptOut
