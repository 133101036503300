import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'

type Props = {
  category: string
  className?: string
}

const CategoryIndicator: React.FC<Props> = ({ category, className = '' }) => {
  const t = buildTranslate('thanx_campaigns.landing.reporting.category_names')
  return (
    <div className={className}>
      <span
        className={`inline-block category-${category}-bkg p-xxs border-round mr-xxs`}
      />
      <Text.SmallCaps3 bold color="grey50">
        {t(category)}
      </Text.SmallCaps3>
    </div>
  )
}

export default CategoryIndicator
