import { AxiosAction } from 'redux-axios-middleware'

export function createSmartLink(data: {
  smart_link: { url: string }
}): AxiosAction<'smartLinks/CREATE', { smart_link: { url: string } }> {
  return {
    type: 'smartLinks/CREATE',
    payload: {
      client: 'auth',
      request: {
        url: '/smart_links',
        method: 'post',
        data: data,
      },
    },
  }
}
