import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}

const Bill: React.FC<PropsT> = ({ className, fillColor = 'currentColor' }) => (
  <svg
    className={className}
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4062 2.9375L9.0625 0.59375C8.6875 0.21875 8.1875 0 7.65625 0H2C0.875 0 0 0.90625 0 2V14C0 15.125 0.875 16 2 16H10C11.0938 16 12 15.125 12 14V4.34375C12 3.8125 11.7812 3.3125 11.4062 2.9375ZM10.5 14C10.5 14.2812 10.25 14.5 10 14.5H2C1.71875 14.5 1.5 14.2812 1.5 14V2.03125C1.5 1.75 1.71875 1.53125 2 1.53125H7V4C7 4.5625 7.4375 5 8 5H10.4688V14H10.5ZM3 4H5.5C5.75 4 6 3.78125 6 3.5C6 3.25 5.75 3 5.5 3H3C2.71875 3 2.5 3.25 2.5 3.5C2.5 3.78125 2.71875 4 3 4ZM6 5.5C6 5.25 5.75 5 5.5 5H3C2.71875 5 2.5 5.25 2.5 5.5C2.5 5.78125 2.71875 6 3 6H5.5C5.75 6 6 5.78125 6 5.5ZM6.15625 9.65625L5.96875 9.625C5.25 9.40625 5.25 9.28125 5.28125 9.15625C5.3125 8.90625 5.8125 8.84375 6.21875 8.90625C6.40625 8.9375 6.59375 9 6.78125 9.0625C7.09375 9.1875 7.46875 9 7.5625 8.6875C7.6875 8.34375 7.53125 8 7.1875 7.875C6.96875 7.8125 6.78125 7.75 6.625 7.71875V7.5C6.625 7.15625 6.34375 6.875 6 6.875C5.625 6.875 5.375 7.15625 5.375 7.5V7.6875C4.65625 7.84375 4.15625 8.28125 4.0625 8.9375C3.8125 10.2812 5.09375 10.6562 5.625 10.8125L5.8125 10.875C6.71875 11.125 6.71875 11.2188 6.6875 11.375C6.65625 11.625 6.15625 11.6875 5.75 11.625C5.53125 11.5938 5.25 11.4688 5 11.4062L4.875 11.3438C4.53125 11.2188 4.1875 11.4062 4.0625 11.7188C3.9375 12.0625 4.125 12.4062 4.4375 12.5312L4.5625 12.5625C4.8125 12.6562 5.09375 12.75 5.375 12.8125V13C5.375 13.375 5.625 13.625 6 13.625C6.34375 13.625 6.625 13.375 6.625 13V12.8125C7.3125 12.6562 7.8125 12.25 7.90625 11.5938C8.15625 10.2188 6.84375 9.875 6.15625 9.65625Z"
      fill={fillColor}
    />
  </svg>
)

export default Bill
