export default {
  header: {
    title: 'Manage dashboard access',
    invite_someone: 'Invite someone',
  },
  merchant_users_list: {
    table: {
      name: 'Name',
      role: 'Role',
      status: 'Status',
      edit: 'Edit',
      remove: 'Remove',
      active: 'Active',
      invited: 'Invited',
    },
  },
  invitation: {
    success: {
      title: "You've successfully set up your account",
      description: 'Log in to continue',
      back: 'Back to login',
    },
    expired: {
      title: 'Your invitation has expired',
      description: 'Request a new invitation from your admin and try again',
      back: 'Back to login',
    },
    complete: {
      first_name: 'First name',
      last_name: 'Last name',
      password: 'Password',
      confirm_password: 'Confirm password',
      save: 'Save and continue',
      title: 'Complete your profile',
      generic_error: 'An error happened while trying to complete your profile.',
      validations: {
        is_required: '%{field} is required',
        equals_field: 'Must match %{field}',
        min_length: 'Must be at least %{length} characters',
      },
    },
  },
  delete_modal: {
    title: 'Remove this user',
    description:
      'Revoke access for %{userName}? They will not be able to access the dashboard going forward.',
    cancel: 'Cancel',
    remove: 'Remove',
  },
  edit: {
    title: 'Edit user permissions',
    subtitle: 'Manage dashboard access',
    page_title: 'Edit user permissions | Thanx',
    cancel: 'Exit without saving',
    continue: 'Update user permissions',
    success: 'User permissions updated successfully.',
    resend_invitation: 'Re-send invitation',
    error_generic: 'An error happened while trying to update the user.',
    invitation_success: 'Invitation sent successfully.',
    invitation_error: 'An error happened while trying to send the invitation.',
  },
  new: {
    title: 'Invite someone',
    subtitle: 'Manage dashboard access',
    page_title: 'Invite someone | Thanx',
    cancel: 'Cancel',
    continue: 'Send invitation',
    success: 'Your invitation has been sent',
    error_generic: 'An error happened while trying to invite the user.',
  },
  form: {
    email: {
      header: 'Who you’re inviting',
      title: 'Email',
      help_text:
        'Invite multiple people by separating their email addresses with commas (e.g., john@pizzaco.com, mary@pizzaco.com)',
    },
    access: {
      header: 'Choose their access',
    },
    location: {
      title: 'This user can access feedback only for these locations',
      select_all: 'Select all',
      deselect_all: 'Deselect all',
    },
    roles: {
      admin: {
        description:
          'Access to every part of the merchant dashboard for all locations.',
      },
      manager: {
        description: 'Access to specific parts of the merchant dashboard.',
        helper:
          'Automated campaigns, Message to Everyone campaigns, CMS, rewards management, and loyalty configuration are only available to admins.',
        one_time_campaign: {
          title: 'One-time campaigns',
          description:
            'Create one-time campaigns and view campaign reports for those campaigns. Requires admin approval before campaigns can be sent.',
        },
        reports: {
          title: 'Reports',
          description:
            'Access to all reports. Report data is displayed for all locations.',
        },
        feedback: {
          title: 'Feedback',
          description:
            'Review and reply to customer feedback for select locations.',
        },
      },
      customer_service: {
        description: 'Access only to Feedback parts of the dashboard.',
        write:
          'Can review and reply to feedback, view customer profiles, grant rewards and loyalty progress',
        read: 'Can review but not reply to feedback (read only)',
      },
      accountant: {
        description:
          'Access only to specific accounting reports and to the Billing page.',
      },
    },
    customer_service_write: {
      title: 'Customer service',
      description: 'Access only to Feedback parts of the dashboard.',
    },
    accountant: {
      title: 'Accountant',
      description:
        'Access only to specific accounting reports and to the Billing page.',
    },
  },
  roles: {
    admin: 'Admin',
    manager: 'Location manager',
    manager_mall: 'Marketing manager',
    customer_service_read: 'Customer service',
    customer_service_write: 'Customer service',
    accountant: 'Accountant',
  },
}
