import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { getPurchaseDetails } from 'actions/orders'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { OrderDetails } from 'models/Order'
import { Fields as UserFeedback } from 'models/UserFeedback'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useState } from 'react'
import OrderDetailsModal from '../OrderDetailsModal'
import { useFeedbacksTable } from './hooks'
import Table from './Table'

const t = buildTranslate('users.view.activity.feedback')

type PropsT = {
  profile: UserProfile
}

const Feedback: React.FC<PropsT> = ({ profile }: PropsT) => {
  const {
    isFetchingFeedbacks,
    feedbacks,
    currentPage,
    numPages,
    onChangePage,
  } = useFeedbacksTable(profile)
  const dispatch = useDispatch()
  const [displayModal, setDisplayModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<OrderDetails>()

  const fetchOrder = async (feedback: UserFeedback) => {
    if (!feedback.purchase_id) return

    const result = await dispatch(getPurchaseDetails(feedback.purchase_id))
    if (!result.error) {
      return result.payload.data.purchase
    }
  }

  async function handleOrderDetailsClick(feedback: UserFeedback) {
    const order = await fetchOrder(feedback)
    if (!order) return

    setSelectedOrder(order)
    setDisplayModal(true)
  }

  return (
    <div>
      <Text.Header4 className={'mb-m'}>{t('title')}</Text.Header4>
      <Spinner
        className={'d-flex justify-content-center'}
        size={'3x'}
        isLoading={isFetchingFeedbacks}
      >
        <Switch
          condition={feedbacks.length > 0}
          fallback={
            <Text.BodyText3 color="grey70">{t('empty_state')}</Text.BodyText3>
          }
        >
          <Table
            feedbacks={feedbacks}
            onOrderDetailsClick={handleOrderDetailsClick}
          />
          <div className={'d-flex justify-content-center margin-top-huge'}>
            <Pagination
              currentPage={currentPage}
              onChangePage={onChangePage}
              numPages={numPages}
            />
          </div>
        </Switch>
      </Spinner>
      {selectedOrder && (
        <OrderDetailsModal
          show={displayModal}
          order={selectedOrder}
          onHide={() => setDisplayModal(false)}
        />
      )}
    </div>
  )
}

export default Feedback
