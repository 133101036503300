import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { makeApiClient } from 'utilities/makeApiClient'
import { APIErrorMessageT } from '../utilities/types'

const useFetch = (config: AxiosRequestConfig) => {
  const client = useMemo<AxiosInstance>(makeApiClient, [])
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState<AxiosResponse | undefined>()
  const [errors, setErrors] = useState<APIErrorMessageT | undefined>()

  useEffect(() => {
    const cancelSource = axios.CancelToken.source()
    const fetch = async () => {
      setIsLoading(true)

      try {
        const response = await client({
          cancelToken: cancelSource.token,
          ...config,
        })
        setResponse(response)
      } catch (error) {
        if (axios.isCancel(error)) {
          return
        }
        const typedError = error as AxiosError
        const status = typedError?.response?.status

        if (status && status >= 400 && status < 500) {
          setErrors(typedError?.response?.data)
        } else {
          throw error
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetch()

    return cancelSource.cancel
  }, [client, config])

  return { isLoading, response, errors }
}

export default useFetch
