import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import { buildTranslate } from 'locales'
import { Fields as UserCampaign } from 'models/UserCampaign'
import React from 'react'
import { merchantTheme } from 'theme'

const t = buildTranslate('users.view.activity.campaigns.engagement_types')

type PropsT = {
  status: UserCampaign['engagement']
}

const StatusBadge: React.FC<PropsT> = ({ status }: PropsT) => {
  const [css] = useStyletron()
  let color: string = ''
  let backgroundColor: string = ''

  switch (status) {
    case 'marked_as_spam':
    case 'bounced':
    case 'server_rejected':
      color = merchantTheme.colors.cayenne50
      backgroundColor = merchantTheme.colors.cayenne10
      break
    case 'opened':
      color = merchantTheme.colors.nephritis50
      backgroundColor = merchantTheme.colors.nephritis20
      break
    case 'clicked':
      color = merchantTheme.colors.belizeHole50
      backgroundColor = merchantTheme.colors.belizeHole10
      break
    case 'delivered':
    case 'sent':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.grey20
      break
    default:
      break
  }

  return (
    <Badge
      value={t(status)}
      className={`ml-xs ${css({
        backgroundColor,
        color,
      })}`}
    />
  )
}

export default StatusBadge
