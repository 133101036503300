import { Fields } from 'models/ItemPurchase'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectItemsPurchases = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.ItemPurchase.all().toRefArray() || []
  }
)

export const selectItemPurchases = createSelector(
  (state: any) => selectItemsPurchases(state),
  (_: any, id: number): number => id,
  (itemPurchases: Fields[]): Fields[] => {
    return itemPurchases || []
  }
)
