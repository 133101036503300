type Props = {
  className?: string
  testId?: string
  pathColor?: string | null
}

const ExclamationTriangleO = (props: Props) => {
  const { className, testId, pathColor } = props

  return (
    <svg
      className={className}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      strokeWidth={1}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testId}
    >
      <path
        d="M7.75 1.65625L1.5625 11.8125C1.5 11.9062 1.5 11.9688 1.5 12.0625C1.5 12.3125 1.6875 12.5 1.9375 12.5H14.0312C14.2812 12.5 14.5 12.3125 14.5 12.0625C14.5 11.9688 14.4688 11.9062 14.4062 11.8125L8.21875 1.65625C8.1875 1.5625 8.09375 1.5 8 1.5C7.875 1.5 7.8125 1.5625 7.75 1.65625ZM6.46875 0.875C6.78125 0.34375 7.375 0 8 0C8.59375 0 9.1875 0.34375 9.5 0.875L15.6875 11.0312C15.875 11.3438 16 11.6875 16 12.0625C16 13.125 15.125 14 14.0312 14H1.9375C0.875 14 0 13.125 0 12.0625C0 11.6875 0.09375 11.3438 0.28125 11.0312L6.46875 0.875ZM9 10.5C9 11.0625 8.53125 11.5 8 11.5C7.4375 11.5 7 11.0625 7 10.5C7 9.96875 7.4375 9.5 8 9.5C8.53125 9.5 9 9.96875 9 10.5ZM8.75 4.75V7.75C8.75 8.1875 8.40625 8.5 8 8.5C7.5625 8.5 7.25 8.1875 7.25 7.75V4.75C7.25 4.34375 7.5625 4 8 4C8.40625 4 8.75 4.34375 8.75 4.75Z"
        fill={pathColor || '#6A747F'}
      />
    </svg>
  )
}

export default ExclamationTriangleO
