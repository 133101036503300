// @flow
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import Spacer from 'components/Spacer'

type Props = {
  title: string,
  description: string,
  button: string,
  handleContinue: () => void,
}
type State = {}

export default class EmailSuccess extends Component<Props, State> {
  onClick() {
    this.props.handleContinue()
  }

  render() {
    return (
      <div className="center-block width-50">
        <Spacer paddingBottom="40px" />
        <div className="text-center margin-bottom-large">
          <h3 className="deprecated__h3"> {this.props.title}</h3>
          <p className="body-text-2">{this.props.description}</p>
        </div>
        <div className="text-center">
          <Button
            bsSize="large"
            bsStyle="primary"
            onClick={this.onClick.bind(this)}
          >
            {this.props.button}
          </Button>
        </div>
      </div>
    )
  }
}
