import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type CarouselT = {
  className?: string
  image: string
  imageAlt?: string
  index: number
  onClick: () => void
  hidden: boolean
}

const CarouselItem: React.FC<CarouselT> = ({
  className = '',
  image,
  imageAlt,
  index,
  hidden,
  onClick,
}) => {
  const [css] = useStyletron()

  return (
    <div
      className={`${css({
        width: '448px !important',
        borderRadius: '16px',
        overflowX: 'hidden',
        transition: 'transform 1s ease-in-out',
        position: 'absolute',
        transform: `translateX(${472 * index}px)`,
        opacity: hidden ? 0 : 1,
        cursor: 'pointer',
      })} mr-m ${className}`}
      onClick={onClick}
    >
      <img className="w-100" src={image} alt={imageAlt} />
    </div>
  )
}

export default CarouselItem
