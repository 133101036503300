import useFlag from 'hooks/useFlag'
import { useEffect } from 'react'
import useCurrentMerchantUser from './useCurrentMerchantUser'

const useZendesk = () => {
  const isZendeskEnabled = useFlag('zendesk-in-app-chat', false)
  const merchantUser = useCurrentMerchantUser()

  useEffect(() => {
    if (
      !isZendeskEnabled ||
      !process.env.REACT_APP_ZENDESK_WIDGET_KEY ||
      !merchantUser?.zendesk_jwt
    ) {
      return
    }

    const zenDeskScript = document.querySelector('#ze-snippet')

    if (!zenDeskScript) {
      const script = document.createElement('script')
      script.id = 'ze-snippet'
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_WIDGET_KEY}`
      script.async = true
      document.body.appendChild(script)
    }

    // window.zE is not immediately defined after creating <script> tag
    const functionCheck = setInterval(() => {
      //@ts-ignore
      if (window.zE) {
        clearInterval(functionCheck)

        //@ts-ignore
        window.zE('messenger', 'loginUser', function (callback) {
          callback(merchantUser.zendesk_jwt)
        })
      }
    }, 1000)
  }, [isZendeskEnabled, merchantUser])
}

export default useZendesk
