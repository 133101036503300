import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React from 'react'
import { useCards } from './hooks'
import Table from './Table'

const t = buildTranslate('users.view.cards')

type PropsT = {
  profile: UserProfile
}

const Cards: React.FC<PropsT> = ({ profile }) => {
  const { isFetchingCards, cards, currentPage, numPages, onChangePage } =
    useCards(profile)
  const [css] = useStyletron()

  return (
    <TabPane state="cards">
      <div className={css({ minHeight: 'calc(100vh - 290px)' })}>
        <div className="d-flex justify-content-between">
          <Text.Header3 className={'mb-m mt-0'}>{t('title')}</Text.Header3>
        </div>
        <Spinner
          className="d-flex justify-content-center"
          size="3x"
          isLoading={isFetchingCards}
        >
          <Switch
            condition={cards.length > 0}
            fallback={
              <div>
                <Text.BodyText3 color="grey70">
                  {t('empty_state')}
                </Text.BodyText3>
              </div>
            }
          >
            <Table cards={cards} />
            <div className={'d-flex justify-content-center margin-top-huge'}>
              <Pagination
                currentPage={currentPage}
                onChangePage={onChangePage}
                numPages={numPages}
              />
            </div>
          </Switch>
        </Spinner>
      </div>
    </TabPane>
  )
}

export default Cards
