import { buildTranslate } from 'locales'
import React from 'react'
import { Row } from 'react-bootstrap'
import { DisabledContent } from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Audience/components/AudienceDisabled'

const t = buildTranslate('thanx_campaigns.review.customers')

const ReputationAudience: React.FC = () => {
  return (
    <Row className="section">
      <div className="col-header col-sm-2">{t('title')}</div>
      <div className="col-sm-8">
        <DisabledContent
          text={t('disabled.reputation')}
          infoText={t('info.reputation')}
        />
      </div>
    </Row>
  )
}

export default ReputationAudience
