import { Button } from '@thanx/uikit/button'
import Modal from 'components/Modal'
import { Location } from 'history'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Prompt } from 'react-router-dom'
import { push } from 'react-router-redux'
import { useEventListener } from 'web-api-hooks'

type Props = {
  isLoading: boolean
  onConfirm: () => void
}

const t = buildTranslate('redeem_manager.builder.leaving_page_confirmation')

const LeavingPageConfirmation: React.FC<Props> = ({ isLoading, onConfirm }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [lastHref, setLastHref] = useState<string | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const dispatch = useDispatch()

  // handle react-router navigation
  function handleNavigation(nextLocation: Location): boolean {
    if (!confirmedNavigation) {
      setIsModalOpen(true)
      setLastHref(null)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  // Prompt does not work for href links
  // so this block is to handle them.
  useEventListener(window, 'click', event => {
    const element = event.target as HTMLAnchorElement

    if (element.href) {
      // check if the target will open in a new page
      const { target } = element
      if (target !== '_blank') {
        event.preventDefault()
        setLastHref(element.href)
        setIsModalOpen(true)
      }
    }
  })

  // navigate to the last location when user confirms
  useEffect(() => {
    if (confirmedNavigation) {
      if (lastLocation) {
        dispatch(push(lastLocation.pathname))
      } else if (lastHref) {
        window.location.href = lastHref
      }
    }
  }, [confirmedNavigation, lastLocation, lastHref, dispatch])

  return (
    <>
      <Prompt when message={handleNavigation} />
      <Modal
        centered
        show={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
      >
        <div className="d-flex flex-column align-items-center p-xl text-center">
          <h3 className="mb-l extra-bold">{t('title')}</h3>
          <p className="mb-xl grey-70">{t('description')}</p>
          <div className="d-flex w-100">
            <Button
              className="flex-1 mr-m"
              kind="secondary"
              isLoading={isLoading}
              onClick={() => {
                setConfirmedNavigation(true)
              }}
            >
              {t('dont_save_exit')}
            </Button>
            <Button
              className="flex-1"
              isLoading={isLoading}
              onClick={async () => {
                await onConfirm()
                setConfirmedNavigation(true)
              }}
            >
              {t('save_exit')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export default LeavingPageConfirmation
