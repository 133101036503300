import { RESET_DB } from 'actions/orm'
import {
  GetAction,
  GetPointsExperienceMetricsByType,
  GET_POINTS_EXPERIENCE_METRIC_BY_TYPE,
  GET_POINTS_EXPERIENCE_METRIC_BY_TYPE_FAIL,
  GET_POINTS_EXPERIENCE_METRIC_BY_TYPE_SUCCESS,
  ResolvedAction,
} from 'actions/pointsExperienceMetrics'
import isEmpty from 'lodash/isEmpty'
import { attr, Model, ModelType } from 'redux-orm'
import { EmptyObject } from 'utilities/types'
import { DateRangeGranularity } from '../components/DateFilter/utilities'

export enum Type {
  ACTIVITY_COUNTERS_CUSTOM = 'points_experience_metric_points_date_range_custom',
  ACTIVITY_COUNTERS_ALL_TIME = 'points_experience_metric_points_date_range_all_time',
  ACTIVITY_TIME_SERIES_CUSTOM = 'points_experience_metric_points_time_series_custom',
  ACTIVITY_TIME_SERIES_ALL_TIME = 'points_experience_metric_points_time_series_all_time',
}

export enum ShortType {
  ACTIVITY_COUNTERS = 'points_date_range',
  ACTIVITY_TIME_SERIES = 'points_time_series',
  ACTIVITY_COUNTERS_CUSTOM = 'points_date_range_custom',
  ACTIVITY_COUNTERS_ALL_TIME = 'points_date_range_all_time',
  ACTIVITY_TIME_SERIES_CUSTOM = 'points_time_series_custom',
  ACTIVITY_TIME_SERIES_ALL_TIME = 'points_time_series_all_time',
}

export type ActivityCountersData = {
  total_issued_amount: number
  total_redeemed_amount: number
  total_expired_amount: number
  total_outstanding_amount: number
}

export type ActivityTimeSeriesEntry = Record<string, number> & {
  date_time: string
}

interface BaseMetric {
  points_experience_id: number
  type: Type
  data: Record<string, any>
  isLoading?: boolean
  isErrored?: boolean
}

export interface ActivityCountersMetric extends BaseMetric {
  type: Type.ACTIVITY_COUNTERS_CUSTOM | Type.ACTIVITY_COUNTERS_ALL_TIME
  data: ActivityCountersData
}

export interface ActivityTimeSeriesMetric extends BaseMetric {
  type: Type.ACTIVITY_TIME_SERIES_CUSTOM | Type.ACTIVITY_TIME_SERIES_ALL_TIME
  granularity: DateRangeGranularity
  time_zone: string
  data: {
    name: string
    values: Array<ActivityTimeSeriesEntry>
  }
}

export type Fields = ActivityCountersMetric | ActivityTimeSeriesMetric

export type MetricTypes = Array<ShortType>

export type Metrics = {
  activity_counters?: ActivityCountersMetric
  activity_time_series?: ActivityTimeSeriesMetric
}

const getTypePXFromPath = url => {
  const paths: string[] = url.split('/') || []
  const type = `points_experience_metric_${paths[paths.length - 1]}` as Type
  const pointsExperienceId = paths[1]

  return { type, pointsExperienceId }
}

export default class PointsExperienceMetric extends Model<
  // @ts-ignore
  typeof PointsExperienceMetric,
  Fields
> {
  static modelName: string = 'PointsExperienceMetric'

  static get fields(): any {
    return {
      points_experience_id: attr(),
      type: attr(),
      data: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB } | GetAction,
    // @ts-ignore
    PointsExperienceMetric: ModelType<PointsExperienceMetric>
  ) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_POINTS_EXPERIENCE_METRIC_BY_TYPE: {
        const url: string = action.payload?.request?.url || ''
        const { type, pointsExperienceId } = getTypePXFromPath(url)
        const range = action.isAllTime ? 'all_time' : 'custom'

        PointsExperienceMetric.upsert({
          id: `${pointsExperienceId}${type}${range}`,
          type: `${type}_${range}` as Type,
          isLoading: true,
          isErrored: false,
        })
        break
      }

      case GET_POINTS_EXPERIENCE_METRIC_BY_TYPE_SUCCESS: {
        const range = action.meta?.previousAction?.isAllTime
          ? 'all_time'
          : 'custom'
        const { points_experience_id, type } = action.payload.data.metric

        PointsExperienceMetric.upsert({
          ...action.payload.data.metric,
          id: `${points_experience_id}${type}${range}`,
          type: `${type}_${range}` as Type,
          isLoading: false,
          isErrored: false,
        })
        break
      }

      case GET_POINTS_EXPERIENCE_METRIC_BY_TYPE_FAIL: {
        if (!isEmpty(action.meta.previousAction)) {
          const previousAction = action.meta.previousAction as
            | GetPointsExperienceMetricsByType
            | EmptyObject
          const url: string = previousAction.payload?.request?.url || ''
          const { type, pointsExperienceId } = getTypePXFromPath(url)
          const range = action.meta?.previousAction?.isAllTime
            ? 'all_time'
            : 'custom'

          PointsExperienceMetric.upsert({
            id: `${pointsExperienceId}${type}${range}`,
            type: `${type}_${range}` as Type,
            isLoading: false,
            isErrored: true,
          })
        }
        break
      }

      case RESET_DB:
        PointsExperienceMetric.all().delete()
        break
      default:
        break
    }
  }
}
