import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('thanx_campaigns.builder.forms.email')

type Props = {
  className?: string
  subject: string
  previewText: string
}

function EmailSubjectAndPreview(props: Props) {
  const { className, subject, previewText } = props

  return (
    <div className={className}>
      <Text.BodyText4 bold tag="div">{`${t('subject')}:`}</Text.BodyText4>
      <Text.BodyText4 color="grey70" className="mb-s" tag="div">
        {subject}
      </Text.BodyText4>

      <Text.BodyText4 bold tag="div">{`${t('preview_text')}:`}</Text.BodyText4>
      <Text.BodyText4 color="grey70" tag="div">
        {previewText}
      </Text.BodyText4>
    </div>
  )
}

export default EmailSubjectAndPreview
