import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import Accordion from 'react-bootstrap-five/Accordion'

type PropsT = {
  children: React.ReactNode
  buttonContents: React.ReactNode
  paths?: string[]
  className?: string
  disabled?: boolean
}

const AccordionButton: React.FC<PropsT> = ({
  buttonContents,
  children,
  disabled = false,
}) => {
  const [css] = useStyletron()
  return (
    <>
      <Accordion.Button
        disabled={disabled}
        className={css({
          lineHeight: '24px',
        })}
      >
        <Text.BodyText3 color={disabled ? 'grey50' : 'grey90'} bold>
          {buttonContents}
        </Text.BodyText3>
      </Accordion.Button>
      <Accordion.Body>{children}</Accordion.Body>
    </>
  )
}

export default AccordionButton
