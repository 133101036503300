import { getPointsExperiences } from 'actions/pointsExperiences'
import { getPointsProducts } from 'actions/pointsProducts'
import Spinner from 'components/Spinner'
import TabsContainer from 'components/Tabs/Container'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useMemo, useState } from 'react'
import { Container, Row } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { history } from 'store'
import Activity from './Activity'
import Configuration, { Tabs as ConfigurationTabs } from './Configuration'
import EffectiveDiscountRate from './EffectiveDiscountRate'
import ExperienceBadge from './ExperienceBadge'
import Marketplace from './Marketplace'

const t = buildTranslate('points')

enum Tabs {
  ACTIVITY = 'activity',
  MARKETPLACE = 'marketplace',
  CONFIGURATION = 'configuration',
  EFFECTIVE_DISCOUNT_RATE = 'effective_discount_rate',
}

const Landing: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState<Tabs | string>(() => {
    const param = history.location.hash.replace('#', '')
    if (Object.values(Tabs).includes(param as Tabs)) {
      return param as Tabs
    }
    const isActiveTab = tabs => Object.values(tabs).some(t => t === param)
    if (isActiveTab(ConfigurationTabs)) return Tabs.CONFIGURATION

    return Tabs.ACTIVITY
  })

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      await Promise.all([
        dispatch(getPointsExperiences()),
        dispatch(getPointsProducts()),
      ])
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  const getTitle = useMemo(() => {
    switch (activeTab) {
      case Tabs.ACTIVITY:
        return t('activity.page_title')
      case Tabs.MARKETPLACE:
        return t('marketplace.page_title')
      case Tabs.CONFIGURATION:
        return t('configuration.page_title')
      case Tabs.EFFECTIVE_DISCOUNT_RATE:
        return t('effective_discount_rate.page_title')
    }
  }, [activeTab])

  return (
    <Container fluid className="p-0 white-bkg">
      <Helmet>
        <title>{getTitle}</title>
      </Helmet>
      <Container>
        <Row className="mt-xl mb-xs">
          <Spinner isLoading={isLoading} size="1x">
            <ExperienceBadge />
          </Spinner>
        </Row>
        <Row className="mb-l">
          <h2 className="mt-0 mb-0 mr-2 inline-block">{t('landing.title')}</h2>
        </Row>
      </Container>
      <Spinner
        isLoading={isLoading}
        size="4x"
        className="text-center margin-top-huge"
      >
        <TabsContainer
          defaultTab={activeTab}
          shouldReplaceHash
          onSelect={tab => setActiveTab(tab)}
          tabs={[
            {
              id: Tabs.ACTIVITY,
              name: t('activity.title'),
              content: <Activity key={Tabs.ACTIVITY} />,
            },
            {
              id: Tabs.MARKETPLACE,
              name: t('marketplace.title'),
              content: <Marketplace key={Tabs.MARKETPLACE} />,
            },
            {
              id: Tabs.EFFECTIVE_DISCOUNT_RATE,
              name: t('effective_discount_rate.title'),
              content: (
                <EffectiveDiscountRate key={Tabs.EFFECTIVE_DISCOUNT_RATE} />
              ),
            },
            {
              id: Tabs.CONFIGURATION,
              name: t('configuration.title'),
              content: <Configuration key={Tabs.CONFIGURATION} />,
            },
          ]}
        />
      </Spinner>
    </Container>
  )
}

export default Landing
