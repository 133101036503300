import React from 'react'
import { I18n } from 'react-redux-i18n'
import circlePlus from './circle-plus.svg'

type Props = {
  onClick?: () => void
}

const NewSegmentCard: React.FC<Props> = ({ onClick }) => {
  return (
    <div
      className={
        'white-bkg border-1 border-radius-5 cursor-pointer display-flex justify-content-center align-items-center full-height padding-bottom-huge padding-top-huge'
      }
      onClick={() => {
        if (onClick) onClick()
      }}
    >
      <div className="text-center">
        <img alt="new" src={circlePlus} className="mb-m" />
        <div className="small-caps-2">
          {I18n.t('thanx_campaigns.landing.targeted.custom.new')}
        </div>
      </div>
    </div>
  )
}

export default NewSegmentCard
