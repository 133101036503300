import { getLocations } from 'actions/location'
import useDispatch from 'hooks/useDispatch'
import { Fields as Location } from 'models/Location'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMerchantLocations } from 'selectors/location'

type LocationContextT = {
  currentLocation: Location | null
  locations: Location[]
  setCurrentLocationId: (locationId: number) => void
}

export const LocationContext = React.createContext<LocationContextT>({
  currentLocation: null,
  locations: [],
  setCurrentLocationId: () => {},
})

type PropsT = {
  children: React.ReactNode
  initialLocation?: Location | null
}

export const LocationContextProvider: React.FC<PropsT> = props => {
  const { children, initialLocation = null } = props
  const locations = useSelector(selectCurrentMerchantLocations)

  const [currentLocation, setCurrentLocation] = useState<Location | null>(
    initialLocation
  )
  const [isLoaded, setIsLoaded] = useState(false)
  const dispatch = useDispatch()

  const setCurrentLocationId = id => {
    const location = locations.find(l => l.id === id)
    if (location) {
      setCurrentLocation(location)
    } else if (id === null) {
      setCurrentLocation(null)
    }
  }

  useEffect(() => {
    if (locations.length > 0 || isLoaded) {
      return
    }

    const fetchLocations = async () => {
      await dispatch(getLocations({ coming_soon: false }))
      setIsLoaded(true)
    }
    fetchLocations()
  }, [dispatch, isLoaded, locations])

  return (
    <LocationContext.Provider
      value={{
        locations,
        currentLocation,
        setCurrentLocationId,
      }}
    >
      {children}
    </LocationContext.Provider>
  )
}
