import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import MenuItem from 'components/Navbar/Bar/MenuItem'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import { merchantTheme } from 'theme'
import NavButton from '../Bar/NavButton'
import Body from './Body'
import Header from './Header'

const ProfileMenu = () => {
  const merchant = useCurrentMerchant()
  const merchantUser = useSelector(selectCurrentMerchantUser)
  const [css] = useStyletron()

  if (!merchant || !merchantUser) return null
  const merchantUserName = [
    merchantUser.first_name,
    merchantUser.last_name?.[0],
  ]
    .filter(s => s)
    .join(' ')
  return (
    <MenuItem
      buttonContents={
        <NavButton>
          <img
            src={merchant.logo_image_urls.default}
            alt={merchant.name + 'logo'}
            className={css({ height: '34px', borderRadius: '4px' })}
          />
          <div className="d-flex flex-column ml-xs">
            <Text.BodyText5 color="inherit" bold>
              {merchant.name}
            </Text.BodyText5>
            {merchantUserName && (
              <Text.BodyText5 color="inherit">
                {merchantUserName}
              </Text.BodyText5>
            )}
          </div>
        </NavButton>
      }
      paths={['/profile', '/merchant_users', '/billing']}
      className={css({
        borderLeft: '1px solid ' + merchantTheme.colors.grey70,
      })}
      rightJustifyMenu
    >
      <Header merchant={merchant} merchantUser={merchantUser} link="/profile" />
      <Body merchant={merchant} />
    </MenuItem>
  )
}

export default ProfileMenu
