import type { Fields as ActivationRateMetric } from 'models/ActivationRateMetric'
import { ShortType } from 'models/ActivationRateMetric'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectAllActivationRateMetrics = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): ActivationRateMetric[] => {
    return session.ActivationRateMetric.all().toRefArray()
  }
)

export const selectActivationMetricByType = createSelector(
  selectAllActivationRateMetrics,
  (_: any, merchantId?: number) => merchantId,
  (_: any, _merchantId?: number, type?: ShortType) => type,
  (activationMetrics, merchantId, type): ActivationRateMetric | undefined => {
    return activationMetrics.find(
      c => c.id === `${merchantId}_activation_rate_metric_${type}`
    ) as ActivationRateMetric
  }
)
