// @flow
import React, { Component } from 'react'
import isNumber from 'lodash/isNumber'
// $FlowFixMe
import { textUtils } from 'utilities/textUtils'
// $FlowFixMe
import imageDimensions from 'utilities/imageDimensions'

type Props = {
  width?: number,
  height?: number,
  maxWidth?: number,
  maxHeight?: number,
  minWidth?: number,
  minHeight?: number,
  prefix: string,
}

const DIMENSIONS = [
  'width',
  'height',
  'maxWidth',
  'maxHeight',
  'minWidth',
  'minHeight',
]

export default class ImageValidationMessage extends Component<Props> {
  static defaultProps = {
    prefix: '',
  }
  message(): string {
    const msg = DIMENSIONS.reduce((dimensions, dimension) => {
      const value = this.props[dimension]
      if (isNumber(value)) {
        dimensions.push(imageDimensions(dimension, value))
      }
      return dimensions
    }, [])
    let prefix = msg.length > 0 ? this.props.prefix : ''
    return `${prefix}${textUtils.toSentence({
      array: msg,
      separator: ' x ',
      lastSeparator: ' x ',
      punctuation: '',
    })}`
  }

  render() {
    return <span>{this.message()}</span>
  }
}
