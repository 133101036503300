import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { HiddenMenuDefaultCard } from 'components/RewardPreviewCard/HiddenMenu'
import { buildTranslate } from 'locales'
import React from 'react'

type PropsT = {
  categoryName?: string
  imageSrc?: string
}

const t = buildTranslate(
  'redeem_manager.detail.cards.online_redemption.hidden_menu'
)

const HiddenMenu: React.FC<PropsT> = ({ categoryName, imageSrc }) => {
  const [css] = useStyletron()
  return (
    <>
      <Text.BodyText4 bold className="mb-xs">
        {t('title')}
      </Text.BodyText4>
      <Text.BodyText3 className="mb-xs" color="grey70">
        {categoryName || t('name')}
      </Text.BodyText3>
      <Text.BodyText4 className="mb-m" color="grey70">
        {t('info')}
      </Text.BodyText4>
      <Switch
        condition={!!imageSrc}
        fallback={
          <>
            <Text.BodyText4 bold className="mb-xs">
              {t('header')}
            </Text.BodyText4>
            <Text.BodyText4 className="mb-xxs" color="grey70">
              {t('top_of_the_menu')}
            </Text.BodyText4>
            <Text.BodyText3 className="mb-xs mt-xs" color="grey70">
              {t('show_default_message')}
            </Text.BodyText3>
            <HiddenMenuDefaultCard />
          </>
        }
      >
        <>
          <Text.BodyText4 bold className="mb-xs">
            {t('header')}
          </Text.BodyText4>
          <Text.BodyText3 className="mb-xxs" color="grey70">
            {t('custom_image')}
          </Text.BodyText3>
          <img
            alt="online redemption"
            className={css({
              width: '432px',
            })}
            src={imageSrc}
          />
        </>
      </Switch>
    </>
  )
}

export default HiddenMenu
