import { Switch } from '@thanx/uikit/switch'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { Fields as CampaignType } from 'models/CampaignType'
import pluralize from 'pluralize'
import React from 'react'
import { Button } from 'react-bootstrap'

const t = buildTranslate('thanx_campaigns.opportunity')

type Props = {
  type: string
  campaignType?: CampaignType
  handleClick?: () => void
  isLoading?: boolean
}
const Setup: React.FC<Props> = props => {
  const { type, campaignType, handleClick, isLoading = false } = props
  const campaignState = campaignType?.state || ''

  let title = t(`${type}.footer.title`)
  let subtitle = null
  let buttonText = t(`${type}.footer.button`)

  switch (campaignState) {
    case 'upcoming':
      title = t('upcoming.footer.title')
      subtitle = t('upcoming.footer.subtitle')
      buttonText = t('upcoming.footer.button')
      break
    case 'unavailable':
      const days = campaignType?.unavailable_days || 1
      const unavailableTitle = t(`${type}.footer.unavailable.title`, {
        days: days,
        unit: pluralize(t(`${type}.footer.unavailable.unit`), days),
      })
      if (unavailableTitle) {
        title = unavailableTitle
      }

      const unavailableSubtitle = t(`${type}.footer.unavailable.subtitle`)
      if (unavailableSubtitle) {
        subtitle = unavailableSubtitle
      }
      const unavailableButton = t(`${type}.footer.unavailable.button`)
      if (unavailableButton) {
        buttonText = unavailableButton
      }
      break
    default:
      break
  }

  return (
    <div className="padding-bottom-huge padding-top-huge text-center spearmint-10-bkg">
      <h5 className="padding-medium blue-chrome-60 margin-top-huge padding-top-huge">
        {title}
      </h5>
      <Switch condition={!!subtitle}>
        <div>
          <span className="grey-60 font-size-18 width-50 inline-block">
            {subtitle}
          </span>
        </div>
      </Switch>
      <Button
        onClick={() => handleClick && handleClick()}
        bsStyle="primary"
        bsSize="large"
        className="padding-large margin-top-huge margin-bottom-huge"
      >
        <Spinner isLoading={isLoading} className="inline-block">
          {buttonText}
        </Spinner>
      </Button>
    </div>
  )
}

export default Setup
