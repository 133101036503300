import { RedeemTemplateSubtype, RedeemType } from 'models/RedeemTemplate'

export enum InstoreRedemptionType {
  MANAGER_COMP = 'manager_comp',
  CASH_BACK = 'automatic_cash_back',
  COUPON_CODES = 'coupon_codes',
}

export type CategoryKeys =
  | 'free_item'
  | 'discount'
  | 'buy_one_get_one'
  | 'buy_x_get_discount'
  | 'hidden_menu'
  | 'golden_ticket'
  | 'bonus_points'

export type RedeemTypeKeys =
  | 'get_a_free_item'
  | 'get_%_off_purchase'
  | 'get_%_off_item'
  | 'get_$_off_purchase'
  | 'get_$_off_item'
  | 'buy_x_get_%_off_purchase'
  | 'buy_item_get_%_off_item'
  | 'buy_x_get_$_off_purchase'
  | 'buy_item_get_$_off_item'
  | 'buy_one_get_free_one'
  | 'hidden_menu'
  | 'golden_ticket'
  | 'bonus_points'

export type RedeemTypeItem = {
  key: RedeemTypeKeys
  types: RedeemType[]
  subtype: RedeemTemplateSubtype | null
}

export type RedeemCategory = {
  key: CategoryKeys
  forOrdering?: boolean
  items: RedeemTypeItem[]
  isNonDiscount?: boolean
  isDisabled?: boolean
  images?: string[]
}
