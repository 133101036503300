import { Button } from '@thanx/uikit/button'
import { FormGroup } from '@thanx/uikit/form-group'
import { Notification } from '@thanx/uikit/notification'
import { Select } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import {
  createFileUpload,
  processFileUpload,
  SourcePath,
} from 'actions/fileUpload'
import { getMerchantCouponPools } from 'actions/merchantCouponPools'
import axios from 'axios'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import {
  CouponPoolGenerationType,
  CouponPoolType,
  Fields as CouponPoolFields,
} from 'models/CouponPool'
import { CouponPoolFields as CouponPoolFileUpload } from 'models/FileUpload'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React, { useState } from 'react'
import useCouponPools from 'scenes/RedeemManager/Builder/useCouponPools'
import CsvPreview from './CsvPreview'

type Props = {
  couponPool: CouponPoolFields
  file: File
  template: RedeemTemplate
  csvContent: string[][]
  onCancel: () => void
  onContinue?: (fileUpload: CouponPoolFileUpload) => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes.upload'
)

const couponCodesT = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes'
)

const UploadFile: React.FC<Props> = props => {
  const { couponPool, file, template, csvContent, onCancel, onContinue } = props

  const [error, setError] = useState<string | null>(null)
  const [isUploading, setIsUploading] = useState(false)
  const [columnIndex, setColumnIndex] = useState(0)
  const [format, setFormat] = useState({
    label: CouponPoolType.RAW,
    value: CouponPoolType.RAW,
  })

  const dispatch = useDispatch()
  const { updateCouponPool } = useCouponPools()

  const columnOptions =
    csvContent.length === 0
      ? []
      : csvContent[0].map((_, i) => ({
          label: t('column_option', { count: i + 1 }),
          value: i,
        }))

  const formatOptions = Object.values(CouponPoolType).map(format => ({
    label: couponCodesT(`format.${format}`),
    value: format,
  }))

  async function handleContinue() {
    if (!file) return

    function handleError() {
      setError(t('error'))
      setIsUploading(false)
    }

    setIsUploading(true)

    // Create the file upload
    const fileResponse = await dispatch(
      createFileUpload(SourcePath.COUPON_POOLS, couponPool.id, {
        file_type: 'csv',
        settings: {
          column_index: columnIndex,
        },
      })
    )

    if (fileResponse.type === 'fileUpload/CREATE_FAIL') {
      handleError()
      return
    }
    const fileUpload = fileResponse.payload.data
      .file_upload as CouponPoolFileUpload

    // Upload to s3
    try {
      if (!fileUpload.upload_url) {
        handleError()
        return
      }

      await axios.put(fileUpload.upload_url, file, {
        headers: { 'content-type': file.type },
      })
    } catch (error) {
      handleError()
      return
    }

    // Process the file
    const processResponse = await dispatch(
      processFileUpload(
        SourcePath.COUPON_POOLS,
        fileUpload.source_id,
        fileUpload.id
      )
    )

    if (processResponse.type === 'fileUpload/PROCESS_FAIL') {
      handleError()
      return
    }

    setIsUploading(false)

    onContinue?.(fileUpload)
  }

  async function handleFormatChange(selected) {
    if (!selected) return
    setFormat(selected)
    const { value } = selected[0]
    await dispatch(
      updateCouponPool({
        templateId: template?.id,
        couponPool: {
          ...couponPool,
          type: value,
          redemptionProvider: couponPool.redemption_provider,
        },
      })
    )
    dispatch(getMerchantCouponPools(CouponPoolGenerationType.VARIABLE))
  }

  return (
    <div className="mt-m">
      <div className="body-text-4 bold mb-xs">
        {t('table_label', { filename: file.name })}
      </div>

      <CsvPreview data={csvContent} />

      <FormGroup
        className="mb-m"
        label={t('column_label')}
        labelId="columnIndex"
      >
        <Select
          aria-labelledby="columnIndex"
          value={[columnOptions.find(o => o.value === columnIndex)]}
          options={columnOptions}
          onChange={option => setColumnIndex(option[0].value)}
        />
      </FormGroup>
      <FormGroup
        className="mb-m"
        label={t('format_label')}
        labelId="formatIndex"
      >
        <Select
          aria-labelledby="formatIndex"
          value={format}
          options={formatOptions}
          onChange={handleFormatChange}
        />
      </FormGroup>

      <Switch condition={!!error}>
        <div className="mb-m">
          <Notification kind="negative" size="full">
            <div className="d-flex">
              <i className="fa fa-lg fa-exclamation-circle mr-xs" />
              <span
                className="body-text-4"
                dangerouslySetInnerHTML={{ __html: error ?? '' }}
              />
            </div>
          </Notification>
        </div>
      </Switch>

      <div className="d-flex flex-justify-end">
        <Button className="mr-s" kind="secondary" onClick={onCancel}>
          {t('cancel_button')}
        </Button>

        <Button isLoading={isUploading} onClick={handleContinue}>
          {t('continue_button')}
        </Button>
      </div>
    </div>
  )
}

export default UploadFile
