export default {
  get_metrics_error:
    'There was an error fetching your customers. Please try again.',
  get_requirements_error:
    'There was an error fetching the changes required to schedule your campaign. Please refresh.',
  customers: {
    title: 'Audience',
    text: {
      past: 'This campaign was sent to',
      future: 'This campaign will target',
      location_address: {
        positive: 'Who have visited',
        negative: 'Who haven’t visited',
      },
      location_count: 'Who shop at',
      category_count: 'Who shop in',
      location_and_category_count: 'and in',
    },
    in_segment: 'in the segment',
    unit: 'customer',
    customers_in_database: 'customers in your database',
    customers: 'customers',
    control: 'Measure the true impact of your campaign with a control group.',
    excluded: {
      text: 'Exclude customers that received an offer in the past 14 days',
      optimization: {
        title: 'Thanx Optimization',
        body: 'Sending messages too frequently increases the chance of customers unsubscribing.',
      },
    },
    breakout_modal: {
      title: 'Customers',
      subtitle:
        'This campaign will go out to <strong>%{count}</strong> %{unit}.',
      table: {
        targeting: {
          title: 'Targeting',
          members: 'Loyalty members',
          subscribers: 'Email subscribers',
        },
        excluded: {
          title: 'Excluded',
          excluded: 'Customers that have received an offer in the past 14 days',
        },
        footer: {
          title: 'Total Reach',
        },
      },
    },
    custom: {
      breakout_modal: {
        title: 'Customers',
        subtitle: 'This campaign will target %{customer_count} customers.',
        targeted: {
          title: 'Targeted',
        },
        excluded: {
          title: 'Excluded',
          past_14_days: 'Customers that received an offer in the past 14 days',
        },
        total: 'Total',
        next: 'Okay',
      },
    },
    message: {
      breakout_modal: {
        title: 'Customers',
        subtitle: 'This campaign will target %{customer_count} customers.',
        targeted: {
          title: 'Targeted',
          loyalty: 'Loyalty members',
          subscriber: 'Email subscribers',
        },
        excluded: {
          title: 'Excluded',
          past_14_days: 'Customers that received an offer in the past 14 days',
        },
        total: 'Total',
        next: 'Okay',
      },
    },
    control_groups_have_moved: [
      'Control groups have moved. Click ',
      'Test another variant',
      ' below to use a control group. ',
    ],
    learn_more: 'Learn more',
    categoryModal: {
      title:
        'Only target customers who previously shopped at locations in these categories',
      editButton: 'Edit',
      confirmButton: 'Okay',
      unit: 'category',
    },
    locationModal: {
      title: 'Only target customers who previously shopped at these locations',
      editButton: 'Edit',
      confirmButton: 'Okay',
      unit: 'location',
    },
    disabled: {
      reputation:
        'This campaign will be sent to customers when they leave a positive review.',
      winback:
        'Customers who are currently at-risk or lost will receive the offer as soon as you turn it on. Going forward, *every* customer that Thanx flags as at-risk based on their own personal visit behavior will receive this offer.',
    },
    info: {
      reputation:
        'To avoid over communication, customers won’t receive this message more than once per year.',
    },
    add: 'Add audience',
    select:
      'Select which customers will receive this campaign (an audience is required)',
  },
  incentive: {
    title: 'Reward',
    reward: {
      title: 'Reward',
    },
    restrictions: {
      title: 'Restrictions',
    },
    fine_print: {
      title: 'Fine print',
    },
    missing: 'No reward',
    disabled: 'This campaign does not include a reward.',
    info: {
      reputation:
        'You can’t include a reward as it’s illegal to incentivize reviews.',
    },
    add: 'Add reward',
    delete: 'Delete reward',
  },
  message: {
    title: 'Message',
    get_preview_error:
      'There was an error loading your email preview. Please try again.',
    no_message: 'No message',
    missing: 'No message (a message is required)',
    add: 'Add message',
  },
  schedule: {
    title: 'Schedule',
    send_label: 'When do you want to send this campaign?',
    approver_notice: 'Approve campaign to deploy at scheduled time',
    approval_required:
      'An admin needs to review and approve this campaign before it can be sent. Click “Submit For Review” to notify them.',
    send_now: 'Send now',
    send_later: 'Send later',
    stop_label: 'When do you want to stop this campaign?',
    ongoing: 'Ongoing (no end date)',
    on_date: 'On date',
    errors: {
      past: 'You can’t schedule a campaign in the past.',
      endBeforeStart: 'You can’t stop a campaign before its start date.',
    },
  },
  header: {
    notifications: {
      noun_singular: 'notification',
      noun_plural: 'notifications',
    },
  },
  content: {
    title: 'Content',
    percent_audience: '%{percent}% of audience',
    add_variant: 'Test another variant',
    stop_variant_tip:
      'You will be able to end the test and choose a winning variant from the report page.',
    max_variants_tip: 'You can’t test more than 4 variants at one time.',
    variant_selector: {
      title: 'Test another variant',
      subtitle: 'What kind of variant is this?',
      variant_title: 'Variant with content',
      variant_description: 'Test a message and an optional reward.',
      control_variant_title: 'Control group',
      control_variant_description:
        'A variant where customers will not receive a message or reward. They act as a control group allowing you to measure the impact of your marketing.',
      buttons: {
        continue: 'Continue',
        learn_more: 'Learn more',
      },
    },
  },
  button: {
    edit: 'Edit',
    add_one: 'Add one',
    disabled: 'Not editable',
  },
}
