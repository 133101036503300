import Input from 'components/Form/Input'
import useFlag from 'hooks/useFlag'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import { selectMerchant } from 'selectors/merchant'

type Props = {
  campaign: Campaign
}

const Push: React.FC<Props> = ({ campaign }) => {
  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const merchant = useSelector(state =>
    selectMerchant(state, campaign.merchant_id)
  )
  const { config } = useCampaignConfig()

  const campaignPlaceholders = campaignType?.placeholders
  const wrapperContent = I18n.t(
    'thanx_campaigns.builder.previews.push.custom',
    {
      merchant: merchant?.nickname,
      customText: '',
    }
  )
  const maxLength = 148 - wrapperContent.length
  const msgRequired = useFlag('mx-allow-no-reward-messages', false)

  return (
    <div>
      <Input
        label={I18n.t('thanx_campaigns.builder.forms.push.text')}
        value={config.notify_push_text || ''}
        name="notify_push_text"
        placeholder={campaignPlaceholders?.notify_push_text}
        validations={{ maxLength: maxLength, isRequired: msgRequired }}
        validationErrors={{
          maxLength: I18n.t('validations.max_length', {
            maxLength: maxLength,
          }),
          isRequired: I18n.t('validations.is_required'),
        }}
        componentClass="textarea"
        helpBlock={
          <em className="font-size-12 grey-50 block margin-top-tiny">
            {I18n.t('thanx_campaigns.builder.forms.push.help_text')}
          </em>
        }
      />
    </div>
  )
}

export default Push
