import { FormsyInput } from '@thanx/uikit/input'
import { FormsyRadioGroup, Radio } from '@thanx/uikit/radio'
import { buildTranslate } from 'locales'
import { LandingLinkStyle } from 'models/LandingLink'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import UrlPicker from 'scenes/Cms/components/UrlPicker'
import { BlockData } from './index'

type Props = {
  index: number
  id: string
  data: BlockData
}
const MAX_BUTTON_LENGTH = 32
const t = buildTranslate('cms.content.fixed_buttons.block_item')

const BlockItem: React.FC<Props> = ({ id, data, index }) => {
  const { url, title, style } = data

  return (
    <div>
      <p className="mb-m small-caps-2 bold">
        {t('title', { index: index + 1 })}
      </p>
      <FormsyRadioGroup
        // @ts-ignore
        className="mb-m"
        name={`${id}.style`}
        label={t('button_style')}
        align="horizontal"
        value={style}
      >
        <Radio value={LandingLinkStyle.Solid}>{t('solid')}</Radio>
        <Radio value={LandingLinkStyle.Outline}>{t('outline')}</Radio>
      </FormsyRadioGroup>
      <FormsyInput
        // @ts-ignore
        className="mb-m"
        name={`${id}.title`}
        value={title}
        label={t('button_text')}
        validations={{
          isRequired: true,
          maxLength: MAX_BUTTON_LENGTH,
        }}
        validationErrors={{
          isRequired: I18n.t('validations.is_required'),
          maxLength: I18n.t('validations.max_length', {
            maxLength: MAX_BUTTON_LENGTH,
          }),
        }}
      />
      <UrlPicker id={id} type="button" url={url} />
    </div>
  )
}

export default BlockItem
