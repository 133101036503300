import { getFileUpload, SourcePath } from 'actions/fileUpload'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { State } from 'models/FileUpload'
import React, { useCallback, useContext, useRef, useState } from 'react'

const POLL_DELAY_SECONDS = 5

type UploadStateT = 'none' | 'processing' | 'processed' | 'errored'

type UploadStateContextT = {
  uploadState: UploadStateT
  onFileUploaded: (id: number) => void
  onFileProcessed: (fn: VoidFunction) => void
}

export const UploadStateContext =
  React.createContext<UploadStateContextT | null>(null)

export function useUploadState(): UploadStateContextT {
  const state = useContext(UploadStateContext)
  if (state === null) {
    throw 'useUploadState used outside of UploadStateProvider'
  }

  return state
}

type UploadStateProviderT = {
  children: React.ReactNode
}

export function UploadStateProvider({ children }: UploadStateProviderT) {
  const dispatch = useDispatch()
  const fetchUploadInterval = useRef<NodeJS.Timeout | null>(null)
  const [onFinalizedList, setOnFinalizedList] = useState<VoidFunction[]>([])
  const [uploadState, setUploadState] = useState<UploadStateT>('none')
  const merchant = useCurrentMerchant()

  function onFileUploaded(id: number) {
    setUploadState('processing')
    fetchUploadInterval.current = setInterval(
      () => fetchUploadState(id),
      POLL_DELAY_SECONDS * 1000
    )
  }

  function handleFileFinalized() {
    if (fetchUploadInterval.current) {
      clearInterval(fetchUploadInterval.current)
      fetchUploadInterval.current = null
    }

    setUploadState('processed')
    onFinalizedList.forEach(callback => callback())
  }

  const onFileProcessed = useCallback((fn: VoidFunction) => {
    setOnFinalizedList(list => [...list, fn])
  }, [])

  async function fetchUploadState(fileUploadId) {
    if (!merchant || !fileUploadId) return

    const response = await dispatch(
      getFileUpload(SourcePath.MERCHANTS, merchant.id, fileUploadId)
    )

    if (response.type === 'fileUpload/GET_FAIL') {
      setUploadState('errored')
      return
    }

    const responseFileUpload = response.payload.data.file_upload

    if (responseFileUpload.state === State.ERRORED) {
      setUploadState('errored')
      return
    }

    if (responseFileUpload.state === State.FINALIZED) {
      handleFileFinalized()
    }
  }

  return (
    <UploadStateContext.Provider
      value={{
        onFileUploaded,
        onFileProcessed,
        uploadState,
      }}
    >
      {children}
    </UploadStateContext.Provider>
  )
}
