import Switch from '@thanx/uikit/switch/Switch'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as TargetSegment } from 'models/TargetSegment'
import React from 'react'
import Row from './Row'

const t = buildTranslate('segments.landing.table')

type PropsT = {
  segments: TargetSegment[]
  isLoading: boolean
  searchIsActive: boolean
  onExistingClick: (segment: TargetSegment) => void
}

const Table: React.FC<PropsT> = ({
  segments,
  isLoading,
  searchIsActive,
  onExistingClick,
}) => {
  return (
    <table className="full-width mt-l">
      <thead>
        <tr>
          <HeaderCell className="w-50">{t('name')}</HeaderCell>
          <Switch condition={searchIsActive}>
            <HeaderCell className="w-25">Category</HeaderCell>
          </Switch>
          <HeaderCell key="last" />
        </tr>
      </thead>
      <tbody>
        {segments.map(segment => (
          <Row
            key={segment.id}
            segment={segment}
            isLoading={isLoading}
            searchIsActive={searchIsActive}
            onExistingClick={onExistingClick}
          />
        ))}
      </tbody>
    </table>
  )
}

export default Table
