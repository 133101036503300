import { Text } from '@thanx/uikit/text'
import { getLabel } from 'components/LocationSelector/helpers'
import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import React, { useContext } from 'react'

type PropT = {
  className?: string
  title: string
}

const t = buildTranslate('capture_rate')

const Header: React.FC<PropT> = ({ className, title }) => {
  const { currentLocation } = useContext(LocationContext)
  const location = currentLocation
    ? getLabel(currentLocation)
    : t('all_locations')
  return (
    <div className={className}>
      <Text.Header3 className="mt-0 mb-xs">{title}</Text.Header3>
      <Text.SmallCaps3 tag="div" className="mb-xxs" color="grey70">
        {t('location')}
      </Text.SmallCaps3>
      <Text.BodyText5 tag="div" color="grey70">
        {location}
      </Text.BodyText5>
    </div>
  )
}

export default Header
