import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import moment from 'moment-timezone'
import React from 'react'
import { getFormattedDate } from 'utilities/date'
import { useUserTimeZone } from 'utilities/userTimeZone'
import Counters from '../Counters'
import PiiBanner from './PiiBanner'
import Section from './Section'

const t = buildTranslate('users.view.profile.overview')

type PropsT = {
  profile: UserProfile
}

const Overview: React.FC<PropsT> = ({ profile }: PropsT) => {
  const userTimeZone = useUserTimeZone()
  const birth_date = (() => {
    if (!!profile.birth_day && !!profile.birth_month) {
      return getFormattedDate(
        moment.tz(
          `2000-${profile.birth_month}-${profile.birth_day}`,
          'YYYY-MM-DD',
          userTimeZone
        ),
        'anniversary'
      )
    }

    // keep legacy formatting as a fallback precaution against backend rollbacks
    if (!!profile.birth_date) {
      return getFormattedDate(
        moment.tz(profile.birth_date, userTimeZone),
        'daily'
      )
    }

    return '-'
  })()

  return (
    <div>
      <Text.Header4 className="mb-m">{t('title')}</Text.Header4>
      <Counters profile={profile} />
      {!profile.show_pii && <PiiBanner />}

      <Section title={t('contact')} icon={'envelope-o'} className={'mt-l mb-m'}>
        <Text.BodyText4 className="mb-xs" tag={'p'} bold>
          {t('email')}
        </Text.BodyText4>
        <Text.BodyText3 color="grey70">{profile.email ?? '-'}</Text.BodyText3>
        <Text.BodyText4 className="mb-xs mt-m" tag={'p'} bold>
          {t('phone')}
        </Text.BodyText4>
        <Text.BodyText3 color="grey70">{profile.phone ?? '-'}</Text.BodyText3>
      </Section>

      <Section title={t('profile')} icon={'user-o'} className={'mb-m'}>
        <Text.BodyText4 className="mb-xs" tag={'p'} bold>
          {t('birthday')}
        </Text.BodyText4>
        <Text.BodyText3 color="grey70">{birth_date}</Text.BodyText3>
      </Section>
    </div>
  )
}

export default Overview
