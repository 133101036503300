import { api } from './index'

export interface GrantableCampaign {
  id: number
  merchant_id: number
  type: string
  name: string
  state: string
}

export const campaignsApi = api.injectEndpoints({
  endpoints: builder => ({
    getGrantableCampaigns: builder.query<
      { campaigns: GrantableCampaign[] },
      void
    >({
      query: () => 'campaigns/grantable',
      providesTags: ['Campaign'],
    }),
  }),
})
