import React from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import Builder from './Builder'

const Create: React.FC = () => {
  const dispatch = useDispatch()

  return (
    <Builder
      onSuccessExit={() => {
        dispatch(push('/rewards'))
      }}
    />
  )
}

export default Create
