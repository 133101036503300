import { alert } from 'actions/flash'
import { getErrorMessage, isError } from 'api/utils'
import { useDispatch } from 'react-redux'

// Verify that the response is an error, and if so, dispatch an alert to display the message.
export const useDisplayError = () => {
  const dispatch = useDispatch()

  return (response: any, genericMessage: string) => {
    if (isError(response)) {
      dispatch(
        alert({
          key: 'danger',
          message: getErrorMessage(response.error, genericMessage),
        })
      )
    }
  }
}
