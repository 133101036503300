import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { requestPassword, REQUEST_PASSWORD_FAIL } from 'actions/auth'
import Input from 'components/Form/Input'
import Form from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import BasicMessage from './BasicMessage'

const t = buildTranslate('auth')

const RequestPassword: React.FC = () => {
  const dispatch = useDispatch()
  const [message, setMessage] = useState<{
    title: string
    message: string
  } | null>(null)
  const [action, setAction] = useState<{ text: string; path: string } | null>(
    null
  )

  async function submit(data) {
    const response = await dispatch(requestPassword(data.email))
    if (response.type === REQUEST_PASSWORD_FAIL) {
      if (response.error.response?.status === 403) {
        setMessage({
          title: t('request_password.pending_title'),
          message: t('request_password.pending_message'),
        })
      } else {
        setMessage({ title: t('request_password.not_found'), message: '' })
      }
      setAction(null)
    } else {
      setMessage({
        title: t('request_password.success_title'),
        message: t('request_password.success_message'),
      })
      setAction({ text: t('go_back'), path: '/auth/login' })
    }
  }

  if (message) {
    const messageProps = {
      ...(action === null
        ? { buttonAction: () => setMessage(null) }
        : {
            buttonText: action.text,
            buttonPath: action.path,
          }),
      ...message,
    }
    return <BasicMessage {...messageProps} />
  }

  return (
    <>
      <Helmet>
        <title>{t('request_password.page_title')}</title>
      </Helmet>
      <Text.Header3 color="grey90" className="mb-xs mt-0">
        {t('request_password.title')}
      </Text.Header3>
      <Text.BodyText3 color="grey70" className="mb-m" tag="p">
        {t('request_password.message')}
      </Text.BodyText3>
      <Form onValidSubmit={submit} className="text-left">
        <Input
          name="email"
          label={t('email')}
          validations="isRequired,isEmail"
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
            isEmail: I18n.t('validations.is_email'),
          }}
          spellCheck={false}
          inputProps={{ className: 'grey-70-text grey-70-hover grey-70-focus' }}
        />
        <Button type="submit" kind={Kind.PRIMARY} className="btn-block mt-m">
          {t('request_password.submit')}
        </Button>
      </Form>
      <Text.BodyText4 className="mt-s" tag="p">
        <Link to="/auth/login">{t('go_back')}</Link>
      </Text.BodyText4>
    </>
  )
}

export default RequestPassword
