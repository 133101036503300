import { useStyletron } from '@thanx/uikit/theme'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

const t = buildTranslate('looker')

const AppDownloadReport: React.FC = () => {
  const merchant = useCurrentMerchant()
  const [css] = useStyletron()

  if (!merchant) return null

  const title = t('urls.dashboards/120.title')
  const height = 1450

  return (
    <ProtectedPageHeader
      title={title}
      description=""
      resource="LocationReports"
      action="access"
    >
      <Helmet>
        <title>{I18n.t('titles./reports/app_downloads')}</title>
      </Helmet>
      <div
        className={`grey-05-bkg border-top-1 pb-l grey-20-border ${css({
          minHeight: `calc(100% - 50px)`,
        })}`}
      >
        <Container
          className={`px-0 ${css({
            minHeight: `${height + 300}px`,
          })}`}
        >
          <LookerIframe
            path={'dashboards/120'}
            merchant={merchant}
            height={`${height}px`}
          />
          <LookerFooter className="my-xl" />
        </Container>
      </div>
    </ProtectedPageHeader>
  )
}

export default AppDownloadReport
