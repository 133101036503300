import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { OrderDetails } from 'models/Order'
import { Fields as UserEvent } from 'models/UserEvent'
import { Fields as UserProfile } from 'models/UserProfile'
import React, { useState } from 'react'
import Counters from '../Counters'
import { useUserEventsTable } from './hooks'
import OrderDetailsModal from './OrderDetailsModal'
import Table from './Table'

type PropsT = {
  profile: UserProfile
}

const t = buildTranslate('users.view.activity.overview')

const Overview: React.FC<PropsT> = ({ profile }) => {
  const [displayModal, setDisplayModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<OrderDetails>()

  const {
    numPages,
    currentPage,
    onChangePage,
    isFetching,
    userEvents,
    fetchOrder,
  } = useUserEventsTable(profile)

  async function handleOrderDetailsClick(event: UserEvent) {
    const order = await fetchOrder(event)
    setSelectedOrder(order)
    setDisplayModal(true)
  }

  if (isFetching) {
    return <Spinner className="text-center" isLoading size="2x" />
  }

  return (
    <div>
      <Text.Header4 className="mb-m">{t('title')}</Text.Header4>
      <Counters profile={profile} />
      <Switch
        condition={userEvents.length > 0}
        fallback={
          <Text.BodyText3 color="grey70">{t('empty_state')}</Text.BodyText3>
        }
      >
        <Table
          events={userEvents}
          isLoading={isFetching}
          onOrderDetailsClick={handleOrderDetailsClick}
        />
        <div className={'d-flex justify-content-center margin-top-huge'}>
          <Pagination
            currentPage={currentPage}
            onChangePage={onChangePage}
            numPages={numPages}
          />
        </div>
      </Switch>
      {selectedOrder && (
        <OrderDetailsModal
          show={displayModal}
          order={selectedOrder}
          onHide={() => setDisplayModal(false)}
        />
      )}
    </div>
  )
}

export default Overview
