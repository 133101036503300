import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import MessagingForm from 'scenes/CampaignCenter/Builder/Steps/Shared/Messaging'

type Props = {
  campaign: Campaign
  canProceed: boolean
  showValidationErrors?: () => void
}

const Messaging: React.FC<Props> = props => {
  const { campaign, canProceed, showValidationErrors } = props
  return (
    <MessagingForm
      campaign={campaign}
      canProceed={canProceed}
      showValidationErrors={showValidationErrors}
    />
  )
}

export default Messaging
