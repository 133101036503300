import React, { useState } from 'react'

type DateInputs = {
  startDateValid: boolean
  endDateValid: boolean
}

type DateInputsContextT = {
  inputs: DateInputs
  setInputs: (inputs: Partial<DateInputs>) => void
}

const initialState = {
  startDateValid: true,
  endDateValid: true,
}

export const DateInputsContext = React.createContext<DateInputsContextT>({
  inputs: initialState,
  setInputs: () => {},
})

type Props = {
  children: React.ReactNode
}

export const DateInputsProvider: React.FC<Props> = props => {
  const { children } = props
  const [inputs, setInputs] = useState(initialState)

  const updateInputs = (updatedInputs: Partial<DateInputs>) => {
    setInputs(inputs => ({
      ...inputs,
      ...updatedInputs,
    }))
  }

  return (
    <DateInputsContext.Provider value={{ inputs, setInputs: updateInputs }}>
      {children}
    </DateInputsContext.Provider>
  )
}
