import { Button } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import TopBar from '../TopBar'

import Formsy from 'formsy-react'
import { FormModel } from '../FormModel'
import { Step } from '../index'

const t = buildTranslate('redeem_manager.builder.steps.name')

type Props = {
  name: string
  templateId: number | null
  error: string | null
  isLoading: boolean
  skipTypeStep: boolean
  onFormChange: (newFormModel: FormModel) => void
  onContinue: () => void
  onStepSelected: (step: Step) => void
  onSaveAndExit: () => void
}

const MIN_NAME_LENGTH = 5

const NameStep: React.FC<Props> = ({
  name,
  templateId,
  error,
  skipTypeStep,
  isLoading,
  onFormChange,
  onContinue,
  onStepSelected,
  onSaveAndExit,
}) => {
  const [isValidForm, setIsValidForm] = useState(false)

  return (
    <>
      <TopBar
        step="name"
        templateId={templateId}
        hideTypeStep={skipTypeStep}
        onStepSelected={onStepSelected}
      >
        <Button
          className="mr-m"
          kind="secondary_outline"
          disabled={!isValidForm}
          isLoading={isLoading}
          onClick={onSaveAndExit}
        >
          {t('save_exit')}
        </Button>
        <Button
          kind="secondary_outline"
          disabled={!isValidForm}
          isLoading={isLoading}
          onClick={onContinue}
        >
          {t('continue')}
        </Button>
      </TopBar>
      <div className="container">
        <Text.Header2 className="mt-xl mb-xs">{t('title')}</Text.Header2>
        <Text.BodyText3 tag="p" className="mb-xl">
          {t('description')}
        </Text.BodyText3>
        <Formsy
          className="mb-s"
          onValid={() => setIsValidForm(true)}
          onInvalid={() => setIsValidForm(false)}
          onChange={onFormChange}
        >
          <FormsyInput
            aria-label="Name"
            // @ts-ignore
            name="name"
            value={name}
            errored={!!error}
            validations={{
              isRequired: true,
              minLength: MIN_NAME_LENGTH,
            }}
            validationErrors={{
              isRequired: I18n.t('validations.is_required'),
              minLength: I18n.t('validations.min_length', {
                minLength: MIN_NAME_LENGTH,
              }),
            }}
          />
        </Formsy>
        {!!error && <p className="body-text-4 cayenne-50">{error}</p>}
        <p className="body-text-4 grey-70">{t('visible_to_you')}</p>
      </div>
    </>
  )
}

export default NameStep
