import {
  createCampaign as createCampaignAction,
  getCampaigns,
  GET_CAMPAIGNS_SUCCESS,
} from 'actions/campaigns'
import { getCampaignType } from 'actions/campaignTypes'
import { alert } from 'actions/flash'
import Loading from 'components/Loading'
import useDispatch from 'hooks/useDispatch'
import sortBy from 'lodash/sortBy'
import { Fields as Campaign } from 'models/Campaign'
import React, { useEffect } from 'react'
import { I18n } from 'react-redux-i18n'
import { RouteComponentProps } from 'react-router-dom'
import { replace } from 'react-router-redux'
import { useAbility } from 'utilities/ability'

type Props = RouteComponentProps<{ type: string; category: string }>

const Creator: React.FC<Props> = ({ match }) => {
  const { type, category } = match.params
  const ability = useAbility()
  const dispatch = useDispatch()

  useEffect(() => {
    if (category === 'automated') {
      if (ability.cannot('manage', 'AutomatedCampaign')) {
        _redirectTo('/thanx_campaigns')
        return
      }
      ensureNoDuplicates()
    } else {
      if (
        category === 'message' &&
        ability.cannot('manage', 'MessageCampaign')
      ) {
        _redirectTo('/thanx_campaigns')
        return
      }
      createCampaign()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function _redirectTo(path: string) {
    dispatch(replace(path))
  }

  async function ensureNoDuplicates() {
    await dispatch(getCampaignType(type))
    const response = await dispatch(getCampaigns({ type }))
    let campaigns: Campaign[] = []
    if (response.type === GET_CAMPAIGNS_SUCCESS) {
      campaigns = response.payload?.data?.campaigns || []
    }

    const activeCampaigns = campaigns.filter(
      campaign => campaign.state === 'active'
    )

    if (activeCampaigns.length > 0) {
      const id = sortBy(activeCampaigns, campaign => -campaign.id)[0].id
      _redirectTo(`/thanx_campaigns/${category}/${type}/${id}/report`)
      return
    }

    const draftCampaigns = campaigns.filter(
      campaign => campaign.state === 'draft'
    )

    if (draftCampaigns.length > 0) {
      const id = sortBy(draftCampaigns, campaign => -campaign.id)[0].id
      _redirectTo(`/thanx_campaigns/${category}/${type}/${id}`)
      return
    }

    createCampaign()
  }

  async function createCampaign() {
    // we don't know if either of the message types or if upsell is going to
    // include a reward, so we can't prebuild the unlayer email config
    const response = await dispatch(
      createCampaignAction({
        campaign: {
          type: type,
        },
      })
    )

    if (response.error) {
      const error = response.error?.response?.data?.error || null
      dispatch(
        alert({
          key: 'danger',
          message: I18n.t('thanx_campaigns.creator.creation_error'),
          error,
        })
      )
      _redirectTo(`/thanx_campaigns/${category}`)
      return
    }

    const id = response.payload.data.campaign.id
    _redirectTo(`/thanx_campaigns/${category}/${type}/${id}`)
  }

  return <Loading />
}

export default Creator
