import orm from 'models/orm'
import { Fields } from 'models/SmsTrigger'
import { createSelector as createOrmSelector } from 'redux-orm'

export const selectSmsTriggers = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.SmsTrigger.all().toRefArray()
  }
)
