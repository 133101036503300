import { buildTranslate } from 'locales'
import type { UserMetricData } from 'models/MerchantMetric'
import React from 'react'
import ColumnChartMetric from 'scenes/Reports/ActivityReport/components/ColumnChartMetric'
import { numberFormatter } from 'utilities/formatters'

const t = buildTranslate(
  'reports.activity.sections.transactions.metrics.purchases'
)

type Props = {
  data: UserMetricData
}

export const PurchasesPerUser: React.FC<Props> = ({ data }) => {
  return (
    <ColumnChartMetric
      metricData={data}
      title={t('title')}
      summaryText={t('summary_text')}
      xAxisTitle={t('x_axis_title')}
      yAxisTitle={t('y_axis_title')}
      totalKey="total_users"
      xAxisFormatter={numberFormatter}
      yAxisFormatter={numberFormatter}
      backgroundClass="grey-background"
      consolidateMinPercentage={0.05}
      consolidateMinColumns={7}
    />
  )
}

export default PurchasesPerUser
