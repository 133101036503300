import { Text } from '@thanx/uikit/text'
import React from 'react'

type Props = {
  mainDescriptor: string
  linkDescriptor: string
  className?: string
}

const CustomImageDescriptors: React.FC<Props> = ({
  mainDescriptor,
  linkDescriptor,
  className = '',
}) => {
  return (
    <div className={className}>
      <Text.BodyText4 tag="div" color="grey70">
        {mainDescriptor}
      </Text.BodyText4>
      <Text.BodyText4 tag="div" color="grey70">
        <span
          dangerouslySetInnerHTML={{
            __html: linkDescriptor,
          }}
        />
      </Text.BodyText4>
    </div>
  )
}

export default CustomImageDescriptors
