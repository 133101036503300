import { Button } from '@thanx/uikit/button'
import { alert } from 'actions/flash'
import { createSmartLink } from 'actions/smartLinks'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import CmsPage from 'scenes/Cms/components/CmsPage'
import { selectCurrentMerchant } from 'selectors/merchant'
import GeneratedLink from './GeneratedLink'
import { generateUrl } from './helpers'
import LinkGenerationForm, { FormModel } from './LinkGenerationForm'

const t = buildTranslate('cms.settings.smart_links')

const SmartLinks = () => {
  const merchant = useSelector(selectCurrentMerchant)

  const dispatch = useDispatch()

  const [generatedLink, setGeneratedLink] = React.useState<string | null>(null)

  async function handleSubmit(model: FormModel) {
    if (!merchant) {
      return
    }

    setGeneratedLink(null)

    const baseUrl = generateUrl(model, {
      merchantHandle: merchant.handle,
    })

    const response = await dispatch(
      createSmartLink({ smart_link: { url: baseUrl } })
    )

    if (response.error) {
      const error =
        response.error.response.data?.error ?? t('errors.generate_error')
      dispatch(
        alert({
          key: 'danger',
          message: error,
        })
      )
      return
    }

    const generatedLink = response.payload.data.smart_link.url
    setGeneratedLink(generatedLink)
  }

  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      isLoading={false}
    >
      <Button
        kind="secondary"
        className="mr-s"
        // @ts-ignore UIkit button does not currently have a 'role' type definition
        role="link"
        onClick={() =>
          window.open(
            'https://help.thanx.com/hc/en-us/articles/27605309798423-Smart-Links'
          )
        }
      >
        {t('learn_more_text')}
      </Button>
      <div className="mb-l">
        <LinkGenerationForm onSubmit={handleSubmit} />
      </div>
      <GeneratedLink generatedLink={generatedLink} />
    </CmsPage>
  )
}

export default SmartLinks
