import { buildTranslate } from 'locales'
import urlUtils from 'utilities/urlUtils'

const t = buildTranslate('titles')

export function isLegalPage() {
  const legalPages = [
    '/privacy/:handle',
    '/privacy',
    '/privacy_v1',
    '/ccpanotice',
    '/ccpaoptout',
    '/accessibility',
    '/terms',
    '/terms/thanx',
    '/terms/susiecakes',
    '/terms/:handle',
    '/settlement_credits',
    '/exclusive-deals-terms',
  ]
  return legalPages.some(path => urlUtils.matchPath(path, true))
}

export function createTabName() {
  const privacyBrandedUrl: any = urlUtils.matchPath('/privacy/:handle', true)
  const privacyDefaultUrl = urlUtils.matchPath('/privacy', true)
  const termsBrandedUrl: any = urlUtils.matchPath('/terms/:handle', true)
  const termsDefaultUrl = urlUtils.matchPath('/terms', true)

  if (privacyBrandedUrl) {
    return t('privacy_branded', {
      name: capitalizeHandle(privacyBrandedUrl.params.handle),
    })
  } else if (privacyDefaultUrl) {
    return t('privacy_default')
  } else if (termsBrandedUrl) {
    return t('terms_branded', {
      name: capitalizeHandle(termsBrandedUrl.params.handle),
    })
  } else if (termsDefaultUrl) {
    return t('terms_default')
  } else {
    return t('default')
  }
}

function capitalizeHandle(handle) {
  return handle.charAt(0).toUpperCase() + handle.slice(1)
}
