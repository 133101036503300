import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import DefaultRewardImage from './RewardIcon'

type Props = {
  height?: string
  width?: string
  rewardIcon?: string
}

const RewardImage: React.FC<Props> = ({ height, width, rewardIcon }) => {
  const merchant = useCurrentMerchant()
  const image = rewardIcon || merchant?.reward_icon_urls?.small

  const [css] = useStyletron()

  return (
    <Switch
      condition={!!image}
      fallback={
        <DefaultRewardImage
          color={merchant?.primary_color || undefined}
          height={height}
          width={width}
        />
      }
    >
      <img
        src={image}
        alt={I18n.t('redeem_manager.preview_card.reward')}
        className={css({ height: height || '36px', width: width || '36px' })}
      />
    </Switch>
  )
}

export default RewardImage
