import { useStyletron } from '@thanx/uikit/theme'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as CampaignType } from 'models/CampaignType'
import { Fields as UserCampaign } from 'models/UserCampaign'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.view.activity.campaigns')

type PropsT = {
  campaigns: Array<UserCampaign>
  campaignTypes: Array<CampaignType>
}

const Table: React.FC<PropsT> = ({ campaigns, campaignTypes }: PropsT) => {
  const [css] = useStyletron()

  return (
    <table
      className={`full-width ${css({
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
      })}`}
    >
      <thead>
        <tr>
          <HeaderCell className={`${css({ width: '45%' })}`}>
            {t('email')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '20%' })}`}>
            {t('delivered')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '20%' })}`}>
            {t('engagement')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '15%' })}`} key={'last'} />
        </tr>
      </thead>
      <tbody>
        {campaigns.map(campaign => (
          <Row
            key={`${campaign.message_type}_${campaign.id}`}
            userCampaign={campaign}
            campaignTypes={campaignTypes}
          />
        ))}
      </tbody>
    </table>
  )
}

export default Table
