import { Button } from '@thanx/uikit/button'
import { FormsyInput } from '@thanx/uikit/input'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { FormsyTextArea } from '@thanx/uikit/textarea'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { BlockData } from '../hooks'

type Props = {
  id: string
  formModel: BlockData
}

const t = buildTranslate(
  'cms.signup.onboarding_slideshow.content.block_item.accessibility'
)

function AccessibilityProps({ id, formModel }: Props) {
  const [visible, setVisible] = useState(false)

  return (
    <div className={'mt-m'}>
      <Button
        className={'pl-0'}
        kind={'minimal'}
        size={'small'}
        onClick={() => setVisible(visible => !visible)}
      >
        <Text.BodyText4 color={'belizeHole50'}>
          {t('title')}
          <i
            className={`fa ${
              visible ? 'fa-caret-up' : 'fa-caret-down'
            } btn-link font-size-14 padding-left-tiny belize-hole-50`}
          />
        </Text.BodyText4>
      </Button>
      <Switch condition={visible}>
        <Text.BodyText4 className={'mt-m'} color={'grey70'} tag={'div'}>
          {t('description')}
        </Text.BodyText4>

        <FormsyTextArea
          className="mt-m"
          name={`${id}.content_description`}
          placeholder={t(`defaults.${formModel.type}.content`)}
          value={formModel.content_description ?? ''}
          label={t('content')}
          rows={4}
        />

        <FormsyInput
          className="mt-m"
          name={`${id}.button_description`}
          value={formModel.button_description ?? ''}
          placeholder={t(`defaults.${formModel.type}.button`)}
          label={t('button')}
        />
      </Switch>
    </div>
  )
}

export default AccessibilityProps
