import { api } from './index'
import { Location } from './locations'

type PurchaseItem = {
  id: number
  label: string
  price: number
}

export type Purchase = {
  id: number
  purchased_at: string
  location: Location
  prices: {
    subtotal: number
    discount: number
    tax: number
    total: number
  }
  items: Array<PurchaseItem>
}

export const purchasesApi = api.injectEndpoints({
  endpoints: builder => ({
    getPurchase: builder.query<{ purchase: Purchase }, number>({
      query: purchaseId => `purchases/${purchaseId}`,
      providesTags: (_result, _error, purchaseId) => [
        { type: 'Purchase', id: purchaseId },
      ],
    }),
  }),
})
