import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import ActivationRateReport from './ActivationRateReport'
import DetailReport from './ActivationRateReport/ActivationFunnel/DetailReport'
import { stagesWithDetailedReport } from './ActivationRateReport/ActivationFunnel/helpers'
import ActivityReport from './ActivityReport'
import AppDownloadReport from './AppDownloadReport'
import AverageCheckReport from './AverageCheckReport'
import CaptureRateReport from './CaptureRateReport'
import CustomerReport from './CustomerReport'
import DailyActivityReport from './DailyActivityReport'
import EngagementReport from './EngagementReport'
import FrequencyReport from './FrequencyReport'
import MembershipOverviewReport from './MembershipOverviewReport'
import NpsReport from './NpsReport'
import OrderingConversionReport from './OrderingConversionReport'
import PurchasesReport from './PurchasesReport'
import RetentionRateReport from './RetentionRateReport'
import RevenueReport from './RevenueReport'
import RewardActivityReport from './RewardActivityReport'
import TiersReport from './TiersReport'

const ReportsRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/reports/activity" component={ActivityReport} />
      <Route path="/reports/customer" component={CustomerReport} />
      <Route
        path={`/reports/lifecycle/:stage(${stagesWithDetailedReport.join(
          '|'
        )})`}
        component={DetailReport}
      />
      <Route
        path="/reports/lifecycle"
        component={() => (
          <Redirect to={'/reports/activation#activation_funnel'} />
        )}
      />
      <Route path={'/reports/revenue'} component={RevenueReport} />
      <Route path="/reports/capture_rate" component={CaptureRateReport} />
      <Route path="/reports/activation" component={ActivationRateReport} />
      <Route path="/reports/engagement" component={EngagementReport} />
      <Route path="/reports/tiers" component={TiersReport} />
      <Route path="/reports/retention_rate" component={RetentionRateReport} />
      <Route path="/reports/average_check" component={AverageCheckReport} />
      <Route path="/reports/frequency" component={FrequencyReport} />
      <Route path="/reports/feedback" component={NpsReport} />
      <Route
        path="/reports/membership_overview"
        component={MembershipOverviewReport}
      />
      <Route
        path="/reports/ordering_conversion"
        component={OrderingConversionReport}
      />
      <Route path="/reports/app_downloads" component={AppDownloadReport} />
      <Route path={'/reports/purchases'} component={PurchasesReport} />
      <Route path="/reports/daily_activity" component={DailyActivityReport} />
      <Route path="/reports/reward_activity" component={RewardActivityReport} />
    </Switch>
  )
}

export default ReportsRouter
