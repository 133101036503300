import React, { useEffect } from 'react'
import { withRouter } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'

type Props = {
  children: React.ReactElement
} & RouteComponentProps

const ScrollToTop: React.FC<Props> = ({ location, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return children
}

export default withRouter(ScrollToTop)
