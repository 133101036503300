import { MultiSelect } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Error from 'components/Icons/Error'
import Spinner from 'components/Spinner'
import palette from 'constants/palette'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { renderToString } from 'react-dom/server'
import { stages } from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import {
  defaultStages,
  generateBenchmarkDataSeries,
  generateConvertedDataSeries,
  generateSelectOptions,
  generateUnconvertedDataSeries,
  toolTipWidth,
  transformData,
} from './helpers'
import Tooltip from './Tooltip'

const t = buildTranslate('lifecycle_report.overview.chart')

Highcharts.setOptions({
  lang: {
    numericSymbols: [],
    decimalPoint: '.',
    thousandsSep: ',',
  },
})

Highcharts.SVGRenderer.prototype.symbols.line = function (x, y, width, height) {
  return ['M', x, y + width / 2 + 2, 'L', x + height, y + width / 2 + 2]
}

type Props = {
  isLoading: boolean
  isErrored: boolean
  data: any
  merchantType: string | undefined
}

const ColumnChart: React.FC<Props> = ({
  data,
  isLoading,
  isErrored,
  merchantType,
}) => {
  const [css] = useStyletron()
  const [selectedMetrics, setSelectedMetrics] = useState(defaultStages)

  const { unconvertedData, convertedData, benchmarkData } = transformData(
    data,
    selectedMetrics,
    merchantType
  )

  const series = [
    // The grey bars for counting who didn't convert
    generateUnconvertedDataSeries(unconvertedData),

    // The blue bars for counting who did convert
    generateConvertedDataSeries(convertedData, css),

    // The purple lines to show industry benchmarks
    generateBenchmarkDataSeries(benchmarkData),
  ]

  const options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Lato',
        overflow: 'visible !important',
      },
    },
    title: undefined,
    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        if (!this.points) {
          return false
        }
        return renderToString(
          <Tooltip
            stage={this.x.toString() as typeof stages[number]}
            merchantType={merchantType}
            showConversionRate={this.x.toString() !== selectedMetrics[0]}
            didntConvert={this.points[0].y}
            converted={this.points[1].y}
          />
        )
      },
      shared: true,
      backgroundColor: palette.white,
      borderWidth: 1,
      borderColor: palette.grey20,
      borderRadius: 4,
      outside: true,
      useHTML: true,
      shadow: false,
      padding: 0,
      style: {
        width: toolTipWidth,
        whiteSpace: 'normal',
      },
    },
    credits: { enabled: false },
    yAxis: {
      allowDecimals: false,
      min: 0,
      max: !!data ? null : 1000,
      title: {
        text: t('labels.y_axis'),
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      gridLineDashStyle: 'LongDash',
      gridLineColor: palette.grey40,
      tickPixelInterval: 50,
      labels: {
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
    },
    xAxis: {
      categories: selectedMetrics,
      labels: {
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject
        ) {
          return t(`labels.x_axis.${this.value}`)
        },
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        groupPadding: 0.3,
        states: {
          hover: {
            brightness: 0.04,
          },
        },
      },
      scatter: {
        marker: {
          symbol: 'line',
          lineWidth: 2,
          radius: 12,
          lineColor: palette.wisteria,
        },
      },
      series: {
        dataLabels: {
          enabled: true,
        },
        stickyTracking: false,
      },
    },
    legend: {
      align: 'left',
      symbolRadius: 0,
      x: 30,
      itemStyle: {
        color: palette.grey90,
        fontSize: '14px',
      },
    },
    series: series,
  }

  return (
    <div
      className={`w-100 h-100 pl-0 pr-s py-l white-bkg border-1 grey-20-border position-relative ${css(
        {
          borderRadius: '4px',
        }
      )}`}
    >
      <div className="d-flex flex-align-center flex-space-between mb-l">
        <div className="ml-l">
          <Text.Header4 bold className="mt-0 mb-0">
            {t('title')}
          </Text.Header4>
        </div>
        <div className="d-flex align-items-center mr-s">
          <Spinner isLoading={isLoading} />
          {isErrored && (
            <div style={{ lineHeight: '20px' }}>
              <Error />
              <span className="cayenne-50 ml-xs align-middle">
                <Text.BodyText4 color="inherit">{t('error')}</Text.BodyText4>
              </span>
            </div>
          )}
          <span
            className={`ml-s
              ${css({ width: '250px' })}
            `}
          >
            <MultiSelect
              placeholder={t('select_placeholder')}
              options={generateSelectOptions(stages)}
              value={generateSelectOptions(selectedMetrics)}
              onChange={newOptions => {
                setSelectedMetrics(
                  // Here we are filtering all metrics instead of just using
                  // the new options, as we want to maintain the original order
                  stages.filter(
                    stage => newOptions.map(o => o.value).indexOf(stage) >= 0
                  )
                )
              }}
              searchable={true}
            />
          </span>
        </div>
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
      {merchantType && (
        <div
          className={`position-relative float-right mr-m ${css({
            marginTop: '-35px',
          })}`}
          color="grey70"
        >
          <Text.BodyText5>{t('merchant_type_chart_label')[0]}</Text.BodyText5>
          <Text.BodyText5 bold>{merchantType}</Text.BodyText5>
          <Text.BodyText5>{t('merchant_type_chart_label')[1]}</Text.BodyText5>
        </div>
      )}
    </div>
  )
}

export default ColumnChart
