import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import PopoverLearnMore from 'components/PopoverLearnMore'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { currencyFormatter } from 'utilities/formatters'
import { aboutRewardDiscountsAndCosts } from 'utilities/urlUtils'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type ManualTypes = {
  campaignId: number
}

const ManualItem: React.FC<ManualTypes> = props => {
  const { campaignId } = props

  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'redeem')
    ) as RedeemMetric) || {}
  const primaryValue = currencyFormatter(metric?.data?.total_cost, false)
  const secondaryValue = t('revenue.per_reward', {
    value: currencyFormatter(metric?.data?.average_cost),
  })

  const { attributionWindow } = useAttributionWindow()

  const primaryTip = useFlag('DATA-633', false)
    ? [
        {
          content: t('revenue.tooltips.number_of_cogs_v2'),
        },
        {
          content: <PopoverLearnMore url={aboutRewardDiscountsAndCosts} />,
        },
        {
          title: t('example'),
          content: t('revenue.tooltips.number_cogs_example_v2_item_1'),
        },
        {
          content: t('revenue.tooltips.number_cogs_example_v2_item_2'),
        },
        {
          content: t('revenue.tooltips.number_cogs_example_v2_item_3'),
        },
        {
          content: t('revenue.tooltips.number_cogs_example_v2_item_4'),
        },
      ]
    : [
        {
          content: t('revenue.tooltips.number_of_cogs', {
            count: attributionWindow,
          }),
        },
        {
          title: t('how_its_calc'),
          content: t('revenue.tooltips.calc_of_number_cogs'),
        },
        {
          title: t('example'),
          content: t('revenue.tooltips.number_cogs_example_content'),
        },
      ]

  return (
    <InfoRow
      title={t('revenue.cogs')}
      primaryContent={
        <Metric metric={primaryValue}>
          <PopoverContent sections={primaryTip} />
        </Metric>
      }
      secondaryContent={
        <Metric primary={false} metric={secondaryValue}>
          <PopoverContent
            sections={[
              {
                content: t('revenue.tooltips.per_of_cogs', {
                  count: attributionWindow,
                }),
              },
              {
                title: t('how_its_calc'),
                content: t('revenue.tooltips.calc_of_per_cogs'),
              },
              {
                title: t('example'),
                content: t('revenue.tooltips.per_cogs_example_content'),
              },
            ]}
          />
        </Metric>
      }
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default ManualItem
