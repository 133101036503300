import { Text } from '@thanx/uikit/text'
import { Order } from 'api/orders'
import { buildTranslate } from 'locales'
import React from 'react'
import { Button } from 'react-bootstrap'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { getDateWithTimeAndZone } from 'utilities/date'
import { textUtils } from 'utilities/textUtils'
import { useUserTimeZone } from 'utilities/userTimeZone'
import StatusBadge from './StatusBadge'

const t = buildTranslate('users.view.activity.orders')

type PropsT = {
  order: Order
  onDetailsClick?: (order: Order) => void
}

const Row: React.FC<PropsT> = ({ order, onDetailsClick }: PropsT) => {
  const timeZone = useUserTimeZone()

  function handleDetailsClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    onDetailsClick?.(order)
  }

  return (
    <tr>
      <BodyCellContainer isErrored={false} isLoading={false}>
        <Text.BodyText4 color="grey70">
          {order.provider_order_uid}
        </Text.BodyText4>
      </BodyCellContainer>

      <BodyCellContainer isErrored={false} isLoading={false}>
        <Text.BodyText4 color="grey70">
          {textUtils.titleize(order.handoff)}
        </Text.BodyText4>
      </BodyCellContainer>

      <BodyCellContainer isErrored={false} isLoading={false}>
        <Text.BodyText4 color="grey70">
          {<StatusBadge status={order.status} />}
        </Text.BodyText4>
      </BodyCellContainer>

      <BodyCellContainer isErrored={false} isLoading={false}>
        <Text.BodyText4 color="grey70">
          ${order.price_breakdown.total}
        </Text.BodyText4>
      </BodyCellContainer>

      <BodyCellContainer
        isErrored={false}
        isLoading={false}
        className="text-left"
      >
        <Text.BodyText4 color="grey70">
          {getDateWithTimeAndZone(order.placed_at, timeZone)}
        </Text.BodyText4>
      </BodyCellContainer>

      <BodyCellContainer isErrored={false} isLoading={false}>
        <Text.BodyText4 color="grey70">
          <Button onClick={handleDetailsClick} bsStyle="link">
            {t('details')}
          </Button>
        </Text.BodyText4>
      </BodyCellContainer>
    </tr>
  )
}

export default Row
