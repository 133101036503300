import { Button, Kind } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import closeIcon from 'assets/images/nav/img_X.svg'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useCurrentMerchantUser from 'hooks/useCurrentMerchantUser'
import React, { useMemo, useState } from 'react'
import Accordion from 'react-bootstrap-five/Accordion'
import Offcanvas from 'react-bootstrap-five/Offcanvas'
import { history } from 'store'
import Divider from '../Bar/Divider'
import Campaigns from '../Campaigns'
import { paths } from '../constants'
import Customers from '../Customers'
import Experiences from '../Experiences'
import Feedback from '../Feedback'
import Loyalty from '../Loyalty'
import Body from '../ProfileMenu/Body'
import Header from '../ProfileMenu/Header'
import Rewards from '../Rewards'
import Hamburger from './Hamburger'

const Left: React.FC = () => {
  const merchant = useCurrentMerchant()
  const merchantUser = useCurrentMerchantUser()
  const [css] = useStyletron()
  const [isOpen, setIsOpen] = useState(false)
  const close = () => setIsOpen(false)
  const currentLocation = history.location.pathname

  const defaultKey = useMemo(() => {
    let currentKey = ''
    Object.keys(paths).forEach(pathKey => {
      const pathInfo = paths[pathKey]
      if (pathInfo.paths.includes(currentLocation)) {
        currentKey = pathInfo.id
      }
    })
    return currentKey
  }, [currentLocation])
  if (!merchant || !merchantUser) return null

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        className="px-s"
        kind={Kind.MINIMAL}
      >
        <Hamburger />
      </Button>
      <Offcanvas
        className={css({
          overflowY: 'auto',
        })}
        show={isOpen}
        onHide={close}
      >
        <Button
          className={`position-absolute ${css({
            top: 0,
            right: 0,
            padding: '14px !important',
          })}`}
          onClick={close}
          kind={Kind.MINIMAL}
        >
          <img src={closeIcon} alt="close" />
        </Button>
        <Header
          link="/profile"
          onClick={close}
          merchant={merchant}
          merchantUser={merchantUser}
        />
        <Accordion defaultActiveKey={defaultKey}>
          <Accordion.Item eventKey={paths.campaigns.id}>
            <Campaigns
              paths={paths.campaigns.paths}
              isLeftNav
              onClick={close}
            />
          </Accordion.Item>
          <Accordion.Item eventKey={paths.loyalty.id}>
            <Loyalty paths={paths.loyalty.paths} isLeftNav onClick={close} />
          </Accordion.Item>
          <Accordion.Item eventKey={paths.rewards.id}>
            <Rewards paths={paths.rewards.paths} isLeftNav onClick={close} />
          </Accordion.Item>
          <Accordion.Item eventKey={paths.customers.id}>
            <Customers
              paths={paths.customers.paths}
              isLeftNav
              onClick={close}
            />
          </Accordion.Item>
          <Accordion.Item eventKey={paths.feedback.id}>
            <Feedback paths={paths.feedback.paths} isLeftNav onClick={close} />
          </Accordion.Item>
          <Accordion.Item eventKey={paths.experiences.id}>
            <Experiences
              paths={paths.experiences.paths}
              isLeftNav
              onClick={close}
            />
          </Accordion.Item>
        </Accordion>
        <Divider />
        <Body isLeftNav merchant={merchant} onClick={close} />
      </Offcanvas>
    </>
  )
}

export default Left
