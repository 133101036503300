import Section from 'components/Section'
import { buildTranslate } from 'locales'
import { Fields as Link } from 'models/Link'
import React from 'react'
import Header from 'scenes/Cms/components/Header'
import LinkRow from './LinkRow'

const t = buildTranslate('cms.content.custom_links.premium_links')

type Props = {
  links: Link[]
}

const PremiumLinks: React.FC<Props> = ({ links = [] }) => {
  if (links.length === 0) return null

  return (
    <Section className="mt-m">
      <Header
        title={t('title')}
        description={t('description')}
        className="p-m"
      />
      {links.map(link => (
        <LinkRow key={link.id} link={link} />
      ))}
    </Section>
  )
}

export default PremiumLinks
