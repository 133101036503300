import { Fields } from 'models/Product'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_PRODUCTS = 'product/GET_PRODUCTS'
export const GET_PRODUCTS_SUCCESS = 'product/GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'product/GET_PRODUCTS_FAIL'

export function getProducts(): AxiosAction<
  typeof GET_PRODUCTS,
  { products: Fields[] }
> {
  return {
    type: GET_PRODUCTS,
    payload: {
      client: 'default',
      request: {
        url: 'products',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<ReturnType<typeof getProducts>>
