import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import * as BannerImages from 'assets/images/banners/MxUpdateBanner'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme, merchantThemeT } from 'theme'
import { mx4UpdatesUrl } from 'utilities/urlUtils'

type PropsT = {
  type: 'update' | 'replace' | 'delete' | 'beta'
  linkUrl?: string
  className?: string
  subtitle?: string
}
type TypeT = {
  color: keyof merchantThemeT['colors']
  image: string
}
type ConfigT = {
  update: TypeT
  replace: TypeT
  delete: TypeT
  beta: TypeT
}

const t = buildTranslate('components.update_banners')

const MxUpdateBanner: React.FC<PropsT> = props => {
  const { type, className = '', linkUrl = mx4UpdatesUrl, subtitle } = props
  const config: ConfigT = {
    update: {
      color: 'belizeHole50',
      image: BannerImages.update.default,
    },
    beta: {
      color: 'belizeHole50',
      image: BannerImages.beta.default,
    },
    replace: {
      color: 'wisteria',
      image: BannerImages.replace.default,
    },
    delete: {
      color: 'grey90',
      image: BannerImages.delete.default,
    },
  }
  const [css] = useStyletron()

  return (
    <div
      className={`p-m d-flex ${className} ${css({
        backgroundColor: merchantTheme.colors[config[type].color],
      })}`}
    >
      <div className="d-flex align-items-center">
        <img src={config[type].image} alt={type} />
        <div className="mx-m">
          <Text.Header3 bold color="white" className="mt-0 mb-xxs">
            {t(`${type}.title`)}
          </Text.Header3>
          <Text.BodyText3 color="white">
            {subtitle || t(`${type}.subtitle`)}
          </Text.BodyText3>
        </div>
      </div>
      <div className="justify-content-end d-flex flex-grow-2">
        <Button
          $as="a"
          kind={Kind.SECONDARY_REVERSE}
          href={linkUrl}
          target="_blank"
          className={css({
            transition: 'all 0.2s ease !important',
            ':hover': {
              backgroundColor: 'transparent !important',
              color: 'rgba(255,255,255,0.5)',
              textDecoration: 'none',
              borderColor: 'rgba(255,255,255,0.5)',
            },
            ':focus': {
              color: 'white',
              textDecoration: 'none',
            },
          })}
        >
          {t('button')}
        </Button>
      </div>
    </div>
  )
}

export default MxUpdateBanner
