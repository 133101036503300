import Incentive from 'scenes/CampaignCenter/Builder/Steps/Shared/Incentive'
import Success from 'scenes/CampaignCenter/Builder/Steps/Shared/Success'
import Messaging from 'scenes/CampaignCenter/Builder/Steps/Shared/WrappedMessaging'
import Review from 'scenes/CampaignCenter/Builder/Steps/Upsell/Review'
import Setup from 'scenes/CampaignCenter/Builder/Steps/Upsell/Setup'

export default {
  steps: {
    required: {
      setup: Setup,
      incentive: Incentive,
      messaging: Messaging,
      review: Review,
      success: Success,
    },
  },
  options: {
    initialStep: 'setup',
    disableNavFor: {
      setup: true,
      success: true,
    },
  },
}
