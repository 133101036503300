import { Switch } from '@thanx/uikit/switch'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import InfoBox from 'scenes/CampaignCenter/Report/components/InfoBox'

type Props = {
  hasDivider: boolean
}

const CustomerValueInfo: React.FC<Props> = ({ hasDivider }) => {
  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <InfoBox
          title={I18n.t('thanx_campaigns.report.info.customer_value.title')}
          subtitle={I18n.t(
            'thanx_campaigns.report.info.customer_value.subtitle'
          )}
        />
      </div>
    </div>
  )
}

export default CustomerValueInfo
