import { Text } from '@thanx/uikit/text'
import { DateRangeFilter } from 'components/DateFilter/utilities'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { DateTimeValue } from 'models/CampaignMetric'
import moment from 'moment-timezone'
import { automatedCampaignHelpUrl } from 'utilities/urlUtils'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

export const notAvailable = () => ({
  primaryContent: (
    <Text.BodyText4 color="grey70" className="text-right">
      {t('revenue.not_available')}
    </Text.BodyText4>
  ),
  secondaryContent: (
    <a
      className="font-size-12"
      href={automatedCampaignHelpUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('learn_more')}
    </a>
  ),
})

export const getCampaignReportingBounds = (
  campaign: Campaign,
  timezone: string,
  reportType: 'summary' | 'test',
  attributionDays: number
) => {
  const { type, start_at, end_at, test_ended_at } = campaign

  // days after period end to attribute to report
  const startDate = moment.tz(start_at, timezone)
  const now = moment.tz(timezone)

  const endDate = (() => {
    if (type !== 'custom_automated') {
      const refDate = startDate.clone().subtract(1, 'day')
      const diff = refDate.diff(startDate, 'days') + attributionDays
      return startDate.clone().add(diff, 'days')
    }

    if (reportType === 'test' && test_ended_at) {
      return moment.tz(test_ended_at, timezone).add(attributionDays, 'days')
    }

    if (!end_at) {
      return now
    }

    const endDate = moment.tz(end_at, timezone)

    if (endDate.isAfter(now)) {
      return now
    }

    return endDate.add(attributionDays, 'days')
  })()

  return {
    startDate,
    endDate,
  }
}

export const getXAxisCategories = (
  values: DateTimeValue[] | undefined,
  filter: DateRangeFilter,
  timezone
) => {
  const { start, type } = filter
  if (!values) {
    return []
  }

  const startDate = start ? moment.tz(start, timezone) : null
  return values
    .filter(value => {
      if (!startDate) return true
      if (type === 'all_time') return true

      return moment.tz(value.date_time, timezone).isSameOrAfter(startDate)
    })
    .map(value => value.date_time)
}

export const hasDiscount = (redeemType: string | undefined): boolean => {
  return ![
    'experience/hidden_menu',
    'experience/golden_ticket',
    'points/static',
  ].some(type => type === redeemType)
}
