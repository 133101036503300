import orm from 'models/orm'
import type {
  Fields as RetentionRateMetric,
  ShortType,
} from 'models/RetentionRateMetric'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectAllRetentionRateMetrics = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): RetentionRateMetric[] => {
    return session.RetentionRateMetric.all().toRefArray()
  }
)

export const selectRetentionRateMetricByType = createSelector(
  selectAllRetentionRateMetrics,
  (_: any, merchantId?: number) => merchantId,
  (_: any, _merchantId?: number, type?: ShortType) => type,
  (retentionRateMetrics, merchantId, type): RetentionRateMetric | null => {
    return (
      (retentionRateMetrics.find(
        c => c.id === `${merchantId}_retention_rate_metric_${type}`
      ) as RetentionRateMetric) ?? null
    )
  }
)
