import { MultiSelect } from '@thanx/uikit/select'
import { Option } from 'baseui/select'
import { withFormsy } from 'formsy-react'
import { buildTranslate } from 'locales'
import { Fields as MerchantCouponPool } from 'models/MerchantCouponPool'
import React from 'react'
import { mapCouponPoolToSelect } from 'scenes/RedeemManager/helpers'

type PropsT = {
  name: string
  options: MerchantCouponPool[]
  value: MerchantCouponPool[]
  setValue: (MerchantCouponPool) => void
  isVariable: boolean
  onChange?: (items: MerchantCouponPool[]) => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.coupon_codes'
)

const CouponPoolSelect: React.FC<PropsT> = ({
  options,
  value,
  setValue,
  isVariable,
  onChange,
}) => {
  const handleChange = (selected: Option[]) => {
    if (onChange) {
      onChange(selected as MerchantCouponPool[])
    }
    setValue(selected)
  }

  return (
    <>
      <label htmlFor="coupon-pools-select" className="body-text-4 bold mt-m">
        {t('associated_coupon_pools')}
      </label>
      <MultiSelect
        id="coupon-pools-select"
        placeholder={t('select_placeholder')}
        options={options.map(op => mapCouponPoolToSelect(op))}
        value={value}
        multi={isVariable}
        onChange={handleChange}
      />
    </>
  )
}

export default withFormsy(CouponPoolSelect)
