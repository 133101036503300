import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import { RedeemType } from 'models/RedeemTemplate'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { notAvailable } from 'scenes/CampaignCenter/Report/v3/helpers'
import InfoRow, {
  Variants,
} from 'scenes/CampaignCenter/Report/v3/Test/components/InfoRow'
import { selectCampaignVariantMetricByType } from 'selectors/campaignMetric'
import manualItem from './manualItem'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
  redeemTypes: (RedeemType | undefined)[]
  variantIds: number[]
  variantTypes: (string | undefined)[]
}

const Cogs: React.FC<PropsT> = props => {
  const { campaignId, redeemTypes, variantIds, variantTypes } = props
  const metrics =
    (useSelector(state =>
      selectCampaignVariantMetricByType(state, campaignId, 'redeem', variantIds)
    ) as RedeemMetric[]) || {}

  const hasData633Feature = useFlag('DATA-633', false)
  const { attributionWindow } = useAttributionWindow()

  const variants = variantIds.map((_, index) => {
    const redeemType = redeemTypes[index]
    if (redeemType === 'manual/item') {
      return manualItem({
        hasData633Feature,
        attributionWindow,
        metric: metrics[index],
        variantType: variantTypes[index],
      })
    }
    return notAvailable()
  })
  return <InfoRow title={t('revenue.cogs')} variants={variants as Variants[]} />
}

export default Cogs
