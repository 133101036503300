const campaigns = {
  names: {
    short_name: 'campaign',
  },
  types: {
    thanxgiving: {
      title: 'Thanxgiving',
      form_title: 'thanxgiving',
      description:
        'What better way to acquire new customers than to ask happy customers to recommend %{merchantName} to their friends and family? By giving your best customers an incentive to drive referrals, you can acquire high quality customers that on average will spend more and stay longer.',
      learn_more: '',
      form: {
        header: {
          title: 'Create a Thanxgiving Campaign',
          subtitle: 'Leverage your most loyal customers to acquire new ones.',
          about: {
            title: 'About Thanxgiving',
            text: 'What better way to acquire new customers than to ask happy customers to recommend %{merchantName} to their friends and family? By giving your best customers an incentive to drive referrals, you can acquire high quality customers that on average will spend more and stay longer. There are 2 key participants involved: the inviter (existing customer) and invitee (new customer).',
          },
          inviter: {
            title: 'The Inviter',
            description:
              'Thanxgiving encourages your most loyal customers to share your brand by getting reward progress for each individual they invite on to the platform. They won’t receive this until the person they invited has made their first purchase ensuring that you only reward successful referrals.',
          },
          invitee: {
            title: 'The Invitee',
            description:
              'New customers are notified letting them know that they have a reward waiting for them. They “unlock” this reward after their first visit as an incentive for them to come back and make another purchase.',
          },
        },
        inviter: {
          title: 'What does the inviter get for inviting a friend?',
          text: '50% towards your loyalty program <strong>after the invited friend makes a purchase</strong>.',
        },
        invitee: {
          title: 'What does the invitee get for signing up?',
        },
        quick_tip: {
          title: 'Quick tip',
          text: 'Customers can invite as many people as they want, but they’ll only get their additional loyalty progress when the other person signs up and makes their first purchase.',
        },
        confirmation: {
          button_text: 'Turn on',
          title:
            'Are you ready to turn on Thanxgiving? When you turn on this campaign, all of your customers will immediately be able to see a new option in the app to refer friends and family.',
        },
      },
    },
    birthday: {
      title: 'Birthday',
      incentive_title: 'Choose your birthday gift',
      description:
        'Say “Happy Birthday” to your customers by giving them something special on their birthday and at the same time give customers an incentive to come back to your store. Birthday campaigns are known for being more about doing something nice for your customers to build your relationship so they stick around longer.',
      learn_more: '',
      expiration_notice:
        'Customers will have 31 days to redeem their birthday reward.',
      form: {
        header: {
          title: 'Create a Birthday Campaign',
          subtitle:
            'Say “Happy Birthday” to your customers by giving them something special.',
          about: {
            title: 'About this campaign',
            text: 'Say “Happy Birthday” to your customers by giving them something special on their birthday and at the same time give customers an incentive to come back to your store. Birthday campaigns are known for being more about doing something nice for your customers to build your relationship so they stick around longer.',
          },
          details: {
            column1: {
              title: 'Who gets it',
              description:
                'All customers who’ve given you their birthday<br/><span class="text-muted">%{stat} customers</span>',
            },
            column2: {
              title: 'When it is triggered',
              description: 'On your customer’s birthday',
            },
            column3: {
              title: 'Why you should do it',
              description: `
                <ul>
                  <li>
                    Increase lifetime value
                  </li>
                  <li>
                    Drive incremental revenue
                  </li>
                </ul>
              `,
            },
          },
        },
        push_message_text:
          'Happy Birthday from %{merchant_name}! Enjoy %{reward_description}.',
        confirmation: {
          button_text: 'Turn on',
          title:
            'Are you ready to turn on Birthday? When you turn on this campaign, customers will start to receive a reward when it’s their birthday.',
        },
      },
    },
    winback: {
      title: 'Winback',
      incentive_title:
        'Choose what to give your customers to bring them back in',
      description:
        'Thanx identifies anomalies in spending behavior using sophisticated artificial intelligence tools and automatically reaches out to customers that have churned with a targeted offer to bring them back, driving measurable revenue from otherwise lost customers.',
      learn_more: '',
      expiration_notice:
        'Customers will have 30 days to redeem their winback reward.',
      form: {
        header: {
          title: 'Create a Winback Campaign',
          subtitle: 'Identify customers at risk of churning and win them back.',
          about: {
            title: 'About this campaign',
            text: `
              <p>
                Everyone knows that it’s far cheaper to retain an existing customer than acquire a new one. Winback is specifically designed to identify customers that are in that “at risk” stage of their lifecycle and give them an incentive to come back in again. Not only does this get customers back in and drive revenue, but it’s shown to successfully convert them back in to frequently paying customers once again.
              </p>
              <p>
                Unlike traditional winback campaigns that blast everyone after 90 days of inactivity, we understand that it’s normal for some customers to come in once per week, and others once every few months. Because of that, we analyze each individual’s behavior to determine when they are considered at risk, to ensure you’re reaching out at the optimal time.
              </p>
              <p>
                Did you know that <strong>%{stat1}% of your customers</strong> representing <strong>$%{stat2} in revenue</strong> are unengaged or at risk of lapsing? Turn on winback today.
              <p>
            `,
          },
          details: {
            column1: {
              title: 'Who gets it',
              description: `
                <p style="margin-bottom: 2px;">
                  At risk customers <span class="text-muted">(%{at_risk_stat} customers)</span>
                </p>
                <p>
                  Lost customers <span class="text-muted">(%{lost_stat} customers)</span>
                </p>
              `,
            },
            column2: {
              title: 'When it is triggered',
              description:
                'When a customer’s behavior significantly deviates from what’s normal for them.',
            },
            column3: {
              title: 'Why you should do it',
              description: `
                <ul>
                  <li>
                    Increase customer retention
                  </li>
                  <li>
                    Drive incremental revenue
                  </li>
                </ul>
              `,
            },
          },
        },
        confirmation: {
          button_text: 'Turn on',
          title:
            'Are you ready to turn on Winback? When you turn on this campaign, we’ll reach out to your at risk and lost customers with a reward to win them back.',
        },
      },
    },
    vip: {
      title: 'VIP',
      form_title: 'vip',
      description:
        'Show your best customers how much you appreciate them by sending them a special offer. We automatically identify your top customers by looking not only who spends the most, but who does this on an ongoing basis.',
      learn_more: '',
      form: {
        header: {
          title: 'Create a VIP Campaign',
          subtitle: 'Retain your most valuable customers.',
          about: {
            title: 'About VIP',
            text: `
              <p>
                Did you know that in the last 2 months, <strong>%{stat1}% of your revenue</strong> came from just
                <strong>%{stat2}% of your customers</strong>? Your best customers are
                usually a small segment that drives a significant portion of your
                revenue. We call these your VIPs.
              </p>
              <p>
                Just one of your VIPs spent (on average) <strong>$%{stat3} in the last two months</strong>,
                while your average customer only spent <strong>$%{stat4} in the same period</strong>.
                It makes sense that you want to do everything you can to hold on to these
                customers. Our VIP campaign allows you to identify your most valuable
                customers and make sure they keep coming back time and and time again.
              </p>
            `,
          },
          details: {
            column1: {
              title: 'Who gets it',
              description:
                'Top %{topPercent}% of your customers<br/><span class="text-muted">%{stat} customers</span>',
            },
            column2: {
              title: 'When it is triggered',
              description:
                'When a customer spends more than $%{threshold}<br/> 2 months in a row',
            },
            column3: {
              title: 'Why you should do it',
              description: `
                <ul>
                  <li>
                    Drive incremental revenue
                  </li>
                  <li>
                    Increase frequency
                  </li>
                  <li>
                    Increase lifetime value
                  </li>
                </ul>
              `,
            },
          },
        },
        incentive_title: 'Choose your VIP reward',
        confirmation: {
          button_text: 'Turn on',
          title:
            'Are you ready to turn on VIP? When you turn on this campaign, your best customers will start earning exclusive VIP rewards.',
        },
      },
    },
  },
  index: {
    title: 'Your Campaigns',
    stats: {
      heading: 'The numbers below reflect your performance over all time',
    },
    list: {
      title: 'Campaign Performance',
    },
    create: {
      title: 'Create a new Campaign',
      contact_tip: {
        title: 'Need help?',
        description:
          'Need help setting up your campaign? Our merchant success team is always here to help with any questions you might have.',
      },
    },
  },
}
export default campaigns
