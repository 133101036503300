import { Button, Kind, Size } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import cart from 'assets/images/points/cart.svg'
import noIcon from 'assets/images/points/img_noIcon.svg'
import Help from 'components/Help'
import Info from 'components/Info'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import React from 'react'
import { push } from 'react-router-redux'
import { helpArticles } from './helpers'

type Props = {
  canEdit: boolean
  pointsExperience: PointsExperience
}

const t = buildTranslate('points.configuration')

const Collect: React.FC<Props> = ({ canEdit, pointsExperience }) => {
  const {
    id,
    conversion_rate,
    currency_primary_icon,
    currency_name,
    sync_state,
  } = pointsExperience

  const [css] = useStyletron()
  const dispatch = useDispatch()
  const icon = currency_primary_icon.default || noIcon

  const points_conversion = (
    <div className="d-flex align-items-center">
      <img
        height="16px"
        className="padding-right-small"
        src={icon}
        alt={`${currency_name}-icon`}
      />
      <span>{Number(conversion_rate)}</span>
    </div>
  )

  return (
    <section className="grey-90">
      <Text.Header4 className="mb-xl" tag="div">
        {t('collect.title')}
      </Text.Header4>
      <div className="card mb-m p-m white-bkg">
        <div className="d-flex justify-content-between">
          <Text.Header4 tag="div" className="mb-m d-flex align-items-center">
            <img className="mr-xs" src={cart} alt="cart" />
            {t('collect.amount_spent')}
          </Text.Header4>
          {canEdit && (
            <Button
              kind={Kind.SECONDARY}
              size={Size.SMALL}
              disabled={sync_state === 'running'}
              className={css({ height: '32px' })}
              onClick={() =>
                dispatch(push(`/points/experiences/${id}/collect/edit`))
              }
            >
              {t('collect.edit')}
            </Button>
          )}
        </div>
        <Info
          label={t('collect.when_a_customer')}
          value={t('collect.dollar')}
          className="mb-m"
        />
        <Info label={t('collect.they_receive')} value={points_conversion} />
      </div>
      <Help title={t('help.title')} articles={helpArticles.collect} />
    </section>
  )
}

export default Collect
