import {
  GET_ALERT_BANNER_SUCCESS,
  GET_ORDERING_BANNER_SUCCESS,
  ResolvedAction,
  UPDATE_ALERT_BANNER_SUCCESS,
  UPDATE_ORDERING_BANNER_SUCCESS,
} from 'actions/banners'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'
import { ImageUrlsT } from 'utilities/types'

export interface AlertBanner {
  title: string | null
  body_text: string | null
  background_color: string
  text_color: string
  dismissable: boolean
  enabled: boolean
  button_text: string | null
  button_url: string | null
}

export interface OrderingBanner {
  text: string
  image_urls: ImageUrlsT
}

interface OrderingBannerFields extends OrderingBanner {
  id: 'ordering_banner'
}

interface AlertBannerFields extends AlertBanner {
  id: 'alert_banner'
}

export type Fields = OrderingBannerFields | AlertBannerFields

// @ts-ignore
export default class Banner extends Model<typeof Banner, Fields> {
  static modelName: string = 'Banner'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Banner: ModelType<Banner>
  ) {
    switch (action.type) {
      case GET_ALERT_BANNER_SUCCESS:
      case UPDATE_ALERT_BANNER_SUCCESS:
        Banner.upsert({
          id: 'alert_banner',
          ...action.payload.data.alert_banner,
        })
        break
      case GET_ORDERING_BANNER_SUCCESS:
      case UPDATE_ORDERING_BANNER_SUCCESS:
        Banner.upsert({
          id: 'ordering_banner',
          ...action.payload.data.ordering_banner,
        })
        break
      case RESET_DB:
        Banner.all().delete()
        break
      default:
        break
    }
  }
}
