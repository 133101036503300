import { getCampaignMetricByType } from 'actions/campaignMetrics'
import { alert } from 'actions/flash'
import { getLocations } from 'actions/location'
import { getMerchantMetricByType } from 'actions/merchantMetrics'
import useDispatch from 'hooks/useDispatch'
import type { Fields as Campaign } from 'models/Campaign'
import { Type as MetricType, UserCountMetric } from 'models/CampaignMetric'
import { VipSpendData } from 'models/MerchantMetric'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { selectLocationsByIds } from 'selectors/location'
import { selectMerchantMetrics } from 'selectors/merchantMetric'
import View from './View'

type Props = {
  campaign: Campaign
}

const VipContainer: React.FC<Props> = ({ campaign }) => {
  const [isLoadingLocations, setIsLoadingLocations] = useState(true)
  const [isLoadingUserCount, setIsLoadingUserCount] = useState(true)
  const [isLoadingVipSpend, setIsLoadingVipSpend] = useState(true)
  const configLocationsIds = useMemo(
    () => campaign?.config_location_ids || [],
    [campaign?.config_location_ids]
  )
  const campaignId = campaign?.id

  const userCountMetric = useSelector(
    state =>
      selectCampaignMetricByType(
        state,
        campaign.id,
        MetricType.USER_COUNT
      ) as UserCountMetric | null
  )
  const selectedLocations = useSelector(state =>
    selectLocationsByIds(state, configLocationsIds)
  )
  const merchantMetrics = useSelector(selectMerchantMetrics)
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(getLocations({ coming_soon: false }))
      setIsLoadingLocations(false)
      if (response.error) {
        const error = response.error?.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }
    }

    fetchData()
  }, [dispatch])

  useEffect(() => {
    async function reloadUserCount() {
      const campaignMetricResponse = await dispatch(
        getCampaignMetricByType(campaignId, 'user_count')
      )
      setIsLoadingUserCount(false)

      if (campaignMetricResponse.error) {
        const error =
          campaignMetricResponse.error?.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }

      const merchantMetricResponse = await dispatch(
        getMerchantMetricByType('vip_spend', {})
      )
      setIsLoadingVipSpend(false)

      if (merchantMetricResponse.error) {
        const error =
          merchantMetricResponse.error?.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }
    }

    if (campaignId) reloadUserCount()
  }, [campaignId, configLocationsIds, dispatch])

  const metrics = merchantMetrics.vip_spend?.data || ({} as VipSpendData)
  const isLoading =
    isLoadingLocations || isLoadingUserCount || isLoadingVipSpend

  return (
    <View
      isLoading={isLoading}
      campaign={campaign}
      selectedLocations={selectedLocations}
      userCount={userCountMetric?.data?.count}
      metrics={metrics}
    />
  )
}

export default VipContainer
