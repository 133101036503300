import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import FetchableMetricSection from 'components/FetchableMetricSection'
import { CampaignStateT } from 'models/Campaign'
import { MetricTypes } from 'models/CampaignMetric'
import React from 'react'
import Attribution from 'scenes/CampaignCenter/Report/v3/Summary/components/Attribution'

type Props = {
  title: string
  attributionWindow?: number
  attributionTooltip?: string
  subtitle?: string
  subsection?: boolean
  className?: string
  children?: any
  campaignId: number
  metricTypes: MetricTypes
  campaignState: CampaignStateT
}
const Section: React.FC<Props> = props => {
  const {
    campaignId,
    title,
    attributionWindow,
    attributionTooltip,
    subtitle,
    subsection = false,
    className = '',
    children,
    metricTypes,
    campaignState,
  } = props

  const HeaderComponent = subsection ? Text.Header4 : Text.Header3

  return (
    <FetchableMetricSection
      type={'campaign_metric'}
      className={`mb-xxl ${className}`}
      campaignId={campaignId}
      metricTypes={metricTypes}
      shouldFetch={['active', 'complete'].includes(campaignState)}
      attributionWindow={attributionWindow}
    >
      <div className="mb-0">
        <HeaderComponent tag="p" className="mb-0" bold>
          {title}
        </HeaderComponent>
        <Switch condition={!!attributionWindow && !!attributionTooltip}>
          <Attribution
            attributionWindow={attributionWindow!}
            tooltipText={attributionTooltip!}
          />
        </Switch>
        <Switch condition={!!subtitle}>
          <Text.BodyText3 tag="p" className="mb-m mt-xs" color="grey70">
            {subtitle}
          </Text.BodyText3>
        </Switch>
      </div>
      {children}
    </FetchableMetricSection>
  )
}

export default Section
