import React from 'react'

import Section, { KindTypes } from 'components/Section'

type Props = {
  children?: any
  title: string
  description: string
  image: string
  kind: KindTypes
  isIconLeft?: boolean
}

const Card: React.FC<Props> = props => {
  const {
    children,
    description,
    image,
    title,
    kind,
    isIconLeft = false,
  } = props

  return (
    <Section className="p-m mt-l" kind={kind}>
      {isIconLeft && (
        <img
          src={image}
          className="mb-s"
          style={{ height: '32px' }}
          alt={title}
        />
      )}
      <div className="d-flex justify-content-between">
        <div>
          <div className="font-size-18 pb-xs bold">{title}</div>
          <p
            className="grey-70 font-size-14 pb-m"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </div>
        {!isIconLeft && (
          <img src={image} style={{ height: '40px' }} alt={title} />
        )}
      </div>
      {children}
    </Section>
  )
}

export default Card
