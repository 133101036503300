import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Counter from 'components/Counter'
import { buildTranslate } from 'locales'
import React from 'react'
import {
  getBenchmarkConversionRate,
  stages,
  stagesMetricsMapping,
} from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { generateUikitTheme } from 'theme'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'
import { toolTipWidth } from './helpers'

const t = buildTranslate('lifecycle_report.overview.chart.tooltips')

type Props = {
  stage: typeof stages[number]
  merchantType?: string
  showConversionRate: boolean
  didntConvert: number
  converted: number
}

const styletronEngine = new StyletronEngine({ prefix: 'st-tt-' })

const Tooltip: React.FC<Props> = ({
  stage,
  merchantType,
  showConversionRate,
  didntConvert,
  converted,
}) => {
  const metric = stagesMetricsMapping[stage]
  const [css] = useStyletron()
  const uikitTheme = generateUikitTheme()

  let counters

  if (!showConversionRate) {
    counters = (
      <Counter
        primaryValue={numberFormatter(converted)}
        name={t(`${stage}.converted_description`)}
      />
    )
  } else {
    const benchmark = getBenchmarkConversionRate({
      merchantType,
      metric: metric,
    })
    const total = didntConvert + converted

    counters = (
      <>
        <div className="mb-xs">
          <Counter
            primaryValue={percentageFormatter(converted / total, 1)}
            secondaryValue={numberFormatter(converted)}
            name={
              <>
                {t(`${stage}.converted_description`)}
                <br />
                <span className="wisteria">
                  {t('benchmark', {
                    benchmark: percentageFormatter(benchmark, 0),
                  })}
                </span>
              </>
            }
          />
        </div>
        <Counter
          primaryValue={percentageFormatter(didntConvert / total, 1)}
          secondaryValue={numberFormatter(didntConvert)}
          name={t(`${stage}.didnt_convert_description`)}
        />
      </>
    )
  }

  return (
    // Hacking the width to be 1px wider than defined in the HighCharts options
    // in order to get it to actually apply the width and whiteSpace styles
    <div className={`${css({ width: `${toolTipWidth + 1}px` })}`}>
      <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
        <div className="p-m">
          <Text.BodyText4 color="grey70" tag="div" className="mb-xs">
            {t(`${stage}.title`)}
          </Text.BodyText4>
          {counters}
        </div>
        {stage !== 'entered_database' && (
          <div className="d-flex mt-0 grey-10-bkg w-100 pl-m pr-m pt-s pb-s">
            <div className="pr-xs">
              <i className="fa fa-lightbulb-o font-size-20" />
            </div>
            <div>
              <Text.BodyText4 color="grey70" tag="span">
                {t('learn_more')[0]}
              </Text.BodyText4>
              <Text.BodyText4 bold color="grey70" tag="span">
                {t('learn_more')[1]}
              </Text.BodyText4>
              <Text.BodyText4 color="grey70" tag="span">
                {t('learn_more')[2]}
              </Text.BodyText4>
            </div>
          </div>
        )}
      </StyleProvider>
    </div>
  )
}

export default Tooltip
