import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectLandingPage } from 'selectors/landingPage'

type Props = {
  isLoading: boolean
  publishText: string
  disabled?: boolean
}

const t = buildTranslate('cms.content.publish_button')

const PublishButton: React.FC<Props> = ({
  isLoading,
  publishText,
  disabled,
}) => {
  const landingPage = useSelector(selectLandingPage)
  const isDraft = landingPage.state === 'inactive'
  return (
    <Switch
      condition={isDraft}
      fallback={
        <Button
          className="mb-xxxl px-s"
          type="submit"
          isLoading={isLoading}
          disabled={disabled}
        >
          {publishText}
        </Button>
      }
    >
      <Button
        kind="secondary"
        className="mb-xxxl px-s w-100"
        type="submit"
        isLoading={isLoading}
        disabled={disabled}
      >
        {t('save_draft')}
      </Button>
    </Switch>
  )
}

export default PublishButton
