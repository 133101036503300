import React from 'react'

type PropsT = {
  color?: string
  className?: string
}

const ABTest: React.FC<PropsT> = props => {
  const { color = '#36424E', className } = props
  return (
    <svg
      width="80"
      height="83"
      viewBox="0 0 80 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        minWidth: '80px',
      }}
      className={className}
    >
      <path
        d="M1.21898 17.5986C1.21898 15.5789 2.85624 13.9416 4.87591 13.9416H39.9931V66.0292H4.87591C2.85624 66.0292 1.21898 64.392 1.21898 62.3723V17.5986Z"
        fill="white"
        stroke={color}
        strokeWidth="2.43796"
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        d="M78.781 17.5986C78.781 15.5789 77.1438 13.9416 75.1241 13.9416H40.0069V66.0292H75.1241C77.1438 66.0292 78.781 64.392 78.781 62.3723V17.5986Z"
        fill="white"
        stroke={color}
        strokeWidth="2.43796"
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        d="M22.5999 36.8765L20.68 31.9019C20.6232 31.754 20.5634 31.5833 20.5008 31.3899C20.4383 31.1908 20.3785 30.9803 20.3217 30.7585C20.2022 31.2193 20.0799 31.6032 19.9547 31.9104L18.0349 36.8765H22.5999ZM26.0472 41.416H24.7673C24.6194 41.416 24.4999 41.379 24.4089 41.3051C24.3179 41.2311 24.2496 41.1373 24.2041 41.0235L23.0607 38.0711H17.5741L16.4307 41.0235C16.3965 41.1259 16.3311 41.2169 16.2344 41.2966C16.1377 41.3762 16.0183 41.416 15.876 41.416H14.5961L19.4854 29.1884H21.1579L26.0472 41.416Z"
        fill={color}
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        d="M26.5894 46.2553H14.7044C13.933 46.2553 13.333 46.8838 13.333 47.6266V55.8547C13.333 56.6261 13.933 57.2261 14.7044 57.2261H26.5894C27.3322 57.2261 27.9607 56.6261 27.9607 55.8547V47.6266C27.9607 46.8838 27.3322 46.2553 26.5894 46.2553ZM26.5894 47.6266V48.798C25.9323 49.3408 24.9038 50.1408 22.7325 51.855C22.2468 52.2264 21.304 53.1406 20.6469 53.112C19.9612 53.1406 19.0184 52.2264 18.5327 51.855C16.3614 50.1408 15.3329 49.3408 14.7044 48.798V47.6266H26.5894ZM14.7044 55.8547V50.5693C15.3329 51.0836 16.2757 51.8264 17.6756 52.9406C18.3042 53.4263 19.4184 54.5119 20.6469 54.4834C21.8468 54.5119 22.9325 53.4263 23.5896 52.9406C24.9895 51.8264 25.9323 51.0836 26.5894 50.5693V55.8547H14.7044Z"
        fill={color}
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        d="M66.5894 46.2553H54.7044C53.933 46.2553 53.333 46.8838 53.333 47.6266V55.8547C53.333 56.6261 53.933 57.2261 54.7044 57.2261H66.5894C67.3322 57.2261 67.9607 56.6261 67.9607 55.8547V47.6266C67.9607 46.8838 67.3322 46.2553 66.5894 46.2553ZM66.5894 47.6266V48.798C65.9323 49.3408 64.9038 50.1408 62.7325 51.855C62.2468 52.2264 61.304 53.1406 60.6469 53.112C59.9612 53.1406 59.0184 52.2264 58.5327 51.855C56.3614 50.1408 55.3329 49.3408 54.7044 48.798V47.6266H66.5894ZM54.7044 55.8547V50.5693C55.3329 51.0836 56.2757 51.8264 57.6756 52.9406C58.3042 53.4263 59.4184 54.5119 60.6469 54.4834C61.8468 54.5119 62.9325 53.4263 63.5896 52.9406C64.9895 51.8264 65.9323 51.0836 66.5894 50.5693V55.8547H54.7044Z"
        fill={color}
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        d="M60.3985 40.102C60.8706 40.102 61.2773 40.0479 61.6187 39.9398C61.96 39.8318 62.2387 39.681 62.4549 39.4876C62.6767 39.2885 62.8388 39.0553 62.9412 38.7879C63.0436 38.5148 63.0948 38.2162 63.0948 37.8919C63.0948 37.2605 62.8701 36.7628 62.4207 36.3987C61.9713 36.0289 61.2972 35.8441 60.3985 35.8441H57.7533V40.102H60.3985ZM57.7533 30.494V34.6665H59.9377C60.4041 34.6665 60.808 34.6153 61.1493 34.5129C61.4963 34.4105 61.7808 34.2683 62.0026 34.0863C62.2302 33.9043 62.398 33.6853 62.5061 33.4293C62.6142 33.1676 62.6682 32.8832 62.6682 32.576C62.6682 31.8535 62.452 31.3273 62.0197 30.9974C61.5874 30.6618 60.9161 30.494 60.0059 30.494H57.7533ZM60.0059 29.1884C60.7568 29.1884 61.4025 29.2624 61.9429 29.4103C62.489 29.5582 62.9356 29.7687 63.2826 30.0417C63.6352 30.3148 63.8941 30.6504 64.059 31.0486C64.224 31.4411 64.3065 31.8848 64.3065 32.3797C64.3065 32.6812 64.2581 32.9713 64.1614 33.2501C64.0704 33.5231 63.9311 33.7791 63.7433 34.018C63.5556 34.257 63.3167 34.4731 63.0266 34.6665C62.7421 34.8543 62.4065 35.0078 62.0197 35.1273C62.9185 35.298 63.5926 35.6194 64.042 36.0915C64.4971 36.558 64.7246 37.1723 64.7246 37.9346C64.7246 38.4523 64.6279 38.9244 64.4345 39.3511C64.2468 39.7777 63.968 40.1446 63.5983 40.4518C63.2342 40.759 62.7848 40.9979 62.2501 41.1686C61.7154 41.3335 61.1067 41.416 60.4241 41.416H56.1064V29.1884H60.0059Z"
        fill={color}
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
      <path
        d="M40.6064 0V83"
        stroke={color}
        strokeWidth="1.21898"
        strokeDasharray="2.44 2.44"
        style={{
          transition: 'stroke 300ms ease-out, fill 300ms ease-out',
        }}
      />
    </svg>
  )
}

export default ABTest
