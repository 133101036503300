import { Button } from '@thanx/uikit/button'
import { Notification } from '@thanx/uikit/notification'
import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import React from 'react'
import { useIsMobile } from 'utilities/responsive'

const t = buildTranslate('cms.components.publish_banner')

type Props = {
  buttonText: string
  isLoading?: boolean
  disabled?: boolean
  buttonType?: 'button' | 'submit'
  onClick?: () => void
}

const PublishBanner: React.FC<Props> = ({
  buttonText,
  isLoading,
  disabled,
  buttonType = 'submit',
  onClick,
}) => {
  const isMobile = useIsMobile()

  return (
    <Notification kind="info" size="full">
      <div className="d-flex p-xs align-items-center belize-hole-50">
        <p className="flex-2 body-text-4 mr-m">
          <strong>{t('ready_go_live')}</strong> {t('hit_publish')}
        </p>
        <Switch condition={!isMobile}>
          <Button
            type={buttonType}
            isLoading={isLoading}
            disabled={disabled}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </Switch>
      </div>
    </Notification>
  )
}

export default PublishBanner
