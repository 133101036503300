import { helpUrl } from 'utilities/urlUtils'

export const analyticsOptions = {
  facebook_pixel_id: {
    where_to_link:
      'https://www.facebook.com/business/help/1181250022022158?id=180505742745347',
    how_to_link: `${helpUrl}17663751868695-Track-the-engagement-and-performance-of-app-and-web-ordering-via-Facebook-Instagram-and-Google`,
  },
  google_measurement_id: {
    where_to_link: 'https://support.google.com/analytics/answer/9539598',
    how_to_link: `${helpUrl}17663751868695-Track-the-engagement-and-performance-of-app-and-web-ordering-via-Facebook-Instagram-and-Google`,
  },
  google_tag_manager_id: {
    where_to_link: `${helpUrl}17663710525335-Using-Google-Tag-Manager-with-Thanx-Online-Ordering`,
    how_to_link: `${helpUrl}17663710525335-Using-Google-Tag-Manager-with-Thanx-Online-Ordering`,
  },
}
