import { api } from './index'

export type User = {
  id: number
  uid: string
  email: string
  first_name: string
  last_name: string
}

type ProgressPayload = {
  grant_type: 'progress'
  grant_progress: {
    grant_progress_type: 'dollar' | 'percentage'
    grant_value: number
  }
  rating_id: number
}

type RewardPayload = {
  grant_type: 'reward'
  grant_reward: {
    program_id: number
    issuance_reason?: string
  }
  rating_id: number
}

export type GrantPayload = ProgressPayload | RewardPayload

export type GrantParams = {
  user_uid: string
  grant_payload: GrantPayload
}

type ModifyPointsParams = {
  user_uid: string
  amount: number
}

type GrantUserTierParams = {
  userUid: string
  state: string
}

type GrantPurchaseParams = {
  userUid: string
  purchase: {
    purchased_at: string
    amount: number
    location_id: number
  }
}

export const usersApi = api.injectEndpoints({
  endpoints: builder => ({
    getUser: builder.query<{ profile: User }, number>({
      query: userId => `users/${userId}`,
      providesTags: (_result, _error, userId) => [{ type: 'User', id: userId }],
    }),
    grantReward: builder.mutation<any, GrantParams>({
      query: grant_params => ({
        url: `users/${grant_params.user_uid}/grant_reward`,
        method: 'POST',
        body: grant_params.grant_payload,
      }),
      invalidatesTags: ['Feedback'],
    }),
    grantPoints: builder.mutation<any, ModifyPointsParams>({
      query: ({ amount, user_uid }) => ({
        url: `users/${user_uid}/grant_points`,
        method: 'POST',
        body: {
          amount: amount,
        },
      }),
    }),
    block: builder.mutation<any, string>({
      query: userUid => ({
        url: `users/${userUid}/deactivate`,
        method: 'POST',
      }),
    }),
    grantUserTier: builder.mutation<any, GrantUserTierParams>({
      query: ({ userUid, state }) => ({
        url: `users/${userUid}/grant_user_tier`,
        method: 'POST',
        body: {
          state: state,
        },
      }),
    }),
    grantPurchase: builder.mutation<any, GrantPurchaseParams>({
      query: ({ userUid, purchase }) => ({
        url: `users/${userUid}/grant_purchase`,
        method: 'POST',
        body: { provider_purchase: purchase },
      }),
    }),
    clawbackPoints: builder.mutation<any, ModifyPointsParams>({
      query: ({ amount, user_uid }) => ({
        url: `users/${user_uid}/clawback_points`,
        method: 'POST',
        body: {
          amount: amount,
        },
      }),
    }),
  }),
})
