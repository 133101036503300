import { Switch } from '@thanx/uikit/switch'
import { notifyThanx } from 'actions/contact'
import dustImage from 'assets/images/campaign-center/landing/overview/texture_dust.png'
import introImage from 'assets/images/campaigns/intro.png'
import earnedLoyaltyImage from 'assets/images/campaigns/loyalty.png'
import tiersImage from 'assets/images/campaigns/tiers.png'
import useDispatch from 'hooks/useDispatch'
import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { selectCampaignsByTypeAndState } from 'selectors/campaign'
import { selectTiers } from 'selectors/tier'
import EarnedLoyalty from './components/EarnedLoyalty'
import IntroProgram from './components/IntroProgram'
import NotifyModal from './components/NotifyModal'
import OptionTab from './components/OptionTab'
import Tiers from './components/Tiers'

type SelectionType = 'earned_loyalty' | 'intro_reward' | 'tiers'

const LoyaltyContent: React.FC = () => {
  const [selected, setSelected] = useState<SelectionType>('earned_loyalty')
  const [notifyModalVisible, setNotifyModalVisible] = useState(false)
  const earnedLoyalty = useSelector(
    state => selectCampaignsByTypeAndState(state, 'loyalty', 'active')[0]
  )
  const introProgram = useSelector(
    state => selectCampaignsByTypeAndState(state, 'intro', 'active')[0]
  )
  const tiers = useSelector(selectTiers)
  const dispatch = useDispatch()

  async function showNotifyModal() {
    await dispatch(
      notifyThanx({
        merchant_id: 1,
        subject: selected,
        source: 'optional loyalty',
      })
    )
    setNotifyModalVisible(true)
  }

  return (
    <div className="loyalty-landing">
      <Helmet>
        <title>{I18n.t('loyalty.landing.page_title')}</title>
      </Helmet>
      <div
        className="header spearmint-bkg margin-bottom-huge padding-top-huge padding-bottom-huge"
        style={{ backgroundImage: `url(${dustImage})` }}
      >
        <div className="container padding-top-medium padding-bottom-medium">
          <div className="header-text white width-33 full-width-on-sm text-center-xs text-center-sm">
            <Translate
              value="loyalty.landing.heading.title"
              tag="p"
              className="font-size-32 margin-bottom-medium"
            />
            <Translate
              dangerousHTML
              value="loyalty.landing.heading.subtitle"
              className="italic font-size-20 margin-bottom-none block"
              tag="em"
            />
          </div>
        </div>
      </div>
      <div className="container tabs padding-bottom-huge padding-left-none padding-right-none">
        <div className="display-flex">
          <OptionTab
            title={I18n.t('loyalty.earned.title')}
            state={earnedLoyalty ? 'active' : 'not active'}
            image={earnedLoyaltyImage}
            alt={I18n.t('loyalty.earned.tab_icon_alt_text')}
            selected={selected === 'earned_loyalty'}
            onSelect={() => setSelected('earned_loyalty')}
          />
          <OptionTab
            title={I18n.t('loyalty.intro.title')}
            state={introProgram ? 'active' : 'not active'}
            image={introImage}
            alt={I18n.t('loyalty.intro.tab_icon_alt_text')}
            selected={selected === 'intro_reward'}
            onSelect={() => setSelected('intro_reward')}
          />
          <OptionTab
            title={I18n.t('loyalty.tiers.title')}
            state={tiers.length > 0 ? 'active' : 'not active'}
            image={tiersImage}
            alt={I18n.t('loyalty.tiers.tab_icon_alt_text')}
            selected={selected === 'tiers'}
            onSelect={() => setSelected('tiers')}
          />
        </div>
      </div>
      <div className="container padding-none">
        <Switch condition={selected === 'earned_loyalty'}>
          <EarnedLoyalty
            campaign={earnedLoyalty}
            inactiveButtonAction={() => showNotifyModal()}
          />
        </Switch>
        <Switch condition={selected === 'intro_reward'}>
          <IntroProgram
            campaign={introProgram}
            inactiveButtonAction={() => showNotifyModal()}
          />
        </Switch>
        <Switch condition={selected === 'tiers'}>
          <Tiers tiers={tiers} inactiveButtonAction={() => showNotifyModal()} />
        </Switch>
      </div>
      <NotifyModal
        show={notifyModalVisible}
        onHide={() => setNotifyModalVisible(false)}
      />
    </div>
  )
}

export default LoyaltyContent
