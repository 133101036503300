import type { Fields, Metrics, ShortType } from 'models/MerchantMetric'
import orm from '../models/orm'

import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectAllMerchantMetrics = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.MerchantMetric.all().toRefArray()
  }
)

export const selectMerchantMetrics = createSelector(
  selectAllMerchantMetrics,
  (metrics: Fields[]): Metrics => {
    const formattedMetrics = metrics.reduce((acc, metric) => {
      const { type } = metric
      const typeName = type.replace('merchant_metric_', '')
      return { ...acc, [typeName]: metric }
    }, {})

    return formattedMetrics
  }
)
export const selectMerchantMetricByType = createSelector(
  (state: any) => selectMerchantMetrics(state),
  (_: any, type: ShortType) => type,
  (metrics: Metrics, type: ShortType): Fields => metrics[type] || null
)
