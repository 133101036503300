import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { DateRangeFilter } from 'components/DateFilter/utilities'
import Status from 'components/Status'
import palette from 'constants/palette'
import Highcharts, { SeriesLineOptions } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import { Fields as Campaign } from 'models/Campaign'
import { EventsMetric } from 'models/CampaignMetric'
import React, { useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { getXAxisCategories } from 'scenes/CampaignCenter/Report/v3/helpers'
import {
  getDateLabel,
  getFormattedDate,
  getMomentFromSeriesCategory,
} from 'utilities/date'
import { currencyFormatter } from 'utilities/formatters'
import { getVariantSeries } from './helpers'

type PropsT = {
  campaign: Campaign
  metrics: EventsMetric[]
  timezone: string
  dateRangeFilter: DateRangeFilter
}
const t = buildTranslate('thanx_campaigns.report.v3.test.chart')

const LineChart: React.FC<PropsT> = ({
  campaign,
  metrics,
  timezone,
  dateRangeFilter,
}) => {
  const [categories, setCategories] = useState<string[]>([])
  const [series, setSeries] = useState<
    Highcharts.SeriesLineOptions[] | undefined
  >()
  const [isUpdateAllowed, setIsUpdateAllowed] = useState(false)
  const chartRef = useRef()
  const [css] = useStyletron()

  const leftAxisFormatter = label => currencyFormatter(label.value, false)
  const tooltipFormatter = (letter, x, y) => {
    const date = getFormattedDate(
      getMomentFromSeriesCategory(x, granularity, timezone),
      granularity
    )
    // <Text> would be ideal here but it doesn't seem to play nice with renderToString
    const description = isEmpty(letter)
      ? t('control_tooltip')
      : t('tooltip', { name: letter })
    return renderToString(
      <div>
        <div className="mb-xs grey-70 font-size-14">{date}</div>
        <h3 className="m-0">{currencyFormatter(y, false)}</h3>
        <div className="grey-70 font-size-14">{description}</div>
      </div>
    )
  }

  const granularity = metrics[0]?.granularity ?? 'daily'
  // Use a box instead of a line for the legend
  // @ts-ignore
  Highcharts.seriesTypes.line.prototype.drawLegendSymbol =
    // @ts-ignore
    Highcharts.seriesTypes.area.prototype.drawLegendSymbol
  const options: Highcharts.Options = {
    chart: {
      type: 'line',
      style: {
        fontFamily: 'Lato',
        overflow: 'visible !important',
      },
    },
    title: undefined,
    credits: { enabled: false },
    tooltip: {
      formatter: function () {
        return tooltipFormatter(
          this.series.options.custom!.description,
          this.point.category,
          this.y
        )
      },
      backgroundColor: palette.white,
      borderWidth: 1,
      borderColor: palette.grey20,
      borderRadius: 4,
      outside: true,
      useHTML: true,
      shadow: false,
      padding: 16,
    },
    xAxis: {
      categories,
      title: {
        text: t('date'),
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      labels: {
        formatter: function (
          this: Highcharts.AxisLabelsFormatterContextObject
        ) {
          return getDateLabel(String(this.value), granularity, timezone)
        },
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
      tickLength: 0,
      lineColor: palette.grey30,
    },
    yAxis: {
      labels: {
        formatter: leftAxisFormatter,
        style: {
          color: palette.grey70,
          fontSize: '12px',
        },
      },
      allowDecimals: false,
      min: 0,
      softMax: 1,
      title: {
        text: t('net_revenue'),
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: palette.grey90,
          fontSize: '14px',
        },
      },
      gridLineDashStyle: 'LongDash',
      gridLineColor: palette.grey30,
    },
    series,
    legend: {
      align: 'left',
      symbolHeight: 12,
      symbolRadius: 0,
      x: 72,
      itemStyle: {
        color: palette.grey90,
        fontSize: '14px',
      },
    },
  }
  useEffect(() => {
    if (metrics[0]?.data?.[0]?.values) {
      setCategories(
        getXAxisCategories(
          metrics[0]?.data?.[0]?.values,
          dateRangeFilter,
          timezone
        )
      )
    }
  }, [metrics, timezone, campaign, dateRangeFilter])

  useEffect(() => {
    if (metrics.length > 0) {
      setSeries(
        metrics.map(metric => {
          return getVariantSeries(
            metric,
            categories,
            timezone,
            metric?.meta?.name!
          ) as SeriesLineOptions
        })
      )
      setIsUpdateAllowed(true)
    }
  }, [categories, metrics, isUpdateAllowed, timezone, campaign.type])

  if (!isUpdateAllowed) {
    return null
  }
  let status
  if (metrics.every(metric => metric.isLoading)) status = 'loading'
  if (metrics.every(metric => metric.isErrored)) status = 'error'
  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <HighchartsReact
          allowChartUpdate={isUpdateAllowed}
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
          containerProps={{ className: 'w-100' }}
        />
        <Status
          status={status}
          className={`absolute ml-xxl mr-s ${css({ marginTop: '-115px' })}`}
        />
      </div>
      {timezone && (
        <Text.BodyText5
          className={`position-relative float-right mr-s ${css({
            marginTop: '-35px',
          })}`}
          color="grey70"
        >
          {t('timezone', { timezone })}
        </Text.BodyText5>
      )}
    </>
  )
}

export default LineChart
