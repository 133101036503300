import { getCampaignMetricByType } from 'actions/campaignMetrics'
import { alert } from 'actions/flash'
import {
  getUserTagValues,
  GET_USER_TAG_VALUES_SUCCESS,
} from 'actions/userTagValues'
import useDispatch from 'hooks/useDispatch'
import type { Fields as Campaign } from 'models/Campaign'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectCurrentMerchantUserTagValues } from 'selectors/userTagValue'
import View from './View'

type Props = {
  campaign: Campaign
}

const AudienceContainer: React.FC<Props> = ({ campaign }) => {
  const [userCountRequestsCount, setUserCountRequestsCount] = useState(0)
  const [isLoadingUserTags, setIsLoadingUserTags] = useState(true)
  const [multiSelectMenuOpen, setMultiSelectMenuOpen] = useState(false)
  const [requiresUserCountReload, setRequiresUserCountReload] = useState(false)

  const favoriteLocationValues = useSelector(state =>
    selectCurrentMerchantUserTagValues(state, 'favorite_location')
  )

  const configTagValues = campaign.config_tag_values
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(getUserTagValues('favorite_location'))
      setIsLoadingUserTags(false)

      if (response.type !== GET_USER_TAG_VALUES_SUCCESS) {
        const error = response.error.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }
    }

    fetchData()
    if (campaign.id) reloadUserCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (multiSelectMenuOpen) {
      setRequiresUserCountReload(true)
    } else {
      reloadUserCount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configTagValues])

  async function reloadUserCount() {
    setUserCountRequestsCount(userCountRequestsCount + 1)
    const response = await dispatch(
      getCampaignMetricByType(campaign.id, 'user_count')
    )
    setUserCountRequestsCount(userCountRequestsCount - 1)

    if (response.error) {
      const error = response.error.response?.data?.error || null
      dispatch(
        alert({
          key: 'danger',
          message: I18n.t('thanx_campaigns.review.get_metrics_error'),
          error,
          displayDetails: false,
        })
      )
    }
  }

  function onMenuStateChange(isOpened: boolean) {
    setMultiSelectMenuOpen(isOpened)

    if (!isOpened && requiresUserCountReload) {
      reloadUserCount()
      setRequiresUserCountReload(false)
    }
  }

  return (
    <View
      isLoading={userCountRequestsCount > 0}
      isLoadingUserTags={isLoadingUserTags}
      campaign={campaign}
      userTagOptions={favoriteLocationValues}
      onMenuStateChange={onMenuStateChange}
    />
  )
}

export default AudienceContainer
