import { Text } from '@thanx/uikit/text'
import PopoverContent, { Sections } from 'components/PopoverContent'
import React from 'react'
import BodyCellContainer from './BodyCellContainer'

type CellData = {
  value: string | number
  sections?: Sections
}

type Props = {
  className?: string
  isErrored: boolean
  isLoading: boolean
  values: CellData[]
}

const BodyCell: React.FC<Props> = props => {
  const { isErrored, isLoading, values, className = '' } = props

  return (
    <BodyCellContainer
      isErrored={isErrored}
      isLoading={isLoading}
      className={className}
    >
      {values?.map((data, index) => (
        <Text.BodyText4
          key={`channels-cell-${index}`}
          tag="div"
          color="grey70"
          className="ellipsis max-width-100"
        >
          <span className="mr-xs">{data.value}</span>
          {data.sections && <PopoverContent sections={data.sections} />}
        </Text.BodyText4>
      ))}
    </BodyCellContainer>
  )
}

export default BodyCell
