import React from 'react'

type PropsT = {
  className?: string
  fillColor?: string
}

const Bulb = ({ className, fillColor = 'currentColor' }: PropsT) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill={fillColor}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5556 19.92V20.9467C15.5556 21.3298 15.2587 21.6556 14.8444 21.7764V22.12C14.8444 22.606 14.3669 23 13.7778 23H10.2222C9.63311 23 9.15556 22.606 9.15556 22.12V21.7764C8.74133 21.6556 8.44444 21.3298 8.44444 20.9467V19.92C8.44444 19.677 8.68324 19.48 8.97778 19.48H15.0222C15.3168 19.48 15.5556 19.677 15.5556 19.92ZM9.42857 9.24248C9.42857 7.67526 10.7103 6.40025 12.2857 6.40025C12.6802 6.40025 13 6.0821 13 5.68969C13 5.29728 12.6802 4.97913 12.2857 4.97913C9.92254 4.97913 8 6.89164 8 9.24248C8 9.63489 8.31982 9.95304 8.71429 9.95304C9.10875 9.95304 9.42857 9.63489 9.42857 9.24248ZM12 2.98957C15.3157 2.98957 18 5.56046 18 8.7372C18 12.2218 16.2477 11.4528 14.2041 15.9217H9.79594C7.74928 11.4462 6 12.2338 6 8.7372C6 5.56096 8.68378 2.98957 12 2.98957ZM12 1C7.58205 1 4 4.47047 4 8.75101C4 13.2312 6.34986 12.7825 8.11436 17.2363C8.27573 17.6437 8.68118 17.9113 9.13145 17.9113H14.8685C15.3188 17.9113 15.7242 17.6436 15.8856 17.2363C17.6501 12.7825 20 13.2312 20 8.75101C20 4.47047 16.418 1 12 1Z"
      fill="#6A747F"
    />
  </svg>
)

export default Bulb
