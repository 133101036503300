import { Kind } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import FetchableMetricSection from 'components/FetchableMetricSection'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import {
  EffectiveDiscountRateCumulativeMetric,
  ShortType,
} from 'models/EffectiveDiscountRateMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import Benchmark from 'scenes/Home/Metrics/Benchmark'
import Card from 'scenes/Home/Metrics/Card'
import MetricCounter from 'scenes/Home/Metrics/MetricCounter'
import { getReportAccessState } from 'scenes/Points/Landing/EffectiveDiscountRate/helpers'
import { getBenchmarkRate } from 'scenes/Reports/helpers'
import { selectEffectiveDiscountRateCumulativeMetric } from 'selectors/effectiveDiscountRateMetric'
import { selectExperience } from 'selectors/pointsExperience'
import { effectiveDiscountRateUrl } from 'utilities/urlUtils'
import Button from './EffectiveDiscountRateMetric/Button'
import { MetricCardT } from './index'

const t = buildTranslate('home.metrics')

const EffectiveDiscountRateMetric: React.FC<MetricCardT> = ({ merchant }) => {
  const dispatch = useDispatch()
  const pointsExperience = useSelector(selectExperience)

  const cumulativeMetrics = useSelector(state =>
    selectEffectiveDiscountRateCumulativeMetric(state, merchant?.id)
  ) as EffectiveDiscountRateCumulativeMetric

  const { rate: benchmarkRate } = getBenchmarkRate(
    merchant.merchant_type,
    'effective_discount_rate'
  )

  const reportAccessState = getReportAccessState(merchant, pointsExperience)
  const shouldFetch = reportAccessState === 'available'

  const buttonText = (() => {
    if (reportAccessState === 'points_inactive') {
      return t('shared.learn_more')
    }
  })()

  const buttonKind = (() => {
    if (reportAccessState === 'points_inactive') {
      return Kind.SECONDARY
    }
  })()

  const onClick = () => {
    switch (reportAccessState) {
      case 'available':
      case 'coming_soon':
        return dispatch(push('/points#effective_discount_rate'))
      case 'points_inactive':
        return window.open(effectiveDiscountRateUrl, '_blank')
    }
  }

  return (
    <Card
      title={t('effective_discount_rate.title')}
      buttonText={buttonText}
      buttonKind={buttonKind}
      onClick={onClick}
      sections={[
        { content: t('effective_discount_rate.popovers.section_1') },
        {
          title: t('shared.popovers.how_its_calculated'),
          content: t('effective_discount_rate.popovers.section_2'),
        },
        {
          title: t('shared.popovers.example'),
          content: t('effective_discount_rate.popovers.section_3'),
        },
      ]}
    >
      <FetchableMetricSection
        merchantId={merchant?.id}
        shouldFetch={shouldFetch}
        type="effective_discount_rate_metric"
        metricTypes={[ShortType.CUMULATIVE]}
      >
        <Switch
          condition={reportAccessState === 'available'}
          fallback={<Button reportAccessState={reportAccessState} />}
        >
          <MetricCounter
            currentRate={cumulativeMetrics?.current?.effective_discount_rate}
            previousRate={cumulativeMetrics?.previous?.effective_discount_rate}
            isLoading={cumulativeMetrics?.isLoading}
            isErrored={cumulativeMetrics?.isErrored}
            inverted
          />
        </Switch>
        <Benchmark benchmarkRate={benchmarkRate} />
      </FetchableMetricSection>
    </Card>
  )
}

export default EffectiveDiscountRateMetric
