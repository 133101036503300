import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Pagination from 'components/Pagination'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { Fields as UserProfile } from 'models/UserProfile'
import React from 'react'
import { useCampaignsTable } from './hooks'
import Table from './Table'

const t = buildTranslate('users.view.activity.campaigns')

type PropsT = {
  profile: UserProfile
}

const Campaigns: React.FC<PropsT> = ({ profile }: PropsT) => {
  const {
    isFetchingCampaigns,
    campaigns,
    campaignTypes,
    currentPage,
    numPages,
    onChangePage,
  } = useCampaignsTable(profile)

  return (
    <div>
      <div className="d-flex justify-content-between">
        <Text.Header4 className={'mb-m'}>{t('title')}</Text.Header4>
        <Text.BodyText4 className="mt-xs" color="grey70">
          {t('not_live')}
        </Text.BodyText4>
      </div>
      <Spinner
        className={'d-flex justify-content-center'}
        size={'3x'}
        isLoading={isFetchingCampaigns}
      >
        <Switch
          condition={campaigns.length > 0}
          fallback={
            <Text.BodyText3 color="grey70">{t('empty_state')}</Text.BodyText3>
          }
        >
          <Table campaigns={campaigns} campaignTypes={campaignTypes} />
          <div className={'d-flex justify-content-center margin-top-huge'}>
            <Pagination
              currentPage={currentPage}
              onChangePage={onChangePage}
              numPages={numPages}
            />
          </div>
        </Switch>
      </Spinner>
    </div>
  )
}

export default Campaigns
