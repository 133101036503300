import invert from 'lodash/invert'

let types = {
  'segment/configurable': 'generic',
  'segment/frequency': 'frequency',
  'segment/part_of_day': 'day_part',
  'segment/part_of_week': 'week_part',
  invite: 'thanxgiving',
  'vip/spend': 'vip',
}

export default type => {
  return types[type] || invert(types)[type] || type
}
