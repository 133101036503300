import { getMerchantMetricByType } from 'actions/merchantMetrics'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { Fields as CampaignType } from 'models/CampaignType'
import type { Metrics } from 'models/MerchantMetric'
import React, { useEffect, useState } from 'react'
import type { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { selectMerchantMetrics } from 'selectors/merchantMetric'

interface ExistingPropsT {
  handleClick?: () => void
  isCampaignTypeLoading?: boolean
  campaignType?: CampaignType
  merchantName?: string
}
export interface MetricsStatus {
  [key: string]: {
    isLoading: boolean
  }
}

interface WrapperProps {
  merchantMetrics?: Metrics
  merchantMetricStatuses?: MetricsStatus
}

export type MetricsProps = ExistingPropsT & WrapperProps

const withMerchantMetrics =
  (metricNames: string[] | ((names: any) => string[])) =>
  (WrappedComponent: ComponentType<MetricsProps>): React.FC<MetricsProps> => {
    const MetricComponent: React.FC<ExistingPropsT> = props => {
      const metrics = useSelector(selectMerchantMetrics)
      const merchant = useCurrentMerchant()
      const isLoadingStatus = getMetricNames().reduce((obj, name) => {
        const isLoading = !!metrics[name]?.isLoading || !metrics[name]
        obj[name] = { isLoading }
        return obj
      }, {})

      const [merchantMetricStatuses, setMerchantMetricStatuses] =
        useState(isLoadingStatus)
      const [firstMetrics] = useState(metrics)
      const [firstMetricsNames] = useState(getMetricNames())
      const dispatch = useDispatch()

      useEffect(() => {
        firstMetricsNames.map(metricName => {
          if (!firstMetrics?.[metricName]) {
            dispatch(
              getMerchantMetricByType(metricName, { merchant_id: merchant?.id })
            )
          }
          return null
        })
      }, [dispatch, firstMetrics, firstMetricsNames, merchant?.id])

      useEffect(() => {
        const statuses = {}
        firstMetricsNames.forEach(metricName => {
          statuses[metricName] = metrics[metricName]
            ? { isLoading: metrics[metricName].isLoading }
            : { isLoading: true }
        })
        setMerchantMetricStatuses(statuses)
      }, [firstMetricsNames, metrics])

      function getMetricNames() {
        if (typeof metricNames === 'function') {
          return metricNames(props)
        }
        return metricNames
      }

      return (
        <WrappedComponent
          {...props}
          merchantMetrics={metrics}
          merchantMetricStatuses={merchantMetricStatuses}
        />
      )
    }

    return MetricComponent
  }

export default withMerchantMetrics
