import { alert } from 'actions/flash'
import Cell from 'components/Cell'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import CampaignStatus from 'scenes/CampaignCenter/components/CampaignStatus'
import CategoryIndicator from 'scenes/CampaignCenter/components/CategoryIndicator'
import StopCampaignModal from 'scenes/CampaignCenter/components/StopCampaignModal'
import { selectCampaignTypeByType__deprecated } from 'selectors/campaignType'
import { useUserTimeZone } from 'utilities/userTimeZone'
import DeleteModal from '../DeleteModal'
import ReportLink from '../ReportLink'

import { useStyletron } from '@thanx/uikit/theme'
import { Fields as Campaign } from 'models/Campaign'
import TestBadge from 'scenes/CampaignCenter/Report/components/TestBadge'
import { Column } from './index'

type Props = {
  campaign: Campaign
  columns: Column[]
  onStop?: () => void
}

const t = buildTranslate('thanx_campaigns.landing.reporting.table')

const CampaignItem: React.FC<Props> = props => {
  const { campaign, columns, onStop } = props
  const [css] = useStyletron()

  const [isDeleteModalOpen, setIsDeleteOpen] = useState(false)
  const [isStopModalOpen, setIsStopModalOpen] = useState(false)

  const userTimeZone = useUserTimeZone()
  const dispatch = useDispatch()
  const campaignType = useSelector(state =>
    selectCampaignTypeByType__deprecated(
      //@ts-ignore
      state.orm,
      campaign.type
    )
  )

  if (!campaignType) return null

  const timestamp = (function (): string {
    let timeString = campaign.start_at
    if (campaign.state === 'draft') {
      timeString = campaign.created_at
    }

    return moment.tz(timeString, userTimeZone).format('L')
  })()

  const campaignName = campaign.name || campaignType.name

  const creator = campaign.created_by_thanx
    ? I18n.t('thanx_campaigns.landing.reporting.created_by_thanx')
    : campaign.creator
    ? `${campaign.creator.first_name} ${campaign?.creator?.last_name?.[0]}.`
    : ''

  const canDelete = campaign.state === 'draft'
  const canStop =
    campaign.state === 'active' && campaign.type === 'custom_automated'

  function getCell(column: Column): React.ReactNode {
    if (column === 'name') {
      return (
        <Cell key={column}>
          <div className="body-text-3">
            {campaign.name || campaignType?.name}
          </div>
          <div className="d-flex">
            <CategoryIndicator
              category={campaignType?.category || ''}
              className="inline-block mr-xs"
            />
            <TestBadge campaign={campaign} />
          </div>
        </Cell>
      )
    } else if (column === 'date') {
      return <Cell key={column}>{timestamp}</Cell>
    } else if (column === 'createdBy') {
      return (
        <Cell className="fs-mask" key={column}>
          {creator}
        </Cell>
      )
    } else if (column === 'status') {
      return (
        <Cell key={column}>
          <CampaignStatus
            state={campaign.state}
            reason={campaign.review_reason}
          />
        </Cell>
      )
    } else if (column === 'reward') {
      return <Cell key={column}>{campaign.redeem_description}</Cell>
    } else if (column === 'actions') {
      return (
        <Cell
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: '8px',
            whiteSpace: 'nowrap',
          })}
          key={column}
        >
          <ReportLink campaign={campaign} />
          {canDelete && (
            <Button bsStyle="link" onClick={() => setIsDeleteOpen(true)}>
              {t('settings.draft.delete_button')}
            </Button>
          )}
          {canStop && (
            <Button bsStyle="link" onClick={() => setIsStopModalOpen(true)}>
              {t('settings.active.stopButton')}
            </Button>
          )}
        </Cell>
      )
    } else {
      return null
    }
  }

  function handleStop() {
    setIsStopModalOpen(false)
    dispatch(
      alert({
        key: 'success',
        message: I18n.t('thanx_campaigns.components.stopCampaignModal.alert', {
          name: campaignName,
        }),
      })
    )
    onStop && onStop()
  }

  return (
    <>
      <tr className="border-bottom-1 grey-30-border" key={campaign.created_at}>
        {columns.map(column => getCell(column))}
      </tr>

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteOpen(false)}
        campaign={campaign}
      />
      <StopCampaignModal
        isOpen={isStopModalOpen}
        onClose={() => setIsStopModalOpen(false)}
        onStop={handleStop}
        campaignId={campaign.id}
      />
    </>
  )
}

export default CampaignItem
