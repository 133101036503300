import { Switch } from '@thanx/uikit/switch'
import { getCampaign } from 'actions/campaigns'
import { getCampaignType } from 'actions/campaignTypes'
import { alert } from 'actions/flash'
import Loading from 'components/Loading'
import useDispatch from 'hooks/useDispatch'
import useFetchCampaignVariants from 'hooks/useFetchCampaignVariants'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import CampaignConfigContext from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import { selectCampaign } from 'selectors/campaign'
import { selectCampaignConfig } from 'selectors/campaignConfig'
import configs from './configs'

type Props = RouteComponentProps<{ id: string; type: string }>

const Report: React.FC<Props> = ({ match }) => {
  const { type } = match.params
  const id = Number(match.params.id)
  const [isLoading, setIsLoading] = useState(true)
  const campaign = useSelector(state => selectCampaign(state, id))
  const campaignType = campaign?.type || ''
  const dispatch = useDispatch()
  const campaignConfig = useSelector(state =>
    selectCampaignConfig(state, Number(id))
  )

  useEffect(() => {
    function showError(error: string | null) {
      dispatch(
        alert({
          key: 'danger',
          message: I18n.t('thanx_campaigns.report.errors.load_campaign'),
          error,
        })
      )
      dispatch(push('/thanx_campaigns/reports'))
    }
    async function fetchData() {
      const results = await Promise.all([
        dispatch(getCampaignType(type)),
        dispatch(getCampaign(id)),
      ])

      results.forEach(result => {
        if (result.error) showError(result.error?.response?.data?.error || null)
      })

      setIsLoading(false)
    }

    fetchData()
  }, [dispatch, id, type])

  const [didFetchVariants, isFetchingVariants] =
    useFetchCampaignVariants(campaign)

  const config = configs[campaignType] || []
  return (
    <Switch
      condition={!isLoading && !isFetchingVariants && didFetchVariants}
      fallback={<Loading />}
    >
      <CampaignConfigContext.Provider
        value={{ config: campaignConfig!, setConfig: () => {} }}
      >
        <div className="campaign-report">
          {config.sections?.map((Section, i) => (
            <Section key={i} campaign={campaign} hasDivider={true} />
          ))}
        </div>
      </CampaignConfigContext.Provider>
    </Switch>
  )
}

export default Report
