import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import { buildTranslate } from 'locales'
import { RewardState } from 'models/UserReward'
import React from 'react'
import { merchantTheme } from 'theme'

type PropsT = {
  status: RewardState
}

const t = buildTranslate('users.view.activity.rewards.state')

const StatusBadge: React.FC<PropsT> = ({ status }: PropsT) => {
  const [css] = useStyletron()
  let color, backgroundColor
  let className = ''
  switch (status) {
    case 'active':
      color = merchantTheme.colors.nephritis50
      backgroundColor = merchantTheme.colors.nephritis10
      break
    case 'available':
    case 'in_progress':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.grey20
      break
    case 'retired':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.white
      className = 'border grey-20-border rounded'
      break
    case 'fraudulent':
      color = merchantTheme.colors.cayenne50
      backgroundColor = merchantTheme.colors.cayenne10
      className = 'border grey-20-border rounded'
      break
    case 'used':
      color = merchantTheme.colors.belizeHole50
      backgroundColor = merchantTheme.colors.belizeHole10
      break
    default:
      break
  }

  return (
    <Badge
      value={t(status)}
      className={`ml-xs ${className} ${css({
        backgroundColor,
        color,
      })}`}
    />
  )
}

export default StatusBadge
