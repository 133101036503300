import React from 'react'

const Sms: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="title">sms</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM8.00003 15.5C8.00003 11.9375 11.5625 9 16 9C20.4063 9 24 11.9375 24 15.5C24 19.0938 20.4063 22 16 22C14.9688 22 14 21.8438 13.0938 21.5625C12.1875 22.2188 10.6563 23 8.75003 23C8.43753 23 8.15628 22.8438 8.03128 22.5625C7.93753 22.2812 7.96878 21.9688 8.18753 21.75C8.21878 21.75 9.18753 20.6875 9.62503 19.4688C8.59378 18.375 8.00003 17 8.00003 15.5ZM13.5313 20.125C14.3125 20.375 15.1563 20.5 16 20.5C19.5625 20.5 22.5 18.2812 22.5 15.5C22.5 12.75 19.5625 10.5 16 10.5C12.4063 10.5 9.50003 12.75 9.50003 15.5C9.50003 16.8438 10.1563 17.8438 10.7188 18.4375L11.375 19.125L11.0313 20C10.875 20.4375 10.6563 20.875 10.4063 21.25C11.1563 21 11.7813 20.6562 12.2188 20.3438L12.8125 19.9062L13.5313 20.125Z"
      fill="#36424E"
    />
  </svg>
)

export default Sms
