import flatMap from 'lodash/flatMap'
import { RewardStatsMetric } from 'models/MerchantMetric'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import MetricSection from '../../components/MetricSection'
import SectionHeader from '../../components/SectionHeader'
import {
  Customers,
  EarnedLoyaltyRewards,
  OutstandingRewards,
  RedeemedLoyaltyRewards,
  Redemption,
  RewardsPerCustomer,
} from './metrics'

type Props = {
  rewardStats?: RewardStatsMetric
}

const Rewards: React.FC<Props> = ({ rewardStats }) => {
  const {
    earned_loyalty_rewards,
    redeemed_loyalty_rewards,
    loyalty_rewards_per_user_counts,
    outstanding_rewards_count,
    outstanding_loyalty_rewards_count,
    reward_liability_amount,
    average_discount_amount,
    average_redemption_days,
  } = rewardStats?.data || {}

  const earnedLoyaltyRewards = earned_loyalty_rewards && (
    <EarnedLoyaltyRewards data={earned_loyalty_rewards} />
  )

  const redeemedLoyaltyRewards = redeemed_loyalty_rewards && (
    <RedeemedLoyaltyRewards data={redeemed_loyalty_rewards} />
  )

  const rewardsPerCustomer = loyalty_rewards_per_user_counts && (
    <RewardsPerCustomer data={loyalty_rewards_per_user_counts} />
  )

  const outstandingMetrics = (outstanding_rewards_count ||
    outstanding_loyalty_rewards_count) &&
    reward_liability_amount && (
      <OutstandingRewards
        allCount={outstanding_rewards_count}
        loyaltyCount={outstanding_loyalty_rewards_count}
        liabilityAmount={reward_liability_amount}
      />
    )

  const redemption = average_discount_amount && average_redemption_days && (
    <Redemption
      averageDiscountAmount={average_discount_amount}
      averageRedemptionDays={average_redemption_days}
    />
  )

  const customers = earned_loyalty_rewards && redeemed_loyalty_rewards && (
    <Customers
      earnedData={earned_loyalty_rewards}
      redeemedData={redeemed_loyalty_rewards}
    />
  )

  const sections = () => {
    let sections: React.ReactNode[] = []

    if (earnedLoyaltyRewards || redeemedLoyaltyRewards)
      sections.push(
        <MetricSection
          key={0}
          leftMetric={earnedLoyaltyRewards}
          rightMetric={redeemedLoyaltyRewards}
        />
      )

    if (customers)
      sections.push(<MetricSection key={1} leftMetric={customers} />)

    if (rewardsPerCustomer)
      sections.push(<MetricSection key={2} leftMetric={rewardsPerCustomer} />)

    if (outstandingMetrics || redemption)
      sections.push(
        <MetricSection
          key={3}
          leftMetric={outstandingMetrics}
          rightMetric={redemption}
        />
      )

    return flatMap(sections, (value, index, array) =>
      array.length - 1 !== index
        ? [value, <hr key={`divider-${index}`} className="section-divider" />]
        : value
    )
  }

  return (
    <div className="report-container">
      <SectionHeader
        title={I18n.t('reports.activity.sections.rewards.title')}
      />
      {sections()}
    </div>
  )
}

export default Rewards
