import React from 'react'
import styles from './CampaignSection.module.scss'

type Props = {
  title: React.ReactNode
  byline?: React.ReactNode
  badge?: React.ReactNode
  isDisabled?: boolean
  onClick?: () => void
}

const CampaignSectionCard: React.FC<Props> = props => {
  const { title, byline, badge, isDisabled = false, onClick } = props

  function handleClick() {
    if (isDisabled) return
    onClick && onClick()
  }

  return (
    <div className="full-height" onClick={handleClick}>
      <div
        className={`
          ${styles.campaignCard} ${isDisabled ? styles.disabled : ''}
          full-height border-radius-5 z1up d-flex flex-column p-l
        `}
      >
        <div className="mb-s">{badge}</div>
        <div className="flex-grow-1 d-flex flex-column justify-content-between">
          <p className="deprecated__body-text-3 line-height-30 extra-bold">
            {title}
          </p>
          <div className="d-flex justify-content-between align-items-center">
            {byline || <div />}
            <i
              className={`${styles.arrowIcon} fa fa-2x fa-arrow-circle-right`}
            />
          </div>
        </div>
      </div>
      <div className="border-radius-10-bottom padding-top-tiny grey-20-bkg relative nudge-up-2 z0up width-95 center-block flex-grow-0" />
    </div>
  )
}

export default CampaignSectionCard
