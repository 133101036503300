import {
  initializeCampaignVariant,
  updateCampaignVariant,
} from 'actions/campaignVariants'
import noSmsIcon from 'assets/images/campaign-center/builder/img_noSMS.svg'
import smsIcon from 'assets/images/campaign-center/builder/img_sms.svg'
import EmptyContentSection from 'components/review/EmptyContentSection'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'react-router-redux'
import SmsPreview from 'scenes/CampaignCenter/Builder/components/Previews/Sms'
import Channel from './Channel'
import Edit from './Options/Edit'
import Remove from './Options/Remove'
import RemoveModal from './RemoveModal'

const t = buildTranslate('thanx_campaigns.builder.steps.setup.messaging.sms')

type PropsT = {
  campaign: Campaign
  config: VariantOrCampaign
}

const SmsChannel: React.FC<PropsT> = ({ campaign, config }) => {
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const dispatch = useDispatch()

  const isControl = config.internal_type === 'control'

  async function handleAdd() {
    setIsAdding(true)
    await dispatch(
      initializeCampaignVariant(campaign.id, config.id, { channel: 'sms' })
    )
    setIsAdding(false)

    dispatch(push(`/thanx_campaigns/${campaign.id}/sms/${config.id}`))
  }

  async function handleEdit() {
    dispatch(push(`/thanx_campaigns/${campaign.id}/sms/${config.id}`))
  }

  async function handleRemovePrompt() {
    setRemoveModalOpen(true)
  }

  async function handleRemove() {
    setIsRemoving(true)
    await dispatch(
      updateCampaignVariant(campaign.id, config.id, {
        ...config,
        notify_sms: false,
      })
    )
    setIsRemoving(false)
    setRemoveModalOpen(false)
  }

  if (!config.notify_sms) {
    return (
      <EmptyContentSection
        icon={noSmsIcon}
        isEditable={!isControl && !!config.redeem}
        isAdding={isAdding}
        onAdd={handleAdd}
        addText={t('add')}
        text={
          isControl || config.redeem ? t('missing') : t('missing_no_redeem')
        }
      />
    )
  }

  return (
    <>
      <Channel
        title={t('title')}
        icon={smsIcon}
        actions={
          <div className="d-flex">
            <Remove
              title={t('remove_modal.title')}
              onRemove={handleRemovePrompt}
            />
            <Edit title={t('edit')} onEdit={handleEdit} />
          </div>
        }
      >
        <SmsPreview config={config} />
      </Channel>
      <RemoveModal
        onRemove={handleRemove}
        isRemoving={isRemoving}
        campaign={campaign}
        variant={config}
        channel="sms"
        isOpen={removeModalOpen}
        onClose={() => setRemoveModalOpen(false)}
      />
    </>
  )
}

export default SmsChannel
