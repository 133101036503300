import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import MtmEffectivenessBanner from 'components/banners/MtmEffectiveness'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { retentionRateUrl } from 'utilities/urlUtils'
import NotReady from '../components/NotReady'
import { daysUntilReportAccess } from '../helpers'
import Content from './Content'
import { AVAILABLE_IN_DAYS } from './helpers'

const t = buildTranslate('retention_rate')

const RetentionRateReport: React.FC = () => {
  const [css] = useStyletron()
  const merchant = useCurrentMerchant()

  const remainingDays = daysUntilReportAccess(merchant, AVAILABLE_IN_DAYS)
  const showEmptyState = remainingDays === null || remainingDays > 0

  return (
    <ProtectedPageHeader
      title={t('title')}
      resource="OperationalReports"
      description={t('subtitle')}
      learnMoreUrl={retentionRateUrl}
    >
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div
        className={`grey-05-bkg border-top-1 pb-xxl grey-20-border ${css({
          minHeight: `calc(100% - 50px)`,
        })}`}
      >
        <Container>
          <Switch
            condition={!showEmptyState}
            fallback={
              <NotReady
                className="mt-xl"
                title={t('retention_rate')}
                remainingDays={remainingDays}
                minimumDaysAfterLaunch={AVAILABLE_IN_DAYS}
              />
            }
          >
            <Content />
            <MtmEffectivenessBanner className="mt-xxxl" />
          </Switch>
        </Container>
      </div>
    </ProtectedPageHeader>
  )
}

export default RetentionRateReport
