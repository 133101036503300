import { Text } from '@thanx/uikit/text'
import React from 'react'

type Props = {
  items: string[]
  prefix?: string
}
function List({ items, prefix }: Props) {
  return (
    <>
      {items.map((value: string, index: number) => (
        <Text.BodyText3 tag="p" key={`${prefix}-${index}`}>
          {prefix}
          {index + 1} <span dangerouslySetInnerHTML={{ __html: value }} />
        </Text.BodyText3>
      ))}
    </>
  )
}

export default List
