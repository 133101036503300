import notEnabledImage from 'assets/images/cms/ordering-not-enabled.png'
import { buildTranslate } from 'locales'
import React from 'react'
import CmsPage from 'scenes/Cms/components/CmsPage'

const t = buildTranslate('cms.settings.ordering_configuration.not_enabled_page')

const NotEnabledPage: React.FC = () => {
  return (
    <CmsPage
      title={t('title')}
      description={t('description')}
      contactButtonTitle={t('contact_our_team')}
    >
      <img
        className="max-width-100"
        src={notEnabledImage}
        alt={t('image_alt')}
      />
      <p className="mt-xs mb-l text-right font-size-12 grey-70">
        {t('image_alt')}
      </p>
    </CmsPage>
  )
}

export default NotEnabledPage
