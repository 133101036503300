import appearanceHowTo from 'assets/images/redeem-manager/thumbnail_appearance.png'
import hiddenMenuHowTo from 'assets/images/redeem-manager/thumbnail_hidden_menu.png'
import instoreHowTo from 'assets/images/redeem-manager/thumbnail_instore.png'
import { buildTranslate } from 'locales'
import { RedemptionProvider } from 'models/RedemptionProvider'
import {
  bonusPointsHelpUrl,
  configureCouponCodesUrl,
  configureRedemptionMethodUrl,
  configureToastLoyaltyIntegrationUrl,
  customImageManualUrl,
  customImageUrl,
  goldenTicketHelpUrl,
  hiddenMenuHelpUrl,
  hiddenMenuOloHelpUrl,
  restrictionsHelpUrl,
} from 'utilities/urlUtils'

const t = buildTranslate('redeem_manager.builder.steps.configuration')

export const getHelp = type => {
  switch (type) {
    case 'hidden_menu': {
      return [
        {
          title: t('help.hidden_menu_help_1'),
          url: hiddenMenuHelpUrl,
        },
        {
          title: t('help.hidden_menu_help_2'),
          url: hiddenMenuOloHelpUrl,
        },
        {
          title: t('help.restrictions_help'),
          url: restrictionsHelpUrl,
        },
        {
          title: t('help.custom_image'),
          url: customImageUrl,
        },
        {
          title: t('help.custom_manual_image'),
          url: customImageManualUrl,
        },
      ]
    }
    case 'golden_ticket': {
      return [
        {
          title: t('help.golden_ticket_help_1'),
          url: goldenTicketHelpUrl,
        },
        {
          title: t('help.restrictions_help'),
          url: restrictionsHelpUrl,
        },
        {
          title: t('help.custom_image'),
          url: customImageUrl,
        },
        {
          title: t('help.custom_manual_image'),
          url: customImageManualUrl,
        },
      ]
    }
    case 'bonus_points': {
      return [
        {
          title: t('help.get_bonus_points'),
          url: bonusPointsHelpUrl,
        },
        {
          title: t('help.restrictions_add_help'),
          url: restrictionsHelpUrl,
        },
      ]
    }
    default:
      return [
        {
          title: t('help.restrictions_help'),
          url: restrictionsHelpUrl,
        },
        {
          title: t('help.custom_image'),
          url: customImageUrl,
        },
      ]
  }
}

export const getHowTo = {
  appearance: appearanceHowTo,
  instore: instoreHowTo,
  hiddenMenu: hiddenMenuHowTo,
}

export const instoreRedemptionInfoSections = [
  {
    content: t('instore_redemption_card.info_desc'),
  },
  {
    title: t('instore_redemption_card.type.manager_comp'),
    content: t('instore_redemption_card.manager_comp_desc'),
  },
  {
    title: t('instore_redemption_card.type.coupon_codes_enforced_by_pos'),
    content: t('instore_redemption_card.coupon_codes_enforced_by_pos_desc_1'),
  },
  {
    title: '',
    content: t('instore_redemption_card.coupon_codes_enforced_by_pos_desc_2'),
  },
  {
    title: t('instore_redemption_card.type.automatic_cash_back'),
    content: t('instore_redemption_card.automatic_cash_back_desc'),
  },
]

export const getConfigureProviderHelpArticles = (
  redemptionProvider: RedemptionProvider
) => {
  const helpArticles = [
    {
      title: t('configure_provider.help.redemption_method'),
      url: configureRedemptionMethodUrl,
    },
  ]

  if (redemptionProvider.integration_type === 'indirect') {
    helpArticles.push({
      title: t('configure_provider.help.coupon_codes'),
      url: configureCouponCodesUrl,
    })
  } else if (redemptionProvider.value === 'toast') {
    helpArticles.push({
      title: t('configure_provider.help.toast_integration'),
      url: configureToastLoyaltyIntegrationUrl,
    })
  }

  return helpArticles
}
