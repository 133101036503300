import { Text } from '@thanx/uikit/text'
import EditNavbar from 'components/Navbar/EditNavbar'
import { buildTranslate } from 'locales'
import { Fields as Campaign, MinimalVariant } from 'models/Campaign'
import React from 'react'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'

import ContinueButton from '../ContinueButton'

type Props = {
  campaign: Campaign
  infoModal?: React.ReactNode
  children?: React.ReactNode
  triggerSave: () => Promise<void>
  setCurrentStep?: ((step: string) => void) | undefined
  variants: MinimalVariant[]
}

const t = buildTranslate(`thanx_campaigns.builder.nav`)

const Configuration: React.FC<Props> = props => {
  const { campaign, infoModal, setCurrentStep, variants } = props
  const { config } = useCampaignConfig()
  const variantNames = variants.map((variant, _i) => ({
    id: variant.id,
    name: variant.meta?.name,
  }))
  let name: string
  if (variants.length > 1 && config.campaign_config_type === 'variant') {
    name = t('variant', {
      name: variantNames.find(v => v.id === config.id)?.name || '',
    })
  } else {
    name = campaign.name
  }
  const onClick = setCurrentStep ? () => setCurrentStep('review') : () => null
  return (
    <EditNavbar
      title={
        <>
          <Text.SmallCaps3 tag="div" className="mb-xs" color="white">
            {t('config')}
          </Text.SmallCaps3>
          <Text.Header4 tag="div" bold color="white">
            {name}
          </Text.Header4>
        </>
      }
    >
      {infoModal}
      <ContinueButton
        buttonLocale={`thanx_campaigns.builder.nav.v3.save_button`}
        onClick={onClick}
      />
    </EditNavbar>
  )
}

export default Configuration
