import { Text } from '@thanx/uikit/text'
import Info from 'components/Info'
import ProductList from 'components/ProductList'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import { CampaignConfig } from 'models/Campaign'
import React from 'react'

type PropsT = {
  config: CampaignConfig
  categoryName?: String
}

const t = buildTranslate('redeem_manager.detail.cards.online_redemption')

const Online: React.FC<PropsT> = (props: PropsT) => {
  const { config, categoryName } = props
  const {
    redeem_discounted_product_ids = [],
    redeem_venue,
    redeem_type,
    redeem_required_product_ids = [],
  } = config
  const merchant = useCurrentMerchant()
  const notRedeemable = redeem_venue === 'instore'
  const notAvailable = !merchant?.ordering_enabled

  let content

  if (redeem_type === 'points/static') {
    content = (
      <Text.BodyText4 color="grey70" tag="div">
        {t('will_apply_instantly')}
      </Text.BodyText4>
    )
  } else if (notRedeemable || notAvailable) {
    content = notRedeemable ? (
      t('not_redeemable')
    ) : (
      <span>
        {t('not_available')}
        <a href="mailto:merchant.support@thanx.com">{t('contact_us')}</a>
      </span>
    )
  } else if (redeem_type === 'experience/hidden_menu') {
    content = (
      <Info
        label={t('hidden_menu.title')}
        value={
          <span className="grey-70">
            {categoryName && (
              <div className="text-uppercase font-size-16">{categoryName}</div>
            )}
            <div className="font-size-14">{t('hidden_menu.info')}</div>
          </span>
        }
      />
    )
  } else if (
    !redeem_discounted_product_ids.length &&
    !redeem_required_product_ids.length
  ) {
    content = t('able_to_redeem')
  } else {
    content = (
      <>
        {redeem_required_product_ids.length > 0 && (
          <Info
            className="mb-m"
            label={t('items_required')}
            value={<ProductList productIds={redeem_required_product_ids} />}
          />
        )}
        {redeem_discounted_product_ids.length > 0 && (
          <>
            <Info
              label={t('items_discounted')}
              value={<ProductList productIds={redeem_discounted_product_ids} />}
            />
            <p className="grey-70 font-size-14">{t('will_apply_lowest')}</p>
          </>
        )}
      </>
    )
  }

  return <div className="mb-l">{content}</div>
}

export default Online
