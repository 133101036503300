import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

type Props = {
  title: string
  description: string
  image: string
  alt: string
  className: string
}

const HeroBanner: React.FC<Props> = props => {
  const { title, description, image, alt, className } = props
  return (
    <div className={`padding-huge landing-hero-banner ${className}`}>
      <div className="container relative">
        <Row>
          <Col md={6}>
            <div className="header-text relative z1up">
              <p className="small-caps-4 white margin-bottom-huge margin-left-tiny margin-top-medium">
                {I18n.t('thanx_campaigns.title')}
              </p>
              <h1 className="deprecated__h1 white margin-bottom-huge">
                {title}
              </h1>
              <p className="white body-text-1 margin-bottom-extra-large">
                {description}
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="header-image absolute right-0">
              <img src={image} alt={alt} />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default HeroBanner
