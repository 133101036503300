import { Text } from '@thanx/uikit/text'
import { DateRangeFilterContext } from 'components/DateFilter/DateFilterContext'
import { buildTranslate } from 'locales'
import {
  ActivityCountersMetric,
  ActivityTimeSeriesMetric,
  ShortType,
} from 'models/PointsExperienceMetric'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { getOutstandingLiability } from 'scenes/Points/Landing/Activity/utilities'
import { selectPointsExperienceMetricByType } from 'selectors/pointsExperienceMetric'
import Chart from './Chart'
import Counters from './Counters'
import Table from './Table'

const t = buildTranslate('points.activity.outstanding_points')

const OutstandingPoints: React.FC = () => {
  const { filter } = useContext(DateRangeFilterContext)
  const [showTable, setShowTable] = useState(false)

  const counterCustomState = useSelector(state =>
    selectPointsExperienceMetricByType(
      state,
      ShortType.ACTIVITY_COUNTERS_CUSTOM
    )
  ) as ActivityCountersMetric

  const counterAllTimeState = useSelector(state =>
    selectPointsExperienceMetricByType(
      state,
      ShortType.ACTIVITY_COUNTERS_ALL_TIME
    )
  ) as ActivityCountersMetric

  const timeSeriesAllTimeState = useSelector(state =>
    selectPointsExperienceMetricByType(
      state,
      ShortType.ACTIVITY_TIME_SERIES_ALL_TIME
    )
  ) as ActivityTimeSeriesMetric

  if (!counterCustomState || !timeSeriesAllTimeState || !counterAllTimeState) {
    return null
  }

  const {
    isLoading,
    isErrored,
    timeZone,
    seriesData,
    granularity,
    outstandingAtStart,
    outstandingAtEnd,
    totalOutstandingAmount,
    changeInOutstandingAmount,
  } = getOutstandingLiability({
    counterCustomState,
    counterAllTimeState,
    timeSeriesAllTimeState,
    filter,
  })

  return (
    <section className="mt-xxl">
      <h3 className="mt-0 mb-xs mr-2 inline-block">{t('title')}</h3>
      <div className="mb-m">
        <Text.BodyText3 color="grey70">{t('description')}</Text.BodyText3>
      </div>
      <Counters
        totalOutstandingPoints={totalOutstandingAmount}
        changeInOutstandingPoints={changeInOutstandingAmount}
        isErrored={isErrored}
        isLoading={isLoading}
        showTable={showTable}
        onToggleTable={() => setShowTable(show => !show)}
      />
      {showTable && (
        <Table
          start={outstandingAtStart}
          end={outstandingAtEnd}
          changeInOutstandingPoints={changeInOutstandingAmount}
          isErrored={isErrored}
          isLoading={isLoading}
        />
      )}
      <Chart
        granularity={granularity}
        timeZone={timeZone}
        seriesData={seriesData}
        isErrored={isErrored}
        isLoading={isLoading}
      />
    </section>
  )
}

export default OutstandingPoints
