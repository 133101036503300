import { Switch } from '@thanx/uikit/switch'
import NoRewardIcon from 'assets/images/campaign-center/builder/img-no_reward.svg'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Row } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import CustomAutomatedAudience from 'scenes/CampaignCenter/Builder/Steps/CustomAutomated/Review/Audience'
import IncentiveContent from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Content/Incentive/IncentiveContent'
import MessageContent from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Content/Messaging/MessageContent'
import ReputationAudience from 'scenes/CampaignCenter/Report/sections/Reputation/Audience'
import Audience from './components/Audience'
import SendAt from './components/SendAt'

const t = buildTranslate('thanx_campaigns.review.incentive')

type Props = {
  campaign: Campaign
  hasDivider?: boolean
}

const Details: React.FC<Props> = ({ campaign, hasDivider = false }) => {
  function audience() {
    if (campaign.type === 'reputation') {
      return <ReputationAudience />
    }

    if (campaign.type === 'custom_automated') {
      return <CustomAutomatedAudience campaign={campaign} />
    }

    return <Audience campaign={campaign} />
  }

  function sendAt() {
    if (!campaign.start_at) return null

    return (
      <div>
        <hr className="margin-top-huge margin-bottom-huge" />
        <SendAt campaign={campaign} />
      </div>
    )
  }

  function incentive() {
    if (!campaign.redeem)
      return (
        <>
          <img src={NoRewardIcon} alt="no-reward" className="mr-xs" />
          {t('missing')}
        </>
      )
    return <IncentiveContent campaign={campaign} />
  }

  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <div className="details-section">
          <p className="deprecated__small-caps-3 margin-bottom-huge">
            <Translate value="thanx_campaigns.report.details.title" />
          </p>
          <div>
            {audience()}
            <hr className="margin-top-huge margin-bottom-huge" />
            <Row className="section">
              <div className="col-header col-sm-2">
                <Translate value="thanx_campaigns.review.incentive.title" />
              </div>
              <div className="col-sm-8">{incentive()}</div>
            </Row>
            <hr className="margin-top-huge margin-bottom-huge" />
            <Row className="section">
              <div className="col-header col-sm-2">
                <Translate value="thanx_campaigns.review.message.title" />
              </div>
              <div className="col-sm-8">
                <MessageContent campaign={campaign} />
              </div>
            </Row>
            {sendAt()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Details
