import { FormsyInput } from '@thanx/uikit/input'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectExperience } from 'selectors/pointsExperience'
import Card from '../Card'
import NextButton from '../NextButton'

type Props = {
  numberOfPoints?: number
  isCurrent: boolean
  isDraft?: boolean
  onNext: () => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.bonus_points_card'
)

const BounsPointsCard: React.FC<Props> = ({
  numberOfPoints,
  isCurrent,
  isDraft = false,
  onNext,
}) => {
  const pointsExperience: PointsExperience | null =
    useSelector(selectExperience)

  const canProceed = !!numberOfPoints

  return (
    <Card title={t('title')} isOpen>
      <FormsyInput
        // @ts-ignore
        label={
          <Text.BodyText4 bold className="mr-xs mb-xs" tag="div">
            {t('label', {
              currencyName: pointsExperience?.currency_name_plural,
            })}
          </Text.BodyText4>
        }
        name="numberOfPoints"
        startEnhancer={
          <img
            height="16px"
            src={pointsExperience?.currency_primary_icon.default}
            alt={pointsExperience?.currency_name}
          />
        }
        min={1}
        value={numberOfPoints}
        validations={{
          isRequired: true,
        }}
        disabled={!isDraft}
      />
      <Switch condition={isCurrent}>
        <NextButton disabled={!canProceed} onClick={onNext} />
      </Switch>
    </Card>
  )
}

export default BounsPointsCard
