import { campaignsApi } from 'api/campaigns'
import { FeedbackPayload } from 'api/feedbacks'
import { feedbacksApi } from 'api/feedbacks'
import { purchasesApi } from 'api/purchases'
import { GrantParams, usersApi } from 'api/users'
import { isError } from 'api/utils'
import Modal from 'components/ModalContainer'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import { useState } from 'react'
import { merchantTheme } from 'theme'
import { useDisplayError } from 'utilities/displayError'
import { getFeedbackStyles } from '../helpers'
import AlertBanner from './AlertBanner'
import Header from './Header'
import Message from './Message'
import OrderDetails from './OrderDetails'
import Respond from './Respond'

const t = buildTranslate('feedbacks.responses')

type Props = {
  responseId: number
  purchaseId: number | null
  merchantName?: string
  visible: boolean
  onClose: VoidFunction
}

export type UpdateResponsePayload = {
  updatedPayload: FeedbackPayload
  rewardPayload?: GrantParams
}

const ResponseModal = (props: Props) => {
  const [isUpdating, setIsUpdating] = useState(false)
  const { responseId, purchaseId, merchantName, visible, onClose } = props
  const {
    data: responseData,
    isFetching: isFetchingResponse,
    isLoading: isLoadingResponse,
  } = feedbacksApi.useGetFeedbackQuery(responseId)
  const response = responseData?.feedback
  const {
    score,
    user,
    priority,
    comment,
    response: responseContent,
    responder,
    granted_reward_description,
  } = response || {}
  const {
    uid: userUid,
    first_name: userFirstName,
    last_name: userLastName,
    email: userEmail,
  } = user || {}
  const userName = `${userFirstName} ${userLastName}`
  const { first_name: responderFirstName, last_name: responderLastName } =
    responder || {}
  const responderName = `${responderFirstName} ${responderLastName}`

  const { data: purchaseData, isFetching: isFetchingPurchase } = purchaseId
    ? purchasesApi.useGetPurchaseQuery(purchaseId)
    : {
        data: null,
        isFetching: false,
      }
  const purchase = purchaseData?.purchase

  const {
    data: grantableCampaingsData,
    isFetching: isFetchingGrantableCampaigns,
  } = campaignsApi.useGetGrantableCampaignsQuery()
  const grantableCampaigns = grantableCampaingsData?.campaigns || []

  const [updateFeedback, { isLoading: updateFeedbackIsLoading }] =
    feedbacksApi.useUpdateFeedbackMutation()
  const [grantReward, { isLoading: grantRewardIsLoading }] =
    usersApi.useGrantRewardMutation()

  const isLoading =
    isUpdating ||
    isFetchingResponse ||
    isLoadingResponse ||
    isFetchingPurchase ||
    isFetchingGrantableCampaigns ||
    updateFeedbackIsLoading ||
    grantRewardIsLoading

  const feedbackStyles = getFeedbackStyles(score || 0)

  const displayError = useDisplayError()
  const updateResponse = async (
    updateResponsePayload: UpdateResponsePayload
  ): Promise<Boolean> => {
    const { updatedPayload, rewardPayload } = updateResponsePayload

    if (rewardPayload) {
      const grantRewardResult = await grantReward(rewardPayload)
      if (isError(grantRewardResult)) {
        setIsUpdating(false)
        displayError(grantRewardResult, t('errors.grant'))
        return !isError(grantRewardResult)
      }
    }

    const updateFeedbackResult = await updateFeedback(updatedPayload)
    setIsUpdating(false)
    displayError(updateFeedbackResult, t('errors.update'))
    return !isError(updateFeedbackResult)
  }

  return (
    <Modal
      isOpen={visible}
      onClose={onClose}
      hideCancel
      showBottomProceed={false}
      showTopProceed={false}
      zeroSpacing
      zIndex={merchantTheme.zIndex.modal}
    >
      <div className="d-flex flex-column align-items-left grey-05-bkg">
        <Spinner
          className="text-center my-xl"
          isLoading={isLoading}
          size="2x"
        />

        {!isLoading && (
          <>
            <Header
              userUid={userUid}
              userName={userName}
              feedbackIcon={feedbackStyles.icon}
              feedbackIconColor={feedbackStyles.strongColor}
              feedbackText={feedbackStyles.text}
            />
            {priority === 'critical' && (
              <AlertBanner
                merchantName={merchantName}
                textColor={feedbackStyles.strongColor}
              />
            )}
            <OrderDetails
              score={score}
              scoreBackgroundColor={feedbackStyles.strongColor}
              purchase={purchase}
              location={purchase?.location}
            />
            <Message message={comment} />
            <Respond
              responseId={responseId}
              responseContent={responseContent}
              responderName={responderName}
              grantedRewardDescription={granted_reward_description}
              userUid={userUid}
              userEmail={userEmail}
              grantableCampaigns={grantableCampaigns}
              updateResponse={updateResponse}
            />
          </>
        )}
      </div>
    </Modal>
  )
}

export default ResponseModal
