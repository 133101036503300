import { Fields as MerchantUserFields } from 'models/MerchantUser'
import { Fields as UserFields } from 'models/User'
import { api } from './index'

export type Feedback = {
  id: number
  user_id: number
  user: UserFields
  score: number
  comment: string
  status: 'positive' | 'negative' | 'neutral'
  priority: 'standard' | 'critical' | 'important'
  created_at: string
  purchase_id: number | null
  location_id?: number | null
  viewed_at?: string | null
  response?: string | null
  responder?: MerchantUserFields | null
  granted_reward_description?: string | null
  location_name?: string | null
}

export type FeedbackPayload = Partial<Feedback> & { send_email?: boolean }

export type GetFeedbacksParams = {
  location_id?: number
  critical?: boolean
  unread?: boolean
  has_review?: boolean
  page?: number
  per_page?: number
}

export type Pagination = {
  total_pages: number
  per_page: number
  current_page: number
  total_count: number
}

export const feedbacksApi = api.injectEndpoints({
  endpoints: builder => ({
    getFeedbacks: builder.query<
      {
        feedbacks: Feedback[]
        pagination: Pagination
      },
      GetFeedbacksParams
    >({
      query: params => ({
        url: 'feedbacks',
        params,
      }),
      providesTags: ['Feedback'],
    }),
    getFeedback: builder.query<{ feedback: Feedback }, number>({
      query: feedbackId => `feedbacks/${feedbackId}`,
      providesTags: ['Feedback'],
    }),
    updateFeedback: builder.mutation<Feedback, FeedbackPayload>({
      query: payload => ({
        url: `feedbacks/${payload.id}`,
        method: 'PATCH',
        body: {
          feedback: payload,
        },
      }),
      invalidatesTags: ['Feedback'],
    }),
  }),
})
