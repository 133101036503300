import {
  CREATE_FILE_UPLOAD_SUCCESS,
  GET_FILE_UPLOADS_SUCCESS,
  GET_FILE_UPLOAD_SUCCESS,
  PROCESS_FILE_UPLOAD_SUCCESS,
  ResolvedAction,
} from 'actions/fileUpload'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'

export enum State {
  CREATED = 'created',
  PROCESSING = 'processing',
  FINALIZED = 'finalized',
  ERRORED = 'errored',
}

export enum SourceType {
  COUPON_POOL = 'coupon_pool',
  MERCHANTS = 'merchants',
}

interface BaseFields {
  id: number
  source_type: SourceType
  source_id: number
  record_type: string | null
  record_id: number | null

  state: State
  progress: number
  error_message?: string
  upload_url?: string
  started_processing_at?: string
  errored_at?: string
  finalized_at?: string
  settings: Record<string, any>
}

export interface CouponPoolFields extends BaseFields {
  source_type: SourceType.COUPON_POOL
  settings: {
    row_count?: number
    column_index: number
    headers: boolean
  }
}

export interface MerchantsFields extends BaseFields {
  source_type: SourceType.MERCHANTS
  settings: {
    relation_name: string
  }
}

export interface ImportFields extends BaseFields {
  source_type: SourceType.MERCHANTS
  settings: {
    sheet_id: string
    embed_id: string
  }
}

export type Fields = CouponPoolFields | MerchantsFields | ImportFields

// @ts-ignore
export default class FileUpload extends Model<typeof FileUpload, Fields> {
  static modelName: string = 'FileUpload'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    FileUpload: ModelType<FileUpload>
  ): void {
    switch (action.type) {
      case GET_FILE_UPLOADS_SUCCESS:
        for (const upload of action.payload.data.file_uploads) {
          FileUpload.upsert(upload)
        }
        break
      case GET_FILE_UPLOAD_SUCCESS:
      case CREATE_FILE_UPLOAD_SUCCESS:
      case PROCESS_FILE_UPLOAD_SUCCESS:
        FileUpload.upsert(action.payload.data.file_upload)
        break
      case RESET_DB:
        FileUpload.all().delete()
        break
    }
  }
}
