import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { authorize, PASSWORD_AUTH_FAIL } from 'actions/auth'
import { alert } from 'actions/flash'
import Input from 'components/Form/Input'
import Form from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React from 'react'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'

const t = buildTranslate('auth')

type FormModel = {
  email: string
  password: string
}
const PasswordLogin: React.FC = () => {
  const dispatch = useDispatch()

  async function submit(model: FormModel) {
    const data = {
      grant_type: 'password',
      client_id: process.env.REACT_APP_API_CLIENT_ID,
      ...model,
    }
    const response = await dispatch(authorize(data))
    if (response.type === PASSWORD_AUTH_FAIL) {
      const isIncorrectAuth = response.error.response?.status === 401
      dispatch(
        alert({
          key: 'danger',
          message: isIncorrectAuth ? t('login.error') : I18n.t('errors.thanx'),
          error: response.error?.message,
          hasReloadButton: !isIncorrectAuth,
        })
      )
    } else {
      dispatch(push('/choose'))
    }
  }

  return (
    <Form onValidSubmit={submit} className="text-left">
      <Helmet>
        <title>{t('login.page_title')}</title>
      </Helmet>
      <Input
        className="mb-m"
        name="email"
        label={t('email')}
        validations="isRequired,isEmail"
        validationErrors={{
          isRequired: I18n.t('validations.is_required'),
          isEmail: I18n.t('validations.is_email'),
        }}
        spellCheck={false}
        inputProps={{ className: 'grey-70-text grey-70-hover grey-70-focus' }}
      />
      <Input
        className="mb-xs"
        name="password"
        label={t('password')}
        validations="isRequired"
        validationErrors={{
          isRequired: I18n.t('validations.is_required'),
        }}
        type="password"
        spellCheck={false}
        inputProps={{ className: 'grey-70-text grey-70-hover grey-70-focus' }}
      />
      <Text.BodyText4 className="text-right" tag="p">
        <Link to="/auth/forgot_password">{t('login.forgot_password')}</Link>
      </Text.BodyText4>
      <Button type="submit" kind={Kind.PRIMARY} className="btn-block mt-m">
        {t('login.submit')}
      </Button>
    </Form>
  )
}

export default PasswordLogin
