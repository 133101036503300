import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'
import { tooltipCurrency } from 'scenes/Reports/helpers'

type PropsT = {
  color: string
  value: number
  name: string
  shouldShow: boolean
}

const TooltipLine: React.FC<PropsT> = ({ color, value, name, shouldShow }) => {
  const [css] = useStyletron()
  if (!shouldShow) return null
  return (
    <>
      <Text.Header4 className="mb-xxs d-flex align-items-center">
        <span
          className={css({
            display: 'inline-block',
            width: '8px',
            height: '8px',
            backgroundColor: color,
            marginRight: '8px',
          })}
        />
        {tooltipCurrency(value)}
      </Text.Header4>
      <Text.BodyText4 className="mb-m" color="grey70">
        {name}
      </Text.BodyText4>
    </>
  )
}

export default TooltipLine
