import React from 'react'

type Props = {
  className?: string
  bullet?: React.ReactNode
  children: React.ReactNode
}

/**
 * A bullet point item with a customizable bullet point. Defaults to a
 * green checkmark.
 */
const BulletPoint: React.FC<Props> = props => {
  const defaultBullet = <span className="fa fa-check spearmint-50" />
  const { className = '', bullet = defaultBullet, children } = props

  return (
    <div
      className={`d-flex align-items-start justify-content-start ${className}`}
    >
      <div className="pr-xs">{bullet}</div>
      <div>{children}</div>
    </div>
  )
}

export default BulletPoint
