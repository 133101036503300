import { Switch } from '@thanx/uikit/switch'
import mobileReviewIcon from 'assets/images/campaign-center/opportunity/icons/mobile_review.png'
import redStarIcon from 'assets/images/campaign-center/opportunity/icons/red_star.png'
import thumbsUpIcon from 'assets/images/campaign-center/opportunity/icons/thumbs_up.png'
import withMerchantMetrics, {
  MetricsProps,
} from 'components/metrics/withMerchantMetrics'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import numeral from 'numeral'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import Header from '../components/Shared/Header'
import HowItWorks from '../components/Shared/HowItWorks'
import Setup from '../components/Shared/Setup'
import Solution from '../components/Shared/Solution'
import SolutionStep from '../components/Shared/SolutionStep'
import UseCase from '../components/Shared/UseCase'

const t = buildTranslate(
  'thanx_campaigns.opportunity.reputation.solution.steps'
)

const Separator: React.FC = () => {
  return (
    <div className="padding-large">
      <hr className="margin-left-huge margin-right-huge" />
    </div>
  )
}

export const Reputation: React.FC<MetricsProps> = props => {
  const {
    handleClick,
    isCampaignTypeLoading,
    merchantMetrics,
    merchantMetricStatuses,
    campaignType,
  } = props
  const promoterCount =
    merchantMetrics?.reviewer_count?.data?.promoter_count || 0
  const isLoading = merchantMetricStatuses?.reviewer_count.isLoading

  return (
    <div>
      <Header campaignType="reputation" />
      <UseCase campaignType="reputation" />
      <Switch
        condition={isLoading || promoterCount > 0}
        fallback={<Separator />}
      >
        <div className="margin-bottom-huge padding-bottom-huge padding-top-huge text-center spearmint-10-bkg">
          <h1 className="deprecated__h1 font-size-130 spearmint-50">
            <Spinner isLoading={isLoading} className="inline-block">
              {numeral(promoterCount).format('0,0')}
            </Spinner>
          </h1>
          <Translate
            tag="h5"
            value="thanx_campaigns.opportunity.reputation.metric"
          />
        </div>
      </Switch>
      <Solution campaignType="reputation">
        <SolutionStep
          icon={thumbsUpIcon}
          iconAlt={t('image_one_alt')}
          text={t('one')}
        />
        <SolutionStep
          icon={mobileReviewIcon}
          iconAlt={t('image_two')}
          text={t('two')}
        />
        <SolutionStep
          icon={redStarIcon}
          iconAlt={t('image_three_alt')}
          text={t('three')}
        />
      </Solution>
      <Separator />
      <HowItWorks campaignType="reputation" />
      <Setup
        type="reputation"
        isLoading={isCampaignTypeLoading}
        handleClick={handleClick}
        campaignType={campaignType}
      />
    </div>
  )
}

export default withMerchantMetrics(['reviewer_count'])(Reputation)
