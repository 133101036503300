import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Spinner from 'components/Spinner'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import Blocks from 'scenes/Cms/components/Blocks'
import CmsPage from 'scenes/Cms/components/CmsPage'
import { FormModel } from '../hooks'
import BlockItem from './BlockItem'
import DraftBanner from './DraftBanner'
import EmptyState from './EmptyState'
import Footer from './Footer'
import FrequencySelectors from './FrequencySelectors'

const t = buildTranslate('cms.signup.onboarding_slideshow.content')

type Props = {
  blockOrder: Array<string>
  setBlockOrder: (v: Array<string>) => void
  onNewBlock: VoidFunction
  slides: FormModel['slides']
  config: FormModel['config']
  sidebar: React.ReactNode
  isDraft: boolean
  isFetching: boolean
}

const OnboardingSlideshowContent: React.FC<Props> = ({
  blockOrder,
  setBlockOrder,
  onNewBlock,
  slides,
  config,
  sidebar,
  isDraft,
  isFetching,
}) => {
  const gifEnabled = useFlag('gif-enabled-mx', false)
  const badges = [
    t('badges.app_only'),
    t('badges.resolution'),
    t('badges.three_mb'),
    t('badges.jpg'),
    t('badges.png'),
    ...(gifEnabled ? [t('badges.gif')] : []),
  ]
  const badgeDescriptions = [
    t('badges.description.png'),
    ...(gifEnabled ? [t('badges.description.gif')] : []),
  ]
  return (
    <>
      <CmsPage
        className="mr-m"
        title={t('subtitle')}
        description={t('description')}
        badges={badges}
        badgeDescriptions={badgeDescriptions}
        sidebar={sidebar}
      >
        <Switch condition={isEmpty(slides)}>
          <EmptyState />
        </Switch>
        <div className={`${isEmpty(slides) ? '' : 'mt-xl'}`}>
          <Switch condition={!isEmpty(slides)}>
            <FrequencySelectors
              frequency={config.settings?.frequency ?? 'multiple'}
            />
            <Text.Header4 tag={'p'} color="grey90" className="mb-m">
              {t('block_item.header')}
            </Text.Header4>
          </Switch>
          <Spinner
            isLoading={isFetching}
            size={'3x'}
            className={'d-flex justify-content-center'}
          >
            <Blocks
              type={'block'}
              order={blockOrder}
              setOrder={setBlockOrder}
              renderItem={(itemId, index) => (
                <Switch condition={!!slides[itemId]}>
                  <BlockItem index={index} id={itemId} data={slides[itemId]} />
                </Switch>
              )}
              onNewBlock={onNewBlock}
              maxBlockCount={10}
            />
          </Spinner>
        </div>

        <Switch condition={!isEmpty(slides)}>
          <Footer />
        </Switch>
        <Switch condition={isDraft}>
          <DraftBanner />
        </Switch>
      </CmsPage>
    </>
  )
}

export default OnboardingSlideshowContent
