import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import compact from 'lodash/compact'
import { Fields } from 'models/User'
import React from 'react'
import { Link } from 'react-router-dom'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'

const t = buildTranslate('users.user_list.table')

type Props = {
  user: Fields
  isLoading: boolean
}

const Row: React.FC<Props> = ({ user, isLoading }) => {
  const name = compact([user.first_name, user.last_name]).join(' ')
  return (
    <tr>
      <BodyCell
        isErrored={false}
        isLoading={isLoading}
        values={[{ value: name }]}
      />
      <BodyCellContainer
        className={'text-right'}
        isErrored={false}
        isLoading={isLoading}
      >
        <Link to={`/users/${user.uid}`}>
          <Text.BodyText4 tag="span" className="ml-xs mb-0" color="primary">
            {t('view_profile')}
          </Text.BodyText4>
        </Link>
      </BodyCellContainer>
    </tr>
  )
}

export default Row
