// @ts-nocheck
import orm from 'models/orm'
import { Fields, ValidationTypes } from 'models/Validation'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectValidations = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.Validation.all().toRefArray()
  }
)

export const selectHeaderValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined => {
    return validations.find(
      validation => validation.id === ValidationTypes.AppHeader
    ) as Fields
  }
)

export const selectOrderingBannerValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined => {
    return validations.find(
      validation => validation.id === ValidationTypes.OrderingBanner
    ) as Fields
  }
)

export const selectLinkValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined => {
    return validations.find(
      validation => validation.id === ValidationTypes.AppLinkUnpressed
    ) as Fields
  }
)

export const selectRedeemAdvertisingValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined => {
    return validations.find(
      validation => validation.id === ValidationTypes.RedeemAdvertising
    ) as Fields
  }
)

export const selectRedeemDetailValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined => {
    return validations.find(
      validation => validation.id === ValidationTypes.RedeemDetail
    ) as Fields
  }
)

export const selectRedeemIndexValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined => {
    return validations.find(
      validation => validation.id === ValidationTypes.RedeemIndex
    ) as Fields
  }
)

export const selectPointsEarnValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined => {
    return validations.find(
      validation => validation.id === ValidationTypes.PointsEarn
    ) as Fields
  }
)

export const selectPointsPrimaryIconValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined =>
    validations.find(v => v.id === ValidationTypes.PointsPrimaryIcon)
)

export const selectPointsSecondaryIconValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined =>
    validations.find(v => v.id === ValidationTypes.PointsSecondaryIcon)
)

export const selectOnboardingSlideValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined =>
    validations.find(v => v.id === ValidationTypes.OnboardingSlide)
)

export const selectAppHomePageValidation = createSelector(
  state => selectValidations(state),
  (validations: Fields[]): Fields | undefined =>
    validations.find(v => v.id === ValidationTypes.AppHomePage)
)
