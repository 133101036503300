import StepStatus from 'components/StepStatus'
import { buildTranslate } from 'locales'
import React from 'react'
import { useStyletron } from 'styletron-react'

import { Switch } from '@thanx/uikit/switch'
import { useFormModel } from './FormModel'
import { Step } from './index'

type Props = {
  step: Step
  templateId: number | null
  hideTypeStep?: boolean
  onStepSelected: (step: Step) => void
  children: React.ReactNode
}

const t = buildTranslate('redeem_manager.builder.top_bar')

const TopBar: React.FC<Props> = ({
  step,
  templateId,
  hideTypeStep,
  onStepSelected,
  children,
}) => {
  const { name, typeItem } = useFormModel().model
  const [css] = useStyletron()

  return (
    <div
      className={`${css({
        marginTop: '-80px',
        zIndex: 99,
      })} fixed py-s spearmint-50-bkg full-width`}
    >
      <div className="d-flex flex-wrap justify-content-between container">
        <div className="d-flex align-items-center">
          <Switch condition={!hideTypeStep}>
            <StepStatus
              name={t('type_tab')}
              isCurrent={step === 'type'}
              isDone={step !== 'type'}
              onClick={() => onStepSelected('type')}
            />
          </Switch>
          <StepStatus
            name={t('name_tab')}
            disabled={typeItem === null}
            isCurrent={step === 'name'}
            isDone={step === 'configuration'}
            onClick={() => onStepSelected('name')}
          />
          <StepStatus
            name={t('configuration_tab')}
            disabled={!typeItem || !templateId || name?.length < 5}
            isCurrent={step === 'configuration'}
            onClick={() => onStepSelected('configuration')}
          />
        </div>
        <div className="ml-auto pl-m">{children}</div>
      </div>
    </div>
  )
}

export default TopBar
