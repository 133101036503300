import { getPointsExperience } from 'actions/pointsExperiences'
import ProtectedRoute from 'components/ProtectedRoute'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { selectPointsExperience } from 'selectors/pointsExperience'
import EditMultiplier from './EditMultiplier'
import NewMultiplier from './NewMultiplier'

const Multipliers: React.FC<
  RouteComponentProps<{
    id: string
  }>
> = ({ match }) => {
  const dispatch = useDispatch()
  const experienceId = match?.params?.id

  const pointsExperience = useSelector(state =>
    selectPointsExperience(state, experienceId)
  )

  useEffect(() => {
    dispatch(getPointsExperience(experienceId))
  }, [dispatch, experienceId])

  if (!pointsExperience) return null

  return (
    <>
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="Points"
        exact
        path="/points/experiences/:experienceId/multipliers/new"
        component={() => <NewMultiplier pointsExperience={pointsExperience} />}
      />
      <ProtectedRoute
        redirect="/thanx_campaigns"
        resource="Points"
        exact
        path="/points/experiences/:experienceId/multipliers/:id/edit"
        component={({ match }) => {
          return (
            <EditMultiplier
              multiplierId={parseInt(match?.params?.id, 10)}
              pointsExperience={pointsExperience}
            />
          )
        }}
      />
    </>
  )
}

export default Multipliers
