import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'
import type { Fields as CampaignType } from '../models/CampaignType'
import orm from '../models/orm'

export const selectCampaignTypes = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): CampaignType[] => {
    return session.CampaignType.all().toRefArray()
  }
)

export const selectCampaignTypesByCategory = createSelector(
  selectCampaignTypes,
  (_state: any, category: string): string => category,
  (campaignTypes, category): CampaignType[] => {
    return campaignTypes.filter(type => {
      return type.category === category
    })
  }
)

export const selectCampaignTypeByType = createSelector(
  selectCampaignTypes,
  (_: any, type: string | undefined): string | undefined => type,
  (campaignTypes, type): CampaignType | undefined => {
    return campaignTypes.find(t => t.type === type)
  }
)

//Deprecated selectors

export const selectCampaignTypes__deprecated = createOrmSelector(
  orm,
  (ormState: any) => ormState,
  (session: any): CampaignType[] => {
    return session.CampaignType.all().toModelArray()
  }
)

export const selectCampaignTypesByCategory__deprecated = createSelector(
  selectCampaignTypes__deprecated,
  (_, category): string => category,
  (campaignTypes, category): CampaignType[] => {
    return campaignTypes.filter(type => type.category === category)
  }
)

export const selectCampaignTypeByType__deprecated = createSelector(
  selectCampaignTypes__deprecated,
  (_, type): string => type,
  (campaignTypes, type): CampaignType | undefined => {
    return campaignTypes.find(t => t.type === type)
  }
)
