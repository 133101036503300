import { Switch } from '@thanx/uikit/switch'
import Input from 'components/Form/Input'
import { buildTranslate } from 'locales'
import { RedeemType } from 'models/RedeemTemplate'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import RestrictionSection from './RestrictionSection'

type Props = {
  value: number
  redeemType: RedeemType
  redeemDiscount: number
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator'
)

const RedeemMinimumInput: React.FC<Props> = ({
  value,
  redeemType,
  redeemDiscount,
}) => {
  const [toggleMinimum, setToggleMinimum] = useState(!!value)
  const isAmountType = redeemType && redeemType.includes('amount')

  return (
    <>
      <RestrictionSection
        title={t('minimum.title')}
        subtitle={t('minimum.subtitle')}
        toggleValue={toggleMinimum}
        onChange={(value: boolean) => setToggleMinimum(value)}
      >
        <Input
          name="redeem_minimum"
          value={value}
          label={t('minimum.title')}
          placeholder={t('minimum.placeholder')}
          addOnBefore="$"
          validations={{
            isRequired: true,
            isNumeric: true,
            greaterThanOrEqual: isAmountType ? redeemDiscount : 0,
          }}
          validationErrors={{
            isRequired: I18n.t('validations.is_required'),
            isNumeric: I18n.t('validations.is_numeric'),
            greaterThanOrEqual: isAmountType
              ? I18n.t('validations.greater_than_or_equal_reward_amount')
              : I18n.t('validations.greater_than', {
                  value: 0,
                }),
          }}
        />
      </RestrictionSection>
      {/* Hack to set the values to null when the section is closed */}
      <Switch condition={!toggleMinimum}>
        <Input type="hidden" name="redeem_minimum" value={null} />
      </Switch>
    </>
  )
}

export default RedeemMinimumInput
