import { Switch } from '@thanx/uikit/switch'
import MultiSelectWithFormsy from 'components/Form/MultiSelectWithFormsy'
import SearchPlaceholder from 'components/Form/MultiSelectWithFormsy/SearchPlaceholder'
import isEmpty from 'lodash/isEmpty'
import type { Fields as Campaign } from 'models/Campaign'
import type { Fields as UserTagValue } from 'models/UserTagValue'
import React from 'react'
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import AudienceForm from 'scenes/CampaignCenter/Builder/components/Forms/Audience'
import Refinements from 'scenes/CampaignCenter/Builder/components/Refinements'
import { userTagValuesToSelectOptions } from 'utilities/userTagUtils'

type Props = {
  campaign: Campaign
  isLoading: boolean
  isLoadingUserTags: boolean
  userTagOptions: UserTagValue[]
  onMenuStateChange: (state: boolean) => void
}

const LocationPlaceholder = props => (
  <SearchPlaceholder
    getText={count =>
      I18n.t(
        'thanx_campaigns.builder.forms.audience.locationRefinement.location.placeholder',
        { count }
      )
    }
    {...props}
  />
)

const Audience: React.FC<Props> = props => {
  const {
    campaign,
    isLoading,
    isLoadingUserTags,
    userTagOptions,
    onMenuStateChange,
  } = props
  const hasTagValues =
    !!campaign.config_tag_values && campaign.config_tag_values.length > 0

  return (
    <div className="container">
      <Row>
        <Col md={6} className="pt-xl pr-xl pl-xl white-bkg">
          <Translate
            value="thanx_campaigns.builder.steps.setup.audience.title"
            tag="p"
            className="grey-90 font-size-32 margin-bottom-micro bold"
          />
          <Translate
            value="thanx_campaigns.builder.steps.setup.audience.subtitle"
            tag="em"
            className="grey-50 font-size-20 margin-bottom-extra-large block"
          />
          <AudienceForm campaign={campaign} isLoading={isLoading} />
          <Switch condition={!isLoadingUserTags && !isEmpty(userTagOptions)}>
            <div className="margin-bottom-extra-large">
              <Refinements isOpen={hasTagValues} isDisabled={hasTagValues}>
                <FormGroup>
                  <ControlLabel className="body-text-4 bold">
                    {I18n.t(
                      'thanx_campaigns.campaigns.message.steps.setup.select.title'
                    )}
                  </ControlLabel>
                  <MultiSelectWithFormsy
                    name="config_tag_values"
                    defaultValue={campaign.config_tag_values}
                    options={userTagValuesToSelectOptions(userTagOptions)}
                    onMenuOpen={() => onMenuStateChange(true)}
                    onMenuClose={() => onMenuStateChange(false)}
                    isDisabled={false}
                    components={{
                      Placeholder: LocationPlaceholder,
                    }}
                  />
                </FormGroup>
              </Refinements>
            </div>
          </Switch>
        </Col>
        <Col md={6} className="grey-10-bkg" style={{ height: '900px' }}>
          <div className="padding-left-large padding-top-huge" />
        </Col>
      </Row>
    </div>
  )
}

export default Audience
