import { RESET_DB } from 'actions/orm'
import {
  GET_TARGET_SEGMENTS_SUCCESS,
  GET_TARGET_SEGMENT_SUCCESS,
  ResolvedAction,
} from 'actions/targetSegments'
import { attr, fk, Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  merchant_id: number
  merchant_user_id: number
  name: string
  description: string
  category: string
  subcategory: string
  type: 'global' | 'merchant'
  count: number
}

// @ts-ignore
export default class TargetSegment extends Model<typeof TargetSegment, Fields> {
  static modelName: string = 'TargetSegment'

  static get fields(): any {
    return {
      id: attr(),
      merchant_id: fk('Merchant', 'targetSegments'),
      merchant_user_id: fk({
        to: 'MerchantUser',
        as: 'merchant_user',
        relatedName: 'targetSegments',
      }),
      name: attr(),
      description: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    TargetSegment: ModelType<TargetSegment>
  ) {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case GET_TARGET_SEGMENTS_SUCCESS:
        action.payload.data.target_segments.forEach(segment => {
          return TargetSegment.upsert(segment)
        })
        break
      case GET_TARGET_SEGMENT_SUCCESS:
        TargetSegment.upsert(action.payload.data.target_segment)
        break
      case RESET_DB:
        TargetSegment.all().delete()
        break
      default:
        break
    }
  }
}
