import { Switch } from '@thanx/uikit/switch'
import CustomFinePrint from 'components/CustomFinePrint'
import Checkbox from 'components/Form/CheckboxWithFormsy'
import Select from 'components/Form/Select'
import Spacer from 'components/Spacer'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import type { Fields as Redeem } from 'models/Redeem'
import React from 'react'
import { Alert, Button, Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useCampaignConfig } from 'scenes/CampaignCenter/Builder/CampaignConfigContext'
import HiddenInputs from 'scenes/CampaignCenter/Builder/components/Incentive/HiddenInputs'
import RestrictionCreator from 'scenes/CampaignCenter/Builder/components/Incentive/RestrictionCreator'
import RestrictionSummary from 'scenes/CampaignCenter/Builder/components/Incentive/RestrictionSummary'
import RewardCreator from 'scenes/CampaignCenter/Builder/components/Incentive/RewardCreator'
import { selectCampaignTypeByType } from 'selectors/campaignType'
import { selectMerchant } from 'selectors/merchant'
import type { SelectedRedeem } from '../Forms/Incentive'

const t = buildTranslate('thanx_campaigns.builder.steps.incentive')

type Props = {
  handleInclusionChange: (value: boolean) => void
  handleSelectChange: (selectedOption: SelectedRedeem) => void
  toggleRewardRestrictions: () => void
  toggleRewardCreator: () => void
  onRestrictionsClose: () => void
  showValidationErrors?: () => void
  selectOptions: SelectedRedeem[]
  selectedRedeem: SelectedRedeem
  showConfirmationAlert: boolean
  showRewardCreator: boolean
  showRewardRestrictions: boolean
  showRewards: boolean
  campaign: Campaign
  isLoadingRedeems: boolean
  canProceed: boolean
  isSaving: boolean
}

const Fields: React.FC<Props> = props => {
  const {
    handleInclusionChange,
    handleSelectChange,
    toggleRewardRestrictions,
    toggleRewardCreator,
    onRestrictionsClose,
    showValidationErrors,
    selectOptions,
    selectedRedeem,
    showConfirmationAlert,
    showRewardCreator,
    showRewardRestrictions,
    showRewards,
    campaign,
    isLoadingRedeems,
    canProceed,
    isSaving,
  } = props

  const campaignType = useSelector(state =>
    selectCampaignTypeByType(state, campaign?.type)
  )
  const merchant = useSelector(state =>
    selectMerchant(state, campaign.merchant_id)
  )
  const redeemRequired = campaignType?.redeem_required
  const { config } = useCampaignConfig()

  function getSelectedRedeem() {
    if (!selectedRedeem || selectedRedeem.value === 0) {
      let redeem_type = 'manual/item'
      if (merchant?.automatic_capable) redeem_type = 'automatic/amount'

      return {
        redeem_type: redeem_type,
        redeem_description: '',
        redeem_discount: 0,
        redeem_retail_value: 0,
      }
    } else {
      return selectedRedeem.value
    }
  }

  return (
    <div>
      <Switch condition={showConfirmationAlert}>
        <div className="padding-top-large">
          <Alert bsStyle="success">
            <span className="fa fa-check-circle-o padding-right-small" />
            <span className="body-text-4">{t('confirmation_alert')}</span>
          </Alert>
        </div>
      </Switch>
      <Spacer paddingTop={showConfirmationAlert ? '32px' : '48px'}>
        <Switch
          condition={!redeemRequired}
          fallback={<label>{t('required_incentive_label')}</label>}
        >
          <div>
            <Checkbox
              name="redeem"
              onChange={handleInclusionChange}
              checkedValue={true}
              uncheckedValue={false}
              isChecked={config.redeem}
              labelText={t('include_incentive_label')}
            />
          </div>
        </Switch>
      </Spacer>
      <Switch
        condition={!isLoadingRedeems}
        fallback={
          <Spinner
            isLoading={true}
            size="4x"
            className="text-center padding-top-huge"
          />
        }
      >
        <Switch condition={showRewards}>
          <div className="padding-top-large padding-left-large margin-bottom-huge">
            <div className="hidden-inputs">
              <HiddenInputs campaign={campaign} />
            </div>
            <Row>
              <Col lg={9} xs={9}>
                <Select
                  placeholder={t('select_placeholder')}
                  isDisabled={false}
                  onChange={handleSelectChange}
                  options={selectOptions}
                  value={selectedRedeem}
                />
              </Col>
              <Col lg={3} xs={3}>
                <Button
                  bsStyle="link"
                  onClick={toggleRewardCreator}
                  className={
                    'margin-top-small pull-right text-right padding-top padding-right-none ' +
                    (showRewardCreator ? 'hidden' : '')
                  }
                >
                  {t('reward_creator.button')}
                </Button>
              </Col>
            </Row>
            <RewardCreator
              key={getSelectedRedeem().redeem_description}
              redeem={getSelectedRedeem() as Campaign}
              merchant={merchant}
              showRewardCreator={showRewardCreator}
            />
            <RestrictionCreator
              campaign={campaign}
              showRewardRestrictions={showRewardRestrictions}
              onClose={onRestrictionsClose}
              isSaving={isSaving}
            />
            <Spacer
              paddingTop={showRewardCreator ? '24px' : '48px'}
              className="margin-bottom-huge"
            >
              <RestrictionSummary
                campaign={campaign}
                onEdit={toggleRewardRestrictions}
                showEdit={true}
                alwaysShow={true}
                editDisabled={!canProceed}
                validate={showValidationErrors}
              />
            </Spacer>
            <CustomFinePrint
              config={config}
              redeem={getSelectedRedeem() as Redeem}
            />
          </div>
        </Switch>
      </Switch>
    </div>
  )
}

export default Fields
