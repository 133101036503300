import { Radio } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { PointsMultiplierPayload } from 'api/points'
import PopoverContent from 'components/PopoverContent'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import { useState } from 'react'
import Card from './Card'
import DateField from './DateField'

const t = buildTranslate('points.multipliers.form')

type Props = { multiplier: PointsMultiplierPayload }

const HelpPopover = ({ localeKey }) => {
  const sentences = Object.values(t(localeKey)) as string[]
  return (
    <span className="ml-xs align-self-center">
      <PopoverContent sections={sentences.map(content => ({ content }))} />
    </span>
  )
}

function getMinDate(date: string | undefined | null) {
  const existingMoment = moment(date)
  const currentMoment = moment()
  const maximumMoment = existingMoment.isAfter(currentMoment)
    ? existingMoment
    : currentMoment

  return maximumMoment.format('YYYY-MM-DD')
}

const RestrictionsCard = ({ multiplier }: Props) => {
  const [enabled, setEnabled] = useState(
    !!(multiplier.date_range_starts_on || multiplier.date_range_ends_on)
  )
  const [css] = useStyletron()

  const minEndsOn = getMinDate(multiplier.date_range_starts_on)

  return (
    <Card title={t('restrictions')}>
      <Text.Header4 className="mt-0 mb-m">{t('date_range.title')}</Text.Header4>

      <div className="mb-m">
        <Radio
          value="text"
          checked={!enabled}
          onClick={() => setEnabled(false)}
        >
          {t('date_range.disabled.title')}
        </Radio>
        {!enabled && (
          <div className="ml-l mb-xs">
            <Text.BodyText4 className={`${css({ color: palette.grey70 })}`}>
              {t('date_range.disabled.text')}
            </Text.BodyText4>
          </div>
        )}
      </div>

      <div>
        <Radio value="text" checked={enabled} onClick={() => setEnabled(true)}>
          {t('date_range.enabled.title')}
        </Radio>
        {enabled && (
          <div className="ml-l">
            <div className="mb-m">
              <Text.BodyText4>{t('date_range.enabled.text')}</Text.BodyText4>
            </div>

            <DateField
              label={
                <>
                  {t('date_range.starts_on.label')}
                  <HelpPopover localeKey="date_range.starts_on.help" />
                </>
              }
              addText={t('date_range.starts_on.add')}
              timeText={t('date_range.starts_on.time_text')}
              name="date_range_starts_on"
              value={multiplier.date_range_starts_on ?? ''}
              maxDate={multiplier.date_range_ends_on}
            />

            <DateField
              label={
                <>
                  {t('date_range.ends_on.label')}
                  <HelpPopover localeKey="date_range.ends_on.help" />
                </>
              }
              addText={t('date_range.ends_on.add')}
              timeText={t('date_range.ends_on.time_text')}
              name="date_range_ends_on"
              value={multiplier.date_range_ends_on ?? ''}
              minDate={minEndsOn}
            />
          </div>
        )}
      </div>
    </Card>
  )
}

export default RestrictionsCard
