import { PopoverOption } from 'components/PopoverMenu'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type Props = {
  close: () => void
  isEditable: boolean
  setShowDeleteModal: (value: boolean) => void
}

const Popover: React.FC<Props> = props => {
  const { close, setShowDeleteModal, isEditable } = props

  if (!isEditable) return null

  return (
    <>
      <PopoverOption
        onClick={() => {
          setShowDeleteModal(true)
          close()
        }}
      >
        {I18n.t('points.item_card.remove_from_marketplace')}
      </PopoverOption>
    </>
  )
}

export default Popover
