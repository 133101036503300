import {
  EventParams,
  GetCampaignEventsType,
  GetCampaignEventsTypeFailure,
  GetCampaignType,
  GetCampaignTypeFailure,
  GET_CAMPAIGN_METRIC_BY_TYPE,
  GET_CAMPAIGN_METRIC_BY_TYPE_FAIL,
  GET_CAMPAIGN_METRIC_BY_TYPE_SUCCESS,
  GET_CAMPAIGN_METRIC_EVENTS,
  GET_CAMPAIGN_METRIC_EVENTS_FAIL,
  GET_CAMPAIGN_METRIC_EVENTS_SUCCESS,
  ResolvedAction,
} from 'actions/campaignMetrics'
import { RESET_DB } from 'actions/orm'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { AxiosAction } from 'redux-axios-middleware'
import { attr, fk, Model, ModelType } from 'redux-orm'
import { EmptyObject } from 'utilities/types'
import urlUtils from 'utilities/urlUtils'

export enum Type {
  COST = 'program_metric_cost',
  CUSTOMER = 'program_metric_customer',
  CUSTOMERS = 'program_metric_customers',
  CUSTOMER_VALUE = 'program_metric_customer_value',
  ITEM_QUANTITY = 'program_metric_item_quantity',
  MESSAGE_COUNT = 'program_metric_message_count',
  EMAIL_MESSAGE_COUNT = 'program_metric_email_message_count',
  PUSH_MESSAGE_COUNT = 'program_metric_push_message_count',
  SMS_MESSAGE_COUNT = 'program_metric_sms_message_count',
  REDEEM = 'program_metric_redeem',
  UNSCOPED_REDEEM = 'program_metric_unscoped_redeem',
  REPEAT_COUNT = 'program_metric_repeat_count',
  REVENUE = 'program_metric_revenue',
  USER_COUNT = 'program_metric_user_count',
  LINK_ACTIVITY = 'program_metric_link_activity',
  SIGNUP = 'program_metric_signup',
  RESTRICTED_ENGAGEMENT = 'program_metric_restricted_engagement',
  CONVERSION = 'program_metric_conversion',
  EVENTS_PURCHASE = 'program_metric_events_purchase',
  EVENTS_MESSAGE = 'program_metric_events_message',
  EVENTS_REDEEM = 'program_metric_events_redeem',
  EVENTS_NET_REVENUE = 'program_metric_events_net_revenue',
  VARIANT_COUNT = 'program_metric_variant_count',
}

export type ShortType =
  | 'cost'
  | 'customer'
  | 'customers'
  | 'customer_value'
  | 'item_quantity'
  | 'message_count'
  | 'email_message_count'
  | 'push_message_count'
  | 'sms_message_count'
  | 'redeem'
  | 'unscoped_redeem'
  | 'repeat_count'
  | 'revenue'
  | 'user_count'
  | 'link_clicks'
  | 'link_activity'
  | 'signup'
  | 'restricted_engagement'
  | 'conversion'
  | 'events'
  | 'variant_count'

export type EventTypes = 'purchase' | 'redeem' | 'message' | 'net_revenue'

interface BaseMetric {
  campaign_id: number
  type: Type
  data: Record<string, any>
  isLoading?: boolean
  isErrored?: boolean
  errorMessage?: string
  variant_id?: number
}

export interface CustomerData {
  identifier: string
  picture: string
  first_name: string
  average_check: number
  average_frequency: string
  lifetime_value: number
}

interface CustomersData {
  top_customers: CustomerData[]
}

interface MessageCountBase {
  sent_count: number
  clicked_count: number
  viewed_count: number
}
export interface MessageCountData extends MessageCountBase {
  delivered_count: number
  delivered_rate: number
  viewed_rate: number
  clicked_rate: number
  unregistered_viewed_count: never
  registered_viewed_count: never
  unregistered_sent_count: never
  registered_sent_count: never
}

export interface MessageCountDataOld extends MessageCountBase {
  unregistered_viewed_count: number
  registered_viewed_count: number
  unregistered_sent_count: number
  registered_sent_count: number
  delivered_count: never
  delivered_rate: never
  viewed_rate: never
  clicked_rate: never
}

export interface EmailMessageCountData extends MessageCountBase {
  delivered_count: number
  delivered_rate: number
  viewed_rate: number
  clicked_rate: number
  unsubscribed_rate: number
  unsubscribed_count: number
}

export interface PushMessageCountData {
  delivered_count: number
  delivered_rate: number
  sent_count: number
}

export interface SmsMessageCountData {
  delivered_count: number
  delivered_rate: number
  sent_count: number
}

export interface SignupData {
  count: number
  rate?: number
}

interface LinkActivity {
  type: string
  text: string
  url: string
  count: number
  unique_count?: number
  total_count?: number
}
export interface LinkActivityData {
  total_count: number
  unique_count: number
  link_clicks: LinkActivity[]
}

export interface RedeemData {
  count: number
  redeemed_count: number
  issued_count: number
  redeemed_fraction: number
  total_cost?: number
  average_cost?: number
  total_discount?: number
  avg_discount?: number
}

export interface UnscopedRedeemData {
  redeemers_count: number
  issued_count: number
  total_cost?: number
  average_cost?: number
  total_discount?: number
  avg_discount?: number
  average_discount?: number
}

interface UserCountData {
  control_count: number
  count: number
  excluded_count: number
  location_counts: number
  member_count: number
  merchant_id: number
  purchase_user_count: number
  subscriber_count: number
  target_count_breakdown: Object
  target_count: number
}

export interface RevenueDataBase {
  purchasers_count: number
}

export interface RevenueData extends RevenueDataBase {
  purchases_rate: number
  purchases_count: number
  total_revenue: number
  avg_revenue: number
  net_revenue: number
  count: number
  control_engagement_rate: never
  target_purchasers_count: never
  target_engagement_rate: never
  target_gross_revenue: never
  target_incremental_revenue: never
  target_expected_revenue: never
  gross_revenue: never
  incremental_revenue: never
  target_average_check: never
  control_average_check: never
}
export interface RevenueDataOld extends RevenueDataBase {
  control_engagement_rate: number
  target_purchasers_count: number
  target_engagement_rate: number
  target_gross_revenue: number
  target_incremental_revenue: number
  target_expected_revenue: number
  gross_revenue: number
  incremental_revenue: number
  target_average_check: number
  control_average_check: number
  purchases_rate: number
  purchases_count: number
  total_revenue: never
  avg_revenue: never
  net_revenue: never
  count: never
}

interface RepeatCountData {
  '1x'?: number
  '2x'?: number
  '3x'?: number
  '4x'?: number
  '5x'?: number
}

interface CustomerValueData {
  value: number
  total_amount: number
  wonback_count: number
  targeted_count: number
}

interface ItemQuantityData {
  items: Record<string, number>
}

export interface CostData {
  total_cost: number
}

export interface RestrictedEngagementData {
  registered_purchasers_count: number
  registered_sent_engagement_rate: number
  control_sent_engagement_rate: number
}

export interface VariantData {
  count: number
  percent: number
}

export interface VariantCountData {
  a: VariantData
  b: VariantData
  c: VariantData
  d: VariantData
  control: VariantData
}

interface ConversionData {
  rate: number
}

export interface CostMetric extends BaseMetric {
  type: Type.COST
  data: CostData
}

export interface CustomerMetric extends BaseMetric {
  type: Type.CUSTOMER
  data: CustomerData
}

export interface CustomersMetric extends BaseMetric {
  type: Type.CUSTOMERS
  data: CustomersData
}

export interface CustomerValueMetric extends BaseMetric {
  type: Type.CUSTOMER_VALUE
  data: CustomerValueData
}

export interface ItemQuantityMetric extends BaseMetric {
  type: Type.ITEM_QUANTITY
  data: ItemQuantityData
}

export interface MessageCountMetric extends BaseMetric {
  type: Type.MESSAGE_COUNT
  data: MessageCountData | MessageCountDataOld
}

export interface EmailMessageCountMetric extends BaseMetric {
  type: Type.EMAIL_MESSAGE_COUNT
  data: EmailMessageCountData
}

export interface PushMessageCountMetric extends BaseMetric {
  type: Type.PUSH_MESSAGE_COUNT
  data: PushMessageCountData
}

export interface SmsMessageCountMetric extends BaseMetric {
  type: Type.SMS_MESSAGE_COUNT
  data: SmsMessageCountData
}

export interface SignupMetric extends BaseMetric {
  type: Type.SIGNUP
  data: SignupData
}

export interface RedeemMetric extends BaseMetric {
  type: Type.REDEEM
  data: RedeemData
}

export interface UnscopedRedeemMetric extends BaseMetric {
  type: Type.UNSCOPED_REDEEM
  data: UnscopedRedeemData
}

export interface RepeatCountMetric extends BaseMetric {
  type: Type.REPEAT_COUNT
  data: RepeatCountData
}

export interface RevenueMetric extends BaseMetric {
  type: Type.REVENUE
  data: RevenueData | RevenueDataOld
}

export interface UserCountMetric extends BaseMetric {
  type: Type.USER_COUNT
  data: UserCountData
}

export interface LinkActivityMetric extends BaseMetric {
  type: Type.LINK_ACTIVITY
  data: LinkActivityData
}

export interface RestrictedEngagementMetric extends BaseMetric {
  type: Type.RESTRICTED_ENGAGEMENT
  data: RestrictedEngagementData
}

export interface ConversionMetric extends BaseMetric {
  type: Type.CONVERSION
  data: ConversionData
}

export interface VariantCountMetric extends BaseMetric {
  type: Type.VARIANT_COUNT
  data: VariantCountData
}

export type DateTimeValue = {
  date_time: string
  count: number
}

type EventsSeries = {
  name: string
  values: DateTimeValue[]
}

export type EventsTypes =
  | Type.EVENTS_MESSAGE
  | Type.EVENTS_PURCHASE
  | Type.EVENTS_REDEEM
  | Type.EVENTS_NET_REVENUE

export interface EventsMetric {
  campaign_id: number
  type: EventsTypes
  granularity?: EventGranularities
  data?: EventsSeries[]
  time_zone?: string
  isLoading?: boolean
  isErrored?: boolean
  errorMessage?: string
  variant_id?: number
  variant_name?: string
  id?: number
  meta?: any
}

export type Metrics = {
  message_count?: MessageCountData | MessageCountDataOld
  email_message_count?: EmailMessageCountData
  push_message_count?: PushMessageCountData
  sms_message_count?: SmsMessageCountData
  link_activity?: LinkActivityData
  redeem?: RedeemData
  unscoped_redeem?: UnscopedRedeemData
  user_count?: UserCountData
  revenue?: RevenueData | RevenueDataOld
  repeat_count?: RepeatCountData
  customer_value?: CustomerValueData
  customer?: CustomerData
  customers?: CustomersData
  item_quantity?: ItemQuantityData
  cost?: CostData
  signup?: SignupData
  restricted_engagement?: RestrictedEngagementData
  conversion?: ConversionData
}

export type Fields =
  | CostMetric
  | CustomerMetric
  | CustomersMetric
  | CustomerValueMetric
  | ItemQuantityMetric
  | MessageCountMetric
  | EmailMessageCountMetric
  | PushMessageCountMetric
  | SmsMessageCountMetric
  | RedeemMetric
  | UnscopedRedeemMetric
  | RepeatCountMetric
  | RevenueMetric
  | UserCountMetric
  | LinkActivityMetric
  | SignupMetric
  | RestrictedEngagementMetric
  | ConversionMetric
  | EventsMetric
  | VariantCountMetric

export type MetricTypes = Array<ShortType>
export const MetricsWithCustomAttribution = ['redeem', 'revenue']

export enum EventRanges {
  LAST_30 = 'last_30',
  LAST_90 = 'last_90',
  LAST_365 = 'last_365',
  ALL_TIME = 'all_time',
}

export enum EventGranularities {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

const getOptionsFromPath = url => {
  const paths: string[] = url.split('/') || []
  const campaignId = Number(paths[1])
  const params: EventParams = urlUtils.parseUrlParams(url)
  const type: Type | undefined = find(Type, key => {
    const pathKey = paths[3]?.split('?')[0]
    if (pathKey.includes('events')) {
      if (params.type) {
        return key === `program_metric_events_${params.type}`
      }
    }
    return key === `program_metric_${pathKey}`
  })
  const variantId = params?.variant_id ? Number(params?.variant_id) : undefined
  return { campaignId, type, variantId }
}

export default class CampaignMetric extends Model<
  // @ts-ignore
  typeof CampaignMetric,
  Fields
> {
  static modelName: string = 'CampaignMetric'

  /* Fields */
  static get fields(): any {
    return {
      campaign_id: fk({
        to: 'Campaign',
        as: 'campaign',
        relatedName: 'metrics',
      }),
      type: attr(),
      data: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action:
      | ResolvedAction
      | { type: typeof RESET_DB }
      | AxiosAction<typeof GET_CAMPAIGN_METRIC_BY_TYPE>
      | AxiosAction<typeof GET_CAMPAIGN_METRIC_EVENTS>,
    // @ts-ignore
    CampaignMetric: ModelType<CampaignMetric>
  ) {
    function onFailure(
      previousAction: GetCampaignType | GetCampaignEventsType | EmptyObject,
      currentAction: GetCampaignTypeFailure | GetCampaignEventsTypeFailure
    ) {
      const url: string = previousAction?.payload?.request?.url || ''
      const errors = currentAction.error?.response?.data?.errors
      const messages = errors?.messages || []
      const { campaignId, type, variantId } = getOptionsFromPath(url)
      let id = `${campaignId}${type}`
      if (variantId) {
        id += variantId
      }
      CampaignMetric.upsert({
        id,
        campaign_id: campaignId,
        variant_id: variantId,
        type,
        isLoading: false,
        isErrored: true,
        errorMessage: messages[0],
      })
    }

    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_CAMPAIGN_METRIC_BY_TYPE:
      case GET_CAMPAIGN_METRIC_EVENTS: {
        const url: string = action.payload?.request?.url || ''
        const { campaignId, type, variantId } = getOptionsFromPath(url)

        // clean metrics first
        if (!!type && action.type === GET_CAMPAIGN_METRIC_EVENTS) {
          CampaignMetric?.all()
            // @ts-ignore
            .filter(c => c.type.includes('event'))
            .delete()
        }

        let id = `${campaignId}${type}`
        if (variantId) {
          id += variantId
        }
        CampaignMetric.upsert({
          id,
          campaign_id: campaignId,
          variant_id: variantId,
          type,
          isLoading: true,
          isErrored: false,
          errorMessage: undefined,
        })
        break
      }
      case GET_CAMPAIGN_METRIC_BY_TYPE_FAIL:
      case GET_CAMPAIGN_METRIC_EVENTS_FAIL: {
        if (!isEmpty(action.meta.previousAction)) {
          onFailure(action.meta.previousAction, action)
        }
        break
      }
      case GET_CAMPAIGN_METRIC_BY_TYPE_SUCCESS:
        const { campaign_id, type, variant_id } = action.payload.data.metric
        let id = `${campaign_id}${type}`
        if (variant_id) {
          id += variant_id
        }
        CampaignMetric.upsert({
          ...action.payload.data.metric,
          id,
          isLoading: false,
          isErrored: false,
        })
        break
      case GET_CAMPAIGN_METRIC_EVENTS_SUCCESS: {
        const eventData = action.payload.data.event_data
        const { campaign_id, type, variant_id } = eventData
        let id = `${campaign_id}${type}`
        if (variant_id) {
          id += variant_id
        }

        CampaignMetric.upsert({
          ...eventData,
          id: id,
          isLoading: false,
          isErrored: false,
          type: eventData.type,
        })
        break
      }
      case RESET_DB:
        CampaignMetric.all().delete()
        break
      default:
        break
    }
  }
}
