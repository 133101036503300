import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import ContentContainer from 'components/ContentContainer'
import PopoverContent, { Sections } from 'components/PopoverContent'
import palette from 'constants/palette'
import React from 'react'

type CellData = {
  value: string | number
  sections?: Sections
}

type Props = {
  className?: string
  isErrored: boolean
  isLoading: boolean
  values: CellData[]
}

const BodyCell: React.FC<Props> = props => {
  const { isErrored, isLoading, values, className = '' } = props
  const [css] = useStyletron()

  const cellClass = css({
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: palette.grey30,
    paddingLeft: 0,
    paddingRight: 0,
  })

  return (
    <td className={`p-m align-top ${className} ${cellClass}`}>
      <ContentContainer isErrored={isErrored} isLoading={isLoading}>
        {values?.map((data, index) => (
          <Text.BodyText4
            key={`channels-cell-${index}`}
            tag="div"
            color="grey70"
            className="ellipsis max-width-100"
          >
            <span className="mr-xs">{data.value}</span>
            {data.sections && <PopoverContent sections={data.sections} />}
          </Text.BodyText4>
        ))}
      </ContentContainer>
    </td>
  )
}

export default BodyCell
