import { Fields as ItemPurchase } from 'models/ItemPurchase'
import { PaginationT } from 'models/Pagination'
import { Fields as UserItem } from 'models/UserItem'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_ITEM_PURCHASES = 'users/GET_ITEM_PURCHASES'
export const GET_ITEM_PURCHASES_SUCCESS = 'users/GET_ITEM_PURCHASES_SUCCESS'
export const GET_ITEM_PURCHASES_FAIL = 'users/GET_ITEM_PURCHASES_FAIL'
export const RESET_DB = 'users/ITEM_PURCHASES_CLEAR'

export type GetItemPurchasesAction = AxiosAction<
  typeof GET_ITEM_PURCHASES,
  {
    purchases: ItemPurchase[]
    pagination: PaginationT
  }
>

export function getItemPurchases(
  userIdentifier: string,
  item: UserItem,
  params?: { page: number }
): GetItemPurchasesAction {
  return {
    type: GET_ITEM_PURCHASES,
    payload: {
      client: 'default',
      request: {
        url: `users/${userIdentifier}/purchases?tag_id=${item.id}`,
        method: 'get',
        params,
      },
    },
  }
}

export function clearState() {
  return {
    type: RESET_DB,
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getItemPurchases>
>
