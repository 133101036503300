import IconMagic from 'assets/images/campaign-center/IconMagic'
import Checkbox from 'components/Form/CheckboxWithFormsy'
import { buildTranslate } from 'locales'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import ThanxOptimization from 'scenes/CampaignCenter/Builder/components/ThanxOptimization'

type PropsT = {
  isDisabled: boolean
  isChecked: boolean
}

const t = buildTranslate('thanx_campaigns.review.customers.excluded')

const RecentlyRewarded: React.FC<PropsT> = props => {
  const { isDisabled, isChecked } = props

  const optimization = (
    <Tooltip id="optimization-tooltip" className="text-left">
      <ThanxOptimization
        title={t('optimization.title')}
        body={t('optimization.body')}
      />
    </Tooltip>
  )

  return (
    <div>
      <Checkbox
        isDisabled={isDisabled}
        name="config_include_recently_rewarded_users"
        checkedValue={false}
        uncheckedValue={true}
        isChecked={isChecked}
      />
      <span className="margin-left-small font-size-16 grey-70">
        {t('text')}
        <OverlayTrigger placement="right" overlay={optimization}>
          <span className="padding-left-small">
            <IconMagic />
          </span>
        </OverlayTrigger>
      </span>
    </div>
  )
}

export default RecentlyRewarded
