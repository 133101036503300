import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { getPointsExperiences } from 'actions/pointsExperiences'
import Help from 'components/Help'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import EditableName from 'scenes/CampaignCenter/Builder/components/EditableName'
import SendTestModal from 'scenes/RedeemManager/Modals/SendTestModal'
import { selectExperience } from 'selectors/pointsExperience'
import { useAbility } from 'utilities/ability'
import { useIsMobile } from 'utilities/responsive'
import ActivateModal from '../../Modals/ActivateModal'
import { FormModel, useFormModel } from '../FormModel'
import { Step } from '../index'
import Success from '../Success'
import TopBar from '../TopBar'
import AppearanceCard from './AppearanceCard'
import BonusPointsCard from './BonusPointsCard'
import CustomerSupportCard from './CustomerSupportCard'
import { getHelp } from './helpers'
import Preview from './Preview'
import Restrictions from './Restrictions'

export enum Card {
  BONUS_POINTS,
  REWARD_APPEARANCE,
  CUSTOMER_SUPPORT,
}

type Props = {
  templateId: number
  isLoading: boolean
  isDraft: boolean
  isValidForm: boolean
  setIsValidForm: (isValid: boolean) => void
  onStepSelected: (step: Step) => void
  onFormChange: (newFormModel: FormModel) => void
  onSuccessExit: () => void
  saveForm: () => void
}

const t = buildTranslate('redeem_manager.builder.steps.configuration')

const BonusPointsConfigurationStep: React.FC<Props> = ({
  templateId,
  isLoading,
  isDraft,
  isValidForm,
  setIsValidForm,
  onStepSelected,
  onFormChange,
  onSuccessExit,
  saveForm,
}) => {
  const formModel = useFormModel().model
  const {
    typeItem,
    name,
    description,
    availableCustomerSupport,
    numberOfPoints,
  } = formModel
  const isMobile = useIsMobile()
  const ability = useAbility()
  const canAccessFeedback = ability.can('schedule', 'Campaign')
  const key = typeItem?.key
  const dispatch = useDispatch()

  const pointsExperience: PointsExperience | null =
    useSelector(selectExperience)

  const isHiddenMenu = key === 'hidden_menu'
  const isGoldenTicket = key === 'golden_ticket'
  const isExperience = isHiddenMenu || isGoldenTicket

  const [isPublished, setIsPublished] = useState(false)
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false)
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false)
  const [currentCard, setCurrentCard] = useState<Card>(() => {
    if (!numberOfPoints) return Card.BONUS_POINTS
    return Card.CUSTOMER_SUPPORT
  })
  const isComplete = isValidForm

  useEffect(() => {
    dispatch(getPointsExperiences())
  }, [dispatch])

  function handleFormChange(newFormModel: FormModel, isChanged: boolean) {
    if (isChanged) {
      onFormChange(newFormModel)
    }
  }

  const getCardOrder = () => {
    const cardOrder = [Card.BONUS_POINTS, Card.CUSTOMER_SUPPORT]
    return cardOrder
  }

  const handleNext = () => {
    const cardOrder = getCardOrder()
    const index = cardOrder.indexOf(currentCard)
    if (index < cardOrder.length) setCurrentCard(cardOrder[index + 1])
  }

  if (!typeItem) return null

  if (isPublished) {
    return <Success />
  }

  const helpArticles = getHelp(typeItem.key)

  return (
    <>
      <ActivateModal
        templateId={templateId}
        name={name}
        isOpen={isActivateModalOpen}
        onClose={() => setIsActivateModalOpen(false)}
        onActivate={() => setIsPublished(true)}
      />
      <SendTestModal
        templateId={templateId}
        isOpen={isSendTestModalOpen}
        isLoading={isLoading}
        onClose={() => setIsSendTestModalOpen(false)}
        onBeforeSend={async () => {
          // save template to make sure the data synced with the backend
          await saveForm()
        }}
        onSuccess={() => setIsSendTestModalOpen(false)}
      />
      <TopBar
        step="configuration"
        templateId={templateId}
        hideTypeStep={!isDraft}
        onStepSelected={onStepSelected}
      >
        <Button
          className="mr-m"
          kind="secondary_outline"
          disabled={!isDraft && !isComplete}
          isLoading={isLoading}
          onClick={async () => {
            await saveForm()
            onSuccessExit()
          }}
        >
          {t('save_exit')}
        </Button>
        <Button
          className="mr-m"
          kind="secondary_outline"
          disabled={!isComplete}
          isLoading={isLoading}
          onClick={() => setIsSendTestModalOpen(true)}
        >
          {t('send_a_test')}
        </Button>
        <Switch condition={isDraft}>
          <Button
            className={isMobile ? 'mt-xs' : ''}
            kind="secondary_outline"
            disabled={!isComplete}
            isLoading={isLoading}
            onClick={async () => {
              await saveForm()
              setIsActivateModalOpen(true)
            }}
          >
            {t('activate')}
          </Button>
        </Switch>
      </TopBar>
      <Container>
        <Formsy
          className="text-left form-group form-group-lg"
          onValid={() => setIsValidForm(true)}
          onInvalid={() => setIsValidForm(false)}
          onChange={handleFormChange}
        >
          <Row>
            <Col className="my-xl">
              <EditableName
                name={formModel.name}
                styleSize="large"
                type="redeem"
              />
              <p className="body-text-2 grey-70">
                {I18n.t(`redeem_manager.types.${typeItem.key}.title`)}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <BonusPointsCard
                numberOfPoints={numberOfPoints}
                isCurrent={currentCard === Card.BONUS_POINTS && isDraft}
                isDraft={isDraft}
                onNext={handleNext}
              />
              <AppearanceCard
                description={description}
                isEnabled={false}
                isCurrent={false}
                isOpen={currentCard === Card.CUSTOMER_SUPPORT}
                onNext={() => {}}
                templateId={templateId}
              />
              <Switch condition={canAccessFeedback}>
                <CustomerSupportCard
                  isEnabled={availableCustomerSupport && !isExperience}
                  isOpen={currentCard === Card.CUSTOMER_SUPPORT}
                  hideToggle={isExperience}
                />
              </Switch>
              <Restrictions />
              <Help
                articles={helpArticles}
                title={I18n.t(
                  'redeem_manager.builder.steps.configuration.help.title'
                )}
              />
              <Switch condition={isComplete}>
                <Button
                  className="w-100"
                  isLoading={isLoading}
                  onClick={() => setIsSendTestModalOpen(true)}
                >
                  {t('send_a_test')}
                </Button>
              </Switch>
            </Col>
            <Col
              className="d-sm-none d-md-block"
              lg={{
                span: 4,
                offset: 1,
              }}
            >
              <Preview
                currentCard={currentCard}
                isExperience={isExperience}
                templateId={templateId}
                pointsExperience={pointsExperience}
              />
            </Col>
          </Row>
        </Formsy>
      </Container>
    </>
  )
}

export default BonusPointsConfigurationStep
