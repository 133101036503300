import type {
  ChannelTimeSeriesMetric,
  CumulativeMetric,
  Fields,
  RateTimeSeriesMetric,
} from 'models/CaptureRateMetric'
import orm from 'models/orm'

import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectAllCaptureRateMetrics = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.CaptureRateMetric.all().toRefArray()
  }
)

export const selectCumulativeMetric = createSelector(
  selectAllCaptureRateMetrics,
  (_: any, merchantId?: number) => merchantId,
  (_: any, _merchantId?: number, locationId?: number) => locationId,
  (
    captureRateMetrics,
    merchantId,
    locationId
  ): CumulativeMetric | undefined => {
    const location = locationId ? locationId : 'all'
    return captureRateMetrics.find(
      c => c.id === `${merchantId}_cumulative_${location}`
    ) as CumulativeMetric
  }
)

export const selectRateTimeSeriesMetrics = createSelector(
  selectAllCaptureRateMetrics,
  (_: any, merchantId?: number) => merchantId,
  (_: any, _merchantId?: number, locationId?: number) => locationId,
  (captureRateMetrics, merchantId, locationId): RateTimeSeriesMetric[] => {
    const metrics: RateTimeSeriesMetric[] = []
    const all = captureRateMetrics.find(
      c => c.id === `${merchantId}_rate_time_series_all`
    ) as RateTimeSeriesMetric
    if (all) metrics.push(all)
    if (locationId) {
      const location = captureRateMetrics.find(
        c => c.id === `${merchantId}_rate_time_series_${locationId}`
      ) as RateTimeSeriesMetric
      if (location) metrics.push(location)
    }
    return metrics
  }
)

export const selectChannelTimeSeriesMetric = createSelector(
  selectAllCaptureRateMetrics,
  (_: any, merchantId?: number) => merchantId,
  (_: any, _merchantId?: number, locationId?: number) => locationId,
  (
    captureRateMetrics,
    merchantId,
    locationId
  ): ChannelTimeSeriesMetric | undefined => {
    const location = locationId ? locationId : 'all'
    return captureRateMetrics.find(
      c => c.id === `${merchantId}_channel_time_series_${location}`
    ) as ChannelTimeSeriesMetric
  }
)
