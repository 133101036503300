import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import PopoverContent from 'components/PopoverContent'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('thanx_campaigns.report.v3.attribution')

type Props = {
  tooltipText: string
  attributionWindow?: number
  className?: string
}

const Attribution: React.FC<Props> = props => {
  const { attributionWindow, tooltipText, className = '' } = props
  const isCustomAttributionEnabled = useFlag('MX4-CustomAttribution-R1', false)

  const attributionText =
    attributionWindow && attributionWindow > 0
      ? t('days_long', { count: attributionWindow })
      : t('all_time')

  return (
    <div className={`${className} d-flex align-items-center`}>
      <Switch
        condition={isCustomAttributionEnabled}
        fallback={
          <>
            <Text.SmallCaps3 className="mr-xs" bold color="grey70">
              {t('attribution')}
            </Text.SmallCaps3>
            <Text.BodyText5 className="mr-xs" color="grey70">
              {attributionText}
            </Text.BodyText5>
          </>
        }
      >
        <Text.BodyText5 bold color="grey90" className="mr-xs">
          {t('attribution')}
        </Text.BodyText5>
        <Text.BodyText5 color="grey70" className="mr-xs">
          {attributionText}
        </Text.BodyText5>
      </Switch>
      <PopoverContent
        sections={[
          {
            content: tooltipText,
          },
        ]}
      />
    </div>
  )
}

export default Attribution
