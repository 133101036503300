export default {
  new: 'New',
  coming_soon: 'Coming Soon',
  reports_title: 'Reporting',
  targeted_title: 'Targeted Campaigns',
  automated_title: 'Automated Campaigns',
  message_title: 'Message To Everyone',
  rewards_title: 'Rewards',
  rotation_text: {
    engage: 'engage with your customers',
    new_menu: 'kick off a new menu',
    location: 'launch a new location',
    slow_times: 'drive customers to slow times',
    frequency: 'nudge customer frequency up a notch',
    newsletter: 'send your next newsletter',
    event: 'invite customers to a special event',
    rainy: 'get customers in on a rainy day',
    avg_check: 'increase your average check size',
  },
  reporting: {
    page_title: 'All campaigns | Thanx',
    empty: {
      title: {
        general: 'You don’t have any %{state} campaigns yet',
        in_review: 'You don’t have any campaigns that need review yet',
      },
      body: {
        draft: 'Campaigns that you are working on will appear here.',
        scheduled:
          'Campaigns will appear here after they are scheduled to send.',
        active:
          'One-time campaigns will appear here while they are being sent. Automated campaigns will appear here after they are started.',
        complete:
          'One-time campaigns will appear here after they are finished sending. Automated campaigns will appear here after they are stopped.',
        in_review:
          'If a campaign is created by a user who is not an admin, then that campaign must be reviewed by an admin before it can be sent. Campaigns that need to be reviewed by an admin will appear here.',
      },
      button: 'Go to drafts',
    },
    automated_title: 'Active Campaigns',
    category_names: {
      message: 'Send a message',
      targeted: 'Targeted',
      automated: 'Automated',
    },
    created_by_thanx: 'Thanx',
    column_titles: {
      date: {
        created: 'Date created',
        started: 'Date started',
        scheduled: 'Date will start',
      },
      created_by: 'Created by',
      name: 'Name',
      reward: 'Reward',
      status: 'Status',
    },
    no_active_campaigns:
      'You currently have no active campaigns. <a href="/thanx_campaigns">Let’s create one together</a>.',
    active_title: 'Active Campaigns',
    inactive_title: 'Inactive Campaigns',
    table: {
      cta: {
        active: 'Report',
        complete: 'Report',
        scheduled: 'Report',
        in_review: 'Review',
        draft: 'Edit',
        archived: 'Report',
        upcoming: 'View',
      },
      settings: {
        active: {
          stopButton: 'Stop',
        },
        draft: {
          delete_button: 'Delete',
          trash_icon: 'Trash Icon',
          modal: {
            title: 'Delete this draft',
            body: 'Are you sure you want to delete this draft? You won’t be able to undo this action.',
            primary: 'Delete draft',
            secondary: 'Cancel',
            error:
              'Could not delete draft: Something went wrong, please try again later.',
          },
        },
      },
    },
    cancel_button: 'Cancel',
    cancel_error: 'Sorry, we were unable to cancel the campaign.',
    tabs: {
      in_review: 'Need Review',
      scheduled: 'Scheduled',
      active: 'Active',
      draft: 'Draft',
      complete: 'Complete',
    },
  },
  overview: {
    page_title: 'Overview - Campaigns | Thanx',
    subtitle: 'The <span class="bold">only</span> resource you need to <br/>',
    message: {
      tab_text: 'Send a message to everyone',
      button_text: 'Send a message to everyone',
      recipients: 'Recipients',
      individuals: 'Individuals',
      import_contacts: 'Import Contacts',
      subscribers: {
        title: 'Email Subscribers',
        subtitle: 'Imported subscribers without a loyalty account',
      },
      members: {
        title: 'Loyalty Members',
        subtitle: 'Customers who have joined your loyalty program',
      },
      total: {
        title: 'Total Reach',
      },
    },
    targeted: {
      title:
        'Make your message a personal one for meaningful bottom-line impact.',
      tab_text: 'Send a targeted message',
      button_text: 'See all segments',
      tiles: {
        badges: {
          new: 'new',
          popular: 'popular',
        },
        week_part: 'Drive traffic during the week',
        day_part: 'Drive traffic in the afternoon',
        sku: 'Get customers to try something new',
        location: 'Promote a new location',
        menu_change: 'Launch a new menu',
      },
    },
    automated: {
      title: 'Automated Campaigns',
      header: 'Set it — and forget it.',
      body: 'Thanx automated campaigns keep your customers engaged so you can focus on everything else',
      button: 'Automated Campaigns',
    },
  },
  automated: {
    title: 'Automated Campaigns',
    page_title: 'Automated - Campaigns | Thanx',
    description:
      'React to customer behavior in real-time with simple-to-use automated engagement tools.',
    customAutomated: {
      modal: {
        title: 'Campaign trigger',
        subtitle: 'Send your campaign when a customer enters this segment',
        errors: {
          submit:
            'There was an error creating your custom campaign. Please try again.',
        },
      },
    },
    prebuilt: {
      title: 'Pre-built Campaigns',
      subtitle: 'Turn on a ready to go campaign',
      description: 'Start with a pre-defined target segment and message.',
    },
    campaigns: {
      birthday: {
        title: 'Birthday',
        description:
          'Send a “Happy Birthday” gift to customers so they spend it with you.',
      },
      bounceback: {
        title: 'Bounceback',
        description:
          'Convert your first time visitors into lifetime customers.',
      },
      conversion: {
        title: 'Loyalty Memberships',
        description: 'Convert your email subscribers into loyalty members.',
      },
      reputation_manager: {
        title: 'Reputation Manager',
        description:
          'Drive your happiest customers to leave positive ratings online.',
      },
      referrals: {
        title: 'Referrals',
        description: 'Turn your best customers into a viral marketing army.',
      },
      tiers: {
        title: 'Tiers',
        description: 'Give dedicated customers the recognition they deserve.',
      },
      vip: {
        title: 'VIP',
        description:
          'Get personal with your elite customers and drive huge bottom-line lift.',
      },
      winback: {
        title: 'Winback',
        description: 'Win back lost customers the moment you lose them.',
      },
    },
    footer: {
      title: 'One-Time Campaigns',
      subtitle: 'Get customers to act',
      description: 'Send them what they want, when and where they want it.',
      button: 'Explore one-time campaigns',
    },
  },
  segments: {
    header: {
      title: 'Segments',
      subtitle: 'Select a segment below to start a campaign',
      new: '+ New custom segment',
    },
    type_filter: {
      all: 'Pre-built and Custom segments',
      merchant: 'Custom segments only',
      global: 'Pre-built segments only',
    },
    empty: 'No segments available',
    all_segments: 'All segments',
    other: 'Other',
  },
  targeted: {
    title: 'Targeted Campaigns',
    page_title: 'Targeted - Campaigns | Thanx',
    custom: {
      title: 'Custom Campaigns',
      subtitle: 'Use your custom segments',
      description:
        'Start with your custom segments you’ve used in the past or create a new custom segment.',
      new: 'New Custom Segment',
      none: 'Your custom segments will appear here',
      modal: {
        title: 'Create a custom campaign',
        errors: {
          submit:
            'There was an error creating your custom campaign. Please try again.',
        },
      },
    },
    prebuilt: {
      title: 'Pre-Built Campaigns',
      subtitle: 'Start with a ready to go campaign',
      description:
        'Start with a pre-populated audience segment, incentive, and message. Then customize as needed.',
    },
    campaignsTable: {
      title: 'Recent Campaigns',
    },
    custom_campaign: {
      card_title: 'custom campaign',
    },
    description:
      'Get customers to act by sending them what they want, when and where they want it.',
    image_card: {
      custom: {
        title: 'Target a custom audience',
        description:
          'Our team can create a custom campaign for you. Tell us about your objective and your target audience, and we’ll be in touch shortly.',
      },
      item: {
        title: 'Target customers based on what they buy',
        description:
          'When customers explore the diversity of what your business sells they have more reasons to return with greater frequency. Encourage customers to try things outside their normal habits, get them excited about new items they can buy, and more.',
      },
      channel: {
        title: 'Target customers based on how they buy',
        description:
          'Encourage your customers to explore new revenue channels by focusing on how they make purchases today. Shift them to purchase online, to join subscriptions, or make bulk orders for special occasions and more.',
      },
      traffic: {
        title: 'Increase foot traffic & visit frequency',
        description:
          'Focus on when and how often your customers visit to get an extra purchase or encourage them to visit during slow periods to open up room when your business is busy.',
      },
      best_customers: {
        title: 'Talk to your best customers',
        description:
          'Your best customers are the engine on which your business thrives. Help these customers feel appreciated with occasional delights to ensure your business remains a favorite.',
      },
      satisfaction: {
        title: 'Guide customer satisfaction',
        description:
          'Go beyond one-on-one responses to NPS feedback with targeted messages and offers personalized for your satisfied and dissatisfied customers.',
      },
      event: {
        title: 'One time events',
        description:
          'Tell customers about a holiday celebration or special event with Thanx Campaigns to measure customer response and revenue generated.',
      },
      inactive: {
        title: 'Engage your inactive customers',
        description:
          'Target customers who have yet to engage with your loyalty program with special incentives to get them to join.',
      },
      location: {
        title: 'Engage customers based on where they are',
        description:
          'Engage with customers based on where they live, work, and shop to focus your efforts where they are needed the most.',
      },
      car_wash: {
        title: 'Top campaigns for car washes',
        description:
          'Drive more revenue by encouraging customers to buy more profitable items like subscriptions, wash books or add-ons by promoting them to customers that aren’t buying them today.',
      },
    },
    campaigns: {
      //custom
      custom: {
        title: 'Create a custom campaign',
      },

      // item
      upsell: {
        title: 'Get customers to try something new',
      },
      item_launch: {
        title: 'Roll out new menu items',
      },
      item_change: {
        title: 'Inform customers that a favorite menu item is changing',
      },
      item_favorite: {
        title: 'Engage customers based on their favorite items',
      },

      // channel
      channel_shift_catering: {
        title: 'Shift customers to catering',
      },
      engage_catering: {
        request_text:
          'Target everyone whose average check is greater than $500',
        title: 'Engage your catering customers',
      },
      channel_shift_online: {
        title: 'Get customers to try online ordering or order ahead',
      },
      channel_shift_subscription: {
        title: 'Convert customers to your subscription service',
      },

      // timeshift
      timeshift_immediate: {
        title: 'Create a flash offer to drive traffic immediately',
      },
      timeshift_morning: {
        title: 'Drive traffic to breakfast / morning',
      },
      timeshift_midday: {
        title: 'Drive traffic to lunch / midday',
      },
      timeshift_afternoon: {
        title: 'Drive traffic to afternoon',
      },
      timeshift_evening: {
        title: 'Drive traffic to dinner / evening',
      },
      timeshift_night: {
        title: 'Drive traffic to late-night',
      },
      timeshift_weekday: {
        title: 'Drive traffic to weekdays',
      },
      timeshift_weekend: {
        title: 'Drive traffic to weekends',
      },
      engage_slow_season: {
        title: 'Drive traffic during season slow-downs',
      },
      sustain: {
        request_text: 'Target everyone who visits during [slow time]',
        title: 'Say Thanx to customers who shop during your slow periods',
      },
      engage_infrequent: {
        title:
          'Increase the frequency of quarterly & monthly visitors with an earnable offer',
      },

      // best_customers
      engage_vip: {
        title: 'Engage your VIP customers (top 5%)',
      },
      engage_tier: {
        // TODO: Load the actual tier names
        request_text:
          'Target everyone who is in the [Bronze, Silver or Gold tier]',
        title: 'Engage your highest tier customers',
      },
      engage_top: {
        request_text: 'Target everyone who spending is in the top 25%',
        title: 'Engage your best (top 25%) customers',
      },

      // satisfaction
      engage_promoter: {
        request_text:
          'Target everyone that is a promoter (left a score of 9 or 10)',
        title: 'Send a nod to happy customers and build brand enthusiasm',
      },
      engage_detractor: {
        request_text:
          'Target everyone that is a detractor (left a score between 0 and 6)',
        title: 'Acknowledge and soothe unhappy customers',
      },

      // event
      event_special: {
        request_text: 'Target everyone that shops at [location(s)]',
        title: 'Announce a special event at one or more locations',
      },
      event_holiday: {
        request_text: 'Target everyone that shops at [location(s)]',
        title: 'Invite customers to join you for an upcoming holiday',
      },

      // inactive
      engage_inactive: {
        title:
          'Create a super charged signup offer to increase loyalty signups',
      },

      // location
      location_message: {
        title: 'Engage customers based on location',
      },
      location_promote: {
        title: 'Cross-promote a location',
      },
      location_close: {
        title: 'Close a location',
      },
      location_move: {
        request_text: 'Target everyone who shops at [location that is moving]',
        title: 'Move a location',
      },
      location_reopen: {
        title: 'Reopen a location',
      },

      // car wash
      car_wash_subscriber: {
        request_text:
          'Target everyone who has purchased a subscription in the past 30 days.',
        title: 'Talk to your wash subscribers',
      },
      car_wash_convert_single: {
        request_text:
          'Target everyone who has purchased a one-off wash once in the past 30 days and isn’t a subscriber. These are customers that could be buying a subscription.',
        title: 'Convert customers who buy one-off washes',
      },
      car_wash_convert_multiple: {
        request_text:
          'Target everyone who has purchased multiple one-off washes in the past 30 days and isn’t a subscriber. These are customers that are visiting enough to warrant buying a subscription.',
        title: 'Convert customers who buy multiple one-time washes ',
      },
      car_wash_winback: {
        request_text:
          'Target everyone who has ever purchased a subscription but not in the past 60 days.',
        title: 'Winback customers who stopped buying a subscription',
      },
      car_wash_wash_book: {
        request_text:
          'Target everyone who has/hasn’t purchased a wash book in the past 60 days.',
        title: 'Promote wash books',
      },
      car_wash_upsell: {
        request_text:
          'Target everyone who has/hasn’t purchased add-ons in the past 60 days.',
        title: 'Up-sell customers to buy additional products and services',
      },
    },

    custom_campaign_modal: {
      form: {
        create_new: '+ Create new segment',
        new: {
          message_label: 'Customer behavior',
          message_description:
            'Customers can be targeted by frequency, what they buy, spend, and more.',
          message_placeholder:
            'Target these customers by finding people who...',
          name_label: 'Segment name',
          name_description:
            'Give your segment a name so you can reference it later.',
          submit_button: 'Request segment & start campaign',
          cancel_button: 'Cancel',
          errors: {
            name_required: 'Segment name is required',
            message_required: 'Customer behavior description is required',
            submit:
              'There was an error in sending your custom campaign request. Please try again.',
          },
          success: 'Your request has been sent.',
        },
        existing: {
          reach: {
            label: 'Reach',
            details: '%{count} customers currently in this segment',
            error: 'Unable to fetch target segment count',
          },
          description_label: 'Description',
          locations_limit: {
            label: 'Limited to locations',
            description:
              'This segment will be limited to customers that shop at your locations',
          },
          submit_button: 'Create campaign',
          cancel_button: 'Cancel',
        },
      },
      how_it_works: {
        title: 'How it works',
        step1: {
          text: 'Fill out the form to tell us which customers you want to target',
        },
        step2: {
          text: 'We’ll work with you to create a custom segment',
          sub_text: '(Allow 5 business days)',
        },
        step3: {
          text: 'In the meantime you can customize your campaign draft',
        },
      },
    },
    footer: {
      message:
        'Not finding a campaign that suits your needs? Contact us to create a custom campaign.',
      button_text: 'Create a custom campaign',
    },
  },
  campaignsTableSection: {
    seeMore: 'See all',
  },
}
