import { buildTranslate } from 'locales'
import { Fields as Tier } from 'models/Tier'
import React from 'react'
import Markdown from 'react-markdown'
import { I18n } from 'react-redux-i18n'

const t = buildTranslate('loyalty.tier')

type Props = {
  tier: Tier
  icon: string
}

const TierInfo: React.FC<Props> = ({ tier, icon }) => {
  return (
    <div
      className="margin-bottom-small"
      style={{ width: '100%' }}
      id={`tier-${tier.type}`}
    >
      <div
        className="white-background border-radius-5 padding-large"
        style={{
          backgroundColor: 'white',
          border: '1px solid #E0E0E0',
          padding: '24px',
          marginBottom: '10px',
        }}
      >
        <div className="padding-bottom-medium display-flex">
          <img
            src={icon}
            alt="tier icon"
            style={{ width: '48px', height: '48px', marginRight: '16px' }}
          />
          <div className="padding-small">
            <div className="small-caps-1 grey-90 text-nowrap">
              {t('tier_name', {
                name: tier.name,
              })}
            </div>
            <div className="body-text-3 grey-70">
              {t('customer_count', {
                count: I18n.l(tier.user_count),
              })}
            </div>
          </div>
        </div>
        <div className="grey-30-border border-bottom-1 margin-bottom-medium" />
        <div className="small-caps-3 grey-90 pb-xs">
          {t('earn_requirements')}
        </div>
        <div className="grey-70 body-text-4 markdown-blob pb-m">
          {tier.requirements}
        </div>
        <div className="small-caps-3 grey-90 pb-xs">
          {t('reward_description')}
        </div>
        <div className="grey-70 body-text-4 markdown-blob">
          <Markdown>{tier.description}</Markdown>
        </div>
      </div>
    </div>
  )
}

export default TierInfo
