import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import React from 'react'

type PropsT = {
  className?: string
  color?: string
  paddingRight?: string
  variantLetter: string
}

const VariantWinner: React.FC<PropsT> = props => {
  const {
    className,
    variantLetter,
    color = palette.grey90,
    paddingRight = '11px',
  } = props
  const [css] = useStyletron()

  return (
    <span
      className={`${className} ${css({
        color,
        top: '-5px',
        left: '10px',
        width: '55px',
      })}`}
    >
      <svg
        width="55"
        height="32"
        viewBox="0 0 55 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 2H39C46.732 2 53 8.26801 53 16C53 23.732 46.732 30 39 30H16C8.26801 30 2 23.732 2 16C2 8.26801 8.26801 2 16 2ZM0 16C0 7.16344 7.16344 0 16 0H39C47.8366 0 55 7.16344 55 16C55 24.8366 47.8366 32 39 32H16C7.16345 32 0 24.8366 0 16ZM24 10V8.5C24 8.25 23.75 8 23.5 8H14.5C14.2188 8 14 8.25 14 8.5V10H10.5C10.2188 10 10 10.25 10 10.5V12.4062C10 14.3125 12.125 16.3125 14.9688 16.875C15.8438 18.6875 17.0938 19.625 18.25 19.9062V22.5H16.25C15.5312 22.5 15 23.0625 15 23.75C15 23.9062 15.0938 24 15.25 24H22.75C22.875 24 23 23.9062 23 23.75C23 23.0625 22.4375 22.5 21.75 22.5H19.75V19.9062C20.875 19.625 22.1562 18.6875 23 16.875C25.8438 16.3438 28 14.3125 28 12.4062V10.5C28 10.25 27.75 10 27.5 10H24ZM11.5 12.4062V11.5H14C14 12.75 14.0625 13.9062 14.375 15.1562C12.6562 14.5625 11.5 13.3125 11.5 12.4062ZM19 18.5C17.3438 18.5 15.5 16.0625 15.5 11.75V9.5H22.5V11.75C22.5 16.1562 20.5938 18.5 19 18.5ZM26.5 12.4062C26.5 13.3125 25.3125 14.5625 23.5938 15.1562C23.9062 13.9062 24 12.75 24 11.5H26.5V12.4062Z"
          fill={color}
        />
      </svg>
      <Text.Header4
        bold
        className={`text-right ${css({
          color,
          marginTop: '-25px',
          paddingRight,
          height: '14px',
        })}`}
        color="inherit"
      >
        {variantLetter}
      </Text.Header4>
    </span>
  )
}

export default VariantWinner
