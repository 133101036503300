export function sharedConfigs(type: string): string {
  let sharedConfig
  switch (type) {
    case 'timeshift-morning':
    case 'timeshift-midday':
    case 'timeshift-afternoon':
    case 'timeshift-evening':
    case 'timeshift-night':
    case 'timeshift-weekend':
    case 'timeshift-weekday':
      sharedConfig = 'timeshift'
      break
    default:
      sharedConfig = type
  }

  return sharedConfig.replace('-', '_')
}

export function canProceed(
  isValid: boolean,
  currentStepName: string,
  hasRequirements: boolean
): boolean {
  return isValid && (currentStepName !== 'review' || !hasRequirements)
}

export function reviewDisabled(
  canProceed: boolean,
  isSaving: boolean
): boolean {
  return !canProceed || isSaving
}
