import { Button } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import { alert } from 'actions/flash'
import { getLocations } from 'actions/location'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectLocationsByIds } from 'selectors/location'

type Props = {
  isOpen: boolean
  onClose?: () => void
  onEdit?: () => void
  locationIds: number[]
}

const translate = buildTranslate(
  'thanx_campaigns.review.customers.locationModal'
)

const LocationListModal: React.FC<Props> = props => {
  const { isOpen, onClose, onEdit, locationIds } = props

  const dispatch = useDispatch()
  const locations = useSelector(state =>
    selectLocationsByIds(state, locationIds)
  )

  useEffect(() => {
    async function fetchLocations() {
      const action = await dispatch(getLocations())

      if (action.error) {
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
          })
        )
      }
    }

    fetchLocations()
  }, [dispatch])

  function onEditHandler() {
    if (onEdit) onEdit()
    if (onClose) onClose()
  }

  if (locationIds.length === 0) return null

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="fs-unmask p-m mt-l">
        <h5 className="mb-l">{translate('title')}</h5>
        <div className="body-text-2 grey-70 px-m mb-m">
          {locations.map(location => (
            <div key={location.id}>{location.fullName()}</div>
          ))}
        </div>
        <div className="d-flex justify-content-between">
          <Button
            onClick={onEditHandler}
            className="flex-grow-1 mr-s"
            kind="secondary"
          >
            {translate('editButton')}
          </Button>
          <Button className="flex-grow-1" onClick={onClose}>
            {translate('confirmButton')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default LocationListModal
