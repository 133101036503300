import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { Feedback } from 'api/feedbacks'
import ExclamationTriangleO from 'components/Icons/ExclamationTriangleO'
import ReplyO from 'components/Icons/ReplyO'
import PopoverInfo from 'components/PopoverInfo'
import { buildTranslate } from 'locales'
import moment from 'moment'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { merchantTheme } from 'theme'
import { getFeedbackStyles } from './helpers'

const t = buildTranslate('feedbacks.responses.table')

type Props = {
  response: Feedback
  merchantName?: string
  onView: (response: Feedback) => void
}

const Row = (props: Props) => {
  const [css] = useStyletron()
  const { response, merchantName, onView } = props
  const {
    user,
    score,
    priority,
    response: responseText,
    comment,
    created_at,
    viewed_at,
  } = response

  const backgroundColor = viewed_at
    ? merchantTheme.colors.grey05
    : merchantTheme.colors.white
  const feedbackStyles = getFeedbackStyles(score)
  const priorityIcon = getPriorityIcon(priority)
  const { first_name: userFirstName = '', last_name: userLastName = '' } =
    user || {}

  function getPriorityIcon(priority: Feedback['priority']) {
    if (priority === 'critical') {
      return (
        <>
          <ExclamationTriangleO
            className={`pr-xs fa fa-lg fa-flag-o ${css({
              height: '16px',
              width: '30px',
            })}`}
            testId={'icon-exclamation'}
          />
          <PopoverInfo
            className={`fa-lg ${css({
              height: '16px',
              width: '30px',
              fontSize: '22px',
            })}`}
            title={t('popover.critical.title')}
            text={t('popover.critical.text', { merchantName })}
          />
        </>
      )
    } else if (priority === 'important') {
      return (
        <>
          <i
            className={`pr-xs fa fa-lg fa-flag-o ${css({
              color: merchantTheme.colors.grey70,
              height: '16px',
              width: '30px',
            })}`}
            data-testId="icon-flag"
          />
          <PopoverInfo
            className={`fa-lg ${css({
              height: '16px',
              width: '30px',
              fontSize: '22px',
            })}`}
            title={t('popover.important.title')}
            text={t('popover.important.text')}
          />
        </>
      )
    }
  }

  return (
    <tr className={`${css({ backgroundColor })}`}>
      <BodyCellContainer>
        <Text.BodyText4
          className={`d-flex
        ${css({
          width: '36px',
          height: '36px',
          borderRadius: '4px',
          backgroundColor: feedbackStyles.color,
          color: feedbackStyles.strongColor,
          justifyContent: 'center',
          alignItems: 'center',
        })}`}
          tag="div"
        >
          {score}
        </Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer>
        <div className="py-xs">{priorityIcon}</div>
      </BodyCellContainer>
      <BodyCellContainer>
        <div className="py-xs">
          {!!responseText && <ReplyO testId={'icon-reply'} />}
        </div>
      </BodyCellContainer>
      <BodyCellContainer>
        <Text.BodyText4 className="pr-xs py-xs" color="grey70" tag="div">
          {`${userFirstName} ${userLastName.charAt(0)}.`}
        </Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer>
        <Text.BodyText4 className="pr-xs py-xs" color="grey70" tag="div">
          {comment}
        </Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer>
        <Text.BodyText4 className="pr-xs py-xs" color="grey70" tag="div">
          {moment(created_at).format('MMM DD, YYYY')}
        </Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer>
        <div className="pr-xs py-xs">
          <Button
            kind={Kind.MINIMAL}
            size="small"
            onClick={() => onView(response)}
            className="font-weight-normal py-0 border-0"
          >
            {t('headers.view')}
          </Button>
        </div>
      </BodyCellContainer>
    </tr>
  )
}

export default Row
