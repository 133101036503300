import { getCampaignVariants } from 'actions/campaignVariants'
import useDispatch from 'hooks/useDispatch'
import isEmpty from 'lodash/isEmpty'
import { Fields as Campaign } from 'models/Campaign'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignVariantsByCampaignId } from 'selectors/campaignVariant'

export function useFetchCampaignVariants(campaign: Campaign | null) {
  const dispatch = useDispatch()
  const variants =
    useSelector(state =>
      selectCampaignVariantsByCampaignId(state, campaign?.id)
    ) || []

  const [hasFetchedVariants, setHasFetchedVariants] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const minimalVariantIds = useMemo(
    () => campaign?.variants?.map(mv => mv.id) || [],
    [campaign?.variants]
  )
  const campaignId = campaign?.id
  const shouldFetch =
    !!campaignId &&
    !isEmpty(minimalVariantIds) &&
    isEmpty(variants) &&
    !hasFetchedVariants

  useEffect(() => {
    const fetchVariants = async () => {
      setIsFetching(true)
      setHasFetchedVariants(true)
      await dispatch(getCampaignVariants(Number(campaignId)))
      setIsFetching(false)
    }

    if (shouldFetch) {
      fetchVariants()
    }
    if (campaignId && !shouldFetch) setHasFetchedVariants(true)
  }, [shouldFetch, dispatch, minimalVariantIds, campaignId])

  return [hasFetchedVariants, isFetching]
}

export default useFetchCampaignVariants
