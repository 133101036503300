import { buildTranslate } from 'locales'
import type { AverageCheckData } from 'models/MerchantMetric'
import React from 'react'
import BucketMultiMetric from 'scenes/Reports/ActivityReport/components/BucketMultiMetric'
import { oneYearTrajectory } from 'scenes/Reports/ActivityReport/utilities'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate(
  'reports.activity.sections.transactions.metrics.average_check'
)

type Props = {
  data: AverageCheckData
}

export const AvgCheck: React.FC<Props> = ({ data }) => {
  return (
    <BucketMultiMetric
      title={t('title')}
      summaryText={t('summary_text')}
      bucketText={t('bucket_text')}
      keySuffix="amount"
      valueFormatter={currencyFormatter}
      metrics={[
        {
          header: t('all_customers_text'),
          data: data.overall,
        },
        {
          header: t('quartile_1_text'),
          data: data.quartile_1,
        },
        {
          header: t('quartile_2_text'),
          data: data.quartile_2,
        },
        {
          header: t('quartile_3_text'),
          data: data.quartile_3,
        },
      ]}
      trajectoryCalculator={oneYearTrajectory}
    />
  )
}

export default AvgCheck
