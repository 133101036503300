import { Text } from '@thanx/uikit/text'
import powerCircle from 'assets/images/home/img_powerCircle.svg'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import React from 'react'
import { Col, Row } from 'react-bootstrap-five'
import ActivationRateMetric from './MetricCards/ActivationRateMetric'
import CaptureRateMetric from './MetricCards/CaptureRateMetric'
import EffectiveDiscountRateMetric from './MetricCards/EffectiveDiscountRateMetric'
import RetentionRateMetric from './MetricCards/RetentionRateMetric'

const t = buildTranslate('home.metrics')

type MetricsT = {
  merchant: Merchant
}

const Metrics: React.FC<MetricsT> = ({ merchant }) => {
  return (
    <div className="my-xxxl">
      <Row>
        <Col className="text-center">
          <img className="mb-xs" src={powerCircle} alt="performance" />
          <Text.Header2 className="mt-0" color="grey90">
            {t('title')}
          </Text.Header2>
        </Col>
      </Row>
      <Row className="mt-l row-eq-height">
        <CaptureRateMetric merchant={merchant} />
        <ActivationRateMetric merchant={merchant} />
        <RetentionRateMetric merchant={merchant} />
        <EffectiveDiscountRateMetric merchant={merchant} />
      </Row>
    </div>
  )
}

export default Metrics
