import Counter from 'components/Counter'
import React from 'react'
import { diffPercentage, reportPercentage } from 'scenes/Reports/helpers'

type PropsT = {
  currentRate?: number | null
  previousRate?: number | null
  isErrored?: boolean
  isLoading?: boolean
  inverted?: boolean
}

const MetricCounter: React.FC<PropsT> = ({
  currentRate,
  previousRate,
  isErrored,
  isLoading,
  inverted,
}) => {
  const rateDiffToPreviousPeriod = diffPercentage(
    currentRate,
    previousRate,
    inverted
  )

  return (
    <Counter
      primaryValue={reportPercentage(currentRate)}
      isLoading={isLoading}
      isErrored={isErrored}
      secondaryValue={rateDiffToPreviousPeriod?.text}
      secondaryValueColor={rateDiffToPreviousPeriod?.color}
    />
  )
}

export default MetricCounter
