/**
 * Extracts and normalizes search terms from a search string
 * @param search - The search string to process
 * @returns Array of lowercase search terms with extra whitespace removed
 * @example
 * extractSearchTerms("  React  TypeScript  ") // returns ["react", "typescript"]
 * extractSearchTerms(null) // returns []
 */
export const extractSearchTerms = (search: string | null): string[] => {
  if (!search) return []
  return search.replace(/\s+/g, ' ').trim().toLowerCase().split(' ')
}

/**
 * Checks if an item matches all search terms within its searchable text
 * @param item - The item to search within
 * @param searchQuery - The search string to match against
 * @param getSearchableText - Function that returns the searchable text from the item
 * @returns True if all search terms are found in the item's searchable text
 * @example
 * const item = { title: "React TypeScript Tutorial" };
 * matchesSearch(item, "react type", item => item.title); // returns true
 */
export const matchesSearch = <T>(
  item: T,
  searchQuery: string | null,
  getSearchableText: (item: T) => string
): boolean => {
  const searchTerms = extractSearchTerms(searchQuery)
  if (searchTerms.length === 0) return true

  const text = getSearchableText(item).toLowerCase()
  return searchTerms.every(term => text.includes(term))
}
