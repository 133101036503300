import { alert } from 'actions/flash'
import {
  previewFinePrint,
  PREVIEW_FINE_PRINT_FAIL,
  ResolvedAction,
  UpdateData,
  updatePointsProduct,
  UPDATE_FAIL,
} from 'actions/pointsProducts'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import debounce from 'lodash/debounce'
import omitBy from 'lodash/omitBy'
import { Fields as PointsProduct } from 'models/PointsProduct'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import { selectPointsProduct } from 'selectors/pointsProduct'
import { selectRedeemTemplatesByState } from 'selectors/redeemTemplate'

const t = buildTranslate('points.create_edit')

type EditContextT = {
  id?: string | number
  isUpdating: boolean
  onPublish: () => void
  redeemTemplate: RedeemTemplate | null
  pointsProduct: PointsProduct | null
  template: PointsProduct | null
  updateTemplate: (values: Partial<PointsProduct>) => void
}

export const EditContext = React.createContext<EditContextT>({
  isUpdating: false,
  pointsProduct: null,
  redeemTemplate: null,
  template: null,
  onPublish: () => {},
  updateTemplate: () => {},
})

type Props = {
  id: number | string | undefined
  children: React.ReactNode
}

type Updates = Pick<
  PointsProduct,
  | 'cost'
  | 'redemption_venue'
  | 'exchange_start_at'
  | 'exchange_end_at'
  | 'redeem_retire_after_days'
  | 'redeem_experience_expires_in_days'
  | 'redeem_fine_print'
  | 'redeem_maximum'
  | 'redeem_type'
>

function getUpdates(template: Partial<PointsProduct>): Partial<Updates> {
  return omitBy(
    {
      redeem_template_id: template.redeem_template_id,
      cost: template.cost,
      redemption_venue: template.redemption_venue,
      exchange_start_at: template.exchange_start_at,
      exchange_end_at: template.exchange_end_at,
      redeem_retire_after_days: template.redeem_retire_after_days,
      redeem_experience_expires_in_days:
        template.redeem_experience_expires_in_days,
      redeem_fine_print: template.redeem_fine_print,
      redeem_maximum: template.redeem_maximum,
      redeem_type: template.redeem_type,
    },
    v => v === undefined
  )
}

type PreviewUpdates = Pick<
  PointsProduct,
  | 'redeem_experience_expires_in_days'
  | 'redeem_fine_print'
  | 'redeem_maximum'
  | 'redeem_retire_after_days'
  | 'fine_print'
  | 'redeem_type'
>

function getPreviewUpdates(
  template: Partial<PointsProduct>
): Partial<PreviewUpdates> {
  return omitBy(
    {
      redeem_template_id: template.redeem_template_id,
      redeem_experience_expires_in_days:
        template.redeem_experience_expires_in_days,
      redeem_fine_print: template.redeem_fine_print,
      redeem_maximum: template.redeem_maximum,
      redeem_retire_after_days: template.redeem_retire_after_days,
      fine_print: template.fine_print,
      redeem_type: template.redeem_type,
    },
    v => v === undefined
  )
}

export const EditContextProvider: React.FC<Props> = props => {
  const { id, children } = props
  const pointsProduct = useSelector(state => selectPointsProduct(state, id))
  const redeemTemplates = useSelector(state =>
    selectRedeemTemplatesByState(state, 'published')
  )

  const [template, setTemplate] = useState<PointsProduct | null>(pointsProduct)
  const [isLoaded, setIsLoaded] = useState(false)
  const [redeemTemplate, setRedeemTemplate] = useState<RedeemTemplate | null>(
    null
  )
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const dispatch = useDispatch()

  async function updatePreviewImpl(template: PointsProduct) {
    if (!template?.id) {
      return
    }

    const previewUpdates = getPreviewUpdates(template)

    const response = (await dispatch(
      previewFinePrint(template?.id, previewUpdates)
    )) as any

    if (response.type === PREVIEW_FINE_PRINT_FAIL) {
      return
    }

    setTemplate({
      ...template,
      fine_print: response.payload.data.points_product.fine_print,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updatePreview = useCallback(debounce(updatePreviewImpl, 750), [])

  const updateTemplate = useCallback(
    (updateValues: Partial<PointsProduct>) => {
      if (!template) {
        return
      }

      const updates = getUpdates(updateValues)

      // If we changed the redeem template, we need to also update the
      // redemption venue according to that new template
      if (
        updateValues.redeem_template_id &&
        updateValues.redeem_template_id !== template.redeem_template_id
      ) {
        const redeemTemplate = redeemTemplates.find(
          rt => rt.id === updateValues.redeem_template_id
        )
        if (redeemTemplate) {
          updates.redemption_venue = redeemTemplate.redemption_venue
        }
      }

      const newTemplate = {
        ...template,
        ...updates,
      }

      setTemplate(newTemplate)

      updatePreview(newTemplate)
    },
    [redeemTemplates, template, updatePreview]
  )

  useEffect(() => {
    if (redeemTemplates.length <= 0) {
      return
    }

    if (
      template?.redeem_template_id &&
      redeemTemplate?.id !== template?.redeem_template_id
    ) {
      setRedeemTemplate(
        redeemTemplates.find(
          option => option.id === template.redeem_template_id
        ) as RedeemTemplate
      )
    } else if (template?.state !== 'active' && !template?.redeem_template_id) {
      updateTemplate({
        redeem_template_id: redeemTemplates[redeemTemplates.length - 1].id,
      })
    }
  }, [
    redeemTemplate,
    redeemTemplates,
    template?.state,
    template?.redeem_template_id,
    updateTemplate,
  ])

  const onPublish = async () => {
    if (!template || !id) {
      return
    }

    setIsUpdating(true)
    const updatedTemplate = {
      ...getUpdates(template),
      redeem_template_id: template.redeem_template_id,
      state: 'active',
    } as Partial<UpdateData>

    const result: ResolvedAction = (await dispatch(
      updatePointsProduct(Number(id), updatedTemplate)
    )) as any

    const publish =
      pointsProduct?.state === 'active' ? 'publish_changes' : 'publish'
    // handle some error from the endpoint
    if (result.type === UPDATE_FAIL) {
      dispatch(
        alert({
          key: 'danger',
          message: t(`${publish}.failed`),
        })
      )
      setIsUpdating(false)
      return
    }
    dispatch(push('/points#marketplace'))
    // display success message and close the modal
    dispatch(
      alert({
        key: 'success',
        message: t(`${publish}.success`),
        timeout: 5,
      })
    )
    setIsUpdating(false)
  }

  useEffect(() => {
    if (pointsProduct && !template && !isLoaded) {
      setIsLoaded(true)
      setTemplate(pointsProduct)
    }
  }, [isLoaded, pointsProduct, template])

  return (
    <EditContext.Provider
      value={{
        id,
        isUpdating,
        onPublish,
        pointsProduct,
        template,
        updateTemplate,
        redeemTemplate,
      }}
    >
      {children}
    </EditContext.Provider>
  )
}
