import { Text } from '@thanx/uikit/text'
import React from 'react'
import { useStyletron } from 'styletron-react'
import { merchantTheme } from 'theme'

type PropsT = {
  title: string
  icon: string
  className?: string
  children: React.ReactNode
}

const Section: React.FC<PropsT> = ({
  title,
  icon,
  className,
  children,
}: PropsT) => {
  const [css] = useStyletron()

  return (
    <div className={`p-m white-bkg ${className} border grey-20-border rounded`}>
      <div className={'d-inline-flex align-items-center mb-m'}>
        <div
          className={`d-flex align-items-center justify-content-center p-xxs ${css(
            {
              height: '32px',
              width: '32px',
              borderRadius: '16px',
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: merchantTheme.colors.grey90,
            }
          )}`}
        >
          <i className={`fa fa-${icon} fa-2x font-size-14`} />
        </div>
        <Text.Header4 className={'ml-xs'}>{title}</Text.Header4>
      </div>
      <div className={'d-block'}>{children}</div>
    </div>
  )
}

export default Section
