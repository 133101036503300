import { Switch } from '@thanx/uikit/switch'
import PopoverMenu from 'components/PopoverMenu'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import { Fields as Profile } from 'models/UserProfile'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap-five'
import BlockUserModal from './BlockUserModal'
import ClawbackPointsModal from './ClawbackPointsModal'
import GrantPurchaseModal from './GrantPurchaseModal'
import GrantTierModal from './GrantTierModal'

type Props = {
  profile: Profile
}

const t = buildTranslate('users.view.actions')

const ActionsMenu: React.FC<Props> = ({ profile }) => {
  const [showBlockUserModal, setShowBlockUserModal] = useState(false)
  const [showGrantPurchaseModal, setShowGrantPurchaseModal] = useState(false)
  const [showClawbackPointsModal, setShowClawbackPointsModal] = useState(false)
  const [isGrantTierModalOpen, setIsGrantTierModalOpen] = useState(false)
  const enableClawbackPoints = useFlag('mx-clawback-points', false)

  const canDisable = ['enrolled', 'favorited'].some(
    state => profile.membership_state === state
  )

  return (
    <>
      <PopoverMenu dismissOnClickOutside closeOnClick>
        <Button
          className="d-block grey-90 pr-m"
          variant="link"
          onClick={() => setIsGrantTierModalOpen(true)}
        >
          {t('edit_tier')}
        </Button>
        <Button
          className="d-block grey-90 pr-m"
          variant="link"
          onClick={() => setShowGrantPurchaseModal(true)}
        >
          {t('grant_purchase')}
        </Button>
        <Switch condition={enableClawbackPoints}>
          <Button
            className="d-block grey-90 pr-m"
            variant="link"
            onClick={() => setShowClawbackPointsModal(true)}
          >
            {t('remove_points')}
          </Button>
        </Switch>
        <Button
          className="d-block grey-90 pr-m"
          variant="link"
          disabled={!canDisable}
          onClick={() => setShowBlockUserModal(true)}
        >
          {t('block_user')}
        </Button>
      </PopoverMenu>
      <BlockUserModal
        profile={profile}
        isOpen={showBlockUserModal}
        onClose={() => setShowBlockUserModal(false)}
      />
      <GrantTierModal
        profile={profile}
        visible={isGrantTierModalOpen}
        setIsVisible={setIsGrantTierModalOpen}
      />
      <GrantPurchaseModal
        profile={profile}
        visible={showGrantPurchaseModal}
        onClose={() => setShowGrantPurchaseModal(false)}
      />
      <ClawbackPointsModal
        profile={profile}
        visible={showClawbackPointsModal}
        onClose={() => setShowClawbackPointsModal(false)}
      />
    </>
  )
}

export default ActionsMenu
