import { Switch } from '@thanx/uikit/switch'
import Badges from 'components/Badges'
import React from 'react'
import { useIsMobile } from 'utilities/responsive'

type Props = {
  title?: string
  subtitle?: string
  description: string
  children: React.ReactNode
  className?: string
  badges?: string[]
}

const Asset: React.FC<Props> = props => {
  const {
    title,
    subtitle,
    description,
    children,
    className = '',
    badges = [],
  } = props
  const isMobile = useIsMobile()

  return (
    <div className={className}>
      <Switch condition={!!title}>
        <p className="body-text-4 bold">{title}</p>
      </Switch>
      <div className={`d-flex ${isMobile ? 'flex-column' : ''}`}>
        {children}
        <div className={`flex-row ${isMobile ? 'mt-s' : 'ml-xl'}`}>
          <Switch condition={!!subtitle}>
            <p className="font-size-3 grey-70 mb-xs">{subtitle}</p>
          </Switch>
          <p
            className="body-text-4 grey-70"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
          <Switch condition={badges.length > 0}>
            <Badges className="mt-s" values={badges} />
          </Switch>
        </div>
      </div>
    </div>
  )
}

export default Asset
