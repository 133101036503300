import { UserStatsMetric } from 'models/MerchantMetric'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import MetricSection from '../../components/MetricSection'
import SectionHeader from '../../components/SectionHeader'
import Funnel from './metrics/Funnel'
import Membership from './metrics/Membership'
import TotalCustomers from './metrics/TotalCustomers'

type Props = {
  userStats?: UserStatsMetric
}

const Customers: React.FC<Props> = ({ userStats }) => {
  const userStatsData = userStats?.data
  const membership = userStatsData?.new_membership && (
    <Membership data={userStatsData.new_membership} />
  )

  const funnel = userStatsData?.membership_funnel && (
    <Funnel data={userStatsData.membership_funnel} />
  )

  return (
    <div className="report-container">
      <SectionHeader
        title={I18n.t('reports.activity.sections.customers.title')}
      />
      {!!userStatsData?.membership_funnel ? (
        <TotalCustomers data={userStatsData?.membership_funnel} />
      ) : null}
      <MetricSection leftMetric={funnel} rightMetric={membership} />
    </div>
  )
}

export default Customers
