import Counter from 'components/Counter'
import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import moment from 'moment-timezone'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { sortChannelData } from 'scenes/Reports/CaptureRateReport/utilities'
import { reportCurrency } from 'scenes/Reports/helpers'
import { selectChannelTimeSeriesMetric } from 'selectors/captureRateMetric'

type PropT = {
  merchant: Merchant
}

const t = buildTranslate('revenue.tabs.revenue')

const Current: React.FC<PropT> = ({ merchant }) => {
  const { currentLocation } = useContext(LocationContext)

  const metrics = useSelector(state =>
    selectChannelTimeSeriesMetric(state, merchant.id, currentLocation?.id)
  )

  const { isErrored, isLoading } = metrics || {}
  let data
  if (metrics?.values)
    data = sortChannelData(metrics.values, {
      attribute: 'date_time',
      direction: 'desc',
    })[0]
  const month = data?.date_time
    ? moment(data?.date_time).format('MMM YYYY')
    : moment().subtract(1, 'month').format('MMM YYYY')

  return (
    <div className="d-flex mb-xl">
      <Counter
        primaryValue={reportCurrency(data?.member_purchases_total_amount)}
        name={t('stats.revenue', { month })}
        isErrored={isErrored}
        isLoading={isLoading}
        sections={[
          { content: t('popovers.revenue_summary_popover.section_1') },
          { content: t('popovers.revenue_summary_popover.section_2') },
          {
            title: t('popovers.example'),
            content: t('popovers.revenue_summary_popover.section_3'),
          },
        ]}
      />
    </div>
  )
}

export default Current
