import { buildTranslate } from 'locales'
import React, { useState } from 'react'

const t = buildTranslate('components.text_expander')

type Props = {
  items: Array<string>
  itemNounSingular: string
  itemNounPlural: string
}

const TextExpander: React.FC<Props> = props => {
  const { items, itemNounSingular, itemNounPlural } = props

  const [isExpanded, setIsExpanded] = useState(false)

  const expandItems = e => {
    e.preventDefault()
    setIsExpanded(true)
  }

  if (items.length === 0) return null

  if (items.length <= 1) return <>{items[0]}</>

  if (isExpanded) {
    return (
      <>
        {items.map((item, i) => (
          <div key={i} className={i > 0 ? 'mt-s' : ''}>
            {item}
          </div>
        ))}
      </>
    )
  }

  const itemNoun = items.length === 2 ? itemNounSingular : itemNounPlural
  const expandText = t('show_more', { count: items.length - 1, noun: itemNoun })

  return (
    <>
      {items[0]}{' '}
      <a href="/#" onClick={e => expandItems(e)}>
        {expandText}
      </a>
    </>
  )
}

export default TextExpander
