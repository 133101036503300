import { getCampaigns } from 'actions/campaigns'
import { getCampaignType } from 'actions/campaignTypes'
import Spinner from 'components/Spinner'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { push } from 'react-router-redux'
import { selectCampaignsByTypeAndState } from 'selectors/campaign'
import {
  selectCampaignTypeByType,
  selectCampaignTypes,
} from 'selectors/campaignType'
import Daypart from './Daypart'
import EngageVip from './EngageVip'
import LocationClose from './LocationClose'
import LocationMessage from './LocationMessage'
import LocationPromote from './LocationPromote'
import LocationReopen from './LocationReopen'
import Message from './Message'
import Reputation from './Reputation'
import Upsell from './Upsell'
import UpsellRestricted from './UpsellRestricted'
import Weekpart from './Weekpart'
import Winback from './Winback'

import type { Fields as CampaignType } from 'models/CampaignType'

type Props = {
  children?: React.ReactElement
  primaryButtonClick?: () => void
  className?: string
  campaignType?: string
  campaignCategory?: string
  withinBuilder?: boolean
  onChildClick?: () => void
  disabled?: boolean
  openOnInit?: boolean
  onOpen?: VoidFunction
}

const InfoModal: React.FC<Props> = props => {
  const {
    children,
    primaryButtonClick,
    className = '',
    campaignType,
    campaignCategory = '',
    withinBuilder = false,
    disabled = false,
    openOnInit = false,
    onOpen,
  } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const merchant = useCurrentMerchant()
  const campaignTypes = useSelector(state =>
    //@ts-ignore
    selectCampaignTypes(state)
  )
  const campaignTypeModel = useSelector(state =>
    selectCampaignTypeByType(state, campaignType)
  ) as CampaignType
  const campaigns = useSelector(state => {
    if (isEmpty(campaignTypes)) return []
    return selectCampaignsByTypeAndState(state, campaignType || '', 'draft')
  })

  const isRestricted = !campaignTypeModel
    ? false
    : campaignTypeModel.state === 'restricted'

  const openModal = useCallback(async () => {
    onOpen && onOpen()
    setModalOpen(true)
    if (!withinBuilder && campaignType && isEmpty(campaigns) && !isLoading) {
      setIsLoading(true)
      await dispatch(getCampaignType(campaignType))
      await dispatch(getCampaigns({ type: campaignType }))
      setIsLoading(false)
    }
  }, [campaignType, campaigns, dispatch, isLoading, onOpen, withinBuilder])

  useEffect(() => {
    if (openOnInit) openModal()
  }, [openModal, openOnInit])

  function child() {
    const content = getContent()
    if (!children) return null
    if (disabled) return children
    if (!content) {
      if (!children || !primaryButtonClick) return null
      return React.cloneElement(children, {
        onClick: primaryButtonClick,
      })
    }
    return React.cloneElement(children, {
      onClick: onChildClick,
    })
  }

  function getRestrictedContent() {
    if (!isRestricted) return null
    switch (campaignType) {
      case 'upsell':
        return <UpsellRestricted />
      default:
        return null
    }
  }

  function getContent() {
    if (!campaignType) return null
    const restrictedContent = getRestrictedContent()
    if (restrictedContent) return restrictedContent

    switch (campaignType) {
      case 'message':
        return <Message />
      case 'timeshift-morning':
      case 'timeshift-midday':
      case 'timeshift-afternoon':
      case 'timeshift-evening':
      case 'timeshift-night':
        return <Daypart />
      case 'timeshift-weekday':
      case 'timeshift-weekend':
        return <Weekpart />
      case 'reputation':
        return <Reputation />
      case 'winback':
        return <Winback />
      case 'upsell':
        return <Upsell />
      case 'location-message':
        return <LocationMessage />
      case 'location-close':
        return <LocationClose />
      case 'location-reopen':
        return <LocationReopen />
      case 'location-promote':
        return <LocationPromote />
      case 'engage-vip':
        return <EngageVip merchantName={merchant?.name || ''} />
      default:
        return null
    }
  }

  function closeModal(e?) {
    setModalOpen(false)
    setIsLoading(false)
    if (e && e.currentTarget.href) window.location.href = e.currentTarget.href
  }

  function modal() {
    if (!getContent()) return null
    return (
      <Modal
        show={modalOpen}
        onHide={closeModal}
        dialogClassName="modal-800"
        className="fs-unmask"
      >
        <Modal.Body className="text-center info-modal">
          <div className="clearfix">
            <div className="pull-right">
              <Button
                onClick={closeModal}
                className="btn-borderless padding-right-none"
              >
                <span className="font-size-40 line-height-30 grey-60 light">
                  &times;
                </span>
              </Button>
            </div>
          </div>

          {getContent()}
          {buttons()}
        </Modal.Body>
      </Modal>
    )
  }

  function buttons() {
    if (isRestricted) return null

    if (withinBuilder)
      return (
        <Button
          bsSize="large"
          className={`full-width category-${campaignCategory}-bkg grey-40-hover-bkg transparent-border`}
          onClick={onClick}
        >
          <Translate
            value="thanx_campaigns.builder.info_modal.button.continue"
            className="white"
          />
        </Button>
      )

    return (
      <Spinner isLoading={isLoading} size="2x">
        {draftButtons()}
      </Spinner>
    )
  }

  function onClick() {
    if (primaryButtonClick) primaryButtonClick()
    closeModal()
  }

  function onChildClick() {
    if (props.onChildClick) props.onChildClick()
    openModal()
  }

  function draftButtons() {
    if (!isEmpty(campaigns)) {
      return (
        <div>
          <Button
            bsSize="large"
            className={`full-width category-${campaignCategory}-bkg grey-40-hover-bkg transparent-border`}
            onClick={onDraftClick}
          >
            <Translate
              value="thanx_campaigns.builder.info_modal.button.draft"
              className="white"
            />
          </Button>
          <Button
            bsSize="large"
            bsStyle="link"
            className={`full-width category-${campaignCategory} transparent-border`}
            onClick={onClick}
          >
            <Translate value="thanx_campaigns.builder.info_modal.button.create_new" />
          </Button>
        </div>
      )
    }

    return (
      <Button
        bsSize="large"
        className={`full-width category-${campaignCategory}-bkg grey-40-hover-bkg transparent-border`}
        onClick={onClick}
      >
        <Translate
          value="thanx_campaigns.builder.info_modal.button.create_initial"
          className="white"
        />
      </Button>
    )
  }

  function onDraftClick() {
    const id = sortBy(campaigns, campaign => -campaign.id).filter(
      campaign => campaign.type === campaignType
    )[0].id
    if (campaignType && campaignCategory && id) {
      dispatch(
        push(`/thanx_campaigns/${campaignCategory}/${campaignType}/${id}`)
      )
    }

    closeModal()
  }

  return (
    <div className={`inline-block ${className}`}>
      {child()}
      {modal()}
    </div>
  )
}

export default InfoModal
