import { Fields } from 'models/IntegrationsConfig'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

export const GET_INTEGRATIONS = 'integrations/GET_INTEGRATIONS'
export const GET_INTEGRATIONS_SUCCESS = 'integrations/GET_INTEGRATIONS_SUCCESS'
export const GET_INTEGRATIONS_FAIL = 'integrations/GET_INTEGRATIONS_FAIL'

type GetAction = AxiosAction<typeof GET_INTEGRATIONS>

type GetResolvedAction =
  | FailureAction<typeof GET_INTEGRATIONS_FAIL>
  | SuccessAction<
      typeof GET_INTEGRATIONS_SUCCESS,
      {
        merchant_id: number
        integrations_config: Fields['integrations_config']
      },
      GetAction
    >

export function getIntegrations(): GetAction {
  return {
    type: GET_INTEGRATIONS,
    payload: {
      client: 'default',
      request: {
        url: 'integrations',
      },
    },
  }
}

export type ResolvedAction = GetResolvedAction
