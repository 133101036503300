import React from 'react'
import { Container } from 'react-bootstrap-five'
import Tab from 'react-bootstrap-five/Tab'

type Props = {
  state: string
  children: React.ReactNode
}

const TabPane: React.FC<Props> = props => {
  const { state, children } = props

  return (
    <Tab.Pane className="py-xl grey-05-bkg" eventKey={state}>
      <Container className="px-0">{children}</Container>
    </Tab.Pane>
  )
}

export default TabPane
