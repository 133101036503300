import NoRewardIcon from 'assets/images/campaign-center/builder/img-no_reward.svg'
import EmptyContentSection from 'components/review/EmptyContentSection'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('thanx_campaigns.review.incentive')

type Props = {
  isEditable: boolean
  onAdd: () => void
}

const NoIncentive: React.FC<Props> = ({ isEditable, onAdd }) => (
  <EmptyContentSection
    icon={NoRewardIcon}
    iconAlt="no-reward"
    isEditable={isEditable}
    onAdd={onAdd}
    addText={t('add')}
    text={t('missing')}
  />
)

export default NoIncentive
