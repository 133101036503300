import bonusPoints1 from 'assets/images/redeem-manager/bonus_points_1.png'
import goldenTicket1 from 'assets/images/redeem-manager/golden_ticket_1.png'
import goldenTicket2 from 'assets/images/redeem-manager/golden_ticket_2.png'
import goldenTicket3 from 'assets/images/redeem-manager/golden_ticket_3.png'
import hiddenMenu1 from 'assets/images/redeem-manager/secret_menu_1.png'
import hiddenMenu2 from 'assets/images/redeem-manager/secret_menu_2.png'
import hiddenMenu3 from 'assets/images/redeem-manager/secret_menu_3.png'

import {
  bonusPointsHelpUrl,
  goldenTicketHelpUrl,
  hiddenMenuHelpUrl,
} from 'utilities/urlUtils'

export const getContent = type => {
  switch (type) {
    case 'hidden_menu': {
      return {
        images: [hiddenMenu1, hiddenMenu2, hiddenMenu3],
        link: hiddenMenuHelpUrl,
      }
    }
    case 'golden_ticket': {
      return {
        images: [goldenTicket1, goldenTicket2, goldenTicket3],
        link: goldenTicketHelpUrl,
      }
    }
    case 'bonus_points': {
      return {
        images: [bonusPoints1],
        link: bonusPointsHelpUrl,
      }
    }
    default:
      return {
        images: [],
        link: '#',
      }
  }
}
