import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import Review from 'scenes/CampaignCenter/Builder/Steps/Shared/Review'
import AudienceContainer from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Audience'
import AudienceBreakoutModal from './components/Audience/components/AudienceBreakoutModal'

type Props = {
  campaign: Campaign
  setCurrentStep: (step: string) => void
}

const LocationReview: React.FC<Props> = props => {
  const { campaign, setCurrentStep } = props
  return (
    <Review
      campaign={campaign}
      setCurrentStep={setCurrentStep}
      audience={
        <AudienceContainer
          campaign={campaign}
          setCurrentStep={setCurrentStep}
          renderAudienceBreakoutModal={props => (
            <AudienceBreakoutModal {...props} campaign={campaign} />
          )}
        />
      }
    />
  )
}

export default LocationReview
