import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { createContext, useContext, useState } from 'react'

type ContextT = {
  attributionWindow: number
  setAttributionWindow: (value: number) => void
}

const Context = createContext<ContextT | null>(null)

export function useAttributionWindow(): ContextT {
  const value = useContext(Context)

  if (value === null) {
    const errorInfo =
      'useAttributionWindow must be used within a AttributionWindowContext.Provider'
    throw errorInfo
  }

  return value
}

interface PropsT {
  children: React.ReactNode
}

const getStorageKey = merchant =>
  `merchant_${merchant.id}_campaign_report_attribution_window`

export function AttributionWindowProvider(props: PropsT) {
  const { children } = props

  const merchant = useCurrentMerchant()
  const defaultWindow = parseInt(
    window.localStorage.getItem(getStorageKey(merchant)) || '14'
  )
  const [attributionWindow, setAttributionWindow] =
    useState<number>(defaultWindow)

  const updateWindow = (attributionWindow: number) => {
    if (!merchant || !attributionWindow) {
      return
    }

    window.localStorage.setItem(
      getStorageKey(merchant),
      attributionWindow.toString()
    )
    setAttributionWindow(attributionWindow)
  }

  return (
    <Context.Provider
      value={{ attributionWindow, setAttributionWindow: updateWindow }}
    >
      {children}
    </Context.Provider>
  )
}
