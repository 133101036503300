export default {
  title: 'Stored value',
  balance: 'Balance',
  transactions_link: "View activity in provider's dashboard",
  transactions: {
    empty_state: 'This customer has no stored value activity',
    table: {
      date: 'Date',
      activity: 'Activity',
      description: {
        auto_reload:
          'Automatically reloaded $%{amount} to stored value balance',
        add: 'Manually reloaded $%{amount} to stored value balance',
        use: 'Spent $%{amount} from stored value balance',
        refund: 'Refunded $%{amount} to stored value balance',
        bonus: 'Reloaded bonus $%{amount}',
      },
    },
  },
}
