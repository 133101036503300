import { getCampaign } from 'actions/campaigns'
import {
  createCampaignVariant,
  deleteCampaignVariant,
} from 'actions/campaignVariants'
import { AxiosResponse } from 'axios'
import useDispatch from 'hooks/useDispatch'
import { useState } from 'react'

export type VariantAction =
  | 'multiVariant'
  | 'removeVariant'
  | 'addVariant'
  | 'noChange'

export const useVariantSelectionHandler = () => {
  const dispatch = useDispatch()
  const [response, setResponse] = useState<AxiosResponse | undefined>()

  const submitVariant = async ({ action, campaignId, variantIds }) => {
    let response
    switch (action) {
      case 'multiVariant':
        response = await Promise.all([
          dispatch(createCampaignVariant(campaignId)),
          dispatch(createCampaignVariant(campaignId)),
        ])
        await dispatch(getCampaign(campaignId))
        setResponse(response)
        break
      case 'addVariant':
        response = await dispatch(createCampaignVariant(campaignId))
        setResponse(response)
        break
      case 'removeVariant':
        response = await Promise.all(
          variantIds.map(variantId =>
            dispatch(deleteCampaignVariant(campaignId, variantId))
          )
        )
        setResponse(response)
        break
      case 'noChange':
        break
    }
  }

  return { submitVariant, response }
}
