import { Switch } from '@thanx/uikit/switch'
import { alert } from 'actions/flash'
import PopoverMenu from 'components/PopoverMenu'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap-five'
import { useDispatch } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import ActivateModal from './Modals/ActivateModal'
import ArchiveModal from './Modals/ArchiveModal'
import DeleteModal from './Modals/DeleteModal'

import { Fields as RedeemTemplate } from 'models/RedeemTemplate'

type Props = {
  template: RedeemTemplate
}

const t = buildTranslate('redeem_manager.actions')

const ActionsMenu: React.FC<Props> = props => {
  const { template } = props
  const { id: templateId, name, state } = template
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false)
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const dispatch = useDispatch()

  function showSuccessMessage(message: string) {
    dispatch(
      alert({
        key: 'success',
        message: message,
        timeout: 5,
      })
    )
  }

  return (
    <>
      <ActivateModal
        templateId={templateId}
        name={name}
        isOpen={isActivateModalOpen}
        onActivate={() => {
          showSuccessMessage(
            t('activate.success', {
              name,
            })
          )
          setIsActivateModalOpen(false)
        }}
        onClose={() => setIsActivateModalOpen(false)}
      />
      <ArchiveModal
        templateId={templateId}
        name={name}
        isOpen={isArchiveModalOpen}
        onArchive={() => {
          showSuccessMessage(
            t('archive.success', {
              name,
            })
          )
          setIsArchiveModalOpen(false)
        }}
        onClose={() => setIsArchiveModalOpen(false)}
      />
      <DeleteModal
        templateId={templateId}
        name={name}
        isOpen={isDeleteModalOpen}
        onDelete={() => {
          showSuccessMessage(
            t('delete.success', {
              name,
            })
          )
          setIsDeleteModalOpen(false)
        }}
        onClose={() => setIsDeleteModalOpen(false)}
      />
      <PopoverMenu dismissOnClickOutside>
        <Switch condition={state !== 'unpublished'}>
          <LinkContainer
            className="text-left"
            to={`/rewards/${template.id}/edit`}
          >
            <Button className="d-block grey-90 pr-m" variant="link">
              {t('edit')}
            </Button>
          </LinkContainer>
        </Switch>
        <Switch condition={state === 'published'}>
          <Button
            className="d-block grey-90 pr-m"
            variant="link"
            onClick={() => setIsArchiveModalOpen(true)}
          >
            {t('archive.action')}
          </Button>
        </Switch>
        <Switch condition={state === 'unpublished'}>
          <Button
            className="d-block grey-90 pr-m"
            variant="link"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            {t('delete.action')}
          </Button>
        </Switch>
        <Switch condition={state === 'archived'}>
          <Button
            className="d-block grey-90 pr-m"
            variant="link"
            onClick={() => setIsActivateModalOpen(true)}
          >
            {t('activate.action')}
          </Button>
        </Switch>
      </PopoverMenu>
    </>
  )
}

export default ActionsMenu
