import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'
import type { Fields as Campaign } from '../models/Campaign'
import type { Fields as CampaignVariant } from '../models/CampaignVariant'
import { selectCampaign } from './campaign'
import { selectCampaignVariantsByCampaignId } from './campaignVariant'

export const selectCampaignConfig = createSelector(
  (state: any, campaignId: number): Campaign | null =>
    selectCampaign(state, campaignId),
  (state: any, campaignId: number): CampaignVariant[] | null =>
    selectCampaignVariantsByCampaignId(state, campaignId),
  (_: any, _campaignId: number, variantId?: number): number | undefined =>
    variantId,
  (
    campaign,
    variants,
    variantId: number | undefined
  ): CampaignVariant | Campaign | null => {
    if (isEmpty(variants)) return campaign
    if (variantId) {
      const config = variants!.find(v => v.id === variantId)
      return !!config ? config : campaign
    }

    if (variants?.length === 1) return variants![0]

    return campaign
  }
)
