import { Switch } from '@thanx/uikit/switch'
import { getCampaignTypes } from 'actions/campaignTypes'
import automated_icon from 'assets/images/campaign-center/landing/automated/banner_image.png'
import speakerphone_image from 'assets/images/campaign-center/landing/overview/speakerphone.png'
import dustImage from 'assets/images/campaign-center/landing/overview/texture_dust.png'
import targeted_icon from 'assets/images/campaign-center/landing/targeted/banner_image.png'
import Loading from 'components/Loading'
import withMerchantMetrics, {
  MetricsProps,
} from 'components/metrics/withMerchantMetrics'
import Spacer from 'components/Spacer'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Helmet from 'react-helmet'
import { Translate } from 'react-redux-i18n'
import { LinkContainer } from 'react-router-bootstrap'
import { history } from 'store'
import MessageContent from './MessageContent'
import TabBox from './TabBox'
import TargetContent from './TargetContent'
import TextRotation from './TextRotation'

const t = buildTranslate('thanx_campaigns.landing.overview')

type SelectionType = 'message' | 'target'

export const Landing: React.FC<MetricsProps> = ({
  merchantMetrics,
  merchantMetricStatuses,
}) => {
  const queryParams = history.location.search
  const initialTab =
    queryParams?.split('=')?.at(-1) === 'message' ? 'message' : 'target'
  const [isLoading, setIsLoading] = useState(true)
  const [showMessageModal, setShowMessageModal] = useState(
    initialTab === 'message'
  )
  const [selected, setSelected] = useState<SelectionType>(initialTab)
  const dispatch = useDispatch()

  const metricsData = merchantMetrics?.user_count?.data

  useEffect(() => {
    async function fetchData() {
      await dispatch(getCampaignTypes({ category: 'targeted' }))
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="overview-landing">
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div
        className="header spearmint-bkg"
        style={{ backgroundImage: `url(${dustImage})` }}
      >
        <div className="container">
          <div className="header-text white">
            <Translate
              value="thanx_campaigns.title"
              tag="p"
              className="small-caps-4 margin-bottom-extra-large"
            />
            <Translate
              dangerousHTML
              value="thanx_campaigns.landing.overview.subtitle"
              className="white body-text-1 margin-bottom-none"
              tag="p"
            />
            <TextRotation />
          </div>
        </div>
      </div>

      <div className="grey-20-bkg">
        <div className="container">
          <div className="content">
            <div className="display-flex flex-justify-center">
              <div className="tabs display-flex flex-space-between">
                <div
                  className={`relative tab ${
                    selected !== 'message' ? 'unselected' : ''
                  }`}
                  onClick={() => setSelected('message')}
                >
                  <TabBox
                    title={t('message.tab_text')}
                    image={speakerphone_image}
                    alt="message"
                    className="orange-bkg"
                  />
                  <div
                    className="grey-50-bkg opacity-25 absolute padding-large border-radius-2-bottom"
                    style={{
                      zIndex: -1,
                      bottom: '-5px',
                      width: '95%',
                      marginLeft: '2.5%',
                    }}
                  />
                </div>
                <div
                  className={`relative tab ${
                    selected !== 'target' ? 'unselected' : ''
                  }`}
                  onClick={() => setSelected('target')}
                >
                  <TabBox
                    title={t('targeted.tab_text')}
                    image={targeted_icon}
                    alt="targeted"
                    className="wisteria-bkg"
                  />
                  <div
                    className="grey-50-bkg opacity-25 absolute padding-large border-radius-2-bottom"
                    style={{
                      zIndex: -1,
                      bottom: '-5px',
                      width: '95%',
                      marginLeft: '2.5%',
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="spearmint-20-bkg padding-tiny padding-top-small border-radius-2-top center-block"
              style={{ width: '95%', backgroundImage: `url(${dustImage})` }}
            />
            <div className="">
              <Switch
                condition={selected === 'message'}
                fallback={<TargetContent />}
              >
                <MessageContent
                  numEmailSubscribers={metricsData?.subscriber_count || 0}
                  numLoyaltyMembers={metricsData?.member_count || 0}
                  openModalOnLoad={showMessageModal}
                  isLoading={merchantMetricStatuses?.user_count.isLoading}
                  onModalOpen={() => {
                    const activeElement = document.activeElement as HTMLElement
                    activeElement?.blur()
                    setShowMessageModal(false)
                  }}
                />
              </Switch>
              <div className="padding-top-tiny grey-30-bkg border-radius-5-bottom width-99 center-block" />
            </div>
          </div>
          <Spacer paddingTop="80px" />

          <div className="automated-box white-bkg border-radius-2-bottom">
            <div
              className="header spearmint-10-bkg display-flex flex-align-center border-radius-2-top"
              style={{ backgroundImage: `url(${dustImage})` }}
            >
              <p className="small-caps-4 margin-bottom-none grey-80">
                {t('automated.title')}
              </p>
            </div>
            <div className="box-content">
              <Row>
                <Col sm={9} md={6}>
                  <h3 className="deprecated__h3 margin-bottom-medium extra-bold">
                    {t('automated.header')}
                  </h3>
                  <p className="font-size-36 margin-bottom-huge padding-bottom-medium">
                    {t('automated.body')}
                  </p>
                  <LinkContainer to="/thanx_campaigns/automated">
                    <Button
                      bsStyle="primary"
                      className="btn-huge margin-bottom-huge full-width-on-xs"
                    >
                      {t('automated.button')}
                    </Button>
                  </LinkContainer>
                </Col>
                <Col
                  sm={3}
                  md={6}
                  className="display-flex flex-align-center flex-justify-center"
                >
                  <img
                    className="image img-responsive"
                    src={automated_icon}
                    alt="automated"
                  />
                </Col>
              </Row>
            </div>
          </div>
          <div className="padding-top-tiny grey-30-bkg border-radius-5-bottom width-99 center-block" />
          <Spacer paddingTop="116px" />
        </div>
      </div>
    </div>
  )
}

export default withMerchantMetrics(['user_count'])(Landing)
