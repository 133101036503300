import Input from 'components/Form/Input'
import Select from 'components/Form/Select'
import clone from 'lodash/clone'
import flattenDeep from 'lodash/flattenDeep'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import last from 'lodash/last'
import pickBy from 'lodash/pickBy'
import range from 'lodash/range'
import uniq from 'lodash/uniq'
import without from 'lodash/without'
import moment from 'moment-timezone'
import React, { Component } from 'react'
import { Col, ControlLabel, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import { timeUtils } from 'utilities/timeUtils'
import userTimeZone from 'utilities/userTimeZone'
import Day from './Day'

import type { CampaignConfig } from 'models/Campaign'
import type { ElementRef } from 'react'

type HourOption = { value: number; label: string }

type Props = {
  config: CampaignConfig
  isSaving: boolean
  className: string
}

type DayAccumulator = {
  monday: boolean
  tuesday: boolean
  wednesday: boolean
  thursday: boolean
  friday: boolean
  saturday: boolean
  sunday: boolean
}
type State = {
  startOption: HourOption
  endOption: HourOption
  dayAccumulator: DayAccumulator
}

const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export default class TimeAndDayPicker extends Component<Props, State> {
  static defaultProps = {
    className: '',
  }
  // these days are set with this[day], but TS can't see that
  // @ts-ignore
  monday: ElementRef<typeof Input>
  // @ts-ignore
  tuesday: ElementRef<typeof Input>
  // @ts-ignore
  wednesday: ElementRef<typeof Input>
  // @ts-ignore
  thursday: ElementRef<typeof Input>
  // @ts-ignore
  friday: ElementRef<typeof Input>
  // @ts-ignore
  saturday: ElementRef<typeof Input>
  // @ts-ignore
  sunday: ElementRef<typeof Input>
  timer
  days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  constructor(props: Props) {
    super(props)

    const {
      redeem_restriction_monday,
      redeem_restriction_tuesday,
      redeem_restriction_wednesday,
      redeem_restriction_thursday,
      redeem_restriction_friday,
      redeem_restriction_saturday,
      redeem_restriction_sunday,
    } = this.props.config
    let hours = this.hours()
    let dayAccumulator = {}
    if (isEmpty(hours)) {
      DAYS.map(day => (dayAccumulator[day] = true))
    } else {
      dayAccumulator = {
        monday: !isEmpty(redeem_restriction_monday),
        tuesday: !isEmpty(redeem_restriction_tuesday),
        wednesday: !isEmpty(redeem_restriction_wednesday),
        thursday: !isEmpty(redeem_restriction_thursday),
        friday: !isEmpty(redeem_restriction_friday),
        saturday: !isEmpty(redeem_restriction_saturday),
        sunday: !isEmpty(redeem_restriction_sunday),
      }
    }
    this.state = {
      startOption: this.startHourOptions()[hours[0] || 0],
      endOption: this.endHourOptions()[last(hours) || 23],
      dayAccumulator: dayAccumulator as DayAccumulator,
    }
  }

  componentDidMount() {
    this.changeDays()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    this.changeDays()
  }

  inputs = (): Array<React.ReactElement> =>
    DAYS.map(day => (
      <Input
        key={day}
        type="hidden"
        value={this.props.config[`redeem_restriction_${day}`] || []}
        name={`redeem_restriction_${day}`}
        innerRef={el => {
          this[day] = el
          return this[day]
        }}
      />
    ))

  onChangeDay = (day: string, active: boolean) => {
    let accum = clone(this.state.dayAccumulator)
    accum[day] = active
    this.setState({ dayAccumulator: accum })
    clearTimeout(this.timer)
    this.timer = setTimeout(this.changeDays, 1000)
  }

  onChangeStart = ({ value }: HourOption) => {
    this.setState({ startOption: this.startHourOptions()[value] }, () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(this.changeDays, 1000)
    })
  }

  onChangeEnd = ({ value }: HourOption) => {
    this.setState({ endOption: this.endHourOptions()[value] }, () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(this.changeDays, 1000)
    })
  }

  changeDays = () =>
    DAYS.forEach(day => {
      let value
      if (this.state.dayAccumulator[day]) {
        value = range(
          this.state.startOption.value,
          this.state.endOption.value + 1
        )
      } else {
        value = []
      }
      if (this[day]?.props?.setValue) this[day].props.setValue(value)
    })

  hours(): Array<number> {
    const fields = DAYS.map(
      day => this.props.config[`redeem_restriction_${day}`]
    )

    return uniq(flattenDeep(without(fields, undefined))) || []
  }

  hourOptions = (
    isEnd: boolean = false
  ): Array<{ value: number; label: string }> => {
    return range(24).map(hour => {
      let option = { value: hour, label: '' }
      let timezone = moment.tz(userTimeZone()).format('z')
      let label = timeUtils.hourLabel(hour, isEnd, timezone)
      option['label'] = label
      return option
    })
  }

  startHourOptions() {
    return this.hourOptions()
  }

  endHourOptions() {
    return this.hourOptions(true)
  }

  isLastDay(day: string) {
    let activeDays = keys(pickBy(this.state.dayAccumulator))
    if (activeDays.length > 1) return false
    return activeDays[0] === day
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.inputs()}
        <div className="btn-group display-flex margin-bottom-extra-large">
          {DAYS.map(day => (
            <Day
              key={day}
              day={day}
              initialActive={this.state.dayAccumulator[day]}
              onChange={this.onChangeDay}
              isLastDay={this.isLastDay(day)}
            />
          ))}
        </div>

        <Row>
          <Col xs={6}>
            <ControlLabel className="font-size-14 bold">
              {I18n.t(
                'thanx_campaigns.builder.steps.incentive.restriction_creator.day_part.time_start_label'
              )}
            </ControlLabel>
            <div className="padding-top-small">
              <Select
                name="__select"
                options={this.startHourOptions()}
                onChange={this.onChangeStart}
                defaultValue={this.state.startOption}
              />
            </div>
          </Col>
          <Col xs={6}>
            <ControlLabel className="font-size-14 bold">
              {I18n.t(
                'thanx_campaigns.builder.steps.incentive.restriction_creator.day_part.time_end_label'
              )}
            </ControlLabel>
            <Select
              className="padding-top-small"
              name="__select"
              options={this.endHourOptions()}
              onChange={this.onChangeEnd}
              defaultValue={this.state.endOption}
            />
          </Col>
        </Row>
      </div>
    )
  }
}
