import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import numeral from 'numeral'
import React from 'react'
import { I18n } from 'react-redux-i18n'

Highcharts.setOptions({
  lang: {
    decimalPoint: '.',
    thousandsSep: ',',
  },
})

type Props = {
  order: string[]
  data: object[]
  selectedColor: string
  barColor: string
}

const Graph: React.FC<Props> = props => {
  const { order, data, selectedColor, barColor } = props
  const colors = [barColor, selectedColor]
  const options: Highcharts.Options = {
    credits: { enabled: false },
    tooltip: { enabled: false },
    title: {
      text: undefined,
    },
    legend: { enabled: false },
    xAxis: {
      lineColor: 'transparent',
      categories: order,
      labels: {
        useHTML: true,
        align: 'center',
        reserveSpace: true,
        formatter: function () {
          return I18n.t(
            `thanx_campaigns.campaigns.engage_vip.steps.setup.audience.graph.labels.${this.value}`,
            {
              amount: numeral(
                //@ts-ignore
                this.chart.series[0].processedYData[this.pos]
              ).format('$0,0'),
            }
          )
        },
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: {
      visible: false,
    },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        colorByPoint: true,
      },
      series: {
        //@ts-ignore
        minPointLength: 2,
        dataLabels: {
          enabled: true,
          inside: true,
          verticalAlign: 'top',
          y: 20,
          useHTML: true,
          formatter: function () {
            if (this.key === 'vip_spend') {
              return I18n.t(
                `thanx_campaigns.campaigns.engage_vip.steps.setup.audience.graph.labels.lift`,
                //@ts-ignore
                { multiplier: numeral(this.point.lift).format('0') }
              )
            }
          },
        },
      },
    },
    colors,
    chart: {
      type: 'column',
      height: 500,
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Lato',
      },
    },
    series: [
      {
        data,
        type: 'column',
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default Graph
