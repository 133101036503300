import { Fields } from 'models/CouponPool'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectCouponPools = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => session.CouponPool.all().toRefArray()
)

export const selectCouponPoolsByTemplateId = createSelector(
  (state: any) => selectCouponPools(state),
  (_state: any, templateId: number): number => templateId,
  (pools: Fields[], templateId: number): Fields[] | null => {
    return (
      pools.filter(
        p => p.redeem_template_id === templateId && p.state === 'active'
      ) ?? []
    )
  }
)

export const selectMerchantCouponPools = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => session.MerchantCouponPool.all().toRefArray()
)
