export default {
  restrictions: 'Restrictions',
  fine_print: 'Fine print',
  no_restrictions: 'No restrictions',
  no_fine_print: 'No fine print',
  remove_from_marketplace: 'Remove from marketplace',
  remove_modal: {
    title: 'Remove this reward from the marketplace',
    body: 'Customers will no longer see this reward in the marketplace. You can’t undo this.',
    confirm: 'Remove from marketplace',
    cancel: 'Cancel',
  },
  remove_error:
    'Something went wrong while trying to remove the reward. Please try again later',
  remove_success: 'Your reward has been removed from the marketplace',
}
