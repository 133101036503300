import orm from 'models/orm'
import { Fields } from 'models/UserCampaign'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectUsersCampaigns = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.UserCampaign.all().toRefArray() || []
  }
)

export const selectUserCampaigns = createSelector(
  (state: any) => selectUsersCampaigns(state),
  (_: any, id: number): number => id,
  (usersCampaigns: any, id: number): Fields[] => {
    return usersCampaigns.filter((campaign: Fields) => campaign.user_id === id)
  }
)
