import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React from 'react'
import Book from './Icons/Book'
import Play from './Icons/Play'

type PropsT = {
  className?: string
  imageLink: string
  videoLink: string
  title: string
  image: string
  type: string
}

const t = buildTranslate('cms.components.explainer_banner')

const ExplainerBanner: React.FC<PropsT> = ({
  className,
  imageLink,
  videoLink,
  title,
  image,
  type,
}: PropsT) => {
  const [css] = useStyletron()

  return (
    <div
      className={`border d-flex ${css({
        borderRadius: '4px',
        overflow: 'hidden',
      })} ${className}`}
    >
      <img
        alt={type}
        className={css({
          height: '130px',
        })}
        onClick={() => window.open(videoLink)}
        src={image}
      />
      <div
        className={`py-m px-l ${css({
          flex: 1,
          backgroundColor: palette.spearmint10,
        })}`}
      >
        <Text.Header4
          bold
          color="grey90"
          tag="div"
          className="mb-xs text-center"
        >
          {title}
        </Text.Header4>
        <div className="d-flex justify-content-center">
          <Button
            className="mr-s"
            kind="secondary"
            onClick={() => window.open(videoLink)}
          >
            <Play className="mr-xs" />
            {t('explainer')}
          </Button>
          <Button kind="secondary" onClick={() => window.open(imageLink)}>
            <Book className="mr-xs" />
            {t('article')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ExplainerBanner
