import { Radio } from '@thanx/uikit/radio'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import { buildTranslate } from 'locales'
import React from 'react'
import { Carousel, Col } from 'react-bootstrap-five'
import { RedeemCategory, RedeemTypeItem } from 'scenes/RedeemManager/types'
import { getContent } from './helpers'

type Props = {
  checked: boolean
  redeemCategory: RedeemCategory
  setRedeemType: (typeItem: RedeemTypeItem) => void
}

const t = buildTranslate('redeem_manager.builder.steps.type')

const Card: React.FC<Props> = ({ checked, redeemCategory, setRedeemType }) => {
  let { isDisabled = false, key } = redeemCategory
  const [css] = useStyletron()
  const titleColor = isDisabled ? 'grey50' : 'grey90'
  const { images, link } = getContent(key)
  const borderColor = checked
    ? defaultTheme.colors.primary
    : defaultTheme.colors.grey30
  const handleClick = () => {
    if (!isDisabled) {
      setRedeemType(redeemCategory.items[0])
    }
  }
  return (
    <Col className="mb-xl" lg={6}>
      <div
        className={css({
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          overflow: 'hidden',
          borderRadius: '4px',
          border: `1px solid ${borderColor}`,
          ':hover': {
            borderColor:
              checked || isDisabled ? '' : defaultTheme.colors.grey50,
          },
        })}
      >
        <Carousel
          interval={checked ? 2500 : 500000}
          controls={!isDisabled && images.length > 1}
          indicators={!isDisabled && images.length > 1}
          prevLabel=""
          nextLabel=""
        >
          {images.map(image => (
            <Carousel.Item key={image}>
              <img width="100%" src={image} onClick={handleClick} alt={key} />
            </Carousel.Item>
          ))}
        </Carousel>
        <Radio
          disabled={isDisabled}
          key={key}
          className={`${css({
            border: 'none !important',
          })} w-100 px-s py-m white-bkg border-1 cursor-pointer`}
          value={key}
          checked={checked}
          onClick={handleClick}
          overrides={{
            Label: {
              style: {
                width: '100%',
              },
            },
            Root: {
              style: {
                alignItems: 'flex-start',
              },
            },
          }}
        >
          <div className="pl-xs">
            <Text.Header4 color={titleColor} className="mb-xs mt-0">
              {t(`${key}.title`)}
            </Text.Header4>
            <Text.BodyText3 color="grey70">
              {t(
                `${key}.subtitle${
                  isDisabled && key === 'bonus_points' ? '_disabled' : ''
                }`
              )}
            </Text.BodyText3>
            <Switch condition={key !== 'bonus_points'}>
              <div className="mt-xs">
                <a
                  className="font-size-16"
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('browse')}
                  <i className="ml-xs fa fa-lightbulb-o"></i>
                </a>
              </div>
            </Switch>
          </div>
        </Radio>
      </div>
    </Col>
  )
}

export default Card
