import { Text } from '@thanx/uikit/text'
import BulletPoint from 'components/BulletPoint'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectExperience } from 'selectors/pointsExperience'
import moment from 'utilities/thanxMoment'

type Props = {
  className?: string
  daysLength?: number
  showAsBullet?: boolean
  venue?: string
  start: string | null
  end: string | null
  maximum?: number
}

const t = buildTranslate('points.restrictions')

const Restrictions: React.FC<Props> = props => {
  const {
    className = '',
    daysLength,
    showAsBullet = false,
    start,
    end,
    venue,
    maximum,
  } = props
  const pointsExperience = useSelector(selectExperience)
  const restrictions: string[] = []

  // redeem restrictions
  if (venue && venue !== 'all') {
    restrictions.push(t(`can_only_redeem.${venue}`))
  }

  // exchange date restrictions
  if (start || end) {
    let canExchange = t('exchange_dates.can_exchange', {
      currencyName: pointsExperience?.currency_name_plural,
    })
    if (start) {
      canExchange = `${canExchange} ${t('exchange_dates.from', {
        from: moment(start).format('ddd, MMM Do YYYY'),
      })}`
    }
    if (end) {
      canExchange = `${canExchange} ${t('exchange_dates.to', {
        to: moment(end).format('ddd, MMM Do YYYY'),
      })}`
    }
    restrictions.push(canExchange)
  }

  if (daysLength && daysLength > 0) {
    let unit
    let count = daysLength
    if (daysLength < 1) {
      count = Math.round(24 * daysLength)
      unit = t('hour', { count })
    } else {
      unit = t('day', { count })
    }
    restrictions.push(t('after_activation', { unit, count }))
  }

  if (!restrictions.length) {
    restrictions.push(t('no_restrictions'))
  }

  if (maximum) {
    restrictions.push(t('max_amount', { amount: maximum }))
  }

  const showRestriction = (restriction, i) =>
    showAsBullet ? (
      <BulletPoint className="mb-xs" key={i}>
        <Text.BodyText4 color="grey70">{restriction}</Text.BodyText4>
      </BulletPoint>
    ) : (
      <Text.BodyText4 key={i} color="grey70" tag="div" className="mb-xs">
        {restriction}
      </Text.BodyText4>
    )

  return <div className={className}>{restrictions.map(showRestriction)}</div>
}

export default Restrictions
