import { alert } from 'actions/flash'
import { getTargetSegments } from 'actions/targetSegments'
import useDispatch from 'hooks/useDispatch'
import usePaginateArray from 'hooks/usePaginateArray'
import { buildTranslate } from 'locales'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectTargetSegments } from 'selectors/targetSegment'
import { useIsMobile } from 'utilities/responsive'
import { matchesSearch } from 'utilities/search'

type Filter = {
  searchQuery: string | null
  type: string
  category: string
}

const t = buildTranslate('segments.landing')

export const useSegmentsTable = (
  contentRef?: React.RefObject<HTMLDivElement>
) => {
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const [isFetchingSegments, setIsFetchingSegments] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string | null>(null)
  const [type, setType] = useState('all')
  const [category, setCategory] = useState('all')

  const allSegments = useSelector(state => selectTargetSegments(state))
  const filtered = useMemo(() => {
    return allSegments.filter(segment => {
      if (type !== 'all' && segment.type !== type) {
        return false
      }

      if (!matchesSearch(segment, searchQuery, segment => segment.name)) {
        return false
      }

      const segmentCategory = segment.category ?? ''
      if (category !== 'all' && segmentCategory !== category) {
        return false
      }

      return true
    })
  }, [searchQuery, type, category, allSegments])

  const { currentPage, setPage, paginatedData, totalPages } = usePaginateArray(
    filtered,
    15
  )

  const categories = useMemo(
    () =>
      Array.from(new Set(allSegments.map(segment => segment.category ?? ''))),
    [allSegments]
  )

  const scrollToContent = useCallback(() => {
    if (contentRef?.current && !isMobile) {
      contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [contentRef, isMobile])

  const changePage = (page: number) => {
    setPage(page)
    scrollToContent()
  }

  const updateFilter = (update: Partial<Filter>) => {
    setSearchQuery(update.searchQuery ?? searchQuery)
    setType(update.type ?? type)
    setPage(1)

    if (update.searchQuery !== undefined) {
      setCategory('all')
    } else {
      setCategory(update.category ?? category)
    }

    if (update.category !== undefined) {
      scrollToContent()
    }
  }

  const fetchSegments = useCallback(async () => {
    setIsFetchingSegments(true)
    const action = await dispatch(getTargetSegments())
    if (action.error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('fetch_error'),
        })
      )
    }
    setIsFetchingSegments(false)
  }, [dispatch])

  useEffect(() => {
    fetchSegments()
  }, [fetchSegments])

  return {
    isFetchingSegments,
    searchIsActive: !!searchQuery,
    changePage,
    currentPage,
    numPages: totalPages,
    segments: paginatedData,
    type,
    category,
    setSearchQuery: (query: string | null) =>
      updateFilter({ searchQuery: query }),
    setType: (newType: string) => updateFilter({ type: newType }),
    setCategory: (newCategory: string) =>
      updateFilter({ category: newCategory }),
    categories,
  }
}
