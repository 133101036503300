import { useStyletron } from '@thanx/uikit/theme'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

const t = buildTranslate('looker')
enum Tabs {
  OVERVIEW = 'overview',
  HANDOFF_MODE = 'handoff_mode',
  LOCATION = 'location',
}

const AverageCheckReport: React.FC = () => {
  const merchant = useCurrentMerchant()
  const [css] = useStyletron()
  const [activeTab, setActiveTab] = useState<
    'overview' | 'handoff_mode' | 'location'
  >('overview')

  if (!merchant) return null

  const title = t('urls.dashboards/114.title')
  const overviewHeight = 1100
  const handoffModeHeight = 900
  const locationHeight = 1550

  return (
    <>
      <Helmet>
        <title>{I18n.t(`titles./reports/average_check#${activeTab}`)}</title>
      </Helmet>
      <ProtectedPageHeader
        title={title}
        resource="LocationDetailReports"
        action="access"
      >
        <Tab.Container
          activeKey={activeTab}
          transition={false}
          onSelect={(tab: string | null) => {
            if (!tab) return
            setActiveTab(tab as Tabs)
          }}
        >
          <TabNav>
            <TabItem
              state={Tabs.OVERVIEW}
              title={t(`urls.dashboards/114.tabs.overview`)}
            />
            <TabItem
              state={Tabs.HANDOFF_MODE}
              title={t(`urls.dashboards/114.tabs.handoff_mode`)}
            />
            <TabItem
              state={Tabs.LOCATION}
              title={t(`urls.dashboards/114.tabs.location`)}
            />
          </TabNav>
          <Tab.Content className="pb-l h-100 grey-05-bkg">
            <TabPane state={Tabs.OVERVIEW}>
              <Container
                className={`px-0 ${css({
                  minHeight: `${overviewHeight + 300}px`,
                })}`}
              >
                <LookerIframe
                  path={'dashboards/114'}
                  merchant={merchant}
                  height={`${overviewHeight}px`}
                />
                <LookerFooter className="my-xl" />
              </Container>
            </TabPane>
            <TabPane state={Tabs.HANDOFF_MODE}>
              <Container
                className={`px-0 ${css({
                  minHeight: `${handoffModeHeight + 300}px`,
                })}`}
              >
                <LookerIframe
                  path={'dashboards/76'}
                  merchant={merchant}
                  height={`${handoffModeHeight}px`}
                />
                <LookerFooter className="my-xl" />
              </Container>
            </TabPane>
            <TabPane state={Tabs.LOCATION}>
              <Container
                className={`px-0 ${css({
                  minHeight: `${locationHeight + 300}px`,
                })}`}
              >
                <LookerIframe
                  path={'dashboards/115'}
                  merchant={merchant}
                  height={`${locationHeight}px`}
                />
                <LookerFooter className="my-xl" />
              </Container>
            </TabPane>
          </Tab.Content>
        </Tab.Container>
      </ProtectedPageHeader>
    </>
  )
}

export default AverageCheckReport
