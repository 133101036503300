import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import ContentContainer from 'components/ContentContainer'
import PopoverContent, { Sections } from 'components/PopoverContent'
import React from 'react'
import { useStyletron } from 'styletron-react'

type Props = {
  primaryValue: string
  secondaryValue?: string
  height?: string
  name?: string | JSX.Element
  isErrored?: boolean
  isLoading?: boolean
  sections?: Sections
  className?: string
  shouldCenter?: boolean
  primaryIcon?: React.ReactNode
  secondaryValueColor?: string
}

const Counter: React.FC<Props> = props => {
  const {
    primaryValue,
    secondaryValue,
    height = '45px',
    name = null,
    sections,
    isErrored = false,
    isLoading = false,
    className = '',
    shouldCenter = false,
    primaryIcon,
    secondaryValueColor = '',
  } = props
  const [css] = useStyletron()

  const additionalClasses =
    !!isLoading || !!isErrored || shouldCenter
      ? 'justify-content-center align-items-center'
      : ''

  let nameElement = name
  if (name) {
    nameElement =
      typeof name === 'string' ? (
        <span
          className="mr-xs"
          dangerouslySetInnerHTML={{
            __html: name,
          }}
        />
      ) : (
        <span className="mr-xs">{name}</span>
      )
  }

  return (
    <div className={className}>
      <div
        className={`d-flex ${additionalClasses} ${css({
          height,
        })}`}
      >
        <ContentContainer isLoading={isLoading} isErrored={isErrored}>
          <Text.BodyText1 tag="p" className="m-0" bold>
            {primaryIcon}
            {primaryValue}
          </Text.BodyText1>
          <Switch condition={!!secondaryValue}>
            <Text.BodyText2
              tag="p"
              className={`${css({
                paddingBottom: '4px',
              })} m-0 ml-xs align-self-end`}
              color={secondaryValueColor}
            >
              ({secondaryValue})
            </Text.BodyText2>
          </Switch>
        </ContentContainer>
      </div>
      <Switch condition={!!nameElement || !!sections}>
        <Text.BodyText4
          tag="p"
          className="m-0 d-flex align-items-center"
          color="grey70"
        >
          {!!nameElement ? nameElement : null}
          {!!sections && <PopoverContent sections={sections} />}
        </Text.BodyText4>
      </Switch>
    </div>
  )
}

export default Counter
