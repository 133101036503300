import { Button } from '@thanx/uikit/button'
import { Select } from '@thanx/uikit/select'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { createCampaign } from 'actions/campaigns'
import { alert } from 'actions/flash'
import { getTargetSegments } from 'actions/targetSegments'
import opportunity from 'assets/images/reports/icons/opportunity.svg'
import HelpLink from 'components/HelpLink'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import {
  stageSubcategoryMapping,
  stagesWithDetailedReport,
} from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { selectTargetSegmentsByCategories } from 'selectors/targetSegment'
import { lifecycleCampaignHelpUrl } from 'utilities/urlUtils'

type PropsT = {
  stage: typeof stagesWithDetailedReport[number]
}

const t = buildTranslate('lifecycle_report.one_click')
const OneClickBox: React.FC<PropsT> = props => {
  const { stage } = props
  const [css] = useStyletron()
  const dispatch = useDispatch()

  const subcategoryName = stageSubcategoryMapping[stage]
  const targetSegments =
    useSelector(state =>
      selectTargetSegmentsByCategories(state, [
        'customer_lifecycle',
        'Customer lifecycle',
      ])
    ) || []

  const filteredSegments = targetSegments.filter(
    segment => segment.subcategory === subcategoryName
  )

  const options = filteredSegments.map(segment => ({
    label: segment.name,
    value: segment.id,
  }))

  const [shouldFetch, setShouldFetch] = useState(isEmpty(targetSegments))
  const [isLoading, setIsLoading] = useState(false)

  // We want to default the user to the "...after 7 days" segment. This
  // implementation is a little fragile, but the impact of it breaking is
  // minor, as we will instead just default to the first option on the list
  const defaultOption =
    options.find(option => option.label.includes('7 day')) || options[0]
  const [currentSegmentOption, setCurrentSegmentOption] =
    useState(defaultOption)
  const selectedSegment = currentSegmentOption || defaultOption

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)
      await dispatch(getTargetSegments())
      setIsLoading(false)
    }
    if (shouldFetch) {
      fetch()
      setShouldFetch(false)
    }
  }, [dispatch, shouldFetch])

  const onChange = (selected: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentSegmentOption(selected[0])
  }
  const onSubmit = async () => {
    setIsLoading(true)
    const params = {
      campaign: {
        type: 'custom_automated',
        target_segment_id: selectedSegment.value,
      },
    }
    const action = await dispatch(createCampaign(params))
    if (action.error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
        })
      )
      setIsLoading(false)
      return
    }

    const campaignId = action.payload?.data?.campaign.id
    if (campaignId) {
      dispatch(
        push(`/thanx_campaigns/automated/custom_automated/${campaignId}`)
      )
    } else {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
        })
      )
    }
    setIsLoading(false)
  }
  if (isEmpty(options)) return null

  return (
    <>
      <Text.Header3 bold color="grey90" className="mb-m">
        {t('title')}
      </Text.Header3>
      <div
        className={`d-flex white-bkg pb-m pt-xl px-xl border-1 grey-20-border align-items-start ${css(
          {
            borderRadius: '4px',
            boxShadow: '0px 8px 24px rgba(54, 66, 78, 0.2)',
          }
        )}`}
      >
        <img src={opportunity} className="mr-s" alt="" />
        <div className="flex-grow-1">
          <Text.Header4 bold color="grey90" className="mb-xs mt-xs">
            {t('subtitle')}
          </Text.Header4>
          <Text.BodyText3 color="grey70" className="mb-xs" tag="p">
            {t(`content.${stage}`)}
          </Text.BodyText3>
          <Text.BodyText3 color="grey70" className="mb-xs" tag="p">
            {t('going_forward')}
          </Text.BodyText3>
          <div className="max-width-40 mb-m">
            <div className={`${css({ width: '400px' })}`}>
              <Select
                onChange={onChange}
                options={options}
                value={selectedSegment}
                searchable={false}
                disabled={isLoading}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <HelpLink url={lifecycleCampaignHelpUrl}>{t('how_to')}</HelpLink>
            <Button
              disabled={isEmpty(filteredSegments)}
              onClick={onSubmit}
              isLoading={isLoading}
            >
              {t('button')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default OneClickBox
