import { RadioGroup } from '@thanx/uikit/radio'
import { Text } from '@thanx/uikit/text'
import RichSelector from 'components/Form/RichSelector'
import ABTest from 'components/Icons/ABTest'
import NoABTest from 'components/Icons/NoABTest'
import { buildTranslate } from 'locales'
import { Fields as Campaign } from 'models/Campaign'
import React, { useState } from 'react'
import EditableName from 'scenes/CampaignCenter/Builder/components/EditableName'
import { VariantAction } from './helper'

type PropsT = {
  campaign: Campaign
  setVariantAction: (action: VariantAction) => void
}

const t = buildTranslate('thanx_campaigns.builder.variants')

const VariantSelector: React.FC<PropsT> = props => {
  const { campaign, setVariantAction } = props
  const variantLength = campaign.variants?.length || 0

  let initialValue
  switch (variantLength) {
    case 0:
      initialValue = 'useVariants'
      break
    case 1:
      initialValue = 'noVariants'
      break
    default:
      initialValue = 'useVariants'
      break
  }

  const [selected, setSelected] = useState(initialValue)

  const handleClick = event => {
    const type = event.currentTarget.querySelector('input:first-of-type').value
    setSelected(type)
    if (!type) return
    if (type === 'useVariants') {
      switch (variantLength) {
        case 0:
          setVariantAction('multiVariant')
          break
        case 1:
          setVariantAction('addVariant')
          break
        default:
          setVariantAction('noChange')
      }
    } else {
      switch (variantLength) {
        case 0:
          setVariantAction('addVariant')
          break
        case 1:
          setVariantAction('noChange')
          break
        default:
          setVariantAction('removeVariant')
          break
      }
    }
  }
  return (
    <>
      <EditableName
        campaignId={campaign.id}
        className="my-l"
        name={campaign.name}
        type={campaign.campaign_config_type}
        styleSize="large"
        showEdit
      />
      <RadioGroup value={selected}>
        <RichSelector
          value="useVariants"
          image={<ABTest />}
          className="mb-s"
          onClick={handleClick}
        >
          <Text.Header4 bold className="mt-0">
            {t('choices.run_ab.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p" className="mb-0">
            {t('choices.run_ab.content')}
          </Text.BodyText4>
          <Text.BodyText4 tag="p">
            {t('choices.run_ab.multi_variant_content')}
          </Text.BodyText4>
          <Text.BodyText4 tag="p">
            <a
              href="https://help.thanx.com/en/articles/5650795-how-to-a-b-test-an-automated-campaign"
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline"
            >
              {t('choices.run_ab.multi_variant_link')}
            </a>
          </Text.BodyText4>
        </RichSelector>
        <RichSelector
          onClick={handleClick}
          value="noVariants"
          image={<NoABTest />}
        >
          <Text.Header4 bold className="mt-0">
            {t('choices.no_test.title')}
          </Text.Header4>
          <Text.BodyText4 tag="p">
            {t('choices.no_test.content')}
          </Text.BodyText4>
        </RichSelector>
      </RadioGroup>
    </>
  )
}

export default VariantSelector
