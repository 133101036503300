import { Notification } from '@thanx/uikit/notification'
import { Switch } from '@thanx/uikit/switch'
import { withFormsy } from 'formsy-react'
import React, { useState } from 'react'
import {
  ControlLabel,
  FormControl,
  FormGroup,
  InputGroup,
} from 'react-bootstrap'
import { textUtils } from 'utilities/textUtils'
import ChromePicker from './ChromePicker'
import Enhancer from './Enhancer'

type Props = {
  value
  setValue
  errorMessage
  isPristine
  label?: string
  placeholder?: string
  style?: any
  disabled?: boolean
  startEnhancer?: boolean
  onChange?: (value: string) => void
  className?: string
}

const ColorPicker: React.FC<Props> = props => {
  const {
    value = '#EEEEEE',
    setValue,
    errorMessage,
    isPristine,
    label,
    placeholder,
    style,
    disabled = false,
    startEnhancer = false,
    onChange,
    className,
  } = props

  const [isOpen, setIsOpen] = useState(false)

  function toggleColorPicker() {
    if (disabled) return
    setIsOpen(!isOpen)
  }

  function onChangePicker(value) {
    onChange?.(value.hex)
    setValue(value.hex)
  }

  function onChangeText(event) {
    let newValue = event.currentTarget.value
    if (newValue && newValue[0] !== '#') {
      newValue = `#${newValue}`
    }
    setValue(newValue)
    onChange?.(value.hex)
  }

  return (
    <div style={style} className={className}>
      <FormGroup
        className={
          errorMessage && !isPristine ? 'form-group has-error' : 'form-group'
        }
        bsSize="lg"
      >
        <Switch condition={!!label}>
          <ControlLabel>{label}</ControlLabel>
        </Switch>
        <InputGroup className="w-100">
          <Switch condition={startEnhancer}>
            <Enhancer
              value={value}
              onClick={() => toggleColorPicker()}
              disabled={disabled}
            />
          </Switch>
          <FormControl
            className="w-100"
            onChange={onChangeText}
            value={value}
            placeholder={placeholder}
            type="input"
            disabled={disabled}
          />
          <Switch condition={!startEnhancer}>
            <Enhancer
              value={value}
              onClick={() => toggleColorPicker()}
              disabled={disabled}
            />
          </Switch>
        </InputGroup>
        <Switch condition={!isPristine && !!errorMessage}>
          <Notification kind="negative" size="full">
            <span className="body-text-4">
              {textUtils.initialCapital(errorMessage)}
            </span>
          </Notification>
        </Switch>
      </FormGroup>
      <Switch condition={isOpen}>
        <ChromePicker
          onClick={() => toggleColorPicker()}
          value={value}
          onChangeSlider={onChangePicker}
        />
      </Switch>
    </div>
  )
}

export default withFormsy(ColorPicker)
