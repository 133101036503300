import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import measureGraph from 'assets/images/reports/revenue-capture-rate/measure-graph.svg'
import HelpLink from 'components/HelpLink'
import ProgressBar from 'components/ProgressBar'
import Section from 'components/Section'
import Spinner from 'components/Spinner'
import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { selectCumulativeMetric } from 'selectors/captureRateMetric'
import { merchantTheme } from 'theme'
import { useIsMobile } from 'utilities/responsive'
import { captureRateReportUrl } from 'utilities/urlUtils'
import { reportPercentage } from '../helpers'
import { useUploadState } from './UploadStateContext'

type PropsT = {
  merchant: Merchant
  onImportOpen: () => void
  isImporterLoading: boolean
}

const t = buildTranslate('capture_rate')

const ExplainerCard: React.FC<PropsT> = ({
  merchant,
  onImportOpen,
  isImporterLoading,
}) => {
  const { currentLocation } = useContext(LocationContext)
  const isMobile = useIsMobile()
  const [css] = useStyletron()
  const { uploadState } = useUploadState()
  const isProcessing = uploadState === 'processing'
  const cumulativeMetrics = useSelector(state =>
    selectCumulativeMetric(state, merchant?.id, currentLocation?.id)
  )

  const progress = cumulativeMetrics?.current?.capture_rate
    ? cumulativeMetrics?.current?.capture_rate * 100
    : 0
  const isLoading = cumulativeMetrics?.isLoading

  const getTooltipContent = () => {
    return (
      <div className={css({ lineHeight: '20px' })}>
        <Text.Header4 tag="div" className="p-0 mt-0 pt-0 mb-xs">
          {reportPercentage(cumulativeMetrics?.current?.capture_rate)}
        </Text.Header4>
        <Text.BodyText4 tag="div">{t('your_capture_rate')}</Text.BodyText4>
        <Text.BodyText5 tag="div">
          <a href={captureRateReportUrl} target="_blank" rel="noreferrer">
            {t('learn_more')}
          </a>
        </Text.BodyText5>
      </div>
    )
  }

  let progressValue = progress
  if (!progress) {
    progressValue = 0
  } else if (progress > 100) {
    progressValue = 100
  }

  return (
    <Section
      className={`d-flex flex-wrap mb-xl ${isMobile ? 'm-auto' : 'pl-l'} ${css({
        minHeight: '308px',
        backgroundColor: merchantTheme.colors.wisteria10,
        boxShadow: '0 1rem 3rem rgba(54, 66, 78, 0.2)',
      })}`}
    >
      <div className={`mt-xl ${isMobile ? 'ml-auto mr-auto' : ''}`}>
        <img src={measureGraph} alt="" />
      </div>
      <div
        className={`pl-l pt-l pb-m d-flex flex-column justify-content-between ${css(
          { width: isMobile ? '90%' : '70%' }
        )}`}
      >
        <section className="mb-s">
          <Text.Header4>{t('explainer_card.title')}</Text.Header4>
          <Text.BodyText3 color="grey70">
            {t('explainer_card.subtitle')}
            <br />
            <HelpLink
              url={captureRateReportUrl}
              className={`pl-0 ${css({
                display: 'block',
                marginBottom: '12px',
              })}`}
            >
              {t('explainer_card.why_tracking_matters')}
            </HelpLink>
          </Text.BodyText3>
        </section>
        {isLoading && (
          <div
            className={css({
              height: '64px',
            })}
          >
            <Spinner isLoading />
          </div>
        )}
        {!progressValue && !isLoading && (
          <section className="mt-s">
            <Button
              disabled={isProcessing}
              onClick={onImportOpen}
              isLoading={isImporterLoading}
            >
              {t('explainer_card.unlock_your_rate')}
            </Button>
          </section>
        )}
        <section className="mb-m">
          <ProgressBar
            className={`${!!progressValue ? 'mt-xxxl' : 'mt-l'} ${css({
              border: `1px solid ${merchantTheme.colors.grey70}`,
              backgroundColor: merchantTheme.colors.wisteria10,
            })}`}
            color={merchantTheme.colors.wisteria}
            value={progressValue}
            labels={[0, 15, 30, 45, 60]}
            tooltipContent={progressValue ? getTooltipContent() : null}
          />
          <div className="d-flex justify-content-between mt-xxs">
            {[
              t('explainer_card.progress_labels.low'),
              t('explainer_card.progress_labels.moderate'),
              t('explainer_card.progress_labels.high'),
            ].map(labelText => (
              <Text.BodyText5 tag="div" color="grey70" key={labelText}>
                {labelText}
              </Text.BodyText5>
            ))}
          </div>
        </section>
      </div>
    </Section>
  )
}

export default ExplainerCard
