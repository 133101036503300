import React from 'react'

const Email = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="title">email</title>
    <path
      d="M22.5 10H9.5C8.65625 10 8 10.6875 8 11.5V20.5C8 21.3438 8.65625 22 9.5 22H22.5C23.3125 22 24 21.3438 24 20.5V11.5C24 10.6875 23.3125 10 22.5 10ZM22.5 11.5V12.7812C21.7812 13.375 20.6562 14.25 18.2812 16.125C17.75 16.5312 16.7188 17.5312 16 17.5C15.25 17.5312 14.2188 16.5312 13.6875 16.125C11.3125 14.25 10.1875 13.375 9.5 12.7812V11.5H22.5ZM9.5 20.5V14.7188C10.1875 15.2812 11.2188 16.0938 12.75 17.3125C13.4375 17.8438 14.6562 19.0312 16 19C17.3125 19.0312 18.5 17.8438 19.2188 17.3125C20.75 16.0938 21.7812 15.2812 22.5 14.7188V20.5H9.5Z"
      fill="#36424E"
    />
    <circle cx="16" cy="16" r="15" stroke="#36424E" strokeWidth="2" />
  </svg>
)

export default Email
