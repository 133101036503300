import { buildTranslate } from 'locales'

const t = buildTranslate('feedbacks.responses')

export const getFeedbackStyles = (score: number) => {
  if (score <= 6) {
    return {
      text: t('modal.headers.detractor'),
      icon: 'fa-frown-o',
      strongColor: '#F26335',
      color: '#FBDFD6',
    }
  } else if (score >= 7 && score <= 8) {
    return {
      text: t('modal.headers.passive'),
      icon: 'fa-meh-o',
      strongColor: '#E7B41A',
      color: '#FAF0D1',
    }
  } else {
    return {
      text: t('modal.headers.promoter'),
      icon: 'fa-smile-o',
      strongColor: '#27AE60',
      color: '#D6F5E4',
    }
  }
}
