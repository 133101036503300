import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Info from 'components/Info'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React from 'react'
import { generateTypeItem } from 'scenes/RedeemManager/helpers'

type PropsT = {
  template?: RedeemTemplate
  isDirect?: boolean
}

const t = buildTranslate('redeem_manager.detail.cards.instore_redemption')

const DynamicCouponsInfo: React.FC<PropsT> = ({
  template,
  isDirect = false,
}) => {
  if (!template) return null
  const typeItem = generateTypeItem(template)
  const isFreeItem = typeItem?.types.includes('manual/item')
  const requiredProductIds = template?.product_ids?.instore?.required
  const discountedProductIds = template?.product_ids?.instore?.discounted

  const displayRequiredProducts = !isEmpty(requiredProductIds)
  const displayDiscountedProducts =
    template?.subtype !== 'bogo/purchase' && !isEmpty(discountedProductIds)

  return (
    <>
      <Info label={t('redemption')} value={t('coupon_codes.enforced_by_pos')} />
      <Info
        className="mt-s"
        label={t('coupon_codes.type')}
        value={
          <>
            <Text.BodyText3 color="grey70">
              {t('coupon_codes.integration')}
            </Text.BodyText3>
            <Text.BodyText4 color="grey70" tag="div" className="mt-xs">
              <span
                dangerouslySetInnerHTML={{
                  __html: isDirect
                    ? t('coupon_codes.entered_by_customer')
                    : t('coupon_codes.issued_5_digit_code'),
                }}
              />
            </Text.BodyText4>
          </>
        }
      />

      <Switch condition={template.subtype !== 'purchase'}>
        <Switch condition={displayRequiredProducts}>
          <Info
            className="mt-m"
            label={t('coupon_codes.pos_required_product_ids')}
            value={
              <Text.BodyText3 color="grey70">
                {template?.product_ids?.instore?.required?.join(', ')}
              </Text.BodyText3>
            }
          />
        </Switch>
        <Switch condition={displayDiscountedProducts}>
          <Info
            className="mt-m"
            label={t(
              isFreeItem
                ? 'coupon_codes.pos_free_product_ids'
                : 'coupon_codes.pos_discounted_product_ids'
            )}
            value={
              <Text.BodyText3 color="grey70">
                {template?.product_ids?.instore?.discounted?.join(', ')}
              </Text.BodyText3>
            }
          />
        </Switch>
      </Switch>
    </>
  )
}

export default DynamicCouponsInfo
