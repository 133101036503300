import { Text } from '@thanx/uikit/text'
import FetchableMetricSection from 'components/FetchableMetricSection'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useFlag from 'hooks/useFlag'
import { buildTranslate } from 'locales'
import {
  EffectiveDiscountRateCostEfficacyMetric,
  EffectiveDiscountRateCumulativeMetric,
  EffectiveDiscountRateTimeSeriesMetric,
  ShortType,
} from 'models/EffectiveDiscountRateMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { getBenchmarkRate } from 'scenes/Reports/helpers'
import { selectEffectiveDiscountRateMetricByType } from 'selectors/effectiveDiscountRateMetric'
import Chart from './Chart'
import Counters from './Counters'
import ExplainerCard from './ExplainerCard'
import Table from './Table'

const t = buildTranslate('points.effective_discount_rate')

const Content: React.FC = () => {
  const merchant = useCurrentMerchant()
  const showTable = useFlag('MX4-EDR-R2', false)

  const cumulativeMetrics = useSelector(state =>
    selectEffectiveDiscountRateMetricByType(
      state,
      merchant?.id,
      ShortType.CUMULATIVE
    )
  ) as EffectiveDiscountRateCumulativeMetric

  const timeSeriesMetrics = useSelector(state =>
    selectEffectiveDiscountRateMetricByType(
      state,
      merchant?.id,
      ShortType.TIME_SERIES
    )
  ) as EffectiveDiscountRateTimeSeriesMetric

  const rewardsMetrics = useSelector(state =>
    selectEffectiveDiscountRateMetricByType(
      state,
      merchant?.id,
      ShortType.COST_EFFICACY
    )
  ) as EffectiveDiscountRateCostEfficacyMetric

  const {
    isErrored: isCumulativeErrored,
    isLoading: isCumulativeLoading,
    previous,
    current,
    timezone: cumulativeTimezone,
  } = cumulativeMetrics ?? {}

  const {
    isErrored: isTimeSeriesErrored,
    isLoading: isTimeSeriesLoading,
    timezone: seriesTimezone,
    granularity,
    values,
  } = timeSeriesMetrics ?? {}

  const {
    isErrored: isRewardsErrored,
    isLoading: isRewardsLoading,
    values: rewardsValues,
    date_time: rewardsDateTime,
    timezone: rewardsTimezone,
  } = rewardsMetrics ?? {}

  const benchmark = getBenchmarkRate(
    merchant?.merchant_type,
    'effective_discount_rate'
  )

  const metricTypes = [ShortType.CUMULATIVE, ShortType.TIME_SERIES]
  if (showTable) {
    metricTypes.push(ShortType.COST_EFFICACY)
  }

  return (
    <FetchableMetricSection
      className="mb-xl"
      shouldFetch
      type={'effective_discount_rate_metric'}
      metricTypes={metricTypes}
      merchantId={merchant?.id}
    >
      <ExplainerCard />
      <Text.Header3 tag="div" className="mb-s">
        {t('title')}
      </Text.Header3>
      <Counters
        isErrored={isCumulativeErrored}
        isLoading={isCumulativeLoading}
        currentRateData={current}
        previousRateData={previous}
        benchmark={benchmark}
        timezone={cumulativeTimezone}
      />
      <Chart
        isErrored={isTimeSeriesErrored}
        isLoading={isTimeSeriesLoading}
        granularity={granularity}
        values={values}
        timezone={seriesTimezone}
        benchmark={benchmark}
      />
      <Table
        isErrored={isRewardsErrored}
        isLoading={isRewardsLoading}
        values={rewardsValues}
        dateTime={rewardsDateTime}
        timezone={rewardsTimezone}
        currentData={current}
      />
    </FetchableMetricSection>
  )
}

export default Content
