import capitalize from 'lodash/capitalize'
import type { Fields as Campaign } from 'models/Campaign'
import { Metrics } from 'models/CampaignMetric'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'
import configs from 'scenes/CampaignCenter/Report/configs'
import View from './View'

type Props = {
  campaign: Campaign
  hasDivider?: boolean
  metrics?: Metrics
}

export function notEnoughData(config: any, props: Props) {
  return (
    !config.getTargetFraction(props) ||
    config.getTargetFraction(props) < 0.01 ||
    !config.getControlFraction(props)
  )
}

export function targetFraction(
  config: any,
  props: Props,
  hasControlGroup: boolean
) {
  if (notEnoughData(config, props) || hasControlGroup) {
    return 0.7
  }

  return config.getTargetFraction(props)
}

export function controlFraction(
  config: any,
  props: Props,
  hasControlGroup: boolean
) {
  if (notEnoughData(config, props) || !hasControlGroup) {
    return 0.4
  }

  return config.getControlFraction(props)
}

export const ControlGroup: React.FC<Props> = props => {
  const { campaign, hasDivider = false } = props
  const config = configs[campaign.type]
  const hasControlGroup = !!campaign.config_control_percent

  const comparisonDescription = capitalize(
    I18n.t(
      'thanx_campaigns.report.campaign_types.' +
        campaign.type +
        '.success_phrase.past_tense'
    )
  )

  const successPhrase = I18n.t(
    'thanx_campaigns.report.campaign_types.' +
      campaign.type +
      '.success_phrase.present_tense'
  )

  return (
    <View
      hasControlGroup={hasControlGroup}
      notEnoughData={notEnoughData(config, props)}
      targetFraction={targetFraction(config, props, hasControlGroup)}
      controlFraction={controlFraction(config, props, hasControlGroup)}
      comparisonDescription={comparisonDescription}
      successPhrase={successPhrase}
      hasDivider={hasDivider}
      color={config.color}
    />
  )
}

export default withMetrics(props => {
  return [configs[props.campaign.type].successMetric]
})(ControlGroup)
