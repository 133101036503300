import { Text } from '@thanx/uikit/text'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import React from 'react'
import { stages } from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'

const t = buildTranslate('lifecycle_report.overview.table')

type PropsT = {
  stage: typeof stages[number]
  conversionRate?: string
}

const ConversionCell: React.FC<PropsT> = props => {
  const { stage, conversionRate } = props

  if (stage === 'entered_database') {
    return <>-</>
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-end">
        <Text.BodyText4
          color="grey70"
          className="ellipsis d-inline-block max-width-100 pr-xs"
        >
          {conversionRate}
        </Text.BodyText4>
        <PopoverContent
          sections={[
            {
              content: t(
                `tooltips.stages.${stage}.conversion_rate.description`
              ),
            },
            {
              title: t('tooltips.title.how_it_works'),
              content: t(
                `tooltips.stages.${stage}.conversion_rate.how_it_works`
              ),
            },
            {
              title: t('tooltips.title.recency'),
              content: t('tooltips.recency'),
            },
          ]}
        />
      </div>
      <Text.BodyText5 color="grey70" className="ellipsis max-width-100">
        {t(`conversion_rate.${stage}`)}
      </Text.BodyText5>
    </div>
  )
}

export default ConversionCell
