// @flow
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { bindActionCreators } from 'redux'

import ExportSettings from './ExportSettings'
import {
  getActiveProvider,
  getProviderLists,
  updateProvider,
} from 'actions/emailProviders'
import { createProviderList } from 'actions/emailProviderLists'
// $FlowFixMe
import { alert } from 'actions/flash'
import Spinner from 'components/Spinner'
import { selectActiveProviderByType } from 'selectors/emailProvider'
import { selectEmailProviderListsByProviderId } from 'selectors/emailProviderList'
// $FlowFixMe
import store from 'store'

import type { ReduxAxiosPromise } from 'utilities/types'
import type { EmailProviderListT } from 'models/EmailProviderList'
import type { EmailProviderT } from 'models/EmailProvider'
import type { SubmitFormModel } from './ExportSettings'

type Props = {
  providerType: string,
  activeProvider: EmailProviderT,
  lists: Array<EmailProviderListT>,
  onSuccess: (?string) => void,

  createProviderList: any => ReduxAxiosPromise<{
    email_provider_list: EmailProviderListT,
  }>,
  getActiveProvider: string => ReduxAxiosPromise<*>,
  getProviderLists: number => ReduxAxiosPromise<*>,
  updateProvider: (number, any) => ReduxAxiosPromise<*>,
}

type State = {
  isLoaded: boolean,
  isSubmitting: boolean,
}

export class ExportSettingsContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isLoaded: false,
      isSubmitting: false,
    }
  }

  componentDidMount() {
    this.props
      .getActiveProvider(this.props.providerType)
      .then(action => {
        if (action.error) {
          throw action.error.response ? action.error.response.data.error : null
        }

        return this.props.getProviderLists(this.props.activeProvider.id)
      })
      .then(action => {
        if (action.error) {
          throw action.error.response ? action.error.response.data.error : null
        }

        this.setState({
          isLoaded: true,
        })
      })
      .catch(error => {
        store.dispatch(
          alert({
            key: 'danger',
            message: I18n.t(
              'emails.subscribers.setup.settings.load_lists_error_message'
            ),
            error,
          })
        )
      })
  }

  onSubmit(formModel: SubmitFormModel): void {
    if (formModel.listStrategy === 'existing' && formModel.existingListId) {
      this.submitExistingList(Number(formModel.existingListId))
        .then(() => {
          const listName = this.props.lists.filter(
            list => list.id === Number(formModel.existingListId)
          )[0].name
          return this.props.onSuccess(listName)
        })
        .catch(error => {
          store.dispatch(
            alert({
              key: 'danger',
              message: I18n.t(
                'emails.subscribers.setup.settings.load_lists_error_message'
              ),
              error,
            })
          )
        })
    } else if (formModel.listStrategy === 'new' && formModel.newListName) {
      this.submitNewList(formModel.newListName)
        .then(() => {
          return this.props.onSuccess(formModel.newListName)
        })
        .catch(error => {
          store.dispatch(
            alert({
              key: 'danger',
              message: I18n.t(
                'emails.subscribers.setup.settings.load_lists_error_message'
              ),
              error,
            })
          )
        })
    } else {
      throw new Error('Invalid list strategy')
    }
  }

  submitExistingList(listId: number) {
    const params = {
      signup_list_id: listId,
    }

    return this.props
      .updateProvider(this.props.activeProvider.id, params)
      .then(action => {
        if (action.error) {
          throw action.error.response ? action.error.response.data.error : null
        }
      })
  }

  submitNewList(name: string) {
    return this.props
      .createProviderList({
        email_provider_list: {
          provider_id: this.props.activeProvider.id,
          name: name,
        },
      })
      .then(action => {
        if (action.error) {
          throw action.error.response ? action.error.response.data.error : null
        }

        const list = action.payload.data.email_provider_list
        const params = {
          signup_list_id: list.id,
        }
        return this.props.updateProvider(this.props.activeProvider.id, params)
      })
      .then(action => {
        if (action.error) {
          throw action.error.response ? action.error.response.data.error : null
        }
      })
  }

  renderSpinner() {
    return (
      <Col sm={12}>
        <div className="text-center">
          <Spinner isLoading={true} size="5x" />
        </div>
      </Col>
    )
  }

  render() {
    if (!this.state.isLoaded) {
      return this.renderSpinner()
    }

    return (
      <ExportSettings
        allowCreateNew={this.props.providerType !== 'mailchimp'}
        lists={this.props.lists}
        selectedListId={null}
        isSubmitting={this.state.isSubmitting}
        onSubmit={this.onSubmit.bind(this)}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  const providerId = props.provider && props.provider.id
  return {
    lists: selectEmailProviderListsByProviderId(state.orm, providerId),
    activeProvider: selectActiveProviderByType(state.orm, props.providerType),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createProviderList,
      getActiveProvider,
      getProviderLists,
      updateProvider,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportSettingsContainer)
