import { buildTranslate } from 'locales'
import { UserCountMetric } from 'models/CampaignMetric'
import React from 'react'
import AudienceBreakoutModalv3 from 'scenes/CampaignCenter/Builder/Steps/Shared/Review/components/Audience/components/AudienceBreakoutModalv3'

const t = buildTranslate(
  'thanx_campaigns.review.customers.custom.breakout_modal'
)

type Props = {
  show: boolean
  includeRecentlyRewarded?: boolean
  userCountMetric?: UserCountMetric
  targetDescription: string
  onModalCloseClicked: () => void
}

const AudienceBreakoutModal: React.FC<Props> = props => {
  const {
    show,
    includeRecentlyRewarded,
    userCountMetric,
    targetDescription,
    onModalCloseClicked,
  } = props

  const data = userCountMetric?.data
  const targeted = data?.member_count || 0

  let sections = [
    {
      title: t('targeted.title'),
      items: [
        {
          description: targetDescription,
          count: targeted,
        },
      ],
      id: 'targeted',
    },
  ]

  if (!includeRecentlyRewarded) {
    const excluded = data?.excluded_count || 0
    sections.push({
      title: t('excluded.title'),
      items: [
        {
          description: t('excluded.past_14_days'),
          count: excluded,
        },
      ],
      id: 'excluded',
    })
  }

  return (
    <AudienceBreakoutModalv3
      show={show}
      sections={sections}
      isLoading={userCountMetric?.isLoading}
      isErrored={userCountMetric?.isErrored}
      onModalCloseClicked={onModalCloseClicked}
    />
  )
}

export default AudienceBreakoutModal
