import { getLocation } from 'actions/location'
import useDispatch from 'hooks/useDispatch'
import type { Fields as Campaign } from 'models/Campaign'
import type { UserCountMetric } from 'models/CampaignMetric'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import View from 'scenes/CampaignCenter/Builder/components/AudienceBreakoutModalView'
import { selectLocation } from 'selectors/location'

type Props = {
  show: boolean
  userCountMetric?: UserCountMetric
  onModalCloseClicked: () => void
  campaign: Campaign
}

const AudienceBreakoutModal: React.FC<Props> = props => {
  const { campaign, userCountMetric } = props
  const location = useSelector(state =>
    selectLocation(state, campaign.config_location_id)
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (!location && campaign.config_location_id) {
      dispatch(getLocation(campaign.config_location_id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const campaignType = campaign.type.replace('-', '_')

  function audienceDescription() {
    return (
      <Translate
        value={`thanx_campaigns.campaigns.${campaignType}.steps.review.audience.description`}
        locationName={
          location?.street ||
          I18n.t(
            `thanx_campaigns.campaigns.${campaignType}.steps.review.location_placeholder`
          )
        }
      />
    )
  }

  function targetedItems() {
    return [
      {
        label: audienceDescription(),
        value: userCountMetric?.data?.member_count || 0,
      },
    ]
  }

  function excludedItems() {
    return [
      {
        label: I18n.t(
          'thanx_campaigns.review.customers.breakout_modal.table.excluded.excluded'
        ),
        value: userCountMetric?.data?.excluded_count || 0,
      },
    ]
  }

  return (
    <View
      {...props}
      targetedItems={targetedItems()}
      excludedItems={excludedItems()}
    />
  )
}

export default AudienceBreakoutModal
