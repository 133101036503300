import { useStyletron } from '@thanx/uikit/theme'
import BellIcon from 'assets/images/BellIcon'
import { userIsAuthenticated } from 'auth'
import HeadwayLib from 'components/Headway'
import React from 'react'
import { Nav } from 'react-bootstrap-five'
import { merchantTheme } from 'theme'
import { useIsDesktop } from 'utilities/responsive'

const Headway: React.FC = () => {
  const [css] = useStyletron()
  const headwayId = process.env.REACT_APP_HEADWAY_ACCOUNT_ID
  const isDesktop = useIsDesktop()

  if (!headwayId) return null
  return (
    <Nav.Item
      className={`d-flex align-items-center headway-nav-item ${css({
        padding: '10px 11px 10px ' + (isDesktop ? '8px' : '10px'),
        display: 'inline-block',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        color: merchantTheme.colors.grey30,
        ':hover': {
          backgroundColor: merchantTheme.colors.grey100,
          color: merchantTheme.colors.white,
        },
        ':focus': {
          backgroundColor: merchantTheme.colors.grey100,
          color: merchantTheme.colors.white,
        },
      })}`}
    >
      <BellIcon />
      <HeadwayLib
        accountId={headwayId}
        className={css({
          maxHeight: '10px',
          position: 'relative',
          top: '-19px',
        })}
      />
    </Nav.Item>
  )
}

export default userIsAuthenticated(Headway)
