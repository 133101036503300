import InfoBox from 'components/InfoBox'
import TextExpander from 'components/TextExpander'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignRequirementsByCampaign } from 'selectors/campaignRequirement'

type Props = {
  campaign: Campaign
}

const t = buildTranslate('thanx_campaigns.review.header')

const Header: React.FC<Props> = ({ campaign }) => {
  const requirements = useSelector(state =>
    selectCampaignRequirementsByCampaign(state, campaign.id)
  )
  if (isEmpty(requirements)) return null

  return (
    <InfoBox
      type="info"
      className="mt-l mb-l"
      content={
        <TextExpander
          items={requirements.map(r => r.description)}
          itemNounSingular={t('notifications.noun_singular')}
          itemNounPlural={t('notifications.noun_plural')}
        />
      }
    />
  )
}

export default Header
