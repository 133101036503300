import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { buildTranslate } from 'locales'
import {
  Fields as RedeemTemplate,
  RedeemTemplateState,
} from 'models/RedeemTemplate'
import React from 'react'
import { Col, Container } from 'react-bootstrap-five'
import Tab from 'react-bootstrap-five/Tab'
import { LinkContainer } from 'react-router-bootstrap'
import { useIsMobile } from 'utilities/responsive'
import Item from './Item'

type PropsT = {
  state: RedeemTemplateState
  templates: RedeemTemplate[]
}

const t = buildTranslate('redeem_manager.landing.tabs')

const TabPane: React.FC<PropsT> = props => {
  const { state, templates } = props
  const isMobile = useIsMobile()

  let content = (
    <Container>
      <h3 className="extra-bold">{t(`${state}.subtitle`)}</h3>
      <p className="grey-70">{t(`${state}.description`)}</p>
      <table
        className={`mt-xl ${isMobile ? 'table-responsive' : 'full-width'}`}
      >
        <thead>
          <tr>
            <th className="body-text-4 py-s pr-xl pl-m">
              {t('column_titles.name')}
            </th>
            <th className="body-text-4">{t('column_titles.date_created')}</th>
            <th className="body-text-4">{t('column_titles.status')}</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {templates
            .sort(
              (a, b) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .map(template => (
              <Item key={template.id} template={template} />
            ))}
        </tbody>
      </table>
    </Container>
  )

  if (templates.length === 0) {
    content = (
      <Col lg={6} className="m-auto float-none text-center">
        <h3 className="mt-0 pt-xxl extra-bold">
          {t(`${state}.no_template_title`)}
        </h3>
        <p className="mb-0 pb-xl grey-70">
          {t(`${state}.no_template_description`)}
        </p>
        <Switch condition={['published', 'unpublished'].includes(state)}>
          <LinkContainer to="/rewards/create">
            <Button className="px-l ">{t('create_reward')}</Button>
          </LinkContainer>
        </Switch>
      </Col>
    )
  }

  return (
    <Tab.Pane className="py-l grey-05-bkg" eventKey={state}>
      {content}
    </Tab.Pane>
  )
}

export default TabPane
