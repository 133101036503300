import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import DefaultRichReward from 'components/RewardPreviewCard/RichReward'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ImageRadio, {
  HeaderType,
} from 'scenes/RedeemManager/Builder/ConfigurationStep/ImageRadio'
import { selectRedeemTemplate } from 'selectors/redeemTemplate'
import { customImageUrl, customImageVideoUrl } from 'utilities/urlUtils'
import Card from '../Card'
import CustomImageDescriptors from '../CustomImageDescriptors'
import HowTo from '../HowTo'
import NextButton from '../NextButton'
import Image from './Image'

type Props = {
  templateId: number
  description: string
  isEnabled: boolean
  isOpen: boolean
  isCurrent: boolean
  onNext: () => void
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.appearance_card'
)

const AppearanceCard: React.FC<Props> = ({
  description,
  isCurrent,
  isEnabled,
  isOpen,
  onNext,
  templateId,
}) => {
  const [hasInitialized, setHasInitialized] = useState(false)
  const [headerType, setHeaderType] = useState<HeaderType>('default')
  const [css] = useStyletron()
  const template = useSelector(state => selectRedeemTemplate(state, templateId))
  const hasImage = !!template?.images?.index_image?.small
  const uploadImageId = template?.images?.index_image?.file_upload_id
  const canProceed = headerType === 'default' || hasImage
  useEffect(() => {
    if (hasImage && headerType === 'default' && !hasInitialized) {
      setHeaderType('custom')
      setHasInitialized(true)
    } else if (template && !hasImage) {
      setHasInitialized(true)
    }
  }, [hasImage, hasInitialized, headerType, template])
  const isNDR =
    template?.type === 'experience/hidden_menu' ||
    template?.type === 'experience/golden_ticket'
  return (
    <Card
      title={t('title')}
      subtitle={t('subtitle')}
      isOpen={isOpen}
      isEnabled={isEnabled}
    >
      <Switch
        condition={isEnabled}
        fallback={
          <Text.BodyText3 color="grey70">{t('disabled')}</Text.BodyText3>
        }
      >
        <ImageRadio
          customContent={
            <>
              <CustomImageDescriptors
                className="ml-l mb-xs"
                mainDescriptor={t('custom_img_descriptor_1')}
                linkDescriptor={t('custom_img_descriptor_2', {
                  url: customImageUrl,
                })}
              />
              <Image
                existingId={uploadImageId}
                templateId={templateId}
                isNDR={isNDR}
              />
              <HowTo
                className={`ml-l mt-s ${css({
                  width: 'calc(100% - 32px)',
                })}`}
                type="appearance"
                imageLink={customImageUrl}
                videoLink={customImageVideoUrl}
              />
            </>
          }
          customText={t('show_custom')}
          defaultContent={
            <DefaultRichReward
              className="ml-l mb-s"
              description={description}
              width="343px"
            />
          }
          defaultText={t('show_default')}
          setHeaderType={setHeaderType}
          headerType={headerType}
          templateId={templateId}
          uploadImageId={uploadImageId}
        />
        <Switch condition={isCurrent}>
          <NextButton disabled={!canProceed} onClick={onNext} />
        </Switch>
      </Switch>
    </Card>
  )
}

export default AppearanceCard
