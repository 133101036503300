import { Button, Kind } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import { useStyletron } from '@thanx/uikit/theme'
import 'cropperjs/dist/cropper.css'
import { buildTranslate } from 'locales'
import React, { useRef, useState } from 'react'
import Cropper from 'react-cropper'

const t = buildTranslate('components.cropper_modal')

type Props = {
  isOpen: boolean
  onClose: () => void
  onConfirm: (file: File) => void
  width: number
  height: number
  image: HTMLImageElement
  imageName?: string
  imageType: string
  onSelectImage: () => void
}

const CropperModal: React.FC<Props> = props => {
  const {
    isOpen,
    onClose,
    onConfirm,
    width,
    height,
    image,
    imageName = '',
    imageType,
    onSelectImage,
  } = props
  const imageRef = useRef<HTMLImageElement>(null)
  const [cropper, setCropper] = useState<Cropper>()
  const [css] = useStyletron()

  function cropImage() {
    if (!cropper) return
    cropper.getCroppedCanvas().toBlob(blob => {
      if (!blob) return
      const file = new File([blob], imageName, { type: blob.type })
      onConfirm(file)
    }, imageType)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="auto"
      closeType="small"
      zIndex={1200}
    >
      <div
        className={`text-center p-xl d-flex flex-column
          ${css({
            maxWidth: '1020px',
            maxHeight: '1080px',
          })}
        `}
      >
        <h3 className="m-0">{t('upload_image')}</h3>
        <p className="body-text-3 grey-70 mt-xs mb-m">
          {t('image_requirement', { width, height })}
        </p>
        <Cropper
          style={{ maxHeight: 375, width: '100%', backgroundColor: 'black' }}
          preview=".img-preview"
          guides={true}
          src={image.src}
          ref={imageRef}
          dragMode={'move'}
          background={false}
          responsive={true}
          onInitialized={instance => {
            setCropper(instance)
          }}
          autoCropArea={1}
          viewMode={1}
          aspectRatio={width / height}
        />
        <div className="d-flex my-m align-items-center">
          <Button kind={Kind.TERTIARY} onClick={onSelectImage} className="mr-m">
            {t('change_image')}
          </Button>
          <p className="body-text-3 grey-70">{imageName}</p>
        </div>
        <h3 className="mt-0 mb-s">{t('preview')}</h3>
        <div
          className={'w-100 mb-xl d-flex justify-content-center inline-bloc'}
        >
          <div
            className={`img-preview ${css({
              overflow: 'hidden',
              height: '318px',
              width: '318px',
            })}`}
          />
        </div>
        <div className="d-flex justify-content-center">
          <Button
            kind={Kind.SECONDARY}
            onClick={onClose}
            className={`mr-m ${css({
              width: '244px',
            })}`}
          >
            {t('cancel')}
          </Button>
          <Button
            className={css({
              width: '244px',
            })}
            onClick={cropImage}
          >
            {t('crop_and_upload')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CropperModal
