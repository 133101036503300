import custom from './custom'
import custom_automated from './custom_automated'
import engage_vip from './engage_vip'
import location_close from './location_close'
import location_message from './location_message'
import location_promote from './location_promote'
import location_reopen from './location_reopen'
import message from './message'
import reputation from './reputation'
import timeshift from './timeshift'
import upsell from './upsell'
import winback from './winback'

export default {
  custom,
  custom_automated,
  engage_vip,
  location_close,
  location_message,
  location_promote,
  location_reopen,
  message,
  reputation,
  timeshift,
  upsell,
  winback,
}
