import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import { I18n } from 'react-redux-i18n'

type Props = {
  selectedCategory?: string
  order: string[]
  data: Highcharts.XrangePointOptionsObject[]
  selectedColor: string
  barColor: string
}

const Graph: React.FC<Props> = props => {
  const { selectedCategory, order, data, selectedColor, barColor } = props
  const colors: string[] = []
  order.forEach(category => {
    if (category === selectedCategory) {
      colors.push(selectedColor)
    } else {
      colors.push(barColor)
    }
  })

  const options: Highcharts.Options = {
    credits: { enabled: false },
    tooltip: { enabled: false },
    title: undefined,
    legend: { enabled: false },
    xAxis: {
      lineColor: 'transparent',
      categories: order,
      labels: {
        useHTML: true,
        align: 'left',
        reserveSpace: true,
      },
      minorTickLength: 0,
      tickLength: 0,
    },
    yAxis: {
      visible: false,
    },
    plotOptions: {
      bar: {
        pointWidth: 32,
        colorByPoint: true,
      },
      series: {
        //@ts-ignore
        minPointLength: 2,
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            return `<span class="normal opacity-50">${I18n.l(this.y)}</span>`
          },
        },
      },
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    colors,
    chart: {
      type: 'bar',
      height: `${42 * data.length}px`,
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Lato',
      },
    },
    series: [
      {
        data: data,
        type: 'bar',
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

export default Graph
