// @flow
import React, { Component } from 'react'
import { FormGroup, Row, Col } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'
import FakeInput from 'components/Form/FakeInput'

type Props = {
  handleCSVIngestion: (
    hasHeader: boolean,
    file: any,
    contents: Array<string>
  ) => void,
}
type State = {
  isLoading: boolean,
  isValid: boolean,
  file: ?File,
}

export default class CSVUpload extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isLoading: false,
      isValid: true,
      file: null,
    }
  }

  clickPassthrough() {
    if (this.state.isLoading) return
    // $FlowFixMe
    this.fileInputHandle.click()
  }

  fileDidChange(changeEvent: any) {
    this.setState({
      isLoading: true,
      isValid: true,
    })
    let csv = changeEvent.target.files[0]
    if (!csv) {
      this.setState({ isLoading: false })
      return
    }
    this.readCSV(csv)
  }

  readCSV(csv: File) {
    this.setState({ file: csv })
    let reader = new window.FileReader()
    reader.onloadend = function (loadEvent) {
      this.setState({ isLoading: false })

      let hasHeader = true
      let contents = loadEvent.target.result.split(/\r\n|\n|\r/)
      let emailRegex =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      if (emailRegex.test(contents[0])) {
        hasHeader = false
      } else if (!emailRegex.test(contents[1])) {
        // check the second line to see if it has an email in it. if not, invalid!
        this.setState({ isValid: false })
        return
      }
      this.props.handleCSVIngestion(hasHeader, this.state.file, contents)
    }.bind(this)
    reader.readAsText(csv)
  }

  validationMessage() {
    if (this.state.isValid) return ''
    return I18n.t('emails.recipients.setup.choices.upload.error')
  }

  input() {
    if (this.state.isLoading)
      return <FakeInput isLoading={true} button="upload" />

    let placeholderText = I18n.t(
      'emails.recipients.setup.choices.upload.placeholder'
    )
    if (this.state.file) placeholderText = this.state.file.name

    return (
      <div>
        <FakeInput
          text={placeholderText}
          button="upload"
          clickPassthrough={this.clickPassthrough.bind(this)}
        />
        <span className="text-danger">{this.validationMessage()}</span>
        <FormGroup className="hide">
          <input
            type="file"
            onChange={this.fileDidChange.bind(this)}
            style={{ display: 'none' }}
            // $FlowFixMe
            ref={(el: ?HTMLInputElement) => (this.fileInputHandle = el)}
            accept=".csv"
          />
        </FormGroup>
      </div>
    )
  }

  render() {
    return (
      <Row>
        <Col sm={8}>{this.input()}</Col>
      </Row>
    )
  }
}
