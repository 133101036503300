import { alert } from 'actions/flash'
import { getMerchantMetricByType } from 'actions/merchantMetrics'
import useDispatch from 'hooks/useDispatch'
import includes from 'lodash/includes'
import type { Fields as Campaign } from 'models/Campaign'
import { DayPartCountData, WeekPartCountData } from 'models/MerchantMetric'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectMerchantMetrics } from 'selectors/merchantMetric'
import View from './View'

type Props = {
  campaign: Campaign
}

const TimeshiftContainer: React.FC<Props> = ({ campaign }) => {
  const [isLoading, setIsLoading] = useState(true)
  const locationIds = campaign.config_location_ids
  const isWeekPart = includes(campaign.type, 'week')
  const metricType = isWeekPart ? 'week_part_count' : 'day_part_count'
  const merchantMetrics = useSelector(selectMerchantMetrics)
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      const response = await dispatch(
        getMerchantMetricByType(metricType, {
          location_ids: locationIds,
        })
      )
      setIsLoading(false)

      if (response.error) {
        const error = response.error?.response?.data?.error || null
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('thanx_campaigns.review.get_metrics_error'),
            error,
            displayDetails: false,
          })
        )
      }
    }

    fetchData()
  }, [locationIds, metricType, dispatch])

  const metrics = isWeekPart
    ? merchantMetrics.week_part_count?.data
    : merchantMetrics.day_part_count?.data
  return (
    <View
      isLoading={isLoading}
      campaign={campaign}
      metrics={metrics || ({} as WeekPartCountData | DayPartCountData)}
      isWeekPart={isWeekPart}
    />
  )
}

export default TimeshiftContainer
