import RadioButton from 'components/Form/RadioButton'
import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { UserTypeT } from 'utilities/emailUtils'

type Props = {
  userType: UserTypeT
  navStyle: 'tab' | 'radio'
  activeKey: UserTypeT
}

function PreviewNavItem({ navStyle, userType, activeKey }: Props) {
  if (navStyle === 'radio') {
    return (
      <RadioButton
        className="font-size-14 grey-70"
        checked={activeKey === userType}
        title={I18n.t(
          `thanx_campaigns.builder.forms.messaging_tabs.preview.tabs.${userType}.radio_title`
        )}
      />
    )
  } else {
    return (
      <div className="vertical-middle small-caps font-size-12">
        <Translate
          value={`thanx_campaigns.builder.forms.messaging_tabs.preview.tabs.${userType}.tab_title`}
        />
      </div>
    )
  }
}

export default PreviewNavItem
