import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { RedeemMetric } from 'models/CampaignMetric'
import { RedeemType } from 'models/RedeemTemplate'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import { notAvailable } from 'scenes/CampaignCenter/Report/v3/helpers'
import InfoRow from 'scenes/CampaignCenter/Report/v3/Test/components/InfoRow'
import { selectCampaignVariantMetricByType } from 'selectors/campaignMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3')

type PropsT = {
  campaignId: number
  redeemTypes: (RedeemType | undefined)[]
  variantIds: number[]
  variantTypes: (string | undefined)[]
}

const Redeemed: React.FC<PropsT> = props => {
  const { campaignId, redeemTypes, variantIds, variantTypes } = props

  const metrics =
    (useSelector(state =>
      selectCampaignVariantMetricByType(state, campaignId, 'redeem', variantIds)
    ) as RedeemMetric[]) || {}
  const { attributionWindow } = useAttributionWindow()

  const variants = variantIds.map((_, i) => {
    const redeemType = redeemTypes[i] || ''
    if (redeemType === 'experience/hidden_menu') {
      return notAvailable()
    }
    const primarySections =
      variantTypes[i] === 'control'
        ? [
            {
              content: t('test.tooltips.no_reward'),
            },
          ]
        : [
            {
              content: t('summary.revenue.tooltips.percent_of_redeemed', {
                count: attributionWindow,
              }),
            },
            {
              title: t('summary.how_its_calc'),
              content: t('summary.revenue.tooltips.percent_redeemed_calc'),
            },
          ]
    const secondarySections =
      variantTypes[i] === 'control'
        ? [
            {
              content: t('test.tooltips.no_reward'),
            },
          ]
        : [
            {
              content: t('summary.revenue.tooltips.number_of_redeemed', {
                count: attributionWindow,
              }),
            },
            {
              title: t('summary.how_its_calc'),
              content: t('summary.revenue.tooltips.number_redeemed_calc', {
                count: attributionWindow,
              }),
            },
          ]
    return {
      primaryContent: (
        <Metric
          metric={percentageFormatter(
            metrics[i]?.data?.redeemed_fraction,
            1,
            1
          )}
        >
          <PopoverContent sections={primarySections} />
        </Metric>
      ),
      secondaryContent: (
        <Metric
          primary={false}
          metric={numberFormatter(metrics[i]?.data?.redeemed_count)}
        >
          <PopoverContent sections={secondarySections} />
        </Metric>
      ),
      isErrored: metrics[i]?.isErrored,
      isLoading: metrics[i]?.isLoading,
    }
  })
  return <InfoRow title={t('summary.revenue.redeemed')} variants={variants} />
}

export default Redeemed
