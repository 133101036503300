import { defaultTheme } from '@thanx/uikit/theme'
import { Tab, Tabs } from 'baseui/tabs'
import { buildTranslate } from 'locales'
import { CampaignConfig } from 'models/Campaign'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React, { useState } from 'react'
import { StyleObject } from 'styletron-react'
import General from './General'
import MultipleInstore from './MultipleInstore'
import Online from './Online'

type PropsT = {
  config: CampaignConfig
  template?: RedeemTemplate
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.template.details.sections'
)

const Details: React.FC<PropsT> = (props: PropsT) => {
  const { config, template } = props
  const [currentTab, setCurrentTab] = useState<string | number>('0')
  if (!config.redeem) return null

  const tabStyle: StyleObject = {
    paddingTop: '24px',
    paddingBottom: '8px',
    paddingLeft: 0,
    paddingRight: 0,
    flex: '1 1 0px',
    textAlign: 'center',
    marginLeft: 0,
    marginRight: 0,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    ...defaultTheme.typography.smallCaps2,
  }

  return (
    <Tabs
      activeKey={currentTab}
      onChange={({ activeKey }) => setCurrentTab(activeKey)}
      overrides={{
        TabBar: {
          style: () => ({
            justifyContent: 'space-around !important',
            backgroundColor: 'transparent',
            padding: 0,
            borderBottom: `1px solid ${defaultTheme.colors.grey20}`,
          }),
        },
        TabContent: {
          style: () => ({
            paddingTop: '24px',
            paddingLeft: 0,
            paddingBottom: 0,
          }),
        },
      }}
    >
      <Tab
        overrides={{
          Tab: {
            style: () => tabStyle,
          },
        }}
        title={t('general.name')}
      >
        <General config={config} />
      </Tab>

      <Tab
        overrides={{
          Tab: {
            style: () => tabStyle,
          },
        }}
        title={t('online.name')}
      >
        <Online categoryName={template?.category_name} config={config} />
      </Tab>

      <Tab
        overrides={{
          Tab: {
            style: () => tabStyle,
          },
        }}
        title={t('instore.name')}
      >
        <MultipleInstore config={config} template={template} />
      </Tab>
    </Tabs>
  )
}

export default Details
