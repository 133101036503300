import React from 'react'

export type KindTypes = 'primary' | 'secondary'

type Props = {
  children: React.ReactNode
  className?: string
  key?: string
  kind?: KindTypes
}

const Section: React.FC<Props> = props => {
  const { children, className = '', key, kind = 'primary' } = props
  const bkg = kind === 'secondary' ? 'grey-10-bkg' : 'white-bkg'
  return (
    <div
      className={`${bkg} grey-20-border rounded border-1 ${className}`}
      key={key}
    >
      {children}
    </div>
  )
}

export default Section
