export type FullStoryT = {
  shutdown: () => void
  identify: (
    email: string | boolean,
    content?: {
      displayName: string
      email: string
    }
  ) => void
  setUserVars: (vars: { merchant: string }) => void
}

export function getFullStory(): Window | FullStoryT | undefined {
  //@ts-ignore
  const namespace = window._fs_namespace
  if (!namespace) return undefined
  return window[namespace]
}
