import { Button } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import isUndefined from 'lodash/isUndefined'
import React, { useState } from 'react'
import VariantRadio from 'scenes/CampaignCenter/Builder/components/VariantRadio'

type PropsT = {
  isOpen: boolean
  setIsOpen: (boolean) => void
  onDelete: (number) => void
  variants: any // TODO replace with array of variant type
}

const t = buildTranslate('thanx_campaigns.builder.variants.deletion_modal')

const DeletionModal: React.FC<PropsT> = props => {
  const { isOpen, setIsOpen, onDelete, variants = [] } = props

  const [variantForDeletion, setVariantForDeletion] = useState<
    number | undefined
  >()

  const handleDelete = () => {
    setIsOpen(false)
    onDelete(variantForDeletion)
  }

  return (
    <Modal
      size="auto"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      closeType="small"
    >
      <div className="p-xl">
        <Text.Header3 bold className="mb-xs">
          {t('title')}
        </Text.Header3>
        <Text.BodyText4 className="mb-m" tag="p">
          {t('content')}
        </Text.BodyText4>
        <VariantRadio
          className="mb-xl"
          variants={variants.filter(v => v.internal_type !== 'control')}
          setSelectedVariant={setVariantForDeletion}
          translate="thanx_campaigns.builder.variants.deletion_modal.keep_variant"
        />
        <div className="d-flex justify-content-end">
          <Button kind="secondary" onClick={() => setIsOpen(false)}>
            {t('buttons.cancel')}
          </Button>
          <Button
            kind="primary"
            className="ml-s"
            disabled={isUndefined(variantForDeletion)}
            onClick={handleDelete}
          >
            {t('buttons.keep')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeletionModal
