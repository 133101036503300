import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import EditNavbar from 'components/Navbar/EditNavbar'
import { buildTranslate } from 'locales'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap-five'
import { LinkContainer } from 'react-router-bootstrap'
import { merchantTheme } from 'theme'

const t = buildTranslate('points.multipliers.layout')

type Props = {
  canPublish: boolean
  onPublish?: () => void
  children: React.ReactNode
}

const Layout = ({ onPublish, canPublish, children }: Props) => {
  const [css] = useStyletron()

  return (
    <div
      className={`h-100 ${css({
        marginTop: '30px',
        backgroundColor: merchantTheme.colors.grey05,
      })}`}
    >
      <EditNavbar title={t('title')}>
        <div>
          <LinkContainer to="/points#multipliers">
            <Button kind={Kind.SECONDARY_REVERSE} className="mr-s">
              {t('exit')}
            </Button>
          </LinkContainer>
          <Button
            kind={Kind.PRIMARY_REVERSE}
            onClick={onPublish}
            disabled={!canPublish}
          >
            {t('publish')}
          </Button>
        </div>
      </EditNavbar>
      <Container className="h-100">
        <Row>
          <Col className="p-xl">
            <div className="mb-xl">
              <Text.Header2 className="mb-xs mt-0">
                {t('page_header')}
              </Text.Header2>
            </div>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Layout
