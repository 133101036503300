import { getCampaignMetricByType } from 'actions/campaignMetrics'
import Loading from 'components/Loading'
import useDispatch from 'hooks/useDispatch'
import type { Fields as Campaign } from 'models/Campaign'
import type { Metrics } from 'models/CampaignMetric'
import { ShortType } from 'models/CampaignMetric'
import React, { useEffect, useState } from 'react'
import type { ComponentType } from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignMetricsById } from 'selectors/campaignMetric'

interface ExistingPropsT {
  campaign: Campaign
  hasDivider?: boolean
  locationName?: string
}

interface InjectedPropsT {
  metrics?: Metrics
}

export type MetricsProps = ExistingPropsT & InjectedPropsT

const withMetric =
  (metricNames: ShortType[] | ((names: any) => ShortType[])) =>
  (
    WrappedComponent: ComponentType<MetricsProps>
  ): ComponentType<MetricsProps> => {
    const MetricComponent: React.ComponentType<MetricsProps> = (
      props: ExistingPropsT
    ) => {
      const { campaign } = props
      const [isLoading, setIsLoading] = useState(true)
      const metrics = useSelector(state =>
        selectCampaignMetricsById(state, campaign.id)
      )
      const [firstMetrics] = useState(metrics)
      const [firstProps] = useState(props)
      const dispatch = useDispatch()

      useEffect(() => {
        async function fetchMetrics() {
          const promises = getMetricNames().map(metricName => {
            if (!firstMetrics?.[metricName]) {
              return dispatch(getCampaignMetricByType(campaign.id, metricName))
            }
            return null
          })
          await Promise.all(promises)
          setIsLoading(false)
        }

        function getMetricNames() {
          if (typeof metricNames === 'function') {
            return metricNames(firstProps)
          }
          return metricNames
        }

        if (campaign.state === 'scheduled') {
          setIsLoading(false)
        } else {
          fetchMetrics()
        }
      }, [campaign.id, campaign.state, dispatch, firstMetrics, firstProps])

      if (isLoading) {
        return <Loading />
      }

      return <WrappedComponent {...props} metrics={metrics} />
    }

    return MetricComponent
  }

export default withMetric
