import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { MessageCountMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import InfoRow from 'scenes/CampaignCenter/Report/v3/Test/components/InfoRow'
import { selectCampaignVariantMetricByType } from 'selectors/campaignMetric'
import { numberFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3')

type PropsT = {
  campaignId: number
  variantIds: number[]
  variantTypes: (string | undefined)[]
}

const Delivered: React.FC<PropsT> = props => {
  const { campaignId, variantIds, variantTypes } = props
  const metrics =
    (useSelector(state =>
      selectCampaignVariantMetricByType(
        state,
        campaignId,
        'message_count',
        variantIds
      )
    ) as MessageCountMetric[]) || {}
  const variants = variantIds.map((_, i) => {
    const primaryValue = numberFormatter(metrics[i]?.data?.delivered_count)
    const sections =
      variantTypes[i] === 'control'
        ? [
            {
              content: t('test.tooltips.delivered.control'),
            },
          ]
        : [
            {
              content: t('test.tooltips.delivered.variant1'),
            },
            {
              title: t('summary.how_its_calc'),
              content: t('test.tooltips.delivered.variant2'),
            },
            {
              title: t('summary.example'),
              content: t('test.tooltips.delivered.variant3'),
            },
            {
              content: t('test.tooltips.delivered.variant4'),
            },
            {
              content: t('test.tooltips.delivered.variant5'),
            },
          ]
    return {
      primaryContent: (
        <Metric metric={primaryValue}>
          <PopoverContent sections={sections} />
        </Metric>
      ),
      isErrored: metrics[i]?.isErrored,
      isLoading: metrics[i]?.isLoading,
    }
  })
  return <InfoRow title={t('test.delivered')} variants={variants} />
}

export default Delivered
