import {
  GetCumulativeAction,
  GetTimeSeriesAction,
  GET_CUMULATIVE_CAPTURE_RATE_METRICS,
  GET_CUMULATIVE_CAPTURE_RATE_METRICS_FAIL,
  GET_CUMULATIVE_CAPTURE_RATE_METRICS_SUCCESS,
  GET_TIME_SERIES_CAPTURE_RATE_METRICS,
  GET_TIME_SERIES_CAPTURE_RATE_METRICS_FAIL,
  GET_TIME_SERIES_CAPTURE_RATE_METRICS_SUCCESS,
  ResolvedAction,
} from 'actions/captureRateMetrics'
import { RESET_DB } from 'actions/orm'
import { attr, Model, ModelType } from 'redux-orm'
import { EmptyObject } from 'utilities/types'

interface Data {
  capture_rate: number | null
  total_amount: number
  member_purchases_total_amount: number
  date_time: string
  capture_rate_with_third_party: number | null
  total_amount_with_third_party: number | null
}

export interface ChannelData {
  date_time: string
  member_purchases_digital_amount: number
  member_purchases_digital_percent: number
  member_purchases_instore_amount: number
  member_purchases_instore_percent: number
  member_purchases_total_amount: number
  non_member_purchases_digital_amount: number
  non_member_purchases_digital_percent: number
  non_member_purchases_instore_amount: number
  non_member_purchases_instore_percent: number
  third_party_amount: number
  third_party_percent: number
}

interface BaseMetric {
  id: string
  merchant_id: number
  location_id: number | null
  type: 'cumulative' | 'rate_time_series' | 'channel_time_series'
  isLoading?: boolean
  isErrored?: boolean
}

export interface CumulativeMetric extends BaseMetric {
  type: 'cumulative'
  data?: Data
  current?: Data
  previous?: Data
  status: 'complete' | 'no_data' | 'processing'
  revenue_source?: 'pos' | 'manual' | null
}

export interface RateTimeSeriesMetric extends BaseMetric {
  type: 'rate_time_series'
  channel_selected: false
  granularity: 'monthly'
  time_zone: string
  values: Array<Data>
}

export interface ChannelTimeSeriesMetric extends BaseMetric {
  type: 'channel_time_series'
  channel_selected: true
  granularity: 'monthly'
  time_zone: string
  values: Array<ChannelData>
}

export type Fields =
  | CumulativeMetric
  | RateTimeSeriesMetric
  | ChannelTimeSeriesMetric

export default class CaptureRateMetric extends Model<
  // @ts-ignore
  typeof CaptureRateMetric,
  Fields
> {
  static modelName: string = 'CaptureRateMetric'

  static get fields(): any {
    return {
      points_experience_id: attr(),
      type: attr(),
      data: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action:
      | ResolvedAction
      | { type: typeof RESET_DB }
      | GetCumulativeAction
      | GetTimeSeriesAction,
    // @ts-ignore
    CaptureRateMetric: ModelType<CaptureRateMetric>
  ) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_CUMULATIVE_CAPTURE_RATE_METRICS: {
        const params = action.payload.request?.params || {}
        const location = params.location_id || 'all'
        CaptureRateMetric.upsert({
          current: {},
          previous: {},
          id: `${params.merchant_id}_cumulative_${location}`,
          type: 'cumulative',
          isLoading: true,
          isErrored: false,
        })
        break
      }
      case GET_CUMULATIVE_CAPTURE_RATE_METRICS_SUCCESS: {
        const params = action.meta.previousAction.payload.request.params || {}
        const location = params.location_id || 'all'
        CaptureRateMetric.upsert({
          ...action.payload.data,
          id: `${params.merchant_id}_cumulative_${location}`,
          type: 'cumulative',
          isLoading: false,
          isErrored: false,
        })
        break
      }
      case GET_CUMULATIVE_CAPTURE_RATE_METRICS_FAIL: {
        const previousAction = action.meta.previousAction as
          | GetCumulativeAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const location = params.location_id || 'all'
        CaptureRateMetric.upsert({
          id: `${params.merchant_id}_cumulative_${location}`,
          type: 'cumulative',
          isLoading: false,
          isErrored: true,
        })
        break
      }

      case GET_TIME_SERIES_CAPTURE_RATE_METRICS: {
        const params = action.payload.request?.params || {}
        const type = params.filter_channel ? 'channel' : 'rate'
        const location = params.location_id || 'all'
        CaptureRateMetric.upsert({
          id: `${params.merchant_id}_${type}_time_series_${location}`,
          type: `${type}_time_series`,
          isLoading: true,
          isErrored: false,
        })
        break
      }
      case GET_TIME_SERIES_CAPTURE_RATE_METRICS_SUCCESS: {
        const params = action.meta.previousAction.payload.request.params || {}
        const type = params.filter_channel ? 'channel' : 'rate'
        const location = params.location_id || 'all'
        CaptureRateMetric.upsert({
          ...action.payload.data,
          id: `${params.merchant_id}_${type}_time_series_${location}`,
          type: `${type}_time_series`,
          isLoading: false,
          isErrored: false,
        })
        break
      }
      case GET_TIME_SERIES_CAPTURE_RATE_METRICS_FAIL: {
        const previousAction = action.meta.previousAction as
          | GetTimeSeriesAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const type = params.filter_channel ? 'channel' : 'rate'
        const location = params.location_id || 'all'
        CaptureRateMetric.upsert({
          id: `${params.merchant_id}_${type}_time_series_${location}`,
          type: `${type}_time_series`,
          isLoading: false,
          isErrored: true,
        })
        break
      }

      case RESET_DB:
        CaptureRateMetric.all().delete()
        break
      default:
        break
    }
  }
}
