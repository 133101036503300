import { FormsyCheckbox } from '@thanx/uikit/checkbox'
import { FormsyInput } from '@thanx/uikit/input'
import { FormsyRadioGroup, Radio } from '@thanx/uikit/radio'
import { FormsyTextArea } from '@thanx/uikit/textarea'
import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import {
  getAlertBanner,
  GET_ALERT_BANNER_SUCCESS,
  updateAlertBanner,
  UPDATE_ALERT_BANNER_FAIL,
} from 'actions/banners'
import { alert } from 'actions/flash'
import ConfirmModal from 'components/ConfirmModal'
import ColorPicker from 'components/Form/ColorPicker'
import Section from 'components/Section'
import Formsy from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import CmsPage from 'scenes/Cms/components/CmsPage'
import PublishBanner from 'scenes/Cms/components/PublishBanner'
import Sidebar from './Sidebar'

const t = buildTranslate('cms.content.alert_banner')

export enum DismissType {
  YES = 'yes',
  NO = 'no',
}

type FormModel = {
  visibility: boolean
  title: string
  backgroundColor: string
  textColor: string
  body: string
  buttonText: string
  buttonUrl: string
  dismissType: DismissType
}

const AlertBanner: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formModel, setFormModel] = useState<FormModel>({
    visibility: false,
    title: '',
    backgroundColor: '',
    textColor: '',
    body: '',
    buttonText: '',
    buttonUrl: '',
    dismissType: DismissType.YES,
  })
  const [css] = useStyletron()
  const dispatch = useDispatch()

  async function publishChanges() {
    setIsSubmitting(true)
    const response = await dispatch(
      updateAlertBanner({
        title: formModel.title,
        body_text: formModel.body,
        background_color: formModel.backgroundColor,
        text_color: formModel.textColor,
        dismissable: formModel.dismissType === DismissType.YES,
        enabled: formModel.visibility,
        button_text: formModel.buttonText,
        button_url: formModel.buttonUrl,
      })
    )

    setIsSubmitting(false)
    setIsModalOpen(false)

    if (response.type === UPDATE_ALERT_BANNER_FAIL) {
      dispatch(
        alert({
          key: 'danger',
          message: t('submit_error'),
          timeout: 5,
        })
      )
      return
    }

    dispatch(
      alert({
        key: 'success',
        message: t('submit_success'),
        timeout: 5,
      })
    )
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const response = await dispatch(getAlertBanner())

      if (response.type === GET_ALERT_BANNER_SUCCESS) {
        const banner = response.payload.data.alert_banner
        setFormModel({
          visibility: banner.enabled,
          title: banner.title || '',
          backgroundColor: banner.background_color,
          textColor: banner.text_color,
          body: banner.body_text || '',
          buttonText: banner.button_text || '',
          buttonUrl: banner.button_url || '',
          dismissType: banner.dismissable ? DismissType.YES : DismissType.NO,
        })
      }

      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  return (
    <Formsy
      onValidSubmit={() => {
        setIsModalOpen(true)
      }}
      onChange={newFormModel => {
        setFormModel(newFormModel)
      }}
      onInvalidSubmit={() =>
        dispatch(
          alert({
            key: 'danger',
            message: I18n.t('cms.content.form_error'),
            timeout: 5,
          })
        )
      }
    >
      <CmsPage
        title={t('title')}
        description={t('description')}
        badges={[t('app_web')]}
        sidebar={<Sidebar />}
        isLoading={isLoading}
      >
        <Section className="p-m my-m">
          <p className="font-size-14 bold mb-m">{t('banner_visibility')}</p>
          {/* @ts-ignore */}
          <FormsyCheckbox name="visibility" value={formModel.visibility}>
            {t('make_visible')}
          </FormsyCheckbox>
          <p
            className={`
            ${css({
              marginLeft: '40px',
            })}
            body-text-5 grey-70 mb-xs mt-0`}
          >
            {t('visibility_description')}
          </p>
        </Section>
        <Section className="p-m mb-m">
          <ColorPicker
            name="backgroundColor"
            label={t('banner_color')}
            validations={{
              hex: true,
              isRequired: true,
            }}
            value={formModel.backgroundColor}
            validationErrors={{
              hex: I18n.t('validations.hex.message'),
              isRequired: I18n.t('validations.is_required'),
            }}
            startEnhancer
          />
          <p className="body-text-5 grey-70 mt-xs mb-m">
            {t('color_description')}
          </p>
          <ColorPicker
            name="textColor"
            label={t('text_color')}
            validations={{
              hex: true,
              isRequired: true,
            }}
            value={formModel.textColor}
            validationErrors={{
              hex: I18n.t('validations.hex.message'),
              isRequired: I18n.t('validations.is_required'),
            }}
            startEnhancer
          />
          <FormsyInput
            // @ts-ignore
            className="my-m"
            name="title"
            label={t('banner_title')}
            validations={{
              maxLength: 40,
            }}
            validationErrors={{
              maxLength: t('max_length_button'),
            }}
            value={formModel.title}
          />
          <FormsyTextArea
            // @ts-ignore
            className="my-xs"
            name="body"
            label={t('banner_body')}
            validations={{
              isRequired: true,
              maxLength: 130,
            }}
            validationErrors={{
              isRequired: t('body_required'),
              maxLength: t('max_length_body'),
            }}
            value={formModel.body}
            overrides={{
              Input: {
                style: () => ({
                  minHeight: '64px',
                  fontSize: `14px`,
                  color: defaultTheme.colors.grey70,
                }),
              },
            }}
          />
        </Section>
        <Section className="p-m mb-m">
          <FormsyInput
            // @ts-ignore
            className="mb-m"
            name="buttonText"
            label={t('button_text')}
            validations={{
              maxLength: 40,
              areBothRequired: 'buttonUrl',
            }}
            validationErrors={{
              maxLength: t('max_length_button'),
              areBothRequired: I18n.t('validations.is_required'),
            }}
            value={formModel.buttonText}
          />
          <FormsyInput
            // @ts-ignore
            name="buttonUrl"
            label={t('button_url')}
            value={formModel.buttonUrl}
            validations={{
              isUrl: true,
              areBothRequired: 'buttonText',
            }}
            validationErrors={{
              isUrl: I18n.t('validations.is_url'),
              areBothRequired: I18n.t('validations.is_required'),
            }}
          />
        </Section>
        <Section className="p-m mb-m">
          <h4 className="mb-xxs">{t('allow_customers')}</h4>
          <p className="body-text-5 grey-70">{t('allow_description')}</p>
          <FormsyRadioGroup
            // @ts-ignore
            name="dismissType"
            align={'horizontal'}
            value={formModel.dismissType}
          >
            <Radio value={DismissType.YES}>{t('yes')}</Radio>
            <Radio value={DismissType.NO} className="ml-m">
              {t('no')}
            </Radio>
          </FormsyRadioGroup>
        </Section>
        <PublishBanner buttonText={t('publish_alert')} />
      </CmsPage>
      <ConfirmModal
        title={t('modal.publish_changes')}
        description={t('modal.you_are_publishing')}
        confirmText={t('modal.publish')}
        bannerText={t('modal.note')}
        isOpen={isModalOpen}
        isLoading={isSubmitting}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => publishChanges()}
      />
    </Formsy>
  )
}

export default AlertBanner
