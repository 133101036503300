import { FormsyInput } from '@thanx/uikit/input'
import { Radio, RadioGroup } from '@thanx/uikit/radio'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { I18n } from 'react-redux-i18n'

type PropsT = {
  url?: string
}

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.instore_redemption_card.type.manager_comp_url'
)
const Url: React.FC<PropsT> = props => {
  const { url } = props
  const [urlVisible, setUrlVisible] = useState(!isEmpty(url))
  return (
    <>
      <Text.BodyText4 bold tag="p" className="mb-xs">
        {t('title')}
      </Text.BodyText4>
      <RadioGroup name="hasUrl" value={`${urlVisible}`} align="horizontal">
        <Radio
          onClick={() => setUrlVisible(false)}
          overrides={{
            Label: {
              style: {
                marginRight: '24px',
              },
            },
            Root: {
              style: {
                alignItems: 'flex-start',
              },
            },
          }}
          value={'false'}
        >
          <Text.BodyText3 color="grey70">{t('no_url')}</Text.BodyText3>
        </Radio>
        <Radio
          onClick={() => setUrlVisible(true)}
          overrides={{
            Label: {
              style: {
                width: '100%',
              },
            },
            Root: {
              style: {
                alignItems: 'flex-start',
              },
            },
          }}
          value={'true'}
        >
          <Text.BodyText3 color="grey70">{t('url')}</Text.BodyText3>
        </Radio>
      </RadioGroup>
      {urlVisible && (
        <Switch condition={urlVisible}>
          <FormsyInput
            // @ts-ignore
            placeholder={t('placeholder')}
            value={url}
            name="url"
            validations={{
              isRequired: true,
              isUrl: true,
            }}
            validationErrors={{
              isRequired: I18n.t('validations.is_required'),
              isUrl: I18n.t('validations.is_url'),
            }}
          />
        </Switch>
      )}
    </>
  )
}

export default Url
