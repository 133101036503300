import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'

type PropsT = {
  status: string
}

const t = buildTranslate('users.view.activity.orders.status_badge')

const StatusBadge: React.FC<PropsT> = ({ status }: PropsT) => {
  const [css] = useStyletron()
  let color, backgroundColor
  let className = ''
  let border: string = ''
  switch (status) {
    case 'completed':
    case 'placed':
      color = merchantTheme.colors.nephritis50
      backgroundColor = merchantTheme.colors.nephritis10
      break
    case 'billed':
      color = merchantTheme.colors.belizeHole50
      backgroundColor = merchantTheme.colors.belizeHole10
      break
    case 'canceled':
    case 'pending':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.white
      break
    case 'refunded':
      color = merchantTheme.colors.cayenne50
      backgroundColor = merchantTheme.colors.cayenne10
      border = `1px solid ${merchantTheme.colors.grey20}`
      break
    case 'archived':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.grey20
      break
    default:
      break
  }

  return (
    <Badge
      value={t(status)}
      className={`${className} ${css({ backgroundColor, color, border })}`}
    />
  )
}

export default StatusBadge
