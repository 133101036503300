import {
  GetEffectiveDiscountRateMetricsAction,
  GET_EFFECTIVE_DISCOUNT_RATE_METRICS,
  GET_EFFECTIVE_DISCOUNT_RATE_METRICS_FAIL,
  GET_EFFECTIVE_DISCOUNT_RATE_METRICS_SUCCESS,
  ResolvedAction,
} from 'actions/effectiveDiscountRate'
import { RESET_DB } from 'actions/orm'
import { DateRangeGranularity } from 'components/DateFilter/utilities'
import { attr, Model, ModelType } from 'redux-orm'
import { EmptyObject } from 'utilities/types'

export enum Type {
  CUMULATIVE = 'effective_discount_rate_metric_cumulative',
  TIME_SERIES = 'effective_discount_rate_metric_time_series',
  COST_EFFICACY = 'effective_discount_rate_metric_cost_efficacy',
}

export enum ShortType {
  CUMULATIVE = 'cumulative',
  TIME_SERIES = 'time_series',
  COST_EFFICACY = 'cost_efficacy',
}

export type MetricTypes = Array<ShortType>

interface Data {
  date_time: string
  effective_discount_rate: number
  cost_amount: number
  purchases_total_amount: number
}

interface RewardData {
  date_time: string
  name: string
  amount_spent_to_earn: number | null
  cost_per_redemption: number | null
  configured_discount_rate: number | null
  reward_redemptions_count: number
  reward_redemption_cost: number
  live_in_marketplace: boolean
}

interface BaseMetric {
  id: string
  type: Type
  isLoading: boolean
  isErrored: boolean
}

export interface EffectiveDiscountRateCumulativeMetric extends BaseMetric {
  type: Type.CUMULATIVE
  current: Data
  previous: Data
  timezone: string
  granularity: DateRangeGranularity
}

export interface EffectiveDiscountRateTimeSeriesMetric extends BaseMetric {
  type: Type.TIME_SERIES
  values: Array<Data>
  timezone: string
  granularity: DateRangeGranularity
}

export interface EffectiveDiscountRateCostEfficacyMetric extends BaseMetric {
  type: Type.COST_EFFICACY
  date_time: string
  granularity: DateRangeGranularity
  timezone: string
  values: Array<RewardData>
}

const getTypeFromPath = url => {
  const paths: string[] = url.split('/') || []
  return `effective_discount_rate_metric_${paths[paths.length - 1]}` as Type
}

export type Fields =
  | EffectiveDiscountRateCumulativeMetric
  | EffectiveDiscountRateTimeSeriesMetric
  | EffectiveDiscountRateCostEfficacyMetric

export default class EffectiveDiscountRateMetric extends Model<
  // @ts-ignore
  typeof EffectiveDiscountRateMetric,
  Fields
> {
  static modelName: string = 'EffectiveDiscountRateMetric'

  static get fields(): any {
    return {
      merchant_id: attr(),
      type: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action:
      | ResolvedAction
      | { type: typeof RESET_DB }
      | GetEffectiveDiscountRateMetricsAction,
    // @ts-ignore
    EffectiveDiscountRateMetric: ModelType<EffectiveDiscountRateMetric>
  ) {
    switch (action.type) {
      case GET_EFFECTIVE_DISCOUNT_RATE_METRICS: {
        const params = action.payload.request?.params || {}
        const url: string = action.payload?.request?.url || ''
        const type = getTypeFromPath(url)
        EffectiveDiscountRateMetric.upsert({
          id: `${params.merchant_id}_${type}`,
          type: type,
          isLoading: true,
          isErrored: false,
        })
        break
      }
      case GET_EFFECTIVE_DISCOUNT_RATE_METRICS_SUCCESS: {
        const previousAction = action.meta.previousAction as
          | GetEffectiveDiscountRateMetricsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const url: string = previousAction.payload?.request?.url || ''
        const type = getTypeFromPath(url)
        EffectiveDiscountRateMetric.upsert({
          ...action.payload.data,
          id: `${params.merchant_id}_${type}`,
          merchant_id: params.merchant_id,
          type: type,
          isLoading: false,
          isErrored: false,
        })
        break
      }
      case GET_EFFECTIVE_DISCOUNT_RATE_METRICS_FAIL: {
        const previousAction = action.meta.previousAction as
          | GetEffectiveDiscountRateMetricsAction
          | EmptyObject
        const params = previousAction.payload.request.params || {}
        const url: string = previousAction.payload?.request?.url || ''
        const type = getTypeFromPath(url)
        EffectiveDiscountRateMetric.upsert({
          id: `${params.merchant_id}_${type}`,
          type: type,
          isLoading: false,
          isErrored: true,
        })
        break
      }
      case RESET_DB:
        EffectiveDiscountRateMetric.all().delete()
        break
      default:
        break
    }
  }
}
