import { Button, Kind } from '@thanx/uikit/button'
import { useStyletron } from '@thanx/uikit/theme'
import ConfirmModal from 'components/ConfirmModal'
import EditNavbar from 'components/Navbar/EditNavbar'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import Tab from 'react-bootstrap-five/Tab'
import { ContentBlockData } from '../../hooks'
import Content from './Content'
import { getContentTabAvailable } from './helper'
import Style from './Style'

type Props = {
  id: string
  position: number
  contentBlock: ContentBlockData
  onClose: (previousState: ContentBlockData) => void
  onDelete: (id: string) => void
  isLoading: boolean
}

export enum Tabs {
  Content = 'content',
  Style = 'style',
}

const t = buildTranslate('cms.content.app_home_page.content.edit_block')
const tBlock = buildTranslate('cms.content.app_home_page.content.block_item')

const EditBlock = ({
  id,
  position,
  contentBlock,
  onClose,
  onDelete,
  isLoading,
}: Props) => {
  const isContentTabAvailable = getContentTabAvailable(contentBlock.type)
  const startingTab = isContentTabAvailable ? Tabs.Content : Tabs.Style
  const [activeTab, setActiveTab] = useState<Tabs>(startingTab)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [previousContentBlockState] = useState<ContentBlockData>(contentBlock)
  const [css] = useStyletron()

  return (
    <div
      className="h-100 w-100"
      style={{
        position: 'fixed',
        top: '0px',
        paddingTop: '80px',
        left: '0px',
        display: 'flex',
      }}
    >
      <EditNavbar
        title={`${tBlock(`${contentBlock.type}.title`)} - ${position}`}
      >
        <Button
          kind={Kind.MINIMAL}
          className={css({ color: 'white' })}
          onClick={() => onClose(previousContentBlockState)}
        >
          {t('cancel')}
        </Button>
        <Button
          kind={Kind.MINIMAL}
          className={css({ color: 'white' })}
          onClick={() => setIsModalOpen(true)}
        >
          {t('delete.button')}
        </Button>
        <Button
          kind={Kind.SECONDARY_REVERSE}
          disabled={isLoading}
          type="submit"
        >
          {t('save')}
        </Button>
      </EditNavbar>

      <div className="d-flex flex-1 white-bkg flex-column">
        <Tab.Container
          activeKey={activeTab}
          transition={false}
          onSelect={(tab: string | null) => {
            if (!tab) return
            setActiveTab(tab as Tabs)
          }}
        >
          <TabNav>
            <TabItem
              state={Tabs.Content}
              title={t('tabs.content')}
              disabled={!isContentTabAvailable}
            />
            <TabItem state={Tabs.Style} title={t('tabs.style')} />
          </TabNav>
          <Tab.Content className="h-100 grey-05-bkg overflow-scroll">
            <TabPane state={Tabs.Content}>
              <Content blockId={id} contentBlock={contentBlock} />
            </TabPane>
            <TabPane state={Tabs.Style}>
              <Style blockId={id} contentBlock={contentBlock} />
            </TabPane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <ConfirmModal
        title={t('delete.modal.title')}
        description={t('delete.modal.description')}
        confirmText={t('delete.modal.confirm')}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => onDelete(id)}
      />
    </div>
  )
}

export default EditBlock
