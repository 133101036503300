import { useStyletron } from '@thanx/uikit/theme'
import palette from 'constants/palette'
import React from 'react'

type PropsT = {
  className?: string
  color?: string
}

const ControlGroup: React.FC<PropsT> = props => {
  const { className, color = palette.grey90 } = props
  const [css] = useStyletron()

  return (
    <span
      className={`${className} ${css({
        color: color,
        left: '10px',
        width: '32px',
      })}`}
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.8736 24.8192C24.3067 27.9801 20.3892 30 16 30C8.26801 30 2 23.732 2 16C2 12.8323 3.05205 9.91033 4.8258 7.56441L8.46608 10.4133C8.1773 10.6891 8 11.0777 8 11.5V20.5C8 21.3438 8.65625 22 9.5 22H22.5C22.7145 22 22.9203 21.9543 23.107 21.8714L26.8736 24.8192ZM27.9493 23.2986L24 20.2079V11.5C24 10.6875 23.3125 10 22.5 10H10.9566L6.0451 6.15622C8.58286 3.59 12.1057 2 16 2C23.732 2 30 8.26801 30 16C30 18.6743 29.2502 21.1734 27.9493 23.2986ZM32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM22.5 14.7188V19.034L19.7552 16.8859C20.996 15.9 21.8686 15.2129 22.5 14.7188ZM20.0305 14.746C19.5981 15.0865 19.1027 15.4767 18.5311 15.9278L12.8733 11.5H22.5V12.7812C21.9866 13.2054 21.2659 13.773 20.0305 14.746ZM17.0202 17.1079L9.8546 11.5H9.5V12.7812C10.0593 13.2643 10.9081 13.9334 12.4838 15.1756L12.4839 15.1757L12.4848 15.1763C12.8458 15.4609 13.2449 15.7756 13.6875 16.125C13.7853 16.1998 13.9 16.2947 14.0268 16.3996L14.0268 16.3996C14.5889 16.8645 15.3881 17.5255 16 17.5C16.3046 17.5132 16.6652 17.3413 17.0202 17.1079ZM21.3546 20.5L18.2522 18.072C17.6093 18.5526 16.8296 19.0198 16 19C14.849 19.0268 13.7897 18.1593 13.0774 17.5761C12.9581 17.4784 12.8486 17.3887 12.75 17.3125C12.3148 16.9661 11.9199 16.6525 11.5619 16.3681C10.6603 15.6521 9.99209 15.1214 9.5 14.7188V20.5H21.3546Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default ControlGroup
