import { Switch } from '@thanx/uikit/switch'
import React from 'react'
import { Alert } from 'react-bootstrap'
import CheckboxListItem from './CheckboxListItem'
import CheckboxListSum from './CheckboxListSum'

export type CheckboxItemObject = {
  inputName: string
  isChecked: boolean
  title: string
  subtitle: React.ReactNode
  count: number | undefined
  unit: string
  isLoading: boolean
  validations: { [value: string]: any }
  onItemChanged(value: any): void
  disabled?: boolean
}

export type CheckboxListSumObject = {
  title: string
  unit: string
}

type Props = {
  items: CheckboxItemObject[]
  sum: CheckboxListSumObject
  errorMessage: null | string
}

const CheckboxList: React.FC<Props> = props => {
  const { items, sum, errorMessage } = props
  const itemsLoading = items.map(item => item.isLoading)
  const shouldDisplaySum =
    !!sum && items.every(item => item.count !== undefined && !isNaN(item.count))

  return (
    <div>
      <Switch condition={errorMessage !== null}>
        <Alert bsStyle="danger">
          <span dangerouslySetInnerHTML={{ __html: errorMessage || '' }} />
        </Alert>
      </Switch>
      <div>
        {items.map((item, index) => {
          return (
            <CheckboxListItem
              key={index}
              inputName={item.inputName}
              isChecked={item.isChecked}
              title={item.title}
              subtitle={item.subtitle}
              count={item.count}
              unit={item.unit}
              isLoading={item.isLoading}
              validations={item.validations}
              onChange={item.onItemChanged}
              isLast={items.length - 1 === index && !sum}
              disabled={item.disabled || false}
            />
          )
        })}
        <Switch condition={shouldDisplaySum}>
          <CheckboxListSum
            title={sum?.title}
            count={items
              .filter(item => item.isChecked)
              .reduce(
                (accumulator, item) => accumulator + (item.count || 0),
                0
              )}
            unit={sum ? sum.unit : ''}
            isLoading={itemsLoading.reduce(
              (accumulator, isLoading) => accumulator || isLoading,
              false
            )}
          />
        </Switch>
      </div>
    </div>
  )
}

export default CheckboxList
