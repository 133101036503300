import Spinner from 'components/Spinner'
import palette from 'constants/palette'
import omit from 'lodash/omit'
import sum from 'lodash/sum'
import values from 'lodash/values'
import type { Fields as Campaign } from 'models/Campaign'
import type { DayPartCountData, WeekPartCountData } from 'models/MerchantMetric'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import Refinements from 'scenes/CampaignCenter/Builder/components/Refinements'
import LocationRefinement from 'scenes/CampaignCenter/Builder/components/Refinements/LocationRefinement'
import Graph from './components/Graph'
import Stat from './components/Stat'

type Props = {
  campaign: Campaign
  isLoading: boolean
  metrics: DayPartCountData | WeekPartCountData
  isWeekPart: boolean
}

const View: React.FC<Props> = props => {
  const { campaign, isLoading, metrics, isWeekPart } = props
  const hasLocations =
    campaign.config_location_ids && campaign.config_location_ids.length > 0
  const currentPeriod = campaign.type.split('-').pop() || ''
  const totalTargeted = sum(values(omit(metrics, `${currentPeriod}_count`)))
  const order = isWeekPart
    ? ['weekday_count', 'weekend_count']
    : [
        'morning_count',
        'midday_count',
        'afternoon_count',
        'evening_count',
        'night_count',
      ]
  const data = order.map(period => metrics[period])

  return (
    <Spinner
      isLoading={isLoading}
      size="4x"
      className="text-center padding-top-huge"
    >
      <div className="container">
        <Row>
          <Col md={6} className="padding-top-huge">
            <div className="padding-right-huge">
              <Translate
                value="thanx_campaigns.campaigns.timeshift.steps.setup.title"
                tag="p"
                className="grey-90 font-size-32 margin-bottom-micro bold line-height-40"
                period={I18n.t(
                  `thanx_campaigns.campaigns.timeshift.naming.${currentPeriod}`
                )}
                conjunction={I18n.t(
                  `thanx_campaigns.campaigns.timeshift.steps.setup.${
                    isWeekPart ? 'weekpart' : 'daypart'
                  }`
                )}
              />
              <Translate
                value="thanx_campaigns.campaigns.timeshift.steps.setup.subtitle"
                tag="em"
                className="grey-50 font-size-20 margin-bottom-extra-large block"
              />
              <div className="margin-bottom-extra-large">
                <Stat
                  amount={totalTargeted}
                  period={currentPeriod}
                  varied={metrics.other_count}
                  isWeekPart={isWeekPart}
                />
              </div>
              <div className="margin-bottom-extra-large">
                <Refinements isOpen={hasLocations} isDisabled={hasLocations}>
                  <LocationRefinement campaign={campaign} />
                </Refinements>
              </div>
            </div>
          </Col>
          <Col md={6} className="padding-huge grey-10-bkg">
            <div className="text-center padding-top-huge">
              <Translate
                value="thanx_campaigns.campaigns.timeshift.steps.setup.graph.title"
                className="font-size-18 grey-50"
              />
            </div>
            <div className="padding-top-extra-large margin-bottom-huge">
              <Graph
                order={order}
                currentPeriod={currentPeriod}
                data={data}
                selectedColor={palette.grey40}
                barColor={palette.wisteria}
              />
            </div>
            <div className="text-center">
              <Translate
                value="thanx_campaigns.campaigns.timeshift.steps.setup.graph.subtitle"
                tag="em"
                className="grey-50"
              />
            </div>
          </Col>
        </Row>
      </div>
    </Spinner>
  )
}

export default View
