import { buildTranslate } from 'locales'
import { ValidationTypes } from 'models/Validation'
import React from 'react'
import { useSelector } from 'react-redux'
import ImageUploader from 'scenes/RedeemManager/Builder/ImageUploader'
import { selectRedeemDetailValidation } from 'selectors/validation'

const t = buildTranslate('redeem_manager.builder.steps.configuration.images')

type Props = {
  existingId?: number
  templateId: number
}

const ImageUpload: React.FC<Props> = ({ templateId }) => {
  const imageValidations = useSelector(selectRedeemDetailValidation)
  const badgeValues = [t('app_only'), t('instore_size'), t('jpg'), t('png')]
  const marginClass = !imageValidations ? '' : 'pt-xs mb-s'

  return (
    <ImageUploader
      className={`w-100 ${marginClass}`}
      badgeValues={badgeValues}
      imageValidations={imageValidations}
      type={ValidationTypes.RedeemDetail}
      templateId={templateId}
    />
  )
}

export default ImageUpload
