import { useStyletron } from '@thanx/uikit/theme'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import compact from 'lodash/compact'
import sortBy from 'lodash/sortBy'
import { VariantOrCampaign } from 'models/CampaignVariant'
import { Fields as NotifyEmail } from 'models/NotifyEmail'
import React from 'react'
import {
  useCopyEmail,
  useNotifyEmailVariantMeta,
} from 'scenes/CampaignCenter/Builder/hooks'
import { merchantTheme } from 'theme'

type Props = {
  emails: NotifyEmail[]
  isLoading: boolean
  currentVariant: VariantOrCampaign
  campaignId: number
  onModalClose: () => void
  onSelectPreview: (notifyEmail: NotifyEmail) => void
}

const CampaignList: React.FC<Props> = props => {
  const {
    emails,
    isLoading,
    currentVariant,
    campaignId,
    onModalClose,
    onSelectPreview,
  } = props

  const t = buildTranslate(
    'thanx_campaigns.builder.forms.messaging_tabs.import.modal.email_list'
  )

  const { copyEmail } = useCopyEmail(currentVariant, campaignId)
  const { getEmailVariantMeta } = useNotifyEmailVariantMeta()

  const handleCopy = (sourceEmail: NotifyEmail) => {
    copyEmail(sourceEmail)
    onModalClose()
  }

  const getRowName = (rowEmail: NotifyEmail) => {
    if (rowEmail.type === 'campaign') {
      return rowEmail.campaign_name
    }

    const variantMeta = getEmailVariantMeta(rowEmail)
    const variantName = variantMeta ? `Variant ${variantMeta.name}` : null
    return compact([rowEmail.campaign_name, variantName]).join(' - ')
  }

  const [css] = useStyletron()
  const linkClassName = `p-m cursor-pointer ${css({
    color: merchantTheme.colors.belizeHole50,
  })}`

  if (isLoading) {
    return <Spinner isLoading className="mt-m d-flex justify-content-center" />
  }

  const fromOtherCampaigns = emails.filter(
    email => email.campaign_id !== campaignId
  )

  if (fromOtherCampaigns.length === 0) {
    return (
      <div className="mt-xl font-size-14 grey-70">
        {t('no_campaigns_available')}
      </div>
    )
  }

  function sortEmails(email: NotifyEmail) {
    return -new Date(email.start_at || email.updated_at)
  }

  return (
    <table className="full-width mt-m">
      <tbody>
        {sortBy(fromOtherCampaigns, sortEmails).map(email => {
          return (
            <tr className="border-bottom-1 grey-30-border p-m" key={email.id}>
              <td className="p-m">{getRowName(email)}</td>
              <td className={linkClassName}>
                <span onClick={() => onSelectPreview(email)}>
                  {t('preview')}
                </span>
              </td>
              <td className={linkClassName}>
                <span onClick={() => handleCopy(email)}>{t('copy')}</span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default CampaignList
