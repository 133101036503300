import { buildTranslate } from 'locales'
import React from 'react'
import { useAbility } from 'utilities/ability'
import AccordionButton from '../Bar/AccordionButton'
import MenuItem from '../Bar/MenuItem'
import FeedbackContent from './Content'

const t = buildTranslate('nav.feedback_menu')

type PropsT = {
  isLeftNav?: boolean
  onClick?: () => void
  paths: string[]
}

const Feedback: React.FC<PropsT> = ({
  isLeftNav = false,
  onClick = () => {},
  paths,
}) => {
  const ability = useAbility()
  const menuDisabled =
    ability.cannot('access', 'Feedback') &&
    ability.cannot('access', 'NpsReports')

  const Parent = isLeftNav ? AccordionButton : MenuItem
  return (
    <Parent disabled={menuDisabled} paths={paths} buttonContents={t('button')}>
      <FeedbackContent isLeftNav={isLeftNav} onClick={onClick} />
    </Parent>
  )
}

export default Feedback
