import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { MerchantUser } from 'api/merchantUsers'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import compact from 'lodash/compact'
import React from 'react'
import { Link } from 'react-router-dom'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { useStyletron } from 'styletron-react'
import useCurrentMerchantUser from '../../hooks/useCurrentMerchantUser'
import { roleTitle } from './helpers'
import StatusBadge from './StatusBadge'

const t = buildTranslate('merchant_users.merchant_users_list.table')

type Props = {
  merchantUser: MerchantUser & { role?: string }
  onRemove: VoidFunction
}

const Row = ({ merchantUser, onRemove }: Props) => {
  const merchant = useCurrentMerchant()
  const currentMerchantUser = useCurrentMerchantUser()
  const [css] = useStyletron()
  const name =
    compact([merchantUser.first_name, merchantUser.last_name]).join(' ') ||
    merchantUser.email

  const hideActions = currentMerchantUser?.id !== merchantUser.id

  return (
    <tr>
      <BodyCell
        isErrored={false}
        isLoading={false}
        values={[{ value: name }]}
      />
      <BodyCell
        isErrored={false}
        isLoading={false}
        values={[
          {
            value: roleTitle(
              merchantUser.role,
              merchant?.merchant_type === 'Mall'
            ),
          },
        ]}
      />
      <BodyCellContainer
        className={'text-left'}
        isErrored={false}
        isLoading={false}
      >
        <StatusBadge pending={merchantUser.pending} />
      </BodyCellContainer>
      <BodyCellContainer
        className={'text-right'}
        isErrored={false}
        isLoading={false}
      >
        <Switch condition={hideActions}>
          <Link to={`/merchant_users/edit/${merchantUser.id}`}>
            <Text.BodyText4 tag="span" className="ml-xs mb-0" color="primary">
              {t('edit')}
            </Text.BodyText4>
          </Link>
        </Switch>
      </BodyCellContainer>
      <BodyCellContainer
        className={'text-right'}
        isErrored={false}
        isLoading={false}
      >
        <Switch condition={hideActions}>
          <span onClick={onRemove}>
            <Text.BodyText4
              tag="span"
              className={`ml-xs mb-0 ${css({ cursor: 'pointer' })}`}
              color="primary"
            >
              {t('remove')}
            </Text.BodyText4>
          </span>
        </Switch>
      </BodyCellContainer>
    </tr>
  )
}

export default Row
