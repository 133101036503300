import { buildTranslate } from 'locales'
import { Fields } from 'models/Location'

import useFlag from 'hooks/useFlag'

import { Switch } from '@thanx/uikit/switch'
import HeaderCell from 'components/HeaderCell'
import Row from 'scenes/Locations/Landing/Row'

const t = buildTranslate('locations.location_list.table')

type PropsT = {
  locations: Fields[]
  isLoading: boolean
}

const Table = ({ locations, isLoading }: PropsT) => {
  const isEditableLocationPhoneEnabled = useFlag(
    'MX-Editable-Location-PhoneNumber',
    false
  )

  return (
    <table className="full-width">
      <thead>
        <tr>
          <HeaderCell className={'w-75'}>{t('location')}</HeaderCell>
          <HeaderCell className={'w-75'}>{t('phone')}</HeaderCell>
          <HeaderCell />
          <Switch condition={isEditableLocationPhoneEnabled}>
            <HeaderCell />
          </Switch>
        </tr>
      </thead>
      <tbody>
        {locations.map(location => (
          <Row key={location.id} location={location} isLoading={isLoading} />
        ))}
      </tbody>
    </table>
  )
}

export default Table
