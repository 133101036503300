import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { Slide } from 'api/onboardingSlideshow'
import eye from 'assets/images/cms/eye.png'
import fingerpoint from 'assets/images/cms/fingerpoint.png'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate('cms.signup.onboarding_slideshow.content.block_item')

type DescriptionProps = {
  text: string
  image: string
}

const Description = ({ text, image }: DescriptionProps) => {
  const [css] = useStyletron()
  return (
    <div className={'d-flex mt-xs'}>
      <img
        className={`mr-xs mt-xxs ${css({
          height: '12px',
          width: '16px',
        })}`}
        src={image}
      />
      <Text.BodyText4 className={'mb-0'} tag={'p'} color="grey70">
        {text}
      </Text.BodyText4>
    </div>
  )
}

type Props = {
  type: Slide['type']
}

const Descriptions = ({ type }: Props) => {
  switch (type) {
    case 'simple':
      return <Description image={fingerpoint} text={t('next_slide_note')} />
    case 'push_notification':
      return (
        <>
          <Description image={eye} text={t('push_slide_note')} />
          <Description image={fingerpoint} text={t('push_slide_behavior')} />
        </>
      )
    case 'authentication':
      return (
        <>
          <Description image={eye} text={t('auth_slide_restrictions')} />
          <Description image={fingerpoint} text={t('auth_slide_note')} />
        </>
      )
  }
}

export default Descriptions
