import { Switch } from '@thanx/uikit/switch'
import { FormsyToggle, Toggle } from '@thanx/uikit/toggle'
import isNil from 'lodash/isNil'
import React, { useEffect, useState } from 'react'

type Props = {
  children?: React.ReactNode
  hasToggle?: boolean
  helpBlock?: string | boolean
  toggleName?: string
  toggleValue?: boolean
  title: string
  subtitle?: string
  alwaysShowSubtitle?: boolean
  onChange?: (checked: boolean) => void
}

/**
 * A section for a restriction with a toggle that shows/hides the subform.
 * Sometimes the toggle is a is a form element itself, and sometimes it is only
 * there to show/hide the rest of the form. If it is a significant form element,
 * Pass a "toggleName" and this will use a `FormsyToggle`.
 */
const RestrictionSection: React.FC<Props> = props => {
  const {
    children,
    hasToggle = true,
    helpBlock = null,
    toggleName,
    toggleValue = false,
    title,
    subtitle,
    alwaysShowSubtitle = false,
    onChange,
  } = props

  const [show, setShow] = useState(toggleValue)
  const showContents = !hasToggle || show

  const showSubtitle = (subtitle && showContents) || alwaysShowSubtitle
  const isFormsyToggle = !isNil(toggleName) && !isNil(toggleValue)

  function handleChange(checked: boolean) {
    setShow(checked)
    onChange && onChange(checked)
  }

  // Formsy components are weird. They are uncontrolled, but overwrite
  // their internal state if `value` changes. We do the same with our
  // internal state if this is a formsy toggle to keep the ui consistent
  useEffect(() => {
    if (isFormsyToggle) {
      setShow(toggleValue)
    }
  }, [toggleValue, isFormsyToggle])

  const toggle = (function () {
    if (!hasToggle) return null

    if (isFormsyToggle) {
      return (
        <FormsyToggle
          // @ts-ignore
          name={toggleName || ''}
          value={toggleValue}
          labelPlacement="left"
          onChange={handleChange}
        />
      )
    } else {
      return (
        <Toggle labelPlacement="left" onChange={handleChange} checked={show} />
      )
    }
  })()

  return (
    <div className="py-xl" data-testid="restriction-section">
      <div className="d-flex justify-content-between">
        <div className="mr-m">
          <div className="body-text-2">{title}</div>
          <Switch condition={showSubtitle}>
            <div className="grey-50 body-text-4">{subtitle}</div>
          </Switch>
        </div>
        {toggle}
      </div>
      {showContents && <div className="width-80 mt-m">{children}</div>}
      {helpBlock && (
        <div className="mr-m mt-m">
          <div className="grey-50 body-text-4">{helpBlock}</div>
        </div>
      )}
    </div>
  )
}

export default RestrictionSection
