import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { Location } from 'api/locations'
import { Purchase } from 'api/purchases'
import { buildTranslate } from 'locales'
import moment from 'moment'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import OrderDetailsModal from 'scenes/Users/View/Activity/OrderDetailsModal'
import { merchantTheme } from 'theme'

const t = buildTranslate('feedbacks.responses.modal.order_details')

type Props = {
  score?: number
  scoreBackgroundColor: string
  purchase?: Purchase
  location?: Location
}

const OrderDetails = (props: Props) => {
  const [css] = useStyletron()
  const { score, scoreBackgroundColor, purchase, location } = props
  const [displayOrderDetailsModal, setDisplayOrderDetailsModal] =
    useState(false)
  const purchasedAt = purchase?.purchased_at
    ? moment(purchase.purchased_at).format('MMM DD, YYYY')
    : null

  return (
    <div className="d-flex flex-column mx-xl mt-m mb-xl">
      <Text.Header4 className="mb-m" tag="div" bold>
        {t('title')}
      </Text.Header4>
      <Text.Header3
        className={`d-flex mb-m ${css({
          backgroundColor: scoreBackgroundColor,
          borderRadius: '4px',
          height: '48px',
          width: '48px',
          justifyContent: 'center',
          alignItems: 'center',
          color: `${merchantTheme.colors.white} !important`,
        })}`}
        tag="div"
        bold
      >
        {score}
      </Text.Header3>
      <div className="d-flex flex-row">
        <div className="d-flex flex-column mr-xxxl">
          <Text.BodyText4 className="mb-xs" tag="div" bold>
            {t('subtitleOne')}
          </Text.BodyText4>
          <Text.BodyText4 tag="div">{purchase?.id}</Text.BodyText4>
          <Text.BodyText4 tag="div">{purchasedAt}</Text.BodyText4>
          <Switch condition={!!purchase}>
            <Button
              onClick={() => setDisplayOrderDetailsModal(true)}
              className="px-0"
              bsStyle="link"
            >
              {t('view_details')}
            </Button>
          </Switch>
        </div>
        {/* Purchases can be imported from receipts without a location */}
        {!!location && (
          <div className="d-flex flex-column">
            <Text.BodyText4 className="mb-xs" tag="div" bold>
              {t('subtitleTwo')}
            </Text.BodyText4>
            <Text.BodyText4 tag="div">{location.name}</Text.BodyText4>
            <Text.BodyText4 tag="div">{location.street}</Text.BodyText4>
            <Text.BodyText4 tag="div">
              {`${location.city}, ${location.state} ${location.zip}`}
            </Text.BodyText4>
          </div>
        )}
      </div>
      {purchase && (
        <OrderDetailsModal
          show={displayOrderDetailsModal}
          order={purchase}
          onHide={() => setDisplayOrderDetailsModal(false)}
        />
      )}
    </div>
  )
}

export default OrderDetails
