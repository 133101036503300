import { Fields, LandingLinkSection } from 'models/LandingLink'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectLandingLinks = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.LandingLink.all().toRefArray()
  }
)

export const selectCtaLinks = createSelector(
  selectLandingLinks,
  (links: Fields[]): Fields[] => {
    return links.filter(link => link.section === LandingLinkSection.CTA)
  }
)

export const selectMainLinks = createSelector(
  selectLandingLinks,
  (links: Fields[]): Fields[] => {
    return links.filter(link => link.section === LandingLinkSection.Main)
  }
)
