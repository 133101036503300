import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const UPDATE_PASSWORD = 'accounts/UPDATE_PASSWORD'
export const UPDATE_PASSWORD_SUCCESS = 'accounts/UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAIL = 'accounts/UPDATE_PASSWORD_FAIL'

export function updateMerchantUserPassword(
  params
): AxiosAction<typeof UPDATE_PASSWORD> {
  return {
    type: UPDATE_PASSWORD,
    payload: {
      client: 'auth',
      request: {
        url: 'accounts/update_password',
        method: 'post',
        params: params,
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof updateMerchantUserPassword>
>
