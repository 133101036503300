import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import paperPlane from 'components/Icons/img_paperPlane.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import { merchantTheme } from 'theme'
import { productFeedbackUrl } from 'utilities/urlUtils'

type PropsT = {
  className?: string
}

const t = buildTranslate('looker')

const LookerFooter: React.FC<PropsT> = props => {
  const { className = '' } = props

  const [css] = useStyletron()

  return (
    <div
      className={`d-flex flex-column align-items-center p-xxl ${css({
        backgroundColor: merchantTheme.colors.wisteria10,
        borderRadius: merchantTheme.borders.radius100,
      })} ${className}`}
    >
      <img src={paperPlane} alt="paper plane" />
      <Text.Header4 className="my-s text-center" bold color="grey90">
        {t('footer.text')}
      </Text.Header4>
      <Button onClick={() => window.open(productFeedbackUrl, '_blank')}>
        {t('footer.button')}
      </Button>
    </div>
  )
}

export default LookerFooter
