import { CustomTag } from 'models/UserProfile'
import React from 'react'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import MembershipTagContent from './MembershipTagContent'
import SpecialOcassionContent from './SpecialOcassionContent'

type PropsT = {
  tag: CustomTag
}

const Row: React.FC<PropsT> = ({ tag }: PropsT) => {
  const contentComponent =
    tag.type === 'membershipTag' ? (
      <MembershipTagContent tag={tag} />
    ) : (
      <SpecialOcassionContent tag={tag} />
    )
  return (
    <tr>
      <BodyCell
        isErrored={false}
        isLoading={false}
        values={[{ value: tag.key }]}
      />
      {contentComponent}
    </tr>
  )
}

export default Row
