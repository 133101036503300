import { Select } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import Spinner from 'components/Spinner'
import moment, { Moment } from 'moment-timezone'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { getFormattedDate } from 'utilities/date'
import CustomDateRangeFilter from './CustomDateRangeFilter'
import { DateRangeFilterContext } from './DateFilterContext'
import { DateInputsProvider } from './DateInputsContext'
import {
  DateRangeFilter,
  dateRangeFilterOptions,
  getDateFilter,
} from './utilities'

type Props = {
  showDateLabel?: boolean
  showCustomFilter?: boolean
  isLoading?: boolean
  allTimeStartDate?: Moment | null
  allTimeEndDate?: Moment | null
}

const DateFilter: React.FC<Props> = props => {
  const {
    allTimeStartDate,
    allTimeEndDate,
    isLoading = false,
    showCustomFilter = false,
    showDateLabel = false,
  } = props
  const timeZone = moment.tz.guess()
  const { filter, setFilter } = useContext(DateRangeFilterContext)
  const { start, end, range } = filter
  const [showCustomPicker, setShowCustomPicker] = useState(
    filter.type === 'custom'
  )
  const [css] = useStyletron()
  const prevFilter = useRef(filter)
  const selectRef = useRef<HTMLSpanElement>(null)
  const options = showCustomFilter
    ? dateRangeFilterOptions
    : dateRangeFilterOptions.filter(option => option.value !== 'custom')

  useEffect(() => {
    const handleClickSelect = event => {
      // if the select is opened and the current filter is custom,
      // we want to show the custom picker right away
      if (selectRef.current && selectRef.current.contains(event.target)) {
        if (filter.type === 'custom') {
          setShowCustomPicker(true)
        }
      }
    }

    document.addEventListener('click', handleClickSelect)
    return () => {
      document.removeEventListener('click', handleClickSelect)
    }
  }, [filter.type])

  const onChange = selections => {
    const selection = selections[0]
    if (selection.value === 'custom') {
      setShowCustomPicker(true)
      // save the filter's value so it can be reverted if the custom modal is canceled
      prevFilter.current = filter
      setFilter({
        ...filter,
        type: 'custom',
      })
      return
    }
    const start = allTimeStartDate
      ? allTimeStartDate.format('YYYY-MM-DD')
      : null
    const end = allTimeEndDate ? allTimeEndDate.format('YYYY-MM-DD') : null
    setFilter(getDateFilter(selection.value, timeZone, start, end))
  }

  const cancelCustomFilter = () => {
    setShowCustomPicker(false)
    setFilter(prevFilter.current)
  }

  const applyCustomFilter = (updatedFilter: DateRangeFilter) => {
    setFilter(updatedFilter)
    prevFilter.current = updatedFilter
    setShowCustomPicker(false)
  }

  const fromValue = (() => {
    if (start) {
      return getFormattedDate(moment.tz(start, timeZone), 'daily')
    }

    if (range === 'all_time' && allTimeStartDate) {
      return getFormattedDate(allTimeStartDate, 'daily')
    }

    return null
  })()
  const toValue = (() => {
    if (end) {
      return getFormattedDate(moment.tz(end, timeZone), 'daily')
    }

    if (range === 'all_time' && allTimeEndDate) {
      return getFormattedDate(allTimeEndDate, 'daily')
    }

    return null
  })()

  return (
    <div>
      <div className="d-flex">
        <span ref={selectRef} className={`${css({ width: '200px' })}`}>
          <Select
            searchable={false}
            options={options}
            value={options.find(option => option.value === filter.type)}
            onChange={onChange}
            // hide the dropdown if the custom filter is displayed
            zIndex={showCustomPicker && filter.type === 'custom' ? -99 : 1}
          />
        </span>
        {showDateLabel && (
          <span className="d-flex pl-s align-items-center">
            <Switch
              condition={!(range === 'all_time' && isLoading)}
              fallback={<Spinner className="mr-xxs" isLoading />}
            >
              {fromValue && toValue && (
                <Text.BodyText4 color="grey70">
                  {fromValue} - {toValue}
                </Text.BodyText4>
              )}
            </Switch>
          </span>
        )}
      </div>
      <Switch condition={showCustomPicker}>
        <DateInputsProvider>
          <CustomDateRangeFilter
            timeZone={timeZone}
            onCancel={cancelCustomFilter}
            onApply={applyCustomFilter}
          />
        </DateInputsProvider>
      </Switch>
    </div>
  )
}

export default DateFilter
