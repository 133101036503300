import { useStyletron } from '@thanx/uikit/theme'
import useOnScreen from 'hooks/useOnScreen'
import React, { useRef } from 'react'

type Props = {
  radius: number
  fraction?: number
  content: React.ReactNode
  strokeWidth?: number
  strokeColor?: string
  markFraction?: number
  markColor?: string
  markLength?: number
  markWidth?: number
  animate?: boolean
}

const calculateMarkStroke = ({
  circleRadius,
  edge,
  markLength,
  markFraction,
}) => {
  const angle = 2 * Math.PI * markFraction

  const center = {
    x: edge / 2,
    y: edge / 2,
  }

  // The circle radius defines the midpoint of the circle stroke, so we must
  // start one half-mark length inside the radius...
  const start = {
    x: center.x + Math.sin(angle) * (circleRadius - markLength / 2),
    y: center.y - Math.cos(angle) * (circleRadius - markLength / 2),
  }

  // ...and extend one half-mark length outside the radius
  const end = {
    x: center.x + Math.sin(angle) * (circleRadius + markLength / 2),
    y: center.y - Math.cos(angle) * (circleRadius + markLength / 2),
  }

  return `M ${start.x} ${start.y} L ${end.x} ${end.y}`
}

const CircleChart: React.FC<Props> = props => {
  const {
    radius,
    fraction = 1,
    content,
    strokeColor,
    strokeWidth = 8,
    markFraction,
    markColor,
    markLength = 0,
    markWidth = 3,
    animate,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const [css] = useStyletron()
  const isVisible = useOnScreen(ref)
  // As the mark extends past the the stroke, we must accommodate some extra
  // padding in the SVG so the mark does not get cut off
  const paddingForMark = markLength > strokeWidth ? markLength - strokeWidth : 0

  // There is an extra 2px padding here for whitespace
  const edge = 2 * radius + strokeWidth + paddingForMark + 2
  const circumference = 2 * Math.PI * radius
  const fill = circumference * (1 - fraction) || 0

  // As we are using a "round" linecap, SVG will add the rounding on top of
  // the mark length, so we must draw a shorter stroke than requested, as
  const actualStrokeLength = markLength - markWidth

  return (
    <div className="circle-chart relative" ref={ref}>
      <svg height={edge} width={edge}>
        <circle
          className="background"
          cx="50%"
          cy="50%"
          r={radius}
          style={{
            strokeWidth,
          }}
        />
        <circle
          className={`foreground ${
            animate && isVisible && fraction > 0.15 ? 'fadein' : ''
          } ${css({
            opacity: animate && fraction > 0.15 ? 0 : 1,
          })}`}
          cx="50%"
          cy="50%"
          r={radius}
          strokeDasharray={circumference}
          strokeDashoffset={fill}
          style={{
            strokeWidth,
            stroke: strokeColor,
          }}
        />
        {!!markLength && (
          <path
            stroke={markColor}
            strokeWidth={markWidth}
            strokeLinecap="round"
            d={calculateMarkStroke({
              circleRadius: radius,
              edge: edge,
              markLength: actualStrokeLength,
              markFraction: markFraction,
            })}
          />
        )}
      </svg>
      <div
        className="absolute top-0 left-0 display-flex flex-justify-center flex-align-center"
        style={{ height: edge, width: edge }}
      >
        {content}
      </div>
    </div>
  )
}

export default CircleChart
