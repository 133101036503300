import { Switch } from '@thanx/uikit/switch'
import Input from 'components/Form/Input'
import Spinner from 'components/Spinner'
import palette from 'constants/palette'
import type { Fields as Campaign } from 'models/Campaign'
import type { Fields as Location } from 'models/Location'
import type { VipSpendData } from 'models/MerchantMetric'
import numeral from 'numeral'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import Refinements from 'scenes/CampaignCenter/Builder/components/Refinements'
import LocationRefinement from 'scenes/CampaignCenter/Builder/components/Refinements/LocationRefinement'
import StatBox from 'scenes/CampaignCenter/Builder/components/StatBox'
import Graph from './components/Graph'

type Props = {
  campaign: Campaign
  isLoading: boolean
  userCount?: number
  selectedLocations: Location[]
  metrics: VipSpendData
}

const order = ['vip_spend', 'avg_spend']

const View: React.FC<Props> = props => {
  const { campaign, isLoading, userCount, selectedLocations, metrics } = props
  const hasLocations =
    campaign.config_location_ids && campaign.config_location_ids.length > 0

  return (
    <Spinner
      isLoading={isLoading}
      size="4x"
      className="text-center padding-top-huge"
    >
      <div className="container">
        <Row>
          <Col md={6} className="padding-top-huge padding-right-huge">
            <div className="padding-right-huge margin-bottom-huge">
              <Translate
                value="thanx_campaigns.campaigns.engage_vip.steps.setup.audience.title"
                tag="p"
                className="grey-90 font-size-32 margin-bottom-micro bold line-height-40"
              />
              <Translate
                value="thanx_campaigns.campaigns.engage_vip.steps.setup.audience.subtitle"
                tag="em"
                className="grey-50 font-size-20 margin-bottom-extra-large block"
              />
            </div>
            <Switch condition={!!userCount}>
              <StatBox
                title={I18n.t(
                  'thanx_campaigns.campaigns.engage_vip.steps.setup.audience.stat.supertitle'
                )}
                tooltip={I18n.t(
                  'thanx_campaigns.campaigns.engage_vip.steps.setup.audience.stat.tooltip'
                )}
                message={
                  <Translate
                    value="thanx_campaigns.campaigns.engage_vip.steps.setup.audience.stat.message"
                    userCount={numeral(userCount).format('0,0')}
                    street={selectedLocations?.[0]?.street}
                    lift={metrics?.vip_lift}
                    count={selectedLocations.length}
                    dangerousHTML
                  />
                }
                className="category-targeted-bkg white margin-bottom-huge"
              />
            </Switch>

            <div className="margin-bottom-extra-large">
              <Refinements isOpen={hasLocations} isDisabled={hasLocations}>
                <div>
                  <div className="margin-bottom-huge">
                    <Input
                      name="config_vip_count"
                      value={campaign.config_vip_count}
                      label={I18n.t(
                        'thanx_campaigns.campaigns.engage_vip.steps.setup.audience.vip_count.title'
                      )}
                      placeholder={I18n.t(
                        'thanx_campaigns.campaigns.engage_vip.steps.setup.audience.vip_count.placeholder'
                      )}
                      trimValue={true}
                      validations={{
                        isNumeric: true,
                        greaterThan: 0,
                        isRequired: false,
                      }}
                      validationErrors={{
                        isNumeric: I18n.t('validations.is_numeric'),
                        greaterThan: I18n.t('validations.greater_than', {
                          value: 0,
                        }),
                        isRequired: I18n.t('validations.is_required'),
                      }}
                    />
                  </div>
                  <LocationRefinement campaign={campaign} />
                </div>
              </Refinements>
            </div>
          </Col>
          <Col md={6} className="padding-huge grey-10-bkg">
            <div className="text-center padding-top-huge">
              <Translate
                value="thanx_campaigns.campaigns.engage_vip.steps.setup.audience.graph.title"
                className="font-size-18 grey-50"
              />
            </div>
            <div className="padding-top-extra-large margin-bottom-huge">
              <Graph
                order={order}
                data={order.map(period => ({
                  y: metrics?.[period],
                  lift: metrics?.vip_lift,
                }))}
                selectedColor={palette.grey40}
                barColor={palette.wisteria}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Spinner>
  )
}

export default View
