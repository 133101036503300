import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as Link } from 'models/Link'
import React from 'react'
import Item from './Item'

const t = buildTranslate('cms.components.links')

type Props = {
  links: Link[]
  showAuthenticable?: boolean
}

const Links: React.FC<Props> = ({ links, showAuthenticable = false }) => {
  const [css] = useStyletron()

  return (
    <table
      className={`full-width campaign-table ${css({
        tableLayout: 'fixed',
      })}`}
    >
      <thead>
        <tr>
          <HeaderCell>{t('title_name')}</HeaderCell>
          <HeaderCell>{t('type')}</HeaderCell>
          <Switch condition={showAuthenticable}>
            <HeaderCell>{t('authenticable')}</HeaderCell>
          </Switch>
          <HeaderCell className="w-50">{t('url')}</HeaderCell>
        </tr>
      </thead>
      <tbody>
        {links.map(link => (
          <Item
            key={link.id}
            link={link}
            showAuthenticable={showAuthenticable}
          />
        ))}
      </tbody>
    </table>
  )
}

export default Links
