import { Text } from '@thanx/uikit/text'
import Counter from 'components/Counter'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import { ActivationRateCumulativeMetric } from 'models/ActivationRateMetric'
import React from 'react'
import { BenchmarkT, tooltipPercentage } from 'scenes/Reports/helpers'
import { getDateLabel } from 'utilities/date'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('activation_rate.tabs.activation_rate')

type PropsT = {
  isErrored: boolean
  isLoading: boolean
  currentRateData: ActivationRateCumulativeMetric['current']
  previousRateData: ActivationRateCumulativeMetric['previous']
  benchmark: BenchmarkT
  timezone: string
}

const Counters: React.FC<PropsT> = ({
  isErrored,
  isLoading,
  currentRateData,
  previousRateData,
  benchmark,
  timezone,
}) => {
  const changeInRate = ((): number => {
    if (!currentRateData || !previousRateData) return 0

    return currentRateData.rate - previousRateData.rate
  })()

  const changeRateColor = (() => {
    if (changeInRate > 0) return palette.nephritis
    if (changeInRate < 0) return palette.cayenne50

    return ''
  })()

  const displayChangeInRate = (() => {
    if (!previousRateData) return ''

    return `${changeInRate > 0 ? '+' : ''}${percentageFormatter(
      changeInRate,
      1,
      1
    )}`
  })()

  const dateLabel = currentRateData
    ? getDateLabel(currentRateData?.date_time, 'monthly', timezone)
    : null
  const firstPurchaseCount = numberFormatter(
    currentRateData?.first_purchasers_count
  )
  const thirdPurchaseCount = numberFormatter(
    currentRateData?.third_purchasers_count
  )
  const activationRate = percentageFormatter(currentRateData?.rate, 1, 1)

  return (
    <div>
      <div className="d-flex">
        <Counter
          className="mr-xxl"
          primaryValue={activationRate}
          isErrored={isErrored}
          isLoading={isLoading}
          secondaryValue={displayChangeInRate}
          secondaryValueColor={changeRateColor}
          sections={[
            {
              content: t('popovers.activation_rate', { dateLabel })[0],
            },
            {
              title: t('popovers.how_calculated'),
              content: t('popovers.activation_rate')[1],
            },
            {
              title: t('popovers.what_this_means'),
              content: t('popovers.activation_rate', {
                dateLabel,
                firstPurchaseCount,
                thirdPurchaseCount,
                activationRate,
              })[2],
            },
          ]}
          name={t('title')}
        />
        <Counter
          className="mr-xxl"
          primaryValue={thirdPurchaseCount}
          isErrored={isErrored}
          isLoading={isLoading}
          sections={[
            {
              content: t('popovers.third_purchase')[0],
            },
            {
              title: t('popovers.what_this_means'),
              content: t('popovers.third_purchase', {
                dateLabel,
                firstPurchaseCount,
                thirdPurchaseCount,
              })[1],
            },
          ]}
          name={t('counters.third_purchase')}
        />
        <Counter
          className="mr-xxl"
          primaryValue={firstPurchaseCount}
          isErrored={isErrored}
          isLoading={isLoading}
          sections={[
            {
              content: t('popovers.first_purchase')[0],
            },
            {
              title: t('popovers.what_this_means'),
              content: t('popovers.first_purchase', {
                dateLabel,
                firstPurchaseCount,
                thirdPurchaseCount,
              })[1],
            },
          ]}
          name={t('counters.first_purchase')}
        />
      </div>
      <Text.BodyText4 className="my-xs" color="wisteria" tag="div">
        {t('counters.industry_benchmark', {
          rate: tooltipPercentage(benchmark.rate),
        })}
      </Text.BodyText4>
    </div>
  )
}

export default Counters
