import { Fields } from 'models/Brand'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_BRANDS = 'brand/GET_BRANDS'
export const GET_BRANDS_SUCCESS = 'brand/GET_BRANDS_SUCCESS'
export const GET_BRANDS_FAIL = 'brand/GET_BRANDS_FAIL'

export function getBrands(): AxiosAction<
  typeof GET_BRANDS,
  { merchant_brands: Fields[] }
> {
  return {
    type: GET_BRANDS,
    payload: {
      client: 'default',
      request: {
        url: 'brands',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<ReturnType<typeof getBrands>>
