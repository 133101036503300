import { alert } from 'actions/flash'
import { getUploadUrl } from 'actions/s3'
import axios from 'axios'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import isUndefined from 'lodash/isUndefined'
import React, { Component } from 'react'
import UnlayerEditor from 'react-email-editor'
import { connect } from 'react-redux'
import { I18n } from 'react-redux-i18n'
// $FlowFixMe
import store from 'store'
import { deepOmitBy } from 'utilities/objectUtils'

type Props = {
  className: string
  getUploadUrl: any
  onLoad?: () => void
  projectId?: number
  displayMode: string
  style?: React.CSSProperties
  options: Object
  tools: Object
} & PassDownProps<string | Object | null>

export class UnlayerWithFormsy extends Component<Props> {
  static defaultProps = {
    className: '',
  }

  loadTimer: NodeJS.Timeout | undefined

  componentWillUnmount = () => {
    if (this.loadTimer) {
      clearTimeout(this.loadTimer)
    }
  }

  onLoadFailure = () => {
    store.dispatch(
      alert({
        key: 'danger',
        message: I18n.t('thanx_campaigns.errors.email_builder.load_error'),
      })
    )
  }

  onDesignLoaded = () => {
    if (this.loadTimer) {
      clearTimeout(this.loadTimer)
    }
    this.onDesignUpdated()
  }

  onDesignUpdated = () => {
    // @ts-ignore
    window.unlayer.saveDesign(design => {
      const cleanedDesign = deepOmitBy(design, isUndefined)
      this.props.setValue(cleanedDesign)
    })
  }

  imageCallback = (
    files: { attachments: Array<File> },
    done: ({ progress: number }) => void
  ) => {
    done({ progress: 1 })
    const file = files.attachments[0]
    this.props
      .getUploadUrl({
        filename: file.name,
        type: 'image',
        acl: 'public-read',
      })
      .then(action => {
        if (action.error) {
          throw action.error.response ? action.error.response.data.error : null
        }

        done({ progress: 25 })
        const url = action.payload.data.upload_url.url
        return this.uploadFile(url, file, done)
      })
      .then(response => {
        const url = response.config.url
        // @ts-ignore
        done({ progress: 100, url: url.split('?')[0] })
      })
      .catch(error => {
        store.dispatch(
          alert({
            key: 'danger',
            message: I18n.t(
              'thanx_campaigns.errors.email_builder.image_upload_error'
            ),
            error: error,
            displayDetails: false,
          })
        )
      })
  }

  uploadFile = (url: string, file: File, done: Function) => {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: event => {
        const percent = (event.loaded / event.total) * 100
        const progress = 25 + percent * 0.75
        done({ progress })
      },
    }
    return axios.put(url, file, options)
  }

  onLoad = () => {
    // @ts-ignore
    const unlayer = window.unlayer

    unlayer.removeEventListener('design:updated')
    unlayer.addEventListener('design:updated', this.onDesignUpdated)

    unlayer.removeEventListener('design:loaded')
    unlayer.addEventListener('design:loaded', this.onDesignLoaded)

    unlayer.unregisterCallback('image')
    unlayer.registerCallback('image', this.imageCallback)

    this.loadTimer = setTimeout(this.onLoadFailure, 10000)
    unlayer.loadDesign(this.props.value)

    if (this.props.onLoad) {
      this.props.onLoad()
    }
  }

  render() {
    return (
      <div className={`unlayer-wrapper absolute ${this.props.className}`}>
        <UnlayerEditor onLoad={this.onLoad} {...this.props} />
      </div>
    )
  }
}

const mapDispatchToProps = {
  getUploadUrl,
}

export default connect(null, mapDispatchToProps)(withFormsy(UnlayerWithFormsy))
