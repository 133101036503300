import { useStyletron } from '@thanx/uikit/theme'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import TabItem from 'components/Tabs/TabItem'
import TabNav from 'components/Tabs/TabNav'
import TabPane from 'components/Tabs/TabPane'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Tab from 'react-bootstrap-five/Tab'
import Helmet from 'react-helmet'
import { I18n } from 'react-redux-i18n'
import LookerFooter from 'scenes/LookerEmbed/components/LookerFooter'
import LookerIframe from 'scenes/LookerEmbed/LookerIframe'

const t = buildTranslate('looker')
enum Tabs {
  OVERVIEW = 'overview',
  AVERAGE_MONTHLY_VALUE = 'average_monthly_value',
}

const EngagementReport: React.FC = () => {
  const merchant = useCurrentMerchant()
  const [css] = useStyletron()
  const [activeTab, setActiveTab] = useState<
    'overview' | 'average_monthly_value'
  >('overview')

  if (!merchant) return null

  const title = t('urls.dashboards/130.title')
  const overviewHeight = 2170
  const averageMonthlyValueHeight = 2430

  return (
    <>
      <Helmet>
        <title>{I18n.t(`titles./reports/engagement#${activeTab}`)}</title>
      </Helmet>
      <ProtectedPageHeader
        title={title}
        resource="LocationReports"
        action="access"
      >
        <Tab.Container
          activeKey={activeTab}
          transition={false}
          onSelect={(tab: string | null) => {
            if (!tab) return
            setActiveTab(tab as Tabs)
          }}
        >
          <TabNav>
            <TabItem
              state={Tabs.OVERVIEW}
              title={t(`urls.dashboards/130.tabs.overview`)}
            />
            <TabItem
              state={Tabs.AVERAGE_MONTHLY_VALUE}
              title={t(`urls.dashboards/130.tabs.average_monthly_value`)}
            />
          </TabNav>
          <Tab.Content className="pb-l h-100 grey-05-bkg">
            <TabPane state={Tabs.OVERVIEW}>
              <Container
                className={`px-0 ${css({
                  minHeight: `${overviewHeight + 300}px`,
                })}`}
              >
                <LookerIframe
                  path={'dashboards/130'}
                  merchant={merchant}
                  height={`${overviewHeight}px`}
                />
                <LookerFooter className="my-xl" />
              </Container>
            </TabPane>
            <TabPane state={Tabs.AVERAGE_MONTHLY_VALUE}>
              <Container
                className={`px-0 ${css({
                  minHeight: `${averageMonthlyValueHeight + 300}px`,
                })}`}
              >
                <LookerIframe
                  path={'dashboards/133'}
                  merchant={merchant}
                  height={`${averageMonthlyValueHeight}px`}
                />
                <LookerFooter className="my-xl" />
              </Container>
            </TabPane>
          </Tab.Content>
        </Tab.Container>
      </ProtectedPageHeader>
    </>
  )
}

export default EngagementReport
