import Input from 'components/Form/Input'
import Spacer from 'components/Spacer'
import { buildTranslate } from 'locales'
import type { Fields as Redeem } from 'models/Redeem'
import React from 'react'
import { Button, ButtonGroup, Col, InputGroup, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.reward_creator'
)

type Props = {
  redeem: Redeem
  onRedeemTypeChange: (value: string) => void
  selectedRedeemType: string
}

// Validations pairs for input fields
const VALIDATIONS = {
  offerText: {
    validations: {
      minLength: 5,
      maxLength: 47,
      isRequired: true,
      mustNotBeginInWhitespace: true,
      mustEndInPunctuation: false,
      mustNotBeginWithCausativeVerb: true,
    },
    messages: {
      minLength: I18n.t('validations.min_length', { minLength: 5 }),
      maxLength: I18n.t('validations.max_length', { maxLength: 47 }),
      isRequired: I18n.t('validations.is_required'),
      mustNotBeginInWhitespace: I18n.t(
        'validations.must_not_begin_in_whitespace'
      ),
      mustEndInPunctuation: I18n.t('validations.must_not_end_in_punctuation'),
      mustNotBeginWithCausativeVerb: I18n.t(
        'validations.must_not_begin_with_causative_verb'
      ),
    },
  },
  positiveNumber: {
    validations: {
      isNumeric: true,
      greaterThan: 0,
      isRequired: true,
    },
    messages: {
      isNumeric: I18n.t('validations.is_numeric'),
      greaterThan: I18n.t('validations.greater_than', { value: 0 }),
      isRequired: I18n.t('validations.is_required'),
    },
  },
}

const Discount: React.FC<Props> = props => {
  const { redeem, onRedeemTypeChange, selectedRedeemType } = props
  const type = selectedRedeemType.split('/').pop()

  return (
    <div>
      <div className="body-text-2 margin-bottom-small">
        {t('redeem_type.discount')}
      </div>
      <Spacer paddingBottom="40px">
        <Row>
          <Col xs={6}>
            <ButtonGroup className={'full-width'}>
              <Button
                onClick={() => onRedeemTypeChange('manual/amount')}
                className={`${
                  selectedRedeemType.includes('amount') ? 'active' : ''
                } width-50 inline-block`}
                bsSize="lg"
              >
                {t('redeem_type.discounts.amount.label')}
              </Button>
              <Button
                onClick={() => onRedeemTypeChange('manual/percent')}
                className={`${
                  selectedRedeemType.includes('percent') ? 'active' : ''
                } width-50 inline-block`}
                bsSize="lg"
              >
                {t('redeem_type.discounts.percent.label')}
              </Button>
            </ButtonGroup>
          </Col>

          <Col xs={6}>
            <Input
              name="redeem_discount"
              value={redeem.redeem_discount}
              validations={VALIDATIONS.positiveNumber.validations}
              validationErrors={VALIDATIONS.positiveNumber.messages}
            >
              <InputGroup.Addon>
                {t(
                  `redeem_type.discounts.${selectedRedeemType
                    .split('/')
                    .pop()}.addon`
                )}
              </InputGroup.Addon>
            </Input>
          </Col>
        </Row>
      </Spacer>
      <Spacer paddingBottom="40px">
        <div className="body-text-2 margin-bottom-small">
          {t('redeem_description.title')}
        </div>
        <Input
          name="redeem_description"
          value={redeem.redeem_description}
          placeholder={t(`redeem_description.discount.${type}.placeholder`)}
          addOnBefore={t('redeem_description.discount.add_on')}
          trimValue={true}
          validations={VALIDATIONS.offerText.validations}
          validationErrors={VALIDATIONS.offerText.messages}
        />
      </Spacer>
    </div>
  )
}

export default Discount
