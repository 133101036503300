import { Fields as Campaign } from 'models/Campaign'
import { VariantOrCampaign } from 'models/CampaignVariant'
import EmailSection from './EmailSection'
import PushSection from './PushSection'
import SmsSection from './SmsSection'

type Props = {
  campaign: Campaign
  config: VariantOrCampaign
}

function MessageSections(props: Props) {
  return (
    <>
      <EmailSection
        className="mb-m"
        campaign={props.campaign}
        config={props.config}
      />
      <PushSection
        className="mb-m"
        campaign={props.campaign}
        config={props.config}
      />
      <SmsSection
        className="mb-m"
        campaign={props.campaign}
        config={props.config}
      />
    </>
  )
}

export default MessageSections
