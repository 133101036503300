import StyleProvider, { StyletronEngine } from '@thanx/uikit/StyleProvider'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { tooltipPercentage } from 'scenes/Reports/helpers'
import { generateUikitTheme } from 'theme'
import { currencyFormatter } from 'utilities/formatters'

const t = buildTranslate('points.effective_discount_rate')

type PropsT = {
  date: string
  effectiveDiscountRate: number
  costOfRewardsRedeemed: number
  netRevenue: number
}

const Tooltip: React.FC<PropsT> = ({
  date,
  effectiveDiscountRate,
  costOfRewardsRedeemed,
  netRevenue,
}) => {
  const styletronEngine = new StyletronEngine({ prefix: 'st-ctt-' })
  const uikitTheme = generateUikitTheme()

  return (
    <StyleProvider styletronEngine={styletronEngine} theme={uikitTheme}>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {date}
      </Text.BodyText4>
      <Text.Header3 className="mb-xs mt-0" tag="div">
        {tooltipPercentage(effectiveDiscountRate, 1, 1)}
      </Text.Header3>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {t('chart.tooltip.effective_discount_rate')}
      </Text.BodyText4>
      <Text.Header4 className="mt-0" tag="div">
        {currencyFormatter(costOfRewardsRedeemed, false)}
      </Text.Header4>
      <Text.BodyText4 className="mb-s" color="grey70" tag="div">
        {t('chart.tooltip.cost_of_rewards')}
      </Text.BodyText4>
      <Text.Header4 className="mt-0" tag="div">
        {currencyFormatter(netRevenue, false)}
      </Text.Header4>
      <Text.BodyText4 className="mb-xs" color="grey70" tag="div">
        {t('chart.tooltip.net_revenue')}
      </Text.BodyText4>
    </StyleProvider>
  )
}

export default Tooltip
