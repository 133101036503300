import moment, { Moment } from 'moment-timezone'
import React, { useState } from 'react'
import { DateRangeFilter, getDateFilter } from './utilities'

type DateRangeFilterContextT = {
  bounds: {
    startDate: Moment | null
    endDate: Moment | null
  }
  filter: DateRangeFilter
  setFilter: (filter: DateRangeFilter) => void
}

export const DateRangeFilterContext =
  React.createContext<DateRangeFilterContextT>({
    bounds: {
      startDate: null,
      endDate: null,
    },
    filter: getDateFilter('last_30', moment.tz.guess()),
    setFilter: () => {},
  })

type Props = {
  children: React.ReactNode
  bounds: {
    startDate: Moment | null
    endDate: Moment | null
  }
  initialFilter?: DateRangeFilter | null
  timeZone: string
}

export const DateRangeFilterProvider: React.FC<Props> = props => {
  const { children, timeZone, bounds, initialFilter } = props
  const start = bounds.startDate ? bounds.startDate.format('YYYY-MM-DD') : null
  const end = bounds.endDate ? bounds.endDate.format('YYYY-MM-DD') : null
  const [filter, setFilter] = useState(
    initialFilter ?? getDateFilter('last_30', timeZone, start, end)
  )

  return (
    <DateRangeFilterContext.Provider value={{ filter, setFilter, bounds }}>
      {children}
    </DateRangeFilterContext.Provider>
  )
}
