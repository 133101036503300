import { Switch } from '@thanx/uikit/switch'
import palette from 'constants/palette'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import sum from 'lodash/sum'
import React, { useEffect, useRef } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import Graph from './components/Graph'

type Props = {
  metrics: any
  hasDivider: boolean
}

const order = ['1x', '2x', '3x', '4x', '5x+']

export function getKeys(memoizedKeys, hasNoData, metrics) {
  if (memoizedKeys) return memoizedKeys

  if (hasNoData) {
    memoizedKeys = order
  } else {
    const keys: string[] = []
    order.forEach(key => {
      if (metrics[key]) {
        keys.push(key)
      }
    })
    memoizedKeys = keys
  }
  return memoizedKeys
}

export function getData(memoizedData, hasNoData, metrics, keys) {
  if (memoizedData) return memoizedData
  if (hasNoData) {
    memoizedData = [25, 15, 10, 5, 35]
  } else {
    const values = keys.map(key => metrics[key])
    const total = sum(values)
    const data: number[] = values.map(value =>
      Math.round((value / total) * 100)
    )
    // guarantee sum to 100%
    data[0] -= sum(data) - 100
    memoizedData = data
  }
  return memoizedData
}

export function getMaxKey(memoizedMaxKey, hasNoData, metrics) {
  if (memoizedMaxKey) return memoizedMaxKey
  if (hasNoData) {
    memoizedMaxKey = '5x+'
  } else {
    let maxKey = ''
    let max = 0
    forEach(metrics, (count, key) => {
      if (count > max) {
        maxKey = key
        max = count
      }
    })
    memoizedMaxKey = maxKey
  }
  return memoizedMaxKey
}

export function getMajorityKey(memoizedMajorityKey, hasNoData, data) {
  if (hasNoData) return null
  if (memoizedMajorityKey) return memoizedMajorityKey

  let percent = 0
  for (let index = order.length - 1; index >= 0; index--) {
    memoizedMajorityKey = order[index]
    percent += data[index]
    if (percent >= 50) break
  }

  return memoizedMajorityKey
}

const View: React.FC<Props> = props => {
  const { metrics, hasDivider } = props
  const hasNoData = isEmpty(metrics)

  let memoizedKeys = useRef<string[] | null>(null)
  let memoizedData = useRef<number[] | null>(null)
  let memoizedMaxKey = useRef<string | null>(null)
  let memoizedMajorityKey = useRef<string | null>(null)

  useEffect(() => {
    memoizedKeys.current = null
    memoizedData.current = null
    memoizedMaxKey.current = null
    memoizedMajorityKey.current = null
  }, [metrics])

  const keys = getKeys(memoizedKeys.current, hasNoData, metrics)
  const data = getData(memoizedData.current, hasNoData, metrics, keys)
  const maxKey = getMaxKey(memoizedMaxKey.current, hasNoData, metrics)
  const majorityKey = getMajorityKey(
    memoizedMajorityKey.current,
    hasNoData,
    data
  )

  function subtitle() {
    if (hasNoData) {
      return (
        <Translate value="thanx_campaigns.report.repeat_customers.subtitle.no_data" />
      )
    }

    const phrase = I18n.t(
      `thanx_campaigns.report.repeat_customers.times.${majorityKey || ''}`
    )
    return (
      <span>
        <Translate
          value="thanx_campaigns.report.repeat_customers.subtitle.default"
          timesPhrase={phrase}
          dangerousHTML
        />
        <OverlayTrigger placement="top" overlay={tooltip()}>
          <i className="fa fa-question-circle padding-left-small font-size-20 grey-70" />
        </OverlayTrigger>
      </span>
    )
  }

  function tooltip() {
    return (
      <Tooltip id="repeat-tooltip" className="text-left">
        {I18n.t('thanx_campaigns.report.repeat_customers.subtitle.tooltip')}
      </Tooltip>
    )
  }

  return (
    <div>
      <Switch condition={hasDivider}>
        <hr className="section-divider margin-none" />
      </Switch>
      <div className="container margin-top-huge margin-bottom-huge">
        <div className="repeat-customers-section">
          <p className="deprecated__small-caps-3 margin-bottom-huge text-center">
            <Translate value="thanx_campaigns.report.repeat_customers.title" />
          </p>
          <p className="font-size-32 text-center">{subtitle()}</p>
          <div className="width-66 center-block">
            <Graph
              order={keys}
              selectedKey={maxKey}
              data={data}
              selectedColor={hasNoData ? palette.grey20 : palette.spearmint60}
              columnColor={hasNoData ? palette.grey10 : palette.spearmint50}
              showColumnLabels={!hasNoData}
              categoryLabelColor={hasNoData ? palette.grey40 : null}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default View
