import builder from './builder'
import campaigns from './campaigns'
import components from './components'
import customAutomated from './customAutomated'
import landing from './landing'
import newCampaign from './newCampaign'
import opportunity from './opportunity'
import overview from './overview'
import performance from './performance'
import report from './report'
import requestSegment from './requestSegment'
import review from './review'

export default {
  title: 'Thanx Campaigns',

  creator: {
    creation_error: 'We had trouble setting up your campaign. Try again?',
    no_duplicates: 'Only one campaign of this type can be active at a time.',
  },

  errors: {
    validation_errors:
      'There were errors in the form. Please correct all highlighted errors.',
    get_metrics_error:
      'There was an error fetching your campaign data. Please try again.',
    email_builder: {
      image_upload_error:
        'Something went wrong uploading your image. Please try again.',
      load_error:
        'There was an error loading the email builder. Please ensure any ad blockers are disabled and your browser is configured to allow third-party cookies.',
    },
  },

  components,

  builder,
  campaigns,
  customAutomated,
  landing,
  newCampaign,
  opportunity,
  overview,
  performance,
  report,
  requestSegment,
  review,
}
