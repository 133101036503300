import {
  ResolvedAction,
  UPDATE_LANDING_HEADERS_SUCCESS,
} from 'actions/landingHeader'
import { RESET_DB } from 'actions/orm'
import { Model, ModelType } from 'redux-orm'
import { Image } from './App'

export interface Fields {
  id: number
  landing_page_id: number
  image_urls: Image
  position: number
}

// @ts-ignore
export default class LandingHeader extends Model<typeof LandingHeader, Fields> {
  static modelName: string = 'LandingHeader'

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    LandingHeader: ModelType<LandingHeader>
  ) {
    switch (action.type) {
      case UPDATE_LANDING_HEADERS_SUCCESS:
        // Reset LandingHeaders and build them again as some of the headers
        // might be destroyed in the update request.
        LandingHeader.all().delete()
        action.payload.data.headers.forEach(header => {
          LandingHeader.upsert(header)
        })
        break
      case RESET_DB:
        LandingHeader.all().delete()
        break
      default:
        break
    }
  }
}
