import { RESET_DB } from 'actions/orm'
import { GET_USERS_SUCCESS, ResolvedAction } from 'actions/users'
import { Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  uid: string
  email: string
  first_name: string
  last_name: string
}

// @ts-ignore
export default class User extends Model<typeof User, Fields> {
  static modelName: string = 'User'

  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    User: ModelType<User>
  ): void {
    switch (action.type) {
      case GET_USERS_SUCCESS:
        action.payload.data.users.forEach(user => User.upsert(user))
        break
      case RESET_DB:
        User.all().delete()
        break
      default:
        break
    }
  }
}
