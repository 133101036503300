import Select from 'components/Form/Select'
import type { Props as SelectProps } from 'components/Form/Select'
import { withFormsy } from 'formsy-react'
import { PassDownProps } from 'formsy-react/dist/withFormsy'
import React, { useEffect } from 'react'
import { FormGroup } from 'react-bootstrap'
// $FlowFixMe
import { textUtils } from 'utilities/textUtils'
import type { SelectOptionT } from 'utilities/types'
import CustomOption from './CustomOption'

type Props = {
  className?: string
  defaultValue?: any
  options: SelectOptionT[]
  onChange?: (event: any) => void
} & PassDownProps<string | null> &
  SelectProps

const MultiSelectWithFormsy = (props: Props) => {
  const {
    onChange: propsOnChange,
    defaultValue,
    components,
    value: _value, // Don't pass this to the select
    ...selectProps
  } = props

  useEffect(() => {
    props.setValue(props.defaultValue)
    // TODO TA-25291: Make this controlled when we revisit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultOptions = defaultValue
    ? defaultValue.map(value =>
        props.options.find(option => option.value === value)
      )
    : defaultValue

  // NOTE: react-select's api choice to have values be OptionType | OptionType[]
  // makes typing option cumbersome. We know option will always be SelectOptionT[],
  // but we treat it as any to avoid a bunch of unnecessary type refinement checks
  const onChange = (options: Array<any>) => {
    const value = options.map(option => option.value)
    props.setValue(value)

    if (propsOnChange) {
      propsOnChange(value)
    }
  }

  let className = `form-group ${props.className || ''}`
  if (props.showError && !props.isPristine) {
    className = className + ' has-error'
  }
  const errorMessages = props.isPristine ? '' : props.errorMessages

  // Cheating on props here so Select can have exact props.
  // We know that the additional FormsyProps in props are safe
  // to pass.
  return (
    <FormGroup className={className} bsSize="lg">
      <Select
        defaultValue={defaultOptions}
        isMulti={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        isClearable={false}
        controlShouldRenderValue={false}
        components={{
          Option: CustomOption,
          ...components,
        }}
        onChange={onChange}
        {...selectProps}
      />

      <span className="text-danger error-message block">
        {textUtils.initialCapital(textUtils.toSentence(errorMessages))}
      </span>
    </FormGroup>
  )
}

export default withFormsy(MultiSelectWithFormsy)
