import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectChannelTimeSeriesMetric } from 'selectors/captureRateMetric'
import {
  sortChannelData,
  SortFilter,
  SortFilterAttribute,
} from '../../utilities'
import HeaderCell from './HeaderCell'
import Row from './Row'

type PropT = {
  merchant: Merchant
}

const t = buildTranslate('capture_rate.revenue_by_channel.table')

const RevenueByChannel: React.FC<PropT> = ({ merchant }) => {
  const { currentLocation } = useContext(LocationContext)

  const channelTimeSeriesMetrics = useSelector(state =>
    selectChannelTimeSeriesMetric(state, merchant.id, currentLocation?.id)
  )
  const [sortFilter, setSortFilter] = useState<SortFilter>({
    attribute: 'date_time',
    direction: 'desc',
  })

  if (!channelTimeSeriesMetrics?.values) {
    return null
  }
  const { isErrored, isLoading } = channelTimeSeriesMetrics
  const channelData = sortFilter
    ? sortChannelData(channelTimeSeriesMetrics.values, sortFilter)
    : channelTimeSeriesMetrics.values

  const headers: Array<SortFilterAttribute> = [
    'date_time',
    'member_purchases_instore_percent',
    'non_member_purchases_instore_percent',
    'member_purchases_digital_percent',
    'non_member_purchases_digital_percent',
    'third_party_percent',
  ]

  return (
    <table className="w-100">
      <thead>
        <tr>
          {headers.map(attribute => (
            <HeaderCell
              key={attribute}
              onSort={setSortFilter}
              attribute={attribute}
              label={t(attribute)}
              sortFilter={sortFilter}
            />
          ))}
        </tr>
      </thead>
      <tbody>
        {channelData.map((value, index) => (
          <Row
            key={`channels-report-${index}`}
            data={value}
            dateTime={value.date_time}
            isErrored={!!isErrored}
            isLoading={!!isLoading}
          />
        ))}
      </tbody>
    </table>
  )
}

export default RevenueByChannel
