import { Fields as PointsProduct } from 'models/PointsProduct'

import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const CREATE = 'pointsProduct/CREATE'
export const CREATE_SUCCESS = 'pointsProduct/CREATE_SUCCESS'
export const CREATE_FAIL = 'pointsProduct/CREATE_FAIL'
export const GET = 'pointsProduct/GET'
export const GET_SUCCESS = 'pointsProduct/GET_SUCCESS'
export const GET_FAIL = 'pointsProduct/GET_FAIL'
export const GET_ALL = 'pointsProduct/GET_ALL'
export const GET_ALL_SUCCESS = 'pointsProduct/GET_ALL_SUCCESS'
export const GET_ALL_FAIL = 'pointsProduct/GET_ALL_FAIL'
export const UPDATE = 'pointsProduct/UPDATE'
export const UPDATE_SUCCESS = 'pointsProduct/UPDATE_SUCCESS'
export const UPDATE_FAIL = 'pointsProduct/UPDATE_FAIL'
export const DELETE = 'pointsProduct/DELETE'
export const DELETE_SUCCESS = 'pointsProduct/DELETE_SUCCESS'
export const DELETE_FAIL = 'pointsProduct/DELETE_FAIL'
export const PREVIEW_FINE_PRINT = 'pointsProduct/PREVIEW_FINE_PRINT'
export const PREVIEW_FINE_PRINT_SUCCESS =
  'pointsProduct/PREVIEW_FINE_PRINT_SUCCESS'
export const PREVIEW_FINE_PRINT_FAIL = 'pointsProduct/PREVIEW_FINE_PRINT_FAIL'

export function getPointsProducts(): AxiosAction<
  typeof GET_ALL,
  { points_products: PointsProduct[] }
> {
  return {
    type: GET_ALL,
    payload: {
      client: 'default',
      request: {
        url: 'points_products',
        method: 'get',
      },
    },
  }
}

export function getPointsProduct(
  id: string | number
): AxiosAction<typeof GET, { points_product: PointsProduct }> {
  return {
    type: GET,
    payload: {
      client: 'default',
      request: {
        url: `points_products/${id}`,
        method: 'get',
      },
    },
  }
}

export function createPointsProduct(
  id: number
): AxiosAction<typeof CREATE, { points_product: PointsProduct }> {
  return {
    type: CREATE,
    payload: {
      client: 'default',
      request: {
        url: 'points_products',
        method: 'post',
        data: {
          points_product: {
            points_experience_id: id,
          },
        },
      },
    },
  }
}

export type UpdateData = {
  redeem_template_id: number
  start_earn_at: string
  end_earn_at: string
  redemption_venue: string
  cost: number
  state: string
  redeem_retire_after_days: number
  redeem_experience_expires_in_days: number
  redeem_fine_print: string
  redeem_maximum: number
  redeem_type: string
}

export function updatePointsProduct(
  id: number,
  data: Partial<UpdateData>
): AxiosAction<typeof UPDATE, { points_product: PointsProduct }> {
  return {
    type: UPDATE,
    payload: {
      client: 'default',
      request: {
        url: `points_products/${id}`,
        method: 'patch',
        data: {
          points_product: data,
        },
      },
    },
  }
}

type DeleteAction = AxiosAction<'pointsProduct/DELETE'> & {
  pointsProductId: number
}

type DeleteResolvedAction =
  | FailureAction<'pointsProduct/DELETE_FAIL'>
  | SuccessAction<'pointsProduct/DELETE_SUCCESS', DeleteAction>

export function deletePointsProduct(id: number): DeleteAction {
  return {
    type: DELETE,
    payload: {
      client: 'default',
      request: {
        url: `points_products/${id}`,
        method: 'delete',
      },
    },
    pointsProductId: id,
  }
}

export function previewFinePrint(
  id: number,
  pointsProductData: Partial<PointsProduct>
): AxiosAction<typeof PREVIEW_FINE_PRINT, { points_product: PointsProduct }> {
  return {
    type: PREVIEW_FINE_PRINT,
    payload: {
      client: 'default',
      request: {
        url: `points_products/${id}/preview`,
        method: 'post',
        data: {
          points_product: pointsProductData,
        },
      },
    },
  }
}

export type ResolvedAction =
  | ResolvedAxiosAction<ReturnType<typeof getPointsProducts>>
  | ResolvedAxiosAction<ReturnType<typeof getPointsProduct>>
  | ResolvedAxiosAction<ReturnType<typeof updatePointsProduct>>
  | ResolvedAxiosAction<ReturnType<typeof createPointsProduct>>
  | ResolvedAxiosAction<ReturnType<typeof previewFinePrint>>
  | DeleteResolvedAction
