import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import { Fields as UserEvent } from 'models/UserEvent'
import moment from 'moment'
import React from 'react'
import { Button } from 'react-bootstrap'
import BodyCell from 'scenes/Reports/components/Table/BodyCell'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { merchantTheme } from 'theme'
import { getEventDescription } from './helpers'

const t = buildTranslate('users.view.activity.overview.table')

type Props = {
  event: UserEvent
  isLoading: boolean
  onOrderDetailsClick: (event: UserEvent) => void
}

function formatDate(date: string) {
  return {
    day: moment(date).format('MMM D, YYYY'),
    time: moment(date).format('h:mm a'),
  }
}

const Row: React.FC<Props> = ({ event, isLoading, onOrderDetailsClick }) => {
  const { occurred_at, title, type, location_address } = event

  function handleOrderDetailsClick(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault()
    onOrderDetailsClick(event)
  }

  const date = formatDate(occurred_at)
  const { colors } = merchantTheme

  const description = getEventDescription(event)

  return (
    <tr>
      <BodyCellContainer isErrored={false} isLoading={isLoading}>
        <Text.BodyText4 color={colors.grey70} tag="div">
          {date.day}
        </Text.BodyText4>
        <Text.BodyText5 color={colors.grey70} tag="div">
          {date.time}
        </Text.BodyText5>
      </BodyCellContainer>
      <BodyCellContainer isErrored={false} isLoading={isLoading}>
        <Text.BodyText4 color={colors.grey70} tag="div">
          {title}
        </Text.BodyText4>
        <Switch condition={!!description}>
          <Text.BodyText5 color={colors.grey70} tag="div">
            {description}
          </Text.BodyText5>
        </Switch>
      </BodyCellContainer>
      <BodyCell
        isErrored={false}
        isLoading={isLoading}
        values={[{ value: location_address }]}
      />
      <BodyCellContainer
        className={'text-right'}
        isErrored={false}
        isLoading={isLoading}
      >
        {type === 'transacted' && (
          <Button
            onClick={handleOrderDetailsClick}
            className="d-flex align-items-center"
            bsStyle="link"
          >
            {t('order_details')}
          </Button>
        )}
      </BodyCellContainer>
    </tr>
  )
}

export default Row
