export default {
  page_title: 'Edit profile | Thanx',
  edit: 'Edit profile',
  update: 'Update profile',
  personal: 'Personal information',
  first: 'First name',
  last: 'Last name',
  title: 'Title',
  phone: 'Phone number',
  email: 'Email',
  feedback: 'Email me when customers submit new feedback for %{merchant}.',
  timezone: 'Timezone',
  password: {
    title: 'Password',
    page_title: 'Change password | Thanx',
    change_password: 'Change password',
    old_password: 'Old password',
    new_password: 'New password',
    new_confirm: 'New password confirmation',
    update_success: 'Your profile has been successfully updated',
    update_failed: 'An error occurred while updating your profile',
    cancel: 'Cancel',
    should_equal: 'New password confirmation should be equal to New password',
  },
  update_success: 'Your profile has been successfully updated',
  update_failed: 'An error occurred while updating your profile',
}
