export const GET_TEMPLATE = 'campaignType/template/GET_TEMPLATE'
export const GET_TEMPLATE_SUCCESS = 'campaignType/template/GET_TEMPLATE_SUCCESS'
export const GET_TEMPLATE_FAIL = 'campaignType/template/GET_TEMPLATE_FAIL'

export const GET_DESIGN_VARIABLES = 'campaignType/template/GET_DESIGN_VARIABLES'
export const GET_DESIGN_VARIABLES_SUCCESS =
  'campaignType/template/GET_DESIGN_VARIABLES_SUCCESS'
export const GET_DESIGN_VARIABLES_FAIL =
  'campaignType/template/GET_DESIGN_VARIABLES_FAIL'

export function getTemplate(campaignType: string, type: string) {
  return {
    type: GET_TEMPLATE,
    payload: {
      client: 'default',
      request: {
        url: `campaign_types/${campaignType}/templates/${type}`,
        method: 'get',
      },
    },
  }
}

export function getDesignVariables(campaignType: string) {
  return {
    type: GET_DESIGN_VARIABLES,
    payload: {
      client: 'default',
      request: {
        url: `campaign_types/${campaignType}/template_variables`,
        method: 'get',
      },
    },
  }
}
