import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { accountsApi } from 'api/accounts'
import Input from 'components/Form/Input'
import Form from 'formsy-react'
import useDispatch from 'hooks/useDispatch'
import { useDisplayMessage } from 'hooks/useDisplayMessage'
import { buildTranslate } from 'locales'
import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { push } from 'react-router-redux'
import InvitationExpired from './InvitationExpired'
import InvitationLayout from './InvitationLayout'

type Props = {} & RouteComponentProps<{ token: string }>

const t = buildTranslate('merchant_users.invitation.complete')

const CompleteInvitation = ({ match }: Props) => {
  const invitationCode = match.params.token
  const [isValid, setIsValid] = useState(false)
  const dispatch = useDispatch()

  const { isLoading, isSuccess: isValidationSuccess } =
    accountsApi.useValidateInvitationCodeQuery(invitationCode, {
      skip: !invitationCode,
    })

  const [completeInvitation, { isSuccess, isError, reset }] =
    accountsApi.useCompleteInvitationMutation()

  const displayMessage = useDisplayMessage(reset)

  if (isSuccess) {
    reset()
    dispatch(push('/invitation/success'))

    return null
  }

  if (isError) {
    displayMessage(t('generic_error'), 'danger')
  }

  if (!isLoading && !isValidationSuccess) {
    return <InvitationExpired />
  }

  return (
    <InvitationLayout isLoading={isLoading}>
      <Form
        onValid={() => setIsValid(true)}
        onInvalid={() => setIsValid(false)}
        onValidSubmit={form => {
          completeInvitation({
            ...form,
            invitation_code: invitationCode,
          })
        }}
        className="text-left"
      >
        <div className="mb-l text-center">
          <Text.Header3>{t('title')}</Text.Header3>
        </div>
        <Input
          className="mb-m"
          name="first_name"
          label={t('first_name')}
          validations="isRequired"
          validationErrors={{
            isRequired: t('validations.is_required', {
              field: 'First name',
            }),
          }}
          spellCheck={false}
          inputProps={{
            className: 'grey-70-text grey-70-hover grey-70-focus',
          }}
        />
        <Input
          className="mb-m"
          name="last_name"
          label={t('last_name')}
          validations="isRequired"
          validationErrors={{
            isRequired: t('validations.is_required', {
              field: 'Last name',
            }),
          }}
          spellCheck={false}
          inputProps={{
            className: 'grey-70-text grey-70-hover grey-70-focus',
          }}
        />
        <Input
          className="mb-m"
          name="password"
          label={t('password')}
          validations="isRequired,minLength:6"
          validationErrors={{
            isRequired: t('validations.is_required', {
              field: 'Password',
            }),
            minLength: t('validations.min_length', { length: 6 }),
          }}
          type="password"
          spellCheck={false}
          inputProps={{
            className: 'grey-70-text grey-70-hover grey-70-focus',
          }}
        />
        <Input
          className="mb-xs"
          name="confirm_password"
          label={t('confirm_password')}
          validations="equalsField:password"
          validationErrors={{
            equalsField: t('validations.equals_field', {
              field: 'password',
            }),
          }}
          type="password"
          spellCheck={false}
          inputProps={{
            className: 'grey-70-text grey-70-hover grey-70-focus',
          }}
        />
        <Text.BodyText4 className="text-right" tag="p"></Text.BodyText4>
        <Button
          type="submit"
          kind={Kind.PRIMARY}
          className="btn-block mt-m"
          disabled={!isValid}
        >
          {t('save')}
        </Button>
      </Form>
    </InvitationLayout>
  )
}
export default CompleteInvitation
