import { useMediaQuery } from 'react-responsive'

const breakpoints = {
  mobile: 979,
  medium: 1199,
}

export function useIsMobile(): boolean {
  return useMediaQuery({ maxWidth: breakpoints.mobile })
}

export function useIsMedium(): boolean {
  return useMediaQuery({ maxWidth: breakpoints.medium })
}

export function useIsDesktop(): boolean {
  return useMediaQuery({ minWidth: breakpoints.medium + 1 })
}
