import { Fields } from 'models/PointsExperience'
import React from 'react'
import Migrating from './Migrating'

type Props = {
  pointsExperience: Fields | null
}

const ReportingNotActive: React.FC<Props> = () => {
  // we can extend this component to add the Inactive banner when implemented
  return <Migrating />
}

export default ReportingNotActive
