import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import React from 'react'

type PropsT = {
  variantLetter: string
  color?: string
}

const GenericVariant: React.FC<PropsT> = props => {
  const { variantLetter, color = '#36424E' } = props
  const [css] = useStyletron()

  return (
    <div
      className={`relative ${css({
        color,
      })}`}
    >
      <svg
        width="62"
        height="41"
        viewBox="0 0 62 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.71111 2H57.2889C58.7862 2 60 3.21381 60 4.71111V36.2889C60 37.7862 58.7862 39 57.2889 39H4.71111C3.21381 39 2 37.7862 2 36.2889V4.71111C2 3.21381 3.21381 2 4.71111 2ZM0 4.71111C0 2.10924 2.10924 0 4.71111 0H57.2889C59.8908 0 62 2.10924 62 4.71111V36.2889C62 38.8908 59.8908 41 57.2889 41H4.71111C2.10924 41 0 38.8908 0 36.2889V4.71111ZM25.3125 23.5H36.6875C37.3984 23.5 38 24.0729 38 24.75V32.25C38 32.9531 37.3984 33.5 36.6875 33.5H25.3125C24.5742 33.5 24 32.9531 24 32.25V24.75C24 24.0729 24.5742 23.5 25.3125 23.5ZM36.6875 25.8177V24.75H25.3125V25.8177C25.8019 26.2202 26.5446 26.7778 27.9234 27.813C28.2395 28.0503 28.589 28.3128 28.9766 28.6042C29.0621 28.6665 29.1625 28.7456 29.2735 28.833C29.7653 29.2204 30.4646 29.7712 31 29.75C31.5103 29.7711 32.2006 29.2265 32.6912 28.8394C32.8052 28.7495 32.9084 28.668 32.9961 28.6042C33.5899 28.1577 34.0944 27.7793 34.5268 27.4549C35.6077 26.6441 36.2383 26.1711 36.6875 25.8177ZM25.3125 27.4323V32.25H36.6875V27.4323C36.0586 27.901 35.1562 28.5781 33.8164 29.5938C33.7069 29.6708 33.585 29.7645 33.4522 29.8665C32.8224 30.3503 31.9485 31.0215 31 31C29.9929 31.0223 29.066 30.2994 28.4427 29.8134C28.3384 29.732 28.2425 29.6572 28.1562 29.5938C27.7754 29.3051 27.43 29.0438 27.1167 28.8068C26.3278 28.2101 25.7431 27.7678 25.3125 27.4323Z"
          fill={color}
          style={{
            transition: 'stroke 300ms ease-out, fill 300ms ease-out',
          }}
        />
      </svg>
      <Text.BodyText3
        className={`absolute w-100 left-0 text-center ${css({
          color,
          transition: 'color 300ms ease-out',
          top: '2px',
        })}`}
        color="inherit"
      >
        {variantLetter}
      </Text.BodyText3>
    </div>
  )
}

export default GenericVariant
