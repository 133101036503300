import { getCampaigns } from 'actions/campaigns'
import { getTiers } from 'actions/tiers'
import Spinner from 'components/Spinner'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import LoyaltyContent from './LoyaltyContent'

const Loyalty: React.FC = () => {
  const [isLoadingLoyalty, setIsLoadingLoyalty] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      await dispatch(getCampaigns({ type: 'loyalty' }))
      const promises = [
        dispatch(getCampaigns({ type: 'intro' })),
        dispatch(getTiers({})),
      ]

      await Promise.all(promises)
      setIsLoadingLoyalty(false)
    }

    fetchData()
  }, [dispatch])

  return (
    <Spinner
      isLoading={isLoadingLoyalty}
      size="4x"
      className="text-center padding-top-huge"
    >
      <LoyaltyContent />
    </Spinner>
  )
}

export default Loyalty
