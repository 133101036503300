import palette from 'constants/palette'
import upperCase from 'lodash/upperCase'

export const getColorByIndex = index =>
  [palette.spearmint50, palette.wisteria, palette.belizeHole, palette.grey90][
    index
  ]

export const getLetter = (type, index = 0) => {
  if (type === 'control') return ''
  return upperCase(type || getVariantNameFromIndex(index))
}

export const getMeta = (type, name, index) => ({
  index,
  name: getVariantName(type, name, index),
  color: getColorByIndex(index),
  letter: getLetter(type, index),
  isControl: type === 'control',
})

export const getVariantName = (type, name, index = 0) => {
  // TODO: Can't get translations to work properly here
  if (type === 'control') return 'Control group'
  const letter = getLetter(type, index)
  return name ? `${letter}: ${name}` : letter
}

export const getVariantNameFromIndex = index =>
  [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ][index]

export const sortVariants = (a, b) => {
  if (a.internal_type === 'control') return 1
  if (b.internal_type === 'control') return -1

  const nameA = a.internal_type?.toUpperCase()
  const nameB = b.internal_type?.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return a.id - b.id
}
