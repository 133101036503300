import { useStyletron } from '@thanx/uikit/theme'
import React, { ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

type PropsT = {
  children: ReactNode
  className?: string
}

const NavButton: React.FC<PropsT> = ({ children, className = '' }) => {
  const [css] = useStyletron()
  const isMediumDisplay = useMediaQuery({ maxWidth: 1210 })

  return (
    <div className={`d-flex text-left align-items-center ${className}`}>
      {children}
      {!isMediumDisplay && (
        <i
          className={`fa fa-chevron-down fa-lg ml-xs ${css({
            fontSize: '12px',
          })}`}
        />
      )}
    </div>
  )
}

export default NavButton
