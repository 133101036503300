import ArrowRight from 'assets/images/ic-arrow-right.svg'
import palette from 'constants/palette'
import { buildTranslate } from 'locales'
import React from 'react'
import { renderToString } from 'react-dom/server'
import {
  calculateBenchmarkLevel,
  metrics,
  stages,
  stagesMetricsMapping,
} from 'scenes/Reports/ActivationRateReport/ActivationFunnel/helpers'
import { percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('lifecycle_report.overview.chart')

export const generateSelectOptions = stages => {
  return stages.map(stage => {
    return {
      label: t(`labels.x_axis.${stage}`),
      value: stage,
    }
  })
}

export const columnColorMap = [
  palette.spearmint10,
  palette.spearmint20,
  palette.spearmint30,
  palette.spearmint50,
  palette.spearmint50,
].reduce((obj, color, i) => {
  obj[stages[i]] = color
  return obj
}, {})

export const defaultStages = stages.slice(1)

export const toolTipWidth = 405

export const transformData = (data, selectedStages, merchantType) => {
  if (!data) {
    // If no data has been received yet, we still want to display the selected
    // stages on the X-axis. We need to have empty data here for each series
    // otherwise it won't re-render correctly
    return generatePlaceholderData(selectedStages)
  }

  // We need to calculate the conversions based on the previous stage in the
  // funnel, not the previously displayed stage in the chart, so here we must
  // calculate this for all stages, then filter down to the selected stages
  const transformedData = stages
    .map((stage, i) => {
      const metric = stagesMetricsMapping[stage]
      const count = data[metric]
      const shouldExcludeConversion = selectedStages.indexOf(stage) === 0
      // However, we don't want to display the "unconverted count" / grey bar
      // for the first selected stage, so here we check to see if we're
      // transforming the first selected stage
      const previousStageCount = shouldExcludeConversion
        ? 0
        : data[metrics[i - 1]]
      // We also don't want to display the benchmark for the first selected
      // stage
      const benchmarkLevel = shouldExcludeConversion
        ? null
        : calculateBenchmarkLevel({
            merchantType,
            metric: metric,
            previousStageCount,
          })

      return {
        stage: stage,
        isFirstSelectedStage: selectedStages.indexOf(stage) === 0,
        count: count,
        didnt_count: previousStageCount > 0 ? previousStageCount - count : 0,
        benchmark_level: benchmarkLevel,
      }
    })
    // Exclude any stages which are not present in the selected stages
    .filter(stage => selectedStages.indexOf(stage.stage) >= 0)

  const convertedData = transformedData.map(d => {
    return {
      category: d.stage,
      value: d.count,
      isFirstSelectedStage: d.isFirstSelectedStage,
    }
  })
  const unconvertedData = transformedData.map(d => {
    return {
      category: d.stage,
      value: d.didnt_count,
      isFirstSelectedStage: d.isFirstSelectedStage,
    }
  })
  const benchmarkData = transformedData.map(d => {
    return {
      category: d.stage,
      value: d.benchmark_level,
      isFirstSelectedStage: d.isFirstSelectedStage,
    }
  })

  return { convertedData, unconvertedData, benchmarkData }
}

const generatePlaceholderData = selectedStages => {
  const dummyData = selectedStages.map(stage => {
    return {
      category: stage,
      value: null,
      isFirstSelectedStage: selectedStages.indexOf(stage) === 0,
    }
  })

  return {
    convertedData: dummyData,
    unconvertedData: dummyData,
    benchmarkData: dummyData,
  }
}

export const generateUnconvertedDataSeries = data => {
  return {
    dataLabels: [{ enabled: false }],
    data: data.map(p => p.value),
    color: palette.grey30,
    showInLegend: false,
  }
}

export const generateConvertedDataSeries = (data, css) => {
  return {
    dataLabels: [
      {
        formatter: function (this: Highcharts.PointLabelObject) {
          const point = this.point as Highcharts.Point & {
            isFirstSelectedStage: boolean
          }

          if (point.isFirstSelectedStage) {
            // We don't want to display a conversion rate label for the first
            // selected stage
            return null
          }

          return renderToString(
            <div
              className={css({
                height: '20px',
                width: '0px',
              })}
            >
              <div
                className={`d-flex justify-content-center align-items-center ${css(
                  {
                    position: 'relative',
                    left: '-83px',
                    height: '32px',
                    width: '75px',
                    top: '-16px',
                    backgroundColor: palette.grey10,
                    borderRadius: '10000px',
                  }
                )}`}
              >
                {percentageFormatter(this.percentage / 100, 1)}
                <img src={ArrowRight} alt="" className="ml-xxs" />
              </div>
            </div>
          )
        },
        verticalAlign: 'top',
        align: 'left',
        padding: 0,
        useHTML: true,
        color: palette.grey90,
        style: {
          fontSize: '14px',
          fontWeight: 'normal',
        },
      },
    ],
    data: data.map(p => {
      return {
        y: p.value,
        isFirstSelectedStage: p.isFirstSelectedStage,
        stage: p.category,
        color: columnColorMap[p.category],
      }
    }),
    showInLegend: false,
  }
}

export const generateBenchmarkDataSeries = data => {
  return {
    name: t('series.benchmark.name'),
    type: 'scatter',
    dataLabels: [{ enabled: false }],
    data: data.map(p => p.value),
    enableMouseTracking: false,
  }
}
