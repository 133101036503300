// @flow
import identity from 'lodash/identity'
import isArray from 'lodash/isArray'
import isEqual from 'lodash/isEqual'
import isObjectLike from 'lodash/isObjectLike'
import mapValues from 'lodash/mapValues'
import omitBy from 'lodash/omitBy'

export const getObjectDiff = (obj1: Object, obj2: Object) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, (Object.keys(obj2): Array<string>))

  return diff
}

// Similar to lodash omitBy, but deep traverses.
export const deepOmitBy = <ValueT>(
  value: ValueT,
  predicate: any => boolean = identity
): ValueT => {
  if (isArray(value)) {
    const castValue: Array<any> = (value: any)
    const result: any = castValue.map(object => deepOmitBy(object, predicate))
    return (result: ValueT)
  }

  if (isObjectLike(value)) {
    const ommitted = omitBy(value, predicate)
    const deepOmmitted = mapValues(ommitted, v => deepOmitBy(v, predicate))
    return deepOmmitted
  }

  return value
}
