import { Select } from '@thanx/uikit/select'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import Counter from 'components/Counter'
import { MetricsStatus } from 'components/metrics/withMerchantMetrics'
import SearchablePaginatedContent from 'components/SearchablePaginatedContent'
import SideNav from 'components/SideNav'
import { useLocale } from 'hooks/useLocale'
import { buildTranslate } from 'locales'
import { Metrics } from 'models/MerchantMetric'
import { Fields as TargetSegment } from 'models/TargetSegment'
import { useRef } from 'react'
import { Col, Row } from 'react-bootstrap-five'
import { useUsersTable } from 'scenes/Users/Landing/hooks'
import { numberFormatter } from 'utilities/formatters'
import { sortCategoryNames } from 'utilities/segmentCategories'
import { useSegmentsTable } from './hooks'
import Table from './Table'

const t = buildTranslate('segments.landing')

const types = {
  all: t('type_filter.all'),
  merchant: t('type_filter.merchant'),
  global: t('type_filter.global'),
}

const typeFilterOptions = Object.entries(types).map(([value, label]) => ({
  value,
  label,
}))

type Props = {
  onExistingClick: (segment: TargetSegment) => void
  merchantMetrics?: Metrics
  merchantMetricStatuses?: MetricsStatus
}

const Content: React.FC<Props> = ({
  onExistingClick,
  merchantMetrics,
  merchantMetricStatuses,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const {
    numPages,
    currentPage,
    changePage,
    isFetchingSegments,
    segments,
    setSearchQuery,
    type,
    setType,
    category,
    setCategory,
    categories,
    searchIsActive,
  } = useSegmentsTable(contentRef)

  const { isFetchingUsers, totalMembershipCount } = useUsersTable()

  const locale = useLocale()

  const numLoyaltyMembers = merchantMetrics?.user_count?.data?.member_count || 0
  const isLoadingMetrics = merchantMetricStatuses?.user_count?.isLoading

  return (
    <div className="mb-l">
      <div className="d-flex mb-xl">
        <Counter
          primaryValue={numberFormatter(totalMembershipCount)}
          isLoading={isFetchingUsers}
          name={t('total_users')}
          className="mr-xxl"
          sections={[
            {
              content: t('total_users_popover'),
            },
          ]}
        />
        <Counter
          primaryValue={numberFormatter(numLoyaltyMembers)}
          isLoading={isLoadingMetrics}
          name={t('total_loyalty_members')}
          className="mr-xxl"
          sections={[
            {
              content: t('total_loyalty_members_popover'),
            },
          ]}
        />
      </div>
      <SearchablePaginatedContent
        contentIsLoading={isFetchingSegments}
        onChangePage={changePage}
        onChangeSearchInput={setSearchQuery}
        currentPage={currentPage}
        numberOfPages={numPages}
        searchPlaceholder={t('search_placeholder')}
      >
        <Row
          className="mt-xl"
          ref={contentRef}
          style={{ scrollMarginTop: '75px' }}
        >
          <Switch condition={!searchIsActive}>
            <Col md={3} className="pr-l">
              <SideNav
                items={sortCategoryNames(categories, locale)}
                current={category}
                all={t('all_segments')}
                onClick={setCategory}
              />
            </Col>
          </Switch>
          <Col>
            <Row>
              <Col>
                <Text.Header3 className="mt-0 mb-s">
                  {category === 'all'
                    ? t('all_segments')
                    : category || t('other')}
                </Text.Header3>
              </Col>
            </Row>
            <Switch condition={!searchIsActive}>
              <Row>
                <Col md={4}>
                  <Select
                    options={typeFilterOptions}
                    value={{ value: type, label: types[type] }}
                    onChange={selectedOptions => {
                      setType(selectedOptions[0]['value'])
                    }}
                    zIndex={2}
                    searchable={false}
                  />
                </Col>
              </Row>
            </Switch>

            <Switch
              condition={segments.length > 0}
              fallback={<div className="my-xl">{t('empty')}</div>}
            >
              <Table
                segments={segments}
                isLoading={isFetchingSegments}
                searchIsActive={searchIsActive}
                onExistingClick={onExistingClick}
              />
            </Switch>
          </Col>
        </Row>
      </SearchablePaginatedContent>
    </div>
  )
}

export default Content
