import { useStyletron } from '@thanx/uikit/theme'
import GenericChart from 'components/Charts/Generic'
import { getDateFilter } from 'components/DateFilter/utilities'
import { LocationContext } from 'contexts/LocationContext'
import { buildTranslate } from 'locales'
import { Fields as Merchant } from 'models/Merchant'
import moment from 'moment'
import React, { useContext, useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import { useSelector } from 'react-redux'
import {
  getLimitedRateChartSeries,
  getXAxisCategories,
} from 'scenes/Reports/CaptureRateReport/RevenueByChannel/Chart/helpers'
import { selectChannelTimeSeriesMetric } from 'selectors/captureRateMetric'
import { merchantTheme } from 'theme'
import { largeCurrencyFormatter } from 'utilities/formatters'
import { useUserTimeZone } from 'utilities/userTimeZone'
import Tooltip from './Tooltip'

type PropsT = {
  merchant: Merchant
}

const t = buildTranslate('capture_rate.revenue_by_channel.chart')

const Chart: React.FC<PropsT> = ({ merchant }) => {
  const { currentLocation } = useContext(LocationContext)

  const metrics = useSelector(state =>
    selectChannelTimeSeriesMetric(state, merchant.id, currentLocation?.id)
  )

  const [css] = useStyletron()
  const userTimeZone = useUserTimeZone()
  const timezone = metrics?.time_zone || userTimeZone
  const filter = useMemo(() => {
    return getDateFilter(
      'all_time',
      timezone,
      moment(merchant.live_at).format('YYYY-MM-DD'),
      moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
    )
  }, [merchant, timezone])

  const leftAxisFormatter = label => {
    return largeCurrencyFormatter(label.value)
  }

  const categories = useMemo(
    () => getXAxisCategories(filter, timezone),
    [filter, timezone]
  )
  const series: Highcharts.SeriesColumnOptions[] = useMemo(() => {
    if (!metrics || !categories) {
      return []
    }
    return getLimitedRateChartSeries(categories, metrics)
  }, [categories, metrics])

  if (!metrics) return null

  return (
    <div
      className={`mb-xxl py-m pr-m pb-xxs pl-xxs ${css({
        backgroundColor: merchantTheme.colors.white,
        border: '1px solid ' + merchantTheme.colors.grey20,
        borderRadius: '4px',
      })}`}
    >
      <GenericChart
        chartType="column"
        categories={categories}
        isLoading={metrics.isLoading}
        isErrored={metrics.isErrored}
        tooltipFormatter={function () {
          const shouldShow = index => {
            return this.series.chart.series[index].visible
          }
          return renderToString(
            <Tooltip
              date={this.point.category}
              showValues={{
                instore_loyalty: shouldShow(0),
                digital_loyalty: shouldShow(1),
              }}
              instore_loyalty={
                this.point.custom.member_purchases_instore_amount
              }
              digital_loyalty={
                this.point.custom.member_purchases_digital_amount
              }
              total_loyalty={this.point.custom.member_purchases_total_amount}
            />
          )
        }}
        plotOptions={{
          column: {
            stacking: 'normal',
            borderWidth: 0,
            maxPointWidth: 64,
          },
        }}
        leftAxisFormatter={leftAxisFormatter}
        series={series}
        xAxisTitle={t('date')}
        yAxisTitle={t('net_revenue')}
      />
    </div>
  )
}

export default Chart
