import { Fields } from 'models/App'
import {
  AxiosAction,
  FailureAction,
  SuccessAction,
} from 'redux-axios-middleware'

export const GET_APP = 'app/GET_APP'
export const GET_APP_SUCCESS = 'app/GET_APP_SUCCESS'
export const GET_APP_FAIL = 'app/GET_APP_FAIL'

export type GetAppResolvedAction =
  | FailureAction<typeof GET_APP_FAIL>
  | SuccessAction<typeof GET_APP_SUCCESS, Fields>

export function getApp(): AxiosAction<typeof GET_APP> {
  return {
    type: GET_APP,
    payload: {
      client: 'default',
      request: {
        url: 'app',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = GetAppResolvedAction
