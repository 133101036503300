import Audience from 'scenes/CampaignCenter/Builder/Steps/Shared/Audience'
import Incentive from 'scenes/CampaignCenter/Builder/Steps/Shared/Incentive'
import Success from 'scenes/CampaignCenter/Builder/Steps/Shared/Success'
import Messaging from 'scenes/CampaignCenter/Builder/Steps/Shared/WrappedMessaging'
import Review from 'scenes/CampaignCenter/Builder/Steps/Timeshift/Review'
import Setup from 'scenes/CampaignCenter/Builder/Steps/Timeshift/Setup'

export default {
  steps: {
    required: {
      setup: Setup,
      review: Review,
      success: Success,
    },
    optional: {
      audience: Audience,
      incentive: Incentive,
      messaging: Messaging,
    },
  },
  options: {
    disableNavFor: {
      success: true,
    },
  },
}
