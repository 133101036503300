import { Text } from '@thanx/uikit/text'
import { StoredValueTransaction } from 'api/storedValue'
import { buildTranslate } from 'locales'
import moment from 'moment-timezone'
import BodyCellContainer from 'scenes/Reports/components/Table/BodyCellContainer'
import { merchantTheme } from 'theme'
import { numberFormatter } from 'utilities/formatters'
import { useUserTimeZone } from 'utilities/userTimeZone'

const t = buildTranslate('users.stored_value.transactions.table')

type Props = {
  transaction: StoredValueTransaction
}

const Row = ({ transaction }: Props) => {
  const timeZone = useUserTimeZone()
  const { amount, kind, created_at, provider_transaction_url } = transaction

  const { colors } = merchantTheme

  const day = moment.tz(created_at, timeZone).format('MMM D, YYYY')
  const time = moment.tz(created_at, timeZone).format('h:mm a')

  return (
    <tr>
      <BodyCellContainer>
        <Text.BodyText4 color={colors.grey70} tag="div">
          <Text.BodyText4 color={colors.grey70} tag="div">
            {day}
          </Text.BodyText4>
          <Text.BodyText5 color={colors.grey70} tag="div">
            {time}
          </Text.BodyText5>
        </Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer>
        <Text.BodyText4 color={colors.grey70}>
          {t(`description.${kind}`, { amount: numberFormatter(amount) })}
        </Text.BodyText4>
      </BodyCellContainer>
      <BodyCellContainer className="text-right">
        <Text.BodyText4 color={colors.grey70}>
          <a href={provider_transaction_url}>Provider dashboard</a>
        </Text.BodyText4>
      </BodyCellContainer>
    </tr>
  )
}

export default Row
