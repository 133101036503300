import { Switch } from '@thanx/uikit/switch'
import {
  impersonateAndAuthorize,
  IMPERSONATE_USER_SUCCESS,
  setCurrentMerchantID,
} from 'actions/auth'
import { alert } from 'actions/flash'
import { getMerchant, getMerchants } from 'actions/merchant'
import Flash from 'components/Flash'
import Spinner from 'components/Spinner'
import useDispatch from 'hooks/useDispatch'
import useIsThanxAdmin from 'hooks/useIsThanxAdmin'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { push } from 'react-router-redux'
import { selectMerchants } from 'selectors/merchant'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'
import { rootUrl } from 'utilities/urlUtils'
import Choices from './Choices'

const t = buildTranslate('auth.merchant_picker')

const MerchantPicker: React.FC = () => {
  const dispatch = useDispatch()
  const merchants = useSelector(selectMerchants)
  const merchantUser = useSelector(selectCurrentMerchantUser)
  //@ts-ignore
  const pathname = useSelector(state => state.redirect?.pathname || rootUrl)
  const isThanxAdmin = useIsThanxAdmin()
  const merchantUserRef = useRef(merchantUser)
  const [doneFetching, setDoneFetching] = useState(false)

  const setCurrentMerchant = useCallback(
    async (id: number) => {
      const refreshMerchantData = async id => {
        dispatch(setCurrentMerchantID(id))
        await dispatch(getMerchant(id))
      }

      if (isThanxAdmin) {
        const response = await dispatch(impersonateAndAuthorize(id))
        if (response.type === IMPERSONATE_USER_SUCCESS) {
          await refreshMerchantData(id)
          window.location.assign(pathname)
        } else {
          dispatch(
            alert({
              key: 'danger',
              message:
                // @ts-ignore
                response.error.response?.data?.errors?.messages || t('error'),
              displayDetails: false,
              timeout: 5,
            })
          )
        }
      } else {
        await refreshMerchantData(id)
        if (merchantUserRef.current) {
          if (merchantUserRef.current.force_password_change) {
            dispatch(push('/change_password'))
          } else {
            window.location.assign(pathname)
          }
        }
      }
    },
    [isThanxAdmin, dispatch, pathname]
  )

  useEffect(() => {
    const fetchMerchants = async () => {
      await dispatch(getMerchants())
      setDoneFetching(true)
    }

    fetchMerchants()
  }, [dispatch])

  useEffect(() => {
    if (doneFetching && merchants.length === 1) {
      setCurrentMerchant(merchants[0].id)
    }
  }, [merchants, setCurrentMerchant, doneFetching])

  return (
    <div className="h-100 grey-05-bkg">
      <div className="absolute w-100 z1up">
        <Flash />
      </div>
      <Switch
        condition={isEmpty(merchants) || merchants.length === 1}
        fallback={
          <Choices
            merchants={merchants}
            setCurrentMerchant={setCurrentMerchant}
          />
        }
      >
        <Spinner
          isLoading={true}
          size="4x"
          className="text-center padding-top-huge"
        />
      </Switch>
    </div>
  )
}

export default MerchantPicker
