import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import ContentContainer from 'components/ContentContainer'
import DateFilter from 'components/DateFilter'
import { DateRangeFilterContext } from 'components/DateFilter/DateFilterContext'
import FetchableMetricSection from 'components/FetchableMetricSection'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import isEqual from 'lodash/isEqual'
import { CampaignStateT, MinimalVariant } from 'models/Campaign'
import { EventsMetric, EventTypes, Type } from 'models/CampaignMetric'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import Attribution from 'scenes/CampaignCenter/Report/v3/Summary/components/Attribution'
import { selectCampaignEventsMetricsByTypes } from 'selectors/campaignMetric'
import { useUserTimeZone } from 'utilities/userTimeZone'
import ColumnChart from './ColumnChart'
import VariantsDescription from './VariantsDescription'

const t = buildTranslate('thanx_campaigns.report.v3.summary.chart')

type PropsT = {
  campaignId: number
  campaignState: CampaignStateT
  campaignType: string
  startAt: string
  endAt: string | null
  testEndedAt: string | null
  winningVariantId: number | null
  variants: MinimalVariant[]
}

const Chart: React.FC<PropsT> = ({
  campaignId,
  campaignState,
  campaignType,
  startAt,
  endAt,
  testEndedAt,
  winningVariantId,
  variants,
}) => {
  const [css] = useStyletron()
  const { attributionWindow } = useAttributionWindow()

  const types: EventTypes[] = ['message', 'purchase', 'redeem']
  const metrics =
    (useSelector(
      state =>
        selectCampaignEventsMetricsByTypes(state, campaignId, [
          Type.EVENTS_MESSAGE,
          Type.EVENTS_PURCHASE,
          Type.EVENTS_REDEEM,
        ]),
      isEqual
    ) as EventsMetric[]) || []
  const isLoading = metrics.some(metric => metric.isLoading)
  const isErrored = metrics.some(metric => metric.isErrored)
  let errorMessage = ''
  if (isErrored) {
    const types: string[] = []
    metrics.forEach(metric => {
      if (metric.isErrored) {
        types.push(`"${t(metric.type)}"`)
      }
    })
    errorMessage = t('data_has_failed', { type: types.join(', ') })
  }
  const { filter, bounds } = useContext(DateRangeFilterContext)
  const userTimeZone = useUserTimeZone()
  const timezone = metrics[0]?.time_zone || userTimeZone
  const isCustomAutomated = campaignType === 'custom_automated'

  return (
    <FetchableMetricSection
      type="campaign_event"
      className={`w-100 h-100 pl-0 pt-m mb-xxl white-bkg border-1 grey-20-border position-relative ${css(
        {
          borderRadius: '4px',
        }
      )}`}
      campaignId={campaignId}
      shouldFetch={['active', 'complete'].includes(campaignState)}
      types={types}
      attributionWindow={attributionWindow}
      dateRangeFilter={filter}
      isCustomAutomated={isCustomAutomated}
    >
      <div className="d-flex flex-align-center flex-space-between mb-l mr-m">
        <div className="ml-l">
          <Text.Header4 bold className="mt-0">
            {t('title')}
          </Text.Header4>
          <Attribution
            attributionWindow={attributionWindow}
            tooltipText={t('attribution.tooltip', { count: attributionWindow })}
          />
        </div>
        <div className="d-flex align-items-center">
          <ContentContainer isErrored={isErrored} isLoading={isLoading} />
          {isErrored && (
            <span className="cayenne-50 ml-xs">
              <Text.BodyText4 color="inherit">{errorMessage}</Text.BodyText4>
            </span>
          )}
          <Switch condition={isCustomAutomated}>
            <div className="ml-s">
              <DateFilter
                allTimeStartDate={bounds.startDate}
                allTimeEndDate={bounds.endDate}
              />
            </div>
          </Switch>
        </div>
      </div>
      {metrics?.length === 3 ? (
        <ColumnChart
          metrics={metrics}
          t={t}
          startAt={startAt}
          endAt={endAt}
          timezone={timezone}
          testEndedAt={testEndedAt}
          variants={variants}
        />
      ) : (
        <div className="my-m d-flex align-items-center flex-column">
          <Spinner isLoading />
        </div>
      )}
      {timezone && (
        <Text.BodyText5
          className={`position-relative float-right mr-m ${css({
            marginTop: '-35px',
          })}`}
          color="grey70"
        >
          {t('timezone', { timezone })}
        </Text.BodyText5>
      )}
      <Switch condition={variants?.length > 1}>
        <VariantsDescription
          campaignState={campaignState}
          endAt={endAt}
          startAt={startAt}
          testEndedAt={testEndedAt}
          timezone={timezone}
          winningVariantId={winningVariantId}
          variants={variants}
        />
      </Switch>
    </FetchableMetricSection>
  )
}

export default Chart
