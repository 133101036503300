import ProtectedRoute from 'components/ProtectedRoute'
import React from 'react'
import { Switch } from 'react-router'
import Create from './Create'
import Detail from './Detail'
import Edit from './Edit'
import Landing from './Landing'

const RedeemManager: React.FC = () => {
  return (
    <div className="full-height grey-05-bkg">
      <Switch>
        <ProtectedRoute
          accessType="access"
          redirect="/thanx_campaigns"
          resource="RedeemManager"
          exact
          path="/rewards"
          component={Landing}
        />
        <ProtectedRoute
          redirect="/thanx_campaigns"
          resource="RedeemManager"
          exact
          path="/rewards/create"
          component={Create}
        />
        <ProtectedRoute
          redirect="/thanx_campaigns"
          resource="RedeemManager"
          exact
          path="/rewards/:id/edit"
          component={Edit}
        />
        <ProtectedRoute
          accessType="access"
          redirect="/thanx_campaigns"
          resource="RedeemManager"
          exact
          path="/rewards/:id"
          component={Detail}
        />
      </Switch>
    </div>
  )
}

export default RedeemManager
