import React from 'react'
import { Translate } from 'react-redux-i18n'

const Circle: React.FC<{ text: React.ReactNode }> = ({ text }) => (
  <div
    style={{ height: '48px', width: '48px' }}
    className="align-items-center justify-content-center d-flex border-2 rounded-circle"
  >
    {text}
  </div>
)

const HowItWorks: React.FC = () => (
  <div className="padding-huge">
    <div className="small-caps-5 margin-bottom-huge text-center">
      <Translate
        className="small-caps-5 margin-bottom-huge"
        value="thanx_campaigns.landing.targeted.custom_campaign_modal.how_it_works.title"
      />
    </div>
    <div className="d-flex justify-content-center">
      <Circle text="1" />
    </div>
    <div className="text-center font-size-14 grey-70 margin-bottom-huge margin-top-medium">
      <Translate
        className="margin-bottom-huge"
        value="thanx_campaigns.landing.targeted.custom_campaign_modal.how_it_works.step1.text"
      />
    </div>
    <div className="d-flex justify-content-center">
      <Circle text="2" />
    </div>
    <div className="text-center font-size-14 grey-70 margin-top-medium">
      <Translate
        className="margin-bottom-huge"
        value="thanx_campaigns.landing.targeted.custom_campaign_modal.how_it_works.step2.text"
      />
    </div>
    <div className="text-center font-size-14 grey-50 margin-bottom-huge">
      <Translate value="thanx_campaigns.landing.targeted.custom_campaign_modal.how_it_works.step2.sub_text" />
    </div>
    <div className="d-flex justify-content-center">
      <Circle text="3" />
    </div>
    <div className="text-center font-size-14 grey-70 margin-top-medium">
      <Translate
        className="margin-bottom-huge"
        value="thanx_campaigns.landing.targeted.custom_campaign_modal.how_it_works.step3.text"
      />
    </div>
  </div>
)

export default HowItWorks
