import { Button, Kind } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'

type Props = {
  title: string
  message: string
  buttonText?: string
  buttonPath?: string
  buttonAction?: () => void
}

const t = buildTranslate('auth')

const BasicMessage: React.FC<Props> = ({
  title,
  message,
  buttonText = t('try_again'),
  buttonPath,
  buttonAction,
}) => {
  return (
    <>
      <Text.Header3 color="grey90" className="mb-xs mt-0">
        {title}
      </Text.Header3>
      <Text.BodyText3 color="grey70" className="mb-m" tag="p">
        {message}
      </Text.BodyText3>
      {buttonAction ? (
        <Button
          onClick={buttonAction}
          kind={Kind.PRIMARY}
          className="btn-block mt-m"
        >
          {buttonText}
        </Button>
      ) : (
        <LinkContainer to={buttonPath}>
          <Button kind={Kind.PRIMARY} className="btn-block mt-m">
            {buttonText}
          </Button>
        </LinkContainer>
      )}
    </>
  )
}

export default BasicMessage
