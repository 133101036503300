import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { useShowV3 } from 'scenes/CampaignCenter/Builder/hooks'
import VariantSelectModal from './components/VariantSelectModal'

type Props = {
  campaign: Campaign
  variantsLength: number
  isOpen: boolean
  setIsOpen: (isOpen) => void
}

const t = buildTranslate('thanx_campaigns.review.content')

const AddVariant: React.FC<Props> = ({
  campaign,
  variantsLength,
  isOpen,
  setIsOpen,
}) => {
  const [css] = useStyletron()

  const showButton = useShowV3(campaign?.type)
  const allowedVariantCount = 4
  const { variants } = campaign
  const hasControlGroup = !!variants?.find(v => v.internal_type === 'control')
  const onClick = () => setIsOpen(true)

  const addVariantButton = () => {
    if (variantsLength >= allowedVariantCount) {
      return (
        <div
          className={`d-flex mt-xs ${css({
            alignItems: 'center',
          })}`}
        >
          <Button
            className={`mr-xs ${css({
              backgroundColor: 'transparent !important',
            })}`}
            type="button"
            kind="secondary"
            disabled
          >
            {t('add_variant')}
          </Button>
          <PopoverContent
            sections={[
              {
                content: t('max_variants_tip'),
              },
            ]}
          />
        </div>
      )
    } else {
      return (
        <Button
          className="mt-xs"
          type="button"
          kind="secondary"
          onClick={onClick}
        >
          {t('add_variant')}
        </Button>
      )
    }
  }

  return (
    <>
      <Switch condition={showButton}>
        <div className="d-flex justify-content-end">{addVariantButton()}</div>
      </Switch>
      <VariantSelectModal
        campaign={campaign}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        hasControlGroup={hasControlGroup}
      />
    </>
  )
}

export default AddVariant
