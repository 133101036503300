import { createProviderList } from 'actions/emailProviderLists'
import { createProvider } from 'actions/emailProviders'
import { alert } from 'actions/flash'
import { getUploadUrl } from 'actions/s3'
import axios from 'axios'
import Spacer from 'components/Spacer'
import useDispatch from 'hooks/useDispatch'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import ColumnMapper from './components/ColumnMapper'
import DataTable from './components/DataTable'

type Props = {
  hasHeader: boolean
  file: any
  contents: string[]
  onCancel: VoidFunction
  onSuccess: VoidFunction
}
type Columns = {
  email: string
  first_name: string
  last_name: string
}

const DataMapper: React.FC<Props> = (props: Props) => {
  const { contents, hasHeader, file, onCancel, onSuccess } = props

  const getHeaders = () => {
    let headerContents: string[] = contents[0].split(',')
    if (!hasHeader) {
      headerContents = headerContents.map((_value, index) => {
        return I18n.t('emails.recipients.data_mapper.default_column_name', {
          columnNumber: index + 1,
        })
      })
    }
    return headerContents
  }

  const dispatch = useDispatch()
  const [headers] = useState<string[]>(getHeaders())
  const [isValid, setIsValid] = useState<boolean>(true)
  const [columnValues, setColumnValues] = useState<Columns>({
    email: '0',
    first_name: '',
    last_name: '',
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const setValid = (columnState: Columns) => {
    setIsValid(true)
    setColumnValues(columnState)
  }

  const setInvalid = (columnState: Columns) => {
    setIsValid(false)
    setColumnValues(columnState)
  }

  const sendAlert = (
    message: string,
    error?: any,
    displayDetails: boolean = false
  ) => {
    dispatch(
      alert({
        key: 'danger',
        message: message,
        error: error,
        displayDetails: displayDetails,
      })
    )
  }

  const submit = async () => {
    setIsSubmitting(true)
    let params = {
      filename: encodeURIComponent(file.name),
      type: 'csv',
    }
    const result = await dispatch(getUploadUrl(params))
    if (result.error) {
      setIsSubmitting(false)
      sendAlert(
        I18n.t('emails.recipients.data_mapper.submission.config_failure'),
        result.error.response ? result.error.response.data.error : null,
        true
      )
      return
    }

    uploadToS3(result.payload.data.upload_url)
  }

  const uploadToS3 = async (s3_config: any) => {
    let data = new window.FormData()
    data.append('file', file)
    const result = await axios.put(s3_config.url, data)
    if (result.status >= 200 && result.status < 300) {
      handleUploadSuccess(s3_config.key)
    } else {
      setIsSubmitting(false)
      sendAlert(
        I18n.t('emails.recipients.data_mapper.submission.s3_upload_failure')
      )
    }
  }

  const handleUploadSuccess = async (filePath: string) => {
    const createProviderResult = (await dispatch(
      createProvider('upload', null)
    )) as any
    if (createProviderResult.error) return createProviderResult
    const provider = createProviderResult.payload.data.email_provider
    const params = {
      provider_id: provider.id,
      name: file.name,
      state: 'pending',
      settings: {
        mapping: columnValues,
        upload_path: filePath,
        headers: hasHeader,
      },
    }

    const createProviderListResult = (await dispatch(
      //@ts-ignore
      createProviderList(params)
    )) as any
    setIsSubmitting(false)
    if (createProviderListResult.error) {
      sendAlert(
        I18n.t(
          'emails.recipients.data_mapper.submission.list_creation_failure'
        ),
        createProviderListResult.error.response
          ? createProviderListResult.error.response.data.error
          : null,
        true
      )
      return
    } else {
      onSuccess()
    }
  }

  return (
    <div className="container">
      <h3 className="deprecated__h3" style={{ marginBottom: '40px' }}>
        <Translate value="emails.recipients.data_mapper.title" />
      </h3>
      <h6 className="text-muted" style={{ marginBottom: '20px' }}>
        <Translate
          value="emails.recipients.data_mapper.file_name"
          fileName={file.name}
        />
      </h6>

      <div className="fs-mask" style={{ marginBottom: '40px' }}>
        <DataTable
          hasHeader={hasHeader}
          contents={contents}
          headers={headers}
        />
      </div>

      <h6 className="text-muted" style={{ marginBottom: '20px' }}>
        <Translate value="emails.recipients.data_mapper.subscription_fields.title" />
      </h6>
      <Spacer paddingBottom="100px">
        <ColumnMapper
          headers={headers}
          setValid={setValid}
          setInvalid={setInvalid}
        />
      </Spacer>
      <Button
        onClick={submit}
        bsStyle="info"
        disabled={!isValid || isSubmitting}
        style={{ minWidth: '126px' }}
      >
        {isSubmitting ? (
          <span className="fa fa-spin fa-circle-o-notch fa-1x" />
        ) : (
          I18n.t('emails.recipients.data_mapper.submit_button')
        )}
      </Button>
      <Button onClick={onCancel} bsStyle="link" disabled={isSubmitting}>
        {I18n.t('emails.recipients.data_mapper.cancel_button')}
      </Button>
    </div>
  )
}

export default DataMapper
