import FetchableMetricSection from 'components/FetchableMetricSection'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { ShortType } from 'models/MerchantMetric'
import React from 'react'
import { Container } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { selectMerchantMetricByType } from 'selectors/merchantMetric'
import Chart from './components/Chart'
import OpportunityCarousel from './components/OpportunityCarousel'
import Table from './components/Table'

type PropsT = {
  onSwitchTabAction: (tab: any) => void
}

const Overview: React.FC<PropsT> = ({ onSwitchTabAction }) => {
  const merchantId = useCurrentMerchant()?.id
  const memberLifecycleState = useSelector(state =>
    selectMerchantMetricByType(state, ShortType.LIFECYCLE)
  )

  return (
    <div className="grey-05-bkg pb-xxl grey-20-border">
      <Container>
        <FetchableMetricSection
          type="lifecycle_metric"
          merchantId={merchantId}
          shouldFetch={!memberLifecycleState}
          metricType={ShortType.LIFECYCLE}
        >
          <OpportunityCarousel onSwitchTabAction={onSwitchTabAction} />
          <div className="pb-xl">
            <Chart />
          </div>
          <div className="pb-xl">
            <Table />
          </div>
        </FetchableMetricSection>
      </Container>
    </div>
  )
}

export default Overview
