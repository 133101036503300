import { getPointsExperiences } from 'actions/pointsExperiences'
import { Fields as Merchant } from 'models/Merchant'
import React, { useEffect } from 'react'
import { Row } from 'react-bootstrap-five'
import { useDispatch, useSelector } from 'react-redux'
import { getNonDiscountCategories } from 'scenes/RedeemManager/helpers'
import { RedeemTypeItem } from 'scenes/RedeemManager/types'
import { selectExperience } from 'selectors/pointsExperience'
import Card from './Card'

type Props = {
  merchant: Merchant | null
  typeItem: RedeemTypeItem | null
  setRedeemType: (typeItem: RedeemTypeItem) => void
}

const TypeStep: React.FC<Props> = ({ merchant, setRedeemType, typeItem }) => {
  const exclude: string[] = []
  const dispatch = useDispatch()
  const pointsExperience = useSelector(selectExperience)

  useEffect(() => {
    dispatch(getPointsExperiences())
  }, [dispatch])

  if (!pointsExperience) {
    exclude.push('bonus_points')
  }
  const categories = getNonDiscountCategories(
    !!merchant?.ordering_enabled,
    exclude
  )
  return (
    <Row>
      {categories.map(redeemCategory => (
        <Card
          key={redeemCategory.key}
          redeemCategory={redeemCategory}
          checked={typeItem === redeemCategory.items[0]}
          setRedeemType={setRedeemType}
        />
      ))}
    </Row>
  )
}

export default TypeStep
