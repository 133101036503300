'use strict'
var __assign =
  (this && this.__assign) ||
  function () {
    __assign =
      Object.assign ||
      function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
          s = arguments[i]
          for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p]
        }
        return t
      }
    return __assign.apply(this, arguments)
  }
var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value)
          })
    }
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value))
        } catch (e) {
          reject(e)
        }
      }
      function rejected(value) {
        try {
          step(generator['throw'](value))
        } catch (e) {
          reject(e)
        }
      }
      function step(result) {
        result.done
          ? resolve(result.value)
          : adopt(result.value).then(fulfilled, rejected)
      }
      step((generator = generator.apply(thisArg, _arguments || [])).next())
    })
  }
var __generator =
  (this && this.__generator) ||
  function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function () {
          if (t[0] & 1) throw t[1]
          return t[1]
        },
        trys: [],
        ops: [],
      },
      f,
      y,
      t,
      g
    return (
      (g = { next: verb(0), throw: verb(1), return: verb(2) }),
      typeof Symbol === 'function' &&
        (g[Symbol.iterator] = function () {
          return this
        }),
      g
    )
    function verb(n) {
      return function (v) {
        return step([n, v])
      }
    }
    function step(op) {
      if (f) throw new TypeError('Generator is already executing.')
      while (_)
        try {
          if (
            ((f = 1),
            y &&
              (t =
                op[0] & 2
                  ? y['return']
                  : op[0]
                  ? y['throw'] || ((t = y['return']) && t.call(y), 0)
                  : y.next) &&
              !(t = t.call(y, op[1])).done)
          )
            return t
          if (((y = 0), t)) op = [op[0] & 2, t.value]
          switch (op[0]) {
            case 0:
            case 1:
              t = op
              break
            case 4:
              _.label++
              return { value: op[1], done: false }
            case 5:
              _.label++
              y = op[1]
              op = [0]
              continue
            case 7:
              op = _.ops.pop()
              _.trys.pop()
              continue
            default:
              if (
                !((t = _.trys), (t = t.length > 0 && t[t.length - 1])) &&
                (op[0] === 6 || op[0] === 2)
              ) {
                _ = 0
                continue
              }
              if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                _.label = op[1]
                break
              }
              if (op[0] === 6 && _.label < t[1]) {
                _.label = t[1]
                t = op
                break
              }
              if (t && _.label < t[2]) {
                _.label = t[2]
                _.ops.push(op)
                break
              }
              if (t[2]) _.ops.pop()
              _.trys.pop()
              continue
          }
          op = body.call(thisArg, _)
        } catch (e) {
          op = [6, e]
          y = 0
        } finally {
          f = t = 0
        }
      if (op[0] & 5) throw op[1]
      return { value: op[0] ? op[1] : void 0, done: true }
    }
  }
exports.__esModule = true
var jsx_runtime_1 = require('react/jsx-runtime')
var button_1 = require('@thanx/uikit/button')
var modal_1 = require('@thanx/uikit/modal')
var campaigns_1 = require('actions/campaigns')
var flash_1 = require('actions/flash')
var useDispatch_1 = require('hooks/useDispatch')
var react_bootstrap_five_1 = require('react-bootstrap-five')
var react_redux_i18n_1 = require('react-redux-i18n')
var TrashIcon_1 = require('./TrashIcon')
var theme_1 = require('theme')
var DeleteModal = function (props) {
  var campaign = props.campaign,
    isOpen = props.isOpen,
    onClose = props.onClose
  var dispatch = (0, useDispatch_1['default'])()
  function onSubmit() {
    return __awaiter(this, void 0, void 0, function () {
      var response
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [
              4 /*yield*/,
              dispatch((0, campaigns_1.archiveCampaign)(campaign.id)),
            ]
          case 1:
            response = _a.sent()
            if (response.error) {
              dispatch(
                (0, flash_1.alert)({
                  key: 'danger',
                  message: react_redux_i18n_1.I18n.t(
                    'thanx_campaigns.landing.reporting.table.settings.draft.modal.error'
                  ),
                })
              )
              return [2 /*return*/]
            }
            onClose()
            return [2 /*return*/]
        }
      })
    })
  }
  return (0, jsx_runtime_1.jsx)(
    modal_1.Modal,
    __assign(
      {
        isOpen: isOpen,
        onClose: onClose,
        overrides: {
          Root: {
            style: {
              zIndex: theme_1.merchantTheme.zIndex.modal,
            },
          },
        },
      },
      {
        children: (0, jsx_runtime_1.jsxs)(
          'div',
          __assign(
            { className: 'fs-unmask p-xl text-center' },
            {
              children: [
                (0, jsx_runtime_1.jsx)(
                  'div',
                  __assign(
                    { className: 'mb-xl' },
                    {
                      children: (0, jsx_runtime_1.jsx)(
                        TrashIcon_1['default'],
                        {}
                      ),
                    }
                  )
                ),
                (0, jsx_runtime_1.jsx)(
                  'div',
                  __assign(
                    { className: 'mb-xs h3 grey-90' },
                    {
                      children: react_redux_i18n_1.I18n.t(
                        'thanx_campaigns.landing.reporting.table.settings.draft.modal.title'
                      ),
                    }
                  )
                ),
                (0, jsx_runtime_1.jsx)(
                  'div',
                  __assign(
                    { className: 'mb-xl grey-70 body-text-3' },
                    {
                      children: react_redux_i18n_1.I18n.t(
                        'thanx_campaigns.landing.reporting.table.settings.draft.modal.body'
                      ),
                    }
                  )
                ),
                (0, jsx_runtime_1.jsxs)(react_bootstrap_five_1.Row, {
                  children: [
                    (0, jsx_runtime_1.jsx)(
                      react_bootstrap_five_1.Col,
                      __assign(
                        { md: 6 },
                        {
                          children: (0, jsx_runtime_1.jsx)(
                            button_1.Button,
                            __assign(
                              {
                                onClick: onClose,
                                kind: button_1.Kind.SECONDARY,
                                className: 'w-100',
                              },
                              {
                                children: react_redux_i18n_1.I18n.t(
                                  'thanx_campaigns.landing.reporting.table.settings.draft.modal.secondary'
                                ),
                              }
                            )
                          ),
                        }
                      )
                    ),
                    (0, jsx_runtime_1.jsx)(
                      react_bootstrap_five_1.Col,
                      __assign(
                        { md: 6 },
                        {
                          children: (0, jsx_runtime_1.jsx)(
                            button_1.Button,
                            __assign(
                              {
                                onClick: function () {
                                  return onSubmit()
                                },
                                kind: button_1.Kind.PRIMARY,
                                className: 'w-100',
                              },
                              {
                                children: react_redux_i18n_1.I18n.t(
                                  'thanx_campaigns.landing.reporting.table.settings.draft.modal.primary'
                                ),
                              }
                            )
                          ),
                        }
                      )
                    ),
                  ],
                }),
              ],
            }
          )
        ),
      }
    )
  )
}
exports['default'] = DeleteModal
