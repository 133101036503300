import React from 'react'
import styles from './CampaignSection.module.scss'

type Props = {
  coverImageUrl: string
  logoImageUrl: string
}

const CampaignSectionLogoImageCard: React.FC<Props> = ({
  coverImageUrl,
  logoImageUrl,
}) => {
  return (
    <div className="full-height padding-bottom-medium padding-top-medium">
      <div
        className={`${styles.imageCardCover} d-flex justify-content-center align-items-center border-radius-5`}
        style={{ backgroundImage: `url(${coverImageUrl})` }}
      >
        <div
          className={`${styles.imageCardLogo}`}
          style={{ backgroundImage: `url(${logoImageUrl})` }}
        />
      </div>
    </div>
  )
}

export default CampaignSectionLogoImageCard
