import React from 'react'
import Delighted from './Delighted'
import Pendo from './Pendo'
import UserTabbing from './UserTabbing'

const Head: React.FC = () => {
  return (
    <>
      <UserTabbing />
      <Pendo />
      {process.env.NODE_ENV === 'production' && <Delighted />}
    </>
  )
}

export default Head
