import ImagePicker, { ImagePickerValue } from 'components/ImagePicker'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { selectHeaderValidation } from 'selectors/validation'

type Props = {
  itemId: string
  index: number
  image: ImagePickerValue
}

const t = buildTranslate('cms.content.hero_carousel')

const Slide: React.FC<Props> = props => {
  const { image, itemId, index } = props
  const headerValidation = useSelector(selectHeaderValidation)

  return (
    <div>
      <p className="mb-m small-caps-2 bold">
        {t('slide', { index: index + 1 })}
      </p>
      {headerValidation && (
        <ImagePicker
          name={`${itemId}`}
          value={image}
          validations={{
            image: true,
          }}
          validationErrors={{
            image: I18n.t('validations.is_required'),
          }}
          imageValidations={headerValidation}
        />
      )}
    </div>
  )
}

export default Slide
