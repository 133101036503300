import { useEffect, useState } from 'react'

export function useIsUserTabbing(): boolean {
  const [isUserTabbing, setIsUserTabbing] = useState<boolean>(false)

  useEffect(() => {
    function handleFirstTab(event: KeyboardEvent): void {
      if (event.keyCode === 9) {
        setIsUserTabbing(true)

        window.removeEventListener('keydown', handleFirstTab)
        window.addEventListener('mousedown', handleMouseDownOnce)
      }
    }

    function handleMouseDownOnce(): void {
      setIsUserTabbing(false)

      window.removeEventListener('mousedown', handleMouseDownOnce)
      window.addEventListener('keydown', handleFirstTab)
    }

    window.addEventListener('keydown', handleFirstTab)

    return () => {
      window.removeEventListener('mousedown', handleMouseDownOnce)
      window.removeEventListener('keydown', handleFirstTab)
    }
  }, [])

  return isUserTabbing
}
