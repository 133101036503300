import TabsContainer from 'components/Tabs/Container'
import { Fields as RedeemTemplate } from 'models/RedeemTemplate'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import TabPane from './TabPane'

type PropsT = {
  activeTemplates: RedeemTemplate[]
  draftTemplates: RedeemTemplate[]
  archivedTemplates: RedeemTemplate[]
}

const RedeemTemplateTabs: React.FC<PropsT> = props => {
  const { activeTemplates, draftTemplates, archivedTemplates } = props
  const getName = state => I18n.t(`redeem_manager.landing.tabs.${state}.title`)
  return (
    <TabsContainer
      defaultTab="published"
      tabs={[
        {
          id: 'published',
          name: getName('published'),
          content: (
            <TabPane
              key="published"
              state="published"
              templates={activeTemplates}
            />
          ),
        },
        {
          id: 'unpublished',
          name: getName('unpublished'),
          content: (
            <TabPane
              key="unpublished"
              state="unpublished"
              templates={draftTemplates}
            />
          ),
        },
        {
          id: 'archived',
          name: getName('archived'),
          content: (
            <TabPane
              key="archived"
              state="archived"
              templates={archivedTemplates}
            />
          ),
        },
      ]}
    />
  )
}

export default RedeemTemplateTabs
