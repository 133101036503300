import palette from 'constants/palette'
import isNil from 'lodash/isNil'
import { I18n } from 'react-redux-i18n'
import { MetricsProps } from 'scenes/CampaignCenter/Report/components/withMetrics'
import LocationControlGroup from './sections/Location/ControlGroup'
import LocationSummary from './sections/Location/Summary'
import ControlGroup from './sections/Shared/ControlGroup'
import Customers from './sections/Shared/Customers'
import CustomerValue from './sections/Shared/CustomerValue'
import CustomerValueInfo from './sections/Shared/CustomerValueInfo'
import Details from './sections/Shared/Details'
import ItemsSold from './sections/Shared/ItemsSold'
import LinkActivity from './sections/Shared/LinkActivity'
import RepeatCustomers from './sections/Shared/RepeatCustomers'
import Summary from './sections/Shared/Summary'

const timeshiftBase = {
  color: palette.wisteria,
  hasRevenue: true,
  sections: [Summary, LinkActivity, Customers, ControlGroup, Details],
  metrics: [
    'cost',
    'message_count',
    'redeem',
    'restricted_engagement',
    'revenue',
    'link_activity',
  ],
  successMetric: 'restricted_engagement',
  getSuccessCount: (props: MetricsProps) => {
    const count =
      props?.metrics?.restricted_engagement?.registered_purchasers_count
    if (!count) return null
    return I18n.l(count, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  },
  getTargetFraction: (props: MetricsProps) => {
    return props?.metrics?.restricted_engagement
      ?.registered_sent_engagement_rate
  },
  getControlFraction: (props: MetricsProps) =>
    props?.metrics?.restricted_engagement?.control_sent_engagement_rate,
}

const locationBase = {
  color: palette.wisteria,
  hasRevenue: true,
  sections: [
    LocationSummary,
    LinkActivity,
    Customers,
    LocationControlGroup,
    Details,
  ],
  metrics: [
    'cost',
    'message_count',
    'redeem',
    'restricted_engagement',
    'revenue',
    'link_activity',
  ],
  successMetric: 'restricted_engagement',
  getSuccessCount: (props: MetricsProps) => {
    const count =
      props?.metrics?.restricted_engagement?.registered_purchasers_count
    if (!count) return null
    return I18n.l(count, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  },
  getTargetFraction: (props: MetricsProps) =>
    props?.metrics?.restricted_engagement?.registered_sent_engagement_rate,
  getControlFraction: (props: MetricsProps) =>
    props?.metrics?.restricted_engagement?.control_sent_engagement_rate,
}

const configs = {
  custom: {
    color: palette.wisteria,
    hasRevenue: true,
    sections: [Summary, LinkActivity, Customers, ControlGroup, Details],
    metrics: [
      'cost',
      'customers',
      'message_count',
      'redeem',
      'revenue',
      'link_activity',
    ],
    successMetric: 'revenue',
    getSuccessCount: (props: MetricsProps) => {
      const count = props?.metrics?.revenue?.purchasers_count
      if (!count) return null
      return I18n.l(count, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    getTargetFraction: (props: MetricsProps) =>
      props?.metrics?.revenue?.target_engagement_rate,
    getControlFraction: (props: MetricsProps) =>
      props?.metrics?.revenue?.control_engagement_rate,
  },

  custom_automated: {
    color: palette.spearmint,
    hasRevenue: false,
    sections: [Summary, LinkActivity, Details],
    metrics: ['email_message_count', 'redeem'],
    successMetric: 'email_message_count',
    getSuccessCount: (props: MetricsProps) =>
      props?.metrics?.email_message_count?.clicked_count,
  },

  message: {
    color: palette.orange,
    hasRevenue: true,
    sections: [Summary, LinkActivity, Customers, ControlGroup, Details],
    metrics: [
      'cost',
      'customers',
      'message_count',
      'redeem',
      'revenue',
      'link_activity',
    ],
    successMetric: 'revenue',
    getSuccessCount: (props: MetricsProps) => {
      const count = props?.metrics?.revenue?.purchasers_count
      if (!count) return null
      return I18n.l(count, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
    getTargetFraction: (props: MetricsProps) =>
      props?.metrics?.revenue?.target_engagement_rate,
    getControlFraction: (props: MetricsProps) =>
      props?.metrics?.revenue?.control_engagement_rate,
  },

  reputation: {
    color: palette.spearmint,
    hasRevenue: false,
    sections: [Summary, LinkActivity, Details],
    metrics: ['message_count', 'link_activity'],
    successMetric: 'message_count',
    getSuccessCount: (props: MetricsProps) =>
      props?.metrics?.message_count?.clicked_count,
  },

  'timeshift-immediate': { ...timeshiftBase },
  'timeshift-morning': { ...timeshiftBase },
  'timeshift-midday': { ...timeshiftBase },
  'timeshift-afternoon': { ...timeshiftBase },
  'timeshift-evening': { ...timeshiftBase },
  'timeshift-night': { ...timeshiftBase },
  'timeshift-weekday': { ...timeshiftBase },
  'timeshift-weekend': { ...timeshiftBase },

  'location-message': { ...locationBase },
  'location-close': { ...locationBase },
  'location-reopen': { ...locationBase },
  'location-promote': { ...locationBase },

  'engage-vip': {
    color: palette.spearmint,
    hasRevenue: true,
    sections: [Summary, LinkActivity, Customers, RepeatCustomers, Details],
    metrics: [
      'cost',
      'message_count',
      'redeem',
      'revenue',
      'conversion',
      'link_activity',
    ],
    successMetric: 'redeem',
    getSuccessCount: (props: MetricsProps) => {
      const count = props?.metrics?.redeem?.count
      if (isNil(count)) return count

      return I18n.l(count, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    },
  },

  winback: {
    color: palette.spearmint,
    hasRevenue: true,
    sections: [
      Summary,
      LinkActivity,
      Customers,
      CustomerValue,
      CustomerValueInfo,
      RepeatCustomers,
      Details,
    ],
    metrics: [
      'cost',
      'message_count',
      'redeem',
      'revenue',
      'conversion',
      'link_activity',
    ],
    successMetric: 'conversion',
    getSuccessCount: (props: MetricsProps) => {
      const rate = props?.metrics?.conversion?.rate
      if (isNil(rate)) return rate

      return I18n.l(rate, { style: 'percent', maximumFractionDigits: 0 })
    },
  },

  upsell: {
    color: palette.orange,
    hasRevenue: true,
    sections: [Summary, LinkActivity, ControlGroup, ItemsSold, Details],
    metrics: [
      'cost',
      'message_count',
      'redeem',
      'revenue',
      'conversion',
      'link_activity',
    ],
    successMetric: 'conversion',
    getSuccessCount: (props: MetricsProps) => {
      const rate = props?.metrics?.conversion?.rate
      if (isNil(rate)) return rate

      return I18n.l(rate, { style: 'percent', maximumFractionDigits: 0 })
    },
    getTargetFraction: (props: MetricsProps) =>
      props?.metrics?.revenue?.target_engagement_rate,
    getControlFraction: (props: MetricsProps) =>
      props?.metrics?.revenue?.control_engagement_rate,
  },
}

export default configs
