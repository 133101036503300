import { getCampaignTypes } from 'actions/campaignTypes'
import { updatePaginationCurrentPage } from 'actions/pagination'
import { getUserCampaigns } from 'actions/users'
import useDispatch from 'hooks/useDispatch'
import { Fields as UserProfile } from 'models/UserProfile'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignTypes } from 'selectors/campaignType'
import { selectPagination } from 'selectors/pagination'
import { selectUserCampaigns } from 'selectors/userCampaign'

export const useCampaignsTable = (profile: UserProfile) => {
  const dispatch = useDispatch()
  const [isFetchingCampaigns, setIsFetchingCampaigns] = useState(false)
  const paginationInfo = useSelector(ormState =>
    selectPagination(ormState, 'user_campaigns', profile.uid)
  )
  const currentPage = paginationInfo?.current_page ?? 1
  const numPages = paginationInfo?.total_pages ?? 1
  const campaignIds = paginationInfo?.record_ids?.[currentPage] ?? []
  const campaigns = useSelector(state =>
    selectUserCampaigns(state, profile.id)
  ).filter(userCampaign => campaignIds.includes(userCampaign.id))
  const campaignTypes = useSelector(state => selectCampaignTypes(state))

  useEffect(() => {
    if (campaignTypes.length === 0) {
      dispatch(getCampaignTypes())
    }
  }, [dispatch, campaignTypes.length])

  const fetchCampaigns = useCallback(
    async (page: number) => {
      setIsFetchingCampaigns(true)
      await dispatch(getUserCampaigns(profile.uid, { page }))
      setIsFetchingCampaigns(false)
    },
    [dispatch, profile.uid]
  )

  const onChangePage = useCallback(
    async (page: number) => {
      if (page in (paginationInfo?.record_ids ?? {})) {
        // campaigns for that page are already in redux,
        // update the current_page to show it
        window.scrollTo(0, 0)
        dispatch(
          updatePaginationCurrentPage({
            type: 'user_campaigns',
            id: profile.uid,
            page,
          })
        )
        return
      }

      // fetch the new page
      window.scrollTo(0, 0)
      fetchCampaigns(page)
    },
    [dispatch, paginationInfo, fetchCampaigns, profile.uid]
  )

  useEffect(() => {
    onChangePage(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangePage])

  return {
    isFetchingCampaigns,
    currentPage,
    numPages,
    campaigns,
    campaignTypes,
    onChangePage,
  }
}
