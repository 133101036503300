import InnerTabs, { TabT } from 'components/Tabs/InnerTabs'
import useIsDemo from 'hooks/useIsDemo'
import { buildTranslate } from 'locales'
import React from 'react'
import { useOnboardingSlideshow } from './OnboardingSlideshow/hooks'
import SignupMessage from './SignupMessage'
import SmsKeywords from './SmsKeywords'

export enum Tabs {
  OnboardingSlideshowContent = 'onboarding_slideshow_content',
  OnboardingSlideshowStyle = 'onboarding_slideshow_style',
  SignupMessage = 'signup_message',
  SmsKeywords = 'sms_keywords',
}

const t = buildTranslate('cms')

const Signup = () => {
  const { tab: slideshowTab } = useOnboardingSlideshow()
  const isDemo = useIsDemo()
  const tabs: Array<TabT> = [
    slideshowTab,
    {
      key: Tabs.SignupMessage,
      component: <SignupMessage />,
      title: t('signup.signup_message.title'),
    },
  ]
  if (!isDemo) {
    tabs.push({
      key: Tabs.SmsKeywords,
      component: <SmsKeywords />,
      title: t('signup.sms_keywords.title'),
    })
  }

  return <InnerTabs tabs={tabs} />
}

export default Signup
