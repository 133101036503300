import { Fields } from 'models/ExclusiveDeal'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'

export const selectExclusiveDeals = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.ExclusiveDeal.all().toRefArray()
  }
)
