import Formsy from 'formsy-react'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'

import { Button, Kind } from '@thanx/uikit/button'
import { Modal } from '@thanx/uikit/modal'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'

import { EditContext } from 'scenes/Points/CreateEdit/EditContext'
import Length from 'scenes/Points/CreateEdit/Restrictions/Modal/Length'
import Maximum from 'scenes/Points/CreateEdit/Restrictions/Modal/Maximum'
import When from 'scenes/Points/CreateEdit/Restrictions/Modal/When'
import Where from 'scenes/Points/CreateEdit/Restrictions/Modal/Where'
import { selectExperience } from 'selectors/pointsExperience'

import type { Fields as PointsProduct } from 'models/PointsProduct'

type Props = {
  onClose: () => void
  isOpen: boolean
}

export interface FormT extends PointsProduct {
  online: boolean
  instore: boolean
}

const t = buildTranslate('points.create_edit.restrictions.modal')

const RestrictionsModal: React.FC<Props> = props => {
  const { onClose, isOpen } = props
  const [formTemplate, setFormTemplate] = useState<FormT | null>(null)
  const activeExperience = useSelector(selectExperience)
  const currency = activeExperience?.currency_name_plural || ''
  const { redeemTemplate, template, updateTemplate } = useContext(EditContext)
  const { type: redeem_type } = redeemTemplate || {}

  const onSave = () => {
    if (!isEmpty(formTemplate)) {
      const saveTemplate = omit(formTemplate, ['instore', 'online'])
      if (!formTemplate?.instore) {
        saveTemplate.redemption_venue = 'online'
      } else if (!formTemplate?.online) {
        saveTemplate.redemption_venue = 'instore'
      } else {
        saveTemplate.redemption_venue = 'all'
      }
      updateTemplate(saveTemplate)
    }
    onClose()
  }

  const showRedeemMaximum = Boolean(
    redeem_type &&
      (redeem_type.includes('percent') || redeem_type === 'manual/item')
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeType="small"
      size="auto"
      zIndex={1100}
    >
      <div className="fs-unmask p-xl w-100" style={{ maxWidth: '800px' }}>
        <Text.Header3 color="grey90" className="mb-xs mt-xs">
          {t('title')}
        </Text.Header3>
        <Text.BodyText3 color="grey70" className="mb-xl" tag="p">
          {t('subtitle', { currency })}
        </Text.BodyText3>
        <Formsy onChange={setFormTemplate}>
          <When currency={currency} formTemplate={formTemplate} />
          <Length />
          <Where />
          <Switch condition={showRedeemMaximum}>
            <Maximum value={template?.redeem_maximum || 0} />
          </Switch>
          <div className="d-flex justify-content-end mt-xl">
            <Button className="mr-s" kind={Kind.SECONDARY} onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button kind={Kind.PRIMARY} onClick={onSave}>
              {t('save')}
            </Button>
          </div>
        </Formsy>
      </div>
    </Modal>
  )
}

export default RestrictionsModal
