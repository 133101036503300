import { Fields } from 'models/Brand'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'

export const selectBrands = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.Brand.all().toRefArray()
  }
)
