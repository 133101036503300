import { defaultTheme, useStyletron } from '@thanx/uikit/theme'
import backgroundImageLarge from 'assets/images/banners/ABonetime_1336.png'
import backgroundImageSmall from 'assets/images/banners/ABonetime_928.png'
import useWindowSize from 'hooks/useWindowSize'
import React from 'react'
import { abOneTimeUrl } from 'utilities/urlUtils'

type PropsT = {
  className?: string
  onClose?: VoidFunction
}
const AutomatedReportInfo: React.FC<PropsT> = props => {
  const { className = 'mb-xxl', onClose } = props
  const [css] = useStyletron()
  const { isLarge } = useWindowSize()
  const src = isLarge ? backgroundImageLarge : backgroundImageSmall

  return (
    <div className={`d-flex relative ${className}`}>
      <a href={abOneTimeUrl} target="_blank" rel="noopener noreferrer">
        <img
          className={css({ height: 'auto', width: '100%' })}
          src={src}
          alt="New report promo"
        />
      </a>
      <button
        type="button"
        className={`position-absolute close px-xs py-xxs ${css({
          top: 0,
          right: 0,
          opacity: 1,
          fontSize: '28px',
        })}`}
        aria-label="Close"
        onClick={onClose}
      >
        <span
          className={css({
            color: defaultTheme.colors.grey50,
          })}
          aria-hidden="true"
        >
          &times;
        </span>
      </button>
    </div>
  )
}

export default AutomatedReportInfo
