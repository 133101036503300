import React from 'react'

type Props = {
  color: string
}

const ArrowUp: React.FC<Props> = props => {
  const { color } = props

  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.687362 5.76279L5.93381 0.250489C6.08996 0.0938898 6.27733 0.03125 6.49593 0.03125C6.6833 0.03125 6.87068 0.0938898 7.02682 0.250489L12.2733 5.76279C12.5543 6.07599 12.5543 6.54579 12.242 6.82767C11.961 7.10955 11.4613 7.10955 11.1803 6.79635L7.24542 2.6308V13.2796C7.24542 13.7181 6.90191 14.0312 6.49593 14.0312C6.12118 14.0312 5.74644 13.7181 5.74644 13.2796V2.6308L1.78037 6.79635C1.49931 7.10955 0.99965 7.10955 0.71859 6.82767C0.406302 6.54579 0.406302 6.04467 0.687362 5.76279Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowUp
