import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { captureRateReportUrl } from 'utilities/urlUtils'

type PropT = {
  isErrored?: boolean
  isLoading?: boolean
  hasNilValue?: boolean
  title: string
  subtitle: string
}

const t = buildTranslate('capture_rate')

const ValueName: React.FC<PropT> = ({
  isErrored,
  isLoading,
  hasNilValue,
  title,
  subtitle,
}) => {
  return (
    <>
      <Text.BodyText4 color="grey70" className="d-block">
        {title}
      </Text.BodyText4>
      {!isLoading &&
        (hasNilValue || isErrored ? (
          <>
            <Text.BodyText4 className="mr-xxs" color="cayenne50">
              {t('missing_data')}
            </Text.BodyText4>
            <a href={captureRateReportUrl} target="_blank" rel="noreferrer">
              {t('learn_more')}
            </a>
          </>
        ) : (
          <Text.BodyText4 color="grey70">{subtitle}</Text.BodyText4>
        ))}
    </>
  )
}

export default ValueName
