import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectApp } from 'selectors/app'
import Header from '../../components/Header'
import Asset from '../Asset'
import ImageAsset from '../ImageAsset'

const t = buildTranslate('cms.brand.app_store_assets')

const AndroidNotificationIcon: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const { android_notification_icon_image_urls: icon } = app
  const image = icon?.large

  return (
    <Section className="p-xl mt-l">
      <Header title={t('android_notification_icon.title')} />
      <Asset
        className="mt-s"
        description={t('android_notification_icon.description')}
        badges={[
          t('android_notification_icon.size'),
          t('android_notification_icon.png'),
        ]}
      >
        <ImageAsset image={image} darkBackground={!!image} />
      </Asset>
    </Section>
  )
}

export default AndroidNotificationIcon
