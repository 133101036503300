import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import React from 'react'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'

const t = buildTranslate('thanx_campaigns.report.v3.test')

type Props = {
  winningText: string
  startDate: string
  endDate: string
}
const TestEnded: React.FC<Props> = ({ endDate, startDate, winningText }) => {
  const { attributionWindow } = useAttributionWindow()
  return (
    <>
      <Text.BodyText4 color="primary" bold tag="div">
        {t('ended_title')}
      </Text.BodyText4>
      <Text.BodyText4 color="primary" tag="div">
        {t('ended_description', {
          start: startDate,
          end: endDate,
          count: attributionWindow,
        })}
      </Text.BodyText4>
      <Text.BodyText4 color="primary" tag="div">
        {winningText}
      </Text.BodyText4>
    </>
  )
}

export default TestEnded
