import InfoRow from 'components/InfoRow'
import Metric from 'components/Metric'
import PopoverContent from 'components/PopoverContent'
import { buildTranslate } from 'locales'
import { EmailMessageCountMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import { numberFormatter, percentageFormatter } from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type PropsT = {
  campaignId: number
}

const Unsubscribed: React.FC<PropsT> = props => {
  const { campaignId } = props
  const metric =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'email_message_count')
    ) as EmailMessageCountMetric) || {}
  return (
    <InfoRow
      title={t('engagement.email.unsubscribed')}
      primaryContent={
        <Metric
          metric={percentageFormatter(metric?.data?.unsubscribed_rate, 1, 1)}
        >
          <PopoverContent
            sections={[
              {
                content: t('engagement.email.tooltips.percent_of_unsubscribed'),
              },
              {
                title: t('how_its_calc'),
                content: t(
                  'engagement.email.tooltips.percent_unsubscribed_calc'
                ),
              },
            ]}
          />
        </Metric>
      }
      secondaryContent={
        <Metric
          primary={false}
          metric={numberFormatter(metric?.data?.unsubscribed_count)}
        >
          <PopoverContent
            sections={[
              {
                content: t('engagement.email.tooltips.number_of_unsubscribed'),
              },
              {
                title: t('how_its_calc'),
                content: t(
                  'engagement.email.tooltips.number_unsubscribed_calc'
                ),
              },
            ]}
          />
        </Metric>
      }
      isErrored={metric.isErrored}
      isLoading={metric.isLoading}
    />
  )
}

export default Unsubscribed
