import Checkbox from 'components/Form/Checkbox'
import React from 'react'
import { components } from 'react-select'

type Props = {
  label: string
  isSelected: boolean
  [key: string]: any
}

const CustomOption: React.FC<Props> = props => {
  const { label, isSelected } = props
  return (
    <components.Option {...props}>
      <Checkbox
        isChecked={isSelected}
        isDisabled={true}
        disabledClass=""
        labelClass="margin-none"
      />
      <span className="margin-left-small">{label}</span>
    </components.Option>
  )
}

export default CustomOption
