import DateFilterComponent from 'components/DateFilter'
import useFlag from 'hooks/useFlag'
import { Fields } from 'models/PointsExperience'
import {
  ActivityTimeSeriesMetric,
  ShortType,
} from 'models/PointsExperienceMetric'
import moment from 'moment-timezone'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectPointsExperienceMetricByType } from 'selectors/pointsExperienceMetric'
import { useUserTimeZone } from 'utilities/userTimeZone'

type Props = {
  pointsExperience: Fields
}

const DateFilter: React.FC<Props> = props => {
  const { pointsExperience } = props
  const showCustomDateFilter = useFlag(
    'points-enable-custom-date-reporting',
    false
  )
  const timeZone = useUserTimeZone()
  const activityTimeSeriesMetric = useSelector(state =>
    selectPointsExperienceMetricByType(
      state,
      ShortType.ACTIVITY_TIME_SERIES_ALL_TIME
    )
  ) as ActivityTimeSeriesMetric

  const allTimeStartDate = pointsExperience.activated_at
    ? moment.tz(pointsExperience.activated_at, timeZone)
    : null
  const allTimeEndDate = pointsExperience.archived_at
    ? moment.tz(pointsExperience.archived_at, timeZone)
    : moment.tz(timeZone)

  return (
    <DateFilterComponent
      showDateLabel
      showCustomFilter={showCustomDateFilter}
      allTimeStartDate={allTimeStartDate}
      allTimeEndDate={allTimeEndDate}
      isLoading={activityTimeSeriesMetric?.isLoading}
    />
  )
}

export default DateFilter
