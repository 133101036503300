import React from 'react'

const Error: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="title">loading error</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 10C0 4.47714 4.47714 0 10 0C15.5229 0 20 4.47714 20 10C20 15.5229 15.5229 20 10 20C4.47714 20 0 15.5229 0 10ZM18.0645 9.99994C18.0645 5.54232 14.4569 1.93542 10 1.93542C5.54238 1.93542 1.93549 5.54301 1.93549 9.99994C1.93549 14.4576 5.54307 18.0645 10 18.0645C14.4576 18.0645 18.0645 14.4569 18.0645 9.99994Z"
        fill="#F26335"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.53796 10.8329L8.23885 5.34899C8.22373 5.07181 8.44437 4.83875 8.72195 4.83875H11.2777C11.5553 4.83875 11.776 5.07181 11.7609 5.34899L11.4618 10.8329C11.4478 11.0895 11.2356 11.2904 10.9786 11.2904H9.02111C8.76413 11.2904 8.55195 11.0895 8.53796 10.8329ZM8.14502 13.871C8.14502 12.8466 8.97546 12.0162 9.99986 12.0162C11.0243 12.0162 11.8547 12.8466 11.8547 13.871C11.8547 14.8954 11.0243 15.7258 9.99986 15.7258C8.97546 15.7258 8.14502 14.8954 8.14502 13.871Z"
        fill="#F26335"
      />
    </svg>
  )
}

export default Error
