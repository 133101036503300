import * as actions from 'actions/emailProviders'
import { RESET_DB } from 'actions/orm'
import { attr, fk, Model, ModelType } from 'redux-orm'
import titleize from 'titleize'

export interface Fields {
  id: number
  merchant_id: number
  type: string
  state: string
}

export default class EmailProvider extends Model<typeof EmailProvider, Fields> {
  static modelName: string = 'EmailProvider'

  static get fields(): any {
    return {
      id: attr(),
      merchant_id: fk({
        to: 'Merchant',
        as: 'merchant',
        relatedName: 'emailProviders',
      }),
      type: attr(),
      state: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(action: any, EmailProvider: ModelType<EmailProvider>) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case actions.GET_ACTIVE_PROVIDER_SUCCESS:
        EmailProvider.upsert(action.payload.data.email_provider)
        break
      case actions.GET_PROVIDERS_SUCCESS:
        action.payload.data.email_providers.forEach(provider => {
          EmailProvider.upsert(provider)
        })
        break
      case RESET_DB:
        EmailProvider.all().delete()
        break
      default:
        break
    }
  }

  prettyType() {
    // @ts-ignore
    if (!this.ref.type) return ''

    // @ts-ignore
    return titleize(this.ref.type.replace('_', ' '))
  }
}
