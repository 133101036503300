import { RESET_DB } from 'actions/orm'
import { GET_PRODUCTS_SUCCESS, ResolvedAction } from 'actions/products'
import { attr, Model, ModelType } from 'redux-orm'

export interface Fields {
  chain_uid: string
  name: string
  price: number
  image: {
    default: string | null
  }
}

// @ts-ignore
export default class Product extends Model<typeof Product, Fields> {
  static modelName: string = 'Product'

  static options: Object = {
    idAttribute: 'chain_uid',
  }

  static get fields(): any {
    return {
      chain_uid: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Product: ModelType<Product>
  ): void {
    /* eslint-disable no-use-before-define */
    switch (action.type) {
      case GET_PRODUCTS_SUCCESS: {
        action.payload.data.products.forEach(product => {
          // fails because of different id attribute
          // @ts-ignore
          Product.upsert(product)
        })
        break
      }
      case RESET_DB:
        Product.all().delete()
        break
    }
  }
}
