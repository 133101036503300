import { FormsyCheckbox } from '@thanx/uikit/checkbox'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { locationsApi } from 'api/locations'
import { Role } from 'api/merchantUsers'
import Spinner from 'components/Spinner'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import { buildTranslate } from 'locales'
import React, { useEffect, useState } from 'react'

const t = buildTranslate('merchant_users.form.location')

type Props = {
  role: Role
  initialLocationIds?: number[]
  onChange: (selectedLocations: number[]) => void
}

const LocationList = ({ role, onChange, initialLocationIds = [] }: Props) => {
  const { data, isLoading } = locationsApi.useGetLocationsQuery({
    full_visibility: true,
  })

  const [selectedLocations, setSelectedLocations] =
    useState<number[]>(initialLocationIds)

  const merchant = useCurrentMerchant()

  const [css] = useStyletron()

  const actionClassNames = `mb-0 ${css({
    cursor: 'pointer',
    marginRight: '20px',
    ':hover': {
      textDecoration: 'underline',
    },
  })}`

  useEffect(() => {
    onChange(selectedLocations)
  }, [selectedLocations])

  const showLocations =
    merchant?.merchant_type !== 'Mall' &&
    (
      [
        Role.manager,
        Role.customer_service_read,
        Role.customer_service_write,
      ] as Role[]
    ).includes(role)

  return (
    <Switch condition={showLocations}>
      <Text.Header4>{t('title')}</Text.Header4>
      <Spinner isLoading={isLoading}>
        <div className={css({ margin: '20px 0' })}>
          <span
            onClick={() => {
              setSelectedLocations(data?.locations.map(({ id }) => id) || [])
            }}
          >
            <Text.BodyText3
              tag="span"
              className={actionClassNames}
              color="primary"
            >
              {t('select_all')}
            </Text.BodyText3>
          </span>
          <span
            onClick={() => {
              setSelectedLocations([])
            }}
          >
            <Text.BodyText3
              tag="span"
              className={actionClassNames}
              color="primary"
            >
              {t('deselect_all')}
            </Text.BodyText3>
          </span>
        </div>
        {data?.locations.map(location => (
          <FormsyCheckbox
            key={location.id}
            name={`location_${location.id}`}
            value={selectedLocations.includes(location.id)}
            className="flex"
            onChange={isSelected => {
              setSelectedLocations(prev => {
                if (isSelected) {
                  return [...prev, location.id]
                }
                return selectedLocations.filter(id => id !== location.id)
              })
            }}
          >
            <Text.Header4 className="mb-0">
              {location.name || location.street}
            </Text.Header4>
            <Text.BodyText4 color="grey70">{`${location.street}, ${location.city}, ${location.zip}, ${location.state}`}</Text.BodyText4>
          </FormsyCheckbox>
        ))}
      </Spinner>
    </Switch>
  )
}

export default LocationList
