import numeral from 'numeral'

export const textUtils = {
  initialCapital: function (text?: string) {
    if (text && text.length > 0) {
      return text.charAt(0).toUpperCase() + text.slice(1)
    } else {
      return text
    }
  },
  titleize: function (text: string) {
    return text.toLowerCase().replace(/(^| )(\w)/g, s => s.toUpperCase())
  },
  humanizeNumber: function (num: number, scale: number = 50000) {
    if (!num) return '~'
    if (num < Math.abs(scale)) return numeral(num).format('0,0')
    return numeral(num).format('0.[00]a')
  },
  humanizePercent: function (numerator: number, denominator: number) {
    if (!denominator || denominator === 0) return '~'
    let percentage = numerator / denominator
    return numeral(percentage).format('%')
  },
  articleize: function (phrase: string) {
    // largely copied from https://github.com/rigoneri/indefinite-article.js/blob/master/indefinite-article.js
    if (!phrase || !/[a-zA-Z]/.test(phrase[0])) return phrase
    // Getting the first word
    var match = /\w+/.exec(phrase)
    if (match) var word = match[0]
    else return 'an ' + phrase

    var l_word = word.toLowerCase()
    // Specific start of words that should be preceeded by 'an'
    var alt_cases = ['honest', 'hour', 'hono']
    for (let i in alt_cases) {
      if (l_word.indexOf(alt_cases[i]) === 0) return 'an ' + phrase
    }

    // Single letter word which should be preceeded by 'an'
    if (l_word.length === 1) {
      if ('aehilmnorsx'.indexOf(l_word) >= 0) return 'an ' + phrase
      else return 'a ' + phrase
    }

    // Capital words which should likely be preceeded by 'an'
    if (
      word.match(
        /(?!FJO|[HLMNS]Y.|RY[EO]|SQU|(F[LR]?|[HL]|MN?|N|RH?|S[CHKLMNPTVW]?|X(YL)?)[AEIOU])[FHLMNRSX][A-Z]/
      )
    ) {
      return 'an ' + phrase
    }

    // Special cases where a word that begins with a vowel should be preceeded by 'a'
    let regexes = [
      /^e[uw]/,
      /^onc?e\b/,
      /^uni([^nmd]|mo)/,
      /^u[bcfhjkqrst][aeiou]/,
    ]
    for (let i in regexes) {
      if (l_word.match(regexes[i])) return 'a ' + phrase
    }

    // Special capital words (UK, UN)
    if (word.match(/^U[NK][AIEO]/)) {
      return 'a ' + phrase
    } else if (word === word.toUpperCase()) {
      if ('aehilmnorsx'.indexOf(l_word[0]) >= 0) return 'an ' + phrase
      else return 'a ' + phrase
    }

    // Basic method of words that begin with a vowel being preceeded by 'an'
    if ('aeiou'.indexOf(l_word[0]) >= 0) return 'an ' + phrase

    // Instances where y follwed by specific letters is preceeded by 'an'
    if (l_word.match(/^y(b[lor]|cl[ea]|fere|gg|p[ios]|rou|tt)/))
      return 'an ' + phrase

    return 'a ' + phrase
  },
  toSentence: function ({
    array = [] as string[],
    separator = ', ',
    lastSeparator = ' and ',
    punctuation = '.',
  }) {
    if (!array || array.length === 0) return
    if (array.length === 1) return `${array[0]}${punctuation}`
    let arrayTemp: string[] = Object.assign([], array)
    let lastItem = arrayTemp.splice(-1)[0]
    if (arrayTemp.length > 1) arrayTemp = [arrayTemp.join(separator)]
    arrayTemp.push(lastItem)
    return `${arrayTemp.join(lastSeparator || separator)}${punctuation}`
  },
}
