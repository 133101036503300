import { useStyletron } from '@thanx/uikit/theme'
import Badge from 'components/Badge'
import { buildTranslate } from 'locales'
import { Fields as Card } from 'models/Card'
import React from 'react'
import { merchantTheme } from 'theme'

const t = buildTranslate('users.view.cards.badge')

type PropsT = {
  state: Card['state'] | Card['payment_state']
}

const StatusBadge: React.FC<PropsT> = ({ state }) => {
  const [css] = useStyletron()
  let color: string = ''
  let backgroundColor: string = ''
  let border: string = ''
  switch (state) {
    case 'unlinked':
    case 'payment_unlinked':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.grey20
      break
    case 'linked':
    case 'payment_linked':
      color = merchantTheme.colors.belizeHole50
      backgroundColor = merchantTheme.colors.belizeHole10
      break
    case 'expired':
    case 'payment_expired':
      color = merchantTheme.colors.eggYolk50
      backgroundColor = merchantTheme.colors.eggYolk10
      break
    case 'errored':
    case 'payment_errored':
      color = merchantTheme.colors.cayenne50
      backgroundColor = merchantTheme.colors.cayenne10
      break
    case 'archived':
    case 'payment_archived':
      color = merchantTheme.colors.grey70
      backgroundColor = merchantTheme.colors.white
      border = `1px solid ${merchantTheme.colors.grey20}`
      break
    default:
      break
  }

  const styles: any = {
    backgroundColor,
    color,
  }

  if (border) {
    styles.border = border
  }

  return <Badge value={t(state)} className={`ml-xs ${css(styles)}`} />
}

export default StatusBadge
