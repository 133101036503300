import { useStyletron } from '@thanx/uikit/theme'
import { getLocations } from 'actions/location'
import ProtectedPageHeader from 'components/ProtectedPageHeader'
import { LocationContextProvider } from 'contexts/LocationContext'
import useCurrentMerchant from 'hooks/useCurrentMerchant'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import RevenueTab from 'scenes/Reports/RevenueReport/RevenueTab'
import { selectCurrentMerchantLocations } from 'selectors/location'
import { selectCurrentMerchantUser } from 'selectors/merchantUser'

const t = buildTranslate('revenue')

const RevenueReport: React.FC = () => {
  const merchant = useCurrentMerchant()!
  const merchantUser = useSelector(selectCurrentMerchantUser)
  const merchantId = useSelector((state: any) => state.auth.current_merchant_id)
  const roles = merchantUser?.rolesForMerchantId(merchantId)
  const locations = useSelector(selectCurrentMerchantLocations)
  const dispatch = useDispatch()
  const [css] = useStyletron()

  useEffect(() => {
    dispatch(getLocations({ coming_soon: false }))
  }, [dispatch])

  if (isEmpty(locations)) return null

  return (
    <>
      <ProtectedPageHeader
        title={t('title')}
        description=""
        resource="LocationReports"
        action="access"
      >
        <LocationContextProvider
          initialLocation={roles?.includes('manager') ? locations[0] : null}
        >
          <div
            className={`grey-05-bkg border-top-1 pb-xxl grey-20-border ${css({
              minHeight: `calc(100% - 50px)`,
            })}`}
          >
            <RevenueTab merchant={merchant} />
          </div>
        </LocationContextProvider>
      </ProtectedPageHeader>
    </>
  )
}

export default RevenueReport
