import { Text } from '@thanx/uikit/text'
import lockIcon from 'assets/images/redeem-manager/icon_lock.svg'
import { buildTranslate } from 'locales'
import React from 'react'

const t = buildTranslate(
  'redeem_manager.builder.steps.configuration.restrictions'
)

const Restrictions: React.FC = () => {
  return (
    <div className="mt-xxl">
      <Text.BodyText3 bold color="grey90" tag="div" className="mb-s d-flex">
        <img src={lockIcon} className="mr-xxs" alt="lock" />
        {t('title')}
      </Text.BodyText3>
      <Text.BodyText4 color="grey70" tag="div" className="mb-s">
        {t('content1')}
      </Text.BodyText4>
      <Text.BodyText4 color="grey70" tag="div">
        {t('content2')}
      </Text.BodyText4>
    </div>
  )
}

export default Restrictions
