import Input from 'components/Form/Input'
import { buildTranslate } from 'locales'
import { CampaignConfig } from 'models/Campaign'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import RestrictionSection from './RestrictionSection'
import TimeAndDayPicker from './TimeAndDayPicker'

type Props = {
  config: CampaignConfig
  isSaving: boolean
}

const t = buildTranslate(
  'thanx_campaigns.builder.steps.incentive.restriction_creator'
)

const DayPart: React.FC<Props> = ({ config, isSaving }) => {
  return (
    <RestrictionSection
      toggleName="redeem_restriction_time_range"
      toggleValue={config.redeem_restriction_time_range}
      title={t('day_part.title')}
      subtitle={t('day_part.subtitle')}
    >
      <div className="mb-xl">
        <TimeAndDayPicker
          config={config}
          isSaving={isSaving}
          className="margin-top-small"
        />
      </div>
      <Input
        name="redeem_restriction_time_range_description"
        value={config.redeem_restriction_time_range_description || ''}
        label={t('day_part.description.label')}
        labelClassName="font-size-14"
        placeholder={t('day_part.description.placeholder')}
        trimValue={true}
        validations={{
          minLength: 5,
          maxLength: 47,
          isRequired: true,
          mustEndInPunctuation: false,
        }}
        validationErrors={{
          minLength: I18n.t('validations.min_length', { minLength: 5 }),
          maxLength: I18n.t('validations.max_length', {
            maxLength: 47,
          }),
          isRequired: I18n.t('validations.is_required'),
          mustEndInPunctuation: I18n.t(
            'validations.must_not_end_in_punctuation'
          ),
        }}
      />
    </RestrictionSection>
  )
}

export default DayPart
