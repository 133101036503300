import { CouponPoolFields, Fields, SourceType } from 'models/FileUpload'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectFileUploads = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => session.FileUpload.all().toRefArray()
)

export const selectUploadById = createSelector(
  selectFileUploads,
  (_, id): number => id,
  (uploads, id): Fields | null => uploads.find(u => u.id === id) ?? null
)

const selectCouponPoolUploads = createSelector(
  selectFileUploads,
  (uploads): CouponPoolFields[] =>
    uploads.filter(
      u => u.source_type === SourceType.COUPON_POOL
    ) as CouponPoolFields[]
)

export const selectUploadsByCouponPoolId = createSelector(
  (state: any) => selectCouponPoolUploads(state),
  (_: any, poolId: number): number => poolId,
  (uploads, poolId): CouponPoolFields[] =>
    uploads.filter(u => u.source_id === poolId)
)
