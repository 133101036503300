import { GET_MENUS_SUCCESS, ResolvedAction } from 'actions/menus'
import { RESET_DB } from 'actions/orm'
import { attr, fk, Model, ModelType } from 'redux-orm'

export interface Fields {
  id: number
  location_id: number
  name: string
  enabled: boolean
}

export default class Menu extends Model<
  // @ts-ignore
  typeof Menu,
  Fields
> {
  static modelName: string = 'Menu'

  static get fields(): any {
    return {
      id: attr(),
      location_id: fk({
        to: 'Location',
        as: 'location',
        relatedName: 'menus',
      }),
      name: attr(),
      enabled: attr(),
    }
  }

  /* eslint-disable no-use-before-define */
  static reducer(
    action: ResolvedAction | { type: typeof RESET_DB },
    // @ts-ignore
    Menu: ModelType<Menu>
  ) {
    /* eslint-enable no-use-before-define */
    switch (action.type) {
      case GET_MENUS_SUCCESS:
        action.payload.data.menus.forEach(menu => {
          return Menu.upsert(menu)
        })
        break
      case RESET_DB:
        Menu.all().delete()
        break
      default:
        break
    }
  }
}
