import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import { Fields as Campaign } from 'models/Campaign'
import { RedeemMetric } from 'models/CampaignMetric'
import { Fields as CampaignType } from 'models/CampaignType'
import { ReducedCampaign } from 'models/UserCampaign'
import moment from 'moment-timezone'
import store from 'store'
import earnTypeMapper from './earnTypeMapper'
import { textUtils } from './textUtils'

export type ReportLink = {
  href: string
  state: string
  type: string
}

export const campaignName = (name: string) => {
  if (!name) return null
  return textUtils.titleize(earnTypeMapper(name.toLowerCase()))
}

export const isCampaign = () => {
  return store.getState().routing.location.pathname.match('campaign')
}

export const getRewardsTotal = (
  campaigns: Campaign[],
  metrics: RedeemMetric[] | null
) => {
  return campaigns.reduce((sum, campaign) => {
    const stats = metrics?.find(m => m.campaign_id === campaign.id)
    const issuedCount = stats?.data?.issued_count || 0
    return sum + issuedCount
  }, 0)
}

export const getRedeemedTotal = (
  campaigns: Campaign[],
  metrics: RedeemMetric[] | null
) => {
  return campaigns.reduce((sum, campaign) => {
    const stats = metrics?.find(m => m.campaign_id === campaign.id)
    const campaignTotal = stats?.data?.count || 0

    return sum + campaignTotal
  }, 0)
}

export const redeemRestrictions = (campaign: Campaign) => {
  const restrictions = {
    redeem_restriction_monday: 'Monday',
    redeem_restriction_tuesday: 'Tuesday',
    redeem_restriction_wednesday: 'Wednesday',
    redeem_restriction_thursday: 'Thursday',
    redeem_restriction_friday: 'Friday',
    redeem_restriction_saturday: 'Saturday',
    redeem_restriction_sunday: 'Sunday',
  }

  let days: string[] = []
  let hours = []
  forEach(restrictions, (day, restriction) => {
    if (!isEmpty(campaign[restriction])) {
      days.push(day)
      hours = campaign[restriction]
    }
  })

  return {
    days: days,
    hours: hours,
  }
}

export const reportLink = (
  campaign: Campaign | ReducedCampaign,
  campaignTypeInstance: CampaignType,
  showV3 = false
): ReportLink => {
  let href: string
  // this is potentially holding some other strings
  // than CampaignState such as upcoming and unavailable.
  let state: string = campaign.state
  let type = 'linkcontainer'

  const { category: campaignCategory, type: campaignType } =
    campaignTypeInstance

  switch (state) {
    case 'active':
    case 'scheduled':
    case 'complete':
      if (showV3) {
        if (
          campaign.type !== 'custom_automated' &&
          moment(campaign.start_at) < moment('2022-01-25 00:00Z').utc()
        ) {
          href = ''
          state = 'unavailable'
        } else {
          href = `/thanx_campaigns/${campaignCategory}/${campaignType}/${campaign.id}/report/v3`
        }
      } else {
        href = ''
        state = 'unavailable'
      }
      break
    case 'in_review':
      href = `/thanx_campaigns/${campaignCategory}/${campaignType}/${campaign.id}`
      break
    default:
      href = `/thanx_campaigns/${campaignCategory}/${campaignType}/${campaign.id}`
  }

  return {
    href: href,
    state: state,
    type: type,
  }
}

export function hasRevenue(campaign: Campaign) {
  if (campaign.type === 'reputation') return false

  return (
    campaign.type !== 'message' ||
    campaign.config_target_member ||
    campaign.notify_email_conversion ||
    (campaign.redeem && campaign.config_signup_required)
  )
}

export function canCreateSignups(campaign: Campaign) {
  return (
    campaign.type === 'message' &&
    campaign.config_target_subscriber &&
    (campaign.notify_email_conversion || campaign.config_signup_required)
  )
}

export function emailIsClickable(campaign: Campaign) {
  return (
    (campaign.notify_email_button_url && campaign.notify_email_button_text) ||
    campaign.notify_email_hero_url ||
    campaign.notify_email_header_url ||
    campaign.notify_email_conversion
  )
}

export function canSetControl(campaign: Campaign) {
  if (campaign.type === 'message' && !campaign.config_target_member)
    return false
  return !isUndefined(campaign.config_control_percent)
}
