import { buildTranslate } from 'locales'
import { CampaignStateT } from 'models/Campaign'
import { MessageCountMetric, RevenueMetric } from 'models/CampaignMetric'
import React from 'react'
import { useSelector } from 'react-redux'
import { useAttributionWindow } from 'scenes/CampaignCenter/Report/v3/Attribution/AttributionWindowContext'
import Counter from 'scenes/CampaignCenter/Report/v3/Summary/components/Counter'
import { selectCampaignMetricByType } from 'selectors/campaignMetric'
import {
  currencyFormatter,
  numberFormatter,
  percentageFormatter,
} from 'utilities/formatters'

const t = buildTranslate('thanx_campaigns.report.v3.summary')

type Props = {
  campaignId: number
  campaignState: CampaignStateT
}
const Header: React.FC<Props> = ({ campaignId, campaignState }) => {
  const messageCount =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'message_count')
    ) as MessageCountMetric) || {}
  const revenueCount =
    (useSelector(state =>
      selectCampaignMetricByType(state, campaignId, 'revenue')
    ) as RevenueMetric) || {}
  const deliveredCount = numberFormatter(messageCount?.data?.delivered_count)
  const netRevenue = currencyFormatter(revenueCount?.data?.net_revenue, false)

  const { attributionWindow } = useAttributionWindow()

  return (
    <div className="d-flex mb-xl">
      <Counter
        primaryValue={deliveredCount}
        name={t('counter_metrics.delivered')}
        sections={[
          {
            content: t('number_of_delivered'),
          },
          {
            title: t('how_its_calc'),
            content: t('how_delivered'),
          },
          {
            title: t('example'),
            content: t('example_delivered_content1'),
          },
          {
            content: t('example_delivered_content2'),
          },
          {
            content: t('example_delivered_content3'),
          },
        ]}
        className="mr-xxl"
        campaignId={campaignId}
        metricTypes={['message_count']}
        campaignState={campaignState}
        isErrored={messageCount.isErrored}
        isLoading={messageCount.isLoading}
      />
      <Counter
        primaryValue={percentageFormatter(
          revenueCount?.data?.purchases_rate,
          1,
          1
        )}
        secondaryValue={numberFormatter(revenueCount?.data?.count)}
        name={t('counter_metrics.purchases')}
        sections={[
          {
            content: t('revenue.tooltips.number_purchases', {
              count: attributionWindow,
            }),
          },
          {
            title: t('how_its_calc'),
            content: t('revenue.tooltips.number_purchases_calc', {
              count: attributionWindow,
            }),
          },
          {
            title: t('example'),
            content: t('revenue.tooltips.number_purchases_example_content1', {
              days: attributionWindow,
            }),
          },
          {
            content: t('revenue.tooltips.number_purchases_example_content2', {
              days: attributionWindow,
            }),
          },
          {
            content: t('revenue.tooltips.number_purchases_example_content3', {
              days: attributionWindow,
            }),
          },
        ]}
        className="mr-xxl"
        campaignId={campaignId}
        campaignState={campaignState}
        isErrored={revenueCount.isErrored}
        isLoading={revenueCount.isLoading}
      />
      <Counter
        primaryValue={netRevenue}
        name={t('counter_metrics.revenue')}
        sections={[
          {
            content: t('revenue.tooltips.number_of_revenue', {
              count: attributionWindow,
            }),
          },
          {
            title: t('how_its_calc'),
            content: t('revenue.tooltips.calc_of_number_revenue'),
          },
          {
            title: t('example'),
            content: t('revenue.tooltips.number_revenue_example_content1'),
          },
          {
            content: t('revenue.tooltips.number_revenue_example_content2'),
          },
          {
            content: t('revenue.tooltips.number_revenue_example_content3'),
          },
        ]}
        className="mr-xxl"
        campaignId={campaignId}
        campaignState={campaignState}
        isErrored={revenueCount.isErrored}
        isLoading={revenueCount.isLoading}
      />
    </div>
  )
}

export default Header
