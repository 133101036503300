import { Fields } from 'models/ExclusiveDeal'
import { AxiosAction } from 'redux-axios-middleware'
import { ResolvedAxiosAction } from 'utilities/types'

export const GET_EXCLUSIVE_DEALS = 'exclusiveDeal/GET_EXCLUSIVE_DEALS'
export const GET_EXCLUSIVE_DEALS_SUCCESS =
  'exclusiveDeal/GET_EXCLUSIVE_DEALS_SUCCESS'
export const GET_EXCLUSIVE_DEALS_FAIL = 'exclusiveDeal/GET_EXCLUSIVE_DEALS_FAIL'

export function getExclusiveDeals(): AxiosAction<
  typeof GET_EXCLUSIVE_DEALS,
  { exclusive_deals: Fields[] }
> {
  return {
    type: GET_EXCLUSIVE_DEALS,
    payload: {
      client: 'default',
      request: {
        url: 'exclusive_deals',
        method: 'get',
      },
    },
  }
}

export type ResolvedAction = ResolvedAxiosAction<
  ReturnType<typeof getExclusiveDeals>
>
