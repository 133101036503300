import { Button } from '@thanx/uikit/button'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import { createCampaign } from 'actions/campaigns'
import { alert } from 'actions/flash'
import withMerchantMetrics, {
  MetricsProps,
} from 'components/metrics/withMerchantMetrics'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import type { Fields as TargetSegment } from 'models/TargetSegment'
import React, { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { push } from 'react-router-redux'
import NewSegmentCampaignModal from 'scenes/CampaignCenter/Landing/NewSegmentCampaignModal'
import RequestSegmentModal from 'scenes/CampaignCenter/Landing/RequestSegmentModal'
import Content from './Content'

const t = buildTranslate('segments.landing')

const Landing: React.FC<MetricsProps> = ({
  merchantMetrics = {},
  merchantMetricStatuses = {},
}) => {
  const [css] = useStyletron()
  const dispatch = useDispatch()
  const [segment, setSegment] = useState<TargetSegment | null>(null)
  const openRequestSegmentModal = () => setRequestSegmentModalOpen(true)

  const [requestSegmentModalOpen, setRequestSegmentModalOpen] = useState(false)
  const [showNewSegmentCampaignModal, setShowNewSegmentCampaignModal] =
    useState(false)

  const openNewSegmentCampaignModal = (segment: TargetSegment) => {
    setSegment(segment)
    setShowNewSegmentCampaignModal(true)
  }
  const closeNewSegmentCampaignModal = () => {
    setSegment(null)
    setShowNewSegmentCampaignModal(false)
  }

  const onCreate = async (
    segment: TargetSegment,
    type: 'targeted' | 'automated'
  ) => {
    const params = {
      campaign: {
        type: type === 'targeted' ? 'custom' : 'custom_automated',
        target_segment_id: segment.id,
      },
    }

    const action = await dispatch(createCampaign(params))
    if (action.error) {
      dispatch(
        alert({
          key: 'danger',
          message: t('new_segment_campaign_modal.errors.submit'),
        })
      )
      return
    }

    const campaignId = action.payload?.data?.campaign?.id
    if (campaignId) {
      const path =
        type === 'targeted'
          ? `/thanx_campaigns/targeted/custom/${campaignId}`
          : `/thanx_campaigns/automated/custom_automated/${campaignId}`
      dispatch(push(path))
    } else {
      dispatch(
        alert({
          key: 'danger',
          message: t('new_segment_campaign_modal.errors.submit'),
        })
      )
    }
  }

  return (
    <>
      <div className="w-100 h-100 pt-m pb-xxl px-0 white-bkg border-bottom-1 grey-20-border">
        <Container className="pt-m pb-xl">
          <div className="d-flex justify-content-between">
            <Text.Header2 className="m-0 mr-xl">{t('title')}</Text.Header2>
            <div>
              <Button onClick={openRequestSegmentModal}>
                {t('create_new_segment')}
              </Button>
            </div>
          </div>
        </Container>
        <Helmet>
          <title>{t('page_title')}</title>
        </Helmet>
        <div
          className={`grey-05-bkg border-top-1 pb-xxl grey-20-border pt-xl ${css(
            {
              minHeight: 'calc(100vh - 80px)',
            }
          )}`}
        >
          <Container>
            <Content
              onExistingClick={openNewSegmentCampaignModal}
              merchantMetrics={merchantMetrics}
              merchantMetricStatuses={merchantMetricStatuses}
            />
          </Container>
        </div>
      </div>

      <RequestSegmentModal
        title={t('modal.request_segment.title')}
        subtitle={t('modal.request_segment.subtitle')}
        show={requestSegmentModalOpen}
        onModalClose={() => setRequestSegmentModalOpen(false)}
      />

      <NewSegmentCampaignModal
        isOpen={showNewSegmentCampaignModal}
        onClose={closeNewSegmentCampaignModal}
        segment={segment}
        onCreate={onCreate}
      />
    </>
  )
}

export default withMerchantMetrics(['user_count'])(Landing)
