import { pointsApi, PointsMultiplierPayload } from 'api/points'
import { isError } from 'api/utils'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import Helmet from 'react-helmet'
import Form from './Form'
import { useDisplayError } from './hooks'

const t = buildTranslate('points.multipliers.new_multiplier')

type Props = { pointsExperience: PointsExperience }

const NewMultiplier = ({ pointsExperience }: Props) => {
  const displayError = useDisplayError()

  const [createMultiplier, { isLoading }] =
    pointsApi.useCreateMultiplierMutation()

  const multiplier: PointsMultiplierPayload = {
    points_experience_id: pointsExperience.id,
  }

  const handleSubmit = async (
    payload: PointsMultiplierPayload
  ): Promise<Boolean> => {
    const response = await createMultiplier(payload)
    displayError(response, t('error_generic'))
    return !isError(response)
  }

  return (
    <>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <Form
        multiplier={multiplier}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        confirmTitle={t('confirmation.title')}
        confirmSubtitle={t('confirmation.subtitle')}
        confirmProceedText={t('confirmation.proceed_text')}
      />
    </>
  )
}

export default NewMultiplier
