import orm from 'models/orm'
import { Fields } from 'models/UserItem'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

const selectUsersItems = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.UserItem.all().toRefArray() || []
  }
)

export const selectUserItems = createSelector(
  (state: any) => selectUsersItems(state),
  (_: any, id: number): number => id,
  (usersItems: Fields[]): Fields[] => {
    return usersItems || []
  }
)
