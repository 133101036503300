import { Switch } from '@thanx/uikit/switch'
import { UserFunnelData } from 'models/MerchantMetric'
import React from 'react'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { numberFormatter } from 'utilities/formatters'

type Props = {
  data: UserFunnelData
}

const TotalCustomers: React.FC<Props> = ({ data }) => {
  const { subscriber_count, total_member_count } = data

  const popover = (
    <Tooltip id="total_customers_tooltip">
      <Translate value="reports.activity.sections.customers.total_customers_popover" />
    </Tooltip>
  )

  return (
    <div className="container">
      <Row>
        <Col md={5} className="d-flex flex-column justify-content-start">
          <div className="small-caps-5 margin-bottom-large">
            <span>
              <Translate value="reports.activity.sections.customers.metrics.funnel.title" />
            </span>
          </div>
          <div className="margin-bottom-large">
            <div className="hero-number margin-bottom-micro">
              {numberFormatter(total_member_count)}
            </div>
            <span className="grey-70">
              <Translate value="reports.activity.sections.customers.metrics.funnel.summary_text" />
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="right"
                overlay={popover}
              >
                <i className="fa fa-question-circle grey-50 font-size-14 padding-left-small" />
              </OverlayTrigger>
              <span className="pull-right">
                <Switch condition={!!subscriber_count && subscriber_count > 0}>
                  <a href="/subscribers/setup/connect">
                    <Translate
                      value={
                        'reports.activity.sections.customers.metrics.funnel.add_more'
                      }
                    />
                  </a>
                </Switch>
              </span>
            </span>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TotalCustomers
