import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import noIcon from 'assets/images/points/img_noIcon.svg'
import React from 'react'
import { numberFormatter } from 'utilities/formatters'

type Props = {
  cost: number
  image?: string
}

const CostBadge: React.FC<Props> = props => {
  const { cost, image = noIcon } = props
  const [css] = useStyletron()

  const displayCost = numberFormatter(cost)
  return (
    <div
      className={`pl-s pr-xs ${css({
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '100px',
        border: '2px solid #36424E',
        minWidth: '94px',
        height: '40px',
      })}`}
    >
      <img src={image} alt="currency" width={24} />
      <Text.Header4
        className={css({
          padding: 0,
          margin: '0 6px',
        })}
      >
        {displayCost}
      </Text.Header4>
    </div>
  )
}

export default CostBadge
