export default {
  title: 'Revenue',
  tabs: {
    purchases: { title: 'Purchases' },
    badge: 'Coming soon',
    revenue: {
      title: 'Revenue',
      stats: {
        revenue: 'Net revenue attributed to loyalty members (%{month})',
      },
      table: {
        date_time: 'Month',
        member_purchases_instore_amount: 'In-store (loyalty)',
        member_purchases_total_amount: 'Total',
        member_purchases_digital_amount: 'Digital (loyalty)',
      },
      popovers: {
        example: 'Example',
        how_its_calculated: 'How it’s calculated',
        revenue_summary_popover: {
          section_1:
            'Revenue across all channels that was attributed to loyalty members.',
          section_2: 'This revenue is for the latest complete calendar month.',
          section_3: 'Thanx counted that loyalty members spent $3,000 in July.',
        },
        revenue_by_channel: {
          in_store: {
            percent: {
              section_1:
                'The percentage of your revenue attributed to in-store purchases made by loyalty members.',
              section_2:
                'In-store revenue counted by Thanx / Total revenue counted by Thanx.',
              section_3:
                '$10,000 was spent by all loyalty members across digital and in-store channels. $5,000 of that revenue came from in-store purchases made by loyalty members. That’s 50% of your revenue for the month.',
            },
            amount: {
              section_1:
                'Revenue attributed to in-store purchases made by loyalty members.',
              section_2: 'In-store revenue counted by Thanx.',
              section_3:
                '$5,000 of this month’s revenue came from in-store purchases made by loyalty members.',
            },
          },
          digital: {
            percent: {
              section_1:
                'The percentage of your revenue attributed to digital purchases (i.e., app and web) made by loyalty members.',
              section_2:
                'Digital revenue counted by Thanx / Total revenue counted by Thanx.',
              section_3:
                '$10,000 was spent by all loyalty members across digital and in-store channels. $5,000 of that revenue came from digital purchases made by loyalty members. That’s 50% of your revenue for the month.',
            },
            amount: {
              section_1:
                'Revenue attributed to digital purchases (i.e., app and web) made by loyalty members.',
              section_2: 'Digital revenue counted by Thanx.',
              section_3:
                '$5,000 of this month’s revenue came from digital purchases made by loyalty members.',
            },
          },
          total: {
            section_1:
              'Revenue across all channels that was attributed to loyalty members this month.',
            section_2: 'Total revenue counted by Thanx.',
            section_3:
              'Thanx counted that loyalty members spent $10,000 in July.',
          },
        },
      },
    },
  },
}
