import thanxStars from 'assets/images/campaign-center/ic_twinkle.png'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { I18n } from 'react-redux-i18n'

type Props = {
  title: string
  subtitle: string
}

const InfoBox: React.FC<Props> = ({ title, subtitle }) => (
  <Row className="spearmint-50-bkg margin-top margin-bottom padding-huge border-radius-5">
    <Col md={1}>
      <img
        className="img-responsive margin-top-small margin-bottom-large"
        src={thanxStars}
        alt={I18n.t('thanx_campaigns.report.info.stars')}
      />
    </Col>
    <Col md={11}>
      <div className="deprecated__small-caps-3 white margin-bottom-small">
        {title}
      </div>
      <div className="font-size-24 white">{subtitle}</div>
    </Col>
  </Row>
)

export default InfoBox
