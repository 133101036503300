import InnerTabs from 'components/Tabs/InnerTabs'
import TabPane from 'components/Tabs/TabPane'
import { buildTranslate } from 'locales'
import { Fields as PointsExperience } from 'models/PointsExperience'
import React from 'react'
import { Col, Row } from 'react-bootstrap-five'
import { useSelector } from 'react-redux'
import { selectExperience } from 'selectors/pointsExperience'
import { useAbility } from 'utilities/ability'
import Appearance from './Appearance'
import Collect from './Collect'
import Expiration from './Expiration'
import Instructions from './Instructions'
import Multipliers from './Multipliers'

export enum Tabs {
  COLLECT = 'collect',
  EXPIRATION = 'expiration',
  MULTIPLIERS = 'multipliers',
  INSTRUCTIONS = 'instructions',
  APPEARANCE = 'appearance',
}

const t = buildTranslate('points.configuration')

const Configuration: React.FC = () => {
  const pointsExperience: PointsExperience | null =
    useSelector(selectExperience)
  const ability = useAbility()
  const canEdit = ability.can('manage', 'Points')

  if (!pointsExperience) return null

  const tabs = [
    {
      key: 'collect',
      title: t('collect.title'),
      component: (
        <Collect canEdit={canEdit} pointsExperience={pointsExperience} />
      ),
    },
    {
      key: 'expiration',
      title: t('expiration.title'),
      component: (
        <Expiration canEdit={canEdit} pointsExperience={pointsExperience} />
      ),
    },
    {
      key: 'instructions',
      title: t('instructions.title'),
      component: (
        <Instructions canEdit={canEdit} pointsExperience={pointsExperience} />
      ),
    },
    {
      key: 'appearance',
      title: t('appearance.title'),
      component: (
        <Appearance canEdit={canEdit} pointsExperience={pointsExperience} />
      ),
    },
    {
      key: 'multipliers',
      title: t('multipliers.title'),
      component: (
        <Multipliers canEdit={canEdit} pointsExperience={pointsExperience} />
      ),
    },
  ]

  return (
    <TabPane state="configuration">
      <Row>
        <Col>
          <h3 className="mt-0 mb-m mr-2 inline-block">{t('title')}</h3>
          <InnerTabs
            tabs={tabs.map(tab => ({
              key: tab.key,
              title: tab.title,
              component: tab.component,
            }))}
          />
        </Col>
      </Row>
    </TabPane>
  )
}

export default Configuration
