import { Button, Size } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { useStyletron } from '@thanx/uikit/theme'
import plus from 'assets/images/cms/plus.png'
import { AnimateSharedLayout } from 'framer-motion'
import { buildTranslate } from 'locales'
import React from 'react'
import Item from './Item'

type Props = {
  type: string
  order: string[]
  setOrder: (order: string[]) => void
  renderItem: (itemId: string, index: number) => React.ReactNode
  minBlockCount?: number
  maxBlockCount?: number
  onNewBlock: () => void
}

const t = buildTranslate('cms.components.blocks')

const Blocks: React.FC<Props> = props => {
  const {
    type,
    renderItem,
    order,
    setOrder,
    minBlockCount = 0,
    maxBlockCount = Number.MAX_SAFE_INTEGER,
    onNewBlock,
  } = props
  const blockCount = order.length
  const [css] = useStyletron()

  return (
    <AnimateSharedLayout>
      <div className="mb-m">
        {order.map((id, index) => (
          <Item
            key={id}
            index={index}
            blockCount={blockCount}
            type={type}
            canDelete={blockCount > minBlockCount}
            onDelete={() => {
              setOrder(order.filter(o => o !== id))
            }}
            onSort={newPosition => {
              const newOrder = [...order]
              newOrder.splice(index, 1)
              newOrder.splice(newPosition, 0, id)
              setOrder(newOrder)
            }}
          >
            {renderItem(id, index)}
          </Item>
        ))}
        <Switch condition={blockCount < maxBlockCount}>
          <Button
            kind="secondary"
            size={Size.STANDARD}
            onClick={onNewBlock}
            className={'w-100'}
          >
            {t('add_new', {
              type,
            })}
            <img
              alt={t('next_slide_alt')}
              className={`ml-xs ${css({
                height: '16px',
              })}`}
              src={plus}
            />
          </Button>
        </Switch>
      </div>
    </AnimateSharedLayout>
  )
}

export default Blocks
