import { useStyletron } from '@thanx/uikit/theme'
import Status from 'components/Status'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import { merchantTheme } from 'theme'
import { useManageSeries } from './helpers'

type PropsT = {
  chartType: 'column' | 'line'
  categories: string[]
  isLoading?: boolean
  isErrored?: boolean
  tooltipFormatter?: Highcharts.TooltipFormatterCallbackFunction
  plotOptions?: Highcharts.PlotOptions
  leftAxisFormatter?: Highcharts.AxisLabelsFormatterCallbackFunction
  series?: Highcharts.SeriesOptionsType[]
  xAxisTitle?: string
  yAxisTitle?: string
  caption?: Highcharts.CaptionOptions
}

const GenericChart: React.FC<PropsT> = ({
  chartType,
  categories,
  isErrored,
  isLoading,
  tooltipFormatter,
  plotOptions = {},
  leftAxisFormatter,
  series,
  xAxisTitle,
  yAxisTitle,
  caption,
}) => {
  const [css] = useStyletron()
  const { chartRef } = useManageSeries(isLoading, series)

  // Use a box instead of a line for the legend
  // @ts-ignore
  Highcharts.seriesTypes.line.prototype.drawLegendSymbol =
    // @ts-ignore
    Highcharts.seriesTypes.area.prototype.drawLegendSymbol
  const options: Highcharts.Options = {
    chart: {
      type: chartType,
      style: {
        fontFamily: 'Lato',
        overflow: 'visible !important',
      },
    },
    title: undefined,
    credits: { enabled: false },
    tooltip: {
      formatter: tooltipFormatter,
      backgroundColor: merchantTheme.colors.white,
      borderWidth: 1,
      borderColor: merchantTheme.colors.grey20,
      borderRadius: 4,
      outside: true,
      useHTML: true,
      shadow: false,
      padding: 16,
    },
    plotOptions,
    xAxis: {
      categories,
      title: {
        text: xAxisTitle,
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: merchantTheme.colors.grey90,
          fontSize: '14px',
        },
      },
      labels: {
        style: {
          color: merchantTheme.colors.grey70,
          fontSize: '12px',
        },
      },
      tickLength: 0,
      lineColor: merchantTheme.colors.grey30,
    },
    yAxis: {
      labels: {
        formatter: leftAxisFormatter,
        style: {
          color: merchantTheme.colors.grey70,
          fontSize: '12px',
        },
      },
      reversedStacks: false,
      allowDecimals: false,
      min: 0,
      softMax: 1,
      title: {
        text: yAxisTitle,
        margin: 25,
        style: {
          fontWeight: 'bold',
          color: merchantTheme.colors.grey90,
          fontSize: '14px',
        },
      },
      gridLineDashStyle: 'LongDash',
      gridLineColor: merchantTheme.colors.grey30,
    },
    series,
    legend: {
      align: 'left',
      symbolHeight: 12,
      symbolRadius: 0,
      x: 72,
      itemStyle: {
        color: merchantTheme.colors.grey90,
        fontSize: '14px',
      },
      itemDistance: 48,
    },
    caption,
  }
  let status
  if (isLoading) status = 'loading'
  if (isErrored) status = 'error'
  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <HighchartsReact
          ref={chartRef}
          highcharts={Highcharts}
          options={options}
          containerProps={{ className: 'w-100' }}
        />
        <Status
          status={status}
          className={`absolute ml-xxl mr-s ${css({ marginTop: '-115px' })}`}
        />
      </div>
    </>
  )
}

export default GenericChart
