export const GET_ACTIVE_PROVIDER = 'emails/GET_ACTIVE_PROVIDER'
export const GET_ACTIVE_PROVIDER_SUCCESS = 'emails/GET_ACTIVE_PROVIDER_SUCCESS'
export const GET_ACTIVE_PROVIDER_FAIL = 'emails/GET_ACTIVE_PROVIDER_FAIL'

export const GET_PROVIDER_LISTS = 'emails/GET_PROVIDER_LISTS'
export const GET_PROVIDER_LISTS_SUCCESS = 'emails/GET_PROVIDER_LISTS_SUCCESS'
export const GET_PROVIDER_LISTS_FAIL = 'emails/GET_PROVIDER_LISTS_FAIL'

export const CREATE_PROVIDER = 'emails/CREATE_PROVIDER'
export const CREATE_PROVIDER_SUCCESS = 'emails/CREATE_PROVIDER_SUCCESS'
export const CREATE_PROVIDER_FAIL = 'emails/CREATE_PROVIDER_FAIL'

export const UPDATE_PROVIDER = 'emails/UPDATE_PROVIDER'
export const UPDATE_PROVIDER_SUCCESS = 'emails/UPDATE_PROVIDER_SUCCESS'
export const UPDATE_PROVIDER_FAIL = 'emails/UPDATE_PROVIDER_FAIL'

export const GET_PROVIDERS = 'emails/GET_PROVIDERS'
export const GET_PROVIDERS_SUCCESS = 'emails/GET_PROVIDERS_SUCCESS'
export const GET_PROVIDERS_FAIL = 'emails/GET_PROVIDERS_FAIL'

export function getProviderLists(id: number) {
  return {
    type: GET_PROVIDER_LISTS,
    payload: {
      client: 'default',
      request: {
        url: `email_providers/${id}/lists`,
      },
    },
  }
}

export function getActiveProvider(providerType: string) {
  return {
    type: GET_ACTIVE_PROVIDER,
    payload: {
      client: 'default',
      request: {
        url: `email_providers/${providerType}`,
      },
    },
  }
}

export function createProvider(providerType: string, code?: any) {
  return {
    type: CREATE_PROVIDER,
    payload: {
      client: 'default',
      request: {
        method: 'post',
        url: `email_providers`,
        data: {
          email_provider: {
            type: providerType,
          },
          oauth_code: code,
        },
      },
    },
  }
}

export const updateProvider = (id: number, params: any) => {
  return {
    type: UPDATE_PROVIDER,
    payload: {
      client: 'default',
      request: {
        url: `email_providers/${id}`,
        method: 'patch',
        data: {
          email_provider: params,
        },
      },
    },
  }
}

export const getProviders = () => {
  return {
    type: GET_PROVIDERS,
    payload: {
      client: 'default',
      request: {
        url: 'email_providers',
        method: 'get',
      },
    },
  }
}
