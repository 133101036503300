import { Switch } from '@thanx/uikit/switch'
import Cell from 'components/Cell'
import { buildTranslate } from 'locales'
import { Fields as Link } from 'models/Link'
import React from 'react'
import { getDisplayedUrl } from '../../helper'

type Props = {
  link: Link
  showAuthenticable: boolean
}

const t = buildTranslate('cms.components.links')

const Item: React.FC<Props> = ({ link, showAuthenticable }) => {
  const { title, type, url, authenticable } = link

  return (
    <tr className="border-bottom-1 grey-30-border">
      <Cell className="py-m">{title}</Cell>
      <Cell className="py-m">{type}</Cell>
      <Switch condition={showAuthenticable}>
        <Cell className="py-m text-break">
          {authenticable ? t('yes') : t('no')}
        </Cell>
      </Switch>
      <Cell className="py-m text-break">
        {getDisplayedUrl(url, authenticable)}
      </Cell>
    </tr>
  )
}

export default Item
