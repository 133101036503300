import { alert } from 'actions/flash'
import React from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import ContentWithSave from 'scenes/CampaignCenter/Builder/components/ContentWithSave'
import { useFormManagement } from 'scenes/CampaignCenter/Builder/components/FormManagementContext'

type Props = {
  onClick?: () => void
  isSavingOverride?: boolean
  buttonLocale?: string
}

/**
 * Continue button for campaign builder flows.
 * Shows form validation errors on click.
 */
const ContinueButton: React.FC<Props> = props => {
  const {
    buttonLocale = 'thanx_campaigns.builder.steps.nav.buttons.review',
    onClick,
    isSavingOverride = false,
  } = props
  const { canProceed, isSaving, showValidationErrors, triggerSave } =
    useFormManagement()

  const dispatch = useDispatch()

  async function onClickHandler() {
    if (!canProceed) {
      dispatch(
        alert({
          key: 'danger',
          message: I18n.t('thanx_campaigns.errors.validation_errors'),
        })
      )
      showValidationErrors()
      return
    }

    await triggerSave()

    if (onClick) {
      onClick()
    }
  }

  return (
    <Button
      bsSize="large"
      className="btn-inverted"
      disabled={isSaving || isSavingOverride}
      onClick={onClickHandler}
    >
      <ContentWithSave
        value={buttonLocale}
        isSaving={isSaving || isSavingOverride}
      />
    </Button>
  )
}

export default ContinueButton
