import { defaultTheme } from '@thanx/uikit/theme'
import { DateRangeFilter, getDateFilter } from 'components/DateFilter/utilities'
import palette from 'constants/palette'
import Highcharts from 'highcharts'
import { Fields as Campaign } from 'models/Campaign'
import { EventRanges, EventsMetric, Type } from 'models/CampaignMetric'
import moment from 'moment-timezone'
import { getCampaignReportingBounds } from 'scenes/CampaignCenter/Report/v3/helpers'
import { getFormattedDate } from 'utilities/date'

export type OptionsType = {
  label: string
  value: EventRanges
}

export type CategoryType = {
  date: moment.Moment
  xAxis: string
}

export const getDateFormat = granularity => {
  switch (granularity) {
    case 'monthly':
      return 'MMM YYYY'
    default:
      return 'MMM D'
  }
}

export const getDate = (date, timezone, granularity) => {
  let refDate = moment.tz(date, timezone)
  switch (granularity) {
    case 'monthly': {
      refDate.startOf('month')
      break
    }
    case 'weekly': {
      const dayINeed = 1 // 1 for Monday
      refDate.isoWeekday(dayINeed)
      break
    }
  }
  return refDate.format(getDateFormat(granularity))
}

export const getData = (values, categories) => {
  if (!values || !categories) {
    return []
  }

  const valueObj = {}
  values.forEach(value => {
    valueObj[value.date_time] = value.count
  })

  return categories.map(category => valueObj[category] || null)
}

export const getSeries = (metric: EventsMetric, t, categories: string[]) => {
  const values = metric.data?.[0]?.values
  const dataCategories = categories || []
  const data: Highcharts.SeriesColumnOptions = {
    name: t(metric.type),
    type: 'column',
    data: metric.isLoading
      ? dataCategories.map(_ => 0)
      : getData(values, categories),
    id: metric.type,
  }
  data.visible = metric.type === Type.EVENTS_PURCHASE
  switch (metric.type) {
    case Type.EVENTS_PURCHASE:
      data.color = palette.spearmint50
      break
    case Type.EVENTS_REDEEM:
      data.color = palette.wisteria
      break
    case Type.EVENTS_MESSAGE:
      data.color = palette.grey70
      break
    default:
      data.color = palette.grey30
  }
  return data
}

export const getInitialDateFilter = (
  campaign: Campaign,
  timezone: string,
  attributionWindow: number
): DateRangeFilter | null => {
  const { type } = campaign
  if (type === 'custom_automated') return null

  const { startDate, endDate } = getCampaignReportingBounds(
    campaign,
    timezone,
    'summary',
    attributionWindow
  )

  return getDateFilter(
    'all_time',
    timezone,
    startDate.format('YYYY-MM-DD'),
    endDate.format('YYYY-MM-DD')
  )
}

export const createPlotlines = (
  categories,
  startAt,
  endAt,
  timezone,
  granularity,
  t
) => {
  const plotLines: Highcharts.XAxisPlotLinesOptions[] = []
  const addPlotLine = (date, symbol, text) => {
    const index = categories.findIndex(
      cat => getFormattedDate(moment.tz(cat, timezone), granularity) === date
    )
    if (index > -1) {
      plotLines.push({
        color: defaultTheme.colors.grey40,
        width: 1,
        value: index + 0.5 * symbol,
        label: {
          rotation: 0,
          text,
          style: {
            color: defaultTheme.colors.grey70,
            fontSize: '12px',
          },
        },
      })
    }
  }
  const startDate = getFormattedDate(moment.tz(startAt, timezone), granularity)
  const endDate = getFormattedDate(moment.tz(endAt, timezone), granularity)
  addPlotLine(startDate, -1, t('test_start'))
  addPlotLine(endDate, 1, t('test_end'))

  return plotLines
}
