import { Switch } from '@thanx/uikit/switch'
import { ContentBlockTypes } from 'api/landingPageTheme'
import Spinner from 'components/Spinner'
import { buildTranslate } from 'locales'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import Blocks from 'scenes/Cms/components/Blocks'
import CmsPage from 'scenes/Cms/components/CmsPage'
import { ContentBlockData, FormModel } from '../hooks'
import AddNewBlockModal from './AddNewBlockModal'
import BlockItem from './BlockItem'
import EditBlock from './EditBlock'
import EmptyState from './EmptyState'

const t = buildTranslate('cms.content.app_home_page.content')

type Props = {
  sidebar: React.ReactNode
  footer: React.ReactNode
  isFetching: boolean
  blockOrder: Array<string>
  setBlockOrder: (v: Array<string>) => void
  onNewBlock: (type: ContentBlockTypes) => void
  contentBlocks: FormModel['contentBlocks']
  updateContentBlock: (id: string, data: ContentBlockData) => void
  isEditModalOpen: boolean
  setIsEditModalOpen: (v: boolean) => void
  setIsFormValid: (v: boolean) => void
}

const ContentPage = ({
  sidebar,
  footer,
  isFetching,
  blockOrder,
  setBlockOrder,
  onNewBlock,
  contentBlocks,
  updateContentBlock,
  isEditModalOpen,
  setIsEditModalOpen,
  setIsFormValid,
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedBlockId, setSelectedBlockId] = useState<string | null>(null)

  const handleNewBlock = (type: ContentBlockTypes) => {
    onNewBlock(type)
    setModalOpen(false)
  }

  const handleEdit = (blockId: string) => {
    setSelectedBlockId(blockId)
    setIsEditModalOpen(true)
  }

  const handleDelete = (blockId: string) => {
    setBlockOrder(blockOrder.filter(b => b !== blockId))
    setIsEditModalOpen(false)
  }

  const handleClose = (previousState: ContentBlockData) => {
    selectedBlockId && updateContentBlock(selectedBlockId, previousState)
    setIsEditModalOpen(false)
    setIsFormValid(true)
  }

  if (isEditModalOpen && selectedBlockId) {
    const position = blockOrder.indexOf(selectedBlockId) + 1
    return (
      <EditBlock
        id={selectedBlockId}
        position={position}
        contentBlock={contentBlocks[selectedBlockId]}
        onDelete={handleDelete}
        onClose={handleClose}
        isLoading={isFetching}
      />
    )
  }

  return (
    <CmsPage
      className="mr-m"
      title={t('title')}
      description={t('description')}
      sidebar={sidebar}
    >
      <Switch condition={isEmpty(contentBlocks) && !isFetching}>
        <EmptyState />
      </Switch>

      <Spinner
        isLoading={isFetching}
        size="3x"
        className={'d-flex justify-content-center'}
      >
        <Blocks
          type={'block'}
          order={blockOrder}
          setOrder={setBlockOrder}
          renderItem={(itemId, index) => (
            <BlockItem
              id={itemId}
              index={index}
              data={contentBlocks[itemId]}
              onEdit={handleEdit}
            />
          )}
          onNewBlock={() => setModalOpen(true)}
        />
      </Spinner>

      {footer}

      <AddNewBlockModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onAddNewBlock={handleNewBlock}
        contentBlocks={contentBlocks}
        blockOrder={blockOrder}
      />
    </CmsPage>
  )
}

export default ContentPage
