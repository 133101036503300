import Section from 'components/Section'
import { buildTranslate } from 'locales'
import React from 'react'
import { useSelector } from 'react-redux'
import Header from 'scenes/Cms/components/Header'
import { selectApp } from 'selectors/app'
import { cmsBrandedAppConfigUrl } from 'utilities/urlUtils'
import Asset from '../Asset'
import ImageAsset from '../ImageAsset'

const t = buildTranslate('cms.brand.brand_assets')

const Logos: React.FC = () => {
  const app = useSelector(selectApp)

  if (!app) return null

  const { icon_image_urls, primary_image_urls, secondary_image_urls } = app

  return (
    <Section className="p-xl mt-l">
      <Header
        title={t('logos.title')}
        linkText={t('where_customers')}
        link={cmsBrandedAppConfigUrl}
      />
      <Asset
        className="mt-s"
        title={t('logos.app_icon')}
        description={t('logos.app_icon_description')}
        badges={[t('logos.app_icon_size'), t('logos.jpg')]}
      >
        <ImageAsset image={icon_image_urls?.small} />
      </Asset>
      <Asset
        className="mt-xl"
        title={t('logos.primary_logo')}
        description={t('logos.primary_logo_description')}
        badges={[t('logos.logo_size'), t('logos.png')]}
      >
        <ImageAsset
          image={primary_image_urls?.small}
          height="60px"
          objectFit="contain"
        />
      </Asset>
      <Asset
        className="mt-xl"
        title={t('logos.secondary_logo')}
        description={t('logos.secondary_logo_description')}
        badges={[t('logos.logo_size'), t('logos.png')]}
      >
        <ImageAsset
          image={secondary_image_urls?.small}
          height="60px"
          darkBackground={!!secondary_image_urls?.small}
          objectFit="contain"
        />
      </Asset>
    </Section>
  )
}

export default Logos
