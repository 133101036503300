import { Text } from '@thanx/uikit/text'
import { updateCampaign } from 'actions/campaigns'
import { alert } from 'actions/flash'
import InfoBox from 'components/InfoBox'
import Modal from 'components/ModalContainer'
import useDispatch from 'hooks/useDispatch'
import { buildTranslate } from 'locales'
import { MinimalVariant } from 'models/Campaign'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import VariantRadio from 'scenes/CampaignCenter/Builder/components/VariantRadio'
import StopCampaignModal from 'scenes/CampaignCenter/components/StopCampaignModal'
import { selectCampaign } from 'selectors/campaign'

const t = buildTranslate('thanx_campaigns.report.v3.test')

type Props = {
  campaignId: number
  variants: MinimalVariant[]
  isOpen: boolean
  onClose: () => void
}

const WinnerModal: React.FC<Props> = ({
  campaignId,
  variants,
  isOpen,
  onClose,
}) => {
  const [selectedVariant, setSelectedVariant] = useState<number>()
  const [isStopCampaignModalOpen, setIsStopCampaignModalOpen] = useState(false)
  const campaign = useSelector(state => selectCampaign(state, campaignId))
  const dispatch = useDispatch()

  const submitVariant = () => {
    dispatch(
      updateCampaign(campaignId, {
        campaign: {
          winning_variant_id: selectedVariant,
        },
      })
    )
    onClose()
  }

  const nonControlVariants = variants.filter(v => v.internal_type !== 'control')
  const hasControlVariant = nonControlVariants.length < variants.length

  const openCampaignModal = e => {
    e.preventDefault()
    onClose()
    setIsStopCampaignModalOpen(true)
  }

  function handleStop() {
    setIsStopCampaignModalOpen(false)
    dispatch(
      alert({
        key: 'success',
        message: I18n.t('thanx_campaigns.components.stopCampaignModal.alert', {
          name: campaign?.name,
        }),
      })
    )
  }

  return (
    <>
      <Modal
        canProceed={!!selectedVariant}
        title={t('winning_variant_modal.title')}
        subtitle={t('winning_variant_modal.subtitle')}
        proceedText={t('end')}
        isOpen={isOpen}
        onClose={onClose}
        onProceed={submitVariant}
      >
        <VariantRadio
          className="mt-m"
          variants={nonControlVariants}
          setSelectedVariant={setSelectedVariant}
          translate="thanx_campaigns.report.v3.test.start_sending"
        />
        {hasControlVariant && (
          <InfoBox
            className="mt-m"
            content={
              <span>
                {t('winning_variant_modal.info_box')[0]}
                <a href="/#" onClick={e => openCampaignModal(e)}>
                  {t('winning_variant_modal.info_box')[1]}
                </a>
                {t('winning_variant_modal.info_box')[2]}
              </span>
            }
          />
        )}
        <Text.BodyText4 tag="div" color="grey70" className="mt-m">
          {t('winning_variant_modal.footer')}
        </Text.BodyText4>
      </Modal>
      <StopCampaignModal
        campaignId={campaignId}
        isOpen={isStopCampaignModalOpen}
        onClose={() => setIsStopCampaignModalOpen(false)}
        onStop={handleStop}
      />
    </>
  )
}

export default WinnerModal
