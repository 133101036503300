import hollowInfo from 'assets/images/hollowInfo.svg'
import { buildTranslate } from 'locales'
import React from 'react'
import Alert from 'react-bootstrap-five/Alert'

type Props = {
  className?: string
  isApprover: boolean
}

const t = buildTranslate('thanx_campaigns.review.schedule')

/**
 * An alert than shows up for campaign schedulers that aren't approvers
 */
const ScheduleAlert: React.FC<Props> = props => {
  const { className = '', isApprover } = props

  const noticeText = isApprover ? t('approver_notice') : t('approval_required')

  return (
    <Alert variant="info" className={`${className} d-flex`}>
      <div className="d-flex flex-align-center">
        <img src={hollowInfo} alt="info" />
        <span className="pl-xs">{noticeText}</span>
      </div>
    </Alert>
  )
}

export default ScheduleAlert
