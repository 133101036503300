import { Button } from '@thanx/uikit/button'
import { Switch } from '@thanx/uikit/switch'
import { Text } from '@thanx/uikit/text'
import { useStyletron } from '@thanx/uikit/theme'
import SearchInput from 'components/SearchInput'
import { buildTranslate } from 'locales'
import { useState } from 'react'
import { Container } from 'react-bootstrap-five'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import NewCampaignModal from '../NewCampaignModal'
import CampaignTabs from '../Reports/components/CampaignTabs'
import { useFetchCampaignsByState, useSearchCampaigns } from '../Reports/hooks'
import CampaignSearchResults from './components/CampaignSearchResults'

const t = buildTranslate('thanx_campaigns.overview')

const Overview = () => {
  const [css] = useStyletron()
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined)
  const { review, scheduled, active, draft, complete } =
    useFetchCampaignsByState()
  const { campaigns, isLoading, pagination } = useSearchCampaigns(searchValue)
  const [showNewCampaignModal, setShowNewCampaignModal] = useState(false)

  const openNewCampaignModal = () => setShowNewCampaignModal(true)
  const closeNewCampaignModal = () => setShowNewCampaignModal(false)

  return (
    <>
      <Container>
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center py-xl">
          <Text.Header2 className="m-0 mr-xl mb-m mb-sm-0">
            {t('title')}
          </Text.Header2>
          <div className="d-flex flex-column flex-sm-row">
            <Link to="/segments">
              <Button kind="secondary" className="mr-s mb-s mb-sm-0">
                {t('browse_segments')}
              </Button>
            </Link>
            <Button onClick={openNewCampaignModal}>
              {t('create_campaign')}
            </Button>
          </div>
        </div>
      </Container>
      <Helmet>
        <title>{t('page_title')}</title>
      </Helmet>
      <div
        className={`grey-05-bkg border-top-1 pb-xxl grey-20-border pt-xl ${css({
          minHeight: 'calc(100% - 75px)',
        })}`}
      >
        <Container>
          <SearchInput
            className="w-100 mb-xl"
            onChange={setSearchValue}
            value={searchValue || ''}
            placeholder=""
          />
          <Switch condition={!!searchValue}>
            <CampaignSearchResults
              campaigns={campaigns}
              isLoading={isLoading}
              pagination={pagination}
            />
          </Switch>
          <Switch condition={!searchValue}>
            <CampaignTabs
              reviewCampaigns={review.campaigns}
              scheduledCampaigns={scheduled.campaigns}
              activeCampaigns={active.campaigns}
              draftCampaigns={draft.campaigns}
              completeCampaigns={complete.campaigns}
              tabLoading={{
                review: review.loading,
                scheduled: scheduled.loading,
                active: active.loading,
                draft: draft.loading,
                complete: complete.loading,
              }}
            />
          </Switch>
        </Container>
      </div>
      <NewCampaignModal
        isOpen={showNewCampaignModal}
        onClose={closeNewCampaignModal}
      />
    </>
  )
}

export default Overview
