import { Fields as Merchant } from 'models/Merchant'
import React from 'react'
import { Col, Row } from 'react-bootstrap-five'
import { getDiscountCategories } from '../../helpers'
import { RedeemTypeItem } from '../../types'
import Category from './Category'

type Props = {
  merchant: Merchant | null
  typeItem: RedeemTypeItem | null
  setRedeemType: (typeItem: RedeemTypeItem) => void
}

const TypeStep: React.FC<Props> = ({ merchant, typeItem, setRedeemType }) => {
  const categories = getDiscountCategories(!!merchant?.ordering_enabled)

  return (
    <Row className="d-flex justify-content-center">
      <Col lg={6}>
        {categories.map(redeemCategory => (
          <Category
            key={redeemCategory.key}
            redeemCategory={redeemCategory}
            selectedTypeItem={typeItem}
            onItemSelect={setRedeemType}
          />
        ))}
      </Col>
    </Row>
  )
}

export default TypeStep
