import { Fields, LinkSection, LinkType, RegularLinkTypes } from 'models/Link'
import orm from 'models/orm'
import { createSelector as createOrmSelector } from 'redux-orm'
import { createSelector } from 'reselect'

export const selectLinks = createOrmSelector(
  orm,
  (state: any) => state.orm,
  (session: any): Fields[] => {
    return session.Link.all().toRefArray()
  }
)

export const selectRegularLinks = createSelector(
  selectLinks,
  (links: Fields[]): Fields[] => {
    return links.filter(
      link =>
        link.section !== LinkSection.Social &&
        link.type !== RegularLinkTypes.Premium &&
        link.type !== RegularLinkTypes.Featured
    )
  }
)

export const selectSocialLinks = createSelector(
  selectLinks,
  (links: Fields[]): Fields[] => {
    return links.filter(link => link.section === LinkSection.Social)
  }
)

export const selectLinksByType = createSelector(
  selectLinks,
  (_state: any, type: LinkType) => type,
  (links: Fields[], type: LinkType): Fields[] => {
    return links.filter(link => link.type === type)
  }
)
