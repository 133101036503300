import React from 'react'
import { Modal as BSModal, ModalProps } from 'react-bootstrap-five'

type Props = {
  children: React.ReactNode
  show: boolean
  onModalClose: () => any
} & ModalProps

const Modal: React.FC<Props> = ({
  show,
  onModalClose,
  children,
  ...modalProps
}) => {
  return (
    <BSModal
      className="fs-unmask"
      show={show}
      onHide={onModalClose}
      {...modalProps}
    >
      <BSModal.Body className="padding-none">
        <div className="absolute right-0 top-0 text-center close-button z1up">
          <span
            className="font-size-32 grey-60 light cursor-pointer"
            onClick={onModalClose}
          >
            &times;
          </span>
        </div>
        {children}
      </BSModal.Body>
    </BSModal>
  )
}

export default Modal
