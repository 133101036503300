import { Text } from '@thanx/uikit/text'
import { buildTranslate } from 'locales'
import isNil from 'lodash/isNil'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const t = buildTranslate('reports.not_ready_yet')

type PropsT = {
  title: string
  remainingDays?: number | null
  minimumDaysAfterLaunch?: number
  className?: string
  emptyStateMessage?: string | null
}

const NotReady: React.FC<PropsT> = ({
  title,
  remainingDays,
  minimumDaysAfterLaunch,
  className,
  emptyStateMessage,
}) => {
  const message = (() => {
    if (emptyStateMessage) return emptyStateMessage

    if (isNil(remainingDays)) {
      if (!minimumDaysAfterLaunch) {
        return t('available_after_launch')
      } else {
        return t('coming_soon', { days: minimumDaysAfterLaunch })
      }
    }

    return t('available_in_x_days', { days: remainingDays })
  })()

  return (
    <div className={className}>
      <Text.Header3 className="mb-xs mt-0 mb-m" tag="div">
        {title}
      </Text.Header3>

      <section className="mt-xl">
        <Row className="section">
          <Col sm={12}>
            <Text.Header4 bold className="text-center mb-xs">
              {t('title')}
            </Text.Header4>
          </Col>
        </Row>
        <Row className="section justify-content-sm-center">
          <Col className="col-content text-center" sm={7}>
            <Text.BodyText3 color="grey70">{message}</Text.BodyText3>
          </Col>
        </Row>
      </section>
    </div>
  )
}

export default NotReady
