import Select from 'components/Form/SelectWithFormsy'
import Spinner from 'components/Spinner'
import palette from 'constants/palette'
import Highcharts from 'highcharts'
import type { Fields as Campaign } from 'models/Campaign'
import React from 'react'
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap'
import { I18n, Translate } from 'react-redux-i18n'
import Refinements from 'scenes/CampaignCenter/Builder/components/Refinements'
import LocationRefinement from 'scenes/CampaignCenter/Builder/components/Refinements/LocationRefinement'
import Graph from './components/Graph'
import Stat from './components/Stat'
import type { Category } from './helper'

type Props = {
  campaign: Campaign
  isLoading: boolean
  categories: Category[]
  categoryPercent: number | null
}

const View: React.FC<Props> = props => {
  const { campaign, isLoading, categories, categoryPercent } = props
  const hasLocations =
    !!campaign.config_location_ids && campaign.config_location_ids.length > 0
  const selectedCategory = categories.find(
    category => campaign.config_category === category.name
  )
  const selectOptions = categories.map(category => ({
    label: category.name,
    value: category.name,
  }))

  return (
    <Spinner
      isLoading={isLoading}
      size="4x"
      className="text-center padding-top-huge"
    >
      <div className="container">
        <Row>
          <Col md={6} className="padding-top-huge">
            <div className="padding-right-huge">
              <Translate
                value="thanx_campaigns.campaigns.upsell.steps.setup.title"
                tag="p"
                className="grey-90 font-size-32 margin-bottom-huge bold line-height-40"
              />
              {!!categoryPercent && (
                <Stat
                  category={campaign.config_category}
                  percent={categoryPercent}
                />
              )}
              <FormGroup className="margin-bottom-extra-large">
                <ControlLabel>
                  {I18n.t(
                    'thanx_campaigns.campaigns.upsell.steps.setup.selector.title'
                  )}
                </ControlLabel>
                <Select
                  placeholder={I18n.t(
                    'thanx_campaigns.campaigns.upsell.steps.setup.selector.placeholder'
                  )}
                  options={selectOptions}
                  defaultValue={campaign.config_category}
                  name="config_category"
                />
              </FormGroup>
              <div className="margin-bottom-extra-large">
                <Refinements isOpen={hasLocations} isDisabled={hasLocations}>
                  <LocationRefinement campaign={campaign} />
                </Refinements>
              </div>
            </div>
          </Col>
          <Col md={6} className="padding-huge grey-10-bkg">
            <div className="text-center padding-top-huge margin-bottom-large">
              <Translate
                value="thanx_campaigns.campaigns.upsell.steps.setup.graph.title"
                className="font-size-18 grey-50"
              />
            </div>
            <div
              className="padding-bottom-huge overflow-scroll"
              style={{ height: '50vh' }}
            >
              <Graph
                selectedCategory={selectedCategory && selectedCategory.name}
                order={categories.map(category => category.name)}
                data={
                  categories.map(
                    category => category.count
                  ) as Highcharts.XrangePointOptionsObject[]
                }
                selectedColor={palette.wisteria}
                barColor={palette.grey40}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Spinner>
  )
}

export default View
