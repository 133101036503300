import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Translate } from 'react-redux-i18n'
import { textUtils } from 'utilities/textUtils'
import Phone from './Phone'

import { Fields as Campaign } from 'models/Campaign'

type Props = {
  title: string
  subtext: string
  campaign: Campaign
}

const CampaignDisplay: React.FC<Props> = props => {
  const { title, subtext, campaign } = props
  return (
    <Row>
      <Col sm={5}>
        <div className="font-size-24 padding-bottom-medium grey-90 bold">
          {title}
        </div>
        <div
          className="font-size-16 padding-bottom-huge grey-70"
          style={{ width: '360px' }}
        >
          {subtext}
        </div>
        <div
          className="grey-30-border border-1 border-radius-5 margin-bottom-medium"
          style={{ width: '566px' }}
        >
          <div className="margin-large">
            <div className="margin-bottom-small">
              <Translate
                value="loyalty.campaign_details.users_get"
                className="small-caps-4 grey-90"
              />
            </div>
            <div className="grey-70 font-size-14">
              {textUtils.initialCapital(campaign.redeem_description)}
            </div>
          </div>
          <div className="grey-30-border border-bottom-1 margin-right-large margin-left-large" />
          <div className="margin-large">
            <div className="margin-bottom-small">
              <Translate
                value="loyalty.campaign_details.action"
                className="small-caps-4 grey-90"
              />
            </div>
            <div className="grey-70 font-size-14">
              {textUtils.initialCapital(campaign.earn_description)}
            </div>
          </div>
          <div className="grey-30-border border-bottom-1 margin-right-large margin-left-large" />
          <div className="margin-large">
            <div className="margin-bottom-small">
              <Translate
                value="loyalty.campaign_details.cost"
                className="small-caps-4 grey-90"
              />
            </div>
            <div className="grey-70 font-size-14">
              ${campaign.redeem_discount}
            </div>
          </div>
          <div className="grey-30-border border-bottom-1 margin-right-large margin-left-large" />
          <div className="margin-large">
            <div className="margin-bottom-small">
              <Translate
                value="loyalty.campaign_details.retail"
                className="small-caps-4 grey-90"
              />
            </div>
            <div className="grey-70 font-size-14">
              ${campaign.redeem_retail_value}
            </div>
          </div>
        </div>
      </Col>
      <Col sm={2} />
      <Col sm={5}>
        <Phone campaign={campaign} />
      </Col>
    </Row>
  )
}

export default CampaignDisplay
