import { useStyletron } from '@thanx/uikit/theme'
import HeaderCell from 'components/HeaderCell'
import { buildTranslate } from 'locales'
import { Fields as UserFeedback } from 'models/UserFeedback'
import React from 'react'
import Row from './Row'

const t = buildTranslate('users.view.activity.feedback')

type PropsT = {
  feedbacks: Array<UserFeedback>
  onOrderDetailsClick: (feedback: UserFeedback) => void
}

const Table: React.FC<PropsT> = ({
  feedbacks,
  onOrderDetailsClick,
}: PropsT) => {
  const [css] = useStyletron()

  return (
    <table
      className={`full-width ${css({
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
      })}`}
    >
      <thead>
        <tr>
          <HeaderCell className={`${css({ width: '18%' })}`}>
            {t('date')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '10%' })}`}>
            {t('rating')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '40%' })}`}>
            {t('comment')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '20%' })}`}>
            {t('location')}
          </HeaderCell>
          <HeaderCell className={`${css({ width: '12%' })}`} key={'last'} />
        </tr>
      </thead>
      <tbody>
        {feedbacks.map(feedback => (
          <Row
            key={feedback.id}
            feedback={feedback}
            onOrderDetailsClick={onOrderDetailsClick}
          />
        ))}
      </tbody>
    </table>
  )
}

export default Table
