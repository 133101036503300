export default {
  default: 'Thanx Dashboard',
  terms_default: 'Thanx Terms and Conditions',
  privacy_default: 'Thanx Privacy Policy',
  terms_branded: '%{name} Rewards Terms and Conditions',
  privacy_branded: '%{name} Rewards Privacy Policy',
  feedback: 'Respond to customer feedback - Feedback | Thanx',
  'feedback/trends': 'Sentiment analysis - Feedback | Thanx',
  'feedback/return_rate': 'Return rate - Feedback | Thanx',
  'feedback/report_card': 'Report card - Feedback | Thanx',
  'customer_insights/top_customers': 'Top members | Thanx',
  members: 'All customers | Thanx',
  member_profile: 'Member profile | Thanx',
  'customer_insights/quartile': 'Quartile | Thanx',
  'customer_insights/frequency': 'Frequency | Thanx',
  subscriptions: 'Email subscribers | Thanx',
  manage_users: 'Dashboard access | Thanx',
  rewards_report: 'Rewards accounting | Thanx',
  daily_activity: 'Daily activity | Thanx',
  purchases: 'Purchases | Thanx',
  revenue: 'Revenue | Thanx',
  captureRate: 'Revenue capture rate | Thanx',
  performance_reviews: 'Program reviews | Thanx',
  exclusive_deals: 'Exclusive deals | Thanx',
  '/brand_content': 'Brand & content | Thanx',
  '/brand_content#ordering_home_page':
    'App home page - Brand & content | Thanx',
  '/brand_content#web_hero_image': 'Web hero image - Brand & content | Thanx',
  '/brand_content#alert_banner': 'Alert banner - Brand & content | Thanx',
  '/brand_content#custom_links': 'Custom links - Brand & content | Thanx',
  '/brand_content#exclusive_deals': 'Exclusive deals - Brand & content | Thanx',
  '/brand_content#brand_assets': 'Brand assets - Brand & content | Thanx',
  '/brand_content#app_store_assets':
    'App store assets - Brand & content | Thanx',
  '/brand_content#launch_screens': 'Launch screens - Brand & content | Thanx',
  '/brand_content#tiers': 'Tiers - Brand & content | Thanx',
  '/brand_content#social_links': 'Social media links - Brand & content | Thanx',
  '/brand_content#custom_fonts': 'Custom fonts - Brand & content | Thanx',
  '/brand_content#locations': 'Locations - Brand & content | Thanx',
  '/brand_content#smart_links': 'Smart links - Brand & content | Thanx',
  '/brand_content#ordering_configuration':
    'Ordering configuration - Brand & content | Thanx',
  '/brand_content#receipt_settings':
    'Receipt settings - Brand & content | Thanx',
  '/brand_content#analytics':
    'Facebook & Google Analytics - Brand & content | Thanx',
  '/brand_content#signup_message': 'Signup message - Brand & content | Thanx',
  '/brand_content#sms_keywords': 'SMS keywords - Brand & content | Thanx',
  '/reports/ordering_conversion':
    'Cart conversion - Ordering conversion | Thanx',
  '/reports/ordering_conversion#cart_conversion':
    'Cart conversion - Ordering conversion | Thanx',
  '/reports/ordering_conversion#unaccepted_carts':
    'Unaccepted carts - Ordering conversion | Thanx',
  '/reports/app_downloads': 'App downloads | Thanx',
  '/reports/average_check#overview': 'Overview - Average check | Thanx',
  '/reports/average_check#handoff_mode': 'Handoff mode - Average check | Thanx',
  '/reports/average_check#location': 'Locations - Average check | Thanx',
  '/reports/engagement#overview': 'Overview - Engagement | Thanx',
  '/reports/engagement#average_monthly_value':
    'Average Monthly Value - Engagement | Thanx',
  '/reports/tiers#overview': 'Overview - Tiers | Thanx',
  '/reports/tiers#activity': 'Activity - Tiers | Thanx',
  '/reports/tiers#configuration': 'Configuration - Tiers | Thanx',
  '/reports/feedback#ratings': 'Rating details - Overview - Feedback | Thanx',
  '/reports/feedback#reviews': 'Review details - Overview - Feedback | Thanx',
  '/reports/frequency': 'Frequency | Thanx',
  'reports/membershipoverview': 'Membership overview | Thanx',
  '/reports/respond': 'Respond | Thanx',
}
