import redeemedImage from 'assets/images/campaign-center/reports/performance-metrics/clicked.svg'
import clickedImage from 'assets/images/campaign-center/reports/performance-metrics/clicked.svg'
import sentImage from 'assets/images/campaign-center/reports/performance-metrics/sent.svg'
import revenueImage from 'assets/images/campaign-center/reports/performance-metrics/spend.svg'
import viewedImage from 'assets/images/campaign-center/reports/performance-metrics/viewed.svg'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import withMetrics from 'scenes/CampaignCenter/Report/components/withMetrics'
import configs from 'scenes/CampaignCenter/Report/configs'
import PerformanceMetricBox from './PerformanceMetricBox'
import PerformanceMetricsView from './PerformanceMetricsView'

import type { Fields as Campaign } from 'models/Campaign'
import type { Metrics } from 'models/CampaignMetric'

type Props = {
  campaign: Campaign
  metrics?: Metrics
}

export const PerformanceMetricsContainer: React.FC<Props> = ({
  campaign,
  metrics,
}) => {
  const config = configs[campaign.type]

  function sentCountMetric(): React.ReactNode {
    const metric = metrics?.message_count
    let count = undefined
    if (metric && !isNil(metric.sent_count)) {
      count = I18n.l(metric.sent_count)
    }

    return (
      <PerformanceMetricBox
        value={count}
        description={I18n.t(
          'thanx_campaigns.report.summary.performance.sent_count'
        )}
        image={sentImage}
        alt="sent"
        color={config.color}
      />
    )
  }

  function viewedCountMetric(): React.ReactNode {
    const metric = metrics?.message_count
    let count = undefined
    let percent = undefined
    if (metric) {
      if (!isNil(metric.viewed_count)) {
        count = I18n.l(metric.viewed_count)

        if (!isNil(metric.sent_count) && metric.sent_count > 0) {
          const rate = metric.viewed_count / metric.sent_count
          percent = I18n.l(rate, { style: 'percent' })
        }
      }
    }

    return (
      <PerformanceMetricBox
        value={count}
        subValue={percent}
        description={I18n.t(
          'thanx_campaigns.report.summary.performance.viewed_count'
        )}
        image={viewedImage}
        alt="viewed"
        color={config.color}
      />
    )
  }

  function clickedCountMetric(): React.ReactNode | null {
    if (
      campaign.redeem ||
      (!campaign.notify_email_button_url && !campaign.notify_email_conversion)
    )
      return null

    const metric = metrics?.message_count
    let count = undefined
    let percent = undefined
    if (metric) {
      if (!isNil(metric.clicked_count)) {
        count = I18n.l(metric.clicked_count)

        if (!isNil(metric.viewed_count) && metric.viewed_count > 0) {
          const rate = metric.clicked_count / metric.viewed_count
          percent = I18n.l(rate, { style: 'percent' })
        }
      }
    }

    return (
      <PerformanceMetricBox
        value={count}
        subValue={percent}
        description={I18n.t(
          'thanx_campaigns.report.summary.performance.clicked_count'
        )}
        image={clickedImage}
        alt="clicked"
        color={config.color}
      />
    )
  }

  function redeemedCountMetric(): React.ReactNode | null {
    if (!campaign.redeem) return null

    const metric = metrics?.redeem
    const messageCountMetric = metrics?.message_count
    let count = undefined
    let percent = undefined
    if (metric) {
      if (!isNil(metric.count)) {
        count = I18n.l(metric.count)

        if (
          !isNil(messageCountMetric?.viewed_count) &&
          messageCountMetric?.viewed_count &&
          messageCountMetric?.viewed_count > 0
        ) {
          const rate = metric.count / messageCountMetric.viewed_count
          percent = I18n.l(rate, { style: 'percent' })
        }
      }
    }

    return (
      <PerformanceMetricBox
        value={count}
        subValue={percent}
        description={I18n.t(
          'thanx_campaigns.report.summary.performance.redeemed_count'
        )}
        image={redeemedImage}
        alt="redeemed"
        color={config.color}
      />
    )
  }

  function costMetric(): React.ReactNode | null {
    if (!campaign.redeem) return null

    const metric = metrics?.cost
    let cost = undefined

    if (metric && !isNil(metric.total_cost)) {
      cost = numeral(metric.total_cost).format('$0.[0]a').toUpperCase()
    }

    return (
      <PerformanceMetricBox
        value={cost}
        description={I18n.t('thanx_campaigns.report.summary.performance.cost')}
        image={revenueImage}
        alt="sent"
        color={config.color}
      />
    )
  }

  function grossRevenueMetric(): React.ReactNode | null {
    if (campaign.redeem) return null

    const metric = metrics?.revenue

    if (!metric) return null

    let spend = undefined

    if (metric && !isNil(metric.gross_revenue)) {
      spend = I18n.l(metrics?.revenue?.gross_revenue, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
    }

    return (
      <PerformanceMetricBox
        value={spend}
        description={I18n.t(
          'thanx_campaigns.report.summary.performance.gross_revenue'
        )}
        image={revenueImage}
        alt="sent"
        color={config.color}
      />
    )
  }

  function metricBoxes(): React.ReactNode[] {
    return [
      sentCountMetric(),
      viewedCountMetric(),
      clickedCountMetric(),
      redeemedCountMetric(),
      costMetric(),
      grossRevenueMetric(),
    ].filter(Boolean)
  }

  return <PerformanceMetricsView boxes={metricBoxes()} />
}
// @ts-ignore
export default withMetrics(props => {
  const config = configs[props.campaign.type]
  return ['cost', 'message_count', 'redeem', 'revenue'].filter(metric =>
    config.metrics.includes(metric)
  )
})(PerformanceMetricsContainer)
